import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { downloadArchive } from "msa2-ui/src/api/repository";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import { useTranslation } from "react-i18next";
import Repository from "msa2-ui/src/services/Repository";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";

import {
  CircularProgress,
  IconButton,
  Tooltip,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Toolbar from "msa2-ui/src/components/modal/Toolbar";

const useStyles = makeStyles(({ palette, colors, typography }) => ({
  publish: {
    backgroundColor: colors.green,
    color: colors.white,
    marginRight: 10,
  },
  title: {
    marginRight: 15,
  },
  toolbarButton: {
    marginRight: 10,
  },
  fixedToolbar: {
    backgroundColor: palette.background.paper,
    position: "fixed",
  },
  date: {
    fontWeight: typography.fontWeightMedium,
    color: colors.blueDark1,
  },
  dateLabel: {
    fontWeight: typography.fontWeightLight,
    color: colors.blueDark1,
  },
  loading: { marginRight: 15 },
}));

const ModalTitleBar = ({
  title,
  date,
  onClose,
  onSave,
  onPublish,
  onDiscard,
  closeButtonLabel,
  saveButtonLabel,
  workflowUri,
  discardButtonLabel,
  publishButtonLabel,
  isLoading,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector(getToken);
  return (
    <Toolbar className={classes.fixedToolbar}>
      <Toolbar.Section>{title || ""}</Toolbar.Section>
      <Toolbar.Section>
        {isLoading && <CircularProgress className={classes.loading} />}
        {date && (
          <>
            <Typography className={classes.dateLabel}>Last saved: </Typography>
            <Typography className={classes.date}> {date}</Typography>
            <Toolbar.Divider />
          </>
        )}
        <Button
          id="AUTOMATION_DETAILS_EDIT_DISCARD_BTN"
          variant="text"
          size="medium"
          color="primary"
          className={classes.toolbarButton}
          onClick={onDiscard}
          disabled={disabled}
        >
          {discardButtonLabel}
        </Button>
        <Button
          id="AUTOMATION_DETAILS_EDIT_SAVE_BTN"
          variant="contained"
          size="medium"
          color="primary"
          className={classes.toolbarButton}
          onClick={onSave}
          disabled={disabled}
        >
          {saveButtonLabel}
        </Button>
        {onPublish && (
          <Button
            id="AUTOMATION_DETAILS_EDIT_PUBLISH_BTN"
            variant="contained"
            size="medium"
            color="default"
            className={classnames(classes.publish, classes.toolbarButton)}
            onClick={onPublish}
            disabled={disabled}
          >
            {publishButtonLabel}
          </Button>
        )}
        {workflowUri && (
          <Tooltip title={t("Download Workflow")}>
            <IconButton
              id={`AUTOMATION_DETAILS_DOWNLOAD_BTN_${workflowUri}`}
              aria-label={t("Download Workflow")}
              onClick={() => {
                downloadArchive({
                  path: workflowUri + ".xml",
                  token,
                  fileName: Repository.getFilenameFromUri(workflowUri) + ".zip",
                  onError: (e) => {
                    enqueueSnackbar(t("Unable to download contents"), {
                      variant: "error",
                    });
                  },
                });
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
        <Toolbar.Divider />
        <IconButton
          id="AUTOMATION_DETAILS_EDIT_CLOSE_BTN"
          variant="contained"
          size="medium"
          color="default"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar.Section>
    </Toolbar>
  );
};

ModalTitleBar.propTypes = {
  title: PropTypes.object,
  date: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
  onPublish: PropTypes.func,
  closeButtonLabel: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  workflowUri: PropTypes.string,
  discardButtonLabel: PropTypes.string,
  publishButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ModalTitleBar;
