import { setWith, set, isEmpty } from "lodash";
import { iconMapper } from "msa2-ui/src/utils/iconMapping";
import { get, destroy, put, post } from "./request";

const API = process.env.REACT_APP_API_PATH;

export const buildSortParam = ({ sort, sortBy, separator = ":" }) => {
  return sort && sortBy ? `${sortBy}${separator}${sort}` : null;
};

const addExtension = (response) =>
  response.map(({ uri, ...rest }) => ({
    uri: uri + ".xml",
    ...rest,
  }));

const addIcon = (response) =>
  response.map(({ icon, ...microservice }) => ({
    ...microservice,
    icon: iconMapper(icon),
  }));

const buildFiltersParam = ({ vendorId, modelId, searchParams }) => {
  const filter = [];
  if (vendorId) filter.push(`vendor:${vendorId}`);
  if (modelId) filter.push(`model:${modelId}`);
  if (searchParams) filter.push(`displayName:${searchParams}`);
  return filter.join(",");
};
/*
 * Spec: https://10.31.1.52/swagger/#/Asset%20Management/getDeviceAsset
 */
export const getMicroservices = ({
  customerId,
  subtenant,
  token,
  sort,
  sortBy,
  page,
  vendorId,
  modelId,
  searchParams,
  pageSize: page_size,
  transforms = [addExtension, addIcon],
  filterByLabel,
}) => {
  return get({
    url: `${API}/repository/v1/microservice/list/actor`,
    queryParams: {
      customer_id: customerId,
      subtenant,
      sort: buildSortParam({ sort, sortBy }),
      page,
      page_size,
      filterByLabel,
      filters: buildFiltersParam({
        vendorId,
        modelId,
        searchParams,
      }),
    },
    token,
    transforms,
  });
};

const populateMissingVariables = (response) => {
  if (!response.variables.variable) {
    return {
      ...response,
      variables: {
        ...response.variables,
        variable: [],
      },
    };
  }
  return response;
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/getMicroserviceCommandDefinition
 */
export const getMicroservice = ({
  microServiceUri: uri,
  token,
  transforms = [],
}) => {
  return get({
    url: `${API}/repository/v2/resource/microservice`,
    queryParams: {
      uri,
    },
    transforms: [populateMissingVariables, ...transforms],
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/deleteRepositoryResource
 */
export const deleteMicroservice = ({ microServiceUri: uri, token }) => {
  return destroy({
    url: `${API}/repository/v2/resource`,
    queryParams: {
      uri,
    },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/createRepositoryResource
 */
export const createMicroservice = ({ body, token }) => {
  return post({
    url: `${API}/repository/v2/resource/microservice`,
    body: {
      ...body,
    },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/addRepositoryResource
 */
export const updateMicroservice = ({ body, token }) => {
  return put({
    url: `${API}/repository/v2/resource/microservice`,
    body: {
      ...body,
    },
    token,
  });
};

/*
 * The instance data arrives as an object in the following format:
 *  {
 *    microservice_name.path.to.instance.and.property: value
 *  }
 *
 * This transform formats the object like this:
 *  {
 *    microservice_name: {
 *      instance: {
 *        property: value
 *      }
 *    }
 *  }
 */
const formatInstanceData = (rawInstanceDataResponse) => {
  const rawDataEntries = Object.entries(rawInstanceDataResponse);
  const response = {};
  //Two loops:
  //Using set with to create empty objects for the first and second value from .split('.')
  //Those values are guaranteed to be MS name, and object name which could be an integer.
  //Second loop for setting all values within those objects which could contain integers for array variables.
  rawDataEntries.forEach((rawDataEntry) => {
    const [pathToSaveAt] = rawDataEntry;
    const [microservicePath, object_idPath] = pathToSaveAt.split(".");
    setWith(response, `${microservicePath}.${object_idPath}`, {}, Object);
  });

  rawDataEntries.forEach((rawDataEntry) => {
    const [pathToSaveAt, valueToSave] = rawDataEntry;
    set(response, pathToSaveAt, valueToSave);
  }, {});

  return response;
};

/**
 * NOTE: Temporary we will be using backward compatible code to maintain sorting order
 */
const formatInstanceDataForArray = (rawInstanceDataResponse = []) => {
  // This is default key which is being used by most of microservices
  // const key = "object_id";

  // if (rawInstanceDataResponse.some((data) => key in data)) {
  //   return keyBy(rawInstanceDataResponse, key);
  // }

  // This is backward compatible code to maintain object structure. so it will have indices as key
  return rawInstanceDataResponse.reduce((result, data, index) => {
    result[index] = data;
    return result;
  }, {});
};

const formatTestParserResponse = (response) => {
  const { message } = response;
  const entries = message.split("\n").filter((item) => !isEmpty(item));

  const formattedEntries = entries.map((entry) => {
    const [key, value] = entry.split(" = ");
    return { key, value };
  });

  return formattedEntries.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: item.value }),
    {},
  );
};

/*
 * Gets instance data for all microservices attached to a managed entity
 *
 * Spec: https://10.31.1.52/swagger/#/Configuration%20Object/retrieveConfigurationObjects
 */
export const getInstanceDataForMicroservicesOfManagedEntity = ({
  token,
  id,
  msUri,
  searchQuery,
  page,
  pageSize,
  sort,
  sortOrder,
  format = false,
  arrayFormatFlag = false,
  transforms = [],
}) => {
  return get({
    url: `${API}/configuration-objects/v1/device/${id}`,
    queryParams: {
      msUri,
      searchQuery,
      page,
      pageSize,
      sort,
      sortOrder,
      arrayFormatFlag,
    },
    token,
    transforms: [
      ...(format
        ? arrayFormatFlag
          ? [formatInstanceDataForArray]
          : [formatInstanceData]
        : []),
      ...transforms,
    ],
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/ordercommand/listObjectsName
 */
export const listObjectsName = ({ token, deviceId }) => {
  return get({
    url: `${API}/ordercommand/objects/${deviceId}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/ordercommand/listObjectInstances
 */
export const listObjectInstances = ({ token, deviceId, objectName }) => {
  return get({
    url: `${API}/ordercommand/objects/${deviceId}/${objectName}`,
    token,
  });
};

const extractVariablesArrayFromApiResponse = (rawApiResponse) => {
  return rawApiResponse.variable || [];
};

const sortVariablesByOrderKey = (variablesArray) => {
  return variablesArray.sort(
    (variableA, variableB) => variableA.displayOrder - variableB.displayOrder,
  );
};

/*
 * Gets all variables of a microservice
 *
 * Spec: https://10.31.1.52/swagger/#/Repository/getResourceVariables
 */
export const getVariablesOfMicroservice = ({
  token,
  uri,
  transforms = [extractVariablesArrayFromApiResponse, sortVariablesByOrderKey],
}) => {
  return get({
    url: `${API}/repository/v2/resource/variables`,
    queryParams: {
      uri,
    },
    token,
    transforms,
  });
};

/**
 * Test parser
 */
export const testParser = ({
  token,
  deviceId,
  uri,
  transforms = [formatTestParserResponse, formatInstanceData],
}) => {
  return post({
    url: `${API}/repository/v1/test-parser/${deviceId}`,
    queryParams: {
      uri,
    },
    token,
    transforms,
  });
};
