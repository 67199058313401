import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { reset, change } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

import useToggle from "react-use/lib/useToggle";
import reduxForm from "redux-form/lib/reduxForm";

import { getFormValues } from "msa2-ui/src/store/form";

import flow from "lodash/flow";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

import useApi from "msa2-ui/src/hooks/useApi";
import {
  addImportExportEntry,
  getImportExportSettings,
  deactivateImportExportEntry,
} from "msa2-ui/src/api/settings";

import { getToken } from "msa2-ui/src/store/auth";

import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Lens from "@material-ui/icons/Lens";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { required, validUrl } from "msa2-ui/src/utils/validators";

import SettingsForm, {
  getDirectoryValue,
} from "msa2-ui/src/services/SettingsForm";
import MSATableBody from "msa2-ui/src/components/MSATableBody";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";

import FormSection from "msa2-ui/src/components/formSection/FormSection";
import ImportExportEditDialog from "msa2-ui/src/routes/settings/ImportExportEditDialog";
import ImportExportPullDialog from "msa2-ui/src/routes/settings/ImportExportPullDialog";
import ImportExportDeactivateDialog from "msa2-ui/src/routes/settings/ImportExportDeactivateDialog";

const TABLE_HEADER_COLUMNS = [
  {
    id: "status",
    name: "Status",
    align: "center",
  },
  {
    id: "repoType",
    name: "Repository Type",
    align: "center",
  },
  {
    id: "url",
    name: "URL",
    align: "center",
  },
  {
    id: "repoName",
    name: "Directory",
    align: "center",
  },
  {
    id: "username",
    name: "Username",
    align: "center",
  },
  {
    id: "edit",
    name: "",
    align: "right",
  },
  {
    id: "pull",
    name: "",
    align: "right",
  },
  {
    id: "deactivate",
    name: "",
    align: "right",
  },
];

const useStyles = makeStyles(({ palette }) => ({
  header: {
    marginBottom: 16,
    paddingTop: 16,
    paddingLeft: 16,
  },
  button: {
    marginTop: 5,
  },
  noPadding: {
    padding: 0,
  },
  importExportTable: {
    marginTop: 16,
    paddingBottom: 16,
    borderTop: "1px solid rgba(81, 81, 81, 1)",
    borderCollapse: "initial",
  },
  statusNotOk: {
    color: palette.error.main,
    width: 12,
  },
  statusOk: {
    color: palette.success.main,
    width: 12,
  },
}));

const ImportExport = ({ handleSubmit, token }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const dispatch = useDispatch();

  const { path, url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(false);
  const [deactivateEntry, setDeactivateEntry] = useState({});
  const [pullEntry, setPullEntry] = useState({});

  const [showEditDialog, toggleShowEditDialog] = useToggle(false);
  const [showDeactivateDialog, toggleShowDeactivateDialog] = useToggle(false);

  const form = SettingsForm.ImportExportFormName;
  const formValues = useSelector(getFormValues(form));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [
    importExportIsLoading,
    importExportError,
    importExportResponse = [],
    ,
    importExportReload,
  ] = useApi(getImportExportSettings, {
    transforms: [
      (response) =>
        response.map((entry) => {
          return { ...entry, repoName: getDirectoryValue(entry.repoType) };
        }),
    ],
  });

  const handleAddRepositoryEntry = async () => {
    setIsLoading(true);

    const entry = omit(formValues, [
      formValues.repoType,
      formValues.repoName,
      formValues.url,
      formValues.username,
      formValues.password,
      formValues.storeToken,
    ]);

    const [error] = await addImportExportEntry({ body: entry, token });

    const message = error
      ? error.message || t("GenericErrorMessage")
      : t("Repository successfully added");

    const variant = error ? "error" : "success";

    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      dispatch(reset(form));
      importExportReload();
    }

    setIsLoading(false);
  };

  const handleRepoTypeChange = (event, type) => {
    dispatch(
      change(
        SettingsForm.ImportExportFormName,
        SettingsForm.fields.repoName,
        SettingsForm.getDirectoryValue(type),
      ),
    );
  };

  const handleEditDialogClose = () => {
    toggleShowEditDialog(false);
    importExportReload();
  };

  const handleDeactivateClick = (entry) => {
    const { repoName, repoType } = entry;
    setDeactivateEntry({ repoName, repoType });
  };

  const handleDeactivateDialogSubmit = async () => {
    toggleShowDeactivateDialog(false);
    setIsLoading(true);

    const [error] = await deactivateImportExportEntry({
      params: deactivateEntry,
      token,
    });
    const message = error
      ? error.getMessage()
      : t("Repository successfully deactivated");
    const variant = error ? "error" : "success";

    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      importExportReload();
    }

    setIsLoading(false);
  };

  const handleDeactivateDialogClose = () => {
    toggleShowDeactivateDialog(false);
    importExportReload();
  };

  const isStatusOk = (status) => {
    return status === SettingsForm.repositoryStatuses.ok;
  };

  const renderImportExportInputs = () => {
    const types = importExportResponse.map((repository) => repository.repoType);
    const repositoryTypes = SettingsForm.repositoryTypes.filter(
      (repository) => !types.includes(repository.value),
    );
    const isDisabled = repositoryTypes.length <= 0;

    return (
      <>
        <Typography variant="h5" className={classes.header}>
          {t("Import / Export")}
        </Typography>
        {isDisabled ? null : (
          <FormSection loading={isLoading}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              direction={"row"}
            >
              <Grid item xs={2}>
                <FormSelect
                  label={t("Type")}
                  type="text"
                  className={classes.formSelectPadding}
                  name={SettingsForm.fields.repoType}
                  disabled={isDisabled}
                  onChange={handleRepoTypeChange}
                >
                  {repositoryTypes.map((type) => (
                    <MenuItem value={type.value} key={type.id}>
                      {type.label}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>
              <Grid item xs={2}>
                <FieldSelector
                  label={t("URL")}
                  type="text"
                  className={classes.noPadding}
                  name={SettingsForm.fields.url}
                  disabled={isDisabled}
                  validate={[required, validUrl]}
                />
              </Grid>
              <Grid item xs={2}>
                <FieldSelector
                  label={t("Directory")}
                  type="text"
                  className={classes.noPadding}
                  name={SettingsForm.fields.repoName}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <FieldSelector
                  label={t("Username")}
                  type="text"
                  className={classes.noPadding}
                  name={SettingsForm.fields.username}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={2}>
                <FieldSelector
                  label={t("Token")}
                  type="password"
                  className={classes.noPadding}
                  name={SettingsForm.fields.password}
                  disabled={isDisabled}
                  autocomplete={"off"}
                />
              </Grid>
              <Grid item xs={1}>
                <FieldSelector
                  label={t("storeToken")}
                  type="checkbox"
                  name={SettingsForm.fields.storeToken}
                  className={classes.checkbox}
                />
              </Grid>
              {!isLoading && (
                <Grid item xs={1} className={classes.button}>
                  <Button
                    id="SETTINGS_BTN_ADD_IMPORT_EXPORT_ENTRY"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={[
                      classes.commonBtn,
                      classes.commonBtnPrimary,
                    ].join(" ")}
                    onClick={handleSubmit(handleAddRepositoryEntry)}
                    disabled={isDisabled}
                  >
                    <IconPlus />
                    {t("Add")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </FormSection>
        )}
      </>
    );
  };

  const renderImportExportTable = () => {
    return (
      <Table className={classes.importExportTable}>
        <TableHead>
          <TableRow className={commonClasses.commonTableHeadRow}>
            {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
              <TableCell
                key={tableHeaderColumn.id}
                align={tableHeaderColumn.align}
                className={commonClasses.commonTableCellDefault}
              >
                {tableHeaderColumn.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <MSATableBody
          loading={importExportIsLoading}
          error={
            importExportError &&
            t("Unable to load x", { x: t("Repository Settings") })
          }
          noContents={
            importExportResponse.length === 0 &&
            t("No Repository Settings found")
          }
          colSpan={TABLE_HEADER_COLUMNS.length}
          reload={importExportReload}
        >
          {importExportResponse.map((entry) => (
            <TableRow key={entry.repoType}>
              <TableCell align={"center"}>
                {isStatusOk(entry.status) ? (
                  <Lens className={classes.statusOk} />
                ) : (
                  <Lens className={classes.statusNotOk} />
                )}
              </TableCell>
              <TableCell align={"center"}>{entry.repoType}</TableCell>
              <TableCell align={"center"}>{entry.repo}</TableCell>
              <TableCell align={"center"}>{entry.repoName}</TableCell>
              <TableCell align={"center"}>{entry.username}</TableCell>
              <TableCell align={"right"}>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  component={Link}
                  to={{
                    pathname: `${url}/import-export/edit`,
                    state: { entry },
                  }}
                >
                  {t("Edit")}
                </Button>
              </TableCell>
              <TableCell align={"right"}>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setPullEntry(entry);
                  }}
                >
                  {t("Pull")}
                </Button>
              </TableCell>
              <TableCell align={"right"}>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  component={Link}
                  to={{
                    pathname: `${url}/import-export/deactivate`,
                    state: { entry },
                  }}
                  onClick={() => handleDeactivateClick(entry)}
                >
                  {t("Deactivate")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </MSATableBody>
      </Table>
    );
  };

  return (
    <Paper className={commonClasses.commonPaperNoPadding}>
      {renderImportExportInputs()}
      {renderImportExportTable()}
      <Route path={`${path}/import-export/edit`}>
        <ImportExportEditDialog
          showDialog={showEditDialog}
          toggleShowDialog={toggleShowEditDialog}
          handleClose={handleEditDialogClose}
        />
      </Route>
      {!isEmpty(pullEntry) && (
        <ImportExportPullDialog
          entry={pullEntry}
          handleClose={() => setPullEntry({})}
        />
      )}
      <Route path={`${path}/import-export/deactivate`}>
        <ImportExportDeactivateDialog
          showDialog={showDeactivateDialog}
          toggleShowDialog={toggleShowDeactivateDialog}
          handleClose={handleDeactivateDialogClose}
          handleSubmit={handleDeactivateDialogSubmit}
        />
      </Route>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  token: getToken(state),
});

export default flow(
  reduxForm({
    form: SettingsForm.ImportExportFormName,
    validate: SettingsForm.validate,
    forceUnregisterOnUnmount: false,
    destroyOnUnmount: false,
  }),
  connect(mapStateToProps),
)(ImportExport);
