/**
 * Generates unique id
 *
 * @param {Array} existingIds
 * @returns {String}
 */
export const generateId = (prefix, existingIds = []) => {
  const getId = () => prefix + (Math.random() + 1).toString(36).substring(2);
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const id = getId();
    if (!existingIds.includes(id)) {
      return id;
    }
  }
};
