import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tabs, Tab } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const CreateTabs = ({
  associatedSubtenantsTabPath,
  shouldShowAssociatedSubtenantsTab = true,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const activeTab =
    window.location.pathname === associatedSubtenantsTabPath ? 1 : 0;

  return (
    <Tabs value={activeTab} indicatorColor="primary" variant="standard">
      <Tab
        key={0}
        id={"CREATE_MANAGER_DIALOG_INFORMATION_TAB"}
        classes={{
          selected: commonClasses.commonTabSelected,
        }}
        label={t("Information")}
        component={Link}
        to={"/admin/managers/create"}
      />
      {shouldShowAssociatedSubtenantsTab && (
        <Tab
          key={1}
          id={"CREATE_MANAGER_DIALOG_ASSOCIATED_SUBTENANTS_TAB"}
          classes={{
            selected: commonClasses.commonTabSelected,
          }}
          label={t("Associated Subtenants")}
          component={Link}
          to={associatedSubtenantsTabPath}
        />
      )}
    </Tabs>
  );
};

export default CreateTabs;
