import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useApi from "msa2-ui/src/hooks/useApi";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { ResponsiveContainer } from "recharts";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { ReactComponent as PlusIcon } from "msa2-ui/src/assets/icons/plus.svg";
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons";

import { getSelectedWorkflow } from "msa2-ui/src/store/designations";
import { getWorkflowInstanceVariableValues } from "msa2-ui/src/api/workflow";
import { getWorkflow } from "msa2-ui/src/api/workflow";
import Variable from "msa2-ui/src/services/Variable";

import BasicSelect from "msa2-ui/src/components/BasicSelect";

import SpoolDataGraph from "./SpoolDataGraph";
import set from "lodash/set";

const useStyles = makeStyles(({ darkMode, palette, colors }) => ({
  graphTitle: {
    marginTop: 10,
  },
  graphWrapper: {
    height: 300,
    marginBottom: 10,
  },
  select: {
    padding: 5,
  },
  plusIcon: {
    marginLeft: 5,
  },
  alignRight: {
    textAlign: "right",
  },
  autoRefresh: {
    marginBottom: 10,
  },
  autoRefreshTime: {
    width: 200,
    marginLeft: 20,
  },
}));

const WorkflowLineGraph = ({
  target,
  legends,
  references = [],
  variableValues,
  xAxis = "__index__",
}) => {
  const { t } = useTranslation();
  const data = variableValues[target];
  const referencesWithValue = references.map((reference) => ({
    ...reference,
    value: variableValues[reference.id],
  }));
  if (!data || data.length === 0) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Typography>{t("There are no data to show.")}</Typography>
      </Grid>
    );
  }

  return (
    data.length > 0 && (
      <ResponsiveContainer width="100%" height="100%">
        <SpoolDataGraph
          data={data}
          legends={legends}
          references={referencesWithValue}
          xAxis={xAxis}
        />
      </ResponsiveContainer>
    )
  );
};

const WorkflowLineGraphWrapper = ({
  graphData: initGraphData = [],
  autoRefresh: initAutoRefresh = 0,
  hideFunctionalities,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: instanceId, uri: workflowUri } = useSelector(getSelectedWorkflow);
  const ref = useRef(null);
  const [graphData, setGraphData] = useState(initGraphData);
  const [selectedTarget, setSelectedTarget] = useState("");
  const [selectedLegends, setSelectedLegends] = useState([]);
  const [selectedReference, setSelectedReference] = useState([]);
  const [selectedXAxis, setSelectedXAxis] = useState("");
  const [autoRefresh, setAutoRefresh] = useState(initAutoRefresh);
  const [loading, , variableValues = {}] = useApi(
    getWorkflowInstanceVariableValues,
    { instanceId },
    !instanceId,
    autoRefresh,
  );
  const [definitionLoading, , workflowDefinition = {}] = useApi(
    getWorkflow,
    { pathToWorkflowDefinitionFile: decodeURIComponent(workflowUri) },
    !workflowUri,
  );
  const { variables } = workflowDefinition;

  const arrayVariables = variables?.variable
    .filter((variable) => Variable.getTableValData(variable.name).isTable)
    .reduce((acc, variable) => {
      const { tableSection } = Variable.getTableValData(variable.name);
      return set(
        acc,
        tableSection,
        acc[tableSection]?.concat(variable.name) ?? [variable.name],
      );
    }, {});

  const renderMenuForArray = () =>
    arrayVariables[selectedTarget].map((variableName) => {
      const { displayName } = variables.variable.find(
        (variable) => variable.name === variableName,
      );
      return (
        <MenuItem value={variableName} key={variableName}>
          {displayName}
        </MenuItem>
      );
    });

  useEffect(() => {
    if (loading) ref.current = instanceId;
  }, [instanceId, loading]);

  if (!instanceId) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Typography>{t("Please select a Workflow Instance.")}</Typography>
      </Grid>
    );
  }
  if (ref.current !== instanceId || definitionLoading) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Grid container>
        {!hideFunctionalities && (
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            alignContent="center"
            className={classes.autoRefresh}
          >
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ "aria-label": t("Auto Refresh") }}
                    checked={Boolean(autoRefresh)}
                    onChange={() => {
                      autoRefresh === 0
                        ? setAutoRefresh(60000)
                        : setAutoRefresh(0);
                    }}
                  />
                }
                label={t("Auto Refresh")}
              />
            </FormControl>
            <TextField
              label="Auto Refresh Time"
              type="number"
              size="small"
              InputProps={{
                classes: { input: classes.alignRight },
                endAdornment: (
                  <InputAdornment position="end">{t("seconds")}</InputAdornment>
                ),
                inputProps: {
                  "aria-label": t("Auto Refresh Time"),
                },
              }}
              className={classes.autoRefreshTime}
              value={autoRefresh / 1000}
              onChange={({ target: { value } }) => {
                if (value > 0 && value < 5184000) setAutoRefresh(value * 1000);
              }}
              disabled={!autoRefresh}
            />
          </Grid>
        )}
        {variables &&
          graphData.map(
            ({ title, target, legend = [], reference = [], xAxis }, index) => {
              const legends = legend.map((legend) => ({
                id: Variable.getTableValData(legend).tableKey,
                displayName: variables.variable.find(
                  (variable) => variable.name === legend,
                )?.displayName,
              }));
              const references = reference.map(({ id, stroke }) => ({
                id: Variable.getTableValData(id).tableKey,
                displayName: variables.variable.find(
                  (variable) => variable.name === id,
                )?.displayName,
                stroke,
              }));
              const sectionName = variables.variable.find(
                (variable) =>
                  legend.includes(variable.name) && variable.sections?.length,
              )?.sections[0];

              return (
                <Fragment key={index}>
                  <Grid item xs={12} container className={classes.graphTitle}>
                    {(!hideFunctionalities || graphData.length > 1) && (
                      <Grid item xs={10} container alignContent="center">
                        <Typography variant={"h2"}>
                          {title || sectionName || target}
                        </Typography>
                      </Grid>
                    )}
                    {!hideFunctionalities && (
                      <Grid
                        item
                        xs={2}
                        container
                        justifyContent="flex-end"
                        alignContent="center"
                      >
                        <IconButton
                          id={`WORKFLOW_LINE_GRAPH_DELETE_${target}`}
                          aria-label={t("Delete")}
                          onClick={() => {
                            setGraphData(
                              graphData.filter((e, i) => i !== index),
                            );
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.graphWrapper}>
                    <WorkflowLineGraph
                      target={target}
                      legends={legends}
                      references={references}
                      xAxis={
                        xAxis &&
                        Variable.getTableValData(Variable.removePrefix(xAxis))
                          .tableKey
                      }
                      variableValues={variableValues}
                    />
                    <Divider className={classes.divider} />
                  </Grid>
                </Fragment>
              );
            },
          )}
        {!hideFunctionalities && (
          <>
            <Grid
              item
              xs={9}
              container
              justifyContent="flex-start"
              alignContent="center"
            >
              {arrayVariables && (
                <Grid item xs={6} className={classes.select}>
                  <BasicSelect
                    label={t("Section")}
                    variant="outlined"
                    name={t("Section")}
                    value={selectedTarget}
                    onChange={({ target: { value } }) => {
                      setSelectedTarget(value);
                    }}
                    required
                    fullWidth
                  >
                    {Object.keys(arrayVariables).map((selectedTarget, i) => (
                      <MenuItem value={selectedTarget} key={selectedTarget}>
                        {selectedTarget}
                      </MenuItem>
                    ))}
                  </BasicSelect>
                </Grid>
              )}
              {selectedTarget && (
                <Grid item xs={6} className={classes.select}>
                  <BasicSelect
                    label={t("Render Variables")}
                    variant="outlined"
                    name={t("Render Variables")}
                    value={selectedLegends}
                    onChange={({ target: { value } }) => {
                      setSelectedLegends(value);
                    }}
                    required
                    multiple
                    fullWidth
                  >
                    {renderMenuForArray()}
                  </BasicSelect>
                </Grid>
              )}
              {selectedTarget && (
                <Grid item xs={6} className={classes.select}>
                  <BasicSelect
                    label={t("X Axis Variable")}
                    variant="outlined"
                    name={t("X Axis Variable")}
                    value={selectedXAxis}
                    onChange={({ target: { value } }) => {
                      setSelectedXAxis(value);
                    }}
                    fullWidth
                  >
                    {renderMenuForArray()}
                  </BasicSelect>
                </Grid>
              )}
              {selectedTarget && (
                <Grid item xs={6} className={classes.select}>
                  <BasicSelect
                    label={t("References (Integer)")}
                    variant="outlined"
                    name={t("References (Integer)")}
                    value={selectedReference.map(({ id }) => id)}
                    onChange={({ target: { value } }) => {
                      setSelectedReference(value.map((id) => ({ id })));
                    }}
                    fullWidth
                    multiple
                  >
                    {variables.variable
                      .filter(
                        (variable) =>
                          variable.type === "Integer" &&
                          !Variable.getTableValData(variable.name).isTable,
                      )
                      .map((variable) => (
                        <MenuItem value={variable.name} key={variable.name}>
                          {variable.displayName}
                        </MenuItem>
                      ))}
                  </BasicSelect>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignContent="center"
            >
              <Button
                aria-label={t("Add Graph")}
                onClick={() => {
                  setGraphData(
                    graphData.concat({
                      target: selectedTarget,
                      legend: selectedLegends,
                      reference: selectedReference,
                      xAxis: selectedXAxis,
                    }),
                  );
                  setSelectedTarget("");
                  setSelectedLegends([]);
                  setSelectedReference([]);
                  setSelectedXAxis("");
                }}
                disabled={selectedLegends.length === 0}
              >
                {t("Add Graph")}
                <PlusIcon className={classes.plusIcon} />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

WorkflowLineGraphWrapper.propTypes = {
  graphData: PropTypes.array,
  autoRefresh: PropTypes.number,
  hideFunctionalities: PropTypes.bool,
};
export default WorkflowLineGraphWrapper;
