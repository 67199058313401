import { post } from "./request";
import {
  createRepositoryFile,
  updateRepositoryFile,
  deleteRepositoryFile,
} from "./repository";
const API = process.env.REACT_APP_API_PATH;

export const addTaskToRepository = ({
  filename,
  filepath,
  content = "",
  token,
}) => {
  return createRepositoryFile({
    uri: `${filepath}/${filename}`,
    content,
    token,
  });
};

export const updateTaskInRepository = ({
  taskUri: uri,
  content = "",
  token,
}) => {
  return updateRepositoryFile({
    uri,
    content,
    token,
  });
};

export const deleteTaskInRepository = ({ taskUri: uri, token }) => {
  return deleteRepositoryFile({
    uri,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/generateTaskForMicroServiceCall
 */
export const generateTaskForMicroServiceCall = ({
  microServiceUri,
  crudMethod,
  workflowLanguage,
  processPath,
  token,
}) => {
  return post({
    url: `${API}/repository/v1/workflow/task/microservice-call`,
    body: { microServiceUri, crudMethod, workflowLanguage, processPath },
    token,
  });
};
