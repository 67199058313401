import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { acknowledgeAlarm } from "msa2-ui/src/api/logs";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { wait } from "msa2-ui/src/utils/wait";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";

const useStyles = makeStyles(({ spacing }) => ({
  contentWrapper: {
    padding: "10px 20px",
    height: 400,
  },
  formField: {
    margin: `${spacing(2)}px 0`,
  },
}));

const AcknowledgeAlarmDialog = ({ entries = [], onClose, onAck }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));
  const { enqueueSnackbar } = useSnackbar();
  const [ack, setAck] = useState();
  const [comment, setComment] = useState();

  const handleAcknowledgeAlarms = async () => {
    const [error] = await acknowledgeAlarm({
      token,
      body: {
        alarmData: entries.map(({ _index, _id }) => ({
          indexName: _index,
          alarmId: _id,
        })),
        ack: ack,
        comment: comment,
      },
    });

    let variant = "success";
    let message = t("Alarms have been acknowledged");
    if (error) {
      variant = "error";
      message = error.getMessage(t("Unable to acknowledge Alarms"));
    }
    enqueueSnackbar(message, { variant });
    if (!error) {
      await wait(pollingInterval);
      onAck();
    }
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {t("Acknowledge  Alarm")}
        </Typography>
        <IconButton
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Grid container direction="column" alignItems="center">
          <TextField
            variant="outlined"
            multiline
            rows={3}
            id="ACKNOWLEDGE_ALARM_ID"
            value={entries.map(({ _id }) => _id).join("\n")}
            disabled
            label={t("Alarm ID")}
            style={{ width: "50%" }}
            className={classes.formField}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <FormControlLabel
              control={
                <Checkbox
                  id="ACK_ALARM_UPDATE"
                  inputProps={{
                    "data-testid": "upsert-ack-alarm",
                  }}
                  checked={ack}
                  onChange={(e) => setAck(e.currentTarget.checked)}
                />
              }
              label="Acknowledge"
            />
          </Grid>
          <TextField
            variant="outlined"
            multiline
            rows={5}
            id="ACKNOWLEDGE_ALARM_COMMENT"
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
            label={t("Comment")}
            style={{ width: "100%" }}
            className={classes.formField}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          variant="text"
          size="small"
          color="default"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          onClick={handleAcknowledgeAlarms}
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AcknowledgeAlarmDialog.propTypes = {
  entries: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAck: PropTypes.func.isRequired,
};

export default AcknowledgeAlarmDialog;
