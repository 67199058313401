import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const SplitButton = ({ options = [], size = "large" }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  if (options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    const { label, onClick, id, disabled = false, toolTipMessage } = options[0];
    return (
      <Tooltip title={toolTipMessage ?? ""}>
        <div>
          <Button
            color="primary"
            variant="contained"
            size={size}
            onClick={onClick}
            disabled={disabled}
            id={id}
          >
            {label}
          </Button>
        </div>
      </Tooltip>
    );
  }

  const firstOption = options[0];
  const restOptions = options.slice(1);
  return (
    <>
      <ButtonGroup
        variant="contained"
        size={size}
        color="primary"
        aria-label={t("Split Button")}
      >
        <Tooltip title={firstOption.toolTipMessage ?? ""}>
          <Button
            onClick={firstOption.onClick}
            disabled={firstOption.disabled ?? false}
            id={firstOption.id}
          >
            {firstOption.label}
          </Button>
        </Tooltip>
        <Button
          id="SPLIT_BUTTON_EXPAND"
          color="primary"
          size="small"
          aria-label={t("Split Button Expand")}
          aria-haspopup="menu"
          onClick={() => setIsOpen(true)}
          ref={anchorRef}
          data-testid="SPLIT_BUTTON_EXPAND"
          disabled={restOptions.every(({ disabled }) => disabled)}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={() => setIsOpen(false)}
        variant="menu"
        style={{
          marginTop: anchorRef.current?.offsetHeight + 5 ?? undefined,
        }}
      >
        {restOptions.map(
          ({ label, onClick, id, disabled = false, toolTipMessage }) => (
            <Tooltip title={toolTipMessage ?? ""} key={id}>
              <div>
                <MenuItem
                  disabled={disabled}
                  id={id}
                  onClick={() => {
                    setIsOpen(false);
                    onClick();
                  }}
                >
                  {label}
                </MenuItem>
              </div>
            </Tooltip>
          ),
        )}
      </Menu>
    </>
  );
};

SplitButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default SplitButton;
