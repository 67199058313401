import React from "react";
import PropTypes from "prop-types";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import { sortOrder } from "msa2-ui/src/Constants";

const THead = ({ columns, totalCounter, filterState, filterActions }) => {
  const commonClasses = useCommonStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={columns.length}
          className={commonClasses.commonTableCell}
        >
          <FilterMenu
            tpTotal={totalCounter}
            tpPage={filterState.tpPage}
            handleSearchByChange={filterActions.debouncedHandleSearchByChange}
            tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
            tpRowsPerPage={filterState.tpRowsPerPage}
            tpChangePage={filterActions.tpChangePage}
          />
        </TableCell>
      </TableRow>
      <TableRow className={commonClasses.commonTableHeadRow}>
        {columns.map(({ id, name, align, sortable }) => (
          <TableCell
            key={id}
            align={align}
            className={commonClasses.commonTableCellDefault}
          >
            {sortable ? (
              <TableSortLabel
                active={filterState.sortByValue === id}
                direction={sortOrder[filterState.sortOrderValue].text}
                onClick={() => {
                  if (!sortable) return;
                  filterActions.handleSortByOrderChange();
                  filterActions.handleSortByValueChange(id);
                }}
              >
                {name}
              </TableSortLabel>
            ) : (
              name
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

THead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      align: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  totalCounter: PropTypes.number.isRequired,
  filterActions: PropTypes.shape({
    debouncedHandleSearchByChange: PropTypes.func.isRequired,
    handleSortByOrderChange: PropTypes.func.isRequired,
    handleSortByValueChange: PropTypes.func.isRequired,
    tpChangePage: PropTypes.func.isRequired,
  }).isRequired,
  filterState: PropTypes.shape({
    sortByValue: PropTypes.string.isRequired,
    tpPage: PropTypes.number.isRequired,
    tpRowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
};

export default THead;
