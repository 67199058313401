import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import flow from "lodash/flow";
import groupBy from "lodash/groupBy";

import { normalizePath } from "msa2-ui/src/utils/urls";
import useApi from "msa2-ui/src/utils/useApi";

import { listFilesInFolder } from "msa2-ui/src/api/repository";

import Repository from "msa2-ui/src/services/Repository";

import { makeStyles } from "@material-ui/core";
import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import MsaSelect from "msa2-ui/src/components/msa-select";

const useStyles = makeStyles(() => ({
  label: {
    textAlign: "left",
  },
  breadcrumbs: {
    marginLeft: 5,
    marginBottom: 5,
  },
  link: {
    cursor: "pointer",
  },
  clear: {
    cursor: "pointer",
    width: 15,
    height: 15,
    marginLeft: 5,
  },
}));

const RepositorySelector = ({
  initDirectory,
  filterTerm = "",
  onChange,
  label,
  allowRootSearch = false,
  lockInitDirectory = false,
  token,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [directories, setDirectories] = useState(initDirectory.split("/"));
  const [value, setValue] = useState();

  const [loading, , options = []] = useApi({
    apiCall: listFilesInFolder,
    params: {
      uri: directories.join("/"),
      transforms: (response) =>
        flow(
          (paths) => paths.filter((path) => path.match(filterTerm)),
          (paths) => groupBy(paths, Repository.stripFilenameFromUri),
          (filesByPath) => {
            const paths = {
              label: t("Filter by Path"),
              options: Object.keys(filesByPath).map((path) => ({
                value: path,
                label: path,
              })),
            };
            const files = Object.entries(filesByPath).map(([label, paths]) => {
              const options = paths.map((path) => ({
                value: path,
                label: Repository.getFilenameFromUri(path),
              }));
              return { label, options };
            });
            return [paths, ...files];
          },
        )(response),
    },
    token,
  });

  const handleOnChange = (option) => {
    if (!option) {
      setValue(null);
      onChange();
    } else if (Repository.isFile(option?.value)) {
      setValue(option);
      onChange(option?.value);
      setDirectories(Repository.stripFilenameFromUri(option?.value).split("/"));
    } else {
      setValue(null);
      setDirectories((option?.value).split("/"));
    }
  };
  return (
    <Grid container>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Breadcrumbs className={classes.breadcrumbs}>
        {directories.map((directory, i) => {
          const getIsLocked = (index) =>
            lockInitDirectory &&
            index < normalizePath(initDirectory).split("/").length;
          const isLastElement = i === directories.length - 1;
          const isLocked = getIsLocked(i);
          return (
            <Grid
              key={i}
              container
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              {getIsLocked(i + 1) || isLastElement ? (
                <Typography>{directory}</Typography>
              ) : (
                <Link
                  id={`REPOSITORY_SELECTOR_BREADCRUMB_LINK_${i}`}
                  color="inherit"
                  onClick={() => {
                    setDirectories(directories.slice(0, i + 1));
                  }}
                  className={classes.link}
                >
                  {directory}
                </Link>
              )}
              {allowRootSearch && !isLocked && (
                <Close
                  aria-label={t("Close")}
                  id={`REPOSITORY_SELECTOR_BREADCRUMB_CLEAR_${i}`}
                  className={classes.clear}
                  onClick={() => {
                    setDirectories(directories.slice(0, i));
                  }}
                />
              )}
            </Grid>
          );
        })}
      </Breadcrumbs>

      <MsaSelect
        id="REPOSITORY_SELECTOR"
        options={options}
        placeholder={t("Select File from Repository...")}
        value={value}
        onChange={handleOnChange}
        isLoading={loading}
        {...props}
      />
    </Grid>
  );
};

RepositorySelector.propTypes = {
  initDirectory: PropTypes.string,
  filterTerm: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(RegExp),
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  allowRootSearch: PropTypes.bool,
  lockInitDirectory: PropTypes.bool,
};

export default RepositorySelector;
