import React, { useEffect, useState } from "react";
import flow from "lodash/flow";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Table } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useSelector } from "react-redux";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { deleteBpmDiagram } from "msa2-ui/src/api/bpm";
import { useSnackbar } from "notistack";
import { getToken, getIsRootUser } from "msa2-ui/src/store/auth";
import { filter } from "msa2-ui/src/utils/filter";
import { useTranslation } from "react-i18next";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { buildRoute } from "msa2-ui/src/utils/urls";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import TableRow from "msa2-ui/src/components/TableRow";
import AttachDetachBpm from "./AttachDetachBpm";
import TableHead from "./TableHead";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { downloadArchive } from "msa2-ui/src/api/repository";
import Repository from "msa2-ui/src/services/Repository";
import AttachedSubtenantsPopup from "./AttachedSubtenantsPopup";
import AutomationChangeOwnerDialog from "../../AutomationChangeOwnerDialog";

const useStyles = makeStyles(({ palette, typography }) => ({
  tableIconsMinWidth: {
    width: 120,
  },
  tableCellAsLink: {
    fontSize: 13,
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
    cursor: "pointer",
  },
  tableCellAsText: {
    fontSize: 13,
    color: palette.text.secondary,
  },
  downloadIcon: {
    width: 48,
  },
}));

const CommonOverviewTable = ({
  bpmDiagramsIsLoading,
  bpmDiagramsError,
  bpmDiagrams = [],
  filterState,
  filterActions,
  recordsCount,
  reloadGetBpmDiagrams,
  isWorkflowsOwnerDetailsEnabled,
}) => {
  const { t } = useTranslation();
  const { url: currentRoute } = useRouteMatch();
  const { pathname } = window.location;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [showDeleteDialog, DeleteDialog] = useDialog();

  const token = useSelector(getToken);
  const bpmList = filter(bpmDiagrams, filterState.searchValue, ["name"]);

  /**
   * If we navigate back to this page after creating or editing
   * we want to make sure we get the latest BPM diagrams
   */
  useEffect(() => {
    if (pathname === "/automation/bpm") {
      reloadGetBpmDiagrams();
    }
  }, [pathname, reloadGetBpmDiagrams]);

  const { enqueueSnackbar } = useSnackbar();

  const [bmpToAttach, setBmpToAttach] = useState(null);
  const [bpmToDelete, setBpmToDelete] = useState(null);
  const [bpmToShowSubtenants, setBpmToShowSubtenants] = useState(null);
  const [bpmToChangeOwnership, setBpmToChangeOwnership] = useState(null);

  const isRootUser = useSelector(getIsRootUser);

  const TABLE_COLUMNS = [
    {
      id: "diagramName",
      name: "Name",
      align: "left",
    },
    {
      id: "timestamp",
      name: "Date Modified",
      align: "left",
    },
    {
      id: "addedTo",
      name: "Added to",
      align: "left",
    },
    {
      id: "owner",
      name: "Owner",
      align: "left",
      hidden: !isWorkflowsOwnerDetailsEnabled,
    },
    {
      id: "actionIconsContainer",
      name: "",
      align: "right",
    },
  ];

  const handleDelete = async () => {
    const [error, deleted] = await deleteBpmDiagram({
      token,
      bpmFilePath: bpmToDelete.uri,
    });
    if (error) {
      enqueueSnackbar(
        error.getMessage(t("Unable to delete x", { x: bpmToDelete.name })),
        {
          variant: "error",
        },
      );
    } else if (deleted) {
      enqueueSnackbar(
        t("BPM diagram has been deleted!", { name: bpmToDelete.name }),
        { variant: "success" },
      );
      reloadGetBpmDiagrams();
    }
    setBpmToDelete(null);
  };

  const renderTableBody = () => {
    if (bpmDiagramsIsLoading) {
      return <TableLoading numberOfTableColumns={TABLE_COLUMNS.length} />;
    }

    if (bpmDiagrams.length === 0) {
      return (
        <TableMessage
          message={
            bpmDiagramsError
              ? `${t("Error fetching x", { x: t("BPM diagrams") })}. ${t(
                  "Please reload the page",
                )}.`
              : t("No BPM diagrams found")
          }
          numberOfTableColumns={TABLE_COLUMNS.length}
        />
      );
    }

    return (
      <TableBody>
        {Boolean(bmpToAttach) && (
          <AttachDetachBpm
            name={bmpToAttach.name}
            uri={bmpToAttach.uri}
            onClose={(shouldReload) => {
              setBmpToAttach(null);
              shouldReload && reloadGetBpmDiagrams();
            }}
          />
        )}
        {Boolean(bpmToShowSubtenants) && (
          <AttachedSubtenantsPopup
            bpmName={bpmToShowSubtenants.name}
            bpmPath={bpmToShowSubtenants.uri}
            onClose={() => {
              setBpmToShowSubtenants(null);
              reloadGetBpmDiagrams();
            }}
          />
        )}
        <DeleteDialog
          title={t("Delete BPM?")}
          content={t("Are you sure you want to delete x?", {
            x: bpmToDelete?.uri,
          })}
          onExec={handleDelete}
        />
        {bpmList.map((bpmFile, rowIndex) => {
          const bpmDetailsUri = buildRoute(
            currentRoute,
            `${encodeURIComponent(bpmFile.uri)}`,
          );

          return (
            <TableRow
              id={`BPM_TABLE_ROW_${rowIndex}`}
              key={rowIndex}
              actions={
                <>
                  {((isWorkflowsOwnerDetailsEnabled &&
                    bpmFile.permission?.attach) ||
                    !isWorkflowsOwnerDetailsEnabled) && (
                    <DelegationProfiles
                      type={delegationProfileTypes.BPM}
                      action="general.attach"
                    >
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => setBmpToAttach(bpmFile)}
                      >
                        {t("Add to...")}
                      </Button>
                    </DelegationProfiles>
                  )}
                  <>
                    {((isWorkflowsOwnerDetailsEnabled &&
                      bpmFile.permission?.modify) ||
                      !isWorkflowsOwnerDetailsEnabled) && (
                      <>
                        <DelegationProfiles
                          type={delegationProfileTypes.BPM}
                          action="general.modify"
                        >
                          <Link
                            className={commonClasses.commonNoTextDecoration}
                            id={`BPM_TABLE_BTN_EDIT_LINK_${rowIndex}`}
                            data-testid={`BPM_TABLE_BTN_EDIT_LINK_${rowIndex}`}
                            to={buildRoute(bpmDetailsUri, "edit")}
                          >
                            <IconButton
                              id={`BPM_TABLE_BTN_EDIT_${rowIndex}`}
                              aria-label={t("Edit")}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          </Link>
                        </DelegationProfiles>
                        <IconButton
                          id={`BPM_TABLE_BTN_DOWNLOAD_${rowIndex}`}
                          aria-label={t("Download")}
                          className={classes.downloadIcon}
                          onClick={() => {
                            downloadArchive({
                              path: decodeURIComponent(
                                Repository.getFilenameFromUri(bpmDetailsUri),
                              ),
                              token,
                              fileName: flow(
                                decodeURIComponent,
                                Repository.getFilenameFromUri,
                                (bpmDetailsUri) =>
                                  Repository.addExtension(bpmDetailsUri, "zip"),
                              )(bpmDetailsUri),
                              onError: (e) => {
                                enqueueSnackbar(
                                  t("Unable to download contents"),
                                  {
                                    variant: "error",
                                  },
                                );
                              },
                            });
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </>
                    )}
                  </>
                  {((isWorkflowsOwnerDetailsEnabled &&
                    bpmFile.permission?.delete) ||
                    !isWorkflowsOwnerDetailsEnabled) && (
                    <DelegationProfiles
                      type={delegationProfileTypes.BPM}
                      action="general.delete"
                    >
                      <Tooltip
                        title={
                          bpmFile.numberOfSubTenantsAttached
                            ? t(
                                "You must detach all subtenants before deleting",
                              )
                            : ""
                        }
                      >
                        <span>
                          <IconButton
                            id={`BPM_TABLE_BTN_DELETE_${rowIndex}`}
                            aria-label={t("Delete")}
                            onClick={() => {
                              showDeleteDialog();
                              setBpmToDelete(bpmFile);
                            }}
                          >
                            <DeleteIcon
                              color={
                                bpmFile.numberOfSubTenantsAttached
                                  ? "disabled"
                                  : "error"
                              }
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </DelegationProfiles>
                  )}
                </>
              }
            >
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  id={`BPM_TABLE_CELL_NAME_${rowIndex}`}
                  variant="h4"
                  className={classNames(
                    commonClasses.commonTablePrimary,
                    commonClasses.overflowAnywhere,
                  )}
                >
                  <Tooltip title={t("Select Subtenant to see the details.")}>
                    <span>{bpmFile.name}</span>
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {displayMonthDayYearTimeDate(bpmFile.dateModified)}
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                className={commonClasses.commonTableCellDefault}
              >
                {bpmFile.numberOfSubTenantsAttached ? (
                  <Typography
                    variant="h3"
                    className={classes.tableCellAsLink}
                    onClick={() => setBpmToShowSubtenants(bpmFile)}
                  >
                    <NumberFormat
                      value={bpmFile.numberOfSubTenantsAttached}
                      displayType={"text"}
                      thousandSeparator
                    />{" "}
                    {t("Subtenants")}
                  </Typography>
                ) : (
                  <Typography variant="h3" className={classes.tableCellAsText}>
                    {t("none")}
                  </Typography>
                )}
              </TableCell>
              {isWorkflowsOwnerDetailsEnabled && (
                <TableCell className={commonClasses.commonTableCellDefault}>
                  {isRootUser ? (
                    <>
                      <Typography
                        variant="h3"
                        className={classes.tableCellAsLink}
                        onClick={() => setBpmToChangeOwnership(bpmFile)}
                      >
                        {bpmFile.owner?.login || "ncroot"}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="h3"
                      className={classes.tableCellAsText}
                    >
                      {bpmFile.owner?.login || "ncroot"}
                    </Typography>
                  )}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <Paper
      className={classNames([
        commonClasses.commonPaper,
        commonClasses.commonPaperNoPadding,
      ])}
    >
      {bpmToChangeOwnership && (
        <AutomationChangeOwnerDialog
          ownerId={bpmToChangeOwnership?.owner.actorId}
          ownerName={bpmToChangeOwnership?.owner.login}
          bpmPath={bpmToChangeOwnership.uri}
          onClose={() => {
            setBpmToChangeOwnership(null);
            reloadGetBpmDiagrams();
          }}
        />
      )}
      <Table>
        <TableHead
          columns={TABLE_COLUMNS}
          filterActions={filterActions}
          filterState={filterState}
          recordsCount={recordsCount}
        />
        {renderTableBody()}
      </Table>
    </Paper>
  );
};

CommonOverviewTable.propTypes = {
  bpmDiagramsIsLoading: PropTypes.bool.isRequired,
  bpmDiagramsError: PropTypes.object,
  bpmDiagrams: PropTypes.array,
  filterState: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  recordsCount: PropTypes.number,
  reloadGetBpmDiagrams: PropTypes.func.isRequired,
};

export default CommonOverviewTable;
