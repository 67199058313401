import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { BPM_INSTANCE_STATUS, bpmInstanceStatus } from "msa2-ui/src/Constants";
import useApi from "msa2-ui/src/hooks/useApi";

import {
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import TotalWorkflowChart from "msa2-ui/src/components/TotalWorkflowChart";
import AlertBar from "msa2-ui/src/components/AlertBar";
import { getBpmDetails } from "msa2-ui/src/api/bpm";
import { displayYearMonthDayHourMinuteSecond } from "msa2-ui/src/utils/date";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Repository from "msa2-ui/src/services/Repository";
import Bpm from "msa2-ui/src/services/Bpm";

import {
  getAvailableSubtenants,
  getSelectedSubtenant,
  getSelectedTenant,
  selectSubtenantByUbiqubeId,
} from "msa2-ui/src/store/designations";
import classnames from "classnames";

const useStyles = makeStyles(
  ({ darkMode, palette, typography, colors, spacing }) => ({
    paper: {
      padding: spacing(2),
      boxShadow: "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
      height: "100%",
    },
    instancePaper: {
      margin: 10,
    },
    instanceStatus: {
      marginTop: 10,
    },
    processHeading: {
      cursor: "pointer",
      textTransform: "capitalize",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontSize: "1rem",
    },
    paperLeft: {
      display: "flex",
      textAlign: "left",
      flexDirection: "row",
      justifyContent: "left",
    },
    bold: {
      fontFamily: "Rubik",
      fontSize: "0.8125rem",
      fontWeight: typography.fontWeightMedium,
      color: darkMode ? palette.text.primary : colors.blueDark1,
    },
    regular: {
      fontFamily: "Rubik",
      fontSize: "0.79rem",
      fontWeight: typography.fontWeightLight,
      color: palette.text.primary,
    },
    offsetDivider: {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: -16,
      width: "calc(100% + 32px)",
    },
    titleWrapper: {
      display: "flex",
    },
    loaderWrapper: {
      height: 640,
    },
  }),
);

const filterInstances = ({ response, ubiqubeId, subtenants }) => {
  const ids = subtenants.map((st) => st.ubiqubeId);

  if (ubiqubeId) {
    return response.filter((entry) =>
      entry.processDefinitionKey.includes(ubiqubeId),
    );
  } else if (ids.length > 0) {
    return response.filter((entry) =>
      new RegExp(ids.join("|")).test(entry.processDefinitionKey),
    );
  } else {
    return [];
  }
};

const getGraphData = (instances) => {
  return {
    ended: instances.filter((e) => e.state === BPM_INSTANCE_STATUS.COMPLETED)
      .length,
    fail: instances.filter(
      (e) => e.state === BPM_INSTANCE_STATUS.EXTERNALLY_TERMINATED,
    ).length,
    running: instances.filter((e) => e.state === BPM_INSTANCE_STATUS.ACTIVE)
      .length,
    totalProcessInstances: instances.length,
  };
};

const StatusIcon = ({ status: bStatus }) => {
  const { t } = useTranslation();
  const { icon: Icon, name } = bpmInstanceStatus.find(
    (status) => status.status === bStatus,
  );

  return <Icon aria-label={t(name)} />;
};

const BpmHistory = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const subtenants = useSelector(getAvailableSubtenants);
  const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const tenant = useSelector(getSelectedTenant);

  const [isLoading, error, response = [], , reload] = useApi(getBpmDetails, {
    subtenantId: ubiqubeId,
  });

  useEffect(() => {
    reload();
  }, [tenant, reload]);

  const instances = filterInstances({ response, ubiqubeId, subtenants });
  const graphData = getGraphData(instances);
  const goToBPMDetails = (
    ubiqubeId,
    processDefinitionKey,
    processDefinitionId,
    processInstanceId,
  ) => {
    const { bpmFilename } = Bpm.parseProcessDefinitionKey(processDefinitionKey);
    const {
      value: basePath,
      INIT: { value: initPath },
    } = Repository.PATH.BPM;
    const bpmUri = encodeURIComponent(
      [basePath, initPath, bpmFilename].join("/"),
    );

    dispatch(selectSubtenantByUbiqubeId(ubiqubeId));
    if (processDefinitionId && processInstanceId) {
      history.push(
        `/automation/bpm/${bpmUri}/${processDefinitionId}/${processInstanceId}`,
      );
    } else {
      history.push(`/automation/bpm/${bpmUri}/instances`);
    }
  };

  return (
    <Paper id="DASHBOARD_BPM_HISTORY">
      <Typography variant="h5" noWrap>
        {t("BPM Instances")}
      </Typography>
      {Boolean(error) && (
        <AlertBar
          message={t("Unable to load x", { x: t("BPMs") })}
          refreshFnc={reload}
        />
      )}
      {isLoading && (
        <div
          id="DASHBOARD_BPM_HISTORY_LOADING_PROGRESS"
          className={classnames(
            commonClasses.commonLoaderWrapper,
            classes.loaderWrapper,
          )}
        >
          <CircularProgress />
        </div>
      )}
      {!isLoading && instances && (
        <>
          <TotalWorkflowChart workflows={graphData} />
          <Divider variant="fullWidth" className={classes.offsetDivider} />
          {instances
            .slice(0, 5)
            .map(
              (
                {
                  tenantId,
                  state,
                  processDefinitionKey,
                  startTime,
                  processDefinitionId,
                  id: processInstanceId,
                  businessKey,
                },
                i,
              ) => {
                const displayName = businessKey || processInstanceId;
                const { bpmName } = Bpm.parseProcessDefinitionKey(
                  processDefinitionKey,
                );
                return (
                  <Paper key={i}>
                    <Paper className={classes.paperLeft}>
                      <Typography className={classes.instanceStatus}>
                        <StatusIcon status={state} />
                      </Typography>
                      <Paper className={classes.instancePaper}>
                        <div className={classes.titleWrapper}>
                          <Typography
                            id={`DASHBOARD_BPM_HISTORY_INSTANCE_NAME_${i}`}
                            className={classes.processHeading}
                            variant="h5"
                            onClick={() => {
                              goToBPMDetails(
                                tenantId,
                                processDefinitionKey,
                                processDefinitionId,
                                processInstanceId,
                              );
                            }}
                          >
                            {displayName}
                          </Typography>
                        </div>
                        <Typography
                          id={`DASHBOARD_BPM_HISTORY_NAME_${i}`}
                          className={classnames(
                            classes.regular,
                            commonClasses.commonLink,
                          )}
                          onClick={() => {
                            goToBPMDetails(tenantId, processDefinitionKey);
                          }}
                        >
                          For <span className={classes.bold}>{bpmName}</span>
                        </Typography>
                        <Typography className={classes.regular}>
                          {displayYearMonthDayHourMinuteSecond(startTime)}
                        </Typography>
                      </Paper>
                    </Paper>
                    <Divider />
                  </Paper>
                );
              },
            )}
        </>
      )}
    </Paper>
  );
};

export default BpmHistory;
