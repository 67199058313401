import merge from "lodash/merge";
import reduce from "lodash/reduce";
import flow from "lodash/flow";
import mapValues from "lodash/mapValues";
import { get, post } from "./request";

const API = process.env.REACT_APP_API_PATH;

/**
 * When there is only one parameter it is returned as an object rather than a single item in an array
 * this function wraps the object in an array so it can be accessed consistently
 */
const wrapParametersWithArray = (parameters) =>
  Array.isArray(parameters) ? parameters : [parameters];

const normalizeParameters = (variableData) =>
  variableData.parameters.reduce(
    (formattedParameters, parameter) => {
      const { advancedVariableParametersByName } = formattedParameters;
      const {
        parameters: nestedParameters,
        parameterName,
        ...parameterData
      } = parameter;
      return {
        ...formattedParameters,
        advancedVariableParametersByName: {
          ...advancedVariableParametersByName,
          [parameterName]: {
            ...parameterData,
            parameters:
              nestedParameters && wrapParametersWithArray(nestedParameters),
          },
        },
      };
    },
    { ...variableData, advancedVariableParametersByName: {} },
  );

const PARAMETERS_TO_HIDE = ["cols", "rows", "isSearchable"];
const omitNeedlessParameters = (variableAdvancedParameters) => ({
  ...variableAdvancedParameters,
  parameters: variableAdvancedParameters.parameters.filter(
    (parameter) => !PARAMETERS_TO_HIDE.includes(parameter.parameterName),
  ),
});

/**
 * The value for boolean is set as string like "True" and "False" in API
 * so this is a function for converting them into proper boolean type.
 */
const parseBoolean = (variableAdvancedParameters) => ({
  ...variableAdvancedParameters,
  parameters: variableAdvancedParameters.parameters.map((parameter) =>
    mapValues(parameter, (parameterValue) => {
      if (parameterValue === "True") {
        return true;
      } else if (parameterValue === "False") {
        return false;
      }
      return parameterValue;
    }),
  ),
});

const flattenParameters = (parameter) => {
  const { parameters: nestedParameters } = parameter;
  return {
    ...parameter,
    parameters: nestedParameters.map(
      (nestedParameter) => nestedParameter.parameterName,
    ),
  };
};

const transformAdvancedVariableParameters = (
  advancedVariableParametersResponse,
) => {
  const variablesByKeyAndValue = Object.entries(
    advancedVariableParametersResponse,
  );

  return variablesByKeyAndValue.reduce(
    (transformedAdvancedVariableParameters, variableByKeyAndValue) => {
      const [, variableValue] = variableByKeyAndValue;
      const formattedAdvancedVariables = flow(
        omitNeedlessParameters,
        parseBoolean,
        normalizeParameters,
        flattenParameters,
      )(variableValue);
      const { advancedVariableParametersByName } = formattedAdvancedVariables;
      return merge(transformedAdvancedVariableParameters, {
        advancedVariableParametersByName,
      });
    },
    { advancedVariableParametersByName: {} },
  );
};

/*
 * Gets variables parameter map
 *
 * Spec: https://10.31.1.52/swagger/#/Repository/loadExtendedParamaterMap
 *
 * The response is controlled by
 *  MS: https://github.com/ubiqube/msa-api/blob/develop/src/resources/templates/conf/variable_extended_parameters_map_ms.yml
 *  WF: https://github.com/ubiqube/msa-api/blob/develop/src/resources/templates/conf/variable_extended_parameters_map_wf.yml
 *
 */
export const getAdvancedVariableParameters = (repositoryType, token) => {
  return get({
    url: `${API}/repository/v2/variables/extended-parameters?repository=${repositoryType}`,
    token,
  });
};

export const getAllAdvancedVariableParameters = async (token) => {
  const [
    microserviceAdvancedVariableParametersResponse,
    workflowAdvancedVariableParametersResponse,
  ] = await Promise.all([
    getAdvancedVariableParameters("microservice", token),
    getAdvancedVariableParameters("workflow", token),
  ]);

  const [
    microserviceAdvancedVariableParametersError,
    microserviceAdvancedVariableParameters,
  ] = microserviceAdvancedVariableParametersResponse;
  const [
    workflowAdvancedVariableParametersError,
    workflowAdvancedVariableParameters,
  ] = workflowAdvancedVariableParametersResponse;

  const transformedAdvancedVariableParameters = transformAdvancedVariableParameters(
    {
      ...microserviceAdvancedVariableParameters,
      ...workflowAdvancedVariableParameters,
    },
  );

  return [
    microserviceAdvancedVariableParametersError ||
      workflowAdvancedVariableParametersError,
    {
      microserviceVariableTypes: microserviceAdvancedVariableParameters
        ? Object.keys(microserviceAdvancedVariableParameters)
        : {},
      workflowVariableTypes: workflowAdvancedVariableParameters
        ? Object.keys(workflowAdvancedVariableParameters)
        : {},
      microserviceAdvancedParametersByType: reduce(
        microserviceAdvancedVariableParameters,
        (acc, value, key) => ({ ...acc, [key]: flattenParameters(value) }),
        {},
      ),
      workflowAdvancedParametersByType: reduce(
        workflowAdvancedVariableParameters,
        (acc, value, key) => ({ ...acc, [key]: flattenParameters(value) }),
        {},
      ),
      ...transformedAdvancedVariableParameters,
    },
  ];
};

/*
 * Gets the list for Microservice Reference variable.
 *
 * Spec: https://10.31.1.52/swagger/#/Configuration%20Object/retrieveMSConfigurationObjects22
 */

const retrieveMSConfigurationObjects = ({
  token,
  objectRefData,
  obmfRedData,
}) => {
  return post({
    url: `${API}/configuration-objects/v2/ref-data`,
    token,
    body: {
      objectRefData,
      obmfRedData,
    },
  });
};

export const getMSReferenceByObjectRefData = ({
  token,
  objectId,
  deviceId,
  fromURI,
  localVarToFilter,
  localVarValue,
  remoteVarToFilter,
  msNames,
}) => {
  return retrieveMSConfigurationObjects({
    token,
    objectRefData: {
      objectId,
      deviceId,
      fromURI,
      localVarToFilter,
      localVarValue,
      remoteVarToFilter,
      msNames,
    },
  });
};

export const getMSReferenceByObmfRefData = ({
  token,
  deviceId,
  msNames,
  localVarToFilter,
  localVarValueToFilter,
  remoteVarToFilter,
  remoteVarValueToFilter,
}) => {
  return retrieveMSConfigurationObjects({
    token,
    obmfRedData: {
      deviceId,
      msNames,
      localVarToFilter,
      localVarValueToFilter,
      remoteVarToFilter,
      remoteVarValueToFilter,
    },
  });
};
