import React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import {
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  form: {
    display: "flex",
    width: "100%",
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  selected: {
    color: "#394867",
  },
  unselected: {
    color: "#a1a1a1",
  },
  buttonText: {
    color: "#a1a1a1",
    fontSize: "0.8125rem",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.3,
    textTransform: "uppercase",
  },
  btnRoot: {
    padding: 5,
    "&:hover": {
      borderRadius: 5,
      padding: 5,
    },
  },
}));

const SearchBar = ({
  onCloseCallBack,
  onChangeCallback,
  searchString,
  onClickClearSearchCallback,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root} elevation={1}>
      <IconButton className={classes.iconButton} aria-label={t("Search")}>
        <SearchIcon />
      </IconButton>
      <form className={classes.form}>
        <InputBase
          onChange={onChangeCallback}
          className={classes.input}
          autoComplete="off"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          placeholder={t("Search for a managed entity")}
          value={searchString}
          id="search-bar-dropdown-input"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                classes={{
                  root: classes.btnRoot,
                }}
                aria-label={t("Clear")}
                size={"small"}
                onClick={onClickClearSearchCallback}
              >
                <Typography className={classes.buttonText} variant="caption">
                  {t("Clear")}
                </Typography>
              </IconButton>
              <Divider className={classes.divider} />
            </InputAdornment>
          }
        />
      </form>
      <IconButton
        className={classes.iconButton}
        aria-label={t("Close")}
        onClick={onCloseCallBack}
        id="search-bar-dropdown-close"
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};

SearchBar.propTypes = {
  searchString: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  onCloseCallBack: PropTypes.func.isRequired,
  onClickClearSearchCallback: PropTypes.func.isRequired,
};

export default SearchBar;
