import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import flow from "lodash/flow";

/**
 * MATERIAL UI
 */
import { withStyles } from "@material-ui/core";

/**
 * COMPONENTS
 */
import DoughnutChart from "./graphs/Doughnut";

import { Paper, Grid } from "@material-ui/core";

const styles = (theme) => ({
  paperCenter: {
    display: "flex",
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
});

const TotalWorkflowChart = (props) => {
  const formatWorkflowsToChartdataTotal = (workflows) => {
    return [
      {
        key: "",
        name: "",
        total: workflows.totalProcessInstances,
        badge: {},
        graph: [
          [
            {
              name: "Running",
              value: workflows.running,
            },
            {
              name: "Fail",
              value: workflows.fail,
            },
            {
              name: "Warning",
              value: workflows.warning,
            },
            {
              name: "None",
              value: workflows.none,
            },
            {
              name: "Pause",
              value: workflows.pause,
            },
            {
              name: "Ended",
              value: workflows.ended,
            },
          ],
        ],
      },
    ];
  };

  const { classes, t, workflows } = props;

  return (
    <Grid container justifyContent="center">
      <Paper className={classes.paperCenter}>
        <DoughnutChart
          chartType={"total workflow"}
          chartData={formatWorkflowsToChartdataTotal(workflows)}
          chartDataText={t("Total Instances")}
          size={132}
          maxCharts={1}
          noborder
        />
      </Paper>
    </Grid>
  );
};

TotalWorkflowChart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default flow(withStyles(styles), withTranslation())(TotalWorkflowChart);
