import { useSelector } from "react-redux";

import {
  getSelectedSubtenant,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";
import useApi from "msa2-ui/src/hooks/useApi";
import { getWorkflowList } from "msa2-ui/src/api/workflow";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { getIsDeveloper } from "msa2-ui/src/store/auth";

const useWorkflowList = (
  { filter: { tenant, subtenant } = {}, ...params },
  wait,
  autoRefreshInterval,
) => {
  const isDeveloper = useSelector(getIsDeveloper);
  const isFilteredByOwner = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );
  const { id: subtenantId } = useSelector(getSelectedSubtenant);
  const { id: tenantId } = useSelector(getSelectedTenant);

  return useApi(
    getWorkflowList,
    {
      isDeveloper,
      isFilteredByOwner,
      // tenant and subtenant can be overwritten by params
      tenantId: tenant && tenantId,
      subtenantId: subtenant && subtenantId,
      ...params,
    },
    wait,
    autoRefreshInterval,
  );
};

export default useWorkflowList;
