import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import get from "lodash/get";
import isNil from "lodash/isNil";

import useApi from "msa2-ui/src/hooks/useApi";
import { getToken } from "msa2-ui/src/store/auth";
import EditorAce from "msa2-ui/src/components/EditorAce";

import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { ReactComponent as PlusIcon } from "msa2-ui/src/assets/icons/plus.svg";
import { DeleteOutlined as DeleteIcon, Web, Code } from "@material-ui/icons";

import { readRepository } from "msa2-ui/src/api/repository";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import RepositoryPicker from "msa2-ui/src/components/RepositoryPicker";

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 10,
  },
  wrapper: {
    marginTop: 10,
    height: "97%",
  },
  codeWrapper: {
    marginTop: 10,
    height: "auto",
  },
  rendererWrapper: {
    marginBottom: 10,
    height: "inherit",
  },
  select: {
    padding: 5,
  },
  plusIcon: {
    marginLeft: 5,
  },
  autoRefresh: {
    marginBottom: 10,
  },
  autoRefreshTime: {
    width: 200,
    marginLeft: 20,
  },
}));

const RenderData = ({ datafilesUri, viewAs, autoRefresh, frameStyle }) => {
  const { t } = useTranslation();
  const [loading, , dataFiles] = useApi(
    readRepository,
    { uri: datafilesUri },
    !datafilesUri,
    autoRefresh,
  );
  const targetFile = dataFiles?.content;

  if (loading && !dataFiles) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (!targetFile) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        {t("There are no contents to show")}
      </Grid>
    );
  }

  return viewAs === 0 ? (
    <EditorAce value={targetFile} />
  ) : (
    <iframe
      title="Datafile"
      srcDoc={targetFile}
      width="100%"
      height="100%"
      style={{ border: 0, ...frameStyle }}
    />
  );
};

const DatafilesRenderer = ({
  renderData: initRenderData = [],
  viewAs: initViewAs = 0,
  autoRefresh: initAutoRefresh = 0,
  hideFunctionalities,
  frameStyle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [renderData, setRenderData] = useState(initRenderData);
  const [typedUri, setTypedUri] = useState("");
  const [viewAs, setViewAs] = useState(initViewAs);
  const [autoRefresh, setAutoRefresh] = useState(initAutoRefresh);
  const state = useSelector((state) => state);
  const token = useSelector(getToken);

  return (
    <>
      {!hideFunctionalities && (
        <FilterMenu
          handleViewAsChange={(event, value) => setViewAs(value)}
          viewAsIcons={[<Code />, <Web />]}
        />
      )}
      <Grid
        container
        className={classnames({
          [classes.codeWrapper]: renderData.length > 0 && viewAs === 0,
          [classes.wrapper]: renderData.length > 0 && viewAs === 1,
        })}
      >
        {!hideFunctionalities && (
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            alignContent="center"
            className={classes.autoRefresh}
          >
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ "aria-label": t("Auto Refresh") }}
                    checked={Boolean(autoRefresh)}
                    onChange={() => {
                      autoRefresh === 0
                        ? setAutoRefresh(60000)
                        : setAutoRefresh(0);
                    }}
                  />
                }
                label={t("Auto Refresh")}
              />
            </FormControl>
            <TextField
              label="Auto Refresh Time"
              type="number"
              size="small"
              InputProps={{
                classes: { input: classes.alignRight },
                endAdornment: (
                  <InputAdornment position="end">{t("seconds")}</InputAdornment>
                ),
                inputProps: {
                  "aria-label": t("Auto Refresh Time"),
                },
              }}
              className={classes.autoRefreshTime}
              value={autoRefresh / 1000}
              onChange={({ target: { value } }) => {
                if (value > 0 && value < 5184000) setAutoRefresh(value * 1000);
              }}
              disabled={!autoRefresh}
            />
          </Grid>
        )}
        {renderData.map(({ datafilesUri: _datafilesUri, replace }, index) => {
          const datafilesUri = replace?.length
            ? replace.reduce((acc, { substr, statePath }) => {
                const stateValue = get(state, statePath);
                return isNil(stateValue)
                  ? acc
                  : acc.replace(substr, stateValue);
              }, _datafilesUri)
            : _datafilesUri;
          return (
            <Fragment key={index}>
              <Grid item xs={12} container className={classes.title}>
                {(!hideFunctionalities || renderData.length > 1) && (
                  <Grid item xs={10} container alignContent="center">
                    <Typography variant={"h2"}>{datafilesUri}</Typography>
                  </Grid>
                )}
                {!hideFunctionalities && (
                  <Grid
                    item
                    xs={2}
                    container
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    <IconButton
                      id={`WORKFLOW_LINE_GRAPH_DELETE_${index}`}
                      aria-label={t("Delete")}
                      onClick={() => {
                        setRenderData(renderData.filter((e, i) => i !== index));
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} className={classes.rendererWrapper}>
                <RenderData
                  viewAs={viewAs}
                  datafilesUri={datafilesUri}
                  autoRefresh={autoRefresh}
                  frameStyle={frameStyle}
                />
                <Divider className={classes.divider} />
              </Grid>
            </Fragment>
          );
        })}
        {!hideFunctionalities && (
          <>
            <Grid
              item
              xs={10}
              container
              justifyContent="flex-start"
              alignContent="center"
            >
              <RepositoryPicker
                value={typedUri}
                label={t("URI")}
                InputProps={{
                  inputProps: {
                    "aria-label": t("URI"),
                  },
                }}
                onChange={(value) => {
                  setTypedUri(value);
                }}
                fullWidth
                token={token}
              />
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent="flex-end"
              alignContent="center"
            >
              <Button
                aria-label={t("Add")}
                onClick={() => {
                  setRenderData(renderData.concat({ datafilesUri: typedUri }));
                  setTypedUri("");
                }}
                disabled={!typedUri}
              >
                {t("Add")}
                <PlusIcon className={classes.plusIcon} />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

DatafilesRenderer.propTypes = {
  renderData: PropTypes.array,
  viewAs: PropTypes.number,
  autoRefresh: PropTypes.number,
  hideFunctionalities: PropTypes.bool,
};

export default DatafilesRenderer;
