import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";

import useApiUtil from "msa2-ui/src/utils/useApi";

import {
  getWorkflow,
  getProcessVariableTypesByTask,
  getWorkflowInstanceVariableValues,
  executeService,
  launchProcessInstance,
} from "msa2-ui/src/api/workflow";

import Process from "msa2-ui/src/services/Process";
import Variable from "msa2-ui/src/services/Variable";

import WorkflowLiveConsoleComponent from "msa2-ui/src/components/workflow-live-console";
import MSAConsoleComponent from "msa2-ui/src/components/msa-console";

export const getFirstCreateProcess = (workflow) =>
  workflow.process.find(({ type }) => Process.isCreate(type))?.name;

export const getWorkflowContext = async ({
  token,
  workflowPath,
  workflow: _workflow = {},
  processName: _processName,
  variables: _variables,
  useFirstCreateProcess = false,
}) => {
  let error = null,
    meta = null;
  const response = {};

  let workflow = _workflow,
    processVariableTypesByTask = {};
  if (!_variables && isEmpty(_workflow)) {
    // if "variables" is passed, don't need to get workflow
    [error, workflow, meta] = await getWorkflow({
      pathToWorkflowDefinitionFile: workflowPath,
      addServiceName: true,
      token,
    });
    if (!error) {
      response.workflow = workflow;
    }
  }
  if (!error) {
    // Get parameter variables
    const processName = useFirstCreateProcess
      ? getFirstCreateProcess(workflow)
      : _processName;
    if (processName) {
      response.processName = processName;
      [
        error,
        processVariableTypesByTask,
        meta,
      ] = await getProcessVariableTypesByTask({
        workflowPath,
        processName,
        token,
      });
      if (!error) {
        const variables = _variables ?? workflow.variables.variable;
        response.processVariables = processVariableTypesByTask;
        const initContext = Variable.createDefaultValue(variables);
        response.initContext = initContext;
      }
    }
  }
  return [error, response, meta];
};

/**
 * A Hook which you can get needed parameters to render and submit Workflow Console
 *
 * Possible combination of params is
 *  - workflowPath + useFirstCreateProcess
 *  - workflow + processName
 *
 * @example
 * const Example = ({ environment }) => {
 *   const {
 *     context,
 *     setContext,
 *     variables,
 *     processVariables,
 *     editMode,
 *     runProcess,
 *     processInstance,
 *     isLoading,
 *   } = useWorkflowContext({
 *     workflowPath: appWFUri,
 *     useFirstCreateProcess: true,
 *   });
 *   const onSubmit = async () => {
 *     const [, status] = await runProcess({ ubiqubeId });
 *   };
 *   return (
 *     <>
 *       {!isLoading && (
 *         <MSAConsole
 *           data={context}
 *           variables={variables}
 *           processVariables={processVariables}
 *           editMode={editMode}
 *           onChange={setContext}
 *         />
 *       )}
 *       {processInstance && (
 *         <WorkflowLiveConsole status={processInstance} />
 *       )}
 *       <Button onClick={onSubmit}>{t("Submit")}</Button>
 *     </>
 *   );
 * };
 * @param {object} workflow
 * @param {string} processName
 * @param {string} workflowPath
 * @param {boolean} useFirstCreateProcess
 * @returns {object} see @example
 */

const useWorkflowContext = ({
  workflow: _workflow,
  processName: _processName,
  workflowPath,
  useFirstCreateProcess = false,
  instanceId,
  ubiqubeId,
  defaultContext = {},
  wait = false,
  token,
}) => {
  const useApi = (apiCall, params = {}, wait) =>
    useApiUtil({ apiCall, params, wait, token });

  const serviceName =
    workflowPath ?? _workflow?.metaInformationList[0].serviceName;

  const _variables = _workflow?.variables.variable;

  const [processInstance, setProcessInstance] = useState();
  const [context, _setContext] = useState({});
  const [isExecuting, setIsExecuting] = useState(false);

  const setContext = (data, key) => {
    if (key) {
      _setContext(cloneDeep(set(context, key, data)));
    } else {
      _setContext(data);
    }
  };
  const [isLoading, error, workflowContext = {}] = useApi(
    getWorkflowContext,
    {
      workflowPath: serviceName,
      workflow: _workflow,
      processName: _processName,
      variables: _variables,
      useFirstCreateProcess,
    },
    !serviceName || wait,
  );

  const {
    workflow = _workflow,
    initContext,
    processVariables,
    processName = _processName,
  } = workflowContext;
  const variables = workflow?.variables.variable;

  const [isExistingContextLoading, , existingContext] = useApi(
    getWorkflowInstanceVariableValues,
    { instanceId },
    !instanceId || wait,
  );

  useEffect(() => {
    if (existingContext) {
      _setContext({ ...existingContext, ...defaultContext });
    } else if (initContext) {
      _setContext({ ...initContext, ...defaultContext });
    } else {
      _setContext(defaultContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingContext, initContext]);

  useEffect(() => {
    if (!_processName) {
      // clear process instance in case multiple process is triggered sequentially
      setProcessInstance();
    }
  }, [_processName]);

  const processType = workflow?.process.find(({ name }) => name === processName)
    ?.type;

  const runProcess = async (onError) => {
    setIsExecuting(true);

    let result = {};
    if (Process.isCreate(processType)) {
      result = await executeService({
        token,
        ubiqubeId,
        serviceName,
        processName,
        body: context,
      });
    } else {
      result = await launchProcessInstance({
        token,
        ubiqubeId,
        serviceId: instanceId,
        processName,
        body: context,
      });
    }
    const [error, processInstance] = result;
    if (error) {
      onError && onError(error);
    } else {
      setProcessInstance(processInstance);
    }
    setIsExecuting(false);
    return result;
  };

  const MSAConsole = (props) => {
    if (isLoading) return null;

    return (
      <MSAConsoleComponent
        data={context}
        variables={workflow?.variables.variable}
        processVariables={processVariables}
        editMode={processType}
        onChange={setContext}
        {...props}
      />
    );
  };

  const WorkflowLiveConsole = ({ pollingInterval = 1000, ...rest }) => {
    if (!processInstance) return null;
    const {
      processId: { id: processId, name: processName },
    } = processInstance;

    return (
      <WorkflowLiveConsoleComponent
        processId={processId}
        processName={processName}
        processInstance={processInstance}
        token={token}
        pollingInterval={pollingInterval}
        {...rest}
      />
    );
  };

  return {
    context,
    setContext,
    workflow,
    variables,
    initContext,
    processVariables,
    editMode: processType,
    serviceName,
    processName,
    runProcess,
    processInstance,
    MSAConsole,
    WorkflowLiveConsole,
    isLoading:
      isLoading ||
      isExistingContextLoading ||
      isExecuting ||
      (!wait && !error && isEmpty(workflowContext)),
    error,
  };
};

export default useWorkflowContext;
