import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import debounce from "lodash/debounce";

import {
  selectTenantByLabel,
  selectSubtenantById,
  getSelectedTenant,
  getSelectedSubtenant,
  getAvailableSubtenants,
  getAvailableTenants,
  getMsaTheme,
} from "msa2-ui/src/store/designations";

import useApi from "msa2-ui/src/hooks/useApi";
import useToggle from "react-use/lib/useToggle";

import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as Search } from "msa2-ui/src/assets/icons/search-00.svg";
import { ReactComponent as SearchLight } from "msa2-ui/src/assets/icons/search-00-light.svg";

import ErrorBoundary from "../ErrorBoundary";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import { simpleSearch } from "msa2-ui/src/api/search";
import { entityTypes } from "msa2-ui/src/Constants";
import Repository from "msa2-ui/src/services/Repository";
import { getIsDeveloper, getUserRole } from "msa2-ui/src/store/auth";

const useStyles = makeStyles(
  ({ palette, darkMode, shadows, colors, breakpoints }) => ({
    root: {
      backgroundColor: "rgba(0, 0, 0, 0.18)",
    },
    paper: {
      backgroundColor: palette.background.paper,
      boxShadow: shadows[5],
      height: 64,
      left: 181,
      outline: "none",
      position: "absolute",
      top: 0,
      transform: "translate 0%, -0%",
      width: "86%",
    },
    searchIcon: {
      cursor: "pointer",
      height: 40,
      width: 40,
    },
  }),
);

const DashboardSearch = ({ searchWrapperClass }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const subtenantList = useSelector(getAvailableSubtenants);
  const tenants = useSelector(getAvailableTenants);
  const selectedTenant = useSelector(getSelectedTenant);
  const selectedSubtenant = useSelector(getSelectedSubtenant);
  const userRole = useSelector(getUserRole);
  const isDeveloper = useSelector(getIsDeveloper);
  const theme = useSelector(getMsaTheme);

  const [showModal, toggleShowModal] = useToggle(false);
  const [inputValue, setInputValue] = useState("");
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const debouncedSetSearchString = debounce(setSearchString, 300);
    debouncedSetSearchString(inputValue);
    return () => {
      debouncedSetSearchString.cancel();
    };
  }, [inputValue]);

  useEffect(() => {
    // If the input value has been altered and then quickly replaced, isLoading state
    // will be true, but the debounced API call has been canceled. So isLoading needs
    // to be manually set to false.
    if (isLoading && inputValue === searchString) {
      setIsLoading(false);
    }
  }, [inputValue, searchString, isLoading]);

  const [apiIsLoading, apiError, apiResponse = {}] = useApi(
    simpleSearch,
    {
      isDeveloper,
      tenants,
      subtenantList,
      searchString,
      selectedTenant,
      selectedSubtenant,
      userRole,
    },
    !searchString,
  );

  useDeepCompareEffect(() => {
    if (apiIsLoading) return;
    setIsLoading(false);
  }, [apiIsLoading, apiResponse, apiError]);

  const resetSearch = () => {
    setInputValue("");
    setSearchString("");
  };

  const closeSearchModal = () => {
    if (apiError) {
      resetSearch();
    }
    toggleShowModal();
  };

  const onSearchResultClicked = (
    resultType,
    tenantLabel,
    subtenantId,
    deviceId,
    path,
    microserviceName,
  ) => {
    dispatch(selectTenantByLabel(tenantLabel));
    dispatch(selectSubtenantById(subtenantId));

    switch (resultType) {
      case entityTypes.microservices.id:
        history.push(
          `/integration/managed-entities/${parseInt(
            deviceId,
          )}/configure/${Repository.stripFileExtensionFromString(
            microserviceName,
          )}`,
        );
        break;
      case entityTypes.workflows.id:
        history.push(`/automation/workflows/${encodeURIComponent(path)}`);
        break;
      case entityTypes.bpm.id:
        history.push(`/automation/bpm/${encodeURIComponent(path)}/edit`);
        break;
      case entityTypes.me.id:
        history.push("/integration/managed-entities/" + parseInt(deviceId));
        break;

      default:
        break;
    }

    closeSearchModal();
  };

  const renderSearchIcon = (darkMode) => {
    return darkMode ? (
      <Search
        className={classes.searchIcon}
        onClick={toggleShowModal}
        id="search-dropdown-searchButton"
        aria-label={t("Search")}
      />
    ) : (
      <SearchLight
        className={classes.searchIcon}
        onClick={toggleShowModal}
        id="search-dropdown-searchButton"
        aria-label={t("Search")}
      />
    );
  };

  return (
    <ErrorBoundary>
      {renderSearchIcon(theme)}
      <Modal
        aria-labelledby={t("Search Interface")}
        aria-describedby={t(
          "An interface for entering search term and viewing results",
        )}
        open={showModal}
        onClose={closeSearchModal}
        BackdropProps={{
          classes: {
            root: classes.root,
          },
        }}
      >
        <div className={searchWrapperClass}>
          <SearchBar
            searchString={inputValue}
            onChangeCallback={(event) => setInputValue(event.target.value)}
            onCloseCallBack={closeSearchModal}
            onClickClearSearchCallback={resetSearch}
          />
          <SearchResults
            showSearchResults={searchString.length > 0}
            searchResults={apiResponse.documents || []}
            searchResultsHitCount={apiResponse.hitCount}
            handleOnClickCallBack={onSearchResultClicked}
            searchErrorMessage={apiError}
            isLoading={isLoading}
            searchString={inputValue}
          />
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

DashboardSearch.propTypes = {
  searchWrapperClass: PropTypes.string,
};

export default DashboardSearch;
