import createTheme from "msa2-ui/src/styles/theme";

const SEVERITY_LEVELS = (darkMode) => {
  const { palette } = createTheme(darkMode);

  return {
    0: {
      color: palette.error.main,
      textColor: palette.common.white,
      label: "Emergency",
    },
    1: {
      color: palette.error.main,
      textColor: palette.common.white,
      label: "Alert",
    },
    2: {
      color: palette.error.main,
      textColor: palette.common.white,
      label: "Critical",
    },
    3: {
      color: palette.error.main,
      textColor: palette.common.white,
      label: "Error",
    },
    4: {
      color: palette.warning.main,
      textColor: palette.common.black,
      label: "Warning",
    },
    5: {
      color: palette.warning.main,
      textColor: palette.common.black,
      label: "Notice",
    },
    6: {
      color: palette.info.main,
      textColor: palette.common.black,
      label: "Informational",
    },
    7: {
      color: palette.info.main,
      textColor: palette.common.black,
      label: "Debug",
    },
    "-1": {
      color: palette.error.main,
      textColor: palette.common.white,
      label: "Same as Log",
    },
  };
};

const extractMessageFromRawlog = (rawlog) => {
  if (!rawlog || typeof rawlog !== "string") {
    return "";
  }

  // For logs in the format of %VNOC-1-IPUP: Message
  const firstMatcher = new RegExp(/%(.*?): (.*)/);
  const matchFromFirstMatcher = rawlog.match(firstMatcher)?.pop();
  if (matchFromFirstMatcher) {
    return matchFromFirstMatcher;
  }

  // For logs in the format of <14>Jan 23 15:23:01 msa yum[7779]: Message
  const secondMatcher = new RegExp(/<(.*?)\]: (.*)/);
  const matchFromSecondMatcher = rawlog.match(secondMatcher)?.pop();
  if (matchFromSecondMatcher) {
    return matchFromSecondMatcher;
  }

  return rawlog;
};

export default {
  SEVERITY_LEVELS,
  extractMessageFromRawlog,
};
