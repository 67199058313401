import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { addDirectory } from "msa2-ui/src/api/repository";
import { getToken } from "msa2-ui/src/store/auth";

import Repository from "msa2-ui/src/services/Repository";
import Process from "msa2-ui/src/services/Process";
import Task from "msa2-ui/src/services/Task";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const useWorkflowProcess = ({
  activeProcessIndex,
  change,
  sections,
  setActiveSidebarTab,
  workflowData,
  workflowProcesses,
  setIsLoading,
}) => {
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  const addProcessToForm = ({ processName, processPath, processType }) => {
    if (!workflowData) return;

    change(
      `process.${workflowProcesses.length}`,
      Process.make({
        displayName: processName,
        name: processPath,
        type: processType,
      }),
    );
  };

  const addProcess = async (processName, processDirectory, processType) => {
    setIsLoading(true);
    const workflowNameFileName = Repository.convertStringToFilename(
      workflowData.information.displayName,
    );
    const workflowPath =
      workflowData.metaInformationList?.[0].parentURI ??
      [Process.processUriPath, workflowNameFileName].join("/");
    const processPath = [workflowPath, processDirectory].join("/");

    const [errorProcess] = await addDirectory({
      uri: processPath,
      token,
    });
    if (errorProcess) {
      showSnackBar(
        errorProcess.getMessage(t("Unable to save x", { x: t("Process") })),
        "error",
      );
      return;
    }

    const taskDirectory = [processPath, Task.DIRECTORY_NAME].join("/");
    const [errorTask] = await addDirectory({
      uri: taskDirectory,
      token,
    });
    if (errorTask) {
      showSnackBar(
        errorTask.getMessage(t("Unable to save x", { x: t("Process") })),
        "error",
      );
      return;
    }

    addProcessToForm({ processName, processPath, processType });
    showSnackBar(t("x has been created", { x: t("Process") }), "success");
    setIsLoading(false);
  };

  const removeProcessFromForm = () => {
    const remainingProcesses = [...workflowProcesses];
    remainingProcesses.splice(activeProcessIndex, 1);
    change(`process`, remainingProcesses);
    if (remainingProcesses.length > 0) {
      setActiveSidebarTab(remainingProcesses[0].displayName);
    } else {
      setActiveSidebarTab(sections.information);
    }
  };

  return { addProcess, removeProcessFromForm };
};

export default useWorkflowProcess;
