import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import MailOutlined from "@material-ui/icons/MailOutlined";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles(({ palette, typography }) => ({
  baseRole: {
    paddingLeft: 20,
  },
  divider: {
    borderColor: "#e6eaee",
    width: "calc(100% + 32px)",
  },
  icon: {
    marginRight: 10,
  },
  label: {
    fontSize: "1rem",
    fontWeight: typography.fontWeightLight,
    lineHeight: "normal",
    letterSpacing: 0.3,
    color: palette.text.secondary,
    marginRight: 10,
  },
}));

const OverviewDetails = ({
  name,
  login,
  firstname,
  baseRoleName,
  address: {
    city,
    country,
    fax,
    phone,
    streetName1,
    streetName2,
    streetName3,
    zipCode,
    mail,
  },
  userCanViewAuthentication,
  userCanEditAuthentication,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const t = useBoundedTranslation();
  return (
    <div style={{ margin: 25 }}>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <div className={commonClasses.commonFlexBaseline}>
            <Typography variant="h1" data-testid="firstNameAndName">
              {firstname ? firstname + " " + name : name}
            </Typography>
            {baseRoleName && (
              <Typography
                variant="body2"
                className={classes.baseRole}
                data-testid="baseRoleName"
              >
                {"(" + baseRoleName + ")"}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {t("BASIC INFORMATION")}
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} lg={3}>
            <div className={commonClasses.commonItemWrapperNoAlign}>
              <MailOutlined className={classes.icon} />
              <Typography variant="body2" data-testid="mail">
                {mail}
              </Typography>
            </div>
          </Grid>
        </Grid>
        {(userCanViewAuthentication || userCanEditAuthentication) && (
          <>
            <Grid item xs={12}>
              <Divider variant="fullWidth" className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("AUTHENTICATION")}</Typography>
            </Grid>
            <Grid item container xs={12} direction="column">
              <div className={commonClasses.commonItemWrapperNoAlign}>
                <Typography variant="subtitle1" className={classes.label}>
                  {t("Login ID")}:
                </Typography>
                <Typography variant="body2" data-testid="loginId">
                  {login}
                </Typography>
              </div>
              <div className={commonClasses.commonItemWrapperNoAlign}>
                <Typography variant="subtitle1" className={classes.label}>
                  {t("Password")}:
                </Typography>
                <Typography variant="body2" data-testid="password">
                  ********
                </Typography>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

OverviewDetails.propTypes = {
  name: PropTypes.string,
  login: PropTypes.string,
  firstname: PropTypes.string,
  baseRoleName: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    fax: PropTypes.string,
    phone: PropTypes.string,
    streetName1: PropTypes.string,
    streetName2: PropTypes.string,
    streetName3: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  userCanViewAuthentication: PropTypes.bool,
  userCanEditAuthentication: PropTypes.bool,
};

export default OverviewDetails;
