import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  passwordToggle: {
    position: "absolute",
    right: 0,
  },
}));

const useTogglePassword = ({
  onIcon: OnIcon = Visibility,
  offIcon: OffIcon = VisibilityOff,
  iconSize,
} = {}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const styleOverride = {
    ...(iconSize && { fontSize: iconSize }),
  };

  const [showPassword, setShowPassword] = useState(false);

  const PasswordInputAdornment = (props) => (
    <InputAdornment
      variant="filled"
      position="end"
      className={classes.passwordToggle}
    >
      <IconButton
        id={"TOGGLE_SHOW_PASSWORD"}
        aria-label={t("Toggle password visibility")}
        onClick={() => setShowPassword(!showPassword)}
        tabIndex="-1"
        {...props}
      >
        {showPassword ? (
          <OffIcon style={styleOverride} />
        ) : (
          <OnIcon style={styleOverride} />
        )}
      </IconButton>
    </InputAdornment>
  );
  return [showPassword, PasswordInputAdornment];
};

export default useTogglePassword;
