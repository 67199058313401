import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import sortBy from "lodash/sortBy";

import {
  getModelsById,
  getManufacturersById,
  getCategoriesWithIds,
} from "msa2-ui/src/store/designations";

import { Grid, Typography } from "@material-ui/core";

import MsaSelect from "msa2-ui/src/components/msa-select";

const filterOption = ({ label }, subStr) =>
  label.toLowerCase().includes(subStr);

const DeviceAdapterSelector = ({
  error,
  vendor,
  model,
  category,
  onSelectDeviceAdapter,
  categoryProps,
  vendorProps,
  modelProps,
  categoryGridProps,
  vendorGridProps,
  modelGridProps,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const manufacturersById = useSelector(getManufacturersById);
  const modelsById = useSelector(getModelsById);
  const categories = useSelector(getCategoriesWithIds);
  const [selectedVendor, setSelectedVendor] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    if (category) {
      setSelectedCategory(category);
    }
  }, [category, categories]);

  useEffect(() => {
    if (vendor) {
      if (vendor.manufacturerId) {
        setSelectedVendor(vendor);
      }
      if (manufacturersById[vendor]) {
        setSelectedVendor(manufacturersById[vendor]);
      }
    }
  }, [vendor, manufacturersById]);

  useEffect(() => {
    if (model) {
      if (model.modelId) {
        setSelectedModel(model);
      }
      if (modelsById[model]) {
        setSelectedModel(modelsById[model]);
      }
    }
  }, [model, modelsById]);

  const vendorOptions = Object.values(
    manufacturersById,
  ).filter((manufacturer) =>
    selectedCategory?.manufacturers
      ? selectedCategory.manufacturers.includes(manufacturer?.manufacturerId)
      : true,
  );

  const modelOptions = Object.values(modelsById).filter((model) =>
    selectedVendor?.models
      ? selectedVendor.models.includes(model?.modelId)
      : selectedCategory?.models
      ? selectedCategory.models.includes(model?.modelId)
      : true,
  );

  return (
    <Grid container>
      {error && (
        <Grid item xs={12}>
          <Typography variant="body1" color="error" align="center">
            {error}
          </Typography>
        </Grid>
      )}
      <Grid item {...categoryGridProps}>
        <MsaSelect
          disabled={disabled}
          id="DEVICE_ADAPTER_SELECTOR_CATEGORY"
          options={Object.values(categories)}
          filterOption={filterOption}
          getOptionLabel={(option) => option.categoryName}
          placeholder={t("Select Category...")}
          value={selectedCategory}
          onChange={(category) => {
            setSelectedCategory(category);
            setSelectedVendor(null);
            setSelectedModel(null);
            onSelectDeviceAdapter &&
              onSelectDeviceAdapter({ category, vendor: null, model: null });
          }}
          {...categoryProps}
        />
      </Grid>
      <Grid item {...vendorGridProps}>
        <MsaSelect
          disabled={disabled}
          id="DEVICE_ADAPTER_SELECTOR_VENDOR"
          options={sortBy(vendorOptions, "manufacturerName")}
          filterOption={filterOption}
          getOptionLabel={(option) => option.manufacturerName}
          placeholder={t("Select Vendor...")}
          value={selectedVendor}
          onChange={(vendor) => {
            const category =
              selectedCategory ||
              Object.values(categories).find(({ manufacturers }) =>
                manufacturers?.includes(vendor?.manufacturerId),
              );
            setSelectedCategory(category);
            setSelectedVendor(vendor);
            setSelectedModel(null);
            onSelectDeviceAdapter &&
              onSelectDeviceAdapter({
                category: selectedCategory,
                vendor,
                model: null,
              });
          }}
          {...vendorProps}
        />
      </Grid>
      <Grid item {...modelGridProps}>
        <MsaSelect
          disabled={disabled}
          id="DEVICE_ADAPTER_SELECTOR_MODEL"
          options={sortBy(modelOptions, "modelName")}
          placeholder={t("Select Model...")}
          filterOption={filterOption}
          getOptionLabel={(option) => option.modelName}
          value={selectedModel}
          onChange={(model) => {
            // todo: modify redux store models to have manufacture id
            const vendor =
              selectedVendor ||
              Object.values(manufacturersById).find(({ models }) =>
                models?.includes(model?.modelId),
              );
            const category =
              selectedCategory ||
              Object.values(categories).find(({ models }) =>
                models?.includes(model?.modelId),
              );
            setSelectedCategory(category);
            setSelectedVendor(vendor);
            setSelectedModel(model);
            onSelectDeviceAdapter &&
              onSelectDeviceAdapter({ category, vendor, model });
          }}
          {...modelProps}
        />
      </Grid>
    </Grid>
  );
};

DeviceAdapterSelector.propTypes = {
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  vendor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  model: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onSelectDeviceAdapter: PropTypes.func,
  categoryProps: PropTypes.object,
  vendorProps: PropTypes.object,
  modelProps: PropTypes.object,
  categoryGridProps: PropTypes.object,
  vendorGridProps: PropTypes.object,
  modelGridProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DeviceAdapterSelector;
