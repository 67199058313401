import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  getSelectedTenant,
  clearSelectedTenant,
} from "msa2-ui/src/store/designations";
import { getToken } from "msa2-ui/src/store/auth";
import { editTenant } from "msa2-ui/src/api/tenant";

import { TextField, Grid, FormControl, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import Dialog from "msa2-ui/src/components/Dialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const useStyles = makeStyles((theme) => ({
  gridSpacing: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const Edit = ({ tenants }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const selectedTenant = useSelector(getSelectedTenant);
  const token = useSelector(getToken);
  const { prefix } = useParams();
  const dispatch = useDispatch();
  const tenant = tenants?.find((tenant) => tenant.prefix === prefix);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState("");
  const [tenantEditName, setTenantName] = useState(tenant?.name);

  if (!tenant) {
    history.push("/admin/tenants");
  }

  const onSubmit = async () => {
    setErrorMessage("");
    if (!tenantEditName) {
      setErrorMessage(t("Please fill out the required fields"));
      return;
    }
    const sameName =
      tenant.name === tenantEditName
        ? false
        : tenants?.find((tenant) => tenant.name === tenantEditName);
    if (sameName) {
      setErrorMessage(
        t("Please use a unique name for the x", { x: t("Tenant") }),
      );
      return;
    }

    const [error] = await editTenant({
      token,
      name: tenantEditName,
      prefix,
    });
    const variant = error ? "error" : "success";
    const message = error
      ? `${t("Unable to update")} ${t("Tenant")}`
      : `${t("Tenant")} ${t("updated")} `;

    if (!error) {
      if (selectedTenant.value === tenant.prefix) {
        dispatch(clearSelectedTenant());
      }
      closeDialog();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  const closeDialog = () => {
    history.goBack();
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={`${t("Edit")} ${t("Tenant")}`}
    >
      <Grid container direction="column">
        <Grid className={classes.gridSpacing}>
          <FormControl fullWidth>
            <TextField
              type={"text"}
              id="TENANT_EDIT_PREFIX"
              variant="outlined"
              label={`${t("Tenant")} ${t("Prefix")}`}
              value={prefix}
              required
              disabled
            />
          </FormControl>
        </Grid>
        <Grid className={classes.gridSpacing}>
          <FormControl fullWidth>
            <TextField
              type={"text"}
              id="TENANT_EDIT_NAME"
              variant="outlined"
              label={`${t("Tenant")} ${t("Name")}`}
              required
              value={tenantEditName}
              onChange={(event) => {
                setTenantName(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.gridSpacing}>
          {errorMessage && (
            <Typography
              color="error"
              className={classes.errorMessage}
              id="TENANT_EDIT_ERROR"
            >
              {errorMessage}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Edit;
