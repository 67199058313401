import React, { useCallback, useState } from "react";
import AttachmentDialog from "msa2-ui/src/components/attachment-dialog";
import { useTranslation } from "react-i18next";
import {
  addDeploymentSettingsToMicroservice,
  getDeploymentSettingsBySubtenant,
} from "msa2-ui/src/api/deploymentSettings";
import { useSelector } from "react-redux";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import useApi from "msa2-ui/src/hooks/useApi";
import { useSnackbar } from "notistack";
import union from "lodash/union";
import difference from "lodash/difference";
import { getToken } from "msa2-ui/src/store/auth";

const AttachDeploymentSettingsDialog = ({ onClose, microservice }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const token = useSelector(getToken);
  const subtenantId = useSelector(getSelectedSubtenant).id;
  const { deploymentSettings = [], vendor, model } = microservice;

  const [attachedDeploymentSettings, setAttachedDeploymentSettings] = useState(
    deploymentSettings,
  );
  const [isSaving, setIsSaving] = useState(false);

  const [isLoading, , response = []] = useApi(
    getDeploymentSettingsBySubtenant,
    {
      subtenantId,
    },
    !subtenantId,
  );

  const handleAttach = useCallback(
    (ids) =>
      setAttachedDeploymentSettings(union(attachedDeploymentSettings, ids)),
    [attachedDeploymentSettings],
  );

  const handleDetach = useCallback(
    (ids) =>
      setAttachedDeploymentSettings(
        difference(attachedDeploymentSettings, ids),
      ),
    [attachedDeploymentSettings],
  );

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    const [attachApiError] = await addDeploymentSettingsToMicroservice({
      token,
      body: {
        profileIds: attachedDeploymentSettings,
        microService: microservice.uri,
      },
    });

    const variant = attachApiError ? "error" : "success";
    const message = attachApiError
      ? attachApiError.getMessage(
          t("Unable to attach x", { x: t("Deployment Settings") }),
        )
      : t("Deployment Settings successfully attached.");

    variant === "success" ? onClose(true) : setIsSaving(false);

    enqueueSnackbar(message, { variant });
  }, [
    attachedDeploymentSettings,
    microservice,
    enqueueSnackbar,
    onClose,
    t,
    token,
  ]);

  const handleClose = useCallback(() => onClose(true), [onClose]);

  const allItems = response.filter(
    (ds) => ds.vendor?.id === Number(vendor) && ds.model?.id === Number(model),
  );

  return (
    <AttachmentDialog
      allItems={allItems || []}
      attachedItemIds={attachedDeploymentSettings}
      idKey="id"
      displayNameKey="name"
      attachedTitle={t("Attached x", { x: t("Deployment Settings") }) + ": "}
      popupTitle={t("Attach x", { x: t("Deployment Settings") })}
      noDataMessage={t("There are no x", { x: t("Deployment Settings") })}
      searchPlaceholder={t("Search for x", { x: t("Deployment Settings") })}
      unattachedTitle={t("Select x to attach", {
        x: t("Deployment Settings"),
      })}
      isLoading={isLoading || isSaving}
      onAttach={handleAttach}
      onDetach={handleDetach}
      onSave={handleSave}
      onClose={handleClose}
    />
  );
};

export default AttachDeploymentSettingsDialog;
