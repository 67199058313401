import React, { useState } from "react";
import PropTypes from "prop-types";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import classnames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import isEqual from "lodash/isEqual";
import intersection from "lodash/intersection";
import set from "lodash/set";
import orderBy from "lodash/orderBy";
import { useSnackbar } from "notistack";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

import { getToken } from "msa2-ui/src/store/auth";
import { statusTypes } from "msa2-ui/src/Constants";
import Bpm, { EXTERNAL_REFERENCE_PREFIX } from "msa2-ui/src/services/Bpm";

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Refresh from "@material-ui/icons/Refresh";

import StatusBadge from "msa2-ui/src/components/StatusBadge";
import {
  getActivityStatus,
  getHistoricActivityInstance,
  throwSignal,
} from "msa2-ui/src/api/bpm";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 5,
  },
  formHeading: {
    padding: 20,
    paddingBottom: 15,
  },
  refreshIcon: {
    marginTop: 4,
  },
  executionItem: {
    borderBottom: `1px solid ${theme.colors.greyLight2}`,
    padding: "14px 20px",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  workflowAndProcess: {
    marginTop: 8,
    paddingLeft: 26,
  },
  workflowInstance: {
    marginTop: 2,
    paddingLeft: 40,
  },
  continueBpm: {
    width: 90,
    height: 25,
    fontSize: 13,
  },
}));

const ExecutionPanel = ({
  executionProcessInstanceId,
  workflowProgress,
  modelerActions,
  activityInstances,
  isFinished,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);
  const [showWorkflowProgress, ,] = workflowProgress;

  const canContinueBpm = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "instance.continue"),
  );

  const [results, setResults] = useState([]);
  const [statuses, setStatuses] = useState({});
  const [lastActivities, setLastActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const getLatestExecutionId = ({ executionIds }) =>
    executionIds[executionIds.length - 1];
  // picks up the executionId and create an array
  const getExecutionIds = (activityInstances) =>
    activityInstances.map((instance) => getLatestExecutionId(instance));
  const onFinished = () => {
    setLastActivities([]);

    enqueueSnackbar(t("BPM tasks completed."), { preventDuplicate: true });
    // Refresh all results in the end in case some tasks finished too fast to catch via API
    onRefreshResult();
  };

  const onRefreshResult = async () => {
    setIsLoading(true);
    setResults([]);
    const [, activityInstancesResponse] = await getHistoricActivityInstance({
      processInstanceId: executionProcessInstanceId,
      token,
    });
    setResults(activityInstancesResponse);

    const taskInstances = activityInstancesResponse.filter(
      (instance) => instance.activityType === "serviceTask",
    );
    const responses = await Promise.all(
      taskInstances.map(({ executionId }) =>
        getActivityStatus({ executionId, token }),
      ),
    );
    // update workflow status with API result
    setStatuses(
      taskInstances.reduce((result, { executionId }, i) => {
        const [, response] = responses[i];

        return set(result, executionId, {
          executionStatus: response?.status?.status ?? statusTypes.NOT_STARTED,
          serviceId: response?.serviceId?.id ?? "",
        });
      }, statuses),
    );
    setIsLoading(false);

    // If there is a task in progress, show the modal
    const runningTaskIndex = responses.findIndex(
      ([, response]) => response?.status?.status === statusTypes.IN_PROGRESS,
    );
    if (runningTaskIndex >= 0) {
      const { activityName: processName, executionId } = taskInstances[
        runningTaskIndex
      ];
      showWorkflowProgress({
        processName,
        processReference: `${EXTERNAL_REFERENCE_PREFIX}${executionId}`,
        hideWhenEnded: true,
      });
    }
  };

  const onActiveInstanceChanged = async (
    lastExecutionIds,
    currentExecutionIds,
    activityInstances,
  ) => {
    const [, activityInstancesResponse] = await getHistoricActivityInstance({
      processInstanceId: executionProcessInstanceId,
      token,
    });
    setResults(activityInstancesResponse);
    // if there are newly finished activities, call msa api and get the result status
    const finishedExecutionIds = activityInstancesResponse
      .filter((instance) => instance.activityType === "serviceTask")
      .filter((instance) => instance.endTime)
      .map((instance) => instance.executionId);
    const finishedActivities = intersection(
      lastExecutionIds,
      finishedExecutionIds,
    );
    if (finishedActivities.length > 0) {
      const responses = await Promise.all(
        finishedActivities.map((executionId) =>
          getActivityStatus({ executionId, token }),
        ),
      );
      const newStatuses = responses.reduce(
        (acc, [, response], i) => ({
          ...acc,
          [finishedActivities[i]]: {
            executionStatus:
              response?.status?.status ?? statusTypes.IN_PROGRESS,
            serviceId: response?.serviceId?.id,
          },
        }),
        statuses,
      );
      setStatuses(newStatuses);
    }

    // if the first activity is changed, show the progress modal

    // // // // // // // // temporary // // // // // // // //
    // Call API once, show progress popup if API does not return the error
    // Delete this after back-end ticket MSA-9049 is fixed
    if (
      currentExecutionIds.length > 0 &&
      lastExecutionIds !== currentExecutionIds
    ) {
      const responses = await Promise.all(
        currentExecutionIds.map((executionId) =>
          getActivityStatus({ executionId, token }),
        ),
      );
      const noErrorIndex = responses.findIndex(([error]) => !error);
      if (noErrorIndex >= 0) {
        showWorkflowProgress({
          processName: activityInstances[noErrorIndex].activityName,
          processReference: `${EXTERNAL_REFERENCE_PREFIX}${currentExecutionIds[noErrorIndex]}`,
          hideWhenEnded: true,
        });
      }
    }
    // // // // // // // // temporary // // // // // // // //

    // Uncomment this after deleting above
    // if (
    //   childActivityInstances.length > 0 &&
    //   lastExecutionIds[0] !== childActivityInstances[0].executionId
    // ) {
    //   showWorkflowProgress({
    //     processName: childActivityInstances[0].activityName,
    //     processReference: childActivityInstances[0].executionId,
    //     hideWhenEnded: true,
    //   });
    // }
  };

  useDeepCompareEffect(() => {
    if (isFinished) {
      onFinished();
      return;
    }

    if (activityInstances) {
      const currentExecutionIds = getExecutionIds(activityInstances);
      const lastExecutionIds = getExecutionIds(lastActivities);

      // set the current activities as "last activities" to compare in the next loop
      // update it here as the following functions takes time and next setInterval could overtake
      setLastActivities(activityInstances);
      // refresh on init
      if (!lastActivities.length) {
        onRefreshResult();
        return;
      }

      // let tempItems = cloneDeep(items);
      // if the running activities are changed,
      if (!isEqual(lastExecutionIds, currentExecutionIds)) {
        onActiveInstanceChanged(
          lastExecutionIds,
          currentExecutionIds,
          activityInstances,
        );
      }
    }
  }, [activityInstances, isFinished]);

  const renderExecutionItem = (instance, index) => {
    const { activityId, executionId, activityType } = instance;
    const shape = modelerActions.getBpmElementById(activityId);
    const businessObject = getBusinessObject(shape);
    const isTask = activityType === "serviceTask";
    const isBreakPoint = [
      "intermediateSignalCatch",
      "intermediateMessageCatch",
    ].includes(activityType);
    const displayName = businessObject.name || businessObject.id;
    const { workflowPath, processName } = isTask
      ? Bpm.readAttachedWorkflowValuesFromBpmElement(shape)
      : {};
    const serviceId = statuses[executionId]?.serviceId;

    const isRunning = lastActivities.some(({ executionIds }) =>
      executionIds.includes(executionId),
    );
    const executionStatus =
      statuses[executionId]?.executionStatus ??
      (isRunning ? statusTypes.IN_PROGRESS : statusTypes.SUCCEEDED);
    const status =
      isBreakPoint && executionStatus === statusTypes.IN_PROGRESS
        ? statusTypes.PAUSE
        : executionStatus;

    const onContinueBPM = async () => {
      if (activityType === "intermediateMessageCatch") {
        modelerActions.selectElement(activityId);
        return;
      }
      const signalName = businessObject.eventDefinitions[0].signalRef.name;
      const [error, response] = await throwSignal({
        name: signalName,
        executionId,
        token,
      });

      const message = error
        ? error.getMessage(response?.message ?? response.type)
        : t("Signal accepted");
      enqueueSnackbar(message, {
        variant: error ? "error" : "success",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    };

    return (
      <Grid
        container
        className={classes.executionItem}
        key={`execution_item_${index}`}
      >
        <Grid item xs={7} className={classes.executionStatus}>
          <StatusBadge status={status} />
        </Grid>
        <Grid
          item
          xs={5}
          container
          justifyContent="center"
          alignContent="flex-end"
        >
          {isTask && (
            <Typography
              variant="h3"
              className={commonClasses.commonLink}
              onClick={() =>
                showWorkflowProgress({
                  processName: processName.split("/").pop(),
                  processReference: `${EXTERNAL_REFERENCE_PREFIX}${executionId}`,
                })
              }
            >
              {t("Show Tasks")}
            </Typography>
          )}
          {isBreakPoint && canContinueBpm && (
            <Button
              id="BPM_USER_BREAKPOINT_CONTINUE_BPM"
              aria-label="Continue BPM"
              variant={"contained"}
              color={"primary"}
              size={"small"}
              fullWidth
              onClick={onContinueBPM}
              disabled={!isRunning}
              className={classes.continueBpm}
            >
              {t("Continue")}
            </Button>
          )}
        </Grid>
        <div className={classes.workflowAndProcess}>
          <Typography variant="body1">
            {workflowPath ? (
              <Link
                to={`/automation/workflows/${encodeURIComponent(workflowPath)}`}
                variant="body1"
                component={RouterLink}
              >
                {displayName}
              </Link>
            ) : (
              displayName
            )}
          </Typography>
        </div>
        {serviceId && (
          <Grid item xs={12} className={classes.workflowInstance}>
            <Typography
              variant="body2"
              className={commonClasses.commonDescription}
            >
              {t("Instance ID")}: {serviceId}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderExecutionResults = (results) => {
    const startEvents = results.filter(
      (event) => event.activityType === "startEvent",
    );
    const otherEvents = orderBy(
      results.filter((event) => event.activityType !== "startEvent"),
      "startTime",
      "asc",
    );

    return startEvents.concat(otherEvents).map(renderExecutionItem);
  };

  return (
    <Grid
      container
      direction="column"
      className={classnames(classes.container)}
    >
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h5" className={classes.formHeading}>
            {t("Latest Execution Result")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton onClick={onRefreshResult} className={classes.refreshIcon}>
            <Refresh fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      {results.length === 0 || isLoading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={classes.loadingSpinner}
        >
          <CircularProgress />
        </Grid>
      ) : (
        renderExecutionResults(results)
      )}
    </Grid>
  );
};

ExecutionPanel.propTypes = {
  executionProcessInstanceId: PropTypes.string,
  workflowProgress: PropTypes.array,
  modelerActions: PropTypes.object,
};

export default ExecutionPanel;
