import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useApi from "msa2-ui/src/hooks/useApi";
import { getWorkflow } from "msa2-ui/src/api/workflow";

import { workflowStatus } from "msa2-ui/src/Constants";
import Bpm, { OPERATORS, DECISION_TYPES } from "msa2-ui/src/services/Bpm";
import Variable from "msa2-ui/src/services/Variable";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import MsaSelect from "msa2-ui/src/components/msa-select";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    paddingBottom: 5,
  },
  formHeading: {
    marginBottom: 35,
  },
  formField: {
    marginBottom: 35,
    width: "100%",
  },
}));

const SequenceFlowPropertiesPanel = ({
  modelerState,
  modelerActions,
  moddle,
  readOnly,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeElement } = modelerState;
  const allAttachedWorkflowValues = modelerActions.getAllAttachedWorkflowValues();
  const id = activeElement.id;
  const sourceGatewayObject = modelerActions.getBpmElementById(
    activeElement.businessObject?.sourceRef?.id,
  );
  const defaultId = sourceGatewayObject?.businessObject.default?.id;
  const [isDefaultFlow, setIsDefaultFlow] = useState(defaultId === id ?? false);

  // This is to force re-rendering when multiple arrows are clicked back and forth
  useEffect(() => {
    if (defaultId) {
      setIsDefaultFlow(defaultId === id ?? false);
    }
  }, [id, defaultId]);

  const activeSequenceFlow = Bpm.readConditionsFromSequenceFlow(activeElement);
  const selectedWorkflow = allAttachedWorkflowValues.find(
    ({ activityId }) => activityId === activeSequenceFlow.camundaTaskId,
  );

  const [workflowLoading, , workflow] = useApi(
    getWorkflow,
    {
      pathToWorkflowDefinitionFile: decodeURIComponent(
        selectedWorkflow?.workflowPath,
      ),
    },
    !selectedWorkflow?.workflowPath,
  );

  const onChangeCondition = (props) => {
    const { camundaTaskId, field, variable, operator, value } = {
      ...activeSequenceFlow,
      ...props,
    };
    const camundaTaskName = allAttachedWorkflowValues.find(
      (workflow) => workflow.activityId === camundaTaskId,
    )?.displayName;

    const displayName =
      Object.values(DECISION_TYPES).find((type) => type.value === field)
        ?.label ?? "";
    let label;
    switch (field) {
      case DECISION_TYPES.STATUS.value: {
        const statusName =
          workflowStatus.find((status) => status.status === value)?.name ?? "";
        label = `${camundaTaskName}: ${displayName} ${operator ??
          ""} ${statusName}`;
        break;
      }
      case DECISION_TYPES.VARIABLE.value: {
        const variableName =
          workflow?.variables.variable.find(
            ({ name }) => Variable.removePrefix(name) === variable,
          )?.displayName ?? "";
        label = `${camundaTaskName}: ${variableName} ${operator ??
          ""} ${value ?? ""}`;
        break;
      }
      case DECISION_TYPES.COMMENT.value:
      default: {
        label = `${camundaTaskName}: ${displayName} ${operator ?? ""} ${value ??
          ""}`;
      }
    }
    const updatedBpmElement = Bpm.writeConditionsToSequenceFlow({
      id: activeSequenceFlow.id,
      camundaTaskId,
      field,
      variable,
      operator,
      value,
      label,
      moddle,
    });
    modelerActions.replaceActiveElement(updatedBpmElement);
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Typography variant="h5" className={classes.formHeading}>
        {t("Conditions")}
      </Typography>

      <FormControl className={classes.formField}>
        <FormControlLabel
          id="BPM_SEQUENCE_FLOW_DEFAULT_FLOW_CHECKBOX"
          control={
            <Checkbox
              checked={isDefaultFlow}
              onChange={({ target: { checked } }) => {
                modelerActions.updateDefaultSequence(id, !checked);
                setIsDefaultFlow(checked);
              }}
              disabled={readOnly}
            />
          }
          label={t("Default Flow")}
        />
      </FormControl>
      {!isDefaultFlow && (
        <>
          <BasicSelect
            id="BPM_SEQUENCE_FLOW_WORKFLOW"
            label={t("Workflow")}
            variant="outlined"
            className={classes.formField}
            name={t("Workflow")}
            value={activeSequenceFlow.camundaTaskId}
            onChange={({ target: { value } }) => {
              onChangeCondition({ camundaTaskId: value, variable: undefined });
            }}
            disabled={readOnly}
          >
            {allAttachedWorkflowValues
              .filter(({ activityId }) =>
                // Show only if BPM task is already triggered (means it is placed before this element)
                modelerActions.isConnectingToCurrentElement(
                  activityId,
                  sourceGatewayObject.id,
                ),
              )
              .map(({ activityId, displayName }) => (
                <MenuItem value={activityId} key={activityId}>
                  {displayName}
                </MenuItem>
              ))}
          </BasicSelect>

          <BasicSelect
            id="BPM_SEQUENCE_FLOW_FIELD"
            label={t("Field")}
            variant="outlined"
            className={classes.formField}
            name={t("Field")}
            value={activeSequenceFlow.field}
            onChange={({ target: { value } }) => {
              onChangeCondition({
                field: value,
                variable: undefined,
                value: "",
              });
            }}
            disabled={readOnly}
          >
            {Object.values(DECISION_TYPES)
              .filter(({ disabled }) => !disabled)
              .map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
          </BasicSelect>

          {activeSequenceFlow.camundaTaskId &&
            activeSequenceFlow.field === DECISION_TYPES.VARIABLE.value && (
              <MsaSelect
                id="BPM_SEQUENCE_FLOW_VARIABLE"
                label={t("Variable")}
                variant="outlined"
                className={classes.formField}
                name={t("Variable")}
                onChange={(variable) => {
                  onChangeCondition({
                    variable: Variable.removePrefix(variable?.name),
                    value: "",
                  });
                }}
                disabled={readOnly}
                placeholder={t("Select Variable...")}
                getOptionLabel={(option) => option.displayName}
                value={workflow?.variables.variable.find(
                  ({ name }) =>
                    Variable.removePrefix(name) === activeSequenceFlow.variable,
                )}
                isLoading={workflowLoading}
                options={workflow?.variables.variable.filter(
                  (variable) =>
                    !Variable.getTableValData(variable.name)?.isTable,
                )}
              />
            )}
          <BasicSelect
            id="BPM_SEQUENCE_FLOW_OPERATOR"
            label={t("Operator")}
            variant="outlined"
            className={classes.formField}
            name={t("Operator")}
            value={activeSequenceFlow.operator}
            onChange={({ target: { value } }) => {
              onChangeCondition({ operator: value });
            }}
            disabled={readOnly}
          >
            {Object.values(OPERATORS).map(({ value, label }, i) => (
              <MenuItem value={value} key={i}>
                {label}
              </MenuItem>
            ))}
          </BasicSelect>
          {activeSequenceFlow.field === DECISION_TYPES.STATUS.value && (
            <BasicSelect
              id="BPM_SEQUENCE_FLOW_STATUS"
              label={t("Status")}
              variant="outlined"
              className={classes.formField}
              name={t("Status")}
              value={activeSequenceFlow.value}
              onChange={({ target: { value } }) => {
                onChangeCondition({ value });
              }}
              disabled={readOnly}
            >
              {workflowStatus
                .filter((status) => status.isResult)
                .map(({ name, status }) => (
                  <MenuItem value={status} key={status}>
                    {name}
                  </MenuItem>
                ))}
            </BasicSelect>
          )}
          {activeSequenceFlow.field === DECISION_TYPES.COMMENT.value && (
            <TextField
              id="BPM_SEQUENCE_FLOW_VALUE"
              variant={"outlined"}
              label={t("Value")}
              className={classes.formField}
              value={activeSequenceFlow.value}
              onChange={({ target: { value } }) => {
                onChangeCondition({ value });
              }}
              disabled={readOnly}
            />
          )}
          {activeSequenceFlow.field === DECISION_TYPES.VARIABLE.value &&
            activeSequenceFlow.variable &&
            activeSequenceFlow.operator &&
            (() => {
              const variable = workflow?.variables.variable.find(
                ({ name }) =>
                  Variable.removePrefix(name) === activeSequenceFlow.variable,
              );
              const id = Variable.removePrefix(variable?.name);
              return !variable ? (
                <TextField
                  id="BPM_SEQUENCE_FLOW_VALUE_DISABLED"
                  variant={"outlined"}
                  label={t("Value")}
                  className={classes.formField}
                  value={activeSequenceFlow.value}
                  disabled
                />
              ) : (
                <ExecutionVariableField
                  id={id}
                  variable={variable}
                  data={{ [id]: activeSequenceFlow.value }}
                  isEditing
                  handleOnChange={(value) => {
                    onChangeCondition({ value });
                  }}
                  showLabel
                />
              );
            })()}
        </>
      )}
    </Grid>
  );
};

SequenceFlowPropertiesPanel.propTypes = {
  modelerState: PropTypes.object.isRequired,
  modelerActions: PropTypes.object.isRequired,
  moddle: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default SequenceFlowPropertiesPanel;
