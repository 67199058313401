import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import usePopover from "msa2-ui/src/hooks/usePopover";
import {
  initialState,
  getSelectedWorkflow,
  selectWorkflow,
} from "msa2-ui/src/store/designations";

import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

import { makeStyles } from "@material-ui/core";
import {
  Button,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import classnames from "classnames";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { DeleteOutlined, StopOutlined } from "@material-ui/icons";
import {
  deleteServiceId,
  attemptToStopWorkflow,
} from "msa2-ui/src/api/workflow";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";

import VariableViewTableCells from "msa2-ui/src/components/variables/VariableViewTableCells";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";
import { instanceTabs } from "./constants";
import Process from "msa2-ui/src/services/Process";

const useStyles = makeStyles(({ palette, typography, colors }) => ({
  tableIconsMinWidth: {
    width: 120,
  },
  moreActions: {
    fontSize: 13,
    letterSpacing: 0.3,
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
    cursor: "pointer",
    margin: 4,
  },
  moreActionsDisabled: {
    opacity: 0.6,
    color: palette.text.disabled,
  },
  deleteIconButton: {
    padding: 10,
  },
  instanceDeleteIcon: {
    width: 20,
    height: 20,
  },
  arrowIcon: {
    color: palette.secondary.main,
  },
  disabledIconButton: {
    opacity: 0.6,
  },
  selectedRow: {
    backgroundColor: "rgba(128, 162, 217, 0.2)",
    outline: colors.blueDark1 + " solid 2px",
  },
}));

const VariableViewTableBody = ({
  isActionButtonsDisabled,
  processExecutionDisabled,
  variables,
  workflowPath,
  id,
  displayName,
  instance,
  processes = [],
  showWorkflowDrawer,
  reloadProcessInstances,
  hideInstanceName,
}) => {
  const { t } = useTranslation("userManagement");
  const [hovered, setHovered] = useState(false);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [showStopDialog, StopDialog] = useDialog();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();
  const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const canTriggerUpdateProcess = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "instance.update"),
  );
  const canTriggerDeleteProcess = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "instance.delete"),
  );

  const [actionPopoverIsShown, showActionPopover, ActionPopover] = usePopover();
  const dispatch = useDispatch();
  const selectedWorkflow = useSelector(getSelectedWorkflow);
  const selected = selectedWorkflow?.id === id;
  const handleOnClick = () => {
    if (selected) {
      dispatch(selectWorkflow(initialState.selectedWorkflow));
    } else {
      dispatch(selectWorkflow({ id, uri: workflowPath }));
    }
  };
  const handleDeletion = async () => {
    const [error] = await deleteServiceId({ token, ubiqubeId, serviceId: id });
    let variant = "success";
    let message = t("Instance has been permanently deleted");
    if (error) {
      variant = "error";
      message = error.getMessage(
        t("Unable to permanently delete this instance"),
      );
    }
    reloadProcessInstances();
    enqueueSnackbar(message, { variant });
  };
  const handleAttemptToStop = async () => {
    const [error] = await attemptToStopWorkflow({
      token,
      process_id: instance.variables.PROCESSINSTANCEID,
    });
    let variant = "success";
    let message = t("Stop Successful");
    if (error) {
      variant = "error";
      message = error.getMessage(t("Attempt to stop failed"));
    }
    enqueueSnackbar(message, { variant });
  };
  return (
    <TableRow
      id={`WORKFLOW_VARIABLE_VIEW_TABLE_ROW_${id}`}
      classes={{ root: commonClasses.commonTableRow }}
      className={classnames(commonClasses.commonTableRowHighlight, {
        [classes.selectedRow]: selected,
      })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!hideInstanceName && (
        <TableCell component="th" scope="row" padding="none">
          <Grid
            id={`WORKFLOW_VARIABLE_VIEW_CELL${id}`}
            className={commonClasses.commonTableCellDefault}
            container
            onClick={handleOnClick}
          >
            <Typography
              variant="h4"
              className={commonClasses.commonTablePrimary}
            >
              {displayName}
            </Typography>
          </Grid>
        </TableCell>
      )}

      <VariableViewTableCells
        variables={variables}
        data={instance.variables}
        onClick={handleOnClick}
        components={{ MSAVariable: ExecutionVariableField }}
      />

      <TableCell
        align="center"
        padding="none"
        classes={{ root: classes.tableIconsMinWidth }}
      >
        <Button
          id={`WORKFLOW_VARIABLE_VIEW_ACTIONS_${id}`}
          disabled={isActionButtonsDisabled || processExecutionDisabled}
          onClick={(event) => {
            showActionPopover(event.currentTarget);
          }}
        >
          <Typography
            variant="body1"
            className={classnames(classes.moreActions, {
              [classes.moreActionsDisabled]:
                isActionButtonsDisabled || processExecutionDisabled,
            })}
          >
            {t("Actions")}
          </Typography>
          {actionPopoverIsShown ? (
            <KeyboardArrowUp className={classes.arrowIcon} />
          ) : (
            <KeyboardArrowDown
              className={classnames(classes.arrowIcon, {
                [classes.disabledIconButton]:
                  isActionButtonsDisabled || processExecutionDisabled,
              })}
            />
          )}
        </Button>
        <ActionPopover
          id={`WORKFLOW_VARIABLE_VIEW_ACTIONS_POPOVER_${id}`}
          options={processes
            .filter(
              (processObject) =>
                // Update Process and user have a permission to execute Update Process
                (canTriggerUpdateProcess &&
                  Process.isUpdate(processObject.type)) ||
                // Delete Process and user have a permission to execute Delete Process
                (canTriggerDeleteProcess &&
                  Process.isDelete(processObject.type)),
            )
            .map((processObject, i) => {
              const TypeIcon = Process.getIcon(processObject.type);
              return {
                id: i,
                label: processObject.displayName,
                name: processObject.name,
                icon: TypeIcon,
              };
            })}
          onSelect={(obj) => {
            if (obj) {
              showWorkflowDrawer({
                serviceId: id,
                displayName,
                processName: obj.name,
              });
            }
          }}
          notFoundMessage={t("No processes found")}
        />
      </TableCell>
      <TableCell
        align="center"
        padding="none"
        style={{ minWidth: (instanceTabs.length + 1) * 46 }}
      >
        {hovered &&
          instanceTabs.map((func, i) => (
            <Tooltip
              key={i}
              title={isActionButtonsDisabled ? "" : `${func.name}`}
            >
              <span>
                <IconButton
                  disabled={isActionButtonsDisabled}
                  className={classnames({
                    [classes.disabledIconButton]: isActionButtonsDisabled,
                  })}
                  id={`WORKFLOW_VARIABLE_VIEW_BTN_FUNCTION_${id}_${func.id}`}
                  aria-label={func.name}
                  key={func.id}
                  onClick={() => {
                    showWorkflowDrawer({ serviceId: id, displayName, tab: i });
                  }}
                >
                  <func.icon
                    color={isActionButtonsDisabled ? "disabled" : "primary"}
                  />
                </IconButton>
              </span>
            </Tooltip>
          ))}
        {hovered && (
          <Tooltip title={t("Stop")}>
            <span>
              <IconButton
                disabled={!processExecutionDisabled}
                className={classnames({
                  [classes.disabledIconButton]: !processExecutionDisabled,
                })}
                id={`WORKFLOW_VARIABLE_VIEW_BTN_STOP_FUNCTION_${id}`}
                onClick={showStopDialog}
              >
                <StopOutlined
                  color={!processExecutionDisabled ? "disabled" : "primary"}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {hovered && (
          <DelegationProfiles
            type={delegationProfileTypes.WORKFLOWS}
            action="instance.hardDelete"
          >
            <Tooltip
              title={isActionButtonsDisabled ? "" : t("Delete Instance")}
            >
              <span>
                <IconButton
                  disabled={isActionButtonsDisabled || processExecutionDisabled}
                  id={`AUTOMATION_DELETE_INSTANCE_BTN_FUNCTION_${id}`}
                  onClick={showDeleteDialog}
                  className={classnames(classes.deleteIconButton, {
                    [classes.disabledIconButton]:
                      isActionButtonsDisabled || processExecutionDisabled,
                  })}
                >
                  <DeleteOutlined
                    className={classes.instanceDeleteIcon}
                    color={
                      isActionButtonsDisabled || processExecutionDisabled
                        ? "disabled"
                        : "error"
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </DelegationProfiles>
        )}
      </TableCell>
      <DeleteDialog
        title={t("Delete x?", { x: t("Instance") })}
        content={t(
          "Are you sure you want to permanently delete  this instance ?",
        )}
        onExec={handleDeletion}
      />
      <StopDialog
        title={t("Stop x?", { x: t("Instance") })}
        content={t("Are you sure you want to stop running this instance ?")}
        onExec={handleAttemptToStop}
      />
    </TableRow>
  );
};

VariableViewTableBody.propTypes = {
  isActionButtonsDisabled: PropTypes.bool,
  processExecutionDisabled: PropTypes.bool,
  variables: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  instance: PropTypes.object.isRequired,
  processes: PropTypes.array,
  reloadProcessInstances: PropTypes.func,
  hideInstanceName: PropTypes.bool,
};

export default VariableViewTableBody;
