import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import omit from "lodash/omit";
import useDialog from "msa2-ui/src/hooks/useDialog";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";

import classnames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { getActiveField, getFormValues } from "msa2-ui/src/store/form";
import {
  getAvailableSubtenants,
  getAvailableTenants,
  getModelsById,
  getManufacturersById,
  fetchManagedEntities,
  getCategoriesWithIds,
} from "msa2-ui/src/store/designations";

import { Divider, Grid, Paper, MenuItem } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import reduxForm from "redux-form/lib/reduxForm";
import SubmissionError from "redux-form/lib/SubmissionError"; // ES6
import { change } from "redux-form";
import flow from "lodash/flow";

import ManagedEntityToolbar from "../ManagedEntityToolbar";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import Modal from "msa2-ui/src/components/modal/Modal";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import {
  sections,
  fields,
  validate,
  managementSection,
  getAdvancedSection,
  renderDynamicFields,
  useSetCurrentSection,
  useFocusFirstFieldInSection,
  useReturnToPreviousPage,
} from "msa2-ui/src/components/formSection/FormCommon";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";

import useApi from "msa2-ui/src/hooks/useApi";
import {
  getManagedEntity,
  getManagedEntityData,
  getManagedEntityFields,
  updateManagedEntity,
} from "msa2-ui/src/api/managedEntity";

import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";

import { natures } from "msa2-ui/src/Constants";
import DeviceAdapterSelector from "msa2-ui/src/components/DeviceAdapterSelector";
import { makeStyles } from "@material-ui/core";
import { getSecretKeyValue } from "msa2-ui/src/store/designations";
import Utility from "../Functions";
import isEmpty from "lodash/isEmpty";

const form = "ManagedEntityEdit";

const useStyles = makeStyles((theme) => ({
  category: {
    padding: 16,
    width: "100%",
  },
  vendor: {
    padding: 16,
    width: "100%",
  },
  model: {
    padding: 16,
    width: "100%",
  },
}));

const ManagedEntityEdit = ({
  destroy,
  match,
  handleSubmit,
  history,
  t,
  initialize,
  initialized,
}) => {
  const dispatch = useDispatch();

  const managedEntityId = get(match, "params.assetId");
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const subtenants = useSelector(getAvailableSubtenants);
  const tenants = useSelector(getAvailableTenants);
  const activeField = useSelector(getActiveField(form));
  const modelsById = useSelector(getModelsById);
  const manufacturersById = useSelector(getManufacturersById);
  const categoriesWithIds = useSelector(getCategoriesWithIds);

  const category = useSelector(getFormValues(form, fields.category));
  const vendor = useSelector(getFormValues(form, fields.vendor));
  const model = useSelector(getFormValues(form, fields.model));

  const [submitting, setSubmitting] = useState(false);
  const [formSubmission, setFormSubmission] = useState(null);
  const [section, setSection] = useState(sections.TENANT_AND_SUBTENANT);
  const secretKey = useSelector(getSecretKeyValue);

  const formValues = useSelector(getFormValues(form));
  const dependencies = [{ name: "reporting", dependsOn: "logMoreEnabled" }];

  if (formValues) {
    dependencies.forEach((dependency) => {
      const { name: field, dependsOn: masterField } = dependency;

      if (!formValues[masterField] && formValues[field]) {
        dispatch(change(form, field, false));
      }
    });
  }

  const [, , meResponse = {}] = useApi(getManagedEntity, {
    managedEntityId,
    transforms: [
      (response) => {
        return {
          ...response,
          password: !isEmpty(response?.password)
            ? Utility.getDecryptedPassword(
                response?.password,
                secretKey[0].value,
              )
            : "",
          passwordAdmin: !isEmpty(response?.passwordAdmin)
            ? Utility.getDecryptedPassword(
                response?.passwordAdmin,
                secretKey[0].value,
              )
            : "",
        };
      },
    ],
  });

  const [, , meDataResponse] = useApi(getManagedEntityData, {
    managedEntityId,
  });

  const [fieldsLoading, , fieldsResponse] = useApi(
    getManagedEntityFields,
    {
      manufacturerId: meResponse.manufacturerId,
      modelId: meResponse.modelId,
    },
    !(meResponse.manufacturerId && meResponse.modelId),
  );

  const [, , updateResponse] = useApi(
    updateManagedEntity,
    {
      managedEntityId,
      body: formSubmission,
    },
    !(submitting && formSubmission),
  );

  useDeepCompareEffect(() => {
    const { manufacturerId, modelId } = meResponse;
    if (
      !initialized &&
      manufacturerId &&
      modelId &&
      meDataResponse &&
      manufacturersById
    ) {
      const {
        deviceNature,
        prefix: selectedTenant,
        abonneId: selectedSubtenant,
      } = meDataResponse;

      const currentCategoryKey =
        manufacturersById?.[manufacturerId]?.categories[0];

      initialize({
        [fields.tenant]: tenants.find((t) => t.value === selectedTenant).label,
        [fields.subtenant]: subtenants.find((c) => c.id === selectedSubtenant)
          ?.label,
        ...meResponse,
        /* [fields.password]: Utility.getDecryptedPassword(
          meResponse?.password,
          secretKey[0].value,
        ),
        [fields.passwordAdmin]: Utility.getDecryptedPassword(
          meResponse?.passwordAdmin,
          secretKey[0].value,
        ), */
        // The nature value doesn't come back from MEResponse so
        // we need to grab it from here
        [fields.nature]: deviceNature,
        [fields.model]: modelsById?.[modelId],
        [fields.vendor]: manufacturersById?.[manufacturerId],
        [fields.category]: categoriesWithIds[currentCategoryKey],
      });
    }
  }, [
    subtenants,
    initialize,
    initialized,
    manufacturersById,
    meDataResponse,
    meResponse,
    modelsById,
    t,
    tenants,
  ]);

  const returnToPreviousPage = useReturnToPreviousPage(
    history,
    match.url,
    submitting,
  );

  useEffect(() => {
    if (updateResponse) {
      destroy();
      setSubmitting(false);
      returnToPreviousPage({ meUpdated: updateResponse.name });
      // update ME list in designation
      dispatch(fetchManagedEntities());
    }
  }, [
    destroy,
    dispatch,
    formSubmission,
    history,
    managedEntityId,
    returnToPreviousPage,
    submitting,
    updateResponse,
  ]);

  const submitForm = (values) => {
    const errors = validate(values);
    if (Object.values(errors).length) {
      throw new SubmissionError(errors);
    }
    setSubmitting(true);
    setFormSubmission(
      omit(values, [
        fields.tenant,
        fields.subtenant,
        fields.category,
        fields.vendor,
        fields.model,
      ]),
    );
  };

  const advancedSection = getAdvancedSection(fieldsResponse);

  useSetCurrentSection(setSection, activeField, advancedSection);

  const [firstFields, handleSectionButtonClick] = useFocusFirstFieldInSection(
    setSection,
  );

  const [showDiscardDialog, DiscardDialog] = useDialog();

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={() => {
          returnToPreviousPage();
          destroy();
        }}
      />
      <Modal onClose={() => returnToPreviousPage({ meDraftSaved: true })}>
        <ManagedEntityToolbar
          title={t("Edit Managed Entity")}
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Save Managed Entity")}
          discardButtonLabel={t("Discard Changes")}
          disabled={submitting}
          onSave={handleSubmit(submitForm)}
          onDiscard={showDiscardDialog}
          onClose={() => returnToPreviousPage({ meDraftSaved: true })}
        />
        <ModalContent>
          <Grid item xs={3} md={2}>
            <Sidebar>
              <Sidebar.ListItem
                title={t("Tenant and Subtenant")}
                id="ME_CREATE_SIDEBAR_TENANT_SUBTENANT"
                selected={section === sections.TENANT_AND_SUBTENANT}
                section={sections.TENANT_AND_SUBTENANT}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Basic Information")}
                id="ME_CREATE_SIDEBAR_BASIC_INFORMATION"
                selected={section === sections.BASIC_INFORMATION}
                section={sections.BASIC_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Administrative Information")}
                id="ME_CREATE_SIDEBAR_ADMINISTRATIVE_INFORMATION"
                selected={section === sections.ADMINISTRATIVE_INFORMATION}
                section={sections.ADMINISTRATIVE_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Management Information")}
                id="ME_CREATE_SIDEBAR_MANAGEMENT_INFORMATION"
                selected={section === sections.MANAGEMENT_INFORMATION}
                section={sections.MANAGEMENT_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Advanced Information")}
                id="ME_CREATE_SIDEBAR_ADVANCED_INFORMATION"
                selected={section === sections.ADVANCED_INFORMATION}
                section={sections.ADVANCED_INFORMATION}
                onClick={handleSectionButtonClick}
              />
            </Sidebar>
          </Grid>
          <ScrollingContainer>
            <Paper className={classnames(commonClasses.commonPaperNoPadding)}>
              <FormSection
                active={section === sections.TENANT_AND_SUBTENANT}
                title={t("Tenant and Subtenant")}
                loading={submitting}
              >
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    label={t(fields.tenant)}
                    type="text"
                    name={fields.tenant}
                    inputProps={{
                      ref: firstFields[sections.TENANT_AND_SUBTENANT],
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    label={t(fields.subtenant)}
                    type="text"
                    name={fields.subtenant}
                    disabled
                  />
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.BASIC_INFORMATION}
                title={t("Basic Information")}
                loading={submitting}
              >
                <DeviceAdapterSelector
                  disabled
                  category={category}
                  vendor={vendor}
                  model={model}
                  categoryProps={{
                    placeholder: t("Filter by Category..."),
                    inputRef: firstFields[sections.BASIC_INFORMATION],
                  }}
                  vendorProps={{ placeholder: t("Filter by Vendor...") }}
                  modelProps={{ placeholder: t("Filter by Model...") }}
                  categoryGridProps={{
                    xs: 6,
                    md: 4,
                    className: classes.category,
                  }}
                  vendorGridProps={{ xs: 6, md: 4, className: classes.vendor }}
                  modelGridProps={{ xs: 6, md: 4, className: classes.model }}
                />
                <Grid item xs={6} md={4}>
                  <FormSelect name={fields.nature} label={t(fields.nature)}>
                    {Object.keys(natures).map((key) => (
                      <MenuItem value={key} key={key}>
                        {t(natures[key])}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    name={fields.externalReference}
                    label={t("External Reference")}
                    type="text"
                  />
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.ADMINISTRATIVE_INFORMATION}
                title={t("Administrative Information")}
                loading={submitting}
              >
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    label={t(fields.name)}
                    type="text"
                    name={fields.name}
                    inputProps={{
                      ref: firstFields[sections.ADMINISTRATIVE_INFORMATION],
                    }}
                    required
                  />
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.MANAGEMENT_INFORMATION}
                title={t("Management Information")}
                loading={submitting || fieldsLoading || !fieldsResponse}
              >
                {fieldsResponse &&
                  renderDynamicFields(
                    managementSection,
                    fieldsResponse,
                    firstFields[sections.MANAGEMENT_INFORMATION],
                  )}
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.ADVANCED_INFORMATION}
                title={t("Advanced Information")}
                loading={submitting || fieldsLoading || !fieldsResponse}
              >
                {fieldsResponse &&
                  renderDynamicFields(
                    advancedSection,
                    fieldsResponse,
                    firstFields[sections.ADVANCED_INFORMATION],
                    formValues,
                    dependencies,
                  )}
              </FormSection>
            </Paper>
          </ScrollingContainer>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

ManagedEntityEdit.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default flow(
  withRouter,
  withTranslation(),
  reduxForm({ form, validate }),
)(ManagedEntityEdit);
