import React from "react";
import PropTypes from "prop-types";
import Field from "redux-form/lib/Field";

/**
 * The CodeMirror editor has an annoying quirk where the data does not get populated in the
 * editor unless the editor itself is rendered. Therefore if a section containing an editor is
 * hidden it leads to strange behaviour. The editor only loads its content once clicked. If we only
 * load the editor once the section is visible, then the issue is avoided.
 *
 * @param {Boolean} visible - Whether the section container the editor is currently visible
 * @param {Object} ...rest - Whatever additional props are passed in are forwarded to the Editor Field
 */
const VisibleField = ({ visible, ...rest }) => {
  return visible && <Field {...rest} />;
};

VisibleField.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default VisibleField;
