import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import { Grid, Button } from "@material-ui/core";
import {
  Add,
  Edit,
  HighlightOff,
  ControlPointDuplicate,
  Input,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const commandButtonsHeight = 50;
const useStyles = makeStyles((theme) => ({
  commandButton: {
    fontSize: "1em",
    padding: "3px 10px 3px 10px",
    marginRight: 5,
  },
  commandButtonsContainer: {
    minHeight: commandButtonsHeight,
    padding: "12px 0px 10px 16px ",
  },
}));

const OrderStackButtons = ({
  commands,
  selectedRows = [],
  onClickAddRow,
  onClickEdit,
  onClickRemove,
  onClickDuplicate,
  onClickRead,
  onClickList,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const isRowSelected = Boolean(selectedRows.length);

  return (
    <Grid container className={classes.commandButtonsContainer}>
      {commands.CREATE && (
        <DelegationProfiles
          type={delegationProfileTypes.MANAGED_ENTITIES}
          action="obmf.create"
        >
          <Grid item>
            <Button
              id="ORDER_STACK_BUTTON_ADD"
              variant="contained"
              color="primary"
              className={classNames([
                commonClasses.commonBtnPrimary,
                classes.commandButton,
              ])}
              onClick={onClickAddRow}
            >
              <Add className={commonClasses.commonBtnIcon} />
              {t("Add Row")}
            </Button>
          </Grid>
        </DelegationProfiles>
      )}
      {commands.UPDATE && isRowSelected && (
        <DelegationProfiles
          type={delegationProfileTypes.MANAGED_ENTITIES}
          action="obmf.update"
        >
          <Grid item>
            <Button
              id="ORDER_STACK_BUTTON_EDIT"
              variant="contained"
              color="primary"
              aria-label={"Edit Button"}
              className={classNames([
                commonClasses.commonBtnPrimary,
                classes.commandButton,
              ])}
              onClick={onClickEdit}
            >
              <Edit className={commonClasses.commonBtnIcon} />
              {t("Edit")}
            </Button>
          </Grid>
        </DelegationProfiles>
      )}
      {commands.DELETE && isRowSelected && (
        <DelegationProfiles
          type={delegationProfileTypes.MANAGED_ENTITIES}
          action="obmf.delete"
        >
          <Grid item>
            <Button
              id="ORDER_STACK_BUTTON_REMOVE"
              variant="contained"
              color="primary"
              aria-label={"Remove Button"}
              className={classNames([
                commonClasses.commonBtnPrimary,
                classes.commandButton,
              ])}
              onClick={onClickRemove}
            >
              <HighlightOff className={commonClasses.commonBtnIcon} />
              {t("Remove")}
            </Button>
          </Grid>
        </DelegationProfiles>
      )}
      {commands.CREATE && selectedRows.length === 1 && (
        <DelegationProfiles
          type={delegationProfileTypes.MANAGED_ENTITIES}
          action="obmf.create"
        >
          <Grid item>
            <Button
              id="ORDER_STACK_BUTTON_DUPLICATE"
              variant="contained"
              color="primary"
              aria-label={"Duplicate Button"}
              className={classNames([
                commonClasses.commonBtnPrimary,
                classes.commandButton,
              ])}
              onClick={onClickDuplicate}
            >
              <ControlPointDuplicate className={commonClasses.commonBtnIcon} />
              {t("Duplicate")}
            </Button>
          </Grid>
        </DelegationProfiles>
      )}
      {commands.READ && selectedRows.length === 1 && (
        <Grid item>
          <Button
            id="ORDER_STACK_BUTTON_READ"
            variant="contained"
            color="primary"
            aria-label={"Read Button"}
            className={classNames([
              commonClasses.commonBtnPrimary,
              classes.commandButton,
            ])}
            onClick={onClickRead}
          >
            <Input className={commonClasses.commonBtnIcon} />
            {t("Export")}
          </Button>
        </Grid>
      )}
      {/* uncomment when we need list feature
      {commands.LIST && Boolean(selectedRows.length) && (
        <Grid item>
          <Button
            id="ORDER_STACK_BUTTON_LIST"
            variant="contained"
            color="primary"
            aria-label={"List Button"}
            className={classNames([
              commonClasses.commonBtnPrimary,
              classes.commandButton,
            ])}
            onClick={onClickList}
          >
            <List className={commonClasses.commonBtnIcon} />
            {t("List")}
          </Button>
        </Grid>
      )}
       */}
    </Grid>
  );
};

OrderStackButtons.propTypes = {
  commands: PropTypes.object,
  selectedRows: PropTypes.array,
  onClickAddRow: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired,
};

export default OrderStackButtons;
