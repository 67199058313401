import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import NumberFormat from "react-number-format";

import { Link } from "react-router-dom";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { iconMapper } from "msa2-ui/src/utils/iconMapping";

import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import classNames from "classnames";

const useStyles = makeStyles(({ darkMode, palette, typography, colors }) => ({
  boxWrapper: {
    background: palette.background.default,
  },
  boxInner: {
    height: "100%",
    background: palette.background.paper,
    boxShadow: darkMode ? `none` : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
    borderRadius: 8,
  },
  boxHeader: {
    backgroundImage: darkMode
      ? "linear-gradient(to bottom, #0A3255, #000)"
      : "linear-gradient(to bottom, #46597e, #2a3752 99%)",
    padding: 24,
    borderRadius: "8px 8px 0 0",
  },
  boxInnerContent: {
    padding: 16,
    paddingBottom: 0,
  },
  boxInnerHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  listItem: {
    borderTop: `1px solid ${darkMode ? palette.text.hint : "#E6EAEE"}`,
    padding: 16,
    "&:first-child": {
      border: 0,
    },
  },
  avatar: {
    background: darkMode ? palette.text.secondary : "#E6EAEE",
    marginRight: 20,
  },
  iconColor: {
    color: "#96A1B5",
  },
  listMainText: {
    fontSize: "1rem",
    fontWeight: typography.fontWeightMedium,
    color: palette.text.primary,
    letterSpacing: 0.5,
  },
  listSubText: {
    fontSize: "0.75rem",
    fontWeight: typography.fontWeightLight,
    letterSpacing: 0.3,
  },
  boxHeading: {
    color: "#fff",
    lineHeight: "1.75rem",
    paddingTop: 8,
  },
  boxSubHeading: {
    display: "block",
    fontSize: "0.8125rem",
    fontWeight: "normal",
  },
  boxInnerTitle: {
    alignSelf: "flex-start",
    flex: 1,
  },
  listBlock: {
    width: "calc(100% + 32px)",
    marginLeft: -16,
  },
  headerWrapper: {
    display: "flex",
  },
  deviceCount: {
    color: darkMode ? palette.primary.main : palette.text.secondary,
    fontWeight: typography.fontWeightMedium,
  },
  spacer: {
    fontSize: "0.75rem",
    margin: "0 10px",
    color: "#ccc",
  },
  largeIcon: {
    display: "block",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginRight: 20,
    width: 72,
    height: 72,
  },
  loader: {
    height: 273,
  },
  noResult: {
    margin: "20px 10px",
  },
}));

const DashboardBoxWrapper = ({ header, innerHeader, children }) => {
  const classes = useStyles();
  return (
    <span className={classes.boxWrapper}>
      <div className={classes.boxInner}>
        <header className={classes.boxHeader}>
          <div className={classes.headerWrapper}>{header}</div>
        </header>
        <div className={classes.boxInnerContent}>
          {innerHeader && (
            <div className={classes.boxInnerHeader}>{innerHeader}</div>
          )}
          {children}
        </div>
      </div>
    </span>
  );
};
DashboardBoxWrapper.propTypes = {
  header: PropTypes.object.isRequired,
  innerHeader: PropTypes.object,
};

const DashboardBoxItem = ({
  icon: Icon,
  linkTo,
  primaryText = "",
  secondaryText,
  canEdit = true,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const isInternalLink = linkTo.startsWith("/");
  const id = primaryText.replace(/ /g, "_").toUpperCase();

  const Wrapper = ({ children }) => {
    return canEdit ? (
      <>
        {isInternalLink ? (
          <ListItem className={classes.listItem} disableGutters>
            <Link
              id={`DASHBOARD_DEV_BTN_CREATE_${id}`}
              className={classnames(commonClasses.commonNoTextDecoration)}
              to={linkTo}
            >
              <Grid container>{children}</Grid>
            </Link>
          </ListItem>
        ) : (
          <ListItem
            className={classes.listItem}
            component="a"
            href={linkTo}
            target="_blank"
            disableGutters
          >
            {children}
          </ListItem>
        )}
      </>
    ) : (
      <ListItem className={classes.listItem} disableGutters>
        {children}
      </ListItem>
    );
  };

  return (
    <Wrapper>
      <ListItemAvatar>
        <Avatar classes={{ root: classes.avatar }}>
          <Icon className={classes.iconColor} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        classes={{
          primary: classNames(
            classes.listMainText,
            commonClasses.overflowAnywhere,
          ),
          secondary: classes.listSubText,
        }}
      />
    </Wrapper>
  );
};
DashboardBoxItem.propTypes = {
  icon: PropTypes.object.isRequired,
  linkTo: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
};

const DeveloperDashboardPanel = ({
  layer,
  backgroundImage,
  total,
  loading,
  items,
  canCreate = true,
  canEdit = true,
  createLink,
  seeMoreLink,
  emptyMessage,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const idSuffix = layer.toUpperCase();
  const header = (
    <>
      <span className={classes.largeIcon} style={{ backgroundImage }} />
      <Typography className={classes.boxHeading} variant="h4" noWrap>
        <span className={classes.boxSubHeading}>{t("Explore our")}</span>
        {t("Library", { name: layer })}
      </Typography>
    </>
  );
  const innerHeader = (
    <>
      <Typography variant="h5" className={classes.boxInnerTitle}>
        {layer}{" "}
        {!loading && (
          <NumberFormat
            value={total}
            displayType={"text"}
            thousandSeparator={true}
            className={classes.deviceCount}
          />
        )}
      </Typography>
      {canCreate && (
        <>
          <Link
            id={"DASHBOARD_DEV_BTN_CREATE_" + idSuffix}
            className={classnames(
              commonClasses.commonDescription,
              commonClasses.commonNoTextDecoration,
            )}
            to={createLink}
          >
            {t("+ Create")}
          </Link>
          <span className={classes.spacer}>|</span>
        </>
      )}
      <Link
        id={"DASHBOARD_DEV_BTN_SEEMORE_" + idSuffix}
        className={classnames(
          commonClasses.commonDescription,
          commonClasses.commonNoTextDecoration,
        )}
        to={seeMoreLink}
      >
        {t("See more")}
      </Link>
    </>
  );

  return (
    <DashboardBoxWrapper header={header} innerHeader={innerHeader}>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={classes.loader}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {!items?.length && (
            <Typography
              className={classnames(
                commonClasses.commonDescription,
                classes.noResult,
              )}
            >
              {emptyMessage}
            </Typography>
          )}
          <List className={classes.listBlock}>
            {items
              ?.slice(0, 3)
              .map(({ displayName, timestamp, icon, uri, disable }, i) => {
                const Icon = typeof icon === "object" ? icon : iconMapper(icon);
                const dateString =
                  displayMonthDayYearTimeDate(timestamp) ?? t("n/a");
                return (
                  <DashboardBoxItem
                    key={i}
                    icon={Icon}
                    primaryText={displayName}
                    secondaryText={t("Last Updated") + ": " + dateString}
                    linkTo={uri}
                    canEdit={canEdit && !disable}
                  />
                );
              })}
          </List>
        </>
      )}
    </DashboardBoxWrapper>
  );
};

DeveloperDashboardPanel.propTypes = {
  layer: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      uri: PropTypes.string.isRequired,
    }),
  ),
  createLink: PropTypes.string.isRequired,
  seeMoreLink: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string,
};

export { DashboardBoxWrapper, DashboardBoxItem, DeveloperDashboardPanel };
