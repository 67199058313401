import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useApi from "msa2-ui/src/hooks/useApi";
import { useSelector } from "react-redux";
import EditorAce from "msa2-ui/src/components/EditorAce";

import { useSnackbar } from "notistack";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { ReactComponent as PlusIcon } from "msa2-ui/src/assets/icons/plus.svg";
import { DeleteOutlined as DeleteIcon, Web, Code } from "@material-ui/icons";

import Variable from "msa2-ui/src/services/Variable";
import {
  getSelectedWorkflow,
  getSelectedSubtenant,
} from "msa2-ui/src/store/designations";
import {
  getWorkflow,
  getWorkflowInstanceVariableValues,
  launchProcessInstance,
} from "msa2-ui/src/api/workflow";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import BasicSelect from "msa2-ui/src/components/BasicSelect";

const useStyles = makeStyles(({ darkMode, palette, colors }) => ({
  title: {
    marginTop: 10,
  },
  wrapper: {
    marginTop: 10,
    height: "97%",
  },
  rendererWrapper: {
    marginBottom: 10,
    height: "inherit",
  },
  select: {
    padding: 5,
  },
  plusIcon: {
    marginLeft: 5,
  },
  autoRefresh: {
    marginBottom: 10,
  },
  autoRefreshTime: {
    width: 200,
    marginLeft: 20,
  },
}));
const workflowInstanceOption = ["Selected Instance"];

const RenderData = ({ targetVariable, viewAs, variableValues, frameStyle }) => {
  const { t } = useTranslation();
  const value = variableValues[Variable.removePrefix(targetVariable)];
  if (!value) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        {t("There are no contents to show")}
      </Grid>
    );
  }

  return viewAs === 0 ? (
    <EditorAce value={value} />
  ) : (
    <iframe
      title="Datafile"
      srcDoc={value}
      width="100%"
      height="100%"
      style={{ border: 0, ...frameStyle }}
    />
  );
};

const VariableRenderer = ({
  renderData: initRenderData = [],
  viewAs: initViewAs = 0,
  autoRefresh: initAutoRefresh = 0,
  workflowUri: workflowUriFromProp,
  instanceId: instanceIdFromProp,
  workflowParams,
  hideFunctionalities,
  frameStyle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: instanceIdFromState, uri: workflowUriFromState } = useSelector(
    getSelectedWorkflow,
  );
  const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const ref = useRef(null);
  const [renderData, setRenderData] = useState(initRenderData);
  const [selectedWorkflowInstance, setSelectedWorkflowInstance] = useState(
    workflowInstanceOption[0],
  );
  const [selectedVariable, setSelectedVariable] = useState("");
  const [viewAs, setViewAs] = useState(initViewAs);
  const [autoRefresh, setAutoRefresh] = useState(initAutoRefresh);

  const workflowUri = workflowUriFromProp || workflowUriFromState;
  const instanceId = instanceIdFromProp || instanceIdFromState;
  const [initLoading, error, processExecution] = useApi(
    launchProcessInstance,
    { ubiqubeId, ...workflowParams },
    !workflowParams || (!ubiqubeId && !workflowParams?.ubiqubeId),
  );
  const [loading, , variableValues = {}] = useApi(
    getWorkflowInstanceVariableValues,
    { instanceId },
    !instanceId || initLoading,
    autoRefresh,
  );
  const [definitionLoading, , workflowDefinition = {}] = useApi(
    getWorkflow,
    { pathToWorkflowDefinitionFile: decodeURIComponent(workflowUri) },
    !workflowUri,
  );
  const { variables } = workflowDefinition;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.getMessage(), {
        variant: "error",
        action: (key) => <SnackbarAction id={key} />,
      });
    }
    if (processExecution) {
      enqueueSnackbar(
        `${processExecution.processId.name}: ${processExecution.status.status}`,
        { action: (key) => <SnackbarAction id={key} /> },
      );
    }
  }, [enqueueSnackbar, error, processExecution]);

  useEffect(() => {
    if (loading) ref.current = instanceId;
  }, [instanceId, loading]);

  if (!instanceId) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Typography>{t("Please select a Workflow Instance.")}</Typography>
      </Grid>
    );
  }
  if (initLoading || ref.current !== instanceId || definitionLoading) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      {!hideFunctionalities && (
        <FilterMenu
          handleViewAsChange={(event, value) => setViewAs(value)}
          viewAsIcons={[<Code />, <Web />]}
        />
      )}
      <Grid
        container
        className={classnames({ [classes.wrapper]: renderData.length > 0 })}
      >
        {!hideFunctionalities && (
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            alignContent="center"
            className={classes.autoRefresh}
          >
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ "aria-label": t("Auto Refresh") }}
                    checked={Boolean(autoRefresh)}
                    onChange={() => {
                      autoRefresh === 0
                        ? setAutoRefresh(60000)
                        : setAutoRefresh(0);
                    }}
                  />
                }
                label={t("Auto Refresh")}
              />
            </FormControl>
            <TextField
              label="Auto Refresh Time"
              type="number"
              size="small"
              InputProps={{
                classes: { input: classes.alignRight },
                endAdornment: (
                  <InputAdornment position="end">{t("seconds")}</InputAdornment>
                ),
                inputProps: {
                  "aria-label": t("Auto Refresh Time"),
                },
              }}
              className={classes.autoRefreshTime}
              value={autoRefresh / 1000}
              onChange={({ target: { value } }) => {
                if (value > 0 && value < 5184000) setAutoRefresh(value * 1000);
              }}
              disabled={!autoRefresh}
            />
          </Grid>
        )}
        {variables &&
          renderData.map(({ targetVariable }, index) => {
            const displayName = variables.variable.find(
              (variable) => variable.name === targetVariable,
            )?.displayName;
            return (
              <Fragment key={index}>
                <Grid item xs={12} container className={classes.title}>
                  {(!hideFunctionalities || renderData.length > 1) && (
                    <Grid item xs={10} container alignContent="center">
                      <Typography variant={"h2"}>{displayName}</Typography>
                    </Grid>
                  )}
                  {!hideFunctionalities && (
                    <Grid
                      item
                      xs={2}
                      container
                      justifyContent="flex-end"
                      alignContent="center"
                    >
                      <IconButton
                        id={`WORKFLOW_LINE_GRAPH_DELETE_${index}`}
                        aria-label={t("Delete")}
                        onClick={() => {
                          setRenderData(
                            renderData.filter((e, i) => i !== index),
                          );
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.rendererWrapper}>
                  <RenderData
                    viewAs={viewAs}
                    variableValues={variableValues}
                    targetVariable={targetVariable}
                    frameStyle={frameStyle}
                  />
                  <Divider className={classes.divider} />
                </Grid>
              </Fragment>
            );
          })}
        {!hideFunctionalities && (
          <>
            <Grid
              item
              xs={9}
              container
              justifyContent="flex-start"
              alignContent="center"
            >
              {
                <>
                  <Grid item xs={6} className={classes.select}>
                    <BasicSelect
                      label={t("Workflow Instance")}
                      variant="outlined"
                      name={t("Workflow Instance")}
                      value={selectedWorkflowInstance}
                      onChange={({ target: { value } }) => {
                        setSelectedWorkflowInstance(value);
                      }}
                      disabled
                      fullWidth
                    >
                      {workflowInstanceOption.map((type, i) => (
                        <MenuItem value={type} key={i}>
                          {type}
                        </MenuItem>
                      ))}
                    </BasicSelect>
                  </Grid>
                  <Grid item xs={6} className={classes.select}>
                    <BasicSelect
                      label={t("Variable")}
                      variant="outlined"
                      name={t("Variable")}
                      value={selectedVariable}
                      onChange={({ target: { value } }) => {
                        setSelectedVariable(value);
                      }}
                      fullWidth
                    >
                      {variables?.variable
                        // add select index
                        .filter(
                          (variable) =>
                            !Variable.getTableValData(variable.name).isTable,
                        )
                        .map((variable) => (
                          <MenuItem value={variable.name} key={variable.name}>
                            {variable.displayName}
                          </MenuItem>
                        ))}
                    </BasicSelect>
                  </Grid>
                </>
              }
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignContent="center"
            >
              <Button
                aria-label={t("Add")}
                onClick={() => {
                  setRenderData(
                    renderData.concat({
                      workflowInstance: selectedWorkflowInstance,
                      targetVariable: selectedVariable,
                    }),
                  );
                  setSelectedWorkflowInstance(workflowInstanceOption[0]);
                  setSelectedVariable("");
                }}
                disabled={!selectedVariable}
              >
                {t("Add")}
                <PlusIcon className={classes.plusIcon} />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

VariableRenderer.propTypes = {
  renderData: PropTypes.array,
  viewAs: PropTypes.number,
  autoRefresh: PropTypes.number,
  hideFunctionalities: PropTypes.bool,
  workflowUri: PropTypes.object,
  instanceId: PropTypes.string,
  workflowParams: PropTypes.object,
};

export default VariableRenderer;
