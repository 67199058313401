import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { filter } from "msa2-ui/src/utils/filter";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

import useFilter from "msa2-ui/src/hooks/useFilter";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  getWorkflowInstances,
  getServiceInstances,
} from "msa2-ui/src/api/workflow";

import { WORKFLOW_STATUS, workflowStatus } from "msa2-ui/src/Constants";

import { CircularProgress, Paper } from "@material-ui/core";

import WorkflowInstance from "./WorkflowInstance";
import WorkflowInstanceVariableView from "./WorkflowInstanceVariableView";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import AlertBar from "msa2-ui/src/components/AlertBar";
import { useHistory } from "react-router-dom";
import {
  getTableSortKeySetting,
  getTableSortOrderSetting,
  automationSortKeys,
  getViewMode,
  workflowViewMode,
  getAutoRefreshSetting,
} from "msa2-ui/src/store/settings";

const MAX_PARALLEL_CALLS = 5;
const filterOptions = workflowStatus.map(({ name, status }, index) => ({
  id: index + 1,
  text: name,
  status,
}));

const filterByOptions = [
  {
    id: 0,
    text: "All instances",
  },
  ...filterOptions,
];

const SORT_ORDER = {
  0: "DESC",
  1: "ASC",
};

const useStyles = makeStyles(({ spacing }) => ({
  filterWrapper: {
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
  },
}));

const AutomationDetails = ({
  sectionTabs: SectionTabs,
  workflowDefinition,
  workflowDefinitionError,
  workflowDefinitionLoading,
  reload: reloadDefinition,
  setReload,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { path } = useRouteMatch();
  const { workflowUri } = useParams();
  const history = useHistory();
  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));
  const isLazyMode =
    useSelector(getViewMode("automationDetail")) === workflowViewMode.LAZY.id;

  const [lazyInstances, setLazyInstances] = useState([]);
  const [shouldPollInstance, setShouldPollInstance] = useState(false);

  const sortByOptions = automationSortKeys;
  const sortKeySetting = useSelector(getTableSortKeySetting("automation"));
  const sortOrderSetting = useSelector(getTableSortOrderSetting("automation"));
  const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const ref = useRef(ubiqubeId);

  const [filterState, filterActions] = useFilter({
    tableName: "automation",
    filterByValue: 0,
    searchValue: "",
    sortByValue: sortKeySetting,
    sortOrderValue: sortOrderSetting,
    tpPage: 0,
    total: 0,
  });

  const filterParam = {
    status: filterState.filterByValue
      ? filterByOptions[filterState.filterByValue].status
      : undefined,
    page: filterState.tpPage + 1,
    pageSize: filterState.tpRowsPerPage,
    sort: filterState.sortByValue,
    sortOrder: SORT_ORDER[sortOrderSetting],
  };

  const [loading, error, { instances, count } = {}, meta, reload] = useApi(
    isLazyMode ? getServiceInstances : getWorkflowInstances,
    {
      ubiqubeId,
      workflowPath: decodeURIComponent(workflowUri),
      definedVarFlag: true,
      ...filterParam,
      transforms: isLazyMode
        ? [(instances) => ({ instances })]
        : [
            (workflow) => ({
              instances: workflow.instances,
              count: workflow.allServiceInstancesCount,
            }),
          ],
    },
    !ubiqubeId || !workflowUri,
    shouldPollInstance ? pollingInterval : 0,
  );

  useEffect(() => {
    if (instances) {
      // Logics after fetching instances
      if (isLazyMode) {
        // generate initial instance array for lazy mode
        const initialLazyInstances = instances.reduce(
          (acc, { id }) => ({ ...acc, [id]: lazyInstances[id] }),
          {},
        );
        setLazyInstances(initialLazyInstances);
      } else {
        // set if at least one instance is running to poll
        const isWorkflowRunning = instances.some(
          ({ status }) => status.status === WORKFLOW_STATUS.RUNNING.status,
        );
        if (shouldPollInstance !== isWorkflowRunning) {
          setShouldPollInstance(isWorkflowRunning);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instances]);

  useEffect(() => {
    if (ubiqubeId !== ref.current) {
      history.push("/automation/workflows");
    }
  }, [ubiqubeId, history]);

  useEffect(() => {
    if (reloadDefinition) {
      reload();
      setReload(false);
    }
  }, [reloadDefinition, setReload, reload]);

  const total = isLazyMode ? meta?.allserviceinstancescount : count ?? 0;

  const serviceIdsToLoad =
    instances
      ?.filter(({ id }) => !lazyInstances[id])
      .slice(0, MAX_PARALLEL_CALLS)
      .map(({ id }) => id) ?? [];

  const filteredInstances = filter(
    isLazyMode
      ? instances
          ?.filter(
            ({ id }) =>
              // loaded instances
              Boolean(lazyInstances[id]) ||
              // instances to load (out of the queue for MAX_PARALLEL_CALLS won't be called )
              serviceIdsToLoad.includes(id),
          )
          .map(
            ({ id }) =>
              lazyInstances[id] ?? {
                variables: { SERVICEINSTANCEID: id },
              },
          )
      : instances,
    filterState.searchValue,
    [
      "variables.SERVICEINSTANCEID",
      ["variables", workflowDefinition?.information.displayField].join("."),
      "status.comment",
    ],
  );

  return (
    <Fragment>
      <SectionTabs workflowUri={decodeURI(workflowUri)} count={[path, total]} />
      <Paper
        className={classnames(
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        )}
      >
        <div className={classes.filterWrapper}>
          <FilterMenu
            {...filterState}
            {...filterActions}
            handleSortByChange={
              !filterState.viewAsValue && filterActions.handleSortByValueChange
            }
            handleSortOrder={
              !filterState.viewAsValue && filterActions.toggleSortOrder
            }
            handleSearchByChange={
              !filterState.viewAsValue && filterActions.handleSearchByChange
            }
            filterByOptions={filterByOptions}
            sortByOptions={sortByOptions}
            tpTotal={total}
            tpChangePage={(event, value) => {
              filterActions.tpChangePage(event, value);
              filterActions.resetSearchValue();
            }}
          />
        </div>
        {(loading && !instances) ||
        (workflowDefinitionLoading && !workflowDefinition) ? (
          <div className={commonClasses.commonLoaderWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            {(error || workflowDefinitionError) && (
              <AlertBar
                message={t("Unable to load x", { x: t("Instances") })}
                refreshFnc={() => {
                  if (error) {
                    reload();
                  }
                  if (workflowDefinitionError) {
                    reloadDefinition();
                  }
                }}
                adjust
              />
            )}
            {workflowDefinition && (
              <>
                {filteredInstances.map((instance, i) => (
                  <WorkflowInstance
                    // call component and control visibility inside to avoid unnecessary API calls
                    show={!filterState.viewAsValue}
                    workflowData={workflowDefinition}
                    instanceData={instance}
                    key={`workflow_instance_${i}`}
                    reloadListServiceId={reload}
                    onInstanceLoaded={
                      isLazyMode
                        ? (serviceId, value) => {
                            setLazyInstances({
                              ...lazyInstances,
                              [serviceId]: value,
                            });
                          }
                        : undefined
                    }
                    isLazyMode={isLazyMode}
                  />
                ))}

                {Boolean(filterState.viewAsValue) && (
                  <WorkflowInstanceVariableView
                    workflowData={workflowDefinition}
                    instanceData={filteredInstances}
                    stateForApi={filterParam}
                  />
                )}
              </>
            )}
            {isLazyMode && Boolean(serviceIdsToLoad.length) && (
              <div className={commonClasses.commonLoaderWrapper}>
                <CircularProgress />
              </div>
            )}
          </Fragment>
        )}
      </Paper>
    </Fragment>
  );
};

export default AutomationDetails;
