import { get, post, put, destroy } from "./request";
import set from "lodash/set";
import unset from "lodash/unset";
import flow from "lodash/flow";
import cloneDeep from "lodash/cloneDeep";
import Alarm from "msa2-ui/src/services/Alarm";

const API = process.env.REACT_APP_API_PATH;

/**
 * Spec: https://10.31.1.5/swagger/#/ElasticSearchManager/listAlarmRulesV2
 */
export const getAlarms = ({ token, page, pageSize }) => {
  return get({
    url: `${API}/elastic-search/v2/alarm-rules`,
    token,
    queryParams: {
      page,
      page_size: pageSize,
    },
  });
};

const transformAlarmDataKeysForUi = (response) => {
  return Alarm.makeAlarmDataObject({
    ...response,
    query: {
      terms: response.query?.mustTerms,
      managedEntityIds: response.query?.deviceIds,
      severities: response.query?.severities,
      subtenantIds: response.query?.customerIds,
    },
    rule: {
      count: response.rule?.count?.toString(),
      duration: response.rule?.duration?.toString(),
      roles: response.rule?.roles,
      email: response.rule?.email,
      workflowPath: response.rule?.service_name,
      processName: response.rule?.process_name,
      processVariables: response.rule?.workflow_params,
      cleanUpProcessInstanceAfterExecution: response.rule?.auto_archive,
      alarmSeverity: response.rule?.alarm_severity,
      snmpTrap: response.rule?.snmp_trap,
    },
    alarmClearance: {
      alarm_regex: response.alarmClearance?.alarm_regex,
      event_regex: response.alarmClearance?.event_regex,
      clearing_delay: response.alarmClearance?.clearing_delay,
      clear_comment: response.alarmClearance?.clear_comment,
    },
  });
};
/**
 * Spec: https://10.31.1.5/swagger/#/ElasticSearchManager/loadAlarmV2
 */
export const getAlarm = ({ alarmId, token }) => {
  return get({
    url: `${API}/elastic-search/v2/alarm-rules/${alarmId}`,
    token,
    transforms: [transformAlarmDataKeysForUi],
  });
};

/**
 * @param {number} userId
 * @param {object} alarmData
 * @param {string} token
 *
 * interface AlarmData {
 *   name: string;
 *   query: {
 *     terms: string;
 *     managedEntityIds: number[];
 *     severities: number[];
 *     subtenantIds: number[];
 *   }
 *   rule: {
 *     count: string;
 *     duration: string;
 *     roles: string[];
 *     email: boolean;
 *     workflowPath: string;
 *     processName: string;
 *     processVariables: {
 *       [key: string]: string;
 *     };
 *     cleanUpProcessInstanceAfterExecution: boolean;
 *   }
 * }
 */

const sanitizeAlarmData = (alarmData) => {
  const sanitizedAlarmData = cloneDeep(alarmData);

  const sanitizedName = alarmData.name.replace(/[^a-z0-9_-]/gi, "_");
  set(sanitizedAlarmData, "name", sanitizedName);

  return sanitizedAlarmData;
};

const transformAlarmDataKeysForApi = (alarmData) => {
  if (!alarmData) {
    return {};
  }
  const { query = {}, rule = {} } = alarmData;

  const transformedAlarmData = cloneDeep(alarmData);
  if ("subtenantIds" in query) {
    set(transformedAlarmData, "query.customerIds", query.subtenantIds);
    unset(transformedAlarmData, "query.subtenantIds");
  }
  if ("managedEntityIds" in query) {
    set(transformedAlarmData, "query.deviceIds", query.managedEntityIds);
    unset(transformedAlarmData, "query.managedEntityIds");
  }
  if ("workflowPath" in rule) {
    set(transformedAlarmData, "rule.service_name", rule.workflowPath);
    unset(transformedAlarmData, "rule.workflowPath");
  }
  if ("processName" in rule) {
    set(transformedAlarmData, "rule.process_name", rule.processName);
    unset(transformedAlarmData, "rule.processName");
  }
  if ("processVariables" in rule) {
    set(transformedAlarmData, "rule.workflow_params", rule.processVariables);
    unset(transformedAlarmData, "rule.processVariables");
  }
  if ("cleanUpProcessInstanceAfterExecution" in rule) {
    set(
      transformedAlarmData,
      "rule.auto_archive",
      rule.cleanUpProcessInstanceAfterExecution,
    );
    unset(transformedAlarmData, "rule.cleanUpProcessInstanceAfterExecution");
  }
  if ("alarmSeverity" in rule) {
    set(transformedAlarmData, "rule.alarm_severity", rule.alarmSeverity);
    unset(transformedAlarmData, "rule.alarmSeverity");
  }
  if ("snmpTrap" in rule) {
    set(transformedAlarmData, "rule.snmp_trap", rule.snmpTrap);
    unset(transformedAlarmData, "rule.snmpTrap");
  }

  // For now, hardcode this field when saving an alarm. It dictates which
  // fields will be available on the ElasticSearch logs for this alarm.
  set(transformedAlarmData, "rule.field_params", ["rawlog", "customer_id"]);

  return transformedAlarmData;
};

/**
 * Spec: https://10.31.1.5/swagger/#/ElasticSearchManager/saveAlarm2V2
 */
export const createAlarm = ({ alarmData, token }) => {
  return post({
    url: `${API}/elastic-search/v2/alarm-rules`,
    token,
    body: flow(sanitizeAlarmData, transformAlarmDataKeysForApi)(alarmData),
  });
};
/**
 * Spec: https://10.31.1.5/swagger/#/ElasticSearchManager/updateAlarmV2
 */
export const updateAlarm = ({ alarmId, alarmData, token }) => {
  return put({
    url: `${API}/elastic-search/v2/alarm-rules/${alarmId}`,
    token,
    body: flow(sanitizeAlarmData, transformAlarmDataKeysForApi)(alarmData),
  });
};
/*
 * Spec: https://10.31.1.5/swagger/#/ElasticSearchManager/deleteRuleV2
 */
export const deleteAlarm = ({ alarmId, token }) => {
  return destroy({
    url: `${API}/elastic-search/v2/alarm-rules/${alarmId}`,
    token,
  });
};

const normalizeJSON = (response) => {
  return {
    response: JSON?.parse(response?.rawJSONResult)?.sms_result?.results?.[0]
      ?.disk,
  };
};

/**
 * Spec: https://10.255.5.190/swagger/#/SecEngine/sendSMSCommand
 */
export const attackDetails = ({
  token,
  deviceId,
  msgId,
  transforms = [normalizeJSON],
}) => {
  return post({
    url: `${API}/sms/cmd/GET_LOG_DETAIL/${deviceId}`,
    token,
    queryParams: {
      params: msgId,
    },
    transforms,
  });
};
