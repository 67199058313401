import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formSections } from "./constants";

export const useStyles = makeStyles(({ topbar }) => ({
  sidebarWrapper: {
    maxHeight: `calc(100% - ${topbar.height / 2}px)`,
    overflowY: "auto",
  },
}));

const ModalSidebar = ({ activeSection, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={3} md={2} className={classes.sidebarWrapper}>
      <Sidebar>
        {Object.values(formSections).map((name) => (
          <Sidebar.ListItem
            key={name}
            selected={activeSection === name}
            title={t(name)}
            onClick={() => onClick(name)}
          />
        ))}
      </Sidebar>
    </Grid>
  );
};

ModalSidebar.propTypes = {
  activeSection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ModalSidebar;
