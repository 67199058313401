import React from "react";
import { Divider, Grid } from "@material-ui/core";
import WorkflowHistory from "msa2-ui/src/routes/dashboard/WorkflowHistory";
import BpmHistory from "msa2-ui/src/routes/dashboard/boards/BpmHistory.board";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  ({ darkMode, palette, typography, colors, spacing }) => ({
    divider: {
      height: 5,
      backgroundColor: palette.common.black,
      margin: "0 -4px",
    },
  }),
);

const AutomationBoard = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <BpmHistory />
        </Grid>
        <Divider variant="fullWidth" className={classes.divider} />
        <Grid item>
          <WorkflowHistory />
        </Grid>
      </Grid>
    </>
  );
};

export default AutomationBoard;
