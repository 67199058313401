import React from "react";
import PropTypes from "prop-types";

/**
 * @param {function} folderComponent Set component that you want to render as folder (parents of tree items).
 *                        You can get the number of children by using "countChildren" props.
 * @param {function} itemComponent Component to use for rendering tree view items
 **/
const TreeView = ({ folderComponent, itemComponent, children, ...rest }) => {
  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { folderComponent, itemComponent, ...rest }),
      )}
    </>
  );
};

TreeView.propTypes = {
  folderComponent: PropTypes.func.isRequired,
  itemComponent: PropTypes.func.isRequired,
};

export default TreeView;
