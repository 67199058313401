import LoginAdminImg from "msa2-ui/src/assets/img/login-background-ops.png";
import LoginDevImg from "msa2-ui/src/assets/img/login-background-dev.png";

const loginStyles = ({
  darkMode,
  typography,
  palette,
  colors,
  spacing,
  breakpoints,
}) => ({
  backgroundContainer: {
    background: "#fafafa",
    height: "100%",
    minHeight: "100vh",
  },
  imageContainer: {
    height: "50%",
  },
  paper: {
    padding: 30,
    textAlign: "center",
    fontWeight: typography.fontWeightLight,
  },
  paperTransparent: {
    padding: 30,
    textAlign: "center",
    fontWeight: typography.fontWeightLight,
    background: "transparent",
  },
  loginWrap: {
    textAlign: "center",
    padding: "25px 50px",
    width: 430,
    height: 310,
    margin: "auto",
  },
  footerLogo: {
    margin: 50,
    width: 120,
  },
  logo: {
    margin: "45px auto",
  },
  loginMessage: {
    maxWidth: 430,
  },
  selectWrap: {
    textAlign: "center",
    backgroundColor: "unset",
  },
  tabs: {
    margin: "20px 0",
    textTransform: "sentence",
  },
  tab: {
    background: "#e9eaec",
    color: palette.text.secondary,
    border: "1px solid #E3E3E7",
    textTransform: "capitalize",
    fontSize: "0.9rem",
  },
  leftTab: {
    borderRadius: "26px 0 0 26px",
  },
  rightTab: {
    borderRadius: "0 26px 26px 0",
  },
  tabFonts: {
    fontSize: "19px",
    color: "#4b4b4b",
  },
  tabSelected: {
    background: palette.primary.main,
    backgroundImage: "linear-gradient(90deg, #2397e3, #71d4ff)",
  },
  fontSelected: {
    color: "#fff",
  },
  indicator: {
    backgroundColor: "transparent",
    border: "none",
  },
  imageBoxAdmin: {
    backgroundImage: `url(${LoginAdminImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center -120px",
    backgroundSize: "100%",
  },
  imageBoxDev: {
    backgroundImage: `url(${LoginDevImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center -70px",
    backgroundSize: "100%",
  },
  whiteH1: {
    paddingBottom: "1em",
    color: "#fff",
    textShadow: "0 3px 8px rgba(0, 0, 0, 0.5)",
  },
  whiteH2: {
    color: "#fff",
    textShadow: "0 3px 8px rgba(0, 0, 0, 0.5)",
  },
  alignSelf: {
    alignSelf: "center",
  },
  bootstrapRoot: {
    "label + &": {
      marginTop: spacing(3),
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    background: palette.background.paper,
    border: "1px solid #dee4ec",
    fontSize: 16,
    padding: "14px 8px",
    "&:hover": {
      border: "1px solid" + colors.blueLight,
    },
    "&:focus": {
      border: "1px solid" + colors.blueLight,
      boxShadow: "0px 0px 0px 1px inset" + colors.blueLight,
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
    width: "133%",
    textAlign: "left",
    display: "block",
    color: "#4c4c4c",
  },
  bootstrapFormErrorLabel: {
    fontSize: 18,
    width: "133%",
    textAlign: "left",
    display: "block",
    color: "red",
  },
  forgotPassword: {
    color: "#4c4c4c",
    display: "inline-block",
    position: "absolute",
    textTransform: "capitalize",
    fontSize: 16,
    padding: 0,
    border: 0,
    right: 0,
    top: "-5px",
    "&:hover": {
      background: "transparent",
      border: 0,
    },
  },
  passwordLabel: {
    display: "inline-block",
  },
  passwordToggle: {
    position: "absolute",
    right: 0,
  },
  margin: {
    margin: "10px 0",
  },
  button: {
    margin: "20px 0",
    borderRadius: "26px",
    padding: "10px 30px",
    minWidth: "160px",
    fontSize: "1rem",
    color: "#fff",
    backgroundImage: "linear-gradient(90deg, #2397e3, #71d4ff)",
    textTransform: "capitalize",
  },
  [breakpoints.down("sm")]: {
    loginWrap: {
      width: "100%",
    },
  },
  forgotPasswordButton: {
    fontSize: "0.7rem",
  },
  footer: {
    padding: "10px 5%",
    marginRight: -1,
  },
  footerTitle: {
    color: colors.greyDark1,
  },
  footerDescription: {
    textAlign: "center",
    padding: 15,
  },
  footerButton: {
    backgroundColor: "#eeeef0",
    borderRadius: 10,
  },
  footerButtonText: {
    color: colors.grey,
    padding: "0px 9px",
  },
  footerDivider: {
    height: 150,
  },
});

export default loginStyles;
