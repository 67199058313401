import React, { useState, createContext } from "react";

/**
 * This context is to provide details for alarms screen
 * e.g. => used as wrapper on TriggeredAlarms right now
 */
export const AlarmContext = createContext({
  triggeredAlarmsCount: 0,
});

export const AlarmContextProvider = ({ children }) => {
  const [triggeredAlarmsCount, setTriggeredAlarmsCount] = useState(0);
  const [searchPayload, setSearchPayload] = useState({});
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [summary, setSummary] = useState({});
  return (
    <AlarmContext.Provider
      value={{
        triggeredAlarmsCount,
        setTriggeredAlarmsCount,
        searchPayload,
        setSearchPayload,
        checkedColumns,
        setCheckedColumns,
        summary,
        setSummary,
      }}
    >
      {children}
    </AlarmContext.Provider>
  );
};
