import { PLACEHOLDER_PROCESS_ID_STRING } from "msa2-ui/src/services/Bpm";

export const bpmStarterDiagram =
  '<?xml version="1.0" encoding="UTF-8"?>' +
  '<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
  'xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" ' +
  'xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" ' +
  'xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" ' +
  'targetNamespace="http://bpmn.io/schema/bpmn" ' +
  'id="Definitions_1">' +
  `<bpmn:process id="${PLACEHOLDER_PROCESS_ID_STRING}" isExecutable="true">` +
  '<bpmn:startEvent id="StartEvent_1" name="Start Event"/>' +
  "</bpmn:process>" +
  '<bpmndi:BPMNDiagram id="BPMNDiagram_1">' +
  '<bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="PLACEHOLDER_PROCESS_ID">' +
  '<bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">' +
  '<dc:Bounds height="36.0" width="36.0" x="173.0" y="102.0"/>' +
  "<bpmndi:BPMNLabel>" +
  '<dc:Bounds width="55.0" height="14.0" x="162.5" y="145.0"/>' +
  "</bpmndi:BPMNLabel>" +
  "</bpmndi:BPMNShape>" +
  "</bpmndi:BPMNPlane>" +
  "</bpmndi:BPMNDiagram>" +
  "</bpmn:definitions>";
