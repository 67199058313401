import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import useDialog from "msa2-ui/src/hooks/useDialog";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { commonStyles } from "./commonStyles";

import { ReactComponent as IconEdit } from "msa2-ui/src/assets/icons/edit.svg";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";

const useStyles = makeStyles(commonStyles);

const ParserTreeItemArray = (props) => {
  const {
    labelPrefix,
    label,
    icon: Icon,
    onSave,
    onDelete,
    addButtons,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);
  const [editing, setEdit] = useState(false);
  const [labelValue, setLabelValue] = useState(label);

  const [showDeleteDialog, DeleteDialog] = useDialog();

  return (
    <Grid
      container
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => setHover(true)}
      className={hover ? classes.hover : ""}
    >
      <Grid item xs={6}>
        <Grid
          container
          wrap={"nowrap"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          className={classes.contentWrapper}
          onDoubleClick={() => setEdit(true)}
        >
          {Icon && <Icon />}
          <Typography variant={"body2"} className={classes.array}>
            {labelPrefix}
          </Typography>

          {editing ? (
            <FormControl variant="outlined">
              <OutlinedInput
                value={labelValue}
                onChange={(event) => setLabelValue(event.target.value)}
                classes={{ input: classes.textField }}
              />
            </FormControl>
          ) : (
            <Typography variant={"body2"} className={classes.array}>
              {label}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        container
        justifyContent="flex-end"
        alignContent="center"
      >
        {editing ? (
          <>
            <Button
              id="CONFIGURATION_PARSER_ARRAY_BTN_CANCEL"
              variant="text"
              size="small"
              color="default"
              className={classnames(
                classes.commonBtn,
                classes.commonBtnPrimary,
              )}
              onClick={() => {
                setEdit(false);
                setLabelValue(label);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              id="CONFIGURATION_PARSER_ARRAY_BTN_SAVE"
              variant="contained"
              size="small"
              color="primary"
              className={classnames(
                classes.commonBtn,
                classes.commonBtnPrimary,
              )}
              onClick={() => {
                setEdit(false);
                onSave(labelValue);
              }}
            >
              {t("Save")}
            </Button>
          </>
        ) : (
          hover && (
            <>
              {addButtons && addButtons()}
              <IconButton
                id="CONFIGURATION_PARSER_ARRAY_BTN_EDIT"
                aria-label={t("Edit")}
                onClick={() => setEdit(true)}
              >
                <IconEdit />
              </IconButton>
              {onDelete && (
                <IconButton
                  id="CONFIGURATION_PARSER_ARRAY_BTN_DELETE"
                  aria-label={t("Delete")}
                  onClick={showDeleteDialog}
                >
                  <IconDeleteRed />
                </IconButton>
              )}
            </>
          )
        )}
        <DeleteDialog
          title={t("Confirm Request")}
          content={t("Are you sure you want to delete this folder?")}
          onExec={onDelete}
        />
      </Grid>
    </Grid>
  );
};

ParserTreeItemArray.propTypes = {
  labelPrefix: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  addButtons: PropTypes.func,
};

export default ParserTreeItemArray;
