import React from "react";
import { IntercomProvider } from "react-use-intercom";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const INTERCOM_APP_ID =
  process.env.NODE_ENV === "production"
    ? window.MSA.keys.a5b9d046
    : process.env.REACT_APP_INTERCOM_APP_ID;
const InquiryChatProvider = ({ classes, children }) => {
  const isTrialMSA = FeatureFlag.isEnabled(FeatureFlag.features.trial);
  if (!isTrialMSA || !INTERCOM_APP_ID) {
    return children;
  }

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      {children}
    </IntercomProvider>
  );
};

export default InquiryChatProvider;
