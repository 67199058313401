import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";

import { useRouteMatch, useHistory } from "react-router-dom";
import { returnToPreviousPage } from "msa2-ui/src/utils/urls";

import { getToken } from "msa2-ui/src/store/auth";
import { createSubtenant } from "msa2-ui/src/api/subtenant";
import {
  getAvailableTenants,
  getAvailableSubtenants,
  fetchSubtenants,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";

import { Grid, Typography, TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import Dialog from "msa2-ui/src/components/Dialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import BasicSelect from "msa2-ui/src/components/BasicSelect";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const Create = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedTenant = useSelector(getSelectedTenant);
  const tenants = useSelector(getAvailableTenants);
  const token = useSelector(getToken);
  const subtenants = useSelector(getAvailableSubtenants);
  const closeDialog = () => {
    returnToPreviousPage(history, url);
  };

  const [name, setName] = useState("");
  const [operatorPrefix, setOperatorPrefix] = useState(
    selectedTenant?.value ? selectedTenant.value : "",
  );
  const [externalReference, setExternalReference] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async () => {
    setError("");

    if (!name || !operatorPrefix) {
      return setError(t("Please fill out all the form fields"));
    }

    const nameDuplicated = subtenants?.find(
      (subtenant) => subtenant.label === name,
    );
    if (nameDuplicated) {
      return setError(t("Please use a unique Name"));
    }

    const refDuplicated = subtenants?.find(
      (subtenant) => subtenant.externalReference === externalReference,
    );
    if (refDuplicated) {
      return setError(t("Please use a unique name for External Reference"));
    }

    setSubmitting(true);
    const [error] = await createSubtenant({
      token,
      body: {
        name,
        contacts: [{}],
        operatorPrefix,
        externalReference,
      },
    });
    setSubmitting(false);
    const variant = error ? "error" : "success";
    const message = error ? error.getMessage() : t("Subtenant created.");
    if (!error) {
      dispatch(fetchSubtenants());
      closeDialog();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={t("Create Subtenant")}
      disabled={submitting}
    >
      <Grid container direction="column">
        <TextField
          type={"text"}
          id="SUBTENANT_CREATE_NAME"
          variant="outlined"
          label={t("Name")}
          value={name}
          required
          onChange={({ target: { value } }) => setName(value)}
          className={classes.formField}
        />
        <BasicSelect
          label={t("Tenant")}
          variant="outlined"
          name="CREATE_MANAGER_DIALOG_TENANT_PICKER"
          className={classes.formField}
          value={operatorPrefix}
          required
          onChange={({ target: { value } }) => {
            setOperatorPrefix(value);
          }}
        >
          {tenants.map((tenant, index) => (
            <MenuItem
              value={tenant.value}
              key={index}
              id={`SUBTENANT_CREATE_TENANT_${index}`}
            >
              {tenant.label}
            </MenuItem>
          ))}
        </BasicSelect>
        <TextField
          type={"text"}
          id="SUBTENANT_CREATE_EXTERNAL_REFERENCE"
          variant="outlined"
          className={classes.formField}
          label={t("External Reference")}
          value={externalReference}
          onChange={({ target: { value } }) => setExternalReference(value)}
        />

        {error && (
          <Typography
            color="error"
            className={classes.error}
            id="SUBTENANT_CREATE_ERROR"
          >
            {error}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default Create;
