import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { getToken, getUserRole, userRoles } from "msa2-ui/src/store/auth";
import { getFormValues } from "msa2-ui/src/store/form";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { makeStyles } from "@material-ui/core";
import {
  Divider,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import Repository from "msa2-ui/src/services/Repository";
import Workflow from "msa2-ui/src/services/Workflow";
import Task from "msa2-ui/src/services/Task";
import MicroserviceCommand from "msa2-ui/src/services/MicroserviceCommand";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import Select from "msa2-ui/src/components/BasicSelect";
import Dialog from "msa2-ui/src/components/Dialog";
import AdornedTextField from "msa2-ui/src/components/AdornedTextField";
import MsaSelect from "msa2-ui/src/components/msa-select";
import MicroserviceSelector from "msa2-ui/src/components/MicroserviceSelector";
import RepositorySelector from "msa2-ui/src/components/RepositorySelector";

const useStyles = makeStyles((theme) => ({
  gridSpacing: {
    textAlign: "left",
    marginTop: 20,
    marginBottom: 20,
  },
  subItems: {
    marginBottom: 20,
  },
  microserviceTitle: {
    textAlign: "left",
    marginTop: 10,
  },
  selectField: {
    textAlign: "left",
    marginBottom: 35,
    width: "100%",
  },
  disabledMenuItem: {
    padding: "6px 16px",
    cursor: "default",
    opacity: 0.5,
  },
  iconSpacing: {
    paddingRight: 20,
  },
  errorMessage: {
    color: theme.colors.red,
  },
}));

const AddTask = ({
  onClose,
  addTask,
  addTaskToForm,
  tasks = [],
  form,
  extractVariableFromTask,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const token = useSelector(getToken);
  const userRole = useSelector(getUserRole);
  const isRBACWorkflowsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );
  const isTrial = FeatureFlag.isEnabled(FeatureFlag.features.trial);

  const state = useSelector((state) => state);
  const formValues = useSelector(getFormValues(form));
  const {
    information: { serviceTaskType },
    metaInformationList: [{ parentURI }],
  } = formValues;
  const fileExtension = Workflow.serviceTaskType[serviceTaskType].fileExtension;

  const [taskName, setTaskName] = useState("");
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);
  // If there is no template file which should be pulled from OpenMSA,
  // set default template type as Blank
  const defaultTemplateType = get(
    state,
    [Task.BASE_TYPES.TEMPLATE.statePath, serviceTaskType].join("."),
  )
    ? Task.BASE_TYPES.TEMPLATE.value
    : Task.BASE_TYPES.BLANK.value;

  const [templateType, setTemplateType] = useState(defaultTemplateType);
  const [selectedMicroservice, setSelectedMicroservice] = useState({});
  const [microserviceMethod, setMicroserviceMethod] = useState();

  useEffect(() => {
    const sameName = tasks?.find((task) => task.displayName === taskName);
    if (isEmpty(taskName)) {
      setErrorMessage(t("Please Enter the Task name"));
    } else if (templateType !== Task.BASE_TYPES.MS_CALL.value && sameName) {
      setErrorMessage(
        t("Please use a unique name for the x", { x: t("Task") }),
      );
    } else if (
      templateType === Task.BASE_TYPES.MS_CALL.value &&
      isEmpty(selectedMicroservice)
    ) {
      setErrorMessage(t("Please select Microservice"));
    } else if (
      templateType === Task.BASE_TYPES.MS_CALL.value &&
      !microserviceMethod
    ) {
      setErrorMessage(t("Please select Microservice Command"));
    } else {
      setErrorMessage(null);
    }
  }, [
    templateType,
    taskName,
    fileName,
    tasks,
    microserviceMethod,
    selectedMicroservice,
    setErrorMessage,
    t,
  ]);

  const sendToProcessForm = () => {
    if (!errorMessage) {
      if (templateType === Task.BASE_TYPES.EXISTING.value) {
        addTaskToForm({ taskName, fileName: filePath });
        extractVariableFromTask(filePath);
      } else {
        addTask({
          templateType,
          taskName,
          fileName,
          microServiceUri: selectedMicroservice?.uri,
          crudMethod: microserviceMethod?.value,
        });
      }
      onClose();
    }
  };

  const clearForm = () => {
    setTaskName("");
    setFileName("");
    setFilePath("");
    setSelectedMicroservice({});
    setMicroserviceMethod();
  };

  return (
    <Dialog
      execLabel={"Save"}
      onClose={onClose}
      onExec={sendToProcessForm}
      title={t("Create Task")}
    >
      <Grid container direction="column">
        <Grid className={classes.gridSpacing}>
          <Select
            id="AUTOMATION_TASK_TYPE"
            variant={"outlined"}
            value={templateType}
            label={"Create Task From..."}
            onChange={({ target: { value } }) => {
              // hack to show tooltip for the disabled menu items..
              if (value) {
                setTemplateType(value);
                clearForm();
              }
            }}
            displayEmpty
            fullWidth
          >
            {Object.values(Task.BASE_TYPES).map(
              ({ value, label, error, statePath }, i) => {
                const templateExists = statePath
                  ? get(state, [statePath, serviceTaskType].join("."))
                  : true;
                return templateExists ? (
                  <MenuItem
                    key={i}
                    id={`AUTOMATION_TASK_LIST_${i}`}
                    value={value}
                    disabled={!templateExists}
                  >
                    <Typography>{label}</Typography>
                  </MenuItem>
                ) : (
                  <Tooltip key={i} title={!templateExists ? error : ""}>
                    <Typography className={classes.disabledMenuItem}>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            )}
          </Select>
        </Grid>
        {templateType === Task.BASE_TYPES.MS_CALL.value && (
          <>
            <Divider />
            <Grid className={classes.microserviceTitle}>
              <Typography variant="body1">
                {t("Create Task from Microservice")}
              </Typography>
            </Grid>
            <Grid className={classes.microserviceTitle}>
              <Typography
                variant="body1"
                className={commonClasses.commonDescription}
              >
                {t(
                  "Choose Microservice and its command you want to call from Workflow.",
                )}
              </Typography>
              <Typography
                variant="body1"
                className={commonClasses.commonDescription}
              >
                {t("Task will be automatically generated.")}
              </Typography>
            </Grid>
            <Grid className={classes.gridSpacing}>
              <MicroserviceSelector
                onSelectMicroservice={(microservice) =>
                  setSelectedMicroservice(microservice)
                }
              />
            </Grid>
            <Grid className={classes.subItems}>
              <MsaSelect
                id="MICROSERVICE_SELECTOR_NAME"
                options={Object.values(MicroserviceCommand.COMMANDS)
                  .filter(({ msCall }) => msCall)
                  .map(({ id }) => ({ label: id, value: id }))}
                placeholder={t("Select Microservice Command...")}
                value={microserviceMethod}
                onChange={(method) => {
                  setMicroserviceMethod(method);
                }}
              />
            </Grid>
            <Divider />
          </>
        )}
        {templateType === Task.BASE_TYPES.EXISTING.value && (
          <RepositorySelector
            initDirectory={parentURI}
            filterTerm={new RegExp(fileExtension + "$")}
            onChange={setFilePath}
            label={t("Task File")}
            uri={filePath}
            noOptionsMessage={() => t("There are no Tasks in this directory.")}
            lockInitDirectory={
              // For Hosted trial or RBAC enabled, we shouldn't show Tasks for other WF
              (isRBACWorkflowsEnabled || isTrial) &&
              userRole === userRoles.MANAGER
            }
            token={token}
          />
        )}
        <Grid className={classes.gridSpacing}>
          <FormControl fullWidth>
            <TextField
              type={"text"}
              id="AUTOMATION_TASK_NAME"
              variant="outlined"
              inputProps={{ "data-testid": "task-name" }}
              onChange={({ target: { value } }) => {
                setTaskName(value);
                setFileName(
                  Task.FILE_PREFIX +
                    Repository.convertStringToFilename(value, fileExtension),
                );
              }}
              label={t("Task Name")}
              value={taskName}
            />
          </FormControl>
        </Grid>
        {![
          Task.BASE_TYPES.MS_CALL.value,
          Task.BASE_TYPES.EXISTING.value,
        ].includes(templateType) && (
          <Grid className={classes.subItems}>
            <AdornedTextField
              id="AUTOMATION_TASK_FILE_NAME"
              label={t("File Name")}
              variant="outlined"
              value={fileName}
              suffix={fileExtension}
              onChange={({ target: { value } }) => {
                setFileName(
                  Repository.convertStringToFilename(value, fileExtension),
                );
              }}
              fullWidth
            />
          </Grid>
        )}

        <Grid>
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AddTask.propTypes = {
  onClose: PropTypes.func.isRequired,
  addTask: PropTypes.func.isRequired,
  addTaskToForm: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  form: PropTypes.string.isRequired,
  extractVariableFromTask: PropTypes.func.isRequired,
};

export default AddTask;
