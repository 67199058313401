import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { getToken } from "msa2-ui/src/store/auth";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  getLabels,
  listAllLabels,
  addLabelsToAManagedEntity,
} from "msa2-ui/src/api/managedEntity";

import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { MsaSelectCreatable } from "msa2-ui/src/components/msa-select";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    padding: "10px 20px",
  },
}));

const categoriesToMsaSelectOptions = (categories) =>
  categories.map((c) => ({ label: c, value: c }));
const msaSelectOptionsToCategories = (options) => options.map((o) => o.value);

const AddCategoryActionDialog = ({
  categories = [],
  onClose,
  isDialogOpen = true,
  meName,
  deviceId,
}) => {
  const t = useBoundedTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);

  const [isLoading, error, _labels = []] = useApi(
    getLabels,
    { deviceId },
    !deviceId,
  );
  const labels = error ? [] : _labels;

  const [isOptionsLoading, , options = []] = useApi(listAllLabels, {
    category: listAllLabels.categories.MANAGED_ENTITY,
  });

  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleChange = (newCategories) => {
    setSelectedCategories(newCategories || []);
  };

  const handleSave = async () => {
    const labels = msaSelectOptionsToCategories(selectedCategories);
    const [error] = await addLabelsToAManagedEntity({
      token,
      deviceId,
      labels,
    });
    const variant = error ? "error" : "success";
    const message = error ? error.getMessage() : t("Tags saved successfully.");
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    onClose();
  };

  const categoriesAsSelectOptions = categoriesToMsaSelectOptions(options);

  useEffect(() => {
    if (!isLoading) {
      setSelectedCategories(
        categoriesToMsaSelectOptions([...categories, ...labels]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const renderLabel = () => (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>{t("Tags")}</Grid>
      <Grid item>
        <Tooltip
          title={t("Enter a new tag or select an existing one from the list")}
        >
          <HelpOutlineIcon fontSize="small" />
        </Tooltip>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        open={isDialogOpen}
        fullWidth
        maxWidth="xs"
        onClose={onClose}
        scroll="body"
        classes={{
          ...classes,
          paper: commonClasses.commonDialogPaper,
        }}
      >
        <DialogTitle
          className={commonClasses.commonDialogHeader}
          disableTypography
        >
          <Typography
            variant="h4"
            className={commonClasses.commonDialogHeaderTitle}
          >
            {`${t("Add Tags")} - ${meName}`}
          </Typography>
          <IconButton onClick={onClose} id="CATEGORY_CLOSE_POPUP">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.contentWrapper}>
          <Grid container spacing={1} alignItems="center">
            <MsaSelectCreatable
              id="CATEGORY_INPUT"
              options={categoriesAsSelectOptions}
              renderLabel={renderLabel}
              placeholder={error ? t("Unable to get Tags") : t("Add Tags")}
              isClearable
              isMulti
              isSearchable
              value={selectedCategories}
              onChange={handleChange}
              onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
              disabled={Boolean(error)}
              isLoading={isLoading || isOptionsLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={commonClasses.commonFlexCenter}>
          <Button
            id="CATEGORY_CANCEL"
            variant="text"
            size="small"
            color="default"
            className={classNames(
              commonClasses.commonBtn,
              commonClasses.commonBtnSecondary,
            )}
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            id="CATEGORY_SAVE"
            variant="contained"
            size="small"
            color="primary"
            className={classNames(
              commonClasses.commonBtn,
              commonClasses.commonBtnPrimary,
            )}
            onClick={handleSave}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddCategoryActionDialog.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  node: PropTypes.object.isRequired,
  onAddCategory: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddCategoryActionDialog;
