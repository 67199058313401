const getTabIndexByPath = (tabs, pathname) => {
  return Math.max(
    tabs.findIndex((tab) => pathname.includes(tab.path)),
    0,
  );
};

export default {
  getTabIndexByPath,
};
