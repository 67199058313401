import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import innerBus from "msa2-ui/src/utils/InnerBus";

import { getToken } from "msa2-ui/src/store/auth";
import { uploadLicense } from "msa2-ui/src/api/license";

import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const useStyles = makeStyles(({ palette, typography }) => ({
  chooseFileButton: {
    marginBottom: 5,
  },
  settingText: {
    flexGrow: 2,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  lightText: {
    color: palette.text.secondary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightRegular,
  },
}));

const LicenseUpload = ({ reloadLicense }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const validateFile = (file) => {
    setErrorMessage(null);
    if (file.type === "" && file.name.split(".").pop() === "lic") {
      uploadFile(file);
    } else {
      setErrorMessage(t("Please upload a valid license file"));
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const [error, , meta] = await uploadLicense({
      token,
      formData,
    });

    setShowFileUpload(false);
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to upload License"))
      : `${t("License Uploaded")} `;
    if (error || meta.status === 400) {
      setShowFileUpload(true);
    } else {
      // event for removing notification from the main menu
      innerBus.emit(innerBus.evt.LICENCE_UPLOAD);
      reloadLicense();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  return (
    <>
      {showFileUpload && (
        <Grid className={classes.settingText}>
          <input
            color="primary"
            accept={`.lic`}
            type="file"
            data-testid="licenseUpload"
            onChange={(event) => {
              if (event.target.files[0]) {
                validateFile(event.target.files[0]);
              }
            }}
            id="icon-button-file"
            style={{ display: "none" }}
          />
          <label htmlFor="icon-button-file">
            <Button
              variant="contained"
              size="medium"
              component="span"
              color="primary"
              className={classes.chooseFileButton}
            >
              {t("Choose File")}
            </Button>
          </label>
          {!errorMessage && (
            <Typography className={classes.lightText}>
              {t("Upload License")}
            </Typography>
          )}
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </Grid>
      )}
      {!showFileUpload && (
        <Typography>{t("License uploaded successfully")}</Typography>
      )}
    </>
  );
};

LicenseUpload.propTypes = {
  reloadLicense: PropTypes.func.isRequired,
};

export default LicenseUpload;
