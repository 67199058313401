import { createSlice } from "@reduxjs/toolkit";

import isEqual from "lodash/isEqual";
import chunk from "lodash/chunk";
import isNil from "lodash/isNil";

import Dashboard from "msa2-ui/src/services/Dashboard";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import WorkflowForm from "msa2-ui/src/services/WorkflowForm";
import Workflow from "msa2-ui/src/services/Workflow";
import {
  getManufacturersAndModels,
  getManagedEntitiesByAdmin,
} from "msa2-ui/src/api/managedEntity";
import { getAllAdvancedVariableParameters } from "msa2-ui/src/api/variables";
import {
  readRepository,
  checkRepositoryExistence,
} from "msa2-ui/src/api/repository";
import { getTenantsByManager } from "msa2-ui/src/api/tenant";
import {
  getSubtenantsByTenant,
  getSubtenantsByManager,
} from "msa2-ui/src/api/subtenant";
import {
  changeLanguage,
  setAllDashboards,
  initializeSettings,
  SETTINGS,
  changeSettingsTheme,
  setAutoRefreshSetting,
} from "msa2-ui/src/store/settings";
import { getMsaVars, getMsaVarsAllUsers } from "msa2-ui/src/api/msaVars";
import { MSA_VARS } from "msa2-ui/src/Constants";
import { rootUser } from "msa2-ui/src/store/auth";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { getManagerByReference } from "msa2-ui/src/api/manager";

const workflowForms = [WorkflowForm.CreateFormName, WorkflowForm.EditFormName];

export const initialState = {
  ready: false,
  msaTheme: null,
  globalSettings: {},
  savedSettings: {},
  msaVars: [],
  secretKey: [],
  selectedSubtenant: {
    value: null,
    id: null,
    ubiqubeId: null,
    label: null,
  },
  selectedTenant: {
    value: null,
    ubiqubeId: null,
    label: null,
    id: null,
  },
  selectedWorkflow: { id: null, uri: null },
  selectedManagedEntity: null,
  subtenants: [],
  tenants: [],
  manufacturers: [],
  manufacturersById: {},
  models: [],
  modelsById: {},
  categoriesWithIds: {},
  managedEntities: [],
  advancedVariableParametersByName: {},
  microserviceVariableTypes: [],
  workflowVariableTypes: [],
  microserviceAdvancedParametersByType: {},
  workflowAdvancedParametersByType: {},
  templates: {},
  error: null,
  monitoringGraph: {},
};

const designationsSlice = createSlice({
  name: "designations",
  initialState,
  reducers: {
    ready(state, action) {
      state.ready = true;
    },
    setGlobalSettings(state, action) {
      state.globalSettings = action.payload;
    },
    setSavedSettings(state, action) {
      state.savedSettings = action.payload;
    },
    setMsaTheme(state, action) {
      state.msaTheme = action.payload;
    },
    setMsaVars(state, action) {
      state.msaVars = action.payload;
    },
    setSecretKey(state, action) {
      state.secretKey = action.payload;
    },
    selectSubtenant(state, action) {
      if (action.payload === null) {
        state.selectedSubtenant = initialState.selectedSubtenant;
      } else if (state.selectedSubtenant.id === action.payload.id) {
        return;
      } else {
        state.selectedSubtenant = action.payload;
      }
    },
    selectSubtenantById(state, action) {
      state.selectedSubtenant = state.subtenants.find(
        (subtenant) => subtenant.id === action.payload,
      );
    },
    selectSubtenantByUbiqubeId(state, action) {
      state.selectedSubtenant = state.subtenants.find(
        (subtenant) => subtenant.ubiqubeId === action.payload,
      );
    },
    clearSelectedSubtenant(state, action) {
      state.selectedSubtenant = initialState.selectedSubtenant;
    },
    fetchSubtenantsError(state, action) {
      state.subtenants = initialState.subtenants;
      state.selectedSubtenant = initialState.selectedSubtenant;
      state.subtenantListError = action.payload;
    },
    selectTenant(state, action) {
      if (action.payload === null) {
        state.selectedTenant = initialState.selectedTenant;
      } else if (state.selectedTenant.value === action.payload.value) {
        return;
      } else {
        state.selectedTenant = action.payload;
      }
      state.selectedSubtenant = initialState.selectedSubtenant;
    },
    selectTenantByLabel(state, action) {
      state.selectedTenant = state.tenants.find(
        (tenant) => tenant.label === action.payload,
      );
    },
    clearSelectedTenant(state, action) {
      state.selectedTenant = initialState.selectedTenant;
    },
    selectWorkflow(state, action) {
      state.selectedWorkflow = action.payload;
    },
    selectManagedEntity(state, action) {
      state.selectedManagedEntity = action.payload;
    },
    clearManagedEntity(state, action) {
      state.selectedManagedEntity = null;
    },
    fetchedSubtenants(state, action) {
      state.subtenants = action.payload;
    },
    fetchedTenants(state, action) {
      state.tenants = action.payload;
    },
    fetchedAdvancedParameters(state, action) {
      const {
        advancedVariableParametersByName,
        microserviceVariableTypes,
        workflowVariableTypes,
        microserviceAdvancedParametersByType,
        workflowAdvancedParametersByType,
      } = action.payload;
      state.advancedVariableParametersByName = advancedVariableParametersByName;
      state.microserviceVariableTypes = microserviceVariableTypes;
      state.workflowVariableTypes = workflowVariableTypes;
      state.microserviceAdvancedParametersByType = microserviceAdvancedParametersByType;
      state.workflowAdvancedParametersByType = workflowAdvancedParametersByType;
    },
    fetchedTemplates(state, action) {
      state.templates = { ...state.templates, ...action.payload };
    },
    fetchedManufacturers(state, action) {
      const {
        manufacturers,
        manufacturersById,
        models,
        modelsById,
        categoriesWithIds,
      } = action.payload;
      state.manufacturers = manufacturers;
      state.manufacturersById = manufacturersById;
      state.models = models;
      state.modelsById = modelsById;
      state.categoriesWithIds = categoriesWithIds;
    },
    fetchedManagedEntities(state, action) {
      if (action.payload && Array.isArray(action.payload)) {
        state.managedEntities = action.payload;
      }
    },
    setMGData(state, action) {
      state.monitoringGraph = action.payload;
    },
  },
  extraReducers: {
    // We have to use the string directly here because if
    // we import the auth module we get a circular dependency
    "auth/logout": () => {
      return initialState;
    },
  },
});

export const getDesignationsReady = ({ designations }) => designations.ready;

export const getGlobalSettings = ({ designations }) =>
  designations.globalSettings;

export const getSavedSettings = ({ designations }) =>
  designations.savedSettings;

export const getMsaTheme = ({ designations }) => designations.msaTheme;

export const getMsaVarValue = (name = "") => ({ designations }) =>
  designations.msaVars?.find((msaVar) => msaVar.name === name)?.value;

export const getSecretKeyValue = ({ designations }) => designations?.secretKey;

export const getAvailableSubtenants = ({ designations }) =>
  designations?.subtenants;

export const getAvailableTenants = ({ designations }) => designations.tenants;

export const getSelectedSubtenant = ({ designations }) =>
  designations?.selectedSubtenant;

export const getMGData = ({ designations }) => designations?.monitoringGraph;

export const getSelectedTenant = ({ designations }) =>
  designations.selectedTenant;

export const getSelectedWorkflow = ({ designations }) =>
  designations.selectedWorkflow;

export const getSelectedManagedEntity = ({ designations }) =>
  designations.selectedManagedEntity;

export const getModelsById = ({ designations }) => designations.modelsById;

export const getManufacturers = ({ designations }) =>
  designations.manufacturers;

export const getManufacturersById = ({ designations }) =>
  designations.manufacturersById;

export const getCategoriesWithIds = ({ designations }) =>
  designations.categoriesWithIds;

export const getManagedEntityList = ({ designations }) =>
  designations.managedEntities.map((managedEntity) => ({
    ubiId: managedEntity.deviceId.ubiId,
    name: managedEntity.name,
  }));

export const isMultiTenant = ({ designations }) =>
  designations.tenants.length > 1;

export const isMultiSubtenant = ({ designations }) =>
  designations.subtenants.length > 1;

export const getVariableTypes = (form) => ({ designations }) => {
  if (MicroserviceForm.isMicroserviceForm(form)) {
    return designations.microserviceVariableTypes;
  }
  if (workflowForms.includes(form)) {
    return designations.workflowVariableTypes;
  }
  return [];
};

export const getAdvancedVariableParametersByType = (form, type) => ({
  designations,
}) => {
  if (MicroserviceForm.isMicroserviceForm(form)) {
    return type
      ? designations.microserviceAdvancedParametersByType[type].parameters
      : designations.microserviceAdvancedParametersByType;
  }
  if (workflowForms.includes(form)) {
    return type
      ? designations.workflowAdvancedParametersByType[type].parameters
      : designations.workflowAdvancedParametersByType;
  }
  return [];
};

export const getAdvancedVariableParametersByName = (form) => ({
  designations,
}) => {
  if (
    MicroserviceForm.isMicroserviceForm(form) ||
    workflowForms.includes(form)
  ) {
    return designations.advancedVariableParametersByName;
  }
  return [];
};

export const getTaskTemplates = ({ designations }) => designations.templates;

export const fetchDesignations = () => async (dispatch, getState) => {
  dispatch(fetchTenants());
  dispatch(fetchSubtenants());
  dispatch(fetchManagedEntities());

  const { auth = {}, settings = {} } = getState();
  const { token, userDetails = {} } = auth;
  const { language: localLanguage } = settings;
  const { login } = userDetails;
  const isRootUser = login === rootUser;
  const ubiqubeId = getState().auth?.userDetails.netceloId.ubiID;
  const workflowServiceTasks = Object.keys(Workflow.serviceTaskType);
  const [
    manufacturersResponse,
    advancedParametersResponse,
    msaVarsResponse,
    secretKeyResponse,
    ...templatesResponses
  ] = await Promise.all([
    getManufacturersAndModels({ token }),
    getAllAdvancedVariableParameters(token),
    getMsaVars({ isRootUser, token, vars: [MSA_VARS.UBI_LDAP_ENABLE] }),
    getMsaVarsAllUsers({ token, vars: [MSA_VARS.UBI_API_UI_KEY] }),
    ...workflowServiceTasks.map((type) => {
      const typeDefinition = Workflow.serviceTaskType[type];
      return readRepository({
        token,
        uri: typeDefinition.templateUri,
      });
    }),
    ...workflowServiceTasks.map((type) => {
      const typeDefinition = Workflow.serviceTaskType[type];
      return checkRepositoryExistence({
        token,
        uri: typeDefinition.msCallUri,
      });
    }),
  ]);

  const [
    [, msaVarUiSettings] = [],
    [, managerUiSettings] = [],
  ] = await Promise.all([
    getMsaVarsAllUsers({ token, vars: [MSA_VARS.UI_SETTINGS] }),
    getManagerByReference(ubiqubeId, token),
  ]);

  const globalUiSettings = {};
  if (msaVarUiSettings !== undefined && msaVarUiSettings.length > 0) {
    // Parse global settings to remove backslashes and recreate the object
    const uiSettings = msaVarUiSettings[0]["value"];
    const uiSettingsWithoutBackslash = uiSettings.replace(/\\/g, "");
    const globalUiSettingsObj = JSON.parse(uiSettingsWithoutBackslash);
    const dashboardsObj = globalUiSettingsObj["dashboards"];
    const titleObj = globalUiSettingsObj["title"];
    globalUiSettings.dashboards = dashboardsObj;
    globalUiSettings.title = titleObj;

    dispatch(setGlobalSettings(globalUiSettings));
  }

  let uiSettingsByUser = {};
  if (
    managerUiSettings !== undefined &&
    "UISettings" in managerUiSettings &&
    managerUiSettings["UISettings"] !== null &&
    Object.keys(managerUiSettings["UISettings"]).length > 0
  ) {
    uiSettingsByUser = managerUiSettings["UISettings"];
    dispatch(setSavedSettings(uiSettingsByUser));
  }

  const {
    language,
    // "dashboard" can be created only < MSA2.6
    dashboard,
    dashboards,
    theme,
    autoRefresh,
    ...rest
  } = { ...globalUiSettings, ...uiSettingsByUser };
  const languageChanged = localLanguage !== SETTINGS.language.defaultValue;
  if (localLanguage !== language && !languageChanged) {
    dispatch(changeLanguage(language));
    SETTINGS.language.onChange(language);
  }
  if (dashboard || dashboards) {
    const convertedDashboards = dashboards ?? [
      Dashboard.convertOldDashboardToNew(dashboard),
    ];
    dispatch(setAllDashboards(convertedDashboards));
  }
  if (!isNil(theme)) {
    dispatch(changeSettingsTheme(theme));
  }
  if (autoRefresh && typeof autoRefresh === "object") {
    dispatch(setAutoRefreshSetting(autoRefresh));
  }
  Object.entries(rest).forEach(([key, value]) => {
    dispatch(initializeSettings({ key, value }));
  });

  const [, manufacturersAndModels] = manufacturersResponse;
  if (manufacturersAndModels) {
    dispatch(fetchedManufacturers(manufacturersAndModels));
  }

  const [, advancedVariableParameters] = advancedParametersResponse;
  if (advancedVariableParameters) {
    dispatch(fetchedAdvancedParameters(advancedVariableParameters));
  }

  const [msaVarsError, msaVars] = msaVarsResponse;
  if (!msaVarsError && msaVars) {
    dispatch(setMsaVars(msaVars));
  }

  const [error, secretKey] = secretKeyResponse;
  if (!error && secretKey) {
    dispatch(setSecretKey(secretKey));
  }

  const [defaultTemplates, msCallTemplates] = chunk(
    templatesResponses,
    workflowServiceTasks.length,
  );

  defaultTemplates.forEach(([, template], i) => {
    const type = workflowServiceTasks[i];
    dispatch(fetchedTemplates({ [type]: template?.content }));
  });
  const msCall = msCallTemplates.reduce(
    (acc, [, fileExists], i) => ({
      ...acc,
      [workflowServiceTasks[i]]: fileExists,
    }),
    {},
  );
  dispatch(fetchedTemplates({ msCall }));

  dispatch(ready());
};

export const fetchManagedEntities = () => async (dispatch, getState) => {
  const { auth = {} } = getState();
  const { token, userDetails } = auth;
  const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.permissionProfileLabels,
  );

  const [error, managedEntities] = await getManagedEntitiesByAdmin({
    token,
    managerId: userDetails?.id,
    filterByLabel: isPermissionProfileLabelsEnabled,
    // This API cannot retrieve all Managed Entities so put page size 10000 temporarily.
    pageSize: 10000,
  });

  if (error) {
    return;
  }

  if (managedEntities) {
    dispatch(fetchedManagedEntities(managedEntities));
  }
};

export const fetchTenants = () => async (dispatch, getState) => {
  const { auth = {}, designations = {} } = getState();
  const { token, userDetails } = auth;
  const { tenants } = designations;

  const [error, tenantsApiResponse] = await getTenantsByManager({
    token,
    managerId: userDetails?.id,
  });

  if (!tenantsApiResponse || isEqual(tenantsApiResponse, tenants) || error) {
    return;
  }

  if (tenantsApiResponse) {
    dispatch(fetchedTenants(tenantsApiResponse));
  }

  if (tenantsApiResponse.length === 1) {
    dispatch(selectTenant(tenantsApiResponse[0]));
  }
};

export const fetchSubtenants = () => async (dispatch, getState) => {
  const { auth = {}, designations = {} } = getState();
  const { token, userDetails } = auth;
  const { selectedTenant, subtenants } = designations;

  const [error, subtenantsApiResponse] = selectedTenant?.value
    ? await getSubtenantsByTenant({
        tenantPrefix: selectedTenant?.value,
        token,
      })
    : await getSubtenantsByManager({
        managerId: userDetails?.id,
        token,
      });

  if (error) {
    return dispatch(fetchSubtenantsError(error.statusCode));
  } else if (
    !subtenantsApiResponse ||
    isEqual(subtenantsApiResponse, subtenants)
  ) {
    return;
  } else if (subtenantsApiResponse) {
    dispatch(fetchedSubtenants(subtenantsApiResponse));
  }
};

export const selectTenant = (tenant) => (dispatch) => {
  dispatch(designationsSlice.actions.selectTenant(tenant));
  dispatch(fetchSubtenants());
};

export const {
  ready,
  setGlobalSettings,
  setSavedSettings,
  setMsaTheme,
  setMsaVars,
  setMGData,
  selectSubtenant,
  selectSubtenantById,
  selectSubtenantByUbiqubeId,
  clearSelectedSubtenant,
  fetchSubtenantsError,
  selectTenantByLabel,
  clearSelectedTenant,
  selectWorkflow,
  selectManagedEntity,
  clearManagedEntity,
  fetchedSubtenants,
  fetchedTenants,
  fetchedAdvancedParameters,
  fetchedTemplates,
  fetchedManufacturers,
  fetchedManagedEntities,
  setSecretKey,
} = designationsSlice.actions;

export default designationsSlice.reducer;
