import {
  getDefaultMonitoringDataForDevice,
  getMonitoringProfileGraphs,
  getMonitoringDataForDevice,
} from "msa2-ui/src/api/monitoringProfiles";
import { defaultProfile, defaultGraphsConfiguration } from "./constants";

export const getGraphs = ({
  deviceUbiId,
  monitoringPeriod,
  customMonitoringPeriod,
  profileId,
  token,
}) => {
  return profileId === defaultProfile.id
    ? getDefaultGraphs({
      deviceUbiId,
      monitoringPeriod,
      customMonitoringPeriod,
      token,
    })
    : getCustomGraphs({
      deviceUbiId,
      monitoringPeriod,
      customMonitoringPeriod,
      profileId,
      token,
    });
};

export const getDefaultGraphs = async ({
  deviceUbiId,
  monitoringPeriod,
  customMonitoringPeriod,
  token,
}) => {
  const [startDate, endDate] = customMonitoringPeriod || [];
  const [error, response] = await getDefaultMonitoringDataForDevice({
    deviceUbiId,
    monitoringPeriod,
    startDate,
    endDate,
    token,
  });

  if (error) {
    return [error, undefined];
  }

  const graphs = mapDefaultConfigurationsToRrdData(
    Object.values(defaultGraphsConfiguration).filter(
      (ids) => response[ids.id] !== undefined,
    ),
    response,
  );

  return [undefined, graphs];
};

export const getCustomGraphs = async ({
  deviceUbiId,
  monitoringPeriod,
  customMonitoringPeriod,
  profileId,
  token,
}) => {
  const [startDate, endDate] = customMonitoringPeriod;
  const [profileGraphsResponse, rrdDataResponse] = await Promise.all([
    getMonitoringProfileGraphs({ profileId, token }),
    getMonitoringDataForDevice({
      deviceUbiId,
      monitoringPeriod,
      startDate,
      endDate,
      profileId,
      token,
    }),
  ]);

  const error = profileGraphsResponse[0] || rrdDataResponse[0];

  if (error) {
    return [error, undefined];
  }

  const graphs = mapCustomConfigurationToRrdData(
    profileGraphsResponse[1],
    rrdDataResponse[1],
  );

  return [undefined, graphs];
};

const convertUptimeToDays = (hundredsOfSeconds) => {
  const days = hundredsOfSeconds / 100 / 60 / 60 / 24;
  return Math.floor(days * 100) / 100;
};

export const mapDefaultConfigurationsToRrdData = (configurations, rrdData) => {
  return configurations.map((configuration) => {
    const { id } = configuration;

    const data = (rrdData[id] || []).map(({ t: timestamp, v }) => {
      const result = {
        time: timestamp * 1000,
      };

      switch (id) {
        case defaultGraphsConfiguration.AVAILABILITY.id:
          return {
            ...result,
            latency: parseFloat(v[1]) || 0,
            ttl: parseFloat(v[2]) || 0,
          };
        case defaultGraphsConfiguration.SYSUPTIME.id:
          return {
            ...result,
            uptime: convertUptimeToDays(parseFloat(v) || 0),
          };
        case defaultGraphsConfiguration.TRAFFIC.id:
          return {
            ...result,
            incoming: parseFloat(v[0]) || 0,
            outgoing: parseFloat(v[1]) || 0,
          };
        case defaultGraphsConfiguration.CPU.id:
          return {
            ...result,
            cpu: parseFloat(v) || 0,
          };
        default:
          return result;
      }
    });

    return { ...configuration, data };
  });
};

export const mapCustomConfigurationToRrdData = (configurations, rrdData) => {
  return configurations.map((configuration) => {
    const { kpis } = configuration;

    const timestampToValueMap = {};

    Object.values(kpis).forEach((axis) => {
      const { snmpPollingName } = axis;
      const axisRrd = rrdData[snmpPollingName] || [];

      axisRrd.forEach(({ t: timestamp, v: value }) => {
        if (!timestampToValueMap[timestamp]) {
          const timePoint = (timestampToValueMap[timestamp] = {});
          Object.values(kpis).forEach(({ id }) => (timePoint[id] = 0));
        }

        timestampToValueMap[timestamp][axis.id] = parseFloat(value) || 0;
      });
    }, []);

    const data = Object.entries(timestampToValueMap)
      .map(([timestamp, values]) => ({
        time: timestamp * 1000,
        ...values,
      }))
      .sort((a, b) => a.time - b.time);

    return { ...configuration, data };
  });
};
