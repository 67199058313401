import { useSelector } from "react-redux";
import { getDelegationProfile } from "msa2-ui/src/store/delegationProfiles";

export default ({ children, type, action }) => {
  const canAccess = useSelector(getDelegationProfile(type, action));
  if (canAccess) {
    return children;
  }
  return null;
};
