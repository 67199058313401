import React, { useState, useEffect } from "react";
import { useInterval } from "react-use";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as AlertNotificationFalse } from "msa2-ui/src/assets/icons/alerts-03.svg";
import { ReactComponent as AlertNotificationTrue } from "msa2-ui/src/assets/icons/alerts-01.svg";
import { ReactComponent as AlertNotificationFalseLight } from "msa2-ui/src/assets/icons/alerts-03-light.svg";
import { ReactComponent as AlertNotificationTrueLight } from "msa2-ui/src/assets/icons/alerts-01-light.svg";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import { getTriggeredAlarms } from "msa2-ui/src/api/logs";
import {
  getAvailableSubtenants,
  getMsaTheme,
  getSelectedSubtenant,
} from "msa2-ui/src/store/designations";
import { displayYearMonthTimeDateAsES } from "msa2-ui/src/utils/date";
import useApi from "msa2-ui/src/hooks/useApi";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  alarmIcon: {
    height: 40,
    width: 40,
    color: "#ffffff",
  },
  marginBadge: {
    margin: spacing(0.5),
  },
  customBadge: {
    backgroundColor: "#d71200",
    color: "white",
  },
}));

const AlarmNotification = () => {
  const classes = useStyles();
  const location = useLocation();
  const autoRefresh = useSelector(getAutoRefreshSetting("notification"));
  const theme = useSelector(getMsaTheme);
  const { id: selectedSubtenantId } = useSelector(getSelectedSubtenant);
  const subtenantsListIds = useSelector(getAvailableSubtenants).map(
    (ids, index) => ids.id,
  );
  const subtenants = subtenantsListIds.length !== 0 ? subtenantsListIds : [0];
  const getCurrentDate = () => displayYearMonthTimeDateAsES(new Date());
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  // Use interval and set current date inside of it because if you set current date
  // in the root level of the component, it updates the component every second and calls flood of api
  useInterval(() => {
    setCurrentDate(getCurrentDate());
  }, autoRefresh);

  const [, error, logsResponse = {}, , reload] = useApi(
    getTriggeredAlarms,
    {
      subtenantIds: selectedSubtenantId ? [selectedSubtenantId] : subtenants,
      pageSize: 1000,
      from: 0,
      ack: "false",
    },
    !currentDate,
    autoRefresh,
  );
  const { count } = logsResponse;
  const total = error ? 0 : count;

  const alertNotificationTrueIcon = (darkTheme) => {
    return darkTheme ? (
      <AlertNotificationTrue
        id="NAV_BTN_ALARMS_TRUE"
        className={classes.alarmIcon}
        style={{ cursor: "pointer" }}
      />
    ) : (
      <AlertNotificationTrueLight
        id="NAV_BTN_ALARMS_TRUE"
        className={classes.alarmIcon}
        style={{ cursor: "pointer" }}
      />
    );
  };

  const alertNotificationFalseIcon = (darkTheme) => {
    return darkTheme ? (
      <AlertNotificationFalse
        id="NAV_BTN_ALARMS_FALSE"
        className={classes.alarmIcon}
        style={{ cursor: "pointer" }}
      />
    ) : (
      <AlertNotificationFalseLight
        id="NAV_BTN_ALARMS_FALSE"
        className={classes.alarmIcon}
        style={{ cursor: "pointer" }}
      />
    );
  };

  useEffect(() => {
    if (location.state?.reloadAlarmNotification) {
      reload();
    }
  }, [location.state, reload]);

  return (
    <Badge
      classes={{ badge: classes.customBadge }}
      className={classes.marginBadge}
      badgeContent={total}
    >
      <NavLink to={"/alarms/"} className={classes.createLink}>
        {total !== 0
          ? alertNotificationTrueIcon(theme)
          : alertNotificationFalseIcon(theme)}
      </NavLink>
    </Badge>
  );
};

export default AlarmNotification;
