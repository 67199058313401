import React from "react";
import PropTypes from "prop-types";

/**
 * MATERIAL UI
 */
import { makeStyles } from "@material-ui/core";
import { Dialog } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "100%",
  },
  paper: {
    background: theme.colors.greyLight3,
    overflowY: "hidden",
    marginTop: theme.topbar.height,
  },
}));

const Modal = ({ children, onClose, ...other }) => {
  const classes = useStyles();
  return (
    <Dialog
      {...other}
      fullScreen={true}
      onClose={onClose}
      classes={{
        root: classes.modal,
        paper: classes.paper,
      }}
      TransitionProps={{ role: "dialog" }}
      scroll="paper"
      open
    >
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onClose: PropTypes.func,
};

export default Modal;
