import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import useApi from "msa2-ui/src/utils/useApi";
import usePopover from "msa2-ui/src/hooks/usePopover";

import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import {
  getManagedEntitiesBySubtenant,
  getManagedEntitiesByAdmin,
} from "msa2-ui/src/api/managedEntity";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const ManagedEntityReference = ({
  predefinedValuesEnabled = false,
  showUniqueValuesInDropdown = false,
  selectedOptions = [],
  sdTypes,
  value,
  label,
  onSelect,
  token,
  subtenantId,
  deviceUbiId,
  managerId,
  ...props
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const [, showAddPopover, AddPopover] = usePopover();
  const topologyDeviceIds = [deviceUbiId];
  const [deviceIsSet, setDeviceIsSet] = useState(false);

  const managedEntitiesBySubtenant = useApi({
    apiCall: getManagedEntitiesBySubtenant,
    params: {
      subtenantId,
      // This API cannot retrieve all Managed Entities so put page size 10000 temporarily.
      pageSize: 10000,
      filterByLabel: isPermissionProfileLabelsEnabled,
    },
    wait: !subtenantId,
    token,
  });

  const managedEntitiesByAdmin = useApi({
    apiCall: getManagedEntitiesByAdmin,
    params: {
      managerId,
      // This API cannot retrieve all Managed Entities so put page size 10000 temporarily.
      pageSize: 10000,
      filterByLabel: isPermissionProfileLabelsEnabled,
    },
    wait: subtenantId || !managerId,
    token,
  });

  const [loading, error, response] = subtenantId
    ? managedEntitiesBySubtenant
    : managedEntitiesByAdmin;

  const devices = response
    ? response
        .filter(({ manId, modId }) =>
          // If sdTypes is passed, show only Managed Entities which match passed Vendor/Model
          sdTypes?.length > 0
            ? sdTypes.some(
                ({ manId: _manId, modId: _modId }) =>
                  manId === _manId && modId === _modId,
              )
            : true,
        )
        .map(({ name, deviceId: { ubiId } }) => ({
          id: ubiId,
          label: `${name} - ${ubiId}`,
        }))
    : [];

  if (
    predefinedValuesEnabled &&
    !deviceIsSet &&
    deviceUbiId &&
    devices.length > 0 &&
    devices.find((device) => device.id === deviceUbiId)
  ) {
    setDeviceIsSet(true);
    onSelect(deviceUbiId);
  }

  if (loading) {
    return <CircularProgress aria-label={t("Loading")} />;
  }
  if (error) {
    return <Typography>{t("Unable to load Managed Entities.")}</Typography>;
  }
  if (!response) return null;

  if (response.length === 0) {
    return <Typography>{t("No Managed Entities found.")}</Typography>;
  }
  //If an option is selected, display corresponding label as button text
  const buttonText =
    devices.find(({ id }) => id === value)?.label ??
    (value ? `Unknown Managed Entity - ${value}` : "Click to Select");

  //Filter device options to not show already selected values, when unique option is selected for ME lists
  let filteredDevices = devices;
  if (showUniqueValuesInDropdown) {
    filteredDevices = devices.filter(
      (device) => device.id === value || !selectedOptions.includes(device.id),
    );
  }

  const options = topologyDeviceIds
    ? [
        { id: "", label: "" },
        {
          label: t("Selected on Topology"),
          options: [
            ...filteredDevices.filter((device) =>
              topologyDeviceIds.includes(device.id),
            ),
          ],
        },
        {
          label: t("Available Managed Entities"),
          options: [
            ...filteredDevices.filter(
              (device) => !topologyDeviceIds.includes(device.id),
            ),
          ],
        },
      ]
    : [{ id: "", label: "" }, ...filteredDevices];

  return (
    <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
      {label && (
        <Grid item>
          <Typography
            variant="subtitle1"
            className={commonClasses.commonLabel}
          >{`${label}:`}</Typography>
        </Grid>
      )}
      <Grid item>
        <Button onClick={showAddPopover} {...props}>
          {buttonText}
        </Button>
        <AddPopover
          topologyDeviceIds={topologyDeviceIds}
          options={options}
          onSelect={({ id }) => onSelect(id)}
          notFoundMessage={t("No Managed Entities found")}
        />
      </Grid>
    </Grid>
  );
};

ManagedEntityReference.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  sdTypes: PropTypes.arrayOf(
    PropTypes.shape({
      manId: PropTypes.number.isRequired,
      modId: PropTypes.number.isRequired,
    }),
  ),
  token: PropTypes.string.isRequired,
  subtenantId: PropTypes.number,
  deviceUbiId: PropTypes.string,
  managerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ManagedEntityReference;
