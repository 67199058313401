import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useApi from "msa2-ui/src/hooks/useApi";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Paper,
  Typography,
} from "@material-ui/core";
import { ReactComponent as PlusIcon } from "msa2-ui/src/assets/icons/plus.svg";
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons";

import Variable from "msa2-ui/src/services/Variable";
import {
  getMsaTheme,
  getSelectedWorkflow,
} from "msa2-ui/src/store/designations";
import { getWorkflowInstanceVariableValues } from "msa2-ui/src/api/workflow";
import { getWorkflow } from "msa2-ui/src/api/workflow";

import BasicSelect from "msa2-ui/src/components/BasicSelect";

import { Bar, BarChart, XAxis, YAxis } from "recharts";
import createTheme from "msa2-ui/src/styles/theme";

import DoughnutChart from "msa2-ui/src/components/graphs/Doughnut";
const useStyles = makeStyles(
  ({ darkMode, palette, typography, colors, spacing }) => ({
    paper: {
      padding: spacing(2),
      boxShadow: "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
      height: "100%",
    },
    barChart: {
      marginBottom: -20,
    },
    autoRefresh: {
      marginBottom: 10,
    },
    autoRefreshTime: {
      width: 200,
      marginLeft: 20,
    },
  }),
);

const WorkflowPieChart = ({
  title,
  legend,
  variableValues: _variableValues,
  instanceId,
  autoRefresh,
}) => {
  const classes = useStyles();
  const theme = useSelector(getMsaTheme);
  const { palette } = createTheme(theme);
  const [, , variableValues = _variableValues] = useApi(
    getWorkflowInstanceVariableValues,
    { instanceId },
    !instanceId,
    autoRefresh,
  );
  if (!variableValues) {
    return;
  }

  const colors = [
    palette.error.main,
    palette.warning.main,
    palette.text.secondary,
    palette.text.secondary,
    palette.success.main,
  ];
  const chartData = [
    {
      key: "",
      name: "",
      total: legend.reduce(
        (total, legend) =>
          total +
          (parseInt(variableValues[Variable.removePrefix(legend.id)]) || 0),
        0,
      ),
      badge: {},
      graph: [
        legend.map(({ id, displayName }, i) => ({
          name: displayName,
          value: parseInt(variableValues[Variable.removePrefix(id)]) || 0,
          color: colors[i % 5],
        })),
      ],
    },
  ];

  return (
    <Paper id="DASHBOARD_WORKFLOWS" className={classes.paper}>
      {title && (
        <Typography variant="h5" noWrap>
          {title}
        </Typography>
      )}
      <Grid container justifyContent="center">
        <Paper className={classes.paperCenter}>
          <Grid container>
            <Grid item xs={4}>
              <DoughnutChart
                chartType={"total workflow"}
                chartData={chartData}
                chartDataText={"Views Total"}
                size={132}
                maxCharts={1}
                noborder
              />
            </Grid>

            <Grid item xs={8}>
              {chartData[0].graph[0].map(({ name, value, color }, i) => {
                const available = Math.round(
                  (value / chartData[0].total) * 100,
                );
                return (
                  <Fragment key={i}>
                    <Grid container>
                      <Grid item xs={1}>
                        <Typography style={{ color }}>●</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{name}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{available}%</Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.barChart}>
                        <BarChart
                          width={300}
                          height={50}
                          data={[{ available, na: 100 - available }]}
                          layout="vertical"
                        >
                          <XAxis
                            type="number"
                            tick={false}
                            tickCount={0}
                            axisLine={false}
                          />
                          <YAxis
                            type="category"
                            tick={false}
                            axisLine={false}
                          />

                          <Bar
                            key={"available"}
                            radius={5}
                            maxBarSize={10}
                            dataKey={"available"}
                            fill={"#617bab"}
                            stackId="instances"
                          />

                          <Bar
                            key={"na"}
                            radius={5}
                            maxBarSize={2}
                            dataKey={"na"}
                            fill={"#bfc9d9"}
                            stackId="instances"
                          />
                        </BarChart>
                      </Grid>
                    </Grid>
                  </Fragment>
                );
              })}
              {/*               
              <AreaChart data={chartData} width={100} height={100}>
                <Legend
                  layout="vertical"
                  verticalAlign="middle"
                  align="left"
                  payload={chartData}
                />
              </AreaChart>
              */}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Divider variant="fullWidth" className={classes.offsetDivider} />
    </Paper>
  );
};

const WorkflowPieChartWrapper = ({
  graphData: initGraphData = [],
  workflowUri: workflowUriFromProp,
  instanceId: instanceIdFromProp,
  autoRefresh: initAutoRefresh = 0,
  hideFunctionalities,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: instanceIdFromState, uri: workflowUriFromState } = useSelector(
    getSelectedWorkflow,
  );

  const [graphData, setGraphData] = useState(initGraphData);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [autoRefresh, setAutoRefresh] = useState(initAutoRefresh);

  const workflowUri = workflowUriFromProp || workflowUriFromState;
  const instanceId = instanceIdFromProp || instanceIdFromState;
  const [loading, , variableValues = {}] = useApi(
    getWorkflowInstanceVariableValues,
    { instanceId },
    !instanceId,
    autoRefresh,
  );
  const [definitionLoading, , workflowDefinition = {}] = useApi(
    getWorkflow,
    { pathToWorkflowDefinitionFile: decodeURIComponent(workflowUri) },
    !workflowUri,
  );
  const { variables } = workflowDefinition;

  const ref = useRef(null);
  useEffect(() => {
    if (loading) ref.current = instanceId;
  }, [instanceId, loading]);

  if (!instanceId) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Typography>{t("Please select a Workflow Instance.")}</Typography>
      </Grid>
    );
  }
  if (ref.current !== instanceId || definitionLoading) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Grid container>
        {!hideFunctionalities && (
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            alignContent="center"
            className={classes.autoRefresh}
          >
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ "aria-label": t("Auto Refresh") }}
                    checked={Boolean(autoRefresh)}
                    onChange={() => {
                      autoRefresh === 0
                        ? setAutoRefresh(60000)
                        : setAutoRefresh(0);
                    }}
                  />
                }
                label={t("Auto Refresh")}
              />
            </FormControl>
            <TextField
              label="Auto Refresh Time"
              type="number"
              size="small"
              InputProps={{
                classes: { input: classes.alignRight },
                endAdornment: (
                  <InputAdornment position="end">{t("seconds")}</InputAdornment>
                ),
                inputProps: {
                  "aria-label": t("Auto Refresh Time"),
                },
              }}
              className={classes.autoRefreshTime}
              value={autoRefresh / 1000}
              onChange={({ target: { value } }) => {
                if (value > 0 && value < 5184000) setAutoRefresh(value * 1000);
              }}
              disabled={!autoRefresh}
            />
          </Grid>
        )}
        {variables &&
          graphData &&
          graphData.map(({ legend, ...rest }, index) => {
            const legends = legend.map((legend) => ({
              id: legend,
              displayName: variables.variable.find(
                (variable) => variable.name === legend,
              ).displayName,
            }));
            return (
              <Fragment key={index}>
                <Grid item xs={12} container className={classes.graphTitle}>
                  {(!hideFunctionalities || graphData.length > 1) && (
                    <Grid item xs={10} container alignContent="center"></Grid>
                  )}
                  {!hideFunctionalities && (
                    <Grid
                      item
                      xs={2}
                      container
                      justifyContent="flex-end"
                      alignContent="center"
                    >
                      <IconButton
                        id={`WORKFLOW_LINE_GRAPH_DELETE_${index}`}
                        aria-label={t("Delete")}
                        onClick={() => {
                          setGraphData(graphData.filter((e, i) => i !== index));
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.graphWrapper}>
                  <WorkflowPieChart
                    autoRefresh={autoRefresh}
                    {...rest}
                    legend={legends}
                    variableValues={variableValues}
                  />
                  <Divider className={classes.divider} />
                </Grid>
              </Fragment>
            );
          })}
        {!hideFunctionalities && variables && (
          <>
            <Grid
              item
              xs={9}
              container
              justifyContent="flex-start"
              alignContent="center"
            >
              <BasicSelect
                label={t("Target Variables (Integer)")}
                variant="outlined"
                name={t("Target Variables (Integer)")}
                value={selectedVariables}
                onChange={({ target: { value } }) => {
                  setSelectedVariables(value);
                }}
                fullWidth
                multiple
              >
                {variables.variable
                  .filter(
                    (variable) =>
                      variable.type === "Integer" &&
                      !Variable.getTableValData(variable.name).isTable,
                  )
                  .map((variable) => (
                    <MenuItem value={variable.name} key={variable.name}>
                      {variable.displayName}
                    </MenuItem>
                  ))}
              </BasicSelect>
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignContent="center"
            >
              <Button
                aria-label={t("Add Graph")}
                onClick={() => {
                  setGraphData(
                    graphData.concat({
                      legend: selectedVariables,
                    }),
                  );
                  setSelectedVariables([]);
                }}
                disabled={selectedVariables.length === 0}
              >
                {t("Add Graph")}
                <PlusIcon className={classes.plusIcon} />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

WorkflowPieChartWrapper.propTypes = {
  graphData: PropTypes.array,
  autoRefresh: PropTypes.number,
  workflowUri: PropTypes.string,
  instanceId: PropTypes.string,
  hideFunctionalities: PropTypes.bool,
};

export default WorkflowPieChartWrapper;
