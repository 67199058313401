import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import reduxForm from "redux-form/lib/reduxForm";
import SubmissionError from "redux-form/lib/SubmissionError";
import flow from "lodash/flow";
import omit from "lodash/omit";

import { natures } from "msa2-ui/src/Constants";
import { getFormValues, getActiveField } from "msa2-ui/src/store/form";
import {
  getAvailableSubtenants,
  getAvailableTenants,
  getSelectedSubtenant,
  getSelectedTenant,
  getManufacturers,
  getManufacturersById,
  fetchManagedEntities,
  getCategoriesWithIds,
} from "msa2-ui/src/store/designations";
import { getToken } from "msa2-ui/src/store/auth";
import useDialog from "msa2-ui/src/hooks/useDialog";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  getManagedEntityFields,
  createManagedEntity,
} from "msa2-ui/src/api/managedEntity";
import { getSubtenantsByTenant } from "msa2-ui/src/api/subtenant";

import { Divider, Grid, Paper, MenuItem } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import ManagedEntityToolbar from "../ManagedEntityToolbar";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import Modal from "msa2-ui/src/components/modal/Modal";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import {
  sections,
  fields,
  validate,
  managementSection,
  getAdvancedSection,
  renderDynamicFields,
  useSetCurrentSection,
  useFocusFirstFieldInSection,
  useReturnToPreviousPage,
} from "msa2-ui/src/components/formSection/FormCommon";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import DeviceAdapterSelector from "msa2-ui/src/components/DeviceAdapterSelector";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const form = "ManagedEntityCreate";

const useStyles = makeStyles((theme) => ({
  category: {
    padding: 16,
    width: "100%",
  },
  vendor: {
    padding: 16,
    width: "100%",
  },
  model: {
    padding: 16,
    width: "100%",
  },
}));

const ManagedEntityCreate = ({
  change,
  destroy,
  match,
  handleSubmit,
  history,
  t,
  initialize,
  initialized,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const subtenants = useSelector(getAvailableSubtenants);
  const tenants = useSelector(getAvailableTenants);
  const selectedSubtenant = useSelector(getSelectedSubtenant);
  const selectedTenant = useSelector(getSelectedTenant);
  const manufacturers = useSelector(getManufacturers);
  const manufacturersById = useSelector(getManufacturersById);
  const categories = useSelector(getCategoriesWithIds);

  const activeField = useSelector(getActiveField(form));
  const subtenantId = useSelector(getFormValues(form, fields.subtenant));
  const tenant = useSelector(getFormValues(form, fields.tenant));
  const category = useSelector(getFormValues(form, fields.category));
  const vendor = useSelector(getFormValues(form, fields.vendor));
  const model = useSelector(getFormValues(form, fields.model));

  const [submitting, setSubmitting] = useState(false);
  const [section, setSection] = useState(sections.TENANT_AND_SUBTENANT);
  const [deviceAdapterError, setDeviceAdapterError] = useState(null);
  const [subtenantsForTenant, setSubtenantsForTenant] = useState(subtenants);

  const formValues = useSelector(getFormValues(form));
  const dependencies = [{ name: "reporting", dependsOn: "logMoreEnabled" }];

  if (formValues) {
    dependencies.forEach((dependency) => {
      const { name: field, dependsOn: masterField } = dependency;

      if (!formValues[masterField] && formValues[field]) {
        dispatch(change(field, false));
      }
    });
  }

  const [subtenantsLoading, , subtenantsResponse = []] = useApi(
    getSubtenantsByTenant,
    {
      tenantPrefix: tenant,
    },
    !tenant,
  );

  const [fieldsLoading, , fieldsResponse] = useApi(
    getManagedEntityFields,
    {
      manufacturerId: vendor,
      modelId: model,
    },
    !(vendor && model),
  );

  useDeepCompareEffect(() => {
    if (tenant) {
      setSubtenantsForTenant(subtenantsResponse);
    }
    if (subtenantsResponse.length === 1) {
      change(fields.subtenant, subtenantsResponse[0].id);
    }
  }, [change, subtenantsResponse, tenant]);

  useDeepCompareEffect(() => {
    if (!initialized && manufacturers && categories) {
      const currentCategoryKey = Object.keys(categories)[0];
      const currentCategory = categories[currentCategoryKey];
      const firstManufacturer =
        currentCategory?.manufacturers[0] ?? manufacturers[0];

      const currentManufacturer =
        manufacturersById[firstManufacturer].manufacturerId;
      const currentModel = manufacturersById[firstManufacturer].models[0];

      initialize({
        [fields.tenant]:
          selectedTenant.value || selectedSubtenant.operatorPrefix || "",
        [fields.subtenant]: selectedSubtenant.id || "",
        [fields.category]: null,
        [fields.vendor]: currentManufacturer,
        [fields.model]: currentModel,
        [fields.nature]: Object.keys(natures)[0],
      });
    }
  }, [
    initialize,
    initialized,
    manufacturers,
    manufacturersById,
    model,
    selectedSubtenant.id,
    selectedSubtenant.value,
    selectedTenant.value,
  ]);

  const returnToPreviousPage = useReturnToPreviousPage(
    history,
    match.url,
    submitting,
  );

  const submitForm = async (values) => {
    if (!vendor || !model) {
      setDeviceAdapterError("Please select Vendor and Model");
      return;
    } else {
      setDeviceAdapterError(null);
    }
    const errors = validate(values);
    if (Object.values(errors).length) {
      throw new SubmissionError(errors);
    }

    setSubmitting(true);
    const managedEntityData = omit(values, [fields.tenant, fields.subtenant]);
    const [createError, createResponse] = await createManagedEntity({
      subtenantId,
      body: managedEntityData,
      token,
    });
    setSubmitting(false);

    if (createError) {
      enqueueSnackbar(createError.getMessage(), {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    } else {
      destroy();
      history.push({
        pathname: `/integration/managed-entities/${createResponse.id}`,
        state: { meCreated: createResponse.name },
      });
    }
    // update ME list in designation
    dispatch(fetchManagedEntities());
  };

  const advancedSection = getAdvancedSection(fieldsResponse);
  useSetCurrentSection(setSection, activeField, advancedSection);
  const [firstFields, handleSectionButtonClick] = useFocusFirstFieldInSection(
    setSection,
  );

  const handleSelectDeviceAdapter = (value) => {
    const { category, vendor, model } = value;

    change(fields.category, category);
    change(fields.vendor, vendor ? vendor.manufacturerId : null);
    change(fields.model, model ? model.modelId : null);

    if (vendor && model) {
      setDeviceAdapterError(null);
    }
  };

  const [showDiscardDialog, DiscardDialog] = useDialog();

  const handleOnChangeSubtenant = (subtenantId) => {
    const operatorPrefix = subtenantsForTenant.find(
      ({ id }) => subtenantId === id,
    )?.operatorPrefix;
    change(fields.tenant, operatorPrefix);
  };

  const renderManagementInformation = () => {
    return !vendor || !model ? (
      <Typography variant="body1" color="textSecondary">
        {t("Please select Vendor and Model")}
      </Typography>
    ) : (
      fieldsResponse &&
        renderDynamicFields(
          managementSection,
          fieldsResponse,
          firstFields[sections.MANAGEMENT_INFORMATION],
        )
    );
  };

  const renderAdvancedInformation = () => {
    return !vendor || !model ? (
      <Typography variant="body1" color="textSecondary">
        {t("Please select Vendor and Model")}
      </Typography>
    ) : (
      fieldsResponse &&
        renderDynamicFields(
          advancedSection,
          fieldsResponse,
          firstFields[sections.ADVANCED_INFORMATION],
          formValues,
          dependencies,
        )
    );
  };

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={() => {
          returnToPreviousPage();
          destroy();
        }}
      />
      <Modal onClose={() => returnToPreviousPage({ meDraftSaved: true })}>
        <ManagedEntityToolbar
          title={t("Create Managed Entity")}
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Create Managed Entity")}
          discardButtonLabel={t("Discard Changes")}
          disabled={submitting}
          onSave={handleSubmit(submitForm)}
          onDiscard={showDiscardDialog}
          onClose={() => returnToPreviousPage({ meDraftSaved: true })}
        />
        <ModalContent>
          <Grid item xs={3} md={2}>
            <Sidebar>
              <Sidebar.ListItem
                title={t("Tenant and Subtenant")}
                id="ME_CREATE_SIDEBAR_TENANT_SUBTENANT"
                selected={section === sections.TENANT_AND_SUBTENANT}
                section={sections.TENANT_AND_SUBTENANT}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Basic Information")}
                id="ME_CREATE_SIDEBAR_BASIC_INFORMATION"
                selected={section === sections.BASIC_INFORMATION}
                section={sections.BASIC_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Administrative Information")}
                id="ME_CREATE_SIDEBAR_ADMINISTRATIVE_INFORMATION"
                selected={section === sections.ADMINISTRATIVE_INFORMATION}
                section={sections.ADMINISTRATIVE_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Management Information")}
                id="ME_CREATE_SIDEBAR_MANAGEMENT_INFORMATION"
                selected={section === sections.MANAGEMENT_INFORMATION}
                section={sections.MANAGEMENT_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Advanced Information")}
                id="ME_CREATE_SIDEBAR_ADVANCED_INFORMATION"
                selected={section === sections.ADVANCED_INFORMATION}
                section={sections.ADVANCED_INFORMATION}
                onClick={handleSectionButtonClick}
              />
            </Sidebar>
          </Grid>
          <ScrollingContainer>
            <Paper className={classnames(commonClasses.commonPaperNoPadding)}>
              <FormSection
                active={section === sections.TENANT_AND_SUBTENANT}
                title={t("Tenant and Subtenant")}
                loading={submitting || subtenantsLoading}
              >
                <Grid item xs={6} md={4}>
                  <FormSelect
                    name={fields.tenant}
                    label={t(fields.tenant)}
                    defaultValue={
                      selectedTenant.value ||
                      selectedSubtenant.operatorPrefix ||
                      ""
                    }
                    inputProps={{
                      ref: firstFields[sections.TENANT_AND_SUBTENANT],
                      id: t("TenantFormField"),
                    }}
                  >
                    {tenants.map(({ label, value }, index) => (
                      <MenuItem value={value} key={`${index}_${value}`}>
                        {label}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormSelect
                    name={fields.subtenant}
                    label={t(fields.subtenant)}
                    defaultValue={selectedSubtenant.id || ""}
                    onChange={handleOnChangeSubtenant}
                    inputProps={{ id: t("SubtenantFormField") }}
                  >
                    {subtenantsForTenant.map(({ id, label }) => (
                      <MenuItem value={id} key={id}>
                        {label}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.BASIC_INFORMATION}
                title={t("Basic Information")}
                loading={submitting || !manufacturers}
              >
                <DeviceAdapterSelector
                  error={deviceAdapterError}
                  onSelectDeviceAdapter={handleSelectDeviceAdapter}
                  category={category}
                  vendor={vendor}
                  model={model}
                  categoryProps={{
                    placeholder: t("Filter by Category..."),
                    inputRef: firstFields[sections.BASIC_INFORMATION],
                  }}
                  vendorProps={{ placeholder: t("Select Vendor...") }}
                  modelProps={{ placeholder: t("Select Model...") }}
                  categoryGridProps={{
                    xs: 6,
                    md: 4,
                    className: classes.category,
                  }}
                  vendorGridProps={{ xs: 6, md: 4, className: classes.vendor }}
                  modelGridProps={{ xs: 6, md: 4, className: classes.model }}
                />
                <Grid item xs={6} md={4}>
                  <FormSelect name={fields.nature} label={t(fields.nature)}>
                    {Object.keys(natures).map((key) => (
                      <MenuItem value={key} key={key}>
                        {t(natures[key])}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    name={fields.externalReference}
                    label={t("External Reference")}
                    type="text"
                  />
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.ADMINISTRATIVE_INFORMATION}
                title={t("Administrative Information")}
                loading={submitting}
              >
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    label={t(fields.name)}
                    type="text"
                    name={fields.name}
                    required
                    inputProps={{
                      ref: firstFields[sections.ADMINISTRATIVE_INFORMATION],
                    }}
                  />
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.MANAGEMENT_INFORMATION}
                title={t("Management Information")}
                loading={
                  vendor &&
                  model &&
                  (submitting || fieldsLoading || !fieldsResponse)
                }
              >
                {renderManagementInformation()}
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.ADVANCED_INFORMATION}
                title={t("Advanced Information")}
                loading={
                  vendor &&
                  model &&
                  (submitting || fieldsLoading || !fieldsResponse)
                }
              >
                {renderAdvancedInformation()}
              </FormSection>
            </Paper>
          </ScrollingContainer>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

ManagedEntityCreate.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default flow(
  withRouter,
  withTranslation(),
  reduxForm({ form, validate, destroyOnUnmount: false }),
)(ManagedEntityCreate);
