import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { change } from "redux-form";

import { getFormValues } from "msa2-ui/src/store/form";

import { CircularProgress, Grid } from "@material-ui/core";

import MicroserviceCommandTemplate from "./MicroserviceCommandTemplate";
import ImportCommandForm from "./ImportCommandForm";
import CreateCommandForm from "./CreateCommandForm";
import UpdateCommandForm from "./UpdateCommandForm";
import ListCommandForm from "./ListCommandForm";
import ReadCommandForm from "./ReadCommandForm";
import DeleteCommandForm from "./DeleteCommandForm";
import ConstraintCommandForm from "./ConstraintCommandForm";

const contentTabs = [
  {
    id: "IMPORT",
    title: "Import",
    subtitle:
      "Define the import rules of the microservice from the configuration",
    component: ImportCommandForm,
  },
  {
    id: "UPDATE",
    title: "Update",
    subtitle:
      "Define the update rules of the microservice from the configuration",
    component: UpdateCommandForm,
  },
  {
    id: "CONSTRAINT",
    title: "Constraint",
    subtitle:
      "Define the constraint rules of the microservice from the configuration",
    component: ConstraintCommandForm,
  },
  {
    id: "CREATE",
    title: "Create",
    subtitle:
      "Define the create rules of the microservice from the configuration",
    component: CreateCommandForm,
  },
  {
    id: "DELETE",
    title: "Delete",
    subtitle:
      "Define the delete rules of the microservice from the configuration",
    component: DeleteCommandForm,
  },
  {
    id: "READ",
    title: "Read",
    subtitle:
      "Define the read rules of the microservice from the configuration",
    component: ReadCommandForm,
  },
  {
    id: "LIST",
    title: "List",
    subtitle:
      "Define the list rules of the microservice from the configuration",
    component: ListCommandForm,
  },
];

export const CommandTabsContent = ({
  isActive,
  onEditVariableClicked,
  microserviceVariables,
  form,
  deviceId,
  isSubmitting,
  submitForm,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commands = useSelector(getFormValues(form, "command"));
  return contentTabs.map(
    ({ id, component: Component, title, subtitle }, index) => {
      const show = isActive === title;

      if (show) {
        if (!commands)
          return (
            <Grid container justifyContent="center" alignContent="center">
              <CircularProgress />
            </Grid>
          );
        const commandIndex = commands.findIndex(({ name }) => name === id);
        // This function is to add command a key in redux-store
        // in case there is none. It can happen to MS from <= v17
        const onFirstChange = (_, newValue, previousValue, name) => {
          const [, , key] = name.split(".");
          dispatch(
            change(form, ["command", commands.length].join("."), {
              name: id,
              [key]: newValue,
            }),
          );
        };
        return (
          <MicroserviceCommandTemplate
            onEditVariableClicked={onEditVariableClicked}
            variables={microserviceVariables}
            title={t(title)}
            subtitle={t(subtitle)}
            key={index}
          >
            <Component
              form={form}
              submitting={isSubmitting}
              submitForm={submitForm}
              deviceId={deviceId}
              isVisible={isActive === title}
              onEditVariableClicked={onEditVariableClicked}
              commandIndex={commandIndex}
              onChange={commandIndex < 0 ? onFirstChange : undefined}
            />
          </MicroserviceCommandTemplate>
        );
      }
      return <Fragment key={index}></Fragment>;
    },
  );
};
