import produce from "immer";
import { maybeUpdateDraftWithPaths } from "msa2-ui/src/utils/immer";
import Repository from "./Repository";
import omit from "lodash/omit";
import Variable from "msa2-ui/src/services/Variable";
import WorkflowForm from "msa2-ui/src/services/WorkflowForm";

const KEY_VARIABLE = "service_id";

const serviceTaskType = {
  python: {
    language: "PYTHON",
    fileExtension: ".py",
    type: "python",
    templateUri: "Process/Reference/Template/template.py",
    msCallUri: "Process/Reference/Template/ms_call_template.py",
  },
  php: {
    language: "PHP",
    fileExtension: ".php",
    type: "php",
    templateUri: "Process/Reference/Template/template.php",
    msCallUri: "Process/Reference/Template/ms_call_template.php",
  },
};

const defaultObject = {
  variables: {
    variable: [],
  },
  example: {},
  process: [],
  information: {
    displayName: null,
    icon: null,
    description: null,
    category: null,
    displayField: "service_id",
    order: 10000,
    visibility: 5,
    serviceTaskType: serviceTaskType.python.type,
  },
  metaInformationList: [
    {
      type: "FILE",
      name: null,
      displayName: null,
      repositoryName: "Process",
      parentURI: null,
      fileType: "text",
      tag: null,
      comment: null,
      modelId: 0,
      vendorId: 0,
      uri: null,
      file: true,
    },
  ],
};

const setDefaultsOnExistingObject = (defaultObject, existingObject) =>
  produce(defaultObject, (defaultObjectDraft) => {
    const keysWithoutInformation = Object.keys(
      omit(defaultObject, ["information"]),
    );
    maybeUpdateDraftWithPaths(defaultObjectDraft, existingObject, [
      ...keysWithoutInformation,
      ...Object.keys(defaultObjectDraft.information).map(
        (key) => `information.${key}`,
      ),
    ]);
  });

const make = (existingObject) => {
  if (!existingObject) {
    return defaultObject;
  }
  return setDefaultsOnExistingObject(defaultObject, existingObject);
};

function getVariable(formValues) {
  return formValues.variables?.variable?.map((variable) => ({
    ...variable,
    name: `${Variable.variablePrefix}.${Variable.removePrefix(
      variable.name.trim(),
    )}`,
  }));
}

const formatDataForEditApi = (workflowFormValues) => {
  return omit(
    {
      ...workflowFormValues,
      variables: {
        variable: getVariable(workflowFormValues),
        frozen: 0,
      },
      process: workflowFormValues.process.map((process) => ({
        ...process,
        tasks: process.tasks === null ? [] : process.tasks,
      })),
    },
    WorkflowForm.localFields,
  );
};

const formatDataForCreateApi = (workflowFormValues) => {
  const formValuesClone = { ...workflowFormValues };
  const filename = Repository.convertStringToFilename(
    formValuesClone.information.displayName,
  );
  const repositoryName = formValuesClone.metaInformationList[0].repositoryName;
  const workflowDir = "workflows";
  return omit(
    {
      ...formValuesClone,
      variables: {
        variable: getVariable(formValuesClone),
        frozen: 0,
      },
      metaInformationList: [
        {
          ...formValuesClone.metaInformationList[0],
          name: `${filename}.xml`,
          displayName: `${filename}.xml`,
          parentURI: `${repositoryName}/${workflowDir}/${filename}`,
          uri: `${repositoryName}/${workflowDir}/${filename}/${filename}.xml`,
        },
      ],
    },
    WorkflowForm.localFields,
  );
};

const getProcessByPath = (processName, workflow) =>
  workflow.process?.find(({ name }) => name === processName);

export default {
  KEY_VARIABLE,
  make,
  formatDataForCreateApi,
  formatDataForEditApi,
  serviceTaskType,
  getProcessByPath,
};
