import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

import { buildRoute } from "msa2-ui/src/utils/urls";

import SectionTabs from "msa2-ui/src/components/SectionTabs";
import Logs from "./Logs";
import Alarms from "./Alarms";
import TriggeredAlarms from "./TriggeredAlarms";
import { AlarmContextProvider } from "./AlarmContext";

const tabs = [
  {
    path: "/alarms/triggered",
    displayName: "Alarms",
  },
  {
    path: "/alarms/logs",
    displayName: "Logs",
  },
  {
    path: "/alarms/manage",
    displayName: "Manage Alarms",
  },
];

export const AlarmsTabs = ({ count, endElements }) => {
  const { t } = useTranslation();
  const logsView = useSelector(
    getDelegationProfile(delegationProfileTypes.LOGS, "general.view"),
  );
  const alarmsView = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "alarms.view"),
  );
  const manageAlarmsView = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "manageAlarms.view"),
  );
  const tabsWithCounts = tabs
    .filter((tab) => {
      if (tab.path === "/alarms/manage" && manageAlarmsView === true) {
        return true;
      }
      if (tab.path === "/alarms/logs" && logsView === true) {
        return true;
      }
      if (tab.path === "/alarms/triggered" && alarmsView === true) {
        return true;
      }
      return false;
    })
    .map((tab) => {
      const [path, value] = count;
      if (tab.path === path) {
        return {
          ...tab,
          displayName: t(tab.displayName),
          count: value,
        };
      }
      return {
        ...tab,
        displayName: t(tab.displayName),
      };
    });
  return <SectionTabs tabs={tabsWithCounts} endElements={endElements} />;
};

export default () => {
  const { path } = useRouteMatch();
  const canAccessLogs = useSelector(
    getDelegationProfile(delegationProfileTypes.LOGS, "general.view"),
  );
  const canAccessAlarms = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "alarms.view"),
  );
  const canManageAlarms = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "manageAlarms.view"),
  );

  const handleDefaultRoute = (
    canAccessAlarms,
    canAccessLogs,
    canManageAlarms,
  ) => {
    if (canAccessAlarms) {
      return buildRoute(path, "triggered");
    }
    if (canAccessLogs) {
      return buildRoute(path, "logs");
    }
    if (canManageAlarms) {
      return buildRoute(path, "manage");
    }
    return "/dashboard";
  };

  const defaultRoute = handleDefaultRoute(
    canAccessAlarms,
    canAccessLogs,
    canManageAlarms,
  );

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={defaultRoute} />
      </Route>
      <AuthorizedRoute
        guard={canAccessLogs}
        redirect="/dashboard"
        path={buildRoute(path, "logs")}
        render={() => <Logs sectionTabs={AlarmsTabs} />}
      />
      <AuthorizedRoute
        guard={canAccessAlarms}
        redirect="/dashboard"
        path={buildRoute(path, "triggered")}
        render={() => (
          <AlarmContextProvider>
            <TriggeredAlarms sectionTabs={AlarmsTabs} />
          </AlarmContextProvider>
        )}
      />
      <AuthorizedRoute
        guard={canManageAlarms}
        redirect="/dashboard"
        path={buildRoute(path, "manage")}
        render={() => <Alarms sectionTabs={AlarmsTabs} />}
      />
      <Redirect to={defaultRoute} />
    </Switch>
  );
};
