import produce from "immer";
import { maybeUpdateDraft } from "msa2-ui/src/utils/immer";
import get from "lodash/get";

import Variable from "msa2-ui/src/services/Variable";
import Repository from "msa2-ui/src/services/Repository";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";

const COMMANDS = {
  IMPORT: {
    id: "IMPORT",
    msCall: true,
    defaultCommand: {
      operation: null,
      name: "IMPORT",
      postTemplate: null,
      parser: { section: [{ regexp: [] }], lines: { line: [], ignore: [] } },
    },
  },
  UPDATE: {
    id: "UPDATE",
    msCall: true,
    runTimeLabel: "Edit",
    defaultCommand: {
      operation: null,
      name: "UPDATE",
    },
  },
  CONSTRAINT: {
    id: "CONSTRAINT",
    defaultCommand: {
      operation: null,
      name: "CONSTRAINT",
    },
  },
  CREATE: {
    id: "CREATE",
    msCall: true,
    runTimeLabel: "Add Row",
    defaultCommand: {
      operation: null,
      name: "CREATE",
    },
  },
  DELETE: {
    id: "DELETE",
    msCall: true,
    runTimeLabel: "Remove",
    defaultCommand: {
      operation: null,
      name: "DELETE",
    },
  },
  READ: {
    id: "READ",
    defaultCommand: {
      output: null,
      name: "READ",
    },
  },
  LIST: {
    id: "LIST",
    defaultCommand: {
      output: null,
      name: "LIST",
    },
  },
};

const type = {
  import: "IMPORT",
  update: "UPDATE",
  constraint: "CONSTRAINT",
  create: "CREATE",
  delete: "DELETE",
  read: "READ",
  list: "LIST",
};

const runtimeTypes = {
  update: "UPDATE",
  create: "CREATE",
  delete: "DELETE",
  duplicate: "DUPLICATE",
  read: "READ",
  list: "LIST",
};

const setDefaultsOnExistingCommand = (
  currentType,
  defaultCommand,
  existingCommand,
) =>
  produce(defaultCommand, (defaultCommandDraft) => {
    if (currentType === type.import) {
      maybeUpdateDraft(defaultCommandDraft, existingCommand, "xpath");
      maybeUpdateDraft(defaultCommandDraft, existingCommand, "postTemplate");
      maybeUpdateDraft(defaultCommandDraft, existingCommand, "parser.section");
      maybeUpdateDraft(
        defaultCommandDraft,
        existingCommand,
        "parser.lines.line",
      );
      maybeUpdateDraft(
        defaultCommandDraft,
        existingCommand,
        "parser.lines.ignore",
      );
    }
    if ([type.create, type.update, type.delete].includes(currentType)) {
      maybeUpdateDraft(defaultCommandDraft, existingCommand, "xpath");
      maybeUpdateDraft(defaultCommandDraft, existingCommand, "rest");
    }

    if ([type.list, type.read].includes(currentType)) {
      maybeUpdateDraft(defaultCommandDraft, existingCommand, "output");
    } else {
      maybeUpdateDraft(defaultCommandDraft, existingCommand, "operation");
    }
  });

/**
 * Creates a new object of the command type requested
 *
 * If an existing object is supplied it will return that instead
 * with any missing fields filled in
 *
 * @param {string} type - the type of command you want
 * @param {object} existingCommand - an existing object command that may be incomplete
 */
const make = (type, existingCommand) => {
  const defaultCommand = COMMANDS[type].defaultCommand;
  if (!existingCommand) {
    return defaultCommand;
  }
  return setDefaultsOnExistingCommand(type, defaultCommand, existingCommand);
};

const getCommandIndex = (commands, commandName) =>
  commands.findIndex(({ name }) => name === commandName);

const buildConstraintByCompositeKey = (formValues) => {
  const formVariableValues = get(formValues, MicroserviceForm.fields.variables);
  const constraintIndex = getCommandIndex(
    formValues.command,
    COMMANDS.CONSTRAINT.id,
  );

  const constraint =
    constraintIndex >= 0
      ? formValues.command[constraintIndex]
      : make(type.constraint);
  const { operation } = constraint;

  const compositeVariables = formVariableValues.filter(
    ({ isCompositeKey }) => isCompositeKey,
  );

  const CONSTRAINT_DEFAULT_COMMENT = (prefix) =>
    `{* ${prefix} - Auto Generated for Composite Key Variables. *}`;
  const CONSTRAINT_COMMENT_BEGIN = CONSTRAINT_DEFAULT_COMMENT("BEGIN");
  const CONSTRAINT_COMMENT_END = CONSTRAINT_DEFAULT_COMMENT("END");

  let validationCode = [];
  if (operation) {
    // remove existing auto generated validation
    validationCode = operation
      .replace(/\r/g, "")
      .replace(/\n*$/, "")
      .split("\n");

    const startIndex = validationCode.findIndex(
      (line) => line === CONSTRAINT_COMMENT_BEGIN,
    );
    const endIndex = validationCode.findIndex(
      (line) => line === CONSTRAINT_COMMENT_END,
    );
    if (startIndex >= 0 && endIndex >= 0) {
      validationCode.splice(startIndex, endIndex - startIndex + 1);
    }
  }

  if (compositeVariables.length) {
    // Add generated validation
    const microserviceName = Repository.stripFileExtensionFromString(
      get(formValues, MicroserviceForm.fields.filename),
    );
    const comparisonCode = compositeVariables
      .map(({ name }) => {
        const id = Variable.removePrefix(name);
        return `($row.${id} == $params.${id})`;
      })
      .join(" && ");
    const displayNames = compositeVariables
      .map(({ displayName }) => displayName)
      .join(" and ");
    const codeTemplate = [
      CONSTRAINT_COMMENT_BEGIN,
      `{foreach $${microserviceName} as $row}`,
      "{if $row.object_id != $params.object_id}",
      `{if ${comparisonCode}}`,
      `The combination for ${displayNames} should be unique between all objects.`,
      "{break}",
      "{/if}",
      "{/if}",
      "{/foreach}",
      CONSTRAINT_COMMENT_END,
    ];
    validationCode = validationCode.concat(codeTemplate);
  }

  validationCode = validationCode.join("\n") || null;
  constraint.operation = validationCode;
  return {
    constraint,
    operation: constraint.operation,
    index: constraintIndex >= 0 ? constraintIndex : formValues.command.length,
  };
};

export default {
  make,
  type,
  runtimeTypes,
  COMMANDS,
  buildConstraintByCompositeKey,
};
