import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import isObject from "lodash/isObject";
import {
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import EcoSharpIcon from "@material-ui/icons/EcoSharp";
import { useDebouncedCallback } from "msa2-ui/src/hooks/useDebouncedCallback";
import { GREEN_REGIONS } from "msa2-ui/src/Constants";

const useStyles = makeStyles(({ colors }) => ({
  chip: {
    marginLeft: 10,
  },
  greenRegionIcon: {
    color: colors.green4,
  },
}));

const ComboBox = ({
  label,
  options,
  value,
  onChange,
  disabled,
  searchOptions,
  variableName,
  ...props
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredOptions, setFilterOptions] = useState(options);
  const ref = useRef(null);
  const searchOptionsHandler = useDebouncedCallback(searchOptions, 500);

  /**
   * This is Main component to return in this file
   *
   * @returns {JSX}
   */
  const renderTextField = () => {
    return (
      <TextField
        ref={ref}
        variant={"outlined"}
        label={label}
        value={value}
        onClick={({ currentTarget }) => {
          !disabled && setAnchorEl(currentTarget);
        }}
        onChange={({ currentTarget, target: { value } }) => {
          setAnchorEl(currentTarget);
          onChange(value);
          if (searchOptions) {
            // pass the typed term to callback
            searchOptionsHandler(value);
          } else {
            setFilterOptions(
              options.filter((option) =>
                isObject(option)
                  ? option.value?.includes(value) ||
                    option.label?.includes(value)
                  : option.includes(value),
              ),
            );
          }
        }}
        disabled={disabled}
        {...props}
      />
    );
  };

  /**
   * [Optional]
   * As of now we have added only one region picker component. In future we may add more
   *
   * @returns {JSX}
   */
  const renderExtraComponent = () => {
    if (!Object.keys(GREEN_REGIONS).includes(variableName)) {
      return <></>;
    }

    return (
      <IconButton
        href={GREEN_REGIONS[variableName].link}
        target="_blank"
        className={classes.greenRegionIcon}
      >
        <EcoSharpIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Grid item container>
        <Grid item xs>
          {renderTextField()}
        </Grid>
        <Grid item>{renderExtraComponent()}</Grid>
      </Grid>
      <Popover
        id="COMBO_BOX_POPOVER"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        {/*
          if "searchOptions" callback is set, we don't use filtering inside of this component 
          because options should be filtered externally 
        */}
        {(searchOptions ? options : filteredOptions).map((option, i) => {
          const value = option.value ?? option;
          const label = option.label ?? option.value ?? option;
          return (
            <MenuItem
              key={i}
              value={value}
              style={{ width: ref.current?.clientWidth }}
              onClick={() => {
                onChange(value);
                setAnchorEl(null);
              }}
            >
              {label}
              {option.chip && (
                <Chip label={option.chip} className={classes.chip} />
              )}
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

ComboBox.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
      }),
    ]),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  searchOptions: PropTypes.func,
};

export default ComboBox;
