import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import flow from "lodash/flow";

import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import { Checkbox, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import VariableViewTableCells from "msa2-ui/src/components/variables/VariableViewTableCells";

const useStyles = makeStyles(({ darkMode, palette, colors }) => ({
  CREATE: {
    backgroundColor: alpha(palette.success.main, 0.1),
  },
  UPDATE: {
    backgroundColor: alpha(palette.warning.main, 0.1),
  },
  DELETE: {
    backgroundColor: alpha(palette.error.main, 0.1),
  },
  tableCellCheckbox: {
    width: 60,
    borderRight: `1px solid ${
      darkMode ? palette.text.hint : colors.greyLight2
    }`,
  },
}));

const MicroserviceConsoleTableBody = ({
  microserviceVariables,
  microserviceInstanceData,
  setSelectedMicroserviceKeys,
  selectedMicroserviceKeys = [],
  sort,
  shouldShowCheckbox = false,
  components,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const handleClick = (key) => {
    if (shouldShowCheckbox) {
      if (selectedMicroserviceKeys.includes(key)) {
        setSelectedMicroserviceKeys(
          selectedMicroserviceKeys.filter((e) => e !== key),
        );
      } else {
        setSelectedMicroserviceKeys(selectedMicroserviceKeys.concat(key));
      }
    } else {
      setSelectedMicroserviceKeys([key]);
    }
  };

  const renderTableRow = (entry) =>
    entry.map(([variableDataKey, variableData], rowIndex) => {
      const key = variableData.object_id;
      const isSelected = selectedMicroserviceKeys.includes(key);
      const hasPendingState = variableData?.PENDING_STATUS;
      return (
        <TableRow
          classes={{
            root: commonClasses.commonTableRow,
            selected: commonClasses.commonTableRowSelect,
          }}
          className={classnames(
            {
              [commonClasses.commonTableRowHighlight]: !hasPendingState,
            },
            {
              [classes[hasPendingState]]: hasPendingState,
            },
          )}
          key={`row_${rowIndex}`}
          selected={isSelected}
          onClick={() => {
            // (isClick) return;
            handleClick(key);
          }}
        >
          {shouldShowCheckbox && (
            <TableCell className={classes.tableCellCheckbox}>
              <Checkbox
                id={`MICROSERVICE_CONSOLE_TABLE_CHECKBOX_${rowIndex}`}
                color="primary"
                checked={isSelected}
                disabled={Boolean(hasPendingState)}
                onClick={() => {
                  handleClick(key);
                }}
              />
            </TableCell>
          )}
          <VariableViewTableCells
            variables={microserviceVariables}
            data={variableData}
            components={components}
          />
        </TableRow>
      );
    });

  return (
    <TableBody>
      {flow(
        (data) =>
          Object.entries(data).sort(([, a], [, b]) => a._order - b._order),
        renderTableRow,
      )(microserviceInstanceData)}
    </TableBody>
  );
};

MicroserviceConsoleTableBody.propTypes = {
  microserviceVariables: PropTypes.array.isRequired,
  microserviceInstanceData: PropTypes.object.isRequired,
  setSelectedMicroserviceKeys: PropTypes.func.isRequired,
  selectedMicroserviceKeys: PropTypes.array,
  sort: PropTypes.object,
  shouldShowCheckbox: PropTypes.bool,
};

export default MicroserviceConsoleTableBody;
