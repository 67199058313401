import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";

import {
  Chip,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  NoSsr,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Search from "@material-ui/icons/Search";
import createTheme from "msa2-ui/src/styles/theme";
import { connect } from "react-redux";

const styles = ({ darkMode, palette, spacing, colors }) => ({
  root: {
    flexGrow: 1,
  },
  inputText: {
    color: palette.text.primary,
  },
  input: {
    display: "flex",
    height: "2rem",
    background: palette.background.paper,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${spacing(0.5)}px ${spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: darkMode ? "transparent" : palette.text.secondary,
  },
  noOptionsMessage: {
    padding: `${spacing(1)}px ${spacing(2)}px`,
  },
  singleValue: {
    fontSize: "1rem",
    maxWidth: 180,
  },
  underlineInput: {
    "&:before": {
      borderColor: palette.background.hint,
    },
  },
  placeholder: {
    position: "absolute",
    left: 38,
    fontSize: "0.875rem",
    fontStyle: "italic",
  },
  paper: {
    fontSize: "1rem",
    zIndex: 1,
    left: 0,
    right: 0,
  },
  divider: {
    height: spacing(2),
  },
  listItemIcon: {
    marginRight: 10,
  },
  menuList: {
    boxShadow:
      "0 14px 16px 4px rgba(81, 97, 133, 0.13), 0 5px 8px 0 rgba(0, 0, 0, 0.15)",
    borderTop: "1px solid #E6EAEE",
    borderRadius: "0 0 5px 5px",
    padding: "8px 0",
    margin: 0,
    maxHeight: 300,
    overflowY: "scroll",
  },
});

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        disableUnderline: true,
        inputComponent,
        startAdornment: (
          <InputAdornment
            position="start"
            style={{
              margin: "0 6px",
            }}
          >
            <Search color="primary" />
          </InputAdornment>
        ),
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <Tooltip title={props.children} enterDelay={1000}>
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
          fontSize: "0.9375rem",
          padding: "12px 15px",
        }}
        {...props.innerProps}
        id={`SELECT_SEARCH_${props.data.id || props.innerProps.id}`}
      >
        {props.data.icon && (
          <ListItemIcon className={props.selectProps.classes.listItemIcon}>
            <props.data.icon />
          </ListItemIcon>
        )}
        {props.children}
      </MenuItem>
    </Tooltip>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      noWrap
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function MenuList(props) {
  return (
    <div
      id="SELECT_SEARCH_MENU_LIST"
      className={props.selectProps.classes.menuList}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
}

export class SelectSearch extends Component {
  handleChange = (value) => {
    const { id, onSelect } = this.props;
    onSelect(id, value);
  };

  render() {
    const {
      classes,
      options,
      noOptionText,
      selectedOption,
      multiOption,
      isClearable = true,
      theme,
    } = this.props;

    const components = {
      Control,
      Menu,
      MenuList,
      inputComponent,
      MultiValue,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
    };

    const msaTheme = createTheme(theme);
    const selectStyles = {
      clearIndicator: (base) => ({
        ...base,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        fontSize: "1rem",
        color: classes.inputText,
        padding: "0",
        "& input": {
          fontSize: "1rem",
        },
        "&:before": {
          borderColor: msaTheme.colors.greyLight1,
        },
      }),
      control: (base) => ({
        ...base,
        "&:hover": {
          cursor: "pointer",
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          {!multiOption ? (
            <Select
              classes={classes}
              styles={selectStyles}
              options={options}
              components={components}
              noOptionsMessage={(e) => (
                <Typography color="primary">
                  {noOptionText ? noOptionText : "No option found."}
                </Typography>
              )}
              value={selectedOption ? selectedOption : {}}
              onChange={this.handleChange}
              menuIsOpen={true}
              closeMenuOnSelect={false}
              blurInputOnSelect={true}
              closeMenuOnScroll={true}
              isClearable={isClearable}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          ) : (
            <Select
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label: "Label",
                InputLabelProps: {
                  shrink: true,
                },
              }}
              options={options}
              components={components}
              value={selectedOption}
              onChange={this.handleChange}
              isMulti
            />
          )}
        </NoSsr>
      </div>
    );
  }
}

SelectSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.designations.msaTheme,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(SelectSearch),
);
