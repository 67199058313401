import {
  TableCell,
  Typography,
  Link,
  ImageList,
  ImageListItem,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { roles } from "msa2-ui/src/store/auth";
import TableRow from "msa2-ui/src/components/TableRow";

const getRoleName = (roleId) => {
  const userRole = roles.find((role) => role.id === roleId);
  return userRole["name"] || roleId;
};

const AuditLogTableRow = (entity) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const [isOpen, setIsOpen] = useState(false);
  const logPropertiesInTable = [
    "timestamp",
    "actor_id",
    "actor_login",
    "actor_role",
    "source_ip",
    "description",
  ];
  const logPropertiesForDetails = ["request", "requestBody", "response"];
  const { entity: log } = entity;

  const handlePropertyValue = (property) => {
    switch (property) {
      case "timestamp": {
        return displayMonthDayYearTimeDate(log[property]);
      }

      case "actor_role": {
        return getRoleName(log["actor_role"]);
      }
      case "requestBody":
      case "response": {
        return JSON.stringify(log[property]);
      }

      default: {
        return log[property];
      }
    }
  };

  return (
    <>
      <TableRow>
        {logPropertiesInTable.map((property, index) => (
          <TableCell
            key={index}
            className={commonClasses.commonTableCellDefault}
          >
            <Typography
              variant="h4"
              className={commonClasses.commonTableSecondary}
              id={`AUDIT_LOG_${property.toUpperCase}_${index}`}
            >
              {handlePropertyValue(property)}
            </Typography>
          </TableCell>
        ))}
        <TableCell className={commonClasses.commonTableCellDefault}>
          <Typography
            className={commonClasses.commonTableSecondary}
            id={`AUDIT_LOG_HIDE_SHOW_DETAIL`}
          >
            <Link
              className={commonClasses.commonLink}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? t("Hide") : t("Details")}
            </Link>
          </Typography>
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <TableCell colSpan={logPropertiesInTable.length - 1}>
            <ImageList cols={12} gap={2} rowHeight="auto">
              {logPropertiesForDetails.map((property) => (
                <ImageListItem cols={6} key={property}>
                  <Typography
                    id={`AUDIT_LOG_DETAIL_${property.toUpperCase}`}
                    variant="body2"
                    color="textSecondary"
                    style={{ padding: 4, wordBreak: "break-word" }}
                  >
                    <strong>{property}</strong>:{handlePropertyValue(property)}
                  </Typography>
                </ImageListItem>
              ))}
            </ImageList>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default AuditLogTableRow;
