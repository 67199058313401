import React from "react";
import Select from "react-select/creatable";
import MsaSelectBase from "./MsaSelectBase";

const MsaSelectCreatable = ({ ...props }) => {
  return <MsaSelectBase {...props} select={Select} />;
};

MsaSelectCreatable.propTypes = MsaSelectBase.propTypes;
export default MsaSelectCreatable;
