import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { buildRoute } from "msa2-ui/src/utils/urls";

import { sortOrder } from "msa2-ui/src/Constants";
import { getIsDeveloper, getIsRootUser } from "msa2-ui/src/store/auth";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";

import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import {
  Create as EditIcon,
  Timeline as WorkflowIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import AlertBar from "msa2-ui/src/components/AlertBar";
import AutomationAttachDialog from "./AutomationAttachDialog";
import AutomationSubtenantsDialog from "./AutomationSubtenantsDialog";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getToken } from "msa2-ui/src/store/auth";
import { deleteWorkflow } from "msa2-ui/src/api/workflow";
import { useSnackbar } from "notistack";
import DeleteWorkflowIcon from "./DeleteWorkflowIcon";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { downloadArchive } from "msa2-ui/src/api/repository";
import Repository from "msa2-ui/src/services/Repository";
import classNames from "classnames";
import AutomationChangeOwnerDialog from "../../AutomationChangeOwnerDialog";

const useStyles = makeStyles(({ palette, typography }) => ({
  tableCellAsLink: {
    fontSize: 13,
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
    cursor: "pointer",
  },
  tableCellAsText: {
    fontSize: 13,
    color: palette.text.secondary,
  },
  columnWorkflow: {
    width: "41%",
  },
}));

const tableHeader = [
  {
    id: "displayName",
    name: "Workflow Name",
    align: "left",
    active: true,
  },
  {
    id: "version",
    name: "Version",
    align: "left",
    active: true,
  },
  {
    id: "timestamp",
    name: "Date Modified",
    align: "left",
    active: true,
  },
  {
    id: "numCustomers",
    name: "Added to",
    align: "left",
    active: true,
  },
  {
    id: "owner",
    name: "Owner",
    align: "left",
    active: true,
  },
  {
    id: "blank2",
    name: "",
    align: "left",
    active: false,
  },
];

const AutomationRepository = ({
  workflows,
  workflowsLoading,
  workflowsCount,
  filterState,
  filterActions,
  apiError,
  reloadWorkflows,
  onAttachDialogClose,
  isWorkflowsOwnerDetailsEnabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { pathname } = useLocation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedRow, setSelectedRow] = useState(null);
  const [alertBar, setAlertBar] = useState(false);
  const [subtenantsDialog, setSubtenantsDialog] = useState(null);
  const [addToDialog, setAddToDialog] = useState(null);
  const [workflowToChangeOwnership, setWorkflowToChangeOwnership] = useState(
    null,
  );

  const isDeveloper = useSelector(getIsDeveloper);
  const isRootUser = useSelector(getIsRootUser);

  useEffect(() => {
    setAlertBar(Boolean(apiError));
  }, [apiError]);

  const getState = () => {
    if (workflowsLoading) return "loading";
    if (alertBar) return "error";
    if (workflows?.length === 0) return "noResults";
    return "ready";
  };

  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  const handleDeletion = async () => {
    if (!workflowToDelete) {
      return;
    }

    const { path } = workflowToDelete;
    const [error] = await deleteWorkflow({ uri: `${path}.xml`, token });

    let variant = "success";
    let message = t("x has been deleted", { x: t("Workflow") });

    if (error) {
      variant = "error";
      message = error.getMessage(t("Unable to delete x", { x: t("Workflow") }));
    }

    reloadWorkflows();
    setWorkflowToDelete(null);
    enqueueSnackbar(message, { variant });
  };

  return (
    <Paper
      className={[
        commonClasses.commonPaper,
        commonClasses.commonPaperNoPadding,
      ].join(" ")}
    >
      {workflowToChangeOwnership && (
        <AutomationChangeOwnerDialog
          ownerId={workflowToChangeOwnership?.owner.externalId}
          ownerName={workflowToChangeOwnership?.owner.login}
          workflowPath={workflowToChangeOwnership.path}
          onClose={() => {
            setWorkflowToChangeOwnership(null);
            reloadWorkflows();
          }}
        />
      )}
      <div>
        <Table>
          <colgroup>
            {tableHeader.map((header) => (
              <col key={header.id} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={tableHeader.length}
                className={commonClasses.commonTableCell}
              >
                <FilterMenu
                  tpTotal={workflowsCount || 0}
                  {...filterState}
                  handleSearchByChange={filterActions.handleSearchByChange}
                  searchValue={filterState.searchValue}
                  tpChangePage={filterActions.tpChangePage}
                  tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {tableHeader.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {header.active && header.id !== "owner" ? (
                    <TableSortLabel
                      id={`AUTOMATION_TABLE_SORT_${header.id}`}
                      active={filterState.sortBy === header.id}
                      direction={sortOrder[filterState.sortOrderValue].text}
                      onClick={() => {
                        filterActions.handleSortByOrderChange();
                        filterActions.handleSortByValueChange(header.id);
                      }}
                    >
                      {t(header.name)}
                    </TableSortLabel>
                  ) : header.id === "owner" &&
                    isWorkflowsOwnerDetailsEnabled ? (
                    <>{header.name}</>
                  ) : (
                    <>{header.active}</>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {getState() === "error" && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={tableHeader.length}>
                  <AlertBar
                    message={t("Unable to load x", { x: t("Workflows") })}
                    refreshFnc={reloadWorkflows}
                    closeFnc={() => setAlertBar(false)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {getState() === "loading" && (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={tableHeader.length}
                  className={commonClasses.commonLoaderWrapperTable}
                >
                  <Grid container justifyContent="center" alignContent="center">
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {getState() === "noResults" && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={tableHeader.length}>
                  <Typography>{t("0 Results Returned")}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {getState() === "ready" && workflows && (
            <TableBody>
              {workflows.map((workflow) => {
                const workflowAvatar = (
                  <Avatar className={commonClasses.commonTableAvatar}>
                    <WorkflowIcon />
                  </Avatar>
                );
                return (
                  <TableRow
                    id={`AUTOMATION_REPOSITORY_TABLE_WORKFLOW_PATH_${workflow.path}`}
                    key={workflow.path}
                    onMouseLeave={setSelectedRow}
                    onMouseEnter={() => setSelectedRow(workflow.path)}
                    onClick={() => setSelectedRow(workflow.path)}
                    classes={{ root: commonClasses.commonTableRow }}
                    className={commonClasses.commonTableRowHighlight}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <div className={commonClasses.commonFlexStart}>
                        {workflowAvatar}
                        <Typography
                          variant="h4"
                          className={classNames(
                            commonClasses.commonTablePrimary,
                            commonClasses.overflowAnywhere,
                          )}
                          title={workflow.path}
                        >
                          {workflow.displayName}
                        </Typography>
                        <Hidden mdDown>
                          {workflow.group &&
                            workflow.group.split("|").map((group) => (
                              <Chip
                                key={group}
                                label={group}
                                classes={{
                                  label: commonClasses.commonTagLabel,
                                }}
                                className={[
                                  commonClasses.commonTags,
                                  commonClasses.commonTagSystem1,
                                ].join(" ")}
                              />
                            ))}
                        </Hidden>
                      </div>
                    </TableCell>
                    <TableCell className={commonClasses.commonTableCellDefault}>
                      <Typography
                        variant="body1"
                        className={commonClasses.commonTableSecondary}
                      >
                        {workflow.version}
                      </Typography>
                      {workflow.released && (
                        <Chip
                          label={t("RELEASED")}
                          className={[
                            commonClasses.commonTags,
                            commonClasses.commonTagSystem2,
                          ].join(" ")}
                        />
                      )}
                    </TableCell>
                    <TableCell className={commonClasses.commonTableCellDefault}>
                      <Typography
                        variant="body1"
                        className={commonClasses.commonTableSecondary}
                      >
                        {workflow.updatedBy}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={commonClasses.commonTableSecondary}
                      >
                        {displayMonthDayYearTimeDate(workflow.timestamp)}
                      </Typography>
                    </TableCell>

                    <TableCell className={commonClasses.commonTableCellDefault}>
                      {workflow.numCustomersAttached !== 0 ? (
                        <>
                          <Typography
                            variant="h3"
                            className={classes.tableCellAsLink}
                            onClick={() => setSubtenantsDialog(workflow.path)}
                          >
                            <NumberFormat
                              value={workflow.numCustomersAttached}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            {` ${t("Subtenants")}`}
                          </Typography>
                          {subtenantsDialog === workflow.path && (
                            <AutomationSubtenantsDialog
                              id={`AUTOMATION_REPOSITORY_TABLE_WORKFLOW_AUTOMATION_SUBTENANT_DIALOG_${workflow.path}`}
                              open
                              onClose={() => {
                                setSubtenantsDialog(false);
                                onAttachDialogClose();
                              }}
                              wfName={workflow.displayName}
                              wfPath={workflow.path}
                              icon={workflowAvatar}
                            />
                          )}
                        </>
                      ) : (
                        <Typography
                          variant="h3"
                          className={classes.tableCellAsText}
                        >
                          {t("none")}
                        </Typography>
                      )}
                    </TableCell>
                    {isWorkflowsOwnerDetailsEnabled && (
                      <TableCell
                        className={commonClasses.commonTableCellDefault}
                      >
                        {isRootUser ? (
                          <>
                            <Typography
                              variant="h3"
                              className={classes.tableCellAsLink}
                              onClick={() =>
                                setWorkflowToChangeOwnership(workflow)
                              }
                            >
                              {workflow.owner?.login || "ncroot"}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="h3"
                            className={classes.tableCellAsText}
                          >
                            {workflow.owner?.login || "ncroot"}
                          </Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      padding="none"
                      className={commonClasses.commonTableActionCell}
                    >
                      {/* edit/delete icons are showed up on hover  */}
                      {workflow.path === selectedRow && (
                        <>
                          <span className={commonClasses.commonInlineBlock}>
                            {((isWorkflowsOwnerDetailsEnabled &&
                              workflow.permission.attach) ||
                              !isWorkflowsOwnerDetailsEnabled) && (
                              <DelegationProfiles
                                type={delegationProfileTypes.WORKFLOWS}
                                action="general.attach"
                              >
                                <Button
                                  id={`AUTOMATION_REPOSITORY_TABLE_WORKFLOW_AUTOMATION_SUBTENANT_DIALOG_BTN_ADDTO_${workflow.path}`}
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={[
                                    commonClasses.commonBtn,
                                    commonClasses.commonBtnPrimary,
                                  ].join(" ")}
                                  onClick={() => setAddToDialog(workflow.path)}
                                >
                                  {t("Add to...")}
                                </Button>
                              </DelegationProfiles>
                            )}
                            {isDeveloper && (
                              <>
                                {((isWorkflowsOwnerDetailsEnabled &&
                                  workflow.permission.modify) ||
                                  !isWorkflowsOwnerDetailsEnabled) && (
                                  <>
                                    <DelegationProfiles
                                      type={delegationProfileTypes.WORKFLOWS}
                                      action="general.modify"
                                    >
                                      <Link
                                        id="AUTOMATION_DETAILS_BTN_EDIT"
                                        to={buildRoute(
                                          pathname,
                                          `${encodeURIComponent(
                                            workflow.path,
                                          )}/edit`,
                                        )}
                                      >
                                        <IconButton>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                      </Link>
                                    </DelegationProfiles>
                                    <IconButton
                                      id={`AUTOMATION_REPOSITORY_TABLE_WORKFLOW_DOWNLOAD_BTN__${workflow.path}`}
                                      aria-label={t("Download")}
                                      onClick={() => {
                                        downloadArchive({
                                          path: workflow.path + ".xml",
                                          token,
                                          fileName:
                                            Repository.getFilenameFromUri(
                                              workflow.path,
                                            ) + ".zip",
                                          onError: (e) => {
                                            enqueueSnackbar(
                                              t("Unable to download contents"),
                                              {
                                                variant: "error",
                                              },
                                            );
                                          },
                                        });
                                      }}
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                  </>
                                )}
                                {((isWorkflowsOwnerDetailsEnabled &&
                                  workflow.permission.delete) ||
                                  !isWorkflowsOwnerDetailsEnabled) && (
                                  <DelegationProfiles
                                    type={delegationProfileTypes.WORKFLOWS}
                                    action="general.delete"
                                  >
                                    <DeleteWorkflowIcon
                                      isEnabled={!workflow.numCustomersAttached}
                                      onClick={() => {
                                        setWorkflowToDelete(workflow);
                                        showDeleteDialog();
                                      }}
                                    />
                                  </DelegationProfiles>
                                )}
                              </>
                            )}
                          </span>
                        </>
                      )}
                      {workflow.path === addToDialog && (
                        <AutomationAttachDialog
                          onClose={() => {
                            onAttachDialogClose();
                            setAddToDialog(false);
                          }}
                          wfPath={workflow.path}
                          wfName={workflow.displayName}
                          icon={workflowAvatar}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
      <DeleteDialog
        title={t("Delete x?", { x: t("Workflow") })}
        content={t("Are you sure you want to delete x?", {
          x: workflowToDelete?.displayName || t("Workflow"),
        })}
        onExec={handleDeletion}
      />
    </Paper>
  );
};

AutomationRepository.propTypes = {
  workflows: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      group: PropTypes.string,
      numCustomersAttached: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      released: PropTypes.bool.isRequired,
      timestamp: PropTypes.number.isRequired,
      updatedBy: PropTypes.string.isRequired,
      version: PropTypes.number.isRequired,
    }),
  ),
  workflowsLoading: PropTypes.bool.isRequired,
  workflowsCount: PropTypes.number,
  filterState: PropTypes.shape({
    sortByValue: PropTypes.string,
    sortOrderValue: PropTypes.number,
  }).isRequired,
  filterActions: PropTypes.shape({
    handleSortByOrderChange: PropTypes.func.isRequired,
    handleSortByValueChange: PropTypes.func.isRequired,
    tpChangeRowsPerPage: PropTypes.func.isRequired,
    tpChangePage: PropTypes.func.isRequired,
  }).isRequired,
  apiError: PropTypes.object,
  reloadWorkflows: PropTypes.func.isRequired,
  onAttachDialogClose: PropTypes.func.isRequired,
};

export default AutomationRepository;
