import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useRouteMatch } from "react-router-dom";

import useDialog from "msa2-ui/src/hooks/useDialog";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import {
  getSelectedSubtenant,
  clearSelectedSubtenant,
  fetchSubtenants,
} from "msa2-ui/src/store/designations";
import { deleteSubtenant } from "msa2-ui/src/api/subtenant";

import {
  Grid,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import TableRow from "msa2-ui/src/components/TableRow";

const SubtenantTableBody = ({ subtenants, reloadSubtenants }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const token = useSelector(getToken);
  const selectedSubtenant = useSelector(getSelectedSubtenant);
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [selectedRow, setSelectedRow] = useState();

  const handleDelete = async () => {
    const [error] = await deleteSubtenant({
      token,
      id: selectedRow.id,
    });
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to delete Subtenant"))
      : t("Subtenant deleted");

    if (!error) {
      if (selectedSubtenant.id === selectedRow.id) {
        dispatch(clearSelectedSubtenant());
      }
      dispatch(fetchSubtenants());
      reloadSubtenants();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  return (
    <>
      <DeleteDialog
        title={t("Delete Subtenant")}
        content={
          <>
            {t("Are you sure you want to delete x?", { x: selectedRow?.name })}
            <br />
            {t(
              "This will delete all Managed Entities associated with this Subtenant.",
            )}
          </>
        }
        onExec={handleDelete}
      />

      {subtenants.map((subtenant, index) => (
        <TableRow
          id={`SUBTENANT_TABLE_ROW_${subtenant.id}`}
          key={subtenant.id}
          actions={
            <>
              <IconButton
                id={`SUBTENANT_TABLE_BTN_EDIT_${subtenant.id}`}
                aria-label={t("Edit")}
                variant="contained"
                color="primary"
                component={Link}
                to={{
                  pathname: `${url}/${subtenant.id}/edit`,
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                id={`SUBTENANT_TABLE_BTN_DELETE_${subtenant.id}`}
                aria-label={t("Delete")}
                onClick={() => {
                  setSelectedRow(subtenant);
                  showDeleteDialog(true);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </>
          }
        >
          <TableCell component="th" scope="row" padding="none">
            <Grid
              id={`SUBTENANT_TABLE_CELL_NAME_${subtenant.id}`}
              className={classNames(commonClasses.commonTableCellClickable)}
              container
              alignItems="center"
            >
              <Tooltip title={subtenant.displayName}>
                <Typography
                  variant="h4"
                  className={commonClasses.commonTablePrimary}
                >
                  {subtenant.name}
                </Typography>
              </Tooltip>
            </Grid>
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="body1"
              className={commonClasses.commonTableSecondary}
            >
              {subtenant.id}
            </Typography>
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="body1"
              className={commonClasses.commonTableSecondary}
            >
              {subtenant.prefix}
            </Typography>
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="body1"
              className={commonClasses.commonTableSecondary}
            >
              {subtenant.operator}
            </Typography>
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="body1"
              className={commonClasses.commonTableSecondary}
            >
              {subtenant.externalReference}
            </Typography>
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="body1"
              className={commonClasses.commonTableSecondary}
            >
              {subtenant.lastUpdated}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

SubtenantTableBody.propTypes = {
  subtenants: PropTypes.array.isRequired,
  reloadSubtenants: PropTypes.func.isRequired,
};

export default SubtenantTableBody;
