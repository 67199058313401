import i18n from "i18next";

import { getManagedEntitiesByAdmin } from "msa2-ui/src/api/managedEntity";
import { getWorkflowList, getWorkflowDetails } from "msa2-ui/src/api/workflow";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import WorkflowHistory from "msa2-ui/src/routes/dashboard/WorkflowHistory";
import ManagedEntityStatusGraphs from "msa2-ui/src/routes/dashboard/ManagedEntityStatusGraphs";
import TopologyTab from "msa2-ui/src/routes/integration/managed-entities/overview/TopologyTab";
import MonitoringGraphs from "msa2-ui/src/routes/integration/managed-entities/detail/monitoring-graphs";
import WorkflowInstanceVariableView from "msa2-ui/src/routes/automation/workflows/detail/WorkflowInstanceVariableView";
import WorkflowLineGraph from "msa2-ui/src/components/WorkflowLineGraph";
import DatafilesRenderer from "msa2-ui/src/components/DatafilesRenderer";
import VariableRenderer from "msa2-ui/src/components/VariableRenderer";
import WorkflowPieChart from "msa2-ui/src/components/WorkflowPieChart";
import AutomationBoard from "msa2-ui/src/routes/dashboard/boards/Automation.board";
import KibanaDashboard, {
  WORKFLOW_PATH,
} from "msa2-ui/src/components/KibanaDashboard";
import BpmHistory from "msa2-ui/src/routes/dashboard/boards/BpmHistory.board";
import AlarmList from "msa2-ui/src/routes/alarms/AlarmList";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);
/**
 * How to add Components
 *
 * Case 1. Target component does not need props
 *
 *  You can simply add the display name for the component as a key
 *  and imported React component with "component" object
 *
 * Case 2. Target component needs props
 *
 *  You can let users choose value which is for props.
 *  "extendedLists" is in charge of generating a list.
 *   - id: You can name it. This will be used when you get value from "extendedListValues"
 *   - displayName: Label for the field
 *   - options: This will be the list in select component.
 *
 *   You can hardcode the list, but most of the case, the parameters should take data from API.
 *   Then you can "apis",
 *   - id: This is to get response value from
 *   - callee: callback for API request function
 *   - parameters: parameters to pass the API
 *
 *   From each functions you can get those 3 values,
 *   - extendedListValues: contains values selected by users with named id
 *   - state: contains all states in redux store
 *   - apiResponses: contains responses from API with named id
 *
 *  Finally, the return of "parameters" would be stored and will be passed into the component.
 *
 */
export default {
  Automation: {
    description: "Automation board",
    component: AutomationBoard,
    getHidden: () => true,
  },
  "Managed Entity Status": {
    description: i18n.t(
      "The list of your Managed Entities status with Doughnut Graph.",
    ),
    component: ManagedEntityStatusGraphs,
  },
  Alarms: {
    description: i18n.t("The list of alarms."),
    component: AlarmList,
    getHidden: (delegationProfiles, userRole) => {
      const alarmsView = getDelegationProfile(
        delegationProfileTypes.ALARMS,
        "alarms.view",
      )({
        delegationProfiles,
        auth: { userDetails: { baseRole: { id: userRole } } },
      });
      return !alarmsView;
    },
  },
  "Recent Workflow": {
    description: i18n.t(
      "The list of your recently executed Workflow with a total graph.",
    ),
    component: WorkflowHistory,
  },
  "Recent BPM": {
    description: i18n.t(
      "The list of your recently executed BPM with a total graph.",
    ),
    component: BpmHistory,
  },
  Topology: {
    description: i18n.t("Topology for the selected Subtenant."),
    component: TopologyTab,
  },
  "Monitoring Graphs": {
    description: i18n.t("Monitoring Graphs for the selected Managed Entity."),
    component: MonitoringGraphs,
    extendedLists: [
      {
        id: "managedEntity",
        displayName: i18n.t("Managed Entity"),
        apis: [
          {
            id: "managedEntities",
            callee: getManagedEntitiesByAdmin,
            parameters: ({ state }) => ({
              pageSize: 10000,
              managerId: state.auth.userDetails.id,
              filterByLabel: isPermissionProfileLabelsEnabled,
            }),
          },
        ],
        options: ({ apiResponses }) =>
          apiResponses.managedEntities
            ? apiResponses.managedEntities.map((managedEntity) => ({
                value: managedEntity.deviceId.id,
                label: managedEntity.name,
              }))
            : [],
      },
    ],
    parameters: ({ extendedListValues, state }) => {
      const selectedManagedEntity = state.designations.managedEntities.find(
        ({ deviceId: { id } }) => id === extendedListValues.managedEntity,
      );
      return {
        deviceId: selectedManagedEntity?.deviceId.id ?? null,
        deviceUbiId: selectedManagedEntity?.deviceId.ubiId ?? null,
      };
    },
  },
  "Workflow Instance Variable List": {
    description: i18n.t(
      "The list of Workflow instances for the selected Subtenant.",
    ),
    component: WorkflowInstanceVariableView,
    extendedLists: [
      {
        id: "workflow",
        displayName: i18n.t("Workflow"),
        apis: [
          {
            id: "workflows",
            callee: getWorkflowList,
            parameters: ({ featureFlags }) => ({
              pageSize: 10000,
              isFilteredByOwner:
                featureFlags[FeatureFlag.features.workflowsOwner],
            }),
          },
        ],
        options: ({ apiResponses }) =>
          apiResponses.workflows
            ? apiResponses.workflows.workflows.map((workflow) => ({
                value: workflow.path,
                label: workflow.displayName,
              }))
            : [],
      },
    ],
    parameters: ({ extendedListValues }) => ({
      workflowUri: extendedListValues.workflow,
    }),
  },
  "Datafiles Renderer": {
    description: i18n.t(
      "This renders the contents for particular file in the repository.",
    ),
    component: DatafilesRenderer,
    getHidden: () => true,
  },
  "Variable Renderer": {
    description: i18n.t(
      "This renders the contents for particular variable in a Workflow instance.",
    ),
    component: VariableRenderer,
    depends: ["Workflow Instance Variable List"],
    getHidden: () => true,
  },
  "Workflow Line Graph": {
    description: i18n.t(
      i18n.t(
        "This renders a line graph based on an array variable for Workflows.",
      ),
    ),
    component: WorkflowLineGraph,
    depends: ["Workflow Instance Variable List"],
  },
  "Workflow Pie Chart": {
    description: i18n.t(
      "This renders a pie chart based on an array variable for Workflows.",
    ),
    component: WorkflowPieChart,
    depends: ["Workflow Instance Variable List"],
  },
  "Kibana Dashboard": {
    description: i18n.t(
      "Show Kibana Dashboard deployed by Deploy Dashboard Workflow.",
    ),
    component: KibanaDashboard,
    extendedLists: [
      {
        id: "kibanaUrl",
        displayName: i18n.t("Dashboard Name"),
        apis: [
          {
            id: "workflowDetails",
            callee: getWorkflowDetails,
            parameters: ({ state }) => ({
              page_size: 10000,
              ubiqubeId: state.designations.selectedSubtenant.ubiqubeId,
              workflowPath: WORKFLOW_PATH,
            }),
          },
        ],
        options: ({ apiResponses }) =>
          apiResponses.workflowDetails?.instances
            ? apiResponses.workflowDetails?.instances.map(
                ([
                  _,
                  {
                    variables: { Hash: value },
                  },
                  label,
                ]) => ({ value, label }),
              )
            : [
                {
                  value: "",
                  label: i18n.t("No Subtenant selected."),
                },
              ],
      },
    ],
    parameters: ({ extendedListValues }) => ({
      hash: extendedListValues.kibanaUrl,
    }),
  },
};
