import React, { memo } from "react";
import PropTypes from "prop-types";
import Field from "redux-form/lib/Field";
import MsaSelect from "msa2-ui/src/components/msa-select";

const MsaSelectRenderer = ({ input, meta, ...rest }) => {
  const { error, touched } = meta;
  const isError = Boolean(touched && error);

  return (
    <MsaSelect
      isError={isError}
      error={error}
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      {...rest}
    />
  );
};

const FormMsaSelect = ({
  name,
  format,
  normalize,
  value,
  validate,
  parse,
  onBlur,
  onChange,
  onFocus,
  ...rest
}) => {
  return (
    <Field
      {...rest}
      component={MsaSelectRenderer}
      name={name}
      format={format}
      normalize={normalize}
      value={value}
      validate={validate}
      parse={parse}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

FormMsaSelect.propTypes = {
  name: PropTypes.string.isRequired,
  format: PropTypes.func,
  normalize: PropTypes.func,
  value: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  parse: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

export default memo(FormMsaSelect);
