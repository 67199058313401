import Variable from "msa2-ui/src/services/Variable";
import Validation from "msa2-ui/src/services/Validation";

const variablePath = "variables.variable";
const processPath = "process";

const requiredFields = [
  "information.displayName",
  "information.displayField",
  "information.serviceTaskType",
];
const localFields = ["config", "active", "checked"];
const requiredFieldsInVariable = ["name"];
const uniqueFieldsInVariable = [{ displayOn: "name", comparisonOn: ["name"] }];

const formConfig = {
  variablePath,
  processPath,
};

const validate = (values) => {
  const mandatoryErrors = Validation.requiredFields({
    values,
    requiredFields,
  });
  const variableErrors = Variable.validate({
    values,
    requiredFields: requiredFieldsInVariable,
    uniqueFields: uniqueFieldsInVariable,
    formPath: variablePath,
  });
  return { ...variableErrors, ...mandatoryErrors };
};
const EditFormName = "WorkflowEdit";
const CreateFormName = "WorkflowCreate";

export default {
  validate,
  formConfig,
  EditFormName,
  CreateFormName,
  localFields,
};
