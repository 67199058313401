import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core";

import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";

import Dialog from "msa2-ui/src/components/Dialog";

const useStyles = makeStyles(({ spacing, palette }) => ({
  prompt: {
    marginBottom: spacing(2),
    textAlign: "center",
  },
  cell: {
    padding: "12px 10px",
  },
  cellTitle: {
    fontWeight: "bold",
  },
  noBorder: {
    border: 0,
  },
  warning: {
    color: palette.warning.main,
    marginBottom: spacing(2),
  },
  topRow: {
    borderTop: "1px solid rgba(81, 81, 81, 1)",
  },
}));

const ImportExportDeactivateDialog = ({ handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const { state } = useLocation();

  const { entry } = state;

  const onSubmit = () => {
    handleSubmit();
    history.push("/settings");
  };

  const onClose = () => {
    handleClose();
    history.push("/settings");
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={onClose}
      execLabel={t("Yes, deactivate")}
      title={t("Deactivate")}
    >
      <Grid container direction="column">
        <Typography className={classes.warning}>
          {t(
            "MSA will remove the git configuration from the following directory and from now onwards, your local changes will not be pushed to the remote git. Please note that no files will be deleted from MSA.",
          )}
        </Typography>
        <Typography className={classes.prompt}>
          {t("Are you sure you want to deactivate that entry?")}
        </Typography>
        <Table>
          <TableBody>
            <TableRow className={classes.topRow}>
              <TableCell
                className={classnames(classes.cell, classes.cellTitle)}
              >
                Type:
              </TableCell>
              <TableCell className={classes.cell}>{entry.repoType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={classnames(classes.cell, classes.cellTitle)}
              >
                URL:
              </TableCell>
              <TableCell className={classes.cell}>{entry.repo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={classnames(classes.cell, classes.cellTitle)}
              >
                Directory:
              </TableCell>
              <TableCell className={classes.cell}>{entry.repoName}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Dialog>
  );
};

export default ImportExportDeactivateDialog;
