import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Close from "@material-ui/icons/Close";

import {
  Dialog as MaterialUiDialog,
  Button,
  Grid,
  Divider,
  IconButton,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: 25,
    lineHeight: 1.5,
    letterSpacing: 0.25,
    textAlign: "center",
    overflowX: "clip",
  },
  tabsWrapper: {
    padding: "0 16px",
  },
  dialogContent: {
    paddingBottom: 20,
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
  actions: {
    paddingBottom: 20,
  },
}));

const Dialog = ({
  maxWidth = "sm",
  fullWidth = true,
  onClose,
  onExec,
  extraAction,
  extraLabel,
  execLabel,
  disabled = false,
  icon,
  title,
  tabs,
  content,
  children,
  classes,
  validation,
}) => {
  const localClasses = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  return (
    <MaterialUiDialog
      id="DIALOG_TITLE"
      open={true}
      onClose={(e, reason) => {
        if (disabled && reason === "backdropClick") {
          return;
        }
        onClose(e);
      }}
      aria-labelledby="modalArea"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disabled}
      classes={{
        ...classes,
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        id="modalArea"
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        {icon && (
          <div className={commonClasses.commonDialogHeaderIcon}>{icon}</div>
        )}
        {title && (
          <Typography
            variant="h4"
            className={commonClasses.commonDialogHeaderTitle}
          >
            {title}
          </Typography>
        )}
        <IconButton
          id="DIALOG_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {tabs && (
        <Grid className={localClasses.tabsWrapper}>
          {tabs}
          <Divider color="secondary" />
        </Grid>
      )}

      <DialogContent className={localClasses.contentWrapper}>
        {content && (
          <Typography variant="body2" className={localClasses.dialogContent}>
            {content}
          </Typography>
        )}
        {children}
      </DialogContent>

      <DialogActions className={localClasses.actions} id="DIALOG_ACTIONS">
        <Grid container justifyContent="center" alignContent="center">
          {onExec && (
            <Button
              id="DIALOG_ACTIONS_BTN_CANCEL"
              variant="text"
              size="small"
              color="default"
              className={classnames(
                commonClasses.commonBtn,
                commonClasses.commonBtnSecondary,
              )}
              onClick={onClose}
            >
              {t("Cancel")}
            </Button>
          )}
          {!onExec && (
            <Button
              id="DIALOG_ACTIONS_BTN_SAVE"
              variant="contained"
              size="small"
              color="primary"
              className={classnames(
                commonClasses.commonBtn,
                commonClasses.commonBtnPrimary,
              )}
              onClick={onClose}
              disabled={disabled}
            >
              {t("OK")}
            </Button>
          )}
          {extraAction && extraLabel && (
            <Button
              id="DIALOG_ACTIONS_BTN_EXTRA"
              variant={"contained"}
              size="small"
              color="primary"
              className={classnames(
                commonClasses.commonBtn,
                commonClasses.commonBtnPrimary,
              )}
              onClick={extraAction}
              disabled={disabled}
            >
              {extraLabel}
            </Button>
          )}
          {onExec && (
            <Tooltip title={validation || ""}>
              <span>
                <Button
                  id="DIALOG_ACTIONS_BTN_SAVE"
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classnames(
                    commonClasses.commonBtn,
                    commonClasses.commonBtnPrimary,
                  )}
                  onClick={onExec}
                  disabled={disabled || Boolean(validation)}
                >
                  {execLabel || t("OK")}
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
      </DialogActions>
    </MaterialUiDialog>
  );
};

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  tabs: PropTypes.element,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  onExec: PropTypes.func,
  execLabel: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string),
  validation: PropTypes.string,
  extraAction: PropTypes.func,
};

export default Dialog;
