import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  field: {
    marginTop: spacing(4),
    textAlign: "left",
    width: 350,
  },
}));

const UpsertAlarmAutoClearanceTab = ({
  formState,
  dispatch,
  setIsAutoClearanceDefined,
}) => {
  const {
    alarmClearance: {
      alarm_regex,
      event_regex,
      clearing_delay,
      clear_comment,
    },
    query: { terms },
  } = formState;
  const { t } = useTranslation();
  const classes = useStyles();

  const conditionString = terms ? t("Conditions String") + " : " + terms : "";
  const autoClearDataFound =
    alarm_regex !== "" ||
    event_regex !== "" ||
    clearing_delay > 0 ||
    clear_comment !== "";
  const [showAutoClearanceFields, setShowAutoClearanceFields] = useState(
    autoClearDataFound,
  );
  const onChange = (event) => {
    setIsAutoClearanceDefined(event.target.checked);
    setShowAutoClearanceFields(event.target.checked);
    if (event.target.checked === false) {
      dispatch({ type: "updateQueryClearAlarmRegex", payload: "" });
      dispatch({ type: "updateQueryClearEventRegex", payload: "" });
      dispatch({ type: "updateQueryClearDelay", payload: 0 });
      dispatch({ type: "updateQueryClearComment", payload: "" });
    }
  };

  return (
    <Grid container direction="column" alignItems="center">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="UPSERT_ALARM_FORM_SHOW_AUTOCLEAR_FORM"
              checked={showAutoClearanceFields}
              onChange={onChange}
              inputProps={{
                "data-testid": "upsert-alarm-form-show-auto-clear",
              }}
            />
          }
          label={t("Set conditions for auto clearance of alarms")}
        />
      </FormGroup>
      {showAutoClearanceFields && (
        <Grid container direction="column" alignItems="center">
          <Grid>
            <Tooltip title={conditionString}>
              <TextField
                id="UPSERT_ALARM_FORM_CLEAR_ALARM_REGEX"
                size="medium"
                label={t("Alarm Matcher")}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Regex : IPDOWN for router (?<var1>\S+)\."
                value={alarm_regex}
                onChange={({ target: { value } }) =>
                  dispatch({
                    type: "updateQueryClearAlarmRegex",
                    payload: value,
                  })
                }
                variant="outlined"
                inputProps={{
                  "data-testid": "upsert-alarm-form-clear-alarm-match",
                }}
                required={true}
                className={classes.field}
              />
            </Tooltip>
          </Grid>
          <TextField
            id="UPSERT_ALARM_FORM_CLEAR_EVENT_REGEX"
            size="medium"
            label={t("Clearance Matcher")}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Regex : IPUP for static router (?<var1>\S+)\."
            value={event_regex}
            onChange={({ target: { value } }) => {
              dispatch({ type: "updateQueryClearEventRegex", payload: value });
            }}
            variant="outlined"
            inputProps={{
              "data-testid": "upsert-alarm-form-clear-event-match",
            }}
            required={true}
            className={classes.field}
          />
          <TextField
            id="UPSERT_ALARM_FORM_CLEAR_DELAY"
            size="medium"
            label={t("Clearance Delay (Minutes)")}
            value={
              isNaN(parseInt(clearing_delay)) ? 0 : parseInt(clearing_delay)
            }
            onChange={({ target: { value } }) =>
              dispatch({ type: "updateQueryClearDelay", payload: value })
            }
            variant="outlined"
            inputProps={{
              "data-testid": "upsert-alarm-form-clear-delay",
              type: "number",
            }}
            required={true}
            className={classes.field}
          />
          <TextField
            id="UPSERT_ALARM_FORM_CLEAR_COMMENT"
            size="medium"
            label={t("Clear Comment")}
            value={clear_comment.replace(/\*/g, "")}
            onChange={({ target: { value } }) =>
              dispatch({ type: "updateQueryClearComment", payload: value })
            }
            variant="outlined"
            inputProps={{
              "data-testid": "upsert-alarm-form-clear-comment",
            }}
            className={classes.field}
          />
        </Grid>
      )}
    </Grid>
  );
};

UpsertAlarmAutoClearanceTab.propTypes = {
  formState: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default UpsertAlarmAutoClearanceTab;
