import set from "lodash/set";
import flow from "lodash/flow";
import partition from "lodash/partition";
import Variable from "msa2-ui/src/services/Variable";

const generatePathObject = (microserviceVariables) =>
  microserviceVariables.reduce((sectionPaths, variable, i) => {
    const { grouped, groupDisplayName, sections, displayName, name } = variable;
    const useName = displayName ? displayName : Variable.removePrefix(name);
    const groupName = grouped && groupDisplayName;
    const key = [...(groupName ? [groupName] : sections ?? []), useName].join(
      ".",
    );
    return {
      ...sectionPaths,
      [key]: i,
    };
  }, {});

const createNestedSections = (objectWithPaths) =>
  Object.keys(objectWithPaths).reduce(
    (nestedObject, key) => set(nestedObject, key, objectWithPaths[key]),
    {},
  );

const booleanToString = (bool) =>
  typeof bool === "boolean" ? bool.toString() : bool;

const stringToBool = (value) => {
  if (typeof value === "boolean") {
    return value;
  }
  if (value === "True" || value === "true") {
    return true;
  } else if (value === "False" || value === "false") {
    return false;
  }
};
/**
 * This function takes a list of microservice variables and returns a nested object
 * representing the sections the variables are grouped in.
 *
 * We start by creating an object where the entries in the section array are used to
 * create keys that represent the section hierarchy down to the variable index.
 * i.e. Group.Rules.Port : 0
 *
 * That object is then converted in another object where the paths are transformed into
 * an actual object hierarchy. This will allow us to cleanly recursively render the table headers.
 */
const generateSections = flow([generatePathObject, createNestedSections]);

/**
 * A utility function for breaking a variable name down
 * into pieces and determining information about the variable
 * based on the pieces.
 *
 * Example input: params.hosts.0.active
 *
 * @param {string} name - the name of a variable
 */
const breakNameIntoPieces = (name) => {
  const variablePathParts = name.split(".");
  const sectionName = variablePathParts[variablePathParts.length - 3];
  const baseVariableName = variablePathParts[variablePathParts.length - 1];

  const possibleSectionIndex = parseInt(
    variablePathParts[variablePathParts.length - 2],
  );
  const isMultipleValues = Number.isInteger(possibleSectionIndex);

  return {
    variablePathParts,
    sectionName,
    baseVariableName,
    isMultipleValues,
  };
};

const transformArrayVariables = (variables) => {
  const nestedVariables = [];
  const [arrayVariables, regularVariables] = partition(
    variables,
    (variable) => {
      const { isMultipleValues } = breakNameIntoPieces(variable.name);
      return isMultipleValues;
    },
  );

  arrayVariables.forEach((variable) => {
    const { sectionName } = breakNameIntoPieces(variable.name);
    const arrayIndex = nestedVariables.findIndex(
      (nestedVariable) => nestedVariable.displayName === sectionName,
    );
    if (arrayIndex > -1) {
      nestedVariables[arrayIndex].variables.push(variable);
    } else {
      nestedVariables.push({
        displayName: sectionName,
        type: "Array",
        variables: [variable],
        visible: true,
      });
    }
  });

  return nestedVariables.length < 1
    ? regularVariables
    : [...regularVariables, ...nestedVariables];
};

export default {
  stringToBool,
  generateSections,
  booleanToString,
  breakNameIntoPieces,
  transformArrayVariables,
};
