import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import Bpm from "msa2-ui/src/services/Bpm";

import { Grid, Typography, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import BasicSelect from "msa2-ui/src/components/BasicSelect";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    paddingBottom: 5,
  },
  formHeading: {
    marginBottom: 15,
  },
  formField: {
    marginBottom: 35,
    width: "100%",
  },
}));

const MessageEventPropertiesPanel = ({
  modelerState,
  modelerActions,
  moddle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { activeElement } = modelerState;
  const allAttachedWorkflowValues = modelerActions.getAllAttachedWorkflowValues();
  const id = activeElement.id;

  const activeMessageEvent = Bpm.readInfoFromMessageEvent(activeElement);

  const onChangeWorkflow = (workflowActivityId) => {
    const updatedBpmElement = Bpm.writeInfoToMessageEvent({
      ...activeMessageEvent,
      workflowActivityId,
      moddle,
    });

    const businessObject = getBusinessObject(updatedBpmElement);
    const extensionElements = businessObject.extensionElements;
    modelerActions.updateActiveElement({ extensionElements });
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Typography variant="h5" className={classes.formHeading}>
        {t("Variable Edit Point")}
      </Typography>
      <Typography
        variant="body1"
        className={classnames(
          commonClasses.commonDescription,
          classes.formField,
        )}
      >
        {t(
          "You can wait for user input. When your BPM reaches here, you will see a popup window to fill Workflow parameters.",
        )}
      </Typography>
      <BasicSelect
        id="BPM_MESSAGE_EVENT_WORKFLOW"
        label={t("Target Workflow")}
        variant="outlined"
        className={classes.formField}
        name={t("Target Workflow")}
        value={activeMessageEvent.workflowActivityId ?? ""}
        onChange={({ target: { value } }) => {
          onChangeWorkflow(value);
        }}
      >
        {allAttachedWorkflowValues
          .filter(({ activityId }) =>
            // Show only if BPM task is already triggered (means it is placed before this element)
            modelerActions.isConnectingToCurrentElement(id, activityId),
          )
          .map(({ activityId, displayName }) => (
            <MenuItem value={activityId} key={activityId}>
              {displayName}
            </MenuItem>
          ))}
      </BasicSelect>
    </Grid>
  );
};

MessageEventPropertiesPanel.propTypes = {
  modelerState: PropTypes.object.isRequired,
  modelerActions: PropTypes.object.isRequired,
  moddle: PropTypes.object.isRequired,
};

export default MessageEventPropertiesPanel;
