import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import { buildRoute } from "msa2-ui/src/utils/urls";

import SectionTabs from "msa2-ui/src/components/SectionTabs";
import Administrators from "./administrators";
import Managers from "./managers";
import Tenants from "./tenants";
import Subtenants from "./subtenants";
import PermissionProfiles from "./permission-profiles";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { getUserRole, userRoles } from "msa2-ui/src/store/auth";
import { useSelector } from "react-redux";

const isPermissionProfilesEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfiles,
);

const ADMIN_TABS = [
  {
    path: "/admin/administrators",
    displayName: "Administrators",
    canAccess: (userRole) => userRole === userRoles.PRIVILEGED_ADMINISTRATOR,
  },
  {
    path: "/admin/managers",
    displayName: "Managers",
    canAccess: () => true,
  },
  {
    path: "/admin/tenants",
    displayName: "Tenants",
    canAccess: (userRole) => userRole === userRoles.PRIVILEGED_ADMINISTRATOR,
  },
  {
    path: "/admin/subtenants",
    displayName: "Subtenants",
    canAccess: () => true,
  },
  {
    path: "/admin/permissions",
    displayName: "Permissions",
    canAccess: () => isPermissionProfilesEnabled,
  },
];

export const AdminTabs = ({ currentCount, endElements }) => {
  const { url } = useRouteMatch();
  const userRole = useSelector(getUserRole);
  const enabledTabs = ADMIN_TABS.filter((tab) => tab.canAccess(userRole));
  const tabsWithCurrentCount = enabledTabs.map((tab) => {
    if (tab.path === url) {
      return {
        ...tab,
        count: currentCount,
      };
    }
    return tab;
  });

  return <SectionTabs tabs={tabsWithCurrentCount} endElements={endElements} />;
};

const AdminRouter = () => {
  const { path } = useRouteMatch();
  const userRole = useSelector(getUserRole);

  const defaultPath = () => {
    const path = ADMIN_TABS.filter((tab) => tab.canAccess(userRole))[0].path;
    return path.substring(path.lastIndexOf("/") + 1);
  };
  const defaultRoute = buildRoute(path, defaultPath());

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={defaultRoute} />
      </Route>
      <Route
        path={buildRoute(path, "administrators")}
        render={() =>
          userRole === userRoles.PRIVILEGED_ADMINISTRATOR ? (
            <Administrators adminTabs={AdminTabs} />
          ) : (
            <Redirect to={path} />
          )
        }
      />
      <Route
        path={buildRoute(path, "managers")}
        render={() => <Managers adminTabs={AdminTabs} />}
      />
      <Route
        path={buildRoute(path, "tenants")}
        render={() =>
          userRole === userRoles.PRIVILEGED_ADMINISTRATOR ? (
            <Tenants adminTabs={AdminTabs} />
          ) : (
            <Redirect to={path} />
          )
        }
      />
      <Route
        path={buildRoute(path, "subtenants")}
        render={() => <Subtenants adminTabs={AdminTabs} />}
      />
      {isPermissionProfilesEnabled && (
        <Route
          path={buildRoute(path, "permissions")}
          render={() => <PermissionProfiles adminTabs={AdminTabs} />}
        />
      )}
      <Redirect to={defaultRoute} />
    </Switch>
  );
};

export default AdminRouter;
