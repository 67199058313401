import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  IconButton,
  TableBody,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TableRow from "msa2-ui/src/components/TableRow";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
  SpeakerPhone as ManagedEntityIcon,
} from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";

const TBody = ({
  t,
  colSpan,
  editLinkBuilder,
  isError,
  isLoading,
  monitoringProfiles,
  subtenantId,
  onAttachClick,
  onDownloadClick,
  onDeleteClick,
  onAttachedManagedEntitiesClick,
}) => {
  const commonClasses = useCommonStyles();

  if (isLoading) {
    return <TableLoading numberOfTableColumns={colSpan} />;
  }

  if (isError || !subtenantId) {
    return (
      <TableMessage
        numberOfTableColumns={colSpan}
        message={
          isError ? t("GenericErrorMessage") : t("Please choose a subtenant")
        }
      />
    );
  }

  return (
    <TableBody>
      {monitoringProfiles.map(
        ({ dateModified, name, numberOfMEAttached, profileId, ubiqubeId }) => (
          <TableRow
            key={profileId}
            actions={
              <>
                <DelegationProfiles
                  type={delegationProfileTypes.MONITORING_PROFILES}
                  action="general.attach"
                >
                  <Button
                    data-testid={`MONITORING_PROFILE_BUTTON_ATTACH_${profileId}`}
                    id={`MONITORING_PROFILE_BUTTON_ATTACH_${profileId}`}
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classNames(
                      commonClasses.commonBtn,
                      commonClasses.commonBtnPrimary,
                    )}
                    onClick={() => onAttachClick({ name, id: profileId })}
                  >
                    {t("Add to...")}
                  </Button>
                </DelegationProfiles>
                <DelegationProfiles
                  type={delegationProfileTypes.MONITORING_PROFILES}
                  action="general.modify"
                >
                  <Link
                    id={`MONITORING_PROFILE_BUTTON_EDIT_${profileId}`}
                    to={{ pathname: editLinkBuilder(profileId) }}
                  >
                    <IconButton aria-label={t("Edit")}>
                      <EditIcon color="primary" />
                    </IconButton>
                  </Link>
                </DelegationProfiles>

                <IconButton
                  data-testid={`monitoring_profile_download_${profileId}`}
                  id={`MONITORING_PROFILE_BUTTON_DOWNLOAD_${profileId}`}
                  aria-label={t("Download")}
                  onClick={() => onDownloadClick(name, profileId)}
                >
                  <DownloadIcon />
                </IconButton>

                <DelegationProfiles
                  type={delegationProfileTypes.MONITORING_PROFILES}
                  action="general.delete"
                >
                  <IconButton
                    data-testid={`monitoring_profile_delete_${profileId}`}
                    id={`MONITORING_PROFILE_BUTTON_DELETE_${profileId}`}
                    aria-label={t("Delete")}
                    onClick={() => onDeleteClick({ id: profileId, name })}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </DelegationProfiles>
              </>
            }
          >
            <TableCell className={commonClasses.commonTableCellDefault}>
              <Typography
                variant="h4"
                className={commonClasses.commonTablePrimary}
              >
                <Tooltip title={ubiqubeId}>
                  <span>{name}</span>
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell
              className={commonClasses.commonTableCellDefault}
              align="center"
            >
              <Typography
                variant="body1"
                className={commonClasses.commonTableSecondary}
              >
                {dateModified
                  ? displayMonthDayYearTimeDate(dateModified)
                  : t("N/A")}
              </Typography>
            </TableCell>
            <TableCell
              className={commonClasses.commonTableCellDefault}
              align="center"
            >
              <div className={commonClasses.commonFlexCenter}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableInstancesNumber}
                >
                  {numberOfMEAttached}
                </Typography>
                <IconButton
                  data-testid={`monitoring_profile_managedentity_${profileId}`}
                  id={`MONITORING_PROFILE_BUTTON_MANAGED_ENTITY_${profileId}`}
                  onClick={() =>
                    onAttachedManagedEntitiesClick({
                      name,
                      id: profileId,
                    })
                  }
                >
                  <ManagedEntityIcon color="primary" />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        ),
      )}
    </TableBody>
  );
};

TBody.propTypes = {
  t: PropTypes.func.isRequired,
  colSpan: PropTypes.number.isRequired,
  editLinkBuilder: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  subtenantId: PropTypes.number,
  monitoringProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      dataModified: PropTypes.string,
      name: PropTypes.string.isRequired,
      numberOfMEAttached: PropTypes.number.isRequired,
      profileId: PropTypes.string.isRequired,
      ubiqubeId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onAttachClick: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onAttachedManagedEntitiesClick: PropTypes.func.isRequired,
};

export default TBody;
