import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  topology: {
    showContextMenu: false,
  },
  filters: {
    alarms: {
      searchValue: "",
      alarmName: "",
      ack: "false",
      severities: [],
      managedEntityIds: [],
      startDate: null,
      endDate: null,
      logType: null,
    },
    logs: {
      searchValue: "",
      severities: [],
      managedEntityIds: [],
      startDate: null,
      endDate: null,
      logType: null,
    },
  },
};

const ui = createSlice({
  name: "UI",
  initialState,
  reducers: {
    resetUi() {
      return initialState;
    },
    setTopologySectionValue(state, action) {
      state.topology[action.payload.key] = action.payload.value;
    },
    setFilters(state, action) {
      state.filters[action.payload.table] = action.payload.value;
    },
    setFilterValue(state, action) {
      state.filters[action.payload.table][action.payload.key] =
        action.payload.value;
    },
  },
});

export const {
  resetUi,
  setTopologySectionValue,
  setFilters,
  setFilterValue,
} = ui.actions;

export const getTopologySectionValue = (value) => ({ ui }) =>
  ui.topology[value];

export const getFilters = (table) => ({ ui }) => ui.filters[table];

export const getFilterValue = (table, key) => ({ ui }) =>
  ui.filters[table] ? ui.filters[table][key] : undefined;

export default ui.reducer;
