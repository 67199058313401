import React from "react";
import PropTypes from "prop-types";

import { IconButton, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

import Toolbar from "msa2-ui/src/components/modal/Toolbar";

const useLocalStyles = makeStyles((theme) => ({
  publish: {
    backgroundColor: theme.colors.green,
    color: theme.colors.white,
  },
  title: {
    marginRight: 15,
  },
  toolbarButton: {
    marginRight: 10,
  },
  fixedToolbar: {
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
  },
}));

const ManagedEntityToolbar = ({
  onClose,
  onSave,
  onDiscard,
  disabled,
  title,
  closeButtonLabel,
  saveButtonLabel,
  discardButtonLabel,
}) => {
  const localClasses = useLocalStyles();
  return (
    <Toolbar className={localClasses.fixedToolbar}>
      <Toolbar.Section>
        <Typography variant="h6" color="inherit" className={localClasses.title}>
          {title}
        </Typography>
      </Toolbar.Section>
      <Toolbar.Section>
        <Button
          id="ME_TOOLBAR_DISCARD_BTN"
          variant="text"
          size="medium"
          color="primary"
          className={localClasses.toolbarButton}
          onClick={onDiscard}
          disabled={disabled}
        >
          {discardButtonLabel}
        </Button>
        <Button
          id="ME_TOOLBAR_SAVE_BTN"
          variant="contained"
          size="medium"
          color="primary"
          className={localClasses.toolbarButton}
          onClick={onSave}
          disabled={disabled}
        >
          {saveButtonLabel}
        </Button>
        <Toolbar.Divider />
        <IconButton
          id="ME_TOOLBAR_CLOSE_BTN"
          aria-label={closeButtonLabel}
          variant="contained"
          size="medium"
          color="default"
          onClick={onClose}
          disabled={disabled}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar.Section>
    </Toolbar>
  );
};

ManagedEntityToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  discardButtonLabel: PropTypes.string.isRequired,
  closeButtonLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
  onPublish: PropTypes.func,
};

export default ManagedEntityToolbar;
