import React from "react";
import { buildRoute, getParentRoute } from "msa2-ui/src/utils/urls";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import Execute from "./Execute";

const ExecuteWithRouting = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    params: { bpmUri: bpmUriFromRoute },
  } = useRouteMatch();
  const bpmUri = decodeURIComponent(bpmUriFromRoute);

  const handleClose = () => {
    history.length > 2
      ? history.goBack()
      : history.push(getParentRoute(pathname));
  };

  const handleExecute = (definitionId, processInstanceId) => {
    history.push(
      buildRoute(
        pathname.endsWith("execute") ? getParentRoute(pathname) : pathname,
        [definitionId, processInstanceId].join("/"),
      ),
    );
  };

  return (
    <Execute bpmUri={bpmUri} onClose={handleClose} onExecute={handleExecute} />
  );
};

export default ExecuteWithRouting;
