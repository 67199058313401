import { get, put, destroy, post } from "./request";
const API = process.env.REACT_APP_API_PATH;

const parseParameters = (response) =>
  response.map((order) => ({
    ...order,
    parameters: JSON.parse(order.parameters),
  }));
/*
Spec: https://10.31.1.52/swagger/#/OrderStackManagement/viewStack
Adds a command in the stack and returns the ID of the order in the stack.
*/
export const listOrderStack = ({ deviceId, token, transforms = [] }) => {
  return get({
    url: `${API}/orderstack/${deviceId}`,
    token,
    transforms: [parseParameters, ...transforms],
  });
};

/*
Spec: https://10.31.1.52/swagger/#/OrderStackManagement/pushOrderCommand
Adds a command in the stack and returns the ID of the order in the stack.
*/
export const addToOrderStack = ({ deviceId, commandType, order, token }) => {
  return put({
    url: `${API}/orderstack/command/${deviceId}/${commandType}`,
    body: { ...order },
    token,
  });
};

/*
Spec: https://10.31.1.52/swagger/#/OrderStackManagement/executeStack
Generates the configuration based on the stacked orders for a device and
stores the configuration items in the database and executes all the commands on the device
*/
export const executeOrderStack = ({ deviceId, token }) => {
  return post({
    url: `${API}/orderstack/execute/${deviceId}`,
    token,
  });
};

/*
 * Remove all the command stacked for a device.
 *
 * Spec: https://10.31.1.52/swagger/#/OrderStackManagement/clearStack
 */
export const clearOrderStack = ({ deviceId, token }) => {
  return destroy({
    url: `${API}/orderstack/${deviceId}`,
    token,
  });
};

/*
Spec: https://10.31.1.52/swagger/#/OrderStackManagement/deleteOrderCommand
Adds a command in the stack and returns the ID of the order in the stack.
*/
export const deleteOrderStackCommand = ({ deviceId, commandId, token }) => {
  return destroy({
    url: `${API}/orderstack/command/${deviceId}/${commandId}`,
    token,
  });
};

/*
Spec: https://10.31.1.52/swagger/#/ordercommand/synchronizeObjectsFromDevice
Whatever configuration is pushed to a device it'll be pulled and saved in backend database
*/
export const synchronizeConfiguration = ({
  deviceId,
  isAsync,
  importWithUpperRank = false,
  importWithSameAndUpperRank = false,
  token,
  microServiceUris = [],
}) => {
  return post({
    url: `${API}/ordercommand/synchronize/${deviceId}`,
    queryParams: gerQueryParamsForSynchronization(
      importWithUpperRank,
      importWithSameAndUpperRank,
    ),
    body: { microServiceUris },
    token,
  });
};

/*
 * Applies configuration defined at microservice execution to a device.
 *
 * Spec: https://10.31.1.52/swagger/#/Device/applyConfigurationToManagedEntity
 */
export const applyConfigurationToManagedEntity = ({ deviceId: id, token }) => {
  return post({
    url: `${API}/device/v2/apply-config/${id}`,
    token,
  });
};

/*
 * Applies configuration defined at microservice execution to a device.
 *
 * Spec: https://10.31.1.52/swagger/#/Device/applyConfigurationToManagedEntity
 */
export const applyConfigurationToManagedEntityAsync = ({
  deviceId: id,
  token,
}) => {
  return post({
    url: `${API}/device/v2/apply-config-async/${id}`,
    token,
  });
};

/*
 * Applies configuration defined at microservice execution to a device.
 *
 * Spec: https://10.31.1.52/swagger/#/Device/applyConfigurationToManagedEntity
 */
export const getPushConfigurationStatus = ({ deviceId: id, token }) => {
  return get({
    url: `${API}/device/push_configuration/status/${id}`,
    token,
  });
};

/*
 * Execute a command passed as a parameter
 *
 * Spec: https://10.31.1.52/swagger/#/ordercommand/executeCommand
 */
export const executeCommand = ({ deviceId, commandName, order, token }) => {
  return post({
    url: `${API}/ordercommand/execute/${deviceId}/${commandName}`,
    body: order,
    token,
  });
};

/**
 * Get the status of the synchronization of device configurations
 * @param {*} deviceId
 * @param {*} token
 */

export const getSynchronizeConfigurationStatus = ({ deviceId, token }) => {
  return get({
    url: `${API}/ordercommand/synchronize/status`,
    queryParams: {
      deviceId: deviceId,
    },
    token,
  });
};

const gerQueryParamsForSynchronization = (
  importWithUpperRank,
  importWithSameAndUpperRank,
) => {
  if (importWithUpperRank) return { isAsync: true, importWithUpperRank };
  if (importWithSameAndUpperRank)
    return { isAsync: true, importWithSameAndUpperRank };
  return { isAsync: true };
};
