import React, { useMemo, useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import Dashboard from "msa2-ui/src/services/Dashboard";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles(({ darkMode, palette }) => ({
  [darkMode && "@global"]: {
    ".react-grid-item .react-resizable-handle": {
      "&:after": {
        content: "",
        position: "absolute",
        right: 3,
        bottom: 3,
        width: 10,
        height: 10,
        borderRight: `2px solid ${palette.text.primary}`,
        borderBottom: `2px solid ${palette.text.primary}`,
      },
    },
  },
}));

const ResponsiveWidthProvider = WidthProvider(Responsive);
const ResponsiveGridLayout = ({
  useCSSTransforms = false,
  className = "layout",
  resizeHandles = ["s", "e", "se"],
  useWindowWidth = false,
  ...rest
}) => {
  useStyles();
  const { breakpoints } = useTheme();
  const cols = useMemo(
    () =>
      breakpoints.keys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: Dashboard.getGridSize(key, breakpoints?.values),
        }),
        {},
      ),
    [breakpoints],
  );

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    if (useWindowWidth) {
      const handleResize = () => {
        setInnerWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [useWindowWidth]);

  const ReactGridLayout = useWindowWidth ? Responsive : ResponsiveWidthProvider;
  return (
    <ReactGridLayout
      width={useWindowWidth ? innerWidth : undefined}
      useCSSTransforms={useCSSTransforms}
      className={className}
      resizeHandles={resizeHandles}
      // Integrate with Material UI breakpoints as we don't want to have two standards
      breakpoints={breakpoints.values}
      cols={cols}
      {...rest}
    />
  );
};

ResponsiveGridLayout.propTypes = ResponsiveWidthProvider.propTypes;

export default ResponsiveGridLayout;
