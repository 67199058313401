import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

import omit from "lodash/omit";
import { getToken } from "msa2-ui/src/store/auth";
import { editSubtenant } from "msa2-ui/src/api/subtenant";
import {
  getAvailableTenants,
  fetchSubtenants,
} from "msa2-ui/src/store/designations";

import { Grid, Typography, TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import Dialog from "msa2-ui/src/components/Dialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import BasicSelect from "msa2-ui/src/components/BasicSelect";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const Edit = ({ subtenants }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams();

  const tenants = useSelector(getAvailableTenants);
  const token = useSelector(getToken);
  const subtenant = subtenants.find((sub) => sub.id === parseInt(id));

  const subtenantPrefix = subtenant?.operatorPrefix || subtenant?.prefix;
  const [name, setName] = useState(subtenant?.label || subtenant?.name);
  const [email] = useState(subtenant?.contacts?.[0]?.address?.email || "");
  const isTenantNotInList = tenants.every(
    ({ value }) => value !== subtenant.operatorPrefix,
  );
  const [externalReference, setExternalReference] = useState(
    subtenant?.externalReference,
  );
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const closeDialog = useCallback(() => history.push("/admin/subtenants"), [
    history,
  ]);

  const isNameDuplicated = useCallback(() => {
    const currentName = subtenant.label || subtenant.name;
    return (
      currentName !== name &&
      subtenants.some((sub) => (sub.label || sub.name) === name)
    );
  }, [subtenant, name, subtenants]);

  const isRefDuplicated = useCallback(() => {
    return (
      subtenant.externalReference !== externalReference &&
      subtenants.some((sub) => sub.externalReference === externalReference)
    );
  }, [subtenant, externalReference, subtenants]);

  const onSubmit = useCallback(async () => {
    setErrorMessage("");
    setSubmitting(true);

    if (!name) {
      setErrorMessage(t("Please fill out all the form fields"));
      setSubmitting(false);
      return;
    }

    if (isNameDuplicated() || isRefDuplicated()) {
      setErrorMessage(
        isNameDuplicated()
          ? t("Please use a unique Name")
          : t("Please use a unique name for External Reference"),
      );
      setSubmitting(false);
      return;
    }

    const subTenantData = {
      ...subtenant,
      operatorPrefix: subtenantPrefix,
      civility: null,
      company: false,
      contacts: null,
      firstname: "",
    };

    const fieldsToRemove = [
      "ubiId",
      "prefix",
      "operatorId",
      "displayName",
      "displayNameForJsps",
      "type",
      "managersCount",
      "sitesStatus",
      "lastUpdated",
      "cclaEnv",
      "operator",
    ];
    fieldsToRemove.forEach((key) => delete subTenantData[key]);

    const subTenantWithOmittedValues = omit(subTenantData, [
      "value",
      "id",
      "ubiqubeId",
      "label",
    ]);
    const [error] = await editSubtenant({
      token,
      subtenant: subTenantWithOmittedValues,
      name,
      email,
      operatorPrefix: subtenantPrefix,
      externalReference,
      id,
    });

    setSubmitting(false);
    const variant = error ? "error" : "success";
    const message = error ? error.getMessage() : t("Subtenant updated");
    if (!error) {
      dispatch(fetchSubtenants());
      closeDialog();
    }

    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  }, [
    id,
    name,
    externalReference,
    subtenant,
    subtenantPrefix,
    t,
    dispatch,
    enqueueSnackbar,
    closeSnackbar,
    isNameDuplicated,
    isRefDuplicated,
    closeDialog,
    email,
    token,
  ]);

  return (
    <Dialog
      onExec={onSubmit}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={t("Edit Subtenant")}
      disabled={submitting}
    >
      <Grid container direction="column">
        <TextField
          type={"text"}
          id="SUBTENANT_EDIT_NAME"
          variant="outlined"
          label={t("Name")}
          value={name}
          required
          onChange={({ target: { value } }) => setName(value)}
          className={classes.formField}
        />
        {isTenantNotInList ? (
          <TextField
            label={t("Tenant")}
            variant="outlined"
            name={t("Tenant")}
            id="SUBTENANT_EDIT_ASSOCIATED_TENANT"
            className={classes.formField}
            value={subtenantPrefix}
            type={"text"}
            disabled
          />
        ) : (
          <BasicSelect
            label={t("Tenant")}
            variant="outlined"
            name={t("Tenant")}
            id="SUBTENANT_EDIT_ASSOCIATED_TENANT"
            className={classes.formField}
            value={subtenantPrefix}
            required
            disabled
          >
            {tenants.map((tenant, index) => (
              <MenuItem
                value={tenant.value}
                key={index}
                id={`SUBTENANT_EDIT_TENANT_${index}`}
              >
                {tenant.label}
              </MenuItem>
            ))}
          </BasicSelect>
        )}
        <TextField
          type={"text"}
          id="SUBTENANT_EDIT_EXTERNAL_REFERENCE"
          variant="outlined"
          className={classes.formField}
          label={t("External Reference")}
          value={externalReference}
          onChange={({ target: { value } }) => setExternalReference(value)}
        />

        {errorMessage && (
          <Typography
            color="error"
            className={classes.error}
            id="SUBTENANT_EDIT_ERROR"
          >
            {errorMessage}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default Edit;
