import React from "react";
import { Route, useRouteMatch, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { getAlarms } from "msa2-ui/src/api/alarms";
import useApi from "msa2-ui/src/hooks/useApi";
import { buildRoute } from "msa2-ui/src/utils/urls";
import useActiveRoute from "msa2-ui/src/hooks/useActiveRoute";
import useFilter from "msa2-ui/src/hooks/useFilter";

import {
  Grid,
  Table,
  Button,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import AlarmsTableBody from "./AlarmsTableBody";
import CreateAlarm from "./CreateAlarm";
import EditAlarm from "./EditAlarm";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

export const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    active: false,
  },
  {
    id: "description",
    name: "Description",
    align: "left",
    active: false,
  },
  {
    id: "timestamp",
    name: "Date Modified",
    align: "left",
    active: false,
  },
  {
    id: "actions",
    name: "",
    align: "right",
    active: false,
  },
];

export default ({ sectionTabs: SectionTabs }) => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const commonClasses = useCommonStyles();

  const [filterState, filterActions] = useFilter({
    tableName: "alarms",
    tpPage: 0,
  });

  const [loading, error, alarms = [], alarmsMeta = {}, reloadAlarms] = useApi(
    getAlarms,
    {
      page: filterState.tpPage + 1,
      pageSize: filterState.tpRowsPerPage,
    },
  );

  useActiveRoute(reloadAlarms);

  const alarmsCount = parseInt(alarmsMeta.rules_total_count) || 0;

  const canCreateAlarm = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "manageAlarms.create"),
  );

  return (
    <>
      <SectionTabs
        count={[path, alarmsCount]}
        endElements={
          canCreateAlarm && (
            <Button
              id="ALARMS_BTN_CREATE_LINK"
              variant="contained"
              color="primary"
              aria-label={`${t("Create")} ${t("Alarm")}`}
              component={Link}
              to={{ pathname: buildRoute(url, "create") }}
            >
              <IconPlus className={commonClasses.commonBtnIcon} />
              {`${t("Create")} ${t("Alarm")}`}
            </Button>
          )
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={TABLE_HEADER_COLUMNS.length}
                    className={commonClasses.commonTableCell}
                  >
                    <FilterMenu
                      tpPage={filterState.tpPage}
                      tpTotal={alarmsCount}
                      tpRowsPerPage={filterState.tpRowsPerPage}
                      tpChangePage={filterActions.tpChangePage}
                      tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                    <TableCell
                      key={tableHeaderColumn.id}
                      align={tableHeaderColumn.align}
                      className={commonClasses.commonTableCellDefault}
                    >
                      {tableHeaderColumn.active ? (
                        <TableSortLabel
                          id={`ALARMS_TABLE_SORT_${tableHeaderColumn.id}`}
                        >
                          {t(tableHeaderColumn.name)}
                        </TableSortLabel>
                      ) : (
                        tableHeaderColumn.name
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <AlarmsTableBody
                loading={loading}
                error={error}
                reload={reloadAlarms}
                alarms={alarms}
                columns={TABLE_HEADER_COLUMNS}
              />
              <Route path={buildRoute(path, "create")}>
                <CreateAlarm />
              </Route>
              <Route path={buildRoute(path, ":alarmId/edit")}>
                <EditAlarm />
              </Route>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
