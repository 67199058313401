import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useToggle from "react-use/lib/useToggle";

import {
  Button,
  Hidden,
  Popover,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { getRoleDetails, userRoles } from "msa2-ui/src/store/auth";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import SelectSearch from "./SelectSearch";

const useStyles = makeStyles((theme) => {
  const { palette, darkMode, colors, breakpoints, typography } = theme;
  return {
    topNav: {
      display: "flex",
      paddingTop: 5,
      [breakpoints.down("md")]: {
        margin: 10,
        order: 2,
      },
    },
    breadcrumbContainer: {
      display: "flex",
    },
    separator: {
      bottom: -3,
      position: "relative",
      fontSize: "1rem",
      color: palette.text.secondary,
      opacity: 0.3,
      marginLeft: 8,
      marginRight: 14,
    },
    title: {
      fontSize: "1rem",
      letterSpacing: 0.5,
      lineHeight: "1.8rem",
      maxWidth: 200,
    },
    btnRoot: {
      padding: "2px 2px 2px 7px",
      background: "transparent",
      borderRadius: 4,
      fontSize: "0.75rem",
      color: darkMode ? palette.secondary.main : palette.text.secondary,
      fontWeight: typography.fontWeightMedium,
      lineHeight: 1,
      textTransform: "uppercase",
      letterSpacing: 0.3,
      border: 0,
      marginLeft: 5,
      "&:hover": {
        background: darkMode ? "#2c3949" : "#F4F8FC",
      },
      "&:disabled": {
        color: colors.blue,
        border: 0,
      },
    },
    btnLabel: {
      color: darkMode ? palette.secondary.main : palette.text.secondary,
    },
    btnOpen: {
      background: darkMode ? "#2c3949" : "#E6ECF7",
    },
    paper: {
      overflowY: "inherit",
      overflowX: "inherit",
    },
    popoverContainer: {
      position: "relative",
      top: 7,
      width: 300,
      flexGrow: 1,
      boxShadow: darkMode
        ? "1px -1px 12px -1px #0059a7"
        : "0 6px 16px 4px rgba(81, 97, 133, 0.13), 0 3px 8px 0 rgba(0, 0, 0, 0.15)",
      border: darkMode ? "none" : "1px solid" + colors.greyLight1,
      borderRadius: "5px 5px 0 0",
      backgroundColor: palette.background.paper,
      "&:after, &:before": {
        bottom: "100%",
        left: "50%",
        border: "solid transparent",
        content: '" "',
        height: 0,
        width: 0,
        position: "absolute",
        pointerEvents: "none",
      },
      "&:after": {
        borderColor: darkMode ? "none" : "rgba(255, 255, 255, 0)",
        borderBottomColor: "#fff",
        borderWidth: 6,
        marginLeft: -6,
      },
      "&:before": {
        borderColor: darkMode ? "none" : "rgba(191, 201, 217, 0)",
        borderBottomColor: darkMode ? "none" : colors.greyLight1,
        borderWidth: 7,
        marginLeft: -7,
      },
    },
  };
});

const TenantSubtenantSelection = ({
  title,
  selectedSubtenant,
  selectedTenant,
  subtenants,
  tenants,
  multiTenant,
  onSelectSubtenant,
  onSelectTenant,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const tenantButton = useRef(null);
  const subtenantButton = useRef(null);

  const roleDetails = useSelector(getRoleDetails);
  const [subtenantIsOpen, toggleSubtenant] = useToggle(false);
  const [tenantIsOpen, toggleTenant] = useToggle(false);
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );

  const dismissPopovers = useCallback(() => {
    toggleSubtenant(false);
    toggleTenant(false);
  }, [toggleSubtenant, toggleTenant]);

  const lockSubtenantList =
    roleDetails?.id === userRoles.MANAGER &&
    subtenants.length === 1 &&
    !isWorkflowsOwnerDetailsEnabled;

  useEffect(() => {
    window.addEventListener("scroll", dismissPopovers);

    return () => {
      window.removeEventListener("scroll", dismissPopovers);
    };
  }, [dismissPopovers]);

  const handleSelectSubtenant = (id, subtenant) => {
    onSelectSubtenant(subtenant);
  };

  const handleSelectTenant = (id, tenant) => {
    onSelectTenant(tenant);
  };
  useEffect(() => {
    if (lockSubtenantList) {
      onSelectSubtenant(subtenants[0]);
    }
  }, [lockSubtenantList, onSelectSubtenant, subtenants]);

  return (
    <nav className={classes.topNav}>
      {/* Title */}
      <Hidden mdDown>
        <div className={classes.breadcrumbContainer}>
          <Typography
            id="msaUserRoleTitle"
            variant="subtitle1"
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
        </div>
        <span className={classes.separator}>/</span>
      </Hidden>

      {/* Tenant Selection */}
      <div className={classes.breadcrumbContainer}>
        <Typography
          variant="subtitle1"
          id="msaTenantLabel"
          noWrap
          className={classes.title}
        >
          {selectedTenant.label || t("Tenant")}
        </Typography>
        {multiTenant ? (
          <Button
            id="msaTenantBtn"
            aria-haspopup="true"
            variant="outlined"
            size="small"
            disableRipple
            onClick={toggleTenant}
            classes={{
              root: classes.btnRoot,
              label: classes.btnLabel,
            }}
            className={tenantIsOpen ? classes.btnOpen : classes.btnClosed}
            ref={tenantButton}
          >
            {t("Tenant")}
            {tenantIsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Popover
              id="msaTenantSelection"
              open={tenantIsOpen}
              anchorEl={tenantButton.current}
              onClose={dismissPopovers}
              BackdropComponent={() => <></>}
              classes={{
                paper: classes.paper,
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className={classes.popoverContainer}>
                <SelectSearch
                  id="tenant"
                  options={tenants}
                  multiOption={false}
                  selectedOption={selectedTenant}
                  noOptionText={t("Tenant not found.")}
                  onSelect={handleSelectTenant}
                />
              </div>
            </Popover>
          </Button>
        ) : (
          <Button
            id="msaTenantBtn"
            disabled
            variant="outlined"
            size="small"
            disableRipple
            classes={{
              disabled: classes.btnRoot,
            }}
          >
            {t("Tenant")}
          </Button>
        )}
      </div>

      <span className={classes.separator}>/</span>

      {/* Subtenant Selection */}
      <div className={classes.breadcrumbContainer}>
        <Tooltip
          title={selectedSubtenant.label}
          disableHoverListener={selectedSubtenant.label === null}
        >
          <Typography
            variant="subtitle1"
            id="msaSubtenantLabel"
            noWrap
            className={classes.title}
          >
            {selectedSubtenant.label ? selectedSubtenant.label : t("Subtenant")}
          </Typography>
        </Tooltip>
        <Button
          id="msaSubtenantBtn"
          aria-haspopup="true"
          variant="outlined"
          size="small"
          disableRipple
          className={subtenantIsOpen ? classes.btnOpen : classes.btnClosed}
          classes={{
            root: classes.btnRoot,
            label: classes.btnLabel,
          }}
          onClick={toggleSubtenant}
          ref={subtenantButton}
        >
          {t("Subtenant")}
          {subtenantIsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Popover
            id="msaSubtenantSelection"
            open={subtenantIsOpen}
            anchorEl={subtenantButton.current}
            onClose={dismissPopovers}
            BackdropComponent={() => <></>}
            classes={{
              paper: classes.paper,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className={classes.popoverContainer}>
              <SelectSearch
                id="subtenant"
                options={subtenants}
                multiOption={false}
                selectedOption={selectedSubtenant}
                noOptionText={t("Subtenant not found.")}
                onSelect={handleSelectSubtenant}
                isClearable={!lockSubtenantList}
              />
            </div>
          </Popover>
        </Button>
      </div>
    </nav>
  );
};

TenantSubtenantSelection.propTypes = {
  title: PropTypes.string,
  selectedSubtenant: PropTypes.object.isRequired,
  selectedTenant: PropTypes.object.isRequired,
  subtenants: PropTypes.array.isRequired,
  tenants: PropTypes.array.isRequired,
  multiTenant: PropTypes.bool.isRequired,
  onSelectSubtenant: PropTypes.func.isRequired,
  onSelectTenant: PropTypes.func.isRequired,
};

export default TenantSubtenantSelection;
