import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useSnackbar } from "notistack";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getToken } from "msa2-ui/src/store/auth";

import classnames from "classnames";
import {
  Avatar,
  Box,
  Chip,
  Hidden,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import TableRow from "msa2-ui/src/components/TableRow";

const VARIABLE_VALUE_LENGTH_LIMIT = 50;

const Edit = ({ rowName, t, editLinkTo, onEdit }) => (
  <Tooltip title={t("Edit")}>
    {editLinkTo ? (
      <Link
        id={`TABLE_ROW_${rowName}_EDIT`}
        aria-label={t("Edit")}
        to={editLinkTo}
      >
        <IconButton>
          <EditIcon color="primary" />
        </IconButton>
      </Link>
    ) : (
      <IconButton aria-label={t("Edit")} onClick={onEdit}>
        <EditIcon color="primary" />
      </IconButton>
    )}
  </Tooltip>
);
const Delete = ({ rowName, t, showDeleteDialog }) => (
  <Tooltip title={t("Delete")}>
    <IconButton
      id={`TABLE_ROW_${rowName}_DELETE`}
      aria-label={t("Delete")}
      onClick={() => showDeleteDialog()}
    >
      <DeleteIcon color="error" />
    </IconButton>
  </Tooltip>
);

const MSATableRow = ({
  id,
  columns,
  description,
  chip,
  data,
  deleteAPI,
  callbackPostDelete,
  editLinkTo,
  onEdit,
  delegationEdit,
  delegationDelete,
  onClick,
  Icon,
  OtherActions,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const rowName = id || data[columns[0].id];
  const token = useSelector(getToken);
  const onDelete = async () => {
    const [error] = await deleteAPI(token);
    const variant = error ? "error" : "success";
    const message = error
      ? t("Unable to delete x", { x: rowName })
      : t("x has been deleted", { x: rowName });

    if (!error) {
      showDeleteDialog(false);
      callbackPostDelete && callbackPostDelete();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };
  return (
    <>
      <DeleteDialog
        title={t("Confirm Request")}
        content={t("Are you sure you want to delete x?", {
          x: rowName,
        })}
        onExec={() => onDelete()}
      />
      <TableRow
        id={rowName}
        actions={
          <>
            {OtherActions && <OtherActions />}
            {(editLinkTo || onEdit) &&
              (delegationEdit ? (
                <DelegationProfiles {...delegationEdit}>
                  <Edit {...{ rowName, t, editLinkTo, onEdit }} />
                </DelegationProfiles>
              ) : (
                <Edit {...{ rowName, t, editLinkTo, onEdit }} />
              ))}
            {deleteAPI &&
              (delegationDelete ? (
                <DelegationProfiles {...delegationDelete}>
                  <Delete {...{ rowName, t, showDeleteDialog }} />
                </DelegationProfiles>
              ) : (
                <Delete {...{ rowName, t, showDeleteDialog }} />
              ))}
          </>
        }
      >
        {columns
          // filter out the last columns as it should be for the actions
          .filter((_, i) => i !== columns.length - 1)
          .map(({ id, format, tooltipId = "" }, index) => {
            const isFirstItem = index === 0;
            const value = format ? format(data[id]) : data[id]?.toString();
            const isLongValue =
              value?.toString().length > VARIABLE_VALUE_LENGTH_LIMIT;
            return (
              <TableCell
                key={id}
                className={classnames(commonClasses.commonTableCellDefault, {
                  [commonClasses.commonTableCellClickable]: onClick,
                })}
                onClick={() => {
                  if (onClick) {
                    onClick(data);
                  }
                }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  {isFirstItem && Icon && (
                    <Box p={1}>
                      <Avatar className={commonClasses.commonTableAvatar}>
                        <Icon />
                      </Avatar>
                    </Box>
                  )}
                  <Box p={1}>
                    <Tooltip
                      title={data[tooltipId] || isLongValue ? value : ""}
                    >
                      <Typography
                        variant={isFirstItem ? "h4" : "body1"}
                        className={classnames(commonClasses.overflowAnywhere, {
                          [commonClasses.commonTablePrimary]: isFirstItem,
                          [commonClasses.commonTableSecondary]: !isFirstItem,
                          [commonClasses.commonTwoLineWrapper]: isLongValue,
                        })}
                      >
                        {value}{" "}
                        {isFirstItem &&
                          chip &&
                          data[chip.id]
                            .split(chip.separator ?? ",")
                            .map((group, i) => (
                              <Hidden key={i} mdDown>
                                <Chip
                                  key={group}
                                  label={group}
                                  classes={{
                                    label: commonClasses.commonTagLabel,
                                  }}
                                  className={classnames(
                                    commonClasses.commonTags,
                                    commonClasses.commonTagSystem1,
                                  )}
                                />
                              </Hidden>
                            ))}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>

                {isFirstItem && description && (
                  <Hidden mdDown>
                    <Typography
                      className={classnames(
                        commonClasses.commonSpacer,
                        commonClasses.commonDescription,
                      )}
                    >
                      {data[description.id]}
                    </Typography>
                  </Hidden>
                )}
              </TableCell>
            );
          })}
      </TableRow>
    </>
  );
};

MSATableRow.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  deleteAPI: PropTypes.func,
  callbackPostDelete: PropTypes.func,
  editLinkTo: PropTypes.string,
  onEdit: PropTypes.func,
  delegationEdit: PropTypes.shape({
    type: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }),
  delegationDelete: PropTypes.shape({
    type: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func,
  Icon: PropTypes.elementType,
};

export default MSATableRow;
