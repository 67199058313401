import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import flow from "lodash/flow";
import get from "lodash/get";
import set from "lodash/set";
import forEachRight from "lodash/forEachRight";
import cloneDeep from "lodash/cloneDeep";

import Process from "msa2-ui/src/services/Process";
import Variable from "msa2-ui/src/services/Variable";
import Validation from "msa2-ui/src/services/Validation";

import { makeStyles } from "@material-ui/core";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";

import Dialog from "msa2-ui/src/components/Dialog";

const useStyles = makeStyles(() => ({
  paper: {
    padding: 16,
    marginBottom: 12,
    width: "inherit",
  },
  variableTable: {
    marginTop: 40,
    marginBottom: 30,
  },
  table: {
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    maxHeight: 300,
    marginTop: 10,
    marginBottom: 20,
  },
  controlButtons: {
    marginLeft: 125,
  },
  iconPlaceHolder: {
    minWidth: 60,
  },
  headerCell: {
    textOverflow: "ellipsis",
  },
  checkboxCell: {
    width: 42,
  },
  iconCell: {
    width: 42,
  },
  section: {
    marginBottom: 10,
    textAlign: "left",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  buttonWrapper: {
    paddingLeft: 4,
  },
  variableWrapper: {
    marginTop: 4,
    marginBottom: 4,
    width: "inherit",
    textAlign: "left",
  },
  nestedArea: {
    cursor: "pointer",
    "&:hover, &:active, &:focus": {
      backgroundColor: "rgba(128, 162, 217, 0.05)",
    },
  },
  descriptionIcon: {
    marginLeft: 7,
    marginBottom: 3,
    marginRight: 10,
  },
}));

export const RunTimeVariablesTable = ({
  data,
  variables,
  editMode,
  handleOnChange,
  depth = 1,
  currentPath,
  noHeader = false,
  MSAVariable,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const [nestedVariables, setNestedVariables] = useState();
  const _currentData = get(data, currentPath, []);
  const currentData = Array.isArray(_currentData) ? _currentData : [];

  const [selected, setSelected] = useState(
    Array(currentData.length).fill(false),
  );

  if (!variables.length) return null;

  const allSelected = selected.length > 0 && selected.every((s) => s === true);
  const indeterminatelySelected =
    selected.some((s) => s === true) && selected.some((s) => s === false);

  // Assuming all variables in the same table have the same attributes related with array, take a first variable
  const { arrayCanAdd, arrayCanMove, arrayCanRemove } = variables[0];
  const { deepTableSection } = Variable.getTableValData(variables[0].name);
  const shouldShowCheckbox = editMode && (arrayCanMove || arrayCanRemove);

  // functions for UI
  // returns Boolean if the table instance can go up more
  const canMoveUp = (range = selected.length) =>
    !selected
      .slice(0, range)
      .slice(0, selected.filter((arr, i) => arr).length)
      .every((arr) => arr);

  // returns Boolean if the table instance can go down more
  const canMoveDown = (range = selected.length) =>
    !selected
      .slice(-range)
      .slice(-selected.filter((arr, i) => arr).length)
      .every((arr) => arr);

  const handleSelectTableInstance = (instanceNumber, checked) => {
    const _selected = cloneDeep(selected);
    _selected[instanceNumber] = checked;
    setSelected(_selected);
  };

  const handleAddTableInstance = (depth) => {
    const _selected = cloneDeep(selected);
    const sectionData = cloneDeep(currentData);

    const defaultInstance = variables.reduce((acc, variable) => {
      // get ids from the original variable name which includes "params.".
      const { id, tableKey, arrayDepth } = Variable.getTableValData(
        variable.name,
        depth,
      );
      // if the object is deeper, do not create a default value as it should be created in the deep level
      // eg. bad case: { "deep.0.var": "Default Value" }
      if (depth < arrayDepth) return acc;

      // Current variable path
      const variableKey = [currentPath, sectionData?.length, tableKey].join(
        ".",
      );

      // get a variable default value
      const defaultValue = flow(
        (variables, id) => Variable.popTargetVariable(variables, id),
        (variable) =>
          Variable.getDefaultValue({
            variable,
            variables,
            data,
            instanceData: sectionData,
            key: variableKey,
          }),
      )(variables, id);

      return {
        ...acc,
        [tableKey]: defaultValue,
      };
    }, {});

    // push init table object to state data
    sectionData.push(defaultInstance);
    _selected.push(false);

    handleOnChange(sectionData, currentPath);
    setSelected(_selected);
  };

  // Move up and Move down instances in the table
  const handleMoveTableInstance = (moveCount) => {
    const _selected = cloneDeep(selected);
    const sectionData = cloneDeep(currentData);
    const isNegative = Math.sign(moveCount) === -1;
    // if it's move up, start from 0. if move down, loop backward.
    for (
      let i = isNegative ? 0 : _selected.length;
      isNegative ? i <= _selected.length : i >= 0;
      isNegative ? i++ : i--
    ) {
      if (
        // if check box is true
        _selected[i] &&
        // and if the instance can move up/down (= if it's not on very top/bottom)
        ((isNegative && canMoveUp(i)) ||
          (!isNegative && canMoveDown(_selected.length - i + 1)))
      ) {
        // get the target instance
        const moveData = sectionData.splice(i, 1)[0];
        const moveSelection = _selected.splice(i, 1)[0];
        // insert the instance
        sectionData.splice(i + moveCount, 0, moveData);
        _selected.splice(i + moveCount, 0, moveSelection);
      }
    }
    handleOnChange(sectionData, currentPath);
    setSelected(_selected);
  };

  // delete table instances. (from "Remove" button or the trash icon)
  const handleDeleteTableInstance = (target) => {
    const _selected = cloneDeep(selected);
    const sectionData = cloneDeep(currentData);
    if (typeof target === "undefined") {
      const target = [..._selected];
      // case from the "Remove" button
      forEachRight(target, (checked, i) => {
        // delete an instance for clicked row
        if (checked) {
          sectionData.splice(i, 1);
          _selected.splice(i, 1);
        }
      });
    } else {
      // case from the delete icon
      sectionData.splice(target, 1);
      _selected.splice(target, 1);
    }
    handleOnChange(sectionData, currentPath);
    setSelected(_selected);
  };

  // Table variables to be shown
  const visibleTableVariables = variables.filter((variable) =>
    Variable.shouldShow(variable, editMode),
  );

  return (
    <>
      {nestedVariables && (
        <Dialog
          title={variables[0].sections?.[0] ?? deepTableSection}
          maxWidth={"xl"}
          onClose={() => setNestedVariables()}
          fullWidth={false}
        >
          <RunTimeVariablesTable
            data={data}
            variables={nestedVariables.variables}
            editMode={editMode}
            handleOnChange={handleOnChange}
            depth={depth + 1}
            currentPath={nestedVariables.tableSection}
            MSAVariable={MSAVariable}
          />
        </Dialog>
      )}
      {editMode && (
        <Grid
          item
          xs={12}
          className={classnames(
            commonClasses.commonFlexStart,
            classes.controlButtons,
          )}
        >
          {arrayCanAdd && (
            <Button
              id={`AUTOMATION_DETAILS_ADD_TABLES_${currentPath}`}
              aria-label={t("Add")}
              variant="contained"
              size="small"
              color="primary"
              className={commonClasses.commonBtnPrimary}
              onClick={() => handleAddTableInstance(depth)}
            >
              <IconPlus />
              {t("Add")}
            </Button>
          )}
          {/* if at least one checkbox on the table is checked, show "Move Up", "Move Down", "Remove" buttons */}
          {selected.some((val) => val) && (
            <>
              {arrayCanMove && (
                <>
                  <div className={classes.buttonWrapper}>
                    <Button
                      id={`AUTOMATION_DETAILS_MOVEUP_TABLES_${currentPath}`}
                      aria-label={t("Move Up")}
                      disabled={!canMoveUp()}
                      variant="contained"
                      size="small"
                      color="primary"
                      className={commonClasses.commonBtnPrimary}
                      onClick={() => handleMoveTableInstance(-1)}
                    >
                      {t("Move Up")}
                    </Button>
                  </div>
                  <div className={classes.buttonWrapper}>
                    <Button
                      id={`AUTOMATION_DETAILS_MOVEDOWN_TABLES_${currentPath}`}
                      aria-label={t("Move Down")}
                      disabled={!canMoveDown()}
                      variant="contained"
                      size="small"
                      color="primary"
                      className={commonClasses.commonBtnPrimary}
                      onClick={() => handleMoveTableInstance(+1)}
                    >
                      {t("Move Down")}
                    </Button>
                  </div>
                </>
              )}
              {arrayCanRemove && (
                <div className={classes.buttonWrapper}>
                  <Button
                    id={`AUTOMATION_DETAILS_REMOVE_TABLES_${currentPath}`}
                    aria-label={t("Remove")}
                    variant="contained"
                    size="small"
                    color="primary"
                    className={commonClasses.commonBtnPrimary}
                    onClick={() => handleDeleteTableInstance()}
                  >
                    {t("Remove")}
                  </Button>
                </div>
              )}
            </>
          )}
        </Grid>
      )}
      <div
        className={classes.tableWrapper}
        style={{
          maxWidth: depth === 1 && "fit-content",
        }}
      >
        <Table stickyHeader className={classes.table}>
          <colgroup>
            {shouldShowCheckbox && <col className={classes.checkboxCell} />}
            {shouldShowCheckbox && <col className={classes.iconCell} />}
            {visibleTableVariables.map(({ name, maxLength }) => {
              return <col key={name} style={{ minWidth: "120px" }} />;
            })}
          </colgroup>
          {!noHeader && (
            <TableHead>
              <TableRow
                key="table-header"
                className={commonClasses.commonTableHeadRow}
              >
                {/* when process execution, add header for the checkbox */}
                {shouldShowCheckbox && (
                  <TableCell
                    key="table-header-checkbox"
                    className={[
                      commonClasses.commonTableCellDefault,
                      classes.iconPlaceHolder,
                    ].join(" ")}
                  >
                    <Checkbox
                      checked={allSelected}
                      indeterminate={indeterminatelySelected}
                      onChange={() =>
                        allSelected
                          ? setSelected(Array(selected.length).fill(false))
                          : setSelected(Array(selected.length).fill(true))
                      }
                      color="primary"
                    />
                  </TableCell>
                )}
                {/* when process execution, add header for a bin icon */}
                {shouldShowCheckbox && (
                  <TableCell
                    key="table-header-bin"
                    className={[
                      commonClasses.commonTableCellDefault,
                      classes.iconPlaceHolder,
                    ].join(" ")}
                  />
                )}
                {visibleTableVariables.map((variable, index) => {
                  return (
                    <TableCell
                      key={`table-header-cell-${index}`}
                      style={{
                        minWidth: "120px",
                      }}
                      className={classnames(
                        commonClasses.commonTableCellDefault,
                        classes.headerCell,
                      )}
                    >
                      <Tooltip title={variable.displayName}>
                        <div
                          style={{
                            wordBreak: "break-all",
                          }}
                        >
                          {variable.displayName}
                          {editMode && Boolean(variable.mandatory) && "*"}
                        </div>
                      </Tooltip>
                      {variable.description && (
                        <Tooltip title={variable.description}>
                          <HelpOutlineIcon
                            id={`AUTOMATION_DETAILS_TABLE_DESCRIPTION_TOOLTIP_${variable.name}`}
                            fontSize="small"
                            color="primary"
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {currentData.map((row, rowIndex) => {
              return (
                <TableRow key={`table-row-${rowIndex}`}>
                  {/* when process execution, add a checkbox */}
                  {shouldShowCheckbox && (
                    <TableCell
                      key={`row-checkbox-${rowIndex}`}
                      className={[
                        commonClasses.commonTableCellDefault,
                        classes.iconPlaceHolder,
                      ].join(" ")}
                    >
                      <Checkbox
                        checked={selected[rowIndex] || false}
                        onChange={(event) =>
                          handleSelectTableInstance(
                            rowIndex,
                            event.target.checked,
                          )
                        }
                        color="primary"
                      />
                    </TableCell>
                  )}
                  {/* when process execution, add a bin icon */}
                  {shouldShowCheckbox && (
                    <TableCell
                      key={`row-delete-${rowIndex}`}
                      className={[
                        commonClasses.commonTableCellDefault,
                        classes.iconPlaceHolder,
                      ].join(" ")}
                    >
                      <IconButton
                        id={`AUTOMATION_DETAILS_VARIABLES_DELETE_${currentPath}_${rowIndex}`}
                        aria-label={t("Delete")}
                        onClick={() => handleDeleteTableInstance(rowIndex)}
                      >
                        <IconDeleteRed />
                      </IconButton>
                    </TableCell>
                  )}
                  {visibleTableVariables.map((variable, variableIndex) => {
                    const propKey = `${rowIndex}-${variableIndex}`;

                    const { arrayDepth, tableKey } = Variable.getTableValData(
                      variable.name,
                      depth,
                    );

                    const variableId = [currentPath, rowIndex, tableKey].join(
                      ".",
                    );

                    const shouldShow = Variable.shouldShow(
                      variable,
                      editMode,
                      data,
                      variableId,
                    );

                    // if the current variable should nest | Nested Variables
                    if (depth < arrayDepth) {
                      const {
                        tableSection: nextTableSection,
                        deepTableSection,
                      } = Variable.getTableValData(variable.name, depth + 1);

                      const hasBeenRendered =
                        variableIndex !== 0 &&
                        // get previous variable, compare the variable section to current one
                        nextTableSection ===
                          Variable.getTableValData(
                            variables[variableIndex - 1].name,
                            depth + 1,
                          ).tableSection;
                      if (hasBeenRendered) return null;

                      const { size: tableSize, hidden } = Variable.getTableSize(
                        variables,
                        variableIndex,
                        editMode,
                        depth + 1,
                      );
                      const nextPath = [
                        currentPath,
                        rowIndex,
                        deepTableSection,
                      ].join(".");

                      const nestedVariableList = variables.slice(
                        variableIndex,
                        variableIndex + tableSize,
                      );

                      return (
                        <TableCell
                          key={propKey}
                          aria-label={t("Nested Table")}
                          colSpan={tableSize - hidden}
                          className={classnames(classes.nestedArea)}
                          padding="none"
                          onClick={() =>
                            setNestedVariables({
                              variables: nestedVariableList,
                              tableSection: nextPath,
                            })
                          }
                        >
                          <Tooltip title={t("Click to show nested Variables")}>
                            <span>
                              <RunTimeVariablesTable
                                data={data}
                                variables={nestedVariableList}
                                handleOnChange={handleOnChange}
                                depth={depth + 1}
                                currentPath={nextPath}
                                noHeader
                                MSAVariable={MSAVariable}
                              />
                            </span>
                          </Tooltip>
                        </TableCell>
                      );
                    }

                    // grouped variable should be in the same cell with concatenated all together.
                    if (!editMode && variable.grouped) {
                      // If groupDisplayName is the same as the previous loop,
                      // return null as it's already rendered
                      if (variableIndex !== 0) {
                        const hasBeenRendered =
                          variable.groupDisplayName ===
                          variables[variableIndex - 1].groupDisplayName;
                        if (hasBeenRendered) return null;
                      }

                      const {
                        size: colSpan,
                      } = Variable.countSameValue(variables, variableIndex, [
                        "grouped",
                        "groupDisplayName",
                      ]);
                      const targetVariables = variables.slice(
                        variableIndex,
                        colSpan,
                      );

                      return (
                        <TableCell key={propKey} colSpan={colSpan}>
                          <Grid container>
                            {targetVariables.map((variable, j) => {
                              const { tableKey } = Variable.getTableValData(
                                variable.name,
                                depth,
                              );
                              return (
                                <Fragment key={j}>
                                  {j !== 0 &&
                                    (variable.groupSeparator || <>&nbsp;</>)}
                                  <span style={{ width: "fit-content" }}>
                                    <MSAVariable
                                      id={[
                                        currentPath,
                                        rowIndex,
                                        tableKey,
                                      ].join(".")}
                                      data={data}
                                      variable={variable}
                                    />
                                  </span>
                                </Fragment>
                              );
                            })}
                          </Grid>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={propKey}
                        className={commonClasses.commonTableCellDefault}
                        style={{
                          minWidth: "120px",
                        }}
                      >
                        {(() => {
                          if (!shouldShow) {
                            return <Fragment key={propKey} />;
                          }
                          return (
                            <MSAVariable
                              id={variableId}
                              variable={variable}
                              data={data}
                              isEditing={
                                Boolean(editMode) &&
                                (Process.isCreate(editMode) ||
                                  variable.arrayCanEdit)
                              }
                              handleOnChange={handleOnChange}
                            />
                          );
                        })()}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
const SectionHeader = ({ sectionName }) => {
  const classes = useStyles();
  return (
    <Fragment key={sectionName}>
      <Divider className={classes.divider} />
      <Typography variant="h5" className={classes.section}>
        {sectionName}
      </Typography>
    </Fragment>
  );
};

const RunTimeVariables = ({
  data,
  variables: _variables,
  processVariables: _processVariables,
  editMode = false,
  onChange,
  predefinedValuesEnabled,
  showOnlyProcessVariables = Boolean(editMode && _processVariables),
  getError,
  components,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { MSAVariable } = components;

  // If type or value is set in "create_var_def" in Task,
  // we take it rather than the one defined in WF itself
  const variables = _processVariables
    ? Variable.convertVariablesBasedOnProcess(_variables, _processVariables)
    : _variables;

  const processVariables =
    _processVariables &&
    Variable.filterVariablesBasedOnProcess(variables, _processVariables);

  const targetVariables = showOnlyProcessVariables
    ? processVariables
    : variables;

  // functions for forms
  // change React state "data" corresponding input field. (* "data" is for API POST when execute process)
  const handleOnChange = (value, key) => {
    let _data = cloneDeep(data);
    // If there is Composite type variable, some variable should change their value automatically
    // This looks up objects which can be changed automatically and set default value.
    _data = Variable.maybeChangeValueForComposite(variables, _data, key, value);
    _data = set(_data, key, value === "" ? null : value);
    onChange(_data);
  };

  useEffect(() => {
    if (getError) {
      getError(Validation.variables(data, targetVariables));
    }
  }, [data, getError, targetVariables]);

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        {targetVariables.map((_variable, i, variables) => {
          // Get nested variable if the type is Composite
          const variable = Variable.maybeReplaceWithBehaviourObject(
            _variable,
            data,
          );

          const { id, tableSection, isTable } = Variable.getTableValData(
            variable.name,
          );
          const currentSection = variable.sections?.length
            ? variable.sections[0]
            : "";
          const previousSection =
            i > 0 ? get(variables, [i - 1, "sections", 0], "") : "";

          if (isTable) {
            // If Variable is in the same array group as the previous loop,
            // return null as it's already rendered
            const hasBeenRendered =
              i !== 0 &&
              tableSection ===
                Variable.getTableValData(variables[i - 1].name).tableSection;
            if (hasBeenRendered) return null;
            const { size: tableSize, hidden } = Variable.getTableSize(
              variables,
              i,
              editMode,
            );
            return (
              <Fragment key={i}>
                {previousSection !== currentSection && (
                  <SectionHeader sectionName={currentSection} />
                )}
                {tableSize - hidden > 0 && (
                  <div className={classes.variableTable}>
                    <RunTimeVariablesTable
                      variables={variables.slice(i, i + tableSize)}
                      editMode={editMode}
                      data={data}
                      handleOnChange={handleOnChange}
                      currentPath={tableSection}
                      MSAVariable={MSAVariable}
                    />
                  </div>
                )}
              </Fragment>
            );
          }

          const shouldShow = Variable.shouldShow(variable, editMode, data);
          // not table
          if (!shouldShow) return null;
          return (
            <Fragment key={i}>
              {previousSection !== currentSection && (
                <SectionHeader sectionName={currentSection} />
              )}
              <Grid container key={id}>
                <Grid
                  item
                  xs={3}
                  className={[
                    classes.variableWrapper,
                    commonClasses.commonFlexStart,
                  ].join(" ")}
                >
                  <Typography variant="subtitle2">
                    {variable.displayName}
                    {editMode && Boolean(variable.mandatory) && "*"}
                    {" :"}
                  </Typography>
                  {variable.description && (
                    <Tooltip
                      title={variable.description}
                      className={classes.descriptionIcon}
                    >
                      <HelpOutlineIcon
                        id={`AUTOMATION_DETAILS_DESCRIPTION_TOOLTIP_${variable.name}`}
                        fontSize="small"
                        color="primary"
                      />
                    </Tooltip>
                  )}
                </Grid>
                <Grid
                  item
                  xs={9}
                  className={[
                    classes.variableWrapper,
                    commonClasses.commonFlexStart,
                  ].join(" ")}
                >
                  <MSAVariable
                    id={id}
                    variable={variable}
                    data={data}
                    isEditing={Boolean(editMode)}
                    handleOnChange={handleOnChange}
                    predefinedValuesEnabled={predefinedValuesEnabled}
                    components={components}
                  />
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </Paper>
    </>
  );
};

RunTimeVariables.propTypes = {
  storeInfo: PropTypes.object,
  components: PropTypes.object,
  data: PropTypes.object.isRequired,
  variables: PropTypes.array.isRequired,
  processVariables: PropTypes.object,
  editMode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: (props, propName, componentName) => {
    if (
      // checks only if editMode has value
      props["editMode"] &&
      (props[propName] === undefined || typeof props[propName] !== "function")
    ) {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${props[propName]}\`.`,
      );
    }
  },
  getError: PropTypes.func,
  MSAVariable: PropTypes.func,
};

export default RunTimeVariables;
