/**
 * Function to check whether given value is number or not
 * (i.e: 2, "2", "2.3" are numbers but "123abc" is not)
 *
 * @param {any} value
 * @returns {Boolean}
 */
export const isValidNumber = (value) =>
  [
    typeof value === "number" && !Number.isNaN(value),
    typeof value === "string" && value.match(/^(\d+(\.\d*)?|\.\d+)$/),
  ].some((x) => x);
