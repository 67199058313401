import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { useRouteMatch, useHistory } from "react-router-dom";
import { returnToPreviousPage } from "msa2-ui/src/utils/urls";

import { getToken } from "msa2-ui/src/store/auth";
import { createTenant } from "msa2-ui/src/api/tenant";

import { TextField, Grid, FormControl, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import Dialog from "msa2-ui/src/components/Dialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const useStyles = makeStyles((theme) => ({
  gridSpacing: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const Create = ({ tenants }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const token = useSelector(getToken);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState("");
  const [tenantPrefix, setTenantPrefix] = useState("");
  const [tenantName, setTenantName] = useState("");

  const onSubmit = async () => {
    setErrorMessage("");
    if (!tenantPrefix || !tenantName) {
      setErrorMessage(t("Please fill out the required fields"));
      return;
    }
    if (tenantPrefix.length !== 3) {
      setErrorMessage(t("Tenant Prefix must be three characters long"));
      return;
    }
    const samePrefix = tenants?.find(
      (tenant) => tenant.prefix === tenantPrefix,
    );
    if (samePrefix) {
      setErrorMessage(t("Please use a unique prefix for the Tenant"));
      return;
    }
    const sameName = tenants?.find((tenant) => tenant.name === tenantName);
    if (sameName) {
      setErrorMessage(
        t("Please use a unique name for the x", { x: t("Tenant") }),
      );
      return;
    }

    const [error] = await createTenant({
      token,
      name: tenantName,
      prefix: tenantPrefix,
    });
    const variant = error ? "error" : "success";
    const message = error
      ? `${t("Unable to save")} ${t("Tenant")}`
      : `${t("Tenant")} ${t("created")} `;

    if (!error) {
      closeDialog();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  const closeDialog = () => {
    returnToPreviousPage(history, url);
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={`${t("Create")} ${t("Tenant")}`}
    >
      <Grid container direction="column">
        <Grid className={classes.gridSpacing}>
          <FormControl fullWidth>
            <TextField
              type={"text"}
              id="TENANT_CREATE_PREFIX"
              variant="outlined"
              label={`${t("Tenant")} ${t("Prefix")}`}
              value={tenantPrefix}
              required
              onChange={(event) => {
                setTenantPrefix(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.gridSpacing}>
          <FormControl fullWidth>
            <TextField
              type={"text"}
              id="TENANT_CREATE_NAME"
              variant="outlined"
              label={`${t("Tenant")} ${t("Name")}`}
              required
              value={tenantName}
              onChange={(event) => {
                setTenantName(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.gridSpacing}>
          {errorMessage && (
            <Typography
              color="error"
              className={classes.errorMessage}
              id="TENANT_CREATE_ERROR"
            >
              {errorMessage}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Create;
