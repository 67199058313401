/**
 * A function that rejects a promise if it takes longer than
 * the specified timeout to resolve
 * @param {Promise} promise - The promise to wait for
 * @param {Any} timeoutResult - What will be returned if the promise times out
 * @param {Number} ms - The number of ms to wait
 */
export const asyncTimeout = (promise, timeoutResult, ms = 10000) => {
  const timerPromise = new Promise((resolve) => {
    setTimeout(() => {
      resolve(timeoutResult);
    }, ms);
  });
  return Promise.race([timerPromise, promise]);
};
