import React from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { Grid, Paper } from "@material-ui/core";

import ManagedEntityHistoryTable from "./ManagedEntityHistoryTable";
import ManagedEntityHistoryDetails from "./ManagedEntityHistoryDetails";

const ManagedEntityHistoryTab = ({ managedEntity, maintenanceMode }) => {
  const commonClasses = useCommonStyles();

  const { revision: revisionParam } = useParams();
  const { deviceID: deviceId } = managedEntity;

  return (
    <Grid item xs={12}>
      <Paper
        id={`MANAGED_ENTITY_HISTORY_${deviceId}`}
        className={classNames([
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        ])}
      >
        {revisionParam ? (
          <ManagedEntityHistoryDetails
            deviceId={deviceId}
            revision={revisionParam}
            maintenanceMode={maintenanceMode}
          />
        ) : (
          <ManagedEntityHistoryTable
            deviceId={deviceId}
            maintenanceMode={maintenanceMode}
          />
        )}
      </Paper>
    </Grid>
  );
};

export default ManagedEntityHistoryTab;
