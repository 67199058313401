import React from "react";
import PropTypes from "prop-types";

const TabPanel = ({ children, value, index, ...other }) => {
  if (value !== index) return null;
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <>{children}</>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

export default TabPanel;
