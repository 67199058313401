export const prepareGraphData = (data, initialStateValue, finalStateValue) => {
  const nodes = [];
  const connections = [];
  const connectionsMap = {};

  if (!data) {
    return { nodes, connections };
  }

  const { states = [], transitions = [] } = data;

  states.forEach((nodeData) => {
    const { pk: nodeId, fields } = nodeData;

    nodes.push({
      id: nodeId,
      label: fields.name || "No name",
      active: [initialStateValue, finalStateValue].includes(nodeId),
      __nodeData__: nodeData,
    });

    (fields.transition || []).forEach((transitionId) => {
      const source = nodeId;
      const transition = transitions.find((t) => t.pk === transitionId);
      const target = transition?.fields?.target_state;

      if (!transition || (target !== 0 && !target)) return;

      const key = `${source}-${target}`;

      if (!connectionsMap[key]) {
        connectionsMap[key] = {
          source,
          target,
          __connectionData__: [transition],
        };
      } else {
        connectionsMap[key].__connectionData__.push(transition);
      }
    });
  });

  return { nodes, connections: Object.values(connectionsMap) };
};
