import React from "react";
import { Redirect, Route } from "react-router-dom";
import classnames from "classnames";
import get from "lodash/get";

import { useDeploymentSettingsStyles } from "./commonStyles";

import InformationTab from "./InformationTab";
import MicroservicesTab from "./MicroservicesTab";
import ManagedEntitiesTab from "./ManagedEntitiesTab";

export const ModalContent = (props) => {
  const classes = useDeploymentSettingsStyles();
  const editPath = "/integration/deployment-settings/:id/edit/";
  const createPath = "/integration/deployment-settings/create/";

  return (
    <>
      <Route
        path={[`${editPath}information`, `${createPath}information`]}
        children={({ match }) => (
          <div
            className={classnames({
              [classes.hiddenTab]: !get(match, "isExact", false),
            })}
          >
            <InformationTab {...props} />
          </div>
        )}
      />
      <Route
        path={[`${editPath}microservices`, `${createPath}microservices`]}
        children={({ match }) => (
          <div
            className={classnames({
              [classes.hiddenTab]: !get(match, "isExact", false),
            })}
          >
            <MicroservicesTab {...props} />
          </div>
        )}
      />
      <Route
        path={[`${editPath}managed-entities`, `${createPath}managed-entities`]}
        children={({ match }) => (
          <div
            className={classnames({
              [classes.hiddenTab]: !get(match, "isExact", false),
            })}
          >
            <ManagedEntitiesTab {...props} />
          </div>
        )}
      />
      <Route
        path={[editPath, createPath]}
        children={({ match }) => {
          return <Redirect to={`${match.url}/information`} />;
        }}
      />
    </>
  );
};
