import { useBoundCallback } from "msa2-ui/src/hooks/useBoundCallbacks";
import { useTranslation } from "react-i18next";

/**
 * Creates a constant reference for translation function.
 *
 * @returns {function(...[*]): never}
 */
export const useBoundedTranslation = (namespace) => {
  const { t } = useTranslation(namespace);
  return useBoundCallback(t);
};
