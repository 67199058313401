import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import reduxForm from "redux-form/lib/reduxForm";
import { useSnackbar } from "notistack";
import flow from "lodash/flow";
import omit from "lodash/omit";
import { getFormValues } from "msa2-ui/src/store/form";
import { getToken } from "msa2-ui/src/store/auth";
import {
  getSelectedSubtenant,
  getManufacturers,
  getManufacturersById,
} from "msa2-ui/src/store/designations";

import { getParentRoute } from "msa2-ui/src/utils/urls";
import Routing from "msa2-ui/src/utils/Routing";
import {
  initBodyDeploymentSetting,
  createDeploymentSetting,
} from "msa2-ui/src/api/deploymentSettings";
import {
  useReturnToDeploymentSettings,
  validateDeploymentSettingsForm,
  modalTabs,
} from "./helpers";

import Close from "@material-ui/icons/Close";
import {
  Dialog,
  Button,
  IconButton,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Divider,
  Tab,
  Tabs,
} from "@material-ui/core";
import { useDeploymentSettingsStyles } from "./commonStyles";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { ModalContent } from "./ModalContent";

const form = "DeploymentSettingsCreate";

const Create = ({
  handleSubmit,
  pristine,
  initialize,
  initialized,
  submitting,
  invalid,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const onClose = useReturnToDeploymentSettings();

  const classes = useDeploymentSettingsStyles();
  const commonClasses = useCommonStyles();

  const token = useSelector(getToken);
  const subtenantId = useSelector(getSelectedSubtenant).id;
  const manufacturers = useSelector(getManufacturers);
  const manufacturersById = useSelector(getManufacturersById);
  const name = useSelector(getFormValues(form, "name"));
  const history = useHistory();

  const submitForm = async ({ subtenantId, ...values }) => {
    const [createError] = await createDeploymentSetting({
      token,
      subtenantId,
      body: omit(values, ["id"]),
    });
    const variant = createError ? "error" : "success";
    const message = createError
      ? createError.getMessage(
          `${t("Unable to create x", { x: t("Deployment Setting") })}: ${
            createError.name
          }`,
        )
      : t("x has been created", { x: t("Deployment Setting") });
    variant === "success" && onClose();
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  useEffect(() => {
    if (!initialized) {
      const currentManufacturer = manufacturers[0];
      initialize({
        ...initBodyDeploymentSetting,
        subtenantId,
        vendor: {
          id: currentManufacturer,
        },
        model: {
          id: manufacturersById[currentManufacturer].models[0],
        },
      });
    }
  }, [initialize, initialized, subtenantId, manufacturers, manufacturersById]);

  return (
    <Dialog
      id="CONFIGURATION_DEPLOYMENT_SETTINGS_EDIT_TITLE"
      open={true}
      onClose={onClose}
      aria-labelledby="modalArea"
      fullWidth={true}
      maxWidth={"md"}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        id="modalArea"
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {name}
        </Typography>
        <IconButton
          id="CONFIGURATION_DEPLOYMENT_SETTINGS_EDIT_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogActions className={classes.tabsWrapper}>
        <Tabs
          value={Routing.getTabIndexByPath(modalTabs, pathname)}
          indicatorColor="primary"
          variant="standard"
          className={classes.tabs}
        >
          {modalTabs.map((tab) => (
            <Tab
              key={tab.id}
              id={`CONFIGURATION_DEPLOYMENT_SETTINGS_EDIT_TAB_${tab.id}`}
              classes={{
                selected: commonClasses.commonTabSelected,
              }}
              component={Link}
              to={`${getParentRoute(pathname)}/${tab.path}`}
              label={tab.displayName}
            />
          ))}
        </Tabs>
      </DialogActions>
      <Divider className={classes.dividerTop} />
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentWrapper}>
          <ModalContent form={form} />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          id="CONFIGURATION_DEPLOYMENT_SETTINGS_DIALOG_ACTIONS_BTN_CANCEL"
          variant="text"
          size="small"
          color="default"
          className={classnames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          disabled={submitting}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="CONFIGURATION_DEPLOYMENT_SETTINGS_DIALOG_ACTIONS_BTN_SAVE"
          variant="contained"
          size="small"
          color="primary"
          className={classnames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          disabled={submitting || pristine}
          onClick={() => {
            if (invalid) {
              history.push(`${getParentRoute(pathname)}/${modalTabs[0].path}`);
            }
            handleSubmit(submitForm)();
          }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Create.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default flow(
  reduxForm({
    form,
    validate: validateDeploymentSettingsForm,
  }),
)(Create);
