import React from "react";
import PropTypes from "prop-types";
import { Table } from "@material-ui/core";
import THead from "./THead";
import TBody from "./TBody";

const OverviewTable = ({
  t,
  columns,
  isError,
  isLoading,
  filterState,
  filterActions,
  permissionProfiles,
  permissionProfilesTotalCount,
  onDeleteProfile,
}) => (
  <Table>
    <THead
      columns={columns}
      filterState={filterState}
      filterActions={filterActions}
      totalCounter={permissionProfilesTotalCount}
    />
    <TBody
      t={t}
      colSpan={columns.length}
      isError={isError}
      isLoading={isLoading}
      permissionProfiles={permissionProfiles}
      onDeleteProfile={onDeleteProfile}
    />
  </Table>
);

OverviewTable.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      align: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  filterState: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  permissionProfiles: PropTypes.arrayOf(PropTypes.object),
  permissionProfilesTotalCount: PropTypes.number.isRequired,
  onDeleteProfile: PropTypes.func.isRequired,
};

export default OverviewTable;
