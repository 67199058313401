import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import flow from "lodash/flow";
import { filter } from "msa2-ui/src/utils/filter";

import extractUbiqubeId from "msa2-ui/src/utils/extractUbiqubeId";
import {
  getSubtenantsByWorkflow,
  detachServices,
} from "msa2-ui/src/api/workflow";
import {
  selectSubtenant,
  getAvailableSubtenants,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import {
  changeTableRowsSetting,
  getTableRowsSetting,
} from "msa2-ui/src/store/settings";
import { sortOrder } from "msa2-ui/src/Constants";

import commonStyles from "msa2-ui/src/styles/commonStyles";
import {
  CircularProgress,
  Dialog as MaterialUiDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import { ReactComponent as IconRemove } from "msa2-ui/src/assets/icons/remove.svg";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import AlertBar from "msa2-ui/src/components/AlertBar";
import AutomationAttachDialog from "./AutomationAttachDialog";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import Dialog from "msa2-ui/src/components/Dialog";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

const localStyles = (theme) => ({
  titleIcon: {
    marginRight: 12,
    marginLeft: 12,
  },
  contentWrapper: {
    padding: "0px 20px",
    height: 512,
  },
  loaderWrapper: {
    textAlign: "center",
  },
  subtenantColumn: {
    width: "40%",
  },
  removeButton: {
    width: 100,
  },
});

// Combine Styles
const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

const tableHeader = [
  {
    id: "name",
    name: "Subtenant Name",
    align: "left",
    width: "40%",
    sortable: false,
  },
  // {
  //   id: "total_instances",
  //   name: "Instances",
  //   align: "left",
  //   width: "50%",
  //   sortable: false,
  // },
  {
    id: "remove",
    name: "",
    align: "right",
    width: "10%",
    sortable: false,
  },
];

class AutomationSubtenantsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertBar: false,
      isLoading: true,
      sortBy: tableHeader[0].id,
      sortingOrder: 0,
      searchString: "",
      page: 0,
      total: 0,
      selected: "",
      deleting: "",
      addToDialog: false,
    };
  }

  // life cycle methods
  componentDidMount() {
    this.getValFromApi();
  }

  // functions for API
  getValFromApi = async () => {
    const { token, rowsPerPage, tenantId, managerId, wfPath } = this.props;
    const { page, sortBy, sortingOrder } = this.state;
    const [apiError, apiResponse] = await getSubtenantsByWorkflow({
      token,
      managerId,
      tenantId,
      workflowPath: wfPath + ".xml",
      page,
      pageSize: rowsPerPage,
      sort: sortBy,
      sortOrder: sortingOrder,
    });

    if (apiError) {
      return this.setState({
        apiError,
        alertBar: true,
        isLoading: false,
      });
    }

    this.setState({
      apiResponse,
      subtenants: apiResponse,
      total: Object.keys(apiResponse).length,
      isLoading: false,
    });
  };

  reload = (addState) => {
    this.handleCloseAlertBar();
    this.setState(
      // merge objects
      Object.assign(
        {
          apiError: undefined,
          isLoading: true,
          apiResponse: undefined,
          subtenants: undefined,
          searchString: "",
        },
        addState,
      ),
      () => {
        this.getValFromApi();
      },
    );
  };

  handleCloseAlertBar = () => {
    this.setState({ alertBar: false });
  };

  // functions for filter
  handleSearchByChange = (filterSearchTerm) => {
    // Will return the list based on search term
    const filteredList = filter(
      this.state.apiResponse,
      filterSearchTerm.toLowerCase(),
      ["name"],
    );
    this.setState({ searchString: filterSearchTerm, subtenants: filteredList });
  };

  handleChangeRowsPerPage = (event) => {
    this.props.changeTableRowsSetting({
      table: "automation",
      numberOfRows: event.target.value,
    });

    this.reload({ rowsPerPage: this.props.rowsPerPage });
  };

  handleChangePage = (event, page) => {
    this.reload({ page });
  };

  // functions for tables
  handleSelectRow = (key) => {
    this.setState({ selected: key });
  };

  handleDeselectRow = () => {
    this.setState({ selected: "" });
  };

  handleSort = (id) => {
    // if the same table header is clicked, switch asc and desc : if different table header is clicked, set desc
    const sortingOrder =
      id === this.state.sortBy ? Number(!this.state.sortingOrder) : 0;
    this.reload({ sortBy: id, sortingOrder: sortingOrder });
  };

  // functions for dialogs
  handleClickSubtenant = (ubiqubeId) => {
    // set ids for the subtenant from subtenant list
    const selectedSubtenant = this.props.subtenants.find(
      (arr) => arr.id.toString() === extractUbiqubeId(ubiqubeId).id,
    );
    this.props.selectSubtenant(selectedSubtenant);
    this.props.history.push(
      `/automation/workflows/${encodeURIComponent(
        this.props.wfPath,
      )}/instances`,
    );
  };

  handleClickAddTo = () => {
    this.setState({ addToDialog: true });
  };

  handleDialogClose = () => {
    this.setState({ addToDialog: false });
    this.getValFromApi();
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ deleting: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ deleting: "" });
  };
  handleRemoveSubtenant = async (ubiqubeId) => {
    const { t, token, wfPath, enqueueSnackbar, closeSnackbar } = this.props;
    const [apiError] = await detachServices({
      token,
      ubiqubeId,
      uri: wfPath + ".xml",
    });

    enqueueSnackbar(
      apiError
        ? t("Unable to detach Subtenants from the workflow.") +
            "(" +
            apiError.name +
            ")"
        : t("Subtenants have been detached."),
      {
        variant: apiError ? "error" : "success",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      },
    );

    if (apiError) {
      return this.setState({
        apiError,
        isLoading: false,
      });
    }

    this.handleCloseDeleteDialog();
    this.getValFromApi();
  };

  render() {
    const {
      classes,
      t,
      open,
      onClose,
      wfPath,
      wfName,
      icon,
      canAttachWorkflow,
    } = this.props;
    const { subtenants } = this.state;
    return (
      <Fragment>
        <MaterialUiDialog
          id="AUTOMATION_SUBTENANT_DIALOG_TITLE"
          open={open}
          onClose={onClose}
          aria-labelledby="modalArea"
          fullWidth={true}
          maxWidth={"md"}
          classes={{
            ...classes,
            paper: classes.commonDialogPaper,
          }}
        >
          <DialogTitle
            id="modalArea"
            className={classes.commonDialogHeader}
            disableTypography
          >
            <div className={classes.commonDialogHeaderIcon}>{icon}</div>
            <Typography
              variant="h4"
              className={classes.commonDialogHeaderTitle}
            >
              {wfName}
            </Typography>
            <IconButton
              id="AUTOMATION_SUBTENANT_DIALOG_BTN_CLOSE"
              onClick={onClose}
              className={classes.commonDialogHeaderCloseButton}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.contentWrapper}>
            <Table className={classes.table}>
              <colgroup>
                {tableHeader.map((header) => (
                  <col key={header.id} style={{ width: header.width }} />
                ))}
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={tableHeader.length}
                    className={classes.commonTableCell}
                  >
                    <FilterMenu
                      handleSearchByChange={this.handleSearchByChange}
                      searchValue={this.state.searchString}
                      id="AUTOMATION_SUBTENANT_DIALOG_BTN_ADDTO"
                      buttonText={canAttachWorkflow && t("Add to...")}
                      buttonOnClick={canAttachWorkflow && this.handleClickAddTo}
                    />
                    {this.state.addToDialog && (
                      <AutomationAttachDialog
                        onClose={this.handleDialogClose}
                        wfPath={wfPath}
                        wfName={wfName}
                        icon={icon}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.commonTableHeadRow}>
                  {tableHeader.map((header) => (
                    <TableCell
                      key={header.id}
                      align={header.align}
                      className={classes.commonTableCellDefault}
                    >
                      {header.name !== "" &&
                        (header.sortable ? (
                          <TableSortLabel
                            id={header.id}
                            active={this.state.sortBy === header.id}
                            direction={sortOrder[this.state.sortingOrder].text}
                            onClick={() => this.handleSort(header.id)}
                          >
                            {t(header.name)}
                          </TableSortLabel>
                        ) : (
                          <TableSortLabel id={header.id}>
                            {t(header.name)}
                          </TableSortLabel>
                        ))}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {this.state.isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={tableHeader.length}
                      className={classes.loaderWrapper}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <Fragment>
                  {/* after loading  */}
                  {this.state.alertBar && (
                    // if api returns an error
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={tableHeader.length}>
                          <AlertBar
                            message={t("Unable to load x", {
                              x: t("Subtenant list"),
                            })}
                            refreshFnc={this.reload}
                            closeFnc={this.handleCloseAlertBar}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {subtenants && (
                    // succeeded case
                    <TableBody>
                      {subtenants.map(({ id, name }) => {
                        return (
                          <TableRow
                            key={id}
                            onMouseLeave={this.handleDeselectRow}
                            onMouseEnter={() => this.handleSelectRow(id)}
                            onClick={() => this.handleSelectRow(id)}
                            className={classes.commonTableRowHighlight}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              className={classes.subtenantColumn}
                            >
                              <Typography
                                variant="h4"
                                className={[
                                  classes.commonTableCellClickable,
                                  classes.commonTablePrimary,
                                ].join(" ")}
                                onClick={() => this.handleClickSubtenant(id)}
                              >
                                {name}
                              </Typography>
                            </TableCell>
                            {/* Uncomment when API is ready
                            <TableCell
                              className={`${classes.commonTableCellDefault} ${classes.instanceColumn}`}
                            >
                              <Typography
                                variant="h3"
                                className={classes.commonTableSecondary}
                              >
                                <NumberFormat
                                  // ToDo: Put number form API When API is ready
                                  value={1}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                {t(" Instances")}
                              </Typography>
                            </TableCell>
                             */}
                            <TableCell
                              align="center"
                              padding="none"
                              className={classes.removeButton}
                            >
                              <DelegationProfiles
                                type={delegationProfileTypes.WORKFLOWS}
                                action="general.attach"
                              >
                                {/* icons are showed up on hover  */}
                                {id === this.state.selected && (
                                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                                  <div
                                    className={classes.commonTextButton}
                                    onClick={() =>
                                      this.handleOpenDeleteDialog(id)
                                    }
                                    role="button"
                                  >
                                    <IconRemove />
                                    <Typography
                                      variant="body1"
                                      className={classes.commonRemove}
                                    >
                                      {t("REMOVE")}
                                    </Typography>
                                  </div>
                                )}
                                {id === this.state.deleting && (
                                  <Dialog
                                    onClose={this.handleCloseDeleteDialog}
                                    onExec={() =>
                                      this.handleRemoveSubtenant(id)
                                    }
                                    title={t("Confirm Request")}
                                    content={t(
                                      "Are you sure you want to remove",
                                      { name },
                                    )}
                                  />
                                )}
                              </DelegationProfiles>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Fragment>
              )}
            </Table>
          </DialogContent>
        </MaterialUiDialog>
      </Fragment>
    );
  }
}
AutomationSubtenantsDialog.propTypes = {
  token: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  wfName: PropTypes.string.isRequired,
  wfPath: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  selectSubtenant: PropTypes.func.isRequired,
  managerId: PropTypes.number.isRequired,
  subtenants: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
  managerId: state.auth.userDetails.id,
  tenantId: getSelectedTenant(state).id,
  subtenants: getAvailableSubtenants(state),
  rowsPerPage: getTableRowsSetting("automation")(state),
  canAttachWorkflow: getDelegationProfile(
    delegationProfileTypes.WORKFLOWS,
    "general.attach",
  )(state),
});

const mapDispatchToProps = {
  changeTableRowsSetting,
  selectSubtenant,
};

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar,
  withTranslation(),
  withRouter,
)(AutomationSubtenantsDialog);
