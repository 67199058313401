import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import Close from "@material-ui/icons/Close";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  changePassword,
  validateChangePasswordLink,
} from "msa2-ui/src/api/auth";
import classNames from "classnames";
import reduxForm from "redux-form/lib/reduxForm";
import { SubmissionError } from "redux-form";
import { reduxFormNames } from "msa2-ui/src/Constants";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import { required } from "msa2-ui/src/utils/validators";
import { useSnackbar } from "notistack";

const ChangePasswordDialog = ({ onClose, handleSubmit: onSubmit }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { hash } = useParams();

  const [isLoading, isError] = useApi(validateChangePasswordLink, {
    hash,
  });

  const handleChangePassword = async ({ password, passwordConfirmation }) => {
    if (password !== passwordConfirmation) {
      throw new SubmissionError({
        passwordConfirmation: t("Passwords do not match"),
      });
    }

    const [error] = await changePassword({ password, hash });

    if (error) {
      return enqueueSnackbar(error.getMessage(t("Unable to change password")), {
        variant: "error",
      });
    }

    enqueueSnackbar(t("Password has been changed"), { variant: "success" });
    onClose();
  };

  const renderDialogContent = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (isError) {
      return (
        <Grid item xs={10}>
          <Typography variant="body1" align="center">
            {t("Unable to change password")}.
          </Typography>
          <Typography variant="body1" align="center">
            {t("Invalid link")}.
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid item container xs={10} alignItems="center" justifyContent="center">
        <FieldSelector
          id="CHANGE_PWD_PASSWORD_FIELD"
          inputProps={{ tabIndex: 1 }}
          name="password"
          type="password"
          validate={[required]}
          label={t("New password")}
        />
        <FieldSelector
          id="CHANGE_PWD_CONFIRMATION_FIELD"
          inputProps={{ tabIndex: 2 }}
          name="passwordConfirmation"
          type="password"
          validate={[required]}
          label={t("Password confirmation")}
        />
      </Grid>
    );
  };

  return (
    <Dialog
      id="CHANGE_PWD_DIALOG"
      data-testid="CHANGE_PWD_DIALOG"
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {t("Change password")}
        </Typography>
        <IconButton
          id="CHANGE_PWD_DIALOG_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={commonClasses.commonDialogContent}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: 150 }}
        >
          {renderDialogContent()}
        </Grid>
      </DialogContent>
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          id="CHANGE_PWD_CANCEL_BTN"
          data-testid="CHANGE_PWD_BTN_CANCEL"
          variant="text"
          size="small"
          color="default"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="CHANGE_PWD_SUBMIT_BTN"
          variant="contained"
          size="small"
          color="primary"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          onClick={onSubmit(handleChangePassword)}
          disabled={isError}
        >
          {t("Change x", { x: t("Password") })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: reduxFormNames.changePasswordForm,
})(ChangePasswordDialog);
