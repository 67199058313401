import React from "react";
import PropTypes from "prop-types";

/**
 * COMPONENTS
 */
import AlertBar from "msa2-ui/src/components/AlertBar";
import DoughnutChart from "msa2-ui/src/components/graphs/Doughnut";
import TotalWorkflowChart from "msa2-ui/src/components/TotalWorkflowChart";

/**
 * MODULES
 */
import { useTranslation } from "react-i18next";

/**
 * MATERIAL UI
 */
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper } from "@material-ui/core";

/**
 * STYLES
 */
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles((theme) => ({
  workflowWrapper: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  totalWorkflow: {
    display: "inline-block",
    borderRight: "1px solid #e7eaee",
    width: 244,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      margin: "0 auto",
      width: "fit-content",
      border: 0,
    },
  },
  filterWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 20,
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "left",
    },
  },
  chartsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 242,
  },
}));

const AutomationGraphs = ({
  workflows,
  workflowsLoading,
  totalWorkflowsReport,
  workflowReports,
  apiError,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const getState = () => {
    if (workflowsLoading) return "loading";
    if (apiError) return "error";
    if (workflows) return "ready";
  };
  return (
    <Paper className={commonClasses.commonPaper}>
      {getState() === "loading" && (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      )}
      {getState() === "error" && (
        <AlertBar message={t("Unable to load x", { x: t("graphs") })} />
      )}
      {getState() === "ready" && (
        <div className={classes.workflowWrapper}>
          {totalWorkflowsReport && (
            <div className={classes.totalWorkflow}>
              <TotalWorkflowChart workflows={totalWorkflowsReport} />
            </div>
          )}
          <div className={classes.chartsWrapper}>
            {workflowReports.length > 0 && (
              <DoughnutChart
                chartData={workflowReports}
                chartDataText={t("Total")}
                size={110}
                maxCharts={5}
                noborder
              />
            )}
          </div>
        </div>
      )}
    </Paper>
  );
};

AutomationGraphs.propTypes = {
  workflows: PropTypes.array,
  totalWorkflowsReport: PropTypes.object,
  workflowsReport: PropTypes.array,
  apiError: PropTypes.object,
};

export default AutomationGraphs;
