import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import flow from "lodash/flow";
import { buildRoute } from "msa2-ui/src/utils/urls";

import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";
import {
  getBpmDiagramsForSubtenant,
  getProcessInstanceStatistics,
  getBpmDetails,
  getExternalTaskLogs,
} from "msa2-ui/src/api/bpm";

import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

import Bpm from "msa2-ui/src/services/Bpm";

import { Grid } from "@material-ui/core";

import AutomationGraphs from "msa2-ui/src/routes/automation/workflows/overview/AutomationGraphs";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import CommonOverviewTable from "./overview-tables/CommonOverviewTable";
import SubtenantOverviewTable from "./overview-tables/SubtenantOverviewTable";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import Repository from "msa2-ui/src/services/Repository";

const mapDiagramsToReports = (diagrams = []) => {
  return diagrams
    .filter((diagram) => diagram.totalInstanceCount > 0)
    .slice(0, 4)
    .map((diagram, index) => {
      return {
        key: index,
        name: diagram.name,
        serviceName: "",
        total: diagram.totalInstanceCount,
        badge: {},
        status: {
          RUNNING: diagram.activeInstanceCount,
          ENDED: diagram.completedInstanceCount,
          FAIL: diagram.failedInstanceCount,
        },
        graph: [
          [
            { name: "Running", value: diagram.activeInstanceCount },
            { name: "Fail", value: diagram.failedInstanceCount },
            { name: "Warning", value: 0 },
            { name: "None", value: 0 },
            { name: "Pause", value: 0 },
            { name: "Ended", value: diagram.completedInstanceCount },
          ],
        ],
      };
    });
};

const getGraphsData = (
  isLoading,
  diagrams,
  bpmDiagramsError,
  reloadGetBpmDiagrams,
) => {
  const reports = diagrams ? mapDiagramsToReports(diagrams) : [];

  const totalCompleted = diagrams.reduce(function(total, diagram) {
    return total + diagram.completedInstanceCount;
  }, 0);

  const totalActive = diagrams.reduce(function(total, diagram) {
    return total + diagram.activeInstanceCount;
  }, 0);

  const totalFailed = diagrams.reduce(function(total, diagram) {
    return total + diagram.failedInstanceCount;
  }, 0);

  const totalCount = totalCompleted + totalActive + totalFailed;

  return {
    workflows: diagrams,
    workflowsCount: totalCount,
    totalWorkflowsReport: {
      ended: totalCompleted,
      running: totalActive,
      fail: totalFailed,
      totalProcessInstances: totalCount,
    },
    workflowReports: reports,
    workflowsLoading: isLoading,
    apiError: bpmDiagramsError,
    reloadWorkflows: reloadGetBpmDiagrams,
  };
};

const BpmOverview = ({ sectionTabs: SectionTabs }) => {
  const { t } = useTranslation();

  const { id: subtenantId, ubiqubeId } = useSelector(getSelectedSubtenant);
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );

  const { path, url } = useRouteMatch();

  const [filterState, filterActions] = useFilter({
    tableName: "bpmOverview",
    searchValue: "",
    tpPage: 0,
    total: 0,
  });

  const [
    bpmDiagramsIsLoading,
    bpmDiagramsError,
    bpmDiagrams = [],
    bpmDiagramsMeta,
    reloadGetBpmDiagrams,
  ] = useApi(getBpmDiagramsForSubtenant, {
    subtenantId,
    page: filterState.tpPage + 1,
    page_size: filterState.tpRowsPerPage,
    isFilteredByOwner: isWorkflowsOwnerDetailsEnabled,
  });

  const [processInstancesLoading, , processInstancesResponse, , ,] = useApi(
    getBpmDetails,
  );

  const [externalTaskLogsLoading, , externalTaskLogsResponse, ,] = useApi(
    getExternalTaskLogs,
  );
  const [statisticsLoading, statisticsError, statistics] = useApi(
    getProcessInstanceStatistics,
  );

  const bpmDiagramsWithGraph =
    statistics && processInstancesResponse && externalTaskLogsResponse
      ? (Array.isArray(bpmDiagrams) ? bpmDiagrams : []).map((bpm) => {
          const [activeRecords, failedRecords, completedRecords] = flow(
            (statistics) =>
              statistics.filter((instance) => {
                const {
                  definition: { key },
                } = instance;
                const regex = new RegExp(
                  `BPM_PROCESS_ID_${ubiqubeId ||
                    ".*"}_${Repository.getFilenameFromUri(bpm.uri)}`,
                );
                return key && key.match(regex);
              }),
            // filter out deleted instances
            (statistics) =>
              Bpm.filterOutDeletedInstances(
                statistics,
                processInstancesResponse,
              ),
            (statistics) => Bpm.getRecords(statistics),
            // a business error (WF Failure) is assumed as completed in camunda, we convert it into failure manually
            (totalRecords) =>
              Bpm.transformBusinessFailure(
                totalRecords,
                externalTaskLogsResponse,
              ),
          )(statistics);

          return {
            ...bpm,
            totalInstanceCount:
              activeRecords.length +
              failedRecords.length +
              completedRecords.length,
            completedInstances: completedRecords,
            completedInstanceCount: completedRecords.length,
            activeInstances: activeRecords,
            activeInstanceCount: activeRecords.length,
            failedInstances: failedRecords,
            failedInstanceCount: failedRecords.length,
          };
        })
      : bpmDiagrams;

  const graphsData = getGraphsData(
    bpmDiagramsIsLoading ||
      statisticsLoading ||
      processInstancesLoading ||
      externalTaskLogsLoading,
    bpmDiagramsWithGraph,
    bpmDiagramsError || statisticsError,
    reloadGetBpmDiagrams,
  );

  return (
    <ErrorBoundary>
      <SectionTabs
        count={[path, bpmDiagramsMeta?.total]}
        endElements={
          <CreateEntityButton
            id="BPM_BTN_CREATE_LINK"
            aria-label={t("Create BPM")}
            link={buildRoute(url, "create")}
            delegationProfileAction="general.create"
            delegationProfileType={delegationProfileTypes.BPM}
          >
            {t("Create BPM")}
          </CreateEntityButton>
        }
      />

      <Grid container spacing={3}>
        {subtenantId && (
          <Grid item xs={12}>
            <AutomationGraphs {...graphsData} />
          </Grid>
        )}
        <Grid item xs={12}>
          {subtenantId ? (
            <SubtenantOverviewTable
              bpmDiagramsIsLoading={bpmDiagramsIsLoading}
              bpmDiagramsError={bpmDiagramsError}
              bpmDiagrams={bpmDiagramsWithGraph}
              reloadGetBpmDiagrams={reloadGetBpmDiagrams}
              filterState={filterState}
              filterActions={filterActions}
              recordsCount={parseInt(bpmDiagramsMeta?.total) || 0}
              isWorkflowsOwnerDetailsEnabled={isWorkflowsOwnerDetailsEnabled}
            />
          ) : (
            <CommonOverviewTable
              bpmDiagramsIsLoading={bpmDiagramsIsLoading}
              bpmDiagramsError={bpmDiagramsError}
              bpmDiagrams={bpmDiagramsWithGraph}
              reloadGetBpmDiagrams={reloadGetBpmDiagrams}
              filterState={filterState}
              filterActions={filterActions}
              recordsCount={parseInt(bpmDiagramsMeta?.total) || 0}
              isWorkflowsOwnerDetailsEnabled={isWorkflowsOwnerDetailsEnabled}
            />
          )}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default BpmOverview;
