import React from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableHead as MaterialTableHead,
  TableRow,
} from "@material-ui/core";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const TableHead = ({ columns, filterState, filterActions, recordsCount }) => {
  const commonClasses = useCommonStyles();

  return (
    <MaterialTableHead>
      <TableRow>
        <TableCell
          colSpan={columns.length}
          className={commonClasses.commonTableCell}
        >
          <FilterMenu
            id="BPM_TABLE_FILTER_MENU"
            tpTotal={parseInt(recordsCount) || 0}
            {...filterState}
            handleSearchByChange={filterActions.handleSearchByChange}
            tpChangePage={filterActions.tpChangePage}
            tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
          />
        </TableCell>
      </TableRow>
      <TableRow className={commonClasses.commonTableHeadRow}>
        {columns
          .filter(({ hidden }) => !hidden)
          .map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              className={commonClasses.commonTableCellDefault}
            >
              {column.name}
            </TableCell>
          ))}
      </TableRow>
    </MaterialTableHead>
  );
};

TableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      align: PropTypes.string.isRequired,
    }),
  ).isRequired,
  filterState: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  recordsCount: PropTypes.number,
};

export default TableHead;
