import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { Chip, makeStyles } from "@material-ui/core";
import Log from "msa2-ui/src/services/Log";
import { useSelector } from "react-redux";
import { getMsaTheme } from "msa2-ui/src/store/designations";
import { displayYearMonthDayHourMinuteSecond } from "msa2-ui/src/utils/date";

const useStyles = makeStyles(({ darkMode, palette }) => ({
  chip: {
    backgroundColor: darkMode ? "#0059a7" : "rgb(38, 189, 111)",
    borderRadius: 16,
    textTransform: "uppercase",
    fontWeight: 500,
    marginRight: 10,
  },
  chipLabel: {
    overflow: "hidden",
    color: darkMode ? palette.common.white : palette.common.black,
    whiteSpace: "nowrap",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 2,
    textOverflow: "ellipsis",
  },
}));

const FilterLabelBadge = ({ filterData, handleDelete }) => {
  const theme = useSelector(getMsaTheme);
  const classes = useStyles();

  const { key, value, childValue } = filterData;
  let chipLabel;

  if (key === "severities") {
    const { label } = Log.SEVERITY_LEVELS(theme)[childValue];
    chipLabel = label;
  } else if (key === "managedEntityIds") {
    chipLabel = "Managed Entity: " + childValue.label;
  } else if (key === "startDate") {
    chipLabel =
      "start: " + displayYearMonthDayHourMinuteSecond(value.getTime());
  } else if (key === "endDate") {
    chipLabel = "end: " + displayYearMonthDayHourMinuteSecond(value.getTime());
  } else {
    chipLabel = key + ": " + value;
  }

  return (
    <Chip
      label={chipLabel}
      id={`filter-label-${chipLabel}`}
      color="secondary"
      classes={{ label: classes.chipLabel }}
      className={classes.chip}
      onDelete={() => handleDelete(filterData)}
    />
  );
};

const LogsFilterLabels = ({
  isTriggeredAlarms,
  filters,
  handleFilterValue,
  handleSearchByChange,
  setAlarmName,
  setLogType,
  setSeverities,
  setManagedEntityIds,
  setAcknowledgement,
  setStartDate,
  setStartDateToES,
  setEndDate,
  setEndDateToES,
}) => {
  const tableName = isTriggeredAlarms ? "alarms" : "logs";

  const setDefaultValue = (key, value) => {
    switch (key) {
      case "searchValue":
        handleSearchByChange(value);
        break;
      case "alarmName":
        setAlarmName(value);
        break;
      case "ack":
        setAcknowledgement(value);
        break;
      case "severities":
        setSeverities(value);
        break;
      case "managedEntityIds":
        setManagedEntityIds(value);
        break;
      case "startDate":
        setStartDate(value);
        setStartDateToES("");
        break;
      case "endDate":
        setEndDate(value);
        setEndDateToES("");
        break;
      case "logType":
        setLogType(value);
        break;
      default:
        return;
    }
  };

  const getDefaultValue = (key) => {
    switch (key) {
      case "searchValue":
      case "alarmName":
      case "ack":
        return "";
      case "severities":
      case "managedEntityIds":
        return [];
      case "startDate":
      case "endDate":
      case "logType":
        return null;
      default:
        return;
    }
  };

  const getArrayDefaultValue = (value, childValue) => {
    return value.filter((v) => ![childValue].includes(v));
  };

  const handleDelete = (data) => {
    const { tableName, key, value, childValue } = data;
    const defaultValue =
      childValue !== undefined
        ? getArrayDefaultValue(value, childValue)
        : getDefaultValue(key);
    setDefaultValue(key, defaultValue);
    handleFilterValue({
      table: tableName,
      key: key,
      value: defaultValue,
    });
  };

  return (
    <>
      {Object.entries(filters)
        .filter(([key]) => !["searchValue"].includes(key))
        .map(([key, value]) => {
          if (value instanceof Array && !isEmpty(value)) {
            return value.map((childValue) => (
              <FilterLabelBadge
                filterData={{
                  tableName: tableName,
                  key: key,
                  value: value,
                  childValue: childValue,
                }}
                handleDelete={handleDelete}
              />
            ));
          } else if (
            !(value instanceof Array) &&
            value != null &&
            value !== ""
          ) {
            return (
              <FilterLabelBadge
                filterData={{
                  tableName: tableName,
                  key: key,
                  value: value,
                }}
                handleDelete={handleDelete}
              />
            );
          }
          return null;
        })}
    </>
  );
};

LogsFilterLabels.propTypes = {
  handleSearchByChange: PropTypes.func,
  setLogType: PropTypes.func,
  setSeverities: PropTypes.func,
  setManagedEntityIds: PropTypes.func,
  handleFilterValue: PropTypes.func,
};

export default LogsFilterLabels;
