import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

import { returnToPreviousPage } from "msa2-ui/src/utils/urls";

import { createState } from "msa2-ui/src/api/ai";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "msa2-ui/src/components/Dialog";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const Create = ({ reloadStates }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [entry, setEntry] = useState({
    name: "",
    initial: false,
    final: false,
    description: "",
  });

  const closeDialog = () => {
    returnToPreviousPage(history, url);
    reloadStates();
  };

  const onSubmit = async () => {
    setError("");

    const { name } = entry;

    if (!name) {
      setError(t("Please fill out the required fields"));
      return;
    }

    setSubmitting(true);
    const [creationError] = await createState({ body: entry });
    setSubmitting(false);

    const variant = error ? "error" : "success";
    const message = error ? creationError.getMessage() : t("State created.");

    if (!error && !creationError) {
      closeDialog();
    }

    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={t("Create State")}
      disabled={submitting}
    >
      <Grid container direction="column">
        <TextField
          type="text"
          id="AI_STATE_CREATE_NAME"
          variant="outlined"
          label={t("Name")}
          value={entry.name}
          required
          onChange={({ target: { value } }) =>
            setEntry({ ...entry, name: value })
          }
          className={classes.formField}
        />

        <TextField
          type={"text"}
          id="AI_STATE_CREATE_DESCRIPTION"
          variant="outlined"
          label={t("Description")}
          value={entry.description}
          multiline
          onChange={({ target: { value } }) =>
            setEntry({ ...entry, description: value })
          }
          className={classes.formField}
        />

        <FormControlLabel
          control={
            <Checkbox
              id={`AI_STATE_CREATE_INITIAL_CHECKBOX`}
              checked={entry.initial}
              onChange={({ target: { checked } }) => {
                setEntry({ ...entry, initial: checked });
              }}
            />
          }
          label={t("Initial")}
        />

        <FormControlLabel
          control={
            <Checkbox
              id={`AI_STATE_CREATE_FINAL_CHECKBOX`}
              checked={entry.final}
              onChange={({ target: { checked } }) => {
                setEntry({ ...entry, final: checked });
              }}
            />
          }
          label={t("Final")}
        />

        {error && (
          <Typography
            color="error"
            className={classes.error}
            id="AI_STATE_CREATE_ERROR"
          >
            {error}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default Create;
