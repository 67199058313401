import React from "react";
import { useSelector } from "react-redux";

import {
  getAvailableSubtenants,
  getManagedEntityList,
  getSelectedManagedEntity,
  getSelectedSubtenant,
} from "msa2-ui/src/store/designations";
import { getUserDetails, getToken } from "msa2-ui/src/store/auth";

import MSAVariable from "msa2-ui/src/components/msa-variable";
import ManagedEntityConfigureTable from "msa2-ui/src/routes/integration/managed-entities/detail/ManagedEntityConfigureTable";

const ExecutionVariableField = (props) => {
  const token = useSelector(getToken);
  const subtenant = useSelector(getSelectedSubtenant);
  const selectedManagedEntity = useSelector(getSelectedManagedEntity);
  const subtenants = useSelector(getAvailableSubtenants);
  const managedEntities = useSelector(getManagedEntityList);
  const userDetails = useSelector(getUserDetails);

  const components = props.components || {};

  return (
    <MSAVariable
      {...props}
      components={{ ...components, ManagedEntityConfigureTable }}
      storeInfo={{
        token,
        subtenant,
        selectedManagedEntity,
        subtenants,
        managedEntities,
        userDetails,
      }}
    />
  );
};

export default ExecutionVariableField;
