export const commonStyles = (theme) => ({
  textButton: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.colors.blueDark1,
  },
  array: {
    marginLeft: 8,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.25,
  },
  folder: {
    marginLeft: 8,
    fontWeight: 300,
    letterSpacing: 0.25,
    color: theme.colors.greyDark1,
  },
  hover: {
    backgroundColor: theme.darkMode
      ? "rgba(255, 255, 255, 0.1)"
      : theme.colors.blueLight2,
    borderRadius: 20,
  },
  icon: {
    minWidth: 16,
  },
  contentWrapper: {
    height: 40,
    marginLeft: 10,
    marginRight: 10,
  },
  content: {
    marginLeft: 4,
    marginRight: 4,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.25,
    overflow: "auto",
    maxHeight: 21,
  },
  textField: {
    fontSize: "0.875rem",
    letterSpacing: 0.25,
    padding: 5,
    backgroundColor: theme.palette.background.paper,
  },
  parserWrapper: {
    width: "100%",
  },
});
