import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";

import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";

import { getIsDeveloper } from "msa2-ui/src/store/auth";

import { getStatesList } from "msa2-ui/src/api/ai";

import { filter } from "msa2-ui/src/utils/filter";

import { buildRoute } from "msa2-ui/src/utils/urls";

import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Button,
} from "@material-ui/core";

import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import AiStatesTableBody from "./AiStatesTableBody";
import CreateState from "msa2-ui/src/routes/ai/states/Create";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";

export const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    active: false,
    primary: true,
  },
  {
    id: "pk",
    name: "Key",
    align: "left",
    active: false,
  },
  {
    id: "tag",
    name: "Tag ID",
    align: "left",
    active: false,
  },
  {
    id: "transition",
    name: "Transition",
    align: "left",
    active: false,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    active: false,
    hidden: true,
  },
];

const AiStates = ({ tabs: Tabs }) => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const isDeveloper = useSelector(getIsDeveloper);
  const commonClasses = useCommonStyles();

  const [searchString, setSearchString] = useState("");

  const [filterState] = useFilter({
    searchString: "",
  });

  const [isLoading, error, response = [], , reload] = useApi(getStatesList);

  const { states = [], count = 0 } = response;
  const statesList = filter(states, searchString, ["name"]);

  const canCreateStates = useSelector(
    getDelegationProfile(delegationProfileTypes.AI, "state.create"),
  );

  return (
    <>
      <Tabs
        currentCount={count}
        endElements={
          <DelegationProfiles
            type={delegationProfileTypes.AI}
            action="state.create"
          >
            <Button
              id="AI_STATE_BTN_CREATE_LINK"
              variant="contained"
              color="primary"
              aria-label={t("Create State")}
              component={Link}
              to={{ pathname: `${url}/create` }}
            >
              <IconPlus className={commonClasses.commonBtnIcon} />
              {t("Create State")}
            </Button>
          </DelegationProfiles>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={TABLE_HEADER_COLUMNS.length}
                    className={commonClasses.commonTableCell}
                  >
                    <FilterMenu
                      id="AI_STATES_TABLE_FILTER_MENU"
                      {...filterState}
                      handleSearchByChange={setSearchString}
                      searchValue={searchString}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => {
                    const { hidden } = tableHeaderColumn;

                    return hidden ? (
                      <TableCell
                        key={tableHeaderColumn.id}
                        align={tableHeaderColumn.align}
                        className={commonClasses.commonTableCellDefault}
                      />
                    ) : (
                      <TableCell
                        key={tableHeaderColumn.id}
                        align={tableHeaderColumn.align}
                        className={commonClasses.commonTableCellDefault}
                      >
                        {tableHeaderColumn.active ? (
                          <TableSortLabel
                            id={`AI_STATES_TABLE_SORT_${tableHeaderColumn.id}`}
                          >
                            {t(tableHeaderColumn.name)}
                          </TableSortLabel>
                        ) : (
                          tableHeaderColumn.name
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <AiStatesTableBody
                loading={isLoading}
                error={error}
                reload={reload}
                states={statesList}
              />
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <AuthorizedRoute
        guard={isDeveloper && canCreateStates}
        redirect={path}
        path={buildRoute(path, "create")}
      >
        <CreateState reloadStates={reload} />
      </AuthorizedRoute>
    </>
  );
};

export default AiStates;
