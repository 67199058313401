import React, { Fragment, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { noop } from "lodash";
import stripAnsi from "strip-ansi";

import {
  displayYearMonthTimeDateAsES,
  formatDateOrString,
} from "msa2-ui/src/utils/date";
import {
  statusTypes,
  WORKFLOW_STATUS,
  workflowStatus,
} from "msa2-ui/src/Constants";
import { instanceTabs } from "./constants";
import Process from "msa2-ui/src/services/Process";
import Variable from "msa2-ui/src/services/Variable";
import { EXTERNAL_REFERENCE_PREFIX } from "msa2-ui/src/services/Bpm";

import useApi from "msa2-ui/src/hooks/useApi";
import {
  getServiceInstanceDetails,
  attemptToStopWorkflow,
} from "msa2-ui/src/api/workflow";
import useWorkflowDrawer from "msa2-ui/src/hooks/useWorkflowDrawer";

import {
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { DeleteOutlined, StopOutlined } from "@material-ui/icons";
import SelectSearch from "msa2-ui/src/components/SelectSearch";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";
import { useSelector } from "react-redux";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import Tooltip from "msa2-ui/src/components/TooltipWithLineBreaks";
import ClipboardIcon from "msa2-ui/src/components/ClipboardIcon";
//import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
//import { deleteServiceId } from "msa2-ui/src/api/workflow";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getToken } from "msa2-ui/src/store/auth";
import { isEmpty } from "lodash";

const useStyles = makeStyles(
  ({ darkMode, palette, colors, typography, spacing }) => ({
    instanceWrapper: {
      padding: spacing(2),
      "&:hover #DRAWER_ACTIONS": {
        display: "block",
      },
      "&:hover #COPY_ICON": {
        display: "inline",
      },
    },
    instanceName: {
      minHeight: 44,
      padding: "4px 0",
      fontWeight: typography.fontWeightMedium,
      marginRight: 15,
    },
    workflowStatusIcon: {
      marginTop: 5,
      marginRight: 10,
    },
    disabledIconButton: {
      opacity: 0.6,
    },
    iconButton: {
      padding: 10,
    },
    instanceDeleteIcon: {
      width: 20,
      height: 20,
    },
    lastProcessRunnerWrapper: {
      margin: "8px 0px",
    },
    taskName: {
      fontWeight: typography.fontWeightMedium,
      letterSpacing: 0.25,
      marginTop: 2,
      marginBottom: 5,
    },
    taskComment: {
      maxWidth: "inherit",
    },
    drawerActions: {
      display: "none",
    },
    runProcessBtn: {
      padding: "2px 12px",
      minWidth: 110,
      fontSize: 13,
      fontWeight: typography.fontWeightMedium,
      backgroundColor: palette.primary.main,
      margin: 4,
    },
    moreActionsWrapper: {
      cursor: "pointer",
      width: "fit-content",
    },
    moreActions: {
      fontSize: 13,
      letterSpacing: 0.3,
      fontWeight: typography.fontWeightMedium,
      color: palette.primary.main,
      cursor: "pointer",
      margin: 4,
    },
    moreActionsDisabled: {
      opacity: 0.6,
      color: palette.text.disabled,
      pointerEvents: "none",
    },
    processTypeIcon: {
      marginRight: 8,
    },
    variableWrapper: {
      marginBottom: 5,
    },
    variableName: {
      lineHeight: "1.5",
      marginRight: 8,
    },
    variablesWrapper: {
      backgroundColor: palette.background.paper,
      padding: "10px",
    },
    paper: {
      overflowY: "inherit",
      overflowX: "inherit",
    },
  }),
);

const WorkflowInstance = (props) => {
  const {
    show,
    workflowData,
    reloadListServiceId,
    shouldShowVariables = true,
    onInstanceLoaded = noop,
    isLazyMode,
  } = props;
  const { t } = useTranslation();
  const token = useSelector(getToken);
  //const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));
  // const [showDeleteDialog, DeleteDialog] = useDialog();
  const [showStopDialog, StopDialog] = useDialog();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();

  const canTriggerUpdateProcess = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "instance.update"),
  );
  const canTriggerDeleteProcess = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "instance.delete"),
  );

  const [shouldShowProcessPicker, setShouldShowProcessPicker] = useState(false);
  const processPickerAnchorRef = useRef(null);

  const [showWorkflowDrawer, WorkflowDrawer] = useWorkflowDrawer();
  const [shouldPollInstance, setShouldPollInstance] = useState(false);

  const serviceId = props.instanceData?.variables.SERVICEINSTANCEID;
  const [loading, error, instanceData = props.instanceData, , reload] = useApi(
    getServiceInstanceDetails,
    { serviceId },
    !serviceId || !isLazyMode,
    shouldPollInstance ? pollingInterval : 0,
  );

  useEffect(() => {
    if (!loading && (instanceData || error)) {
      onInstanceLoaded(serviceId, instanceData || error);
      if (instanceData) {
        const isWorkflowRunning =
          instanceData.status?.status === WORKFLOW_STATUS.RUNNING.status;
        setShouldPollInstance(isWorkflowRunning);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (!show || (isLazyMode && !instanceData.status)) {
    return null;
  }

  const {
    variables: data,
    status: lastExecutedProcess = {},
    taskRunner,
  } = instanceData;
  const id = data?.SERVICEINSTANCEID;
  const displayName = data[workflowData?.information.displayField];
  const processAssociatedWithTrashIcon =
    workflowData?.information.processToAssociateToTrashIcon;
  const trashIconProcessFromWorkflow =
    workflowData &&
    workflowData.process.find(
      (process) => process.displayName === processAssociatedWithTrashIcon,
    );

  const ProcessStatusIcon =
    workflowStatus.find((arr) => arr.status === lastExecutedProcess.status)
      ?.icon ?? workflowStatus[0].icon;
  const lastExecutedProcessName =
    workflowData &&
    Process.getNameFromPath(
      workflowData.process,
      lastExecutedProcess.processName,
    );

  const nonCreateProcesses =
    workflowData &&
    workflowData.process.filter((processObject) => {
      // Filter out when...
      if (
        // Create Process
        Process.isCreate(processObject.type) ||
        // Update Process and user doesn't have a permission to update
        (Process.isUpdate(processObject.type) && !canTriggerUpdateProcess) ||
        // Delete Process and user doesn't have a permission to delete
        (Process.isDelete(processObject.type) && !canTriggerDeleteProcess)
      ) {
        return false;
      }
      return true;
    });

  // const handleDeletion = async () => {
  //   const [error] = await deleteServiceId({ token, ubiqubeId, serviceId: id });
  //   let variant = "success";
  //   let message = t("Instance has been permanently deleted");
  //   if (error) {
  //     variant = "error";
  //     message = error.getMessage(
  //       t("Unable to delete permanently this instance"),
  //     );
  //   }

  //   reloadListServiceId();
  //   enqueueSnackbar(message, { variant });
  // };

  const handleAttemptToStop = async () => {
    const [error] = await attemptToStopWorkflow({
      token,
      process_id: lastExecutedProcess.processInstanceId,
    });
    let variant = "success";
    let message = t("Stop Successful");
    if (error) {
      variant = "error";
      message = error.getMessage(t("Attempt to stop failed"));
    }
    enqueueSnackbar(message, { variant });
  };

  const processExecutionDisabled =
    lastExecutedProcess.status === statusTypes.IN_PROGRESS;

  return (
    <Fragment key={id}>
      <WorkflowDrawer
        workflowDefinition={workflowData}
        onClose={() => {
          reloadListServiceId();
          reload && reload();
        }}
      />
      <Divider variant="fullWidth" />
      <Grid
        container
        className={classnames(
          commonClasses.commonTableRowHighlight,
          classes.instanceWrapper,
        )}
      >
        <Grid item xs={12}>
          <Grid container className={classes.commonTitleWrapper}>
            <Typography variant="h3" className={classes.instanceName}>
              {displayName}
            </Typography>
            <Grid
              item
              className={classnames([
                commonClasses.commonFlexCenter,
                classes.drawerActions,
              ])}
              id="DRAWER_ACTIONS"
            >
              {instanceTabs.map((func, i) => (
                <Tooltip key={i} title={`${func.name}`}>
                  <span>
                    <IconButton
                      className={classes.iconButton}
                      id={`AUTOMATION_DETAILS_BTN_FUNCTION_${id}_${func.id}`}
                      key={func.id}
                      onClick={() =>
                        showWorkflowDrawer({
                          serviceId: id,
                          displayName,
                          tab: i,
                        })
                      }
                    >
                      <func.icon color={"primary"} />
                    </IconButton>
                  </span>
                </Tooltip>
              ))}

              <Tooltip title={t("Stop")}>
                <span>
                  <IconButton
                    disabled={!processExecutionDisabled}
                    className={classnames(classes.iconButton, {
                      [classes.disabledIconButton]: !processExecutionDisabled,
                    })}
                    id={`AUTOMATION_DETAILS_STOP_BTN_FUNCTION_${id}`}
                    onClick={showStopDialog}
                  >
                    <StopOutlined
                      color={!processExecutionDisabled ? "disabled" : "primary"}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <DelegationProfiles
                type={delegationProfileTypes.WORKFLOWS}
                action="instance.delete"
              >
                {!(
                  isEmpty(processAssociatedWithTrashIcon) ||
                  processAssociatedWithTrashIcon === " "
                ) && (
                  <Tooltip title={t("Delete Instance")}>
                    <span>
                      <IconButton
                        disabled={processExecutionDisabled}
                        id={`AUTOMATION_DELETE_INSTANCE_BTN_FUNCTION_${id}`}
                        onClick={
                          // processAssociatedWithTrashIcon === " " ||
                          //   processAssociatedWithTrashIcon === null
                          //   ? showDeleteDialog :
                          () => {
                            showWorkflowDrawer({
                              serviceId: id,
                              displayName,
                              processName: trashIconProcessFromWorkflow.name,
                            });
                          }
                        }
                        className={classnames(classes.iconButton, {
                          [classes.disabledIconButton]: processExecutionDisabled,
                        })}
                      >
                        <DeleteOutlined
                          className={classes.instanceDeleteIcon}
                          color={
                            processExecutionDisabled ? "disabled" : "error"
                          }
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </DelegationProfiles>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={shouldShowVariables ? 6 : 9}
          lg={shouldShowVariables ? 3 : 6}
          container
        >
          <Grid item xs={1} container justifyContent="flex-end">
            <ProcessStatusIcon className={classes.workflowStatusIcon} />
          </Grid>
          <Grid item xs={11}>
            <Tooltip title={lastExecutedProcess.processName}>
              <Typography
                variant="body1"
                className={classnames(
                  classes.overflowEllipsis,
                  classes.taskName,
                )}
              >
                {lastExecutedProcessName}
              </Typography>
            </Tooltip>
            <Tooltip title={stripAnsi(lastExecutedProcess.comment)}>
              <Typography
                variant="h3"
                className={classnames(
                  commonClasses.commonDescription,
                  commonClasses.commonTwoLineWrapper,
                  classes.taskComment,
                )}
              >
                {stripAnsi(lastExecutedProcess.comment)}
              </Typography>
            </Tooltip>
            <ClipboardIcon
              classes={{ tooltip: classes.drawerActions }}
              content={stripAnsi(lastExecutedProcess.comment)}
              item={t("Message")}
            />
            <div className={classes.lastProcessRunnerWrapper}>
              <Typography
                variant="h3"
                className={commonClasses.commonDescription}
              >
                {taskRunner}
                {lastExecutedProcess.processReference?.startsWith(
                  EXTERNAL_REFERENCE_PREFIX,
                ) && t(" (via BPM)")}
              </Typography>
              <span className={commonClasses.commonSpacer}>{"|"}</span>
              <Typography
                variant="h3"
                className={commonClasses.commonDescription}
              >
                {lastExecutedProcess.endingDate
                  ? formatDateOrString(
                      displayYearMonthTimeDateAsES(
                        lastExecutedProcess.endingDate,
                      ) + "+0000",
                      "MMM dd, yyyy h:mm:ssaa",
                    )
                  : "-"}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={shouldShowVariables ? 6 : 3}
          lg={shouldShowVariables ? 3 : 6}
        >
          {/* show 2 processes which is not CREATE type */}
          {nonCreateProcesses.slice(0, 2).map((prc, i) => {
            const { style } = Process.processDefinitions[prc.type];
            return (
              <div key={i}>
                <Button
                  disabled={processExecutionDisabled}
                  id={`AUTOMATION_DETAILS_BTN_RUN_PROCESS_${id}_${i}`}
                  variant="contained"
                  size="large"
                  className={classnames(
                    commonClasses.commonBtnPrimary,
                    classes.runProcessBtn,
                  )}
                  onClick={() => {
                    showWorkflowDrawer({
                      serviceId: id,
                      displayName,
                      processName: prc.name,
                    });
                  }}
                  style={{
                    ...style,
                    backgroundColor: processExecutionDisabled
                      ? ""
                      : style.backgroundColor,
                  }}
                >
                  {prc.displayName}
                </Button>
              </div>
            );
          })}
          {/* if there are more processes, show in a select box after clicking More Actions */}
          {nonCreateProcesses.length > 2 && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
              <div
                id={`AUTOMATION_DETAILS_BTN_MORE_ACTIONS_${id}`}
                className={classnames(
                  commonClasses.commonFlexStart,
                  classes.moreActionsWrapper,
                  {
                    [classes.moreActionsDisabled]: processExecutionDisabled,
                  },
                )}
                onClick={() => setShouldShowProcessPicker(true)}
                role="button"
                ref={processPickerAnchorRef}
              >
                <Typography
                  variant="body1"
                  className={classnames(classes.moreActions, {
                    [classes.moreActionsDisabled]: processExecutionDisabled,
                  })}
                >
                  {t("More Actions")}
                </Typography>
                {shouldShowProcessPicker ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown
                    className={classnames(classes.arrowIcon, {
                      [classes.arrowIconDisabled]: processExecutionDisabled,
                    })}
                  />
                )}
              </div>
              <Popover
                id={`AUTOMATION_DETAILS_PO_MORE_ACTIONS_${id}`}
                open={shouldShowProcessPicker}
                anchorEl={processPickerAnchorRef.current}
                onClose={() => setShouldShowProcessPicker(false)}
                classes={{ paper: classes.paper }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className={commonClasses.commonPopoverContainer}>
                  <SelectSearch
                    id={`AUTOMATION_DETAILS_SS_MORE_ACTIONS_${id}`}
                    options={nonCreateProcesses.map((prc, i) => {
                      const { icon } = Process.processDefinitions[prc.type];
                      return {
                        id: i,
                        label: prc.displayName,
                        name: prc.name,
                        icon,
                        isDisabled: processExecutionDisabled,
                      };
                    })}
                    noOptionText="Process not found."
                    onSelect={(i, obj) =>
                      obj &&
                      showWorkflowDrawer({
                        serviceId: id,
                        displayName,
                        processName: obj.name,
                      })
                    }
                  />
                </div>
              </Popover>
            </>
          )}
        </Grid>
        {shouldShowVariables &&
          [
            [0, 4],
            [4, 8],
          ].map((nl, i) => (
            <Grid
              item
              xs={6}
              lg={3}
              key={i}
              className={classes.variablesWrapper}
            >
              {Variable.filterEditOnly(workflowData.variables.variable, data)
                .filter((e) => !e.name.split(".").includes("0"))
                .slice(nl[0], nl[1])
                .map((variable, i) => {
                  const id = Variable.removePrefix(variable.name);
                  return (
                    <div
                      className={classnames(
                        commonClasses.commonFlexStart,
                        classes.variableWrapper,
                      )}
                      key={id}
                    >
                      <Typography
                        variant="subtitle1"
                        className={classnames(
                          commonClasses.commonDescription,
                          classes.variableName,
                        )}
                      >
                        {variable.displayName}:
                      </Typography>
                      <ExecutionVariableField
                        id={id}
                        variable={variable}
                        data={data}
                      />
                    </div>
                  );
                })}
            </Grid>
          ))}
      </Grid>
      {/* <DeleteDialog
        title={t("Delete x?", { x: t("Instance") })}
        content={t(
          "Are you sure you want to permanently delete  this instance ?",
        )}
        onExec={handleDeletion}
      /> */}
      <StopDialog
        title={t("Stop x?", { x: t("Instance") })}
        content={t("Are you sure you want to stop running this instance ?")}
        onExec={handleAttemptToStop}
      />
    </Fragment>
  );
};

WorkflowInstance.propTypes = {
  workflowData: PropTypes.shape({}).isRequired,
  instanceData: PropTypes.shape({
    variables: PropTypes.object,
    status: PropTypes.object,
    taskRunner: PropTypes.string,
  }),
  reloadListServiceId: PropTypes.func,
};

export default WorkflowInstance;
