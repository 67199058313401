import React, { useCallback, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import get from "lodash/get";

import { useSelector } from "react-redux";
import { getToken, userRoles } from "msa2-ui/src/store/auth";
import { getAvailableTenants } from "msa2-ui/src/store/designations";

import useApi from "msa2-ui/src/hooks/useApi";
import {
  updatePermissionProfile,
  getPermissionProfileById,
  getAvailableActions,
} from "msa2-ui/src/api/permissionProfiles";
import { getManagers } from "msa2-ui/src/api/manager";
import { buildRoute } from "msa2-ui/src/utils/urls";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { useSnackbar } from "notistack";

import { makeStyles, CircularProgress } from "@material-ui/core";

import { Grid } from "@material-ui/core";
import Dialog from "msa2-ui/src/components/Dialog";
import PermissionProfileDialogTabs from "msa2-ui/src/routes/admin/permission-profiles/DialogTabs";
import InformationTab from "msa2-ui/src/routes/admin/permission-profiles/tabs/InformationTab";
import AssociatedManagersTab from "msa2-ui/src/routes/admin/permission-profiles/tabs/AssociatedManagersTab";
import PermissionsTab from "msa2-ui/src/routes/admin/permission-profiles/tabs/PermissionsTab";
import { isEmpty } from "lodash";
const getProfileActions = (profile, actionsData) => {
  const { permissionList } = profile;

  permissionList.forEach((permission) => {
    const {
      right: value,
      categoryName,
      subCategoryName,
      actionName,
    } = permission;

    if (value) {
      if (actionsData?.[categoryName]?.[subCategoryName]?.[actionName]) {
        actionsData[categoryName][subCategoryName][actionName].value = value;
      }
      const subCategoryActions = permissionList.filter(
        (item) =>
          item.categoryName === categoryName &&
          item.subCategoryName === subCategoryName,
      );
      if (
        subCategoryActions.length > 0 &&
        !subCategoryActions.find((action) => action.right === false) &&
        actionsData?.[categoryName]?.[subCategoryName]
      ) {
        actionsData[categoryName][subCategoryName].value = true;
      }
    }
  });

  return actionsData;
};

const useStyles = makeStyles(({ spacing }) => ({
  dialogWindow: {
    height: "calc(100% - 64px)",
  },
  commonNoContentWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    height: "100%",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const Edit = ({ reloadPermissionProfiles }) => {
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { id } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const tenants = useSelector(getAvailableTenants);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [tenant, setTenant] = useState("");
  const [actions, setActions] = useState({});
  const [entry, setEntry] = useState({
    profileName: "",
    comment: "",
    tenantID: "",
    prefix: "",
    creatorReference: "",
    attachedManagers: [],
    permissionList: [],
  });

  const [
    profileLoading,
    profileError,
    profile,
  ] = useApi(getPermissionProfileById, { id, token });

  const [, , actionsData = {}] = useApi(getAvailableActions, { token });

  const { prefix } = entry;

  const [managersLoading, managersError, managers = []] = useApi(
    getManagers,
    {
      sortOrder: "ASC",
      sort: "name",
      filterCriteria: prefix,
      filter: "prefix",
      page: 1,
      pageSize: 0,
    },
    !prefix,
  );

  const filterManagers = (managers) => {
    return managers.filter(
      (manager) => get(manager, "baseRole.id") === userRoles.MANAGER,
    );
  };

  const closeDialog = useCallback(() => {
    history.push("/admin/permissions");
  }, [history]);

  useEffect(() => {
    const categories = Object.keys(actionsData);
    if (profile && categories.length > 0) {
      if (profileError) {
        enqueueSnackbar(profileError.getMessage("Unable to load profile"), {
          variant: "error",
          action: (key) => (
            <SnackbarAction id={key} handleClose={closeSnackbar} />
          ),
        });
        closeDialog();
      } else {
        const tenant = tenants?.find(
          (tenant) => tenant.id === profile.tenantID,
        );
        const profileActions = getProfileActions(profile, actionsData);
        setEntry({ ...profile, prefix: tenant.value });
        setActions(profileActions);
        setTenant(tenant);
      }
    }
  }, [
    profile,
    profileError,
    tenants,
    actionsData,
    enqueueSnackbar,
    closeSnackbar,
    closeDialog,
  ]);

  const onSubmit = async () => {
    setIsSubmitting(true);

    const [error] = await updatePermissionProfile({
      entry,
      actions,
      token,
      id,
    });

    setIsSubmitting(false);

    const message = error
      ? error.getMessage()
      : t("Permission profile updated");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      reloadPermissionProfiles();
      history.push("/admin/permissions");
    }
  };

  const defaultRoute = buildRoute(path, "information");

  return (
    <Dialog
      onExec={onSubmit}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={t("Edit Permission Profile")}
      maxWidth={"md"}
      disabled={Boolean(profileError) || profileLoading || isSubmitting}
      tabs={<PermissionProfileDialogTabs />}
      classes={{ paperScrollPaper: classes.dialogWindow }}
    >
      {profileLoading || isEmpty(profile) ? (
        <div className={classes.commonNoContentWrapper}>
          <CircularProgress aria-label={t("Loading")} />
        </div>
      ) : (
        <Grid container direction="column">
          <Switch>
            <Route
              exact
              path={buildRoute(path, "information")}
              render={() => {
                return (
                  <InformationTab
                    entry={entry}
                    setEntry={setEntry}
                    tenant={tenant}
                    setTenant={setTenant}
                    tenants={tenants}
                    editMode
                  />
                );
              }}
            />
            <Route
              exact
              path={buildRoute(path, "managers")}
              render={() => {
                return (
                  <AssociatedManagersTab
                    tenant={tenant}
                    entry={entry}
                    setEntry={setEntry}
                    managers={filterManagers(managers)}
                    managersLoading={managersLoading}
                    managersError={managersError}
                  />
                );
              }}
            />
            <Route
              exact
              path={buildRoute(path, "permissions")}
              render={() => {
                return (
                  <PermissionsTab
                    actions={actions}
                    setActions={setActions}
                    permissionsList={profile?.permissionList.filter(
                      (permission) => permission.subCategoryName === "labels",
                    )}
                  />
                );
              }}
            />
            <Redirect to={defaultRoute} />
          </Switch>
        </Grid>
      )}
    </Dialog>
  );
};

export default Edit;
