import { post } from "./request";
import { userRoles } from "msa2-ui/src/store/auth";
import { entityTypes, topologyWFPath } from "msa2-ui/src/Constants";

const API = process.env.REACT_APP_API_PATH;

const searchFields = [
  "customer_id",
  "device_id",
  "device_name",
  "default",
  "device_ubi_id",
  "asset",
  "hostname",
  "external_reference",
  "asset_model",
  "asset_cpu",
  "asset_firmware",
  "asset_memory",
  "asset_serial",
  "asset_attributes",
  "asset_keys",
  "crud_value",
  "conf_var",
  "name",
  "type",
  "labels",
  "vendor",
  "model",
];

/**
 * Builds a query string that will be used with the "q" parameter of the API call.
 * Wildcards are added to both ends of the search string in order to find partial matches in
 * elastic search documents, without the user having to specifically type them
 * @param searchString entered by the user in the searchbar
 * @param customerIdString created based on user role and selected tenant/subtenant
 * @returns {string} query string formed from search field names and the search string
 */
const buildElasticSearchQuery = (searchString, customerIdString) => {
  const nonNumberQuery = Number.isNaN(Number(searchString));
  const searchStringFiltered = searchString.replace(/"/g, "").trim();
  const wildSearchString = "*" + searchStringFiltered + "*";
  const query = searchFields
    .map((field, i) => {
      if (
        nonNumberQuery &&
        (field === "customer_id" || field === "device_id")
      ) {
        return "";
      }
      if (i === searchFields.length - 1) {
        return `${field}:${wildSearchString}`;
      }
      return `${field}:${wildSearchString} OR `;
    })
    .join("");
  return customerIdString ? `(${customerIdString}) AND (${query})` : query;
};

const temporaryFilterByTenantAndSubtenant = (
  results,
  tenants,
  subtenantList,
) => {
  const hits = results.documents;

  //TODO Remove filters results to not show results for clients that don't exist
  const filteredDocuments = hits.filter((esDocument) => {
    const foundSubtenant = subtenantList.find(
      (subtenant) =>
        subtenant.id === parseInt(esDocument?._source?.customer_id),
    );
    if (foundSubtenant) {
      //TODO remove: artificial addition of tenant into search results until added into the back end ES index
      const selectedOp = tenants.find(
        (tenant) => tenant.value === foundSubtenant.operatorPrefix,
      );
      if (selectedOp) {
        esDocument._source.tenant = selectedOp.label;
        return true;
      }
    }
    return false;
  });

  return {
    ...results,
    documents: filteredDocuments,
  };
};

/**
 * Hack to remove Topology WF from the list.
 * See MSA-8844 for more details.
 */
const maybeWithTopologyWF = (isDeveloper) => (response) => {
  if (isDeveloper) {
    return response;
  }

  let hitCount = response.hitCount || 0;
  let documents = response?.documents || [];

  documents = documents.filter(({ _source }) => {
    const { path, type } = _source;

    if (type === entityTypes.workflows.id && path === topologyWFPath) {
      hitCount--;
      return false;
    }

    return true;
  });

  return { ...response, documents, hitCount };
};

const buildCustomerIdString = (
  userRole,
  tenants,
  subtenantList,
  selectedTenant,
  selectedSubtenant,
) => {
  const { id: selectedTenantId } = selectedTenant;
  const { id: selectedSubtenantId } = selectedSubtenant;

  if (selectedSubtenantId) {
    return `customer_id:${selectedSubtenantId}`;
  } else if (selectedTenantId) {
    return subtenantList.length > 0
      ? subtenantList
          .map((subtenant, i) => {
            const { id } = subtenant;
            if (i === subtenantList.length - 1) {
              return `customer_id:${id}`;
            }
            return `customer_id:${id} OR `;
          })
          .join("")
      : null;
  } else {
    return userRole === userRoles.PRIVILEGED_ADMINISTRATOR
      ? `customer_id:*`
      : "";
  }
};

/*
 * Spec: https://10.31.1.52/swagger/#/ElasticSearchManager/simpleURISearch
 */
export const simpleSearch = async ({
  isDeveloper = false,
  token,
  index = "customer-data",
  type = "_doc",
  searchString,
  tenants,
  subtenantList,
  selectedTenant,
  selectedSubtenant,
  userRole,
  transforms = [
    (results) =>
      temporaryFilterByTenantAndSubtenant(results, tenants, subtenantList),
  ],
}) => {
  const customerIdString = buildCustomerIdString(
    userRole,
    tenants,
    subtenantList,
    selectedTenant,
    selectedSubtenant,
  );
  const [error, response] = await post({
    url: `${API}/elastic-search/v1/uri-search`,
    queryParams: {
      index,
      type,
      q: buildElasticSearchQuery(searchString, customerIdString),
    },
    token,
    transforms: [...transforms, maybeWithTopologyWF(isDeveloper)],
  });

  if (error) {
    return ["An error has occurred while searching. Try again", response];
  }
  return [error, response];
};
