import Validation from "msa2-ui/src/services/Validation";
import { get } from "lodash";

const requiredFields = ["repoType", "url", "username", "password"];

const fields = {
  repoType: "repoType",
  repoName: "repoName",
  url: "url",
  username: "username",
  password: "password",
  storeToken: "storeToken",
  repo: "repo",
};

const repositoryTypes = [
  {
    id: "workflow",
    value: "WF",
    label: "Workflow",
    directory: "/opt/fmc_repository/Process/workflows",
  },
  {
    id: "microservice",
    value: "MS",
    label: "Microservice",
    directory: "/opt/fmc_repository/CommandDefinition/microservices",
  },
  {
    id: "bpm",
    value: "BPM",
    label: "BPM",
    directory: "/opt/fmc_repository/Bpmn/bpmns",
  },
  {
    id: "ccla_app_repo",
    value: "CCLA_APP",
    label: "CCLA APP",
    directory: "/opt/fmc_repository/Datafiles/ccla_app_repo",
  },
];

const repositoryStatuses = {
  ok: "OK",
  error: "ERROR",
};

export const getDirectoryValue = (type) => {
  const repo = repositoryTypes.find((repository) => repository.value === type);
  return get(repo, "directory", "");
};

const validate = (values) =>
  Validation.requiredFields({
    values,
    requiredFields,
  });

const ImportExportFormName = "SettingsImportExport";

export default {
  getDirectoryValue,
  validate,
  ImportExportFormName,
  fields,
  repositoryTypes,
  repositoryStatuses,
};
