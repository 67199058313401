import produce from "immer";
import { maybeUpdateDraftWithPaths } from "msa2-ui/src/utils/immer";

import { ReactComponent as IconCreate } from "msa2-ui/src/assets/icons/process/processCreate.svg";
import { ReactComponent as IconUpdate } from "msa2-ui/src/assets/icons/process/processUpdate.svg";
import { ReactComponent as IconRead } from "msa2-ui/src/assets/icons/process/processRead.svg";
import { ReactComponent as IconDelete } from "msa2-ui/src/assets/icons/process/processDelete.svg";

import { workflowStatus } from "msa2-ui/src/Constants";
const DIRECTORY_PREFIX = "Process_";
const processUriPath = "Process/workflows";

const processDefinitions = {
  CREATE: {
    id: "create",
    type: "CREATE",
    label: "Create",
    canAdd: true,
    icon: IconCreate,
  },
  SET: {
    id: "set",
    type: "SET",
    label: "Set",
    style: { backgroundColor: "#1363AA", color: "#FFFFFF", fontWeight: 400 },
    icon: IconUpdate,
  },
  UPDATE: {
    id: "update",
    type: "UPDATE",
    label: "Update",
    canAdd: true,
    style: { backgroundColor: "#1363AA", color: "#FFFFFF", fontWeight: 400 },
    icon: IconUpdate,
  },
  READ: {
    id: "read",
    type: "READ",
    label: "Read",
    style: { backgroundColor: "#4397D6", color: "#000000" },
    icon: IconRead,
  },
  DELETE: {
    id: "delete",
    type: "DELETE",
    label: "Delete",
    canAdd: true,
    style: { backgroundColor: "#E94F5D", color: "#000000" },
    icon: IconDelete,
  },
  COMPENSATION: {
    id: "compensation",
    type: "COMPENSATION",
    label: "Compensation",
    style: { backgroundColor: "#1363AA", color: "#FFFFFF", fontWeight: 400 },
    icon: IconUpdate,
  },
};

const defaultObject = {
  name: null,
  displayName: null,
  type: null,
  visibility: 5,
  tasks: [],
};

/**
 * Gets icon for process
 * @param {string} type process type. ("CREATE", "UPDATE" or etc)
 * @return {object} icon element
 */
const getIcon = (processType) =>
  processDefinitions[processType]?.icon ?? IconUpdate;

/**
 * Returns true if the process type is SET/CREATE
 * @param {string} type process type. ("CREATE", "UPDATE" or etc)
 * @return {boolean} describes if it is create type of process or not
 */
const isCreate = (processType) => {
  const targetProcess = processDefinitions[processType];
  if (!targetProcess) return false;
  return ["create", "set"].includes(targetProcess.id);
};

/**
 * Returns true if the process type is UPDATE
 * @param {string} type process type. ("CREATE", "UPDATE" or etc)
 * @return {boolean} describes if it is delete type of process or not
 */
const isUpdate = (processType) => {
  const targetProcess = processDefinitions[processType];
  if (!targetProcess) return false;
  return targetProcess.id === processDefinitions.UPDATE.id;
};

/**
 * Returns true if the process type is DELETE
 * @param {string} type process type. ("CREATE", "UPDATE" or etc)
 * @return {boolean} describes if it is delete type of process or not
 */
const isDelete = (processType) => {
  const targetProcess = processDefinitions[processType];
  if (!targetProcess) return false;
  return targetProcess.id === "delete";
};

/**
 * Returns a process definition corresponding process path.
 * @param {object} processes process definition object returned by API.
 * @param {string} processPath path for workflow processes to execute.
 * @return {object} A process definition object for the passed process path.
 */
const getDefinition = (processes, processPath) =>
  processes.find((processDefinition) => processDefinition.name === processPath);

/**
 * Returns a display name for processes corresponding process path.
 * @param {object} processes process definition object returned by API.
 * @param {string} processPath path for workflow processes to execute.
 * @return {string} displayName for passed process path. If the process is already removed
 */
const getNameFromPath = (processes, processPath) =>
  getDefinition(processes, processPath)?.displayName ?? processPath;

/**
 * Returns true if the process definition is gone.
 * There is a case where processes are removed after running.
 * For example, if you have a workflow with processes of CREATE_WF and UPDATE_WF.
 * After you ran these 2 processes to INSTANCE_A, then you can delete the definition of UPDATE_WF.
 * You can see executed CREATE_WF and UPDATE_WF on INSTANCE_A as history, but only CREATE_WF exists as a definition and UPDATE_WF is gone.
 * @param {object} processes process definition object returned by API.
 * @param {string} processPath path for workflow processes to execute.
 * @return {string} describes if the process is already deleted or not
 */
const instanceIsOrphaned = (processes, processInstance) =>
  Boolean(!getDefinition(processes, processInstance));

const setDefaultsOnExistingObject = (defaultObject, existingObject) =>
  produce(defaultObject, (defaultObjectDraft) => {
    maybeUpdateDraftWithPaths(
      defaultObjectDraft,
      existingObject,
      Object.keys(defaultObject),
    );
  });

const make = (existingObject) => {
  if (!existingObject) {
    return defaultObject;
  }
  return setDefaultsOnExistingObject(defaultObject, existingObject);
};

const getWorkflowStatusProperties = (_status) =>
  workflowStatus.find((status) => status.status === _status);

const getProcessByLabel = (label, processes = []) =>
  processes.find(({ displayName }) => displayName === label);

export default {
  make,
  DIRECTORY_PREFIX,
  processUriPath,
  getIcon,
  isCreate,
  isUpdate,
  isDelete,
  getDefinition,
  getNameFromPath,
  instanceIsOrphaned,
  processDefinitions,
  getWorkflowStatusProperties,
  getProcessByLabel,
};
