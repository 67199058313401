import React from "react";
import { Dialog } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const IncidentTicketView = (incidentTicketsData) => {
  const commonClasses = useCommonStyles();
  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      //onClose={close}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    ></Dialog>
  );
};

export default IncidentTicketView;
