import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import get from "lodash/get";
import { userRoles, getUserRole } from "msa2-ui/src/store/auth";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import {
  getModelsById,
  getManufacturersById,
} from "msa2-ui/src/store/designations";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Utility from "../Functions";

import MonitoringGraphs from "./monitoring-graphs";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(
  ({ palette, breakpoints, colors, typography }) => ({
    configSectionContent: {
      borderLeft: "1px solid " + colors.blueLight4,
      "&:first-child": {
        borderLeft: 0,
      },
      [breakpoints.down("md")]: {
        borderLeft: 0,
        borderBottom: "1px solid " + colors.blueLight4,
      },
      paddingBottom: 10,
      marginBottom: 20,
    },
    tableCell: {
      border: "1px none " + colors.blueLight4,
    },
    lightText: {
      color: palette.text.secondary,
      fontSize: "0.8125rem",
      fontWeight: typography.fontWeightRegular,
      letterSpacing: "0.3px",
    },
    primaryText: {
      color: palette.text.primary,
      fontSize: "0.8125rem",
      fontWeight: typography.fontWeightRegular,
    },
    uppercaseHeadingText: {
      color: palette.text.primary,
      fontSize: "0.8125rem",
      fontWeight: typography.fontWeightMedium,
      textTransform: "uppercase",
      paddingBottom: "1em",
    },
    select: {
      width: "100%",
    },
  }),
);

const ManagedEntityOverview = ({ managedEntity }) => {
  const { t } = useTranslation();
  const modelsById = useSelector(getModelsById);
  const manufacturersById = useSelector(getManufacturersById);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const userRole = useSelector(getUserRole);
  const isManager = userRole === userRoles.MANAGER;
  const isUiHaDisplayEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.haDisplay,
  );

  const manufacturer = get(
    manufacturersById,
    `${managedEntity.manId}.manufacturerName`,
    "",
  );
  const model = get(modelsById, `${managedEntity.modId}.modelName`, "");

  return (
    <Grid item xs={12}>
      <Paper
        id={`MANAGED_ENTITY_OVERVIEW_${managedEntity.deviceId.id}`}
        className={commonClasses.commonPaper}
      >
        {!(isUiHaDisplayEnabled && isManager) && (
          <Grid>
            <Typography className={classes.uppercaseHeadingText}>
              {t("Information")}
            </Typography>
          </Grid>
        )}
        {!(isUiHaDisplayEnabled && isManager) && (
          <Grid container>
            <Grid item md={12} lg={4} className={classes.configSectionContent}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("Serial Number")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(managedEntity.serialNb)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText} variant="body2">
                        {t("Vendor")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {manufacturer}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText} variant="body2">
                        {t("Model")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {model}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText} variant="body2">
                        {t("Memory")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.memory)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("CPU")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.cpu)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("Certificate Expiration Date")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.certExpire)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            {/* Section 2 */}
            <Grid item md={12} lg={4} className={classes.configSectionContent}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("Firmware")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.firmware)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("License")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.licence)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("IPS Version")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.ipsVersion)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("IPS Expiration Date")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.ipsDate)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("External Reference")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(
                          managedEntity.deviceId.externalReference,
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText} variant="body2">
                        {t("Description")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(managedEntity.description)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            {/* Section 3 */}
            <Grid item md={12} lg={4} className={classes.configSectionContent}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("External Network")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        variant="body2"
                        className={classes.primaryText}
                      >
                        {Utility.stringCheck(managedEntity.network)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText} variant="body2">
                        {t("Configuration")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.primaryText}>
                        {Utility.stringCheck(managedEntity.config)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("Interface Name")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(
                          managedEntity.managementInterfaceName,
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("IP Address")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(managedEntity.ipConfig)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText} variant="body2">
                        {t("Mask")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(managedEntity.mask)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText}>
                        {t("DHCP Server")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(managedEntity.dhcp)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography className={classes.lightText} variant="body2">
                        {t("Bandwidth Port")}:
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.primaryText}
                        variant="body2"
                      >
                        {Utility.stringCheck(managedEntity.port)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
        <DelegationProfiles
          type={delegationProfileTypes.MANAGED_ENTITIES}
          action="monitoring.view"
        >
          <MonitoringGraphs
            deviceId={managedEntity.deviceId.id}
            deviceUbiId={managedEntity.deviceId.ubiId}
            externalReference={managedEntity.deviceId.externalReference}
          />
        </DelegationProfiles>
      </Paper>
    </Grid>
  );
};

ManagedEntityOverview.propTypes = {
  managedEntity: PropTypes.object.isRequired,
};
export default ManagedEntityOverview;
