// A generic function to map redux form props to Material UI props
// Some components may require a custom mapping function
export default ({
  meta: { touched, error, warning } = {},
  input,
  ...props
}) => {
  const variantProps = {
    variant: "outlined",
  };

  if (touched && (error || warning)) {
    return {
      ...variantProps,
      ...props,
      ...input,
      helperText: error || warning,
      error: Boolean(error),
    };
  }
  return { ...variantProps, ...input, ...props };
};
