import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IconButton, Button, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { downloadArchive } from "msa2-ui/src/api/repository";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import Repository from "msa2-ui/src/services/Repository";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";

import Toolbar from "msa2-ui/src/components/modal/Toolbar";

const useLocalStyles = makeStyles(({ palette, typography, colors }) => ({
  publish: {
    backgroundColor: colors.green,
    color: colors.white,
    marginRight: 10,
  },
  title: {
    marginRight: 15,
  },
  toolbarButton: {
    marginRight: 10,
  },
  fixedToolbar: {
    backgroundColor: palette.background.paper,
    position: "fixed",
  },
  date: {
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
    marginLeft: 5,
  },
  dateLabel: {
    fontWeight: typography.fontWeightLight,
    color: palette.primary.main,
  },
}));

const ModalTitleBar = ({
  onClose,
  onSave,
  onDiscard,
  onPublish,
  disabled,
  title,
  date,
  microServiceUri,
  closeButtonLabel,
  saveButtonLabel,
  discardButtonLabel,
  publishButtonLabel,
}) => {
  const localClasses = useLocalStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector(getToken);
  return (
    <Toolbar className={localClasses.fixedToolbar}>
      <Toolbar.Section>{title || ""}</Toolbar.Section>

      <Toolbar.Section>
        {date && (
          <>
            <Typography className={localClasses.dateLabel}>
              Last saved:{" "}
            </Typography>
            <Typography className={localClasses.date}> {date}</Typography>
            <Toolbar.Divider />
          </>
        )}
        <Button
          id="ME_TOOLBAR_DISCARD_BTN"
          variant="text"
          size="medium"
          color="primary"
          className={localClasses.toolbarButton}
          onClick={onDiscard}
          disabled={disabled}
        >
          {discardButtonLabel}
        </Button>
        <Button
          id="ME_TOOLBAR_SAVE_BTN"
          variant="contained"
          size="medium"
          color="primary"
          className={localClasses.toolbarButton}
          onClick={onSave}
          disabled={disabled}
        >
          {saveButtonLabel}
        </Button>
        {onPublish && (
          <Button
            id="ME_TOOLBAR_PUBLISH_BTN"
            variant="contained"
            size="medium"
            className={localClasses.publish}
            onClick={onPublish}
            disabled={disabled}
          >
            {publishButtonLabel}
          </Button>
        )}
        {microServiceUri && (
          <Tooltip title={t("Download Microservice")}>
            <IconButton
              id={`ME_TOOLBAR_DOWNLOAD_BTN_BTN_${microServiceUri}`}
              aria-label={t("Download Microservice")}
              onClick={() => {
                downloadArchive({
                  path: microServiceUri,
                  token,
                  fileName:
                    Repository.stripFolderPathAndFileExtensionFromUri(
                      microServiceUri,
                      ".xml",
                    ) + ".zip",
                  onError: (e) => {
                    enqueueSnackbar(t("Unable to download contents"), {
                      variant: "error",
                    });
                  },
                });
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
        <Toolbar.Divider />
        <IconButton
          id="ME_TOOLBAR_CLOSE_BTN"
          aria-label={closeButtonLabel}
          variant="contained"
          size="medium"
          onClick={onClose}
          disabled={disabled}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar.Section>
    </Toolbar>
  );
};

ModalTitleBar.propTypes = {
  title: PropTypes.object,
  date: PropTypes.string,
  saveButtonLabel: PropTypes.string.isRequired,
  discardButtonLabel: PropTypes.string.isRequired,
  closeButtonLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
  onPublish: PropTypes.func,
};

export default ModalTitleBar;
