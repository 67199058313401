import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { updateMsaVars } from "msa2-ui/src/api/msaVars";
import { getIsRootUser, getToken } from "msa2-ui/src/store/auth";
import PasswordInput from "msa2-ui/src/components/PasswordInput";

import { makeStyles } from "@material-ui/core";
import { Grid, TextField, Typography } from "@material-ui/core";

import EditorAce from "msa2-ui/src/components/EditorAce";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Dialog from "msa2-ui/src/components/Dialog";
import ClipboardIcon from "msa2-ui/src/components/ClipboardIcon";

const useStyles = makeStyles(({ spacing, palette }) => ({
  dialog: {
    width: 650,
  },
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
  warningMessage: {
    color: palette.warning.main,
    textAlign: "center",
    marginTop: 15,
  },
  commandWrapper: {
    position: "relative",
  },
  copyToClipboard: {
    position: "absolute",
    top: 3,
    right: 10,
  },
}));

const EditMSAVar = ({
  name,
  comment: _comment,
  value: _value,
  help,
  regex,
  type,
  mandatory,
  defaultValue,
  description,
  onClose,
  actionToPerform,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const token = useSelector(getToken);
  const isRootUser = useSelector(getIsRootUser);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState(_value ?? defaultValue ?? "");
  const [comment, setComment] = useState(_comment ?? "");

  const onSubmit = async () => {
    setSubmitting(true);
    const [error] = await updateMsaVars({
      isRootUser,
      token,
      vars: [{ name, value, comment }],
    });
    setSubmitting(false);

    const variant = error ? "error" : "success";
    const message = error ? error.getMessage() : t("Variable saved.");
    if (!error) {
      onClose();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };
  const Component = type === "Password" ? PasswordInput : TextField;

  return (
    <Dialog
      maxWidth="md"
      classes={{ paperFullWidth: classes.dialog }}
      onExec={onSubmit}
      onClose={onClose}
      execLabel={t("Save")}
      title={description}
      disabled={Boolean(error || submitting)}
    >
      <Grid container direction="column">
        <Component
          type={type === "Integer" ? "number" : "text"}
          id="SETTINGS_MSA_VAR_EDIT_VALUE"
          variant="outlined"
          label={t("Value")}
          value={value}
          onChange={({ target: { value } }) => {
            setValue(value);

            const re = new RegExp(regex);
            const found = value.match(re);
            if (!found) {
              setError(t("Invalid value"));
            } else {
              setError();
            }
          }}
          className={classes.formField}
          error={Boolean(error)}
          helperText={error || help}
          required={mandatory}
        />
        <TextField
          type="text"
          id="SETTINGS_MSA_VAR_EDIT_COMMENT"
          variant="outlined"
          label={t("Comment")}
          value={comment}
          onChange={({ target: { value } }) => {
            setComment(value);
          }}
          className={classes.formField}
        />
        {actionToPerform && (
          <>
            <Typography variant="body1" className={classes.warningMessage}>
              {t("Action needed to reflect this setting")}:
            </Typography>
            <div className={classes.commandWrapper}>
              <EditorAce readOnly value={actionToPerform} height={40} />
              <ClipboardIcon
                classes={{ icon: classes.copyToClipboard }}
                content={actionToPerform}
                item={t("Command")}
              />
            </div>
          </>
        )}
      </Grid>
    </Dialog>
  );
};

EditMSAVar.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  comment: PropTypes.string,
  value: PropTypes.any,
  help: PropTypes.string,
  regex: PropTypes.string,
  type: PropTypes.string,
  mandatory: PropTypes.bool,
  defaultValue: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default EditMSAVar;
