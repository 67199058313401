import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getFormValues } from "msa2-ui/src/store/form";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { Button, Divider, Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import WorkflowForm from "msa2-ui/src/services/WorkflowForm";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";
import Process from "msa2-ui/src/services/Process";

export const useStyles = makeStyles(({ topbar }) => ({
  divider: {
    marginBottom: 10,
  },
  sidebarWrapper: {
    maxHeight: `calc(100% - ${topbar.height / 2}px)`,
    overflowY: "auto",
  },
  processesHeader: {
    marginBottom: 10,
  },
  processCount: {
    marginLeft: 6,
    marginTop: 5,
  },
  addProcessIcon: {
    marginTop: -5,
  },
}));

const ModalSidebar = ({
  activeTab,
  change,
  handleOnClick,
  sections,
  processes,
  processPath,
  setShowAddProcessDialog,
  form,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const processCount = processes ? processes.length : 0;

  const variables =
    useSelector(getFormValues(form, WorkflowForm.formConfig.variablePath)) ??
    [];

  const handleChangePosition = (sourceIndex, targetIndex) => {
    const values = processes.reduce((acc, current, currentIndex) => {
      if (currentIndex === sourceIndex) {
        return acc;
      }

      if (currentIndex === targetIndex) {
        if (sourceIndex < targetIndex) {
          return [...acc, current, processes[sourceIndex]];
        } else {
          return [...acc, processes[sourceIndex], current];
        }
      }
      return [...acc, current];
    }, []);
    change(processPath, values);
  };

  return (
    <Grid item xs={3} md={2} className={classes.sidebarWrapper}>
      <Sidebar>
        {Object.values(sections).map((section, i) => {
          const isVariablesTab = section === "Variables";

          return (
            <Sidebar.ListItem
              key={i}
              id={`WORKFLOWS_${section.toUpperCase()}_SIDEBAR_TAB`}
              title={t(section)}
              selected={activeTab === section}
              section={section}
              onClick={() => {
                handleOnClick(section);
              }}
              count={isVariablesTab ? variables.length : ""}
            />
          );
        })}
        <Divider className={classes.divider} />
        {setShowAddProcessDialog && (
          <Grid
            container
            justifyContent="space-between"
            className={classes.processesHeader}
          >
            <Typography
              variant="body1"
              className={classnames(
                commonClasses.commonDescription,
                classes.processCount,
              )}
            >
              {t("Processes")}: {processCount}
            </Typography>
            <Tooltip title={t("Add Process")}>
              <Button
                aria-label={t("Add Process")}
                className={classes.addProcessIcon}
                onClick={() => {
                  setShowAddProcessDialog(true);
                }}
                id="WORKFLOWS_PROCESS_ADD"
              >
                <AddIcon color="primary" />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {processes &&
          processes.map((workflowProcess, i) => {
            const { displayName, type } = workflowProcess;
            return (
              <Sidebar.ListItemDraggable
                key={displayName}
                index={i}
                type="WORKFLOW_PROCESSES"
                onDragged={handleChangePosition}
                id={`WORKFLOWS_${displayName}_SIDEBAR_TAB`}
                icon={Process.getIcon(type)}
                title={displayName}
                selected={activeTab === displayName}
                section={displayName}
                onClick={() => handleOnClick(displayName)}
              />
            );
          })}
      </Sidebar>
    </Grid>
  );
};

ModalSidebar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  sections: PropTypes.object.isRequired,
  processes: PropTypes.array,
  processPath: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
};

export default ModalSidebar;
