const removePrefix = (managedEntityId) => managedEntityId.substring(3);

const removeGivenPrefix = (managedEntityId, prefix) =>
  managedEntityId.substring(prefix.length);

const buildUbiId = (operatorPrefix, device_id) => {
  if (!operatorPrefix || !device_id) {
    return;
  }
  return operatorPrefix + device_id;
};

export default { removePrefix, removeGivenPrefix, buildUbiId };
