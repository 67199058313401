import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { is, getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import useApi from "msa2-ui/src/hooks/useApi";
import { getHistoricActivityInstance } from "msa2-ui/src/api/bpm";

import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

import TaskRuntimeDialog from "./TaskRuntimeDialog";
import ContinueBreakpointDialog from "./ContinueBreakpointDialog";
import ContinueVariableEditDialog from "./ContinueVariableEditDialog";
import { useSelector } from "react-redux";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";

const RuntimeDialog = ({
  bpmModeler,
  processInstanceId,
  activityInstances,
  isFinished,
}) => {
  const { activeElement } = bpmModeler.modelerState;
  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));

  const [autoRefresh, setAutoRefresh] = useState(
    isFinished ? null : pollingInterval,
  );
  const canContinue = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "instance.continue"),
  );
  const canEditVariable = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "instance.editVariable"),
  );

  const getActivityInfo = (activityInstances, isFinished) => {
    if (isFinished) {
      return { isActive: false, willExecute: false };
    }
    if (!activityInstances) {
      return {};
    }
    const activityInstance = activityInstances.find(
      ({ activityId }) => activityId === activeElement.id,
    );
    const isActive = Boolean(activityInstance);
    const executionId = activityInstance?.executionIds[0];
    const willExecute = activityInstances.some(({ activityId }) =>
      bpmModeler.modelerActions.isConnectingToCurrentElement(activityId),
    );
    return { isActive, willExecute, executionId };
  };
  const { isActive, willExecute, executionId } = getActivityInfo(
    activityInstances,
    isFinished,
  );

  const [, , { isExecuted } = {}] = useApi(
    getHistoricActivityInstance,
    {
      processInstanceId,
      transforms: (response) => {
        const isExecuted = response.some(
          ({ activityId }) => activityId === activeElement.id,
        );
        return { isExecuted };
      },
    },
    !processInstanceId,
    autoRefresh,
  );
  const loading = [isExecuted, isActive, willExecute].some(
    (status) => status === undefined,
  );

  useEffect(() => {
    // need to pass false explicitly because undefined shouldn't go here
    if (willExecute === false && isActive === false) {
      // If the selected element is finished its execution, stop polling
      // as the status won't change anymore
      setAutoRefresh(null);
    }
  }, [isActive, willExecute]);

  const activeElementStatus = { isExecuted, isActive, willExecute };
  switch (activeElement?.type) {
    case "bpmn:IntermediateCatchEvent": {
      const businessObject = getBusinessObject(activeElement);
      const eventDefinition = businessObject.eventDefinitions[0];
      if (is(eventDefinition, "bpmn:SignalEventDefinition") && canContinue) {
        return (
          <ContinueBreakpointDialog
            bpmModeler={bpmModeler}
            activeElementStatus={activeElementStatus}
            executionId={executionId}
          />
        );
      }
      if (
        is(eventDefinition, "bpmn:MessageEventDefinition") &&
        canEditVariable
      ) {
        return (
          <ContinueVariableEditDialog
            bpmModeler={bpmModeler}
            activeElementStatus={activeElementStatus}
            isFinished={isFinished}
            processInstanceId={processInstanceId}
          />
        );
      }
      // unmount self
      bpmModeler.modelerActions.unselectElement();
      return null;
    }
    case "bpmn:Task":
    case "bpmn:ServiceTask": {
      if (canEditVariable) {
        return (
          <TaskRuntimeDialog
            bpmModeler={bpmModeler}
            processInstanceId={processInstanceId}
            activeElementStatus={activeElementStatus}
            isFinished={isFinished}
            statusLoading={loading}
          />
        );
      }
      // unmount self
      bpmModeler.modelerActions.unselectElement();
      return null;
    }
    default: {
      // unmount self
      bpmModeler.modelerActions.unselectElement();
      return null;
    }
  }
};

RuntimeDialog.propTypes = {
  bpmModeler: PropTypes.shape({
    modelerState: PropTypes.object.isRequired,
    modelerActions: PropTypes.object.isRequired,
    moddle: PropTypes.object.isRequired,
  }),
  processInstanceId: PropTypes.string,
};

export default RuntimeDialog;
