import { useReducer, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import isNil from "lodash/isNil";
import {
  getColumnSetting,
  getTableRowsSetting,
  changeTableRowsSetting,
  changeTableSortKeySetting,
  changeTableSortOrderSetting,
  addColumn,
  removeColumn,
  replaceColumns,
  SETTINGS,
} from "msa2-ui/src/store/settings";

const defaultState = {
  viewAsValue: null,
  groupByValue: null,
  searchValue: null,
  sortByValue: null,
  sortOrderValue: null,
  tpPage: null,
  tpRowsPerPage: null,
  viewByValue: null,
  filterByValue: null,
  viewValue: null,
  flag1: false,
};

export default (_initialState = defaultState) => {
  const tableName = _initialState.tableName;
  const tpRowsPerPage = useSelector(getTableRowsSetting(tableName));
  const tpRowsPerPageOptions = SETTINGS.tableRows[tableName]?.options;
  const columns = useSelector(getColumnSetting(tableName));
  const initialState = {
    tpRowsPerPage,
    tpRowsPerPageOptions,
    columns,
    ..._initialState,
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "reset":
        return initialState;
      case "sortOrderValue":
        return {
          ...state,
          sortOrderValue: isNil(action.value)
            ? Number(!state.sortOrderValue)
            : action.value,
        };
      default:
        return {
          ...state,
          [action.type]: action.value,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchGlobal = useDispatch();

  const actions = useMemo(
    () => ({
      handleViewAsChange(event, value) {
        dispatch({
          type: "viewAsValue",
          value,
        });
      },
      handleViewByChange(event, value) {
        dispatch({
          type: "viewByValue",
          value,
        });
      },
      handleFilterByChange({ target: { value } }) {
        dispatch({
          type: "filterByValue",
          value,
        });
      },
      handleSortByOrderChange(e) {
        dispatch({
          type: "sortOrderValue",
          value: e?.target?.value ?? e,
        });
      },
      toggleSortOrder(e, sortOrder) {
        const { tableName } = state;
        dispatch({
          type: "sortOrderValue",
          value: sortOrder,
        });
        if (tableName) {
          dispatchGlobal(
            changeTableSortOrderSetting({
              table: tableName,
              sortOrder: Number(sortOrder),
            }),
          );
        }
      },
      handleSortByValueChange(e) {
        const { tableName } = state;
        dispatch({
          type: "sortByValue",
          value: e.target?.value ?? e,
        });
        if (tableName) {
          dispatchGlobal(
            changeTableSortKeySetting({
              table: tableName,
              sortKey: e.target?.value ?? e,
            }),
          );
        }
      },
      handleGroupByChange(event, value) {
        dispatch({
          type: "groupByValue",
          value,
        });
      },
      handleViewChange(event, value) {
        dispatch({
          type: "viewValue",
          value,
        });
      },
      tpChangePage(event, value) {
        dispatch({
          type: "tpPage",
          value,
        });
      },
      tpChangeRowsPerPage({ target: { value } }) {
        const { tpPage, tpRowsPerPage, tableName } = state;
        dispatch({
          type: "tpPage",
          value: Math.floor((tpPage * tpRowsPerPage) / value),
        });
        dispatch({
          type: "tpRowsPerPage",
          value,
        });
        if (tableName) {
          dispatchGlobal(
            changeTableRowsSetting({
              table: tableName,
              numberOfRows: value,
            }),
          );
        }
        return {
          type: "tpRowsPerPage",
          value,
        };
      },
      handleAddColumn(value) {
        const { columns, tableName } = state;
        dispatch({
          type: "columns",
          value: columns.concat(value),
        });
        dispatchGlobal(
          addColumn({
            table: tableName,
            columnName: value,
          }),
        );
      },
      handleRemoveColumn(value) {
        const { columns, tableName } = state;
        dispatch({
          type: "columns",
          value: columns.filter((column) => column !== value),
        });
        dispatchGlobal(
          removeColumn({
            table: tableName,
            columnName: value,
          }),
        );
      },
      handleReplaceColumn(value) {
        const { tableName } = state;
        dispatch({
          type: "columns",
          value,
        });
        dispatchGlobal(
          replaceColumns({
            table: tableName,
            columns: value,
          }),
        );
      },
      handleSearchByChange(value) {
        dispatch({
          type: "tpPage",
          value: 0,
        });
        dispatch({
          type: "searchValue",
          value,
        });
        // resets page in case users are on later than second page
        dispatch({
          type: "tpPage",
          value: 0,
        });
      },
      resetSearchValue() {
        dispatch({
          type: "searchValue",
          value: "",
        });
      },
      toggleFlag1(value) {
        dispatch({
          type: "flag1",
          value,
        });
      },
      debouncedHandleSearchByChange: debounce((value) => {
        dispatch({
          type: "searchValue",
          value,
        });
        dispatch({
          type: "tpPage",
          value: 0,
        });
      }, 400),
    }),
    [dispatchGlobal, state],
  );

  const reset = useCallback(() => {
    dispatch({ type: "reset" });
  }, [dispatch]);
  return [state, actions, reset];
};
