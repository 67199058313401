import IconInfo from "msa2-ui/src/assets/icons/info";
import IconHistory from "msa2-ui/src/assets/icons/history";
import Calendar from "msa2-ui/src/assets/icons/calendar";

import AutomationDetailsTabDetails from "msa2-ui/src/routes/automation/workflows/detail/AutomationDetailsTabDetails";
import AutomationDetailsTabHistory from "msa2-ui/src/routes/automation/workflows/detail/AutomationDetailsTabHistory";
import AutomationDetailsTabScheduled from "msa2-ui/src/routes/automation/workflows/detail/AutomationDetailsTabScheduled";

export const instanceTabs = [
  {
    id: "detail",
    name: "Details",
    icon: IconInfo,
    component: AutomationDetailsTabDetails,
  },
  {
    id: "history",
    name: "History",
    icon: IconHistory,
    component: AutomationDetailsTabHistory,
  },
  {
    id: "scheduled",
    name: "Scheduled",
    icon: Calendar,
    component: AutomationDetailsTabScheduled,
  },
];
