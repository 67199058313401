import React from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import usePopover from "msa2-ui/src/hooks/usePopover";
import get from "lodash/get";
import concat from "lodash/concat";
import flow from "lodash/flow";
import union from "lodash/union";
import { change } from "redux-form";
import { getFormValues } from "msa2-ui/src/store/form";

import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plus.svg";

import Parser, { CONFIGURATION_TYPES } from "msa2-ui/src/services/Parser";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";

import ParserShowHide from "./ParserShowHide";
import ParserArrayExtractor from "./ParserArrayExtractor";
import VariableExtractors from "./VariableExtractors";

const useStyles = makeStyles((theme) => ({
  treeArea: {
    backgroundColor: theme.palette.background.default,
    padding: 13,
    margin: "8px 0",
  },
  textButton: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.colors.blueDark1,
  },
  addPopoverList: {
    marginLeft: 10,
  },
}));

const ConfigurationParser = ({ form, change }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { t } = useTranslation();

  const [isPoppedover, showAddPopover, AddPopover] = usePopover();

  const formObject = useSelector(getFormValues(form));
  const parserFormPath = MicroserviceForm.getParserPath(formObject?.command);
  const parser = get(formObject, parserFormPath);
  const configType = get(formObject, MicroserviceForm.fields.configType);

  const identifierObjectType = Parser.getParserDefinitionByKey(
    configType === "cli" ? "regexp" : "xpath",
    configType,
  );

  const identifierExtractorPath = [parserFormPath, "section"].join(".");
  const identifierExtractor =
    useSelector(getFormValues(form, identifierExtractorPath)) ?? [];

  const variableExtractorPath = [parserFormPath, "lines", "line"].join(".");
  const variableExtractor =
    useSelector(getFormValues(form, variableExtractorPath)) ?? [];

  const ignoreLinesPath = [parserFormPath, "lines", "ignore"].join(".");
  const ignoreLines = useSelector(getFormValues(form, ignoreLinesPath)) ?? [];

  return (
    <Grid container>
      {!parser ? (
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Typography
            variant="body1"
            className={commonClasses.commonDescription}
          >
            {t("Filter the imported information with regexp.")}
          </Typography>
          <Grid item xs={12} container className={classes.treeArea}>
            <ParserShowHide
              label={t(CONFIGURATION_TYPES[configType]?.rootParserLabel)}
              extraButtons={
                <Button
                  id={"ID_EXTRACTOR_ADD_BTN"}
                  onClick={() =>
                    change(
                      form,
                      [
                        identifierExtractorPath,
                        0,
                        identifierObjectType.id,
                      ].join("."),
                      concat(
                        ...identifierExtractor[0][identifierObjectType.id],
                        identifierObjectType.defaultValue,
                      ),
                    )
                  }
                >
                  <IconPlus />
                  <Typography variant={"body1"} className={classes.textButton}>
                    {t("Add")}
                  </Typography>
                </Button>
              }
            >
              <ParserArrayExtractor
                value={identifierExtractor}
                objectType={identifierObjectType}
                onChange={(value) => {
                  change(form, identifierExtractorPath, value);
                }}
              />
            </ParserShowHide>
          </Grid>

          <Grid item xs={12} container className={classes.treeArea}>
            <ParserShowHide
              label={t("Microservice variables extractor")}
              extraButtons={
                <Button
                  id={"VARIABLE_EXTRACTOR_ADD_BTN"}
                  onClick={showAddPopover}
                >
                  <IconPlus />
                  <Typography variant={"body1"} className={classes.textButton}>
                    {t("Add")}
                  </Typography>
                  {isPoppedover ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Button>
              }
            >
              <AddPopover
                id={"VARIABLE_EXTRACTOR_ADD_SELECT"}
                // render options which can be added as children for Microservice variables extractor
                options={flow(
                  // add "array" as only top node for "line" with xml and netconf can have "array" as children
                  (parsers) => union(parsers, ["array"]),
                  // map children and render options
                  (parsers) =>
                    parsers.map((key) => {
                      const { name, ...rest } = Parser.getParserDefinitionByKey(
                        key,
                        configType,
                      );
                      return {
                        label: (
                          <Typography className={classes.addPopoverList}>
                            {t("Add an extractor.", { name })}
                          </Typography>
                        ),
                        ...rest,
                      };
                    }),
                )(Parser.getParserDefinitionByKey("line", configType).children)}
                onSelect={({ id, defaultValue, sibling }) => {
                  change(
                    form,
                    variableExtractorPath,
                    concat(variableExtractor, {
                      [id]: defaultValue,
                      ...sibling,
                    }),
                  );
                }}
                notFoundMessage={t("No extractor to add more")}
                isClearable={false}
              />
              <VariableExtractors
                form={form}
                variableExtractor={variableExtractor}
                variableExtractorPath={variableExtractorPath}
              />
            </ParserShowHide>
          </Grid>
          {configType === "cli" && (
            <Grid item xs={12} container className={classes.treeArea}>
              <ParserShowHide
                label={t("Ignore Lines Parsers")}
                extraButtons={
                  <Button
                    id={"CONFIGURATION_PARSER_ADD_BTN"}
                    onClick={(event) =>
                      change(form, ignoreLinesPath, [
                        ...ignoreLines,
                        { regexp: identifierObjectType.defaultValue },
                      ])
                    }
                  >
                    <IconPlus />
                    <Typography
                      variant={"body1"}
                      className={classes.textButton}
                    >
                      {t("Add")}
                    </Typography>
                  </Button>
                }
              >
                <ParserArrayExtractor
                  value={ignoreLines}
                  objectType={identifierObjectType}
                  onChange={(value) => change(form, ignoreLinesPath, value)}
                />
              </ParserShowHide>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

ConfigurationParser.propTypes = {
  form: PropTypes.string.isRequired,
};

export default connect(null, { change })(ConfigurationParser);
