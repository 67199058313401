import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Close from "@material-ui/icons/Close";
import useFilter from "msa2-ui/src/hooks/useFilter";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import { useTranslation } from "react-i18next";
import { sortOrder } from "msa2-ui/src/Constants";
import TableLoading from "msa2-ui/src/components/TableLoading";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  getMonitoringProfileById,
  updateMonitoringProfileById,
} from "msa2-ui/src/api/monitoringProfiles";
import AttachDialog from "msa2-ui/src/routes/integration/monitoring-profiles/attach-dialog/AttachDialog";
import { getManagedEntitiesBySubtenant } from "msa2-ui/src/api/managedEntity";
import MSATableRow from "msa2-ui/src/components/TableRow";
import get from "lodash/get";
import without from "lodash/without";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { ReactComponent as IconRemove } from "msa2-ui/src/assets/icons/remove.svg";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const useStyles = makeStyles(() => ({
  tableRow: {
    height: 0,
  },
  contentWrapper: {
    padding: "0px 20px",
    height: 512,
  },
}));

const TABLE_COLUMNS = [
  {
    id: "managedEntityName",
    name: "Managed Entity Name",
    align: "left",
    isSortable: true,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    isSortable: false,
  },
];
const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const AttachedManagedEntitiesDialog = ({
  profileName,
  profileId,
  subtenantId,
  onClose,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector(getToken);
  const canModify = useSelector(
    getDelegationProfile(
      delegationProfileTypes.MONITORING_PROFILES,
      "general.attach",
    ),
  );
  const history = useHistory();
  const [showDetachDialog, DetachDialog, managedEntityToDetach] = useDialog();

  const [isAttachDialogShown, setIsAttachDialogShown] = useState(false);

  const [filterState, filterActions] = useFilter({
    sortByValue: TABLE_COLUMNS[0].id,
    sortOrderValue: 0,
    searchValue: "",
  });

  const [
    isLoadingFetchedManagedEntities,
    ,
    fetchedManagedEntities = [],
  ] = useApi(getManagedEntitiesBySubtenant, {
    pageSize: 10000,
    subtenantId,
    filterByLabel: isPermissionProfileLabelsEnabled,
  });

  const [
    isMonitorinProfileLoading,
    ,
    fetchedProfileData,
    ,
    reloadMonitoringProfile,
  ] = useApi(getMonitoringProfileById, {
    profileId,
  });
  const { attachedManagedEntities = [] } = fetchedProfileData || {};

  const isLoading =
    isLoadingFetchedManagedEntities || isMonitorinProfileLoading;

  const managedEntitiesToRender = useMemo(() => {
    return fetchedManagedEntities
      .filter((entity) =>
        attachedManagedEntities.includes(get(entity, "deviceId.ubiId")),
      )
      .filter((entity) =>
        entity.name
          .toLowerCase()
          .includes(filterState.searchValue.toLowerCase()),
      )
      .sort((a, b) => {
        const [aName, bName] = [a.name.toLowerCase(), b.name.toLowerCase()];
        const [left, right] =
          filterState.sortOrderValue === 0 ? [aName, bName] : [bName, aName];
        if (left > right) return 1;
        if (left < right) return -1;
        return 0;
      });
  }, [
    filterState.searchValue,
    filterState.sortOrderValue,

    attachedManagedEntities,
    fetchedManagedEntities,
  ]);

  const handleManagedEntityDetaching = async (managedEntity) => {
    const attachedIds = without(
      attachedManagedEntities,
      get(managedEntity, "deviceId.ubiId"),
    );
    const [error] = await updateMonitoringProfileById({
      data: {
        ...fetchedProfileData,
        attachedManagedEntities: attachedIds,
      },
      profileId: profileId,
      subtenantId,
      token,
    });

    const variant = error ? "error" : "success";
    const message = error
      ? t("Unable to delete x", { x: t("Managed Entity") })
      : t("x has been detached", { x: t("Managed Entity") });

    enqueueSnackbar(message, { variant });

    if (!error) {
      reloadMonitoringProfile();
    }
  };

  const handleManagedEntityClick = (managedEntity) => {
    history.push(
      `/integration/managed-entities/${managedEntity.deviceID}/overview`,
    );
  };
  const handleAddToClick = () => {
    setIsAttachDialogShown(true);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <TableLoading numberOfTableColumns={TABLE_COLUMNS.length} />;
    }

    return (
      <TableBody>
        {managedEntitiesToRender.map((managedEntity) => {
          const {
            deviceId: { ubiId },
            name,
          } = managedEntity;
          return (
            <MSATableRow
              key={ubiId}
              id={`ATTACHED_ME_POPUP_ROW_${ubiId}`}
              classes={{ row: classes.tableRow }}
              actions={
                <DelegationProfiles
                  type={delegationProfileTypes.MONITORING_PROFILES}
                  action="general.attach"
                >
                  <Button
                    id="ATTACHED_ME_POPUP_BTN_REMOVE"
                    onClick={() => showDetachDialog(true, managedEntity)}
                  >
                    <IconRemove />
                    <Typography
                      variant="body1"
                      className={commonClasses.commonRemove}
                    >
                      {t("REMOVE")}
                    </Typography>
                  </Button>
                </DelegationProfiles>
              }
            >
              <TableCell>
                <Typography
                  variant="h4"
                  className={classNames(
                    commonClasses.commonTableCellClickable,
                    commonClasses.commonTablePrimary,
                  )}
                  onClick={() => handleManagedEntityClick(managedEntity)}
                >
                  {name}
                </Typography>
              </TableCell>
            </MSATableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      classes={{ paper: commonClasses.commonDialogPaper }}
      onClose={onClose}
    >
      <DetachDialog
        title={t("Confirm Request")}
        content={`${t("Are you sure you want to remove", {
          name: managedEntityToDetach.name,
        })}`}
        onExec={() => handleManagedEntityDetaching(managedEntityToDetach)}
      />
      {isAttachDialogShown && (
        <AttachDialog
          profileId={profileId}
          profileName={profileName}
          subtenantId={subtenantId}
          onClose={() => {
            setIsAttachDialogShown(false);
            reloadMonitoringProfile();
          }}
        />
      )}
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {profileName}
        </Typography>
        <IconButton
          id="ATTACHED_ME_POPUP_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={TABLE_COLUMNS.length}
                className={commonClasses.commonTableHeadRow}
              >
                <FilterMenu
                  {...filterState}
                  id="ATTACHED_MANAGED_ENTITIES_POPUP_FILTER_MENU"
                  buttonText={canModify && t("Add to...")}
                  buttonOnClick={canModify && handleAddToClick}
                  handleSearchByChange={filterActions.handleSearchByChange}
                />
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_COLUMNS.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  <TableSortLabel
                    id={column.id}
                    {...(column.isSortable && {
                      active: filterState.sortByValue === column.id,
                      direction: sortOrder[filterState.sortOrderValue].text,
                      onClick: () => {
                        filterActions.handleSortByOrderChange();
                        filterActions.handleSortByValueChange(column.id);
                      },
                    })}
                  >
                    {column.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {renderTableBody()}
        </Table>
      </DialogContent>
    </Dialog>
  );
};

AttachedManagedEntitiesDialog.propTypes = {
  profileId: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  subtenantId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AttachedManagedEntitiesDialog;
