/**
 * @example
 * This converts
 *   'JSON(var_name).prop("prop1").prop("prop2").value()'
 * into
 *    { "JSON": [ "var_name" ], "prop": [ "prop1", "prop2" ], "value": [ "" ] }
 *
 * @param {string} str
 * @returns {object}
 */

export const parseChainFunctions = (str) =>
  str.match(/(\w+\("?\w*"?\))/g)?.reduce((acc, cur) => {
    const regexp = /(\w+)\("?(\w*)"?\)?/;
    const [, key, value] = cur.match(regexp) ?? [];
    let newValue;

    switch (typeof acc[key]) {
      case "function":
      case "undefined": {
        newValue = [value];
        break;
      }
      default:
        newValue = acc[key].concat(value);
    }
    return { ...acc, [key]: newValue };
  }, {}) ?? {};
