import AttachmentDialog from "msa2-ui/src/components/attachment-dialog";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import useApi from "msa2-ui/src/hooks/useApi";

import union from "lodash/union";
import difference from "lodash/difference";

import { addTransitionsToState, getActionsList } from "msa2-ui/src/api/ai";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const AiStatesActionsAttachDialog = ({
  stateId,
  stateTransitions,
  onClose,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [attachedActions, setAttachedActions] = useState(stateTransitions);
  const [isSaving, setIsSaving] = useState(false);

  const [isLoading, , response = {}] = useApi(getActionsList);

  const handleAttach = useCallback(
    (ids) => setAttachedActions(union(attachedActions, ids)),
    [attachedActions],
  );

  const handleDetach = useCallback(
    (ids) => setAttachedActions(difference(attachedActions, ids)),
    [attachedActions],
  );

  const handleClose = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    const [attachApiError] = await addTransitionsToState({
      body: {
        state_id: stateId,
        transitions: attachedActions,
      },
    });

    const variant = attachApiError ? "error" : "success";
    const message = attachApiError
      ? attachApiError.getMessage(t("Unable to attach x", { x: t("Actions") }))
      : t("Actions have been attached.");

    variant === "success" ? onClose(true) : setIsSaving(false);

    enqueueSnackbar(message, { variant });
  }, [attachedActions, enqueueSnackbar, onClose, stateId, t]);

  return (
    <AttachmentDialog
      allItems={response?.actions || []}
      attachedItemIds={attachedActions}
      idKey="pk"
      displayNameKey="name"
      attachedTitle={t("Attached x", { x: t("Actions") }) + ": "}
      popupTitle={t("Attach x", { x: t("Action") })}
      noDataMessage={t("There are no x.", { x: t("Actions") })}
      searchPlaceholder={t("Search for x", { x: t("Actions") })}
      unattachedTitle={t("Select x to attach", {
        x: t("Actions"),
      })}
      isLoading={isLoading || isSaving}
      onAttach={handleAttach}
      onDetach={handleDetach}
      onSave={handleSave}
      onClose={handleClose}
    />
  );
};

AiStatesActionsAttachDialog.propTypes = {
  stateId: PropTypes.number.isRequired,
  stateTransitions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AiStatesActionsAttachDialog;
