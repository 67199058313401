import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

import {
  makeStyles,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import useApi from "msa2-ui/src/utils/useApi";
import Repository from "msa2-ui/src/services/Repository";
import {
  getMSReferenceByObmfRefData,
  getMSReferenceByObjectRefData,
} from "msa2-ui/src/api/variables";
import { getDeploymentSetting } from "msa2-ui/src/api/deploymentSettings";
import { getManagedEntityData } from "msa2-ui/src/api/managedEntity";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import useDialog from "msa2-ui/src/hooks/useDialog";
import MsaSelect from "msa2-ui/src/components/msa-select";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const useStyles = makeStyles(() => ({
  menuLabel: {
    paddingLeft: 0,
  },
  consoleButton: {
    marginLeft: "10px",
  },
}));

const MicroserviceReferenceSelect = ({
  objectReference = [],
  value,
  showConsole = false,
  components = {},
  showUniqueValuesInDropdown = false,
  selectedOptions = [],
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { msName, deviceId, token } = props;
  const { ManagedEntityConfigureTable } = components;

  const [showParserResultDialog, ParserResultDialog] = useDialog();

  const [, , managedEntity] = useApi({
    apiCall: getManagedEntityData,
    params: {
      managedEntityId: deviceId,
    },
    wait: !showConsole || !deviceId,
    token,
  });

  const deploymentSettingId = managedEntity?.configProfileId;
  const [, , deploymentSetting] = useApi({
    apiCall: getDeploymentSetting,
    params: {
      deploymentSettingId,
      filterByLabel: isPermissionProfileLabelsEnabled,
    },
    wait: !showConsole || !deploymentSettingId,
    token,
  });

  const microserviceUri =
    deploymentSetting &&
    Object.values(deploymentSetting.microserviceUris).find(
      (fullPath) =>
        Repository.stripFolderPathAndFileExtensionFromUri(fullPath) === msName,
    );

  const options = [
    {
      // Empty value selection
      label: "",
      value: "",
    },
    ...objectReference.map((referenceObject = {}) => {
      const { msName, values = [] } = referenceObject;
      const filteredValues = !showUniqueValuesInDropdown
        ? values
        : values.filter(
            ({ objectId }) =>
              objectId === value || !selectedOptions.includes(objectId),
          );

      return {
        label: msName,
        options: filteredValues.map((valueObject = {}) => {
          const { objectId, displayValue } = valueObject;
          return {
            value: objectId,
            label: displayValue,
            ...valueObject,
          };
        }),
        ...referenceObject,
      };
    }),
  ];

  return (
    <>
      <MsaSelect
        variant={"outlined"}
        displayEmpty
        fullWidth
        options={options}
        value={{
          label: value,
          value: value,
        }}
        noOptionsMessage={() => t("No Microservice Object found.")}
        {...props}
      />
      {showConsole && ManagedEntityConfigureTable && (
        <>
          <Button
            id="MICROSERVICE_REFERENCE_EXAMPLE"
            variant="contained"
            size="small"
            color="primary"
            onClick={() => showParserResultDialog(true)}
            className={classes.consoleButton}
          >
            {t("Console")}
          </Button>
          <ParserResultDialog
            title={t("Microservice Console")}
            maxWidth="lg"
            onClose={() => {
              // setIsTestingParser(false);
              showParserResultDialog(false);
            }}
          >
            <ManagedEntityConfigureTable
              microserviceUri={microserviceUri}
              deviceId={deviceId}
              isFilterVariableEnabled={false}
              q={value}
              microserviceData={!objectReference.length ? [] : undefined} // Workaround to show data when ms objects are filtered by other parameters
            />
          </ParserResultDialog>
        </>
      )}
    </>
  );
};

const MicroserviceReferenceObject = ({
  objectId,
  managedEntityId,
  sourceUri,
  localVarToFilter,
  remoteVarToFilter,
  microserviceNames,
  localVarValue,
  token,
  ...props
}) => {
  const { t } = useTranslation();
  const { assetId, microserviceName } = useParams();
  const { state } = useLocation();

  const deviceId = managedEntityId || assetId;
  const [managedEntityLoading, , managedEntity] = useApi({
    apiCall: getManagedEntityData,
    params: {
      managedEntityId: deviceId,
    },
    wait: !deviceId,
    token,
  });

  const deploymentSettingId = managedEntity?.configProfileId;
  const [deploymentSettingLoading, , deploymentSetting] = useApi({
    apiCall: getDeploymentSetting,
    params: {
      deploymentSettingId,
      filterByLabel: isPermissionProfileLabelsEnabled,
    },
    wait: !deploymentSettingId,
    token,
  });

  const fromURI =
    sourceUri ??
    state.microServiceUri ??
    (deploymentSetting &&
      Object.values(deploymentSetting.microserviceUris).find(
        (fullPath) =>
          Repository.stripFolderPathAndFileExtensionFromUri(fullPath) ===
          microserviceName,
      ));
  const [objectReferenceLoading, error, objectReference = []] = useApi({
    apiCall: getMSReferenceByObjectRefData,
    params: {
      objectId,
      deviceId,
      fromURI,
      localVarToFilter,
      localVarValue,
      remoteVarToFilter,
      msNames: microserviceNames,
    },
    wait: !deviceId || !microserviceNames?.length || !fromURI,
    token,
  });

  if (
    managedEntityLoading ||
    deploymentSettingLoading ||
    objectReferenceLoading
  ) {
    return <CircularProgress aria-label={t("Loading")} />;
  }
  if (error) {
    return <Typography>{t("Unable to load Microservice Object")}</Typography>;
  }

  return (
    <MicroserviceReferenceSelect
      objectReference={objectReference}
      deviceId={deviceId}
      token={token}
      {...props}
    />
  );
};

const MicroserviceReferenceObmf = ({
  deviceId,
  uris,
  token,
  localVarToFilter,
  localVarValueToFilter,
  remoteVarToFilter,
  remoteVarValueToFilter,
  ...props
}) => {
  const { t } = useTranslation();
  const msNames =
    uris?.map((uri) =>
      Repository.stripFolderPathAndFileExtensionFromUri(uri),
    ) ?? [];
  const [objectReferenceLoading, error, objectReference = []] = useApi({
    apiCall: getMSReferenceByObmfRefData,
    params: {
      deviceId,
      msNames,
      localVarToFilter,
      localVarValueToFilter,
      remoteVarToFilter,
      remoteVarValueToFilter,
    },
    wait: !deviceId || msNames.length === 0,
    token,
  });

  if (!uris || !uris.length) {
    return (
      <Typography>{t("Target Microservices are not defined.")}</Typography>
    );
  }
  if (!deviceId) {
    return <Typography>{t("Target Managed Entity is not set.")}</Typography>;
  }
  if (objectReferenceLoading) {
    return <CircularProgress aria-label={t("Loading")} />;
  }
  if (error) {
    return <Typography>{t("Unable to load Microservice Object")}</Typography>;
  }

  return (
    <MicroserviceReferenceSelect
      objectReference={objectReference}
      deviceId={deviceId}
      token={token}
      msName={msNames?.[0] || ""}
      {...props}
    />
  );
};

const MicroserviceReference = ({ objectRefData, obmfRefData, ...props }) => {
  if (objectRefData)
    return <MicroserviceReferenceObject {...objectRefData} {...props} />;
  if (obmfRefData)
    return <MicroserviceReferenceObmf {...obmfRefData} {...props} />;
  return null;
};

MicroserviceReference.propTypes = {
  objectRefData: PropTypes.shape({
    objectId: PropTypes.string,
    managedEntityId: PropTypes.string,
    localVarToFilter: PropTypes.string,
    localVarValue: PropTypes.string,
    remoteVarToFilter: PropTypes.string,
    microserviceNames: PropTypes.array,
    sourceUri: PropTypes.string,
  }),
  obmfRefData: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    uris: PropTypes.array,
  }),
  token: PropTypes.string.isRequired,
};

export default MicroserviceReference;
