import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { buildRoute } from "msa2-ui/src/utils/urls";

import SectionTabs from "msa2-ui/src/components/SectionTabs";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";
import AuditLog from "./AuditLog";
import ProfileDetails from "msa2-ui/src/routes/profile/details/Details";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import { getDelegationProfile } from "msa2-ui/src/store/delegationProfiles";

const isProfileAuditLogsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.profileAuditLogs,
);

export const ProfileTabs = ({ count, endElements, canViewAuditLogs }) => {
  const { t } = useTranslation();

  const tabs = [
    {
      path: "/profile/details",
      displayName: "Profile",
    },
    {
      path: "/profile/audit",
      displayName: "Audit Logs",
      disabled: !canViewAuditLogs,
    },
  ];

  const enabledTabs = tabs.filter((tab) => !tab.disabled);
  const tabsWithCounts = enabledTabs.map((tab) => {
    if (count) {
      const [path, value] = count;
      if (tab.path === path) {
        return {
          ...tab,
          displayName: t(tab.displayName),
          count: value,
        };
      }
    }
    return {
      ...tab,
      displayName: t(tab.displayName),
    };
  });
  return <SectionTabs tabs={tabsWithCounts} endElements={endElements} />;
};

export default () => {
  const { path } = useRouteMatch();

  const defaultRoute = buildRoute(path, "details");
  const canViewAuditLogs =
    useSelector(
      getDelegationProfile(delegationProfileTypes.AUDIT_LOGS, "general.view"),
    ) && isProfileAuditLogsEnabled;

  const sectionTabs = (props) => (
    <ProfileTabs canViewAuditLogs={canViewAuditLogs} {...props} />
  );

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={defaultRoute} />
      </Route>
      <Route exact path={buildRoute(path, "details")}>
        <ProfileDetails sectionTabs={sectionTabs} />
      </Route>
      <AuthorizedRoute
        guard={canViewAuditLogs}
        redirect={defaultRoute}
        path={buildRoute(path, "audit")}
      >
        <AuditLog sectionTabs={sectionTabs} />
      </AuthorizedRoute>
      <Redirect to={defaultRoute} />
    </Switch>
  );
};
