import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { change } from "redux-form";
import { getFormValues } from "msa2-ui/src/store/form";

import {
  Checkbox,
  FormControlLabel,
  Divider,
  Grid,
  Typography,
  Collapse,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { CONFIGURATION_TYPES } from "msa2-ui/src/services/Parser";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import ConfigurationParser from "msa2-ui/src/components/configurationParser/ConfigurationParser";
import Editor from "msa2-ui/src/components/connectedFormComponents/Editor";
import EditorAce from "msa2-ui/src/components/EditorAce";
import Textfield from "msa2-ui/src/components/connectedFormComponents/Textfield";
import VisibleField from "./VisibleField";
import useDialog from "msa2-ui/src/hooks/useDialog";

export const useStyles = makeStyles((theme) => ({
  sectionText: {
    fontSize: "0.8125rem",
    fontWeight: theme.typography.fontWeightLight,
    color: theme.colors.greyDark1,
  },
  example: {
    fontSize: "0.8125rem",
    fontWeight: theme.typography.fontWeightLight,
    color: theme.colors.greyDark1,
    marginBottom: 15,
  },
  sectionTextMargin: {
    marginBottom: 15,
  },
  marginAfter: {
    marginRight: 10,
  },
  pointer: {
    cursor: "pointer",
  },
}));

const ImportCommandForm = ({
  form,
  submitForm,
  commandIndex,
  deviceId,
  submitting,
  isVisible,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showExample, toggleShowExample] = useToggle(true);
  const [showConfirmationDialog, ConfirmationDialog] = useDialog();

  const configTypeFromForm = useSelector(
    getFormValues(form, MicroserviceForm.fields.configType),
  );
  const xpath = useSelector(
    getFormValues(form, ["command", commandIndex, "xpath"].join(".")),
  );
  const {
    editorMode,
    commandName,
    showExtraCommand,
    showCommandToRun,
  } = CONFIGURATION_TYPES[configTypeFromForm];

  if (!configTypeFromForm) {
    return null;
  }

  const proceedWithParserTest = () => {
    submitForm();
  };

  const renderConfigurationParserActions = () =>
    deviceId ? (
      <Button
        variant="text"
        size="small"
        color="default"
        onClick={() => showConfirmationDialog(true)}
      >
        {t("Parse Example")}
      </Button>
    ) : null;

  return (
    <>
      <ConfirmationDialog
        title={t("Confirm request")}
        content={t(
          "All changes done to Microservice will be saved to test the parser. Are you sure you want to continue?",
        )}
        onExec={() => proceedWithParserTest()}
      />
      <FormSection title={t("Command to Run")} loading={submitting}>
        <Grid item container xs={12} direction={"column"}>
          {showCommandToRun && (
            <>
              <Grid
                item
                container
                direction={"row"}
                alignItems={"baseline"}
                className={classes.sectionTextMargin}
              >
                <Typography
                  className={classnames(
                    classes.sectionText,
                    classes.marginAfter,
                  )}
                >
                  {t(
                    "Retrieve the configuration from the managed entity for this microservice.",
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <FieldSelector
                  label={commandName}
                  type="text"
                  name={["command", commandIndex, "operation"].join(".")}
                />
              </Grid>
            </>
          )}
          {showExtraCommand &&
            (() => {
              const commands = xpath?.split("#") ?? [];
              let newTextLabel = t("XPATH Command");
              if (configTypeFromForm === CONFIGURATION_TYPES.json.value) {
                newTextLabel = t("Endpoint");
              }
              const hasExtraCommand = commands.length > 1;
              const command1 = commands[0];
              const command2 = hasExtraCommand && commands.slice(1).join("#");
              return (
                <>
                  <Grid item>
                    <Textfield
                      label={newTextLabel}
                      aria-label={newTextLabel}
                      type="text"
                      fullWidth
                      value={command1}
                      onChange={({ target: { value } }) => {
                        dispatch(
                          change(
                            form,
                            ["command", commandIndex, "xpath"].join("."),
                            hasExtraCommand
                              ? [value, command2].join("#")
                              : value,
                          ),
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasExtraCommand}
                          name={"subCategory"}
                          onChange={({ target: { checked } }) => {
                            dispatch(
                              change(
                                form,
                                ["command", commandIndex, "xpath"].join("."),
                                checked ? command1 + "#" : command1,
                              ),
                            );
                          }}
                          id={"MICROSERVICE_IMPORT_EXTRA_COMMAND"}
                        />
                      }
                      label={
                        <Typography className={classes.subCategoryLabel}>
                          {t("Use Microservice Configuration")}
                        </Typography>
                      }
                    />
                  </Grid>
                  {hasExtraCommand && (
                    <Grid item>
                      <EditorAce
                        aria-label={t("Import Command Additional  area")}
                        name={["command", commandIndex, "operation"].join(".")}
                        mode={Editor.mode.smarty}
                        options={{ maxLines: Infinity }}
                        value={command2 || ""}
                        onChange={(value) => {
                          dispatch(
                            change(
                              form,
                              ["command", commandIndex, "xpath"].join("."),
                              [command1, value].join("#"),
                            ),
                          );
                        }}
                      />
                    </Grid>
                  )}
                </>
              );
            })()}
        </Grid>
      </FormSection>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <FormSection title={t("Output Example")} loading={submitting}>
        <Grid item xs={12}>
          <Typography
            className={classnames(classes.example, classes.pointer)}
            onClick={() => toggleShowExample(!showExample)}
          >
            {t("Example", { name: configTypeFromForm.toUpperCase() })} (
            {t(showExample ? "Hide" : "Show")})
          </Typography>
          <Collapse in={showExample}>
            <VisibleField
              visible={isVisible}
              name={"example.content"}
              component={Editor}
              mode={Editor.mode[editorMode]}
            />
          </Collapse>
        </Grid>
      </FormSection>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <FormSection
        title={t("Configuration Parser")}
        loading={submitting}
        actions={renderConfigurationParserActions()}
      >
        <Grid item xs={12}>
          <ConfigurationParser form={form} />
        </Grid>
      </FormSection>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <FormSection title={t("Post Import Operations")} loading={submitting}>
        <Grid item xs={12}>
          <VisibleField
            visible={isVisible}
            name={["command", commandIndex, "postTemplate"].join(".")}
            component={Editor}
            mode={Editor.mode.smarty}
          />
        </Grid>
      </FormSection>
    </>
  );
};

ImportCommandForm.propTypes = {
  form: PropTypes.string.isRequired,
  commandIndex: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default ImportCommandForm;
