import { useRef } from "react";
import throttle from "lodash/throttle";
import { useUnmountEffect } from "msa2-ui/src/hooks/useUnmountEffect";

const CLICK_DELTA = 10;

export const useMouseManipulations = ({
  targetRef,
  onDragStart,
  onDrag,
  onDragEnd,
  onClick,
  clickDelta = CLICK_DELTA,
  dragTimeout = 100,
}) => {
  const isDragging = useRef(false);
  const startX = useRef(0);
  const startY = useRef(0);

  const handleMouseMoveThrottledRef = useRef();

  function handleMouseDown(evt) {
    if (!targetRef.current || evt.button !== 0) {
      return;
    }

    targetRef.current.setAttribute("draggable", false);
    targetRef.current.style.userSelect = "none";

    isDragging.current = false;

    startX.current = evt.clientX;
    startY.current = evt.clientY;

    addListeners();
  }

  function handleMouseMove(evt) {
    const { clientX, clientY } = evt;

    if (
      !isDragging.current &&
      (Math.abs(clientX - startX.current) > clickDelta ||
        Math.abs(clientY - startY.current) > clickDelta)
    ) {
      isDragging.current = true;
      onDragStart && onDragStart(evt);
    }

    if (isDragging.current) {
      evt.preventDefault();

      const delta = calculateDelta(
        { x: startX.current, y: startY.current },
        { x: clientX, y: clientY },
      );

      onDrag && onDrag(evt, delta);
    }
  }

  function handleMouseUp(evt) {
    if (isDragging.current) {
      evt.preventDefault();

      const delta = calculateDelta(
        { x: startX.current, y: startY.current },
        { x: evt.clientX, y: evt.clientY },
      );

      onDragEnd && onDragEnd(evt, delta);
    }

    handleMouseMoveThrottledRef.current.cancel();
    removeListeners();
  }

  function handleClick(evt) {
    if (isDragging.current) {
      return evt.preventDefault();
    }

    onClick && onClick(evt);
  }

  function addListeners() {
    const handleMouseMoveThrottled = throttle(handleMouseMove, dragTimeout);

    handleMouseMoveThrottledRef.current = handleMouseMoveThrottled;

    document.addEventListener("mousemove", handleMouseMoveThrottled);
    document.addEventListener("mouseup", handleMouseUp);
  }

  function removeListeners() {
    document.removeEventListener(
      "mousemove",
      handleMouseMoveThrottledRef.current,
    );
    document.removeEventListener("mouseup", handleMouseUp);
  }

  useUnmountEffect(() => {
    removeListeners();
  });

  return {
    onMouseDown: handleMouseDown,
    onClick: handleClick,
  };
};

function calculateDelta(start, end) {
  return { x: end.x - start.x, y: end.y - start.y };
}
