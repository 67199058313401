import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";

import useDialog from "msa2-ui/src/hooks/useDialog";

import useApi from "msa2-ui/src/hooks/useApi";
import { getScheduledWorkflows } from "msa2-ui/src/api/workflow";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import Process from "msa2-ui/src/services/Process";
import Variable from "msa2-ui/src/services/Variable";
import Repository from "msa2-ui/src/services/Repository";

import { workflowStatus } from "msa2-ui/src/Constants";
import {
  monthStringToMonthObject,
  weekdayStringToDayObject,
} from "msa2-ui/src/components/schedule/utils";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";

import AutomationDetailsScheduleDialog from "msa2-ui/src/components/schedule/ScheduleDialog";
import RunTimeVariables from "msa2-ui/src/components/variables/RunTimeVariableFields";
import AlertBar from "msa2-ui/src/components/AlertBar";

const useStyles = makeStyles(({ colors, spacing }) => ({
  dialogLink: {
    marginLeft: 8,
  },
  processName: {
    fontSize: 16,
  },
  processWrapper: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingRight: spacing(2),
    "&:hover #DRAWER_ACTIONS": {
      display: "flex",
    },
  },
  processLineWrapper: {
    paddingBottom: 5,
  },
  removedProcess: {
    color: colors.red,
  },
  processTypeWrapper: {
    padding: "1px 10px 1px 10px",
  },
  loaderWrapper: {
    padding: 10,
    textAlign: "center",
  },
  drawerActions: {
    display: "none",
  },
}));

const AutomationDetailsTabScheduled = ({
  instanceId,
  processes,
  open,
  variables = [],
  workflowPath,
  ubiqubeId: ubiqubeIdFromProps,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { ubiqubeId: selectedUbiqubeId } = useSelector(getSelectedSubtenant);
  const ubiqubeId = selectedUbiqubeId ?? ubiqubeIdFromProps;

  const [showAlert, setShowAlert] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedProcessData, setSelectedProcessDeta] = useState({
    details: {},
    variables: {},
  });
  const [showVariableDialog, VariableDialog] = useDialog();

  const [isLoading, error, apiResponse = [], , reload] = useApi(
    getScheduledWorkflows,
    {
      ubiqubeId,
      workflowPath: Repository.stripFileExtensionFromString(
        decodeURIComponent(workflowPath),
      ),
      serviceId: instanceId,
    },
    !instanceId,
  );

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }
  }, [error]);

  const closeDetailsDialog = () => {
    setShowDetailsDialog(false);
  };

  const handleCloseAlertBar = () => {
    setShowAlert(false);
  };

  if (!open) return null;

  return (
    <>
      {showAlert && (
        <AlertBar
          id="AUTOMATION_DETAILS_TAB_HISTORY_ALERT_BAR_FAILED_TO_LOAD"
          message={t("Unable to load x", { x: t("Workflows") })}
          refreshFnc={reload}
          closeFnc={handleCloseAlertBar}
        />
      )}
      <Paper className={commonClasses.commonPaper}>
        {(() => {
          if (isLoading) {
            return (
              <div className={classes.loaderWrapper}>
                <CircularProgress />
              </div>
            );
          }

          if (!apiResponse.length) {
            return (
              <Typography
                variant="body1"
                className={commonClasses.commonDescription}
              >
                {t("There are no scheduled processes")}
              </Typography>
            );
          }

          return apiResponse?.map((process, i) => {
            const {
              processId,
              processDisplayName,
              processName,
              status,
              beginDate,
              endDate,
              nextExecutionDate,
              lastExecutionDate,
              period,
              periodUnit,
              weekDay,
              month,
              variablesJson,
            } = process;

            const instanceIsOrphaned = Process.instanceIsOrphaned(
              processes,
              processName,
            );

            const processStatus = workflowStatus.find(
              (workflow) => workflow.id === status,
            );

            const ProcessStatusIcon = processStatus.iconBig;

            const entry = {
              periodicity: periodUnit,
              periodicityValue: period,
              startDate: beginDate,
              endDate,
              weekDay: weekdayStringToDayObject(weekDay),
              month: monthStringToMonthObject(month),
            };

            return (
              <Grid
                key={i}
                id={`AUTOMATION_DETAILS_TAB_SCHEDULED_SELECT_ROW_${processId}`}
                container
                className={classnames(
                  commonClasses.commonTableRowHighlight,
                  classes.processWrapper,
                )}
              >
                <Grid
                  item
                  xs={1}
                  className={classnames(
                    commonClasses.commonFlexVerticalStart,
                    classes.processTypeWrapper,
                  )}
                >
                  {!instanceIsOrphaned && <ProcessStatusIcon />}
                </Grid>
                <Grid item xs={11}>
                  <Grid container className={classes.processLineWrapper}>
                    <Grid item xs={8}>
                      <Typography variant="h4" className={classes.processName}>
                        {processDisplayName}
                      </Typography>
                      {instanceIsOrphaned && (
                        <Typography
                          variant="body2"
                          className={classes.removedProcess}
                        >
                          [{t("Removed Process")}]
                        </Typography>
                      )}
                    </Grid>
                    {!instanceIsOrphaned && (
                      <Grid
                        id="DRAWER_ACTIONS"
                        item
                        xs={4}
                        className={classnames(
                          commonClasses.commonFlexEnd,
                          classes.drawerActions,
                        )}
                      >
                        <Typography
                          variant="h3"
                          className={classnames(
                            commonClasses.commonLink,
                            classes.dialogLink,
                          )}
                          onClick={() => {
                            setSelectedProcessDeta({
                              ...selectedProcessData,
                              details: entry,
                            });
                            setShowDetailsDialog(true);
                          }}
                        >
                          {t("Details")}
                        </Typography>
                        <Typography
                          variant="h3"
                          className={classnames(
                            commonClasses.commonLink,
                            classes.dialogLink,
                          )}
                          onClick={() => {
                            setSelectedProcessDeta({
                              ...selectedProcessData,
                              variables: variablesJson,
                            });
                            showVariableDialog();
                          }}
                        >
                          {t("Variables")}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container className={classes.processLineWrapper}>
                    <Grid
                      item
                      xs={12}
                      className={commonClasses.commonFlexStart}
                    >
                      <Typography
                        variant="body1"
                        className={commonClasses.commonDescription}
                      >
                        <span>{`${t("Begin date")}: `}</span>
                        <span>
                          {beginDate
                            ? displayMonthDayYearTimeDate(beginDate)
                            : "-"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={commonClasses.commonFlexStart}
                    >
                      <Typography
                        variant="body1"
                        className={commonClasses.commonDescription}
                      >
                        <span>{`${t("End date")}: `}</span>
                        <span>
                          {endDate ? displayMonthDayYearTimeDate(endDate) : "-"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={commonClasses.commonFlexStart}
                    >
                      <Typography
                        variant="body1"
                        className={commonClasses.commonDescription}
                      >
                        <span>{`${t("Next execution date")}: `}</span>
                        <span>
                          {nextExecutionDate
                            ? displayMonthDayYearTimeDate(nextExecutionDate)
                            : "-"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={commonClasses.commonFlexStart}
                    >
                      <Typography
                        variant="body1"
                        className={commonClasses.commonDescription}
                      >
                        <span>{`${t("Last execution date")}: `}</span>
                        <span>
                          {lastExecutionDate
                            ? displayMonthDayYearTimeDate(lastExecutionDate)
                            : "-"}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          });
        })()}

        {showDetailsDialog && (
          <AutomationDetailsScheduleDialog
            open
            onClose={closeDetailsDialog}
            predefinedEntry={selectedProcessData.details}
            readOnly
          />
        )}
        <VariableDialog title={t("Edit Variables")} maxWidth={"md"}>
          <RunTimeVariables
            data={selectedProcessData.variables}
            variables={variables.filter(({ name }) =>
              Object.keys(selectedProcessData.variables).includes(
                Variable.removePrefix(name),
              ),
            )}
          />
        </VariableDialog>
      </Paper>
    </>
  );
};

export default AutomationDetailsTabScheduled;
