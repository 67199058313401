import React from "react";
import PropTypes from "prop-types";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { MenuItem } from "msa2-ui/src/components/menu";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { deleteDevice } from "msa2-ui/src/api/managedEntity";

const DeleteAction = ({ node, onChange }) => {
  const t = useBoundedTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteDialog, DeleteDialog] = useDialog();

  const handleDelete = async () => {
    const [error] = await deleteDevice({
      token,
      id: node.__metadata__.objectId,
    });

    if (error) {
      enqueueSnackbar(error.getMessage(t("Unable to delete Managed Entity.")), {
        variant: "error",
      });
      return;
    }

    enqueueSnackbar(t("Managed Entity has been deleted!"), {
      variant: "success",
    });
    onChange();
  };

  return (
    <>
      <DeleteDialog
        title={t("Confirm Request")}
        content={t("Are you sure you want to remove", {
          name: node.id,
        })}
        onExec={handleDelete}
      />
      <MenuItem onClick={showDeleteDialog} id="DELETE_ACTION">
        {t("Delete")}
      </MenuItem>
    </>
  );
};

DeleteAction.propTypes = {
  node: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DeleteAction;
