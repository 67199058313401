import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import MenuLoader from "./MenuLoader";
import { getToken } from "msa2-ui/src/store/auth";
import { NestedMenuItem, MenuItem } from "msa2-ui/src/components/menu";
import { useSnackbar } from "notistack";
import { getBpmDiagramsForSubtenant } from "msa2-ui/src/api/bpm";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { buildRoute } from "msa2-ui/src/utils/urls";
import BpmExecute from "msa2-ui/src/routes/automation/bpm/Execute";

const TriggerBpmAction = ({ onClose }) => {
  const [isBpmListPending, setIsBpmListPending] = useState(false);
  const [bmpList, setBpmList] = useState([]);
  const [bpmUri, setBpmUri] = useState("");

  const t = useBoundedTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const token = useSelector(getToken);
  const { id: subtenantId } = useSelector(getSelectedSubtenant);

  const history = useHistory();

  const handleTriggerBpmClick = async () => {
    setIsBpmListPending(true);

    const [error, response] = await getBpmDiagramsForSubtenant({
      subtenantId,
      token,
      page_size: 10000, // we need to use some ridiculously large number here as api doesn't support pagination-less approach
    });

    setIsBpmListPending(false);

    if (error) {
      enqueueSnackbar(error.getMessage(), {
        variant: "error",
      });
      return;
    }

    setBpmList(response || []);
  };

  const handleBmpItemClick = (bmpUri) => {
    setBpmUri(bmpUri);
  };

  const handleBpmPopupClose = () => {
    setBpmUri("");
    onClose();
  };

  const handleBpmPopupExecute = (definitionId, processInstanceId) => {
    history.push(
      buildRoute(
        "/automation/bpm/",
        [encodeURIComponent(bpmUri), definitionId, processInstanceId].join("/"),
      ),
    );
  };

  const renderBpmList = () => {
    if (!bmpList.length)
      return (
        <MenuItem id={`TRIGGET_BMP_NAME_NO_OP`} disabled>
          {t("No data available")}
        </MenuItem>
      );

    return bmpList.map((bmp, i) => (
      <MenuItem
        id={`TRIGGET_BMP_NAME_${i}`}
        key={bmp.uri}
        onClick={() => handleBmpItemClick(bmp.uri)}
      >
        {bmp.name}
      </MenuItem>
    ));
  };

  return (
    <>
      {Boolean(bpmUri) && (
        <BpmExecute
          bpmUri={bpmUri}
          onClose={handleBpmPopupClose}
          onExecute={handleBpmPopupExecute}
        />
      )}
      <NestedMenuItem
        id="TRIGGER_BPM_ACTION"
        label={t("Trigger x", { x: "BPM" })}
        shouldOpenOnClick
        onClick={handleTriggerBpmClick}
      >
        {isBpmListPending ? <MenuLoader /> : renderBpmList()}
      </NestedMenuItem>
    </>
  );
};

TriggerBpmAction.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TriggerBpmAction;
