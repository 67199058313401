import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Close from "@material-ui/icons/Close";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import { useTranslation } from "react-i18next";
import useFilter from "msa2-ui/src/hooks/useFilter";
import { sortOrder } from "msa2-ui/src/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUserDetails } from "msa2-ui/src/store/auth";
import {
  getAvailableSubtenants,
  getSelectedTenant,
  selectSubtenant,
} from "msa2-ui/src/store/designations";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  detachServicesFromMultipleSubtenants,
  getSubtenantsByBpm,
} from "msa2-ui/src/api/bpm";
import TableLoading from "msa2-ui/src/components/TableLoading";
import MSATableRow from "msa2-ui/src/components/TableRow";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { ReactComponent as IconRemove } from "msa2-ui/src/assets/icons/remove.svg";
import { useSnackbar } from "notistack";
import AttachDetachBpm from "./AttachDetachBpm";
import extractUbiqubeId from "msa2-ui/src/utils/extractUbiqubeId";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  tableRow: {
    height: 0,
  },
  contentWrapper: {
    padding: "0px 20px",
    height: 512,
  },
}));

const TABLE_COLUMNS = [
  {
    id: "subtenantName",
    name: "Subtenant Name",
    align: "left",
    isSortable: true,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    isSortable: false,
  },
];

const AttachedSubtenantsPopup = ({ bpmName, bpmPath, onClose }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [showDeleteDialog, DeleteDialog, subtenantToDetach] = useDialog();
  const history = useHistory();

  const [filterState, filterActions] = useFilter({
    sortByValue: TABLE_COLUMNS[0].id,
    sortOrderValue: 0,
    searchValue: "",
  });

  const [isAttachDialogShown, setIsAttachDialogShown] = useState(false);

  const managerId = useSelector(getUserDetails).id;
  const { id: tenantId } = useSelector(getSelectedTenant);
  const subtenants = useSelector(getAvailableSubtenants);
  const token = useSelector(getToken);

  const [
    isLoading,
    ,
    attachedSubtenants = [],
    ,
    reloadAttachedSubtenants,
  ] = useApi(getSubtenantsByBpm, {
    managerId,
    tenantId,
    bpmPath,
  });

  const subtenantsToRender = useMemo(() => {
    return attachedSubtenants
      .filter((subtenant) =>
        subtenant.name
          .toLowerCase()
          .includes(filterState.searchValue.toLowerCase()),
      )
      .sort((a, b) => {
        const [aName, bName] = [a.name.toLowerCase(), b.name.toLowerCase()];
        const [left, right] =
          filterState.sortOrderValue === 0 ? [aName, bName] : [bName, aName];

        if (left > right) return 1;
        if (left < right) return -1;
        return 0;
      });
  }, [filterState.searchValue, filterState.sortOrderValue, attachedSubtenants]);

  const handleSubtenantDetaching = async (subtenantId) => {
    const [isError] = await detachServicesFromMultipleSubtenants({
      token,
      uri: bpmPath,
      ubiqubeIds: subtenantId,
    });

    const variant = isError ? "error" : "success";
    const message = isError
      ? t("Unable to delete x", { x: t("Subtenant") })
      : t("x has been detached", { x: t("Subtenant") });

    enqueueSnackbar(message, { variant });

    if (!isError) {
      reloadAttachedSubtenants();
    }
  };

  const handleShowAttachDialog = () => setIsAttachDialogShown(true);

  const handleSubtenantClick = (ubiqubeId) => {
    const selectedSubtenant = subtenants.find(
      (arr) => arr.id.toString() === extractUbiqubeId(ubiqubeId).id,
    );
    dispatch(selectSubtenant(selectedSubtenant));
    history.push(`/automation/bpm/${encodeURIComponent(bpmPath)}/instances`);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <TableLoading numberOfTableColumns={TABLE_COLUMNS.length} />;
    }

    return (
      <TableBody>
        {subtenantsToRender.map((subtenant) => {
          const { id, name } = subtenant;

          return (
            <MSATableRow
              key={id}
              id={`ATTACHED_SUBTENANTS_POPUP_ROW_${id}`}
              actions={
                <DelegationProfiles
                  type={delegationProfileTypes.BPM}
                  action="general.attach"
                >
                  <Button
                    id="ATTACHED_SUBTENANTS_POPUP_BTN_REMOVE"
                    onClick={() => showDeleteDialog(true, subtenant)}
                  >
                    <IconRemove />
                    <Typography
                      variant="body1"
                      className={commonClasses.commonRemove}
                    >
                      {t("REMOVE")}
                    </Typography>
                  </Button>
                </DelegationProfiles>
              }
              classes={{ row: classes.tableRow }}
            >
              <TableCell>
                <Typography
                  variant="h4"
                  className={[
                    commonClasses.commonTableCellClickable,
                    commonClasses.commonTablePrimary,
                  ].join(" ")}
                  onClick={() => handleSubtenantClick(id)}
                >
                  {name}
                </Typography>
              </TableCell>
            </MSATableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      classes={{ paper: commonClasses.commonDialogPaper }}
      onClose={onClose}
    >
      <DeleteDialog
        title={t("Confirm Request")}
        content={`${t("Are you sure you want to remove", {
          name: subtenantToDetach.name,
        })}`}
        onExec={() => handleSubtenantDetaching(subtenantToDetach.id)}
      />
      {isAttachDialogShown && (
        <AttachDetachBpm
          name={bpmName}
          uri={bpmPath}
          onClose={(shouldReload) => {
            setIsAttachDialogShown(false);
            shouldReload && reloadAttachedSubtenants();
          }}
        />
      )}
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {bpmName}
        </Typography>
        <IconButton
          data-testid="attached-subtenants-popup-close-icon"
          id="ATTACHED_SUBTENANTS_POPUP_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={TABLE_COLUMNS.length}
                className={commonClasses.commonTableHeadRow}
              >
                <FilterMenu
                  {...filterState}
                  id="ATTACHED_SUBTENANTS_POPUP_FILTER_MENU"
                  buttonText={t("Add to...")}
                  buttonOnClick={handleShowAttachDialog}
                  handleSearchByChange={filterActions.handleSearchByChange}
                />
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_COLUMNS.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  <TableSortLabel
                    id={column.id}
                    {...(column.isSortable && {
                      active: filterState.sortByValue === column.id,
                      direction: sortOrder[filterState.sortOrderValue].text,
                      onClick: () => {
                        filterActions.handleSortByOrderChange();
                        filterActions.handleSortByValueChange(column.id);
                      },
                    })}
                  >
                    {column.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {renderTableBody()}
        </Table>
      </DialogContent>
    </Dialog>
  );
};

AttachedSubtenantsPopup.propTypes = {
  bpmName: PropTypes.string.isRequired,
  bpmPath: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AttachedSubtenantsPopup;
