import "typeface-rubik";
import { createTheme as createMuiTheme } from "@material-ui/core";

const createTheme = (darkMode = true) =>
  createMuiTheme({
    direction: "ltr",
    // Don't use these if possible - use the palette instead
    // since it contains light/dark mode logic
    colors: {
      black: "#1d2129",
      blue: "#617bab",
      blueDark1: "#46597e",
      blueDark2: "#232d43",
      blueDark3: "#324261",
      blueLight: "#80a2d9",
      blueLight2: "#dee4f2",
      blueLight3: "#c6d4ec",
      blueLight4: "#d9dee8",
      blueLight5: "#e7ebf2",
      green: "#26bd6f",
      green2: "#24b06c",
      green3: "#24a36a",
      green4: "#0ca940",
      grey: "#8995ab",
      greyDark1: "#646c7d",
      greyDark2: "#b1bbcc",
      greyDark3: "#45484d",
      greyLight1: "#bfc9d9",
      greyLight2: "#f2f4f7",
      greyLight3: "#f3f5f9",
      orange: "#f56527",
      red: "#db2e14",
      redLight: "#f9dfdb",
      white: "#ffffff",
      yellow: "#f5b622",
    },
    darkMode, // Convenience variable for quickly checking darkMode status
    palette: {
      type: darkMode ? "dark" : "light",
      common: {
        black: "#1d2129",
        white: "#ffffff",
      },
      background: {
        paper: darkMode ? "#161a20" : "#ffffff",
        default: darkMode ? "#1e232b" : "#f3f5f9",
      },
      text: {
        primary: darkMode ? "#ffffff" : "#1d2129",
        secondary: darkMode ? "#aaa" : "#646c7d",
        disabled: "#8995ab",
        hint: darkMode ? "#646c7d" : "#bfc9d9",
      },
      shadow: {
        main: "#0A3255",
      },
      primary: {
        main: darkMode ? "#0059a7" : "#46597e",
        contrastText: darkMode ? "#ffffff" : "#000000",
      },
      secondary: {
        main: darkMode ? "#7E93A6" : "#617bab",
      },
      bpm: {
        path: darkMode ? "#46daff" : "#000",
        background: darkMode ? "#000" : "#f3f5f9",
      },
      error: {
        main: "#db2e14",
      },
      warning: {
        main: "#f5b622",
      },
      info: {
        main: "#646c7d",
      },
      running: {
        main: "#617bab",
      },
      success: {
        main: "#26bd6f",
      },
    },
    typography: {
      fontFamily: "'Rubik', 'Helvetica', 'Arial', 'sans-serif'",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontSize: 16,
      h1: {
        color: darkMode ? "#ffffff" : "#1d2129",
        fontSize: "2.25rem",
        lineHeight: "normal",
        fontWeight: 400,
        letterSpacing: 0.5,
        margin: "8px 0",
      },
      h2: {
        color: darkMode ? "#ffffff" : "#1d2129",
        fontSize: "1.75rem",
        lineHeight: "normal",
        fontWeight: 300,
        letterSpacing: 0.3,
        margin: "8px 0",
      },
      h3: {
        color: darkMode ? "#ffffff" : "#1d2129",
        fontSize: "1.5rem",
        lineHeight: 1.5,
        letterSpacing: 0.3,
        fontWeight: 300,
      },
      h4: {
        color: darkMode ? "#ffffff" : "#1d2129",
        fontSize: "1.25rem",
        lineHeight: "normal",
        letterSpacing: 0.5,
        fontWeight: 500,
      },
      h5: {
        color: darkMode ? "#ffffff" : "#1d2129",
        fontSize: "0.8125rem",
        lineHeight: "normal",
        letterSpacing: 0.5,
        fontWeight: 500,
        textTransform: "uppercase",
      },
      subtitle2: {
        fontSize: "0.8125rem",
        fontWeight: 300,
        lineHeight: "1.23",
        letterSpacing: 0.3,
        color: darkMode ? "#aaa" : "#646c7d",
      },
      body1: {
        fontSize: "0.875rem",
      },
      body2: {
        fontSize: "0.8125rem",
      },
      button: {
        fontWeight: "normal",
        textTransform: "none",
      },
    },
    overrides: {
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: "#ffffff",
        },
        toolbarBtnSelected: {
          color: "#ffffff",
          fontWeight: 500,
          textDecoration: "underline",
        },
      },
      MuiPickersDay: {
        daySelected: {
          color: "#ffffff",
        },
      },
      MuiButton: {
        root: {
          color: darkMode ? "#ffffff" : "#1d2129",
          borderRadius: "30px",
        },
        containedPrimary: {
          color: "#ffffff",
        },
      },
      MuiListItemAvatar: {
        root: {
          minWidth: "none",
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: "none",
        },
      },
      MuiListItemText: {
        root: {
          marginBottom: "0",
          marginTop: "0",
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: "1.5rem",
        },
      },
      MuiTab: {
        root: {
          "@media (min-width: 0px)": {
            minWidth: 120,
          },
        },
        wrapper: {
          flexDirection: "row",
        },
      },
      MuiTableCell: {
        root: {
          padding: "4px 10px",
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: "1.15rem",
        },
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: "#e7eaee",
        },
      },
      MuiChip: {
        root: {
          color: darkMode ? "#fff" : "#1d2129",
          height: "auto",
          padding: "2px 0",
          fontSize: "0.625rem",
          borderRadius: 3,
        },
        colorPrimary: {
          color: darkMode ? "#fff" : "#1d2129",
          backgroundColor: darkMode ? "#000" : "#d9dee8",
        },
      },
      MuiRadio: {
        colorSecondary: {
          color: "#46597e",
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      MuiTooltip: {
        tooltip: {
          maxWidth: "50vw",
          fontWeight: 400,
          fontSize: "0.8125rem",
        },
      },
    },
    shadows: Array(25).fill("none"),
    topbar: {
      height: 64,
    },
  });

export default createTheme;
