import { get, post, destroy, put } from "./request";
import { userRoles } from "msa2-ui/src/store/auth";

const API = process.env.REACT_APP_API_PATH;

/*
 * Spec: https://10.31.1.52/swagger/#/Lookup/listManagerIdByOperatorPrefix
 */
export const getManagersByOperatorPrefix = ({ operatorPrefix, token }) => {
  return get({
    url: `${API}/lookup/v1/operator/${operatorPrefix}/managers`,
    token,
  });
};

export const getManagerByReference = (reference, token) => {
  return get({
    url: `${API}/user/reference/${reference}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Lookup/listManagers
 */
export const getManagers = ({
  token,
  filter = "",
  filterCriteria = "",
  sort = "name",
  sortOrder: sort_order = "ASC",
  page = 1,
  pageSize: page_size = 10,
  transforms = [],
}) => {
  return get({
    url: `${API}/lookup/v1/managers`,
    token,
    transforms,
    queryParams: {
      filter,
      filter_criteria: filterCriteria,
      sort,
      sort_order,
      page,
      page_size,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/User/createManager
 */
export const createManager = ({
  token,
  tenantPrefix,
  firstname,
  name,
  email,
  username,
  password,
  usertype,
  privileged = false,
  subtenantIds = [],
}) => {
  return post({
    url: `${API}/user/v1/manager`,
    token,
    body: {
      baseRole: {
        id: privileged ? userRoles.PRIVILEGED_MANAGER : userRoles.MANAGER,
      },
      address: {
        email,
      },
      login: username,
      firstname: firstname,
      name: name,
      operatorPrefix: tenantPrefix,
      pwd: password,
      userType: usertype,
      customerIds: privileged ? undefined : subtenantIds,
    },
  });
};

export const updateManager = ({
  token,
  id: managerId,
  manager,
  name,
  email,
  username: login,
  password: pwd,
  usertype: userType,
  associatedSubtenantIds: customerIds,
  operatorIds,
  delegationProfileId,
  externalReference,
  firstname,
  address,
}) => {
  return put({
    url: `${API}/user/v1/manager/${managerId}`,
    token,
    body: {
      ...manager,
      name,
      address: {
        ...manager.address,
        email,
      },
      login,
      pwd,
      userType,
      customerIds,
      delegationProfileId,
      externalReference,
      firstname,
      operatorIds,
    },
  });
};

export const updateManagerWithUISettings = ({
  token,
  id: managerId,
  userType,
  UISettings,
}) => {
  return put({
    url: `${API}/user/v1/manager/${managerId}`,
    token,
    body: {
      userType,
      UISettings,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/User/deleteManagerById
 */
export const deleteManager = ({ id, token }) => {
  return destroy({
    url: `${API}/user/id/${id}`,
    token,
  });
};
