import React from "react";
import { Link, useRouteMatch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { getSubtenants } from "msa2-ui/src/api/subtenant";
import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";
import useActiveRoute from "msa2-ui/src/hooks/useActiveRoute";
import { getAvailableTenants } from "msa2-ui/src/store/designations";

import {
  Button,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { sortOrder } from "msa2-ui/src/Constants";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import MSATableBody from "msa2-ui/src/components/MSATableBody";

import SubtenantTableBody from "./SubtenantTableBody";
import Create from "./Create";
import Edit from "./Edit";

const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    sortKey: true,
  },
  {
    id: "id",
    name: "ID",
    align: "left",
    sortKey: false,
  },
  {
    id: "prefix",
    name: "Tenant Prefix",
    align: "left",
    sortKey: true,
  },
  {
    id: "operator",
    name: "Tenant",
    align: "left",
    sortKey: false,
  },
  {
    id: "externalReference",
    name: "External Reference",
    align: "left",
    sortKey: false,
  },
  {
    id: "lastUpdated",
    name: "Date Modified",
    align: "left",
    sortKey: true,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    sortKey: false,
  },
];
const Subtenants = ({ adminTabs: AdminTabs }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { path, url } = useRouteMatch();

  const tenants = useSelector(getAvailableTenants);

  const [filterState, filterActions] = useFilter({
    tableName: "admin",
    sortByValue: "lastUpdated",
    sortOrderValue: 0,
    searchValue: "",
    tpPage: 0,
    total: 0,
  });

  const [loading, error, subtenants = [], meta, reload] = useApi(
    getSubtenants,
    filterState,
  );

  useActiveRoute(reload);

  const subtenantsWithOperatorPrefix = subtenants.map((subtenant) => ({
    ...subtenant,
    operator: tenants.find((tenant) => tenant.id === subtenant.operatorId)
      ?.label,
    // lastUpdated here does not contain hhmmss
    lastUpdated: displayMonthDayYearTimeDate(subtenant.lastUpdated),
  }));

  const colSpan = TABLE_HEADER_COLUMNS.length;

  return (
    <>
      <AdminTabs
        currentCount={meta?.total_customers_count ?? 0}
        endElements={
          <Button
            id="SUBTENANT_BTN_CREATE_LINK"
            variant="contained"
            color="primary"
            aria-label={`${t("Create Subtenant")}`}
            component={Link}
            to={{ pathname: `${url}/create` }}
          >
            <IconPlus className={commonClasses.commonBtnIcon} />
            {`${t("Create Subtenant")}`}
          </Button>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={colSpan}
                    className={commonClasses.commonTableCell}
                  >
                    <FilterMenu
                      id="SUBTENANT_TABLE_FILTER_MENU"
                      tpTotal={parseInt(meta?.total_customers_count) || 0}
                      {...filterState}
                      {...filterActions}
                      handleViewAsChange={undefined}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                    <TableCell
                      key={tableHeaderColumn.id}
                      align={tableHeaderColumn.align}
                      className={commonClasses.commonTableCellDefault}
                    >
                      {tableHeaderColumn.sortKey ? (
                        <TableSortLabel
                          id={`SUBTENANT_TABLE_SORT_${tableHeaderColumn.id}`}
                          active={
                            tableHeaderColumn.id === filterState.sortByValue
                          }
                          direction={sortOrder[filterState.sortOrderValue].text}
                          onClick={() => {
                            filterActions.handleSortByOrderChange();
                            filterActions.handleSortByValueChange(
                              tableHeaderColumn.id,
                            );
                          }}
                        >
                          {tableHeaderColumn.name}
                        </TableSortLabel>
                      ) : (
                        tableHeaderColumn.name
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <MSATableBody
                loading={loading}
                error={error && t("Unable to load x", { x: t("Subtenants") })}
                noContents={subtenants.length === 0 && t("No Subtenants found")}
                colSpan={colSpan}
                reload={reload}
              >
                <SubtenantTableBody
                  subtenants={subtenantsWithOperatorPrefix}
                  reloadSubtenants={reload}
                  numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
                />
              </MSATableBody>

              <Route path={`${path}/create`}>
                <Create />
              </Route>
              <Route path={`${path}/:id/edit`}>
                <Edit subtenants={subtenantsWithOperatorPrefix} />
              </Route>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
Subtenants.propTypes = {
  adminTabs: PropTypes.func.isRequired,
};

export default Subtenants;
