import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import getFormValues from "redux-form/lib/getFormValues";

import { reduxFormNames } from "msa2-ui/src/Constants";
import BpmCreate from "msa2-ui/src/routes/automation/bpm/Create";
import { Grid, Typography } from "@material-ui/core";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";

const BmpSuggestions = ({ data, onBpmClose }) => {
  const t = useBoundedTranslation();

  const aiParameters = useSelector(
    getFormValues(reduxFormNames.aiParametersForm),
    shallowEqual,
  );

  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div style={{ padding: 30 }}>
        <Grid container justifyContent="center">
          <Typography variant="h3">{t("No workflows to show")}</Typography>
        </Grid>
      </div>
    );
  }

  return (
    <BpmCreate
      initWorkflows={data}
      onClose={onBpmClose}
      aiParameters={aiParameters}
    />
  );
};

BmpSuggestions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      workflowPath: PropTypes.string.isRequired,
      processName: PropTypes.string.isRequired,
      processType: PropTypes.string.isRequired,
    }),
  ),
  onBpmClose: PropTypes.func.isRequired,
};

export default BmpSuggestions;
