import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { change } from "redux-form";
import reduxForm from "redux-form/lib/reduxForm";
import getFormValues from "redux-form/lib/getFormValues";
import getFormSyncErrors from "redux-form/lib/getFormSyncErrors";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import Modal from "msa2-ui/src/components/modal/Modal";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { reduxFormNames } from "msa2-ui/src/Constants";
import { formArrayTypes, formSections } from "./constants";
import ModalToolbar from "./ModalToolbar";
import ModalSidebar from "./ModalSidebar";
import ModalSections from "./ModalSections";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

const formValuesSelector = getFormValues(reduxFormNames.monitoringProfileForm);
const formErrorsSelector = getFormSyncErrors(
  reduxFormNames.monitoringProfileForm,
);

const adjustDataList = (dataList, snmpPollingIdToDelete) => {
  if (!dataList) return;

  return dataList.reduce((acc, dataListItem) => {
    const { snmpPollingId } = dataListItem;

    if (snmpPollingId === snmpPollingIdToDelete) {
      return acc;
    }

    acc.push({
      ...dataListItem,
      snmpPollingId:
        snmpPollingId > snmpPollingIdToDelete
          ? snmpPollingId - 1
          : snmpPollingId,
    });

    return acc;
  }, []);
};

const MonitoringProfileForm = ({
  pending,
  submitButtonTitle,
  title,
  onClose,
  array: reduxFormArray,
  dirty,
  handleSubmit: onSubmit,
}) => {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(
    formSections.snmpPollingList,
  );
  const [showDiscardDialog, DiscardDialog] = useDialog();
  const { t } = useTranslation();

  const formErrors = useSelector(formErrorsSelector, shallowEqual) || {};
  const formValues = useSelector(formValuesSelector, shallowEqual) || {};

  const graphListValues = formValues[formArrayTypes.graph];

  const sectionClickHandler = (section) => setActiveSection(section);

  const addSnmpListField = useCallback(() => {
    reduxFormArray.push(formArrayTypes.snmp, {});
  }, [reduxFormArray]);

  const removeSnmpListField = useCallback(
    (fieldIndex) => {
      // dataList are dependent on snmpListField, so we need to do a clean up and update indexes
      if (graphListValues?.length) {
        const newGraphList = graphListValues.map((graphListField) => ({
          ...graphListField,
          dataList: adjustDataList(graphListField?.dataList, fieldIndex),
        }));

        dispatch(
          change(
            reduxFormNames.monitoringProfileForm,
            formArrayTypes.graph,
            newGraphList,
          ),
        );
      }

      reduxFormArray.remove(formArrayTypes.snmp, fieldIndex);
    },
    [dispatch, graphListValues, reduxFormArray],
  );

  const submitHandler = useCallback(() => {
    if (
      formErrors.snmpPollingList &&
      !formErrors.snmpPollingList?.every((entry) => isEmpty(entry))
    ) {
      setActiveSection(formSections.snmpPollingList);
    } else if (formErrors.graphRendererList) {
      setActiveSection(formSections.graphRendererList);
    }

    onSubmit();
  }, [formErrors, onSubmit]);

  const closeHandler = useCallback(() => {
    if (dirty) {
      return showDiscardDialog();
    }
    onClose();
  }, [dirty, onClose, showDiscardDialog]);

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={onClose}
      />
      <Modal onClose={closeHandler}>
        <ModalToolbar
          submitButtonTitle={submitButtonTitle}
          title={title}
          isCreateDisabled={pending}
          onClose={closeHandler}
          onSubmit={submitHandler}
          onDiscardChanges={showDiscardDialog}
        />
        <ModalContent>
          <ModalSidebar
            activeSection={activeSection}
            onClick={sectionClickHandler}
          />
          <ModalSections
            pending={pending}
            activeSection={activeSection}
            onAddSnmpListField={addSnmpListField}
            onRemoveSnmpListField={removeSnmpListField}
          />
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

MonitoringProfileForm.propTypes = {
  pending: PropTypes.bool.isRequired,
  submitButtonTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: reduxFormNames.monitoringProfileForm,
  touchOnChange: true,
})(MonitoringProfileForm);
