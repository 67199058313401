import i18n from "i18next";
import flow from "lodash/flow";

const REPOSITORY_PREFIX = "/opt/fmc_repository/";
const PATH = {
  WORKFLOW: {
    value: "Process",
    INIT: {
      value: "workflows",
    },
  },
  MICROSERVICE: {
    value: "CommandDefinition",
    INIT: {
      value: "microservices",
    },
  },
  BPM: {
    value: "Bpmn",
    INIT: {
      value: "bpmns",
    },
  },
  DATAFILES: {
    value: "Datafiles",
  },
};
const VALIDATION_FILE_PATH = [PATH.DATAFILES.value, "validations.json"].join(
  "/",
);
const nameRegex = "[^A-Za-z0-9_぀-ヿ㐀-䶿一-鿿豈-﫿ｦ-ﾟ]";
const VISIBILITY = {
  ALL: {
    value: 5,
    displayName: i18n.t("All (always visible)"),
  },
  MANAGER: {
    value: 4,
    displayName: i18n.t("Manager"),
  },
  PRIVILEGED_MANAGER: {
    value: 3,
    displayName: i18n.t("Privileged manager"),
  },
  ADMINISTRATOR: {
    value: 2,
    displayName: i18n.t("Administrator"),
  },
  PRIVILEGED_ADMINISTRATOR: {
    value: 1,
    displayName: i18n.t("Privileged manager"),
  },
  NONE: {
    value: 0,
    displayName: i18n.t("None (never visible)"),
  },
};

const isFile = (string) => getFilenameFromUri(string).includes(".");

const isAbsolutePath = (string) => {
  return string.startsWith(REPOSITORY_PREFIX);
};

const convertStringToFilename = (string = "", fileExtension = "") => {
  const cleanedString = string.replace(/[^a-z0-9._-]/gi, "_");

  if (!fileExtension) {
    return cleanedString;
  }

  const cleanedFileExtension =
    fileExtension.charAt(0) === "." ? fileExtension : `.${fileExtension}`;

  return `${cleanedString}${cleanedFileExtension}`;
};

const getFilenameFromUri = (string = "") => string.split("/").pop();

const addExtension = (string = "", extension = "") => {
  if (string.endsWith("." + extension)) return string;
  return [string, extension.replace(/[^a-zA-Z]/g, "").toLowerCase()].join(".");
};

const getParentUri = (uri = "") => {
  const uriArray = uri.split("/");
  uriArray.pop();
  return uriArray.join("/");
};

const stripFileExtensionFromString = (string = "") => {
  if (!string?.includes(".")) return string;
  return string
    .split(".")
    .slice(0, -1)
    .join(".");
};

const stripFolderPathAndFileExtensionFromUri = flow(
  getFilenameFromUri,
  stripFileExtensionFromString,
);

const stripFilenameFromUri = (uri) => {
  return uri.substr(0, uri.lastIndexOf("/")) || uri;
};

/**
 * Some paths may include the beginning of the absolute path for the repository
 * In certain scenarios we don't want this and and only want the subpath
 * @param {string} uri - a string with the full absolute path in the repository
 */
const removeRepositoryPrefix = (uri) => uri.replace(REPOSITORY_PREFIX, "");

const maybeAddRepositoryPrefix = (url) =>
  url.includes(REPOSITORY_PREFIX) ? url : `${REPOSITORY_PREFIX}${url}`;

export default {
  isFile,
  isAbsolutePath,
  convertStringToFilename,
  getFilenameFromUri,
  addExtension,
  stripFileExtensionFromString,
  stripFolderPathAndFileExtensionFromUri,
  stripFilenameFromUri,
  getParentUri,
  removeRepositoryPrefix,
  REPOSITORY_PREFIX,
  maybeAddRepositoryPrefix,
  VISIBILITY,
  nameRegex,
  PATH,
  VALIDATION_FILE_PATH,
};
