import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import union from "lodash/union";
import difference from "lodash/difference";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import useApi from "msa2-ui/src/hooks/useApi";
import { getManagedEntitiesBySubtenant } from "msa2-ui/src/api/managedEntity";
import {
  getMonitoringProfileById,
  updateMonitoringProfileById,
} from "msa2-ui/src/api/monitoringProfiles";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import AttachmentDialog from "msa2-ui/src/components/attachment-dialog";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const AttachDialog = ({ profileId, profileName, subtenantId, onClose }) => {
  const t = useBoundedTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();

  const [attachedIds, setAttachedIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [
    isLoadingFetchedManagedEntities,
    ,
    fetchedManagedEntities = [],
  ] = useApi(getManagedEntitiesBySubtenant, {
    pageSize: 10000, // Right now API returns only 10 records if we don't specify pageSize :(
    subtenantId,
    filterByLabel: isPermissionProfileLabelsEnabled,
  });

  const [isLoadingFetchedProfileData, , fetchedProfileData] = useApi(
    getMonitoringProfileById,
    {
      profileId,
    },
  );

  useEffect(() => {
    const { attachedManagedEntities = [] } = fetchedProfileData || {};
    if (attachedManagedEntities.length) {
      setAttachedIds(attachedManagedEntities);
    }
  }, [fetchedProfileData]);

  const handleAttach = useCallback(
    (ids) => setAttachedIds(union(attachedIds, ids)),
    [attachedIds],
  );

  const handleDetach = useCallback(
    (ids) => setAttachedIds(difference(attachedIds, ids)),
    [attachedIds],
  );

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    const [error] = await updateMonitoringProfileById({
      data: {
        ...fetchedProfileData,
        attachedManagedEntities: attachedIds,
      },
      profileId: profileId,
      subtenantId,
      token,
    });

    if (error) {
      enqueueSnackbar(
        error.getMessage(
          t("Unable to attach x", { x: t("Monitoring Profile") }),
        ),
        {
          variant: "error",
        },
      );

      setIsSaving(false);
      return;
    }

    enqueueSnackbar(t("Managed Entities have been attached"), {
      variant: "success",
    });

    onClose(true);
  }, [
    attachedIds,
    enqueueSnackbar,
    onClose,
    profileId,
    fetchedProfileData,
    subtenantId,
    t,
    token,
  ]);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <AttachmentDialog
      allItems={fetchedManagedEntities}
      attachedItemIds={attachedIds}
      idKey="deviceId.ubiId"
      displayNameKey="name"
      attachedTitle={t("Attached x", { x: t("Managed Entities") }) + ": "}
      popupTitle={t("Attach", { wfName: profileName })}
      noDataMessage={t("There is nothing to display", {
        name: t("Managed Entities"),
      })}
      searchPlaceholder={t("Search")}
      unattachedTitle={t("Select x to attach", {
        x: t("Managed Entities"),
      })}
      isLoading={
        isLoadingFetchedManagedEntities ||
        isLoadingFetchedProfileData ||
        isSaving
      }
      onAttach={handleAttach}
      onDetach={handleDetach}
      onClose={handleClose}
      onSave={handleSave}
    />
  );
};

AttachDialog.propTypes = {
  profileId: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  subtenantId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AttachDialog;
