import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import reduxForm from "redux-form/lib/reduxForm";
import flow from "lodash/flow";
import get from "lodash/get";

import useApi from "msa2-ui/src/hooks/useApi";
import { listAllLabels } from "msa2-ui/src/api/managedEntity";

import { getFormValues } from "msa2-ui/src/store/form";
import Variable from "msa2-ui/src/services/Variable";
import { CONFIGURATION_TYPES } from "msa2-ui/src/services/Parser";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";

import { makeStyles } from "@material-ui/core";
import { Divider, Grid, Paper, MenuItem, Typography } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import FormRadio from "msa2-ui/src/components/formSection/FormRadio";
import { MsaSelectCreatable } from "msa2-ui/src/components/msa-select";
import isEmpty from "lodash/isEmpty";

export const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    fontSize: "1.5rem",
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 20,
  },
  formSelectPadding: {
    paddingBottom: "1.4rem",
  },
  formSection: {
    marginTop: 0,
    padding: "3px 10px",
  },
  warning: {
    fontSize: "0.8125rem",
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.colors.red,
    textTransform: "uppercase",
  },
  pointer: {
    cursor: "pointer",
  },
}));

const InformationTabContentEdit = ({ change, microservice }) => {
  const { t } = useTranslation();
  const form = MicroserviceForm.EditFormName;

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const formCategories = useSelector(
    getFormValues(form, MicroserviceForm.fields.category),
  );

  const categories = isEmpty(formCategories)
    ? []
    : formCategories?.split("|").map((c) => ({ label: c, value: c }));

  const formDirectoryNames = useSelector(
    getFormValues(form, MicroserviceForm.fields.directoryNames),
  );

  const directoryNames =
    formDirectoryNames?.map((c) => ({ label: c, value: c })) || [];

  const { variablePath } = MicroserviceForm.formConfig;

  const formVariableValues =
    useSelector(getFormValues(form, variablePath)) || [];

  const manufacturers = useSelector(
    (state) => state.designations.manufacturers,
  );
  const modelsById = useSelector((state) => state.designations.modelsById);
  const manufacturersById = useSelector(
    (state) => state.designations.manufacturersById,
  );
  const vendor = useSelector(
    getFormValues(form, MicroserviceForm.fields.vendor),
  );
  const path = useSelector(
    getFormValues(form, MicroserviceForm.fields.parentURI),
  );
  const filename = useSelector(
    getFormValues(form, MicroserviceForm.fields.filename),
  );

  const [isOptionsLoading, , options = []] = useApi(listAllLabels, {
    category: listAllLabels.categories.MICROSERVICE,
    transforms: [
      (response) => response.map((label) => ({ label, value: label })),
    ],
  });

  const handleVendorChange = (event, value) => {
    change([MicroserviceForm.fields.vendor], value);
    change([MicroserviceForm.fields.model], manufacturersById[value].models[0]);
  };

  const handleCategoryChange = (newCategories) => {
    const categoryList = (newCategories || []).map((c) => c.value);
    change([MicroserviceForm.fields.category], categoryList.join("|"));
  };

  const handleDirectoryNamesChange = (newDirectoryNames = []) => {
    change(
      [MicroserviceForm.fields.directoryNames],
      Array.isArray(newDirectoryNames)
        ? newDirectoryNames.map((c) => c.value)
        : [],
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.sectionHeader}>
          {t("Microservice Information")}
        </Typography>
      </Grid>
      <Paper className={commonClasses.commonPaperNoPadding}>
        <Grid
          container
          direction={"row"}
          spacing={2}
          className={classes.formSection}
        >
          <FormSection title={t("Basic Information")}>
            <Grid container item xs={8} md={8} direction={"row"} spacing={2}>
              <Grid item xs={12}>
                <FieldSelector
                  label={t("Microservice Name")}
                  type="text"
                  name={MicroserviceForm.fields.name}
                />
              </Grid>
              <Grid item xs={12}>
                <MsaSelectCreatable
                  id="DIRECTORY_INPUT"
                  options={options}
                  label={t("Category")}
                  placeholder={t("Add Category")}
                  isClearable
                  isMulti
                  isSearchable
                  value={directoryNames}
                  onChange={handleDirectoryNamesChange}
                  onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
                  isLoading={isOptionsLoading}
                />
              </Grid>
              {path && (
                <Grid item xs={12} md={8}>
                  <FieldSelector
                    label={t("File path")}
                    type="text"
                    name={MicroserviceForm.fields.parentURI}
                    disabled
                  />
                </Grid>
              )}
              {filename && (
                <Grid item xs={12} md={4}>
                  <FieldSelector
                    label={t("File name")}
                    type="text"
                    name={MicroserviceForm.fields.filename}
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <MsaSelectCreatable
                  id="CATEGORY_INPUT"
                  options={options}
                  label={t("Tags")}
                  placeholder={t("Add Tags")}
                  isClearable
                  isMulti
                  isSearchable
                  value={categories}
                  onChange={handleCategoryChange}
                  onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
                  isLoading={isOptionsLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  label={t("Variable for Microservice Object Name")}
                  type="text"
                  name={MicroserviceForm.fields.displayField}
                  className={classes.formSelectPadding}
                >
                  {!formVariableValues.some(
                    ({ name }) => name === "params.object_id",
                  ) && <MenuItem value={"object_id"}>{"object_id"}</MenuItem>}
                  {formVariableValues.map(({ name, displayName }) => (
                    <MenuItem value={Variable.removePrefix(name)} key={name}>
                      {displayName}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormSelect
                  label={t("Vendor")}
                  type="text"
                  name={MicroserviceForm.fields.vendor}
                  onChange={handleVendorChange}
                  className={classes.formSelectPadding}
                  disabled
                >
                  {manufacturers &&
                    manufacturers.map((id) => (
                      <MenuItem value={id} key={id}>
                        {manufacturersById[id].manufacturerName}
                      </MenuItem>
                    ))}
                </FormSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelect
                  label={t("Model")}
                  name={MicroserviceForm.fields.model}
                  className={classes.formSelectPadding}
                  disabled
                >
                  {get(manufacturersById, `[${vendor}].models`, []).map(
                    (id) => (
                      <MenuItem value={id} key={id}>
                        {modelsById[id].modelName}
                      </MenuItem>
                    ),
                  )}
                </FormSelect>
              </Grid>
              <Grid item xs={12}>
                <FormRadio
                  label={t("Configuration Type")}
                  name={MicroserviceForm.fields.configType}
                  options={Object.values(CONFIGURATION_TYPES)}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FieldSelector
                  label={t("Import Rank")}
                  type="number"
                  name={MicroserviceForm.fields.importRank}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldSelector
                  label={t("Description")}
                  type="text"
                  name={MicroserviceForm.fields.description}
                />
              </Grid>
            </Grid>
          </FormSection>
          <Grid item xs={12}>
            {" "}
            <Divider />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

InformationTabContentEdit.propTypes = {
  change: PropTypes.func.isRequired,
};

export default flow(
  withRouter,
  reduxForm({
    form: MicroserviceForm.EditFormName,
    forceUnregisterOnUnmount: false,
    destroyOnUnmount: false,
  }),
)(InformationTabContentEdit);
