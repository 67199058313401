import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import { buildRoute } from "msa2-ui/src/utils/urls";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { getIsDeveloper, getToken } from "msa2-ui/src/store/auth";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import classNames from "classnames";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow as MUITableRow,
  TableSortLabel,
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
  Avatar,
  Chip,
  Button,
} from "@material-ui/core";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import useFilter from "msa2-ui/src/hooks/useFilter";
import { sortOrder } from "msa2-ui/src/Constants";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  getMicroservices,
  deleteMicroservice,
} from "msa2-ui/src/api/microservices";
import AlertBar from "msa2-ui/src/components/AlertBar";
import TableRow from "msa2-ui/src/components/TableRow";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
  FlashOn as MicroserviceIcon,
  ScatterPlot as DeploymentSettingsIcon,
} from "@material-ui/icons";
import { downloadArchive } from "msa2-ui/src/api/repository";
import Repository from "msa2-ui/src/services/Repository";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import { useSnackbar } from "notistack";
import get from "lodash/get";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import NumberFormat from "react-number-format";
import useDialog from "msa2-ui/src/hooks/useDialog";
import AttachDeploymentSettingsDialog from "msa2-ui/src/routes/integration/microservices/dialog/AttachDeploymentSettings.dialog";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import ListDeploymentSettingsDialog from "msa2-ui/src/routes/integration/microservices/dialog/ListDeploymentSettings.dialog";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const tableHeader = [
  {
    id: "name",
    name: "Microservice Name",
    align: "left",
  },
  {
    id: "vendor",
    name: "Vendor / Model",
    align: "left",
  },
  {
    id: "mod_date",
    name: "Date Modified",
    align: "left",
  },
  {
    id: "settings",
    name: "Deployment Settings",
    align: "center",
  },
  {
    id: "add_to_button",
    name: "",
    align: "center",
  },
  {
    id: "blank1",
    name: "",
    align: "left",
  },
];

const useStyles = makeStyles(({ typography }) => ({
  tableInstances: {
    fontSize: "0.8rem",
    fontWeight: typography.fontWeightRegular,
  },
  tableRowButton: {
    margin: "0 4px",
  },
  downloadIcon: {
    width: 48,
  },
  disabled: {
    color: "rgba(255,255,255, 0.3)",
  },
}));

const OverviewTable = ({ sectionTabs: SectionTabs }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const t = useBoundedTranslation();
  const { path, url } = useRouteMatch();
  const [showDeleteDialog, DeleteDialog] = useDialog();

  const subtenantId = useSelector(getSelectedSubtenant).id;

  const [selectedRow, setSelectedRow] = useState(null);
  const [isDeploymentSettingsDialog, setIsDeploymentSettingsDialog] = useState(
    false,
  );
  const [microserviceToDeleteUri, setMicroserviceToDeleteUri] = useState("");
  const [showAddToDialog, setShowAddToDialog] = useState(null);

  const { isDeveloper, manufacturers, models, token } = useSelector(
    (state) => ({
      isDeveloper: getIsDeveloper(state),
      manufacturers: state.designations.manufacturersById,
      models: state.designations.modelsById,
      token: getToken(state),
    }),
    shallowEqual,
  );

  const [filterState, filterActions] = useFilter({
    tableName: "configurations",
    searchValue: "",
    sortByValue: tableHeader[2].id,
    sortOrderValue: 0,
    tpPage: 0,
  });

  const [
    isLoading,
    isError,
    microservices = [],
    microservicesMeta = {},
    reloadMicroservices,
  ] = useApi(getMicroservices, {
    customerId: subtenantId,
    page: filterState.tpPage + 1,
    pageSize: filterState.tpRowsPerPage,
    searchParams: filterState.searchValue,
    sort: sortOrder[filterState.sortOrderValue].text,
    sortBy: filterState.sortByValue,
    filterByLabel: isPermissionProfileLabelsEnabled,
  });

  const microservicesTotalCount =
    microservicesMeta.status === 204
      ? 0
      : parseInt(microservicesMeta.total_ms_count, 10) || null;

  const microservicesToRender =
    microservicesMeta.status === 204 ? [] : microservices;

  const handleDialogDeploymentSettingsClose = () =>
    setIsDeploymentSettingsDialog(false);

  const openDeleteDialogHandler = useCallback(
    (uri) => {
      setMicroserviceToDeleteUri(uri);
      showDeleteDialog();
    },
    [showDeleteDialog],
  );

  const handleDeleteMicroservice = async () => {
    const [deleteError] = await deleteMicroservice({
      microServiceUri: microserviceToDeleteUri,
      token,
    });

    if (deleteError) {
      enqueueSnackbar(
        deleteError.getMessage(
          t("Unable to Delete x", {
            x: microserviceToDeleteUri,
          }),
        ),
        {
          variant: "error",
        },
      );
    } else {
      enqueueSnackbar(
        t("Microservice has been deleted!", {
          name: microserviceToDeleteUri,
        }),
        {
          variant: "success",
        },
      );

      reloadMicroservices();
      setMicroserviceToDeleteUri("");
    }
  };

  const handleAttachDialogClose = () => {
    setShowAddToDialog(false);
    reloadMicroservices();
  };

  return (
    <>
      <DeleteDialog
        onExec={handleDeleteMicroservice}
        title={t("Delete x", {
          x: t("Microservice"),
        })}
        content={t("Are you sure you want to delete x?", {
          x: microserviceToDeleteUri,
        })}
      />
      {showAddToDialog && (
        <AttachDeploymentSettingsDialog
          onClose={handleAttachDialogClose}
          microservice={showAddToDialog}
        />
      )}
      <SectionTabs
        count={[path, microservicesTotalCount]}
        endElements={
          isDeveloper && (
            <CreateEntityButton
              id="MICROSERVICE_BTN_CREATE"
              aria-label={t("Create Microservice")}
              delegationProfileAction="general.create"
              delegationProfileType={delegationProfileTypes.MICROSERVICE}
              link={buildRoute(url, "create")}
            >
              {t("Create Microservice")}
            </CreateEntityButton>
          )
        }
      />
      <Paper
        className={classNames([
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        ])}
      >
        <Table>
          <colgroup>
            {tableHeader.map((header) => (
              <col key={header.id} />
            ))}
          </colgroup>
          <TableHead>
            <MUITableRow>
              <TableCell
                colSpan={tableHeader.length}
                className={commonClasses.commonTableCell}
              >
                <FilterMenu
                  id="CONFIGURATION_TABLE_FILTER_MENU"
                  tpTotal={microservicesTotalCount}
                  tpPage={filterState.tpPage}
                  tpRowsPerPage={filterState.tpRowsPerPage}
                  handleSearchByChange={
                    filterActions.debouncedHandleSearchByChange
                  }
                  tpChangePage={filterActions.tpChangePage}
                  tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
                />
              </TableCell>
            </MUITableRow>
            <MUITableRow className={commonClasses.commonTableHeadRow}>
              {tableHeader.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  className={classNames(
                    commonClasses.commonTableCellDefault,
                    commonClasses.commonTableCellHead,
                  )}
                >
                  {header.name !== "" && (
                    <TableSortLabel
                      id={`CONFIGURATION_TABLE_SORT_${header.id}`}
                      active={filterState.sortByValue === header.id}
                      direction={sortOrder[filterState.sortOrderValue].text}
                      onClick={() => {
                        filterActions.handleSortByOrderChange();
                        filterActions.handleSortByValueChange(header.id);
                      }}
                      classes={{
                        root: commonClasses.commonTableSortLabel,
                        active: commonClasses.commonTableSortLabelActive,
                        icon: commonClasses.commonTableSortLabelIcon,
                      }}
                    >
                      {t(header.name)}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </MUITableRow>
          </TableHead>
          {isLoading && (
            <TableBody>
              <MUITableRow>
                <TableCell
                  colSpan={tableHeader.length}
                  className={commonClasses.commonLoaderWrapperTable}
                >
                  <Grid container justifyContent="center" alignContent="center">
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </MUITableRow>
            </TableBody>
          )}
          {!isLoading && isError && (
            <TableBody>
              <MUITableRow>
                <TableCell colSpan={tableHeader.length}>
                  <AlertBar
                    message={t("Unable to load x", {
                      x: t("Microservices"),
                    })}
                    refreshFnc={reloadMicroservices}
                  />
                </TableCell>
              </MUITableRow>
            </TableBody>
          )}
          {!isLoading && !microservicesTotalCount && (
            // if api returns no data
            <TableBody>
              <MUITableRow>
                <TableCell colSpan={tableHeader.length}>
                  <Typography>{t("0 Results Returned")}</Typography>
                </TableCell>
              </MUITableRow>
            </TableBody>
          )}
          <TableBody>
            {!isLoading &&
              !isError &&
              Boolean(microservicesToRender.length) &&
              microservicesToRender.map((microservice, index) => {
                const microserviceGroups = microservice.group.split("|");
                const isAttachedToDeploymentSettings =
                  microservice.deploymentSettings.length > 0;

                return (
                  <TableRow
                    id={`CONFIGURATION_TABLE_ROW_${microservice.uri}`}
                    key={microservice.uri}
                    onMouseLeave={setSelectedRow}
                    onMouseEnter={() => setSelectedRow(microservice.uri)}
                    actions={
                      isDeveloper ? (
                        <>
                          <DelegationProfiles
                            type={delegationProfileTypes.MICROSERVICE}
                            action="general.modify"
                          >
                            <Link
                              id={`MICROSERVICE_BTN_EDIT_${microservice.uri}`}
                              to={{
                                pathname: buildRoute(
                                  url,
                                  `${encodeURIComponent(
                                    microservice.uri,
                                  )}/edit`,
                                ),
                              }}
                            >
                              <IconButton>
                                <EditIcon color="primary" />
                              </IconButton>
                            </Link>
                          </DelegationProfiles>
                          <IconButton
                            id={`MICROSERVICE_BTN_DOWNLOAD_${microservice.uri}`}
                            aria-label={t("Download")}
                            className={classes.downloadIcon}
                            onClick={() => {
                              downloadArchive({
                                path: microservice.uri,
                                token,
                                fileName:
                                  Repository.stripFolderPathAndFileExtensionFromUri(
                                    microservice.uri,
                                    ".xml",
                                  ) + ".zip",
                                onError: () => {
                                  enqueueSnackbar(
                                    t("Unable to download contents"),
                                    {
                                      variant: "error",
                                    },
                                  );
                                },
                              });
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                          <DelegationProfiles
                            type={delegationProfileTypes.MICROSERVICE}
                            action="general.delete"
                          >
                            <Tooltip
                              title={
                                isAttachedToDeploymentSettings
                                  ? t(
                                      "You cannot delete Microservices which are attached to Deployment Settings. Detach first to delete.",
                                    )
                                  : ""
                              }
                            >
                              <span data-testid="delete-button">
                                <IconButton
                                  id={`MICROSERVICE_BTN_DELETE_${microservice.uri}`}
                                  aria-label="Delete"
                                  onClick={() =>
                                    openDeleteDialogHandler(microservice.uri)
                                  }
                                  disabled={isAttachedToDeploymentSettings}
                                >
                                  <DeleteIcon
                                    color={
                                      isAttachedToDeploymentSettings
                                        ? "disabled"
                                        : "error"
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </DelegationProfiles>
                        </>
                      ) : null
                    }
                  >
                    <TableCell
                      className={classNames(
                        commonClasses.commonTableCellDefault,
                      )}
                    >
                      <div className={commonClasses.commonFlexStart}>
                        <Avatar className={commonClasses.commonTableAvatar}>
                          <MicroserviceIcon />
                        </Avatar>
                        <Typography
                          id={`CONFIGURATION_TABLE_DISPLAY_NAME_${index}`}
                          variant="h4"
                          className={classNames(
                            commonClasses.commonTablePrimary,
                            commonClasses.overflowAnywhere,
                          )}
                          title={microservice.displayName}
                        >
                          {microservice.displayName}
                        </Typography>
                        {microserviceGroups &&
                          microserviceGroups.map((group) => (
                            <Chip
                              key={group}
                              label={group}
                              className={classNames(
                                commonClasses.commonTags,
                                commonClasses.commonTagSystem1,
                              )}
                            />
                          ))}
                        {microservice.subcategory && (
                          <Chip
                            label={microservice.subcategory}
                            className={classNames(
                              commonClasses.commonTags,
                              commonClasses.commonTagSystem1,
                            )}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <Typography
                        variant="body1"
                        className={classes.tableInstances}
                      >
                        {get(manufacturers, [
                          microservice.vendor,
                          "manufacturerName",
                        ])}{" "}
                        / {get(models, [microservice.model, "modelName"])}
                      </Typography>
                    </TableCell>
                    <TableCell className={commonClasses.commonTableCellDefault}>
                      <Typography
                        variant="body1"
                        className={commonClasses.commonTableSecondary}
                      >
                        {microservice.lastModified
                          ? displayMonthDayYearTimeDate(
                              microservice.lastModified,
                            )
                          : t("N/A")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <Typography
                        variant="body1"
                        className={commonClasses.commonTableInstancesNumber}
                      >
                        <NumberFormat
                          className={classNames({
                            [commonClasses.tableRowValue]:
                              !subtenantId ||
                              microservice.deploymentSettings.length === 0,
                          })}
                          value={microservice.deploymentSettings.length}
                          displayType="text"
                          thousandSeparator
                        />
                        <Tooltip
                          title={
                            !subtenantId
                              ? t(
                                  "Please select a subtenant to view deployment settings",
                                )
                              : microservice.deploymentSettings.length === 0
                              ? t("There are no deployment settings attached")
                              : ""
                          }
                        >
                          <span>
                            <IconButton
                              className={classes.tableRowButton}
                              disabled={
                                !subtenantId ||
                                microservice.deploymentSettings.length === 0
                              }
                              onClick={() =>
                                setIsDeploymentSettingsDialog(microservice.uri)
                              }
                              id={`CONFIGURATION_TABLE_DEPLOYMENT_SETTINGS_BTN_${index}`}
                            >
                              <DeploymentSettingsIcon
                                color={
                                  !subtenantId ||
                                  microservice.deploymentSettings.length === 0
                                    ? "disabled"
                                    : "primary"
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        {/*Dialogs*/}
                        {isDeploymentSettingsDialog === microservice.uri && (
                          <ListDeploymentSettingsDialog
                            title={microservice.displayName}
                            deploymentSettings={microservice.deploymentSettings}
                            onClose={handleDialogDeploymentSettingsClose}
                          />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      className={commonClasses.commonTableActionCell}
                    >
                      <DelegationProfiles
                        type={delegationProfileTypes.MICROSERVICE}
                        action="general.attach"
                      >
                        {microservice.uri === selectedRow && (
                          <Tooltip
                            title={
                              !subtenantId
                                ? t(
                                    "Please select a subtenant to attach deployment settings",
                                  )
                                : ""
                            }
                          >
                            <span>
                              <Button
                                id={`MICROSERVICES_ADD_TO_${microservice.id}`}
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={!subtenantId}
                                className={[
                                  commonClasses.commonBtn,
                                  commonClasses.commonBtnPrimary,
                                ].join(" ")}
                                onClick={() => setShowAddToDialog(microservice)}
                              >
                                {t("Add to...")}
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                      </DelegationProfiles>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

OverviewTable.propTypes = {
  sectionTabs: PropTypes.func.isRequired,
};

export default OverviewTable;
