import { destroy, get, post, put } from "./request";
import { isObject, get as lodashGet } from "lodash";

const API = process.env.REACT_APP_API_PATH;

const processAvailableActions = (response) => {
  const { permissionActions } = response;
  let actionsTree = {};

  if (permissionActions && permissionActions.length) {
    permissionActions.forEach((actionObj) => {
      const nullValue = "NULL";
      const { comment, action, category, subCategory } = actionObj;

      actionsTree = {
        ...actionsTree,
        [category]: {
          ...lodashGet(actionsTree, `${category}`),
          ...(subCategory !== nullValue
            ? {
                [subCategory]: {
                  ...lodashGet(actionsTree, `${category}.${subCategory}`, {}),
                  ...(action !== nullValue
                    ? {
                        [action]: {
                          ...lodashGet(
                            actionsTree,
                            `${category}.${subCategory}.${action}`,
                            {},
                          ),
                          comment,
                          value: false,
                        },
                      }
                    : { comment }),
                  value: false,
                },
              }
            : { comment }),
        },
      };
    });
  }

  return actionsTree;
};

const getPermissionList = (actions) => {
  const permissionList = [];
  const categories = Object.keys(actions);

  categories.forEach((category) => {
    const subCategories = Object.keys(actions[category]);

    subCategories.forEach((subCategory) => {
      const subCategoryData = actions[category][subCategory];

      if (isObject(subCategoryData)) {
        const actionsList = Object.keys(subCategoryData);

        actionsList.forEach((action) => {
          const actionObj = actions[category][subCategory][action];
          if (isObject(actionObj)) {
            permissionList.push({
              categoryName: category,
              subCategoryName: subCategory,
              actionName: action,
              right: actionObj.value,
            });
          }
        });
      }
    });
  });

  return permissionList;
};

export const getPermissionProfiles = ({
  filter,
  filterCriteria,
  page,
  pageSize,
  sort,
  sortOrder,
  tenantId,
  token,
}) => {
  return get({
    url: `${API}/permission/v2/list`,
    token,
    queryParams: {
      filter,
      filter_criteria: filterCriteria,
      page,
      page_size: pageSize,
      sort,
      sort_order: sortOrder,
      tenant: tenantId,
    },
  });
};

export const getPermissionProfileById = ({ id, token }) => {
  return get({
    url: `${API}/permission/${id}`,
    token,
  });
};

export const createPermissionProfile = ({ actions, entry, token }) => {
  return post({
    url: `${API}/permission`,
    token,
    body: {
      ...entry,
      permissionList: getPermissionList(actions),
    },
  });
};

export const updatePermissionProfile = ({ entry, actions, token, id }) => {
  return put({
    url: `${API}/permission/${id}`,
    token,
    body: {
      ...entry,
      permissionList: getPermissionList(actions),
    },
  });
};

export const deletePermissionProfileById = ({ profileId, token }) => {
  return destroy({
    url: `${API}/permission/${profileId}`,
    token,
  });
};

export const getAvailableActions = ({
  token,
  type,
  transforms = [processAvailableActions],
}) => {
  return get({
    url: `${API}/permission/v2/available-actions`,
    token,
    queryParams: {
      type,
    },
    transforms,
  });
};
