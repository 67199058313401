import React from "react";
import { Link, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core";
import { isUndefined } from "lodash";

import { Grid, Tabs, Tab } from "@material-ui/core";

import Routing from "msa2-ui/src/utils/Routing";

const useStyles = makeStyles(
  ({ darkMode, typography, palette, breakpoints }) => ({
    tab: {
      minWidth: "auto",
      fontSize: ".875rem",
      fontWeight: typography.fontWeightLight,
      marginRight: 20,
      padding: "10px 20px",
    },
    tabContainer: {
      flexGrow: 2,
      paddingLeft: 25,
    },
    icon: {
      marginRight: 5,
    },
    tabCount: {
      paddingLeft: 5,
      color: darkMode ? palette.primary.main : palette.text.secondary,
    },
    tabSelected: {
      fontWeight: typography.fontWeightMedium,
      border: 0,
      background: palette.background.paper,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      overflow: "visible",
      boxShadow: darkMode
        ? `1px -3px 5px -1px ${palette.primary.main}`
        : "none",
    },
    indicator: {
      display: "none",
    },
    labelText: {
      color: palette.text.primary,
      marginRight: 5,
    },
    count: {
      fontWeight: typography.fontWeightLight,
      lineHeight: "normal",
      letterSpacing: 0.3,
      color: palette.text.secondary,
      marginLeft: 10,
    },
    endElements: {
      textAlign: "right",
      [breakpoints.down("sm")]: {
        order: -1,
        marginBottom: 20,
      },
    },
    tabScroller: {
      padding: "5px 5px 0 0",
    },
  }),
);

const TabLabel = ({ displayName, count }) => {
  const classes = useStyles();
  return (
    <>
      <span className={classes.labelText}>{displayName}</span>
      {!isUndefined(count) && (
        <NumberFormat
          value={count}
          displayType={"text"}
          thousandSeparator={true}
          className={classes.tabCount}
        />
      )}
    </>
  );
};

const SectionTabs = ({ tabs, endElements, onChange, ...props }) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const tabClasses = {
    root: classes.tab,
    wrapper: classes.tabWrapper,
    selected: classes.tabSelected,
  };

  const isControlled = Boolean(onChange);
  const tabsToRender = tabs.filter(({ canAccess = true }) => canAccess);

  return (
    <Grid container>
      <Grid xs={12} md item className={classes.tabContainer}>
        <Tabs
          value={Routing.getTabIndexByPath(tabsToRender, pathname)}
          onChange={onChange}
          classes={{
            indicator: classes.indicator,
            scroller: classes.tabScroller,
          }}
          {...props}
        >
          {tabsToRender.map(({ count, path, displayName, icon: Icon, id }) => (
            <Tab
              component={!isControlled ? Link : undefined}
              to={path}
              label={
                <>
                  {Icon && <Icon className={classes.icon} />}
                  <TabLabel count={count} displayName={displayName} />
                </>
              }
              id={id}
              classes={tabClasses}
              key={`${displayName}-${path}`}
            />
          ))}
        </Tabs>
      </Grid>
      {endElements && (
        <Grid xs={12} md item className={classes.endElements}>
          {/*
            For some reason the buttons expand to fill the height of the flex container
            so we wrap the buttons with a div so that doesn't happen
         */}
          <div>{endElements}</div>
        </Grid>
      )}
    </Grid>
  );
};

export default SectionTabs;
