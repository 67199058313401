import React, { useRef, useCallback } from "react";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import i18n from "i18next";
import { getParentRoute } from "msa2-ui/src/utils/urls";
// import Validation from "msa2-ui/src/services/Validation";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import { Grid } from "@material-ui/core";

export const sections = {
  TENANT_AND_SUBTENANT: 0,
  BASIC_INFORMATION: 1,
  ADMINISTRATIVE_INFORMATION: 2,
  MANAGEMENT_INFORMATION: 3,
  ADVANCED_INFORMATION: 4,
  DUPLICATE_EXTRA_INFORMATION: 5,
};

export const fields = {
  tenant: "Tenant",
  subtenant: "Subtenant",
  category: "category",
  vendor: "manufacturerId",
  model: "modelId",
  nature: "sdNature",
  name: "name",
  managementAddress: "managementAddress",
  managementInterface: "managementInterface",
  hostname: "hostname",
  managementPort: "managementPort",
  serialNumber: "serialNumber",
  login: "login",
  password: "password",
  passwordAdmin: "passwordAdmin",
  externalReference: "externalReference",
};

export const tenantSubtenantSection = [fields.tenant, fields.subtenant];

export const basicInformationSection = [
  fields.vendor,
  fields.model,
  fields.nature,
];

export const administrativeInformationSection = [fields.name];

export const managementSection = [
  fields.managementAddress,
  fields.managementInterface,
  fields.hostname,
  fields.managementPort,
  fields.serialNumber,
  fields.login,
  fields.password,
  fields.passwordAdmin,
];

export const requiredFields = [
  fields.tenant,
  fields.subtenant,
  fields.name,
  fields.nature,
  fields.managementAddress,
];

const mapTypes = {
  String: "text",
  Boolean: "checkbox",
  Password: "password",
  IPAddress: "text",
  Integer: "number",
};

/**
 * Take the object of dynamically loaded fields and separate
 * the fields that aren't already in the management section
 * @param {object} fields an object with all the dynamic fields for a managed entity
 */
export const getAdvancedSection = (fields) =>
  Object.keys(fields || {}).filter((key) => !managementSection.includes(key));

/**
 * Validate the form fields
 * @param {object} values the form values to be validated
 */
export const validate = (values) => {
  const requiredField = i18n.t("This is a required field");

  return requiredFields.reduce(
    (errors, field) => ({
      ...errors,
      ...(!values[field] && {
        [field]: requiredField,
      }),
    }),
    {},
  );
};

/**
 * Render the dynamically loaded fields in a ManagedEntity form
 *
 * @param {array} fieldList the list of fields represented by their key
 * @param {object} fieldLookup the object that contains the data for each field
 * @param {object} firstFieldRef the ref to target the first field in each section
 * @param {object} formValues contains form values
 * @param {array} fieldsWithDependencies list of fields of dependencies in format ( name, dependsOn }
 */
export const renderDynamicFields = (
  fieldList,
  fieldLookup,
  firstFieldRef,
  formValues = {},
  fieldsWithDependencies = [],
) => {
  return fieldList.map((key, i) => {
    const field = fieldLookup[key];
    if (!field) return null;
    const required = requiredFields.includes(field.name);
    const masterField = fieldsWithDependencies.find(
      (field) => field.name === key,
    )?.dependsOn;
    return (
      <Grid item xs={6} lg={4} key={field.label}>
        <FieldSelector
          {...field}
          type={mapTypes[field.type]}
          required={required}
          {...(masterField && { disabled: !formValues[masterField] })}
          inputProps={{
            ref: i === 0 ? firstFieldRef : undefined,
          }}
          // validate={
          //   field.type === "IPAddress" ? Validation.ipAddress : undefined
          // }
        />
      </Grid>
    );
  });
};

/**
 * Set the section based on the currently active field
 *
 * @param {function} setSection
 * @param {string} activeField
 * @param {array} advancedSection
 */
export const useSetCurrentSection = (
  setSection,
  activeField,
  advancedSection,
) => {
  useDeepCompareEffect(() => {
    if (tenantSubtenantSection.includes(activeField)) {
      setSection(sections.TENANT_AND_SUBTENANT);
    } else if (basicInformationSection.includes(activeField)) {
      setSection(sections.BASIC_INFORMATION);
    } else if (administrativeInformationSection.includes(activeField)) {
      setSection(sections.ADMINISTRATIVE_INFORMATION);
    } else if (managementSection.includes(activeField)) {
      setSection(sections.MANAGEMENT_INFORMATION);
    } else if (advancedSection.includes(activeField)) {
      setSection(sections.ADVANCED_INFORMATION);
    }
  }, [activeField, advancedSection]);
};

/**
 * Generates a click handler which will set the section
 * and focus the correct field.
 *
 * It returns an object with a ref for each section in the form
 * @param {function} setSection
 */
export const useFocusFirstFieldInSection = (setSection) => {
  const sectionsRefs = {
    [sections.TENANT_AND_SUBTENANT]: useRef(null),
    [sections.BASIC_INFORMATION]: useRef(null),
    [sections.ADMINISTRATIVE_INFORMATION]: useRef(null),
    [sections.MANAGEMENT_INFORMATION]: useRef(null),
    [sections.ADVANCED_INFORMATION]: useRef(null),
    [sections.DUPLICATE_EXTRA_INFORMATION]: useRef(null),
  };

  const handleSectionButtonClick = (sectionId) => {
    const sectionRef = sectionsRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.focus();
    }
    setSection(sectionId);
  };

  return [sectionsRefs, handleSectionButtonClick];
};

/**
 * Generates a handler to will return a user to the correct page
 * for the create and edit forms respectively
 *
 * @param {object} history - the browser history object
 * @param {string} url - the current URL
 * @param {boolean} skip - Whether the function should skip routing
 * @param {string} parentRoute - Whether the function should skip routing
 */
export const useReturnToPreviousPage = (
  history,
  url,
  skip,
  parentRoute = "",
) => {
  return useCallback(
    (state) => {
      if (skip) return;
      history.push({ pathname: parentRoute || getParentRoute(url), state });
    },
    [skip, history, url, parentRoute],
  );
};
