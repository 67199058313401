import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { flags as featureFlags } from "msa2-ui/src/services/FeatureFlag";
import { useSnackbar } from "notistack";

import {
  getDashboards,
  deleteDashboardByIndex,
  addNewDashboard,
  getDashboardSetting,
  setDashboardNameByIndex,
  changeDashboardLayout,
  changeDashboardSetting,
  setSelectedDashboard,
  getSelectedDashboard,
  setAllDashboards,
} from "msa2-ui/src/store/settings";

import useDialog from "msa2-ui/src/hooks/useDialog";
import usePopover from "msa2-ui/src/hooks/usePopover";

import { makeStyles, useTheme } from "@material-ui/core";
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  FilledInput,
  FormControl,
  Select,
} from "@material-ui/core";
import {
  Settings,
  DeleteForever as DeleteIcon,
  Add,
  Create as EditIcon,
  MoreVert as More,
} from "@material-ui/icons";
import ResponsiveGridLayout from "msa2-ui/src/components/ResponsiveGridLayout";
import COMPONENTS from "./DashboardComponents";

import DashboardSettings, {
  MSAComponent,
  DeleteComponentIcon,
  SaveButton,
  getComponentStatus,
} from "./DashboardSettings";

const useStyles = makeStyles(({ darkMode, palette, breakpoints, spacing }) => {
  const TITLE_HEIGHT = 35;
  return {
    menuItem: {
      [breakpoints.up("md")]: {
        width: "auto",
        paddingRight: 15,
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
    root: {
      flexGrow: 1,
      alignItems: "stretch",
      height: "100%",
    },
    customButton: {
      display: "flex",
      justifyContent: "center",
    },
    configIcon: {
      top: -6,
      marginTop: -48,
      marginRight: 20,
    },
    gridLayout: {
      marginTop: -12,
    },
    componentWrapper: {
      height: "inherit",
      width: "inherit",
      color: palette.text.primary,
      backgroundColor: palette.background.paper,
      overflowY: "auto",
      boxShadow: darkMode
        ? `1px -1px 12px -1px ${palette.primary.main}`
        : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
    },
    componentWrapperWithTitle: {
      height: `calc(100% - ${TITLE_HEIGHT}px)`,
    },
    componentTitle: {
      paddingBottom: TITLE_HEIGHT,
      height: TITLE_HEIGHT,
    },
    componentTitleEdit: {
      paddingBottom: TITLE_HEIGHT + 15,
      height: TITLE_HEIGHT + 15,
    },
    more: {
      textAlign: "right",
    },
    paper: {
      padding: spacing(2),
      height: "100%",
    },
    buttonComponent: {
      height: "90vh",
    },
    actionButton: {
      margin: 5,
    },
    formControl: {
      margin: spacing(1),
      minWidth: 120,
    },
    selectInput: {
      fontSize: "0.8125rem",
      lineHeight: "1.2rem",
      padding: "0px 38px 2px 10px",
      textTransform: "capitalize",
    },
    select: {
      borderRadius: 16,
      padding: 6,
      marginRight: 10,
      backgroundColor: "transparent",
      border: "1px solid #d5d7da",
      "&:hover, &:focus, &:active": {
        backgroundColor: "transparent",
      },
    },
  };
});

const ExternalLink = ({ url }) => {
  const state = useSelector((state) => state);
  const externalRef = useRef(null);

  useEffect(() => {
    if (externalRef.current) {
      const iframe = externalRef.current.contentWindow;
      // if you run MSA locally, origin will be null
      if (process.env.NODE_ENV === "production") {
        iframe.postMessage(state, url);
      } else {
        iframe.postMessage(state, "*");
      }
    }
  }, [externalRef, state, url]);

  return (
    <iframe
      ref={externalRef}
      src={url}
      width="100%"
      height="100%"
      title={url}
      style={{ border: 0 }}
    />
  );
};

const DashboardPanel = ({ index, params, isEditing, dashboardIndex }) => {
  const {
    key,
    type,
    component,
    extendedListValues,
    url,
    props,
    title,
  } = params;
  const classes = useStyles();
  const t = useBoundedTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [showActionDialog, ActionDialog] = useDialog();
  const [, showAddPopover, AddPopover] = usePopover();
  const { contents: dashboardComponents } = useSelector(
    getDashboardSetting(dashboardIndex),
  );
  const { dependingComponents, canDelete } = getComponentStatus({
    componentName: component,
    dashboardComponents,
  });

  const onChangeSetting = (key, value) => {
    dispatch(
      changeDashboardSetting({
        dashboardIndex: dashboardIndex,
        componentIndex: index,
        key,
        value,
      }),
    );
  };

  return (
    <>
      <ActionDialog>
        <MSAComponent
          index={index}
          component={component}
          dashboardIndex={dashboardIndex}
        />
      </ActionDialog>
      <AddPopover
        options={[
          {
            id: "CHANGE_COMPONENT",
            label: t("Change Component"),
            title: t("Change Component"),
          },
        ]}
        onSelect={(option) => {
          showActionDialog(true, option);
        }}
        notFoundMessage={t("No actions to show")}
      />
      {isEditing ? (
        <Grid container className={classes.componentTitleEdit}>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              label={"Name"}
              fullWidth
              size={"small"}
              value={title}
              onChange={({ target: { value } }) => {
                onChangeSetting("title", value);
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.more}>
            <DeleteComponentIcon
              index={index}
              canDelete={canDelete}
              dependingComponents={dependingComponents}
              dashboardIndex={dashboardIndex}
            />
            <Tooltip title={"Actions"}>
              <IconButton onClick={showAddPopover}>
                <More />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        title && (
          <Typography variant="h4" className={classes.componentTitle}>
            {title}
          </Typography>
        )
      )}

      {(() => {
        if (type === "External Link") {
          return (
            <Paper key={key} className={classes.paper}>
              <ExternalLink url={url} />
            </Paper>
          );
        }

        if (type === "MSA Component") {
          const Component = COMPONENTS[component]?.component;
          const parameters = COMPONENTS[component]?.parameters;

          const mergedProps = {
            ...(parameters &&
              parameters({ state, extendedListValues, featureFlags })),
            ...props,
          };

          const propsAreReady = Object.values(mergedProps).every((prop) =>
            Boolean(prop),
          );

          return (
            <Paper
              className={classnames(classes.componentWrapper, {
                [classes.componentWrapperWithTitle]: title || isEditing,
              })}
            >
              <Paper className={classes.paper}>
                {Component && propsAreReady ? (
                  <Component {...mergedProps} />
                ) : null}
              </Paper>
            </Paper>
          );
        }
      })()}
    </>
  );
};

const AdminDashboard = () => {
  const classes = useStyles();
  const t = useBoundedTranslation();
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const snackbar = useSnackbar();

  const state = useSelector((state) => state);
  const selectedIndex = useSelector(getSelectedDashboard);
  const [dashboardIndex, setDashboardIndex] = useState(
    selectedIndex >= 0 ? selectedIndex : 0,
  );
  const [newDashboardName, setNewDashboardName] = useState("New Dashboard");
  const [showAddDashboardDialog, AddDashboardDialog] = useDialog();
  const { contents: dashboardComponents, layouts, name } = useSelector(
    getDashboardSetting(dashboardIndex),
  );
  const dashboards = useSelector(getDashboards);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [dashboardsBeforeEdit, setDashboardsBeforeEdit] = useState();
  const [dashboardIndexBeforeEdit, setDashboardIndexBeforeEdit] = useState();
  const isEditing = Boolean(dashboardsBeforeEdit);

  const [buttonComponent, setButtonComponent] = useState();
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const breakpoint = Object.entries(breakpoints.values)
    .sort(([, breakpointA], [, breakpointB]) =>
      breakpointA < breakpointB ? 1 : -1,
    )
    .find(([, breakpoint]) => breakpoint <= containerWidth)[0];

  const deleteDashboard = () => {
    const indexAfterDelete =
      dashboardIndex < dashboards.length - 1 ? dashboardIndex : 0;
    dispatch(
      deleteDashboardByIndex({
        dashboardIndex: dashboardIndex,
      }),
    );
    setDashboardIndex(indexAfterDelete);
    dispatch(setSelectedDashboard(indexAfterDelete));
    snackbar.enqueueSnackbar(
      t(`Dashboard removed. Click on Save to delete ${name} permanently`),
    );
  };

  return (
    <>
      {isSettingOpen && (
        <DashboardSettings
          onClose={() => {
            setIsSettingOpen(false);
          }}
          breakpoint={breakpoint}
          dashboardIndex={dashboardIndex}
        />
      )}
      <div id="DASHBOARD_ADMIN" className={classes.root}>
        <Grid
          container
          justifyContent="flex-end"
          alignContent="center"
          className={classes.configIcon}
        >
          {isEditing && (
            <Grid item xs={5}>
              <Typography variant="h4">
                {`${t("Screen Size")}: ${t(breakpoint)}`}
              </Typography>
            </Grid>
          )}
          {dashboardComponents
            .filter((component) => component.style === "Drawer Button")
            .map(({ component, extendedListValues, title, props }, i) => {
              const Component = COMPONENTS[component]?.component;
              const parameters = COMPONENTS[component]?.parameters;
              const mergedProps = {
                ...(parameters &&
                  parameters({ state, extendedListValues, featureFlags })),
                ...props,
              };

              const disabled = !(
                Component &&
                Object.values(mergedProps).every((prop) => Boolean(prop))
              );
              return (
                <Tooltip
                  key={i}
                  title={
                    disabled
                      ? t(
                          "This Component is not fully configured. Check Dashboard Settings.",
                        )
                      : ""
                  }
                >
                  <span className={classes.customButton}>
                    <Button
                      color="primary"
                      disabled={disabled}
                      onClick={() =>
                        setButtonComponent(<Component {...mergedProps} />)
                      }
                    >
                      {title}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          {isEditing && (
            <Grid
              item
              xs={5}
              container
              alignItems="center"
              md="auto"
              className={classes.menuItem}
            >
              <TextField
                variant="outlined"
                onChange={({ target: { value } }) => {
                  dispatch(
                    setDashboardNameByIndex({
                      dashboardIndex: dashboardIndex,
                      value: value,
                    }),
                  );
                }}
                id="ADMIN_EDIT_DASHBOARD_NAME"
                value={name}
                label={t("Name")}
                fullWidth
                size={"small"}
              />
            </Grid>
          )}
          {isEditing && (
            <Button
              id="DASHBOARD_ADMIN_CANCEL"
              aria-label={t("Cancel")}
              variant="text"
              size="small"
              color="default"
              className={classes.actionButton}
              onClick={() => {
                setDashboardIndex(dashboardIndexBeforeEdit);
                dispatch(setSelectedDashboard(dashboardIndexBeforeEdit));
                dispatch(setAllDashboards(dashboardsBeforeEdit));
                setDashboardsBeforeEdit();
              }}
            >
              {t("Cancel")}
            </Button>
          )}
          {isEditing && dashboards.length !== 1 && (
            <Tooltip title={"Delete Dashboard"}>
              <IconButton
                id="DASHBOARD_ADMIN_DELETE"
                aria-label={t("Delete Dashboard")}
                onClick={deleteDashboard}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          )}
          {!isEditing && (
            <Grid
              item
              xs={5}
              container
              alignItems="center"
              md="auto"
              className={classes.menuItem}
            >
              <Typography
                id="ADMIN_DASHBOARD_SELECT_TEXT"
                className={classes.filterLabel}
              >
                {t("Select Dashboard") + ":"}
              </Typography>
              <FormControl
                id="ADMIN_DASHBOARD_SELECT"
                variant="outlined"
                className={classes.formControl}
              >
                <Select
                  native
                  onChange={({ target: { value } }) => {
                    setDashboardIndex(value);
                    dispatch(setSelectedDashboard(value));
                  }}
                  value={dashboardIndex}
                  className={classes.select}
                  classes={{
                    select: classes.selectInput,
                  }}
                  input={<FilledInput disableUnderline />}
                >
                  {dashboards.map(({ name = "Default Dashboard" }, index) => (
                    <option key={index} value={index}>
                      {t(name)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {isEditing && (
            <Tooltip title={"Add Dashboard"}>
              <IconButton
                id="DASHBOARD_ADMIN_ADD"
                aria-label={t("Add Dashboard")}
                onClick={showAddDashboardDialog}
              >
                <Add color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {!isEditing && (
            <Tooltip title={"Edit Dashboard"}>
              <IconButton
                id="DASHBOARD_ADMIN_EDIT"
                aria-label={t("Edit Dashboard")}
                onClick={() => {
                  setDashboardsBeforeEdit(dashboards);
                  setDashboardIndexBeforeEdit(dashboardIndex);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {isEditing && (
            <SaveButton
              onSuccess={() => {
                setDashboardsBeforeEdit();
              }}
            />
          )}
          <Tooltip title={"Dashboard Settings"}>
            <IconButton
              id="DASHBOARD_ADMIN_SETTINGS"
              onClick={() => {
                setIsSettingOpen(true);
              }}
            >
              <Settings />
            </IconButton>
          </Tooltip>
        </Grid>

        <ResponsiveGridLayout
          className={classes.gridLayout}
          layouts={layouts}
          rowHeight={76}
          margin={[15, 15]}
          onLayoutChange={(_, layouts) => {
            if (isEditing) {
              dispatch(
                changeDashboardLayout({
                  dashboardIndex: dashboardIndex,
                  layouts: layouts,
                }),
              );
            }
          }}
          isDraggable={isEditing}
          isResizable={isEditing}
          onWidthChange={(width, _, cols) => {
            setContainerWidth(width);
          }}
        >
          {dashboardComponents
            .filter((component) => component.style === "Dashboard Panel")
            .map((params, i) => (
              <Grid item key={params.key}>
                <DashboardPanel
                  index={i}
                  params={params}
                  isEditing={isEditing}
                  dashboardIndex={dashboardIndex}
                />
              </Grid>
            ))}
        </ResponsiveGridLayout>
        <AddDashboardDialog
          title={t("Add Dashboard")}
          maxWidth={"sm"}
          onExec={() => {
            dispatch(
              addNewDashboard({
                dashboardName: newDashboardName,
              }),
            );
            setDashboardIndex(dashboards.length);
            dispatch(setSelectedDashboard(dashboards.length));
            snackbar.enqueueSnackbar(
              t(
                `Dashboard ${newDashboardName} added. Click on Save to add this permanently`,
              ),
            );
          }}
        >
          <Grid
            item
            xs={5}
            container
            alignItems="center"
            md="auto"
            className={classes.menuItem}
          >
            <TextField
              variant="outlined"
              onChange={({ target: { value } }) => setNewDashboardName(value)}
              id="ADMIN_ADD_DASHBOARD_TEXT"
              label={t("Dashboard Name")}
              fullWidth
              size={"small"}
            />
          </Grid>
        </AddDashboardDialog>
      </div>
      {buttonComponent && (
        <Drawer anchor="bottom" open onClose={() => setButtonComponent()}>
          <Paper className={classnames(classes.paper, classes.buttonComponent)}>
            {buttonComponent}
          </Paper>
        </Drawer>
      )}
    </>
  );
};

export default AdminDashboard;
