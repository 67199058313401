import { makeStyles } from "@material-ui/core";

const commonStyles = ({ darkMode, typography, palette, colors, spacing }) => ({
  mainWrapper: {
    marginTop: 20,
    borderRadius: 8,
    height: "100%",
    minHeight: "100vh",
  },
  paper: {
    padding: spacing(2),
    color: palette.text.secondary,
    backgroundColor: palette.background.paper,
    boxShadow: darkMode
      ? `1px -1px 12px -1px ${palette.primary.main}`
      : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
    minHeight: "100vh",
  },
  overflowEllipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  overflowAnywhere: {
    overflowWrap: "anywhere",
  },

  // Containers / Layout
  commonFlexStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  commonFlexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  commonFlexStartRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  commonFlexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  commonFlexVerticalStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  commonFlexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  commonFlexBaseline: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",
  },
  commonMarginRight: {
    marginRight: "10px",
  },
  commonMainWrapper: {
    marginTop: 20,
    borderRadius: 8,
    height: "100%",
  },
  commonTitleWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingBottom: 10,
  },
  commonPaper: {
    padding: 16,
    color: palette.text.secondary,
    backgroundColor: palette.background.paper,
    boxShadow: darkMode
      ? `1px -1px 12px -1px ${palette.primary.main}`
      : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
  },
  commonPaperNoPadding: {
    padding: 0,
    backgroundColor: palette.background.paper,
    boxShadow: darkMode
      ? `1px -1px 12px -1px ${palette.primary.main}`
      : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
  },
  commonLoaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
  commonLoaderWrapperTable: {
    height: 200,
    textAlign: "center",
  },
  commonItemWrapperNoAlign: {
    display: "flex",
    padding: "0px 10px 10px 10px",
  },
  commonItemWrapper: {
    display: "flex",
    alignItems: "center",
  },
  commonHoverArea: {
    backgroundColor: "rgba(128, 162, 217, 0.05)",
    outline: palette.primary.main + " solid 2px",
  },
  tableRowValue: {
    color: darkMode ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.26)",
  },
  // Table row
  commonTableRowSelect: {
    backgroundColor: "rgba(128, 162, 217, 0.05)",
    outline: palette.primary.main + " solid 2px",
  },
  commonTableRowHighlight: {
    "&:hover, &:active, &:focus": {
      backgroundColor: "rgba(128, 162, 217, 0.05)",
      outline: palette.primary.main + " solid 2px",
      boxShadow: darkMode
        ? `inset 0px 0px 8px 2px ${palette.shadow.main}`
        : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
    },
  },
  commonDescription: {
    display: "inline-block",
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightLight,
    lineHeight: "1.23",
    letterSpacing: 0.3,
    color: palette.text.secondary,
  },
  commonWarning: {
    color: palette.warning.main,
  },
  commonItemValue: {
    fontSize: "0.8125rem",
    letterSpacing: 0.3,
    color: palette.text.primary,
    width: "inherit",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  // Labels
  commonLabel: {
    fontSize: "1rem",
    fontWeight: typography.fontWeightLight,
    lineHeight: "normal",
    letterSpacing: 0.3,
    color: palette.text.secondary,
    marginRight: 10,
  },
  commonCreateButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  commonInlineBlock: {
    display: "inline-block",
  },
  commonRemove: {
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightMedium,
    padding: "0px 3px",
    color: palette.error.main,
  },
  commonNoTextDecoration: {
    textDecoration: "none",
  },
  commonTextButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  commonTwoLineWrapper: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "16px",
    maxHeight: "32px",
    overflowWrap: "anywhere",
  },
  // Tabs
  commonTab: {
    minWidth: "auto",
    fontSize: "1rem",
    fontWeight: typography.fontWeightLight,
    letterSpacing: 0.3,
    marginRight: 20,
    padding: "6px 10px",
  },
  commonTabWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  commonTabIcon: {
    marginRight: 5,
  },
  commonTabCount: {
    paddingLeft: 5,
    color: palette.text.secondary,
  },
  commonTabSelected: {
    fontSize: "1rem",
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.3,
  },
  commonVerticalLine: {
    margin: "0px 10px",
    width: 1,
    height: 16,
    opacity: "0.2",
    backgroundColor: darkMode ? palette.text.secondary : colors.grey,
  },
  // Drawers
  commonDrawerHeader: {
    paddingTop: spacing(2),
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    color: palette.text.secondary,
    backgroundColor: palette.background.default,
  },
  commonDrawerBody: {
    padding: spacing(2),
    color: palette.text.secondary,
    backgroundColor: "transparent",
  },
  // Dialogs
  commonDialogPaper: {
    boxShadow: darkMode
      ? "1px -1px 12px -1px #0059a7"
      : "0 6px 16px 4px rgba(81, 97, 133, 0.13), 0 3px 8px 0 rgba(0, 0, 0, 0.15)",
  },
  commonDialogPaperNoOverflow: {
    boxShadow: "1px -1px 12px -1px #0059a7",
    overflow: "initial",
  },
  commonDialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: darkMode ? "transparent" : "rgba(191, 201, 217, 0.4)",
    padding: 6,
    fontSize: "1.25rem",
    textTransform: "uppercase",
  },
  commonDialogHeaderIcon: {
    display: "flex",
    marginLeft: 6,
  },
  commonDialogHeaderTitle: {
    fontSize: "0.9375rem",
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.5,
    marginLeft: 10,
    color: palette.text.primary,
    flex: 2,
  },
  commonDialogHeaderCloseButton: {
    alignSelf: "flex-end",
  },
  commonDialogContent: {
    padding: "20px 50px",
  },
  commonDialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 20,
  },
  // Tables
  commonTableHeadRow: {
    height: "30px",
    backgroundColor: darkMode ? "transparent" : colors.greyLight2,
    fontSize: "0.75rem",
    color: colors.blueDark1,
  },
  commonTableRow: {
    height: 64,
  },
  commonTableCell: {
    padding: "5px 20px",
  },
  commonTableCellHead: {
    lineHeight: "1.1rem",
  },
  commonTableSortLabel: {
    color: palette.text.secondary,
    fontSize: "0.85rem",
  },
  commonTableSortLabelActive: {
    color: palette.text.primary,
  },
  commonTableSortLabelIcon: {
    fontSize: "1.1rem",
  },
  commonTableActionCell: {
    minWidth: 220,
  },
  commonTableCellClickable: {
    padding: "4px 10px",
    cursor: "pointer",
    width: "fit-content",
  },
  commonTablePrimary: {
    fontSize: "1rem",
    margin: "0 8px",
    /* maxWidth: "250px", */
  },
  commonTableSecondary: {
    display: "inline-block",
    margin: "0 3px",
    color: darkMode ? palette.text.secondary : "#666",
    fontWeight: 200,
    fontSize: "0.8rem",
  },
  commonTableInstance: {
    textDecoration: "none",
  },
  commonTableInstancesNumber: {
    fontWeight: 500,
    letterSpacing: 0.25,
    color: palette.primary.main,
  },
  commonTableChip: {
    height: "auto",
    marginLeft: 5,
    fontSize: 10,
    fontWeight: typography.fontWeightMedium,
    color: palette.text.primary,
    opacity: 0.8,
    borderRadius: 3,
    backgroundColor: darkMode ? "transparent" : colors.blueLight,
    textTransform: "uppercase",
  },
  commonTableAvatar: {
    margin: "0px 5px;",
    backgroundColor: darkMode ? "transparent" : "#e7eaee",
    color: darkMode ? palette.text.secondary : palette.primary.main,
  },
  commonTableCellDefault: {
    padding: "4px 10px",
  },
  commonTableLink: {
    textDecoration: "none",
    color: "currentColor",
    "&:active, &:visited": {
      color: "currentColor",
    },
  },
  // Links
  commonLink: {
    cursor: "pointer",
    fontSize: 13,
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
  },
  commonSpacer: {
    margin: "0px 5px",
  },
  categoryLabel: {
    height: "12px",
    opacity: 0.8,
    fontSize: "0.7rem",
    fontWeight: typography.fontWeightMedium,
    lineHeight: "normal",
    letterSpacing: 0.3,
    paddingLeft: "5px",
    paddingRight: "5px",
    marginLeft: "5px",
    background: darkMode ? palette.secondary.main : colors.blueLight,
  },
  commonRemoveLabel: {
    fontSize: "0.8rem",
    fontWeight: typography.fontWeightMedium,
    color: palette.error.main,
  },
  // Tags
  commonTags: {
    height: "auto",
    padding: "0 4px",
    borderRadius: 3,
    margin: "0 2px",
    fontSize: "0.625rem",
    fontWeight: typography.fontWeightMedium,
    color: palette.text.primary,
    textTransform: "uppercase",
    letterSpacing: 0.3,
    "& span": {
      padding: "3px 4px 2px 4px",
    },
  },
  // Tags - Managed Entities
  commonTagPhysical: {
    border: `solid 1px ${
      darkMode ? palette.text.secondary : "rgba(191, 201, 217, 0.6)"
    }`,
  },
  commonTagPublic: {
    border: "1px dashed" + colors.blueLight,
  },
  commonTagPrivate: {
    border: "solid 1px #32363D",
  },
  commonTagDefault: {
    backgroundColor: colors.blueLight,
  },
  // Tags - System
  commonTagSystem1: {
    backgroundColor: darkMode ? "transparent" : colors.blueLight4,
    border: darkMode ? `1px solid ${palette.text.secondary}` : "none",
  },
  commonTagSystem2: {
    backgroundColor: darkMode ? "#26bd6f" : "#B6E4CE",
  },
  commonTagSystem3: {
    backgroundColor: "#EDB9B3",
  },
  // Tags - Alerts
  commonTagAlert: {
    backgroundColor: "#F5F6F9",
  },
  commonTagCritical: {
    backgroundColor: "#F46527",
  },
  commonTagError: {
    backgroundColor: "#F5C458",
  },
  commonTagWarning: {
    backgroundColor: "#F5DCA3",
  },
  commonTagNotice: {
    backgroundColor: "#BACBE7",
  },
  commonTagInformation: {
    backgroundColor: colors.blueLight2,
  },
  // Tags - Process
  commonTagNotStarted: {
    backgroundColor: "#DFE3EC",
  },
  commonTagSuccess: {
    backgroundColor: "#A1DEC1",
  },
  commonTagFailed: {
    backgroundColor: "#EBA69C",
  },
  commonTagInProgress: {
    backgroundColor: "#BBC4D9",
  },
  commonTagLabel: {
    display: "inline-block",
    maxWidth: 120,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  commonSearchInput: {
    fontSize: "0.8125rem",
    fontWeight: "normal",
  },
  // Buttons
  commonBtn: {
    borderRadius: 22,
    fontSize: "0.875rem",
    lineHeight: "1.4375rem",
    letterSpacing: 0.3,
    fontWeight: typography.fontWeightMedium,
    border: "2px solid transparent",
    marginLeft: 10,
    minHeight: 46,
    padding: "5px 14px",
  },
  commonBtnPrimary: {
    color: colors.white,
  },
  commonBtnSecondary: {
    background: "transparent",
  },
  commonBtnDeveloper: {
    background: colors.green,
    color: colors.white,
    "&:hover": {
      background: colors.green2,
    },
    "&:active": {
      background: colors.green3,
    },
    "&:focus": {
      background: colors.green3,
    },
  },
  commonBtnLarge: {
    fontSize: "1rem",
    lineHeight: "1.4375rem",
    padding: "9px 14px",
  },
  commonBtnIcon: {
    display: "inline-block",
    fontSize: "1.125rem",
    marginRight: 10,
  },
  commonCopyToClipboard: {
    cursor: "pointer",
    // !important is a workaround for the issue where the material ui style overwrites custom style in production build
    width: "13px !important",
    height: "13px !important",
    marginLeft: 5,
  },
  commonPopoverContainer: {
    position: "relative",
    top: 7,
    minWidth: 300,
    maxWidth: "80vw",
    flexGrow: 1,
    boxShadow:
      "0 6px 16px 4px rgba(81, 97, 133, 0.13), 0 3px 8px 0 rgba(0, 0, 0, 0.15)",
    border: "1px solid" + palette.text.secondary,
    borderRadius: "5px 5px 0 0",
    backgroundColor: colors.white,
    "&:after, &:before": {
      bottom: "100%",
      left: "50%",
      border: "solid transparent",
      content: '" "',
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
    },
    "&:after": {
      borderColor: "rgba(255, 255, 255, 0)",
      borderBottomColor: colors.white,
      borderWidth: 6,
      marginLeft: -6,
    },
    "&:before": {
      borderColor: "rgba(191, 201, 217, 0)",
      borderBottomColor: palette.text.secondary,
      borderWidth: 7,
      marginLeft: -7,
    },
  },
  commonPopoverPaper: {
    boxShadow: "1px -1px 12px -1px #0059a7",
  },
});

export const snackbarVariantStyles = ({ palette }) => ({
  default: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    minWidth: "auto",
  },
  success: {
    backgroundColor: palette.success.main,
    minWidth: "auto",
  },
  error: {
    backgroundColor: palette.error.main,
    minWidth: "auto",
  },
  warning: {
    backgroundColor: palette.warning.main,
    minWidth: "auto",
  },
});

export const useCommonStyles = makeStyles(commonStyles);

export default commonStyles;
