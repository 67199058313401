import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import chunk from "lodash/chunk";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import {
  deleteScheduledWorkflow,
  updateScheduledWorkflow,
} from "msa2-ui/src/api/workflow";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getToken } from "msa2-ui/src/store/auth";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { workflowStatus } from "msa2-ui/src/Constants";
import { instanceTabs } from "./constants";

import Variable from "msa2-ui/src/services/Variable";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";

import { formatDateOrString } from "msa2-ui/src/utils/date";

import AutomationDetailsScheduleDialog from "msa2-ui/src/components/schedule/ScheduleDialog";
import {
  monthStringToMonthObject,
  weekdayStringToDayObject,
} from "msa2-ui/src/components/schedule/utils";

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  instanceWrapper: {
    padding: spacing(2),
    "&:hover #DRAWER_ACTIONS": {
      display: "block",
    },
    "&:hover #COPY_ICON": {
      display: "inline",
    },
  },
  instanceName: {
    minHeight: 40,
    padding: 2,
    fontWeight: typography.fontWeightMedium,
    marginRight: 15,
  },
  workflowStatusIcon: {
    marginTop: 5,
  },
  instanceDeleteIcon: {
    width: 16,
    height: 16,
  },
  lastProcessRunnerWrapper: {
    margin: "8px 0px",
  },
  taskName: {
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.25,
    marginTop: 2,
    marginBottom: 5,
  },
  taskComment: {
    maxWidth: "inherit",
  },
  drawerActions: {
    display: "none",
  },
  runProcessBtn: {
    padding: "2px 12px",
    minWidth: 110,
    fontSize: 13,
    fontWeight: typography.fontWeightMedium,
    backgroundColor: palette.primary.main,
    margin: 4,
  },
  moreActionsWrapper: {
    cursor: "pointer",
    width: "fit-content",
  },
  moreActions: {
    fontSize: 13,
    letterSpacing: 0.3,
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
    cursor: "pointer",
    margin: 4,
  },
  processTypeIcon: {
    marginRight: 8,
  },
  variableWrapper: {
    marginBottom: 5,
  },
  variableName: {
    lineHeight: "1.5",
    marginRight: 8,
  },
  variablesWrapper: {
    backgroundColor: palette.background.paper,
    padding: "10px",
  },
  paper: {
    overflowY: "inherit",
    overflowX: "inherit",
  },
}));

const ScheduledWorkflowInstance = ({
  workflowDefinition = {},
  instanceData = {},
  reloadScheduledWorkflows,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const token = useSelector(getToken);
  const {
    id,
    status,
    instanceId,
    processDisplayName,
    processName,
    processType,
    lastExecutionDate,
    nextExecutionDate,
    beginDate,
    endDate,
    weekDay,
    month,
    period,
    periodUnit,
    variablesJson,
  } = instanceData;

  const ProcessStatusIcon = workflowStatus.find(
    (wfStatus) => wfStatus.id === status,
  ).icon;

  const openDetailsDialog = () => {
    setShowDetailsDialog(true);
  };

  const closeDetailsDialog = () => {
    setShowDetailsDialog(false);
  };

  const openEditDialog = () => {
    setShowEditDialog(true);
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
  };

  const renderParams = () => {
    const emptyValue = "-";
    const columns = 1;
    const paramsLimit = 4;
    const paramsPerColumn = paramsLimit / columns;

    const params = [
      {
        title: t("Begin date"),
        value: formatDateOrString(beginDate, "MMM dd, yyyy h:mm:ssaa OOOO"),
      },
      {
        title: t("End date"),
        value: formatDateOrString(endDate, "MMM dd, yyyy h:mm:ssaa OOOO"),
      },
      {
        title: t("Last execution date"),
        value: formatDateOrString(
          lastExecutionDate,
          "MMM dd, yyyy h:mm:ssaa OOOO",
        ),
      },
      {
        title: t("Next execution date"),
        value: formatDateOrString(
          nextExecutionDate,
          "MMM dd, yyyy h:mm:ssaa OOOO",
        ),
      },
    ];

    const sections = chunk(params.slice(0, paramsLimit), paramsPerColumn);

    return sections.map((section, sectionIndex) => (
      <Grid container item xs={12 / columns} key={sectionIndex}>
        {section.map((param, paramIndex) => (
          <Grid item xs={12} key={paramIndex}>
            <Typography
              variant="subtitle1"
              className={commonClasses.commonDescription}
            >
              <span className={classes.variableName}>{`${param.title}: `}</span>
              <span className={commonClasses.commonItemValue}>
                {param.value ?? emptyValue}
              </span>
            </Typography>
          </Grid>
        ))}
      </Grid>
    ));
  };

  const renderVariables = () => {
    const columns = 2;
    const variablesLimit = 8;
    const variablesPerColumn = variablesLimit / columns;

    const variables = [];

    for (const [key] of Object.entries(variablesJson)) {
      const value =
        get(workflowDefinition, "variables.variable", []).find(
          ({ name }) => Variable.removePrefix(name) === key,
        ) || {};

      variables.push({
        title: key,
        value: value,
      });
    }

    const sections = chunk(
      variables.slice(0, variablesLimit),
      variablesPerColumn,
    );

    return sections.map((section, sectionIndex) => (
      <Grid container item xs={12 / columns} key={sectionIndex}>
        {section.map((variable, variableIndex) => (
          <Grid
            item
            xs={12}
            key={variableIndex}
            className={classnames(
              commonClasses.commonFlexStart,
              classes.variableWrapper,
            )}
          >
            <Typography
              variant="subtitle1"
              className={classnames(
                commonClasses.commonDescription,
                classes.variableName,
              )}
            >
              {variable.title}:
            </Typography>
            <ExecutionVariableField
              id={variable.title}
              variable={variable.value}
              data={variablesJson}
            />
          </Grid>
        ))}
      </Grid>
    ));
  };

  const entry = {
    periodicity: periodUnit,
    periodicityValue: period,
    startDate: beginDate,
    endDate,
    weekDay: weekdayStringToDayObject(weekDay),
    month: monthStringToMonthObject(month),
  };

  const handleDeletion = async () => {
    const [error] = await deleteScheduledWorkflow({ token, schedId: id });
    let variant = "success";
    let message = t("Scheduled Process has been deleted");
    if (error) {
      variant = "error";
      message = error.getMessage(t("Unable to delete this Scheduled Process"));
    }

    reloadScheduledWorkflows();
    enqueueSnackbar(message, { variant });
  };

  const handleSave = async (entry) => {
    const [error] = await updateScheduledWorkflow({
      token,
      schedId: id,
      body: entry,
    });

    let variant = "success";
    let message = t("Scheduled Process has been updated");
    if (error) {
      variant = "error";
      message = error.getMessage(t("Unable to update this Scheduled Process"));
    }

    reloadScheduledWorkflows();
    enqueueSnackbar(message, { variant });
  };

  return (
    <>
      {showDetailsDialog && (
        <AutomationDetailsScheduleDialog
          open
          onClose={closeDetailsDialog}
          predefinedEntry={entry}
          readOnly
        />
      )}
      {showEditDialog && (
        <AutomationDetailsScheduleDialog
          open
          onClose={closeEditDialog}
          predefinedEntry={entry}
          editMode={true}
          onSave={(entry) => handleSave(entry)}
        />
      )}
      <Divider variant="fullWidth" />
      <Grid
        container
        className={classnames(
          commonClasses.commonTableRowHighlight,
          classes.instanceWrapper,
        )}
      >
        <Grid item xs={12}>
          <Grid container className={classes.commonTitleWrapper}>
            <Typography variant="h3" className={classes.instanceName}>
              {id}
            </Typography>
            <Grid
              item
              className={classnames([
                commonClasses.commonFlexCenter,
                classes.drawerActions,
              ])}
              id="DRAWER_ACTIONS"
            >
              {[instanceTabs[0]].map((func, i) => (
                <Tooltip key={i} title={`${func.name}`}>
                  <IconButton
                    id={`AUTOMATION_DETAILS_BTN_FUNCTION_${id}_${func.id}`}
                    key={func.id}
                    onClick={() => openDetailsDialog()}
                  >
                    <func.icon />
                  </IconButton>
                </Tooltip>
              ))}
              <DelegationProfiles
                type={delegationProfileTypes.WORKFLOWS}
                action="schedule.modify"
              >
                <Tooltip title={t("Edit Scheduled Process")}>
                  <IconButton
                    id={`AUTOMATION_EDIT_SCHEDULED_BTN_FUNCTION_${id}`}
                    onClick={() => openEditDialog()}
                  >
                    <EditOutlined
                      className={classes.instanceDeleteIcon}
                      color="primary"
                    />
                  </IconButton>
                </Tooltip>
              </DelegationProfiles>
              <DelegationProfiles
                type={delegationProfileTypes.WORKFLOWS}
                action="schedule.delete"
              >
                <Tooltip title={t("Delete Scheduled Process")}>
                  <IconButton
                    id={`AUTOMATION_DELETE_SCHEDULED_BTN_FUNCTION_${id}`}
                    onClick={showDeleteDialog}
                    className={classes.deleteIconButton}
                  >
                    <DeleteOutlined
                      className={classes.instanceDeleteIcon}
                      color="error"
                    />
                  </IconButton>
                </Tooltip>
              </DelegationProfiles>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={3} lg={3}>
          <Grid item xs={1}>
            <ProcessStatusIcon className={classes.workflowStatusIcon} />
          </Grid>
          <Grid item xs={11}>
            <Tooltip title={processName}>
              <Typography
                variant="body1"
                className={classnames(
                  classes.overflowEllipsis,
                  classes.taskName,
                )}
              >
                {processDisplayName}
              </Typography>
            </Tooltip>
            <Typography
              variant="h3"
              className={classnames(
                commonClasses.commonDescription,
                commonClasses.commonTwoLineWrapper,
                classes.taskComment,
              )}
            >
              {processType}
            </Typography>
            {Boolean(instanceId) && (
              <Typography
                variant="body1"
                className={classnames(classes.overflowEllipsis)}
              >
                <span className={classes.variableName}>{`${t(
                  "Instance ID",
                )}:`}</span>
                <span className={commonClasses.commonItemValue}>
                  {instanceId}
                </span>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={3} lg={3}>
          {renderParams()}
        </Grid>
        <Grid className={classes.variablesWrapper} container item xs={6} lg={6}>
          {renderVariables()}
        </Grid>
      </Grid>
      <DeleteDialog
        title={t("Delete x?", { x: t("Scheduled") })}
        content={t("Are you sure you want to delete this Scheduled Process ?")}
        onExec={handleDeletion}
      />
    </>
  );
};

export default ScheduledWorkflowInstance;
