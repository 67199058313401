import React, { memo, useCallback, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import flow from "lodash/flow";

import useApi from "msa2-ui/src/hooks/useApi";
import { listAllLabels } from "msa2-ui/src/api/managedEntity";

import {
  prepareData,
  applyDeviceTypeFilter,
  applyCategoryFilter,
  prepareGraphData,
  prepareNodesToSave,
  prepareNodeCategoriesToSave,
} from "./utils";
import TopologyView from "./TopologyView";
import Graph from "./graph/Graph";

const Topology = ({
  isPending,
  data,
  onSaveNodes,
  onUpdateTopology,
  workflowInstances,
  serviceId,
  onTopologyChange,
  reloadWorkflowInstances,
}) => {
  const graphRef = useRef(null);
  const { t } = useTranslation();

  const [deviceTypesFilterArray, setDeviceTypesFilterArray] = useState([]);
  const [categoriesFilterArray, setCategoriesFilterArray] = useState([]);

  const [, , categories = []] = useApi(listAllLabels, {
    category: listAllLabels.categories.MANAGED_ENTITY,
  });

  const preparedData = useMemo(() => prepareData(data), [data]);

  const filteredData = useMemo(
    () =>
      flow([
        applyDeviceTypeFilter(deviceTypesFilterArray),
        applyCategoryFilter(categoriesFilterArray),
      ])(preparedData.nodes),
    [preparedData.nodes, deviceTypesFilterArray, categoriesFilterArray],
  );

  const graphData = useMemo(() => prepareGraphData(filteredData), [
    filteredData,
  ]);

  const saveHandler = useCallback(() => {
    if (!graphRef.current || !onSaveNodes) {
      return;
    }

    onSaveNodes(prepareNodesToSave(graphRef.current.getNodesData()));
  }, [onSaveNodes]);

  const addCategoryHandler = useCallback(
    (node, categories) => {
      if (!onSaveNodes) return;

      const nodeToSave = prepareNodeCategoriesToSave(node, categories);

      setCategoriesFilterArray([]);
      onSaveNodes([nodeToSave], true);
    },
    [onSaveNodes],
  );

  const zoomHandler = useCallback((direction) => {
    if (!graphRef.current) {
      return;
    }

    if (direction === Graph.ZOOM_IN) {
      graphRef.current.zoomGraphIn();
    } else if (direction === Graph.ZOOM_OUT) {
      graphRef.current.zoomGraphOut();
    }
  }, []);

  return (
    <TopologyView
      isPending={isPending}
      t={t}
      graphRef={graphRef}
      categories={categories}
      data={graphData}
      categoriesFilterArray={categoriesFilterArray}
      setCategoriesFilterArray={setCategoriesFilterArray}
      deviceTypesFilterArray={deviceTypesFilterArray}
      setDeviceTypesFilterArray={setDeviceTypesFilterArray}
      onAddCategory={addCategoryHandler}
      onSaveNodes={saveHandler}
      onUpdate={onUpdateTopology}
      onZoom={zoomHandler}
      workflowInstances={workflowInstances}
      serviceId={serviceId}
      onTopologyChange={onTopologyChange}
      reloadWorkflowInstances={reloadWorkflowInstances}
    />
  );
};

Topology.propTypes = {
  isPending: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      device_nature: PropTypes.string,
      subtype: PropTypes.string.isRequired,
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      links: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  onSaveNodes: PropTypes.func.isRequired,
  onUpdateTopology: PropTypes.func.isRequired,
  workflowInstances: PropTypes.array.isRequired,
  serviceId: PropTypes.any,
  onTopologyChange: PropTypes.func.isRequired,
  reloadWorkflowInstances: PropTypes.func.isRequired,
};

export default memo(Topology);
