import noop from "lodash/noop";
import { useState, useRef } from "react";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import omit from "lodash/omit";

/**
 * A hook to handle making API calls and automatically calling again when its inputs
 * change and/or at the specified auto refresh interval
 *
 * It also returns the loading state, error, response, meta information and a reload
 * callback
 *
 * @param {function} apiCall the API call to make
 * @param {object} params an object with the parameters to pass to the API call
 * @param {boolean} wait an expression that will prevent the API call from firing while true
 * @param {number} autoRefreshInterval the interval in milliseconds to refresh the API call
 * @returns {array} the outputs of of the API call in the following order: the loading state,
 * the error state, the successful response, the meta information of the request and a callback
 * to make the call again with the same parameters
 */
const useApi = ({
  apiCall,
  params = {},
  wait = false,
  autoRefreshInterval = 0,
  token,
}) => {
  const makeCall = useRef(noop);
  const [response, setResponse] = useState([undefined, undefined, undefined]);
  const [loading, setLoading] = useState(false);

  /**
   * We never want to fire a new a API call based on the transform
   * functions for the response so we omit the transforms from the
   * dependency check
   */
  const dependencyParams = omit(params, "transforms");

  useDeepCompareEffect(() => {
    /**
     * Sometimes we might not have all the data we need to make a call
     * so we can set wait to true to prevent any requests being made before
     * we're ready
     */
    if (wait) return;

    let mounted = true;
    makeCall.current = async () => {
      setLoading(true);
      const data = await apiCall({ ...params, token });
      if (mounted) {
        setResponse(data);
        setLoading(false);
      }
    };
    makeCall.current();

    let interval;
    if (autoRefreshInterval) {
      interval = setInterval(makeCall.current, autoRefreshInterval);
    }

    return () => {
      mounted = false;
      makeCall.current = noop;
      clearInterval(interval);
    };
  }, [apiCall, dependencyParams, wait, autoRefreshInterval]);

  return [loading, ...response, makeCall.current];
};

export default useApi;
