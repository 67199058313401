import React from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  CircularProgress,
  Typography,
  Grid,
} from "@material-ui/core";
import Dialog from "msa2-ui/src/components/Dialog";
import useApi from "msa2-ui/src/hooks/useApi";
import { attackDetails } from "msa2-ui/src/api/alarms";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles(({ colors }) => ({
  textAlign: {
    textAlign: "left",
  },
}));

const AttackDetails = ({ onClose, msgId, deviceId }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, error, attackData] = useApi(
    attackDetails,
    {
      msgId: msgId,
      deviceId: deviceId,
    },
    !msgId,
  );

  return (
    <div data-testid="attack-details-component">
      <Dialog
        title={t("Attack Details")}
        onClose={onClose}
        maxWidth={"md"}
        open
        id={t("ATTACK_DETAILS")}
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography variant="body2" color="textSecondary">
            {error?.message || t("GenericErrorMessage")}
          </Typography>
        ) : isEmpty(attackData?.response) ? (
          <Typography>{t("No Data Found")}</Typography>
        ) : null}

        {!isEmpty(attackData?.response) && (
          <>
            {Object.entries(attackData?.response).map(([key, value], index) => (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                key={`ATTACK_DATA_${index}`}
                style={{ padding: "3px" }}
              >
                <Grid item xs={4}>
                  <Typography className={classes.textAlign}>
                    <strong>{key.toUpperCase()}</strong> :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.textAlign}>
                    {value.toString()}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AttackDetails;
