import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import get from "lodash/get";
import intersection from "lodash/intersection";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";

import { filter } from "msa2-ui/src/utils/filter";

import {
  Grid,
  Button,
  Checkbox,
  Input,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { ReactComponent as Search } from "msa2-ui/src/assets/icons/search.svg";
import { ReactComponent as IconDoubleRight } from "msa2-ui/src/assets/icons/arrow/arrowDoubleRightWhite.svg";
import { ReactComponent as IconRight } from "msa2-ui/src/assets/icons/arrow/arrowRightWhite.svg";
import { ReactComponent as IconLeft } from "msa2-ui/src/assets/icons/arrow/arrowLeftWhite.svg";
import { ReactComponent as IconDoubleLeft } from "msa2-ui/src/assets/icons/arrow/arrowDoubleLeftWhite.svg";

const useStyles = makeStyles(({ darkMode, palette }) => ({
  contentWrapper: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  listItems: {
    color: palette.text.primary,
  },
  description: {
    marginLeft: 11,
    marginBottom: 11,
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
    backgroundColor: darkMode ? palette.background.paper : "#f5f5f5",
    marginTop: 13,
  },
  searchIcon: {
    marginLeft: 13,
  },
  searchField: {
    marginLeft: 12,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cursorDefault: {
    cursor: "default",
  },
  button: {
    margin: 2,
  },
  spacer: {
    margin: 14,
  },
}));

const AttachmentBoard = (props) => {
  const {
    t,
    allItems,
    attachedItemIds,
    noDataMessage = "",
    idKey,
    displayNameKey,
    displayNameTooltipKey,
    checkboxTooltipText,
    unattachedTitle = "",
    attachedTitle = "",
    searchPlaceholder = "",
    handleAttachAll,
    handleAttach,
    handleDetach,
    handleDetachAll,
    shouldDisableCheckbox,
  } = props;

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [unattachedSearchString, setUnattachedSearchString] = useState("");
  const [attachedSearchString, setAttachedSearchString] = useState("");

  const handleToggleChecked = (id) => {
    if (checkedItemIds.includes(id)) {
      setCheckedItemIds(checkedItemIds.filter((e) => e !== id));
    } else {
      setCheckedItemIds(checkedItemIds.concat(id));
    }
  };

  const allItemsFilteredByUnattachedSearch = useMemo(
    () => filter(allItems, unattachedSearchString, [displayNameKey]),
    [allItems, unattachedSearchString, displayNameKey],
  );
  const allItemsFilteredByAttachedSearch = useMemo(
    () => filter(allItems, attachedSearchString, [displayNameKey]),
    [allItems, attachedSearchString, displayNameKey],
  );

  const filteredUnattachedItemIds = useMemo(
    () =>
      difference(
        allItemsFilteredByUnattachedSearch.map((item) => get(item, idKey)),
        attachedItemIds,
      ),
    [allItemsFilteredByUnattachedSearch, attachedItemIds, idKey],
  );
  const filteredAttachedItemIds = useMemo(
    () =>
      intersection(
        allItemsFilteredByAttachedSearch.map((item) => get(item, idKey)),
        attachedItemIds,
      ),
    [allItemsFilteredByAttachedSearch, attachedItemIds, idKey],
  );

  const excludeDisabledItems = (idList) =>
    shouldDisableCheckbox
      ? idList.filter((id) => {
          const targetData = allItems.find((data) => data[idKey] === id);
          return !shouldDisableCheckbox(targetData);
        })
      : idList;

  const attachAll = () => {
    const enabledItems = excludeDisabledItems(filteredUnattachedItemIds);
    handleAttachAll(enabledItems);
    setCheckedItemIds(difference(checkedItemIds, filteredUnattachedItemIds));
  };

  const CheckboxItem = ({ id, displayName, disabled, displayNameTooltip }) => {
    const isChecked = checkedItemIds.includes(id);
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
        <div
          className={classnames(commonClasses.commonFlexStart, {
            [classes.cursorPointer]: !disabled,
          })}
          onClick={() => {
            if (!disabled) {
              handleToggleChecked(id);
            }
          }}
          role="button"
        >
          <Tooltip
            title={disabled ? checkboxTooltipText : ""}
            enterDelay={1000}
          >
            <div>
              <Checkbox
                inputProps={{
                  "aria-label": t("Check") + ` ${displayName}`,
                }}
                checked={isChecked}
                color="primary"
                size="small"
                className={classnames({ [classes.cursorDefault]: disabled })}
                disabled={disabled}
              />
            </div>
          </Tooltip>
          <Tooltip title={displayNameTooltip || ""} enterDelay={500}>
            <Typography variant="body2" className={classes.listItems}>
              {displayName}
            </Typography>
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <div className={classes.contentWrapper}>
      <Grid container>
        {(unattachedTitle || attachedTitle) && (
          <>
            <Grid item xs={5}>
              <Typography variant="body1" className={classes.description}>
                {unattachedTitle}
              </Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <Typography variant="body1" className={classes.description}>
                {attachedTitle} {attachedItemIds.length}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <div className={commonClasses.commonItemWrapper}>
            <Search className={classes.searchIcon} />
            <Input
              id="ATTACHMENT_BOARD_INPUT_SEARCH_ALL"
              value={unattachedSearchString}
              onChange={(event) =>
                setUnattachedSearchString(event.target.value)
              }
              classes={{
                input: commonClasses.commonSearchInput,
              }}
              fullWidth
              inputProps={{
                "aria-label": t("Search all"),
                placeholder: searchPlaceholder,
              }}
              className={classes.searchField}
            />
          </div>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <div className={commonClasses.commonItemWrapper}>
            <Search className={classes.searchIcon} />
            <Input
              id="ATTACHMENT_BOARD_INPUT_SEARCH_ATTACHED"
              value={attachedSearchString}
              onChange={(event) => {
                setAttachedSearchString(event.target.value);
              }}
              classes={{
                input: commonClasses.commonSearchInput,
              }}
              fullWidth
              inputProps={{
                "aria-label": t("Search attached"),
                placeholder: searchPlaceholder,
              }}
              className={classes.searchField}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5} className={classnames(classes.itemWrapper)}>
          {filteredUnattachedItemIds.length ? (
            filteredUnattachedItemIds.map((itemId, i) => {
              const item = allItems.find((item) => get(item, idKey) === itemId);

              return (
                <CheckboxItem
                  key={i}
                  id={itemId}
                  displayName={get(item, displayNameKey)}
                  displayNameTooltip={get(item, displayNameTooltipKey)}
                  disabled={
                    shouldDisableCheckbox ? shouldDisableCheckbox(item) : false
                  }
                />
              );
            })
          ) : (
            <Typography variant="body2" className={classes.listItems}>
              {noDataMessage}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} className={commonClasses.commonFlexColumnCenter}>
          <Tooltip title={t("Attach checked items")} enterDelay={500}>
            <div>
              <Button
                id="ATTACHMENT_BOARD_ATTACH"
                aria-label={t("Attach checked items")}
                disabled={isEmpty(
                  intersection(filteredUnattachedItemIds, checkedItemIds),
                )}
                onClick={() => {
                  handleAttach(
                    intersection(checkedItemIds, filteredUnattachedItemIds),
                  );
                  setCheckedItemIds((checkedItemIds) =>
                    difference(checkedItemIds, filteredUnattachedItemIds),
                  );
                }}
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
              >
                <IconRight />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={t("Detach checked items")} enterDelay={500}>
            <div>
              <Button
                id="ATTACHMENT_BOARD_DETACH"
                aria-label={t("Detach checked items")}
                disabled={isEmpty(
                  intersection(filteredAttachedItemIds, checkedItemIds),
                )}
                onClick={() => {
                  handleDetach(
                    intersection(checkedItemIds, filteredAttachedItemIds),
                  );
                  setCheckedItemIds((checkedItemIds) =>
                    difference(checkedItemIds, filteredAttachedItemIds),
                  );
                }}
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
              >
                <IconLeft />
              </Button>
            </div>
          </Tooltip>
          <span className={classes.spacer} />
          <Tooltip title={t("Attach all")} enterDelay={500}>
            <div>
              <Button
                id="ATTACHMENT_BOARD_ATTACH_ALL"
                aria-label={t("Attach all")}
                onClick={attachAll}
                disabled={isEmpty(filteredUnattachedItemIds)}
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
              >
                <IconDoubleRight />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={t("Detach all")} enterDelay={500}>
            <div>
              <Button
                id="ATTACHMENT_BOARD_DETACH_ALL"
                aria-label={t("Detach all")}
                disabled={isEmpty(filteredAttachedItemIds)}
                onClick={() => {
                  handleDetachAll(filteredAttachedItemIds);
                  setCheckedItemIds((checkedItemIds) =>
                    difference(checkedItemIds, filteredAttachedItemIds),
                  );
                }}
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
              >
                <IconDoubleLeft />
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={5} className={classnames(classes.itemWrapper)}>
          {filteredAttachedItemIds.map((itemId, i) => {
            const item = allItems.find((item) => get(item, idKey) === itemId);

            return (
              <CheckboxItem
                key={i}
                id={itemId}
                displayName={get(item, displayNameKey)}
                displayNameTooltip={get(item, displayNameTooltipKey)}
              />
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

AttachmentBoard.propTypes = {
  allItems: PropTypes.array.isRequired,
  attachedItemIds: PropTypes.array.isRequired,
  noDataMessage: PropTypes.string,
  idKey: PropTypes.string.isRequired,
  displayNameKey: PropTypes.string.isRequired,
  unattachedTitle: PropTypes.string,
  attachedTitle: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  handleAttachAll: PropTypes.func.isRequired,
  handleAttach: PropTypes.func.isRequired,
  handleDetach: PropTypes.func.isRequired,
  handleDetachAll: PropTypes.func.isRequired,
  shouldDisableCheckbox: PropTypes.func,
};

export default withTranslation()(AttachmentBoard);
