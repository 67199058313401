import { get } from "./request";

const API = process.env.REACT_APP_NTT_API_PATH;

const transformResponse = (response) => {
  return response
    ? response.map(({ id, fields, ticketid }) => {
        const fieldObj = fields.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.key]: cur.value,
          };
        }, {});
        return { id, fields: fieldObj, ticketid };
      })
    : [];
};

export const getIncidentTicketList = ({
  ticketingLogin,
  pattern = "*",
  transforms = [],
}) => {
  return get({
    url: `${API}/tickets/v1/remedygateway`,
    queryParams: {
      ticketingLogin,
      pattern,
    },
    transforms: [transformResponse, ...transforms],
  });
};
