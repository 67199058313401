import React, { useMemo, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import set from "lodash/set";
import Dialog from "msa2-ui/src/components/Dialog";
import { useTranslation } from "react-i18next";
import RunTimeVariables from "msa2-ui/src/components/variables/RunTimeVariableFields";
import { useSelector } from "react-redux";
import { getFormValues } from "msa2-ui/src/store/form";
import { getToken } from "msa2-ui/src/store/auth";
import TaskExecutionDialog from "msa2-ui/src/routes/automation/workflows/edit/task-executor/TaskExecutionDialog";
import { checkTaskStatus, testTask } from "msa2-ui/src/api/workflow";
import { withRetries } from "msa2-ui/src/utils/withRetries";
import { useUnmountEffect } from "msa2-ui/src/hooks/useUnmountEffect";
import { TASK_STATUS } from "./constants";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";

const getStatus = (rawStatus) => {
  switch (rawStatus) {
    case "RUNNING":
    case "WAITING":
      return TASK_STATUS.RUNNING;
    case "FAIL":
      return TASK_STATUS.FAIL;
    case "ENDED":
    case "OK":
      return TASK_STATUS.ENDED;
    default:
      return TASK_STATUS.FAIL;
  }
};

const convertTaskStatusResponse = ({ logs, status, start, end, stdErr }) => {
  return {
    logs,
    status: getStatus(status),
    startDate: start,
    endDate: end,
    error: stdErr,
  };
};

const initialState = {
  taskVariables: {},
  isExecutionDialogShown: false,
  isVariablesDialogShown: true,
  task: {
    status: TASK_STATUS.PENDING,
    startDate: "",
    endDate: "",
    error: "",
  },
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_VARIABLES":
      return { ...state, taskVariables: payload };
    case "EXECUTION_ERROR":
      return {
        ...state,
        task: {
          ...state.task,
          status: TASK_STATUS.FAIL,
          startDate: Date.now(),
          endDate: Date.now(),
        },
      };
    case "EXECUTION_STATUS":
      return {
        ...state,
        task: {
          ...state.task,
          ...payload,
          status: getStatus(payload.status),
        },
      };
    case "EXECUTION_START":
      return {
        ...state,
        isVariablesDialogShown: false,
        isExecutionDialogShown: true,
        task: {
          ...initialState.task,
          status: TASK_STATUS.PENDING,
        },
      };
    default:
      return state;
  }
};

const TaskExecutor = ({ form, taskCode, taskType, taskName, onClose }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  const isMountedRef = useRef(true);
  useUnmountEffect(() => (isMountedRef.current = false));

  const token = useSelector(getToken);
  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));

  const { variablePath } = useSelector(getFormValues(form, "config"));
  const unprocessedVariables =
    useSelector(getFormValues(form, variablePath)) || [];
  // we need to skip validation for required variables here
  const variables = useMemo(
    () => unprocessedVariables.map((v) => ({ ...v, mandatory: false })),
    [unprocessedVariables],
  );

  const handleTry = async () => {
    dispatch({ type: "EXECUTION_START" });

    const [isTaskStartError, , taskMeta] = await testTask({
      token,
      file: taskCode,
      fileContent: {
        taskType,
        parameters: {
          ...state.taskVariables,
        },
      },
    });

    if (isTaskStartError) {
      return dispatch({ type: "EXECUTION_ERROR" });
    }

    // TODO: pretty awkward way of getting task id...
    const taskId = taskMeta?.location?.split("/").slice(-1)[0];

    const checkTaskStatusWithRetries = withRetries({
      apiCall: checkTaskStatus,
      interval: pollingInterval,
      maxNumberOfTries: 600, // 10 minutes of polling
      checkForRetry: ([, response]) => {
        response && dispatch({ type: "EXECUTION_STATUS", payload: response });
        return isMountedRef.current && response?.status === TASK_STATUS.RUNNING;
      },
    });

    try {
      const [isRetryError, response] = await checkTaskStatusWithRetries({
        token,
        taskId,
        transforms: [convertTaskStatusResponse],
      });

      if (isRetryError) {
        return dispatch({ type: "EXECUTION_ERROR" });
      }

      dispatch({ type: "EXECUTION_STATUS", payload: response });
    } catch (err) {
      dispatch({ type: "EXECUTION_ERROR" });
    }
  };

  return (
    <>
      {state.isVariablesDialogShown && (
        <Dialog
          title={t("Test Task with Context Variable")}
          maxWidth="md"
          onExec={handleTry}
          onClose={onClose}
        >
          {variables.length ? (
            <RunTimeVariables
              editMode
              data={state.taskVariables}
              variables={variables}
              onChange={(value, key) =>
                dispatch({
                  type: "SET_VARIABLES",
                  payload: key ? set(state.taskVariables, key, value) : value,
                })
              }
            />
          ) : (
            t("No variables found")
          )}
        </Dialog>
      )}
      {state.isExecutionDialogShown && (
        <TaskExecutionDialog
          taskName={taskName}
          taskExecutionData={state.task}
          onClose={onClose}
        />
      )}
    </>
  );
};

TaskExecutor.propTypes = {
  form: PropTypes.string.isRequired,
  taskCode: PropTypes.string.isRequired,
  taskName: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskExecutor;
