import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Bpm, { VARIABLE_TYPE } from "msa2-ui/src/services/Bpm";
import Process from "msa2-ui/src/services/Process";
import Variable from "msa2-ui/src/services/Variable";
import Repository from "msa2-ui/src/services/Repository";
import useWorkflow from "msa2-ui/src/hooks/useWorkflow";
import useDialog from "msa2-ui/src/hooks/useDialog";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import MsaSelect from "msa2-ui/src/components/msa-select";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import Radio from "msa2-ui/src/components/connectedFormComponents/Radio";
import RunTimeVariables from "msa2-ui/src/components/variables/RunTimeVariableFields";

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    paddingBottom: 5,
    minHeight: 500,
  },
  loading: {
    marginBottom: 30,
  },
  formHeading: {
    marginBottom: 35,
  },
  formField: {
    marginBottom: 35,
    width: "100%",
  },
  formRadio: {
    marginBottom: 10,
    width: "100%",
  },
}));

const NoData = ({ isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return isLoading ? (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.loading}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Typography variant="body1" className={commonClasses.commonDescription}>
      {t("There are no data to show.")}
    </Typography>
  );
};

const TaskPropertiesPanel = ({
  modelerState,
  modelerActions,
  moddle,
  workflows,
  readOnly,
}) => {
  const { activeElement } = modelerState;
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const INSTANCE_TYPE = {
    EXISTING: {
      value: "existing",
      displayName: t("Existing Instance"),
    },
    NEW: {
      value: "new",
      displayName: t("New Instance"),
      title: t(
        "Select if you want to use an instance which will be created in this BPM.",
      ),
    },
  };
  const [showVariableDialog, VariableDialog] = useDialog();
  const [isUpdate, setIsUpdate] = useState(false);

  const allAttachedWorkflowValues = modelerActions.getAllAttachedWorkflowValues();
  const bpmElementValues = Bpm.readAttachedWorkflowValuesFromBpmElement(
    activeElement,
  );
  const {
    workflowPath,
    processName,
    processType,
    workflowInstanceId,
    processVariables,
    resumeOnFail,
  } = bpmElementValues;
  const sameWorkflowsWithCreateProcess = allAttachedWorkflowValues.filter(
    ({ activityId, processType, workflowPath }) =>
      // Filter workflows when...
      // BPM task which has the same Workflow as current selection
      workflowPath === bpmElementValues.workflowPath &&
      // and is Create process
      processType === Process.processDefinitions.CREATE.type &&
      // and is WF is already triggered (means it is placed before this node)
      modelerActions.isConnectingToCurrentElement(activityId),
  );

  const [instanceOption, setInstanceOption] = useState(
    Bpm.isCamundaJsonParser(workflowInstanceId)
      ? INSTANCE_TYPE.NEW.value
      : INSTANCE_TYPE.EXISTING.value,
  );
  const [variableOption, setVariableOption] = useState(
    Bpm.getVariableType(processVariables),
  );

  const {
    workflow,
    workflowDetails,
    workflowInstanceVariableValues,
    processVariableTypesByTask,
    loading,
    isLoading: workflowLoading,
  } = useWorkflow({
    workflowPath,
    processName,
    instanceId:
      !Bpm.isCamundaJsonParser(workflowInstanceId) && workflowInstanceId,
  });
  // Process options for picker
  const [processes, setProcesses] = useState();

  // Workflow instance options for picker
  const [workflowInstances, setWorkflowInstances] = useState(
    workflowDetails?.instances,
  );

  useEffect(() => {
    if (workflow) {
      setProcesses(workflow?.process);
    }
    if (workflowDetails) {
      setWorkflowInstances(workflowDetails?.instances);
    }
  }, [workflow, workflowDetails]);

  useEffect(() => {
    if (
      workflow !== undefined &&
      Object.keys(processVariables).length !==
        workflow.variables.variable.length &&
      !isUpdate
    ) {
      const defaultValues = Variable.createDefaultValue(
        workflow.variables.variable,
      );
      const newObj = {};

      Object.keys(defaultValues).forEach((key) => {
        if (!(key in processVariables)) {
          newObj[key] = defaultValues[key];
        }
        if (
          processVariables[key] === null ||
          processVariables[key] === undefined ||
          processVariables[key] === ""
        ) {
          newObj[key] = defaultValues[key];
        }
      });

      const updatedBpmElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
        ...bpmElementValues,
        processVariables: { ...processVariables, ...newObj },
        moddle,
      });

      modelerActions.replaceActiveElement(updatedBpmElement);
      setIsUpdate(true);
    }
  }, [
    workflow,
    processVariables,
    bpmElementValues,
    modelerActions,
    moddle,
    isUpdate,
    setIsUpdate,
  ]);

  const onChangeAttachedWorkflow = (newAttachedWorkflow) => {
    setProcesses(null);
    const updatedBpmElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
      displayName: newAttachedWorkflow.displayName,
      workflowPath: newAttachedWorkflow.path,
      processName: "",
      processType: "",
      workflowInstanceId: "",
      processVariables: {},
      moddle,
    });
    modelerActions.replaceActiveElement(updatedBpmElement);

    if (sameWorkflowsWithCreateProcess.length) {
      // If there is the same WF as selected one, select New Instance
      setInstanceOption(INSTANCE_TYPE.NEW.value);
    }
  };

  const onChangeProcess = (newProcess) => {
    const defaultValues = Variable.createDefaultValue(
      workflow.variables.variable,
    );
    const updatedBpmElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
      ...bpmElementValues,
      displayName: newProcess
        ? `${newProcess.displayName} (${workflow.information.displayName})`
        : "",
      processName: newProcess ? newProcess.name : "",
      processType: newProcess ? newProcess.type : "",
      workflowInstanceId: "",
      processVariables:
        newProcess && Process.isCreate(newProcess.type) ? defaultValues : {},
      moddle,
    });
    modelerActions.replaceActiveElement(updatedBpmElement);
    // If Process type is CREATE and variable type is LATEST, change it to SAVED
    if (
      Process.isCreate(newProcess?.type) &&
      variableOption === VARIABLE_TYPE.LATEST.value
    ) {
      setVariableOption(VARIABLE_TYPE.SAVED.value);
    }
  };

  const onChangeWorkflowInstance = (instanceId) => {
    const updatedBpmElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
      ...bpmElementValues,
      processType: selectedProcessType,
      workflowInstanceId: instanceId ? instanceId : "",
      moddle,
    });
    modelerActions.replaceActiveElement(updatedBpmElement);
  };

  const onChangeResumeOnFail = (checked) => {
    const updatedBpmElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
      ...bpmElementValues,
      resumeOnFail: checked,
      moddle,
    });
    modelerActions.replaceActiveElement(updatedBpmElement);
  };

  const onChangeVariableType = (value) => {
    setVariableOption(value);

    const instanceId = workflowInstanceId;
    let variableData = {};
    // set current context if you can set variables manually
    if (
      instanceId &&
      value === VARIABLE_TYPE.SAVED.value &&
      instanceOption === INSTANCE_TYPE.EXISTING.value
    ) {
      variableData = Variable.getDataToPost(
        workflowInstanceVariableValues,
        selectedProcessType,
        workflow.variables.variable,
        processVariableTypesByTask,
      );
    } else if (
      value === VARIABLE_TYPE.SAVED.value &&
      instanceOption === INSTANCE_TYPE.EXISTING.value
    ) {
      variableData = Variable.createDefaultValue(workflow.variables.variable);
    }
    if (value === VARIABLE_TYPE.PASSED.value) {
      variableData = null;
    }
    onChangeVariables(variableData);
  };

  const onChangeVariables = (processVariables) => {
    const updatedBpmElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
      ...bpmElementValues,
      processType: selectedProcessType,
      processVariables,
      moddle,
    });
    modelerActions.replaceActiveElement(updatedBpmElement);
  };

  if (!activeElement) {
    return null;
  }

  const shouldShowProcessPicker = workflowPath;
  const selectedProcessType = processType;
  const shouldShowWorkflowInstancePicker =
    selectedProcessType && !Process.isCreate(selectedProcessType);

  const shouldShowProcessVariables = processName;

  return (
    <Grid container direction="column" className={classes.container}>
      <Typography variant="h5" className={classes.formHeading}>
        {t("ID")}: {activeElement.id}
      </Typography>

      <Typography variant="h5" className={classes.formHeading}>
        {t("Workflow Selection")}
      </Typography>

      <Grid item xs={12}>
        <MsaSelect
          id="BPM_PROPERTIES_PANEL_WORKFLOW_SELECT"
          className={classes.formField}
          placeholder={
            workflowPath
              ? Repository.getFilenameFromUri(workflowPath)
              : t("Select Workflow")
          }
          getOptionLabel={(option) =>
            option.displayName ?? option.value ?? option
          }
          value={
            workflows?.find(({ path }) => path === workflowPath) ?? workflowPath
          }
          isClearable={false}
          onChange={onChangeAttachedWorkflow}
          disabled={readOnly}
          options={workflows}
          isLoading={!workflows}
        />
      </Grid>

      <>
        {shouldShowProcessPicker && (
          <>
            <Typography variant="h5" className={classes.formHeading}>
              {t("Workflow Settings")}
            </Typography>

            <MsaSelect
              id="BPM_PROPERTIES_PANEL_PROCESS_SELECT"
              className={classes.formField}
              placeholder={
                processName
                  ? Repository.getFilenameFromUri(processName)
                  : t("Select Process")
              }
              getOptionLabel={({ displayName }) => displayName}
              value={
                processes?.find(({ name }) => name === processName) ?? null
              }
              onChange={onChangeProcess}
              disabled={readOnly}
              options={processes ?? []}
              isLoading={!processes}
              cacheOptions
            />
          </>
        )}

        {shouldShowWorkflowInstancePicker && (
          <>
            <Radio
              input={{
                onChange: (value) => {
                  setInstanceOption(value);
                  onChangeWorkflowInstance("");
                },
                value: instanceOption,
              }}
              options={Object.values(INSTANCE_TYPE)}
              className={classes.formRadio}
              disabled={readOnly}
            />
            {instanceOption === INSTANCE_TYPE.NEW.value ? (
              <MsaSelect
                id="BPM_PROPERTIES_PANEL_INSTANCE_WORKFLOW_SELECT"
                className={classes.formField}
                label={"Workflow Instance"}
                placeholder={
                  workflowInstanceId ?? t("Select Workflow to Create Instance")
                }
                getOptionLabel={({ displayName }) => displayName}
                noOptionsMessage={({ inputValue }) => (
                  <Typography
                    variant="body1"
                    className={commonClasses.commonDescription}
                  >
                    {inputValue
                      ? t("There are no matches for your search.", {
                          key: t("BPM elements"),
                          term: inputValue,
                        })
                      : t(
                          "There are no BPM element with CREATE Process which has the same Workflow as this.",
                        )}
                  </Typography>
                )}
                value={
                  allAttachedWorkflowValues.find(({ activityId }) =>
                    workflowInstanceId.includes(activityId),
                  ) ?? null
                }
                onChange={(option) =>
                  onChangeWorkflowInstance(
                    option?.activityId
                      ? Bpm.buildCamundaJsonParser({
                          variableName: option.activityId,
                          props: ["serviceId"],
                        })
                      : "",
                  )
                }
                disabled={readOnly}
                options={sameWorkflowsWithCreateProcess}
                isLoading={!allAttachedWorkflowValues}
              />
            ) : (
              <MsaSelect
                id="BPM_PROPERTIES_PANEL_INSTANCE_SELECT"
                className={classes.formField}
                label={"Workflow Instance"}
                placeholder={
                  processName
                    ? Repository.getFilenameFromUri(
                        bpmElementValues.processName,
                      )
                    : t("Select Instance")
                }
                getOptionLabel={(option) => {
                  const [serviceId, , instanceName] =
                    workflowInstances?.find(
                      ([serviceId]) => option.serviceId === serviceId,
                    ) ?? [];
                  return serviceId
                    ? `${t("ID")}: ${serviceId} ${
                        instanceName ? "- " + instanceName : ""
                      }`
                    : option.serviceId;
                }}
                value={{ serviceId: workflowInstanceId }}
                onChange={(option) =>
                  onChangeWorkflowInstance(option?.serviceId)
                }
                disabled={readOnly}
                options={workflowInstances?.map(([serviceId]) => ({
                  serviceId,
                }))}
                isLoading={!workflowInstances}
              />
            )}
          </>
        )}

        {shouldShowProcessPicker && (
          <Tooltip
            title={t(
              "When enabled, BPM will continue to execute even if this Workflow is failed.",
            )}
            enterDelay={500}
          >
            <FormControl className={classes.formField}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      resumeOnFail === "false" ? false : Boolean(resumeOnFail)
                    }
                    onChange={({ target: { checked } }) => {
                      onChangeResumeOnFail(checked);
                    }}
                    disabled={readOnly}
                  />
                }
                label={t("Continue on Failure")}
              />
            </FormControl>
          </Tooltip>
        )}

        {shouldShowProcessVariables && (
          <>
            <Grid container alignItems={"baseline"}>
              <Grid item xs={8}>
                <Typography variant="h5" className={classes.formHeading}>
                  {t("Workflow Parameters")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={commonClasses.commonCreateButton}>
                {variableOption === VARIABLE_TYPE.SAVED.value && (
                  <Button
                    id="BPM_PROPERTIES_PANEL_EDIT"
                    aria-label="Edit"
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    onClick={showVariableDialog}
                    disabled={
                      loading.workflowIsLoading ||
                      (!Process.isCreate(selectedProcessType) &&
                        !workflowInstanceId)
                    }
                  >
                    {readOnly ? t("Show") : t("Edit")}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <Radio
                  input={{
                    onChange: onChangeVariableType,
                    value: variableOption,
                  }}
                  options={Object.values(VARIABLE_TYPE).map((option) => ({
                    ...option,
                    disabled: option.disabled
                      ? option.disabled(selectedProcessType)
                      : false,
                  }))}
                  className={classes.formRadio}
                  disabled={readOnly || workflowLoading}
                />
              </Grid>
              {workflow?.variables && (
                <VariableDialog title={t("Edit Variables")} maxWidth={"md"}>
                  <RunTimeVariables
                    data={processVariables}
                    variables={workflow.variables.variable}
                    processVariables={processVariableTypesByTask}
                    editMode={readOnly ? false : selectedProcessType}
                    onChange={onChangeVariables}
                  />
                </VariableDialog>
              )}
            </Grid>

            {/* Saved */}
            {variableOption === VARIABLE_TYPE.SAVED.value &&
              (!workflow || !processVariableTypesByTask ? (
                <NoData isLoading={workflowLoading} />
              ) : (
                <RunTimeVariables
                  data={processVariables}
                  variables={workflow.variables.variable}
                  processVariables={processVariableTypesByTask}
                  showOnlyProcessVariables
                />
              ))}
            {/* Latest */}
            {variableOption === VARIABLE_TYPE.LATEST.value &&
              instanceOption !== INSTANCE_TYPE.NEW.value &&
              (!workflowInstanceVariableValues ||
              !workflow ||
              !processVariableTypesByTask ? (
                <NoData isLoading={workflowLoading} />
              ) : (
                <>
                  <Typography
                    variant="body1"
                    className={commonClasses.commonWarning}
                  >
                    {t("Value can be changed in Run-time.")}
                  </Typography>
                  <RunTimeVariables
                    data={workflowInstanceVariableValues}
                    variables={workflow.variables.variable}
                    processVariables={processVariableTypesByTask}
                  />
                </>
              ))}
            {/* Pass */}
            {variableOption === VARIABLE_TYPE.PASSED.value && (
              <BasicSelect
                label={t("Workflow")}
                variant="outlined"
                className={classes.formField}
                name={t("Workflow")}
                value={processVariables}
                onChange={({ target: { value } }) => {
                  onChangeVariables(value);
                }}
                disabled={readOnly}
              >
                {allAttachedWorkflowValues
                  .filter(
                    ({ activityId }) =>
                      // filter out self
                      activityId !== activeElement.id &&
                      // filter out elements not connected to the selected element
                      modelerActions.isConnectingToCurrentElement(
                        activityId,
                        activeElement.id,
                      ),
                  )
                  .map(({ activityId, displayName }) => (
                    <MenuItem
                      value={Bpm.buildCamundaJsonParser({
                        variableName: activityId,
                        props: ["variables"],
                        parser: "toString",
                      })}
                      key={activityId}
                    >
                      {displayName}
                    </MenuItem>
                  ))}
              </BasicSelect>
            )}
          </>
        )}
      </>
    </Grid>
  );
};

TaskPropertiesPanel.propTypes = {
  modelerState: PropTypes.object.isRequired,
  modelerActions: PropTypes.object.isRequired,
  moddle: PropTypes.object.isRequired,
  workflows: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ),
};

export default TaskPropertiesPanel;
