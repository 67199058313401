import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@material-ui/core";

import FormSection from "msa2-ui/src/components/formSection/FormSection";
import Editor from "msa2-ui/src/components/connectedFormComponents/Editor";
import VisibleField from "./VisibleField";

const ListCommandForm = ({ commandIndex, submitting, isVisible, onChange }) => {
  const { t } = useTranslation();

  return (
    <FormSection title={t("Command to run for list")} loading={submitting}>
      <Grid item xs={12}>
        <VisibleField
          visible={isVisible}
          aria-label={t("Create List Text area")}
          name={["command", commandIndex, "output"].join(".")}
          component={Editor}
          mode={Editor.mode.smarty}
          onChange={onChange}
        />
      </Grid>
    </FormSection>
  );
};

ListCommandForm.propTypes = {
  commandIndex: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default ListCommandForm;
