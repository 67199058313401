import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import { buildRoute } from "msa2-ui/src/utils/urls";

import SectionTabs from "msa2-ui/src/components/SectionTabs";

import GlobalSettings from "./GlobalSettings";
import LocalSettings from "./LocalSettings";

const tabs = [
  {
    path: "global",
    displayName: "Global",
    component: GlobalSettings,
  },
  {
    path: "local",
    exact: true,
    displayName: "Local",
    component: LocalSettings,
  },
];

export const SettingTabs = ({ currentCount, endElements, isDeveloper }) => {
  const { url } = useRouteMatch();

  const tabsWithCount = tabs.map((tab) => {
    if (tab.path === url) {
      return {
        ...tab,
        count: currentCount,
      };
    }
    return tab;
  });

  return <SectionTabs tabs={tabsWithCount} endElements={endElements} />;
};

const SettingRouter = () => {
  const { path } = useRouteMatch();
  const defaultRoute = buildRoute(path, tabs[0].path);

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={defaultRoute} />
      </Route>
      {tabs.map(({ path: subPath, exact, component: Component }) => (
        <Route
          key={subPath}
          exact={exact}
          path={buildRoute(path, subPath)}
          render={() => (
            <Component tabs={(props) => <SettingTabs {...props} />} />
          )}
        />
      ))}
      <Redirect to={defaultRoute} />
    </Switch>
  );
};

export default SettingRouter;
