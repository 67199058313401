import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import { FilterList } from "@material-ui/icons";
import {
  MenuItem,
  Button,
  Popover,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { filterableNodeTypesMap } from "../constants";
import { typeToIconsMapDark, typeToIconsMapLight } from "../icons";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "15px 5px",
  },
  filterButton: {
    transition: "all .3s ease",
  },
  field: {
    textAlign: "left",
    width: 320,
  },
  paper: {
    overflowY: "inherit",
    overflowX: "inherit",
    padding: 10,
  },
}));

const renderInputValues = (values) => values.join(", ");
const renderTypesInputValues = (values) =>
  renderInputValues(values.map((value) => filterableNodeTypesMap[value]));

const Filters = ({
  t,
  categories,
  categoriesFilterArray,
  disabled,
  setCategoriesFilterArray,
  deviceTypesFilterArray,
  setDeviceTypesFilterArray,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { darkMode, palette } = useTheme();

  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const handlePopoverOpen = (evt) => setPopoverAnchor(evt.currentTarget);
  const handlePopoverClose = () => setPopoverAnchor(null);

  const handleFilterChange = (fn) => ({ target }) => {
    fn(target.value);
  };

  const handleDeviceTypeFilterChange = useCallback(
    handleFilterChange(setDeviceTypesFilterArray),
    [setDeviceTypesFilterArray],
  );

  const handleCategoryFilterChange = useCallback(
    handleFilterChange(setCategoriesFilterArray),
    [setCategoriesFilterArray],
  );

  return (
    <div className={classes.container}>
      <Button
        className={classes.filterButton}
        variant="outlined"
        color={
          deviceTypesFilterArray.length || categoriesFilterArray.length
            ? "primary"
            : "default"
        }
        startIcon={<FilterList />}
        onClick={handlePopoverOpen}
        ref={popoverAnchor}
        data-testid="topology-filter-button"
        disabled={disabled}
      >
        {t("Filter By")}
      </Button>
      <Popover
        classes={{ paper: classes.paper }}
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        data-testid="topology-filter-popup"
      >
        <Paper square className={commonClasses.commonPaper}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs="auto">
              <Typography variant="h5" paragraph>
                {t("Filter By")}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <BasicSelect
                className={classes.field}
                variant="outlined"
                label={t("Type")}
                value={deviceTypesFilterArray}
                multiple
                onChange={handleDeviceTypeFilterChange}
                renderValue={renderTypesInputValues}
              >
                {Object.entries(filterableNodeTypesMap).map(([key, name]) => {
                  const Icon = darkMode
                    ? typeToIconsMapDark[key]
                    : typeToIconsMapLight[key];
                  return (
                    <MenuItem
                      id={`TOPOLOGY_FILTER_TYPE_${name}`}
                      key={key}
                      value={key}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {name}
                        <Icon width={30} fill={palette.text.primary} />
                      </Grid>
                    </MenuItem>
                  );
                })}
              </BasicSelect>
            </Grid>
            {Boolean(categories.length) && (
              <Grid item xs="auto">
                <BasicSelect
                  className={classes.field}
                  variant="outlined"
                  label={t("Tags")}
                  value={categoriesFilterArray}
                  multiple
                  onChange={handleCategoryFilterChange}
                  renderValue={renderInputValues}
                >
                  {categories.map((category, i) => (
                    <MenuItem
                      id={`TOPOLOGY_FILTER_CATEGORY_${i}`}
                      key={i}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                  ))}
                </BasicSelect>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Popover>
    </div>
  );
};

Filters.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
  deviceTypesFilterArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDeviceTypesFilterArray: PropTypes.func.isRequired,
};

export default Filters;
