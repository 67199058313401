import React, { useState } from "react";
import Process from "msa2-ui/src/services/Process";

import AutomationProcessExecutionDialog from "msa2-ui/src/routes/automation/workflows/detail/AutomationProcessExecutionDialog";

const useWorkflowProgress = () => {
  const [show, setShow] = useState(false);
  const [processInfo, setProcessInfo] = useState({ type: "UPDATE" });
  const [hideWhenEnded, setHideWhenEnded] = useState(false);
  const [executionResult] = useState();
  const onClose = () => {
    setShow(false);
  };

  const modal = () => {
    const { id, name, type, reference } = processInfo;
    return (
      show && (
        <AutomationProcessExecutionDialog
          open
          onClose={onClose}
          processId={id}
          processReference={reference}
          processName={name}
          processIcon={Process.getIcon(type)}
          apiResponse={executionResult}
          onEnded={hideWhenEnded && onClose}
        />
      )
    );
  };

  return [
    ({
      processId,
      processReference,
      processName,
      processType,
      hideWhenEnded,
    }) => {
      setProcessInfo({
        id: processId,
        name: processName,
        reference: processReference,
        type: processType,
      });
      setHideWhenEnded(hideWhenEnded);
      setShow(true);
    },
    modal,
    show,
  ];
};

export default useWorkflowProgress;
