import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import FieldArray from "redux-form/lib/FieldArray";
import { Button, Grid, IconButton, MenuItem } from "@material-ui/core";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import { required, minValue } from "msa2-ui/src/utils/validators";
import { makeStyles } from "@material-ui/core";
import {
  pollingPeriods,
  thresholdFrequency,
  pollingTypes,
  thresholdComparator,
  formArrayTypes,
} from "./constants";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import classNames from "classnames";
import get from "lodash/get";
import i18n from "i18next";

const minValueZero = minValue(0);

const useStyles = makeStyles(() => ({
  row: {
    position: "relative",
    padding: "50px 0",

    "& + &": {
      borderTop: "1px solid #646c7d",
    },

    "&:hover > .deleteIconHover": {
      opacity: 1,
    },
  },
  deleteIcon: {
    position: "absolute",
    top: 5,
    right: 0,
    opacity: 0,
    transition: ".2s ease",
  },
}));

const renderMenuItems = (obj) =>
  Object.entries(obj).map(([key, name]) => (
    <MenuItem value={key} key={key}>
      {name}
    </MenuItem>
  ));

const warn = (value, _, { initialValues }, path) =>
  value &&
  value !== get(initialValues, path) &&
  get(initialValues, path) !== undefined
    ? i18n.t("By changing this, you will lose the monitoring data history")
    : undefined;

const SnmpList = memo(({ fields, onRemove }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const pollingTypesOptions = useMemo(() => renderMenuItems(pollingTypes), []);

  const pollingPeriodsOptions = useMemo(
    () => renderMenuItems(pollingPeriods),
    [],
  );

  const thresholdComparatorOptions = useMemo(
    () => renderMenuItems(thresholdComparator),
    [],
  );

  const thresholdFrequencyOptions = useMemo(
    () => renderMenuItems(thresholdFrequency),
    [],
  );

  return fields.map((field, rowIndex) => (
    <Grid item sm={12} key={field} className={classes.row}>
      <div className={classNames(classes.deleteIcon, "deleteIconHover")}>
        <IconButton onClick={() => onRemove(rowIndex)}>
          <IconDeleteRed />
        </IconButton>
      </div>
      <Grid container spacing={4} data-testid="snmp-rule-row">
        <Grid item sm={6} md={4} lg={3}>
          <FieldSelector
            name={`${field}.name`}
            label={t("Name")}
            type="text"
            validate={required}
            warn={warn}
            noIndent
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <FieldSelector
            name={`${field}.oid`}
            label="OID"
            type="text"
            validate={required}
            noIndent
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <FieldSelector
            name={`${field}.comment`}
            label={t("Comment")}
            type="text"
            noIndent
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <FormSelect
            required={false}
            name={`${field}.pollingType`}
            label={t("Type")}
            onChange={(evt, newValue, previousValue) => {
              // We need to reset and disable maxValue field if pollingType is not a gauge
              if (newValue !== "G" && newValue !== previousValue) {
                const currentRow = fields.get(rowIndex);
                fields.splice(rowIndex, 1, {
                  ...currentRow,
                  maxValue: undefined,
                });
              }
            }}
          >
            {pollingTypesOptions}
          </FormSelect>
        </Grid>
        <Grid item sm={6} md={4} lg={2}>
          <FieldSelector
            name={`${field}.maxValue`}
            label={t("Max")}
            type="number"
            noIndent
            disabled={fields.get(rowIndex).pollingType !== "G"}
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <FormSelect
            required={false}
            name={`${field}.pollingPeriod`}
            label={t("Poll every")}
            warn={warn}
          >
            {pollingPeriodsOptions}
          </FormSelect>
        </Grid>
        <Grid item sm={6} md={4} lg={2}>
          <FormSelect
            required={false}
            name={`${field}.thresholdComparator`}
            label={t("Comparator")}
          >
            {thresholdComparatorOptions}
          </FormSelect>
        </Grid>
        <Grid item sm={6} md={4} lg={2}>
          <FieldSelector
            name={`${field}.threshold`}
            label={t("Threshold")}
            type="number"
            validate={minValueZero}
            noIndent
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <FormSelect
            required={false}
            name={`${field}.thresholdFrequency`}
            label={t("Alerting Frequency")}
          >
            {thresholdFrequencyOptions}
          </FormSelect>
        </Grid>
      </Grid>
    </Grid>
  ));
});

const SnmpSection = ({ onAddSnmpListField, onRemoveSnmpListField }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  return (
    <FormSection title={t("SNMP Polling")}>
      <Grid item sm={12}>
        <Grid container justifyContent="center">
          <Button
            data-testid="add-snmp-rule"
            variant="contained"
            color="primary"
            onClick={onAddSnmpListField}
          >
            <IconPlus className={commonClasses.commonBtnIcon} />
            {t("Add Rule")}
          </Button>
        </Grid>
        <FieldArray
          name={formArrayTypes.snmp}
          component={SnmpList}
          onRemove={onRemoveSnmpListField}
        />
      </Grid>
    </FormSection>
  );
};

SnmpSection.propTypes = {
  onAddSnmpListField: PropTypes.func.isRequired,
  onRemoveSnmpListField: PropTypes.func.isRequired,
};

export default memo(SnmpSection);
