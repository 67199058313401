import React from "react";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { TABLE_HEADER_COLUMNS } from "msa2-ui/src/routes/profile/AuditLog";
import { useTranslation } from "react-i18next";
import AuditLogTableRow from "./AuditLogTableRow";
import { TableBody } from "@material-ui/core";

const AuditLogTableBody = ({ loading, logs, reload, error }) => {
  const { t } = useTranslation();

  if (loading) {
    return <TableLoading numberOfTableColumns={TABLE_HEADER_COLUMNS.length} />;
  }

  if (!logs || error || logs.length === 0) {
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching audit logs")}. ${t(
                "Please reload the page",
              )}.`
            : t("No audit logs found")
        }
        numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
      />
    );
  }

  return (
    <>
      <TableBody>
        {logs.map((entity, index) => (
          <AuditLogTableRow key={index} entity={entity} />
        ))}
      </TableBody>
    </>
  );
};

export default AuditLogTableBody;
