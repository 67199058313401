import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Field from "redux-form/lib/Field";
import ColorPicker from "msa2-ui/src/components/color-picker/ColorPicker";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  colorPickerWrap: {
    padding: "0 10px 0 11px",
  },
  labelError: {
    color: `${theme.colors.red}`,
  },
}));

const ColorPickerRenderer = ({ input, meta: { error, touched }, ...rest }) => {
  const classes = useStyles();
  const isError = touched && error;

  return (
    <FormControl
      error={isError}
      disabled={rest.disabled}
      required={rest.required}
    >
      <FormControlLabel
        control={
          <div className={classes.colorPickerWrap}>
            <ColorPicker error={isError} {...input} {...rest} />
          </div>
        }
        label={rest.label}
        className={classNames({ [classes.labelError]: isError })}
      />
      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const FormColorPicker = ({ name, disabled, label, required, validate }) => (
  <Field
    id={name}
    name={name}
    disabled={disabled}
    label={label}
    component={ColorPickerRenderer}
    required={required}
    validate={validate}
  />
);

FormColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

export default FormColorPicker;
