import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useToggle } from "react-use";
import reduxForm from "redux-form/lib/reduxForm";
import getFormSyncErrors from "redux-form/lib/getFormSyncErrors";

import { useTranslation } from "react-i18next";
import classNames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import flow from "lodash/flow";
import pick from "lodash/pick";
import produce from "immer";

import { useSnackbar } from "notistack";

import { getToken } from "msa2-ui/src/store/auth";
import { getFormValues } from "msa2-ui/src/store/form";
import {
  getAdvancedVariableParametersByName,
  getAdvancedVariableParametersByType,
} from "msa2-ui/src/store/designations";

import {
  getInstanceDataForMicroservicesOfManagedEntity,
  getMicroservice,
  updateMicroservice,
} from "msa2-ui/src/api/microservices";

import {
  addToOrderStack,
  executeCommand,
  deleteOrderStackCommand,
} from "msa2-ui/src/api/orderStack";
import Microservice from "msa2-ui/src/services/Microservice";
import MicroserviceCommand from "msa2-ui/src/services/MicroserviceCommand";
import useDialog from "msa2-ui/src/hooks/useDialog";
import useApi from "msa2-ui/src/hooks/useApi";

import Variable from "msa2-ui/src/services/Variable";
import Repository from "msa2-ui/src/services/Repository";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Popover,
  Table,
  TableContainer,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
  MoreVert as More,
} from "@material-ui/icons";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plus.svg";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";

import useFilter from "msa2-ui/src/hooks/useFilter";
import FilterMenu from "msa2-ui/src/components/FilterMenu";

import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";

import VariableViewTableHeader from "msa2-ui/src/components/variables/VariableViewTableHeader";
import VariableEditor from "msa2-ui/src/components/variables/VariableEditor";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import EditorAce from "msa2-ui/src/components/EditorAce";

import MicroserviceConsoleTableBody from "msa2-ui/src/components/msa-console-table/MicroserviceConsoleTableBody";
import OrderStackButtons from "./add/OrderStackButtons";
import MicroserviceInstanceModal from "./add/MicroserviceInstanceModal";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";
import cloneDeepWith from "lodash/cloneDeepWith";
import mapKeys from "lodash/mapKeys";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    height: "100%",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 58px)",
    minHeight: 200,
    overflowX: "scroll",
  },
  errorMessage: {
    paddingTop: 54,
    paddingBottom: 54,
    textAlign: "center",
  },
  tableError: {
    width: "100%",
    position: "absolute",
  },
  commandButton: {
    fontSize: "1em",
    padding: "3px 10px 3px 10px",
    marginRight: 5,
  },
  table: {
    // The table needs to be below the sidebar so it doesn't cover the shadow
    zIndex: 0,
    position: "relative",
  },
  filterMenuWrapper: {
    minHeight: "58px",
    maxHeight: "118px",
    paddingLeft: 10,
    paddingRight: 10,
  },
  listItemIcon: {
    marginRight: 12,
  },
  icon: {
    width: 18,
    height: 18,
  },
  headerCellPrimary: {
    width: 60,
  },
}));

const form = MicroserviceForm.ConsoleEditFormName;
const variablePath = MicroserviceForm.formConfig.variablePath;

export const getManagedEntityConfigureTableData = ({
  microserviceDataFromProps,
  microserviceBaseFilename,
  microserviceData,
  orderStack,
}) => {
  return flow(
    // if there is microserviceData passed as a prop, use it. otherwise take a data from API
    () =>
      microserviceDataFromProps?.[microserviceBaseFilename] ??
      microserviceData ??
      {},
    // merge order stack into microservice object
    (microserviceData) => {
      let ret = microserviceData;
      let retWithObjectId = mapKeys(ret, (value) => value.object_id);

      orderStack.forEach(
        ({
          commandName,
          parameters: { [microserviceBaseFilename]: targetObject },
        }) => {
          switch (commandName) {
            case MicroserviceCommand.type.create: {
              const createdObject = Object.entries(targetObject).reduce(
                (acc, [key, object]) => {
                  const objectId =
                    key || (object.object_id?.replaceAll(".", "_") ?? null);
                  const objectWithStatus = {
                    ...object,
                    PENDING_STATUS: commandName,
                  };
                  return { ...acc, [objectId]: objectWithStatus };
                },
                {},
              );
              ret = { ...ret, ...createdObject };

              break;
            }

            case MicroserviceCommand.type.update: {
              Object.entries(targetObject).forEach(([objectId, object]) => {
                if (retWithObjectId[objectId]) {
                  const updatedObject = {
                    PENDING_STATUS: commandName,
                    ...retWithObjectId[objectId],
                    ...object,
                  };
                  retWithObjectId = {
                    ...retWithObjectId,
                    [objectId]: updatedObject,
                  };
                }
              });
              ret = cloneDeep(retWithObjectId);

              break;
            }

            case MicroserviceCommand.type.delete: {
              Object.keys(targetObject).forEach((objectId) => {
                if (retWithObjectId[objectId]) {
                  retWithObjectId = produce(retWithObjectId, (draft) => {
                    draft[objectId].PENDING_STATUS = commandName;
                  });
                }
              });
              ret = cloneDeep(retWithObjectId);

              break;
            }

            default: {
              break;
            }
          }
        },
      );

      return ret;
    },
  )();
};

const PopupMenu = ({
  onClose,
  change,
  // spread selectedVariable
  anchorEl,
  variable,
  index,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const formValues = useSelector(getFormValues(form));
  const formError = useSelector(getFormSyncErrors(form));

  const error = formError.variables?.variable[index];

  const [
    showVariableEditDialog,
    VariableEditDialog,
    { activeVariable: activeVariableEdit },
  ] = useDialog();
  const [showVariableDeleteDialog, VariableDeleteDialog] = useDialog();

  const isMandatoryItem =
    variable && Variable.isMandatoryItem(variable.name, form);
  const isCompositeKey = variable?.isCompositeKey;
  const canDelete = !isMandatoryItem && !isCompositeKey;

  const onCloseEditVariable = () => {
    change(
      [variablePath, activeVariableEdit.index].join("."),
      activeVariableEdit.variable,
    );
    onClose();
  };

  const onDeleteVariable = (index) => {
    const variables = get(formValues, variablePath);
    change(
      variablePath,
      variables.filter((_, i) => i !== index),
    );
    showVariableDeleteDialog(false);
    onClose();
  };

  return (
    <>
      <Popover
        id="MANAGED_ENTITY_VARIABLES_TABLE_VARIABLE_POPOVER"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{ paper: commonClasses.commonDialogPaper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          id="MANAGED_ENTITY_VARIABLES_TABLE_EDIT_VARIABLE_BUTTON"
          onClick={() => {
            showVariableEditDialog(true, {
              activeVariable: {
                index,
                variable,
              },
            });
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <EditIcon className={classes.icon} />
          </ListItemIcon>
          {"Edit Variable"}
        </MenuItem>
        {canDelete && (
          <MenuItem
            id="MANAGED_ENTITY_VARIABLES_TABLE_DELETE_VARIABLE_BUTTON"
            onClick={() => {
              const { displayName } = variable;
              showVariableDeleteDialog(true, {
                content: t("Are you sure you want to delete x?", {
                  x: displayName,
                }),
              });
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon className={classes.icon} />
            </ListItemIcon>
            {"Delete Variable"}
          </MenuItem>
        )}
      </Popover>
      <VariableDeleteDialog
        title={t("Delete Variable")}
        onExec={() => {
          onDeleteVariable(index);
        }}
      />
      <VariableEditDialog
        title={t("Edit Variable")}
        maxWidth={"lg"}
        onClose={onCloseEditVariable}
        onExec={onClose}
        disabled={Boolean(error)}
      >
        <VariableEditor
          form={form}
          variablePath={variablePath}
          activeVariable={activeVariableEdit}
        />
      </VariableEditDialog>
    </>
  );
};

const ManagedEntityConfigureTable = ({
  microserviceUri,
  microserviceData: microserviceDataFromProps,
  onAddToOrderStack,
  orderStack = [],
  maintenanceMode,
  deviceId,
  isBulkOperationEnabled = false,
  isEditVariableEnabled = false,
  isFilterVariableEnabled = true,
  q = "",
  // props from redux-form
  initialize,
  destroy,
  change,
  handleSubmit,
  submitting,
}) => {
  const isReadonly = Boolean(microserviceDataFromProps);
  const shouldShowCheckbox = isBulkOperationEnabled && !isReadonly;

  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const [sort, setSort] = useState({
    active: "_order",
    direction: "asc",
    type: "Integer",
  });
  const [isSearchBoxOpen, toggleSearchBoxOpen] = useToggle(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [filterState, filterActions] = useFilter({
    tableName: "managedEntitiesConfigure",
    searchValue: q,
    tpPage: 0,
  });

  const { searchValue, tpPage, tpRowsPerPage } = filterState;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedVariable, setSelectedVariable] = useState({});

  const [
    showVariableCreateDialog,
    VariableCreateDialog,
    { activeVariable: activeVariableCreate },
  ] = useDialog();
  const [showRemoveDialog, RemoveDialog] = useDialog();
  const [showCommandDialog, CommandDialog] = useDialog();
  const [commandResult, setCommandResult] = useState();

  const [commandType, setCommandType] = useState(null);
  const [commandDialogMode, setCommandDialogMode] = useState(null);

  const token = useSelector(getToken);
  const microserviceFormValues = useSelector(getFormValues(form));
  const microserviceFormError = useSelector(getFormSyncErrors(form));
  const advancedVariableParametersByName = useSelector(
    getAdvancedVariableParametersByName(form),
  );
  const advancedVariableParameters = useSelector(
    getAdvancedVariableParametersByType(form),
  );

  const isEditingMicroservice = Boolean(microserviceFormValues);

  const microserviceBaseFilename = Repository.stripFolderPathAndFileExtensionFromUri(
    microserviceUri,
  );

  // Get the variables of the currently selected microservice
  const [isMicroserviceLoading, , microservice, , reloadMicroservice] = useApi(
    getMicroservice,
    { microServiceUri: microserviceUri },
    !microserviceUri,
  );

  /**
   * 1. Get all instance data at once for the managed entity's microservices to calculate next auto increment id
   * 2. It will be called only when we sync changes, not when we perform order stack operations
   */
  const [, , allMicroserviceData = {}] = useApi(
    getInstanceDataForMicroservicesOfManagedEntity,
    {
      id: deviceId,
      msUri: microserviceUri,
      page: 1,
      pageSize: 100000,
      format: true,
      arrayFormatFlag: true,
    },
    // if microserviceData is passed from props, do not call the API
    !microserviceUri || isReadonly,
  );

  const allTableData = useMemo(
    () =>
      microservice
        ? getManagedEntityConfigureTableData({
            microserviceDataFromProps,
            microserviceBaseFilename,
            microserviceData: allMicroserviceData ?? {},
            orderStack,
          })
        : {},
    [
      microservice,
      microserviceBaseFilename,
      allMicroserviceData,
      microserviceDataFromProps,
      orderStack,
    ],
  );

  // Get instance data for the managed entity's microservices
  const [
    isMicroserviceDataLoading,
    ,
    microserviceData = {},
    { totalobjects } = {},
  ] = useApi(
    getInstanceDataForMicroservicesOfManagedEntity,
    {
      id: deviceId,
      msUri: microserviceUri,
      searchQuery: searchValue,
      pageSize: tpRowsPerPage,
      page: tpPage + 1,
      sort: sort.active,
      sortOrder: sort.direction.toUpperCase(),
      format: true,
      arrayFormatFlag: true,
    },
    // if microserviceData is passed from props, do not call the API
    !microserviceUri || isReadonly,
  );

  const tableData = useMemo(() => {
    return microservice
      ? getManagedEntityConfigureTableData({
          microserviceDataFromProps,
          microserviceBaseFilename,
          microserviceData,
          orderStack,
        })
      : {};
  }, [
    microservice,
    microserviceBaseFilename,
    microserviceData,
    microserviceDataFromProps,
    orderStack,
  ]);

  const allRows = Object.entries(tableData)
    .filter(([, { PENDING_STATUS }]) => !PENDING_STATUS)
    .map(([key]) => key);

  const isAllRowsSelected = allRows.every((row) => selectedRows.includes(row));

  const toggleSelectAllRows = () => {
    if (isAllRowsSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allRows);
    }
  };

  if (!microservice && isMicroserviceLoading) {
    return (
      <Grid
        className={classNames([
          commonClasses.commonLoaderWrapper,
          classes.container,
        ])}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const visibleVariables = microservice
    ? Variable.filterEditOnly(microservice.variables.variable)
    : [];
  const allVariablesHidden = !visibleVariables.length;
  const hasTable = visibleVariables.some(
    (variable) => Variable.getTableValData(variable.name).isTable,
  );

  if (allVariablesHidden) {
    return (
      <Grid className={classNames([classes.container, classes.errorMessage])}>
        {t("There are no visible variables for this microservice")}
      </Grid>
    );
  }

  const handleSortData = ({ active, direction, variable: { type } }) => {
    setSort({ active, direction, type });
  };

  const handleBoolean = (data) => {
    return cloneDeepWith(data, (value) => {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
      }
    });
  };

  const addCommandToOrderStack = async (
    data,
    checkedVariables,
    bulkOperationInfo,
  ) => {
    const dataWithoutStatus = omit(data, ["PENDING_STATUS"]);
    const cloneData = handleBoolean(dataWithoutStatus);

    let commandName = commandType;
    orderStack.map(async (obj) => {
      const commandId = obj.commandId;
      const parametersMap = obj.parameters[microserviceBaseFilename];
      const parameter = parametersMap[cloneData.object_id];
      if (parameter) {
        commandName = obj.commandName;
        const [error] = await deleteOrderStackCommand({
          token,
          commandId,
          deviceId: deviceId,
        });
        const variant = error ? "error" : "success";
        const message = error
          ? error.getMessage(t(`Unable to clear command`))
          : t(`Command has been cleared from the stack.`);
        enqueueSnackbar(message, {
          variant,
          action: (key) => (
            <SnackbarAction id={key} handleClose={closeSnackbar} />
          ),
        });
      }
    });

    const formattedData = {
      [microserviceBaseFilename]: (() => {
        switch (commandName) {
          case MicroserviceCommand.type.create: {
            const buildMicroserviceObjectKey = (string) =>
              String(string)?.replaceAll(".", "_") ?? null;
            const { values } = bulkOperationInfo;
            if (values.length > 1) {
              // bulk create
              return values.reduce((acc, keyData) => {
                //Keep this for feature purpose
                //const concatValues = Object.values(keyData).join("_");
                return {
                  ...acc,
                  [buildMicroserviceObjectKey(keyData.object_id)]: {
                    ...cloneData,
                    ...keyData,
                  },
                };
              }, {});
            }
            // single create
            return {
              [buildMicroserviceObjectKey(cloneData.object_id)]: cloneData,
            };
          }
          case MicroserviceCommand.type.update: {
            if (selectedRows.length > 1) {
              // bulk update
              const newData = checkedVariables.reduce(
                (acc, id) => ({ ...acc, [id]: cloneData[id] }),
                {},
              );
              return selectedRows.reduce((acc, key) => {
                const originalData = handleBoolean(selectedData[key]);
                return { ...acc, [key]: { ...originalData, ...newData } };
              }, {});
            }

            return { [selectedRows[0]]: cloneData };
          }

          default: {
            return handleBoolean(selectedData);
          }
        }
      })(),
    };

    const [error] = await addToOrderStack({
      token,
      commandType: commandName,
      deviceId: deviceId,
      order: formattedData,
    });

    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t(`Unable to add command`))
      : t(`Command has been added to the stack.`);
    if (variant === "success") {
      setCommandDialogMode(false);
      setSelectedRows([]);
      onAddToOrderStack && onAddToOrderStack();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  const onCreateVariable = () => {
    const variables = get(microserviceFormValues, variablePath);
    const initValue = {
      ...Variable.getInitVariableByType(
        advancedVariableParametersByName,
        advancedVariableParameters,
      ),
      displayOrder: variables.length,
    };

    change(variablePath, variables.concat(initValue));
    showVariableCreateDialog(true, {
      activeVariable: {
        index: variables.length,
      },
    });
  };

  const onCloseCreateVariable = () => {
    const variables = get(microserviceFormValues, variablePath);
    change(
      variablePath,
      variables.filter((_, i) => i !== activeVariableCreate.index),
    );
  };

  const onSaveVariables = async (values) => {
    // Update Constraint
    const {
      constraint,
      index: constraintIndex,
    } = MicroserviceCommand.buildConstraintByCompositeKey(values);
    const body = produce(values, (draft) => {
      draft.command[constraintIndex] = constraint;
    });

    const [error] = await updateMicroservice({ body, token });
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to update Microservice."))
      : t("Microservice has been updated.");
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      reloadMicroservice();
      destroy();
    }
  };

  const selectedData = pick(
    mapKeys(microserviceData, (value) => value.object_id),
    selectedRows,
  );

  const getInitDataBasedOnCommandType = () => {
    if (commandDialogMode === MicroserviceCommand.runtimeTypes.create) {
      return Variable.getInitData(
        microservice.variables.variable,
        allTableData,
      );
    }

    const data = mapKeys(tableData, (value) => value.object_id)[
      selectedRows[0]
    ];

    if (commandDialogMode === MicroserviceCommand.runtimeTypes.duplicate) {
      const nextAutoincrementVariables = Variable.getInitData(
        microservice.variables.variable.filter(
          ({ type }) => type === "AutoIncrement",
        ),
        allTableData,
      );
      return { ...data, ...nextAutoincrementVariables };
    }
    return data;
  };

  const renderOrderStackButtons = () => (
    <OrderStackButtons
      commands={Microservice.hasCommands(microservice.command)}
      selectedRows={selectedRows}
      onClickAddRow={() => {
        setCommandType(MicroserviceCommand.type.create);
        setCommandDialogMode(MicroserviceCommand.runtimeTypes.create);
      }}
      onClickEdit={() => {
        setCommandType(MicroserviceCommand.type.update);
        setCommandDialogMode(MicroserviceCommand.runtimeTypes.update);
      }}
      onClickRemove={() => {
        setCommandType(MicroserviceCommand.type.delete);
        showRemoveDialog();
      }}
      onClickDuplicate={() => {
        setCommandType(MicroserviceCommand.type.create);
        setCommandDialogMode(MicroserviceCommand.runtimeTypes.duplicate);
      }}
      onClickRead={async () => {
        showCommandDialog();
        const readResult = await executeCommand({
          deviceId,
          commandName: MicroserviceCommand.type.read,
          order: { [microserviceBaseFilename]: selectedData },
          token,
        });
        setCommandResult(readResult);
      }}
      onClickList={async () => {
        showCommandDialog();
        const listResult = await executeCommand({
          deviceId,
          commandName: MicroserviceCommand.type.list,
          order: {
            [microserviceBaseFilename]: isEmpty(selectedData)
              ? microserviceData
              : selectedData,
          },
          token,
        });
        setCommandResult(listResult);
      }}
    />
  );

  const renderDelegationProfiles = () => (
    <DelegationProfiles
      type={delegationProfileTypes.MANAGED_ENTITIES}
      action="obmf.edit"
    >
      <Tooltip title={t("Edit Variables")}>
        <IconButton
          id="MANAGED_ENTITY_CONFIGURE_TABLE_EDIT_VARIABLE_BUTTON"
          aria-label={t("Edit Variables")}
          onClick={() => {
            initialize(microservice);
          }}
        >
          <EditIcon color="primary" />
        </IconButton>
      </Tooltip>
    </DelegationProfiles>
  );

  const renderAddVariableButton = () => (
    <Button
      id="MANAGED_ENTITY_CONFIGURE_TABLE_ADD_VARIABLE_BUTTON"
      variant="contained"
      color="primary"
      className={classNames([
        commonClasses.commonBtnPrimary,
        classes.commandButton,
      ])}
      onClick={onCreateVariable}
    >
      {t("Add Variable")}
    </Button>
  );

  const renderCancelButton = () => (
    <Button
      id="MANAGED_ENTITY_CONFIGURE_TABLE_CANCEL_BUTTON"
      variant="text"
      size="small"
      color="default"
      className={commonClasses.commonBtnSecondary}
      onClick={() => {
        destroy();
      }}
      disabled={submitting}
    >
      {t("Cancel")}
    </Button>
  );

  const renderSaveButton = () => (
    <Button
      id="MANAGED_ENTITY_CONFIGURE_TABLE_SAVE_BUTTON"
      variant="contained"
      size="small"
      color="primary"
      className={commonClasses.commonBtnPrimary}
      onClick={() => {
        handleSubmit(onSaveVariables)();
      }}
      disabled={submitting}
    >
      {t("Save")}
    </Button>
  );

  const renderFilterMenu = () => {
    const defaultProps = {
      id: "MANAGED_ENTITY_VARIABLES_TABLE_FILTER_MENU",
    };
    let props;

    if (isEditingMicroservice) {
      props = {
        ActionLeft: () => renderAddVariableButton(),
        ActionRight: () => (
          <>
            {renderCancelButton()}
            {renderSaveButton()}
          </>
        ),
      };
    } else {
      props = {
        ...filterState,
        ...filterActions,
        // Hide value if search is done in column level
        searchValue: visibleVariables
          .map(({ name }) => Variable.removePrefix(name))
          .includes(searchValue.split(":")[0])
          ? ""
          : searchValue,
        handleToggleSearch: toggleSearchBoxOpen,
        handleViewAsChange: undefined,
        tpTotal: totalobjects ?? 0,
        ActionLeft: () =>
          isFilterVariableEnabled
            ? !maintenanceMode
              ? renderOrderStackButtons()
              : null
            : null,
        ActionRight: () =>
          isEditVariableEnabled ? renderDelegationProfiles() : null,
      };
    }

    return (
      <FilterMenu
        id="MANAGED_ENTITY_VARIABLES_TABLE_FILTER_MENU"
        {...defaultProps}
        {...props}
      />
    );
  };

  const renderEditModeTableHeader = () => (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <VariableViewTableHeader
          variables={get(microserviceFormValues, variablePath)}
          onClickLabel={({ currentTarget }, variable) => {
            const variables = get(microserviceFormValues, variablePath);
            const index = variables.findIndex((e) => e === variable);
            setSelectedVariable({
              anchorEl: currentTarget,
              variable,
              index,
            });
          }}
          SortIcon={More}
        />
      </Box>
      <Box p={1}>
        <Tooltip title={t("Add Variable")}>
          <IconButton
            id="MANAGED_ENTITY_CONFIGURE_TABLE_ADD_VARIABLE_ICON_BUTTON"
            aria-label={t("Add Variable")}
            onClick={onCreateVariable}
          >
            <IconPlus />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );

  const renderTableHeader = () => {
    if (isEditingMicroservice) return renderEditModeTableHeader();
    return (
      <VariableViewTableHeader
        classes={{ headerCellPrimary: classes.headerCellPrimary }}
        variables={visibleVariables}
        onClickLabel={handleSortData}
        shouldShowSearchBox={isSearchBoxOpen}
        searchValue={searchValue}
        setSearchValue={filterActions.handleSearchByChange}
        primaryLabel={
          shouldShowCheckbox ? (
            <Checkbox
              id="MANAGED_ENTITY_VARIABLES_TABLE_HEADER_CHECKBOX"
              onClick={toggleSelectAllRows}
              checked={isAllRowsSelected}
              indeterminate={!isAllRowsSelected && Boolean(selectedRows.length)}
            />
          ) : (
            undefined
          )
        }
      />
    );
  };

  const renderTableBody = () => {
    if (isEditingMicroservice) {
      return null;
    }
    if (isMicroserviceDataLoading) {
      return (
        <TableLoading
          numberOfTableColumns={visibleVariables.length + (isReadonly ? 0 : 1)}
        />
      );
    }
    if (isEmpty(tableData)) {
      return (
        <TableMessage
          numberOfTableColumns={visibleVariables.length + (isReadonly ? 0 : 1)}
          message={t("No instance data for this microservice")}
        />
      );
    }

    return (
      <MicroserviceConsoleTableBody
        setSelectedMicroserviceKeys={setSelectedRows}
        selectedMicroserviceKeys={selectedRows}
        microserviceVariables={visibleVariables}
        microserviceInstanceData={tableData}
        shouldShowCheckbox={shouldShowCheckbox}
        sort={sort}
        components={{ MSAVariable: ExecutionVariableField }}
      />
    );
  };

  return (
    <div className={classes.container}>
      <PopupMenu
        {...selectedVariable}
        onClose={() => {
          setSelectedVariable({});
        }}
        change={change}
      />
      <VariableCreateDialog
        title={t("Create Variable")}
        maxWidth={"lg"}
        onExec={() => {}}
        onClose={onCloseCreateVariable}
        disabled={Boolean(
          microserviceFormError.variables?.variable[
            activeVariableCreate?.index
          ],
        )}
      >
        <VariableEditor
          form={form}
          variablePath={variablePath}
          activeVariable={activeVariableCreate}
        />
      </VariableCreateDialog>
      <RemoveDialog
        title={t("Remove row?")}
        content={t("Are you sure you want to remove this row?")}
        onExec={addCommandToOrderStack}
      />
      <CommandDialog
        title={t("Command Result")}
        maxWidth="md"
        onClose={() => {
          setCommandResult();
        }}
      >
        {commandResult ? (
          <EditorAce
            readOnly
            showCopyIcon
            value={commandResult[1].message || commandResult[0].getMessage()}
          />
        ) : (
          <CircularProgress />
        )}
      </CommandDialog>

      {Boolean(commandDialogMode) && (
        <MicroserviceInstanceModal
          commandType={commandType}
          data={(() => getInitDataBasedOnCommandType())()}
          onSave={addCommandToOrderStack}
          onClose={() => {
            setCommandDialogMode(false);
          }}
          variables={microservice.variables.variable}
          instanceDataForMicroservice={tableData}
          shouldShowCheckbox={
            commandType === MicroserviceCommand.type.update &&
            selectedRows.length > 1
          }
          selectedRows={selectedRows}
        />
      )}
      {!isReadonly && (
        <Grid
          container
          justifyContent="center"
          className={classes.filterMenuWrapper}
        >
          {renderFilterMenu()}
        </Grid>
      )}
      <TableContainer classes={{ root: classes.tableContainer }}>
        <Table
          stickyHeader
          className={classes.table}
          style={{
            width: hasTable && !isEmpty(tableData) ? "auto" : "inherit",
          }}
        >
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    </div>
  );
};

ManagedEntityConfigureTable.propTypes = {
  microserviceUri: PropTypes.string.isRequired,
  microserviceData: PropTypes.object,
  onAddToOrderStack: PropTypes.func,
  orderStack: PropTypes.array,
  deviceId: PropTypes.number,
  isBulkOperationEnabled: PropTypes.bool,
  isEditVariableEnabled: PropTypes.bool,
  isFilterVariableEnabled: PropTypes.bool,
};

export default reduxForm({
  form,
  validate: MicroserviceForm.validate,
})(ManagedEntityConfigureTable);
