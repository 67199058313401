import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import {
  getLastPointForConnection,
  getDimensions,
  getViewBox,
} from "msa2-ui/src/routes/ai/generate-bpm/network-graph/graph-utils";
import cloneDeep from "lodash/cloneDeep";

class Graph extends PureComponent {
  containerEl = null;
  rootEl = null;
  tooltipEl = null;
  d3Simulation = null;
  prevNodes = [];

  constructor(props) {
    super(props);

    this.d3Simulation = d3
      .forceSimulation()
      .force(
        "link",
        d3
          .forceLink()
          .id((link) => link.id)
          .distance(100)
          .iterations(10),
      )
      .force("charge", d3.forceManyBody())
      .force("collide", d3.forceCollide().radius(130));
  }

  componentDidMount() {
    this.renderGraph();
  }

  componentWillUnmount() {
    if (this.d3Simulation) {
      this.d3Simulation.stop();
    }

    if (this.tooltipEl) this.tooltipEl.remove();
  }

  componentDidUpdate() {
    // We need to fix nodes in their previous positions otherwise they will change it on each render.
    if (this.props.nodes !== this.prevNodes) {
      this.props.nodes.forEach((node, i) => {
        const prevNode = this.prevNodes[i];

        if (prevNode && node.id === prevNode.id) {
          node.fx = prevNode.x;
          node.fy = prevNode.y;
        }
      });
    }

    this.renderGraph();
  }

  prepareNodes() {
    const { nodes, nodeRenderer } = this.props;

    const prepareNode = (node) => {
      node.html = nodeRenderer
        ? nodeRenderer(node)
        : `<div style="background: #ddd; color: #000; padding: 5px;">${node.label}</div>`;

      const { width, height } = getDimensions(node.html);

      node.width = width;
      node.height = height;
    };

    nodes.forEach(prepareNode);
  }

  renderNodes(svg, nodesData) {
    const selection = svg
      .append("g")
      .selectAll("foreignObject")
      .data(nodesData, (node) => node.id)
      .enter();

    return selection
      .append("foreignObject")
      .attr("class", "node")
      .attr("width", (node) => node.width)
      .attr("height", (node) => node.height)
      .html((node) => node.html);
  }

  prepareConnections() {
    const { connections } = this.props;

    return connections.map((connection) => ({
      ...connection,
    }));
  }

  renderConnections(svg, connectionsData) {
    const mainColor = "#aaa";
    const hoverColor = "#fff";
    const getMarkerId = ({ source, target }) => `arrow-${source}-${target}`;

    // Creates arrows
    svg
      .append("defs")
      .selectAll("marker")
      .data(connectionsData)
      .join("marker")
      .attr("id", getMarkerId)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 9)
      .attr("refY", 0)
      .attr("markerWidth", 5)
      .attr("markerHeight", 5)
      .attr("orient", "auto")
      .append("path")
      .attr("fill", mainColor)
      .attr("stroke", mainColor)
      .attr("stroke-opacity", ".5")
      .attr("fill-opacity", "1")
      .attr("d", "M0,-5L10,0L0,5");

    const onMouseEnter = (d) => {
      svg
        .select("g.connectionGroup")
        .selectAll(".connection")
        .attr("stroke", (c) => (c.index === d.index ? hoverColor : mainColor));

      svg
        .select(`#${getMarkerId({ source: d.source.id, target: d.target.id })}`)
        .select("path")
        .attr("stroke", hoverColor)
        .attr("fill", hoverColor);

      const tooltipContent = this.props.connectionTooltipRenderer
        ? this.props.connectionTooltipRenderer(d.__connectionData__)
        : "";

      this.showTooltip(tooltipContent);
    };

    const onMouseLeave = () => {
      svg
        .select("g.connectionGroup")
        .selectAll(".connection")
        .attr("stroke", mainColor);

      svg
        .selectAll("marker")
        .select("path")
        .attr("fill", mainColor)
        .attr("stroke", mainColor);

      this.hideTooltip();
    };

    // Creates lines between nodes
    return svg
      .append("g")
      .attr("class", "connectionGroup")
      .selectAll("polyline")
      .data(connectionsData)
      .enter()
      .append("polyline")
      .attr("class", "connection")
      .attr("stroke", mainColor)
      .attr("stroke-width", "2")
      .attr("fill", "none")
      .attr("marker-end", (d) => `url(#${getMarkerId(d)})`)
      .on("mouseenter", onMouseEnter)
      .on("mouseleave", onMouseLeave);
  }

  showTooltip(content = "") {
    this.tooltipEl.innerHTML = content;

    this.tooltipEl.style.opacity = "1";
    // @TODO : we have updated D3 version so this needs to be fixed later
    /*
      this.tooltipEl.style.left =
        d3.event.pageX - this.tooltipEl.offsetWidth / 2 + "px";
      this.tooltipEl.style.top =
        d3.event.pageY - this.tooltipEl.offsetHeight + "px";
    */
  }

  hideTooltip() {
    this.tooltipEl.innerHTML = "";
    this.tooltipEl.style.opacity = "0";
  }

  renderTooltip() {
    if (this.tooltipEl) return;

    const tooltip = document.createElement("div");

    tooltip.style.position = "absolute";
    tooltip.style.top = "0";
    tooltip.style.left = "0";
    tooltip.style.opacity = "0";
    tooltip.style.transition = "opacity .3s ease";
    tooltip.style.pointerEvents = "none";

    this.tooltipEl = tooltip;
    document.body.append(tooltip);
  }

  onTickHandler(d3Connections, d3Nodes) {
    d3Connections.attr("points", (connection) => {
      const targetPoint = getLastPointForConnection(connection);
      return `${connection.source.x},${connection.source.y} ${targetPoint.x},${targetPoint.y}`;
    });

    d3Nodes
      .attr("x", (node) => node.x - node.width / 2)
      .attr("y", (node) => node.y - node.height / 2);

    this.prevNodes = cloneDeep(d3Nodes.data());
  }

  configureZoom(svg) {
    const zoomHandler = (event) => {
      svg.selectAll("svg > g").attr("transform", event.transform);
    };

    return d3
      .zoom()
      .scaleExtent([0.3, 3])
      .on("zoom", zoomHandler);
  }

  renderGraph() {
    const svg = d3.select(this.rootEl);

    // Clean old nodes and connections
    svg.selectAll("*").remove();

    const connectionsData = this.prepareConnections();
    const d3Connections = this.renderConnections(svg, connectionsData);

    const { nodes } = this.props;
    this.prepareNodes();
    const d3Nodes = this.renderNodes(svg, nodes);

    this.renderTooltip();

    this.d3Simulation
      .nodes(nodes)
      .force("link")
      .links(connectionsData);

    // Tick the simulation 100 times.
    for (let i = 0; i < 100; i += 1) {
      this.d3Simulation.tick();
    }

    this.onTickHandler(d3Connections, d3Nodes);

    svg
      .attr("viewBox", getViewBox(nodes, this.containerEl))
      .call(this.configureZoom(svg))
      .on("dblclick.zoom", null)
      .on("wheel.zoom", null);
  }

  render() {
    return (
      <div
        ref={(el) => (this.containerEl = el)}
        style={{ position: "relative" }}
      >
        <svg ref={(el) => (this.rootEl = el)} />
      </div>
    );
  }
}

Graph.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      __nodeData__: PropTypes.object.isRequired,
    }),
  ).isRequired,
  connections: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      target: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      __connectionData__: PropTypes.array.isRequired,
    }),
  ).isRequired,
  nodeRenderer: PropTypes.func,
  connectionTooltipRenderer: PropTypes.func,
};

export default Graph;
