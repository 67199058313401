import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { TableBody } from "@material-ui/core";

import LogsTableRow from "./LogsTableRow";
import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";
import isEmpty from "lodash/isEmpty";

const LogsTableBody = ({
  loading,
  logs,
  error,
  isTriggeredAlarms,
  canAck,
  checkedEntries = [],
  enableHighlighting = false,
  searchWordsToHighlight = [],
  setCheckedEntries,
  columns,
  dynamicStyling,
  components,
  tableName,
}) => {
  const { t } = useTranslation();

  if (isEmpty(logs) && loading) {
    return <TableLoading numberOfTableColumns={8} />;
  }

  if (logs && !error && logs.length !== 0) {
    return (
      <TableBody>
        {logs.map((entry, index) => (
          <LogsTableRow
            key={index}
            index={index}
            entry={entry}
            isTriggeredAlarms={isTriggeredAlarms}
            canAck={canAck}
            checked={checkedEntries[index] ?? false}
            setChecked={(checked) => {
              setCheckedEntries(index, checked);
            }}
            columns={columns}
            dynamicStyling={dynamicStyling}
            components={components}
            enableHighlighting={enableHighlighting}
            searchWordsToHighlight={searchWordsToHighlight}
            tableName={tableName}
          />
        ))}
      </TableBody>
    );
  } else {
    let message = "";
    if (isTriggeredAlarms) {
      message = t("No alarms found");
    } else {
      message = t("No logs found");
    }
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching x", { x: t("Logs") })}. ${t(
                "Please reload the page",
              )}.`
            : message
        }
        numberOfTableColumns={8}
      />
    );
  }
};

LogsTableBody.propTypes = {
  loading: PropTypes.bool,
  logs: PropTypes.array,
  error: PropTypes.object,
  columns: PropTypes.array.isRequired,
};

export default LogsTableBody;
