import React, { Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  Route,
  Link,
  useRouteMatch,
  useParams,
  Redirect,
  Switch,
  useHistory,
} from "react-router-dom";
import flow from "lodash/flow";

import { buildRoute, getParentRoute } from "msa2-ui/src/utils/urls";

import {
  delegationProfileTypes,
  getDelegationProfile,
  getDelegationProfilesList,
} from "msa2-ui/src/store/delegationProfiles";

import Repository from "msa2-ui/src/services/Repository";

import { Avatar, Grid, IconButton, Typography } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Folder from "@material-ui/icons/Folder";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import AuthorizedRouteWithSubtenant from "msa2-ui/src/components/AuthorizedRouteWithSubtenant";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";

import BpmDetails from "./BpmDetails";
import BpmScheduled from "./BpmScheduled";
import BpmEdit from "./Edit";
import BpmExecute from "./ExecuteWithRouting";
import BpmRead from "./Read";
import BpmDeploymentEdit from "./DeploymentEdit";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { getListOwnerDetails } from "msa2-ui/src/api/workflow";
import { readRepository } from "msa2-ui/src/api/repository";

export const useStyles = makeStyles(
  ({ darkMode, palette, typography, spacing }) => ({
    link: {
      display: "flex",
      textDecoration: "none",
      color: "inherit",
      width: "fit-content",
      marginBottom: 20,
    },
    backIcon: {
      color: darkMode ? palette.secondary.main : palette.primary.main,
    },
    back: {
      display: "flex",
      alignItems: "center",
      color: darkMode ? palette.secondary.main : palette.primary.main,
      letterSpacing: 0.3,
      fontSize: 13,
    },
    avatar: {
      margin: "0px 16px",
      width: 56,
      height: 56,
      backgroundColor: palette.primary.main,
    },
    bpmFileName: {
      fontWeight: typography.fontWeightMedium,
      marginRight: 15,
    },
  }),
);

const Detail = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { bpmUri } = useParams();
  const bpmFilename = flow(
    decodeURIComponent,
    Repository.getFilenameFromUri,
  )(bpmUri);

  const [canEditBpm, canManageBpm] = useSelector(
    getDelegationProfilesList([
      [delegationProfileTypes.BPM, "general.modify"],
      [delegationProfileTypes.BPM, "instance.execute"],
    ]),
    shallowEqual,
  );

  const [, , ownerDetails, ,] = useApi(
    getListOwnerDetails,
    {
      body: [
        Repository.stripFileExtensionFromString(decodeURIComponent(bpmUri)),
      ],
    },
    !isWorkflowsOwnerDetailsEnabled,
  );

  const [
    fetchDiagramXmlLoading,
    ,
    fetchDiagramXmlResponse,
    ,
    reloadDiagramXmlResponse,
  ] = useApi(readRepository, { uri: decodeURIComponent(bpmUri) }, !bpmUri);

  const canModifyBpm = ownerDetails
    ? ownerDetails[decodeURIComponent(bpmUri)]?.permission?.modify
    : true;

  const canExecuteBpm = ownerDetails
    ? ownerDetails[decodeURIComponent(bpmUri)]?.permission?.execute
    : true;

  const canViewBPMSchedule = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "schedule.view"),
  );

  const BPM_DETAILS_TABS = [
    {
      destination: "instances",
      displayName: "Instance",
      id: "BPM_DETAILS_INSTANCE_TAB",
      component: BpmDetails,
      canAccess: true,
    },
    {
      destination: "scheduled",
      displayName: "Scheduled BPM",
      id: "BPM_DETAILS_SCHEDULE_TAB",
      component: BpmScheduled,
      canAccess: canViewBPMSchedule,
    },
  ];

  const tabs = BPM_DETAILS_TABS.map((tab, i) => ({
    ...tab,
    displayName: t(tab.displayName),
    path: buildRoute(url, tab.destination),
  }));

  const defaultRoute = tabs[0].path;

  const renderHeader = () => {
    return (
      <Fragment>
        <div className={commonClasses.commonFlexStart}>
          <Typography variant="h2" noWrap className={classes.bpmFileName}>
            {fetchDiagramXmlResponse?.displayName ?? bpmFilename}
          </Typography>
          {canModifyBpm && (
            <DelegationProfiles
              type={delegationProfileTypes.BPM}
              action="general.modify"
            >
              <Link
                className={commonClasses.commonNoTextDecoration}
                id={"BPM_DETAILS_BTN_EDIT_LINK"}
                to={{
                  pathname: buildRoute(url, "edit"),
                  state: { referer: path },
                }}
              >
                <IconButton
                  id="BPM_DETAILS_BTN_EDIT"
                  aria-label={t("Edit BPM")}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </Link>
            </DelegationProfiles>
          )}
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      {/* go back to BPM list page */}
      <Link
        id="BPM_DETAILS_LINK_BACK_TO_BPM_LIST"
        className={classes.link}
        to={getParentRoute(url)}
      >
        <KeyboardArrowLeft className={classes.backIcon} />
        <Typography variant="h4" className={classes.back}>
          {t("Back to BPM List")}
        </Typography>
      </Link>
      <Grid container className={commonClasses.commonTitleWrapper}>
        <Grid item xs={1} className={commonClasses.commonFlexCenter}>
          <Avatar className={classes.avatar}>
            <Folder />
          </Avatar>
        </Grid>
        <Grid item xs={8}>
          {!fetchDiagramXmlLoading && renderHeader()}
        </Grid>
        <Grid item xs={3} className={commonClasses.commonCreateButton}>
          {((isWorkflowsOwnerDetailsEnabled && canExecuteBpm) ||
            !isWorkflowsOwnerDetailsEnabled) && (
            <CreateEntityButton
              id="BPM_DETAILS_EXECUTE_BPM"
              aria-label="Execute BPM"
              delegationProfileAction="instance.execute"
              delegationProfileType={delegationProfileTypes.BPM}
              link={buildRoute(url, "execute")}
            >
              {t("Execute BPM")}
            </CreateEntityButton>
          )}
        </Grid>
      </Grid>

      <Switch>
        <Route exact path={path}>
          <Redirect to={defaultRoute} />
        </Route>
        {tabs.map(({ destination, component: Component }, i) => {
          return (
            <Route
              key={i}
              exact
              path={buildRoute(path, destination)}
              render={() => <Component bpmFilename={bpmFilename} tabs={tabs} />}
            />
          );
        })}
        <AuthorizedRoute
          exact
          guard={canEditBpm}
          redirect={defaultRoute}
          path={buildRoute(path, "edit")}
          render={() => (
            <BpmEdit
              onClose={() => {
                reloadDiagramXmlResponse();
                history.push(url);
              }}
            />
          )}
        />
        <AuthorizedRouteWithSubtenant
          exact
          guard={canManageBpm}
          redirect={defaultRoute}
          path={buildRoute(path, "execute")}
          render={() => <BpmExecute />}
        />
        <Route
          exact
          path={buildRoute(path, ":definitionId/edit")}
          render={() => <BpmDeploymentEdit />}
        />
        <Route
          exact
          path={buildRoute(path, ":definitionId/:instanceId")}
          render={() => <BpmRead />}
        />
        <Redirect to={defaultRoute} />
      </Switch>
    </div>
  );
};

export default Detail;
