import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import useToggle from "react-use/lib/useToggle";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import {
  getUserRole,
  getIsDeveloper,
  getRoleDetails,
} from "msa2-ui/src/store/auth";
import {
  selectSubtenant,
  selectTenant,
  getAvailableSubtenants,
  getAvailableTenants,
  getSelectedSubtenant,
  getSelectedTenant,
  isMultiTenant,
} from "msa2-ui/src/store/designations";

import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  Backdrop,
  Hidden,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";

import MsaDeveloperLogo from "msa2-ui/src/assets/img/logo-MSADeveloper.png";
import MsaManagerLogo from "msa2-ui/src/assets/img/logo-MSAManager.png";
import AlarmNotification from "./AlarmNotification";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import DashboardSearch from "./searchBar/DashboardSearch";
import TenantSubtenantSelection from "msa2-ui/src/components/TenantSubtenantSelection";
import MainMenu from "./MainMenu";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import innerBus from "msa2-ui/src/utils/InnerBus";
import MainMenuItem from "msa2-ui/src/components/MainMenuItem";

const isBPMEnabled = FeatureFlag.isEnabled(FeatureFlag.features.enableBPM);
const drawerWidth = 181;
const drawerWidthClosed = 60;

const useStyles = makeStyles((theme) => {
  const { darkMode, palette, typography, transitions, breakpoints } = theme;

  return {
    fullHeight: {
      display: "flex",
      minHeight: "100vh",
    },
    appBar: {
      background: darkMode
        ? `linear-gradient(-90deg, ${palette.background.paper} 30%, #0A3255 50%, ${palette.background.paper} 70%)`
        : theme.colors.blueDark2,
      transition: transitions.create(["width", "margin"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      zIndex: theme.zIndex.drawer + 1,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: transitions.create(["width", "margin"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    search: {
      position: "absolute",
      top: 14,
      right: 10,
    },
    alarm: {
      position: "absolute",
      top: 10,
      right: 70,
    },
    searchPaper: {
      top: "0",
      transition: transitions.create(["width", "margin"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      position: "absolute",
      height: theme.topbar.height,
      backgroundColor: palette.background.paper,
      outline: "none",
    },
    searchSmall: {
      left: drawerWidth,
      right: 0,
    },
    searchFull: {
      left: 60,
      right: 0,
    },
    menuButton: {
      marginLeft: 6,
      marginRight: 28,
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        top: 10,
        left: 6,
        margin: 0,
      },
    },
    listItemIcon: {
      marginRight: 10,
      color: darkMode ? palette.text.hint : "#c7cdd8",
    },
    listItemText: {
      fontSize: "0.813rem",
      color: darkMode ? palette.text.hint : "#c7cdd8",
      margin: "0",
      padding: "0",
    },
    drawerRoot: {
      width: drawerWidth,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    drawerRootClose: {
      width: drawerWidthClosed,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    drawerPaper: {
      height: "100%",
      border: 0,
      position: "fixed",
      width: drawerWidth,
      background: darkMode ? palette.background.paper : palette.primary.main,
      overflow: "hidden",
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      width: drawerWidthClosed,
      [theme.breakpoints.up("sm")]: {
        width: drawerWidthClosed,
      },
      "& $listItemIcon": {
        marginRight: 18,
      },
      "& $plusSign": {
        marginLeft: 6,
        marginRight: 27,
      },
      "& $badgeRoot": {
        right: "auto",
        left: -16,
        top: -10,
      },
    },
    toolbar: {
      alignItems: "center",
      backgroundImage: (props) =>
        `url(${props.isDeveloper ? MsaDeveloperLogo : MsaManagerLogo})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "15px 16px",
      backgroundSize: "65%",
      display: "flex",
      justifyContent: "flex-end",
      minHeight: "60px",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    primaryMenu: {
      flexGrow: 1,
    },
    sidebar: {
      padding: 0,
    },
    chevrons: {
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        top: 12,
      },
    },
    sidebarLinks: {
      textDecoration: "none",
      color: "#fff",
      display: "block",
    },
    disabledLink: {
      pointerEvents: "none",
      cursor: "default",
    },
    sidebarBtn: {
      padding: "10px 18px",
      fontSize: "12px",
      margin: "8px 0",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: darkMode ? palette.background.paper : "#394766",
      },
    },
    sidebarDeveloperBtn: {
      padding: 10,
      paddingLeft: 14,
      borderRadius: 22,
      background: darkMode ? palette.primary.main : theme.colors.green,
      boxShadow: darkMode
        ? `1px 0 5px 1px #4397D6`
        : "0 3px 8px 0 rgba(0, 0, 0, 0.16)",
      margin: "10px 3px",
      whiteSpace: "nowrap",
      fontSize: "0.8125rem",
      color: "#fff",
      width: "92%",
      "&:hover": {
        background: darkMode ? palette.primary.main : theme.colors.green,
      },
      "& $listItemText": {
        color: "#fff",
        fontWeight: typography.fontWeightMedium,
      },
    },
    plusSign: {
      display: "inline-block",
      fontSize: "1.375rem",
      lineHeight: "1.5rem",
      fontWeight: 800,
      marginRight: 13,
      marginLeft: 8,
    },
    createLink: {
      textDecoration: "none",
      color: palette.text.primary,
    },
    sidebarLinksActive: {
      padding: 0,
      "& $sidebarBtn": {
        margin: 0,
        padding: 18,
        background: darkMode
          ? palette.background.default
          : theme.colors.greyLight3,
        borderRadius: "30px 0 0 30px",
      },
      "& $listItemText": {
        color: darkMode ? palette.text.primary : theme.colors.blueDark1,
        fontSize: "1rem",
        fontWeight: 500,
      },
      "& $listItemIcon": {
        color: darkMode ? palette.primary.main : theme.colors.blueDark1,
      },
    },
    badgeRoot: {
      left: -10,
    },
    badgeInside: {
      color: "#fff",
      fontSize: "0.6875rem",
      fontWeight: typography.fontWeightMedium,
      background: theme.colors.orange,
    },
    menuDivider: {
      background: "#c7cdd8",
      opacity: darkMode ? 0 : 0.2,
    },
    topToolbar: {
      backgroundColor: darkMode ? "transparent" : "#fff",
      color: palette.text.primary,
      borderBottom: darkMode ? "none" : "1px solid #f7f7f7",
      padding: "0 24px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
    topToolbarFullWidth: {
      padding: "0 20px 0 0",
    },
    content: {
      backgroundColor: palette.background.paper,
      position: "relative",
      flexGrow: 1,
      minWidth: 1, // Content should have some width, otherwise it's growth is unlimited
      [theme.breakpoints.down("md")]: {
        width: `calc(100% - ${drawerWidthClosed})`,
      },
    },
    mainContent: {
      background: palette.background.default,
      padding: "18px 24px",
      height: `calc(100% - ${theme.topbar.height}px)`,
    },
    pageTitle: {
      fontSize: 20,
      fontWeight: typography.fontWeightMedium,
    },
    userDisplay: {
      fontSize: "1rem",
      lineHeight: "1rem",
      letterSpacing: 0.5,
      minWidth: 200,
      marginTop: 3,
    },
    developerTitle: {
      borderRadius: 8,
      padding: "6px 12px",
      background: theme.colors.green,
      color: "#fff",
      fontSize: "1rem",
      fontWeight: typography.fontWeightMedium,
      letterSpacing: 0.5,
    },
    popoutMenu: {
      fontSize: "0.875rem",
      fontWeight: typography.fontWeightMedium,
    },
    drawerBackdrop: {
      zIndex: theme.zIndex.drawer + 1,
      position: "absolute",
    },
    menuStart: {
      [breakpoints.up("md")]: {
        width: "auto",
      },
    },
    menuEnd: {
      marginLeft: "auto",
    },
    childItem: {
      marginLeft: 20,
    },
  };
});

const AppWrapper = ({ children }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const canAccessBpm = isBPMEnabled;

  const delegationProfiles = useSelector(
    ({ delegationProfiles }) => delegationProfiles,
  );
  const canCreateBpm =
    useSelector(
      getDelegationProfile(delegationProfileTypes.BPM, "general.create"),
    ) && canAccessBpm;
  const canCreateWorkflow = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "general.create"),
  );
  const canCreateMicroservice = useSelector(
    getDelegationProfile(delegationProfileTypes.MICROSERVICE, "general.create"),
  );

  const showCreateButton =
    canCreateBpm || canCreateWorkflow || canCreateMicroservice;

  const [drawerOpen, toggleDrawer] = useToggle(
    window.innerWidth > theme.breakpoints.values.md,
  );
  const [developerMenuOpen, toggleDeveloperMenu] = useToggle(false);
  const developerMenu = useRef(null);

  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);
  const multiTenant = useSelector(isMultiTenant);
  const selectedTenant = useSelector(getSelectedTenant);
  const selectedSubtenant = useSelector(getSelectedSubtenant);
  const subtenants = useSelector(getAvailableSubtenants);
  const tenants = useSelector(getAvailableTenants);
  const roleDetails = useSelector(getRoleDetails);
  const isDeveloper = useSelector(getIsDeveloper);
  const classes = useStyles({ isDeveloper });
  const isAlarmsEnabled =
    FeatureFlag.isEnabled(FeatureFlag.features.alarms) === true;

  const logsView = useSelector(
    getDelegationProfile(delegationProfileTypes.LOGS, "general.view"),
  );
  const alarmsView = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "alarms.view"),
  );
  const manageAlarmsView = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "manageAlarms.view"),
  );
  const canViewLogs =
    (logsView || alarmsView || manageAlarmsView) && isAlarmsEnabled;

  const logoutFromAllTabs = () => {
    innerBus.emit(innerBus.evt.LOGOUT);
  };

  return (
    <div className={classes.fullHeight}>
      <AppBar
        className={classnames(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
        position={"fixed"}
      >
        <Toolbar
          className={classnames(classes.topToolbar, {
            [classes.topToolbarFullWidth]: !drawerOpen,
          })}
        >
          {!drawerOpen && (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid container>
            <Grid
              item
              xs={12}
              lg={"auto"}
              className={classes.menuStart}
              container
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <TenantSubtenantSelection
                multiTenant={multiTenant}
                selectedSubtenant={selectedSubtenant}
                selectedTenant={selectedTenant}
                tenants={tenants}
                subtenants={subtenants}
                title={roleDetails.name}
                onSelectSubtenant={(subtenant) => {
                  dispatch(selectSubtenant(subtenant));
                }}
                onSelectTenant={(tenant) => {
                  dispatch(selectTenant(tenant));
                }}
              />
            </Grid>
            <Grid item xs={"auto"} className={classes.menuEnd}>
              {/* Alarm icon with badge notification*/}
              <Grid item className={classes.alarm}>
                {canViewLogs && <AlarmNotification />}
              </Grid>
              {/*Search Interface*/}
              <Grid item xs={2} className={classes.search}>
                <DashboardSearch
                  searchWrapperClass={classnames(classes.searchPaper, {
                    [classes.searchSmall]: drawerOpen,
                    [classes.searchFull]: !drawerOpen,
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: classnames(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose,
          ),
          root: classnames(
            classes.drawerRoot,
            !drawerOpen && classes.drawerRootClose,
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbar}>
          <IconButton
            className={classes.chevrons}
            onClick={toggleDrawer}
            aria-label="Open drawer"
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        {/* Sidebar */}
        <div className={classes.primaryMenu}>
          <List className={classes.sidebar}>
            {/* Alarms */}

            {/* Developer */}
            {isDeveloper && showCreateButton ? (
              <li>
                {/* User */}
                <div className={classes.sidebarLinks}>
                  <ListItem
                    id="SIDEBAR_DEVELOPER_BUTTON"
                    button
                    className={classes.sidebarDeveloperBtn}
                    onClick={toggleDeveloperMenu}
                    aria-owns={developerMenuOpen ? "developerMenu" : undefined}
                    aria-haspopup="true"
                    ref={developerMenu}
                  >
                    <span className={classes.plusSign}>+</span>
                    {drawerOpen && (
                      <ListItemText
                        primary={"Create..."}
                        classes={{
                          root: classes.listItemText,
                          primary: classes.listItemText,
                        }}
                      />
                    )}
                  </ListItem>
                  <Menu
                    id="developerMenu"
                    anchorEl={developerMenu.current}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={developerMenuOpen}
                    onClose={() => toggleDeveloperMenu(false)}
                  >
                    {canCreateBpm && (
                      <NavLink
                        id="DEVELOPER_MENU_CREATE_BPM"
                        to={"/automation/bpm/create"}
                        className={classes.createLink}
                      >
                        <MenuItem
                          className={classes.popoutMenu}
                          onClick={() => toggleDeveloperMenu(false)}
                        >
                          {t("BPM")}
                        </MenuItem>
                      </NavLink>
                    )}
                    {canCreateWorkflow && (
                      <NavLink
                        id="DEVELOPER_MENU_CREATE_WF"
                        to={"/automation/workflows/create"}
                        className={classes.createLink}
                      >
                        <MenuItem
                          className={classes.popoutMenu}
                          onClick={() => toggleDeveloperMenu(false)}
                        >
                          {t("Workflow")}
                        </MenuItem>
                      </NavLink>
                    )}
                    {canCreateMicroservice && (
                      <NavLink
                        id="DEVELOPER_MENU_CREATE_MS"
                        to={"/integration/microservices/create"}
                        className={classes.createLink}
                      >
                        <MenuItem
                          className={classes.popoutMenu}
                          onClick={() => toggleDeveloperMenu(false)}
                        >
                          {t("Microservice")}
                        </MenuItem>
                      </NavLink>
                    )}
                  </Menu>
                </div>
                <Divider className={classes.menuDivider} />
              </li>
            ) : null}
            {MainMenu.filter(({ canAccess }) =>
              canAccess({ isDeveloper, userRole, delegationProfiles }),
            ).map((item, i) => (
              <MainMenuItem key={i} minimized={!drawerOpen} item={item} />
            ))}
            <li>
              <NavLink
                id="PRIMARY_MENU_NAV_BTN_LOGIN"
                to={"/login"}
                className={classes.sidebarLinks}
                onClick={logoutFromAllTabs}
              >
                <ListItem button className={classes.sidebarBtn}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <LogoutIcon />
                  </ListItemIcon>
                  {drawerOpen && (
                    <ListItemText
                      primary={t("Logout")}
                      classes={{
                        root: classes.listItemText,
                        primary: classes.listItemText,
                      }}
                    />
                  )}
                </ListItem>
              </NavLink>
              <Divider className={classes.menuDivider} />
            </li>
          </List>
        </div>
      </Drawer>
      {/* Sidebar */}
      <main className={classes.content}>
        <Hidden mdUp>
          <Backdrop
            open={drawerOpen}
            classes={{
              root: classnames({ [classes.drawerBackdrop]: drawerOpen }),
            }}
            onClick={() => toggleDrawer(false)}
          />
        </Hidden>
        <div className={classes.toolbar} />
        <div className={classes.mainContent}>{children}</div>
      </main>
    </div>
  );
};

export default AppWrapper;
