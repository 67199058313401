import React, { Fragment, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useRouteMatch, Switch, Link } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import useApi from "msa2-ui/src/hooks/useApi";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { getWorkflow, getListOwnerDetails } from "msa2-ui/src/api/workflow";

import { getIsDeveloper } from "msa2-ui/src/store/auth";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

import { buildRoute, getParentRoute } from "msa2-ui/src/utils/urls";

import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";

import Process from "msa2-ui/src/services/Process";

import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import Folder from "@material-ui/icons/Folder";
import EditIcon from "@material-ui/icons/Create";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import RouteWithSubtenant from "msa2-ui/src/components/RouteWithSubtenant";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import SplitButton from "msa2-ui/src/components/SplitButton";
import AutomationDetailDrawer from "./AutomationDetailDrawer";
import SectionTabs from "msa2-ui/src/components/SectionTabs";
import AutomationDetails from "./AutomationDetails";
import AutomationScheduled from "./AutomationScheduled";
import AutomationEdit from "msa2-ui/src/routes/automation/workflows/edit/AutomationEdit";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";

const initialDrawerState = {
  id: "",
  name: "",
  open: false,
  process: {},
  tab: 0,
};

const AutomationTabs = ({ workflowUri, count, ...props }) => {
  const canViewWorkflowSchedule = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "schedule.view"),
  );

  const tabs = [
    {
      path: `/automation/workflows/${workflowUri}/instances`,
      pathName: "/automation/workflows/:workflowUri/instances",
      displayName: "Instances",
    },
    {
      path: `/automation/workflows/${workflowUri}/scheduled`,
      pathName: "/automation/workflows/:workflowUri/scheduled",
      displayName: "Scheduled Processes",
      canAccess: canViewWorkflowSchedule,
    },
  ];

  const tabsWithCounts = tabs.map((tab) => {
    const [path, value] = count;
    if (tab.pathName === path) {
      return {
        ...tab,
        count: value,
      };
    }
    return tab;
  });

  return <SectionTabs tabs={tabsWithCounts} variant="scrollable" {...props} />;
};

const useStyles = makeStyles(({ darkMode, palette, typography, spacing }) => ({
  link: {
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    width: "fit-content",
    marginBottom: 20,
  },
  backIcon: {
    color: darkMode ? palette.secondary.main : palette.primary.main,
  },
  backToPreviousPage: {
    display: "flex",
    alignItems: "center",
    color: darkMode ? palette.secondary.main : palette.primary.main,
    letterSpacing: 0.3,
    fontSize: 13,
  },
  avatar: {
    margin: "0px 16px",
    width: 56,
    height: 56,
    backgroundColor: palette.primary.main,
  },
  workflowName: {
    fontWeight: typography.fontWeightMedium,
    marginRight: 15,
  },
  description: {
    fontSize: 14,
    letterSpacing: 0.3,
    color: palette.text.secondary,
  },
  statusIcon: {
    marginRight: 5,
  },
  tabs: {
    paddingLeft: 20,
  },
  scrollable: {
    overflowX: "auto",
  },
  scrollButtons: {
    width: 20,
  },
  filterWrapper: {
    paddingTop: spacing(1),
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
  },
  statusCell: {
    maxWidth: "350px",
  },
  moreActionsListButton: {
    padding: "6px 0 0 10px",
  },
  blueButton: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
  },
  lightBlueButton: {
    backgroundColor: palette.primary.main,
    color: palette.common.black,
  },
  redButton: {
    backgroundColor: palette.error.main,
    color: palette.common.black,
  },
}));

const Detail = ({ isDeveloper }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { workflowUri } = useParams();
  const { path, url } = useRouteMatch();
  const [drawer, setDrawer] = useState(initialDrawerState);
  const [reloadProcessInstances, setReloadProcessInstances] = useState(false);
  const [reloadScheduledWorkflows, setReloadScheduledWorkflows] = useState(
    false,
  );
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );

  const [, , ownerDetails, ,] = useApi(
    getListOwnerDetails,
    {
      body: [decodeURIComponent(workflowUri)],
    },
    !isWorkflowsOwnerDetailsEnabled,
  );

  const canModifyWorkflows = ownerDetails
    ? ownerDetails[decodeURIComponent(workflowUri + ".xml")]?.permission?.modify
    : true;

  const canExecuteWorkflows = ownerDetails
    ? ownerDetails[decodeURIComponent(workflowUri + ".xml")]?.permission
        ?.execute
    : true;
  const [
    workflowDefinitionLoading,
    workflowDefinitionError,
    workflowDefinition,
    ,
    reloadWorkflowDefinitions,
  ] = useApi(getWorkflow, {
    pathToWorkflowDefinitionFile: decodeURIComponent(workflowUri),
  });

  const reloadApi = useCallback(() => {
    reloadWorkflowDefinitions();
    setReloadProcessInstances(true);
    setReloadScheduledWorkflows(true);
  }, [reloadWorkflowDefinitions]);

  const onDrawerClose = () => {
    reloadApi();
    setDrawer(initialDrawerState);
  };

  const createProcesses =
    workflowDefinition?.process?.filter((processObject) =>
      Process.isCreate(processObject.type),
    ) ?? [];

  const handleRunCreateProcess = (objProcess) => {
    const { process: processes } = workflowDefinition;
    const process = processes.find((prc) => prc.name === objProcess.name);

    setDrawer({
      ...drawer,
      open: true,
      process,
      tab: 0,
    });
  };

  const renderBackToPreviousPage = () => {
    return (
      <Link
        id="AUTOMATION_DETAILS_LINK_BACK_TO_WORKFLOWS"
        className={classes.link}
        to={{
          pathname: url
            .split("/")
            .slice(0, -1)
            .join("/"),
        }}
      >
        <KeyboardArrowLeft className={classes.backIcon} />
        <Typography variant="h4" className={classes.backToPreviousPage}>
          {t("Back to Workflow List")}
        </Typography>
      </Link>
    );
  };

  const renderHeader = () => {
    return (
      <Grid container className={commonClasses.commonTitleWrapper}>
        <Grid item className={commonClasses.commonFlexCenter}>
          <Avatar className={classes.avatar}>
            <Folder />
          </Avatar>
        </Grid>
        <Grid item xs={8}>
          {workflowDefinition && (
            <Fragment>
              <div className={commonClasses.commonFlexStart}>
                <Typography
                  variant="h2"
                  noWrap
                  className={classes.workflowName}
                >
                  {workflowDefinition.information.displayName}
                </Typography>
                {isDeveloper && canModifyWorkflows && (
                  <DelegationProfiles
                    type={delegationProfileTypes.WORKFLOWS}
                    action="general.modify"
                  >
                    <Link
                      id="AUTOMATION_DETAILS_BTN_EDIT"
                      to={buildRoute(url, "edit")}
                    >
                      <IconButton>
                        <EditIcon color="primary" />
                      </IconButton>
                    </Link>
                  </DelegationProfiles>
                )}
              </div>
              <div className={commonClasses.commonFlexStart}>
                <Typography variant="body2" className={classes.description}>
                  {workflowDefinition.information.description}
                </Typography>
              </div>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={3} className={commonClasses.commonCreateButton}>
          <DelegationProfiles
            type={delegationProfileTypes.WORKFLOWS}
            action="instance.create"
          >
            {/* Show CREATE process as a button */}
            {createProcesses && canExecuteWorkflows
              ? (() => {
                  if (createProcesses.length === 1) {
                    return (
                      <Button
                        id="AUTOMATION_DETAILS_BTN_CREATE"
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classnames(
                          commonClasses.commonBtn,
                          commonClasses.commonBtnPrimary,
                          commonClasses.commonBtnLarge,
                        )}
                        onClick={() =>
                          handleRunCreateProcess(createProcesses[0])
                        }
                      >
                        <IconPlus className={commonClasses.commonBtnIcon} />
                        {createProcesses[0].displayName}
                      </Button>
                    );
                  }
                  if (createProcesses.length > 1) {
                    return (
                      <>
                        <SplitButton
                          options={createProcesses.map((objProcess, i) => {
                            const TypeIcon = Process.getIcon(objProcess.type);
                            return {
                              id: `AUTOMATION_DETAILS_BTN_CREATE_${i}`,
                              label: objProcess.displayName,
                              icon: TypeIcon,
                              onClick: () => handleRunCreateProcess(objProcess),
                            };
                          })}
                        />
                      </>
                    );
                  }
                  return null;
                })()
              : null}
          </DelegationProfiles>
        </Grid>
      </Grid>
    );
  };

  const canEditWorkflow = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "general.modify"),
  );

  const defaultRoute = buildRoute(url, "instances");

  return (
    <Fragment>
      {drawer.open && (
        <AutomationDetailDrawer
          onClose={onDrawerClose}
          tab={drawer.tab}
          instanceId={drawer.id}
          instanceName={drawer.name}
          execProcess={drawer.process}
          workflow={workflowDefinition}
        />
      )}
      <div>
        {renderBackToPreviousPage()}
        {renderHeader()}
        <Switch>
          <RouteWithSubtenant
            exact
            path={path}
            redirectTo={getParentRoute(url)}
          >
            <Redirect to={defaultRoute} />
          </RouteWithSubtenant>
          <AuthorizedRoute
            exact
            guard={isDeveloper && canEditWorkflow}
            redirect={defaultRoute}
            path={buildRoute(path, "edit")}
            render={() => <AutomationEdit reload={reloadApi} />}
          />
          <RouteWithSubtenant
            exact
            path={buildRoute(path, "instances/:serviceId?")}
            redirectTo={getParentRoute(url)}
            render={() => (
              <AutomationDetails
                sectionTabs={AutomationTabs}
                workflowDefinition={workflowDefinition}
                workflowDefinitionError={workflowDefinitionError}
                workflowDefinitionLoading={workflowDefinitionLoading}
                reload={reloadProcessInstances}
                setReload={setReloadProcessInstances}
              />
            )}
          />
          <RouteWithSubtenant
            exact
            path={buildRoute(path, "scheduled")}
            redirectTo={getParentRoute(url)}
            render={() => (
              <AutomationScheduled
                sectionTabs={AutomationTabs}
                reload={reloadScheduledWorkflows}
                setReload={setReloadScheduledWorkflows}
                workflowDefinition={workflowDefinition}
              />
            )}
          />
          <Redirect to={defaultRoute} />
        </Switch>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDeveloper: getIsDeveloper(state),
});

export default connect(mapStateToProps)(Detail);
