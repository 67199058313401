import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Cell, PieChart, Pie } from "recharts";
import NumberFormat from "react-number-format";

import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import {
  Close,
  MoreHoriz,
  PauseCircleOutline,
  PriorityHigh,
} from "@material-ui/icons";
import createTheme from "msa2-ui/src/styles/theme";
import commonStyles from "msa2-ui/src/styles/commonStyles";

import GraphTableView from "msa2-ui/src/routes/dashboard/GraphTableView";
import NotificationsOutlined from "@material-ui/icons/NotificationsOutlined";

const chartWrapperPadding = 30;

const localStyles = ({
  darkMode,
  palette,
  typography,
  colors,
  breakpoints,
}) => ({
  chartName: {
    color: palette.text.primary,
    textAlign: "center",
    fontSize: "0.875rem",
    fontWeight: typography.fontWeightRegular,
    margin: "0 auto",
    marginBottom: 10,
    maxWidth: 200,
  },
  chartTotal: {
    textAlign: "center",
    fontWeight: "inherit",
    marginBottom: "inherit",
    width: "inherit",
    position: "absolute",
    fontSize: "28px",
    lineHeight: "5",
  },
  chartText: {
    textAlign: "center",
    fontWeight: "inherit",
    marginBottom: "inherit",
    width: "inherit",
    position: "absolute",
    fontSize: "14px",
    lineHeight: "13",
    "&:hover": {
      cursor: "pointer",
    },
  },
  chartInstance: {
    margin: "0 auto",
    width: 132,
  },
  chartWrapper: {
    padding: chartWrapperPadding,
    position: "relative",
    borderTop: darkMode ? "none" : "1px solid #e7eaee",
    borderRight: darkMode ? "none" : "1px solid #e7eaee",
    margin: "0 auto",
    "&:first-child:nth-last-child(1)": {
      borderRight: 0,
    },
    [breakpoints.down("sm")]: {
      width: "50%",
      "&:nth-child(2n)": {
        borderRight: 0,
      },
    },
    [breakpoints.only("md")]: {
      width: "33.333%",
      "&:nth-child(3n)": {
        borderRight: 0,
      },
    },
    [breakpoints.up("lg")]: {
      width: "25%",
      "&:nth-child(4n)": {
        borderRight: 0,
      },
    },
  },
  badgeWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    marginTop: "5px",
  },
  badgeIcon: {
    fontSize: "18px",
    fontWeight: typography.fontWeightMedium,
    color: "#ffffff",
    paddingTop: "5px",
    paddingLeft: "3px",
  },
  badgeFont: {
    fontFamily: "'Rubik', 'Helvetica', 'Arial', 'sans-serif'",
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightMedium,
    paddingTop: "3px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.3px",
    color: "#ffffff",
    alignContent: "center",
    paddingRight: "6px",
  },
  badgeCritical: {
    borderRadius: "12px",
    backgroundColor: "#f5b622",
    display: "flex",
    minHeight: "23px",
    margin: "0 4px",
  },
  badgeNotConnected: {
    borderRadius: "12px",
    backgroundColor: "#b1bbcc",
    display: "flex",
    minHeight: "23px",
    margin: "0 4px",
  },
  badgePause: {
    borderRadius: "12px",
    backgroundColor: colors.blueLight,
    display: "flex",
    minHeight: "23px",
    margin: "0 4px",
  },
  badgeUnreachable: {
    borderRadius: "12px",
    backgroundColor: colors.red,
    display: "flex",
    minHeight: "23px",
    margin: "0 4px",
  },
  seeMoreWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  textSeeMore: {
    textAlign: "center",
    fontSize: "1.1rem",
    fontWeight: typography.fontWeightMedium,
  },
  badgeAlarm: {
    borderRadius: "12px",
    display: "flex",
    minHeight: "23px",
    margin: "0 4px",
    position: "relative",
    color: "red",
  },
  alarmIcon: {
    fill: "red",
    height: "25px",
    width: "25px",
  },
  numberBadge: {
    position: "absolute",
    top: "0px",
    right: "0px",
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "3px",
    fontWeight: "bold",
    fontSize: "12px",
    width: "auto",
    height: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(50%, -50%)",
    zIndex: 1,
  },
});

const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

class DoughnutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openPopover: false,
      subtenantName: "",
    };
  }

  handleOnChartClick = (event, name) => {
    this.setState({
      openPopover: !this.state.openPopover,
      subtenantName: name,
      anchorEl: event.currentTarget,
    });
  };

  formatMetricPrefixes = (val) => {
    const met = ["", "k", "M", "G", "T"];
    const dot = val.length % 3;
    return (
      val.slice(0, dot) +
      (dot === 0 || val.length < 3 ? "" : ".") +
      val.slice(dot, 3) +
      met[Math.floor((val.length - 1) / 3)]
    );
  };

  render() {
    const {
      classes,
      chartData,
      chartDataText,
      size,
      maxCharts,
      noborder,
      theme,
    } = this.props;

    const { palette, colors } = createTheme(theme);
    // Slice chartData at limit number and show "See more xx ".
    // If actual size and max size are the same, show the last data instaead of  "See more xx "
    const iDispChart =
      maxCharts === chartData.length ? maxCharts : maxCharts - 1;
    return chartData.slice(0, iDispChart).map((chart, i) => {
      const chartThickness = 0.1;
      const chartPadding = 0.13;

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
        <div
          key={chart.key}
          className={classes.chartWrapper}
          onClick={(event) => this.handleOnChartClick(event, chart.name)}
          style={{
            minWidth: size + chartWrapperPadding,
            ...(noborder && { border: 0 }),
          }}
          role="button"
        >
          {chart.subtenant &&
            this.state.openPopover &&
            this.state.subtenantName === chart.name && (
              <GraphTableView
                anchorEl={this.state.anchorEl}
                chart={chart.subtenant}
                handleTitleOnClick={this.props.handleTitleOnClick}
                handleDataOnClick={this.props.handleDataOnClick}
              />
            )}
          {/* Subtenant Name */}
          {chart.name && (
            <Typography variant="h4" className={classes.chartName} noWrap>
              {chart.name}
            </Typography>
          )}

          {/* Chart */}
          <div className={classes.chartInstance} style={{ width: size }}>
            <Typography variant="h4" className={classes.chartTotal}>
              <NumberFormat
                value={chart.total}
                displayType={"text"}
                format={this.formatMetricPrefixes}
              />
            </Typography>
            <Typography
              variant="h4"
              className={classes.chartText}
              title={chartDataText}
            >
              {chartDataText}
            </Typography>

            <PieChart width={size} height={150}>
              {chart.graph.map((entry, index) => {
                // in case no data, create dashed chart
                entry.filter((arr) => arr.value !== 0).length === 0 &&
                  entry.push({ name: "no data" });
                return (
                  <Pie
                    startAngle={90}
                    endAngle={-270}
                    data={entry}
                    dataKey="value"
                    key={index}
                    innerRadius={
                      (size / 2) * (1 - index * chartPadding - chartThickness)
                    }
                    outerRadius={(size / 2) * (1 - index * chartPadding)}
                    paddingAngle={0.5}
                    cornerRadius={12}
                  >
                    <Cell
                      fill={palette.running.main}
                      stroke={palette.background.paper}
                    />
                    <Cell
                      fill={palette.error.main}
                      stroke={palette.background.paper}
                    />
                    <Cell
                      fill={palette.warning.main}
                      stroke={palette.background.paper}
                    />
                    <Cell
                      fill={palette.text.secondary}
                      stroke={palette.background.paper}
                    />
                    <Cell
                      fill={palette.primary.main}
                      stroke={palette.background.paper}
                    />
                    <Cell
                      fill={palette.success.main}
                      stroke={palette.background.paper}
                      strokeWidth={4.5}
                    />
                    {/* in case no data, create dashed chart */}
                    <Cell
                      fill={colors.greyLight1}
                      stroke={palette.background.paper}
                      strokeWidth={5}
                      value={1}
                    />
                  </Pie>
                );
              })}
            </PieChart>
          </div>

          {/* Badges */}
          <div className={classes.badgeWrapper}>
            {chart.badge.red ? (
              <div className={classes.badgeUnreachable}>
                <Close className={classes.badgeIcon} />
                <div className={classes.badgeFont}>
                  <NumberFormat
                    value={chart.badge.red}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              </div>
            ) : null}

            {chart.badge.yellow ? (
              <div className={classes.badgeCritical}>
                <PriorityHigh className={classes.badgeIcon} />
                <div className={classes.badgeFont}>
                  <NumberFormat
                    value={chart.badge.yellow}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              </div>
            ) : null}

            {chart.badge.grey ? (
              <div className={classes.badgeNotConnected}>
                <MoreHoriz className={classes.badgeIcon} />
                <div className={classes.badgeFont}>
                  <NumberFormat
                    value={chart.badge.grey}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              </div>
            ) : null}

            {chart.badge.blue ? (
              <div className={classes.badgePause}>
                <PauseCircleOutline className={classes.badgeIcon} />
                <div className={classes.badgeFont}>
                  <NumberFormat
                    value={chart.badge.blue}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              </div>
            ) : null}

            {chart.numOfDevicesWithAlarms ? (
              <div className={classes.badgeAlarm}>
                <NotificationsOutlined className={classes.alarmIcon} />
                <span className={classes.numberBadge}>
                  {chart.subtenant.alarmCount}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  }
}

DoughnutChart.propTypes = {
  chartDataText: PropTypes.string,
  size: PropTypes.number,
  chartData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  theme: state.designations.msaTheme,
});

export default connect(mapStateToProps)(withStyles(styles)(DoughnutChart));
