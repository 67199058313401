import i18n from "i18next";
import set from "lodash/set";
import get from "lodash/get";
import isNil from "lodash/isNil";
import uniqBy from "lodash/uniqBy";
import isString from "lodash/isString";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import ipRegex from "ip-regex";
import flattenObject from "msa2-ui/src/utils/flattenObject";
import Variable from "msa2-ui/src/services/Variable";

//Regex shamelessly stolen from here https://stackoverflow.com/a/9204568/2023357
const validEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const required = (value) =>
  isNil(value) || value?.length < 1
    ? i18n.t("This is a required field")
    : undefined;

const requiredFields = ({ values, requiredFields }) => {
  const errors = {};
  requiredFields.forEach((field) => {
    const mandatoryError = required(get(values, field.split(".")));
    if (mandatoryError) {
      set(errors, field, mandatoryError);
    }
  });
  return errors;
};

const validString = (value) => {
  if (!isString(value) || value?.trim().length === 0) {
    return i18n.t("Invalid");
  }
  if (Number.isInteger(Number(Variable.removePrefix(value)))) {
    return i18n.t("Numerical value cannot be a variable name");
  }
  return undefined;
};

const createUniqueParametersStringForComparison = (
  checkValues,
  paramsToCheck,
) =>
  paramsToCheck
    .map((param) => checkValues[param])
    .join()
    .replace(/[ ,]+/g, "");

const unique = (existingData, paramsToCheck) => {
  const uniqueDataValues = uniqBy(existingData, (checkValues) =>
    createUniqueParametersStringForComparison(checkValues, paramsToCheck),
  );
  if (uniqueDataValues.length < existingData.length) {
    return i18n.t("Duplicated");
  }
};

const objectStructure = (variables, variable) => {
  const { tableArray } = Variable.getTableValData(variable.name);
  const errorVariable = variables.find(({ name }) => {
    const { tableArray: targetTableArray } = Variable.getTableValData(name);
    if (tableArray.length !== targetTableArray.length) {
      const arrays = sortBy([tableArray, targetTableArray], (e) => e.length);
      // Slice the longer variable name and compare them if they are the same
      return isEqual(arrays[1].slice(0, arrays[0].length), arrays[0]);
    }
    return false;
  });
  if (errorVariable) {
    return i18n.t("These variable name cannot coexist", {
      name: Variable.removePrefix(errorVariable.name),
    });
  }
};

const ipv4 = (value) =>
  value && !ipRegex({ exact: true }).test(value)
    ? i18n.t("Invalid IPv4 address")
    : undefined;

const ipv6 = (value) =>
  value && !ipRegex.v6({ exact: true }).test(value)
    ? i18n.t("Invalid IPv6 address")
    : undefined;

const ipAddress = (value) =>
  value &&
  !ipRegex({ exact: true }).test(value) &&
  !ipRegex.v6({ exact: true }).test(value)
    ? i18n.t("Invalid IP address")
    : undefined;

const netmask = (value) => {
  if (!value) return;
  const regex = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
  return regex.test(value) ? undefined : i18n.t("Invalid Subnet mask");
};

const regex = (value) => {
  try {
    new RegExp(value);
  } catch (e) {
    return i18n.t("Invalid Regular Expression");
  }
};

const uniqueValue = (variable, dataRows, data) => (value) => {
  return Variable.isUniqueValueInMicroservice(
    value,
    variable,
    Object.values(dataRows),
    data,
  )
    ? undefined
    : i18n.t("Must be a unique value");
};

const variable = (value, variable, externalValidator) => {
  let error;
  // mandatory check
  if (variable.mandatory) {
    error = required(value);
  }
  if (error) return error;

  // regex check
  const { validationRegex } = variable;
  if (validationRegex && !regex(validationRegex) && value) {
    error = RegExp(validationRegex).test(value)
      ? undefined
      : i18n.t("Value does not match pattern.");
  }
  if (error) return error;

  // type check
  switch (variable.type) {
    case "IpAddress": {
      error = ipv4(value);
      break;
    }
    case "Ipv6Address": {
      error = ipv6(value);
      break;
    }
    case "IpMask": {
      error = netmask(value);
      break;
    }
    default:
  }
  if (error) return error;

  // custom check
  if (externalValidator) {
    error = externalValidator(value, variable);
  }

  return error;
};

// Validates all variable values, for asyncValidate
const variables = (values, variables = [], externalValidator) => {
  const validateVariable = variable;
  const flattenedData = flattenObject(values);
  return Object.entries(flattenedData).reduce((acc, [key, value]) => {
    const variableName = Variable.convertKeyToName(key);
    const variable = variables.find(({ name }) => name === variableName);
    if (!variable) return acc;
    const error = validateVariable(
      value,
      // get behaviour object if the variable is Composite
      Variable.maybeReplaceWithBehaviourObject(variable, values, key),
      externalValidator,
    );
    return error ? { ...acc, [key]: error } : acc;
  }, {});
};

export default {
  validString,
  validEmail,
  required,
  requiredFields,
  unique,
  objectStructure,
  ipv4,
  ipv6,
  ipAddress,
  netmask,
  regex,
  uniqueValue,
  variable,
  variables,
};
