import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import difference from "lodash/difference";
import classnames from "classnames";

import { displayMonthDayYearTimeDateTimeZone } from "msa2-ui/src/utils/date";
import Checked from "@material-ui/icons/Check";
import ManagedEntityService from "msa2-ui/src/services/ManagedEntity";
import { DETAIL_PROPERTIES_TO_BE_FILTERED } from "msa2-ui/src/Constants";

import {
  TableCell,
  Typography,
  Link,
  ImageList,
  ImageListItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import AttackDetails from "./AttackDetails";

import TableRow from "msa2-ui/src/components/TableRow";
import HighlighterComponent from "msa2-ui/src/components/highlight/Highlighter";

const useStyles = makeStyles(({ darkMode, palette }) => ({
  detailsToggleCell: {
    textAlign: "center",
    width: 80,
  },
  rawlog: {
    wordBreak: "break-word",
  },
  propertyItem: {
    padding: 4,
    wordBreak: "break-word",
  },
  cellAlign: {
    display: "block",
    textAlign: "center",
  },
  btnIcon: {
    width: "13px",
    height: "13px",
    backgroundColor: darkMode ? "transparent" : palette.common.black,
    borderRadius: "10px",
  },
  ackCheck: {
    color: darkMode ? palette.text.secondary : "#666",
    width: 18,
    height: 18,
  },
}));

const LogsTableRow = ({
  index,
  entry,
  checked = false,
  enableHighlighting = false,
  searchWordsToHighlight = [],
  setChecked,
  isTriggeredAlarms,
  canAck,
  columns,
  dynamicStyling,
  components,
  tableName = "logs",
}) => {
  const props = { dynamicStyling };
  const TableRowComponent = components.TableRow ?? TableRow;
  const { SeverityBadge: SeverityBadgeComponent } = components;
  const { _id: logId, fields: log } = entry;
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const history = useHistory();

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const logProperties = Object.keys(log);
  const [showAttackDialog, setShowAttackDialog] = useState(false);

  const logPropertiesInTable = columns.map(({ id }) => id);
  const logPropertiesNotInTable = difference(
    logProperties,
    logPropertiesInTable,
  );

  const logPropertiesForDetails = isTriggeredAlarms
    ? logPropertiesNotInTable.concat(["_index", "_id", "ack"])
    : logPropertiesNotInTable;

  const filteredlogPropertiesForDetails = logPropertiesForDetails.filter(
    (item) => !DETAIL_PROPERTIES_TO_BE_FILTERED.includes(item),
  );

  const ack = log.ack === "true" || log.ack === true ? true : false;

  const defaultHighlighterProps = {
    enableHighlighting,
    searchWords: searchWordsToHighlight,
  };

  // Returns link component to redirect on ME overview page
  const getMEOverviewLink = (deviceId, deviceName) => {
    return (
      <Link
        className={commonClasses.commonLink}
        onClick={() => {
          history.push(`/integration/managed-entities/${deviceId}/overview`);
        }}
      >
        {deviceName}
      </Link>
    );
  };
  const handlePropertyKey = (property) => {
    switch (property) {
      case "device_id": {
        return "Managed Entity ID";
      }
      case "device_ref": {
        return "Managed Entity Ref";
      }
      case "device_name": {
        return "Managed Entity Name";
      }
      case "device_mgmt_ip": {
        return "Managed Entity IP";
      }
      case "customer_id": {
        return "Subtenant ID";
      }
      case "customer_ref": {
        return "Subtenant Ref";
      }
      default: {
        return property;
      }
    }
  };
  const handlePropertyValue = (property) => {
    switch (property) {
      case "_id": {
        return logId;
      }

      case "ack": {
        return ack && <Checked className={classes.ackCheck} />;
      }

      case "date": {
        return displayMonthDayYearTimeDateTimeZone(log.date);
      }

      case "message": {
        return (
          <HighlighterComponent
            {...defaultHighlighterProps}
            value={log.rawlog}
          />
        );
      }

      case "severity": {
        return <SeverityBadgeComponent severityLevel={log.severity} />;
      }

      case "name": {
        const deviceId = ManagedEntityService.removePrefix(log.device_id);
        return getMEOverviewLink(deviceId, log.name);
      }

      case "device_name": {
        const deviceId = ManagedEntityService.removePrefix(log.device_id);
        return getMEOverviewLink(deviceId, log.device_name);
      }

      default: {
        return log[property] ?? entry[property];
      }
    }
  };

  const extractID = (log, str) => {
    const arr = log.split(" ");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].startsWith(str)) {
        return arr[i].substring(str.length);
      }
    }
    return "";
  };

  const getMsgID = extractID(log?.rawlog, "msg_id=");

  const handleClose = () => {
    setShowAttackDialog(false);
  };

  const isLogsPage = tableName === "logs";

  return (
    <>
      <TableRowComponent
        style={{
          backgroundColor: checked ? "rgba(255, 255, 255, 0.08)" : null,
        }}
        id={`ALARM_ROW_${index}`}
      >
        {isTriggeredAlarms && canAck && (
          <>
            <TableCell className={commonClasses.commonTableCellDefault}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid={`ACK_ALARM_UPDATE_LINK_${index}`}
                    id={`ACK_ALARM_UPDATE_LINK_${index}`}
                    checked={checked}
                    onChange={({ currentTarget: { checked } }) => {
                      setChecked(checked);
                    }}
                    inputProps={{
                      "data-testid": "upsert-ack-alarm",
                    }}
                  />
                }
              />
            </TableCell>
          </>
        )}
        {logPropertiesInTable
          .filter((key) => !["check", "details"].includes(key))
          .map((property) => (
            <TableCell
              className={commonClasses.commonTableCellDefault}
              key={property}
            >
              <Typography className={commonClasses.commonTableSecondary}>
                {handlePropertyValue(property)}
              </Typography>
            </TableCell>
          ))}
        <TableCell
          className={classnames(
            commonClasses.commonTableCellDefault,
            classes.detailsToggleCell,
          )}
        >
          <Typography className={commonClasses.commonTableSecondary}>
            <Link
              className={commonClasses.commonLink}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? t("Hide") : t("Details")}
            </Link>
          </Typography>
        </TableCell>
      </TableRowComponent>

      {isOpen && (
        <TableRowComponent
          className={
            props.dynamicStyling
              ? classnames(props.dynamicStyling.detailsRow)
              : undefined
          }
        >
          <TableCell colSpan={logPropertiesInTable.length - 1}>
            <ImageList cols={12} gap={2} rowHeight="auto">
              {filteredlogPropertiesForDetails.map((property) => {
                const value = log[property] ?? entry[property];

                if (value !== undefined && value !== null && value !== "") {
                  return (
                    <ImageListItem
                      cols={6}
                      key={property}
                      id={`ALARM_ROW_DETAILS_${property}`}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.propertyItem}
                      >
                        <strong>{handlePropertyKey(property)}</strong>:{" "}
                        {(() => {
                          if (property === "rawlog") {
                            return (
                              <HighlighterComponent
                                {...defaultHighlighterProps}
                                value={value}
                              />
                            );
                          }
                          return value;
                        })()}
                      </Typography>
                    </ImageListItem>
                  );
                }
                return null;
              })}
            </ImageList>
          </TableCell>

          <TableCell>
            {isLogsPage && getMsgID.length > 0 && (
              <Link
                className={commonClasses.commonLink}
                noWrap
                onClick={() => {
                  setShowAttackDialog(true);
                }}
              >
                {"Attack Details"}
              </Link>
            )}
          </TableCell>
          {/* This extra TableCell is due to a Material UI bug 
              where TableRow expects an array of children */}
          <TableCell colSpan={1} />
        </TableRowComponent>
      )}

      {showAttackDialog && (
        <AttackDetails
          msgId={getMsgID}
          deviceId={log?.device_id.substring(3)}
          onClose={handleClose}
        />
      )}
    </>
  );
};

LogsTableRow.propTypes = {
  entry: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  setChecked: PropTypes.func.isRequired,
  isTriggeredAlarms: PropTypes.bool,
  dynamicStyling: PropTypes.object,
  components: PropTypes.shape({
    SeverityBadge: PropTypes.elementType.isRequired,
    TableRow: PropTypes.elementType,
  }).isRequired,
};

export default LogsTableRow;
