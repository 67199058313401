import defaultsDeep from "lodash/defaultsDeep";

const makeAlarmDataObject = (alarmData) => {
  const defaultAlarmDataObject = {
    name: "",
    description: "",
    query: {
      terms: "",
      managedEntityIds: [],
      severities: [],
      subtenantIds: [],
    },
    rule: {
      count: "1",
      duration: "1",
      roles: [],
      email: false,
      workflowPath: "",
      processName: "",
      processVariables: {},
      cleanUpProcessInstanceAfterExecution: false,
      alarmSeverity: "-1",
      snmpTrap: false,
    },
    alarmClearance: {
      alarm_regex: "",
      event_regex: "",
      clearing_delay: 0,
      clear_comment: "",
    },
  };

  if (!alarmData) {
    return defaultAlarmDataObject;
  }

  return defaultsDeep(alarmData, defaultAlarmDataObject);
};

export default {
  makeAlarmDataObject,
};
