import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import union from "lodash/union";
import difference from "lodash/difference";
import classnames from "classnames";
import useApi from "msa2-ui/src/hooks/useApi";
import { useSnackbar } from "notistack";

import { getToken, getUserDetails } from "msa2-ui/src/store/auth";
import { getSelectedTenant } from "msa2-ui/src/store/designations";
import { getSubtenantsByManager } from "msa2-ui/src/api/subtenant";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  getSubtenantsByWorkflow,
  attachServicesToMultipleSubtenants,
  detachServicesFromMultipleSubtenants,
} from "msa2-ui/src/api/workflow";

import Close from "@material-ui/icons/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    padding: "10px 20px",
    height: 444,
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
  },
}));

const AutomationAttachDialog = ({ onClose, wfPath, wfName, icon }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const idKey = "ubiqubeId";
  const displayNameKey = "label";
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const token = useSelector(getToken);
  const { id: tenantId, value: tenantPrefix } = useSelector(getSelectedTenant);
  const managerId = useSelector(getUserDetails).id;
  const [loadingAttachedSubtenants, , subtenantsByWorkflow] = useApi(
    getSubtenantsByWorkflow,
    {
      managerId,
      tenantId,
      workflowPath: wfPath + ".xml",
    },
  );
  const [loadingSubtenants, , subtenants = []] = useApi(
    getSubtenantsByManager,
    {
      managerId,
    },
  );

  const [attachedSubtenants, setAttachedSubtenants] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const handleAttachAll = (target) => {
    setAttachedSubtenants(union(attachedSubtenants, target));
  };
  const handleAttach = (target) => {
    setAttachedSubtenants(union(attachedSubtenants, target));
  };
  const handleDetach = (target) => {
    setAttachedSubtenants(difference(attachedSubtenants, target));
  };
  const handleDetachAll = (target) => {
    setAttachedSubtenants(difference(attachedSubtenants, target));
  };

  const handleAttachWorkflow = async () => {
    const prevAttachedSubtenants = subtenantsByWorkflow.map(({ id }) => id);
    const newAttachedSubtenants = attachedSubtenants;

    const diffAttachments = difference(
      newAttachedSubtenants,
      prevAttachedSubtenants,
    );
    const diffDetachments = difference(
      prevAttachedSubtenants,
      newAttachedSubtenants,
    );

    setSubmitting(true);

    const [attachmentResponse, detachmentResponse] = await Promise.all([
      diffAttachments.length
        ? attachServicesToMultipleSubtenants({
            token,
            uri: wfPath + ".xml",
            ubiqubeIds: diffAttachments.join(","),
          })
        : [],
      diffDetachments.length
        ? detachServicesFromMultipleSubtenants({
            token,
            uri: wfPath + ".xml",
            ubiqubeIds: diffDetachments.join(","),
          })
        : [],
    ]);

    const error = attachmentResponse[0] || detachmentResponse[0];
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to attach subtenants to the workflow."))
      : t("Subtenants have been attached.");

    variant === "success" ? onClose() : setSubmitting(false);

    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  useEffect(() => {
    subtenantsByWorkflow &&
      setAttachedSubtenants(
        subtenantsByWorkflow.map((subtenant) => subtenant.id),
      );
  }, [subtenantsByWorkflow]);

  return (
    <>
      <Dialog
        id="AUTOMATION_ATTACH_DIALOG"
        open
        onClose={onClose}
        aria-labelledby="modalArea"
        fullWidth={true}
        maxWidth={"md"}
        classes={{
          paper: commonClasses.commonDialogPaper,
        }}
      >
        <DialogTitle
          id="modalArea"
          className={commonClasses.commonDialogHeader}
          disableTypography
        >
          {icon}
          <Typography
            variant="h4"
            className={commonClasses.commonDialogHeaderTitle}
          >
            {t("Attach", { wfName })}
          </Typography>
          <IconButton
            id="AUTOMATION_ATTACH_DIALOG_BTN_CLOSE"
            onClick={onClose}
            className={commonClasses.commonDialogHeaderCloseButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.contentWrapper}>
          <div className={classes.contentWrapper}>
            {loadingSubtenants || loadingAttachedSubtenants || submitting ? (
              <div
                className={classnames(
                  classes.itemWrapper,
                  commonClasses.commonFlexCenter,
                )}
              >
                <CircularProgress />
              </div>
            ) : (
              <AttachmentBoard
                allItems={
                  tenantPrefix
                    ? subtenants.filter(
                        (subtenant) =>
                          subtenant.operatorPrefix === tenantPrefix,
                      )
                    : subtenants
                }
                attachedItemIds={attachedSubtenants}
                noDataMessage={t("There are no Subtenants.")}
                idKey={idKey}
                displayNameKey={displayNameKey}
                unattachedTitle={t(
                  "Select Subtenants to attach the workflow to",
                )}
                attachedTitle={t("Attached Subtenants") + ": "}
                searchPlaceholder={t("Search for Subtenants")}
                handleAttachAll={handleAttachAll}
                handleAttach={handleAttach}
                handleDetach={handleDetach}
                handleDetachAll={handleDetachAll}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions
          id="AUTOMATION_ATTACH_DIALOG_ACTIONS"
          className={commonClasses.commonFlexCenter}
        >
          <Button
            id="AUTOMATION_ATTACH_DIALOG_ACTIONS_BTN_CANCEL"
            variant="text"
            size="small"
            color="default"
            className={classnames(
              commonClasses.commonBtn,
              commonClasses.commonBtnSecondary,
            )}
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            id="AUTOMATION_ATTACH_DIALOG_ACTIONS_BTN_SAVE"
            variant="contained"
            size="small"
            color="primary"
            className={classnames(
              commonClasses.commonBtn,
              commonClasses.commonBtnPrimary,
            )}
            onClick={handleAttachWorkflow}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AutomationAttachDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  wfPath: PropTypes.string.isRequired,
  wfName: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default AutomationAttachDialog;
