import { useMouseManipulations } from "msa2-ui/src/hooks/useMouseManipulations";
import { useState } from "react";

export const HANDLE_POSITION = {
  RIGHT: "RIGHT",
  LEFT: "LEFT",
};

export const useAdjustableDrawerWidth = ({
  drawerResizeHandleRef,
  defaultWidth = 0,
  minWidth = 0,
  handlePosition = HANDLE_POSITION.LEFT,
  onChange,
  onFinalChange,
}) => {
  const [width, setWidth] = useState(
    Math.min(window.innerWidth - 50, defaultWidth),
  );

  const handlers = useMouseManipulations({
    dragTimeout: 70,
    targetRef: drawerResizeHandleRef,
    onDrag: handleDrag,
    onDragEnd: handleDragEnd,
  });

  function handleDrag(evt, delta) {
    const nextWidth = calculateNextWidth(
      delta,
      width,
      minWidth,
      handlePosition,
    );

    setWidth(nextWidth);
    onChange && onChange(nextWidth);
  }

  function handleDragEnd(evt, delta) {
    const nextWidth = calculateNextWidth(
      delta,
      width,
      minWidth,
      handlePosition,
    );

    onFinalChange && onFinalChange(nextWidth);
  }

  return [width, { onMouseDown: handlers.onMouseDown }];
};

function calculateNextWidth(delta, width, minWidth, handlePosition) {
  const absX = Math.abs(delta.x);
  let nextWidth;

  if (delta.x <= 0) {
    // <-
    nextWidth =
      handlePosition === HANDLE_POSITION.LEFT ? width + absX : width - absX;
  } else {
    // ->
    nextWidth =
      handlePosition === HANDLE_POSITION.RIGHT ? width + absX : width - absX;
  }

  return Math.max(minWidth, nextWidth);
}
