import i18n from "i18next";
import validator from "validator";

export const required = (value) =>
  value || typeof value === "number"
    ? undefined
    : i18n.t("This is a required field");

export const minValue = (min) => (value) =>
  value && value < min
    ? `${i18n.t("Must be equal or more than", { x: min })}`
    : undefined;

export const maxValue = (max) => (value) =>
  value && value > max
    ? `${i18n.t("Must be equal or less than", { x: max })}`
    : undefined;

export const integerValue = (value) =>
  value && !Number.isInteger(parseFloat(value))
    ? i18n.t("Must be an integer")
    : undefined;

export const validUrl = (value) =>
  validator.isURL(value, {
    protocols: ["http", "https"],
    require_protocol: true,
  })
    ? undefined
    : i18n.t("Must be a valid URL");

export const hasUnicode = (str) =>
  [...Array(str.length)].some((_, i) => str.charCodeAt(i) > 127);
