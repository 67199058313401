import i18n from "i18next";
import produce from "immer";
import { maybeUpdateDraftWithPaths } from "msa2-ui/src/utils/immer";
import Repository from "msa2-ui/src/services/Repository";
import Workflow from "msa2-ui/src/services/Workflow";

const DIRECTORY_NAME = "Tasks";
const FILE_PREFIX = "Task_";
const BASE_TYPES = {
  BLANK: {
    label: "Blank",
    value: "blank",
  },
  TEMPLATE: {
    label: "Template",
    value: "template",
    statePath: "designations.templates",
    error: i18n.t(
      "Template not found in Repository. Please pull them from the latest OpenMSA.",
    ),
  },
  MS_CALL: {
    label: "Microservice Call",
    value: "ms_call",
    statePath: "designations.templates.msCall",
    error: i18n.t(
      "MS Call Template not found in Repository. Please pull them from the latest OpenMSA.",
    ),
  },
  EXISTING: {
    label: "Existing Task",
    value: "existing",
  },
};
const defaultObject = {
  displayName: "",
  fileName: "",
  fileUri: "",
};

const setDefaultsOnExistingObject = (defaultObject, existingObject) =>
  produce(defaultObject, (defaultObjectDraft) => {
    maybeUpdateDraftWithPaths(
      defaultObjectDraft,
      existingObject,
      Object.keys(defaultObject),
    );
  });

const make = (existingObject) => {
  if (!existingObject) {
    return defaultObject;
  }
  return setDefaultsOnExistingObject(defaultObject, existingObject);
};

const getTaskIndex = (activeWorkflowProcess, task) =>
  activeWorkflowProcess.tasks.indexOf(task);

const getTaskUri = (task) =>
  Repository.isAbsolutePath(task.fileName)
    ? task.fileName
    : `${task.fileUri}${task.fileUri && "/"}${task.fileName}`;

const getTemplateType = (taskUri) => {
  return Object.keys(Workflow.serviceTaskType).find((taskKey) => {
    return taskUri.endsWith(Workflow.serviceTaskType[taskKey].templateUri);
  });
};
export default {
  DIRECTORY_NAME,
  FILE_PREFIX,
  BASE_TYPES,
  make,
  getTaskIndex,
  getTaskUri,
  getTemplateType,
};
