import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Variable from "msa2-ui/src/services/Variable";
import Process from "msa2-ui/src/services/Process";
import MsaSelect from "msa2-ui/src/components/msa-select";

import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import RunTimeVariables from "msa2-ui/src/components/variables/RunTimeVariableFields";

const useStyles = makeStyles(({ spacing }) => ({
  heading: {
    marginBottom: spacing(2),
  },
  field: {
    marginTop: spacing(4),
    textAlign: "left",
    width: 350,
  },
}));

const UpsertAlarmActionsTab = ({
  formState,
  dispatch,
  workflows = [],
  processes = [],
  processVariableTypes = [],
  workflow,
  processVariableTypesByTask,
}) => {
  const { rule } = formState;
  const selectedProcess = processes.find(
    ({ name }) => name === rule.processName,
  );

  const { t } = useTranslation();
  const classes = useStyles();

  const [shouldShowForm, setShouldShowForm] = useState(
    Boolean(rule.workflowPath),
  );

  const workflowsOption = workflows.map(({ path, displayName }, i) => ({
    label: displayName,
    key: `workflow_${i + 1}`,
    value: path,
  }));

  const processesOption = processes.map(({ name, displayName }, i) => ({
    label: displayName,
    key: `process_${i + 1}`,
    value: name,
  }));

  const onChangeShouldExecuteWorkflow = (event) => {
    const { checked } = event.target;
    setShouldShowForm(checked);

    if (!checked) {
      dispatch({
        type: "updateRuleWorkflowPath",
        payload: "",
      });
    }
  };

  const onChangeShouldCleanUpProcessInstance = (event) => {
    dispatch({
      type: "updateRuleCleanUpProcessInstanceAfterExecution",
      payload: event.target.checked,
    });
  };

  const onChangeSelectedWorkflow = (workflow) => {
    dispatch({
      type: "updateRuleWorkflowPath",
      payload: workflow?.value ?? "",
    });
  };

  const onChangeSnmpTrap = (event) => {
    const { checked } = event.target;
    dispatch({
      type: "updateRuleSnmpTrap",
      payload: checked,
    });
  };

  const onChangeSelectedProcess = (selectedProcess) => {
    const processName = selectedProcess?.value ?? "";
    const process = processes.find(({ name }) => name === processName);
    const processVariables = Process.isCreate(process?.type)
      ? Variable.createDefaultValue(workflow?.variables.variable)
      : {};
    dispatch({
      type: "updateRuleProcessName",
      payload: { processName, processVariables },
    });
  };

  const onChangeProcessVariable = (data) => {
    dispatch({
      type: "updateRuleProcessVariable",
      payload: data,
    });
  };

  const shouldShowProcessPicker = Boolean(rule.workflowPath);
  const shouldShowProcessVariables =
    Boolean(rule.processName) && processVariableTypes.length > 0;

  return (
    <Grid container direction="column" alignItems="center">
      <Typography className={classes.heading}>
        {t("When alarm is triggered")}:
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldShowForm}
              onChange={onChangeShouldExecuteWorkflow}
              inputProps={{
                "data-testid": "upsert-alarm-form-should-execute-workflow",
              }}
            />
          }
          label={t("Execute a workflow")}
        />
        {shouldShowForm && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rule.cleanUpProcessInstanceAfterExecution}
                  onChange={onChangeShouldCleanUpProcessInstance}
                  inputProps={{
                    "data-testid":
                      "upsert-alarm-form-rule-cleanUpProcessInstanceAfterExecution",
                  }}
                />
              }
              label={t("Clean up process instance after execution")}
            />
            <MsaSelect
              id="UPSERT_ALARM_FORM_RULE_WF_PATH"
              placeholder={t("Workflow")}
              className={classes.field}
              name="upsert-alarm-form-rule-workflowPath"
              options={workflowsOption}
              value={
                workflowsOption?.find(
                  ({ value }) => value === rule.workflowPath,
                ) ?? null
              }
              onChange={onChangeSelectedWorkflow}
              onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
            />

            {shouldShowProcessPicker && (
              <MsaSelect
                id="UPSERT_ALARM_FORM_RULE_PROCESS_NAME"
                placeholder={t("Process")}
                className={classes.field}
                name="upsert-alarm-form-rule-processName"
                options={processesOption}
                value={
                  processesOption?.find(
                    ({ value }) => value === rule.processName,
                  ) ?? null
                }
                onChange={onChangeSelectedProcess}
                onKeyDown={(evt) => evt.stopPropagation()}
              />
            )}

            {shouldShowProcessVariables && (
              <RunTimeVariables
                variables={workflow.variables.variable}
                processVariables={processVariableTypesByTask}
                editMode={selectedProcess.type}
                data={rule.processVariables}
                onChange={onChangeProcessVariable}
              />
            )}
          </>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={rule.snmpTrap}
              onChange={onChangeSnmpTrap}
              inputProps={{
                "data-testid": "upsert-alarm-form-send-snap-trap",
              }}
            />
          }
          label={t(" Send a SnmpTrap ")}
        />
      </FormGroup>
    </Grid>
  );
};

UpsertAlarmActionsTab.propTypes = {
  formState: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  workflows: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ),
  processes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ),
  processVariableTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  workflow: PropTypes.shape({
    variables: PropTypes.shape({
      variable: PropTypes.array.isRequired,
    }),
  }),
  processVariableTypesByTask: PropTypes.object,
};

export default UpsertAlarmActionsTab;
