import React from "react";
import ReduxField from "redux-form/lib/Field";
import classnames from "classnames";

import { Grid, FormControl } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import RawField from "msa2-ui/src/components/variables/Field";
import createComponent from "msa2-ui/src/components/connectedFormComponents/createComponent";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: 10,
  },
}));

const component = createComponent(
  RawField,
  ({
    meta,
    input: { value, ...inputProps },
    onChange: _onChange,
    ...props
  }) => {
    const onClick = (val) => {
      inputProps.onChange(val);
      _onChange && _onChange(val);
    };
    const onChange = ({ target: { value } }) => {
      inputProps.onChange(value);
      _onChange && _onChange(value);
    };

    const onBlur = () => inputProps.onBlur(value);
    return {
      ...props,
      value,
      inputProps: {
        ...inputProps,
        ...props.inputProps,
        id: props.id,
        "aria-label": props.label,
        error: Boolean(meta.error),
        helperText: meta.error,
      },
      onClick,
      onChange,
      onBlur,
    };
  },
);

const Field = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={props.fieldColumnWidth}
      className={classnames({
        [classes.field]: props.isNested,
      })}
    >
      <FormControl fullWidth>
        <ReduxField
          component={component}
          name={props.name}
          props={{ ...props }}
          validate={props.validate}
        />
      </FormControl>
    </Grid>
  );
};

Field.defaultProps = {
  fieldColumnWidth: 12,
};
export default Field;
