import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { isObject, snakeCase, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { listAllLabels } from "msa2-ui/src/api/managedEntity";
import { getToken } from "msa2-ui/src/store/auth";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";
import { MsaSelectCreatable } from "msa2-ui/src/components/msa-select";
import { isEmpty } from "lodash";

const MAP_CATEGORY_TO_FEATURE_FLAG = {
  monitoringProfiles: { NULL: FeatureFlag.features.monitoringProfiles },
  auditLogs: { NULL: FeatureFlag.features.profileAuditLogs },
  ai: { NULL: FeatureFlag.features.ai },
  managedEntities: {
    obmf: {
      edit: FeatureFlag.features.msEditVariableInConsole,
    },
  },
};
const MAP_CATEGORY_TO_LABEL_PARAM = {
  microservice: listAllLabels.categories.MICROSERVICE,
  managedEntities: listAllLabels.categories.MANAGED_ENTITY,
};

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const getCategoryId = (category) => {
  return `PERMISSIONS_TREE_CATEGORY_LABEL_${snakeCase(category)}`.toUpperCase();
};

const getCategoryTitle = (category) => {
  const CATEGORY_TITLES = {
    ai: "Intent-Based",
    bpm: "BPM",
  };

  return CATEGORY_TITLES[category] || startCase(category);
};

const getSubcategoryCheckboxId = ({ category, subCategory }) => {
  return `PERMISSIONS_TREE_SUBCATEGORY_CHECKBOX_${snakeCase(
    category,
  )}_${snakeCase(subCategory)}`.toUpperCase();
};

const getSubCategoryTitle = (subCategory) => {
  const SUB_CATEGORY_TITLES = {
    obmf: "Configure",
    labels: "Tags",
  };

  return SUB_CATEGORY_TITLES[subCategory] || startCase(subCategory);
};

const getActionId = ({ category, subCategory, action }) => {
  return `PERMISSIONS_TREE_ACTION_CHECKBOX_${snakeCase(category)}_${snakeCase(
    subCategory,
  )}_${snakeCase(action)}`.toUpperCase();
};

const getActionTitle = (action) => {
  const ACTION_TITLES = {
    modify: "Edit",
    provisioning: "Activate",
  };

  return ACTION_TITLES[action] || startCase(action);
};

const useStyles = makeStyles(() => ({
  categoriesSidebar: {
    position: "absolute",
    width: 250,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderColor: "rgba(255, 255, 255, 0.12)",
    marginRight: 30,
    paddingRight: 20,
  },
  scrollContainer: {
    marginLeft: 300,
  },
  subCategoryLabel: {
    textTransform: "uppercase",
    fontWeight: 600,
  },
  subCategoryDescription: {
    textAlign: "left",
    margin: "-5px 0px 5px 30px",
  },

  subCategoryDivider: {
    marginTop: 10,
    marginBottom: 10,
  },
  action: {
    marginLeft: 20,
  },
  actionLabel: {
    textTransform: "capitalize",
  },
  labelsInput: {
    alignItems: "center",
    paddingLeft: "35px",
    marginTop: "9px",
  },
}));

const PermissionsTab = ({ actions, permissionsList = [], setActions }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const token = useSelector(getToken);
  const categories = Object.keys(actions);
  const [activeCategory, setActiveCategory] = useState(categories[0]);
  const [labelOptions, setLabelOptions] = useState({});
  useEffect(() => {
    if (actions && isPermissionProfileLabelsEnabled) {
      const categoriesToGetLabels = Object.entries(
        actions,
      ).filter(([, { labels }]) => Boolean(labels));

      // Call API to get Label options
      Promise.all(
        categoriesToGetLabels.map(([category]) =>
          listAllLabels({
            category: MAP_CATEGORY_TO_LABEL_PARAM[category],
            token,
          }),
        ),
      ).then((responses) => {
        const labelOptions = responses.reduce((acc, [, labels = []], index) => {
          const category = categoriesToGetLabels[index][0];
          return { ...acc, [category]: labels };
        }, {});
        setLabelOptions(labelOptions);
      });
    }
  }, [actions, token]);

  //Iterates over existing labels from API and transforms to actions format
  const extractLabels = (permissionsList) => {
    const labels = {};

    permissionsList.forEach((profile) => {
      if (profile.subCategoryName === "labels") {
        const value = {
          category: profile.categoryName,
          subCategory: profile.subCategoryName,
          action: profile.actionName,
          value: profile.right,
        };
        if (profile.categoryName in labels) {
          labels[profile.categoryName] = [
            ...labels[profile.categoryName],
            { label: profile.actionName, value },
          ];
        } else {
          labels[profile.categoryName] = [{ label: profile.actionName, value }];
        }
      }
    });
    return labels;
  };

  const labels = extractLabels(permissionsList);

  const [selectedLabels, setSelectedLabels] = useState(labels);

  const onChangeSubCategory = ({ event, category, subCategory }) => {
    const { checked } = event.target;
    const subCategoryActions = actions[category][subCategory];

    for (const action in subCategoryActions) {
      if (
        Object.prototype.hasOwnProperty.call(subCategoryActions, action) &&
        isObject(subCategoryActions[action])
      ) {
        subCategoryActions[action].value = checked;
      }
    }

    setActions({
      ...actions,
      [category]: {
        ...actions[category],
        [subCategory]: {
          ...subCategoryActions,
          value: checked,
        },
      },
    });
  };

  const onChangeAction = ({ event, category, subCategory, action }) => {
    const { checked } = event.target;

    const allActionsAreChecked = (subCategory) => {
      const allActions = Object.values(subCategory).filter((item) =>
        Object.prototype.hasOwnProperty.call(item, "value"),
      );
      const checkedActions = Object.values(allActions).filter(
        (item) => item.value,
      );
      return checkedActions.length === Object.values(allActions).length;
    };

    const newActions = {
      ...actions,
      [category]: {
        ...actions[category],
        [subCategory]: {
          ...actions[category][subCategory],
          [action]: {
            ...actions[category][subCategory][action],
            value: checked,
          },
        },
      },
    };

    setActions({
      ...newActions,
      [category]: {
        ...newActions[category],
        [subCategory]: {
          ...newActions[category][subCategory],
          value: allActionsAreChecked(newActions[category][subCategory]),
        },
      },
    });
  };

  const renderCategory = (category, data) => {
    const subCategories = Object.keys(data);

    return subCategories
      .filter(
        (subCategory) =>
          subCategory !== "labels" || isPermissionProfileLabelsEnabled,
      )
      .map((subCategory, index, filtered) =>
        renderSubCategory({
          category,
          subCategory,
          data: data[subCategory],
          lastChild: index === filtered.length - 1,
          index,
        }),
      );
  };

  //mapper to convert existing labels array to MSA Select options format
  const labelsToMsaSelectOptions = (labels) => {
    return !isEmpty(labels) ? labels.map((c) => ({ label: c, value: c })) : [];
  };
  const renderSubCategory = ({
    category,
    subCategory,
    data,
    lastChild,
    index,
  }) => {
    const { value } = data;
    const actionsList = Object.keys(data);

    const someActionsAreChecked = ({
      category,
      subCategory,
      subCategoryChecked,
    }) => {
      const subCategoryActions = actions[category][subCategory];
      const checkedActions = Object.values(subCategoryActions).filter(
        (item) => item.value,
      );
      return checkedActions.length > 0 && !subCategoryChecked;
    };

    return isObject(data) ? (
      <Grid
        key={index}
        container
        direction="column"
        data-testid="permissions-tab-container"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={
                (subCategory === "labels" &&
                  selectedLabels[category]?.some((item) => item.value.value)) ||
                value
              }
              name={subCategory}
              indeterminate={
                subCategory !== "labels" &&
                someActionsAreChecked({
                  category,
                  subCategory,
                  subCategoryChecked: value,
                })
              }
              onChange={(event) => {
                !isEmpty(selectedLabels[category]) &&
                  subCategory === "labels" &&
                  removeExisitngLabels(category);
                onChangeSubCategory({ event, category, subCategory });
              }}
              id={getSubcategoryCheckboxId({ category, subCategory })}
            />
          }
          label={
            <Typography className={classes.subCategoryLabel}>
              {t(getSubCategoryTitle(subCategory))}
            </Typography>
          }
        />
        <Typography
          variant="body1"
          className={classnames(
            commonClasses.commonDescription,
            classes.subCategoryDescription,
          )}
        >
          {actions[category][subCategory].comment ?? ""}
        </Typography>
        {subCategory === "labels" ? (
          <Grid container spacing={1} className={classes.labelsInput}>
            <MsaSelectCreatable
              id="PERMISSION_PROFILE_CATEGORY_INPUT"
              options={labelsToMsaSelectOptions(labelOptions[category])}
              placeholder={t("Add x", { x: t("Tags") })}
              isClearable
              isMulti
              isSearchable
              value={getSelectedLabels(category)}
              onChange={(event) => {
                handleChange(event, subCategory, category);
              }}
              onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
            />
          </Grid>
        ) : (
          actionsList
            .filter((action) => {
              // Filter out actions hidden behind feature flag.
              const featureFlag =
                MAP_CATEGORY_TO_FEATURE_FLAG[category]?.[subCategory]?.[action];
              return featureFlag ? FeatureFlag.isEnabled(featureFlag) : true;
            })
            .map((action) =>
              renderAction({
                category,
                subCategory,
                action,
                data: data[action],
              }),
            )
        )}
        {!lastChild && <Divider className={classes.subCategoryDivider} />}
      </Grid>
    ) : null;
  };

  const getSelectedLabels = (category) => {
    return selectedLabels[category]
      ? selectedLabels[category]
          .filter((label) => label.value.value)
          .map(({ label }) => ({ label, value: label }))
      : [];
  };

  const removeExisitngLabels = (category) => {
    const currentLabels = [];
    const labelActions = {};
    labelActions[category] = {};
    const existingLabels = selectedLabels[category];
    for (const existingLabel of existingLabels) {
      existingLabel["value"] = { ...existingLabel["value"], value: false };
      labelActions[category][existingLabel.label] = {
        value: existingLabel.value.value,
      };
      currentLabels.push(existingLabel);
    }
    setSelectedLabels((prev) => ({
      ...prev,
      [category]: currentLabels,
    }));
    const newActions = { ...actions };
    for (const category in labelActions) {
      newActions[category]["labels"] = { ...labelActions[category] };
    }
    setActions(newActions);
  };

  const handleChange = (newLabels, subCategory, category) => {
    const currentLabels = [];
    const labelActions = {};
    labelActions[category] = {};

    if (!isEmpty(newLabels)) {
      newLabels.forEach((label) => {
        if (!label.value.category) {
          //transform newly added label structure to the default one
          label.value = {
            category,
            subCategory,
            action: label.label,
            value: true,
          };
        }
        //handles the labelActions for labels that were already present, newly added
        labelActions[category][label.label] = { value: label.value.value };
      });
      currentLabels.push(...newLabels);
    }

    //logic to handle when exisitng labels are removed
    if (!isEmpty(selectedLabels[category])) {
      const existingLabels = selectedLabels[category];
      for (const existingLabel of existingLabels) {
        if (
          !currentLabels.some((obj) => obj["label"] === existingLabel["label"])
        ) {
          existingLabel["value"] = { ...existingLabel["value"], value: false };
          //handles the labelActions for labels that were already present but removed now
          labelActions[category][existingLabel.label] = {
            value: existingLabel.value.value,
          };
          currentLabels.push(existingLabel);
        }
      }
    }
    setSelectedLabels((prev) => ({
      ...prev,
      [category]: currentLabels,
    }));

    //handles labels Actions for other categories
    for (const currentCategory in selectedLabels) {
      if (currentCategory !== category) {
        labelActions[currentCategory] = {};
        const labels = selectedLabels[currentCategory];
        labels.forEach((label) => {
          labelActions[currentCategory][label.label] = {
            value: label.value.value,
          };
        });
      }
    }

    const newActions = { ...actions };
    for (const category in labelActions) {
      newActions[category]["labels"] = { ...labelActions[category] };
    }
    setActions(newActions);
  };

  const renderAction = ({ category, subCategory, action, data }) => {
    const { value, comment } = data;
    return isObject(data) ? (
      <Tooltip title={comment || ""} enterDelay={500}>
        <FormControlLabel
          className={classes.action}
          key={action}
          control={
            <Checkbox
              id={getActionId({ category, subCategory, action })}
              checked={value}
              name={action}
              onChange={(event) =>
                onChangeAction({ event, category, subCategory, action })
              }
            />
          }
          label={
            <Typography className={classes.actionLabel}>
              {t(getActionTitle(action))}
            </Typography>
          }
        />
      </Tooltip>
    ) : null;
  };

  return (
    <>
      {categories.length > 0 ? (
        <Grid container direction="row">
          <Grid item xs={4} className={classes.categoriesSidebar}>
            <Sidebar>
              {categories
                .filter((category) => {
                  // Filter out if the category is hidden behind feature flag.
                  const featureFlag =
                    MAP_CATEGORY_TO_FEATURE_FLAG[category]?.["NULL"];
                  return featureFlag
                    ? FeatureFlag.isEnabled(featureFlag)
                    : true;
                })
                .map((category, index) => (
                  <Sidebar.ListItem
                    key={index}
                    id={getCategoryId(category)}
                    selected={activeCategory === category}
                    title={t(getCategoryTitle(category))}
                    onClick={() => setActiveCategory(category)}
                    tooltipText={actions[category].comment}
                    tooltipPlacement="top"
                  />
                ))}
            </Sidebar>
          </Grid>
          <Grid item xs={8} className={classes.scrollContainer}>
            {categories.map(
              (category) =>
                activeCategory === category &&
                renderCategory(category, actions[category]),
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid>{t("No actions available")}</Grid>
      )}
    </>
  );
};

export default PermissionsTab;
