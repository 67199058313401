import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withSnackbar } from "notistack";
import get from "lodash/get";
import flow from "lodash/flow";
import isEmpty from "lodash/isEmpty";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import { buildRoute } from "msa2-ui/src/utils/urls";
import {
  getManagedEntitiesBySubtenant,
  getManagedEntitiesByTenant,
  getManagedEntitiesByAdmin,
  deleteDevice,
} from "msa2-ui/src/api/managedEntity";
import {
  changeTableRowsSetting,
  getTableRowsSetting,
} from "msa2-ui/src/store/settings";
import {
  isMultiTenant,
  isMultiSubtenant,
  getSelectedSubtenant,
  getSelectedTenant,
  getAvailableSubtenants,
  getAvailableTenants,
} from "msa2-ui/src/store/designations";
import { getToken, getUserRole, userRoles } from "msa2-ui/src/store/auth";
import {
  Typography,
  Divider,
  Paper,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { ViewList, ViewModule, DeviceHub } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";

import AlertBar from "msa2-ui/src/components/AlertBar";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";

import ManagedEntity from "./ManagedEntity";
import ManagedEntityList from "./ManagedEntityList";
import TopologyTab from "./TopologyTab";
import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const isTopologyEnabled = FeatureFlag.isEnabled(FeatureFlag.features.topology);

const localStyles = () => ({
  root: {
    flexGrow: 1,
    alignItems: "stretch",
    height: "100%",
  },
  divider: {
    borderColor: "#e6eaee",
    width: "calc(100% + 32px)",
    marginLeft: -16,
    marginTop: 15,
  },
  loaderWrapper: {
    height: 200,
    textAlign: "center",
    width: "100%",
    paddingTop: 25,
  },
});

const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

class ManagedEntityOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorsById: null,
      modelsById: null,
      isLoading: true,
      showListView: false,
      managedEntities: [],
      apiResponse: [],
      selectedEntityId: null,
      searchString: "",
      page: 0,
      totalAssets: null,
      graphData: null,
    };
  }

  componentDidMount() {
    this.getManagedEntity();
    this.handleStatusSnackbar();
  }

  handleStatusSnackbar() {
    const { t, enqueueSnackbar, closeSnackbar, history } = this.props;
    const draftSaved = get(this.props, "location.state.meDraftSaved");
    if (draftSaved) {
      enqueueSnackbar(t("Managed Entity draft saved"), {
        variant: "info",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    }
    if (draftSaved) {
      history.replace({
        state: {},
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevSubtenant = get(prevProps, "subtenant.id", null);
    const subtenant = get(this.props, "subtenant.id", null);

    const prevTenant = get(prevProps, "tenant.id", null);
    const tenant = get(this.props, "tenant.id", null);

    if (prevSubtenant !== subtenant || prevTenant !== tenant) {
      this.getManagedEntity();
    }
  }

  reload = (addState) => {
    this.handleCloseAlertBar();
    this.setState(
      // merge objects
      Object.assign(
        {
          apiError: undefined,
          alertBar: false,
          isLoading: true,
          apiResponse: [],
          managedEntities: [],
        },
        addState,
      ),
      () => {
        this.getManagedEntity();
      },
    );
  };

  getManagedEntityCall = () => {
    const {
      token,
      subtenant,
      tenant,
      manager_id: managerId,
      rowsPerPage,
    } = this.props;
    const { page, searchString } = this.state;
    const common = {
      // Offset because paging on the backend starts at 1 and paging in the front end starts at 0...
      page: page + 1,
      pageSize: rowsPerPage,
      filter: "name",
      filterName: searchString,
      token,
    };

    const status = new URLSearchParams(this.props.location.search).get(
      "status",
    );

    if (subtenant && subtenant.id) {
      return getManagedEntitiesBySubtenant({
        subtenantId: subtenant.id,
        filterByLabel: isPermissionProfileLabelsEnabled,
        filterByStatus: status,
        ...common,
      });
    } else if (tenant && tenant.id) {
      return getManagedEntitiesByTenant({
        tenantId: tenant.value,
        filterByLabel: isPermissionProfileLabelsEnabled,
        ...common,
      });
    } else {
      return getManagedEntitiesByAdmin({
        managerId,
        filterByLabel: isPermissionProfileLabelsEnabled,
        ...common,
      });
    }
  };

  getManagedEntity = async () => {
    const managedEntityResponse = await this.getManagedEntityCall();
    const [
      managedEntityError,
      managedEntityList,
      managedEntityMeta,
    ] = managedEntityResponse;
    const { total_asset_count: count } = managedEntityMeta;

    if (managedEntityError) {
      return this.setState({
        apiError: managedEntityError,
        alertBar: true,
        isLoading: false,
      });
    }

    this.setState({
      alertBar: false,
      apiResponse: managedEntityList,
      managedEntities: managedEntityList,
      isLoading: false,
      totalAssets: count,
    });
  };

  addManagedEntityData = ({
    entity,
    tenants,
    subtenants,
    manufacturersById,
    modelsById,
  }) => {
    const { t } = this.props;
    const subtenant = subtenants.find(
      (subtenant) => subtenant.id === entity.abonneId,
    );
    const manufacturer = manufacturersById[entity.manId];
    const model = modelsById[entity.modId];
    return {
      ...entity,
      subtenantId: get(subtenant, "id"),
      subtenantName: get(subtenant, "label"),
      tenantId: tenants.find((tenant) => tenant.value === entity.prefix)?.id,
      tenantName:
        tenants.find((tenant) => tenant.value === entity.prefix)?.label ?? "",
      manufacturerName:
        manufacturer?.manufacturerName ?? `${t("Unknown")} - ${entity.manId}`,
      modelName: model?.modelName ?? `${t("Unknown")} - ${entity.modId}`,
      isHa: entity.haType !== 0 ? true : false,
      haPeerName: entity.haType !== 0 ? entity.haPeerId?.name : "",
      isOpenStack:
        manufacturer?.manufacturerName.toLowerCase().includes("openstack") ??
        false,
    };
  };

  handleViewAsChange = (event, value) => {
    this.setState({ showAsView: value });
  };

  handleGroupByChange = () => {};

  handleFilterByChange = () => {};

  handleSearchByChange = (filterSearchTerm) => {
    this.reload({ searchString: filterSearchTerm, page: 0 });
  };

  handleSortByChange = () => {};

  handleChangeRowsPerPage = (event) => {
    const {
      target: { value },
    } = event;
    const { page } = this.state;
    const { rowsPerPage } = this.props;

    this.props.changeTableRowsSetting({
      table: "managedEntities",
      numberOfRows: value,
    });

    this.reload({
      rowsPerPage: rowsPerPage,
      page: Math.floor((page * rowsPerPage) / value),
    });
  };

  handleChangePage = (event, page) => {
    this.reload({ page });
  };

  handleCloseAlertBar = () => {
    this.setState({ apiError: "", alertBar: false, isLoading: false });
  };

  handleDeleteDevice = async (id) => {
    const { token, enqueueSnackbar, closeSnackbar } = this.props;
    const [error] = await deleteDevice({
      token,
      id,
    });
    if (error) {
      enqueueSnackbar(error.getMessage("Unable to delete Managed Entity."), {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    } else {
      enqueueSnackbar("Managed Entity has been deleted!", {
        variant: "success",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
      this.reload();
    }
  };

  render() {
    const {
      classes,
      t,
      match,
      history,
      isMultiSubtenant,
      isMultiTenant,
      subtenant,
      subtenants,
      tenants,
      manufacturersById,
      modelsById,
      sectionTabs: SectionTabs,
      canViewTopology,
      userRole,
      ...other
    } = this.props;
    const { managedEntities } = this.state;
    const managedEntitiesList = managedEntities.map((entity) =>
      this.addManagedEntityData({
        entity,
        tenants,
        subtenants,
        manufacturersById,
        modelsById,
      }),
    );

    const isManager = userRoles.MANAGER === userRole;

    const progressBar = (
      <Paper
        id="MANAGED_ENTITIES_PROGRESSBAR"
        className={classes.loaderWrapper}
        aria-label="loading"
      >
        <CircularProgress />
      </Paper>
    );

    const errorBar = (
      <Paper id="MANAGED_ENTITIES_LOAD_ERROR" className={classes.loaderWrapper}>
        <AlertBar
          message={t("Failed to load managed entities.")}
          refreshFnc={this.reload}
          closeFnc={this.handleCloseAlertBar}
        />
      </Paper>
    );

    const noResults = (
      <Paper id="MANAGED_ENTITIES_NO_RESULTS" className={classes.loaderWrapper}>
        <Typography>{t("There are no managed entities to display")}</Typography>
      </Paper>
    );

    const list = managedEntitiesList.map((managedEntity) => (
      <ManagedEntity
        {...other}
        key={managedEntity.deviceId.id}
        managedEntity={managedEntity}
        handleDeleteDevice={this.handleDeleteDevice}
        isMultiSubtenant={isMultiSubtenant}
        isMultiTenant={isMultiTenant}
        isManager={isManager}
      />
    ));

    const shouldShowTopologyTab =
      isTopologyEnabled && canViewTopology && this.state.showAsView === 2;
    const getCurrentTableState = () => {
      if (this.state.isLoading) return progressBar;
      if (this.state.alertBar) return errorBar;
      if (list.length === 0) return noResults;
      if (this.state.showAsView === 1) {
        return (
          <ManagedEntityList
            {...other}
            handleDeleteDevice={this.handleDeleteDevice}
            managedEntities={managedEntitiesList}
            isMultiSubtenant={isMultiSubtenant}
            isMultiTenant={isMultiTenant}
            isManager={isManager}
          />
        );
      }
      if (shouldShowTopologyTab) {
        return <TopologyTab />;
      }
      return list;
    };
    const hasNoManufacturers = isEmpty(manufacturersById);
    return (
      <ErrorBoundary>
        <SectionTabs
          count={[match.url, this.state.totalAssets]}
          endElements={
            <CreateEntityButton
              id="MANAGED_ENTITIES_BTN_CREATE"
              aria-label="Create Managed Entity"
              disabled={Boolean(hasNoManufacturers)}
              delegationProfileAction="general.create"
              delegationProfileType={delegationProfileTypes.MANAGED_ENTITIES}
              link={buildRoute(match.url, "create")}
              tooltipTitle={
                hasNoManufacturers
                  ? t("There are no Device Adaptor installed")
                  : ""
              }
            >
              {t("Create Managed Entity")}
            </CreateEntityButton>
          }
        />
        <Grid container spacing={3}>
          {/* Managed Entities */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <FilterMenu
                paginationDisabled={shouldShowTopologyTab}
                searchDisabled={shouldShowTopologyTab}
                handleGroupByChange={this.handleGroupByChange}
                handleFilterByChange={this.handleFilterByChange}
                handleSearchByChange={this.handleSearchByChange}
                handleViewAsChange={this.handleViewAsChange}
                handleSortByChange={this.handleSortByChange}
                searchValue={this.state.searchString}
                tpRowsPerPage={this.props.rowsPerPage}
                tpTotal={this.state.totalAssets}
                tpPage={this.state.page}
                tpChangePage={this.handleChangePage}
                tpChangeRowsPerPage={this.handleChangeRowsPerPage}
                viewAsIcons={
                  isTopologyEnabled && canViewTopology
                    ? [<ViewModule />, <ViewList />, <DeviceHub />]
                    : [<ViewModule />, <ViewList />]
                }
              />

              <Divider variant="fullWidth" className={classes.divider} />
              <div className="wrapper">{getCurrentTableState()}</div>
            </Paper>
          </Grid>
        </Grid>
      </ErrorBoundary>
    );
  }
}

ManagedEntityOverview.propTypes = {
  subtenant: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  manager_id: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isMultiSubtenant: PropTypes.bool.isRequired,
  isMultiTenant: PropTypes.bool.isRequired,
  subtenants: PropTypes.array.isRequired,
  tenants: PropTypes.array.isRequired,
  manufacturersById: PropTypes.object.isRequired,
  modelsById: PropTypes.object.isRequired,
  userRole: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  token: getToken(state),
  subtenant: getSelectedSubtenant(state),
  tenant: getSelectedTenant(state),
  subtenants: getAvailableSubtenants(state),
  tenants: getAvailableTenants(state),
  isMultiTenant: isMultiTenant(state),
  isMultiSubtenant: isMultiSubtenant(state),
  manufacturersById: state.designations.manufacturersById,
  modelsById: state.designations.modelsById,
  manager_id: state.auth.userDetails.id,
  rowsPerPage: getTableRowsSetting("managedEntities")(state),
  canViewTopology: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "topology.view",
  )(state),
  userRole: getUserRole(state),
});

const mapDispatchToProps = {
  changeTableRowsSetting,
};

export default flow(
  withRouter,
  withTranslation(),
  withStyles(styles),
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps),
)(ManagedEntityOverview);
