import React from "react";
import PropTypes from "prop-types";
import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import Toolbar from "msa2-ui/src/components/modal/Toolbar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  toolbarTitle: {
    marginRight: 15,
  },
  toolbarButton: {
    marginRight: 10,
  },
  toolbarFixed: {
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
  },
}));

const ModalToolbar = ({
  submitButtonTitle,
  title,
  isCreateDisabled,
  onClose,
  onSubmit,
  onDiscardChanges,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbarFixed}>
      <Toolbar.Section>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.toolbarTitle}
        >
          {title}
        </Typography>
      </Toolbar.Section>
      <Toolbar.Section>
        <Button
          variant="text"
          size="medium"
          color="primary"
          className={classes.toolbarButton}
          onClick={onDiscardChanges}
        >
          {t("Discard Changes")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          className={classes.toolbarButton}
          onClick={onSubmit}
          disabled={isCreateDisabled}
        >
          {submitButtonTitle}
        </Button>
        <Toolbar.Divider />
        <IconButton
          variant="contained"
          size="medium"
          color="default"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar.Section>
    </Toolbar>
  );
};

ModalToolbar.propTypes = {
  submitButtonTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isCreateDisabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
};

export default ModalToolbar;
