import React from "react";
import { useLocation } from "react-router-dom";
import UpsertAlarmForm from "./UpsertAlarmForm";

const CreateAlarm = () => {
  const { state } = useLocation();

  return <UpsertAlarmForm alarmData={state?.alarmData} isCreate={true} />;
};

export default CreateAlarm;
