import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import get from "lodash/get";

import { getToken, userRoles } from "msa2-ui/src/store/auth";
import {
  getAvailableTenants,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";

import { buildRoute } from "msa2-ui/src/utils/urls";

import useApi from "msa2-ui/src/hooks/useApi";
import { getManagers } from "msa2-ui/src/api/manager";
import {
  createPermissionProfile,
  getAvailableActions,
} from "msa2-ui/src/api/permissionProfiles";

import { makeStyles } from "@material-ui/core";

import { Grid, Typography } from "@material-ui/core";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Dialog from "msa2-ui/src/components/Dialog";
import PermissionProfileDialogTabs from "msa2-ui/src/routes/admin/permission-profiles/DialogTabs";
import InformationTab from "msa2-ui/src/routes/admin/permission-profiles/tabs/InformationTab";
import AssociatedManagersTab from "msa2-ui/src/routes/admin/permission-profiles/tabs/AssociatedManagersTab";
import PermissionsTab from "msa2-ui/src/routes/admin/permission-profiles/tabs/PermissionsTab";

const useStyles = makeStyles(({ spacing }) => ({
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
  dialogWindow: {
    height: "calc(100% - 64px)",
  },
}));

const Create = ({ reloadPermissionProfiles }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const token = useSelector(getToken);
  const tenants = useSelector(getAvailableTenants);
  const selectedTenant = useSelector(getSelectedTenant);

  const [tenant, setTenant] = useState(
    selectedTenant?.id
      ? tenants?.find((tenant) => tenant.id === selectedTenant.id)
      : "",
  );
  const [actions, setActions] = useState({});
  const [entry, setEntry] = useState({
    profileName: "",
    comment: "",
    tenantID: tenant ? tenant.id : "",
    prefix: tenant ? tenant.value : "",
    creatorReference: "",
    attachedManagers: [],
    permissionList: [],
  });

  const { prefix } = entry;

  const [, , actionsData = {}] = useApi(getAvailableActions, { token });

  const [managersLoading, managersError, managers = []] = useApi(
    getManagers,
    {
      sortOrder: "ASC",
      sort: "name",
      filterCriteria: prefix,
      filter: "prefix",
      page: 1,
      pageSize: 0,
    },
    !prefix,
  );

  useEffect(() => {
    if (Object.keys(actionsData).length > 0) {
      setActions(actionsData);
    }
  }, [actionsData]);

  const filterManagers = (managers) => {
    return managers.filter(
      (manager) => get(manager, "baseRole.id") === userRoles.MANAGER,
    );
  };

  const closeDialog = () => {
    history.push("/admin/permissions");
  };

  const onSubmit = async () => {
    setError("");

    const { profileName } = entry;

    if (!profileName) {
      return setError(t("Please fill out all the required form fields"));
    }

    setIsSubmitting(true);

    const [createProfileError] = await createPermissionProfile({
      entry,
      actions,
      token,
    });

    setIsSubmitting(false);

    const message = createProfileError
      ? createProfileError.getMessage()
      : t("Permission profile created");
    enqueueSnackbar(message, {
      variant: createProfileError ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!createProfileError) {
      reloadPermissionProfiles();
      history.push("/admin/permissions");
    }
  };

  const defaultRoute = buildRoute(path, "information");

  return (
    <Dialog
      onExec={onSubmit}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={t("Create Permission Profile")}
      disabled={isSubmitting}
      maxWidth={"md"}
      tabs={<PermissionProfileDialogTabs />}
      classes={{ paperScrollPaper: classes.dialogWindow }}
    >
      <Grid container direction="column">
        <Switch>
          <Route
            exact
            path={buildRoute(path, "information")}
            render={() => {
              return (
                <InformationTab
                  entry={entry}
                  setEntry={setEntry}
                  tenant={tenant}
                  setTenant={setTenant}
                  tenants={tenants}
                />
              );
            }}
          />
          <Route
            exact
            path={buildRoute(path, "managers")}
            render={() => {
              return (
                <AssociatedManagersTab
                  tenant={tenant}
                  entry={entry}
                  setEntry={setEntry}
                  managers={filterManagers(managers)}
                  managersLoading={managersLoading}
                  managersError={managersError}
                />
              );
            }}
          />
          <Route
            exact
            path={buildRoute(path, "permissions")}
            render={() => {
              return (
                <PermissionsTab actions={actions} setActions={setActions} />
              );
            }}
          />
          <Redirect to={defaultRoute} />
        </Switch>
        {error && (
          <Typography
            color="error"
            className={classes.error}
            id="PERMISSION_PROFILE_CREATE_ERROR"
          >
            {error}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default Create;
