//Core
import get from "lodash/get";

//Icons
import { ReactComponent as FileBugIcon } from "msa2-ui/src/assets/icons/file/fileBug.svg";
import { ReactComponent as FileClipboardIcon } from "msa2-ui/src/assets/icons/file/fileClipboard.svg";
import { ReactComponent as FileComputerIcon } from "msa2-ui/src/assets/icons/file/fileComputer.svg";
import { ReactComponent as FileDashboardIcon } from "msa2-ui/src/assets/icons/file/fileDashboard.svg";
import { ReactComponent as FileDocumentationIcon } from "msa2-ui/src/assets/icons/file/fileDocumentation.svg";
import { ReactComponent as FileNetworkIcon } from "msa2-ui/src/assets/icons/file/fileNetwork.svg";
import { ReactComponent as FileShieldIcon } from "msa2-ui/src/assets/icons/file/fileShield.svg";
import { ReactComponent as DevAutomationIcon } from "msa2-ui/src/assets/icons/devAutomation.svg";
import { ReactComponent as DevManagedEntitesIcon } from "msa2-ui/src/assets/icons/devManagedEntites.svg";
import { ReactComponent as ManagedEntityIcon } from "msa2-ui/src/assets/icons/managedEntity.svg";
import { ReactComponent as CalendarIcon } from "msa2-ui/src/assets/icons/calendar.svg";
import { ReactComponent as ListWideIcon } from "msa2-ui/src/assets/icons/listWide.svg";

//Function will be called when displaying icon from workflow or microservice or elsewhere
export const iconMapper = (inputIcon) => {
  //Create map of all icons available for system
  const iconsAvailableMap = {
    FileBugIcon: FileBugIcon,
    FileClipboardIcon: FileClipboardIcon,
    FileComputerIcon: FileComputerIcon,
    FileDashboardIcon: FileDashboardIcon,
    FileDocumentationIcon: FileDocumentationIcon,
    FileNetworkIcon: FileNetworkIcon,
    FileShieldIcon: FileShieldIcon,
    DevAutomationIcon: DevAutomationIcon,
    DevManagedEntitesIcon: DevManagedEntitesIcon,
    ManagedEntityIcon: ManagedEntityIcon,
    CalendarIcon: CalendarIcon,
    ListWideIcon: ListWideIcon,
  };

  return get(iconsAvailableMap, inputIcon, FileShieldIcon);
};
