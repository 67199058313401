import isNumber from "lodash/isNumber";
import groupBy from "lodash/groupBy";
import intersection from "lodash/intersection";
import {
  nodeTypes,
  statusTypes,
} from "msa2-ui/src/components/topology/constants";

const getNodeType = ({ device_nature, subtype }) => {
  if (subtype === "network") {
    return nodeTypes.NETWORK;
  }
  return nodeTypes[device_nature] || nodeTypes.UNKNOWN_TYPE;
};

/**
 * Normalization of the raw data from the server. Sets up proper format of types, coordinates etc.
 */
export const prepareData = (rawData) => {
  const data = rawData.reduce(
    (acc, d) => {
      const categories = new Set(d.categories);
      categories.forEach((c) => acc.categories.add(c));

      const fx = parseFloat(d.x) || null;
      const fy = parseFloat(d.y) || null;

      acc.nodes.push({
        id: d.name,
        fx,
        fy,
        initialX: fx,
        initialY: fy,
        __metadata__: {
          categories: Array.from(categories),
          deviceId: d.deviceUbiqubeID,
          objectId: d.object_id, // this id will be needed for saving node position
          links: d.links,
          type: getNodeType(d), // this type will be needed for an icon rendering
          status: statusTypes[d.status] || statusTypes.UNKNOWN_STATUS, // status will define color for an ic
          color: d.color,
        },
      });

      return acc;
    },
    { nodes: [], categories: new Set() },
  );

  return { ...data, categories: Array.from(data.categories) };
};

export const applyDeviceTypeFilter = (deviceTypeFilterArray) => (data) => {
  return data.filter(
    (d) =>
      !deviceTypeFilterArray.length ||
      deviceTypeFilterArray.includes(d.__metadata__.type),
  );
};

export const applyCategoryFilter = (categoriesFilterArray) => (data) => {
  return data.filter(
    (d) =>
      !categoriesFilterArray.length ||
      Boolean(
        intersection(d.__metadata__.categories, categoriesFilterArray).length,
      ),
  );
};

export const prepareGraphData = (nodes) => {
  const nodesByIds = groupBy(nodes, (node) => node.id);

  const linksKeySet = new Set();
  const links = [];

  nodes.forEach((node) => {
    const source = node.id;
    const rawLinks = node.__metadata__.links;

    if (rawLinks) {
      rawLinks.forEach((link) => {
        const target = link.link;
        const label = link.label;
        const key = `${source} - ${target}`;
        const reversedKey = `${target} - ${source}`;

        if (
          !nodesByIds[target] ||
          linksKeySet.has(key) ||
          linksKeySet.has(reversedKey)
        ) {
          return;
        }

        linksKeySet.add(key).add(reversedKey);

        links.push({ id: key, source, target, label });
      });
    }
  });

  return { nodes, links };
};

export const prepareNodesToSave = (d3Nodes) =>
  d3Nodes
    .filter(({ fx, fy }) => isNumber(fx) && isNumber(fy))
    .map((node) => ({
      objectId: node.__metadata__.objectId,
      x: node.fx,
      y: node.fy,
      categories: node.__metadata__.categories,
    }));

export const prepareNodeCategoriesToSave = (d3Node, categories) => ({
  objectId: d3Node.__metadata__.objectId,
  x: d3Node.initialX,
  y: d3Node.initialY,
  categories,
});

export const getCategories = (node) => node.__metadata__.categories || [];
