import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { Button, Grid, IconButton } from "@material-ui/core";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import classNames from "classnames";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import { required } from "msa2-ui/src/utils/validators";
import FieldArray from "redux-form/lib/FieldArray";
import { makeStyles } from "@material-ui/core";
import DataList from "./DataList";

const useStyles = makeStyles(() => ({
  row: {
    position: "relative",
    padding: "50px 0",

    "& + &": {
      borderTop: "1px solid #646c7d",
    },

    "&:hover > .deleteIconHover": {
      opacity: 1,
    },
  },
  deleteIcon: {
    position: "absolute",
    top: 5,
    right: 0,
    opacity: 0,
    transition: ".2s ease",
  },
}));

const GraphList = ({ fields }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Grid item sm={12}>
      <Grid container>
        <Grid container item sm={12} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => fields.push({})}
          >
            <IconPlus className={commonClasses.commonBtnIcon} />
            {t("Add x", { x: t("Graph") })}
          </Button>
        </Grid>
        {fields.map((field, i) => (
          <Grid
            item
            sm={12}
            key={field}
            className={classes.row}
            data-testid="graph-row"
          >
            <div className={classNames(classes.deleteIcon, "deleteIconHover")}>
              <IconButton onClick={() => fields.remove(i)}>
                <IconDeleteRed />
              </IconButton>
            </div>
            <Grid container spacing={4}>
              <Grid item sm={12} md={6}>
                <FieldSelector
                  name={`${field}.name`}
                  label={t("Graph Name")}
                  type="text"
                  validate={required}
                  noIndent
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <FieldSelector
                  name={`${field}.verticalLabel`}
                  label={t("Units")}
                  type="text"
                  validate={required}
                  noIndent
                />
              </Grid>
              <Grid item sm={12}>
                <FieldArray name={`${field}.dataList`} component={DataList} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default memo(GraphList);
