import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { buildRoute } from "msa2-ui/src/utils/urls";
import {
  listConfigurationVariable,
  deleteConfigurationVariable,
} from "msa2-ui/src/api/managedEntity";
import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";

import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { sortOrder } from "msa2-ui/src/Constants";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import MSATableBody from "msa2-ui/src/components/MSATableBody";
import MSATableRow from "msa2-ui/src/components/MSATableRow";
import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";

const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    width: "20%",
    sortKey: false,
  },
  {
    id: "value",
    name: "Value",
    align: "left",
    width: "40%",
    sortKey: false,
  },
  {
    id: "comment",
    name: "Comment",
    align: "left",
    sortKey: false,
  },
  {
    id: "action",
    name: "",
    align: "left",
    width: "104px",
    sortKey: false,
  },
];

const ManagedEntityVariablesTab = () => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { assetId: deviceId } = useParams();
  const { url: pathname, isExact } = useRouteMatch(
    "/integration/managed-entities/:assetId/variables",
  );

  const [filterState, filterActions] = useFilter({
    searchValue: "",
  });

  const [loading, error, configurationVariables = [], , reload] = useApi(
    listConfigurationVariable,
    { deviceId },
    !deviceId,
  );

  // reload when comes back from the different route.
  useEffect(() => {
    if (isExact) {
      reload();
    }
  }, [isExact, reload]);

  const colSpan = TABLE_HEADER_COLUMNS.length;
  return (
    <Grid item xs={12}>
      <Paper
        id="MANAGED_ENTITY_VARIABLES_TAB"
        className={commonClasses.commonPaperNoPadding}
      >
        <Table>
          <colgroup>
            {TABLE_HEADER_COLUMNS.map(({ id, width }) => (
              <col key={id} style={{ width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={colSpan}
                className={commonClasses.commonTableCell}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <FilterMenu
                      id="MANAGED_ENTITY_VARIABLES_TABLE_FILTER_MENU"
                      {...filterState}
                      {...filterActions}
                      handleViewAsChange={undefined}
                    />
                  </Grid>
                  <Grid item>
                    <CreateEntityButton
                      id="MANAGED_ENTITIES_VARIABLES_BTN_CREATE"
                      aria-label="Create Variable"
                      delegationProfileAction="variable.create"
                      delegationProfileType={
                        delegationProfileTypes.MANAGED_ENTITIES
                      }
                      link={buildRoute(pathname, "create")}
                    >
                      {t("Create Variable")}
                    </CreateEntityButton>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                <TableCell
                  key={tableHeaderColumn.id}
                  align={tableHeaderColumn.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {tableHeaderColumn.sortKey ? (
                    <TableSortLabel
                      id={`MANAGED_ENTITY_VARIABLES_TABLE_SORT_${tableHeaderColumn.id}`}
                      active={tableHeaderColumn.id === filterState.sortByValue}
                      direction={sortOrder[filterState.sortOrderValue].text}
                      onClick={() => {
                        filterActions.handleSortByOrderChange();
                        filterActions.handleSortByValueChange(
                          tableHeaderColumn.id,
                        );
                      }}
                    >
                      {tableHeaderColumn.name}
                    </TableSortLabel>
                  ) : (
                    tableHeaderColumn.name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <MSATableBody
            loading={loading && !configurationVariables.length}
            error={error?.getMessage(
              t("Unable to load x", { x: t("Variables") }),
            )}
            noContents={
              !configurationVariables.length && t("No Variables found")
            }
            colSpan={colSpan}
          >
            {configurationVariables
              .filter(({ name }) =>
                filterState.searchValue
                  ? name
                      .toLowerCase()
                      .search(filterState.searchValue.toLowerCase()) !== -1
                  : true,
              )
              .map((configurationVariable, i) => {
                const name = configurationVariable.name;
                const onDelete = (token) =>
                  deleteConfigurationVariable({ token, deviceId, name });
                return (
                  <MSATableRow
                    key={i}
                    columns={TABLE_HEADER_COLUMNS}
                    data={configurationVariable}
                    deleteAPI={onDelete}
                    callbackPostDelete={reload}
                    editLinkTo={buildRoute(
                      pathname,
                      [encodeURIComponent(name), "edit"].join("/"),
                    )}
                    delegationEdit={{
                      type: delegationProfileTypes.MANAGED_ENTITIES,
                      action: "variable.modify",
                    }}
                    delegationDelete={{
                      type: delegationProfileTypes.MANAGED_ENTITIES,
                      action: "variable.delete",
                    }}
                  />
                );
              })}
          </MSATableBody>
        </Table>
      </Paper>
    </Grid>
  );
};

export default ManagedEntityVariablesTab;
