import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default () => {
  return (
    <SvgIcon viewBox="0 0 840 840">
      <g>
        <g>
          <path
            d="M426.7,753.5h-2.1c-7.2,0-13-5.8-13-13s5.8-13,13-13h2.1c3.8,0,7.5-0.8,10.9-2.4c6.5-3.1,14.2-0.3,17.3,6.1
			c3.1,6.5,0.3,14.2-6.1,17.3C441.8,751.9,434.4,753.5,426.7,753.5z M386.3,753.5h-19.2c-7.2,0-13-5.8-13-13s5.8-13,13-13h19.2
			c7.2,0,13,5.8,13,13S393.4,753.5,386.3,753.5z M328.8,753.5h-19.2c-7.2,0-13-5.8-13-13s5.8-13,13-13h19.2c7.2,0,13,5.8,13,13
			S336,753.5,328.8,753.5z M271.3,753.5h-19.2c-7.2,0-13-5.8-13-13s5.8-13,13-13h19.2c7.2,0,13,5.8,13,13S278.5,753.5,271.3,753.5z
			M213.9,753.5h-19.2c-7.2,0-13-5.8-13-13s5.8-13,13-13h19.2c7.2,0,13,5.8,13,13S221.1,753.5,213.9,753.5z M156.4,753.5h-19.2
			c-7.2,0-13-5.8-13-13s5.8-13,13-13h19.2c7.2,0,13,5.8,13,13S163.6,753.5,156.4,753.5z M99,753.5H79.8c-7.2,0-13-5.8-13-13
			s5.8-13,13-13H99c7.2,0,13,5.8,13,13S106.1,753.5,99,753.5z M43.4,745.1c-2.9,0-5.7-0.9-8.1-2.9c-6.7-5.4-12-12.4-15.2-20.3
			c-2.8-6.6,0.4-14.2,7-17c6.6-2.8,14.2,0.4,17,7c1.6,3.9,4.2,7.4,7.5,10c5.6,4.5,6.5,12.7,2,18.3C51,743.4,47.2,745.1,43.4,745.1z
			M464.7,720.1c-0.5,0-1.1,0-1.6-0.1c-7.1-0.9-12.2-7.4-11.3-14.5c0.1-1.1,0.2-2.1,0.2-3.2v-14.3c0-7.2,5.8-13,13-13s13,5.8,13,13
			v14.3c0,2.2-0.1,4.4-0.4,6.5C476.8,715.3,471.2,720.1,464.7,720.1z M29.1,692c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13
			s13,5.8,13,13V679C42.1,686.2,36.3,692,29.1,692z M465,662.6c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2
			C478,656.8,472.2,662.6,465,662.6z M29.1,634.6c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2
			C42.1,628.7,36.3,634.6,29.1,634.6z M465,605.2c-7.2,0-13-5.8-13-13V573c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2
			C478,599.4,472.2,605.2,465,605.2z M29.1,577.1c-7.2,0-13-5.8-13-13V545c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2
			C42.1,571.3,36.3,577.1,29.1,577.1z M465,547.7c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2
			C478,541.9,472.2,547.7,465,547.7z M29.1,519.6c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2
			C42.1,513.8,36.3,519.6,29.1,519.6z M444.2,511.1C444.2,511.1,444.2,511.1,444.2,511.1l-19.2,0c-7.2,0-13-5.8-13-13
			c0-7.2,5.8-13,13-13c0,0,0,0,0,0l19.2,0c7.2,0,13,5.8,13,13C457.2,505.2,451.4,511.1,444.2,511.1z M386.7,511
			C386.7,511,386.7,511,386.7,511l-19.2,0c-7.2,0-13-5.8-13-13c0-7.2,5.8-13,13-13c0,0,0,0,0,0l19.2,0c7.2,0,13,5.8,13,13
			C399.7,505.2,393.9,511,386.7,511z M329.3,511C329.3,511,329.3,511,329.3,511l-19.2,0c-7.2,0-13-5.8-13-13c0-7.2,5.8-13,13-13
			c0,0,0,0,0,0l19.2,0c7.2,0,13,5.8,13,13C342.3,505.2,336.5,511,329.3,511z M271.8,511C271.8,511,271.8,511,271.8,511l-11.5,0
			c-0.2,0-0.4,0-0.6,0c-8.6-0.4-17-2.9-24.3-7.4c-6.1-3.7-8.1-11.7-4.4-17.9c3.7-6.1,11.7-8.1,17.9-4.4c3.5,2.1,7.6,3.4,11.7,3.6
			l11.1,0c7.2,0,13,5.8,13,13C284.8,505.2,279,511,271.8,511z M223.8,473.7c-7,0-12.8-5.6-13-12.7c0-0.4,0-0.8,0-1.3v-18.2
			c0-7.2,5.8-13,13-13s13,5.8,13,13v18.2c0,0.2,0,0.4,0,0.6c0.2,7.2-5.5,13.1-12.7,13.3C224,473.7,223.9,473.7,223.8,473.7z
			M29.1,462.2c-7.2,0-13-5.8-13-13V430c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2C42.1,456.4,36.3,462.2,29.1,462.2z M223.8,416.3
			c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2C236.8,410.4,231,416.3,223.8,416.3z M29.1,404.7
			c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2C42.1,398.9,36.3,404.7,29.1,404.7z M223.8,358.8
			c-7.2,0-13-5.8-13-13v-19.2c0-7.2,5.8-13,13-13s13,5.8,13,13v19.2C236.8,353,231,358.8,223.8,358.8z M30.1,347.3
			c-1,0-1.9-0.1-2.9-0.3c-7-1.6-11.4-8.6-9.8-15.6c1.9-8.3,6-16.1,11.7-22.6c4.8-5.4,13-5.8,18.4-1.1c5.4,4.8,5.8,13,1.1,18.4
			c-2.8,3.2-4.8,7-5.7,11.1C41.4,343.3,36,347.3,30.1,347.3z M207.5,317.6h-19.2c-7.2,0-13-5.8-13-13s5.8-13,13-13h19.2
			c7.2,0,13,5.8,13,13S214.7,317.6,207.5,317.6z M150,317.6h-19.2c-7.2,0-13-5.8-13-13s5.8-13,13-13H150c7.2,0,13,5.8,13,13
			S157.2,317.6,150,317.6z M92.6,317.6H73.4c-7.2,0-13-5.8-13-13s5.8-13,13-13h19.2c7.2,0,13,5.8,13,13S99.7,317.6,92.6,317.6z"
          />
          <path
            d="M546.6,511.1H262.1c-0.8,0-1.5,0-2-0.1l-0.3,0c-27.4-1.2-49-23.7-49-51.2V81.6c0-28.3,23-51.3,51.3-51.3
			h378.2c27.5,0,50,21.5,51.2,49c0,0,0,0,0,0l0,0.3c0,0.5,0.1,1.2,0.1,2v378.2c0,25.5-19,47.3-44.2,50.8c-3.8,0.5-7.7-0.7-10.6-3.3
			l-77.2-70.8v61.6C559.6,505.3,553.8,511.1,546.6,511.1z M262.2,485.1h271.4v-78.2c0-5.2,3-9.8,7.8-11.9c4.7-2.1,10.2-1.2,14,2.3
			l93.8,86.1c9.6-3.7,16.3-13,16.3-23.6V81.7l0-0.3c0-0.3,0-0.6-0.1-0.9c-0.6-13.5-11.7-24.1-25.2-24.1H262.1
			c-14,0-25.3,11.4-25.3,25.3v378.2c0,13.5,10.6,24.6,24.1,25.2c0.3,0,0.6,0,0.9,0.1L262.2,485.1z"
          />
        </g>
        <path
          d="M703.7,809.7c-4.7,0-9.2-2.6-11.5-7l-57.9-111.5l-78.4,78.4c-3.7,3.7-9.3,4.8-14.2,2.8c-4.9-2-8-6.8-8-12
		V406.9c0-5.2,3-9.8,7.8-11.9c4.7-2.1,10.2-1.2,14,2.3l264.2,242.4c3.9,3.6,5.3,9.3,3.3,14.3s-6.8,8.3-12.1,8.3H709.4l53.7,103.6
		c1.6,3.1,1.9,6.6,0.9,9.9c-1,3.3-3.4,6-6.4,7.6l-48,24.8C707.7,809.3,705.7,809.7,703.7,809.7z M637.6,656.5c0.7,0,1.3,0,2,0.2
		c4.1,0.6,7.6,3.2,9.6,6.9l60.1,115.8l24.9-12.9l-57.6-111.1c-2.1-4-1.9-8.9,0.4-12.7c2.4-3.9,6.6-6.2,11.1-6.2h89.4L559.6,436.4
		V729l68.8-68.7C630.9,657.8,634.2,656.5,637.6,656.5z"
        />
      </g>
    </SvgIcon>
  );
};
