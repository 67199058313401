import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Grid, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { workflowStatus, statusTypes } from "msa2-ui/src/Constants";

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: "row",
    textTransform: "uppercase",
  },
  statusIcon: {
    fontSize: "1.3rem",
    marginBottom: -7,
    marginRight: 5,
  },
}));

const StatusBadge = ({ status = statusTypes.NOT_STARTED }) => {
  const classes = useStyles();
  const { name, backgroundColor, icon: Icon } = workflowStatus.find(
    (workflowStatus) => workflowStatus.status === status,
  );
  return (
    <Grid className={classes.container}>
      <Icon className={classnames(classes.statusIcon, classes.iconSucceeded)} />
      <Chip label={name} style={backgroundColor} />
    </Grid>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string,
};

export default StatusBadge;
