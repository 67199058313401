import { get, post, destroy, constructURL } from "./request";
import {
  createRepositoryFile,
  updateRepositoryFile,
  deleteRepositoryFile,
} from "msa2-ui/src/api/repository";
import Bpm from "msa2-ui/src/services/Bpm";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";

import {
  EXTERNAL_REFERENCE_PREFIX,
  VARIABLE_NAME_FOR_INPUT_CONTEXT,
} from "msa2-ui/src/services/Bpm";
import Variable from "msa2-ui/src/services/Variable";
import { parseDuration } from "msa2-ui/src/components/schedule/utils";

const API = process.env.REACT_APP_API_PATH;
const API_CAMUNDA = `${API}/camunda`;

const createCamundaUri = (url, queryParams = {}) =>
  constructURL(url, omitBy(queryParams, isNil));

export const getProcessInstanceStatistics = ({ token }) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri("/process-definition/statistics", {
        incidents: true,
      }),
    },
    token,
  });
};

export const getExternalTaskLogs = ({ token, subtenantId, bpmFilename }) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri("/history/external-task-log", {
        processDefinitionKey: Bpm.buildProcessDefinitionKey(
          subtenantId,
          bpmFilename,
        ),
      }),
    },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/createBPMNdatafilesList
 */
export const getBpmDiagramsForSubtenant = ({
  subtenantId: customerId,
  page,
  page_size,
  isFilteredByOwner: filterByOwner,
  token,
}) => {
  return get({
    url: `${API}/repository/v1/list/bpmn`,
    token,
    queryParams: {
      customerId,
      page,
      filterByOwner: filterByOwner || undefined,
      page_size,
    },
  });
};

export const getBpmDetails = ({
  subtenantId,
  bpmFilename,
  sortBy = "startTime",
  sortOrder = "desc",
  token,
}) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri("/history/process-instance", {
        processDefinitionKey: Bpm.buildProcessDefinitionKey(
          subtenantId,
          bpmFilename,
        ),
        sortBy,
        sortOrder,
      }),
    },
    token,
  });
};

export const getProcessInstance = ({ id, token }) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(`/process-instance/${id}`),
    },
    token,
  });
};
export const getHistoricProcessInstance = ({ id, token }) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(`/history/process-instance/${id}`),
    },
    token,
  });
};

export const deleteHistoricProcessInstance = ({ id, token }) => {
  return destroy({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(`/history/process-instance/${id}`),
    },
    token,
  });
};

export const deleteProcessInstance = ({ id, token }) => {
  return destroy({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(`/process-instance/${id}`),
    },
    token,
  });
};

export const getProcessDefinitions = ({ subtenantId, bpmFilename, token }) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(`/process-definition`, {
        key: Bpm.buildProcessDefinitionKey(subtenantId, bpmFilename),
      }),
    },
    token,
  });
};

export const deleteProcessDefinition = ({ id, token }) => {
  return destroy({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(`/process-definition/${id}`),
    },
    token,
  });
};

export const getBpmnXml = ({ id, token }) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(`/process-definition/${id}/xml`),
    },
    token,
  });
};

export const addBpmDiagram = ({
  bpmFilename,
  displayName,
  customerId,
  xmlContent,
  saveOwner,
  token,
}) => {
  return createRepositoryFile({
    uri: `Bpmn/bpmns/${bpmFilename}`,
    // saveOwner is a hidden flag for a particular users
    // so we convert false into undefined to make the key invisible
    saveOwner: saveOwner || undefined,
    content: xmlContent,
    displayName,
    customerId,
    token,
  });
};

export const updateBpmDiagram = ({
  bpmFilename,
  displayName,
  xmlContent,
  pathToBpmDatafilesDirectory,
  token,
}) => {
  return updateRepositoryFile({
    uri: `${pathToBpmDatafilesDirectory}/${bpmFilename}`,
    displayName,
    content: xmlContent,
    token,
  });
};

/*
 * deletes BPM file from file system.
 */
export const deleteBpmDiagram = ({ bpmFilePath: uri, token }) => {
  return deleteRepositoryFile({ uri, token });
};

/*
 * Spec: https://docs.camunda.org/manual/7.12/reference/rest/deployment/post-deployment/
 * Create a deployment on the Camunda Engine for the given BPM XML content,
 * named according to the supplied BPM filename which must end in `.bpmn`.
 * Once the deployment and its corresponding process definition is created, a process
 * instance must be manually triggered by the `startProcessInstanceOnCamundaEngine` call.
 */

export const createDeploymentOnCamundaEngine = ({
  bpmFilename,
  xmlContent,
  subtenantId,
  token,
}) => {
  return post({
    url: API_CAMUNDA,
    body: {
      bpmn_uri: "/deployment/create",
      formDataFlag: true,
      data: {
        "deployment-name": bpmFilename,
        "deployment-source": "MSA UI",
        "deploy-changed-only": false,
        "enable-duplicate-filtering": false,
        "tenant-id": subtenantId,
        file: xmlContent,
      },
    },
    token,
  });
};

/*
 * Spec: https://docs.camunda.org/manual/7.12/reference/rest/deployment/delete-deployment/
 * Deletes a deployment by id.
 */

export const deleteDeploymentFromCamundaEngine = (id, token) => {
  return destroy({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: `/deployment/${id}`,
    },
    token,
  });
};

/* Spec: https://docs.camunda.org/manual/7.12/reference/rest/process-definition/post-start-process-instance/
 * Start a process instance on the Camunda Engine with the given `processDefinitionKey`, which is
 * taken from the `deployedProcessDefinitions` returned from `createDeploymentOnCamundaEngine`call.
 * This will execute the BPM contained in the process definition.
 */
export const startProcessInstanceOnCamundaEngine = ({
  processDefinitionKey,
  businessKey,
  value,
  type = "Json",
  subtenantId: tenantId,
  token,
}) => {
  return post({
    url: API_CAMUNDA,
    body: {
      bpmn_uri: createCamundaUri(
        `/process-definition/key/${processDefinitionKey}/tenant-id/${tenantId}/start`,
      ),
      data: value
        ? {
            businessKey,
            variables: {
              [VARIABLE_NAME_FOR_INPUT_CONTEXT]: {
                type,
                value: type === "Json" ? JSON.stringify(value) : value,
              },
            },
          }
        : {},
    },
    token,
  });
};

export const getActivityInstances = ({
  processInstanceId,
  token,
  transforms,
}) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(
        `/process-instance/${processInstanceId}/activity-instances`,
      ),
    },
    token,
    transforms,
  });
};

export const getHistoricActivityInstance = ({
  processInstanceId,
  sortBy = "startTime",
  sortOrder = "asc",
  token,
  transforms,
}) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri("/history/activity-instance", {
        processInstanceId,
        sortBy,
        sortOrder,
      }),
    },
    token,
    transforms,
  });
};

export const getActivityStatus = ({ executionId, token }) => {
  return get({
    url: `${API}/orchestration/v1/process-instance/reference/${EXTERNAL_REFERENCE_PREFIX}${executionId}`,
    token,
  });
};

const parseValue = (res) => {
  const value = JSON.parse(res?.value);
  const variables =
    typeof value?.variables === "string"
      ? JSON.parse(value.variables)
      : value?.variables;
  return {
    ...res,
    value: variables
      ? {
          ...value,
          variables: Variable.convertObjectPath(variables),
        }
      : value,
  };
};
const parseAllValues = (res) =>
  Object.entries(res).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: parseValue(value) }),
    {},
  );
const parseJsonValues = (res) =>
  res.map((entry) => (entry.type === "Json" ? parseValue(entry) : entry));

export const getProcessVariables = ({
  processInstanceId,
  deserializeValues = false,
  transforms = [],
  token,
}) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(
        `/process-instance/${processInstanceId}/variables`,
        {
          deserializeValues,
        },
      ),
    },
    transforms: [parseAllValues, ...transforms],
    token,
  });
};

export const getHistoricProcessVariables = ({
  processInstanceId,
  transforms = [],
  deserializeValues = false,
  token,
}) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri("/history/variable-instance", {
        processInstanceId,
        deserializeValues,
      }),
    },
    transforms: [parseJsonValues, ...transforms],
    token,
  });
};

export const getProcessVariable = ({
  processInstanceId,
  varName,
  deserializeValue = false,
  transforms = [],
  token,
}) => {
  return get({
    url: API_CAMUNDA,
    queryParams: {
      bpmn_uri: createCamundaUri(
        `/process-instance/${processInstanceId}/variables/${varName}`,
        {
          deserializeValue,
        },
      ),
    },
    transforms: [parseValue, ...transforms],
    token,
  });
};

export const updateProcessVariable = ({
  processInstanceId,
  varName,
  type = "Json",
  value,
  token,
}) => {
  return post({
    url: API_CAMUNDA,
    body: {
      bpmn_uri: createCamundaUri(
        `/process-instance/${processInstanceId}/variables`,
      ),
      data: {
        modifications: {
          [varName]: {
            type,
            value: type === "Json" ? JSON.stringify(value) : value,
          },
        },
      },
    },
    token,
  });
};

/**
 * This is a convenience wrapper for the two API calls needed to execute a BPM on the Camunda Engine
 */
export const executeBpmDiagram = async ({ bpmFilename, xmlContent, token }) => {
  const [
    createDeploymentError,
    createDeploymentResponse,
  ] = await createDeploymentOnCamundaEngine({ bpmFilename, xmlContent, token });

  const deployedProcessDefinitions =
    createDeploymentResponse?.deployedProcessDefinitions || {};
  const firstDeployedProcessDefinition = Object.values(
    deployedProcessDefinitions,
  )[0];
  const processDefinitionKey = firstDeployedProcessDefinition?.key;

  const [
    startProcessInstanceError,
    startProcessInstanceResponse,
  ] = await startProcessInstanceOnCamundaEngine({
    processDefinitionKey,
    token,
  });

  return [
    createDeploymentError ?? startProcessInstanceError,
    startProcessInstanceResponse,
  ];
};

/* Spec: https://docs.camunda.org/manual/7.14/reference/rest/signal/post-signal/
 * Restart a suspended BPM by User Breakpoints
 */
export const throwSignal = ({ name, executionId, token }) => {
  return post({
    url: API_CAMUNDA,
    token,
    body: {
      bpmn_uri: "/signal",
      data: {
        name,
        executionId,
      },
    },
  });
};

/* Spec: https://docs.camunda.org/manual/7.14/reference/rest/message/post-message/
 * Restart a suspended BPM with Variable
 */
export const correlateMessage = ({
  messageName,
  processInstanceId,
  processVariables,
  token,
}) => {
  const stringifiedProcessVariables = Object.entries(processVariables).reduce(
    (acc, [key, value]) =>
      value?.value && value?.type === "Json"
        ? {
            ...acc,
            [key]: { type: value.type, value: JSON.stringify(value.value) },
          }
        : { ...acc, [key]: value },
    {},
  );
  return post({
    url: API_CAMUNDA,
    token,
    body: {
      bpmn_uri: "/message",
      data: {
        messageName,
        processInstanceId,
        processVariables: stringifiedProcessVariables,
      },
    },
  });
};

const parseJobConfiguration = (response) =>
  response.map((entry) => {
    if (entry.jobType === "timer-start-event") {
      const { jobConfiguration } = entry;
      const timerType = jobConfiguration.substr(
        0,
        jobConfiguration.indexOf(":"),
      );
      if (timerType === "CYCLE") {
        // example of jobConfiguration "CYCLE: R1/2021-03-31T05:56:32.819Z/PT1M"
        const [interval, startDateInString, duration] =
          jobConfiguration?.replace(`${timerType}: `, "")?.split("/") ?? [];

        const recurringTime = parseInt(interval.replace("R", "")) + 1;
        const {
          add,
          value,
          periodUnit,
          label: periodUnitLabel,
        } = parseDuration(duration);
        const startDate = new Date(startDateInString);

        const dueDates = [...Array(recurringTime)].map((_, i) =>
          add(startDate, i * value),
        );
        const endDate = dueDates[dueDates.length - 1];
        const nextExecDate = dueDates.find(
          (e) => new Date().getTime() < e.getTime(),
        );
        return {
          ...entry,
          timerType,
          periodUnit,
          periodUnitLabel,
          recurringTime,
          startDate,
          endDate,
          nextExecDate,
          periodicityValue: value,
        };
      }
      if (timerType === "DATE") {
        // example of jobConfiguration "DATE: 2021-04-16T04:16:00.000Z"
        const endDate = new Date(
          jobConfiguration.replace(`${timerType}: `, ""),
        );
        const nextExecDate =
          new Date().getTime() < endDate.getTime() && endDate;
        return {
          ...entry,
          timerType,
          periodUnitLabel: "Once",
          endDate,
          nextExecDate,
        };
      }
    }
    return entry;
  });

/* Spec: https://docs.camunda.org/manual/7.14/reference/rest/job-definition/get-query/
 * Queries for job definitions that fulfill given parameters.
 */
export const getJobDefinitions = ({
  jobType,
  subtenantId,
  bpmFilename,
  processDefinitionId,
  transforms = [parseJobConfiguration],
  token,
}) => {
  return get({
    url: API_CAMUNDA,
    token,
    transforms,
    queryParams: {
      bpmn_uri: createCamundaUri("/job-definition", {
        jobType,
        processDefinitionKey:
          subtenantId &&
          bpmFilename &&
          Bpm.buildProcessDefinitionKey(subtenantId, bpmFilename),
        processDefinitionId,
      }),
    },
  });
};

/* Spec: https://docs.camunda.org/manual/7.14/reference/rest/job-definition/get-query/
 * Queries for job definitions that fulfill given parameters.
 */
export const getJobs = ({
  jobType,
  subtenantId,
  bpmFilename,
  // active = true,
  token,
}) => {
  return get({
    url: API_CAMUNDA,
    token,
    queryParams: {
      bpmn_uri: createCamundaUri("/job", {
        timers: true,
        jobType,
        processDefinitionKey: Bpm.buildProcessDefinitionKey(
          subtenantId,
          bpmFilename,
        ),
      }),
    },
  });
};

const createSubtenantObjects = (response) =>
  Object.entries(response).map(([id, name]) => ({
    id,
    name,
  }));

export const getSubtenantsByBpm = ({
  token,
  managerId: managerID,
  tenantId: tenant_id,
  bpmPath: URI,
  transforms = [createSubtenantObjects],
}) => {
  return get({
    url: `${API}/repository/v1/usage`,
    queryParams: {
      managerID,
      tenant_id,
      URI,
    },
    token,
    transforms,
  });
};

export const attachServicesToMultipleSubtenants = ({
  token,
  uri,
  ubiqubeIds,
  transforms = [],
}) => {
  return post({
    url: `${API}/orchestration/service/attach`,
    queryParams: {
      uri,
      ubiqubeIds,
    },
    token,
    transforms,
  });
};

export const detachServicesFromMultipleSubtenants = ({
  token,
  uri,
  ubiqubeIds,
  transforms = [],
}) => {
  return post({
    url: `${API}/orchestration/service/detach`,
    queryParams: {
      uri,
      ubiqubeIds,
    },
    token,
    transforms,
  });
};
