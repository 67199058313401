import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { change, getFormSyncErrors, touch } from "redux-form";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Variable from "msa2-ui/src/services/Variable";
import get from "lodash/get";

import { getFormValues } from "msa2-ui/src/store/form";

import { makeStyles } from "@material-ui/core";
import { Button, Grid, Typography, Tooltip, Paper } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { ReactComponent as DeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import { ReactComponent as Delete } from "msa2-ui/src/assets/icons/delete.svg";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";

import VariableEditor from "./VariableEditor";

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    position: "fixed",
    marginRight: 20,
    padding: 10,
  },
  section: {
    padding: 15,
  },
  createEditDescriptionContainer: {
    textAlign: "center",
  },
  createButton: {
    marginTop: 24,
  },
  createButtonIcon: {
    marginRight: 5,
  },
  deleteButton: {
    color: palette.text.primary,
    margin: 3,
  },
  deleteButtonRed: {
    color: palette.error.main,
    margin: 3,
  },
  editSaveButton: {
    marginLeft: 5,
  },
}));

const VariablesEdit = ({
  form,
  touch,
  createVariable,
  deleteVariable,
  saveVariable,
  cancelEdit,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { variablePath } = useSelector(getFormValues(form, "config"));

  const { t } = useTranslation("variables");

  const activeVariable = useSelector(getFormValues(form, "active"));
  const activeVariablePath = `${variablePath}.${activeVariable?.index.toString()}`;
  const variable = useSelector(
    getFormValues(form, [variablePath, activeVariable?.index].join(".")),
  );

  const currentVariable = useSelector(
    getFormValues(form, `${activeVariablePath}.name`),
  );

  const [hasError, setHasError] = useState();

  const formError = useSelector(getFormSyncErrors(form));
  const variableError = get(formError, activeVariablePath);
  useEffect(() => {
    if (activeVariable) {
      const hasError = Object.values(variableError ?? {}).some((error) => {
        if (Array.isArray(error)) {
          return error.some((message) => Boolean(message));
        }
        return Boolean(error);
      });
      setHasError(hasError);
      if (hasError) {
        Object.keys(variableError).forEach((field) =>
          touch(form, `${activeVariablePath}.${field}`),
        );
      }
    }
  }, [activeVariable, activeVariablePath, form, touch, variableError]);

  const cannotDelete =
    Variable.isMandatoryItem(currentVariable, form) || variable?.isCompositeKey;

  const ControlButtons = () => (
    <Grid container justifyContent="space-between" className={classes.section}>
      <Grid item>
        {activeVariable?.variable && (
          <Tooltip
            title={cannotDelete ? t("You cannot delete a key variables.") : ""}
          >
            <span>
              <Button
                id="MICROSERVICE_VARIABLES_EDIT_DETAILS_BTN_DELETE"
                size="small"
                color="primary"
                disabled={cannotDelete}
                onClick={() =>
                  deleteVariable(
                    activeVariable.index,
                    activeVariable.variable.displayName,
                  )
                }
              >
                {cannotDelete ? <Delete /> : <DeleteRed />}
                <Typography
                  variant={"h5"}
                  className={classnames(
                    cannotDelete
                      ? classes.deleteButton
                      : classes.deleteButtonRed,
                  )}
                >
                  {t("DELETE")}
                </Typography>
              </Button>
            </span>
          </Tooltip>
        )}
      </Grid>
      <Grid item>
        <Button
          id="MICROSERVICE_VARIABLES_EDIT_DETAILS_BTN_CANCEL"
          variant="text"
          size="small"
          color="default"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => {
            cancelEdit();
          }}
        >
          {t("Cancel")}
        </Button>
        <Tooltip title={hasError ? t("Resolve errors to save.") : ""}>
          <span>
            <Button
              id="MICROSERVICE_VARIABLES_EDIT_DETAILS_BTN_SAVE"
              className={classes.editSaveButton}
              variant="contained"
              size="small"
              color="primary"
              disabled={hasError}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                saveVariable();
              }}
            >
              {t("Save")}
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );

  return (
    <Paper
      className={classnames(commonClasses.commonPaperNoPadding, classes.paper)}
    >
      {!activeVariable ? (
        <Grid
          container
          className={classnames(
            commonClasses.commonFlexCenter,
            classes.section,
          )}
        >
          <Grid item xs={12} className={classes.createEditDescriptionContainer}>
            <Typography className={commonClasses.commonDescription}>
              {t("Choose a variable to edit, or create a new one.")}
            </Typography>
          </Grid>
          <Button
            variant="contained"
            id="VARIABLES_EDIT_CREATE_VARIABLE"
            size="medium"
            color="primary"
            aria-label={t("Create Variable")}
            className={classes.createButton}
            onClick={createVariable}
          >
            <IconPlus className={classes.createButtonIcon} />
            {t("Create Variable")}
          </Button>
        </Grid>
      ) : (
        <Grid container>
          <ControlButtons />
          <VariableEditor
            form={form}
            variablePath={variablePath}
            activeVariable={activeVariable}
          />
        </Grid>
      )}
    </Paper>
  );
};

VariablesEdit.propTypes = {
  form: PropTypes.string.isRequired,
  createVariable: PropTypes.func,
  deleteVariable: PropTypes.func,
  saveVariable: PropTypes.func,
  cancelEdit: PropTypes.func,
};

export default connect(null, { change, touch })(VariablesEdit);
