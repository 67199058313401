import React from "react";
import get from "lodash/get";

import Repository from "msa2-ui/src/services/Repository";
import {
  addTaskToRepository,
  updateTaskInRepository,
  deleteTaskInRepository,
  generateTaskForMicroServiceCall,
} from "msa2-ui/src/api/task";
import { extractVariablesFromTaskWithType } from "msa2-ui/src/api/workflow";
import {
  checkRepositoryExistence,
  addDirectory,
} from "msa2-ui/src/api/repository";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import { change as reduxChange } from "redux-form";
import { getFormValues } from "msa2-ui/src/store/form";
import {
  fetchedTemplates,
  getTaskTemplates,
  getAdvancedVariableParametersByName,
  getAdvancedVariableParametersByType,
} from "msa2-ui/src/store/designations";

import Task from "msa2-ui/src/services/Task";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { getToken } from "msa2-ui/src/store/auth";
import Variable from "msa2-ui/src/services/Variable";

const useWorkflowTask = ({ activeProcessIndex, setIsLoading, form }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const change = (key, value) => dispatch(reduxChange(form, key, value));

  const advancedVariableParametersByName = useSelector(
    getAdvancedVariableParametersByName(form),
  );
  const advancedVariableParameters = useSelector(
    getAdvancedVariableParametersByType(form),
  );
  const workflowData = useSelector(getFormValues(form)) ?? {};
  const { process: workflowProcesses = [] } = workflowData;
  const activeWorkflowProcess = workflowProcesses[activeProcessIndex];
  const variablePath = workflowData.config?.variablePath;
  const variables = get(workflowData, variablePath, []);
  const taskTemplates = useSelector(getTaskTemplates);

  const showSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  const extractVariableFromTask = async (uri) => {
    const [, variablesFromTask] = await extractVariablesFromTaskWithType({
      token,
      task: uri,
    });
    if (variablesFromTask.length) {
      const variablesToAdd = Variable.getVariablesToAdd(
        variables,
        variablesFromTask,
        advancedVariableParametersByName,
        advancedVariableParameters,
      );
      if (variablesToAdd.length) {
        change([variablePath], variables.concat(variablesToAdd));
        showSnackBar(t(`New Variable added from Task`));
      }
    }
  };

  const addTaskToForm = ({ taskName, fileName, fileUri }) => {
    const newTaskIndex = activeWorkflowProcess.tasks?.length || 0;
    change(
      `process.${activeProcessIndex}.tasks.${newTaskIndex}`,
      Task.make({
        displayName: taskName,
        fileName,
        fileUri,
      }),
    );
  };

  const addTask = async ({
    templateType,
    taskName,
    fileName: _fileName,
    microServiceUri,
    crudMethod,
  }) => {
    setIsLoading(true);
    const taskType = workflowData.information.serviceTaskType;
    const filepath = [activeWorkflowProcess.name, Task.DIRECTORY_NAME].join(
      "/",
    );

    const [, directoryExists] = await checkRepositoryExistence({
      uri: filepath,
      token,
    });

    if (!directoryExists) {
      await addDirectory({
        uri: filepath,
        token,
      });
    }

    let error = null;
    let fileName = _fileName;

    if (templateType === Task.BASE_TYPES.MS_CALL.value) {
      // if template type is MS Call, call Task auto generation API
      const response = await generateTaskForMicroServiceCall({
        microServiceUri,
        crudMethod,
        workflowLanguage: taskType,
        processPath: filepath,
        token,
      });
      [error] = response;
      const uri = response[1]?.uri;
      if (uri) {
        fileName = Repository.getFilenameFromUri(uri);
        extractVariableFromTask(uri);
      }
    } else if (templateType === Task.BASE_TYPES.TEMPLATE.value) {
      // if template type is TEMPLATE, create a task file based on passed template
      [error] = await addTaskToRepository({
        filename: fileName,
        filepath,
        content: taskTemplates[taskType],
        token,
      });
    } else {
      // if not, create a blank file
      [error] = await addTaskToRepository({
        filename: fileName,
        filepath,
        content: "",
        token,
      });
    }
    const variant = error ? "error" : "success";
    const message = error
      ? // WF Editor is for Developer so we can always show developerMessage if there is
        error.data?.developerMessage ??
        error.data?.message ??
        t("Unable to save x", { x: t("Task") })
      : t("x has been created", { x: t("Task") });

    if (!error) {
      const fileUri = Repository.maybeAddRepositoryPrefix(filepath);
      addTaskToForm({ taskName, fileName, fileUri });
    }

    showSnackBar(message, variant);
    setIsLoading(false);
  };

  const updateTemplate = (taskUri, taskCode) => {
    /**
     * If the file that is currently being edited is the task template itself
     * We want to make sure we update our local reference to it so it is clear
     * that it is updated
     **/
    const templateTaskType = Task.getTemplateType(taskUri);
    const isTemplateTask = Boolean(templateTaskType);
    if (isTemplateTask) {
      dispatch(fetchedTemplates({ [templateTaskType]: taskCode }));
    }
  };

  const saveContentsToFile = async (taskCode, task, callback) => {
    setIsLoading(true);
    const taskIndex = Task.getTaskIndex(activeWorkflowProcess, task);
    const activeTask = activeWorkflowProcess.tasks[taskIndex];
    const taskUri = Task.getTaskUri(activeTask);
    const [error] = await updateTaskInRepository({
      taskUri,
      content: taskCode,
      token,
    });
    if (!error) {
      callback();
      updateTemplate(taskUri, taskCode);
      extractVariableFromTask(Repository.removeRepositoryPrefix(taskUri));
    }
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to save x", { x: t("Task") }))
      : t(`Task Saved`);

    showSnackBar(message, variant);
    setIsLoading(false);
  };

  const removeTaskFile = async (task) => {
    setIsLoading(true);
    const taskUri = Task.getTaskUri(task);
    const [error] = await deleteTaskInRepository({
      taskUri,
      token,
    });
    if (!error) {
      removeTaskFromForm(task);
    }
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to delete x", { x: t("Task") }))
      : t("Task Deleted");

    showSnackBar(message, variant);
    setIsLoading(false);
  };

  const removeTaskFromForm = (task) => {
    const taskIndex = Task.getTaskIndex(activeWorkflowProcess, task);
    const remainingTasks =
      [...workflowProcesses[activeProcessIndex].tasks] || [];
    remainingTasks.splice(taskIndex, 1);
    change(`process.${activeProcessIndex}.tasks`, remainingTasks);
  };

  return {
    addTask,
    addTaskToForm,
    removeTaskFile,
    removeTaskFromForm,
    saveContentsToFile,
    extractVariableFromTask,
  };
};

export default useWorkflowTask;
