import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {
  List,
  ListItem as MUIListItem,
  ListItemIcon,
  ListItemText,
  Divider as MUIDivider,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { mergeStyles } from "msa2-ui/src/utils/styles";
import commonStyles from "msa2-ui/src/styles/commonStyles";
import { DragIndicator as DragIcon } from "@material-ui/icons";
import { useDrag, useDrop } from "react-dnd";

const sidebarStyles = (theme) => ({
  sidebar: {
    minHeight: "100%",
    backgroundColor: theme.palette.type === "dark" ? "transparent" : "#e5e8f0",
    padding: "10px",
  },
});

const Sidebar = ({ classes, children }) => {
  return <List className={classes.sidebar}>{children}</List>;
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
};

const dividerStyles = () => ({
  divider: {
    backgroundColor: "#d2d7e1",
    marginBottom: 10,
  },
});

const Divider = ({ classes }) => {
  return <MUIDivider className={classes.divider} />;
};

Divider.propTypes = {
  classes: PropTypes.object.isRequired,
};

Sidebar.Divider = withStyles(dividerStyles)(Divider);

const listItemStyles = mergeStyles([
  commonStyles,
  ({ palette, typography }) => ({
    listItem: {
      padding: "10px 20px",
      marginBottom: 10,
      borderRadius: "22px",
    },
    listItemIcon: {
      marginRight: 12,
    },
    text: {
      fontWeight: typography.fontWeightMedium,
      fontSize: ".9rem",
    },
    count: {
      fontWeight: typography.fontWeightLight,
      fontSize: "0.8125rem",
      color: palette.text.secondary,
    },
    selected: {
      backgroundColor: "#7fa1d8",
      "&:hover, &:focus": {
        backgroundColor: "#7fa1d8",
      },
    },
    selectedText: {
      color: palette.common.white,
    },
    wrapper: {
      position: "relative",
      paddingLeft: 25,
    },
    handle: {
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      display: "flex",
    },
  }),
]);

const ListItem = ({
  classes,
  id,
  selected,
  onClick,
  title,
  icon: Icon,
  section,
  count,
  tooltipText,
  tooltipPlacement = "bottom",
}) => {
  const renderListItem = () => {
    return (
      <MUIListItem
        className={classnames(classes.listItem, {
          [classes.selected]: selected,
        })}
        key={title}
        id={id}
        button
        onClick={() => onClick(section)}
      >
        {Icon && (
          <ListItemIcon className={classes.listItemIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={title}
          classes={{
            primary: classnames(classes.text, {
              [classes.selectedText]: selected,
            }),
          }}
        />
        {count && (
          <ListItemText
            primary={count}
            className={classes.commonFlexEnd}
            classes={{
              primary: classnames(classes.count, {
                [classes.selectedText]: selected,
              }),
            }}
          />
        )}
      </MUIListItem>
    );
  };

  return (
    <li>
      {tooltipText ? (
        <Tooltip
          title={tooltipText}
          placement={tooltipPlacement}
          enterDelay={500}
        >
          {renderListItem()}
        </Tooltip>
      ) : (
        renderListItem()
      )}
    </li>
  );
};

ListItem.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  icon: PropTypes.object,
  id: PropTypes.string,
  section: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Sidebar.ListItem = withStyles(listItemStyles)(ListItem);

const ListItemDraggable = ({ classes, type, index, onDragged, ...rest }) => {
  const handleRef = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type, index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: type,
    hover: (item, monitor) => {
      if (!handleRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = handleRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onDragged(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(handleRef));

  return (
    <div
      ref={preview}
      className={classes.wrapper}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <div ref={handleRef} className={classes.handle}>
        <DragIcon
          color="secondary"
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
        />
      </div>
      <ListItem classes={classes} {...rest} />
    </div>
  );
};

ListItemDraggable.propTypes = {
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDragged: PropTypes.func.isRequired,
};

Sidebar.ListItemDraggable = withStyles(listItemStyles)(ListItemDraggable);

export default withStyles(sidebarStyles)(Sidebar);
