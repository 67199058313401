import { post, put } from "./request";

const API = process.env.REACT_APP_API_PATH;

const extractLogsFromResponse = ({
  searchResults: logs = [],
  hitCount: count = 0,
}) => ({ logs, count });

const extractTriggeredAlarmsFromResponse = ({
  searchResults: logs = [],
  hitCount: count = 0,
}) => ({ logs, count });

const extractAuditLogsFromResponse = ({
  searchResults: logs = [],
  hitCount: count = 0,
}) => {
  const auditLogs = logs.map((log) => {
    return { ...log.fields };
  });
  return { logs: auditLogs, count };
};

/**
 * Spec: https://10.31.1.52/swagger/#/ElasticSearchManager/searchLogs
 */
export const getLogs = ({
  // assuming these 2 parameters are not needed for this API, leave them hardcoded until I get the answer from PD team
  actorId = 1,
  checkPrivilege = true,
  queryString: terms,
  mustTerms,
  mustNotTerms,
  startDate,
  endDate,
  srcIpOperator,
  srcIP,
  dstIpOperator,
  dstIP,
  dstPortOperator,
  dstPort,
  srcPortOperator,
  srcPort,
  sortOrder,
  sortField = "date",
  managedEntityIds: deviceIds = [],
  timeRangeOperator,
  highlight,
  logType = null,
  severities = [],
  subtenantIds: customerIds = [],
  pageSize,
  from,
  displayMode,
  aggregationFields = [],
  id,
  transforms = [extractLogsFromResponse],
  token,
}) => {
  return post({
    url: `${API}/elastic-search/v1/logs`,
    token,
    queryParams: { actorId, checkPrivilege },
    body: {
      terms,
      mustTerms,
      mustNotTerms,
      startDate,
      endDate,
      srcIpOperator,
      srcIP,
      dstIpOperator,
      dstIP,
      dstPortOperator,
      dstPort,
      srcPortOperator,
      srcPort,
      sortOrder,
      sortField,
      deviceIds,
      timeRangeOperator,
      highlight,
      logType,
      severities,
      customerIds,
      pageSize,
      from,
      displayMode,
      aggregationFields,
      id,
    },
    transforms,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/ElasticSearchManager/searchAlarms
 */
export const getTriggeredAlarms = ({
  actorId = 1,
  checkPrivilege = true,
  gte,
  lte,
  sortField = "date",
  subtenantIds: customerIds = [],
  severity,
  deviceID,
  search,
  ack,
  rule,
  startDate,
  endDate,
  pageSize,
  logType = null,
  from,
  transforms = [extractTriggeredAlarmsFromResponse],
  token,
}) => {
  return post({
    url: `${API}/elastic-search/v1/logs/alarms`,
    token,
    queryParams: { actorId, checkPrivilege },
    body: {
      gte,
      lte,
      customerIds,
      sortField,
      pageSize,
      logType,
      severity,
      deviceID,
      search,
      ack,
      rule,
      from,
      startDate,
      endDate,
    },
    transforms,
  });
};

export const getAlarmsDownloadFilePath = ({
  actorId = 1,
  checkPrivilege = true,
  gte,
  lte,
  sortField = "date",
  subtenantIds: customerIds = [],
  severity,
  deviceID,
  search,
  ack,
  rule,
  startDate,
  endDate,
  pageSize,
  logType = null,
  from,
  fields,
  token,
}) => {
  return post({
    url: `${API}/elastic-search/v1/logs/alarms/export`,
    token,
    queryParams: { actorId, checkPrivilege },
    body: {
      gte,
      lte,
      customerIds,
      sortField,
      pageSize,
      logType,
      severity,
      deviceID,
      search,
      ack,
      rule,
      from,
      fields,
      startDate,
      endDate,
    },
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/ElasticSearchManager/ Update an ES document entry
 **/
export const acknowledgeAlarm = ({ token, body, transforms = [] }) => {
  return put({
    url: `${API}/elastic-search/v2/alarm/acknowledge`,
    token,
    body,
  });
};

export const acknowledgeAllAlarms = ({
  token,
  body: { subtenantIds: customerIds = [], sortField = "date", ...restBody },
  transforms = [],
}) => {
  return put({
    url: `${API}/elastic-search/v2/alarm/acknowledge-all`,
    token,
    body: {
      ...restBody,
      customerIds,
      sortField,
      ack: "false",
    },
  });
};

export const getAuditLogs = ({
  body,
  token,
  transforms = [extractAuditLogsFromResponse],
}) => {
  return put({
    url: `${API}/elastic-search/audit-log`,
    body: {
      ...body,
    },
    token,
    transforms,
  });
};

export const getAlarmSummary = ({
  actorId = 1,
  token,
  gte,
  lte,
  sortField = "date",
  subtenantIds: customerIds = [],
  severity,
  deviceID,
  search,
  ack,
  rule,
  startDate,
  endDate,
  pageSize,
  logType = null,
  from,
}) => {
  return post({
    url: `${API}/elastic-search/v1/logs/alarms/summary`,
    token,
    queryParams: { actorId },
    body: {
      gte,
      lte,
      customerIds,
      sortField,
      pageSize,
      logType,
      severity,
      deviceID,
      search,
      ack,
      rule,
      from,
      startDate,
      endDate,
    },
  });
};
