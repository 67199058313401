import { head } from "./request";

/**
 * No swagger doc
 *
 * This endpoint is made available in the msa-docker front
 * nginx config. It is the wildfly status page which becomes
 * available when the API container comes online.
 *
 * We use the HEAD method because we don't care about the response
 * body at all, just that the the request is successful.
 */
export const pingApi = () => {
  return head({
    url: "/ubi-api-ping/",
    /**
     * Never cache the response because we want to make sure we get the
     * most update to date response from the server each time
     */
    customHeaders: { "Cache-Control": "no-cache" },
  });
};
