import EditorAce from "msa2-ui/src/components/EditorAce";

/**
 * COMPONENTS
 */
import createComponent from "./createComponent";

export default createComponent(EditorAce, ({ input, options, ...props }) => {
  const { onChange, ...restOfInput } = input;
  return {
    onChange: (val) => {
      onChange(val);
    },
    options: {
      maxLines: Infinity,
      ...options,
    },
    ...restOfInput,
    ...props,
  };
});
