import React from "react";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getIsDeveloper } from "msa2-ui/src/store/auth";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";

import { useSelector } from "react-redux";
import { getDelegationProfile } from "msa2-ui/src/store/delegationProfiles";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";

import SectionTabs from "msa2-ui/src/components/SectionTabs";
import ManagedEntityOverview from "./managed-entities/overview/ManagedEntityOverview";
import ManagedEntityCreate from "./managed-entities/create/ManagedEntityCreate";
import ManagedEntityDetail from "./managed-entities/detail/ManagedEntityDetail";
import ManagedEntityEdit from "./managed-entities/edit/ManagedEntityEdit";
import ManagedEntityDuplicate from "./managed-entities/duplicate/ManagedEntityDuplicate";
import MicroservicesOverview from "./microservices/Overview";
import MicroservicesEdit from "./microservices/Edit";
import MicroservicesCreate from "./microservices/Create";
import DeploymentSettingsOverview from "./deployment-settings/Overview";
import DeploymentSettingsEdit from "./deployment-settings/Edit";
import DeploymentSettingsCreate from "./deployment-settings/Create";
import MonitoringProfiles from "./monitoring-profiles/MonitoringProfiles";
import MonitoringProfileCreate from "./monitoring-profile-create/MonitoringProfileCreate";
import MonitoringProfileEdit from "./monitoring-profile-edit/MonitoringProfileEdit";
import Logs from "../alarms/Logs";
import IncidentTicketOverview from "./incident-tickets/IncidentTicketsOverview";
import FeatureFlag from "../../services/FeatureFlag";

const IntegrationRouter = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const defaultRoute = "/integration/managed-entities";
  const isDeveloper = useSelector(getIsDeveloper);

  const canCreateManagedEntity = useSelector(
    getDelegationProfile(
      delegationProfileTypes.MANAGED_ENTITIES,
      "general.create",
    ),
  );
  const canEditManagedEntity = useSelector(
    getDelegationProfile(
      delegationProfileTypes.MANAGED_ENTITIES,
      "general.modify",
    ),
  );
  const canCreateMicroservice = useSelector(
    getDelegationProfile(delegationProfileTypes.MICROSERVICE, "general.create"),
  );
  const canEditMicroservice = useSelector(
    getDelegationProfile(delegationProfileTypes.MICROSERVICE, "general.modify"),
  );
  const canViewDeploymentSettings = useSelector(
    getDelegationProfile(
      delegationProfileTypes.DEPLOYMENT_SETTINGS,
      "general.view",
    ),
  );
  const canCreateDeploymentSettings = useSelector(
    getDelegationProfile(
      delegationProfileTypes.DEPLOYMENT_SETTINGS,
      "general.create",
    ),
  );
  const canEditDeploymentSettings = useSelector(
    getDelegationProfile(
      delegationProfileTypes.DEPLOYMENT_SETTINGS,
      "general.modify",
    ),
  );
  const canViewMonitoringProfiles = useSelector(
    getDelegationProfile(
      delegationProfileTypes.MONITORING_PROFILES,
      "general.view",
    ),
  );
  const canViewLogs = useSelector(
    getDelegationProfile(delegationProfileTypes.LOGS, "general.view"),
  );
  const canViewMicroservice = useSelector(
    getDelegationProfile(delegationProfileTypes.MICROSERVICE, "general.view"),
  );
  const canCreateMonitoringProfiles = useSelector(
    getDelegationProfile(
      delegationProfileTypes.MONITORING_PROFILES,
      "general.create",
    ),
  );
  const canEditMonitoringProfiles = useSelector(
    getDelegationProfile(
      delegationProfileTypes.MONITORING_PROFILES,
      "general.modify",
    ),
  );

  const canAccessIncidentTicketManagement = FeatureFlag.isEnabled(
    FeatureFlag.features.incidentTicketManagement,
  );

  const tabs = [
    {
      id: "MANAGED_ENTITIES_TAB",
      path: "/integration/managed-entities",
      displayName: t("Managed Entities"),
    },
    {
      id: "LOGS_TAB",
      path: "/integration/logs",
      displayName: t("Logs"),
      canAccess: canViewLogs,
    },
    {
      id: "MICROSERVICES_TAB",
      path: "/integration/microservices",
      displayName: t("Microservices"),
      canAccess: canViewMicroservice,
    },
    {
      id: "DEPLOYMENT_SETTINGS_TAB",
      path: "/integration/deployment-settings",
      displayName: t("Deployment Settings"),
      canAccess: canViewDeploymentSettings,
    },
    {
      id: "MONITORING_PROFILE_TAB",
      path: "/integration/monitoring-profiles",
      displayName: t("Monitoring Profiles"),
      canAccess: canViewMonitoringProfiles,
    },
    {
      id: "INCIDENT_TICKETS_TAB",
      path: "/integration/incident-tickets",
      displayName: t("Incident Tickets"),
      canAccess: canAccessIncidentTicketManagement,
    },
  ];

  const IntegrationTabs = ({ count, endElements }) => {
    const tabsWithCounts = tabs.map((tab) => {
      const [path, value] = count ?? [];
      if (tab.path === path) {
        return {
          ...tab,
          count: value,
        };
      }
      return tab;
    });
    return <SectionTabs tabs={tabsWithCounts} endElements={endElements} />;
  };

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Redirect to={defaultRoute} />
        </Route>
        <Route
          exact
          path={`${path}/managed-entities`}
          render={() => <ManagedEntityOverview sectionTabs={IntegrationTabs} />}
        />
        <AuthorizedRoute
          exact
          guard={canCreateManagedEntity}
          redirect={defaultRoute}
          path={`${path}/managed-entities/create`}
          render={() => <ManagedEntityCreate />}
        />
        <AuthorizedRoute
          exact
          guard={canEditManagedEntity}
          redirect={defaultRoute}
          path={`${path}/managed-entities/:assetId/edit`}
          render={() => <ManagedEntityEdit />}
        />
        <AuthorizedRoute
          exact
          guard={canCreateManagedEntity}
          redirect={defaultRoute}
          path={`${path}/managed-entities/:assetId/duplicate`}
          render={() => <ManagedEntityDuplicate />}
        />
        <Route
          path={`${path}/managed-entities/:assetId`}
          render={() => <ManagedEntityDetail />}
        />
        <AuthorizedRoute
          exact
          guard={canViewLogs}
          redirect={defaultRoute}
          path={`${path}/logs`}
          render={() => (
            <Logs sectionTabs={IntegrationTabs} createAlarmView={false} />
          )}
        />
        <Route
          exact
          path={`${path}/microservices`}
          render={() => <MicroservicesOverview sectionTabs={IntegrationTabs} />}
        />
        <AuthorizedRoute
          exact
          guard={isDeveloper && canEditMicroservice}
          redirect={defaultRoute}
          path={`${path}/microservices/:microServiceUri/edit/`}
          render={() => <MicroservicesEdit />}
        />
        <AuthorizedRoute
          exact
          guard={isDeveloper && canCreateMicroservice}
          redirect={defaultRoute}
          path={`${path}/microservices/create/`}
          render={() => <MicroservicesCreate />}
        />
        <AuthorizedRoute
          guard={canViewDeploymentSettings}
          redirect={defaultRoute}
          path={`${path}/deployment-settings`}
        >
          <DeploymentSettingsOverview sectionTabs={IntegrationTabs} />
          {canCreateDeploymentSettings && (
            <AuthorizedRoute
              guard={canCreateDeploymentSettings}
              redirect={defaultRoute}
              path={`${path}/deployment-settings/create`}
            >
              <DeploymentSettingsCreate />
            </AuthorizedRoute>
          )}
          {canEditDeploymentSettings && (
            <AuthorizedRoute
              guard={canEditDeploymentSettings}
              redirect={defaultRoute}
              path={`${path}/deployment-settings/:id/edit`}
            >
              <DeploymentSettingsEdit />
            </AuthorizedRoute>
          )}
        </AuthorizedRoute>
        <AuthorizedRoute
          exact
          guard={canViewMonitoringProfiles}
          redirect={defaultRoute}
          path={`${path}/monitoring-profiles`}
          render={() => <MonitoringProfiles sectionTabs={IntegrationTabs} />}
        />
        <AuthorizedRoute
          exact
          guard={canCreateMonitoringProfiles}
          redirect={defaultRoute}
          path={`${path}/monitoring-profiles/create`}
          render={() => <MonitoringProfileCreate />}
        />
        <AuthorizedRoute
          exact
          guard={canEditMonitoringProfiles}
          redirect={defaultRoute}
          path={`${path}/monitoring-profiles/:profileId/edit`}
          render={() => <MonitoringProfileEdit />}
        />
        <AuthorizedRoute
          exact
          guard={canAccessIncidentTicketManagement}
          redirect={defaultRoute}
          path={`${path}/incident-tickets`}
          render={() => (
            <IncidentTicketOverview sectionTabs={IntegrationTabs} />
          )}
        />
        <Redirect strict to={defaultRoute} />
      </Switch>
    </>
  );
};

export default IntegrationRouter;
