import React from "react";
import PropTypes from "prop-types";
import reduxForm from "redux-form/lib/reduxForm";
import { reduxFormNames } from "msa2-ui/src/Constants";
import { Divider, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import { required } from "msa2-ui/src/utils/validators";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";

const useStyles = makeStyles(({ darkMode, palette, spacing, typography }) => ({
  addressWrapper: {
    backgroundColor: darkMode ? "transparent" : "#f5f5f5",
    padding: "10px",
  },
  groupWrapper: {
    padding: "10px",
  },
  passwordLink: {
    margin: "0px 10px",
  },
  divider: {
    borderColor: "#e6eaee",
  },
  label: {
    fontSize: "1rem",
    fontWeight: typography.fontWeightLight,
    lineHeight: "normal",
    letterSpacing: 0.3,
    color: palette.text.secondary,
  },
}));

const EditDetails = ({
  onGeneratePassword,
  userCanViewAuthentication,
  userCanEditAuthentication,
  isManager,
}) => {
  const classes = useStyles();
  const t = useBoundedTranslation();

  return (
    <div style={{ margin: 25 }}>
      <Grid container spacing={5}>
        <Grid item md={6} lg={3}>
          <FieldSelector
            id="DETAILS_FIRST_NAME"
            noIndent
            name="firstname"
            label={t("First Name")}
            disabled={isManager}
          />
        </Grid>
        <Grid item md={6} lg={3}>
          <FieldSelector
            id="DETAILS_LAST_NAME"
            noIndent
            name="name"
            label={t("Last Name")}
            validate={[required]}
            disabled={isManager}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("BASIC INFORMATION")}</Typography>
        </Grid>
        <Grid item lg={4}>
          <FieldSelector
            id="DETAILS_EMAIL"
            name="address.mail"
            label={t("Email")}
            type="email"
            className={classes.textField}
            validate={[required]}
          />
        </Grid>
        {(userCanViewAuthentication || userCanEditAuthentication) && (
          <>
            <Grid item xs={12}>
              <Divider variant="fullWidth" className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                {t("AUTHENTICATION")}
              </Typography>
            </Grid>
            <Grid item md={6} lg={3}>
              <FieldSelector
                id="DETAILS_LOGIN"
                noIndent
                disabled
                name="login"
                label={t("Login ID")}
                validate={[required]}
                className={classes.textField}
              />
            </Grid>
            <Grid item md={6} lg={3}>
              <FieldSelector
                id="DETAILS_PASSWORD"
                noIndent
                disabled={!userCanEditAuthentication}
                name="password"
                label={t("Password")}
                type="password"
                className={classes.textField}
              />
              {userCanEditAuthentication && (
                <Link
                  id="DETAILS_GENERATE_PASSWORD"
                  component="button"
                  className={classes.link}
                  onClick={onGeneratePassword}
                  margin="normal"
                >
                  {t("Generate New Password")}
                </Link>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

EditDetails.propTypes = {
  onGeneratePassword: PropTypes.func.isRequired,
  userCanViewAuthentication: PropTypes.bool,
  userCanEditAuthentication: PropTypes.bool,
  isManager: PropTypes.bool,
};

export default reduxForm({
  form: reduxFormNames.profileForm,
})(EditDetails);
