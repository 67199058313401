import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import set from "lodash/set";
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom";
import difference from "lodash/difference";

import { getToken, getUserRole } from "msa2-ui/src/store/auth";
import {
  getAvailableTenants,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";
import { getSubtenantsByTenant } from "msa2-ui/src/api/subtenant";
import { createManager } from "msa2-ui/src/api/manager";
import useApi from "msa2-ui/src/hooks/useApi";
import { returnToPreviousPage } from "msa2-ui/src/utils/urls";
import { userRoles } from "msa2-ui/src/store/auth";

import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import Dialog from "msa2-ui/src/components/Dialog";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import Radio from "msa2-ui/src/components/connectedFormComponents/Radio";
import PasswordInput from "msa2-ui/src/components/PasswordInput";
import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import CreateTabs from "./CreateTabs";

const ASSOCIATED_SUBTENANTS_TAB_PATH =
  "/admin/managers/create/associated-subtenants";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const Create = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { url } = useRouteMatch();

  const selectedTenant = useSelector(getSelectedTenant);
  const tenants = useSelector(getAvailableTenants);
  const token = useSelector(getToken);
  const userRole = useSelector(getUserRole);

  const [firstname, setFirstname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tenant, setTenant] = useState(
    selectedTenant?.value ? selectedTenant.value : "",
  );
  const [roleId, setRoleId] = useState(userRoles.MANAGER);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usertype, setUserType] = useState(2);
  const [associatedSubtenantIds, setAssociatedSubtenantIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const [subtenantsLoading, subtenantsError, subtenants = []] = useApi(
    getSubtenantsByTenant,
    { tenantPrefix: tenant },
    !tenant,
  );

  const handlePortalAccessChange = ({ target: { name, checked } }) => {
    const portalView = {
      manager: [0, 2].includes(usertype),
      developer: [1, 2].includes(usertype),
    };
    set(portalView, name, checked);
    if (portalView.manager && !portalView.developer) {
      setUserType(0);
    } else if (!portalView.manager && portalView.developer) {
      setUserType(1);
    } else if (portalView.manager && portalView.developer) {
      setUserType(2);
    } else {
      setUserType(null);
    }
  };

  const onSubmit = async () => {
    setError("");

    if (
      !name ||
      !tenant ||
      !username ||
      !password ||
      !email ||
      usertype === null
    ) {
      return setError(t("Please fill out all the form fields"));
    }

    setIsSubmitting(true);
    const [error] = await createManager({
      token,
      tenantPrefix: tenant,
      firstname,
      name,
      email,
      username,
      password,
      usertype,
      privileged: roleId === userRoles.PRIVILEGED_MANAGER,
      subtenantIds: associatedSubtenantIds,
    });
    setIsSubmitting(false);

    const message = error ? error.getMessage() : t("Manager created");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      history.push("/admin/managers");
    }
  };

  const renderInformationTab = () => (
    <>
      <TextField
        variant="outlined"
        id="MANAGER_CREATE_FIRST_NAME"
        label={t("First Name")}
        value={firstname}
        onChange={(event) => setFirstname(event.target.value)}
        className={classes.formField}
      />
      <TextField
        variant="outlined"
        id="MANAGER_CREATE_NAME"
        label={t("Name")}
        value={name}
        required
        onChange={(event) => setName(event.target.value)}
        className={classes.formField}
      />
      <TextField
        variant="outlined"
        id="MANAGER_CREATE_EMAIL"
        label={t("Email")}
        value={email}
        required
        onChange={(event) => setEmail(event.target.value)}
        className={classes.formField}
      />
      <BasicSelect
        label={t("Tenant")}
        id="MANAGER_CREATE_TENANT"
        variant="outlined"
        name="CREATE_MANAGER_DIALOG_TENANT_PICKER"
        className={classes.formField}
        value={tenant}
        required
        onChange={(event) => {
          setAssociatedSubtenantIds([]);
          setTenant(event.target.value);
        }}
      >
        {tenants.map((tenant, index) => (
          <MenuItem
            value={tenant.value}
            key={index}
            id={`MANAGER_CREATE_TENANT_${index}`}
          >
            {tenant.label}
          </MenuItem>
        ))}
      </BasicSelect>
      <Radio
        label={t("Role")}
        input={{
          onChange: (value) => setRoleId(parseInt(value)),
          value: roleId,
        }}
        options={[
          {
            value: userRoles.MANAGER,
            displayName: t("Manager"),
            id: "MANAGER_CREATE_ROLE_0",
          },
          userRole !== userRoles.PRIVILEGED_MANAGER && {
            value: userRoles.PRIVILEGED_MANAGER,
            displayName: t("Privileged Manager"),
            id: "MANAGER_CREATE_ROLE_PRIVILEGED_1",
          },
        ].filter(Boolean)}
        className={classes.formField}
      />
      <TextField
        variant="outlined"
        id="MANAGER_CREATE_USERNAME"
        label={t("Username")}
        value={username}
        required
        onChange={(event) => setUsername(event.target.value)}
        className={classes.formField}
      />
      <PasswordInput
        variant="outlined"
        id="MANAGER_CREATE_PASSWORD"
        label={t("Password")}
        value={password}
        required
        onChange={(event) => setPassword(event.target.value)}
        className={classes.formField}
      />
      <FormControl
        required
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend" className={classes.formField}>
          {t("User Portal Access")}
        </FormLabel>
        <FormGroup>
          <Grid container direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  id="MANAGER_CREATE_MANAGER_PORTAL_ACCESS"
                  checked={[0, 2].includes(usertype)}
                  onChange={handlePortalAccessChange}
                  name="manager"
                  color="primary"
                />
              }
              label="Manager"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="MANAGER_CREATE_DEVELOPER_PORTAL_ACCESS"
                  checked={[1, 2].includes(usertype)}
                  onChange={handlePortalAccessChange}
                  name="developer"
                  color="primary"
                />
              }
              label="Developer"
            />
          </Grid>
        </FormGroup>
      </FormControl>
    </>
  );

  const renderAssociatedSubtenantsTab = () => {
    if (!tenant) {
      return <Typography>{t("Please select a tenant first")}</Typography>;
    } else if (subtenantsLoading) {
      return <Typography>{t("Loading subtenants")}...</Typography>;
    } else if (subtenantsError) {
      return (
        <Typography>
          {t("Error loading x", { x: t("Subtenants") })}.{" "}
          {t("Please reload the page")}
        </Typography>
      );
    } else if (!subtenants.length) {
      return (
        <Typography>{t("This tenant does not have any subtenants")}</Typography>
      );
    }

    return (
      <AttachmentBoard
        allItems={subtenants}
        attachedItemIds={associatedSubtenantIds}
        idKey="id"
        displayNameKey="label"
        unattachedTitle={`${t("Select the Subtenants to associate")}:`}
        attachedTitle={`${t("Associated Subtenants")}:`}
        searchPlaceholder={t("Search for Subtenants")}
        handleAttachAll={() =>
          setAssociatedSubtenantIds(subtenants.map(({ id }) => id))
        }
        handleAttach={(subtenantIdsToAttach) =>
          setAssociatedSubtenantIds(
            associatedSubtenantIds.concat(subtenantIdsToAttach),
          )
        }
        handleDetach={(subtenantIdsToDetach) =>
          setAssociatedSubtenantIds(
            difference(associatedSubtenantIds, subtenantIdsToDetach),
          )
        }
        handleDetachAll={() => setAssociatedSubtenantIds([])}
      />
    );
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={() => returnToPreviousPage(history, url)}
      execLabel={t("Save")}
      title={`${t("Create")} ${t("Manager")}`}
      disabled={isSubmitting}
      tabs={
        <CreateTabs
          associatedSubtenantsTabPath={ASSOCIATED_SUBTENANTS_TAB_PATH}
          shouldShowAssociatedSubtenantsTab={roleId === userRoles.MANAGER}
        />
      }
    >
      <Grid container direction="column">
        <Switch>
          <Route
            path={ASSOCIATED_SUBTENANTS_TAB_PATH}
            render={renderAssociatedSubtenantsTab}
          />
          <Route render={renderInformationTab} />
        </Switch>
        {error && (
          <Typography
            color="error"
            className={classes.error}
            id="MANAGER_CREATE_ERROR"
          >
            {error}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default Create;
