import React from "react";

/**
 * MATERIAL UI
 */
import { withStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  paragraph: {
    whiteSpace: "pre-wrap",
  },
  summary: {
    marginTop: 10,
    fontFamily: "Rubik, sans-serif",
  },
  heading: {
    fontWeight: 500,
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      errorInfo: info,
    });
  }

  render() {
    const { classes } = this.props;
    const { hasError, errorInfo } = this.state;

    if (hasError) {
      return (
        <>
          <Typography variant="h3" className={classes.heading} noWrap>
            The MSA application has encountered an error.
          </Typography>
          {errorInfo && (
            <details className={classes.paragraph}>
              <summary className={classes.summary}>Error information:</summary>
              <Typography component="p">{errorInfo.componentStack}</Typography>
            </details>
          )}
        </>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
