import React, { useState } from "react";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { TABLE_HEADER_COLUMNS } from "msa2-ui/src/routes/ai/states/AiStates";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TableRow from "msa2-ui/src/components/TableRow";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import AiStatesActionsAttachDialog from "msa2-ui/src/routes/ai/states/dialog/AiStatesActionsAttachDialog";
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { deleteState } from "msa2-ui/src/api/ai";
import { useSnackbar } from "notistack";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

const AiStatesTableBody = ({ loading, states, reload, error }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [showAttachDialog, setShowAttachDialog] = useState(false);
  const [attachDialogState, setAttachDialogState] = useState({
    pk: "",
    transitions: [],
  });

  const [stateToDelete, setStateToDelete] = useState(null);
  const [showDeleteDialog, DeleteDialog] = useDialog();

  if (loading) {
    return <TableLoading numberOfTableColumns={TABLE_HEADER_COLUMNS.length} />;
  }

  if (!states || error || states.length === 0) {
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching states list")}. ${t(
                "Please reload the page",
              )}.`
            : t("No states found")
        }
        numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
      />
    );
  }

  const handleDelete = async () => {
    showDeleteDialog();

    const [error] = await deleteState(stateToDelete.pk);

    if (error) {
      return enqueueSnackbar(
        error.getMessage(t("Unable to delete x", { x: stateToDelete.name })),
        { variant: "error" },
      );
    }

    enqueueSnackbar(t("x has been deleted", { x: stateToDelete.name }), {
      variant: "success",
    });

    reload();
  };

  const emptyValue = "-";

  return (
    <TableBody>
      <DeleteDialog
        title={t("Delete x", { x: t("State") })}
        content={t("Are you sure you want to delete x?", {
          x: stateToDelete?.name,
        })}
        onExec={handleDelete}
      />
      {showAttachDialog && (
        <AiStatesActionsAttachDialog
          stateId={attachDialogState.pk}
          stateTransitions={attachDialogState.transitions}
          onClose={(shouldReload) => {
            setAttachDialogState({
              pk: "",
              transitions: [],
            });
            setShowAttachDialog(false);
            shouldReload && reload();
          }}
        />
      )}
      {states.map((state, index) => (
        <TableRow
          key={index}
          actions={
            <>
              <DelegationProfiles
                type={delegationProfileTypes.AI}
                action="state.add"
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setAttachDialogState(state);
                    setShowAttachDialog(true);
                  }}
                >
                  {t("Add to...")}
                </Button>
              </DelegationProfiles>
              <DelegationProfiles
                type={delegationProfileTypes.AI}
                action="state.delete"
              >
                <Tooltip
                  title={
                    state.transitions.length
                      ? t("You must detach all actions before deleting")
                      : ""
                  }
                >
                  <span>
                    <IconButton
                      id="AI_STATE_BTN_DELETE"
                      disabled={Boolean(state.transitions.length)}
                      onClick={() => {
                        setStateToDelete(state);
                        showDeleteDialog();
                      }}
                    >
                      <DeleteIcon
                        color={state.transitions.length ? "disabled" : "error"}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </DelegationProfiles>
            </>
          }
        >
          {TABLE_HEADER_COLUMNS.map((column) => {
            const { id, primary, hidden } = column;
            return hidden ? null : (
              <TableCell
                key={id}
                className={commonClasses.commonTableCellDefault}
              >
                <Typography
                  variant="h4"
                  className={
                    primary
                      ? commonClasses.commonTablePrimary
                      : commonClasses.commonTableSecondary
                  }
                >
                  {state[id] || emptyValue}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default AiStatesTableBody;
