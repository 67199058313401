import { wait } from "msa2-ui/src/utils/wait";

/**
 *
 * @param {Function} apiCall - async function to call with retries. NB! should return Promise
 * @param {Number} interval - period of time to wait until the next call
 * @param {Number} maxNumberOfTries - total number of calls to make (first call is included)
 * @param {Function} checkForRetry - this predicate is gonna be called at the end of the successful try. Accepts result of the response.
 * Return true if you want to initiate another try.
 *
 * @returns {Function} - returns wrapped api call that accepts the same arguments as original apiCall func
 */
export const withRetries = ({
  apiCall,
  interval = 1000,
  maxNumberOfTries = 1,
  checkForRetry = () => false,
}) => {
  return async (...args) => {
    let counter = 0;
    let result = null;
    let shouldRetry = counter < maxNumberOfTries;

    while (shouldRetry) {
      counter++;

      if (counter > 1 && Boolean(wait)) await wait(interval);

      try {
        result = await apiCall(...args);
      } catch (err) {
        // if error was thrown proceed to next try unless we didn't exceed maxNumberOfTries
        if (counter < maxNumberOfTries) {
          continue;
        }

        throw err;
      }

      shouldRetry = checkForRetry(result);

      if (shouldRetry && counter >= maxNumberOfTries) {
        throw new Error("No retries left");
      }
    }

    return result;
  };
};
