import { useReducer, useEffect } from "react";
import produce from "immer";
import Alarm from "msa2-ui/src/services/Alarm";

const reducer = (draft, action) => {
  switch (action.type) {
    case "updateName": {
      draft.name = action.payload;
      return;
    }
    case "updateDescription": {
      draft.description = action.payload;
      return;
    }
    case "updateQueryTerms": {
      draft.query.terms = action.payload;
      return;
    }
    case "updateQuerySubtenantIds": {
      draft.query.subtenantIds = action.payload;
      return;
    }
    case "updateQueryManagedEntityIds": {
      draft.query.managedEntityIds = action.payload;
      return;
    }
    case "updateQuerySeverities": {
      draft.query.severities = action.payload;
      return;
    }
    case "updateRuleCount": {
      draft.rule.count = action.payload;
      return;
    }
    case "updateRuleDuration": {
      draft.rule.duration = action.payload;
      return;
    }
    case "updateRuleRoles": {
      draft.rule.roles = action.payload;
      draft.rule.email = action.payload.length > 0;
      return;
    }
    case "updateRuleWorkflowPath": {
      draft.rule.workflowPath = action.payload;
      draft.rule.processName = "";
      draft.rule.processVariables = {};
      return;
    }
    case "updateRuleProcessName": {
      const { processName, processVariables = {} } = action.payload;
      draft.rule.processName = processName;
      draft.rule.processVariables = processVariables;
      return;
    }
    case "updateRuleProcessVariable": {
      draft.rule.processVariables = action.payload;
      return;
    }
    case "updateRuleCleanUpProcessInstanceAfterExecution": {
      draft.rule.cleanUpProcessInstanceAfterExecution = action.payload;
      return;
    }
    case "updateRuleAlarmSeverity": {
      draft.rule.alarmSeverity = action.payload;
      return;
    }
    case "updateRuleSnmpTrap": {
      draft.rule.snmpTrap = action.payload;
      return;
    }
    case "updateFormState": {
      return action.payload;
    }
    case "updateQueryClearAlarmRegex": {
      draft.alarmClearance.alarm_regex = action.payload;
      return;
    }
    case "updateQueryClearEventRegex": {
      draft.alarmClearance.event_regex = action.payload;
      return;
    }
    case "updateQueryClearDelay": {
      draft.alarmClearance.clearing_delay = action.payload;
      return;
    }
    case "updateQueryClearComment": {
      draft.alarmClearance.clear_comment = action.payload;
      return;
    }
    default:
      return;
  }
};

const useUpsertAlarmForm = (alarmData) => {
  const curriedReducer = produce(reducer);
  const [formState, dispatch] = useReducer(curriedReducer);

  useEffect(() => {
    const updatedFormState = Alarm.makeAlarmDataObject(alarmData);
    dispatch({
      type: "updateFormState",
      payload: updatedFormState,
    });
  }, [alarmData]);

  return [formState, dispatch];
};

export default useUpsertAlarmForm;
