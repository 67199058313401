import React, { memo, useCallback, useState, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Process from "msa2-ui/src/services/Process";

import { Button, makeStyles, useTheme } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Graph from "./graph/Graph";
import Filters from "./filters/Filters";
import { CircularProgress, Grid, MenuItem } from "@material-ui/core";
import {
  getIconComponent,
  NETWORK_COLOR,
  statusToColorMap,
} from "msa2-ui/src/components/topology/icons";
import { nodeTypes } from "./constants";
import ContextMenu from "./context-menu/ContextMenu";
import { clearManagedEntity } from "msa2-ui/src/store/designations";
import { setTopologySectionValue } from "msa2-ui/src/store/ui";
import ActionButtons from "./action-buttons/ActionButtons";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import useApi from "msa2-ui/src/hooks/useApi";
import { getWorkflow } from "msa2-ui/src/api/workflow";
import AutomationDetailDrawer from "msa2-ui/src/routes/automation/workflows/detail/AutomationDetailDrawer";
import BasicSelect from "msa2-ui/src/components/BasicSelect";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    overflow: "hidden",
  },
  loading: { marginRight: 15 },
  field: {
    textAlign: "left",
    width: 220,
    marginRight: "10px",
  },
}));

const ICON_SIZE = 25;
const pathToWorkflowDefinitionFile = "Process/Topology/Topology";

const initialDrawerState = {
  id: "",
  name: "",
  open: false,
  process: {},
  tab: 0,
};

const TopologyView = ({
  isPending,
  graphRef,
  t,
  categories,
  data,
  categoriesFilterArray,
  setCategoriesFilterArray,
  deviceTypesFilterArray,
  setDeviceTypesFilterArray,
  onAddCategory,
  onSaveNodes,
  onUpdate,
  onZoom,
  workflowInstances = [],
  serviceId,
  onTopologyChange,
  reloadWorkflowInstances,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const commonClasses = useCommonStyles();

  const { darkMode, palette } = useTheme();
  const [isToggle, setIsToggle] = useState(false);

  const onToggleChange = (val) => {
    setIsToggle(val);
  };

  const iconRenderer = useCallback(
    (node) => {
      const { type, status, color } = node.__metadata__;
      const IconComponent = getIconComponent({ type, darkMode });
      const fillColor = (() => {
        if (type === nodeTypes.NETWORK) {
          return color || NETWORK_COLOR;
        }
        return statusToColorMap[status] || statusToColorMap.UNKNOWN_STATUS;
      })();

      return IconComponent
        ? renderToStaticMarkup(
            <IconComponent
              fill={fillColor}
              width={ICON_SIZE}
              height={ICON_SIZE}
            />,
          )
        : "";
    },
    [darkMode],
  );

  const [drawer, setDrawer] = useState(initialDrawerState);
  const [selectedInstance, setSelectedInstance] = useState("");
  const [contextMenuData, setContextMenuData] = useState(null);

  useEffect(() => {
    if (serviceId) {
      setSelectedInstance(serviceId);
    }
  }, [serviceId]);

  const contextClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (contextMenuData) {
      return;
    }

    const node = graphRef.current.getClosestParentNodeData(e.target);

    if (node) {
      dispatch(
        setTopologySectionValue({ key: "showContextMenu", value: true }),
      );
      setContextMenuData({
        position: { top: e.clientY, left: e.clientX },
        node,
      });
    }
  };

  const [workflowDefinitionLoading, , workflowDefinition] = useApi(
    getWorkflow,
    {
      pathToWorkflowDefinitionFile,
    },
  );

  const onDrawerClose = () => {
    reloadWorkflowInstances();
    if (selectedInstance) {
      onTopologyChange(selectedInstance);
    }
    setDrawer(initialDrawerState);
  };

  const createProcess =
    workflowDefinition?.process?.find((processObject) =>
      Process.isCreate(processObject.type),
    ) ?? {};

  const handleRunProcess = (objProcess) => {
    const process = workflowDefinition.process.find(
      (prc) => prc.name === objProcess.name,
    );

    setDrawer({
      ...drawer,
      open: true,
      process,
      tab: 0,
    });
  };

  return (
    <>
      {drawer.open && (
        <AutomationDetailDrawer
          onClose={onDrawerClose}
          tab={drawer.tab}
          instanceId={drawer.id}
          instanceName={drawer.name}
          execProcess={drawer.process}
          workflow={workflowDefinition}
        />
      )}
      <div
        className={classes.container}
        onContextMenu={contextClickHandler}
        data-testid="topology-view"
      >
        <ContextMenu
          {...contextMenuData}
          onClose={() => {
            setContextMenuData(null);
            dispatch(
              setTopologySectionValue({ key: "showContextMenu", value: false }),
            );
            dispatch(clearManagedEntity());
          }}
          onAddCategory={onAddCategory}
          onChange={onUpdate}
        />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" justifyContent="center">
              <BasicSelect
                id="TOPOLOGY_INSTANCE_SELECTION"
                data-testid="topology-selection"
                value={selectedInstance}
                className={classes.field}
                variant="outlined"
                displayEmpty
                onChange={(e) => {
                  setSelectedInstance(e.target.value);
                  onTopologyChange(e.target.value);
                }}
              >
                <MenuItem value="" disabled>
                  {t("Select topology")}
                </MenuItem>
                {workflowInstances.map((instance, i) => (
                  <MenuItem
                    id={`TOPOLOGY_FILTER_INSTANCE_${i}`}
                    key={i}
                    value={instance.serviceId}
                  >
                    {instance.title}
                  </MenuItem>
                ))}
              </BasicSelect>
              <Filters
                t={t}
                categories={categories}
                categoriesFilterArray={categoriesFilterArray}
                disabled={isPending}
                setCategoriesFilterArray={setCategoriesFilterArray}
                deviceTypesFilterArray={deviceTypesFilterArray}
                setDeviceTypesFilterArray={setDeviceTypesFilterArray}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {isPending ? (
                <CircularProgress size={30} className={classes.loading} />
              ) : (
                <Grid item>
                  <ActionButtons
                    onSaveNodes={onSaveNodes}
                    onUpdate={onUpdate}
                    onZoomIn={() => onZoom(Graph.ZOOM_IN)}
                    onZoomOut={() => onZoom(Graph.ZOOM_OUT)}
                    isToggle={isToggle}
                    onToggleChange={onToggleChange}
                  />
                </Grid>
              )}
              {!workflowDefinitionLoading && (
                <Grid item>
                  <Button
                    id="TOPOLOGY_BTN_CREATE"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleRunProcess(createProcess)}
                    data-testid="topology-create-button"
                  >
                    <IconPlus className={commonClasses.commonBtnIcon} />
                    {createProcess.displayName}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Graph
          ref={graphRef}
          width={1000}
          height={600}
          data={data}
          iconSize={ICON_SIZE}
          iconRenderer={iconRenderer}
          labelColor={palette.text.primary}
          showLinkLabel={isToggle}
        />
      </div>
    </>
  );
};

TopologyView.propTypes = {
  isPending: PropTypes.bool.isRequired,
  graphRef: PropTypes.shape({ current: PropTypes.instanceOf(Graph) }),
  t: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
      }),
    ).isRequired,
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        icon: PropTypes.string,
        fx: PropTypes.number,
        fy: PropTypes.number,
      }),
    ).isRequired,
  }).isRequired,
  categoriesFilterArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCategoriesFilterArray: PropTypes.func.isRequired,
  deviceTypesFilterArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDeviceTypesFilterArray: PropTypes.func.isRequired,
  onAddCategory: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
  onSaveNodes: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  workflowInstances: PropTypes.array.isRequired,
  serviceId: PropTypes.any,
  onTopologyChange: PropTypes.func.isRequired,
  reloadWorkflowInstances: PropTypes.func.isRequired,
};

export default memo(TopologyView);
