import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { useDispatch, useSelector } from "react-redux";

import {
  getDashboardSetting,
  getSettings,
  getDashboards,
  addDashboardSetting,
  changeDashboardSetting,
  deleteDashboardSetting,
  setDashboardSetting,
  resetDashboardSetting,
  changeDashboardLayout,
} from "msa2-ui/src/store/settings";

import {
  getGlobalSettings,
  setGlobalSettings,
  getSavedSettings,
  setSavedSettings,
} from "msa2-ui/src/store/designations";
import {
  getToken,
  getUserDetails,
  getRoleDetails,
  userRoles,
  getUserRole,
  getIsRootUser,
} from "msa2-ui/src/store/auth";
import Dashboard from "msa2-ui/src/services/Dashboard";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useSnackbar } from "notistack";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

import { makeStyles, useTheme } from "@material-ui/core";
import {
  Button,
  Divider,
  Drawer,
  TextField,
  Grid,
  IconButton,
  MenuItem,
  Slider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  DeleteOutlined as DeleteIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { ReactComponent as PlusIcon } from "msa2-ui/src/assets/icons/plus.svg";

import BasicSelect from "msa2-ui/src/components/BasicSelect";
import COMPONENTS from "./DashboardComponents";
import isEmpty from "lodash/isEmpty";
import { updateMsaVars } from "msa2-ui/src/api/msaVars";
import { MSA_VARS } from "msa2-ui/src/Constants";
import { updateManagerWithUISettings } from "msa2-ui/src/api/manager";

const { DEFAULT_GRID } = Dashboard;
const isBPMEnabled = FeatureFlag.isEnabled(FeatureFlag.features.enableBPM);

const useStyles = makeStyles(() => ({
  verticalDivider: {
    height: "auto",
    marginRight: 10,
    marginLeft: 10,
  },
  settingHeader: {
    padding: 15,
  },
  settingWrapper: {
    padding: 15,
    maxHeight: "70vh",
    overflowY: "auto",
  },
  title: {
    margin: 10,
  },
  itemWrapper: {
    padding: 10,
    maxWidth: "100%",
  },
  select: {
    width: "100%",
  },
  actionButton: {
    margin: 5,
  },
  iconButton: {
    marginLeft: 5,
  },
}));

export const getComponentStatus = ({
  componentName,
  dashboardComponents,
  delegationProfiles,
  userRole,
}) => {
  const componentDefinition = COMPONENTS[componentName];

  const dependingComponents = dashboardComponents.reduce(
    (acc, { component: componentName }, index) =>
      // It should be deleted if component is the target component itself,
      // or the component is depending the target component
      COMPONENTS[componentName]?.depends?.includes(componentName)
        ? acc.concat(componentName)
        : acc,
    [],
  );
  const numberOfSameComponents = dashboardComponents.filter(
    ({ component }) => component === componentName,
  ).length;
  const canDelete =
    dependingComponents.length === 0 || numberOfSameComponents > 1;

  const isHiddenComponent = componentDefinition?.getHidden?.(
    delegationProfiles,
    userRole,
  );

  return {
    componentDefinition,
    dependingComponents,
    canDelete,
    isHiddenComponent,
  };
};

export const DeleteComponentIcon = ({
  index,
  canDelete,
  dependingComponents,
  dashboardIndex,
}) => {
  const t = useBoundedTranslation();
  const dispatch = useDispatch();
  return (
    <Tooltip
      title={
        canDelete
          ? "Remove Component"
          : `${t(
              "Remove depending components to delete.",
            )} [${dependingComponents.join(", ")}]`
      }
    >
      <span>
        <IconButton
          id={`DASHBOARD_SETTINGS_REMOVE_${index}`}
          onClick={() => {
            dispatch(
              deleteDashboardSetting({
                dashboardIndex: dashboardIndex,
                componentIndex: index,
              }),
            );
          }}
          disabled={!canDelete}
        >
          <DeleteIcon color={canDelete ? "error" : "disabled"} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const ExtendedList = ({ extendedList, index, dashboardIndex }) => {
  const classes = useStyles();
  const t = useBoundedTranslation();

  const { id, displayName, apis, options, validation } = extendedList;
  const state = useSelector((state) => state);
  const [apiResponses, setApiResponses] = useState({});
  const dispatch = useDispatch();
  const { contents } = useSelector(getDashboardSetting(dashboardIndex));
  const { extendedListValues } = contents[index];

  const token = useSelector(getToken);
  const setExtendedListValues = (value) => {
    dispatch(
      changeDashboardSetting({
        dashboardIndex: dashboardIndex,
        componentIndex: index,
        key: "extendedListValues",
        value,
      }),
    );
  };

  const selectOptions = options({
    state,
    extendedListValues,
    apiResponses,
    featureFlags: FeatureFlag.flags,
  });
  const loaded = apis
    .map((api) => api.id)
    .every((apiKey) => Object.keys(apiResponses).includes(apiKey));
  useEffect(() => {
    if (!loaded) {
      apis.forEach(async ({ id, callee, parameters, wait }) => {
        const [, response] = await callee({
          token,
          ...parameters({
            state,
            extendedListValues,
            apiResponses,
            featureFlags: FeatureFlag.flags,
          }),
        });
        setApiResponses({
          ...apiResponses,
          [id]: response,
        });
      });
    }
  }, [apiResponses, apis, extendedListValues, loaded, state, token]);

  return (
    <Grid item className={classes.itemWrapper} key={id}>
      <BasicSelect
        id={`DASHBOARD_SETTINGS_EXTENDED_LIST_${id}`}
        aria-label={displayName}
        label={displayName}
        name={displayName}
        value={extendedListValues[id] || ""}
        defaultValue={" "}
        onChange={({ target: { value } }) =>
          setExtendedListValues({ ...extendedListValues, [id]: value })
        }
        className={classes.select}
        helperText={
          validation && validation(state, extendedListValues, apiResponses)
        }
        disabled={!loaded || selectOptions.length === 0}
        displayEmpty
        renderValue={(value) =>
          loaded ? (
            selectOptions.find((option) => option.value === value)?.label ??
            value
          ) : (
            <em>{t("Loading...")}</em>
          )
        }
      >
        {selectOptions.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </BasicSelect>
    </Grid>
  );
};

export const MSAComponent = ({ index, component, dashboardIndex }) => {
  const classes = useStyles();
  const t = useBoundedTranslation();
  const dispatch = useDispatch();
  const { contents: dashboardComponents } = useSelector(
    getDashboardSetting(dashboardIndex),
  );
  const delegationProfiles = useSelector(
    ({ delegationProfiles }) => delegationProfiles,
  );
  const userRole = useSelector(getUserRole);
  const {
    componentDefinition,
    dependingComponents,
    canDelete,
    isHiddenComponent,
  } = getComponentStatus({
    componentName: component,
    dashboardComponents,
    delegationProfiles,
    userRole,
  });

  const onChangeSetting = (key, value) => {
    dispatch(
      changeDashboardSetting({
        dashboardIndex: dashboardIndex,
        componentIndex: index,
        key,
        value,
      }),
    );
  };

  return (
    <>
      <Grid item className={classes.itemWrapper}>
        <Tooltip
          title={
            canDelete
              ? ""
              : `${t(
                  "Remove depending components to change.",
                )} [${dependingComponents.join(", ")}]`
          }
        >
          <span>
            <BasicSelect
              id={`DASHBOARD_SETTINGS_COMPONENT_${index}`}
              aria-label={t("Component")}
              label={t("Component")}
              name={t("Component")}
              value={component}
              onChange={({ target: { value } }) => {
                onChangeSetting("component", value);
                onChangeSetting("extendedListValues", {});
              }}
              className={classes.select}
              disabled={!canDelete || isHiddenComponent}
            >
              {Object.entries(COMPONENTS)
                // If dependency components are not selected, filter it out
                .filter(([_, { depends }]) =>
                  depends
                    ? dashboardComponents.some(
                        ({ component }, i) =>
                          index !== i && depends.includes(component),
                      )
                    : true,
                )
                // Hide components which is for particular customers.
                .filter(([_, { getHidden }]) =>
                  isHiddenComponent
                    ? true
                    : !getHidden?.(delegationProfiles, userRole),
                )
                // Filter based on Feature Flag
                .filter(([key]) => key !== "Recent BPM" || isBPMEnabled)
                .map(([name, { description }], i) => (
                  <MenuItem value={name} key={i}>
                    {name}
                  </MenuItem>
                ))}
            </BasicSelect>
          </span>
        </Tooltip>
      </Grid>

      {componentDefinition?.extendedLists?.map((extendedList) => (
        <ExtendedList
          extendedList={extendedList}
          index={index}
          key={index}
          dashboardIndex={dashboardIndex}
        />
      ))}
    </>
  );
};

const DashboardSetting = ({
  dashboardComponent: { key, style, type, component, title, url },
  index,
  layout,
  breakpoint = "lg",
  gridSize = DEFAULT_GRID,
  dashboardIndex,
}) => {
  const classes = useStyles();
  const t = useBoundedTranslation();
  const dispatch = useDispatch();
  const { contents: dashboardComponents } = useSelector(
    getDashboardSetting(dashboardIndex),
  );

  const delegationProfiles = useSelector(
    ({ delegationProfiles }) => delegationProfiles,
  );
  const userRole = useSelector(getUserRole);

  const { dependingComponents, canDelete } = getComponentStatus({
    componentName: component,
    dashboardComponents,
    delegationProfiles,
    userRole,
  });

  const onChangeSetting = (index, key, value) => {
    dispatch(
      changeDashboardSetting({
        dashboardIndex: dashboardIndex,
        componentIndex: index,
        key,
        value,
      }),
    );
  };

  return (
    <>
      <Grid item xs={3} container direction={"column"} key={index}>
        <Grid item className={classes.itemWrapper}>
          <BasicSelect
            label={t("Style")}
            name={t("Style")}
            value={style}
            className={classes.select}
            onChange={({ target: { value } }) => {
              onChangeSetting(index, "style", value);
            }}
          >
            {Dashboard.styles.map((style, i) => {
              return (
                <MenuItem value={style} key={i}>
                  {style}
                </MenuItem>
              );
            })}
          </BasicSelect>
        </Grid>
        {style === "Dashboard Panel" && (
          <>
            <Grid item className={classes.itemWrapper}>
              <Typography variant="body2">{t("Width")}</Typography>
              <Slider
                value={layout.w || 1}
                valueLabelFormat={(value) =>
                  `${Math.round((100 / gridSize) * value)}%`
                }
                valueLabelDisplay="auto"
                marks={[
                  { value: Math.round(gridSize / 2), label: "50%" },
                  { value: gridSize, label: "100%" },
                ]}
                step={1}
                min={1}
                max={gridSize}
                onChange={(_, value) => {
                  dispatch(
                    changeDashboardLayout({
                      dashboardIndex: dashboardIndex,
                      i: key,
                      breakpoint,
                      key: "w",
                      value,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item className={classes.itemWrapper}>
              <Typography variant="body2">{t("Height")}</Typography>
              <Slider
                value={layout.h || 1}
                valueLabelFormat={(value) =>
                  `${Math.round((100 / DEFAULT_GRID) * value)}%`
                }
                valueLabelDisplay="auto"
                marks={[
                  { value: DEFAULT_GRID / 2, label: "50%" },
                  { value: DEFAULT_GRID, label: "100%" },
                ]}
                step={1}
                min={1}
                max={DEFAULT_GRID}
                onChange={(_, value) => {
                  dispatch(
                    changeDashboardLayout({
                      dashboardIndex: dashboardIndex,
                      i: key,
                      breakpoint,
                      key: "h",
                      value,
                    }),
                  );
                }}
              />
            </Grid>
          </>
        )}

        <Grid item className={classes.itemWrapper}>
          <TextField
            id={`DASHBOARD_SETTINGS_TITLE_${index}`}
            aria-label={t("Title")}
            label={t("Title")}
            variant="outlined"
            name={t("Title")}
            value={title}
            onChange={({ target: { value } }) => {
              onChangeSetting(index, "title", value);
            }}
            fullWidth
          />
        </Grid>

        <Grid item className={classes.itemWrapper}>
          <BasicSelect
            label={t("Type")}
            name={t("Type")}
            value={type}
            className={classes.select}
            onChange={({ target: { value } }) => {
              onChangeSetting(index, "type", value);
            }}
            disabled
          >
            {Dashboard.types.map((type, i) => (
              <MenuItem value={type} key={i}>
                {type}
              </MenuItem>
            ))}
          </BasicSelect>
        </Grid>

        {type === "External Link" && (
          <Grid item className={classes.itemWrapper}>
            <TextField
              id={`DASHBOARD_SETTINGS_URL_${index}`}
              aria-label={t("URL")}
              required
              variant="outlined"
              label="URL"
              onChange={({ target: { value } }) => {
                onChangeSetting(index, "url", value);
              }}
              value={url}
              fullWidth
            />
          </Grid>
        )}
        {type === "MSA Component" && (
          <MSAComponent
            index={index}
            component={component}
            dashboardIndex={dashboardIndex}
          />
        )}
        <Grid
          item
          className={classes.itemWrapper}
          container
          alignContent="center"
        >
          <DeleteComponentIcon
            dashboardIndex={dashboardIndex}
            index={index}
            canDelete={canDelete}
            dependingComponents={dependingComponents}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const SaveAsGlobalSettingsButton = () => {
  const classes = useStyles();
  const t = useBoundedTranslation();
  const token = useSelector(getToken);
  const isRootUser = useSelector(getIsRootUser);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dashboards = useSelector(getDashboards);
  const globalDashboardSettings = useSelector(getGlobalSettings);
  const [showSaveDialog, SaveDialog] = useDialog();
  const roleDetails = useSelector(getRoleDetails);

  if (roleDetails?.id !== userRoles.PRIVILEGED_ADMINISTRATOR) {
    // only ncroot can save global settings
    return null;
  }

  const handleSave = async () => {
    const content = { ...globalDashboardSettings, dashboards: dashboards };
    const contentAsString = JSON.stringify(content);
    const [error] = await updateMsaVars({
      isRootUser,
      token,
      vars: [
        { name: MSA_VARS.UI_SETTINGS, value: contentAsString, comment: "" },
      ],
    });
    const message = error
      ? error.getMessage(t("Unable to save settings"))
      : t("Settings saved successfully");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      dispatch(setGlobalSettings(content));
    }
  };
  return (
    <>
      <SaveDialog
        title={t("Confirm Request")}
        content={`${t("Are you sure you want to save as global settings?")}`}
        onExec={handleSave}
      />
      <Button
        id="DASHBOARD_SETTINGS_GLOBAL_SAVE"
        aria-label={t("Set as Global Settings")}
        color="primary"
        variant="contained"
        onClick={() => {
          showSaveDialog();
        }}
        className={classes.actionButton}
      >
        {t("Set as Global Settings")}
      </Button>
    </>
  );
};

export const SaveButton = ({ onSuccess }) => {
  const classes = useStyles();
  const t = useBoundedTranslation();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const { id, userType } = useSelector(getUserDetails);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dashboards = useSelector(getDashboards);
  const settings = useSelector(getSettings);
  const [showSaveDialog, SaveDialog] = useDialog();

  const handleSave = async () => {
    const content = { ...settings, dashboards: dashboards };
    const [error] = await updateManagerWithUISettings({
      token,
      id: id,
      userType,
      UISettings: content,
    });
    const message = error
      ? error.getMessage(t("Unable to save settings"))
      : t("Settings saved successfully");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    if (!error) {
      onSuccess && onSuccess();
      dispatch(setSavedSettings(content));
    }
  };

  return (
    <>
      <SaveDialog
        title={t("Confirm Request")}
        content={t("Are you sure you want to save this settings?")}
        onExec={handleSave}
      />
      <Button
        id="DASHBOARD_SETTINGS_SAVE"
        aria-label={t("Save")}
        color="primary"
        variant="contained"
        onClick={() => {
          showSaveDialog();
        }}
        className={classes.actionButton}
      >
        {t("Save")}
      </Button>
    </>
  );
};

const DashboardSettings = ({
  onClose,
  breakpoint = "lg",
  dashboardIndex = 0,
}) => {
  const classes = useStyles();
  const t = useBoundedTranslation();
  const { breakpoints } = useTheme();
  const dashboard = useSelector(getDashboardSetting(dashboardIndex));
  const { contents: dashboardComponents, layouts } = dashboard;
  const globalDashboardSettings = useSelector(getGlobalSettings);
  const savedSettings = useSelector(getSavedSettings);
  const [opacity, setOpacity] = useState(100);
  const [initDashboard, setInitDashboard] = useState(dashboard);

  const dispatch = useDispatch();

  const gridSize = Dashboard.getGridSize(breakpoint, breakpoints?.values);

  //savedSettings value is empty [] when we don't have saved settings, other wise value is object,
  const isResetDisable =
    (savedSettings !== undefined && savedSettings?.length !== undefined
      ? !savedSettings.length
      : !savedSettings?.dashboards?.length) &&
    dashboardComponents === initDashboard?.contents;

  const handleReset = () => {
    if (dashboard === initDashboard) {
      dispatch(
        setDashboardSetting({
          dashboardIndex: dashboardIndex,
          value: savedSettings.dashboards?.[0],
        }),
      );
    } else {
      dispatch(
        setDashboardSetting({
          dashboardIndex: dashboardIndex,
          value: initDashboard,
        }),
      );
    }
  };
  const handleOnClose = () => {
    onClose();
  };

  return (
    <Drawer
      anchor="top"
      open
      onClose={handleOnClose}
      style={{ opacity: `${opacity}%` }}
    >
      <Grid container className={classes.settingHeader}>
        <Grid item xs={2}>
          <Typography variant="subtitle1" className={classes.title}>
            {t("Dashboard Settings")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">{t("Opacity")}</Typography>
          <Slider
            value={opacity}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
            marks={[
              { value: 50, label: "50%" },
              { value: 100, label: "100%" },
            ]}
            step={5}
            min={50}
            max={100}
            onChange={(_, value) => setOpacity(value)}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.settingWrapper}>
        {dashboardComponents.map((dashboardComponent, index) => {
          const layout = layouts[breakpoint]?.find(
            ({ i }) => i === dashboardComponent.key,
          ) ?? { i: dashboardComponent.key, x: 0, y: 0, w: 1, h: 1 };
          return (
            <DashboardSetting
              key={index}
              index={index}
              dashboardComponent={dashboardComponent}
              layout={layout}
              breakpoint={breakpoint}
              gridSize={gridSize}
              dashboardIndex={dashboardIndex}
            />
          );
        })}
      </Grid>
      <Grid container justifyContent="flex-end" className={classes.itemWrapper}>
        <Button
          id="DASHBOARD_SETTINGS_ADD_MORE"
          aria-label={t("Add More")}
          onClick={() => {
            dispatch(
              addDashboardSetting({
                dashboardIndex: dashboardIndex,
                breakpoints: breakpoints.value,
              }),
            );
          }}
          className={classes.actionButton}
        >
          {t("Add More")}
          <PlusIcon className={classes.iconButton} />
        </Button>
        <Divider orientation="vertical" className={classes.verticalDivider} />
        <Tooltip
          title={
            isEmpty(globalDashboardSettings.dashboards)
              ? t("Save Global settings before reset")
              : ""
          }
        >
          <div>
            <Button
              id="DASHBOARD_SETTINGS_RESET_TO_INIT"
              aria-label={t("Reset to Global Settings")}
              color="primary"
              onClick={() => {
                dispatch(
                  resetDashboardSetting({
                    dashboardIndex: dashboardIndex,
                    value: globalDashboardSettings.dashboards?.[0],
                  }),
                );
              }}
              className={classes.actionButton}
              disabled={
                isEmpty(globalDashboardSettings.dashboards) ? true : false
              }
            >
              {t("Reset to Global Settings")}
            </Button>
          </div>
        </Tooltip>
        <SaveAsGlobalSettingsButton />
        <Tooltip title={isResetDisable ? t("Save settings before reset") : ""}>
          <div>
            <Button
              id="DASHBOARD_SETTINGS_RESET"
              aria-label={t("Reset")}
              color="primary"
              onClick={handleReset}
              className={classes.actionButton}
              disabled={isResetDisable}
            >
              {t("Reset")}
            </Button>
          </div>
        </Tooltip>
        <SaveButton
          onSuccess={() => {
            setInitDashboard(dashboard);
          }}
        />
        <Divider orientation="vertical" className={classes.verticalDivider} />
        <IconButton
          id="DASHBOARD_SETTINGS_CLOSE"
          aria-label={t("Close")}
          onClick={handleOnClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Drawer>
  );
};

DashboardSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  dashboardIndex: PropTypes.number.isRequired,
};

export default DashboardSettings;
