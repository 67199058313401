import React, { useState, useEffect } from "react";
import {
  Redirect,
  useLocation,
  useHistory,
  Route,
  Switch,
} from "react-router-dom";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import useTogglePassword from "msa2-ui/src/hooks/useTogglePassword";

import { useDispatch, useSelector } from "react-redux";
import {
  getIsAuth,
  signIn,
  UserTypes,
  authError,
  getUserType,
  setUserType,
} from "msa2-ui/src/store/auth";

import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import loginStyles from "./styles";

import MsaLogoWhite from "msa2-ui/src/assets/img/logo-MSActivator-white.png";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import LanguageController from "msa2-ui/src/components/LanguageController";
import Dashboard from "msa2-ui/src/routes/dashboard";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import ForgotPasswordDialog from "./forgot-password-dialog";
import ChangePasswordDialog from "./change-password-dialog";

export const useStyles = makeStyles((theme) => loginStyles(theme));

export const routes = {
  login: "/login",
  forgotPassword: "/login/forgot-password",
  changePassword: "/login/change-password/:hash",
};

const Login = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { from = { pathname: "/" } } = useLocation();
  const history = useHistory();

  const isAuth = useSelector(getIsAuth);
  const dispatch = useDispatch();

  const { redirectToReferrer } = isAuth;

  const logInAsUserType = useSelector(getUserType);
  const isTrialMSA = FeatureFlag.isEnabled(FeatureFlag.features.trial);

  const logInAsOperator = logInAsUserType === UserTypes.operator;
  const logInAsDeveloper = logInAsUserType === UserTypes.developer;

  const [userName, setUserName] = useState();
  const [userNameError, setUserNameError] = useState(false);
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, PasswordInputAdornment] = useTogglePassword();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setUserNameError(userName === "");
    setPasswordError(password === "");
  }, [userName, password]);

  const handleTabChange = (event, tabIndex) => {
    dispatch(
      setUserType(tabIndex === 0 ? UserTypes.operator : UserTypes.developer),
    );
  };

  const handleSubmit = async () => {
    if (!userNameError && !passwordError) {
      const response = await signIn({
        username: userName,
        password,
        logInAsOperator,
        logInAsDeveloper,
      })(dispatch);
      if (response?.type === authError.type) {
        closeSnackbar();
        enqueueSnackbar(response.payload, {
          variant: "error",
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleForgotPasswordClick = () => history.push(routes.forgotPassword);

  const handleForgotPasswordDialogClose = () => {
    history.push(routes.login);
    setUserName("");
    setPassword("");
  };

  const handleChangePasswordDialogClose = () => history.push(routes.login);

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  if (isAuth) {
    return <Redirect to="/dashboard" component={Dashboard} />;
  }

  return (
    <ErrorBoundary>
      <Switch>
        <Route
          exact
          path={routes.forgotPassword}
          render={() => (
            <ForgotPasswordDialog
              userName={userName}
              onClose={handleForgotPasswordDialogClose}
            />
          )}
        />
        <Route
          exact
          path={routes.changePassword}
          render={() => (
            <ChangePasswordDialog onClose={handleChangePasswordDialogClose} />
          )}
        />
      </Switch>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          className={classes.backgroundContainer}
        >
          <LanguageController />
          <Grid
            container
            justifyContent="center"
            style={{ height: isTrialMSA ? "80vh" : "100vh" }}
          >
            <Grid item className={classes.alignSelf}>
              <Paper className={classes.selectWrap}>
                <Tabs
                  className={classes.tabs}
                  classes={{
                    indicator: classes.indicator,
                  }}
                  variant="fullWidth"
                  value={logInAsOperator ? 0 : 1}
                  onChange={handleTabChange}
                >
                  <Tab
                    id="LOGIN_TAB_SELECT_ADMIN"
                    label={
                      <span
                        className={
                          logInAsUserType === UserTypes.operator
                            ? classnames(classes.tabFonts, classes.fontSelected)
                            : classnames(classes.tabFonts)
                        }
                      >
                        {t("Manager")}
                      </span>
                    }
                    className={classes.leftTab}
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                  <Tab
                    id="LOGIN_TAB_SELECT_DEV"
                    label={
                      <span
                        className={
                          logInAsUserType === UserTypes.developer
                            ? classnames(classes.tabFonts, classes.fontSelected)
                            : classnames(classes.tabFonts)
                        }
                      >
                        {t("Developer")}
                      </span>
                    }
                    className={classes.rightTab}
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                </Tabs>
              </Paper>
              <Grid className={classes.loginWrap}>
                <FormControl fullWidth className={classes.margin}>
                  <InputLabel
                    shrink
                    htmlFor="LOGIN_INPUT_USERNAME"
                    className={classnames({
                      [classes.bootstrapFormErrorLabel]: userNameError,
                      [classes.bootstrapFormLabel]: !userNameError,
                    })}
                  >
                    {userNameError
                      ? `${t("Username is required")}`
                      : `${t("Username")}`}
                  </InputLabel>
                  <InputBase
                    type="text"
                    id="LOGIN_INPUT_USERNAME"
                    classes={{
                      root: classes.bootstrapRoot,
                      input: classes.bootstrapInput,
                    }}
                    value={userName || ""}
                    onChange={(event) => {
                      setUserName(event.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.margin}>
                  <InputLabel
                    shrink
                    htmlFor="INPUT_PASSWORD"
                    className={classnames({
                      [classes.bootstrapFormErrorLabel]: passwordError,
                      [classes.bootstrapFormLabel]: !passwordError,
                    })}
                  >
                    <span className={classes.passwordLabel}>
                      {passwordError
                        ? `${t("Password is required")}`
                        : `${t("Password")}`}
                    </span>
                  </InputLabel>
                  <InputBase
                    type={showPassword ? "text" : "password"}
                    id="INPUT_PASSWORD"
                    value={password || ""}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    classes={{
                      root: classes.bootstrapRoot,
                      input: classes.bootstrapInput,
                    }}
                    endAdornment={<PasswordInputAdornment />}
                  />
                  <span className={classes.forgotPassword}>
                    <Button
                      className={classes.forgotPasswordButton}
                      color="primary"
                      onClick={handleForgotPasswordClick}
                      id="FORGOT_PASSWORD_BTN_FORGOT_PASSWORD"
                      tabIndex="-1"
                    >
                      {t("Forgot Password?")}
                    </Button>
                  </span>
                </FormControl>
                <Button
                  id="LOGIN_BTN_SUBMIT"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  {t("Log In")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {isTrialMSA && (
            <Grid container justifyContent="center">
              <Grid item xs={7} container className={classes.footer}>
                <Grid
                  item
                  xs={12}
                  alignContent="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h4"
                    className={classnames(
                      commonClasses.commonFlexCenter,
                      classes.footerTitle,
                    )}
                  >
                    {t("Community & Support")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classnames(
                      commonClasses.commonDescription,
                      classes.footerDescription,
                    )}
                  >
                    {t(
                      "Don't get stuck, we are here to help and give you all the answers about MSActivator. No need for answer, but willing to contribute? Be our guest as well! Visit our platforms",
                    ) + ":"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignContent="center"
                  justifyContent="center"
                >
                  <Button
                    id="LOGIN_FOOTER_BUTTON_SLACK"
                    aria-label={t("Slack Team")}
                    variant="contained"
                    className={classes.footerButton}
                    classes={{ label: classes.footerButtonText }}
                    target="_blank"
                    href="https://join.slack.com/t/openmsa/shared_invite/zt-c4esz1b6-9HKD9eHc2I9ycB1uga5Dvg"
                  >
                    {t("Slack Team")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignContent="center"
                  justifyContent="center"
                >
                  <Button
                    id="LOGIN_FOOTER_BUTTON_FORUM"
                    aria-label={t("Forum")}
                    variant="contained"
                    className={classes.footerButton}
                    classes={{ label: classes.footerButtonText }}
                    target="_blank"
                    href="https://discuss.ubiqube.com/"
                  >
                    {t("Forum")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignContent="center"
                  justifyContent="center"
                >
                  <Button
                    id="LOGIN_FOOTER_BUTTON_GITHUB"
                    aria-label={t("GitHub")}
                    variant="contained"
                    className={classes.footerButton}
                    classes={{ label: classes.footerButtonText }}
                    target="_blank"
                    href="https://github.com/openmsa/"
                  >
                    {t("GitHub")}
                  </Button>
                </Grid>
              </Grid>
              <Divider
                orientation="vertical"
                className={classes.footerDivider}
              />
              <Grid item xs={5} container className={classes.footer}>
                <Grid
                  item
                  xs={12}
                  alignContent="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h4"
                    className={classnames(
                      commonClasses.commonFlexCenter,
                      classes.footerTitle,
                    )}
                  >
                    {t("Quickstart")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classnames(
                      commonClasses.commonDescription,
                      classes.footerDescription,
                    )}
                  >
                    {t(
                      "The quickstart covers how you can quickly get started using MSActivator by installing a mini lab.",
                    )}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  alignContent="center"
                  justifyContent="center"
                >
                  <Button
                    id="LOGIN_FOOTER_BUTTON_ACCESS"
                    aria-label={t("Access")}
                    variant="contained"
                    className={classes.footerButton}
                    classes={{ label: classes.footerButtonText }}
                    target="_blank"
                    href="https://ubiqube.com/wp-content/docs/latest/trial-guide/msa_quickstart_guide.html"
                  >
                    {t("Access")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className={classnames({
            [classes.imageBoxAdmin]: logInAsOperator,
            [classes.imageBoxDev]: logInAsDeveloper,
          })}
        >
          <Grid
            container
            className={classes.imageContainer}
            justifyContent="center"
            alignContent="flex-start"
          >
            <Grid item>
              <img
                src={MsaLogoWhite}
                className={classes.logo}
                alt="MSActivator"
                height="40"
              />
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.imageContainer}
            justifyContent="center"
            alignContent="flex-end"
          >
            <Grid item>
              <Paper className={classes.paperTransparent}>
                <Typography variant="h1" className={classes.whiteH1}>
                  {logInAsOperator
                    ? t("Infrastructure management")
                    : t("Integrated automation design")}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default Login;
