import { useTheme } from "@material-ui/core";

export const useSelectStyles = (isError, disabled) => {
  const { colors, palette, darkMode } = useTheme();

  return {
    control: (styles) => ({
      ...styles,
      backgroundColor: palette.background.paper,
      borderColor: isError
        ? colors.red
        : disabled
        ? "rgba(255, 255, 255, 0.3)"
        : colors.greyDark3,
      ...(isError ? { boxShadow: "none" } : {}),
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: disabled ? "rgba(255, 255, 255, 0.3)" : colors.greyDark3,
    }),
    group: (styles) => ({
      ...styles,
      backgroundColor: palette.background.paper,
      overflowWrap: "break-word",
      paddingBottom: 0,
    }),
    groupHeading: () => ({
      color: palette.text.secondary,
      textTransform: "initial",
      paddingLeft: "15px",
      textAlign: "left",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: disabled ? "rgba(255, 255, 255, 0.3)" : colors.greyDark3,
    }),
    input: (styles) => ({
      ...styles,
      fontSize: "1rem",
      color: palette.text.primary,
      margin: 0,
    }),
    loadingMessage: (styles) => ({
      ...styles,
      backgroundColor: palette.background.paper,
      color: palette.text.primary,
      fontSize: "1rem",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 100,
    }),
    menuList: (styles) => ({
      ...styles,
      boxShadow: darkMode
        ? "1px -1px 12px -1px #0059a7"
        : "0 6px 16px 4px rgba(81, 97, 133, 0.13), 0 3px 8px 0 rgba(0, 0, 0, 0.15)",
      padding: 0,
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 1500,
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      backgroundColor: palette.background.paper,
      color: palette.text.primary,
      fontSize: "1rem",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused
        ? palette.background.default
        : palette.background.paper,
      fontSize: "1rem",
      textAlign: "left",
      color: palette.text.primary,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? palette.primary.main : colors.blueLight,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: darkMode ? palette.text.primary : colors.black,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: darkMode ? palette.text.primary : colors.blueDark2,
      ":hover": {
        backgroundColor: palette.secondary.main,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1rem",
      color: palette.text.secondary,
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "1rem",
      color: disabled ? palette.text.disabled : palette.text.primary,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "14px 8px",
    }),
  };
};
