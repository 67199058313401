import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import { provisioningStatus } from "msa2-ui/src/Constants";

import {
  Chip,
  Grid,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import commonStyles from "msa2-ui/src/styles/commonStyles";
import { mergeStyles } from "msa2-ui/src/utils/styles";

const localStyles = (theme) => ({
  container: {
    height: 650,
  },
  flexSpaces: {
    display: "flex",
  },
  firstCellRoot: {
    paddingLeft: 24,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 5,
    width: 50,
  },
  heading: {
    paddingLeft: 24,
    paddingBottom: 24,
    paddingTop: 24,
  },
  headingError: {
    color: theme.colors.red,
  },
  timeText: {
    fontSize: ".8125rem",
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.colors.greyDark1,
  },
  stageText: {
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightMedium,
  },
});

const styles = mergeStyles([commonStyles, localStyles]);

const createStatusLabel = (smsStatus, smsMsg, classes) => {
  const stage = provisioningStatus[smsStatus];
  const { text, Icon, color } = stage;

  return (
    <Tooltip title={smsMsg}>
      <div className={classes.flexSpaces}>
        <Icon />
        <Chip
          label={text}
          className={classes.commonTableChip}
          style={{ backgroundColor: color }}
        />
      </div>
    </Tooltip>
  );
};

const createDates = (stageStartDateTime, stageStopDateTime, classes) => {
  let timeTaken = "";
  if (
    stageStartDateTime &&
    stageStartDateTime !== "" &&
    stageStopDateTime &&
    stageStopDateTime !== ""
  ) {
    const startDate = new Date(stageStartDateTime);
    const endDate = new Date(stageStopDateTime);

    // Calculate the difference in milliseconds
    let difference_ms = Math.abs(endDate.getTime() - startDate.getTime());
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    let seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    let minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    let hours = Math.floor(difference_ms % 24);

    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }

    const startTime = stageStartDateTime
      .split(" ")
      .pop()
      .split(".")[0];
    const stopTime = stageStopDateTime
      .split(" ")
      .pop()
      .split(".")[0];

    timeTaken = `${startTime} - ${stopTime} (${hours}:${minutes}:${seconds})`;
  }

  return (
    <div className={classes.flexSpaces}>
      <Typography className={classes.timeText}>{timeTaken}</Typography>
    </div>
  );
};

const generateStageId = (sms_stage, sms_status) =>
  `${sms_stage.replace(" ", "_")}-${sms_status}`;

const InitialProvisioningStatusContent = ({ classes, t, results }) => {
  const stageResults = results.map(
    ({ sms_status, sms_stage, sms_message, start_time, end_time }) => (
      <TableRow key={sms_stage} id={generateStageId(sms_stage, sms_status)}>
        <TableCell
          size="small"
          align="right"
          classes={{
            root: classes.firstCellRoot,
          }}
        >
          {createStatusLabel(sms_status, sms_message, classes)}
        </TableCell>
        <TableCell align="left" size="small">
          <Typography className={classes.stageText}>{sms_stage}</Typography>
        </TableCell>
        <TableCell align="right">
          {createDates(start_time, end_time, classes)}
        </TableCell>
      </TableRow>
    ),
  );

  const emptyResults = (
    <TableRow>
      <TableCell>
        {" "}
        <Typography>
          {t(
            "No Provisioning Stages were returned, there may have been a problem with Provisioning the Managed Entity",
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const failedStage = results.find(
    ({ sms_status }) => provisioningStatus.FAIL.status === sms_status,
  );

  return (
    <Grid className={classes.container}>
      <div>
        <Typography
          className={classNames(classes.heading, {
            [classes.headingError]: failedStage,
          })}
        >
          {failedStage
            ? `${failedStage.sms_stage}: ${failedStage.sms_message}`
            : t("Initial Provisioning in progress")}
        </Typography>
        <Divider />
      </div>

      <Table size={"small"}>
        <TableBody>
          {results.length === 0 ? emptyResults : stageResults}
        </TableBody>
      </Table>
    </Grid>
  );
};

InitialProvisioningStatusContent.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  results: PropTypes.array,
};

export default withStyles(styles)(
  withTranslation()(InitialProvisioningStatusContent),
);
