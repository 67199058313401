import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";

import { getParentRoute } from "msa2-ui/src/utils/urls";

import {
  getAvailableSubtenants,
  getAvailableTenants,
} from "msa2-ui/src/store/designations";
import { getUserRole, userRoles } from "msa2-ui/src/store/auth";

const RepositoryRoute = ({ directories, pathName, ...rest }) => {
  const { pathname } = useLocation();

  const userRole = useSelector(getUserRole);
  const subtenants = useSelector(getAvailableSubtenants);
  const tenants = useSelector(getAvailableTenants);
  const isPathInDataFiles = pathName.includes("/repository/Datafiles");

  if (userRole === userRoles.PRIVILEGED_ADMINISTRATOR) {
    // ncroot can view everything
    return <Route {...rest} />;
  }

  // if (directories.length === 1 && userRole >= userRoles.PRIVILEGED_MANAGER) {
  //   return <Redirect to={buildRoute(pathname, tenants[0].value)} />;
  // }

  if (
    directories.length >= 2 &&
    userRole >= userRoles.ADMINISTRATOR &&
    isPathInDataFiles
  ) {
    const tenantPrefix = directories[1];
    const canViewDirectory = tenants.some(
      ({ value }) => value === tenantPrefix,
    );
    if (!canViewDirectory) {
      return <Redirect to={getParentRoute(pathname)} />;
    }
  }

  if (
    directories.length >= 3 &&
    userRole === userRoles.MANAGER &&
    isPathInDataFiles
  ) {
    const subtenantId = directories[2];
    const canViewDirectory = subtenants.some(
      ({ ubiqubeId }) => ubiqubeId === subtenantId,
    );
    const tenantDirectory = pathname
      .split("/")
      .slice(0, 4)
      .join("/");

    if (!canViewDirectory) {
      return <Redirect to={tenantDirectory} />;
    }
  }

  return <Route {...rest} />;
};

export default RepositoryRoute;
