import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useWorkflowContext from "msa2-ui/src/utils/useWorkflowContext";

import { restartWorkflow } from "msa2-ui/src/api/workflow";

import { CircularProgress } from "@material-ui/core";

import MSADialog from "msa2-ui/src/components/Dialog";
import MSAConsole from "msa2-ui/src/components/msa-console";

const WorkflowLiveConsoleRerunDialog = ({
  processInstance: {
    processId: { id: processId, name: processName },
    serviceId: { id: instanceId, name: workflowPath },
  },
  onClose,
  components,
  token,
  onEvent,
}) => {
  const { t } = useTranslation();
  const { Dialog = MSADialog } = components;
  const [showWorkflowDialog, setShowWorkflowDialog] = useState();

  const {
    context,
    setContext,
    variables,
    processVariables,
    editMode,
    isLoading,
  } = useWorkflowContext({
    workflowPath,
    processName,
    instanceId,
    token,
  });

  const restartProcess = async (data) => {
    const [error] = await restartWorkflow({
      token,
      process_id: processId,
      body: data,
    });
    if (onEvent) {
      const message = error
        ? error.getMessage(t("Restart failed"))
        : t("Task has been successfully restarted.");

      onEvent({ message, isError: Boolean(error) });
    }
    onClose();
    setShowWorkflowDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        onExec={() => {
          restartProcess({});
        }}
        execLabel={t("Run Now")}
        title={t("Restart Process")}
        content={t("Are you sure you want to restart Process?")}
        disabled={isLoading && showWorkflowDialog}
        extraAction={() => setShowWorkflowDialog(true)}
        extraLabel={t("Edit Parameters")}
      >
        {isLoading && showWorkflowDialog && <CircularProgress />}
      </Dialog>

      {showWorkflowDialog && !isLoading && (
        <Dialog
          onClose={() => setShowWorkflowDialog(false)}
          onExec={() => {
            restartProcess(context);
          }}
          execLabel={t("Run Now")}
          title={t("Edit Variables")}
          maxWidth={"md"}
        >
          <MSAConsole
            data={context}
            variables={variables}
            processVariables={processVariables}
            editMode={editMode}
            onChange={setContext}
            components={components}
          />
        </Dialog>
      )}
    </>
  );
};

WorkflowLiveConsoleRerunDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  processInstance: PropTypes.shape({
    processId: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    serviceId: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WorkflowLiveConsoleRerunDialog;
