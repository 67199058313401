import { set, get } from "idb-keyval";

const currentMSAVersion = process.env.REACT_APP_MSA_VERSION;
const MSAVersion = "msaVersion";
const MSAData = "msaData";

/**
 * Save the current state to indexedDB. This method is fire and forget.
 * We call the operation to set the data in indexed Db but we don't wait to see if it succeeded.
 * @param state comes from the REDUX store
 */
export const saveStateToStorage = (state) => {
  const serializedState = JSON.stringify(state);

  set(MSAData, serializedState).catch((error) =>
    console.log("MSA2 : Error saving state to storage", error),
  );
  set(MSAVersion, currentMSAVersion).catch((error) =>
    console.log("MSA2 : Error saving version to storage", error),
  );
};

/**
 * Loads the state saved in indexDb and deserialize it.
 * @returns {Promise<undefined>}
 */
export const loadStateFromStorage = async () => {
  const storedMsaVersion = await getValueFromIDB(MSAVersion);

  /**
   *  will never be read from local storage.
   *  If the currentMSAVersion has changed on another environment,
   *  we will not load the stored state to prevent any conflicts in
   *  the expected state shape.
   **/
  if (currentMSAVersion && currentMSAVersion !== storedMsaVersion) {
    return;
  }

  const serializedState = await getValueFromIDB(MSAData);
  return serializedState ? JSON.parse(serializedState) : undefined;
};

/**
 * Method that  retrieves data from indexed DB based on the key
 * @param key: The key in indexedDB who's value will be returned if it exists
 * @returns {Promise<any>} returns the the database value
 */
const getValueFromIDB = async (key) => {
  try {
    return await get(key);
  } catch (e) {
    console.log("MSA2 UI: Error - Could not read from IndexedDB.");
  }
};
