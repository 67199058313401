import { useCallback, useEffect, useRef } from "react";

/**
 * Creates constant reference for a callback.
 *
 * @param callback {Function} - function for which you need a constant reference.
 * @returns {function(...[*]): never}
 */

export const useBoundCallback = (callback) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useCallback((...args) => callbackRef.current(...args), []);
};
