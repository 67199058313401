import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { makeStyles } from "@material-ui/core";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import { defaultProfile, monitoringPeriods } from "./constants";
import Graph from "./graph/Graph";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";
import CustomPeriodPicker from "./custom-period-picker/CustomPeriodPicker";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles(({ palette, typography }) => ({
  select: { width: "100%" },
  title: {
    color: palette.text.primary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightMedium,
    textTransform: "uppercase",
  },
  graphsWrap: {
    position: "relative",
    minHeight: 500,
  },
  wrap: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  spinnerWrap: {
    paddingRight: 20,
  },
  messageWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noDataMessage: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
}));

const MonitoringGraphsView = ({
  error,
  isError,
  isLoading,
  attachedProfiles,
  externalReference,
  profileId,
  monitoringPeriod,
  isCustomPeriodShown,
  customMonitoringPeriod,
  graphs,
  onMonitoringPeriodChange,
  onCustomMonitoringPeriodChange,
  onCustomMonitoringPeriodVisibilityChange,
  onProfileChange,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const printRef = useRef();
  const t = useBoundedTranslation();

  const handleErrorMessage = (error) => {
    if (isEmpty(error?.data?.message)) {
      return t("GenericErrorMessage");
    } else {
      return error?.data?.message;
    }
  };

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${externalReference}_Monitoring.pdf`);
  };

  return (
    <Grid container spacing={2} data-testid="MONITORINGGRAPHSVIEW">
      {isCustomPeriodShown && (
        <CustomPeriodPicker
          initialValues={customMonitoringPeriod}
          onClose={onCustomMonitoringPeriodVisibilityChange}
          onSelect={onCustomMonitoringPeriodChange}
        />
      )}
      <Grid item xs={12}>
        <Typography className={classes.title}>{t("Monitoring")}</Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <BasicSelect
          className={classes.select}
          label={t("Monitoring Profiles")}
          value={profileId}
          onChange={onProfileChange}
        >
          {[defaultProfile, ...(attachedProfiles || [])].map(
            ({ id, name }, i) => (
              <MenuItem key={i} value={id}>
                {name}
              </MenuItem>
            ),
          )}
        </BasicSelect>
      </Grid>
      <Grid item xs={12} md={2}>
        <BasicSelect
          disabled={Boolean(customMonitoringPeriod?.length)}
          className={classes.select}
          label={`${t("View data for")}:`}
          value={monitoringPeriod}
          onChange={onMonitoringPeriodChange}
        >
          {monitoringPeriods.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </BasicSelect>
      </Grid>
      <Grid item xs={6} md={1}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={4}
        >
          <Grid item xs={5}>
            <Tooltip title={t("Select custom period")}>
              <IconButton
                id="MONITORING_GRAPHS_OPEN_CUSTOM_PERIOD_POPUP"
                onClick={onCustomMonitoringPeriodVisibilityChange}
              >
                <DateRangeIcon
                  color={customMonitoringPeriod?.length ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          {Boolean(customMonitoringPeriod?.length) && (
            <Tooltip title={t("Clear")}>
              <Grid item xs={1}>
                <IconButton
                  id="MONITORING_GRAPHS_RESET_CUSTOM_PERIOD"
                  onClick={() => onCustomMonitoringPeriodChange([], false)}
                >
                  <CloseIcon color="error" />
                </IconButton>
              </Grid>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={1}
        alignItems="center"
        justifyContent="flex-end"
      >
        {isLoading && (
          <div className={classes.spinnerWrap}>
            <CircularProgress size={30} />
          </div>
        )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <Button
            startIcon={<DownloadIcon />}
            disabled={isError || isLoading}
            className={classNames(
              commonClasses.commonBtn,
              commonClasses.commonBtnSecondary,
            )}
            onClick={handleDownloadPdf}
          >
            Download as PDF
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.graphsWrap} ref={printRef}>
        {isError ? (
          <div className={classNames(classes.wrap, classes.messageWrap)}>
            {handleErrorMessage(error)}
          </div>
        ) : graphs?.length > 0 ? (
          graphs?.map(({ id, name, kpis, data }) => (
            <Graph key={id} t={t} name={name} kpis={kpis} data={data} />
          ))
        ) : (
          !isLoading && (
            <div className={classes.noDataMessage}>
              {t("No Data Available")}
            </div>
          )
        )}
      </Grid>
    </Grid>
  );
};

MonitoringGraphsView.propTypes = {
  attachedProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.array,
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      kpis: PropTypes.object,
    }),
  ),
  externalReference: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customMonitoringPeriod: PropTypes.array.isRequired,
  monitoringPeriod: PropTypes.oneOf(["hour", "day", "week", "month", "year"])
    .isRequired,
  profileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onMonitoringPeriodChange: PropTypes.func.isRequired,
  onCustomMonitoringPeriodChange: PropTypes.func.isRequired,
  onCustomMonitoringPeriodVisibilityChange: PropTypes.func.isRequired,
  onProfileChange: PropTypes.func.isRequired,
};

export default MonitoringGraphsView;
