import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { change, arrayRemove } from "redux-form";
import isEmpty from "lodash/isEmpty";

import { getFormValues } from "msa2-ui/src/store/form";

import { makeStyles } from "@material-ui/core";
import { Typography, Button, IconButton, Grid } from "@material-ui/core";
import { ReactComponent as PlusIcon } from "msa2-ui/src/assets/icons/plus.svg";
import { ReactComponent as CloseIcon } from "msa2-ui/src/assets/icons/close.svg";

import Field from "msa2-ui/src/components/connectedFormComponents/Field";

const useStyles = makeStyles((theme) => ({
  parameterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  groupedField: {
    paddingBottom: 10,
  },
  field: {
    width: "100%",
    paddingBottom: 10,
  },
  icon: {
    cursor: "pointer",
  },
  removeButtonContainer: {
    alignSelf: "flex-start",
    marginTop: 5,
  },
  addButtonContainer: {
    alignSelf: "flex-end",
    marginTop: 20,
  },
  sectionHeader: {
    paddingBottom: 10,
  },
}));

const getElementId = (prefix, postfix) =>
  prefix && postfix ? `${prefix}_${postfix}` : undefined;

const NestedAdvancedParameters = ({
  form,
  parameter: parentParameter,
  name: variablePath,
  sectionHeader,
  childProps,
  extraChildObject = {},
  id,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const parameterInstances = useSelector(getFormValues(form, variablePath));
  const dispatch = useDispatch();

  //This is being used as redux form has a strange thing where it removed the key if the value is empty.
  //Which meant if you edited the value in a field and then deleted it the field would disappear
  //This keeps a hard count of the how many instances there should be
  const [instancesCount, setInstancesCount] = useState(
    parameterInstances ? Object.keys(parameterInstances) : [],
  );

  const initObject = {
    ...extraChildObject,
    ...parentParameter.parameters.reduce(
      (acc, parameter) => ({
        ...acc,
        [parameter.parameterName]: parameter.defaultValue ?? "",
      }),
      {},
    ),
  };
  const isRowOfFields = parentParameter.parameters.length > 1;
  const hasChildObject = isRowOfFields || !isEmpty(extraChildObject);

  return (
    <>
      <Grid className={classes.sectionHeader} container>
        <Grid item xs={12}>
          <Typography>{sectionHeader}</Typography>
        </Grid>
      </Grid>
      {instancesCount.map((count) => {
        return (
          <Grid
            key={count}
            spacing={1}
            className={classes.parameterContainer}
            container
          >
            {parentParameter.parameters.map((parameter, index) => {
              const props = childProps
                ? childProps(count)[parameter.parameterName] ?? {}
                : {};
              const parameterNameTarget =
                hasChildObject && parameter.parameterName !== "class"
                  ? `.${parameter.parameterName}`
                  : ``;
              const shouldShowRemoveButton =
                parentParameter.parameters.length === index + 1;
              const fieldColumnWidth =
                (10 - (props.additionalAction ? 2 : 0)) /
                (isRowOfFields ? 2 : 1);
              return (
                <Fragment key={`${index}.${count}`}>
                  <Field
                    id={getElementId(
                      id,
                      `${parameter.parameterName.toUpperCase()}_${count}`,
                    )}
                    type={parameter.type}
                    name={`${variablePath}.${count}${parameterNameTarget}`}
                    label={props.label || `${t(parameter.parameterName)}`}
                    inputProps={{
                      "aria-label": `${parameter.parameterName}`,
                    }}
                    fieldColumnWidth={fieldColumnWidth}
                    isNested
                    {...rest}
                    {...props}
                  />
                  {props.additionalAction && (
                    <Grid
                      className={classes.removeButtonContainer}
                      item
                      xs={2}
                      container
                      justifyContent="center"
                      alignContent="center"
                    >
                      <props.additionalAction
                        id={getElementId(
                          id,
                          `${parameter.parameterName.toUpperCase()}_${count}_ACTION`,
                        )}
                      />
                    </Grid>
                  )}
                  {shouldShowRemoveButton && (
                    <Grid className={classes.removeButtonContainer} item xs={2}>
                      <IconButton
                        id={getElementId(
                          id,
                          `${parameter.parameterName.toUpperCase()}_${count}_REMOVE`,
                        )}
                        aria-label="removeButton"
                        className={classes.icon}
                        onClick={() => {
                          dispatch(arrayRemove(form, variablePath, count));

                          setInstancesCount((state) => {
                            state.pop();
                            return [...state];
                          });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid>
        );
      })}
      <Grid className={classes.addButtonContainer} item xs={4}>
        {(parentParameter.allowMultipleEntries ||
          instancesCount.length === 0) && (
          <Button
            id={getElementId(id, "ADD")}
            aria-label="addButton"
            onClick={() => {
              dispatch(
                change(
                  form,
                  `${variablePath}.${instancesCount.length}`,
                  hasChildObject
                    ? initObject
                    : parentParameter.parameters[0].defaultValue ?? "",
                ),
              );
              setInstancesCount((state) => [...state, state.length]);
            }}
            disabled={rest.inputProps?.disabled ?? false}
          >
            {t("Add More")}
            <PlusIcon />
          </Button>
        )}
      </Grid>
    </>
  );
};

NestedAdvancedParameters.propTypes = {
  parameter: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  sectionHeader: PropTypes.string.isRequired,
};

export default NestedAdvancedParameters;
