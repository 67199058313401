import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import useDialog from "msa2-ui/src/hooks/useDialog";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";

import { getToken } from "msa2-ui/src/store/auth";
import {
  getSelectedTenant,
  clearSelectedTenant,
} from "msa2-ui/src/store/designations";
import { deleteTenant } from "msa2-ui/src/api/tenant";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";

import {
  Grid,
  IconButton,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";

import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableRow from "msa2-ui/src/components/TableRow";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const TenantTableBody = ({
  loading,
  tenants,
  error,
  numberOfTableColumns,
  reloadTenants,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const token = useSelector(getToken);
  const selectedTenant = useSelector(getSelectedTenant);
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [selectedRow, setSelectedRow] = useState();

  const handleDelete = async () => {
    const [error] = await deleteTenant({
      token,
      prefix: selectedRow.prefix,
    });
    const variant = error ? "error" : "success";
    const message = error
      ? `${t("Unable to delete")} ${t("Tenant")}`
      : `${t("Tenant")} ${t("deleted")} `;

    if (!error) {
      if (selectedTenant.value === selectedRow.prefix) {
        dispatch(clearSelectedTenant());
      }
      showDeleteDialog(false);
      reloadTenants();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  if (loading) {
    return <TableLoading numberOfTableColumns={numberOfTableColumns} />;
  }

  if (tenants.length === 0) {
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching x", { x: t("Tenants") })}. ${t(
                "Please reload the page",
              )}.`
            : t("No Tenants found")
        }
        numberOfTableColumns={numberOfTableColumns}
      />
    );
  }

  return (
    <>
      <DeleteDialog
        title={`${t("Delete")} ${t("Tenant")}`}
        content={
          selectedRow?.customersCount > 0
            ? `${t("You must delete all attached subtenants before deleting.")}`
            : `${t("Are you sure you want to delete x?", {
                x: selectedRow?.name,
              })}`
        }
        onExec={selectedRow?.customersCount > 0 ? () => {} : handleDelete}
      />
      <TableBody>
        {tenants.map((tenant, index) => {
          return (
            <TableRow
              id={`TENANT_TABLE_ROW_${index}`}
              key={tenant.id}
              actions={
                <>
                  <IconButton
                    id={`TENANT_TABLE_BTN_EDIT_${index}`}
                    aria-label={t("Edit")}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={{
                      pathname: `${url}/${tenant.prefix}/edit`,
                    }}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton
                    id={`TENANT_TABLE_BTN_DELETE_${index}`}
                    aria-label={t("Delete")}
                    onClick={() => {
                      setSelectedRow(tenant);
                      showDeleteDialog();
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </>
              }
            >
              <TableCell component="th" scope="row" padding="none">
                <Grid
                  id={`TENANT_TABLE_CELL_NAME_${index}`}
                  className={classNames(commonClasses.commonTableCellClickable)}
                  container
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    className={commonClasses.commonTablePrimary}
                  >
                    {tenant.name}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {tenant.prefix}
                </Typography>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {tenant.id}
                </Typography>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {displayMonthDayYearTimeDate(tenant.lastUpdated)}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

TenantTableBody.propTypes = {
  loading: PropTypes.bool,
  tenants: PropTypes.array.isRequired,
  error: PropTypes.object,
  numberOfTableColumns: PropTypes.number.isRequired,
  reloadTenants: PropTypes.func.isRequired,
};

export default TenantTableBody;
