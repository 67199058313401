import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import set from "lodash/set";
import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import flow from "lodash/flow";
import reduxForm from "redux-form/lib/reduxForm";
import useDialog from "msa2-ui/src/hooks/useDialog";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import Variable from "msa2-ui/src/services/Variable";

import { getFormValues } from "msa2-ui/src/store/form";
import {
  getModelsById,
  getManufacturers,
  getManufacturersById,
} from "msa2-ui/src/store/designations";

import { createMicroservice } from "msa2-ui/src/api/microservices";
import useApi from "msa2-ui/src/hooks/useApi";

import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import MicroserviceCommand from "msa2-ui/src/services/MicroserviceCommand";
import Microservice from "msa2-ui/src/services/Microservice";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Modal from "msa2-ui/src/components/modal/Modal";
import { useReturnToPreviousPage } from "msa2-ui/src/components/formSection/FormCommon";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";

import ModalTitleBar from "./ModalTitleBar";
import InformationTabContentCreate from "./InformationTabContentCreate";
import VariablesTabContent from "msa2-ui/src/components/variables/VariablesTabContent";
import { CommandTabsContent } from "./CommandTabsContent";
import ModalSidebar from "./ModalSidebar";
import { getFormSyncErrors } from "redux-form";
import { isEmpty } from "lodash";

const Create = ({
  destroy,
  dirty,
  match,
  handleSubmit,
  history,
  initialize,
  initialized,
  change,
  formErrors,
}) => {
  const { t } = useTranslation();
  const form = MicroserviceForm.CreateFormName;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formDataToSubmit, setFormDataToSubmit] = useState(null);
  const [activeSidebarTab, setActiveSidebarTab] = useState(
    MicroserviceForm.sidebarTabs.information,
  );

  const isEditingVariables = Boolean(
    useSelector(getFormValues(form, "active")),
  );
  const microserviceVariables = useSelector(
    getFormValues(form, MicroserviceForm.formConfig.variablePath),
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDiscardDialog, DiscardDialog] = useDialog();

  const modelsById = useSelector(getModelsById);
  const manufacturers = useSelector(getManufacturers);
  const manufacturersById = useSelector(getManufacturersById);
  const [deviceAdapterError, setDeviceAdapterError] = useState(null);
  const [checkVendor, setCheckVendor] = useState(" ");
  const [checkModel, setCheckModel] = useState(" ");

  const [, createError, createResponse] = useApi(
    createMicroservice,
    {
      body: formDataToSubmit,
    },
    !(isSubmitting && formDataToSubmit),
  );

  useDeepCompareEffect(() => {
    if (!initialized && manufacturers) {
      const currentManufacturer = manufacturers[0];
      initialize(
        set(
          {
            information: {
              displayName: "",
              configType: "cli",
              displayField: "object_id",
              description: "",
              category: "Default",
            },
            metaInformationList: [
              {
                vendorId: currentManufacturer,
                modelId: manufacturersById[currentManufacturer]?.models[0],
              },
            ],
            command: [
              MicroserviceCommand.make(MicroserviceCommand.type.import),
              MicroserviceCommand.make(MicroserviceCommand.type.create),
              MicroserviceCommand.make(MicroserviceCommand.type.read),
              MicroserviceCommand.make(MicroserviceCommand.type.update),
              MicroserviceCommand.make(MicroserviceCommand.type.constraint),
              MicroserviceCommand.make(MicroserviceCommand.type.delete),
              MicroserviceCommand.make(MicroserviceCommand.type.list),
            ],
            config: MicroserviceForm.formConfig,
          },
          MicroserviceForm.formConfig.variablePath,
          Variable.mandatoryItems.map((name) =>
            assign(cloneDeep(Variable.initVariable), {
              name: [Variable.variablePrefix, name].join("."),
              displayName: name,
            }),
          ),
        ),
      );
    }
  }, [initialize, initialized, manufacturers, manufacturersById]);

  const onSidebarTabClicked = (sidebarTab) => {
    setActiveSidebarTab(sidebarTab);
  };

  const returnToPreviousPage = useReturnToPreviousPage(
    history,
    match.url,
    isSubmitting,
  );

  const handleStatusSnackbar = useCallback(
    (status, msg) => {
      enqueueSnackbar(msg, {
        variant: status,
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );

  useEffect(() => {
    if (createError) {
      setIsSubmitting(false);
      if (!isSubmitting) {
        const reason = t("Microservice create FAILED");
        handleStatusSnackbar("error", createError.getMessage(reason));
      }
      return;
    }

    if (createResponse) {
      destroy();
      setIsSubmitting(false);
      returnToPreviousPage();
      if (!isSubmitting) {
        handleStatusSnackbar("success", t("Microservice created"));
      }
    }
  }, [
    destroy,
    formDataToSubmit,
    history,
    returnToPreviousPage,
    isSubmitting,
    createResponse,
    createError,
    handleStatusSnackbar,
    t,
  ]);

  const submitForm = (values) => {
    if (!checkVendor || !checkModel) {
      setIsSubmitting(false);
      setActiveSidebarTab(MicroserviceForm.sidebarTabs.information);
      setDeviceAdapterError("Please select Vendor and Model");
    } else {
      setDeviceAdapterError(null);
      setIsSubmitting(true);
      setFormDataToSubmit(Microservice.createMicroserviceObject(values));
    }
  };

  const onEditVariableClicked = (index, variable) => {
    change("active", { index, variable });
    setActiveSidebarTab(MicroserviceForm.sidebarTabs.variables);
  };

  const navigateToError = (errors) => {
    const fieldsWithErrors = Object.keys(errors);
    setActiveSidebarTab(MicroserviceForm.mapFieldsToTabs[fieldsWithErrors[0]]);
  };

  const handleClose = () => {
    if (dirty) {
      return showDiscardDialog();
    }
    returnToPreviousPage();
  };

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={() => returnToPreviousPage()}
      />
      <Modal onClose={handleClose}>
        <ModalTitleBar
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Create Microservice")}
          discardButtonLabel={t("Discard Changes")}
          disabled={isSubmitting || isEditingVariables}
          onSave={() => {
            if (!isEmpty(formErrors)) {
              navigateToError(formErrors);
            }

            handleSubmit(submitForm)();
          }}
          onDiscard={showDiscardDialog}
          onClose={handleClose}
        />
        <ModalContent>
          <ModalSidebar
            activeTab={activeSidebarTab}
            handleOnClick={onSidebarTabClicked}
            form={form}
          />
          <ScrollingContainer>
            <InformationTabContentCreate
              manufacturers={manufacturers}
              manufacturersById={manufacturersById}
              modelsById={modelsById}
              isActive={
                activeSidebarTab === MicroserviceForm.sidebarTabs.information
              }
              deviceAdapterError={deviceAdapterError}
              setCheckModel={setCheckModel}
              setCheckVendor={setCheckVendor}
              setDeviceAdapterError={setDeviceAdapterError}
            />
            <VariablesTabContent isActive={activeSidebarTab} form={form} />
            <CommandTabsContent
              isActive={activeSidebarTab}
              onEditVariableClicked={onEditVariableClicked}
              microserviceVariables={microserviceVariables}
              form={form}
              isSubmitting={isSubmitting}
            />
          </ScrollingContainer>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

Create.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors(MicroserviceForm.CreateFormName)(state),
});

export default flow(
  withRouter,
  reduxForm({
    form: MicroserviceForm.CreateFormName,
    validate: MicroserviceForm.validate,
  }),
  connect(mapStateToProps),
)(Create);
