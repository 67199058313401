import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AttachmentDialog from "msa2-ui/src/components/attachment-dialog";
import useApi from "msa2-ui/src/hooks/useApi";
import { useSelector } from "react-redux";
import { getToken, getUserDetails } from "msa2-ui/src/store/auth";
import { getSubtenantsByManager } from "msa2-ui/src/api/subtenant";
import { getSelectedTenant } from "msa2-ui/src/store/designations";
import { getSubtenantsByBpm } from "msa2-ui/src/api/bpm";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import union from "lodash/union";
import difference from "lodash/difference";
import {
  attachServicesToMultipleSubtenants,
  detachServicesFromMultipleSubtenants,
} from "msa2-ui/src/api/bpm";
import { useSnackbar } from "notistack";

const AttachDetachBpm = ({ name, uri, onClose }) => {
  const t = useBoundedTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const managerId = useSelector(getUserDetails).id;
  const { id: tenantId, value: tenantPrefix } = useSelector(getSelectedTenant);
  const token = useSelector(getToken);

  const [attachedSubtenants, setAttachedSubtenants] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [
    isSubtenantsLoading,
    ,
    subtenants = [],
  ] = useApi(getSubtenantsByManager, { managerId });

  const [isSubtenantsByBpmLoading, , subtenantsByBpm] = useApi(
    getSubtenantsByBpm,
    {
      managerId,
      tenantId,
      bpmPath: uri,
    },
  );

  const handleAttach = useCallback(
    (ids) => setAttachedSubtenants(union(attachedSubtenants, ids)),
    [attachedSubtenants],
  );

  const handleDetach = useCallback(
    (ids) => setAttachedSubtenants(difference(attachedSubtenants, ids)),
    [attachedSubtenants],
  );

  const handleClose = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSave = useCallback(async () => {
    const prevAttachedSubtenants = subtenantsByBpm.map(({ id }) => id);
    const newAttachedSubtenants = attachedSubtenants;

    const diffAttachments = difference(
      newAttachedSubtenants,
      prevAttachedSubtenants,
    );
    const diffDetachments = difference(
      prevAttachedSubtenants,
      newAttachedSubtenants,
    );

    setIsSaving(true);

    const [attachmentResponse, detachmentResponse] = await Promise.all([
      diffAttachments.length
        ? attachServicesToMultipleSubtenants({
            token,
            uri,
            ubiqubeIds: diffAttachments.join(","),
          })
        : [],
      diffDetachments.length
        ? detachServicesFromMultipleSubtenants({
            token,
            uri,
            ubiqubeIds: diffDetachments.join(","),
          })
        : [],
    ]);

    const error = attachmentResponse[0] || detachmentResponse[0];
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to attach x", { x: t("Subtenants") }))
      : t("Subtenants have been attached.");

    variant === "success" ? onClose(true) : setIsSaving(false);

    enqueueSnackbar(message, { variant });
  }, [
    attachedSubtenants,
    enqueueSnackbar,
    onClose,
    subtenantsByBpm,
    t,
    token,
    uri,
  ]);

  useEffect(() => {
    subtenantsByBpm &&
      setAttachedSubtenants(subtenantsByBpm.map(({ id }) => id));
  }, [subtenantsByBpm]);

  return (
    <AttachmentDialog
      allItems={
        tenantPrefix
          ? subtenants.filter(
              (subtenant) => subtenant.operatorPrefix === tenantPrefix,
            )
          : subtenants
      }
      attachedItemIds={attachedSubtenants}
      idKey="ubiqubeId"
      displayNameKey="label"
      attachedTitle={t("Attached Subtenants") + ": "}
      popupTitle={t("Attach", { wfName: name })}
      noDataMessage={t("There are no Subtenants.")}
      searchPlaceholder={t("Search for Subtenants")}
      unattachedTitle={t("Select x to attach", {
        x: t("Subtenants"),
      })}
      isLoading={isSubtenantsLoading || isSubtenantsByBpmLoading || isSaving}
      onAttach={handleAttach}
      onDetach={handleDetach}
      onSave={handleSave}
      onClose={handleClose}
      warningMessage={t(
        "Please ensure that the Workflows used inside this BPM are also attached to each selected Subtenant.",
      )}
    />
  );
};

AttachDetachBpm.propTypes = {
  name: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AttachDetachBpm;
