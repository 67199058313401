import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";
import { makeStyles } from "@material-ui/core";

import { getParentRoute } from "msa2-ui/src/utils/urls";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  readConfigurationVariable,
  createConfigurationVariable,
  deleteConfigurationVariable,
} from "msa2-ui/src/api/managedEntity";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    padding: 0,
  },
  contentWrapper: {
    padding: 25,
    lineHeight: 1.5,
    letterSpacing: 0.25,
    textAlign: "center",
  },
  tabsWrapper: {
    padding: "0 16px",
  },
  dialogContent: {
    paddingBottom: 20,
    fontSize: "1rem",
    color: palette.text.secondary,
    height: 350,
  },
  item: {
    margin: "15px 0px",
  },
}));

const ConfigurationVariable = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { assetId: deviceId, variableName: _variableName } = useParams();
  const variableName = _variableName && decodeURIComponent(_variableName);
  const history = useHistory();

  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [comment, setComment] = useState("");

  const onClose = () => {
    history.push(getParentRoute(pathname, variableName ? 2 : 1));
  };

  const [loading, , configurationVariable] = useApi(
    readConfigurationVariable,
    { deviceId, name: variableName },
    !deviceId || !variableName,
  );

  const token = useSelector(getToken);
  const [saving, setSaving] = useState(false);
  const onSave = async () => {
    setSaving(true);
    if (variableName && variableName !== name) {
      // If the variable name is changed, delete the old one
      await deleteConfigurationVariable({
        token,
        deviceId,
        name: variableName,
      });
    }

    const [error] = await createConfigurationVariable({
      token,
      deviceId,
      name,
      value,
      comment,
    });
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to save x", { x: t("Variable") }))
      : t("x saved successfully", { x: t("Variable") });
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    if (!error) {
      onClose();
    }
    setSaving(false);
  };

  useEffect(() => {
    // Set values from API
    if (configurationVariable) {
      setName(configurationVariable.name);
      setValue(configurationVariable.value);
      setComment(configurationVariable.comment);
    }
  }, [configurationVariable]);

  return (
    <Dialog
      id="CONFIGURATION_VARIABLE"
      open
      onClose={onClose}
      aria-labelledby="CONFIGURATION_VARIABLE_TITLE"
      fullWidth
      maxWidth={"md"}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        id="CONFIGURATION_VARIABLE_TITLE"
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {name}
        </Typography>
        <IconButton
          id="CONFIGURATION_VARIABLE_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.contentWrapper}>
          {loading || saving ? (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignContent="center"
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} className={classes.item}>
                <TextField
                  type={"text"}
                  id="CONFIGURATION_VARIABLE_NAME"
                  variant="outlined"
                  label={t("Name")}
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.item}>
                <TextField
                  type={"text"}
                  id="CONFIGURATION_VARIABLE_VALUE"
                  variant="outlined"
                  label={t("Value")}
                  value={value}
                  onChange={({ target: { value } }) => setValue(value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.item}>
                <TextField
                  type={"text"}
                  id="CONFIGURATION_VARIABLE_COMMENT"
                  variant="outlined"
                  label={t("Comment")}
                  value={comment}
                  onChange={({ target: { value } }) => setComment(value)}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          id="CONFIGURATION_VARIABLE_DIALOG_ACTIONS_BTN_CANCEL"
          variant="text"
          size="small"
          color="default"
          className={classnames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          disabled={saving}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="CONFIGURATION_VARIABLE_DIALOG_ACTIONS_BTN_SAVE"
          variant="contained"
          size="small"
          color="primary"
          className={classnames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          disabled={saving}
          onClick={onSave}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurationVariable;
