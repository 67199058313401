import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useDialog from "msa2-ui/src/hooks/useDialog";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import { commonStyles } from "./commonStyles";

const useStyles = makeStyles(commonStyles);

const ParserTreeFolder = (props) => {
  const { label, countChildren, onDelete, addButtons } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const [showDeleteDialog, DeleteDialog] = useDialog();

  return (
    <Grid
      container
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => setHover(true)}
      className={hover ? classes.hover : ""}
    >
      <Grid item xs={8}>
        <Grid
          container
          wrap={"nowrap"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          className={classes.contentWrapper}
        >
          <Typography variant={"body1"} className={classes.folder}>
            {label}
            {": "}
            {countChildren}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        container
        justifyContent="flex-end"
        alignContent="center"
      >
        <div>
          {hover && (
            <>
              {addButtons()}
              <IconButton
                id="CONFIGURATION_PARSER_FOLDER_BTN_DELETE"
                aria-label={t("Delete")}
                onClick={showDeleteDialog}
              >
                <IconDeleteRed />
              </IconButton>
            </>
          )}
          <DeleteDialog
            title={t("Confirm Request")}
            content={t("Are you sure you want to delete this folder?")}
            onExec={onDelete}
          />
        </div>
      </Grid>
    </Grid>
  );
};

ParserTreeFolder.propTypes = {
  label: PropTypes.string,
  countChildren: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  addButtons: PropTypes.func,
};

export default ParserTreeFolder;
