import React from "react";
import { Typography } from "@material-ui/core";
import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";
import difference from "lodash/difference";
import { useTranslation } from "react-i18next";

const AssociatedManagersTab = ({
  tenant,
  entry,
  setEntry,
  managers,
  managersLoading,
  managersError,
}) => {
  const { t } = useTranslation();
  const { attachedManagers } = entry;
  const handleAttach = (managersToAttach) =>
    setEntry({
      ...entry,
      attachedManagers: attachedManagers.concat(managersToAttach),
    });
  const handleDetach = (managersToDetach) =>
    setEntry({
      ...entry,
      attachedManagers: difference(attachedManagers, managersToDetach),
    });
  if (!tenant) {
    return <Typography>{t("Please select a tenant first")}</Typography>;
  } else if (managersLoading) {
    return <Typography>{t("Loading managers")}...</Typography>;
  } else if (managersError) {
    return (
      <Typography>
        {t("Error loading x", { x: t("Managers") })}.{" "}
        {t("Please reload the page")}
      </Typography>
    );
  } else if (!managers.length) {
    return (
      <Typography>{t("This tenant does not have any managers")}</Typography>
    );
  }

  return (
    <AttachmentBoard
      allItems={managers}
      attachedItemIds={attachedManagers}
      idKey="id"
      displayNameKey="name"
      unattachedTitle={`${t("Select Managers to associate")}:`}
      attachedTitle={`${t("Associated Managers")}:`}
      searchPlaceholder={t("Search for Managers")}
      handleAttachAll={handleAttach}
      handleAttach={handleAttach}
      handleDetach={handleDetach}
      handleDetachAll={handleDetach}
    />
  );
};

export default AssociatedManagersTab;
