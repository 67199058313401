import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { components } from "react-select";
import PropTypes from "prop-types";

import { getMicroservices } from "msa2-ui/src/api/microservices";
import {
  getModelsById,
  getManufacturersById,
} from "msa2-ui/src/store/designations";

import { makeStyles } from "@material-ui/core";
import { Chip, Grid } from "@material-ui/core";

import { MsaSelectLazy } from "msa2-ui/src/components/msa-select";
import DeviceAdapterSelector from "msa2-ui/src/components/DeviceAdapterSelector";
import { getToken } from "msa2-ui/src/store/auth";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const useStyles = makeStyles((theme) => ({
  selectField: {
    textAlign: "left",
    width: "100%",
  },
  chip: {
    marginLeft: 10,
  },
  category: {
    paddingBottom: 5,
    width: "100%",
  },
  vendor: {
    paddingRight: 5,
    paddingBottom: 5,
    width: "100%",
  },
  model: {
    paddingBottom: 5,
    width: "100%",
  },
}));

const Option = memo((props) => {
  const {
    children,
    data: { vendor, model },
    selectProps: { classes, manufacturersById, modelsById },
  } = props;
  const chip = `${manufacturersById[vendor]?.manufacturerName ?? vendor}
     / ${modelsById[model]?.modelName ?? model}`;
  return (
    <components.Option {...props}>
      {children}
      <Chip label={chip} className={classes.chip} />
    </components.Option>
  );
});

const MicroserviceSelector = ({ onSelectMicroservice, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const manufacturersById = useSelector(getManufacturersById);
  const modelsById = useSelector(getModelsById);
  const token = useSelector(getToken);

  const [selectedMicroservice, setSelectedMicroservice] = useState();
  const [selectedDeviceAdapter, setSelectedDeviceAdapter] = useState();
  const vendorId = selectedDeviceAdapter?.vendor?.manufacturerId;
  const modelId = selectedDeviceAdapter?.model?.modelId;

  useEffect(() => {
    if (
      // If Microservice is selected
      selectedMicroservice &&
      // Vendor is selected and different from selected Microservice
      ((vendorId &&
        selectedMicroservice.vendor &&
        vendorId.toString() !== selectedMicroservice.vendor) ||
        // or Model is selected and different from selected Microservice
        (modelId &&
          selectedMicroservice.modelId &&
          modelId.toString() !== selectedMicroservice.modelId))
    ) {
      // Then reset Microservice
      setSelectedMicroservice(null);
    }
  }, [vendorId, modelId, selectedMicroservice]);

  const handleLoad = async (search, loadedOptions, page = 1) => {
    const [error, data, meta = {}] = await getMicroservices({
      token,
      vendorId,
      modelId,
      page,
      pageSize: 100,
      searchParams: search,
      filterByLabel: isPermissionProfileLabelsEnabled,
    });

    if (error) {
      return { options: [], hasMore: false, additional: page + 1 };
    }

    const totalCount = parseInt(meta.total_ms_count || 0);
    return {
      options: data,
      hasMore: loadedOptions.length + data.length < totalCount,
      additional: page + 1,
    };
  };

  return (
    <Grid container>
      <DeviceAdapterSelector
        onSelectDeviceAdapter={setSelectedDeviceAdapter}
        vendorProps={{ placeholder: t("Filter by Vendor...") }}
        modelProps={{ placeholder: t("Filter by Model...") }}
        categoryProps={{ placeholder: t("Filter by Category...") }}
        categoryGridProps={{ xs: 12, className: classes.category }}
        vendorGridProps={{ xs: 6, className: classes.vendor }}
        modelGridProps={{ xs: 6, className: classes.model }}
      />
      <Grid item xs={12} className={classes.selectField}>
        <MsaSelectLazy
          id="MICROSERVICE_SELECTOR_NAME"
          placeholder={t("Select Microservice ...")}
          debounceTimeout={300}
          scrollToLoadRatio={0.7}
          value={selectedMicroservice}
          onChange={(microservice) => {
            setSelectedMicroservice(microservice);
            onSelectMicroservice(microservice);
          }}
          loadOptions={handleLoad}
          components={{ Option }}
          manufacturersById={manufacturersById}
          modelsById={modelsById}
          cacheUniqs={[vendorId, modelId]}
          classes={classes}
          getOptionLabel={(option) => option.displayName}
          {...props}
        />
      </Grid>
    </Grid>
  );
};

MicroserviceSelector.propTypes = {
  onSelectMicroservice: PropTypes.func.isRequired,
};

export default MicroserviceSelector;
