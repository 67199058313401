import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { acknowledgeAllAlarms } from "msa2-ui/src/api/logs";

import { getToken } from "msa2-ui/src/store/auth";

import AcknowledgeAlarmDialog from "./AcknowledgeAlarmDialog";
import SplitButton from "msa2-ui/src/components/SplitButton";
import useDialog from "msa2-ui/src/hooks/useDialog";

const AcknowledgeAlarmButton = ({
  alarms = [],
  onAck,
  searchQuery = {},
  searchResult = {
    count: 0,
  },
}) => {
  const { count } = searchResult;
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();

  const [showAckAllDialog, AckAllDialog] = useDialog();
  const [showAckDialog, setShowAckDialog] = useState(false);

  const handleAcknowledgeAllAlarms = async () => {
    const [error] = await acknowledgeAllAlarms({
      token,
      body: searchQuery,
    });

    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to acknowledge Alarms"))
      : t("All {{count}} Alarms have been acknowledged", { count });

    enqueueSnackbar(message, { variant });

    if (!error) {
      onAck();
    }
  };

  const hideAcknowledgeButton = !alarms.length;
  const ackOptions = [
    {
      id: "ACKNOWLEDGE_ALARM_BTN",
      label: t("Acknowledge"),
      onClick: () => setShowAckDialog(true),
      disabled: hideAcknowledgeButton,
    },
    {
      id: "ACKNOWLEDGE_ALL_ALARM_BTN",
      label: t("Acknowledge All"),
      onClick: showAckAllDialog,
      disabled: hideAcknowledgeButton,
    },
  ];

  return (
    <>
      <SplitButton size="medium" options={ackOptions} />
      {showAckDialog && (
        <AcknowledgeAlarmDialog
          entries={alarms}
          onAck={() => {
            setShowAckDialog(false);
            onAck();
          }}
          onClose={() => setShowAckDialog(false)}
        />
      )}
      <AckAllDialog
        title={t("Confirm Request")}
        content={t(
          "Are you sure you want to acknowledge all {{count}} alarms",
          {
            count,
          },
        )}
        onExec={handleAcknowledgeAllAlarms}
        shouldHideOnExec={false}
      />
    </>
  );
};

export default AcknowledgeAlarmButton;
