import React from "react";
import PropTypes from "prop-types";
import {
  Popover,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const KEY_BINDINGS = [
  { name: "Find All", mac: "Command-Shift-L", win: "Ctrl-Shift-L" },
  { name: "Copy Line Down", mac: "Shift-Option-Down", win: "Alt-Shift-Down" },
  { name: "Remove Line", mac: "Command-Shift-K", win: "Ctrl-Shift-K" },
  { name: "Find Next", mac: "Command-G", win: "Ctrl-K|Ctrl-D" },
  { name: "Select or Find Next", mac: "Command-D", win: "Ctrl-D" },
  { name: "Block Indent", mac: "Command-]", win: "Ctrl-]" },
  { name: "Autocomplete", mac: "Ctrl-Space", win: "Ctrl+Space" },
];

const TaskEditorKeyBindings = ({ isOpen, anchorRef, onClose }) => {
  const commonClasses = useCommonStyles();

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorRef.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Paper className={commonClasses.commonPaper}>
        <Table data-testid="TASK_EDITOR_KEY_BINDINGS">
          <TableHead>
            <TableRow>
              <TableCell>Key binding</TableCell>
              <TableCell>Mac</TableCell>
              <TableCell>Win</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {KEY_BINDINGS.map(({ name, mac, win }) => (
              <TableRow key={name}>
                <TableCell>{name}</TableCell>
                <TableCell>{mac}</TableCell>
                <TableCell>{win}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Popover>
  );
};

TaskEditorKeyBindings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskEditorKeyBindings;
