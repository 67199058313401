import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  getFormSyncErrors,
  reduxForm,
  updateSyncErrors,
  getFormValues,
} from "redux-form";
import flow from "lodash/flow";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";
import produce from "immer";

import useDialog from "msa2-ui/src/hooks/useDialog";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import Variable from "msa2-ui/src/services/Variable";
import MicroserviceCommand from "msa2-ui/src/services/MicroserviceCommand";
import Validation from "msa2-ui/src/services/Validation";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";

import { setFormValues } from "msa2-ui/src/store/form";

import {
  makeStyles,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Checkbox, Divider, Grid, Typography } from "@material-ui/core";
import EditOutlined from "@material-ui/icons/EditOutlined";

import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import Dialog from "msa2-ui/src/components/Dialog";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";
import { RunTimeVariablesTable } from "msa2-ui/src/components/msa-console";
import { difference } from "lodash";
import AdvancedParameters from "msa2-ui/src/services/AdvancedParameters";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  groupName: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: "left",
  },
  arrayTable: {
    textAlign: "left",
    margin: "20px 20px 20px 115px",
  },
  variable: {
    padding: 10,
  },
  checkbox: {
    padding: "15px 10px",
  },
  checkboxTable: {
    padding: 10,
    height: 42,
  },
  headerAction: {
    marginBottom: 50,
  },
  editKeysIcon: {
    marginRight: 8,
  },
}));

const form = MicroserviceForm.ConsoleFormName;

const MicroserviceInstanceModal = ({
  data,
  instanceDataForMicroservice = {},
  commandType,
  onSave,
  onClose,
  variables: _variables,
  initialize,
  dirty,
  shouldShowCheckbox = false,
  selectedRows = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isBulkOperationEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.msBulkOperation,
  );
  const variables = useMemo(() => {
    return commandType === MicroserviceCommand.type.update
      ? AdvancedParameters.utilizeAdvancedParameters(_variables)
      : _variables;
  }, [_variables, commandType]);

  const [showDiscardDialog, DiscardDialog] = useDialog();
  const [showKeyEditDialog, KeyEditDialog] = useDialog();

  const formValues = useSelector(getFormValues(form));
  const syncErrors = useSelector(getFormSyncErrors(form));

  // // // for bulk operation // // //
  const isBulkUpdate =
    commandType === MicroserviceCommand.type.update && selectedRows.length > 1;

  const [checkedItems, setCheckedItems] = useState([]);
  const singleKeyVariables = variables.filter(
    ({ name, type }) =>
      Variable.isKeyVariable(name, form) ||
      (type === "Index" && !Variable.getTableValData(name).isTable),
  );
  const compositeKeyVariables = variables.filter(
    ({ isCompositeKey }) => isCompositeKey,
  );
  const keyVariables = uniqBy(
    [...singleKeyVariables, ...compositeKeyVariables],
    "name",
  );
  const autoIncrementVariables = variables.filter(
    ({ type, name }) =>
      type === "AutoIncrement" && !Variable.getTableValData(name).isTable,
  );
  const bulkOpsVariable = uniqBy(
    [
      ...singleKeyVariables,
      ...compositeKeyVariables,
      ...autoIncrementVariables,
    ],
    "name",
  );
  const compositeAndNotKeys = difference(
    compositeKeyVariables,
    singleKeyVariables.concat(autoIncrementVariables),
  );

  // Note: The first member of this array should be synced with the form
  const [keysForBulkOps, setKeysForBulkOps] = useState([data]);

  // merge new object and existing object to check duplication
  // if the entered variable should be unique, it shouldn't exist in both objects
  const mergedMicroserviceObject = keysForBulkOps.concat(
    Object.values(instanceDataForMicroservice),
  );

  const hasDuplicatedKey =
    commandType === MicroserviceCommand.type.create &&
    keysForBulkOps.some((data, index) => {
      // Check if there is the same key for unique key variable
      const hasDuplicateSingleKey =
        Boolean(singleKeyVariables.length) &&
        singleKeyVariables.some(({ name }) => {
          const id = Variable.removePrefix(name);
          return mergedMicroserviceObject.some(
            (entry, i) => index !== i && entry[id] === data[id],
          );
        });
      // Check if there is the same key for composite key values
      const hasDuplicateCompositeKey =
        Boolean(compositeKeyVariables.length) &&
        mergedMicroserviceObject.some((entry, i) => {
          const hasDuplicate = compositeKeyVariables.every(({ name }) => {
            const id = Variable.removePrefix(name);
            return entry[id] === data[id];
          });
          return index !== i && hasDuplicate;
        });
      return hasDuplicateSingleKey || hasDuplicateCompositeKey;
    });
  // slice the first member of the array to rely validating on the non-bulk validation
  const validationErrors = keysForBulkOps.slice(1).reduce((acc, data) => {
    const error = Validation.variables(data, bulkOpsVariable);
    return { ...acc, ...error };
  }, {});

  // // // for bulk operation // // //

  const errors = [
    ...Variable.convertErrorsToDisplayName(syncErrors, variables),
    ...(hasDuplicatedKey
      ? [
          t(
            "There are duplicate Primary/Composite key values. Click 'Edit Keys' and check values.",
          ),
        ]
      : []),
    ...(!isEmpty(validationErrors)
      ? [
          t(
            "There are some errors for key values. Click 'Edit Keys' and check values.",
          ) +
            `[${Variable.convertErrorsToDisplayName(
              validationErrors,
              variables,
            )}]`,
        ]
      : []),
  ];

  const handleOnChange = (value, key, disabled) => {
    if (!checkedItems.includes(key) && !disabled) {
      setCheckedItems(checkedItems.concat(key));
    }

    let _data = cloneDeep(formValues);
    // If there is Composite type variable, some variable should change their value automatically
    // This looks up objects which can be changed automatically and set default value.
    _data = Variable.maybeChangeValueForComposite(variables, _data, key, value);
    _data = set(_data, key, value);
    dispatch(setFormValues(form, _data));
  };

  const onChangeRowsToAdd = (value) => {
    if (value < 1) return;

    if (value > keysForBulkOps.length) {
      const objectToAdd = [...Array(value - keysForBulkOps.length)].map(
        (_, index) => {
          return bulkOpsVariable.reduce((acc, variable) => {
            const { name } = variable;
            const id = Variable.removePrefix(name);
            const currentValue = formValues[id];

            const currentIndex = index + keysForBulkOps.length;
            if (
              // For non-incrementable variables (like Device), we keep the current value.
              (Variable.isNumberVariable(variable) ||
                Variable.isFreeFormatVariable(variable)) &&
              // but not increment for Composite Key
              !compositeAndNotKeys.includes(variable)
            ) {
              if (Variable.isFreeFormatVariable(variable) && currentValue) {
                // case for String like variables
                return {
                  ...acc,
                  [id]: `${currentValue}_${currentIndex}`,
                };
              }
              // case for Numeric variables
              return {
                ...acc,
                [id]: (parseInt(currentValue || 0) + currentIndex).toString(),
              };
            }
            // case for non-free-formatted variables
            return {
              ...acc,
              [id]: currentValue,
            };
          }, {});
        },
      );
      setKeysForBulkOps(keysForBulkOps.concat(objectToAdd));
    } else {
      // Remove
      setKeysForBulkOps(keysForBulkOps.slice(0, value));
    }
  };

  const validateField = (microserviceObject, data) => (value, variable) => {
    if (variable.type === "Index" && !isBulkUpdate) {
      return Validation.uniqueValue(variable, microserviceObject, data)(value);
    }
  };

  // Load the instance data for microservices into Redux Form state
  useEffect(() => {
    if (data) {
      initialize(data);
    }
  }, [initialize, data]);
  useEffect(() => {
    // When bulk operation, sync first line to the form value
    if (formValues) {
      const newValue = produce(keysForBulkOps, (draft) => {
        draft[0] = formValues;
      });
      setKeysForBulkOps(newValue);
    }
  }, [formValues, keysForBulkOps]);

  useEffect(() => {
    const validateUniqueValue = validateField(
      instanceDataForMicroservice,
      formValues,
    );
    const errors = Validation.variables(
      formValues,
      variables,
      validateUniqueValue,
    );
    dispatch(updateSyncErrors(form, errors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={onClose}
      />
      <KeyEditDialog
        title={t("Edit Keys")}
        validation={
          isEmpty(validationErrors)
            ? ""
            : Variable.convertErrorsToDisplayName(validationErrors, variables)
        }
      >
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {keyVariables.map((variable, i) => {
                return <TableCell key={i}>{variable.displayName}</TableCell>;
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {keysForBulkOps.map((row, index) => {
              return (
                <TableRow key={index}>
                  {keyVariables.map((variable, i) => {
                    const id = Variable.removePrefix(variable.name);
                    return (
                      <TableCell key={i}>
                        <ExecutionVariableField
                          id={id}
                          variable={variable}
                          data={{ ...formValues, ...row }}
                          isEditing
                          handleOnChange={(value, key) => {
                            if (index === 0) {
                              handleOnChange(value, key);
                            } else {
                              setKeysForBulkOps(
                                produce(keysForBulkOps, (draft) => {
                                  draft[index][key] = value;
                                }),
                              );
                            }
                          }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </KeyEditDialog>
      <Dialog
        maxWidth={"md"}
        onClose={dirty ? showDiscardDialog : onClose}
        onExec={() => {
          onSave(formValues, checkedItems, {
            keys: keyVariables,
            values: keysForBulkOps,
          });
        }}
        execLabel={t("Save")}
        title={
          commandType === MicroserviceCommand.type.update
            ? t("Edit Row")
            : t("Add Row")
        }
        validation={errors.join(", ")}
      >
        {shouldShowCheckbox && (
          <Grid
            container
            justifyContent="center"
            className={classes.headerAction}
          >
            <Grid item xs={10} container justifyContent="flex-start">
              <Typography variant="subtitle2">
                {t(
                  "Check Variables you want to edit. Otherwise, it keeps its value.",
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        {isBulkOperationEnabled &&
          commandType === MicroserviceCommand.type.create && (
            <Grid
              container
              justifyContent="flex-end"
              className={classes.headerAction}
            >
              <Grid item>
                <TextField
                  id={"MICROSERVICE_OBJECT_ROWS_TO_ADD_TEXT"}
                  label={t("Rows to Add")}
                  required
                  value={keysForBulkOps.length}
                  onChange={({ target: { value } }) => onChangeRowsToAdd(value)}
                  type="number"
                />
              </Grid>
              <Grid item xs={2}>
                {keysForBulkOps.length > 1 && (
                  <Button
                    id="MICROSERVICE_OBJECT_EDIT_KEYS_BUTTON"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={showKeyEditDialog}
                  >
                    <EditOutlined className={classes.editKeysIcon} />
                    {t("Edit Keys")}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          className={classes.root}
        >
          {variables.map((variable, index) => {
            const { isTable, tableSection } = Variable.getTableValData(
              variable.name,
            );

            const id = Variable.removePrefix(variable.name);
            const shouldShow = Variable.shouldShow(variable, true, formValues);

            if (!shouldShow) {
              return null;
            }

            const isChecked = checkedItems.includes(id);
            const isKeyVariable = singleKeyVariables.includes(variable);
            // disable the check box if values for composite keys can duplicate by checking
            const canBulkUpdateCompositeKey =
              variable.isCompositeKey && selectedRows.length
                ? selectedRows.every((object_id, index) => {
                    const restVariables = compositeKeyVariables.filter(
                      ({ name }) => {
                        const id = Variable.removePrefix(name);
                        return !(
                          variable.name === name || checkedItems.includes(id)
                        );
                      },
                    );

                    const hasDuplicateKeyCombination = selectedRows
                      .slice(index + 1)
                      .some((_object_id) => {
                        return restVariables.every(({ name }) => {
                          const id = Variable.removePrefix(name);

                          return (
                            instanceDataForMicroservice[object_id][id] ===
                            instanceDataForMicroservice[_object_id][id]
                          );
                        });
                      });

                    return !hasDuplicateKeyCombination;
                  })
                : true;
            const disabled =
              isKeyVariable ||
              variable.userLocked ||
              !canBulkUpdateCompositeKey;

            const onCheck = (key) => {
              if (isChecked) {
                setCheckedItems(checkedItems.filter((item) => item !== key));
              } else {
                setCheckedItems(checkedItems.concat(key));
              }
            };

            const showDivider =
              index === 0
                ? Boolean(variable.sections?.length) ??
                  Boolean(variable.groupDisplayName)
                : variable.sections?.[0] !==
                    variables[index - 1].sections?.[0] ??
                  variable.groupDisplayName !==
                    variables[index - 1].groupDisplayName;
            const groupName =
              showDivider &&
              (variable.sections?.[0] ?? variable.groupDisplayName);

            return (
              <Fragment key={index}>
                {showDivider && <Divider className={classes.divider} />}
                {groupName && (
                  <Grid
                    container
                    justifyContent="center"
                    key={`${index}.${groupName}`}
                  >
                    <Grid item xs={10} className={classes.groupName}>
                      <Typography variant="h5">{groupName}</Typography>
                    </Grid>
                  </Grid>
                )}

                {isTable ? (
                  (() => {
                    const hasBeenRendered =
                      index !== 0 &&
                      tableSection ===
                        Variable.getTableValData(variables[index - 1].name)
                          .tableSection;
                    if (hasBeenRendered) return null;
                    const { size: tableSize } = Variable.getTableSize(
                      variables,
                      index,
                      true,
                    );
                    const tableVariables = Variable.filterInvisible(
                      variables.slice(index, index + tableSize),
                    );
                    return (
                      <Grid
                        container
                        justifyContent="center"
                        key={`${index}.${variable.name}`}
                      >
                        {shouldShowCheckbox && Boolean(tableVariables.length) && (
                          <Grid
                            item
                            xs={1}
                            container
                            justifyContent={"center"}
                            className={classes.checkboxTable}
                          >
                            <Checkbox
                              checked={isChecked}
                              onClick={() => {
                                onCheck(variable.displayName);
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={10} className={classes.arrayTable}>
                          <RunTimeVariablesTable
                            variables={tableVariables}
                            editMode
                            data={formValues}
                            handleOnChange={handleOnChange}
                            currentPath={tableSection}
                            MSAVariable={ExecutionVariableField}
                          />
                        </Grid>
                      </Grid>
                    );
                  })()
                ) : (
                  <Grid container justifyContent="center" key={variable.name}>
                    {shouldShowCheckbox && shouldShow && (
                      <Tooltip
                        title={
                          canBulkUpdateCompositeKey
                            ? ""
                            : t(
                                "You cannot bulk update this field because of Composite Key constraint.",
                              )
                        }
                      >
                        <Grid
                          item
                          xs={1}
                          container
                          justifyContent={"center"}
                          className={classes.checkbox}
                        >
                          <Checkbox
                            checked={isChecked}
                            onClick={() => {
                              onCheck(id);
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                      </Tooltip>
                    )}
                    <Grid
                      item
                      xs={9}
                      container
                      justifyContent={"center"}
                      className={classes.variable}
                    >
                      <ExecutionVariableField
                        id={id}
                        variable={variable}
                        data={formValues}
                        isEditing={
                          !(
                            (shouldShowCheckbox || keysForBulkOps.length > 1) &&
                            isKeyVariable
                          )
                        }
                        handleOnChange={(key, value) =>
                          handleOnChange(key, value, disabled)
                        }
                        showLabel
                        validate={validateField(
                          instanceDataForMicroservice,
                          formValues,
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            );
          })}
        </Grid>
      </Dialog>
    </ErrorBoundary>
  );
};

MicroserviceInstanceModal.propTypes = {
  commandType: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  variables: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  instanceDataForMicroservice: PropTypes.object,
};

export default flow(reduxForm({ form }))(MicroserviceInstanceModal);
