import { ReactComponent as ProcessNotStarted } from "msa2-ui/src/assets/icons/status_process/notStartedWhite.svg";
import { ReactComponent as ProcessSuccess } from "msa2-ui/src/assets/icons/status_process/statusProcessSuccess.svg";
import { ReactComponent as ProcessInProgress } from "msa2-ui/src/assets/icons/status_process/runningWhite.svg";
import { ReactComponent as ProcessFailed } from "msa2-ui/src/assets/icons/status_process/statusProcessFailed.svg";
import { ReactComponent as ProcessWarning } from "msa2-ui/src/assets/icons/status_process/warningWhite.svg";
import { ReactComponent as ProcessPaused } from "msa2-ui/src/assets/icons/status_process/pausedWhite.svg";
import { ReactComponent as IconRunning } from "msa2-ui/src/assets/icons/status_process/running.svg";
import { ReactComponent as IconFailed } from "msa2-ui/src/assets/icons/status_process/failed.svg";
import { ReactComponent as IconNotStarted } from "msa2-ui/src/assets/icons/status_process/notStarted.svg";
import { ReactComponent as IconPaused } from "msa2-ui/src/assets/icons/status_process/paused.svg";
import { ReactComponent as IconSuccess } from "msa2-ui/src/assets/icons/status_process/success.svg";
import { ReactComponent as IconWarning } from "msa2-ui/src/assets/icons/status_process/warning.svg";

import { ReactComponent as UnreachableIcon } from "msa2-ui/src/assets/icons/status/statusUnreachable.svg";
import { ReactComponent as CriticalIcon } from "msa2-ui/src/assets/icons/status/statusCritical.svg";
import { ReactComponent as NeverReachedIcon } from "msa2-ui/src/assets/icons/status/statusNeverReached.svg";
import { ReactComponent as ConnectedIcon } from "msa2-ui/src/assets/icons/status/statusConnected.svg";

export const sortOrder = [
  {
    id: "-",
    param: "DESC",
    text: "desc",
  },
  {
    id: "+",
    param: "ASC",
    text: "asc",
  },
];

export const bpmStatus = [
  {
    id: "running",
    name: "Running",
    icon: ProcessInProgress,
    status: "RUNNING",
    color: "#617bab",
    barSize: 10,
    filters: { withIncidents: false, active: true },
  },
  {
    id: "fail",
    name: "Failed",
    icon: ProcessFailed,
    status: "FAIL",
    color: "#db2e14",
    barSize: 10,
    isResult: true,
    filters: { withIncidents: true, incidentType: "failedExternalTask" },
  },
  {
    id: "ended",
    name: "Success",
    icon: ProcessSuccess,
    status: "ENDED",
    color: "#26bd6f",
    barSize: 2,
    isResult: true,
    filters: { completed: true },
  },
];

export const BPM_INSTANCE_STATUS = {
  COMPLETED: "COMPLETED",
  ACTIVE: "ACTIVE",
  EXTERNALLY_TERMINATED: "EXTERNALLY_TERMINATED",
};

export const bpmInstanceStatus = [
  {
    id: "completed",
    name: "Completed",
    icon: ProcessSuccess,
    status: BPM_INSTANCE_STATUS.COMPLETED,
    color: "#26bd6f",
    barSize: 2,
    isResult: true,
    filters: { completed: true },
  },
  {
    id: "active",
    name: "Active",
    icon: ProcessInProgress,
    status: BPM_INSTANCE_STATUS.ACTIVE,
    color: "#617bab",
    barSize: 2,
    isResult: true,
    filters: { completed: true },
  },
  {
    id: "terminated",
    name: "Terminated",
    icon: ProcessFailed,
    status: BPM_INSTANCE_STATUS.EXTERNALLY_TERMINATED,
    color: "#db2e14",
    barSize: 2,
    isResult: true,
    filters: { completed: true },
  },
];

export const workflowActions = {
  PAUSE: {
    displayName: "Pause",
    delegationName: "instance.pause",
  },
  RESTART: {
    displayName: "Continue",
    delegationName: "instance.continue",
  },
  RESUME: {
    displayName: "Retry",
    delegationName: "instance.retry",
  },
  STOP: {
    displayName: "Stop",
    delegationName: "instance.stop",
  },
};

export const WORKFLOW_STATUS = {
  NONE: {
    id: "none",
    name: "Not Started",
    icon: ProcessNotStarted,
    iconBig: IconNotStarted,
    status: "NONE",
    color: "#bfc9d9",
    barSize: 10,
    actions: [],
  },
  RUNNING: {
    id: "running",
    name: "Running",
    icon: ProcessInProgress,
    iconBig: IconRunning,
    status: "RUNNING",
    color: "#617bab",
    barSize: 10,
    actions: ["PAUSE", "STOP"],
  },
  FAIL: {
    id: "fail",
    name: "Failed",
    icon: ProcessFailed,
    iconBig: IconFailed,
    status: "FAIL",
    color: "#db2e14",
    barSize: 10,
    isResult: true,
    actions: ["RESUME"],
  },
  WARNING: {
    id: "warning",
    name: "Warning",
    icon: ProcessWarning,
    iconBig: IconWarning,
    status: "WARNING",
    color: "#f5b622",
    barSize: 10,
    isResult: true,
    actions: [],
  },
  PAUSE: {
    id: "pause",
    name: "Paused",
    icon: ProcessPaused,
    iconBig: IconPaused,
    status: "PAUSE",
    color: "#80a2d9",
    barSize: 10,
    actions: ["RESTART"],
  },
  ENDED: {
    id: "ended",
    name: "Success",
    icon: ProcessSuccess,
    iconBig: IconSuccess,
    status: "ENDED",
    color: "#26bd6f",
    barSize: 2,
    isResult: true,
    actions: [],
  },
};
export const workflowStatus = Object.values(WORKFLOW_STATUS);

export const provisioningStatus = {
  RUNNING: {
    id: "running",
    text: "In Progress",
    Icon: ProcessInProgress,
    status: "RUNNING",
    color: "#617bab",
  },
  OK: {
    id: "ok",
    text: "Success",
    Icon: ProcessSuccess,
    status: "OK",
    color: "#26bd6f",
  },
  FAIL: {
    id: "fail",
    text: "Failed",
    Icon: ProcessFailed,
    status: "FAIL",
    color: "#db2e14",
  },
  NONE: {
    id: "none",
    text: "Not Started",
    Icon: ProcessNotStarted,
    status: "NONE",
    color: "#bfc9d9",
  },
};

export const defaultRowsPerPageOptions = [20, 50, 100];

export const natures = {
  PHSL: "Physical",
  VPUB: "Public Virtual",
  VPRV: "Private Virtual",
};

export const variablePrefix = "params";

export const statusTypes = {
  SUCCEEDED: "ENDED",
  FAILED: "FAIL",
  IN_PROGRESS: "RUNNING",
  NOT_STARTED: "NONE",
  WARNING: "WARNING",
  PAUSE: "PAUSE",
};

export const topologyWFPath = "Process/Topology/Topology";

export const entityTypes = {
  me: {
    id: "me",
    displayName: "Managed entity",
  },
  bpm: {
    id: "bpm",
    displayName: "BPM",
  },
  microservices: {
    id: "microservices",
    displayName: "Microservice",
  },
  workflows: {
    id: "workflows",
    displayName: "Workflow",
  },
};

export const reduxFormNames = {
  monitoringProfileForm: "monitoringProfileForm",
  aiParametersForm: "aiParametersForm",
  aiCreateActionDialog: "aiCreateActionDialog",
  profileForm: "profileForm",
  changePasswordForm: "changePasswordForm",
};

export const MSA_VARS = {
  UBI_LDAP_ENABLE: "UBI_LDAP_ENABLE",
  UBI_VALIDATION_MAPPING: "UBI_VALIDATION_MAPPING",
  UBI_API_UI_KEY: "UBI_API_UI_KEY",
  UI_SETTINGS: "UI_SETTINGS",
};

export const rootUser = "ncroot";

export const userRoles = {
  PRIVILEGED_ADMINISTRATOR: 1,
  ADMINISTRATOR: 2,
  PRIVILEGED_MANAGER: 3,
  MANAGER: 4,
  SUBTENANT: 5,
  SITE: 6,
  READ_ONLY_USER: 7,
};

export const managedEntityStatus = {
  OK: {
    color: "#26BD6F",
    icon: ConnectedIcon,
    label: "Connected",
    status: "OK",
  },
  NEVERREACHED: {
    color: "#B1BBCC",
    icon: NeverReachedIcon,
    label: "Never Reached",
    status: "NEVERREACHED",
  },
  CRITICAL: {
    color: "#F5B622",
    icon: CriticalIcon,
    label: "Critical",
    status: "CRITICAL",
  },
  ERROR: {
    color: "#DB2E14",
    icon: UnreachableIcon,
    label: "Unreachable",
    status: "ERROR",
  },
  null: {
    color: "#B1BBCC",
    icon: NeverReachedIcon,
    label: "Never Reached",
    status: "NEVERREACHED",
  },
};

export const TABLE_HEADER_COLUMNS = {
  alarms: [
    {
      id: "check",
      name: "",
      align: "left",
      width: "8%",
      active: false,
      check: true,
    },
    {
      id: "rule",
      name: "Alarm Name",
      align: "left",
      width: "15%",
      active: false,
      check: true,
    },
    {
      id: "rule_desc",
      name: "Alarm Description",
      width: "15%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "_id",
      name: "Alarm ID",
      width: "8%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "ack",
      name: "Acknowledged",
      width: "8%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "date",
      name: " Created Time",
      width: "12%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "message",
      name: "Message",
      width: "12%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "severity",
      name: "Severity",
      align: "left",
      active: false,
      width: "10%",
      check: true,
    },
    {
      id: "device_id",
      name: "Managed Entity ID",
      width: "10%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "device_name",
      name: "Managed Entity Name",
      width: "10%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "device_ref",
      name: "Managed Entity Ref",
      width: "8%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "device_mgmt_ip",
      name: "Managed Entity IP",
      width: "12%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "comment",
      name: "Comment",
      align: "left",
      width: "12%",
      active: false,
      check: false,
    },
    {
      id: "tenant_id",
      name: "Tenant ID",
      width: "8%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "customer_id",
      name: "Subtenant ID",
      width: "8%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "_index",
      name: "Alarm Index",
      width: "8%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "details",
      name: "",
      align: "right",
      width: "10%",
      active: false,
      check: true,
    },
  ],
  logs: [
    {
      id: "date",
      name: "Timestamp",
      align: "left",
      width: "12%",
      active: false,
      check: true,
    },
    {
      id: "message",
      name: "Message",
      width: "100%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "severity",
      name: "Severity",
      align: "left",
      width: "9%",
      active: false,
      check: true,
    },
    {
      id: "device_id",
      name: "Managed Entity ID",
      align: "left",
      width: "9%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "device_name",
      name: "Managed Entity Name",
      align: "left",
      width: "12%",
      active: false,
      check: true,
    },
    {
      id: "device_mgmt_ip",
      name: "Managed Entity IP",
      align: "left",
      width: "12%",
      active: false,
      check: true,
    },
    {
      id: "customer_ref",
      name: "Subtenant Ref",
      align: "left",
      width: "7%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "type",
      name: "Type",
      align: "left",
      width: "5%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "subtype",
      name: "Subtype",
      align: "left",
      width: "5%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "rule",
      name: "Rule",
      align: "left",
      width: "9%",
      active: false,
      check: false,
    },
    {
      id: "man_id",
      name: "Vendor ID",
      align: "left",
      width: "10%",
      active: false,
      check: false,
    },
    {
      id: "dst_ip",
      name: "Destination IP",
      align: "left",
      width: "12%",
      active: false,
      check: false,
    },
    {
      id: "src_ip",
      name: "Source IP",
      align: "left",
      width: "12%",
      active: false,
      check: false,
    },
    {
      id: "device_hostname",
      name: "Hostname",
      align: "left",
      width: "12%",
      active: false,
      check: false,
    },
    {
      id: "protocol",
      name: "Protocol",
      width: "12%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "mod_id",
      name: "Model ID",
      align: "left",
      width: "12%",
      active: false,
      check: false,
    },
    {
      id: "dst_port",
      name: "Destination Port",
      width: "12%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "tenant_id",
      name: "Tenant ID",
      width: "12%",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "customer_id",
      name: "Subtenant ID",
      width: "12%",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "details",
      name: "",
      align: "right",
      width: 80,
      active: false,
      check: true,
    },
  ],
  managedEntityLogs: [
    {
      id: "date",
      name: "Timestamp",
      align: "left",
      width: "12%",
      active: false,
      check: true,
    },
    {
      id: "message",
      name: "Message",
      align: "left",
      active: false,
      check: true,
    },
    {
      id: "severity",
      name: "Severity",
      align: "left",
      width: "9%",
      active: false,
      check: true,
    },
    {
      id: "device_id",
      name: "Managed Entity ID",
      align: "left",
      width: "9%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "customer_ref",
      name: "Subtenant Ref",
      align: "left",
      width: "7%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "type",
      name: "Type",
      align: "left",
      width: "5%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "subtype",
      name: "Subtype",
      align: "left",
      width: "5%",
      active: false,
      onlyOnWideScreen: true,
      check: true,
    },
    {
      id: "rule",
      name: "Rule",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "man_id",
      name: "Vendor ID",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "dst_ip",
      name: "Destination IP",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "src_ip",
      name: "Source IP",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "device_hostname",
      name: "Hostname",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "protocol",
      name: "Protocol",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "mod_id",
      name: "Model ID",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "dst_port",
      name: "Destination Port",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "customer_id",
      name: "Subtenant ID",
      align: "left",
      active: false,
      check: false,
    },
    {
      id: "details",
      name: "",
      align: "right",
      width: 80,
      active: false,
      check: true,
    },
  ],
};

export const GREEN_REGIONS = {
  aws_region: {
    link:
      "https://sustainability.aboutamazon.com/environment/the-cloud?energyType=true#renewable-energy-map",
  },
  azure_region: {
    link:
      "https://infrastructuremap.microsoft.com/powering-sustainable-transformation",
  },
  gcp_region: {
    link: "https://cloud.withgoogle.com/region-picker/",
  },
  scaleway_region: {
    link: "https://www.scaleway.com/en/environmental-leadership/",
  },
};

export const LOG_EVENT_TYPES = {
  syslog: {
    value: "syslog",
    label: "Syslogs",
  },
  trap: {
    value: "trap",
    label: "SNMP Traps",
  },
  event: {
    value: "event",
    label: "VNOC",
  },
};

export const incidentTicketStatus = [
  {
    id: "open",
    name: "Open",
    status: "OPEN",
  },
  {
    id: "closed",
    name: "Closed",
    status: "CLOSED",
  },
];

export const DETAIL_PROPERTIES_TO_BE_FILTERED = [
  "_timestamp_",
  "_timestamp_epoch_",
];

export const REPOSITORY_FOLDERS = {
  DATAFILES: "Datafiles",
  DOCUMENTATION: "Documentation",
  FIRMWARE: "Firmware",
  LICENSE: "License",
  PKI: "Pki",
};
