import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

const DeleteWorkflowIcon = ({ isEnabled, onClick }) => {
  const { t } = useTranslation();

  const button = (
    <IconButton
      aria-label={t("Delete")}
      onClick={onClick}
      disabled={!isEnabled}
      data-testid="button-wrap"
    >
      <DeleteIcon color={isEnabled ? "error" : "disabled"} />
    </IconButton>
  );

  if (!isEnabled) {
    return (
      <Tooltip
        data-testid="tooltip"
        title={t("You must detach all subtenants before deleting")}
      >
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};

DeleteWorkflowIcon.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DeleteWorkflowIcon;
