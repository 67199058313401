import React from "react";
import Field from "redux-form/lib/Field";
import Radio from "msa2-ui/src/components/connectedFormComponents/Radio";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  formLabel: {
    ...theme.typography.h5,
  },
}));

const FormRadio = (props) => {
  const {
    name,
    options,
    className,
    label,
    onChange,
    disabled = false,
    required = false,
  } = props;
  const classes = useStyles();
  return (
    <Field
      name={name}
      id={name}
      label={label}
      options={options}
      component={Radio}
      className={classnames(className, classes.fullWidth)}
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
  );
};

export default FormRadio;
