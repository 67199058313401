import React, { useCallback, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  deleteMonitoringProfileById,
  getMonitoringProfiles,
  downloadMonitoringProfile,
  uploadMonitoringProfile,
} from "msa2-ui/src/api/monitoringProfiles";
import { useSelector } from "react-redux";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { Paper } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useFilter from "msa2-ui/src/hooks/useFilter";
import { sortOrder } from "msa2-ui/src/Constants";
import AttachDialog from "./attach-dialog/AttachDialog";
import OverviewTable from "./overview-table/OverviewTable";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";
import { buildRoute } from "msa2-ui/src/utils/urls";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import AttachedManagedEntitiesDialog from "./AttachedManagedEntitiesDialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const TABLE_COLUMNS = {
  name: {
    id: "profile_name",
    name: "Name",
    align: "left",
    sortable: true,
  },
  dateModified: {
    id: "last_updated",
    name: "Date Modified",
    align: "center",
    sortable: true,
  },
  managedEntities: {
    id: "managed_entities",
    name: "Managed Entities",
    align: "center",
    sortable: false,
  },
  actions: {
    id: "actions",
    name: "",
    align: "right",
    sortable: false,
  },
};

const TABLE_COLUMNS_LIST = Object.values(TABLE_COLUMNS);

const MonitoringProfiles = ({ sectionTabs: SectionTabs }) => {
  const [
    profileToShowManagedEntities,
    setProfileToShowManagedEntities,
  ] = useState(null);
  const [profileToAttach, setProfileToAttach] = useState(null);
  const [profileToDelete, setProfileToDelete] = useState();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const commonClasses = useCommonStyles();
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const token = useSelector(getToken);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const inputRefForFileUpload = useRef(null);

  const subtenantId = useSelector(getSelectedSubtenant).id;

  const [filterState, filterActions] = useFilter({
    tableName: "monitoringProfiles",
    searchValue: "",
    sortByValue: TABLE_COLUMNS.dateModified.id,
    sortOrderValue: 0,
    tpPage: 0,
  });

  const setReferenceForUpload = () => {
    inputRefForFileUpload.current.click();
  };

  const [
    isLoading,
    isError,
    monitoringProfiles = [],
    monitoringProfilesMeta = {},
    reloadMonitoringProfiles,
  ] = useApi(
    getMonitoringProfiles,
    {
      subtenantId,
      filter: "profile_name",
      filterCriteria: filterState.searchValue,
      page: filterState.tpPage + 1,
      pageSize: filterState.tpRowsPerPage,
      sort: filterState.sortByValue,
      sortOrder: sortOrder[filterState.sortOrderValue].param,
    },
    !subtenantId,
  );

  const monitoringProfilesTotalCount = subtenantId
    ? parseInt(monitoringProfilesMeta.mon_total)
    : 0;

  const editLinkBuilder = useCallback(
    (profileId) => {
      return buildRoute(url, `${profileId}/edit`);
    },
    [url],
  );

  const openAttachDialogHandler = useCallback(
    (profileId) => {
      setProfileToAttach(profileId);
    },
    [setProfileToAttach],
  );

  const closeAttachDialogHandler = useCallback(
    (shouldReload) => {
      setProfileToAttach(null);
      shouldReload && reloadMonitoringProfiles();
    },
    [reloadMonitoringProfiles, setProfileToAttach],
  );

  const openAttachedManagedEntitiesDialog = useCallback(
    (profile) => {
      setProfileToShowManagedEntities(profile);
    },
    [setProfileToShowManagedEntities],
  );

  const closeAttachedManagedEntitiesDialog = useCallback(() => {
    setProfileToShowManagedEntities(null);
    reloadMonitoringProfiles();
  }, [reloadMonitoringProfiles, setProfileToShowManagedEntities]);

  const openDeleteDialogHandler = useCallback(
    (profileId) => {
      setProfileToDelete(profileId);
      showDeleteDialog();
    },
    [showDeleteDialog],
  );

  const deleteMonitoringProfileHandler = useCallback(async () => {
    const [error] = await deleteMonitoringProfileById({
      profileId: profileToDelete.id,
      token,
    });

    if (error) {
      enqueueSnackbar(
        error.getMessage(t("Unable to delete x", { x: profileToDelete.name })),
        {
          variant: "error",
        },
      );
    } else {
      enqueueSnackbar(t("x has been deleted", { x: profileToDelete.name }), {
        variant: "success",
      });
    }

    reloadMonitoringProfiles();
    setProfileToDelete(null);
  }, [enqueueSnackbar, profileToDelete, reloadMonitoringProfiles, t, token]);

  const downloadMonitoringProfileHandler = async (name, profileId) => {
    await downloadMonitoringProfile({
      name,
      profileId: profileId,
      token,
      onError: () => {
        enqueueSnackbar(t("Unable to download x", { x: name }), {
          variant: "error",
        });
      },
    });
  };

  const handleFileUpload = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;
    uploadMonitoringProfileHandler(fileObj);
  };

  const uploadMonitoringProfileHandler = async (fileObj) => {
    const formData = new FormData();
    formData.append("file", fileObj);
    const [error, response] = await uploadMonitoringProfile({
      customerId: subtenantId,
      file: formData,
      token,
    });
    if (error || response?.errorCode) {
      const message = error
        ? error.getMessage(t("Error uploading file"))
        : response.message;
      enqueueSnackbar(message, {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    } else {
      enqueueSnackbar(t("File uploaded successfully"), {
        variant: "success",
      });
      reloadMonitoringProfiles();
    }
  };

  const MENU = [
    {
      id: "MONITORING_PROFILE_BTN_UPLOAD",
      disabled: !subtenantId,
      onClick: setReferenceForUpload,
      delegationProfileAction: "general.create",
      delegationProfileType: delegationProfileTypes.MONITORING_PROFILES,
      tooltipTitle: subtenantId ? "" : t("Please choose a subtenant"),
      label: "Upload Monitoring Profile",
    },
  ];

  return (
    <div>
      <div>
        <input
          style={{ display: "none" }}
          ref={inputRefForFileUpload}
          data-testid="monitoringProfileUpload"
          type="file"
          onChange={handleFileUpload}
        />
      </div>
      {Boolean(profileToAttach) && (
        <AttachDialog
          profileId={profileToAttach.id}
          profileName={profileToAttach.name}
          subtenantId={subtenantId}
          onClose={closeAttachDialogHandler}
        />
      )}
      {Boolean(profileToShowManagedEntities) && (
        <AttachedManagedEntitiesDialog
          profileName={profileToShowManagedEntities.name}
          profileId={profileToShowManagedEntities.id}
          subtenantId={subtenantId}
          onClose={closeAttachedManagedEntitiesDialog}
        />
      )}
      <DeleteDialog
        content={t("Are you sure you want to delete x?", {
          x: profileToDelete?.name,
        })}
        title={t("Delete x?", { x: "Monitoring Profile" })}
        onExec={deleteMonitoringProfileHandler}
      />
      <SectionTabs
        count={[path, monitoringProfilesTotalCount]}
        endElements={
          <CreateEntityButton
            id="MONITORING_PROFILE_BTN_CREATE"
            disabled={!subtenantId}
            link={buildRoute(url, "create")}
            delegationProfileAction="general.create"
            delegationProfileType={delegationProfileTypes.MONITORING_PROFILES}
            tooltipTitle={subtenantId ? "" : t("Please choose a subtenant")}
            size="medium"
            additionalOptions={MENU}
          >
            {t("Create Monitoring Profile")}
          </CreateEntityButton>
        }
      />
      <Paper
        className={classNames([
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        ])}
      >
        <OverviewTable
          t={t}
          columns={TABLE_COLUMNS_LIST}
          editLinkBuilder={editLinkBuilder}
          isError={isError}
          isLoading={isLoading}
          subtenantId={subtenantId}
          filterState={filterState}
          filterActions={filterActions}
          monitoringProfiles={monitoringProfiles || []}
          monitoringProfilesTotalCount={monitoringProfilesTotalCount}
          onAttachClick={openAttachDialogHandler}
          onDownloadClick={downloadMonitoringProfileHandler}
          onDeleteClick={openDeleteDialogHandler}
          onAttachedManagedEntitiesClick={openAttachedManagedEntitiesDialog}
        />
      </Paper>
    </div>
  );
};

MonitoringProfiles.propTypes = {
  sectionTabs: PropTypes.func.isRequired,
};

export default MonitoringProfiles;
