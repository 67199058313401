import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFormValues } from "msa2-ui/src/store/form";

import { Grid, Typography } from "@material-ui/core";

import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import Editor from "msa2-ui/src/components/connectedFormComponents/Editor";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import VisibleField from "./VisibleField";

const CreateCommandForm = ({
  form,
  commandIndex,
  submitting,
  isVisible,
  onChange,
}) => {
  const { t } = useTranslation();
  const configTypeFromForm = useSelector(
    getFormValues(form, MicroserviceForm.fields.configType),
  );

  return (
    <FormSection title={t("Command to run for Create")} loading={submitting}>
      {["xml", "json"].includes(configTypeFromForm) && (
        <>
          <Grid item xs={12}>
            <FieldSelector
              label={t("Rest Command")}
              type="text"
              name={["command", commandIndex, "rest"].join(".")}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldSelector
              label={
                configTypeFromForm === "json"
                  ? t("Endpoint")
                  : t("XPATH Command")
              }
              type="text"
              name={["command", commandIndex, "xpath"].join(".")}
              onChange={onChange}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography>{t("Microservice Configuration")}</Typography>
        <VisibleField
          visible={isVisible}
          aria-label={t("Create Command Text area")}
          name={["command", commandIndex, "operation"].join(".")}
          component={Editor}
          mode={Editor.mode.smarty}
          onChange={onChange}
        />
      </Grid>
    </FormSection>
  );
};

CreateCommandForm.propTypes = {
  form: PropTypes.string.isRequired,
  commandIndex: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default CreateCommandForm;
