import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import NumberFormat from "react-number-format";
import { useSnackbar } from "notistack";
import { downloadArchive } from "msa2-ui/src/api/repository";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import Repository from "msa2-ui/src/services/Repository";

import { filter } from "msa2-ui/src/utils/filter";
import { buildRoute } from "msa2-ui/src/utils/urls";
import { getIsDeveloper, getToken } from "msa2-ui/src/store/auth";
import { sortOrder, workflowStatus } from "msa2-ui/src/Constants";
import { detachServices } from "msa2-ui/src/api/workflow";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  Chip,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Create as EditIcon,
  Timeline as WorkflowIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import AlertBar from "msa2-ui/src/components/AlertBar";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import useDialog from "msa2-ui/src/hooks/useDialog";

const useStyles = makeStyles(({ typography }) => ({
  tableInstances: {
    fontSize: "1.3rem",
    fontWeight: typography.fontWeightRegular,
  },
  barChartAlign: {
    top: 15,
  },
  tableIconsMinWidth: {
    width: 120,
  },
  tableLink: {
    textDecoration: "none",
    color: "currentColor",
    "&:active, &:visited": {
      color: "currentColor",
    },
  },
}));

const tableHeader = [
  {
    id: "displayname",
    name: "Workflow Name",
    align: "left",
    active: true,
  },
  {
    id: "timestamp",
    name: "Date Modified",
    align: "left",
    active: true,
  },
  {
    id: "total",
    name: "Instances",
    align: "right",
    active: false,
  },
  {
    id: "blank1",
    name: "",
    align: "left",
    active: false,
  },
  {
    id: "blank2",
    name: "",
    align: "left",
    active: false,
    width: 160,
  },
];

const AutomationTable = ({
  workflows,
  workflowsLoading,
  workflowReports,
  workflowsCount,
  filterState,
  filterActions,
  apiError,
  reloadWorkflows,
  onAttachDialogClose,
  isWorkflowsOwnerDetailsEnabled,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState(null);
  const [alertBar, setAlertBar] = useState(false);
  const [searchString, setSearchString] = useState("");
  const isDeveloper = useSelector(getIsDeveloper);

  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [workflowToDetach, setWorkflowToDetach] = useState(null);
  const { ubiqubeId } = useSelector(getSelectedSubtenant);

  useEffect(() => {
    setAlertBar(Boolean(apiError));
  }, [apiError]);

  const workflowList = filter(workflows, searchString, ["displayName"]);
  const getState = () => {
    if (workflowsLoading) return "loading";
    if (alertBar) return "error";
    if (workflowList.length === 0) return "noResults";
    return "ready";
  };

  const handleDetach = async () => {
    if (!workflowToDetach) {
      return;
    }

    const { path } = workflowToDetach;
    const [error] = await detachServices({
      uri: `${path}.xml`,
      token,
      ubiqubeId,
    });

    let variant = "success";
    let message = t("x has been detached", { x: t("Workflow") });

    if (error) {
      variant = "error";
      message = error.getMessage(t("Unable to detach x", { x: t("Workflow") }));
    }

    reloadWorkflows();
    setWorkflowToDetach(null);
    enqueueSnackbar(message, { variant });
  };

  return (
    <Paper
      className={[
        commonClasses.commonPaper,
        commonClasses.commonPaperNoPadding,
      ].join(" ")}
    >
      <>
        <Table>
          <colgroup>
            {tableHeader.map((header) => (
              <col key={header.id} style={{ width: header.width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={tableHeader.length}
                className={commonClasses.commonTableCell}
              >
                <FilterMenu
                  id="AUOMATION_TABLE_FILTER_MENU"
                  tpTotal={workflowsCount || 0}
                  {...filterState}
                  handleSearchByChange={setSearchString}
                  searchValue={searchString}
                  tpChangePage={filterActions.tpChangePage}
                  tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {tableHeader.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {header.active ? (
                    <TableSortLabel
                      id={`AUTOMATION_TABLE_SORT_${header.id}`}
                      active={filterState.sortBy === header.id}
                      direction={sortOrder[filterState.sortOrderValue].text}
                      onClick={() => {
                        filterActions.handleSortByOrderChange();
                        filterActions.handleSortByValueChange(header.id);
                      }}
                    >
                      {t(header.name)}
                    </TableSortLabel>
                  ) : (
                    t(header.name)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {getState() === "error" && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={tableHeader.length}>
                  <AlertBar
                    message={t("Unable to load x", { x: t("Workflows") })}
                    refreshFnc={reloadWorkflows}
                    closeFnc={() => setAlertBar(false)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {getState() === "loading" && (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={tableHeader.length}
                  className={commonClasses.commonLoaderWrapperTable}
                >
                  <Grid container justifyContent="center" alignContent="center">
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {getState() === "noResults" && (
            // if api returns no data
            <TableBody>
              <TableRow>
                <TableCell colSpan={tableHeader.length}>
                  <Typography>{t("0 Results Returned")}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {getState() === "ready" && (
            <TableBody>
              {workflowList.map((workflow, i) => {
                const { path: id, displayName, permission } = workflow;
                const report =
                  workflowReports.find(
                    (workflowReport) => workflowReport.name === displayName,
                  ) ?? {};

                return (
                  <TableRow
                    id={`AUTOMATION_TABLE_ROW_${i}`}
                    key={id}
                    onMouseLeave={setSelectedRow}
                    onMouseEnter={() => setSelectedRow(id)}
                    onClick={() => setSelectedRow(id)}
                    classes={{ root: commonClasses.commonTableRow }}
                    className={commonClasses.commonTableRowHighlight}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <Grid
                        id={`AUTOMATION_TABLE_CELL_WORKFLOW_NAME_${i}`}
                        className={classnames(
                          commonClasses.commonTableCellClickable,
                          classes.tableLink,
                        )}
                        to={`${pathname}/${encodeURIComponent(id)}/instances`}
                        component={Link}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className={commonClasses.commonTableAvatar}>
                          <WorkflowIcon />
                        </Avatar>
                        <Typography
                          variant="h4"
                          className={commonClasses.commonTablePrimary}
                        >
                          {workflow.displayName}
                        </Typography>
                        <Hidden mdDown>
                          {workflow.group &&
                            workflow.group
                              .split("|")
                              .map((group, i) => (
                                <Chip
                                  key={i}
                                  value="primary"
                                  label={group}
                                  color="primary"
                                  className={[
                                    commonClasses.commonTags,
                                    commonClasses.commonTagSystem1,
                                  ].join(" ")}
                                />
                              ))}
                        </Hidden>
                      </Grid>
                    </TableCell>
                    <TableCell className={commonClasses.commonTableCellDefault}>
                      <Typography
                        variant="body1"
                        className={commonClasses.commonTableSecondary}
                      >
                        {displayMonthDayYearTimeDate(workflow.timestamp)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <Typography
                        variant="h4"
                        className={classes.tableInstances}
                      >
                        <NumberFormat
                          value={report.total ?? "-"}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </Typography>
                      <Typography
                        variant="body2"
                        className={commonClasses.commonTableSecondary}
                      >
                        {t("Instances")}
                      </Typography>
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell
                        className={commonClasses.commonTableCellDefault}
                      >
                        <BarChart
                          width={300}
                          height={50}
                          data={[report.status]}
                          layout="vertical"
                          className={classes.barChartAlign}
                        >
                          <XAxis
                            type="number"
                            tick={false}
                            tickCount={0}
                            axisLine={false}
                          />
                          <YAxis
                            type="category"
                            tick={false}
                            axisLine={false}
                          />
                          {workflowStatus.map((status) => (
                            <Bar
                              key={status.id}
                              radius={5}
                              maxBarSize={status.barSize}
                              dataKey={status.status}
                              fill={status.color}
                              stackId="instances"
                            />
                          ))}
                        </BarChart>
                      </TableCell>
                    </Hidden>
                    <TableCell className={commonClasses.commonTableCellDefault}>
                      {selectedRow === id && isDeveloper && (
                        <>
                          {((isWorkflowsOwnerDetailsEnabled &&
                            permission.modify) ||
                            !isWorkflowsOwnerDetailsEnabled) && (
                            <>
                              <DelegationProfiles
                                type={delegationProfileTypes.WORKFLOWS}
                                action="general.modify"
                              >
                                <Link
                                  id="AUTOMATION_DETAILS_BTN_EDIT"
                                  to={buildRoute(
                                    pathname,
                                    `${encodeURIComponent(workflow.path)}/edit`,
                                  )}
                                >
                                  <IconButton>
                                    <EditIcon color="primary" />
                                  </IconButton>
                                </Link>
                              </DelegationProfiles>

                              <IconButton
                                id={`AUTOMATION_DETAILS_BTN_DOWNLOAD_${workflow.path}`}
                                aria-label={t("Download")}
                                onClick={() => {
                                  downloadArchive({
                                    path: workflow.path + ".xml",
                                    token,
                                    fileName:
                                      Repository.getFilenameFromUri(
                                        workflow.path,
                                      ) + ".zip",
                                    onError: (e) => {
                                      enqueueSnackbar(
                                        t("Unable to download contents"),
                                        {
                                          variant: "error",
                                        },
                                      );
                                    },
                                  });
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                              <IconButton
                                id={`AUTOMATION_DETAILS_BTN_DETACH_${workflow.path}`}
                                aria-label={t("Detach")}
                                onClick={() => {
                                  setWorkflowToDetach(workflow);
                                  showDeleteDialog();
                                }}
                              >
                                <DeleteIcon color={"error"} />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </>
      <DeleteDialog
        title={t("Detach x?", { x: t("Workflow") })}
        content={t("Are you sure you want to detach x?", {
          x: workflowToDetach?.displayName || t("Workflow"),
        })}
        onExec={handleDetach}
      />
    </Paper>
  );
};

AutomationTable.propTypes = {
  workflows: PropTypes.array,
  workflowsReport: PropTypes.array,
  workflowsCount: PropTypes.number,
  filterState: PropTypes.object,
  filterActions: PropTypes.object,
  apiError: PropTypes.object,
  reloadWorkflows: PropTypes.func,
};

export default AutomationTable;
