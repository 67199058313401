import React, { useCallback } from "react";
import { Link, useRouteMatch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { fetchTenants } from "msa2-ui/src/store/designations";

import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";
import useActiveRoute from "msa2-ui/src/hooks/useActiveRoute";

import { getTenants } from "msa2-ui/src/api/tenant";
import { sortOrder } from "msa2-ui/src/Constants";

import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Button,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import TenantTableBody from "./TenantTableBody";
import Create from "./Create";
import Edit from "./Edit";

const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    active: true,
  },
  {
    id: "prefix",
    name: "Prefix",
    align: "left",
    active: true,
  },
  {
    id: "id",
    name: "ID",
    align: "left",
    active: false,
  },
  {
    id: "lastUpdated",
    name: "Date Modified",
    align: "left",
    active: true,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    active: false,
  },
];

const Tenants = ({ adminTabs: AdminTabs }) => {
  const commonClasses = useCommonStyles();
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filterState, filterActions] = useFilter({
    tableName: "admin",
    sortByValue: "lastUpdated",
    sortOrderValue: 0,
    searchValue: "",
    tpPage: 0,
  });

  const [loading, error, tenants = [], meta, reloadTenantList] = useApi(
    getTenants,
    {
      sortOrder: sortOrder[filterState.sortOrderValue].param,
      sort: filterState.sortByValue,
      filterCriteria: filterState.searchValue,
      filter: TABLE_HEADER_COLUMNS[0].id,
      page: filterState.tpPage + 1,
      pageSize: filterState.tpRowsPerPage,
    },
  );

  const total = meta?.total_operators_count || 0;

  const reloadTenants = useCallback(() => {
    reloadTenantList();
    dispatch(fetchTenants());
  }, [reloadTenantList, dispatch]);

  useActiveRoute(reloadTenants);

  return (
    <>
      <AdminTabs
        currentCount={parseInt(total, 10)}
        endElements={
          <Button
            id="TENANT_BTN_CREATE_LINK"
            variant="contained"
            color="primary"
            aria-label={`${t("Create")} ${t("Tenant")}`}
            component={Link}
            to={{
              pathname: `${url}/create`,
            }}
          >
            <IconPlus className={commonClasses.commonBtnIcon} />
            {`${t("Create")} ${t("Tenant")}`}
          </Button>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={TABLE_HEADER_COLUMNS.length}
                    className={commonClasses.commonTableCell}
                  >
                    <FilterMenu
                      {...filterState}
                      searchValue={filterState.searchValue}
                      handleSearchByChange={filterActions.handleSearchByChange}
                      tpChangePage={filterActions.tpChangePage}
                      tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
                      tpTotal={parseInt(total, 10)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                    <TableCell
                      key={tableHeaderColumn.id}
                      align={tableHeaderColumn.align}
                      className={commonClasses.commonTableCellDefault}
                    >
                      {tableHeaderColumn.active ? (
                        <TableSortLabel
                          id={`TENANTS_TABLE_SORT_${tableHeaderColumn.id}`}
                          active={
                            filterState.sortByValue === tableHeaderColumn.id
                          }
                          direction={sortOrder[filterState.sortOrderValue].text}
                          onClick={() => {
                            filterActions.handleSortByOrderChange();
                            filterActions.handleSortByValueChange(
                              tableHeaderColumn.id,
                            );
                          }}
                        >
                          {tableHeaderColumn.name}
                        </TableSortLabel>
                      ) : (
                        tableHeaderColumn.name
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TenantTableBody
                loading={loading}
                error={error}
                tenants={tenants}
                numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
                reloadTenants={reloadTenants}
              />
              <Route path={`${path}/create`}>
                <Create tenants={tenants} />
              </Route>
              <Route path={`${path}/:prefix/edit`}>
                <Edit tenants={tenants} />
              </Route>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Tenants;
