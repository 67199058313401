import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { getToken } from "msa2-ui/src/store/auth";
import { deleteManager } from "msa2-ui/src/api/manager";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";

import {
  Grid,
  IconButton,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";

import TableRow from "msa2-ui/src/components/TableRow";
import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const ManagerTableBody = ({ loading, managers, error, reloadManagers }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const token = useSelector(getToken);
  const { url } = useRouteMatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDelete = async () => {
    const [error] = await deleteManager({
      token,
      id: selectedRow.id,
    });

    const message = error
      ? `${t("Unable to delete")} ${t("Manager")}`
      : `${t("Manager")} ${t("deleted")} `;
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      showDeleteDialog(false);
      reloadManagers();
    }
  };

  if (loading) {
    return <TableLoading numberOfTableColumns={4} />;
  }

  if (managers.length === 0) {
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching x", { x: t("Managers") })}. ${t(
                "Please reload the page",
              )}.`
            : t("No Managers found")
        }
        numberOfTableColumns={4}
      />
    );
  }

  return (
    <>
      <DeleteDialog
        title={`${t("Delete")} ${t("Manager")}`}
        content={`${t("Are you sure you want to delete x?", {
          x: selectedRow?.name,
        })}`}
        onExec={handleDelete}
      />

      <TableBody>
        {managers.map((manager, index) => {
          return (
            <TableRow
              id={`MANAGER_TABLE_ROW_${index}`}
              key={manager.id}
              actions={
                <>
                  <IconButton
                    id={`MANAGER_TABLE_BTN_EDIT_${index}`}
                    aria-label={t("Edit")}
                    component={Link}
                    to={{
                      pathname: `${url}/${manager.id}/edit`,
                    }}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton
                    id={`MANAGER_TABLE_BTN_DELETE_${index}`}
                    aria-label={t("Delete")}
                    onClick={() => {
                      setSelectedRow(manager);
                      showDeleteDialog(true);
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </>
              }
            >
              <TableCell component="th" scope="row" padding="none">
                <Grid
                  id={`MANAGER_TABLE_CELL_NAME_${index}`}
                  className={commonClasses.commonTableCellClickable}
                  container
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    className={commonClasses.commonTablePrimary}
                  >
                    {manager.name}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {manager.externalReference}
                </Typography>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {manager.operatorPrefix}
                </Typography>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {displayMonthDayYearTimeDate(manager.lastUpdated)}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

ManagerTableBody.propTypes = {
  loading: PropTypes.bool,
  managers: PropTypes.array.isRequired,
  error: PropTypes.object,
  reloadManagers: PropTypes.func.isRequired,
};

export default ManagerTableBody;
