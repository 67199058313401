import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as ConnectedIcon } from "msa2-ui/src/assets/icons/status/statusConnected.svg";
import { ReactComponent as CriticalIcon } from "msa2-ui/src/assets/icons/status/statusCritical.svg";
import { ReactComponent as NeverReachedIcon } from "msa2-ui/src/assets/icons/status/statusNeverReached.svg";
import { ReactComponent as UnreachableIcon } from "msa2-ui/src/assets/icons/status/statusUnreachable.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  listItem: {
    padding: "0 6px",
  },
  status: {
    padding: 0,
    margin: "0 5px",
  },
  avatar: {
    background: "transparent",
    width: 20,
    height: 20,
    marginRight: 3,
  },
  count: {
    fontSize: "1.125rem",
    lineHeight: "1rem",
    maxWidth: 80,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  type: {
    fontSize: "0.75rem",
    lineHeight: 1.17,
    color: theme.colors.greyDark1,
  },
  divider: {
    borderColor: "#e6eaee",
    width: "calc(100% + 32px)",
    marginLeft: -16,
  },
  cellSubtenant: {
    width: "30%",
  },
  addCursor: {
    cursor: "pointer",
  },
  cellTenant: {
    width: "20%",
  },
  cellAccount: {
    width: "20%",
  },
  emptyTable: {
    minHeight: 100,
  },
}));

const ItemStatus = ({ children, primary }) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.status}>
      <ListItemAvatar>
        <Avatar aria-label="status icon" className={classes.avatar}>
          {children}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={classes.listItem}
        classes={{
          primary: classes.count,
          secondary: classes.type,
        }}
        primary={primary}
      />
    </ListItem>
  );
};

const SubtenantList = ({ listData, showComponent, handleTitleOnClick }) => {
  const { t } = useTranslation();
  const isSubtenantType = listData[0]?.type === "Customer";
  const classes = useStyles();

  if (listData.length <= 0) {
    return (
      <Table className={classes.emptyTable}>
        <TableRow>
          <TableCell align="center" colSpan={3}>
            {t("There is nothing to display", { name: "Managed Entities" })}
          </TableCell>
        </TableRow>
      </Table>
    );
  }

  const renderTableRows = listData.map((row) => {
    return (
      <TableRow id={`DASHBOARD_LIST_ROW_${row.id}`} key={row.id}>
        <TableCell
          onClick={() => {
            if (handleTitleOnClick !== null) {
              handleTitleOnClick(row.id);
            }
          }}
          align="left"
          className={classnames(classes.cellSubtenant, {
            [classes.addCursor]: Boolean(handleTitleOnClick),
          })}
        >
          {row.name}
        </TableCell>
        {isSubtenantType && (
          <TableCell align="left" className={classes.cellTenant}>
            {isSubtenantType ? row.tenantName.toUpperCase() : row.tenantName}
          </TableCell>
        )}
        <TableCell align="left">
          <List className={classes.root}>
            <ItemStatus primary={row.error}>
              <UnreachableIcon />
            </ItemStatus>
            <ItemStatus primary={row.critical}>
              <CriticalIcon />
            </ItemStatus>
            <ItemStatus primary={row.neverReached}>
              <NeverReachedIcon />
            </ItemStatus>
            <ItemStatus primary={row.ok}>
              <ConnectedIcon />
            </ItemStatus>
          </List>
        </TableCell>
      </TableRow>
    );
  });

  return showComponent ? (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {isSubtenantType && (
              <TableCell align="left">
                <Paper className={classes.title}>{t("Subtenant")}</Paper>
              </TableCell>
            )}
            <TableCell align="left">
              <Paper className={classes.secondaryType}>{t("Tenant")}</Paper>
            </TableCell>
            <TableCell align="left">
              <Paper className={classes.secondaryType}></Paper>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderTableRows}</TableBody>
      </Table>
    </>
  ) : null;
};

SubtenantList.propTypes = {
  listData: PropTypes.array.isRequired,
  showComponent: PropTypes.bool.isRequired,
  handleTitleOnClick: PropTypes.func,
};
export default SubtenantList;
