import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createTopologyAndRetrieveTheData,
  runUpdateTopologyProcessAndRetrieveData,
  saveNodesPositions,
  getTopologyDataByInstanceId,
} from "msa2-ui/src/api/topology";
import { getWorkflowInstances } from "msa2-ui/src/api/workflow";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { useSnackbar } from "notistack";
import { useUnmountEffect } from "msa2-ui/src/hooks/useUnmountEffect";
import TopologyTabView from "./TopologyTabView";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import { useInterval } from "react-use";
import { getTopologySectionValue } from "msa2-ui/src/store/ui";
import useApi from "msa2-ui/src/hooks/useApi";
import { useBoundCallback } from "msa2-ui/src/hooks/useBoundCallbacks";

const pathToWorkflowDefinitionFile = "Process/Topology/Topology";

const TopologyTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useRef(true);
  const { t } = useTranslation();

  const token = useSelector(getToken);
  const { id: subtenantId, ubiqubeId } = useSelector(getSelectedSubtenant);
  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));
  const isContextMenuOpened = useSelector(
    getTopologySectionValue("showContextMenu"),
  );
  const topologyAutoRefreshInterval = useSelector(
    getAutoRefreshSetting("topology"),
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [topologyData, setTopologyData] = useState({
    nodes: [],
    serviceVariables: {},
  });
  const [workflowInstances, setWorkflowInstances] = useState([]);

  useUnmountEffect(() => (isMounted.current = false));

  const [
    workflowInstancesLoading,
    workflowInstancesError,
    workflowDefinition,
    ,
    reloadWorkflowInstances,
  ] = useApi(getWorkflowInstances, {
    ubiqubeId,
    workflowPath: decodeURIComponent(pathToWorkflowDefinitionFile),
    definedVarFlag: true,
  });

  useEffect(() => {
    let message = "";

    if (workflowInstancesLoading) {
      message = `${t("Loading topology")}...`;
    } else if (workflowInstancesError) {
      message = t("Unable to load x", { x: t("Topology") });
    } else if (
      !workflowDefinition?.instances ||
      !workflowDefinition?.instances.length
    ) {
      message = t("No topology to show.");
    } else {
      message = "";
    }

    setErrorMessage(message);
  }, [t, workflowDefinition, workflowInstancesLoading, workflowInstancesError]);

  const transformedWorkFlowInstances = useCallback(
    (instances) => {
      const displayField = workflowDefinition.information.displayField;
      return instances.map((instance) => {
        const { SERVICEINSTANCEID, UBIQUBEID } = instance.variables;
        return {
          title: instance.variables[displayField] ?? "Default Topology",
          serviceId: SERVICEINSTANCEID,
          ubiqubeId: UBIQUBEID,
        };
      });
    },
    [workflowDefinition],
  );

  const createTopology = useBoundCallback(async () => {
    setErrorMessage(t("No topology to show. Creating..."));

    const [error, data] = await createTopologyAndRetrieveTheData({
      token,
      subtenantId,
    });

    if (error) {
      setErrorMessage(t("Unable to load x", { x: t("Topology") }));
    } else {
      setErrorMessage("");
      setTopologyData(data);
    }
  });

  const updateTopology = useCallback(
    async (
      pollingInterval,
      serviceVariables,
      subtenantId,
      token,
      isMountedComponentRef,
    ) => {
      setIsPending(true);

      const { serviceId, ubiqubeId } = serviceVariables;
      const [error, response] = await runUpdateTopologyProcessAndRetrieveData({
        pollingInterval,
        ubiqubeId,
        serviceId,
        subtenantId,
        token,
        isMountedComponentRef,
      });

      setIsPending(false);

      if (error) {
        enqueueSnackbar(error.getMessage(t("Unable to load Topology")), {
          variant: "error",
        });
      } else {
        setTopologyData(response);
      }
    },
    [t, enqueueSnackbar],
  );

  const onTopologyChangeHandler = useCallback(
    async (instanceId) => {
      setErrorMessage(`${t("Loading topology")}...`);
      setIsPending(true);

      const [error, data] = await getTopologyDataByInstanceId({
        token,
        instanceId,
      });

      setErrorMessage("");

      if (error) {
        enqueueSnackbar(error.getMessage(t("Unable to load Topology")), {
          variant: "error",
        });
      } else {
        setTopologyData(data);
      }
      setIsPending(false);
    },
    [t, token, enqueueSnackbar],
  );

  // If there is no instance available then we are creating new one and reloading workflow
  useEffect(() => {
    if (
      !workflowInstancesLoading &&
      workflowDefinition?.instances &&
      !workflowDefinition?.instances.length
    ) {
      (async () => {
        await createTopology();
        reloadWorkflowInstances();
      })();
    }
  }, [
    workflowInstancesLoading,
    workflowDefinition,
    reloadWorkflowInstances,
    createTopology,
  ]);

  useEffect(() => {
    if (workflowDefinition?.instances && workflowDefinition?.instances.length) {
      const tranformedInstancesResult = transformedWorkFlowInstances(
        workflowDefinition.instances,
      );
      setWorkflowInstances(tranformedInstancesResult);
      const isServiceIdForSelectedSubtenant = tranformedInstancesResult?.some(
        ({ serviceId }) =>
          serviceId === topologyData.serviceVariables.serviceId,
      );
      // Default - we are selecting first instance for topology if instance is not selected
      if (
        !topologyData ||
        !topologyData.nodes.length ||
        !isServiceIdForSelectedSubtenant
      ) {
        onTopologyChangeHandler(tranformedInstancesResult[0].serviceId);
      }
    }
  }, [
    workflowDefinition,
    topologyData,
    transformedWorkFlowInstances,
    onTopologyChangeHandler,
  ]);

  const updateTopologyHandler = useCallback(() => {
    updateTopology(
      pollingInterval,
      topologyData.serviceVariables,
      subtenantId,
      token,
      isMounted,
    );
  }, [updateTopology, pollingInterval, topologyData, subtenantId, token]);

  useInterval(
    () => {
      if (!subtenantId || isPending || isContextMenuOpened) {
        return;
      }

      updateTopologyHandler();
    },
    topologyAutoRefreshInterval > 0 ? topologyAutoRefreshInterval : null,
  );

  const saveNodesHandler = useCallback(
    async (nodes, shouldUpdateTopology) => {
      const { serviceId } = topologyData.serviceVariables;
      const [error] = await saveNodesPositions({ token, serviceId, nodes });

      if (error) {
        return enqueueSnackbar(error.getMessage(t("Unable to save")), {
          variant: "error",
        });
      }

      enqueueSnackbar(t("x saved successfully", { x: t("Topology") }), {
        variant: "success",
      });

      shouldUpdateTopology && updateTopologyHandler();
    },
    [topologyData, enqueueSnackbar, token, t, updateTopologyHandler],
  );

  return (
    <TopologyTabView
      t={t}
      errorMessage={errorMessage}
      isPending={isPending}
      isSubtenantEmpty={!subtenantId}
      topologyData={topologyData}
      onSaveNodes={saveNodesHandler}
      onUpdateTopology={updateTopologyHandler}
      workflowInstances={workflowInstances}
      onTopologyChange={onTopologyChangeHandler}
      reloadWorkflowInstances={reloadWorkflowInstances}
    />
  );
};

export default memo(TopologyTab);
