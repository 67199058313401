import React, { useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getParentRoute } from "msa2-ui/src/utils/urls";
import SplitButton from "msa2-ui/src/components/SplitButton";
import { useSelector, shallowEqual } from "react-redux";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";
import EditFileDialog from "./EditFileDialog";
import RepositoryService from "msa2-ui/src/services/Repository";
import isEmpty from "lodash/isEmpty";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import {
  getAvailableSubtenants,
  getAvailableTenants,
} from "msa2-ui/src/store/designations";
import { getUserRole, userRoles } from "msa2-ui/src/store/auth";

import useDialog from "msa2-ui/src/hooks/useDialog";
import useFilter from "msa2-ui/src/hooks/useFilter";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  listRepositoriesOrFilesInFolder,
  listFilesInFolder,
  uploadDataFile,
  repoFileUpload,
  deleteRepositoryFile,
  addDirectory,
  removeDirectory,
  unzipFile,
  zipFolder,
  downloadArchive,
} from "msa2-ui/src/api/repository";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

import { makeStyles } from "@material-ui/core";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";

import {
  Description as FileIcon,
  Folder as FolderIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Unarchive,
  Archive,
} from "@material-ui/icons";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { sortOrder } from "msa2-ui/src/Constants";

import MSATableBody from "msa2-ui/src/components/MSATableBody";
import MSATableRow from "msa2-ui/src/components/MSATableRow";
import {
  delegationProfileTypes,
  getDelegationProfilesList,
} from "msa2-ui/src/store/delegationProfiles";

import { getMsaVarsAllUsers } from "msa2-ui/src/api/msaVars";
import { MSA_VARS, REPOSITORY_FOLDERS } from "msa2-ui/src/Constants";

const ZIP_EXTENSIONS = ["zip", "gz", "csar"];

const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    width: "40%",
  },
  {
    id: "action",
    name: "",
    align: "left",
    width: "8%",
    sortKey: false,
  },
];

const useStyles = makeStyles(({ palette, spacing, darkMode }) => ({
  paper: {
    boxShadow: darkMode
      ? `1px -1px 12px -1px ${palette.primary.main}`
      : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
    padding: spacing(2),
  },
  header: {
    marginTop: 10,
    marginBottom: 10,
  },
  backToPreviousPage: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: darkMode ? palette.secondary.main : palette.primary.main,
    letterSpacing: 0.3,
    fontSize: 13,
  },
  disabledLink: {
    pointerEvents: "none",
    textDecoration: "none",
  },
  backIcon: {
    color: darkMode ? palette.secondary.main : palette.primary.main,
  },
  downloadButton: {
    width: 48,
    height: 48,
  },
  newFolder: {
    width: "70%",
  },
}));

const Repository = ({ directories = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const userRole = useSelector(getUserRole);
  const subtenants = useSelector(getAvailableSubtenants);
  const tenants = useSelector(getAvailableTenants);
  const { pathname } = useLocation();
  const history = useHistory();
  const uri = directories.join("/");
  const isPathNameRepository = pathname === "/repository";
  const isPathInDataFiles = pathname.startsWith("/repository/Datafiles");
  const isPathInDocumentation = pathname.startsWith(
    "/repository/Documentation",
  );
  const isPathInFirmware = pathname.startsWith("/repository/Firmware");
  const isPathInLicense = pathname.startsWith("/repository/License");
  const isPathInPki = pathname.startsWith("/repository/Pki");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);
  const inputRefForFileUpload = useRef(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [fileName, setFileName] = useState("");

  const [showZipDialog, ZipDialog] = useDialog();
  const [showCreateFolderDialog, CreateFolderDialog] = useDialog();
  const [newFolderName, setNewFolderName] = useState("");
  const [zipInfo, setZipInfo] = useState({ target: "", name: "" });
  const [showUnzipDialog, UnzipDialog] = useDialog();
  const [unzipInfo, setUnzipInfo] = useState({ target: "", name: "" });

  const [
    canCreateInDatafiles,
    canEditInDatafiles,
    canDeleteInDatafiles,
    canCreateInDocumentation,
    canEditInDocumentation,
    canDeleteInDocumentation,
    canCreateInFirmware,
    canEditInFirmware,
    canDeleteInFirmware,
    canCreateInLicense,
    canEditInLicense,
    canDeleteInLicense,
    canCreateInPki,
    canEditInPki,
    canDeleteInPki,
    canViewDatafiles,
    canViewDocumentation,
    canViewFirmware,
    canViewLicense,
    canViewPki,
  ] = useSelector(
    getDelegationProfilesList([
      [delegationProfileTypes.REPOSITORY, "datafiles.create"],
      [delegationProfileTypes.REPOSITORY, "datafiles.edit"],
      [delegationProfileTypes.REPOSITORY, "datafiles.delete"],
      [delegationProfileTypes.REPOSITORY, "documentation.create"],
      [delegationProfileTypes.REPOSITORY, "documentation.edit"],
      [delegationProfileTypes.REPOSITORY, "documentation.delete"],
      [delegationProfileTypes.REPOSITORY, "firmware.create"],
      [delegationProfileTypes.REPOSITORY, "firmware.edit"],
      [delegationProfileTypes.REPOSITORY, "firmware.delete"],
      [delegationProfileTypes.REPOSITORY, "license.create"],
      [delegationProfileTypes.REPOSITORY, "license.edit"],
      [delegationProfileTypes.REPOSITORY, "license.delete"],
      [delegationProfileTypes.REPOSITORY, "pki.create"],
      [delegationProfileTypes.REPOSITORY, "pki.edit"],
      [delegationProfileTypes.REPOSITORY, "pki.delete"],
      [delegationProfileTypes.REPOSITORY, "datafiles.view"],
      [delegationProfileTypes.REPOSITORY, "documentation.view"],
      [delegationProfileTypes.REPOSITORY, "firmware.view"],
      [delegationProfileTypes.REPOSITORY, "license.view"],
      [delegationProfileTypes.REPOSITORY, "pki.view"],
    ]),
    shallowEqual,
  );

  /* Decision to hide following functionalities : create folder, upload file, delete folder and files 
      1. Hide for all users at root folder level
      2. At Datafiles Folder - Hide for administrators and below
      3. Inside Datafiles at Tenant level - Hide for Managers and below
  */
  const hideFunctionality =
    isPathNameRepository ||
    (isPathInDataFiles &&
      directories.length === 1 &&
      userRole >= userRoles.ADMINISTRATOR) ||
    (isPathInDataFiles &&
      directories.length === 2 &&
      userRole >= userRoles.MANAGER);

  /*
      In addition to the above checks : 
      1. Inside Datafiles at Subtenant level - Hide for Managers if permission profile is not given
      2. At folders Documentation, Firmware, License and Pki - hide for Managers and below, if permission profile is not defined
  */
  const hideCreateUploadOption =
    hideFunctionality ||
    (isPathInDataFiles &&
      directories.length > 2 &&
      userRole >= userRoles.MANAGER &&
      !canCreateInDatafiles) ||
    (userRole >= userRoles.MANAGER &&
      ((isPathInDocumentation && !canCreateInDocumentation) ||
        (isPathInFirmware && !canCreateInFirmware) ||
        (isPathInLicense && !canCreateInLicense) ||
        (isPathInPki && !canCreateInPki)));

  const hideDeleteOption =
    hideFunctionality ||
    (isPathInDataFiles &&
      directories.length > 2 &&
      userRole >= userRoles.MANAGER &&
      !canDeleteInDatafiles) ||
    (userRole >= userRoles.MANAGER &&
      ((isPathInDocumentation && !canDeleteInDocumentation) ||
        (isPathInFirmware && !canDeleteInFirmware) ||
        (isPathInLicense && !canDeleteInLicense) ||
        (isPathInPki && !canDeleteInPki)));

  /* Decision to hide files :
       1. path is in /Datafiles (or)
       2. path is in /Datafiles and userRole >= userRoles.ADMINISTRATOR (or)
       3. path is in /Datafiles/xxx and && userRole >= userRoles.MANAGER
  */
  const hideFiles =
    !uri ||
    (isPathInDataFiles &&
      ((directories.length === 1 && userRole >= userRoles.ADMINISTRATOR) ||
        (directories.length === 2 && userRole >= userRoles.MANAGER)));

  //Decision to hide Edit File functionality
  const hideEditFileOption =
    userRole >= userRoles.MANAGER &&
    ((isPathInDocumentation && !canEditInDocumentation) ||
      (isPathInFirmware && !canEditInFirmware) ||
      (isPathInLicense && !canEditInLicense) ||
      (isPathInPki && !canEditInPki) ||
      (isPathInDataFiles && !canEditInDatafiles));

  let selectedTenantLabel = "";
  let selectedSubtenantLabel = "";
  if (
    directories.length >= 2 &&
    isPathInDataFiles &&
    tenants.some(({ value }) => value === directories[1])
  ) {
    const tenantName = tenants.filter(
      ({ value }) => value === directories[1],
    )[0].label;
    selectedTenantLabel = tenantName;
  }
  if (
    directories.length >= 3 &&
    isPathInDataFiles &&
    subtenants.some(({ ubiqubeId }) => ubiqubeId === directories[2])
  ) {
    const subtenant = subtenants.filter(
      ({ ubiqubeId }) => ubiqubeId === directories[2],
    )[0];
    selectedSubtenantLabel = subtenant.label;
  }

  const setReferenceForUpload = () => {
    inputRefForFileUpload.current.click();
  };

  const [
    foldersLoading,
    foldersError,
    folders,
    ,
    reloadFilesAndFolders,
  ] = useApi(listRepositoriesOrFilesInFolder, {
    uri,
    transforms: [(response) => response],
    token,
  });

  const [filesLoading, , files, , reloadFiles] = useApi(
    listFilesInFolder,
    {
      uri,
      token,
      transforms: [
        (response) =>
          // show only files in the current directory as API returns all files under the folder
          response.filter((file) => {
            return file.split("/").length === directories.length + 1;
          }),
        (response) =>
          response.map((file) => ({
            path: file,
            name: file.split("/").slice(-1)[0],
          })),
      ],
    },
    !uri,
  );

  const [, , validations] = useApi(getMsaVarsAllUsers, {
    token,
    vars: [MSA_VARS.UBI_VALIDATION_MAPPING],
  });
  let validationsFromMsaVar;
  if (validations !== undefined) {
    const validationsValue = validations[0]?.value || "";
    validationsFromMsaVar = JSON.parse(validationsValue.replace(/\\n/g, ""));
  }

  const reloadAll = () => {
    reloadFilesAndFolders();
    reloadFiles();
  };

  const handleZip = async () => {
    const [error] = await zipFolder({
      token,
      path: zipInfo.path,
      zipFileName: zipInfo.name,
    });
    const variant = error ? "error" : "success";
    const message = error ? error.getMessage() : t("File zipped successfully.");
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      reloadAll();
      showZipDialog(false);
      setZipInfo({});
    }
  };

  const handleUnzip = async () => {
    const [error] = await unzipFile({
      token,
      path: unzipInfo.path,
      folderNameForextractedContent: unzipInfo.name,
    });
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage()
      : t("File unzipped successfully.");
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      reloadAll();
      showUnzipDialog(false);
      setUnzipInfo({});
    }
  };

  const createFolder = async () => {
    const uriToCreate = directories.join("/") + "/" + newFolderName;
    const [error] = await addDirectory({
      uri: uriToCreate,
      token,
    });
    const message = error
      ? error.getMessage(t("Error creating folder"))
      : t("Folder created successfully");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    setNewFolderName("");
    reloadFilesAndFolders();
  };

  const MENU = [
    {
      id: "REPOSITORY_CREATE_BUTTON",
      label: "Create",
      onClick: showCreateFolderDialog,
      disabled: hideCreateUploadOption ? true : false,
      toolTipMessage: hideCreateUploadOption
        ? t("You do not have permissions to create folder at this level")
        : t("Create folder"),
    },
    {
      id: "REPOSITORY_UPLOAD_BUTTON",
      label: "Upload",
      onClick: setReferenceForUpload,
      disabled: hideCreateUploadOption ? true : false,
    },
  ];

  const handleFileUpload = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;
    if (isPathInDataFiles) {
      uploadFile(fileObj);
    } else {
      uploadFiletoDiffPath(fileObj);
    }
  };

  const uploadFiletoDiffPath = async (fileObj) => {
    const formData = new FormData();
    const pathToSend = pathname.replace("/repository/", "");

    formData.append("file", fileObj);
    const [error, response] = await repoFileUpload({
      file: formData,
      path: `${pathToSend}/${fileObj.name}`,
      token,
    });
    if (error || response?.errorCode) {
      const message = error
        ? error.getMessage(t("Error uploading file"))
        : response.message;
      enqueueSnackbar(message, {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    } else {
      enqueueSnackbar(t("File uploaded successfully"), {
        variant: "success",
      });
      reloadAll();
    }
  };

  const uploadFile = async (fileObj) => {
    const formData = new FormData();
    const pathToSend = pathname.replace("/repository", "");

    formData.append("file", fileObj);
    const [error, response] = await uploadDataFile({
      file: formData,
      path: pathToSend,
      token,
    });
    if (error || response?.errorCode) {
      const message = error
        ? error.getMessage(t("Error uploading file"))
        : response.message;
      enqueueSnackbar(message, {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    } else {
      enqueueSnackbar(t("File uploaded successfully"), {
        variant: "success",
      });
      reloadAll();
    }
  };

  const onClickFolders = ({ name }) => {
    if (name.includes("(")) {
      const newName = name.substring(name.indexOf("(") + 1, name.length - 1);
      history.push([pathname, newName].join("/"));
    } else {
      history.push([pathname, name].join("/"));
    }
  };

  const colSpan = TABLE_HEADER_COLUMNS.length;
  const [filterState, filterActions] = useFilter({
    searchValue: "",
    sortByValue: "lastUpdate",
    sortOrderValue: 0,
  });

  const respFolderList = FeatureFlag.getFeatureValueAsArray(
    "repositoryFolderList",
  );
  const foldersToDisplay = Array.isArray(respFolderList)
    ? respFolderList
    : [REPOSITORY_FOLDERS.DATAFILES];

  const foldersToDisplayAfterPermissionCheck = foldersToDisplay.filter(
    (name) => {
      /* Show folders if permission profile exists
       */
      switch (name) {
        case REPOSITORY_FOLDERS.DATAFILES: {
          return canViewDatafiles;
        }
        case REPOSITORY_FOLDERS.DOCUMENTATION: {
          return canViewDocumentation;
        }
        case REPOSITORY_FOLDERS.FIRMWARE: {
          return canViewFirmware;
        }
        case REPOSITORY_FOLDERS.LICENSE: {
          return canViewLicense;
        }
        case REPOSITORY_FOLDERS.PKI: {
          return canViewPki;
        }
        default:
          return true;
      }
    },
  );

  return (
    <div data-testid="REPOSITORY">
      <div>
        <input
          style={{ display: "none" }}
          ref={inputRefForFileUpload}
          type="file"
          onChange={handleFileUpload}
        />
      </div>
      <CreateFolderDialog
        title={t("Create Folder")}
        content={t("Enter folder name and click Create Folder")}
        onExec={() => createFolder()}
        disabled={isEmpty(newFolderName)}
        execLabel={t("Create Folder")}
      >
        <TextField
          id="CREATE_FOLDER_NAME"
          variant={"outlined"}
          label={t("Folder Name")}
          value={newFolderName}
          className={classes.newFolder}
          onChange={({ target: { value } }) =>
            setNewFolderName(RepositoryService.convertStringToFilename(value))
          }
          fullWidth
        />
      </CreateFolderDialog>
      {showEditDialog && (
        <EditFileDialog
          closeDialog={() => setShowEditDialog(false)}
          fileUri={fileName}
          isBinary={ZIP_EXTENSIONS.some((extension) =>
            fileName.endsWith("." + extension),
          )}
          validations={validationsFromMsaVar}
        />
      )}
      <ZipDialog title={t("Zip Folder")} onExec={handleZip}>
        <TextField
          value={zipInfo.name}
          onChange={({ target: { value } }) => {
            setZipInfo({
              ...zipInfo,
              name: RepositoryService.convertStringToFilename(value),
            });
          }}
          variant="outlined"
          id="REPOSITORY_ZIP_FILE_NAME"
          label={"File Name"}
          fullWidth
        />
      </ZipDialog>
      <UnzipDialog title={t("Unzip File")} onExec={handleUnzip}>
        <TextField
          value={unzipInfo.name}
          onChange={({ target: { value } }) => {
            setUnzipInfo({
              ...unzipInfo,
              name: RepositoryService.convertStringToFilename(value),
            });
          }}
          variant="outlined"
          id="REPOSITORY_UNZIP_FOLDER_NAME"
          label={"Folder Name"}
          fullWidth
        />
      </UnzipDialog>
      <Paper>
        <Box
          data-testid="REPOSITORY_NAV_MENU"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            p={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box>
              {!isPathNameRepository && (
                <Link
                  id="REPOSITORY_GO_BACK"
                  to={getParentRoute(pathname)}
                  className={classes.backToPreviousPage}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <KeyboardArrowLeft className={classes.backIcon} />
                    <Typography
                      variant="h6"
                      className={classes.backToPreviousPage}
                    >
                      {t("Back to Parent Directory")}
                    </Typography>
                  </Box>
                </Link>
              )}
            </Box>
            <Box
              p={1}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              {
                <Link
                  id={`REPOSITORY_GO_BACK_ROOT`}
                  to={"/repository"}
                  className={
                    !isPathNameRepository
                      ? classes.backToPreviousPage
                      : classes.disabledLink
                  }
                >
                  <Typography
                    variant="h1"
                    className={classes.backToPreviousPage}
                  >
                    {t("Repository")}
                    {!isPathNameRepository && (
                      <KeyboardArrowRight className={classes.backIcon} />
                    )}
                  </Typography>
                </Link>
              }
              {directories.map((item, itemIndex) => (
                <Link
                  key={item}
                  id={`REPOSITORY_GO_BACK_${item}`}
                  to={getParentRoute(
                    pathname,
                    directories.length - itemIndex - 1,
                  )}
                  className={
                    itemIndex !== directories.length - 1
                      ? classes.backToPreviousPage
                      : classes.disabledLink
                  }
                >
                  <Typography
                    variant="h1"
                    className={classes.backToPreviousPage}
                  >
                    {itemIndex === 1 && selectedTenantLabel}
                    {itemIndex === 2 && selectedSubtenantLabel}
                    {((itemIndex === 1 && selectedTenantLabel) ||
                      (itemIndex === 2 && selectedSubtenantLabel)) &&
                      " ("}
                    {item}
                    {((itemIndex === 1 && selectedTenantLabel) ||
                      (itemIndex === 2 && selectedSubtenantLabel)) &&
                      ")"}
                    {itemIndex !== directories.length - 1 && (
                      <KeyboardArrowRight className={classes.backIcon} />
                    )}
                  </Typography>
                </Link>
              ))}
            </Box>
          </Box>

          <Box p={1}>
            <SplitButton options={MENU} />
          </Box>
        </Box>
        <Table>
          <colgroup>
            {TABLE_HEADER_COLUMNS.map(({ id, width }) => (
              <col key={id} style={{ width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={colSpan}
                className={commonClasses.commonTableCell}
              >
                {/* <FilterMenu
                  id="SETTINGS_MSA_VAR_FILTER_MENU"
                  {...filterState}
                  {...filterActions}
                  handleViewAsChange={undefined}
                /> */}
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                <TableCell
                  key={tableHeaderColumn.id}
                  align={tableHeaderColumn.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {tableHeaderColumn.sortKey ? (
                    <TableSortLabel
                      id={`SETTINGS_MSA_VAR_${tableHeaderColumn.id}`}
                      active={tableHeaderColumn.id === filterState.sortByValue}
                      direction={sortOrder[filterState.sortOrderValue].text}
                      onClick={() => {
                        filterActions.handleSortByOrderChange(
                          filterState.sortByValue !== tableHeaderColumn.id
                            ? Number(filterState.sortOrderValue)
                            : Number(!filterState.sortOrderValue),
                        );
                        filterActions.handleSortByValueChange(
                          tableHeaderColumn.id,
                        );
                      }}
                    >
                      {tableHeaderColumn.name}
                    </TableSortLabel>
                  ) : (
                    tableHeaderColumn.name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <MSATableBody
            loading={foldersLoading}
            error={foldersError && t("Unable to load Repository")}
            noContents={
              !folders?.length && !files?.length && t("No Contents found")
            }
            colSpan={colSpan}
          >
            {folders
              ?.filter(({ path }) => {
                // Temporary function to filter out redundancy
                return files ? files.every((file) => file.path !== path) : true;
              })
              .filter(({ name }) => {
                /* Decision to show folders in "Datafiles"
                    1. If path is in /Datafiles and userRole >= userRoles.ADMINISTRATOR - show only tenant folders corresponding to logged in user
                    2. If path is in /Datafiles/xxx and userRole >= userRoles.MANAGER - show only subtenant folders corresponding to logged in user
                   If path is not in Datafiles, show all folders 
                */
                switch (directories.length) {
                  case 1: {
                    if (
                      userRole >= userRoles.ADMINISTRATOR &&
                      isPathInDataFiles
                    ) {
                      return tenants.some(({ value }) => value === name);
                    }
                    return true;
                  }
                  case 2: {
                    if (userRole >= userRoles.MANAGER && isPathInDataFiles) {
                      return subtenants.some(
                        ({ ubiqubeId }) => ubiqubeId === name,
                      );
                    }
                    return true;
                  }
                  default:
                    return true;
                }
              })
              .map((folder, i) => {
                const { path, name } = folder;
                let newFolder = folder;
                if (directories.length === 1 && isPathInDataFiles) {
                  const tenant = tenants.filter(
                    ({ value }) => value === name,
                  )[0];
                  if (!isEmpty(tenant?.label)) {
                    const nameWithTenantInfo =
                      tenant?.label + " (" + name + ")";
                    newFolder = { ...folder, name: nameWithTenantInfo };
                  }
                }
                if (directories.length === 2 && selectedTenantLabel) {
                  const subtenant = subtenants.filter(
                    ({ ubiqubeId }) => ubiqubeId === name,
                  )[0];
                  if (!isEmpty(subtenant?.label)) {
                    const nameWithTenantInfo =
                      subtenant?.label + " (" + name + ")";
                    newFolder = { ...folder, name: nameWithTenantInfo };
                  }
                }
                const uriToDelete = directories.join("/") + "/" + name;
                const onDeleteFolder = (token) =>
                  removeDirectory({ token, uri: uriToDelete });
                //Temporary logic, to be removed later

                if (
                  //Decision to show folder -  At /repository level show folder if docker compose config has folder name and appropriate permission profile to display
                  (isPathNameRepository &&
                    foldersToDisplayAfterPermissionCheck.includes(name)) ||
                  !isPathNameRepository
                ) {
                  return (
                    <MSATableRow
                      key={name}
                      columns={TABLE_HEADER_COLUMNS}
                      data={newFolder}
                      onClick={onClickFolders}
                      Icon={FolderIcon}
                      deleteAPI={hideDeleteOption ? undefined : onDeleteFolder}
                      callbackPostDelete={reloadFilesAndFolders}
                      OtherActions={() =>
                        hideFunctionality ? (
                          <div></div>
                        ) : (
                          <Tooltip title="Zip folder">
                            <IconButton
                              id={`REPOSITORY_ZIP_FILE_${i}`}
                              data-testid={`REPOSITORY_ZIP_FILE_${name}`}
                              onClick={() => {
                                showZipDialog(true);
                                setZipInfo({
                                  path,
                                  name: [name, "zip"].join("."),
                                });
                              }}
                            >
                              <Archive />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                  );
                }
              })}

            {!hideFiles && (
              <>
                {(!isPathNameRepository && foldersLoading) || filesLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={colSpan}
                      className={commonClasses.commonLoaderWrapperTable}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  files?.map((file, i) => {
                    const { name, path } = file;
                    const onDeleteFile = (token) =>
                      deleteRepositoryFile({ token, uri: path });
                    const canUnzip = ZIP_EXTENSIONS.some((extension) =>
                      name.endsWith("." + extension),
                    );
                    return (
                      <MSATableRow
                        key={name}
                        columns={TABLE_HEADER_COLUMNS}
                        data={file}
                        onEdit={
                          hideEditFileOption
                            ? undefined
                            : () => {
                                setFileName(path);
                                setShowEditDialog(true);
                              }
                        }
                        deleteAPI={hideDeleteOption ? undefined : onDeleteFile}
                        callbackPostDelete={reloadAll}
                        Icon={FileIcon}
                        OtherActions={() => (
                          <>
                            {canUnzip && (
                              <Tooltip title="Unzip file">
                                <IconButton
                                  id={`REPOSITORY_UNZIP_FILE_${i}`}
                                  onClick={() => {
                                    showUnzipDialog(true);
                                    setUnzipInfo({
                                      path,
                                      name: RepositoryService.stripFileExtensionFromString(
                                        name,
                                      ),
                                    });
                                  }}
                                >
                                  <Unarchive />
                                </IconButton>
                              </Tooltip>
                            )}

                            <Tooltip title="Download file">
                              <IconButton
                                id={`REPOSITORY_DOWNLOAD_FILE_${i}`}
                                className={classes.downloadButton}
                                aria-label={t("Download")}
                                onClick={() => {
                                  downloadArchive({
                                    path,
                                    token,
                                    fileName: name,
                                    onError: () => {
                                      enqueueSnackbar(
                                        t("Unable to download contents"),
                                        { variant: "error" },
                                      );
                                    },
                                  });
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      />
                    );
                  })
                )}
              </>
            )}
          </MSATableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default Repository;
