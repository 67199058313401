import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

import SelectSearch from "msa2-ui/src/components/SelectSearch";

const useStyles = makeStyles((theme) => {
  const { darkMode } = theme;
  return {
    paper: {
      boxShadow: darkMode
        ? "1px -1px 12px -1px #0059a7"
        : "0 6px 16px 4px rgba(81, 97, 133, 0.13), 0 3px 8px 0 rgba(0, 0, 0, 0.15)",
      overflowY: "inherit",
      overflowX: "inherit",
    },
    popoverContainer: {
      position: "relative",
      top: 7,
      width: 300,
      flexGrow: 1,
      boxShadow:
        "0 6px 16px 4px rgba(81, 97, 133, 0.13), 0 3px 8px 0 rgba(0, 0, 0, 0.15)",
      border: `1px solid ${theme.colors.greyLight1}`,
      borderRadius: "5px 5px 0 0",
      backgroundColor: "#fff",
      "&:after, &:before": {
        bottom: "100%",
        left: "50%",
        border: "solid transparent",
        content: '" "',
        height: 0,
        width: 0,
        position: "absolute",
        pointerEvents: "none",
      },
      "&:after": {
        borderColor: "rgba(255, 255, 255, 0)",
        borderBottomColor: "#fff",
        borderWidth: 6,
        marginLeft: -6,
      },
      "&:before": {
        borderColor: "rgba(191, 201, 217, 0)",
        borderBottomColor: theme.colors.greyLight1,
        borderWidth: 7,
        marginLeft: -7,
      },
    },
  };
});

/**
 * @returns {array} See the docs below for the details inside of this array.
 *  @returns {boolean} Returns the state if popover component is open.
 *  @returns {function} You should pass HTML element on which you want to show the popover.
 *  @returns {function} React component includes popover.
 */
const usePopover = ({ component: Component = SelectSearch } = {}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [params, setParams] = useState();
  const onClose = () => {
    setShow(false);
    setAnchorEl(null);
  };

  /**
   * This returns a React component
   * @param {string} id This goes to id attribute for an HTML element.
   * @param {array} options Will be passed to SelectSearch component.
   * @param {function} onSelect A function that will be triggered when you click the popover.
   * @returns React component.
   */
  const modal = ({ id, options, onSelect, notFoundMessage }) =>
    show && (
      <Popover
        id={`POPOVER_${id}`}
        open
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popoverContainer}>
          <Component
            id={`POPOVER_SELECT_SEARCH_${id}`}
            options={options}
            noOptionText={notFoundMessage || t("Item not found")}
            onSelect={(id, obj) => {
              onSelect(obj, params);
              onClose();
            }}
            {...params}
          />
        </div>
      </Popover>
    );

  return [
    show,
    (e, params) => {
      setAnchorEl(e.currentTarget ?? e);
      setParams(params);
      setShow(true);
    },
    modal,
  ];
};

export default usePopover;
