import React from "react";
import PropTypes from "prop-types";

const Node = ({ isActive, label }) => {
  return (
    <div
      style={{
        background: isActive ? "#27bd6e" : "#324261",
        color: "#fff",
        fontSize: 14,
        borderRadius: "50%",
        textAlign: "center",
        padding: 10,
        minWidth: 50,
        maxWidth: 150,
        transition: ".3s ease",
      }}
    >
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {label}
      </div>
    </div>
  );
};

Node.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Node;
