import React from "react";
import PropTypes from "prop-types";
import Menu, { MenuItem } from "msa2-ui/src/components/menu";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  delegationProfileTypes,
  getDelegationProfilesList,
} from "msa2-ui/src/store/delegationProfiles";
import { selectManagedEntity } from "msa2-ui/src/store/designations";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import TriggerWorkflowAction from "./TriggerWorkflowAction";
import TriggerBpmAction from "msa2-ui/src/components/topology/context-menu/TriggerBpmAction";
import AddCategoryAction from "./AddCategoryAction";
import DetailsAction from "msa2-ui/src/components/topology/context-menu/DetailsAction";
import EditAction from "msa2-ui/src/components/topology/context-menu/EditAction";
import DeleteAction from "./DeleteAction";
import ActivateAction from "./ActivateAction";
import ActivateWithoutConnectionAction from "./ActivateWithoutConnectionAction";
import { nodeTypes } from "msa2-ui/src/components/topology/constants";

const ContextMenu = ({ node, position, onAddCategory, onClose, onChange }) => {
  const t = useBoundedTranslation();
  const dispatch = useDispatch();

  const [
    canCreateWFInstance,
    canUpdateWFInstance,
    canDeleteWFInstance,
    canExecuteBPM,
    canAddCategory,
    canEditManagedEntity,
    canDeleteManagedEntity,
    canProvision,
  ] = useSelector(
    getDelegationProfilesList([
      [delegationProfileTypes.WORKFLOWS, "instance.create"],
      [delegationProfileTypes.WORKFLOWS, "instance.update"],
      [delegationProfileTypes.WORKFLOWS, "instance.delete"],
      [delegationProfileTypes.BPM, "instance.execute"],
      [delegationProfileTypes.MANAGED_ENTITIES, "general.addCategory"],
      [delegationProfileTypes.MANAGED_ENTITIES, "general.modify"],
      [delegationProfileTypes.MANAGED_ENTITIES, "general.delete"],
      [delegationProfileTypes.MANAGED_ENTITIES, "general.provisioning"],
    ]),
    shallowEqual,
  );
  const canManageWF =
    canCreateWFInstance || canUpdateWFInstance || canDeleteWFInstance;

  function renderNodeMenu() {
    const nodeType = node.__metadata__.type;
    const deviceId = node.__metadata__.deviceId;

    if (deviceId) {
      dispatch(selectManagedEntity(deviceId));
    }

    const isManagedEntity =
      nodeType !== nodeTypes.NETWORK && nodeType !== nodeTypes.UNKNOWN_TYPE;

    const actions = [
      canManageWF && (
        <TriggerWorkflowAction
          key="trigger-wf-action"
          onDrawerClose={onClose}
          deviceId={deviceId}
        />
      ),
      canExecuteBPM && (
        <TriggerBpmAction key="trigger-bpm-action" onClose={onClose} />
      ),
      canProvision && isManagedEntity && (
        <ActivateAction key="activate-action" node={node} onChange={onChange} />
      ),
      canProvision && isManagedEntity && (
        <ActivateWithoutConnectionAction
          key="activate-without-connection-action"
          node={node}
          onChange={onChange}
        />
      ),
      canAddCategory && (
        <AddCategoryAction
          key="add-category-action"
          node={node}
          onAddCategory={onAddCategory}
          onClose={onClose}
        />
      ),

      canEditManagedEntity && isManagedEntity && (
        <EditAction key="edit-action" node={node} />
      ),
      canDeleteManagedEntity && isManagedEntity && (
        <DeleteAction key="delete-action" node={node} onChange={onChange} />
      ),
      isManagedEntity && <DetailsAction key="details-action" node={node} />,
    ].filter(Boolean);

    return actions.length ? (
      actions
    ) : (
      <MenuItem disabled>{t("No actions available")}</MenuItem>
    );
  }

  return (
    <Menu anchorPosition={position} open={Boolean(position)} onClose={onClose}>
      {node && renderNodeMenu()}
    </Menu>
  );
};

ContextMenu.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContextMenu;
