import i18n from "msa2-ui/src/localisation/i18n";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import { generateId } from "msa2-ui/src/utils/generateId";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import Dashboard from "msa2-ui/src/services/Dashboard";
import { TABLE_HEADER_COLUMNS } from "msa2-ui/src/Constants";

const isPingEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.connectionStatus,
);

export const workflowViewMode = {
  DEFAULT: {
    id: "default",
    label: "Default",
  },
  LAZY: {
    id: "lazy",
    label: i18n.t("Lazy Loading"),
  },
};

export const automationSortKeys = [
  { id: "lastupdated", text: "Updated Time" },
  { id: "name", text: "Instance Name" },
];
const automationSortKeysValues = automationSortKeys.map(
  (sortKey) => sortKey.id,
);
const automationSortKeysLabels = automationSortKeys.map(
  (sortKey) => sortKey.text,
);

const commonSortOrder = [
  { id: 0, text: "Descending" },
  { id: 1, text: "Ascending" },
];
const commonSortOrderValues = commonSortOrder.map((orderKey) => orderKey.id);
const commonSortOrderLabels = commonSortOrder.map((orderKey) => orderKey.text);

export const systemThemeId = 2;
export const darkThemeId = 1;
const themeOptions = [
  { id: 0, text: "Light" },
  { id: darkThemeId, text: "Dark" },
  { id: systemThemeId, text: "Same as OS" },
];
const themeOptionsValues = themeOptions.map((theme) => theme.id);
const themeOptionsLabels = themeOptions.map((theme) => theme.text);

const UNITS = {
  second: {
    label: "second(s)",
    multiplier: 1000,
  },
  minute: {
    label: "minute(s)",
    multiplier: 60000,
  },
  px: {
    label: "px",
  },
};

const defaultMultiplier = 1000;

const getDefaultColumns = (namespace) =>
  TABLE_HEADER_COLUMNS[namespace]
    .filter(({ check }) => check)
    .map(({ id }) => id);

export const SETTINGS = {
  language: {
    label: i18n.t("Language"),
    type: "text",
    options: ["en", "jp"],
    defaultValue: "en",
    onChange: (lng) => {
      i18n.changeLanguage(lng);
    },
  },
  theme: {
    label: i18n.t("Theme"),
    type: "text",
    options: themeOptionsValues,
    optionsLabels: themeOptionsLabels,
    defaultValue: themeOptionsValues[darkThemeId],
  },
  autoRefresh: {
    label: i18n.t("Auto Refresh"),
    type: "number",
    unitLabel: UNITS.second.label,
    unitMultiplier: UNITS.second.multiplier,
    maxValue: Math.pow(2, 28) / 1000,
    managedEntityStatus: {
      minValue: 60,
      defaultValue: 60,
      label: i18n.t("Managed Entity Status"),
      disabled: true,
    },
    ping: {
      minValue: 1,
      defaultValue: 5,
      label: i18n.t("Ping"),
      disabled: !isPingEnabled,
    },
    notification: {
      minValue: 60,
      defaultValue: 60,
      label: i18n.t("Notification"),
    },
    pollingInterval: {
      minValue: 1,
      defaultValue: 2,
      label: i18n.t("Polling Interval"),
    },
    topology: {
      minValue: 1,
      defaultValue: 30,
      label: i18n.t("Topology"),
      unitLabel: UNITS.minute.label,
      unitMultiplier: UNITS.minute.multiplier,
    },
    alarms: {
      minValue: 30,
      defaultValue: 30,
      label: i18n.t("Alarms"),
    },
  },
  drawerWidth: {
    label: i18n.t("Drawer Width"),
    unitLabel: UNITS.px.label,
    automationDetail: {
      label: i18n.t("Workflow"),
      type: "number",
      minValue: 600,
      defaultValue: 1000,
    },
  },
  viewMode: {
    label: i18n.t("View Mode"),
    type: "text",
    automationDetail: {
      label: i18n.t("Workflow Details"),
      options: Object.values(workflowViewMode).map(({ id }) => id),
      optionsLabels: Object.values(workflowViewMode).map(({ label }) => label),
      defaultValue: workflowViewMode.DEFAULT.id,
    },
  },
  tableRows: {
    label: i18n.t("Table Rows"),
    type: "number",
    options: [20, 50, 100],
    dashboard: {
      label: i18n.t("Manager Dashboard"),
      options: [12, 24, 36],
    },
    managedEntities: {
      label: i18n.t("Managed Entities"),
    },
    managedEntityLogs: {
      label: i18n.t("Managed Entities - Logs"),
    },
    managedEntitiesConfigure: {
      label: i18n.t("Managed Entities - Configure"),
      options: [10, 25, 50, 100],
    },
    history: {
      label: i18n.t("History"),
    },
    logs: {
      label: i18n.t("Logs"),
    },
    configurations: {
      label: i18n.t("Microservice"),
    },
    monitoringProfiles: {
      label: i18n.t("Monitoring Profiles"),
    },
    bpmOverview: {
      label: i18n.t("BPM Overview"),
    },
    bpmDetails: {
      label: i18n.t("BPM Details"),
    },
    automation: {
      label: i18n.t("Workflow"),
    },
    aiStates: {
      label: i18n.t("AI States"),
    },
    admin: {
      label: i18n.t("Admin"),
    },
    permissionProfiles: {
      label: i18n.t("Permission Profiles"),
    },
    profileAuditLogs: {
      label: i18n.t("Audit Logs"),
    },
    alarms: {
      label: i18n.t("Alarms"),
    },
    incidentTickets: {
      label: i18n.t("Incident Tickets"),
    },
  },
  tableSortKey: {
    label: i18n.t("Table Sort Key"),
    type: "text",
    automation: {
      label: i18n.t("Workflow Instances"),
      options: automationSortKeysValues,
      optionsLabels: automationSortKeysLabels,
      defaultValue: automationSortKeysValues[0],
    },
  },
  tableSortOrder: {
    label: i18n.t("Table Sort Order"),
    type: "text",
    options: commonSortOrderValues,
    automation: {
      label: i18n.t("Workflow Instances"),
      optionsLabels: commonSortOrderLabels,
      defaultValue: commonSortOrderValues[0],
    },
  },
  columns: {
    label: i18n.t("Columns"),
    type: "text",
    options: [],
    isMulti: true,
    valueField: "id",
    labelField: "name",
    alarms: {
      label: i18n.t("Alarms"),
      defaultValue: getDefaultColumns("alarms"),
      options: TABLE_HEADER_COLUMNS.alarms,
      unremovableOptions: ["check", "details"],
    },
    logs: {
      label: i18n.t("Logs"),
      defaultValue: getDefaultColumns("logs"),
      options: TABLE_HEADER_COLUMNS.logs,
      unremovableOptions: ["details"],
    },
    managedEntityLogs: {
      label: i18n.t("Managed Entity Logs"),
      defaultValue: getDefaultColumns("managedEntityLogs"),
      options: TABLE_HEADER_COLUMNS.managedEntityLogs,
      unremovableOptions: ["details"],
    },
  },
};
const initialValues = Object.entries(SETTINGS).reduce((acc, [key, value]) => {
  const subObject = Object.entries(value).reduce(
    (acc, [subKey, { label, defaultValue, options }]) => {
      return label
        ? {
            ...acc,
            [subKey]:
              defaultValue ??
              options?.[0] ??
              value.defaultValue ??
              value.options?.[0],
          }
        : acc;
    },
    {},
  );

  return Object.values(value).every(({ label }) => !label)
    ? { ...acc, [key]: value.defaultValue ?? value.options?.[0] }
    : { ...acc, [key]: subObject };
}, {});

export const initialState = {
  ...initialValues,
  dashboards: Dashboard.INITIAL_SETTINGS,
  selectedDashboard: { index: 0 },
};

const settings = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    initializeSettings(state, action) {
      const { key, value } = action.payload;
      set(state, key, { ...state[key], ...value });
    },
    changeSettings(state, action) {
      set(state, action.payload.key, action.payload.value);
    },
    changeLanguage(state, action) {
      const lang = action.payload;
      if (SETTINGS.language.options.includes(lang)) {
        state.language = lang;
        SETTINGS.language.onChange(lang);
      }
    },
    changeSettingsTheme(state, action) {
      state.theme = action.payload;
    },
    changeDrawerWidthSetting(state, action) {
      state.drawerWidth[action.payload.drawer] = action.payload.width;
    },
    changeTableRowsSetting(state, action) {
      state.tableRows[action.payload.table] = action.payload.numberOfRows;
    },
    changeTableSortKeySetting(state, action) {
      state.tableSortKey[action.payload.table] = action.payload.sortKey;
    },
    changeTableSortOrderSetting(state, action) {
      state.tableSortOrder[action.payload.table] = action.payload.sortOrder;
    },
    changeAutoRefreshSetting(state, action) {
      state.autoRefresh[action.payload.type] = action.payload.interval;
    },
    setDashboard(state, { payload: { dashboardIndex = 0, value } }) {
      state.dashboards[dashboardIndex] = value;
    },
    addNewDashboard(state, { payload: { dashboardName } }) {
      const indexToAdd = state.dashboards.length;
      const initialDashboard = {
        ...initialState.dashboards[0],
        name: dashboardName,
      };
      state.dashboards[indexToAdd] = initialDashboard;
    },
    deleteDashboardByIndex(state, { payload: { dashboardIndex } }) {
      state.dashboards.splice(dashboardIndex, 1);
    },
    setSelectedDashboard(state, action) {
      state.selectedDashboard.index = action.payload;
    },
    setAllDashboards(state, action) {
      state.dashboards = action.payload;
    },
    addDashboardSetting(
      state,
      { payload: { dashboardIndex = 0, breakpoints } },
    ) {
      const key = generateId(Dashboard.ID_PREFIX);
      const contents = state.dashboards[dashboardIndex].contents;
      state.dashboards[dashboardIndex].contents = contents.concat({
        key,
        style: "Dashboard Panel",
        type: "MSA Component",
        component: "",
        title: "",
      });
      const layouts = Object.entries(
        state.dashboards[dashboardIndex].layouts,
      ).reduce((acc, [breakpoint, layout]) => {
        const previousContents = contents[contents.length - 1];
        const previousLayout =
          layout.find(({ i }) => i === previousContents.key) ??
          layout[layout.length - 1];
        const { x, y, w, h } = previousLayout;

        const gridSize = Dashboard.getGridSize(breakpoint, breakpoints);
        return {
          ...acc,
          [breakpoint]: layout.concat({
            i: key,
            x: (x + w) % gridSize,
            y: (x + w) / gridSize >= 1 ? y + h : y,
            w,
            h,
          }),
        };
      }, {});
      state.dashboards[dashboardIndex].layouts = layouts;
    },
    changeDashboardSetting(
      state,
      { payload: { dashboardIndex = 0, componentIndex, key, value } },
    ) {
      state.dashboards[dashboardIndex].contents[componentIndex][key] = value;
    },
    changeDashboardLayout(
      state,
      {
        payload: { dashboardIndex = 0, layouts, i: _i, breakpoint, key, value },
      },
    ) {
      if (layouts) {
        state.dashboards[dashboardIndex].layouts = layouts;
      }
      const layout = state.dashboards[dashboardIndex].layouts[breakpoint];
      if (layout) {
        const layoutIndex = layout.findIndex(({ i }) => i === _i);
        state.dashboards[dashboardIndex].layouts[breakpoint][layoutIndex] = {
          ...layout[layoutIndex],
          [key]: value,
        };
      }
    },
    deleteDashboardSetting(
      state,
      { payload: { dashboardIndex = 0, componentIndex } },
    ) {
      let id;
      state.dashboards[dashboardIndex].contents = state.dashboards[
        dashboardIndex
      ].contents.filter(({ key }, i) => {
        // remove deleted component and get id.
        if (i === componentIndex) {
          id = key;
          return false;
        }
        return true;
      });
      state.dashboards[dashboardIndex].layouts = Object.entries(
        state.dashboards[dashboardIndex].layouts,
      ).reduce(
        (acc, [size, layout]) => ({
          ...acc,
          // remove deleted layout
          [size]: layout.filter(({ i }) => i !== id),
        }),
        {},
      );
    },
    setDashboardSetting(state, { payload: { dashboardIndex = 0, value } }) {
      state.dashboards[dashboardIndex] = value;
    },
    setDashboardNameByIndex(state, { payload: { dashboardIndex, value } }) {
      state.dashboards[dashboardIndex].name = value;
    },
    resetDashboardSetting(state, { payload: { dashboardIndex = 0, value } }) {
      state.dashboards[dashboardIndex] = isEmpty(value)
        ? initialState.dashboards
        : value;
    },
    setTableRowsSetting(state, action) {
      state.tableRows = isEmpty(action.payload)
        ? initialValues.tableRows
        : action.payload;
    },
    setTableSortKeySetting(state, action) {
      state.tableSortKey = isEmpty(action.payload)
        ? initialValues.tableSortKey
        : action.payload;
    },
    setTableSortOrderSetting(state, action) {
      state.tableSortOrder = isEmpty(action.payload)
        ? initialValues.tableSortOrder
        : action.payload;
    },
    setAutoRefreshSetting(state, action) {
      state.autoRefresh = isEmpty(action.payload)
        ? initialValues.autoRefresh
        : { ...initialValues.autoRefresh, ...action.payload };
    },
    replaceColumns(state, action) {
      const { table, columns } = action.payload;
      state.columns[table] = columns;
    },
    addColumn(state, action) {
      const { table, columnName } = action.payload;
      state.columns[table] = state.columns[table].concat(columnName);
    },
    removeColumn(state, action) {
      const { table, columnName } = action.payload;
      state.columns[table] = state.columns[table].filter(
        (column) => column !== columnName,
      );
    },
    updateColumns(state, action) {
      action.payload.checked
        ? state.columns[action.payload.table].push(action.payload.value)
        : state.columns[action.payload.table].pop(action.payload.value);
    },
  },
  extraReducers: {
    // We have to use the string directly here because if
    // we import the auth module we get a circular dependency
    "auth/logout": () => {
      return initialState;
    },
  },
});

export const getSettings = ({ settings }) => settings;

export const getDrawerWidthSetting = (type) => ({ settings }) =>
  settings.drawerWidth[type];

export const getTableRowsSetting = (type) => ({ settings }) =>
  settings.tableRows[type];

export const getTableSortKeySetting = (type) => ({ settings }) =>
  settings.tableSortKey[type];

export const getTableSortOrderSetting = (type) => ({ settings }) =>
  settings.tableSortOrder[type];

export const getLanguage = ({ settings }) => settings.language;

export const getSettingsTheme = ({ settings }) => settings.theme;

export const getAutoRefreshSetting = (type) => ({ settings }) => {
  const interval =
    settings.autoRefresh[type] || initialValues.autoRefresh[type];
  const multiplier =
    SETTINGS.autoRefresh[type]?.unitMultiplier ||
    SETTINGS.autoRefresh?.unitMultiplier ||
    defaultMultiplier;
  return interval * multiplier;
};

export const getViewMode = (type) => ({ settings }) => settings.viewMode[type];

export const getDashboards = ({ settings }) => settings.dashboards;

export const getDashboardSetting = (dashboardIndex) => ({ settings }) =>
  settings.dashboards[dashboardIndex];

export const getSelectedDashboard = ({ settings }) =>
  settings.selectedDashboard.index;

export const getColumnSetting = (type) => ({ settings }) =>
  settings.columns[type];

export const {
  initializeSettings,
  changeSettings,
  changeLanguage,
  changeSettingsTheme,
  changeDrawerWidthSetting,
  changeTableRowsSetting,
  changeTableSortKeySetting,
  changeTableSortOrderSetting,
  changeAutoRefreshSetting,
  addDashboardSetting,
  changeDashboardSetting,
  changeDashboardLayout,
  deleteDashboardSetting,
  setDashboardSetting,
  setDashboardNameByIndex,
  setDashboard,
  addNewDashboard,
  deleteDashboardByIndex,
  setSelectedDashboard,
  setAllDashboards,
  setTableRowsSetting,
  setTableSortKeySetting,
  setTableSortOrderSetting,
  setAutoRefreshSetting,
  updateColumns,
  addColumn,
  removeColumn,
  replaceColumns,
  resetDashboardSetting,
} = settings.actions;

export default settings.reducer;
