import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useRouteMatch } from "react-router-dom";
import NumberFormat from "react-number-format";
import get from "lodash/get";

import { buildRoute } from "msa2-ui/src/utils/urls";
import { getToken } from "msa2-ui/src/store/auth";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import {
  getDelegationProfile,
  delegationProfileTypes,
} from "msa2-ui/src/store/delegationProfiles";

import { sortOrder } from "msa2-ui/src/Constants";
import {
  changeTableRowsSetting,
  getTableRowsSetting,
} from "msa2-ui/src/store/settings";
import useApi from "msa2-ui/src/hooks/useApi";
import useDialog from "msa2-ui/src/hooks/useDialog";
import {
  getDeploymentSettingsBySubtenant,
  getSortBy,
  deleteDeploymentSetting,
} from "msa2-ui/src/api/deploymentSettings";
import { getDeploymentSettingsCount } from "./helpers";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";

import { makeStyles } from "@material-ui/core";
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableRow as MUITableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import CommonStyles from "msa2-ui/src/styles/commonStyles";
import {
  FlashOn as MicroserviceIcon,
  SpeakerPhone as ManagedEntityIcon,
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";

import AlertBar from "msa2-ui/src/components/AlertBar";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import TableMessage from "msa2-ui/src/components/TableMessage";
import TableRow from "msa2-ui/src/components/TableRow";
import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";

const [SORT_DESC, SORT_ASC] = sortOrder;

const tableHeaderColumns = [
  {
    id: "name",
    name: "Deployment Setting Name",
    sortable: true,
    align: "left",
  },
  {
    id: "vendor",
    name: "Vendor / Model",
    align: "left",
    sortable: true,
  },
  {
    id: "mod_date",
    name: "Date Modified",
    align: "left",
    sortable: true,
  },
  {
    id: "microservices",
    name: "Microservices",
    align: "center",
    sortable: false,
  },
  {
    id: "mnagedEntities",
    name: "Managed Entities",
    align: "center",
    sortable: false,
  },
  {
    id: "blankDelete",
    name: "",
    align: "right",
    sortable: false,
  },
];

const useStyles = makeStyles(({ palette, typography }) => ({
  createButtonIcon: {
    marginRight: 5,
  },
  tableInstances: {
    padding: "10px 0 2px 10px",
  },
  tableInstancesComment: {
    fontSize: "0.75rem",
    fontWeight: typography.fontWeightLight,
    opacity: "0.8",
    margin: "8px 8px 0 8px",
  },
}));

const useCommonStyles = makeStyles(CommonStyles);

const Loading = () => {
  const commonClasses = useCommonStyles();
  return (
    <TableBody>
      <MUITableRow>
        <TableCell
          colSpan={5}
          className={commonClasses.commonLoaderWrapperTable}
        >
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress aria-label="Loading" />
          </Grid>
        </TableCell>
      </MUITableRow>
    </TableBody>
  );
};

const AlertBarError = ({ onReload, onClose, message }) => (
  <TableBody>
    <MUITableRow>
      <TableCell colSpan={5}>
        <AlertBar message={message} refreshFnc={onReload} closeFnc={onClose} />
      </TableCell>
    </MUITableRow>
  </TableBody>
);

const Overview = ({ sectionTabs: SectionTabs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { isExact } = useRouteMatch();

  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const subtenantId = useSelector(getSelectedSubtenant).id;
  const rowsPerPage = useSelector(getTableRowsSetting("configurations"));

  const canEdit = useSelector(
    getDelegationProfile(
      delegationProfileTypes.DEPLOYMENT_SETTINGS,
      "general.modify",
    ),
  );
  const canDelete = useSelector(
    getDelegationProfile(
      delegationProfileTypes.DEPLOYMENT_SETTINGS,
      "general.delete",
    ),
  );

  const [selectedDeploymentSetting, setSelectedDeploymentSetting] = useState(
    null,
  );
  const [filterSortBy, setFilterSortBy] = useState("mod_date");
  const [filterSortOrder, setFilterSortOrder] = useState(SORT_DESC);
  const [, setFilterBy] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const [
    deploymentSettingsLoading,
    deploymentSettingsError,
    deploymentSettingsList = [],
    meta,
    reloadDeploymentSettings,
  ] = useApi(
    getDeploymentSettingsBySubtenant,
    {
      subtenantId,
      pageSize: rowsPerPage,
      sortBy: filterSortBy,
      page: searchTerm ? 1 : page + 1,
      sortOrder: filterSortOrder.text,
      filterCriteria: searchTerm,
    },
    !subtenantId,
  );

  /**
   * If we navigate back to this page after creating or editing
   * we want to make sure have the latest deployment settings
   */
  const wasExact = useRef(isExact);
  useEffect(() => {
    if (isExact && !wasExact.current) {
      reloadDeploymentSettings();
    }
    if (isExact !== wasExact.current) {
      wasExact.current = isExact;
    }
  }, [isExact, reloadDeploymentSettings]);

  /**
   * Reset the seach value when we unselect the SubtenantId
   */
  useEffect(() => {
    if (!subtenantId) {
      setSearchTerm("");
    }
  }, [subtenantId]);

  const deploymentSettingsCount = getDeploymentSettingsCount(subtenantId, meta);

  const setRowsPerPage = (e) => {
    dispatch(
      changeTableRowsSetting({
        table: "configurations",
        numberOfRows: e.target.value,
      }),
    );

    setPage(Math.floor((page * rowsPerPage) / e.target.value));
  };

  const handleSortByChange = (id) => {
    if (!getSortBy(id)) {
      return;
    }

    const sameHeader = filterSortBy === id;
    const newSortOrder =
      sameHeader && filterSortOrder === SORT_DESC ? SORT_ASC : SORT_DESC;
    setFilterSortOrder(newSortOrder);
    setFilterSortBy(id);
  };

  const handleDelete = async () => {
    const [error, deleted] = await deleteDeploymentSetting({
      token,
      profileId: selectedDeploymentSetting.id,
    });
    if (error) {
      enqueueSnackbar(
        error.getMessage(
          t("Unable to delete x", {
            x: selectedDeploymentSetting.name,
          }),
        ),
        {
          variant: "error",
          action: (key) => (
            <SnackbarAction id={key} handleClose={closeSnackbar} />
          ),
        },
      );
    } else if (deleted) {
      enqueueSnackbar(
        t("x has been deleted", {
          x: selectedDeploymentSetting.name,
        }),
        {
          variant: "success",
          action: (key) => (
            <SnackbarAction id={key} handleClose={closeSnackbar} />
          ),
        },
      );
      reloadDeploymentSettings();
    }
    setSelectedDeploymentSetting(null);
  };

  const renderMessages = () => {
    if (deploymentSettingsLoading && !deploymentSettingsList.length) {
      return <Loading />;
    }
    if (deploymentSettingsError) {
      return (
        <AlertBarError
          message={t("Unable to load x", { x: t("Deployment Settings") })}
        />
      );
    }
    if (!subtenantId) {
      return (
        <TableMessage
          message={t("Please select a subtenant to view deployment settings")}
          numberOfTableColumns={5}
        />
      );
    }
    if (deploymentSettingsList.length === 0) {
      return (
        <TableMessage
          message={t("No Deployment Settings are created")}
          numberOfTableColumns={5}
        />
      );
    }
  };

  const TableHeader = ({ id, align, name, sortable }) => {
    return (
      <TableCell
        key={id}
        align={align}
        className={commonClasses.commonTableCellDefault}
      >
        {name !== "" && (
          <TableSortLabel
            id={id}
            active={filterSortBy === id}
            direction={filterSortOrder.text}
            onClick={() => handleSortByChange(id)}
            hideSortIcon={!sortable}
            classes={{
              root: commonClasses.commonTableSortLabel,
              active: commonClasses.commonTableSortLabelActive,
              icon: commonClasses.commonTableSortLabelIcon,
            }}
          >
            {name}
          </TableSortLabel>
        )}
      </TableCell>
    );
  };

  const [showDeleteDialog, DeleteDialog] = useDialog();

  return (
    <>
      <DeleteDialog
        title={t("Delete Deployment Setting?")}
        content={t("Are you sure you want to delete x?", {
          x: get(selectedDeploymentSetting, "name"),
        })}
        onExec={handleDelete}
      />
      <SectionTabs
        count={[pathname, deploymentSettingsCount]}
        endElements={
          <CreateEntityButton
            id="DEPLOYMENT_SETTING_BTN_CREATE"
            aria-label={t("Create Deployment Setting")}
            link={buildRoute(pathname, "create")}
            delegationProfileAction="general.create"
            delegationProfileType={delegationProfileTypes.DEPLOYMENT_SETTINGS}
          >
            {t("Create Deployment Setting")}
          </CreateEntityButton>
        }
      />
      <Paper
        className={classNames(
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        )}
      >
        <div>
          <Table>
            <TableHead>
              <MUITableRow>
                <TableCell
                  colSpan={tableHeaderColumns.length}
                  className={commonClasses.commonTableCell}
                >
                  <FilterMenu
                    handleFilterByChange={setFilterBy}
                    handleSearchByChange={setSearchTerm}
                    searchValue={searchTerm}
                    tpRowsPerPage={rowsPerPage}
                    tpTotal={deploymentSettingsCount}
                    tpPage={page}
                    tpChangePage={(e, value) => {
                      setPage(value);
                    }}
                    tpChangeRowsPerPage={setRowsPerPage}
                  />
                </TableCell>
              </MUITableRow>
              <MUITableRow className={commonClasses.commonTableHeadRow}>
                {tableHeaderColumns.map((header) => (
                  <TableHeader key={header.id} {...header} />
                ))}
              </MUITableRow>
            </TableHead>
            {renderMessages()}
            {deploymentSettingsList.length > 0 && subtenantId && (
              <TableBody>
                {deploymentSettingsList.map((deploymentSetting) => (
                  <TableRow
                    key={deploymentSetting.id}
                    actions={
                      <>
                        {canEdit && (
                          <Link
                            to={`${pathname}/${deploymentSetting.id}/edit`}
                            id={`CONFIG_DEPLOYMENT_SETTING_BTN_EDIT_${deploymentSetting.id}`}
                            aria-label="Edit"
                          >
                            <IconButton>
                              <EditIcon color="primary" />
                            </IconButton>
                          </Link>
                        )}
                        {canDelete && (
                          <IconButton
                            id={`CONFIG_DEPLOYMENT_SETTING_BTN_DELETE_${deploymentSetting.id}`}
                            aria-label="Delete"
                            onClick={() => {
                              showDeleteDialog();
                              setSelectedDeploymentSetting(deploymentSetting);
                            }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        )}
                      </>
                    }
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <div className={classes.tableInstances}>
                        <Typography
                          variant="h4"
                          className={commonClasses.commonTablePrimary}
                        >
                          {deploymentSetting.name}
                        </Typography>
                        <Typography className={classes.tableInstancesComment}>
                          {deploymentSetting.comment}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <Typography
                        variant="body2"
                        className={commonClasses.commonTableInstance}
                      >
                        {deploymentSetting.vendor.name} /{" "}
                        {deploymentSetting.model.name}
                      </Typography>
                    </TableCell>
                    <TableCell className={commonClasses.commonTableCellDefault}>
                      <Typography
                        variant="body1"
                        className={commonClasses.commonTableSecondary}
                      >
                        {displayMonthDayYearTimeDate(
                          deploymentSetting.lastUpdate,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <div className={commonClasses.commonFlexCenter}>
                        <Typography
                          variant="body1"
                          className={commonClasses.commonTableInstancesNumber}
                        >
                          <NumberFormat
                            value={deploymentSetting.microserviceUris.length}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </Typography>
                        {canEdit ? (
                          <Link
                            to={`${pathname}/${deploymentSetting.id}/edit/microservices`}
                          >
                            <IconButton>
                              <MicroserviceIcon color="primary" />
                            </IconButton>
                          </Link>
                        ) : (
                          <MicroserviceIcon color="primary" />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={commonClasses.commonTableCellDefault}
                    >
                      <div className={commonClasses.commonFlexCenter}>
                        <Typography
                          variant="body1"
                          className={commonClasses.commonTableInstancesNumber}
                        >
                          <NumberFormat
                            value={
                              deploymentSetting.attachedManagedEntities.length
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </Typography>
                        {canEdit ? (
                          <Link
                            to={`${pathname}/${deploymentSetting.id}/edit/managed-entities`}
                          >
                            <IconButton>
                              <ManagedEntityIcon color="primary" />
                            </IconButton>
                          </Link>
                        ) : (
                          <ManagedEntityIcon color="primary" />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </div>
      </Paper>
    </>
  );
};

Overview.propTypes = {
  sectionTabs: PropTypes.func.isRequired,
};

export default Overview;
