import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { useTranslation } from "react-i18next";
import Variable from "msa2-ui/src/services/Variable";

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles(({ palette, darkMode }) => ({
  headerCell: {
    whiteSpace: "nowrap",
    backgroundColor: darkMode ? palette.background.default : "#f2f4f7",
    border: 0,
    "&:not(:last-child)": {
      borderRight: "1px dotted rgba(255, 255, 255, 0.1)",
    },
  },
  headerFont: {
    fontWeight: 500,
  },
  headerCellPrimary: {
    width: 200,
  },
}));

const DIRECTION = ["asc", "desc"];
const VariableViewTableHeader = ({
  variables,
  columnCount,
  primaryLabel,
  colSpanSuffix,
  onClickLabel,
  SortIcon,
  shouldShowSearchBox = false,
  searchValue = "",
  setSearchValue,
  classes: classesFromProp = {},
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const [active, setActive] = useState();
  const [sortDirection, setSortDirection] = useState(0);
  const [searchKey] = searchValue.split(":");

  const targetVariables = Variable.filterEditOnly(variables || []).slice(
    0,
    columnCount,
  );
  const sectionLength =
    targetVariables.length === 0
      ? 0
      : Math.max(
          ...targetVariables.map((variable) => variable.sections?.length ?? 0),
        );

  return (
    <TableHead>
      {[...Array(sectionLength)].map((_, i) => {
        const secNum = sectionLength - i - 1;
        return (
          <TableRow className={commonClasses.commonTableHeadRow} key={i}>
            {primaryLabel && <TableCell className={classes.headerCell} />}
            {targetVariables?.map((variable, index) => {
              const getSectionLabel = (variable) => {
                return variable.sections?.[secNum];
              };
              const currentSection = !variable.grouped
                ? getSectionLabel(variable)
                : undefined;

              // If section is the same as the previous one,
              // return null as it's already rendered
              if (index !== 0) {
                const previousVariable = targetVariables[index - 1];
                const previousSection = getSectionLabel(previousVariable);
                const hasBeenRendered =
                  Boolean(currentSection) && currentSection === previousSection;
                if (hasBeenRendered) return null;
                if (variable.grouped) {
                  const hasBeenRendered =
                    variable.groupDisplayName ===
                    previousVariable.groupDisplayName;
                  if (hasBeenRendered) return null;
                }
              }

              const colSpan = currentSection
                ? targetVariables
                    .slice(index)
                    .reduce((cur, variable, i, arr) => {
                      if (currentSection !== getSectionLabel(variable)) {
                        // break
                        arr.splice(1);
                        return cur;
                      }
                      return i + 1;
                    }, 1)
                : 1;

              if (variable.grouped) {
                const hasBeenRendered =
                  variable.groupDisplayName ===
                  targetVariables[index - 1].groupDisplayName;
                if (hasBeenRendered) return null;
              }

              return (
                <TableCell
                  key={variable.name}
                  variant={"head"}
                  colSpan={colSpan}
                  className={classes.headerCell}
                  align={colSpan ? "center" : "left"}
                >
                  {currentSection}
                </TableCell>
              );
            })}
            {Boolean(colSpanSuffix) && (
              <TableCell
                className={classes.headerCell}
                colSpan={colSpanSuffix}
              />
            )}
          </TableRow>
        );
      })}

      <TableRow className={commonClasses.commonTableHeadRow}>
        {primaryLabel && (
          <TableCell
            variant={"head"}
            className={classnames(
              classes.headerCell,
              classes.headerCellPrimary,
              classesFromProp.headerCellPrimary,
            )}
          >
            {primaryLabel}
          </TableCell>
        )}
        {targetVariables?.map((variable, index) => {
          const { id, isTable } = Variable.getTableValData(variable.name);
          if (variable.grouped) {
            if (index !== 0) {
              const hasBeenRendered =
                variable.groupDisplayName ===
                variables[index - 1].groupDisplayName;
              if (hasBeenRendered) return null;
            }
            return (
              <TableCell
                key={variable.name}
                variant={"head"}
                className={classes.headerCell}
              >
                <Tooltip title={variable.groupDisplayName}>
                  <Typography
                    variant="body2"
                    className={classnames(
                      commonClasses.overflowEllipsis,
                      classes.headerFont,
                    )}
                  >
                    {variable.groupDisplayName}
                  </Typography>
                </Tooltip>
              </TableCell>
            );
          }
          return (
            <TableCell
              key={variable.name}
              variant={"head"}
              className={classes.headerCell}
            >
              <div
                style={{
                  width:
                    (variable.maxLength || Variable.DEFAULT_TABLE_WIDTH) - 21,
                }}
              >
                {(() => {
                  if (SortIcon && onClickLabel) {
                    return (
                      <Tooltip
                        title={t("Click to select an action for this Variable")}
                      >
                        <TableSortLabel
                          id={`VARIABLE_VIEW_TABLE_SORT_${variable.name}`}
                          onClick={(event) => {
                            onClickLabel && onClickLabel(event, variable);
                          }}
                          IconComponent={SortIcon}
                        >
                          {variable.displayName}
                        </TableSortLabel>
                      </Tooltip>
                    );
                  }
                  if (onClickLabel && !isTable) {
                    return (
                      <Tooltip title={variable.displayName} enterDelay={500}>
                        <TableSortLabel
                          id={`VARIABLE_VIEW_TABLE_SORT_${variable.name}`}
                          active={active === variable.name}
                          direction={DIRECTION[sortDirection]}
                          onClick={() => {
                            const direction =
                              variable.name === active ? 1 - sortDirection : 0;
                            setActive(variable.name);
                            setSortDirection(direction);
                            onClickLabel({
                              active: Variable.removePrefix(variable.name),
                              direction: DIRECTION[direction],
                              variable,
                            });
                          }}
                          IconComponent={SortIcon}
                        >
                          {variable.displayName}
                        </TableSortLabel>
                      </Tooltip>
                    );
                  }
                  return (
                    <Tooltip title={variable.displayName} enterDelay={500}>
                      <Typography
                        variant="body2"
                        className={classnames(
                          commonClasses.overflowEllipsis,
                          classes.headerFont,
                        )}
                      >
                        {variable.displayName}
                      </Typography>
                    </Tooltip>
                  );
                })()}
              </div>
              {shouldShowSearchBox && (
                <TextField
                  id={`VARIABLE_VIEW_TABLE_SEARCH_${id}`}
                  data-testid={`VARIABLE_VIEW_TABLE_SEARCH_${id}`}
                  value={
                    id === searchKey
                      ? searchValue.slice(searchKey.length + 1)
                      : ""
                  }
                  onChange={({ target: { value } }) => {
                    if (value) {
                      setSearchValue(id + ":" + value);
                    } else {
                      setSearchValue("");
                    }
                  }}
                />
              )}
            </TableCell>
          );
        })}
        {Boolean(colSpanSuffix) && (
          <TableCell className={classes.headerCell} colSpan={colSpanSuffix} />
        )}
      </TableRow>
    </TableHead>
  );
};

VariableViewTableHeader.propTypes = {
  variables: PropTypes.array.isRequired,
  columnCount: PropTypes.number,
  primaryLabel: PropTypes.node,
  colSpanSuffix: PropTypes.number,
  onClickLabel: PropTypes.func,
  SortIcon: PropTypes.object,
  actions: PropTypes.array,
  shouldShowSearchBox: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
};

export default VariableViewTableHeader;
