import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import {
  getSelectedTenant,
  getSelectedSubtenant,
  selectSubtenantByUbiqubeId,
} from "msa2-ui/src/store/designations";
import {
  displayYearMonthTimeDateAsES,
  formatDateOrString,
} from "msa2-ui/src/utils/date";
import { workflowStatus } from "msa2-ui/src/Constants";
import { getWorkflowReportByActor } from "msa2-ui/src/api/workflow";
import useApi from "msa2-ui/src/hooks/useApi";

import {
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import TotalWorkflowChart from "msa2-ui/src/components/TotalWorkflowChart";
import AlertBar from "msa2-ui/src/components/AlertBar";
import { getIsDeveloper } from "msa2-ui/src/store/auth";

import useWorkflowDrawer from "msa2-ui/src/hooks/useWorkflowDrawer";

const useStyles = makeStyles(
  ({ darkMode, palette, typography, colors, spacing }) => ({
    paper: {
      padding: spacing(2),
      boxShadow: "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
      height: "100%",
    },
    instancePaper: {
      margin: 10,
    },
    instanceStatus: {
      marginTop: 10,
    },
    processHeading: {
      cursor: "pointer",
      textTransform: "capitalize",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "232px",
      fontSize: "1rem",
    },
    paperLeft: {
      display: "flex",
      textAlign: "left",
      flexDirection: "row",
      justifyContent: "left",
    },
    bold: {
      fontFamily: "Rubik",
      fontSize: "0.8125rem",
      fontWeight: typography.fontWeightMedium,
      color: darkMode ? palette.text.primary : colors.blueDark1,
    },
    regular: {
      fontFamily: "Rubik",
      fontSize: "0.79rem",
      fontWeight: typography.fontWeightLight,
      color: palette.text.primary,
    },
    offsetDivider: {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: -16,
      width: "calc(100% + 32px)",
    },
    titleWrapper: {
      display: "flex",
    },
    loaderWrapper: {
      height: 640,
    },
  }),
);

const StatusIcon = ({ status: wfStatus }) => {
  const { t } = useTranslation();
  const { icon: Icon, name } = workflowStatus.find(
    (status) => status.status === wfStatus,
  );

  return <Icon aria-label={t(name)} />;
};

const WorkflowHistory = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { id: subtenantId } = useSelector(getSelectedSubtenant);
  const { value: tenantPrefix } = useSelector(getSelectedTenant);
  const isDeveloper = useSelector(getIsDeveloper);
  const [showWorkflowDrawer, WorkflowDrawer] = useWorkflowDrawer();

  const [isLoading, isError, workflows, , reload] = useApi(
    getWorkflowReportByActor,
    {
      isDeveloper,
      subtenantId,
      tenantPrefix,
    },
  );

  const goToWorkflowDetails = (ubiqubeId, workflowPath) => {
    dispatch(selectSubtenantByUbiqubeId(ubiqubeId));
    history.push(
      `/automation/workflows/${encodeURIComponent(workflowPath)}/instances`,
    );
  };

  return (
    <>
      <WorkflowDrawer />
      <Paper id="DASHBOARD_WORKFLOWS">
        <Typography variant="h5" noWrap>
          {t("Workflow Instances")}
        </Typography>
        {Boolean(isError) && (
          <AlertBar
            message={t("Unable to load x", { x: t("Workflows") })}
            refreshFnc={reload}
          />
        )}
        {isLoading && (
          <div
            id="DASHBOARD_WORKFLOWS_LOADING_PROGRESS"
            className={classnames(
              commonClasses.commonLoaderWrapper,
              classes.loaderWrapper,
            )}
          >
            <CircularProgress />
          </div>
        )}
        {!isLoading && workflows?.recentlyExecutedProcesses && (
          <>
            <TotalWorkflowChart workflows={workflows} />
            <Divider variant="fullWidth" className={classes.offsetDivider} />
            {workflows.recentlyExecutedProcesses.processes.map(
              (
                {
                  status,
                  displayName,
                  serviceDisplayName,
                  executorName,
                  lastExecutionDate,
                  serviceName,
                  serviceId,
                  customerId: ubiqubeId,
                },
                i,
              ) => (
                <Paper key={i}>
                  <Paper className={classes.paperLeft}>
                    <Typography className={classes.instanceStatus}>
                      <StatusIcon status={status} />
                    </Typography>
                    <Paper className={classes.instancePaper}>
                      <div className={classes.titleWrapper}>
                        <Typography
                          id={`DASHBOARD_WORKFLOWS_PROCESS_NAME_${i}`}
                          className={classes.processHeading}
                          variant="h5"
                          title={displayName}
                          onClick={() => {
                            showWorkflowDrawer({
                              serviceId,
                              workflowPath: serviceName,
                              ubiqubeId,
                            });
                          }}
                        >
                          {displayName}
                        </Typography>
                      </div>
                      <Typography
                        id={`DASHBOARD_WORKFLOWS_NAME_${i}`}
                        className={classnames(
                          classes.regular,
                          commonClasses.commonLink,
                        )}
                        onClick={() => {
                          goToWorkflowDetails(ubiqubeId, serviceName);
                        }}
                      >
                        For{" "}
                        <span className={classes.bold}>
                          {serviceDisplayName}
                        </span>{" "}
                      </Typography>
                      <Typography className={classes.regular}>
                        {executorName} <span>|</span>{" "}
                        {formatDateOrString(
                          displayYearMonthTimeDateAsES(lastExecutionDate) +
                            "+0000",
                          "MMM dd, yyyy h:mm:ssaa",
                        )}
                      </Typography>
                    </Paper>
                  </Paper>
                  <Divider />
                </Paper>
              ),
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default WorkflowHistory;
