import React, { memo, useCallback, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getFormSyncErrors } from "redux-form";
import reduxForm from "redux-form/lib/reduxForm";
import FieldArray from "redux-form/lib/FieldArray";
import { makeStyles } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";
import flow from "lodash/flow";

import { returnToPreviousPage } from "msa2-ui/src/utils/urls";
import { required } from "msa2-ui/src/utils/validators";

import { createAction, getStatesList } from "msa2-ui/src/api/ai";
import { getWorkflow } from "msa2-ui/src/api/workflow";

import { getFormValues } from "msa2-ui/src/store/form";

import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import useApi from "msa2-ui/src/hooks/useApi";
import useWorkflowList from "msa2-ui/src/hooks/useWorkflowList";

import { reduxFormNames } from "msa2-ui/src/Constants";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { Grid, IconButton, Typography } from "@material-ui/core";

import FormMsaSelect from "msa2-ui/src/components/formSection/FormMsaSelect";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import Dialog from "msa2-ui/src/components/Dialog";

const NUMBER_OF_WORKFLOWS_TO_REQUEST = 10000;

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const contextFieldsRenderer = memo(({ fields }) => {
  const t = useBoundedTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ paddingBottom: 20 }}>
          {t("Pre-Condition")}
        </Typography>
      </Grid>
      {fields.map((field, rowIndex) => (
        <Grid item xs={12} key={field}>
          <Grid container spacing={4}>
            <Grid item md={3}>
              <FieldSelector
                name={`${field}.name`}
                label={t("Name")}
                type="text"
              />
            </Grid>
            <Grid item md={3}>
              <FieldSelector
                name={`${field}.value`}
                label={t("Value")}
                type="text"
              />
            </Grid>
            <Grid item md={3}>
              <IconButton
                id={`${field}.deleteBtn`}
                onClick={() => fields.remove(rowIndex)}
              >
                <IconDeleteRed />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton
          id="AI_ACTION_CREATE_IF_CONDITION_ADD_BTN"
          onClick={() => fields.push({})}
        >
          <IconPlus />
        </IconButton>
      </Grid>
    </Grid>
  );
});

const prepareWorkflowSelectOptions = (workflows) => {
  return workflows.map((workflow) => ({
    label: workflow.displayName,
    value: workflow.path,
  }));
};

const prepareProcessSelectOptions = (processes) => {
  return processes.map((process) => ({
    label: process.displayName,
    value: process.name,
    type: process.type,
  }));
};

const prepareStateSelectOptions = (states) => {
  return states.map((state) => ({
    label: state.name,
    value: state.pk,
  }));
};

const Create = ({ handleSubmit, formErrors, reloadActions }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const formValues =
    useSelector(getFormValues(reduxFormNames.aiCreateActionDialog)) || {};
  const { execute = {} } = formValues;

  const [submitting, setSubmitting] = useState(false);

  const [, , workflowsApiResponse = {}] = useWorkflowList({
    filter: { subtenant: true },
    pageSize: NUMBER_OF_WORKFLOWS_TO_REQUEST,
  });

  const { workflows = [] } = workflowsApiResponse;
  const [, , workflowApiResponse = {}] = useApi(
    getWorkflow,
    {
      pathToWorkflowDefinitionFile: execute.value,
    },
    !execute.value,
  );

  const { process = [] } = workflowApiResponse;
  const processes = process
    ? process.map(({ name, displayName, type }) => {
        return { name, displayName, type };
      })
    : [];

  const showProcessPicker = execute;

  const [, , statesApiResponse = {}] = useApi(getStatesList);
  const { states = [] } = statesApiResponse;

  const closeDialog = useCallback(() => {
    returnToPreviousPage(history, url);
    reloadActions();
  }, [history, url, reloadActions]);

  const onSubmit = useCallback(
    async ({
      name,
      description,
      execute,
      process,
      target_state_id,
      if_condition = {},
    }) => {
      if (isEmpty(formErrors)) {
        setSubmitting(true);

        const [error = {}] = await createAction({
          body: {
            name: name,
            description: description,
            execute: execute?.value,
            workflow_name: execute?.label,
            process: process?.label,
            process_path: process?.value,
            process_type: process?.type,
            target_state_id: target_state_id?.value,
            if_condition,
            condition: {},
            tag_id: "",
          },
        });
        const { message: errorMessage = "" } = error;

        setSubmitting(false);

        const variant = !isEmpty(error) ? "error" : "success";
        const message = !isEmpty(error) ? errorMessage : t("Action created.");

        if (isEmpty(error)) {
          closeDialog();
        }

        enqueueSnackbar(message, {
          variant,
          action: (key) => (
            <SnackbarAction id={key} handleClose={closeSnackbar} />
          ),
        });
      }
    },
    [closeDialog, closeSnackbar, enqueueSnackbar, formErrors, t],
  );

  const workflowsAsOptions = useMemo(
    () => prepareWorkflowSelectOptions(workflows),
    [workflows],
  );
  const processesAsOptions = useMemo(
    () => prepareProcessSelectOptions(processes),
    [processes],
  );
  const statesAsOptions = useMemo(() => prepareStateSelectOptions(states), [
    states,
  ]);

  return (
    <Dialog
      onExec={handleSubmit(onSubmit)}
      onClose={closeDialog}
      execLabel={t("Save")}
      title={t("Create Action")}
      disabled={submitting}
      maxWidth={"md"}
    >
      <Grid container direction="column">
        <FieldSelector
          type="text"
          id="AI_ACTION_CREATE_NAME"
          name="name"
          variant="outlined"
          label={t("Name")}
          validate={required}
          multiline
          noIndent
          className={classes.formField}
          required
        />

        <FieldSelector
          type="text"
          id="AI_ACTION_CREATE_DESCRIPTION"
          name="description"
          variant="outlined"
          label={t("Description")}
          multiline
          noIndent
          className={classes.formField}
        />

        <FormMsaSelect
          id="AI_ACTION_CREATE_WORKFLOW_SELECT"
          className={classes.formField}
          name="execute"
          options={workflowsAsOptions}
          validate={required}
          placeholder={t("Select workflow...")}
          isClearable
          isSearchable
        />

        {showProcessPicker && (
          <FormMsaSelect
            id="AI_ACTION_CREATE_PROCESS_SELECT"
            className={classes.formField}
            name="process"
            options={processesAsOptions}
            validate={required}
            placeholder={t("Select process...")}
            isClearable
            isSearchable
          />
        )}

        <FormMsaSelect
          id="AI_ACTION_CREATE_STATE_SELECT"
          className={classes.formField}
          name="target_state_id"
          options={statesAsOptions}
          validate={required}
          placeholder={t("Select target state...")}
          isClearable
          isSearchable
        />

        <Grid className={classes.formField} item xs={12}>
          <FieldArray name="if_condition" component={contextFieldsRenderer} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors(reduxFormNames.aiCreateActionDialog)(state),
});

export default flow(
  reduxForm({
    form: reduxFormNames.aiCreateActionDialog,
  }),
  connect(mapStateToProps),
)(Create);
