import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { Button, Paper } from "@material-ui/core";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  deletePermissionProfileById,
  getPermissionProfiles,
} from "msa2-ui/src/api/permissionProfiles";
import OverviewTable from "./overview-table/OverviewTable";
import Create from "msa2-ui/src/routes/admin/permission-profiles/Create";
import Edit from "msa2-ui/src/routes/admin/permission-profiles/Edit";
import { useSelector } from "react-redux";
import useFilter from "msa2-ui/src/hooks/useFilter";
import { sortOrder } from "msa2-ui/src/Constants";
import { getSelectedTenant } from "msa2-ui/src/store/designations";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import { buildRoute } from "msa2-ui/src/utils/urls";

const TABLE_COLUMNS = {
  name: {
    id: "name",
    name: "Name",
    align: "left",
    sortable: true,
  },
  dateModified: {
    id: "lastUpdated",
    name: "Date Modified",
    align: "center",
    sortable: true,
  },
  attachedManagers: {
    id: "attachedManagers",
    name: "Attached Managers",
    align: "center",
    sortable: false,
  },
  actions: {
    id: "actions",
    name: "",
    align: "right",
    sortable: false,
  },
};

const TABLE_COLUMNS_LIST = Object.values(TABLE_COLUMNS);

const PermissionProfiles = ({ adminTabs: AdminTabs }) => {
  const commonClasses = useCommonStyles();

  const [selectedProfile, setSelectedProfile] = useState(null);

  const { path, url } = useRouteMatch();

  const t = useBoundedTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const token = useSelector(getToken);
  const { id: tenantId } = useSelector(getSelectedTenant);

  const [showDeleteDialog, DeleteDialog] = useDialog();

  const [filterState, filterActions] = useFilter({
    tableName: "permissionProfiles",
    searchValue: "",
    sortByValue: TABLE_COLUMNS.dateModified.id,
    sortOrderValue: 0,
    tpPage: 0,
  });

  const [
    isLoading,
    isError,
    permissionProfilesData,
    ,
    reloadPermissionProfiles,
  ] = useApi(getPermissionProfiles, {
    filter: TABLE_COLUMNS.name.id,
    filterCriteria: filterState.searchValue,
    page: filterState.tpPage + 1,
    pageSize: filterState.tpRowsPerPage,
    sort: filterState.sortByValue,
    sortOrder: sortOrder[filterState.sortOrderValue].param,
    tenantId,
  });

  const {
    permissionProfiles = [],
    totalCount: permissionProfilesTotalCount = 0,
  } = permissionProfilesData || {};

  const deleteProfileClickHandler = useCallback(
    (profile) => {
      setSelectedProfile(profile);
      showDeleteDialog();
    },
    [showDeleteDialog],
  );

  const deleteProfile = useCallback(async () => {
    const [error] = await deletePermissionProfileById({
      profileId: selectedProfile.id,
      token,
    });

    let message = t("x has been deleted", { x: t("Permission Profile") });
    let variant = "success";

    if (error) {
      message = error.getMessage(
        t("Unable to delete x", { x: t("Permission Profile") }),
      );
      variant = "error";
    }

    reloadPermissionProfiles();
    setSelectedProfile(null);
    enqueueSnackbar(message, { variant });
  }, [enqueueSnackbar, reloadPermissionProfiles, selectedProfile, t, token]);

  return (
    <div>
      <DeleteDialog
        title={t("Delete x", { x: selectedProfile?.name })}
        content={t("Are you sure you want to delete x?", {
          x: selectedProfile?.name,
        })}
        onExec={deleteProfile}
      />
      <AdminTabs
        currentCount={permissionProfilesTotalCount}
        endElements={
          <Button
            id="PERMISSION_PROFILE_BTN_CREATE_LINK"
            variant="contained"
            color="primary"
            aria-label={`${t("Create Permission Profile")}`}
            component={Link}
            to={{ pathname: `${url}/create` }}
          >
            <IconPlus className={commonClasses.commonBtnIcon} />
            {`${t("Create Permission Profile")}`}
          </Button>
        }
      />
      <Paper
        className={classNames([
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        ])}
      >
        <OverviewTable
          t={t}
          columns={TABLE_COLUMNS_LIST}
          isError={Boolean(isError)}
          isLoading={isLoading}
          filterState={filterState}
          filterActions={filterActions}
          permissionProfiles={permissionProfiles}
          permissionProfilesTotalCount={permissionProfilesTotalCount}
          onDeleteProfile={deleteProfileClickHandler}
          tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
        />
      </Paper>
      <Route path={buildRoute(path, "create")}>
        <Create reloadPermissionProfiles={reloadPermissionProfiles} />
      </Route>
      <Route path={buildRoute(path, ":id/edit")}>
        <Edit reloadPermissionProfiles={reloadPermissionProfiles} />
      </Route>
    </div>
  );
};

PermissionProfiles.propTypes = {
  adminTabs: PropTypes.func.isRequired,
};

export default PermissionProfiles;
