import React, { Component } from "react";
import PropTypes from "prop-types";

import { format } from "date-fns";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

import AutomationProcessExecutionDialog from "./AutomationProcessExecutionDialog";
import AlertBar from "msa2-ui/src/components/AlertBar";
import RunTimeVariables from "msa2-ui/src/components/variables/RunTimeVariableFields";
import { withSnackbar } from "notistack";

import get from "lodash/get";
import set from "lodash/set";
import flow from "lodash/flow";
import isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";
import {
  getWorkflowInstanceVariableValues,
  getProcessVariableTypesByTask,
  launchProcessInstance,
  executeService,
} from "msa2-ui/src/api/workflow";
import { scheduleProcess } from "msa2-ui/src/api/workflow";
import Process from "msa2-ui/src/services/Process";
import Variable from "msa2-ui/src/services/Variable";
import Repository from "msa2-ui/src/services/Repository";
import {
  getSelectedSubtenant,
  getAvailableSubtenants,
} from "msa2-ui/src/store/designations";

import commonStyles from "msa2-ui/src/styles/commonStyles";

import { Button, CircularProgress, Grid } from "@material-ui/core";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Tooltip from "msa2-ui/src/components/TooltipWithLineBreaks";

import AutomationDetailsScheduleDialog from "msa2-ui/src/components/schedule/ScheduleDialog";

const localStyles = (theme) => ({
  loaderWrapper: {
    padding: 10,
    textAlign: "center",
  },
  submitButton: {
    margin: 6,
    minWidth: 120,
  },
});
const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

let isMounted = false;

class AutomationDetailsTabDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertBar: false,
      isLoaded: 0,
      countApi: 0,
      dialog: false,
      showSchedule: false,
      devices: [],
      data: {},
      errors: [],
    };
  }

  // life cycle methods
  componentDidMount() {
    isMounted = true;
    const { execProcess } = this.props;
    // after clicking information button or update/delete process button, call API to get current value for variables
    (!execProcess || !Process.isCreate(execProcess.type)) &&
      this.getValFromApi();
    // after clicking process button, call other API to get which variables are the parameter for the process
    execProcess && this.getVariables();
    this.props.setTotal("");
  }

  componentWillUnmount() {
    isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // if tab is opened, get the latest data from API
    !prevProps.open && this.props.open && this.getValFromApi();
    // if instance id is changed, get the latest data from API (mainly after executing CREATE process)
    prevProps.execProcess &&
      !this.props.execProcess &&
      this.props.instanceId &&
      this.getValFromApi();
    // when process execution dialog is closed, reload the information.
    if (prevState.dialog && !this.state.dialog) {
      const { execProcess, onClose } = this.props;
      // after executing delete process, close Drawer
      if (Process.isDelete(execProcess.type)) {
        onClose();
      } else {
        this.props.reloadDrawer(this.state.retPost.serviceId.id);
      }
    }
  }

  // returns an init table type variable with appending it to the input array. (intermidiate function for getValFromApi)
  appendInitTableData = (responseData, param, inputArray) => {
    const {
      tableArray,
      tableDivPoint,
      tableSection,
      tableKey,
    } = Variable.getTableValData(param);
    const tableSize = Object.keys(responseData).reduce((acc, cur, i) => {
      const curArr = cur.split(".");
      return curArr.length === tableArray.length &&
        curArr.slice(0, tableDivPoint).every((v, i) => v === tableArray[i])
        ? // if the array variable exists in the response, put it in the temporary array
          Object.assign({ [curArr[tableDivPoint]]: true }, acc)
        : acc;
    }, []);
    // loop with temporary array and create a new array data which will be a payload for API POST (process execution)
    return Object.keys(tableSize).reduce(
      (tmpAcc, val) =>
        set(
          tmpAcc,
          [tableSection, val, tableKey],
          get(responseData, [tableSection, val, tableKey].join(".")),
        ),
      inputArray,
    );
  };

  // functions for API
  getValFromApi = async () => {
    const { token, instanceId, displayField } = this.props;
    this.countApi();
    const [apiError, response] = await getWorkflowInstanceVariableValues({
      definedVarFlag: true,
      token,
      instanceId,
    });
    if (!isMounted) return false;
    if (apiError) {
      this.isLoaded();
      return this.setState({ apiError, alertBar: true });
    }

    // set instance name on top of the drawer
    this.props.handleSetInstanceName(response[displayField]);
    this.setState({ apiResponse: response, data: response });
    this.isLoaded();
  };

  reload = (addState) => {
    this.handleCloseAlertBar();
    this.setState(
      // merge objects
      Object.assign(
        {
          apiError: undefined,
          isLoaded: 0,
          apiResponse: undefined,
        },
        addState,
      ),
      () => {
        this.getValFromApi();
      },
    );
  };

  isLoaded = () => {
    this.setState((prev) => ({
      isLoaded: prev.isLoaded + 1,
    }));
  };

  countApi = () => {
    this.setState((prev) => ({
      countApi: prev.countApi + 1,
    }));
  };

  handleCloseAlertBar = () => {
    this.setState({ alertBar: false });
  };

  getVariables = async () => {
    const { token, execProcess, workflowPath, variables } = this.props;

    this.countApi();
    const [apiError, response] = await getProcessVariableTypesByTask({
      token,
      workflowPath,
      processName: execProcess.name,
      subtenantId: this.props?.subtenantId,
    });
    if (!isMounted) return false;

    if (apiError) {
      return this.setState({ apiError, alertBar: true });
    }
    const res = response;
    const setData = { processVar: res };
    if (Process.isCreate(execProcess.type)) {
      const data = Variable.createDefaultValue(variables);
      Object.assign(setData, { data });
    }

    this.setState(setData);
    this.isLoaded();
  };

  postDataToApi = async (data) => {
    const {
      token,
      instanceId,
      execProcess,
      workflowPath,
      ubiqubeId,
    } = this.props;
    const [apiError, apiResponse] = Process.isCreate(execProcess.type)
      ? await executeService({
          token,
          ubiqubeId,
          serviceName: Repository.stripFileExtensionFromString(workflowPath),
          processName: execProcess.name,
          body: data,
        })
      : await launchProcessInstance({
          token,
          ubiqubeId,
          serviceId: instanceId,
          processName: execProcess.name,
          body: data,
        });
    if (apiError) {
      return this.setState({ apiError });
    }
    this.setState({ dialog: true, retPost: apiResponse });
  };

  // functions for dialogs
  handleDialogClose = () => {
    this.setState({ dialog: false });
  };

  // functions for forms
  // change React state "data" corresponding input field. (* "data" is for API POST when execute process)
  onChange = (value, key) => {
    const { execProcess, handleSetInstanceName, displayField } = this.props;
    const { data } = this.state;
    this.setState({
      data: key ? set(data, key, value) : value,
    });
    // if the field input is display field, change instance name
    execProcess && key === displayField && handleSetInstanceName(value);
  };

  handleScheduleOpen = () => {
    this.setState({ showSchedule: true });
  };

  handleScheduleClose = () => {
    this.setState({ showSchedule: false });
  };

  handleSchedule = async (params, processType, shownVariables) => {
    const { t, enqueueSnackbar, closeSnackbar, onClose } = this.props;
    const { data } = this.state;
    const dataToPost = Variable.getDataToPost(
      data,
      processType,
      shownVariables,
    );
    const {
      token,
      ubiqubeId,
      instanceId: serviceId,
      execProcess,
      workflowPath,
    } = this.props;

    const convertParams = (params) => {
      const {
        startDate: beginDate,
        endDate,
        weekDay,
        month,
        periodicity,
        periodicityValue,
      } = params;

      const getDateValue = (date) => {
        return date ? format(new Date(date), "yyyy-MM-dd HH:mm:ss") : "";
      };

      const getDayValue = (day) => {
        const {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        } = day;
        return [
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        ].join("");
      };

      const getMonthValue = (month) => {
        const {
          january,
          february,
          march,
          april,
          may,
          june,
          july,
          august,
          september,
          october,
          november,
          december,
        } = month;
        return [
          january,
          february,
          march,
          april,
          may,
          june,
          july,
          august,
          september,
          october,
          november,
          december,
        ].join("");
      };

      return {
        beginDate: getDateValue(
          beginDate.getTime() + beginDate.getTimezoneOffset() * 60000,
        ),
        endDate: getDateValue(
          endDate.getTime() + endDate.getTimezoneOffset() * 60000,
        ),
        weekDay: getDayValue(weekDay),
        month: getMonthValue(month),
        period: periodicityValue,
        periodUnit: periodicity,
      };
    };

    const [apiError] = Process.isCreate(execProcess.type)
      ? await scheduleProcess({
          token,
          ubiqubeId,
          serviceName: Repository.stripFileExtensionFromString(workflowPath),
          processName: execProcess.name,
          processType: execProcess.type,
          query: convertParams(params),
          data: dataToPost,
        })
      : await scheduleProcess({
          token,
          ubiqubeId,
          serviceId,
          serviceName: Repository.stripFileExtensionFromString(workflowPath),
          processName: execProcess.name,
          processType: execProcess.type,
          query: convertParams(params),
          data: dataToPost,
        });

    if (apiError) {
      enqueueSnackbar(apiError.getMessage(t("Unable to schedule workflow")), {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });

      this.setState({ apiError });
    } else {
      enqueueSnackbar(t("Workflow successfully scheduled"), {
        variant: "success",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });

      this.setState({ showSchedule: false });
      onClose();
    }
  };

  // Run a process
  handleSubmit = (data, processType, shownVariables) => {
    // Filter data to post only the parameter for triggering Process

    const dataToPost = Variable.getDataToPost(
      data,
      processType,
      shownVariables,
    );
    this.postDataToApi(dataToPost);
  };

  // control select component to make "open" state false
  handleOnClose = (tmpStateId) => {
    this.setState((prev) => ({
      [tmpStateId]: { ...prev[tmpStateId], open: false },
    }));
  };

  render() {
    const { classes, t, open, onClose, variables, execProcess } = this.props;
    const allowSchedule = execProcess ? execProcess["allowSchedule"] : false;
    const { processVar, data, showSchedule, errors } = this.state;
    const shownVariables =
      processVar && Variable.getShownVariables(variables, processVar, data);

    if (!open) {
      return null;
    }

    if (!this.state.isLoaded || this.state.isLoaded < this.state.countApi) {
      return (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.alertBar) {
      return (
        // after loading - error case
        <AlertBar
          message={t("Unable to load x", { x: t("Variables") })}
          refreshFnc={this.reload}
          closeFnc={this.handleCloseAlertBar}
        />
      );
    }

    return (
      <>
        {this.state.dialog && (
          <AutomationProcessExecutionDialog
            open
            onClose={this.handleDialogClose}
            serviceId={this.state.retPost.serviceId.id}
            processId={this.state.retPost.processId.id}
            processName={execProcess.displayName}
            processIcon={Process.getIcon(execProcess.type)}
            apiResponse={this.state.retPost}
          />
        )}
        {showSchedule && (
          <AutomationDetailsScheduleDialog
            onClose={this.handleScheduleClose}
            onSchedule={(params) =>
              this.handleSchedule(params, execProcess.type, shownVariables)
            }
            // Create Process cannot take repeat schedule.
            // But do not use Process.isCreate here because "SET" can repeat
            onlyOnce={execProcess.type === "CREATE"}
          />
        )}
        <Grid container justifyContent="flex-start" alignContent="center">
          <Grid item xs={12}>
            <RunTimeVariables
              data={data}
              variables={variables}
              processVariables={processVar}
              editMode={execProcess?.type}
              onChange={this.onChange}
              predefinedValuesEnabled
              getError={(_errors) => {
                const errors = Variable.convertErrorsToDisplayName(
                  _errors,
                  variables,
                );
                if (!isEqual(errors, this.state.errors)) {
                  this.setState({ errors });
                }
              }}
            />
          </Grid>
          {execProcess && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignContent="center"
            >
              <Button
                id="AUTOMATION_DETAILS_PROCESS_CANCEL"
                variant="text"
                size="small"
                color="default"
                className={classes.submitButton}
                onClick={onClose}
              >
                {t("Cancel")}
              </Button>
              <Tooltip title={errors.length > 0 ? errors : ""}>
                <span>
                  <Button
                    id="AUTOMATION_DETAILS_PROCESS_RUN"
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.submitButton}
                    onClick={() =>
                      this.handleSubmit(data, execProcess.type, shownVariables)
                    }
                    disabled={errors.length > 0}
                  >
                    {t("Run")}
                  </Button>
                  {allowSchedule && (
                    <Button
                      id="AUTOMATION_DETAILS_PROCESS_SCHEDULE"
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.submitButton}
                      onClick={this.handleScheduleOpen}
                      disabled={errors.length > 0}
                    >
                      {t("Schedule")}
                    </Button>
                  )}
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}
AutomationDetailsTabDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  execProcess: PropTypes.object,
  token: PropTypes.string.isRequired,
  managerId: PropTypes.number.isRequired,
  subtenantId: PropTypes.number,
  ubiqubeId: PropTypes.string,
  subtenants: PropTypes.array.isRequired,
  setTotal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reloadDrawer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  variables: PropTypes.array.isRequired,
  displayField: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => {
  const { id: subtenantId, ubiqubeId } = getSelectedSubtenant(state);
  return {
    token: state.auth.token,
    managerId: state.auth.userDetails.id,
    subtenantId,
    ubiqubeId,
    subtenants: getAvailableSubtenants(state),
  };
};

export default flow(
  connect(mapStateToProps),
  withStyles(styles),
  withSnackbar,
  withTranslation(),
)(AutomationDetailsTabDetails);
