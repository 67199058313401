import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { returnToPreviousPage } from "msa2-ui/src/utils/urls";
import { getToken } from "msa2-ui/src/store/auth";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { addBpmDiagram } from "msa2-ui/src/api/bpm";
import Repository from "msa2-ui/src/services/Repository";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import {
  Typography,
  TextField,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import AdornedTextField from "msa2-ui/src/components/AdornedTextField";
import Modal from "msa2-ui/src/components/modal/Modal";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import BpmModeler from "msa2-ui/src/components/bpm/BpmModeler";
import ModalTitleBar from "./ModalTitleBar";

const useStyles = makeStyles((theme) => ({
  createDialogContent: {
    padding: "0 20px",
  },
  formField: {
    marginBottom: 20,
    width: "100%",
  },
  subtenantSelect: {
    textAlign: "left",
  },
  errorMessage: {
    color: theme.colors.red,
  },
  emptySubtenant: {
    color: theme.colors.greyDark1,
    fontWeight: theme.typography.fontWeightLight,
  },
}));

const Create = ({ initWorkflows, onClose, aiParameters }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const token = useSelector(getToken);
  const { id: selectedSubtenantId } = useSelector(getSelectedSubtenant);

  const history = useHistory();
  const { url } = useRouteMatch();

  const [isBpmChanged, setIsBpmChanged] = useState(false);

  const returnToBpmHome = () => history.push("/automation/bpm");

  const handleBmpChange = ({ touched }) => setIsBpmChanged(touched);

  const handleCloseWithCheck = () => {
    if (isBpmChanged) {
      return showDiscardDialog();
    }
    handleClose();
  };

  const handleClose = () => {
    if (onClose) {
      return onClose();
    }
    returnToPreviousPage(history, url);
  };

  const initInfo =
    aiParameters &&
    Object.entries(aiParameters).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Array.isArray(value)
          ? value
              .map((value) =>
                value.operator ? Object.values(value).join(" ") : value.label,
              )
              .join(", ")
          : value.label ?? value.value ?? value,
      }),
      {},
    );

  const [showCreateDialog, CreateDialog] = useDialog();
  const [showDiscardDialog, DiscardDialog] = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const [bpmName, setBpmName] = useState("");
  const [bpmFilename, setBpmFilename] = useState(".bpmn");
  const [shouldTriggerCreateBpm, setShouldTriggerCreateBpm] = useState(false);
  const [createBpmIsLoading, setCreateBpmIsLoading] = useState(false);
  const [createBpmErrorMessage, setCreateBpmErrorMessage] = useState("");
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );

  const createBpm = async ({ xml: xmlContent, error: xmlError }) => {
    setShouldTriggerCreateBpm(false);

    if (xmlError) {
      return setCreateBpmErrorMessage(
        t("XML error. Please update diagram and try again."),
      );
    }

    if (!bpmName) {
      return setCreateBpmErrorMessage(t("Please enter a name"));
    }

    if (bpmFilename === ".bpmn") {
      return setCreateBpmErrorMessage(t("Please enter a file name"));
    }

    setCreateBpmErrorMessage("");
    setCreateBpmIsLoading(true);

    const [upsertError] = await addBpmDiagram({
      bpmFilename,
      displayName: bpmName,
      customerId: selectedSubtenantId,
      xmlContent,
      saveOwner: isWorkflowsOwnerDetailsEnabled,
      token,
    });
    setCreateBpmIsLoading(false);

    if (upsertError) {
      const message = upsertError.getMessage();
      const errorMessage = `${t("Error creating x", {
        x: t("BPM diagram"),
      })}. ${message || t("Please try again")}`;
      setCreateBpmIsLoading(false);
      enqueueSnackbar(errorMessage, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return setCreateBpmErrorMessage(errorMessage);
    } else {
      enqueueSnackbar(t("x created successfully", { x: t("Bpm diagram") }), {
        variant: "success",
        autoHideDuration: 3000,
      });
      return returnToBpmHome();
    }
  };

  return (
    <ErrorBoundary>
      <CreateDialog
        title={t("Choose a Name for Your BPM Diagram")}
        execLabel={t("Create BPM")}
        onExec={() => setShouldTriggerCreateBpm(true)}
        shouldHideOnExec={false}
        disabled={createBpmIsLoading}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.createDialogContent}
        >
          {createBpmIsLoading ? (
            <CircularProgress />
          ) : (
            <>
              <TextField
                size="medium"
                label={t("Name")}
                id="bpm-name-field"
                className={classes.formField}
                variant="outlined"
                value={bpmName}
                onChange={({ target: { value } }) => {
                  setBpmName(value);
                  setBpmFilename(
                    Repository.convertStringToFilename(value, "bpmn"),
                  );
                }}
              />
              <AdornedTextField
                size="medium"
                label={t("File Name")}
                id="bpm-name-field"
                className={classes.formField}
                variant="outlined"
                value={bpmFilename}
                suffix={".bpmn"}
                onChange={({ target: { value } }) => {
                  setBpmFilename(
                    Repository.convertStringToFilename(value, "bpmn"),
                  );
                }}
              />
              {createBpmErrorMessage && (
                <Typography variant="body1" className={classes.errorMessage}>
                  {createBpmErrorMessage}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </CreateDialog>

      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard the BPM diagram?")}
        onExec={handleClose}
      />

      <Modal onClose={handleCloseWithCheck}>
        <ModalTitleBar
          title={t("Create BPM")}
          closeButtonLabel={t("Close")}
          saveButtonLabel={aiParameters ? t("Accept") : t("Create")}
          discardButtonLabel={aiParameters ? t("Reject") : t("Discard Changes")}
          disabled={false}
          onSave={showCreateDialog}
          onDiscard={showDiscardDialog}
          onClose={handleCloseWithCheck}
        />
        <ModalContent>
          <BpmModeler
            shouldTriggerCallback={shouldTriggerCreateBpm}
            callback={createBpm}
            initWorkflows={initWorkflows}
            initInfo={initInfo}
            onChange={handleBmpChange}
          />
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

Create.propTypes = {
  onClose: PropTypes.func,
};

export default Create;
