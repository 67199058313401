import { useSelector } from "react-redux";

import {
  getWorkflow,
  getWorkflowDetails,
  getWorkflowInstanceVariableValues,
  getProcessVariableTypesByTask,
} from "msa2-ui/src/api/workflow";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import Variable from "msa2-ui/src/services/Variable";
import useApi from "./useApi";

const useWorkflow = ({ workflowPath, processName, instanceId, wait = {} }) => {
  const { ubiqubeId } = useSelector(getSelectedSubtenant);

  const [workflowIsLoading, , workflow] = useApi(
    getWorkflow,
    {
      pathToWorkflowDefinitionFile: workflowPath,
    },
    !workflowPath || wait.workflow,
  );

  const [workflowDetailsAreLoading, , workflowDetails] = useApi(
    getWorkflowDetails,
    {
      ubiqubeId,
      workflowPath,
    },
    !workflowPath || !ubiqubeId || wait.workflowDetails,
  );

  const [
    processVariableTypesByTaskAreLoading,
    processVariableTypesByTaskError,
    processVariableTypesByTask,
  ] = useApi(
    getProcessVariableTypesByTask,
    {
      workflowPath,
      processName,
    },
    !processName || wait.processVariableTypesByTask,
  );

  const processVariableTypes =
    workflow?.variables && processVariableTypesByTask
      ? Variable.filterVariablesBasedOnProcess(
          workflow.variables.variable,
          processVariableTypesByTask,
        )
      : [];

  const [
    workflowInstanceVariableValuesAreLoading,
    ,
    workflowInstanceVariableValues,
  ] = useApi(
    getWorkflowInstanceVariableValues,
    {
      instanceId,
    },
    !instanceId || wait.workflowInstanceVariableValues,
  );

  const isLoading =
    workflowIsLoading ||
    workflowDetailsAreLoading ||
    processVariableTypesByTaskAreLoading ||
    workflowInstanceVariableValuesAreLoading;

  return {
    workflow,
    workflowDetails,
    processVariableTypesByTask:
      !processVariableTypesByTaskError && processVariableTypesByTask,
    processVariableTypes,
    workflowInstanceVariableValues,
    isLoading,
    loading: {
      workflowIsLoading,
      workflowDetailsAreLoading,
      processVariableTypesByTaskAreLoading,
      workflowInstanceVariableValuesAreLoading,
    },
  };
};

export default useWorkflow;
