import React from "react";
import PropTypes from "prop-types";
import Option from "./Option";
import { useSelectStyles } from "./useSelectStyles";
import FormField from "./FormField";

const MsaSelectBase = ({
  isClearable = true,
  isError = false,
  disabled = false,
  error,
  label,
  renderLabel,
  value = {},
  required,
  select: Select,
  inputRef,
  fullWidth = true,
  ...rest
}) => {
  const styles = useSelectStyles(isError, disabled);
  return (
    <FormField
      value={value}
      label={label}
      renderLabel={renderLabel}
      error={error}
      isError={isError}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
    >
      <Select
        ref={(ref) => {
          if (inputRef && ref) {
            inputRef.current = ref.select;
          }
        }}
        id="MSA_SELECT"
        styles={styles}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement="auto"
        menuPosition="fixed"
        menuPortalTarget={document.body}
        components={{ Option }}
        value={value}
        {...rest}
      />
    </FormField>
  );
};

MsaSelectBase.propTypes = {
  defaultValue: PropTypes.object,
  isClearable: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  renderLabel: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default MsaSelectBase;
