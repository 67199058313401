/**
 * COMPONENTS
 */
import createComponent from "./createComponent";

import { Checkbox as MUICheckbox } from "@material-ui/core";

const Checkbox = createComponent(
  MUICheckbox,
  ({
    input: { value, ...inputProps },
    meta,
    onCheck,
    defaultChecked,
    color = "primary",
    ...props
  }) => {
    const checked = Boolean(value);
    const onKeyPress = (event) => {
      if (event.key === "Enter") {
        inputProps.onChange(!checked);
      }
    };
    return {
      ...inputProps,
      ...props,
      color,
      onKeyPress,
      checked,
    };
  },
);

export default Checkbox;
