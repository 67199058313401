import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import MonitoringProfileForm from "msa2-ui/src/components/monitoring-profile-form";
import { useReturnToPreviousPage } from "msa2-ui/src/components/formSection/FormCommon";
import {
  getMonitoringProfileById,
  updateMonitoringProfileById,
} from "msa2-ui/src/api/monitoringProfiles";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";

const MonitoringProfileEdit = () => {
  const t = useBoundedTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { profileId } = useParams();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();
  const subtenantId = useSelector(getSelectedSubtenant).id;

  const [pending, setPending] = useState(true);
  const [profileData, setProfileData] = useState();

  const returnToOverviewTab = useReturnToPreviousPage(
    history,
    url,
    false,
    "/integration/monitoring-profiles",
  );

  const closeHandler = useCallback(() => {
    returnToOverviewTab();
  }, [returnToOverviewTab]);

  useEffect(() => {
    async function fetchProfileData() {
      setPending(true);

      const [error, response] = await getMonitoringProfileById({
        token,
        profileId,
      });

      if (error) {
        enqueueSnackbar(
          error.getMessage(
            t("Unable to load x", { x: t("Monitoring Profile") }),
          ),
          {
            variant: "error",
          },
        );
        returnToOverviewTab();
        return;
      }

      setProfileData(response);
      setPending(false);
    }

    fetchProfileData();
  }, [enqueueSnackbar, profileId, returnToOverviewTab, t, token]);

  const submitHandler = useCallback(
    async (formData) => {
      setPending(true);

      const [error] = await updateMonitoringProfileById({
        token,
        profileId,
        subtenantId,
        data: formData,
      });

      setPending(false);

      const variant = error ? "error" : "success";
      const message = error
        ? error.message || t("Unable to save x", { x: t("Monitoring Profile") })
        : t("x has been created", { x: t("Monitoring Profile") });

      enqueueSnackbar(message, {
        variant,
      });

      !error && returnToOverviewTab();
    },
    [profileId, returnToOverviewTab, subtenantId, enqueueSnackbar, t, token],
  );

  return (
    <MonitoringProfileForm
      title={t("Edit x", { x: t("Monitoring Profile") })}
      submitButtonTitle={t("Save x", { x: t("Monitoring Profile") })}
      initialValues={profileData}
      pending={pending}
      onClose={closeHandler}
      onSubmit={submitHandler}
    />
  );
};

export default MonitoringProfileEdit;
