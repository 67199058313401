import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "msa2-ui/src/components/menu";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { useHistory, useRouteMatch } from "react-router-dom";
import { buildRoute } from "msa2-ui/src/utils/urls";

const DetailsAction = ({ node }) => {
  const t = useBoundedTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const clickHandler = () =>
    history.push(buildRoute(path, `${node.__metadata__.objectId}/overview`));

  return (
    <MenuItem onClick={clickHandler} id="DETAILS_ACTION">
      {t("Details")}
    </MenuItem>
  );
};

DetailsAction.propTypes = {
  node: PropTypes.object.isRequired,
};

export default DetailsAction;
