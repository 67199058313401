import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getFormValues } from "msa2-ui/src/store/form";
import {
  getAdvancedVariableParametersByName,
  getAdvancedVariableParametersByType,
  getVariableTypes,
} from "msa2-ui/src/store/designations";
import { useTranslation } from "react-i18next";
import AdvancedParameters from "msa2-ui/src/services/AdvancedParameters";
import Variable from "msa2-ui/src/services/Variable";

import { makeStyles } from "@material-ui/core";
import { FormControl } from "@material-ui/core";

import Field from "msa2-ui/src/components/connectedFormComponents/Field";
import NestedAdvancedParameters from "./NestedAdvancedParameters";

const useStyles = makeStyles((theme) => ({
  fieldPadding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const getIdFromName = (name) => name.toUpperCase().replace(/\./g, "_");
const convertLegacyKeys = (type, variableTypes) => {
  if (variableTypes.includes(type)) return type;
  const newKey = Variable.MAP_LEGACY_KEY_TO_NEW[type];
  if (newKey) return newKey;
  const oldKey = Object.entries(Variable.MAP_LEGACY_KEY_TO_NEW).find(
    ([_, newKey]) => newKey === type,
  )?.[0];
  if (oldKey) return oldKey;
  return variableTypes[0];
};
const AdvancedParameterField = ({
  parameterName,
  form,
  path,
  label,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formVariableValues = useSelector(getFormValues(form, path));
  const variableTypes = useSelector(getVariableTypes(form));
  const advancedVariableParametersByName = useSelector(
    getAdvancedVariableParametersByName(form),
  );

  const parameter = advancedVariableParametersByName[parameterName];

  const variableType = convertLegacyKeys(
    formVariableValues?.type,
    variableTypes,
  );

  const advancedVariableParameters = useSelector(
    getAdvancedVariableParametersByType(form, variableType),
  );

  const showFieldBasedOnDependencyValue =
    parameter &&
    AdvancedParameters.showFieldBasedOnDependencyValue(
      parameter,
      formVariableValues,
      advancedVariableParametersByName[parameter.depends],
    );
  const showFieldBasedOnVariableType = advancedVariableParameters.includes(
    parameterName,
  );
  const name = `${path}.${parameterName}`;

  return (
    showFieldBasedOnVariableType &&
    showFieldBasedOnDependencyValue && (
      <FormControl
        key={parameterName}
        className={classes.fieldPadding}
        fullWidth
      >
        {parameter.parameters ? (
          <NestedAdvancedParameters
            name={name}
            id={getIdFromName(name)}
            form={form}
            parameter={parameter}
            sectionHeader={`${label || t(parameterName)}:`}
            {...rest}
          />
        ) : (
          <Field
            id={getIdFromName(name)}
            name={name}
            type={parameter.type}
            label={label}
            {...rest}
          />
        )}
      </FormControl>
    )
  );
};

AdvancedParameterField.propTypes = {
  parameterName: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  variablePath: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.array,
  listData: PropTypes.array,
};

export default AdvancedParameterField;
