import React from "react";
import PropTypes from "prop-types";
import ParserField from "./ParserField";

const replaceElementAtIndex = (coll, newElement, targetIndex) =>
  coll.map((element, i) => {
    if (i === targetIndex) {
      return newElement;
    }
    return element;
  });

const ParserArrayExtractor = ({ onChange, value, objectType }) =>
  value.map((extractor, i) => {
    const parser = extractor[objectType.id];
    if (Array.isArray(parser)) {
      // case for Microservice Identifier Extractor ("section")
      return parser.map((parser, i, parsers) => (
        <ParserField
          key={`${parser}${i}`}
          onSave={(newRegexp) => {
            onChange([
              {
                [objectType.id]: replaceElementAtIndex(parsers, newRegexp, i),
              },
            ]);
          }}
          onDelete={() => {
            onChange([
              {
                [objectType.id]: parsers.filter(
                  (_, identifierExtractorIndex) => {
                    return i !== identifierExtractorIndex;
                  },
                ),
              },
            ]);
          }}
          hasAction
          icon={objectType.icon}
          value={parser}
        />
      ));
    }
    return (
      <ParserField
        key={`${parser}${i}`}
        onSave={(newRegexp) => {
          onChange(
            replaceElementAtIndex(value, { [objectType.id]: newRegexp }, i),
          );
        }}
        onDelete={() => {
          onChange(
            value.filter((_, ignoreLineIndex) => {
              return i !== ignoreLineIndex;
            }),
          );
        }}
        hasAction
        icon={objectType.icon}
        value={parser}
      />
    );
  });

ParserArrayExtractor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  objectType: PropTypes.object.isRequired,
};

export default ParserArrayExtractor;
