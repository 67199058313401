import format from "date-fns/format";
import { destroy, download, get, post, put } from "./request";
import { contentTypes } from "msa2-ui/src/api/constants";

const API = process.env.REACT_APP_API_PATH;

export const getMonitoringProfiles = ({
  token,
  subtenantId,
  page,
  pageSize,
  filter,
  filterCriteria,
  sort,
  sortOrder,
}) => {
  return get({
    url: `${API}/profile/monitoring-profile/customer/${subtenantId}`,
    token,
    queryParams: {
      filter,
      filter_criteria: filterCriteria,
      page,
      page_size: pageSize,
      sort,
      sort_order: sortOrder,
    },
  });
};

export const createMonitoringProfile = ({ token, data, subtenantId }) => {
  return post({
    url: `${API}/profile/monitoring-profile/${subtenantId}`,
    token,
    body: {
      ...data,
    },
  });
};

export const getMonitoringProfileById = ({
  token,
  transforms = [],
  profileId,
}) => {
  return get({
    url: `${API}/profile/monitoring-profile/${profileId}`,
    token,
    transforms,
  });
};

const normalizeProfileGraphData = (profileData) =>
  profileData?.graphRendererList?.map((graph) => {
    return {
      id: graph.id,
      name: graph.name,
      verticalLabel: graph.verticalLabel,
      kpis:
        graph.dataList?.reduce((acc, axisData, i) => {
          const id = axisData.id || i;

          acc[id] = {
            id,
            label: axisData.horizontalLabel,
            color: axisData.color,
            snmpPollingName:
              profileData?.snmpPollingList[axisData.snmpPollingId]?.name,
          };

          return acc;
        }, {}) || {},
    };
  }) || [];

export const getMonitoringProfileGraphs = ({
  profileId,
  token,
  transforms = [],
}) => {
  return getMonitoringProfileById({
    profileId,
    token,
    transforms: [normalizeProfileGraphData, ...transforms],
  });
};

export const updateMonitoringProfileById = ({
  token,
  profileId,
  subtenantId,
  data,
}) => {
  return put({
    url: `${API}/profile/monitoring-profile/${subtenantId}`,
    token,
    body: {
      ...data,
      id: profileId,
    },
  });
};

export const deleteMonitoringProfileById = ({ token, profileId }) => {
  return destroy({
    url: `${API}/profile/monitoring-profile/${profileId}`,
    token,
  });
};

export const getAttachedMonitoringProfiles = ({ deviceId, token }) => {
  return get({
    url: `${API}/profile/monitoring-profile/device/${deviceId}`,
    token,
  });
};

const normalizeRrdData = (rrdData) => {
  if (rrdData.errorCode) {
    return rrdData;
  } else {
    return Object.entries(rrdData || {}).reduce((acc, [key, value]) => {
      acc[key] = value?.xport?.data?.row ?? [];
      return acc;
    }, {});
  }
};

export const getMonitoringDataForDevice = ({
  deviceUbiId,
  monitoringPeriod = "day",
  startDate,
  endDate,
  profileId,
  token,
  transforms = [],
}) => {
  return get({
    url: `${API}/profile/monitoring/rrd-data/${deviceUbiId}`,
    queryParams: {
      profileId,
      period: startDate && endDate ? undefined : monitoringPeriod,
      startDate: startDate && format(startDate, "t"),
      endDate: endDate && format(endDate, "t"),
    },
    token,
    transforms: [normalizeRrdData, ...transforms],
  });
};

/*
 * Spec: https://10.31.1.5/swagger/#/Profile/downloadMonitoringProfile
 */
export const downloadMonitoringProfile = ({
  name,
  profileId,
  token,
  onError,
  type = "json",
}) => {
  return download({
    url: `${API}/profile/monitoring/download/${profileId}`,
    fileName: `${name}_${profileId}.${type}`,
    token,
    onError,
  });
};

/*
 * Spec: https://10.31.1.5/swagger/#/Profile/uploadMonitoringProfile
 */
export const uploadMonitoringProfile = ({ token, file, customerId }) => {
  return post({
    url: `${API}/profile/monitoring/upload`,
    body: file,
    queryParams: { customerId },
    contentType: contentTypes.FORM_DATA,
    token,
  });
};

export const getDefaultMonitoringDataForDevice = ({
  token,
  deviceUbiId,
  monitoringPeriod = "day",
  startDate,
  endDate,
  transforms = [],
}) => {
  return get({
    url: `${API}/device/id/${deviceUbiId}/monitor`,
    token,
    queryParams: {
      period: startDate && endDate ? undefined : monitoringPeriod,
      startDate: startDate && format(startDate, "t"),
      endDate: endDate && format(endDate, "t"),
    },
    transforms: [normalizeRrdData, ...transforms],
  });
};
