import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core";
import { Grid, CircularProgress } from "@material-ui/core";

import { getDesignationsReady } from "msa2-ui/src/store/designations";

import useScrollOnPageChange from "msa2-ui/src/hooks/useScrollOnPageChange";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
}));

const AppContents = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useScrollOnPageChange();

  const designationsReady = useSelector(getDesignationsReady);

  if (!designationsReady) {
    return (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        className={classes.container}
      >
        <CircularProgress aria-label={t("Loading MSA")} />
      </Grid>
    );
  }
  return children;
};

AppContents.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppContents;
