import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Paper,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import IncidentTicketList from "./IncidentTicketList";
import { useSelector } from "react-redux";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import useApi from "msa2-ui/src/hooks/useApi";
import { getIncidentTicketList } from "msa2-ui/src/api/incidentTicket";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import classNames from "classnames";
import { incidentTicketStatus } from "../../../Constants";
import useFilter from "../../../hooks/useFilter";
import { Refresh } from "@material-ui/icons";

const columns = [
  {
    id: "check",
    name: "",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "subject",
    name: "Subject",
  },
  {
    id: "id",
    name: "Id",
  },
  {
    id: "creationDate",
    name: "CreationDate",
  },
  {
    id: "details",
    name: "",
  },
];

const IncidentTicketOverview = ({ sectionTabs: SectionTabs }) => {
  const commonClasses = useCommonStyles();
  const { path } = useRouteMatch;
  const tableCellLength = columns.length;
  const {
    id: selectedSubtenantId,
    externalReference: ticketingLogin,
  } = useSelector(getSelectedSubtenant);
  const [ticketsCount, setTicketsCount] = useState(0);

  const incidentTicketStatusList = [
    {
      id: "all",
      displayName: "All",
      text: "All",
    },
    ...incidentTicketStatus.map(({ id, name }) => ({
      id,
      displayName: name,
      text: name,
    })),
  ];

  const [filterState, filterActions] = useFilter({
    tabeName: "incidentTickets",
    searchValue: "",
    filterByValue: incidentTicketStatusList[0].id,
    tpPage: 0,
  });

  const [
    isLoading,
    isError,
    incidentTicketsData = [],
    ,
    reloadIncidentTicketsData,
  ] = useApi(
    getIncidentTicketList,
    {
      ticketingLogin: ticketingLogin,
    },
    !selectedSubtenantId,
  );

  const handleRefresh = () => {
    reloadIncidentTicketsData();
  };

  return (
    <>
      <SectionTabs count={[path, ticketsCount]} />
      <Paper
        className={classNames(
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        )}
      >
        <Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={tableCellLength}
                  className={commonClasses.commonTableCell}
                >
                  <FilterMenu
                    id="INCIDEN_TICKET_FILTER_MENU"
                    {...filterState}
                    searchValue={filterState.searchValue}
                    handleSearchByChange={filterActions.handleSearchByChange}
                    filterByValue={filterState.filterByValue}
                    filterByOptions={incidentTicketStatusList}
                    handleFilterByChange={filterActions.handleFilterByChange}
                    tpTotal={0}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={commonClasses.commonTableHeadRow}>
                {columns.map((tableCellHead) =>
                  tableCellHead.id === "check" ? (
                    <TableCell
                      key={tableCellHead.id}
                      className={commonClasses.commonTableCellDefault}
                    >
                      {
                        <IconButton
                          style={{ cursor: "pointer" }}
                          onClick={handleRefresh}
                        >
                          <Refresh />
                        </IconButton>
                      }
                    </TableCell>
                  ) : (
                    <TableCell
                      key={tableCellHead.id}
                      className={commonClasses.commonTableCellDefault}
                    >
                      {tableCellHead.name}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <IncidentTicketList
              colSpan={tableCellLength}
              isLoading={isLoading}
              isError={isError}
              incidentTicketsData={incidentTicketsData}
              selectedSubtenantId={selectedSubtenantId}
              setTicketsCount={setTicketsCount}
            />
          </Table>
        </Grid>
      </Paper>
    </>
  );
};

export default IncidentTicketOverview;
