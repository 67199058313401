import { createElement } from "react";

const transferKeys = (oldObj, newObj) => {
  const keys = Object.keys(oldObj);
  keys.forEach((key) => {
    if (key === "mode" || key === "theme") {
      newObj[key] = oldObj[key];
    }
  });
};

/**
 * Creates a component that renders the given Material UI component
 */
const createComponent = (PlainComponent, mapProps) => {
  const ConnectedComponent = (props) => {
    return createElement(PlainComponent, mapProps(props));
  };
  ConnectedComponent.displayName = `ReduxForms${PlainComponent.name}`;
  transferKeys(PlainComponent, ConnectedComponent);
  return ConnectedComponent;
};

export default createComponent;
