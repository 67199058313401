import React from "react";
import PropTypes from "prop-types";

import { IconButton, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import flow from "lodash/flow";

import {
  PlayCircleOutline as PlayIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { ReactComponent as CalendarIcon } from "msa2-ui/src/assets/icons/calendar.svg";

import Toolbar from "msa2-ui/src/components/modal/Toolbar";
import { downloadArchive } from "msa2-ui/src/api/repository";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import Repository from "msa2-ui/src/services/Repository";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";

import Tooltip from "msa2-ui/src/components/TooltipWithLineBreaks";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import classNames from "classnames";

const useStyles = makeStyles(({ palette, typography, colors }) => ({
  publish: {
    backgroundColor: colors.green,
    color: colors.white,
    marginRight: 10,
  },
  title: {
    marginRight: 15,
  },
  toolbarButton: {
    marginRight: 10,
  },
  fixedToolbar: {
    backgroundColor: palette.background.paper,
    position: "fixed",
  },
  date: {
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
    marginLeft: 5,
  },
  dateLabel: {
    fontWeight: typography.fontWeightLight,
    color: palette.primary.main,
  },
  executeIcon: {
    color: palette.primary.main,
    marginBottom: 2,
    marginRight: 7,
  },
}));

const ModalTitleBar = ({
  onClose,
  onSave,
  onDiscard,
  onPublish,
  onSchedule,
  onExecute,
  disabled,
  title,
  bpmUri,
  date,
  closeButtonLabel,
  saveButtonLabel,
  discardButtonLabel,
  publishButtonLabel,
  scheduleButtonLabel,
  executeButtonLabel,
  executeButtonDisabled = disabled,
  executeButtonTooltip,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();
  const commonClasses = useCommonStyles();

  return (
    <Toolbar className={classes.fixedToolbar}>
      <Toolbar.Section>
        <Typography
          className={classNames(
            classes.title,
            commonClasses.overflowAnywhere,
            commonClasses.commonTablePrimary,
          )}
          variant="h4"
        >
          {title || ""}
        </Typography>
      </Toolbar.Section>

      <Toolbar.Section>
        {date && (
          <>
            <Typography className={classes.dateLabel}>Last saved: </Typography>
            <Typography className={classes.date}> {date}</Typography>
            <Toolbar.Divider />
          </>
        )}
        {onSchedule && (
          <>
            <Tooltip title={executeButtonTooltip || ""}>
              <span>
                <Button
                  id="MODAL_TOOLBAR_SCHEDULE_BTN"
                  variant="text"
                  size="medium"
                  color="primary"
                  className={classes.toolbarButton}
                  onClick={onSchedule}
                  disabled={Boolean(executeButtonDisabled)}
                >
                  <CalendarIcon className={classes.executeIcon} />
                  {scheduleButtonLabel}
                </Button>
              </span>
            </Tooltip>
          </>
        )}
        {onExecute && (
          <DelegationProfiles
            type={delegationProfileTypes.BPM}
            action="instance.execute"
          >
            <Tooltip title={executeButtonTooltip || ""}>
              <span>
                <Button
                  id="MODAL_TOOLBAR_EXECUTE_BTN"
                  variant="text"
                  size="medium"
                  color="primary"
                  className={classes.toolbarButton}
                  onClick={onExecute}
                  disabled={Boolean(executeButtonDisabled)}
                >
                  <PlayIcon className={classes.executeIcon} />
                  {executeButtonLabel}
                </Button>
              </span>
            </Tooltip>
            <Toolbar.Divider />
          </DelegationProfiles>
        )}
        {onDiscard && (
          <Button
            id="MODAL_TOOLBAR_DISCARD_BTN"
            variant="text"
            size="medium"
            color="primary"
            className={classes.toolbarButton}
            onClick={onDiscard}
            disabled={disabled}
          >
            {discardButtonLabel}
          </Button>
        )}
        {onSave && (
          <Button
            id="MODAL_TOOLBAR_SAVE_BTN"
            variant="contained"
            size="medium"
            color="primary"
            className={classes.toolbarButton}
            onClick={onSave}
            disabled={disabled}
          >
            {saveButtonLabel}
          </Button>
        )}
        {onPublish && (
          <Button
            id="MODAL_TOOLBAR_PUBLISH_BTN"
            variant="contained"
            size="medium"
            className={classes.publish}
            onClick={onPublish}
            disabled={disabled}
          >
            {publishButtonLabel}
          </Button>
        )}

        {bpmUri && (
          <Tooltip title={t("Download BPM")}>
            <IconButton
              id="MODAL_TOOLBAR_DOWNLOAD_BTN"
              aria-label={t("Download")}
              onClick={() => {
                downloadArchive({
                  path: bpmUri,
                  token,
                  fileName: flow(
                    decodeURIComponent,
                    Repository.getFilenameFromUri,
                    (bpmDetailsUri) =>
                      Repository.addExtension(bpmDetailsUri, "zip"),
                  )(bpmUri),
                  onError: (e) => {
                    enqueueSnackbar(t("Unable to download contents"), {
                      variant: "error",
                    });
                  },
                });
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
        {(onDiscard || onSave || onPublish) && <Toolbar.Divider />}
        <IconButton
          id="MODAL_TOOLBAR_CLOSE_BTN"
          aria-label={closeButtonLabel}
          variant="contained"
          size="medium"
          color="default"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar.Section>
    </Toolbar>
  );
};

ModalTitleBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  saveButtonLabel: PropTypes.string,
  discardButtonLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string.isRequired,
  executeButtonLabel: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
  onPublish: PropTypes.func,
  onExecute: PropTypes.func,
};

export default ModalTitleBar;
