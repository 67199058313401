import { get, post, destroy, put } from "./request";

const API = process.env.REACT_APP_API_PATH;

const restructureResponse = (response) =>
  response.map((tenant) => ({
    value: tenant.prefix,
    id: tenant.id,
    ubiqubeId: tenant.externalReference,
    label: tenant.displayName,
  }));

/*
 * Spec: https://10.31.1.52/swagger/index.html#/Lookup/listOperatorId
 */
export const getTenantsByManager = ({
  token,
  managerId,
  transforms = [restructureResponse],
}) => {
  return get({
    url: `${API}/lookup/manager/operators/id/${managerId}`,
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Lookup/listOperators
 */
export const getTenants = ({
  token,
  filter = "",
  filterCriteria = "",
  sort = "name",
  sortOrder: sort_order = "ASC",
  page = 1,
  pageSize: page_size = 10,
  transforms = [],
}) => {
  return get({
    url: `${API}/lookup/v1/operators`,
    token,
    transforms,
    queryParams: {
      filter,
      filter_criteria: filterCriteria,
      sort,
      sort_order,
      page,
      page_size,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Operator/readOperatorById
 */
export const getTenant = ({ token, id, transforms = [] }) => {
  return get({
    url: `${API}/operator/v1/${id}`,
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Operator/createOperator
 */
export const createTenant = ({ name, prefix, token }) => {
  return post({
    url: `${API}/operator/${prefix}`,
    token,
    queryParams: {
      name,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Operator/updateOperatorName
 */
export const editTenant = ({ name, prefix, token }) => {
  return put({
    url: `${API}/operator/${prefix}`,
    token,
    queryParams: {
      name,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Operator/deleteOperatorByPrefix
 */
export const deleteTenant = ({ prefix, token }) => {
  return destroy({
    url: `${API}/operator/${prefix}`,
    token,
  });
};
