import set from "lodash/set";
import get from "lodash/get";

/**
 * A utility for updating a path on a draft object if the path
 * exists on an existing object. This function will mutate the draft
 * object.
 * @param {object} draftObject - The draft object produced by immer
 * @param {object} existingObject - The existing  object the value may come from
 * @param {string} path - the object path that we want to access
 */
export const maybeUpdateDraft = (draftObject, existingObject, path) => {
  const potentialNewValue = get(existingObject, path);
  if (potentialNewValue) {
    set(draftObject, path, potentialNewValue);
  }
};

/**
 * A utility for updating multiple paths on a draft object if the path
 * exists on an existing object. This function will mutate the draft object.
 * @param {object} draftObject - The draft object produced by immer
 * @param {object} existingObject - The existing  object the value may come from
 * @param {array} paths - an array with the keys to be updated
 */
export const maybeUpdateDraftWithPaths = (
  draftObject,
  existingObject,
  paths,
) => {
  paths.forEach((path) => {
    maybeUpdateDraft(draftObject, existingObject, path);
  });
};
