import React, { useRef } from "react";
import { MenuItem as MaterialUIMenuItem } from "@material-ui/core";
import { MenuContext } from "./Menu";

const MenuItem = ({ onMouseEnter, ...rest }) => {
  const itemId = useRef(`${Math.floor(Math.random() * 1000)}.${Date.now()}`);
  const context = React.useContext(MenuContext);

  return (
    <MaterialUIMenuItem
      aria-disabled={true}
      onMouseEnter={() => {
        context.handleActiveItem(itemId.current);
        onMouseEnter && onMouseEnter();
      }}
      {...rest}
    />
  );
};

export default React.forwardRef((props, _) => <MenuItem {...props} />);
