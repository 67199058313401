import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { userRoles } from "msa2-ui/src/store/auth";

import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  heading: {
    marginBottom: spacing(2),
  },
}));

const UpsertAlarmNotificationTab = ({ formState, dispatch }) => {
  const { roles } = formState.rule;

  const { t } = useTranslation();
  const classes = useStyles();

  const onChange = (event) => {
    const { name, checked } = event.target;
    const updatedRoles = [...roles];
    if (checked === true) {
      updatedRoles.push(name);
    } else {
      const indexOfExistingRole = updatedRoles.indexOf(name);
      updatedRoles.splice(indexOfExistingRole, 1);
    }
    dispatch({
      type: "updateRuleRoles",
      payload: updatedRoles,
    });
  };

  const getCheckboxValue = (userRole) => {
    return roles.indexOf(userRole.toString()) > -1;
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Typography className={classes.heading}>
        {t("When alarm is triggered, send an email to these user roles")}:
      </Typography>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={getCheckboxValue(userRoles.PRIVILEGED_ADMINISTRATOR)}
              onChange={onChange}
              name={userRoles.PRIVILEGED_ADMINISTRATOR.toString()}
              inputProps={{
                "data-testid": "upsert-alarm-form-rule-roles-1",
              }}
            />
          }
          label={t("Privileged Administrator")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={getCheckboxValue(userRoles.ADMINISTRATOR)}
              onChange={onChange}
              name={userRoles.ADMINISTRATOR.toString()}
              inputProps={{
                "data-testid": "upsert-alarm-form-rule-roles-2",
              }}
            />
          }
          label={t("Administrator")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={getCheckboxValue(userRoles.PRIVILEGED_MANAGER)}
              onChange={onChange}
              name={userRoles.PRIVILEGED_MANAGER.toString()}
              inputProps={{
                "data-testid": "upsert-alarm-form-rule-roles-3",
              }}
            />
          }
          label={t("Privileged Manager")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={getCheckboxValue(userRoles.MANAGER)}
              onChange={onChange}
              name={userRoles.MANAGER.toString()}
              inputProps={{
                "data-testid": "upsert-alarm-form-rule-roles-4",
              }}
            />
          }
          label={t("Manager")}
        />
      </FormGroup>
    </Grid>
  );
};

UpsertAlarmNotificationTab.propTypes = {
  formState: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default UpsertAlarmNotificationTab;
