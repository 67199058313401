import { destroy, get, post, put } from "./request";
import produce from "immer";
import { iconMapper } from "msa2-ui/src/utils/iconMapping";
import Variable from "msa2-ui/src/services/Variable";
import Repository from "msa2-ui/src/services/Repository";
import { contentTypes } from "msa2-ui/src/api/constants";
import { topologyWFPath } from "msa2-ui/src/Constants";
import { weekdayObjectToString } from "msa2-ui/src/components/schedule/utils";
import { PERIODICITY } from "msa2-ui/src/components/schedule/constants";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";

const API = process.env.REACT_APP_API_PATH;

const createSubtenantObjects = (response) =>
  Object.entries(response).map(([id, name]) => ({
    id,
    name,
    // TODO: Replace hardcoded data
    total_instances: 8,
  }));

/*
 * Spec: https://10.31.1.52/swagger/index.html#/Repository/getUsage
 */
export const getSubtenantsByWorkflow = ({
  token,
  managerId: managerID,
  tenantId: tenant_id,
  workflowPath: URI,
  page,
  pageSize: page_size,
  sort,
  sortOrder: sort_order,
  transforms = [createSubtenantObjects],
}) => {
  return get({
    url: `${API}/repository/v1/usage`,
    queryParams: {
      managerID,
      tenant_id,
      URI,
      page,
      page_size,
      sort,
      sort_order,
    },
    token,
    transforms,
  });
};

const addServiceNameToMetaInformation = (response) => {
  return produce(response, (draft) => {
    draft.metaInformationList[0].serviceName = Repository.stripFileExtensionFromString(
      response.metaInformationList[0].uri,
    );
  });
};
/*
 * Spec: https://10.31.1.52/swagger/#/Repository/getWorkflowDefinition
 */
export const getWorkflow = ({
  token,
  pathToWorkflowDefinitionFile,
  addServiceName = false,
  transforms = [],
}) => {
  return get({
    url: `${API}/repository/v2/resource/workflow`,
    queryParams: {
      uri: Repository.addExtension(pathToWorkflowDefinitionFile, "xml"),
    },
    token,
    transforms: [
      ...(addServiceName ? [addServiceNameToMetaInformation] : []),
      ...transforms,
    ],
  });
};

// TODO: Look at normalizing the instances instead
// as it should help simplify the logic where the
// data is used
const convertInstancesToArray = (response) => ({
  ...response,
  instances: Object.entries(response.instances),
});

// Set displayField as a third object in array.
// If the variable which is defined as "displayField" does not exist, put the instance id as instance name
const addDisplayFieldIfDoesNotExist = (response) => ({
  ...response,
  instances: response.instances.map(([id, instance]) => [
    id,
    instance,
    instance.variables[response.information.displayField] || "",
  ]),
});

const normalizeScheduledWorkflow = (response) => {
  const defaultStatusValue = "none";
  const defaultWeekDayValue = "0000000";
  const defaultMonthValue = "000000000000";

  return response.map((instance) => {
    const STATUS_MAP = {
      ready: "ended",
      running: "running",
    };

    const {
      schedId,
      processId,
      instanceId,
      processSchedStatus,
      serviceName,
      processName,
      processType,
      beginDate,
      endDate,
      lastExecDate,
      nextExecDate,
      weekDay,
      month,
      period,
      periodUnit,
      parameters: { jsonvariable },
    } = instance;

    return {
      id: schedId,
      status: STATUS_MAP[processSchedStatus] ?? defaultStatusValue,
      serviceName,
      instanceId,
      processId,
      processDisplayName: processName.split("/").pop(),
      processName,
      processType,
      beginDate,
      endDate,
      lastExecutionDate: lastExecDate,
      nextExecutionDate: nextExecDate,
      weekDay: weekDay ?? defaultWeekDayValue,
      month: month ?? defaultMonthValue,
      period,
      periodUnit,
      variablesJson: jsonvariable,
    };
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/getServiceInstanceOwner
 * This API call returns more data, like workflow instances, than is
 * usually needed. For basic workflow data, use the getWorkflow call.
 */
export const getWorkflowDetails = ({
  token,
  ubiqubeId,
  workflowPath: serviceName,
  page,
  page_size,
  transforms = [convertInstancesToArray, addDisplayFieldIfDoesNotExist],
}) => {
  return get({
    url: `${API}/orchestration/v1/${ubiqubeId}/workflow/details`,
    queryParams: {
      serviceName,
      page,
      page_size,
    },
    token,
    transforms,
  });
};

const addInstanceIdAndName = ({ instances, ...rest }) => ({
  ...rest,
  instances: instances.map((entry) => ({
    ...entry,
    instanceId: entry.variables.SERVICEINSTANCEID,
    instanceName:
      entry.variables[rest.information?.displayField] ??
      entry.variables.SERVICEINSTANCEID,
  })),
});

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/getServiceInstanceDetails
 * This API call returns workflow instances
 */
export const getWorkflowInstances = ({
  token,
  ubiqubeId,
  workflowPath: serviceName,
  status,
  page = 1,
  pageSize: page_size = 10000,
  sort = "name",
  sortOrder: sort_order = "ASC",
  transforms = [],
  definedVarFlag,
}) => {
  return get({
    url: `${API}/orchestration/v2/${ubiqubeId}/workflow/details`,
    queryParams: {
      serviceName,
      status,
      page,
      page_size,
      sort,
      sort_order,
      definedVarFlag,
    },
    token,
    transforms: [addInstanceIdAndName, ...transforms],
  });
};

const convertIdToString = (response) =>
  response.map((instance) => ({ ...instance, id: String(instance.id) }));

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/getServiceInstances
 * This API call returns workflow instances
 */
export const getServiceInstances = ({
  token,
  ubiqubeId,
  workflowPath: serviceName,
  status,
  page = 1,
  pageSize: page_size = 10000,
  sort = "name",
  sortOrder: sort_order = "ASC",
  transforms = [],
}) => {
  return get({
    url: `${API}/orchestration/v2/${ubiqubeId}/service/instances`,
    queryParams: {
      serviceName,
      status,
      page,
      page_size,
      sort,
      sort_order,
    },
    token,
    transforms: [convertIdToString, ...transforms],
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/getServiceInstanceDetails
 * This API call returns workflow instance details
 */
export const getServiceInstanceDetails = ({
  token,
  serviceId,
  definedVarFlag,
  transforms = [],
}) => {
  return get({
    url: `${API}/orchestration/v2/service/instance/details/${serviceId}`,
    queryParams: {
      definedVarFlag,
    },
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/scheduleProcessesList
 */
export const getScheduledWorkflows = ({
  token,
  ubiqubeId,
  workflowPath: serviceName,
  serviceId,
  transforms = [normalizeScheduledWorkflow],
}) => {
  return get({
    url: `${API}/orchestration/${ubiqubeId}/schedule/list`,
    queryParams: {
      serviceName,
      serviceId,
    },
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/scheduleProcess2
 */
export const scheduleProcess = ({
  token,
  ubiqubeId,
  serviceId,
  serviceName,
  processName,
  processType,
  query,
  data,
}) => {
  const { beginDate, endDate, weekDay, month, period, periodUnit } = query;

  const params = {
    serviceId,
    serviceName,
    processName,
    processType,
    beginDate: beginDate.toString(),
    endDate: endDate.toString(),
    weekDay,
    month,
    period: period.toString(),
    periodUnit,
  };

  return post({
    url: `${API}/orchestration/${ubiqubeId}/schedule`,
    queryParams: {
      ...omitBy(params, isEmpty),
    },
    body: data,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/Delete
 */
export const deleteScheduledWorkflow = ({ token, schedId }) => {
  return destroy({
    url: `${API}/orchestration/schedule/${schedId}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/scheduleProcessEdit
 */
export const updateScheduledWorkflow = ({ token, schedId, body }) => {
  const {
    startDate,
    endDate,
    periodicity,
    periodicityValue,
    weekDay,
    month,
  } = body;

  return put({
    url: `${API}/orchestration/schedule/${schedId}`,
    token,
    body: {
      beginDate: startDate,
      endDate,
      periodUnit: periodicity,
      period: periodicityValue,
      ...(periodicity === PERIODICITY.monthly && {
        month: weekdayObjectToString(month),
      }),
      ...(periodicity === PERIODICITY.weekly && {
        weekDay: weekdayObjectToString(weekDay),
      }),
    },
  });
};

const convertDate = (dateStr) => dateStr && new Date(dateStr);

const transformProcessDate = (response) => {
  return response.map((prc) => ({
    ...prc,
    status: {
      ...prc.status,
      startingDate: convertDate(prc.status.startingDate),
      endingDate: convertDate(prc.status.endingDate),
    },
  }));
};

/*
 * Spec: https://10.31.1.52/swagger/index.html#/Orchestration/listProcessInstance
 */
export const getWorkflowInstanceHistory = ({
  token,
  instanceId,
  page = 0,
  rowsPerPage,
  transforms = [transformProcessDate],
}) => {
  return get({
    url: `${API}/orchestration/v1/service/process-instance/${instanceId}`,
    token,
    transforms,
    queryParams: {
      page: page + 1,
      page_size: rowsPerPage,
    },
  });
};

const transformTaskDate = (response) =>
  response.status
    ? {
        ...response,
        status: {
          ...response.status,
          startingDate: convertDate(response.status.startingDate),
          endingDate: convertDate(response.status.endingDate),
          processTaskStatus: response.status.processTaskStatus.map((task) => ({
            ...task,
            startingDate: convertDate(task.startingDate),
            endingDate: convertDate(task.endingDate),
          })),
        },
      }
    : null;

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/getProcessInstanceStatus
 */
export const getProcessInstanceStatus = ({
  token,
  processId,
  transforms = [transformTaskDate],
}) => {
  return get({
    url: `${API}/orchestration/v1/process-instance/${processId}`,
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/getProcessInstanceByReference
 */
export const getProcessInstanceByReference = ({
  token,
  processReference,
  transforms = [transformTaskDate],
}) => {
  return get({
    url: `${API}/orchestration/v1/process-instance/reference/${processReference}`,
    token,
    transforms,
  });
};

export const getWorkflowProgress = ({
  token,
  transforms,
  processId,
  processReference,
}) => {
  if (processReference)
    return getProcessInstanceByReference({
      token,
      transforms,
      processReference,
    });
  return getProcessInstanceStatus({
    token,
    transforms,
    processId,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/getProcessLog
 */
export const getProcessLogs = ({ token, serviceId, processId }) => {
  return get({
    url: `${API}/orchestration/logs/${serviceId}/${processId}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/launchProcessInstance
 */
export const launchProcessInstance = ({
  token,
  ubiqubeId,
  serviceId,
  processName,
  body,
  transforms = [transformTaskDate],
}) => {
  return post({
    url: `${API}/orchestration/process/execute/${ubiqubeId}/${serviceId}`,
    queryParams: {
      processName,
    },
    body,
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Orchestration/executeService
 */
export const executeService = ({
  token,
  ubiqubeId,
  serviceName,
  processName,
  body,
  transforms = [transformTaskDate],
}) => {
  return post({
    url: `${API}/orchestration/service/execute/${ubiqubeId}`,
    ubiqubeId,
    queryParams: {
      serviceName,
      processName,
    },
    body,
    token,
    transforms,
  });
};

export const addIconsToWorkflowList = ({ workflows, ...rest }) => {
  const newWorkflows = workflows.map(({ icon, ...workflow }) => ({
    ...workflow,
    icon: iconMapper(icon),
  }));

  return {
    workflows: newWorkflows,
    ...rest,
  };
};

// Hack! Right now we need to hide Topology WF from managers( I hope we will move this to BE in the future.
const maybeWithTopologyWF = (isDeveloper) => (response = {}) => {
  if (isDeveloper) {
    return response;
  }

  let workflows_count = response?.workflows_count || 0;
  let workflows = response?.workflows || [];

  workflows = workflows?.filter((wf) => {
    if (wf.path === topologyWFPath) {
      workflows_count--;
      return false;
    }

    return true;
  });

  return { ...response, workflows, workflows_count };
};

/**
 * Retrieves WFs
 * Also has some filtering logic for cases when we don't want to show Topology WF (if you are not a developer).
 * See MSA-8844 for more details.
 *
 * @param {Boolean} isDeveloper
 * @param {String} token
 * @param {Number} subtenantId
 * @param {Number} tenantId
 * @param {Number} page
 * @param {Number} page_size
 * @param {String} sort
 * @param {String} sort_order
 * @param {Object} defaultResponse
 * @param {Array} transforms
 * @returns {Promise<void>}
 */
export const getWorkflowList = ({
  isDeveloper = false,
  token,
  subtenantId,
  tenantId,
  page = 1,
  pageSize: page_size = 10,
  sort = "displayname",
  sortOrder: sort_order = "ASC",
  filterCriteria: filter_criteria,
  filter = "displayName",
  isFilteredByOwner: filterByOwner,
  defaultResponse = {
    workflows: [],
    workflows_count: 0,
  },
  transforms = [addIconsToWorkflowList],
}) => {
  return get({
    url: `${API}/orchestration/v1/wf-details`,
    queryParams: {
      customerId: subtenantId,
      tenant_id: tenantId,
      page,
      page_size,
      sort,
      sort_order,
      filter: filter_criteria ? filter : undefined,
      filter_criteria: filter_criteria || undefined,
      filterByOwner: filterByOwner || undefined,
    },
    token,
    defaultResponse,
    transforms: [maybeWithTopologyWF(isDeveloper), ...transforms],
  });
};

/**
 * Get workflow owner details
 *
 * @param {array} body - list of the workflows
 * @param {string} token - api token
 * @returns {Promise<[error, response, meta]>}
 */
export const getListOwnerDetails = ({ token, body }) => {
  return post({
    url: `${API}/repository/owner-details`,
    body,
    token,
  });
};

export const changeOwner = ({ token, ownerId, uri }) => {
  return put({
    url: `${API}/repository/owner-details?ownerId=${ownerId}`,
    body: [uri],
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/index.html#/Orchestration/getWorkflowProcessMapByStatus
 */
export const getWorkflowStatusReport = ({ token, subtenantId, transforms }) => {
  return get({
    url: `${API}/orchestration/v2/workflow-process/status/count`,
    queryParams: {
      customerId: subtenantId,
    },
    token,
    transforms,
  });
};

// Hack! Right now we need to hide Topology WF from managers( I hope we will move this to BE in the future.
const maybeShowReportsWithTopology = (isDeveloper) => (response) => {
  if (isDeveloper) return response;

  const { workflowReports, ...restResponse } = response;

  const filteredWorkflowReports = workflowReports?.filter((wfReport) => {
    if (wfReport.workflow.serviceName === topologyWFPath) {
      [
        "ended",
        "fail",
        "none",
        "pause",
        "running",
        "standby",
        "total",
        "total_workflows",
        "waiting",
        "warning",
        "recentlyExecutedProcesses",
      ].forEach((status) => {
        if (status === "total_workflows") {
          restResponse[status]--;
          return false;
        }
        if (status === "recentlyExecutedProcesses") {
          for (const key in restResponse[status]["processes"]) {
            const obj = restResponse[status]["processes"][key];
            if (obj.serviceName === topologyWFPath) {
              delete restResponse[status]["processes"][key];
            }
          }
          return false;
        }
        restResponse[status] -= wfReport[status];
      });

      return false;
    }
    return true;
  });

  return { ...restResponse, workflowReports: filteredWorkflowReports };
};

const renameKeys = ({
  total_workflows: totalProcessInstances,
  ...response
}) => ({
  ...response,
  totalProcessInstances,
});

/*
 * Spec: https://10.31.1.52/swagger/index.html#/Orchestration/getWorkflowReportByActor
 */
export const getWorkflowReportByActor = ({
  isDeveloper = false,
  token,
  subtenantId,
  tenantPrefix,
  page,
  pageSize: page_size,
  sort,
  sortOrder: sort_order,
  transforms = [renameKeys],
}) => {
  return get({
    url: `${API}/orchestration/v1/summary/actor`,
    queryParams: {
      customerId: subtenantId,
      tenantPrefix,
      page,
      page_size,
      sort,
      sort_order,
    },
    transforms: [maybeShowReportsWithTopology(isDeveloper), ...transforms],
    token,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/attachServicesToMultipleSubtenants
 */
export const attachServicesToMultipleSubtenants = ({
  token,
  uri,
  ubiqubeIds,
  transforms = [],
}) => {
  return post({
    url: `${API}/orchestration/service/attach`,
    queryParams: {
      uri,
      ubiqubeIds,
    },
    token,
    transforms,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/detachServicesFromMultipleCustomers
 */
export const detachServicesFromMultipleSubtenants = ({
  token,
  uri,
  ubiqubeIds,
  transforms = [],
}) => {
  return post({
    url: `${API}/orchestration/service/detach`,
    queryParams: {
      uri,
      ubiqubeIds,
    },
    token,
    transforms,
  });
};

/**
 * Spec: https://10.30.18.86/swagger/#/Orchestration/detachServices
 */
export const detachServices = ({ token, ubiqubeId, uri, transforms = [] }) => {
  return post({
    url: `${API}/orchestration/${ubiqubeId}/service/detach`,
    queryParams: {
      uri,
    },
    token,
    transforms,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/listServiceVariables_1
 */
export const getWorkflowInstanceVariableValues = ({
  token,
  instanceId,
  definedVarFlag,
  transforms = [Variable.convertObjectPath],
}) => {
  return get({
    url: `${API}/orchestration/v1/services/${instanceId}/service-variables`,
    queryParams: {
      definedVarFlag,
    },
    token,
    transforms,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/extractProcessVariablesWithTypeFromTask
 */
export const getProcessVariableTypesByTask = ({
  token,
  workflowPath,
  processName,
  subtenantId = "",
  transforms = [],
}) => {
  let urlToSend = `${API}/orchestration/v1/process-variables-with-type-from-task?serviceName=${workflowPath}&processName=${processName}`;
  if (subtenantId !== "") {
    urlToSend = urlToSend + `&subtenantId=${subtenantId}`;
  }
  return get({
    url: urlToSend,
    token,
    transforms,
  });
};

// This converts the response to fit "variables" object for Workflow definition.
const adjustVariables = (response) =>
  response.map(({ values, default_value, name, type }) => ({
    name: [Variable.variablePrefix, name].join("."),
    // If you do not define type in create_var_def in Task, it will be returned as "String".
    ...(type !== "String" && { type }),
    // Change key name to the same name as the one in "variables" object for Workflow definition
    ...(default_value && { defaultValue: default_value }),
    ...(values !== "[]" && values !== "" && { values }),
  }));

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/extractVariablesFromTaskWithType
 */
export const extractVariablesFromTaskWithType = ({
  token,
  task,
  transforms = [adjustVariables],
}) => {
  return get({
    url: `${API}/orchestration/v1/variables-from-task-with-type`,
    queryParams: { task },
    token,
    transforms,
  });
};

/*
https://10.31.1.52/swagger/#/Repository/createWorkflowRepositoryResource
*/
export const editWorkflow = ({ workflow, token }) => {
  return put({
    url: `${API}/repository/v2/resource/workflow`,
    body: { ...workflow },
    token,
  });
};

/*
https://10.31.1.52/swagger/#/Repository/addRepositoryResource_1
*/
export const createWorkflow = ({ workflow, token, saveOwner }) => {
  return post({
    url: `${API}/repository/v2/resource/workflow`,
    queryParams: {
      // saveOwner is a hidden flag for a particular users
      // so we convert false into undefined to make the key invisible
      saveOwner: saveOwner || undefined,
    },
    body: { ...workflow },
    token,
  });
};

/**
 * Deletes workflow.
 *
 * @param {string} uri - path to the workflow
 * @param {string} token - api token
 * @returns {Promise<[error, response, meta]>}
 */
export const deleteWorkflow = ({ uri, token }) => {
  return destroy({
    url: `${API}/repository/v1/resource/workflow`,
    queryParams: { uri },
    token,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/readServiceId
 */
export const readServiceId = ({ token, ubiqubeId, serviceId = "" }) => {
  return get({
    url: `${API}/orchestration/${ubiqubeId}/service/instance/${serviceId}`,
    token,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/restartWorkflow
 */

export const restartWorkflow = ({ token, process_id, body = {} }) => {
  return post({
    url: `${API}/orchestration/v2/process/${process_id}/resume`,
    body,
    token,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/pauseWorkflow
 */
export const pauseWorkflow = ({ token, process_id }) => {
  return post({
    url: `${API}/orchestration/v2/process/${process_id}/suspend`,
    token,
  });
};

/**
 * Spec: https://10.31.1.5/swagger/#/Orchestration/attemptToStopWorkflow
 */
export const attemptToStopWorkflow = ({ token, process_id }) => {
  return post({
    url: `${API}/orchestration/v2/process/${process_id}/attempt-to-stop`,
    token,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Orchestration/deleteServiceId
 */
export const deleteServiceId = ({ token, ubiqubeId, serviceId }) => {
  return destroy({
    url: `${API}/orchestration/${ubiqubeId}/service/instance/${serviceId}`,
    token,
  });
};

export const testTask = ({ token, file, fileContent }) => {
  const formData = new FormData();
  formData.append("file", new Blob([file], { type: "text/plain" }));
  formData.append(
    "content",
    new Blob([JSON.stringify(fileContent)], { type: "application/json" }),
  );

  return post({
    url: `${API}/orchestration/test-task`,
    body: formData,
    token,
    contentType: contentTypes.FORM_DATA,
  });
};

export const checkTaskStatus = ({ token, taskId, transforms }) => {
  return get({
    url: `${API}/orchestration/test-task/${taskId}`,
    token,
    transforms,
  });
};

export const getPythonSdk = ({ transforms = [] }) => {
  return get({
    url: "/msa_sdk/msa_sdk.json",
    transforms,
  });
};
