import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  CircularProgress,
  Grid,
  makeStyles,
  Tooltip,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { Close, FileCopy as CopyIcon } from "@material-ui/icons";
import { STATUS_TO_ICON_MAP, TASK_STATUS } from "./constants";
import { displayYearMonthTimeDateAsES } from "msa2-ui/src/utils/date";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import EditorAce from "msa2-ui/src/components/EditorAce";

const useStyles = makeStyles(({ palette }) => ({
  contentWrapper: {
    padding: 0,
  },
  loaderWrap: {
    textAlign: "center",
  },
  statusIcon: {
    width: 23,
    height: 23,
  },
  tabs: {
    backgroundColor: palette.background.default,
  },
  taskName: {
    fontSize: 16,
    paddingBottom: 5,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "19px",
    maxHeight: "38px",
    maxWidth: 420,
  },
}));

const TaskExecutionDialog = ({ taskName, taskExecutionData, onClose }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_, tab) => setActiveTab(tab);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(taskExecutionData.error);
    enqueueSnackbar(t("Message copied."));
  };

  const renderTaskStatusPanel = () => {
    const StatusIcon = STATUS_TO_ICON_MAP[taskExecutionData.status];
    const { startDate, endDate } = taskExecutionData;

    return (
      <Grid container>
        <Grid item xs={1}>
          <StatusIcon className={classes.statusIcon} />
        </Grid>
        <Grid item container xs={3} direction="column">
          <Typography variant="h4" className={classes.taskName}>
            {taskName}
          </Typography>
          <Grid item container alignItems="center">
            <Tooltip title={taskExecutionData.error || ""}>
              <Typography
                variant="body1"
                className={commonClasses.commonDescription}
              >
                {taskExecutionData.status}
              </Typography>
            </Tooltip>
            {taskExecutionData.error && (
              <Tooltip title={t("Click to copy messages")}>
                <CopyIcon
                  onClick={handleCopyToClipboard}
                  className={commonClasses.commonCopyToClipboard}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body1"
            className={commonClasses.commonDescription}
            align="center"
          >
            {startDate ? displayYearMonthTimeDateAsES(startDate) : ""}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body1"
            className={commonClasses.commonDescription}
            align="center"
          >
            {endDate ? displayYearMonthTimeDateAsES(endDate) : ""}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {startDate && endDate && (
            <Typography
              variant="body1"
              className={commonClasses.commonDescription}
            >
              {"("}
              {(new Date(endDate) - new Date(startDate)) / 1000}
              {"s)"}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
      PaperComponent={({ className, ...props }) => (
        <Paper
          className={classNames(
            commonClasses.commonPaperNoPadding,
            commonClasses.commonDialogPaper,
            className,
          )}
          {...props}
        />
      )}
    >
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {taskName}
        </Typography>
        <IconButton
          id="TASK_EXECUTION_DIALOG_CLOSE_BTN"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab label={t("Tasks")} />
          <Tab label={t("Logs")} />
        </Tabs>
        <Box p={3}>
          {taskExecutionData.status === TASK_STATUS.PENDING ? (
            <div className={classes.loaderWrap}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div style={{ display: activeTab === 0 ? "block" : "none" }}>
                {renderTaskStatusPanel()}
              </div>
              <div style={{ display: activeTab === 1 ? "block" : "none" }}>
                <EditorAce
                  readOnly
                  value={taskExecutionData.logs || t("No logs to show")}
                />
              </div>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

TaskExecutionDialog.propTypes = {
  taskName: PropTypes.string.isRequired,
  taskExecutionData: PropTypes.shape({
    logs: PropTypes.string,
    status: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskExecutionDialog;
