import Validation from "msa2-ui/src/services/Validation";

const arrayParameters = [
  "arrayCanAdd",
  "arrayCanEdit",
  "arrayCanMove",
  "arrayCanRemove",
];

const ignoreParameters = [
  "type",
  "displayName",
  "description",
  "name",
  "arrayCanAdd",
  "arrayCanEdit",
  "arrayCanMove",
  "arrayCanRemove",
  "displayOrder",
];

const getDeviceTypeVariables = (variables) =>
  variables.filter((variable) => variable.type === "Device");

const getType = (advancedParameterName, type, formVariables) => {
  let returnType = type;
  let listData = [];
  let fieldValidation = [];
  if (advancedParameterName === "refDeviceIdVar") {
    returnType = "combo";
    listData = getDeviceTypeVariables(formVariables);
    fieldValidation = [Validation.required];
  }
  return {
    type: returnType,
    listData,
    fieldValidation,
  };
};

const showFieldBasedOnDependencyValue = (
  parameter,
  formVariableValue,
  dependentOnParameter,
) => {
  if (!parameter.depends) {
    return true;
  }
  const dependentMustBeOfValue = parameter.dependsValue;
  const currentValueOfDependency = formVariableValue[parameter.depends];

  if (dependentMustBeOfValue === "required") {
    if (Array.isArray(currentValueOfDependency)) {
      return currentValueOfDependency.length > 0;
    }
    return Boolean(currentValueOfDependency);
  }

  const dependencyAllowsMultipleEntries = Boolean(
    dependentOnParameter?.allowMultipleEntries,
  );
  return (
    dependentMustBeOfValue === currentValueOfDependency ||
    dependencyAllowsMultipleEntries
  );
};

/**
 * These advanced parameters are available in microservice and workflow to utilize
 */
const commonAdvancedParametersToUtilize = ["editLocked"];

/**
 *
 * Function to utilise or update variables based on given parameters
 *
 * @param {Array} variables | Workflow variables or Microservice variables
 * @param {*} parameters
 * @returns {Array}
 */
const utilizeAdvancedParameters = (
  variables = [],
  parameters = commonAdvancedParametersToUtilize,
) => {
  const updateFunctions = {
    editLocked: (variable) => ({
      ...variable,
      userLocked: variable.userLocked || variable.editLocked,
    }),
  };

  return variables.map((variable) =>
    parameters.reduce(
      (updatedVariable, parameter) =>
        parameter in updateFunctions
          ? updateFunctions[parameter](updatedVariable)
          : updatedVariable,
      { ...variable },
    ),
  );
};

export default {
  arrayParameters,
  ignoreParameters,
  getType,
  showFieldBasedOnDependencyValue,
  getDeviceTypeVariables,
  utilizeAdvancedParameters,
};
