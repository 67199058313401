import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import Topology from "msa2-ui/src/components/topology/Topology";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    minHeight: 500,
  },
}));

const TopologyTabView = ({
  t,
  errorMessage,
  isPending,
  isSubtenantEmpty,
  topologyData,
  onSaveNodes,
  onUpdateTopology,
  workflowInstances,
  onTopologyChange,
  reloadWorkflowInstances,
}) => {
  const classes = useStyles();

  const renderView = () => {
    if (isSubtenantEmpty) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          {t("Please choose a subtenant")}
        </Grid>
      );
    }

    if (errorMessage) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          {errorMessage}
        </Grid>
      );
    }

    return (
      <Topology
        isPending={isPending}
        data={topologyData.nodes}
        onSaveNodes={onSaveNodes}
        onUpdateTopology={onUpdateTopology}
        serviceId={topologyData?.serviceVariables?.serviceId}
        workflowInstances={workflowInstances}
        onTopologyChange={onTopologyChange}
        reloadWorkflowInstances={reloadWorkflowInstances}
      />
    );
  };

  return (
    <Grid container className={classes.container}>
      {renderView()}
    </Grid>
  );
};

TopologyTabView.propTypes = {
  t: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isPending: PropTypes.bool.isRequired,
  isSubtenantEmpty: PropTypes.bool.isRequired,
  topologyData: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  onSaveNodes: PropTypes.func.isRequired,
  onUpdateTopology: PropTypes.func.isRequired,
  workflowInstances: PropTypes.array.isRequired,
  onTopologyChange: PropTypes.func.isRequired,
  reloadWorkflowInstances: PropTypes.func.isRequired,
};

export default TopologyTabView;
