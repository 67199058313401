import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default ({ color = "primary" }) => {
  const COLORS = {
    primary: "#46597E",
    secondary: "#7E93A6",
    disabled: "rgba(255,255,255,0.3)",
  };

  return (
    <SvgIcon viewBox="-4 -4 24 24">
      <g
        transform="translate(1 1)"
        stroke={COLORS[color]}
        strokeWidth="2"
        fill="none"
      >
        <circle cx="7" cy="7" r="7" />
        <path d="M7 2v5h3.997" />
      </g>
    </SvgIcon>
  );
};
