import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import difference from "lodash/difference";
import { useParams } from "react-router";
import { Link, useHistory, Switch, Route } from "react-router-dom";

import { getAvailableTenants } from "msa2-ui/src/store/designations";
import { getToken } from "msa2-ui/src/store/auth";
import set from "lodash/set";
import { editAdministrator } from "msa2-ui/src/api/administrator";
import Validation from "msa2-ui/src/services/Validation";

import {
  Tabs,
  Tab,
  TextField,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import Dialog from "msa2-ui/src/components/Dialog";
import PasswordInput from "msa2-ui/src/components/PasswordInput";
import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const Edit = ({ administrators }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const token = useSelector(getToken);
  const { id: adminId } = useParams();
  const administrator = administrators?.find(
    (item) => item.id.toString() === adminId,
  );
  const tenants = useSelector(getAvailableTenants);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [name, setName] = useState(administrator?.name);
  const [email, setEmail] = useState(administrator?.address.email);
  const [username, setUsername] = useState(administrator?.login);
  const [password, setPassword] = useState(administrator?.pwd);
  const [usertype, setUserType] = useState(administrator?.userType);
  const [errorMessage, setErrorMessage] = useState("");
  const [associatedTenantIds, setAssociatedTenantIds] = useState(
    administrator?.operatorIds,
  );

  const handlePortalAccessChange = ({ target: { name, checked } }) => {
    const portalView = {
      manager: [0, 2].includes(usertype),
      developer: [1, 2].includes(usertype),
    };
    set(portalView, name, checked);
    if (portalView.manager && !portalView.developer) {
      setUserType(0);
    } else if (!portalView.manager && portalView.developer) {
      setUserType(1);
    } else if (portalView.manager && portalView.developer) {
      setUserType(2);
    } else {
      setUserType(null);
    }
  };

  if (!administrator) {
    history.push("/admin/administrators");
  }

  const onSubmit = async () => {
    setErrorMessage("");
    if (!name || !email || !username || usertype === null) {
      return setErrorMessage(t("Please fill out all the form fields"));
    }
    if (!Validation.validEmail(email)) {
      return setErrorMessage(t("Please enter a valid email address"));
    }

    const [error] = await editAdministrator({
      token,
      id: adminId,
      administrator,
      email,
      username,
      password,
      usertype,
      name,
      associatedTenantIds,
    });
    const message = error ? error.getMessage() : t("Administrator updated");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    if (!error) {
      history.push("/admin/administrators");
    }
  };

  const renderTabs = () => {
    const { pathname } = window.location;
    const activeTab =
      pathname === `/admin/administrators/${adminId}/edit/associated-tenants`
        ? 1
        : 0;

    return (
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        variant="standard"
        className={classes.tabs}
      >
        <Tab
          key={0}
          id={"EDIT_ADMINISTRATOR_DIALOG_INFORMATION_TAB"}
          classes={{
            selected: commonClasses.commonTabSelected,
          }}
          label={t("Information")}
          component={Link}
          to={`/admin/administrators/${adminId}/edit`}
        />
        <Tab
          key={1}
          id={"EDIT_ADMINISTRATOR_DIALOG_ASSOCIATED_TENANTS_TAB"}
          classes={{
            selected: commonClasses.commonTabSelected,
          }}
          label={t("Associated Tenants")}
          component={Link}
          to={`/admin/administrators/${adminId}/edit/associated-tenants`}
        />
      </Tabs>
    );
  };

  const renderInformationTab = () => {
    return (
      <>
        <TextField
          variant="outlined"
          id="ADMINISTRATOR_EDIT_NAME"
          label={t("Name")}
          value={name}
          required
          onChange={(event) => setName(event.target.value)}
          className={classes.formField}
        />
        <TextField
          variant="outlined"
          id="ADMINISTRATOR_EDIT_EMAIL"
          type="email"
          label={t("Email")}
          value={email}
          required
          onChange={(event) => setEmail(event.target.value)}
          className={classes.formField}
        />
        <TextField
          variant="outlined"
          id="ADMINISTRATOR_EDIT_USERNAME"
          label={t("Username")}
          autoComplete="new-password"
          value={username}
          required
          onChange={(event) => setUsername(event.target.value)}
          className={classes.formField}
        />
        <PasswordInput
          variant="outlined"
          id="ADMINISTRATOR_CREATE_PASSWORD"
          label={t("Password")}
          autoComplete="new-password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className={classes.formField}
        />

        <FormControl
          required
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel component="legend" className={classes.formField}>
            {t("User Portal Access")}
          </FormLabel>
          <FormGroup>
            <Grid container direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    id="ADMINISTRATOR_EDIT_MANAGER_PORTAL_ACCESS"
                    checked={[0, 2].includes(usertype)}
                    onChange={handlePortalAccessChange}
                    name="manager"
                    color="primary"
                  />
                }
                label="Manager"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="ADMINISTRATOR_EDIT_DEVELOPER_PORTAL_ACCESS"
                    checked={[1, 2].includes(usertype)}
                    onChange={handlePortalAccessChange}
                    name="developer"
                    color="primary"
                  />
                }
                label="Developer"
              />
            </Grid>
          </FormGroup>
        </FormControl>
      </>
    );
  };

  const renderTenantsTab = () => {
    return (
      <AttachmentBoard
        allItems={tenants}
        attachedItemIds={associatedTenantIds}
        idKey="id"
        displayNameKey="label"
        unattachedTitle={`${t("Tenants to associate")}`}
        attachedTitle={`${t("Associated Tenants")}`}
        searchPlaceholder={t("Search for Tenants")}
        handleAttachAll={() =>
          setAssociatedTenantIds(tenants.map(({ id }) => id))
        }
        handleAttach={(tenantIdsToAttach) =>
          setAssociatedTenantIds(associatedTenantIds.concat(tenantIdsToAttach))
        }
        handleDetach={(tenantIdsToDetach) =>
          setAssociatedTenantIds(
            difference(associatedTenantIds, tenantIdsToDetach),
          )
        }
        handleDetachAll={() => setAssociatedTenantIds([])}
      />
    );
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={() => history.push("/admin/administrators")}
      execLabel={t("Save")}
      title={t("Update Administrator")}
      tabs={renderTabs()}
    >
      <Grid container direction="column">
        <Switch>
          <Route
            path={"/admin/administrators/:id/edit/associated-tenants"}
            render={renderTenantsTab}
          />
          <Route render={renderInformationTab} />
        </Switch>
        {errorMessage && (
          <Typography
            color="error"
            className={classes.error}
            id="ADMINISTRATOR_EDIT_ERROR"
          >
            {errorMessage}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default Edit;
