import get from "lodash/get";

/**
 * A generic filter function for lists of items
 *
 * If the pathsToFilterAgainst parameter is passed it will assume
 * that the list contains objects and will use the paths to
 * filter against nested properties
 */
export const filter = (list, filterTerm, pathsToFilterAgainst = []) => {
  if (!list) {
    return [];
  }

  if (!Array.isArray(list) || !list.length || !filterTerm) {
    return list;
  }

  return list.filter((listItem) => {
    const contentItemsToFilterAgainst = [];

    if (Array.isArray(pathsToFilterAgainst) && pathsToFilterAgainst.length) {
      pathsToFilterAgainst.forEach((path) => {
        const contentAtPath = get(listItem, path);
        contentItemsToFilterAgainst.push(contentAtPath);
      });
    } else {
      contentItemsToFilterAgainst.push(listItem);
    }

    let shouldKeepItemInList = false;

    contentItemsToFilterAgainst.forEach((contentItem) => {
      if (typeof contentItem === "object") {
        return;
      }

      const contentItemAsLowerCaseString = String(contentItem).toLowerCase();
      const filterTermAsLowerCaseString = String(filterTerm).toLowerCase();

      if (contentItemAsLowerCaseString.includes(filterTermAsLowerCaseString)) {
        shouldKeepItemInList = true;
      }
    });

    return shouldKeepItemInList;
  });
};
