import { ReactComponent as IconSuccess } from "msa2-ui/src/assets/icons/status_process/success.svg";
import { ReactComponent as IconRunning } from "msa2-ui/src/assets/icons/status_process/running.svg";
import { ReactComponent as IconFailed } from "msa2-ui/src/assets/icons/status_process/failed.svg";
import { ReactComponent as IconNotStarted } from "msa2-ui/src/assets/icons/status_process/notStarted.svg";

export const TASK_STATUS = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  ENDED: "ENDED",
  FAIL: "FAIL",
};

export const STATUS_TO_ICON_MAP = {
  [TASK_STATUS.PENDING]: IconNotStarted,
  [TASK_STATUS.RUNNING]: IconRunning,
  [TASK_STATUS.ENDED]: IconSuccess,
  [TASK_STATUS.FAIL]: IconFailed,
};
