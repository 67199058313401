import { get, post, put, destroy } from "./request";

const API = process.env.REACT_APP_API_PATH;
const currentMsaVersion = process.env.REACT_APP_MSA_VERSION;

/**
 * Adds the current UI commit hash so it's easier to track what the currently
 * deployed version is
 * @param {object} response
 */
const addUiHash = (response) => ({
  ...response,
  uiVersion: currentMsaVersion ?? "Development",
});

/*
 * No Swagger doc exists, this is hosted directly on the front container
 */
export const getSystemVersion = ({ token }) => {
  return get({
    /**
     * The trailing slash here is very important for local proxying
     * If it is not there, the front nginx container will redirect you
     * which will bring up all sorts of CORs issues
     */
    url: `/msa_version/`,
    token,
    transforms: [addUiHash],
  });
};

export const getImportExportSettings = ({ token, transforms }) => {
  return get({
    url: `${API}/library/git-configure/`,
    token,
    transforms,
  });
};

export const addImportExportEntry = ({ token, body }) => {
  return post({
    url: `${API}/library/git-configure/`,
    body,
    token,
  });
};

export const deactivateImportExportEntry = ({ token, params }) => {
  return destroy({
    url: `${API}/library/git-configure/`,
    queryParams: params,
    token,
  });
};

export const editImportExportEntry = ({ token, body }) => {
  return put({
    url: `${API}/library/git-configure/`,
    body,
    token,
  });
};

/**
 * SPEC: https://10.31.1.52/swagger/#/Library/gitRemoteRepoPull
 */
export const gitRemoteRepoPull = ({ token, body }) => {
  return post({
    url: `${API}/library/git-pull/`,
    body,
    token,
  });
};

/**
 * SPEC: https://10.31.1.190/swagger/#/Library/gitRemoteRepoPull_1
 */
export const gitRemoteRepoV2Pull = ({ token, body, prefix }) => {
  return post({
    url: `${API}/library/git-pull/v2/${prefix}`,
    body,
    token,
  });
};
