import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { getParentRoute } from "msa2-ui/src/utils/urls";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Link, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ManagedEntityHistoryButtons from "./ManagedEntityHistoryButtons";
import useApi from "msa2-ui/src/hooks/useApi";
import { getConfiguration } from "msa2-ui/src/api/managedEntity";
import EditorAce from "msa2-ui/src/components/EditorAce";

const useStyles = makeStyles(({ darkMode, palette, typography }) => ({
  link: {
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    width: "fit-content",
    marginBottom: 20,
  },
  backIcon: {
    color: darkMode ? palette.secondary.main : palette.primary.main,
  },
  backToPreviousPage: {
    display: "flex",
    alignItems: "center",
    color: darkMode ? palette.secondary.main : palette.primary.main,
    letterSpacing: 0.3,
    fontSize: 13,
  },
  header: {
    padding: 10,
  },
  errorMessage: {
    textAlign: "center",
    padding: 10,
  },
  loadingIndicator: {
    height: 300,
  },
}));

const ManagedEntityHistoryDetails = ({
  deviceId,
  revision,
  maintenanceMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const [isLoading, error, configuration = ""] = useApi(
    getConfiguration,
    { deviceId, revision },
    !deviceId || !revision,
  );

  return (
    <>
      <Grid container direction="column" className={classes.header}>
        <Grid item>
          <Link
            id="REVISION_DETAILS_LINK_BACK_TO_REVISIONS_LIST"
            className={classes.link}
            to={{
              pathname: getParentRoute(url),
            }}
          >
            <KeyboardArrowLeft className={classes.backIcon} />
            <Typography className={classes.backToPreviousPage}>
              {t("Back to Revisions list")}
            </Typography>
          </Link>
        </Grid>
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid item alignItems="center">
            <Typography display="inline" variant="subtitle1">
              {revision}
            </Typography>
            <Typography display="inline" variant="body2" />
          </Grid>
          <Grid item>
            <ManagedEntityHistoryButtons
              deviceId={deviceId}
              selectedRevisions={[revision]}
              revisionsEntries={[]}
              hideDiff
              hideBackup
              maintenanceMode={maintenanceMode}
            />
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid
          container
          className={classes.loadingIndicator}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {error ? (
            <Typography className={classes.errorMessage} color="error">
              {error}
            </Typography>
          ) : (
            <EditorAce readOnly value={configuration} />
          )}
        </>
      )}
    </>
  );
};

ManagedEntityHistoryDetails.propTypes = {
  deviceId: PropTypes.number.isRequired,
  revision: PropTypes.string.isRequired,
};

export default ManagedEntityHistoryDetails;
