import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogActions,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
import { forgotPassword } from "msa2-ui/src/api/auth";
import { ReactComponent as SentEmailIcon } from "msa2-ui/src/assets/img/sent.svg";
import { useSnackbar } from "notistack";
import { status } from "msa2-ui/src/api/constants";

const REQUEST_STATUS = {
  OK: "OK",
  NOT_FOUND: "NOT_FOUND",
  NOT_INITIALIZED: "NOT_INITIALIZED",
  PENDING: "PENDING",
};

const ForgotPasswordDialog = ({ userName: initialUserName = "", onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const commonClasses = useCommonStyles();

  const { t } = useTranslation();
  const [userName, setUserName] = useState(initialUserName);

  const [requestStatus, setRequestStatus] = useState(
    REQUEST_STATUS.NOT_INITIALIZED,
  );

  const handleSend = async () => {
    setRequestStatus(REQUEST_STATUS.PENDING);

    const [error] = await forgotPassword({ userName });

    if (error?.statusCode === status.NOT_FOUND) {
      return setRequestStatus(REQUEST_STATUS.NOT_FOUND);
    }

    // some generic error
    if (error) {
      enqueueSnackbar(error.getMessage(), {
        variant: "error",
      });
      return onClose();
    }

    setRequestStatus(REQUEST_STATUS.OK);
  };

  return (
    <Dialog
      data-testid="FORGOT_PASSWORD_DIALOG"
      id="FORGOT_PASSWORD_DIALOG"
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="forgot-password-dialog-title"
      aria-describedby="forgot-password-dialog-success"
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        id="FORGOT_PASSWORD_DIALOG_TITLE"
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
          aling="center"
        >
          {t("Forgot Password?")}
        </Typography>
        <IconButton
          id="FORGOT_PASSWORD_DIALOG_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={commonClasses.commonDialogContent}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: 150 }}
        >
          {requestStatus === REQUEST_STATUS.NOT_INITIALIZED && (
            <>
              <Grid item xs={10}>
                <Typography
                  id="FORGOT_PASSWORD_DIALOG_MSG_NOT_INITIALIZED"
                  variant="body1"
                  align="center"
                >
                  {t(
                    "Enter your username and we'll send you a link to reset your password.",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  data-testid="FORGOT_PASSWORD_DIALOG_INPUT"
                  required
                  variant="outlined"
                  style={{ marginTop: 20, width: 400 }}
                  label={t("Username")}
                  value={userName}
                  onChange={({ target }) => setUserName(target.value)}
                />
              </Grid>
            </>
          )}

          {requestStatus === REQUEST_STATUS.PENDING && <CircularProgress />}

          {requestStatus === REQUEST_STATUS.OK && (
            <>
              <Grid item xs={10}>
                <SentEmailIcon />
              </Grid>
              <br />
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  id="FORGOT_PASSWORD_DIALOG_MSG_SUCCESS"
                  align="center"
                >
                  {t("email sent", { userName })}
                </Typography>
              </Grid>
              <br />
              <Grid item xs={10}>
                <Typography variant="body2" align="center">
                  {t("Don't see the email? Please contact your administrator.")}
                </Typography>
              </Grid>
            </>
          )}

          {requestStatus === REQUEST_STATUS.NOT_FOUND && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  id="FORGOT_PASSWORD_DIALOG_MSG_FAIL"
                  align="center"
                >
                  {t("There is no email address associated to user", {
                    userName,
                  })}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          id="FORGOT_PASSWORD_DIALOG_CANCEL"
          variant="text"
          size="small"
          color="default"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="FORGOT_PASSWORD_DIALOG_SEND_LINK"
          variant="contained"
          size="small"
          color="primary"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          onClick={handleSend}
          disabled={
            !userName || requestStatus !== REQUEST_STATUS.NOT_INITIALIZED
          }
        >
          {t("Send Link")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ForgotPasswordDialog.propTypes = {
  userName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ForgotPasswordDialog;
