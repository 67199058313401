import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { VARIABLE_NAME_FOR_INPUT_CONTEXT } from "msa2-ui/src/services/Bpm";

import { updateProcessVariable } from "msa2-ui/src/api/bpm";
import { getToken } from "msa2-ui/src/store/auth";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import VariableEditDialog from "./VariableEditDialog";

const TaskRuntimeDialog = ({
  bpmModeler: { modelerState, modelerActions },
  processInstanceId,
  activeElementStatus,
  statusLoading,
  isFinished,
}) => {
  const { activeElement } = modelerState;
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);

  const unmount = modelerActions.unselectElement;

  const onChangeVariable = async ({ inputVariables }) => {
    const [error] = await updateProcessVariable({
      token,
      processInstanceId,
      varName: VARIABLE_NAME_FOR_INPUT_CONTEXT,
      value: inputVariables,
    });

    const message = error
      ? error.getMessage(t("Unable to update Variables."))
      : t("Variables has been updated.");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      unmount();
    }
  };

  return (
    <VariableEditDialog
      element={activeElement}
      onClose={unmount}
      onExec={onChangeVariable}
      message={null}
      processInstanceId={processInstanceId}
      activeElementStatus={activeElementStatus}
      isFinished={isFinished}
      statusLoading={statusLoading}
    />
  );
};

TaskRuntimeDialog.propTypes = {
  bpmModeler: PropTypes.shape({
    modelerState: PropTypes.object.isRequired,
    modelerActions: PropTypes.object.isRequired,
    moddle: PropTypes.object,
  }).isRequired,
  processInstanceId: PropTypes.string.isRequired,
  activeElementStatus: PropTypes.shape({
    isExecuted: PropTypes.bool,
    isActive: PropTypes.bool,
    willExecute: PropTypes.bool,
  }).isRequired,
  statusLoading: PropTypes.bool.isRequired,
};

export default TaskRuntimeDialog;
