import React, {
  Fragment,
  Children,
  useState,
  useRef,
  cloneElement,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import get from "lodash/get";
import has from "lodash/has";
import flattenDeep from "lodash/flattenDeep";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import createTheme from "msa2-ui/src/styles/theme";

import { ReactComponent as IconArrowUp } from "msa2-ui/src/assets/icons/arrow/arrowUp.svg";
import { ReactComponent as IconArrowDown } from "msa2-ui/src/assets/icons/arrow/arrowDown.svg";

const msaTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  iconArrow: {
    marginLeft: 1,
  },
  folderWrapper: {
    cursor: "pointer",
  },
}));
const getRuledLine = (current, max, isLastChild, height = 40) => {
  const pathEnd = (
    <svg x={0} y={0} width={20} height={height}>
      <line
        x1={12}
        y1={21}
        x2={20}
        y2={21}
        stroke={msaTheme.colors.blueDark1}
        strokeWidth={2}
        strokeDasharray={2}
      />
      <line
        x1={9}
        y1={0}
        x2={9}
        y2={22}
        stroke={msaTheme.colors.blueDark1}
        strokeWidth={2}
        strokeDasharray={2}
      />
    </svg>
  );
  const pathMiddle = (
    <svg x={0} y={0} width={20} height={height}>
      <line
        x1={12}
        y1={21}
        x2={20}
        y2={21}
        stroke={msaTheme.colors.blueDark1}
        strokeWidth={2}
        strokeDasharray={2}
      />
      <line
        x1={9}
        y1={0}
        x2={9}
        y2={height}
        stroke={msaTheme.colors.blueDark1}
        strokeWidth={2}
        strokeDasharray={2}
      />
    </svg>
  );
  const pathBlank = <svg x={0} y={0} width={20} height={height} />;
  const pathStraight = (
    <svg x={0} y={0} width={20} height={height}>
      <line
        x1={9}
        y1={0}
        x2={9}
        y2={height}
        stroke={msaTheme.colors.blueDark1}
        strokeWidth={2}
        strokeDasharray={2}
      />
    </svg>
  );
  if (current !== max) {
    return isLastChild ? pathBlank : pathStraight;
  } else {
    return isLastChild ? pathEnd : pathMiddle;
  }
};

/**
 * The combination of this component and TreeView component makes foldable tree structured item.
 * @param {function} folderComponent
 * @param {function} itemComponent
 *         You have to pass these 2 props to TreeView component and it passes them to this component.
 * @param {Boolean} hasAction pass true to change clickable target to only arrow icon.
 * @param {Array<Boolean>} isLastChild
 * @param {Boolean} isEmptyFolder
 *         Do not pass these 2 props as they are passed from this component itself when it is recursive.
 * @param {Integer} countChildren The number you want to show on the folder.
 * @param {Boolean} hideCount Pass true to hide count.
 * @param rest of items will be passed to either of itemComponent and folderComponent.
 * @return React component
 **/
const TreeItem = (props) => {
  const {
    component,
    folderComponent,
    itemComponent,
    hasAction,
    children,
    isLastChild = [],
    isEmptyFolder,
    countChildren,
    hideCount = false,
    ...rest
  } = props;

  const isFolder = Boolean(children) || isEmptyFolder;
  const FolderComponent = component || folderComponent;
  const ItemComponent = component || itemComponent;
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const ref = useRef(null);
  const toggleOpen = (execute) => execute && setOpen(!open);
  return (
    <>
      <Grid
        ref={ref}
        container
        wrap={"nowrap"}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        {isLastChild &&
          isLastChild.map((val, i) => (
            <Fragment key={i}>
              {getRuledLine(
                i + 1,
                isLastChild.length,
                val,
                ref.current?.clientHeight,
              )}
            </Fragment>
          ))}
        {isFolder && (
          <Grid
            container
            wrap={"nowrap"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            onClick={() => toggleOpen(!hasAction)}
            className={classnames({ [classes.folderWrapper]: !hasAction })}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
            <div
              onClick={() => toggleOpen(hasAction)}
              className={classnames({ [classes.folderWrapper]: hasAction })}
              role="button"
            >
              {open ? (
                <IconArrowUp className={classes.iconArrow} />
              ) : (
                <IconArrowDown className={classes.iconArrow} />
              )}
            </div>
            {!hideCount && (
              <FolderComponent countChildren={countChildren} {...rest} />
            )}
          </Grid>
        )}
        {!isFolder && <ItemComponent {...rest} />}
      </Grid>
      {open &&
        Children.map(
          children,
          (child, i) =>
            !isEmptyFolder &&
            cloneElement(child, {
              isLastChild: isLastChild.concat(
                i + 1 ===
                  (Array.isArray(children) ? flattenDeep(children).length : 1),
              ),
              isEmptyFolder:
                has(child, "props.children") && !get(child, "props.children"),
              folderComponent,
              itemComponent,
            }),
        )}
    </>
  );
};

TreeItem.propTypes = {
  folderComponent: PropTypes.func,
  itemComponent: PropTypes.func,
  countChildren: PropTypes.number,
};

export default TreeItem;
