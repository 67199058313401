import i18n from "i18next";
import { post, put } from "./request";
import { status } from "./constants";
import isEmpty from "lodash/isEmpty";

const API = process.env.REACT_APP_API_PATH;

export const extractTokenFromHeaders = ({ authorization }) => {
  return authorization.replace(/ /g, "").split("Bearer:")[1];
};

/*
 * Spec: https://10.31.1.52/swagger/#/Authentication/authenticate
 */
export const login = async ({ credentials }) => {
  const { username, password, logInAsOperator, logInAsDeveloper } = credentials;
  const [error, response, headers] = await post({
    url: `${API}/auth/token`,
    body: { username, password },
  });
  const userType = response?.userDetails?.userType;

  // Explicitly handle the scenario where the API is not yet available
  if (headers?.status === status.BAD_GATEWAY) {
    return [
      i18n.t("Please wait, API is starting"),
      { message: "Please wait, API is starting" },
    ];
    //return [i18n.t("Login Unavailable")];
  }

  //API is not avaiable
  if (headers?.status === status.INTERNAL_SERVER_ERROR && isEmpty(error.data)) {
    return [
      i18n.t("Please check, API is down"),
      { message: "Please check, API is down" },
    ];
    //return [i18n.t("Login Unavailable")];
  }

  // Incorrect credentials result in an error from the API
  if (error) {
    return [i18n.t("invalidUsernameOrPassword"), response];
  }

  if (!error) {
    if (logInAsOperator && userType !== 0 && userType !== 2) {
      return [i18n.t("Manager Portal access not allowed"), response];
    }
    if (logInAsDeveloper && userType !== 1 && userType !== 2) {
      return [i18n.t("Developer Portal access not allowed"), response];
    }
  }

  return [
    error,
    {
      ...response,
      token: extractTokenFromHeaders(headers),
    },
    headers,
  ];
};

/*
 * Spec: https://10.31.1.52/swagger/#/User/forgotPassword
 */
export const forgotPassword = ({ userName, ccla = false, urlContext = "" }) => {
  return post({
    url: `${API}/user/v1/forgot-password/${userName}`,
    queryParams: {
      ccla,
      urlContext,
    },
  });
};

export const validateChangePasswordLink = ({ hash }) => {
  return post({
    url: `${API}/user/v1/link-validation`,
    body: { hash },
  });
};

export const changePassword = ({ hash, password }) => {
  return put({
    url: `${API}/user/v1/change-password`,
    body: { hash, password },
  });
};

export const kibanaLogin = ({ username, password, currentURL }) => {
  return post({
    url: "kibana/internal/security/login",
    body: {
      providerType: "basic",
      providerName: "basic",
      currentURL,
      params: { username, password },
    },
    customHeaders: { "kbn-xsrf": "true" },
  });
};
