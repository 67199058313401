import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getUserDetails } from "msa2-ui/src/store/auth";
import {
  selectSubtenantById,
  getSelectedSubtenant,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";

import { sortOrder } from "msa2-ui/src/Constants";
import { filter } from "msa2-ui/src/utils/filter";
import {
  getManagedEntityReport,
  getAllManagedEntityReports,
} from "msa2-ui/src/api/managedEntity";
import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";

import { CircularProgress, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import AlertBar from "msa2-ui/src/components/AlertBar";
import ChartKey from "msa2-ui/src/components/ChartKey";
import SubtenantList from "./SubtenantList";
import DoughnutChart from "msa2-ui/src/components/graphs/Doughnut";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import { statusTypes } from "msa2-ui/src/components/topology/constants";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

export const sortByOptions = [
  {
    id: "entity_status_error",
    text: "Unreachable",
  },
  {
    id: "entity_status_critical",
    text: "Critical",
  },
  {
    id: "entity_status_neverreached",
    text: "Never Reached",
  },
  {
    id: "entity_status_ok",
    text: "Connected",
  },
];

const useStyles = makeStyles(
  ({ darkMode, palette, typography, spacing, breakpoints }) => ({
    filterWrapper: {
      paddingTop: 5,
      paddingBottom: 20,
    },
    chartsWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    deviceCount: {
      fontSize: "0.813rem",
      position: "relative",
      marginTop: -2,
      fontWeight: typography.fontWeightMedium,
      lineHeight: "normal",
      letterSpacing: 0.3,
      color: darkMode ? palette.primary.main : palette.text.secondary,
      marginLeft: 5,
    },
    divider: {
      borderColor: darkMode ? palette.text.secondary : "#e6eaee",
      width: "calc(100% + 32px)",
      marginLeft: -16,
    },
    chartWrapper: {
      padding: 30,
      borderTop: `1px solid ${darkMode ? palette.text.secondary : "#e7eaee"}`,
      borderRight: `1px solid ${darkMode ? palette.text.secondary : "#e7eaee"}`,
      marginLeft: "-1px",
      marginTop: "-1px",
      [breakpoints.down("sm")]: {
        width: "50%",
        "&:nth-child(2n)": {
          borderRight: 0,
        },
      },
      [breakpoints.up("md")]: {
        width: "33.333%",
        "&:nth-child(3n)": {
          borderRight: 0,
        },
      },
      [breakpoints.up("lg")]: {
        width: "25%",
        "&:nth-child(4n)": {
          borderRight: 0,
        },
      },
    },
    chartInstance: {
      width: 132,
      height: 132,
      margin: "auto",
    },
  }),
);

const statusValues = {
  OK: "ok",
  NEVERREACHED: "neverReached",
  CRITICAL: "critical",
  ERROR: "error",
};

const getTotalValue = (entry, filterStatus) => {
  const status = filterStatus ? statusValues[filterStatus] : "total";
  return entry[status] || 0;
};

const getValueForStatus = (value, valueStatus, filterStatus) => {
  if (filterStatus) {
    return filterStatus === valueStatus ? value : 0;
  } else {
    return value;
  }
};

const formatDataForChartsStructure = (data, filterStatus) => {
  return data.map((entry) => {
    return {
      subtenant: entry,
      key: entry.id,
      name: entry.name,
      total: getTotalValue(entry, filterStatus),
      numOfDevicesWithAlarms: entry.numOfDevicesWithAlarms,
      badge: {
        green: getValueForStatus(entry.ok, statusTypes.OK, filterStatus),
        red: getValueForStatus(entry.error, statusTypes.ERROR, filterStatus),
        yellow: getValueForStatus(
          entry.critical,
          statusTypes.CRITICAL,
          filterStatus,
        ),
        grey: getValueForStatus(
          entry.neverReached,
          statusTypes.NEVERREACHED,
          filterStatus,
        ),
        blue: null,
      },
      graph: entry.natureDeviceStatusReports.map((statusReport) => {
        let stringStatus = "";
        switch (statusReport.deviceNature) {
          case "PHSL":
            stringStatus = "Physical";
            break;
          case "VPUB":
            stringStatus = "Public";
            break;
          case "VPRV":
            stringStatus = "Private";
            break;
          default:
            break;
        }
        return [
          {
            name: "",
            value: 0,
          },
          {
            name: `Unreachable - ${stringStatus}`,
            value: getValueForStatus(
              statusReport.error,
              statusTypes.ERROR,
              filterStatus,
            ),
          },
          {
            name: `Critical - ${stringStatus}`,
            value: getValueForStatus(
              statusReport.critical,
              statusTypes.CRITICAL,
              filterStatus,
            ),
          },
          {
            name: `Never Reached - ${stringStatus}`,
            value: getValueForStatus(
              statusReport.neverReached,
              statusTypes.NEVERREACHED,
              filterStatus,
            ),
          },
          {
            name: "",
            value: 0,
          },
          {
            name: `Connected - ${stringStatus}`,
            value: getValueForStatus(
              statusReport.ok,
              statusTypes.OK,
              filterStatus,
            ),
          },
        ];
      }),
    };
  });
};

const formatDataForListStructure = (data, filterStatus) => {
  if (filterStatus) {
    const status = statusValues[filterStatus];
    return data.filter((entry) => entry[status] > 0);
  } else {
    return data;
  }
};

const ManagedEntityStatusGraphs = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  // const rowsPerPage = useSelector(getTableRowsSetting("dashboard"));
  const { id: subtenantId } = useSelector(getSelectedSubtenant);
  const { id: tenantId } = useSelector(getSelectedTenant);
  const { id: managerId } = useSelector(getUserDetails);

  const viewByOptions = [
    {
      id: "customer",
      text: t("Subtenant"),
    },
    {
      id: "tenant",
      text: t("Tenant"),
    },
  ];
  const [filterState, filterActions] = useFilter({
    tableName: "dashboard",
    viewAsValue: false,
    viewByOptions,
    viewByValue: viewByOptions[0].id,
    sortByOptions,
    sortByValue: sortByOptions[0].id,
    sortOrderValue: 0,
    tpPage: 0,
    searchValue: "",
  });
  const rowsPerPage = filterState.tpRowsPerPage;

  const [alertBar, setAlertBar] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);

  const getReportsApiCall = () => {
    if (
      (subtenantId && filterState.viewByValue !== viewByOptions[1].id) ||
      (filterState.viewByValue === viewByOptions[1].id &&
        tenantId &&
        !subtenantId) ||
      (subtenantId && tenantId)
    ) {
      return getManagedEntityReport;
    }
    return getAllManagedEntityReports;
  };

  const getReportsParams = () => {
    if (
      (subtenantId && filterState.viewByValue !== viewByOptions[1].id) ||
      (filterState.viewByValue === viewByOptions[1].id &&
        tenantId &&
        !subtenantId) ||
      (subtenantId && tenantId)
    ) {
      return {
        id: subtenantId ? subtenantId : tenantId,
        idType:
          filterState.viewByValue === viewByOptions[1].id
            ? getManagedEntityReport.reportBy.tenant
            : getManagedEntityReport.reportBy.subtenant,
        filterByLabel: isPermissionProfileLabelsEnabled,
      };
    }
    return {
      reportBy:
        filterState.viewByValue === viewByOptions[1].id
          ? getAllManagedEntityReports.reportBy.tenant
          : getAllManagedEntityReports.reportBy.subtenant,
      page: filterState.tpPage + 1,
      pageSize: rowsPerPage,
      sort:
        sortOrder[filterState.sortOrderValue].text + filterState.sortByValue,
      tenantId: tenantId,
      subtenantId: subtenantId,
      status: filterStatus,
      filterByLabel: isPermissionProfileLabelsEnabled,
    };
  };

  const [
    loading,
    error,
    subtenantTenantData = {},
    meta = {},
    reloadSubtenantTenantData,
  ] = useApi(getReportsApiCall(), {
    ...getReportsParams(),
    managerId,
  });

  const {
    me_total_count = subtenantTenantData.summaryReport?.total,
    // Set 1 as default as in some case, like selecting Subtenant and grouping by Subtenant where total is always 1, API does not return total_count
    total_count = 1,
  } = meta;

  useEffect(() => {
    setAlertBar(error);
  }, [error]);

  const setSelectedSubtenant = (id) => {
    dispatch(selectSubtenantById(id));
  };

  const handleDataOnClick = (id, path) => {
    dispatch(selectSubtenantById(id));
    history.push(path);
  };
  const filteredSubtenantTenantData = filter(
    subtenantTenantData.reports,
    filterState.searchValue,
    ["name"],
  );

  return (
    <>
      <Typography variant="h5" noWrap>
        {t("Managed Entities")}{" "}
        <span className={classes.deviceCount}>{me_total_count || "-"}</span>
      </Typography>
      <div className={classes.filterWrapper}>
        <FilterMenu
          id="DASHBOARD_ADMIN_FILTER_MENU"
          {...filterState}
          handleViewAsChange={filterActions.handleViewAsChange}
          handleViewByChange={filterActions.handleViewByChange}
          handleSortByChange={filterActions.handleSortByValueChange}
          handleSortOrder={filterActions.handleSortByOrderChange}
          handleSearchByChange={filterActions.handleSearchByChange}
          tpTotal={total_count || "-"}
          tpChangePage={filterActions.tpChangePage}
          tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
          disabledTenantTab={Boolean(subtenantId && tenantId)}
        />
      </div>
      <Divider variant="fullWidth" className={classes.divider} />
      {!filterState.viewAs && (
        <ChartKey
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          summaryReport={subtenantTenantData?.summaryReport}
        />
      )}
      <Divider variant="fullWidth" className={classes.divider} />

      {alertBar && (
        <AlertBar
          message={t("Unable to load x", { x: t("Managed Entities") })}
          refreshFnc={reloadSubtenantTenantData}
          closeFnc={setAlertBar(false)}
        />
      )}
      {loading ? (
        <div
          id="DASHBOARD_ADMIN_PROGRESS_LOADING"
          className={commonClasses.commonLoaderWrapper}
        >
          <CircularProgress />
        </div>
      ) : (
        filteredSubtenantTenantData && (
          <>
            {filteredSubtenantTenantData.length === 0 && (
              <div className={commonClasses.commonLoaderWrapper}>
                <Typography variant="subtitle1" color="inherit" noWrap>
                  {t("No Subtenants associated with this Tenant")}
                </Typography>
              </div>
            )}
            {!filterState.viewAsValue ? (
              <div className={classes.chartsWrapper}>
                <DoughnutChart
                  chartData={formatDataForChartsStructure(
                    filteredSubtenantTenantData,
                    filterStatus,
                  )}
                  chartDataText={t("Total")}
                  size={132}
                  maxCharts={rowsPerPage}
                  handleTitleOnClick={
                    filterState.viewByValue === viewByOptions[0].id
                      ? setSelectedSubtenant
                      : null
                  }
                  handleDataOnClick={
                    filterState.viewByValue === viewByOptions[0].id
                      ? handleDataOnClick
                      : null
                  }
                />
              </div>
            ) : (
              <SubtenantList
                showComponent={
                  filteredSubtenantTenantData &&
                  Boolean(filterState.viewAsValue)
                }
                listData={formatDataForListStructure(
                  filteredSubtenantTenantData,
                  filterStatus,
                )}
                handleTitleOnClick={
                  filterState.viewByValue === viewByOptions[0].id
                    ? setSelectedSubtenant
                    : null
                }
              />
            )}
          </>
        )
      )}
    </>
  );
};

export default ManagedEntityStatusGraphs;
