import React from "react";
import PropTypes from "prop-types";

import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";

const useCommonStyles = makeStyles(commonStyles);

const TableMessage = ({ message, numberOfTableColumns = 1 }) => {
  const commonClasses = useCommonStyles();

  return (
    <TableBody>
      <TableRow>
        <TableCell
          colSpan={numberOfTableColumns}
          className={commonClasses.commonLoaderWrapperTable}
        >
          <Grid container justifyContent="center" alignContent="center">
            <Typography align={"center"}>{message}</Typography>
          </Grid>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

TableMessage.propTypes = {
  message: PropTypes.string,
  numberOfTableColumns: PropTypes.number,
};

export default TableMessage;
