import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useApi from "msa2-ui/src/hooks/useApi";
import { getToken, getUserDetails } from "msa2-ui/src/store/auth";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import {
  createDeploymentOnCamundaEngine,
  startProcessInstanceOnCamundaEngine,
} from "msa2-ui/src/api/bpm";
import { readRepository } from "msa2-ui/src/api/repository";
import Bpm, { VARIABLE_TYPE } from "msa2-ui/src/services/Bpm";
import Repository from "msa2-ui/src/services/Repository";

import {
  Grid,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Modal from "msa2-ui/src/components/modal/Modal";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import BpmModeler from "msa2-ui/src/components/bpm/BpmModeler";
import ModalTitleBar from "./ModalTitleBar";
import ScheduleDialog from "msa2-ui/src/components/schedule/ScheduleDialog";
import useDialog from "msa2-ui/src/hooks/useDialog";

const useStyles = makeStyles(() => ({
  modalMessage: {
    justifyContent: "center",
    marginTop: 30,
  },
  instanceName: {
    width: "70%",
  },
}));

const Execute = ({ bpmUri, onClose, onExecute }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const token = useSelector(getToken);
  const { login } = useSelector(getUserDetails);
  const { ubiqubeId } = useSelector(getSelectedSubtenant);

  const [showSchedule, setShowSchedule] = useState(false);
  const [timeDate, setTimeDate] = useState();

  const [shouldTriggerExecuteBpm, setShouldTriggerExecuteBpm] = useState(false);
  const [executionProcessInstanceId, setExecutionProcessInstanceId] = useState(
    "",
  );
  const [isExecutedBpm, setIsExecutedBpm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [isBpmChanged, setIsBpmChanged] = useState(false);
  const [instanceName, setInstanceName] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showDiscardDialog, DiscardDialog] = useDialog();
  const [showConfirmDialog, ConfirmDialog] = useDialog();

  const bpmFilename = Repository.getFilenameFromUri(bpmUri);

  const [fetchDiagramXmlLoading, , fetchDiagramXmlResponse] = useApi(
    readRepository,
    { uri: bpmUri },
    !bpmUri,
  );
  const title = Repository.stripFolderPathAndFileExtensionFromUri(bpmUri);

  const scheduleBpm = async ({
    bpmModeler: { modelerActions },
    modelerInstance,
  }) => {
    enqueueSnackbar(t("Scheduling BPM..."));
    setIsExecutedBpm(true);
    setShouldTriggerExecuteBpm(false);
    const { replaceStartEventWithTimer } = modelerActions;
    replaceStartEventWithTimer(timeDate);

    // We cannot use xml and error objects passed as parameters
    // because it needs to take the latest XML changed above
    let diagramXml, xmlError;
    modelerInstance.saveXML({ format: true }, (error, xml) => {
      diagramXml = xml;
      xmlError = error;
    });
    const diagramXmlWithRealValues = Bpm.replacePlaceholdersInDiagramXml({
      xml: diagramXml,
      bpmFilename,
      ubiqubeId,
      authToken: token,
      processExecutor: login,
    });
    const [error] = await createDeploymentOnCamundaEngine({
      bpmFilename,
      xmlContent: diagramXmlWithRealValues,
      subtenantId: ubiqubeId,
      token,
    });
    enqueueSnackbar(
      error
        ? xmlError ?? error.getMessage()
        : t("BPM has been successfully scheduled!"),
      {
        variant: error ? "error" : "success",
        persist: error,
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      },
    );
    if (!error) {
      onClose();
    }
  };

  const executeBpm = async ({
    bpmModeler: { modelerActions },
    modelerInstance,
  }) => {
    enqueueSnackbar(t("Starting BPM..."));
    setIsExecutedBpm(true);
    setShouldTriggerExecuteBpm(false);

    const allAttachedWorkflowValues = modelerActions?.getAllAttachedWorkflowValues();
    // Collect context values for each WFs to pass it as camunda variable in "start" API
    // And replace WF Variable from actual values into variable reference
    const inputs = allAttachedWorkflowValues.reduce(
      (acc, { activityId, processVariables }) => {
        const type = Bpm.getVariableType(processVariables);
        if (type === VARIABLE_TYPE.PASSED.value) {
          // If the type is PASSED, create an object with ternary to take input parameters when users change it in run-time
          // eg. <camunda:value>${JSON(inputs).hasProp("Activity_1w07oak") ? JSON(inputs).prop("Activity_1w07oak").toString() : JSON(Activity_1w07oak).prop("variables").toString()}</camunda:value>
          const [{ name }] = Bpm.parseCamundaJsonParser(processVariables);
          modelerActions.replaceVariablesWithReference(activityId, name);
          return acc;
        }

        const hasCamundaJsonParser =
          type === VARIABLE_TYPE.SAVED.value
            ? Object.values(processVariables).some((value) =>
                typeof value === "string"
                  ? Bpm.isCamundaJsonParser(value)
                  : false,
              )
            : false;
        const hasMultiByteCharacters = [
          ...JSON.stringify(processVariables),
        ].some((c) => c.codePointAt(0) > 255);
        if (!hasCamundaJsonParser && !hasMultiByteCharacters) {
          // update WF element with variable reference
          // eg. <camunda:entry key="processVariables">${JSON(inputs).prop("Activity_0kf8tep").toString()}</camunda:entry>
          // But if there are some variables have a parser as its value, we don't replace it because parser only works in XML
          // And if there are multi byte characters, camunda cannot handle it so we store it in XML. (Remove !hasMultiByteCharacters when it's fixed in camunda side)
          modelerActions.replaceVariablesWithReference(activityId);
        }
        return { ...acc, [activityId]: processVariables };
      },
      {},
    );

    let diagramXml, xmlError;
    modelerInstance.saveXML({ format: true }, (error, xml) => {
      diagramXml = xml;
      xmlError = error;
    });

    let executeBpmError = xmlError;
    if (!executeBpmError) {
      const diagramXmlWithRealValues = Bpm.replacePlaceholdersInDiagramXml({
        xml: diagramXml,
        bpmFilename,
        ubiqubeId,
        authToken: token,
        processExecutor: login,
      });

      const [
        createDeploymentError,
        createDeploymentResponse,
      ] = await createDeploymentOnCamundaEngine({
        bpmFilename,
        xmlContent: diagramXmlWithRealValues,
        subtenantId: ubiqubeId,
        token,
      });
      executeBpmError = createDeploymentError;

      if (!executeBpmError) {
        const deployedProcessDefinitions =
          createDeploymentResponse?.deployedProcessDefinitions || {};
        const firstDeployedProcessDefinition = Object.values(
          deployedProcessDefinitions,
        )[0];
        const processDefinitionKey = firstDeployedProcessDefinition?.key;

        const [
          startProcessInstanceError,
          startProcessInstanceResponse,
        ] = await startProcessInstanceOnCamundaEngine({
          processDefinitionKey,
          businessKey: instanceName,
          value: inputs,
          subtenantId: ubiqubeId,
          token,
        });
        executeBpmError = startProcessInstanceError;

        if (!executeBpmError) {
          const executeBpmResponse = startProcessInstanceResponse;

          const { id: processInstanceId, definitionId } = executeBpmResponse;

          setExecutionProcessInstanceId(processInstanceId);

          onExecute(definitionId, processInstanceId);
        }
      }
    }

    enqueueSnackbar(
      executeBpmError
        ? xmlError ?? executeBpmError?.data?.message ?? executeBpmError?.message
        : t("BPM has been successfully started!"),
      {
        variant: executeBpmError ? "error" : "success",
        persist: executeBpmError,
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      },
    );
  };

  const handleCloseWithCheck = () => {
    if (isBpmChanged) {
      return showDiscardDialog();
    }
    onClose();
  };

  const handleBpmChange = ({ touched }) => setIsBpmChanged(touched);

  const renderModalContent = () => {
    if (fetchDiagramXmlLoading) {
      return (
        <Grid container className={classes.modalMessage}>
          <CircularProgress />
        </Grid>
      );
    } else if (!fetchDiagramXmlResponse) {
      return (
        <Grid container className={classes.modalMessage}>
          <Typography className={classes.errorMessage}>
            {t("Could not fetch BPM diagram")}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <BpmModeler
          diagramXml={fetchDiagramXmlResponse.content}
          callback={showSchedule ? scheduleBpm : executeBpm}
          shouldTriggerCallback={shouldTriggerExecuteBpm}
          executionProcessInstanceId={executionProcessInstanceId}
          setErrorMessage={setErrorMessage}
          onChange={handleBpmChange}
        />
      );
    }
  };

  const shouldDisableModalTitleBar = !fetchDiagramXmlResponse || isExecutedBpm;

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={onClose}
      />
      <ConfirmDialog
        title={t("Confirm Request")}
        content={t("Are you sure you want to execute BPM?", { name: title })}
        onExec={() => setShouldTriggerExecuteBpm(true)}
        execLabel={t("Run")}
      >
        <TextField
          id="EXECUTE_BPM_INSTANCE_NAME"
          variant={"outlined"}
          label={t("Instance Name (optional)")}
          value={instanceName}
          className={classes.instanceName}
          onChange={({ target: { value } }) =>
            setInstanceName(value.replace(/[^a-zA-Z0-9 ]/g, ""))
          }
          fullWidth
        />
      </ConfirmDialog>

      {showSchedule && (
        <ScheduleDialog
          onClose={() => {
            setShowSchedule(false);
          }}
          onSchedule={(entry, repetition) => {
            setTimeDate({ ...entry, repetition });
            setShouldTriggerExecuteBpm(true);
          }}
          disableDayMonthPicker
        />
      )}
      <Modal onClose={handleCloseWithCheck}>
        <ModalTitleBar
          title={title}
          scheduleButtonLabel={t("Schedule BPM")}
          executeButtonLabel={t("Execute BPM")}
          executeButtonDisabled={shouldDisableModalTitleBar || errorMessage}
          executeButtonTooltip={errorMessage}
          closeButtonLabel={t("Close")}
          disabled={shouldDisableModalTitleBar}
          onClose={onClose}
          onSchedule={() => setShowSchedule(true)}
          onExecute={() => showConfirmDialog(true)}
        />
        <ModalContent>{renderModalContent()}</ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

Execute.propTypes = {
  bpmUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
};

export default Execute;
