import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getFormSyncErrors } from "redux-form";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import flow from "lodash/flow";
import get from "lodash/get";
import omit from "lodash/omit";
import useDialog from "msa2-ui/src/hooks/useDialog";
import reduxForm from "redux-form/lib/reduxForm";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";

import { getFormValues } from "msa2-ui/src/store/form";
import useApi from "msa2-ui/src/hooks/useApi";

import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";

import {
  getMicroservice,
  testParser,
  updateMicroservice,
} from "msa2-ui/src/api/microservices";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import Modal from "msa2-ui/src/components/modal/Modal";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";
import { ReactComponent as FileComputer } from "msa2-ui/src/assets/icons/fileComputer.svg";

import ModalTitleBar from "./ModalTitleBar";
import InformationTabContentEdit from "./InformationTabContentEdit";
import VariablesTabContent from "msa2-ui/src/components/variables/VariablesTabContent";
import ModalTitle from "msa2-ui/src/components/modal//ModalTitle";
import ModalSidebar from "./ModalSidebar";

import { CommandTabsContent } from "./CommandTabsContent";
import { getToken } from "msa2-ui/src/store/auth";
import ManagedEntityConfigureTable from "msa2-ui/src/routes/integration/managed-entities/detail/ManagedEntityConfigureTable";

const Edit = ({
  destroy,
  dirty,
  match,
  handleSubmit,
  history,
  initialize,
  initialized,
  change,
  formErrors,
}) => {
  const isBulkOperationEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.msBulkOperation,
  );
  const isEditVariableEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.msEditVariableInConsole,
  );

  const { t } = useTranslation();
  const { state } = useLocation();
  const form = MicroserviceForm.EditFormName;

  const [showParserResultDialog, ParserResultDialog] = useDialog();
  const [parserData, setParserData] = useState({});

  const isEditingVariables = Boolean(
    useSelector(getFormValues(form, "active")),
  );
  const microserviceVariables = useSelector(
    getFormValues(form, MicroserviceForm.formConfig.variablePath),
  );
  const token = useSelector(getToken);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTestingParser, setIsTestingParser] = useState(false);
  const [formDataToSubmit, setFormDataToSubmit] = useState(null);
  const [activeSidebarTab, setActiveSidebarTab] = useState(
    MicroserviceForm.sidebarTabs.information,
  );
  const [showDiscardDialog, DiscardDialog] = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const microServiceUri = decodeURIComponent(
    get(match, "params.microServiceUri"),
  );
  const microserviceName = useSelector(
    getFormValues(form, MicroserviceForm.fields.name),
  );

  // Status: a string that is one of the following [success, warning, error, info]
  const handleStatusSnackbar = useCallback(
    (status, msg) => {
      enqueueSnackbar(msg, {
        variant: status,
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );

  const [isLoading, getMSError, microServiceToEdit, , reload] = useApi(
    getMicroservice,
    {
      microServiceUri,
    },
  );
  const [, updateError, updateResponse] = useApi(
    updateMicroservice,
    {
      body: formDataToSubmit,
    },
    !(isSubmitting && formDataToSubmit),
  );

  const returnToPreviousPage = useCallback(() => {
    if (isSubmitting) return;
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push("/integration/microservices");
    }
  }, [history, isSubmitting]);

  const submitForm = (values) => {
    setIsSubmitting(true);
    setFormDataToSubmit(omit(values, MicroserviceForm.localFields));
  };

  const onSidebarSectionClicked = (activeSidebarTab) => {
    setActiveSidebarTab(activeSidebarTab);
  };

  // Initialize the FormDate from the Microservice we want to edit
  useDeepCompareEffect(() => {
    if ((!initialized && microServiceToEdit) || isTestingParser) {
      initialize({
        ...microServiceToEdit,
        config: MicroserviceForm.formConfig,
      });
    }
  }, [initialize, initialized, microServiceToEdit]);

  // Check if there is an error when retrieving the microservice
  useEffect(() => {
    if (getMSError) {
      destroy();
      setIsSubmitting(false);
      handleStatusSnackbar(
        "error",
        t("Unable to retrieve x", { x: microServiceUri }),
      );
      returnToPreviousPage();
    }
  }, [
    destroy,
    getMSError,
    handleStatusSnackbar,
    microServiceUri,
    returnToPreviousPage,
    t,
  ]);

  /**
   * Wait for the Managed Entity to be updated and then redirect to the
   * detail page
   */
  useDeepCompareEffect(() => {
    if (updateError) {
      setIsSubmitting(false);
      if (!isSubmitting) {
        const reason = t("Microservice update FAILED");
        handleStatusSnackbar("error", updateError.getMessage(reason));
      }
      return;
    }
    if (updateResponse) {
      if (!isTestingParser) {
        destroy();
        returnToPreviousPage();
      }

      setIsSubmitting(false);

      if (!isSubmitting) {
        handleStatusSnackbar(
          "success",
          t("Microservice updated", { name: microServiceUri }),
        );
      }
    }
  }, [
    destroy,
    microServiceUri,
    returnToPreviousPage,
    isSubmitting,
    updateResponse,
    updateError,
  ]);

  const onEditVariableClicked = (index, variable) => {
    change("active", { index, variable });
    setActiveSidebarTab(MicroserviceForm.sidebarTabs.variables);
  };

  const navigateToError = (errors) => {
    const fieldsWithErrors = Object.keys(errors);
    setActiveSidebarTab(MicroserviceForm.mapFieldsToTabs[fieldsWithErrors[0]]);
  };

  const handleClose = () => {
    if (dirty) {
      return showDiscardDialog();
    }
    returnToPreviousPage();
  };

  const handleTestParser = async () => {
    setIsSubmitting(true);
    setIsTestingParser(true);
    await handleSubmit(submitForm)();
    await reload();

    if (!isLoading && !isSubmitting) {
      const [, response] = await testParser({
        token,
        deviceId: state?.deviceId,
        uri: microServiceUri,
      });

      if (response) {
        setParserData(response);
        showParserResultDialog(true);
      }
    }
  };

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={() => {
          returnToPreviousPage();
          destroy();
        }}
      />
      <ParserResultDialog
        title={t("Parser result")}
        maxWidth="lg"
        onClose={() => {
          setIsTestingParser(false);
          showParserResultDialog(false);
        }}
      >
        <ManagedEntityConfigureTable
          microserviceUri={microServiceUri}
          microserviceData={parserData}
          isBulkOperationEnabled={isBulkOperationEnabled}
          isEditVariableEnabled={isEditVariableEnabled}
        />
      </ParserResultDialog>
      <Modal onClose={handleClose}>
        <ModalTitleBar
          title={
            microServiceToEdit && (
              <ModalTitle
                icon={<FileComputer />}
                name={microserviceName}
                uri={microServiceUri}
                version={microServiceToEdit.version}
              />
            )
          }
          date={microServiceToEdit && microServiceToEdit.modifiedDate}
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Save Microservice")}
          discardButtonLabel={t("Discard Changes")}
          microServiceUri={microServiceUri}
          disabled={
            isLoading || isSubmitting || isEditingVariables || isTestingParser
          }
          onSave={() => {
            if (!isEmpty(formErrors)) {
              navigateToError(formErrors);
            } else {
              handleSubmit(submitForm)();
            }
          }}
          onDiscard={showDiscardDialog}
          onClose={handleClose}
        />
        <ModalContent>
          <ModalSidebar
            activeTab={activeSidebarTab}
            handleOnClick={onSidebarSectionClicked}
            form={form}
          />
          <ScrollingContainer>
            {activeSidebarTab === MicroserviceForm.sidebarTabs.information && (
              <InformationTabContentEdit microservice={microServiceToEdit} />
            )}
            {initialized && (
              <VariablesTabContent
                isActive={activeSidebarTab}
                form={form}
                formErrors={formErrors}
              />
            )}
            <CommandTabsContent
              isActive={activeSidebarTab}
              onEditVariableClicked={onEditVariableClicked}
              microserviceVariables={microserviceVariables}
              form={form}
              deviceId={state?.deviceId}
              isSubmitting={isLoading || isSubmitting || isTestingParser}
              submitForm={() => handleTestParser()}
            />
          </ScrollingContainer>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

Edit.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors(MicroserviceForm.EditFormName)(state),
});

export default flow(
  withRouter,
  reduxForm({
    form: MicroserviceForm.EditFormName,
    validate: MicroserviceForm.validate,
  }),
  connect(mapStateToProps),
)(Edit);
