import createComponent from "./createComponent";
import mapProps from "./mapProps";

import BasicSelect from "msa2-ui/src/components/BasicSelect";

export const Select = createComponent(
  BasicSelect,
  ({
    input: { onChange, value, onBlur, ...inputProps },
    onChange: onChangeFromField,
    defaultValue = "",
    ...props
  }) => ({
    ...mapProps(props),
    ...inputProps,
    value: value || typeof value === "number" ? value : defaultValue,
    onChange: (event) => {
      onChange(event.target.value);
      if (onChangeFromField) {
        onChangeFromField(event.target.value);
      }
    },
    onBlur: () => onBlur(value),
  }),
);

export default Select;
