import React from "react";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { buildRoute } from "msa2-ui/src/utils/urls";
import { getManagers } from "msa2-ui/src/api/manager";
import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";
import useActiveRoute from "msa2-ui/src/hooks/useActiveRoute";
import { MSA_VARS, sortOrder } from "msa2-ui/src/Constants";

import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import ManagerTableBody from "./ManagerTableBody";
import Create from "./Create";
import Edit from "./Edit";
import { getMsaVarValue } from "msa2-ui/src/store/designations";

const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    active: true,
  },
  {
    id: "displayName",
    name: "External Reference",
    align: "left",
    active: false,
  },
  {
    id: "tenantPrefix",
    name: "Tenant Prefix",
    align: "left",
    active: false,
  },
  {
    id: "lastUpdated",
    name: "Date Modified",
    align: "left",
    active: true,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    active: false,
  },
];

const Managers = ({ adminTabs: AdminTabs }) => {
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const ldapEnabled = Number(
    useSelector(getMsaVarValue(MSA_VARS.UBI_LDAP_ENABLE)),
  );

  const [filterState, filterActions] = useFilter({
    tableName: "admin",
    sortByValue: "lastUpdated",
    sortOrderValue: 0,
    searchValue: "",
    tpPage: 0,
  });

  const [loading, error, managers = [], meta, reloadManagers] = useApi(
    getManagers,
    {
      sortOrder: sortOrder[filterState.sortOrderValue].param,
      sort: filterState.sortByValue,
      filterCriteria: filterState.searchValue,
      filter: TABLE_HEADER_COLUMNS[0].id,
      page: filterState.tpPage + 1,
      pageSize: filterState.tpRowsPerPage,
    },
  );

  useActiveRoute(reloadManagers);

  const totalManagersCount = parseInt(meta?.total_managers_count ?? 0, 10);

  const endElements = ldapEnabled ? null : (
    <Button
      id="MANAGER_BTN_CREATE_LINK"
      variant="contained"
      color="primary"
      aria-label={`${t("Create")} ${t("Manager")}`}
      component={Link}
      to={{
        pathname: buildRoute(url, "create"),
      }}
    >
      <IconPlus className={commonClasses.commonBtnIcon} />
      {`${t("Create")} ${t("Manager")}`}
    </Button>
  );

  return (
    <>
      <AdminTabs currentCount={totalManagersCount} endElements={endElements} />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={TABLE_HEADER_COLUMNS.length}
                    className={commonClasses.commonTableCell}
                  >
                    <FilterMenu
                      {...filterState}
                      searchValue={filterState.searchValue}
                      handleSearchByChange={filterActions.handleSearchByChange}
                      tpChangePage={filterActions.tpChangePage}
                      tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
                      tpTotal={totalManagersCount}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                    <TableCell
                      key={tableHeaderColumn.id}
                      align={tableHeaderColumn.align}
                      className={commonClasses.commonTableCellDefault}
                    >
                      {tableHeaderColumn.active ? (
                        <TableSortLabel
                          id={`MANAGERS_TABLE_SORT_${tableHeaderColumn.id}`}
                          active={
                            filterState.sortByValue === tableHeaderColumn.id
                          }
                          direction={sortOrder[filterState.sortOrderValue].text}
                          onClick={() => {
                            filterActions.handleSortByOrderChange();
                            filterActions.handleSortByValueChange(
                              tableHeaderColumn.id,
                            );
                          }}
                        >
                          {t(tableHeaderColumn.name)}
                        </TableSortLabel>
                      ) : (
                        tableHeaderColumn.name
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <ManagerTableBody
                loading={loading}
                error={error}
                managers={managers}
                reloadManagers={reloadManagers}
              />

              <Route path={buildRoute(path, "create")}>
                <Create />
              </Route>
              <Route path={buildRoute(path, ":id/edit")}>
                <Edit managers={managers} />
              </Route>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Managers;
