import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import useApi from "msa2-ui/src/hooks/useApi";
import { pingApi } from "msa2-ui/src/api/ping";
import { status } from "msa2-ui/src/api/constants";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { asyncTimeout } from "msa2-ui/src/utils/async";

const isEnabled = FeatureFlag.isEnabled(FeatureFlag.features.connectionStatus);

/**
 * We want to timeout on our ping requests quickly so the user
 * is notified sooner there are connection issues. Waiting for the
 * actual timeout results in a long period with no feedback.
 */
const timeoutPing = () =>
  asyncTimeout(
    pingApi(),
    [undefined, undefined, { status: status.REQUEST_TIMEOUT }],
    2000,
  );

const ConnectionStatus = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const autoRefresh = useSelector(getAutoRefreshSetting("ping"));
  const [, , , meta = {}] = useApi(timeoutPing, {}, !isEnabled, autoRefresh);

  useEffect(() => {
    if (meta.status === 200 || meta.status === 304) {
      closeSnackbar();
      enqueueSnackbar(t("Connected to the MSActivator API."), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
        action: (key) => <SnackbarAction id={key} />,
      });
    } else if (meta.status >= 400 && meta.status < 500 && isEnabled) {
      closeSnackbar();
      enqueueSnackbar(t("Your network connection is unstable."), {
        variant: "warning",
        persist: true,
        preventDuplicate: true,
        action: (key) => <SnackbarAction id={key} />,
      });
    } else if (meta.status === 500 && isEnabled) {
      closeSnackbar();
      enqueueSnackbar(t("Please check, API is down."), {
        variant: "error",
        persist: true,
        preventDuplicate: true,
        action: (key) => <SnackbarAction id={key} />,
      });
    } else if (meta.status === 502 && isEnabled) {
      closeSnackbar();
      enqueueSnackbar(t("Please wait, API is starting."), {
        variant: "warning",
        persist: true,
        preventDuplicate: true,
        action: (key) => <SnackbarAction id={key} />,
      });
    }
  }, [closeSnackbar, enqueueSnackbar, meta.status, t]);

  return null;
};

export default ConnectionStatus;
