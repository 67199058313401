import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import flow from "lodash/flow";

import { getParentRoute, buildRoute } from "msa2-ui/src/utils/urls";

import useApi from "msa2-ui/src/hooks/useApi";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getToken } from "msa2-ui/src/store/auth";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import {
  createDeploymentOnCamundaEngine,
  deleteProcessDefinition,
} from "msa2-ui/src/api/bpm";
import { getBpmnXml, getJobDefinitions } from "msa2-ui/src/api/bpm";
import Repository from "msa2-ui/src/services/Repository";

import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { PERIODICITY } from "msa2-ui/src/components/schedule/constants";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Modal from "msa2-ui/src/components/modal/Modal";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import BpmModeler from "msa2-ui/src/components/bpm/BpmModeler";
import ModalTitleBar from "./ModalTitleBar";
import ScheduleDialog from "msa2-ui/src/components/schedule/ScheduleDialog";

const useStyles = makeStyles(() => ({
  modalMessage: {
    justifyContent: "center",
    marginTop: 30,
  },
}));

const DeploymentEdit = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const token = useSelector(getToken);
  const { ubiqubeId } = useSelector(getSelectedSubtenant);

  const { state } = useLocation();
  const {
    url,
    params: { bpmUri, definitionId },
  } = useRouteMatch();
  const history = useHistory();

  const [showSchedule, setShowSchedule] = useState(false);
  const [timeDate, setTimeDate] = useState();

  const [shouldTriggerExecuteBpm, setShouldTriggerExecuteBpm] = useState(false);
  const [isExecutedBpm, setIsExecutedBpm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showDiscardDialog, DiscardDialog] = useDialog();
  const [isBpmChanged, setIsBpmChanged] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onClose = () => {
    history.push({
      pathname: flow(getParentRoute, getParentRoute, (url) =>
        buildRoute(url, "scheduled"),
      )(url),
      state: { bpmFile: state?.bpmFile ?? {} },
    });
  };

  const handleCloseWithCheck = () => {
    if (isBpmChanged) {
      return showDiscardDialog();
    }
    onClose();
  };
  const handleBmpChange = ({ touched }) => setIsBpmChanged(touched);

  const bpmFilename = flow(
    decodeURIComponent,
    Repository.getFilenameFromUri,
  )(bpmUri);

  const [loading, , bpmnXmlResponse] = useApi(
    getBpmnXml,
    { id: definitionId },
    !definitionId,
  );

  const [startEventsLoading, , startEvents] = useApi(getJobDefinitions, {
    jobType: "timer-start-event",
    processDefinitionId: definitionId,
  });

  const scheduleBpm = async ({
    bpmModeler: { modelerActions },
    modelerInstance,
  }) => {
    enqueueSnackbar(t("Scheduling BPM..."));
    setIsExecutedBpm(true);
    setShouldTriggerExecuteBpm(false);
    const { replaceStartEventWithTimer } = modelerActions;
    replaceStartEventWithTimer(timeDate);

    let diagramXml, xmlError;
    modelerInstance.saveXML({ format: true }, (error, xml) => {
      diagramXml = xml;
      xmlError = error;
    });

    const [error] = await createDeploymentOnCamundaEngine({
      bpmFilename,
      xmlContent: diagramXml,
      subtenantId: ubiqubeId,
      token,
    });
    enqueueSnackbar(
      error
        ? xmlError ?? error.getMessage()
        : t("BPM has been successfully scheduled!"),
      {
        variant: error ? "error" : "success",
        persist: error,
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      },
    );
    if (!error) {
      await deleteProcessDefinition({ id: definitionId, token });
      onClose();
    }
  };

  const renderModalContent = () => {
    if (loading) {
      return (
        <Grid container className={classes.modalMessage}>
          <CircularProgress />
        </Grid>
      );
    } else if (!bpmnXmlResponse) {
      return (
        <Grid container className={classes.modalMessage}>
          <Typography className={classes.errorMessage}>
            {t("Could not fetch BPM diagram")}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <BpmModeler
          diagramXml={bpmnXmlResponse.bpmn20Xml}
          callback={scheduleBpm}
          shouldTriggerCallback={shouldTriggerExecuteBpm}
          setErrorMessage={setErrorMessage}
          onChange={handleBmpChange}
        />
      );
    }
  };

  const shouldDisableModalTitleBar =
    !bpmnXmlResponse || isExecutedBpm || startEventsLoading;

  const {
    periodUnit,
    periodicityValue,
    timerType,
    recurringTime,
    startDate,
    endDate,
  } = startEvents?.[0] ?? {};
  const predefinedEntry = {
    periodicity: timerType === "DATE" ? PERIODICITY.once : periodUnit,
    periodicityValue,
    timeCycle: recurringTime,
    startDate: timerType === "DATE" ? endDate : startDate,
    endDate,
  };

  return (
    <ErrorBoundary>
      {showSchedule && (
        <ScheduleDialog
          predefinedEntry={predefinedEntry}
          onClose={() => {
            setShowSchedule(false);
          }}
          onSchedule={(entry, repetition) => {
            setTimeDate({ ...entry, repetition });
            setShouldTriggerExecuteBpm(true);
          }}
          disableDayMonthPicker
        />
      )}
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={onClose}
      />
      <Modal onClose={handleCloseWithCheck}>
        <ModalTitleBar
          title={bpmFilename}
          scheduleButtonLabel={t("Schedule BPM")}
          executeButtonDisabled={shouldDisableModalTitleBar || errorMessage}
          executeButtonTooltip={errorMessage}
          closeButtonLabel={t("Close")}
          onClose={onClose}
          onSchedule={() => setShowSchedule(true)}
        />
        <ModalContent>{renderModalContent()}</ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

export default DeploymentEdit;
