import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isObject } from "lodash";
import { useSnackbar } from "notistack";

import useDialog from "msa2-ui/src/hooks/useDialog";
import {
  changeSettings,
  SETTINGS,
  getSettings,
} from "msa2-ui/src/store/settings";
import {
  getGlobalSettings,
  setGlobalSettings,
  getSavedSettings,
  setSavedSettings,
} from "msa2-ui/src/store/designations";
import {
  getToken,
  getUserDetails,
  getUserRole,
  userRoles,
  getIsRootUser,
} from "msa2-ui/src/store/auth";

import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Select from "msa2-ui/src/components/BasicSelect";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import MsaSelect from "msa2-ui/src/components/msa-select";

import { updateMsaVars } from "msa2-ui/src/api/msaVars";
import { MSA_VARS } from "msa2-ui/src/Constants";
import { updateManagerWithUISettings } from "msa2-ui/src/api/manager";

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 20,
  },
  title: {
    margin: 5,
  },
  field: {
    margin: 10,
    width: 250,
  },
  actionButton: {
    margin: 5,
  },
  multiSelect: {
    marginTop: 15,
  },
}));

const SettingField = ({
  options,
  optionsLabels,
  path,
  id,
  value,
  label,
  type,
  onChange,
  unitLabel,
  minValue: min,
  maxValue: max,
  isMulti = false,
  valueField = "value",
  labelField = "label",
  unremovableOptions = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleOnChange = (value) => {
    onChange && onChange(value);
    dispatch(
      changeSettings({
        key: path ? [path, id].join(".") : id,
        value,
      }),
    );
  };
  if (options) {
    if (isMulti) {
      const selectOptions = options
        .filter((column) => !unremovableOptions.includes(column[valueField]))
        .map((column) => ({
          ...column,
          value: valueField ? column[valueField] : undefined,
          label: labelField ? column[labelField] : undefined,
        }));
      const selectValues = selectOptions.filter((column) =>
        value.includes(column[valueField]),
      );
      return (
        <div className={classes.multiSelect}>
          <MsaSelect
            id={`LOCAL_SETTINGS_${id}`}
            label={label}
            options={selectOptions}
            value={selectValues}
            onChange={(option) => {
              handleOnChange(
                option
                  .map((column) => column[valueField])
                  .concat(unremovableOptions),
              );
            }}
            isMulti
          />
        </div>
      );
    }
    return (
      <Select
        id={`LOCAL_SETTINGS_${id}`}
        variant={"outlined"}
        label={label ?? t(id)}
        className={classes.field}
        value={value}
        onChange={({ target: { value } }) => {
          handleOnChange(value);
        }}
      >
        {optionsLabels
          ? options.map((option, i) => (
              <MenuItem key={i} value={option}>
                {optionsLabels[i] ?? option}
              </MenuItem>
            ))
          : options.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))}
      </Select>
    );
  }
  return (
    <TextField
      id={`LOCAL_SETTINGS_${id}`}
      variant={"outlined"}
      label={label ?? t(id)}
      className={classes.field}
      value={value}
      type={type ?? "text"}
      margin="none"
      onChange={({ target: { value } }) => {
        handleOnChange(value);
      }}
      InputProps={{
        inputProps: { min, max },
        endAdornment: unitLabel && (
          <InputAdornment position="end">{unitLabel}</InputAdornment>
        ),
      }}
    />
  );
};

const LocalSettings = ({ tabs: Tabs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const dispatch = useDispatch();

  const token = useSelector(getToken);
  const {
    netceloId: { ubiID },
    id,
    userType,
  } = useSelector(getUserDetails);
  const globalDashboardSettings = useSelector(getGlobalSettings);
  const savedSettings = useSelector(getSavedSettings);
  const settings = useSelector(getSettings);
  const userRole = useSelector(getUserRole);
  const isRootUser = useSelector(getIsRootUser);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showSaveDialog, SaveDialog] = useDialog();
  const [showGlobalSaveDialog, GlobalSaveDialog] = useDialog();

  const handleSave = async (ubiID) => {
    const isGlobalSave = !ubiID;
    // take dashboard settings from the redux store not to overwrite dashboard
    const content = {
      ...settings,
      dashboards: isGlobalSave
        ? globalDashboardSettings.dashboards
        : savedSettings.dashboards,
    };
    let error = {};
    if (isGlobalSave) {
      const contentAsString = JSON.stringify(content);
      const [globalSettingsError] = await updateMsaVars({
        isRootUser,
        token,
        vars: [
          { name: MSA_VARS.UI_SETTINGS, value: contentAsString, comment: "" },
        ],
      });
      error = globalSettingsError;
    } else {
      const [settingsByUserError] = await updateManagerWithUISettings({
        token,
        id: id,
        userType,
        UISettings: content,
      });
      error = settingsByUserError;
    }

    const message = error
      ? t("Unable to save settings")
      : t("Settings saved successfully");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    if (!error) {
      if (isGlobalSave) {
        dispatch(setGlobalSettings(content));
      } else {
        dispatch(setSavedSettings(content));
      }
    }
  };
  return (
    <ErrorBoundary>
      <GlobalSaveDialog
        title={t("Confirm Request")}
        content={`${t("Are you sure you want to set as global settings?")}`}
        onExec={() => {
          handleSave();
        }}
      />
      <SaveDialog
        title={t("Confirm Request")}
        content={`${t("This will persist this settings for the current user.")}
        ${t("Are you sure you want to save?")}`}
        onExec={() => {
          handleSave(ubiID);
        }}
      />
      <Tabs
        endElements={
          <>
            {userRole === userRoles.PRIVILEGED_ADMINISTRATOR && (
              <Button
                id="DASHBOARD_SETTINGS_GLOBAL_SAVE"
                aria-label={t("Set as Global Settings")}
                color="primary"
                variant="contained"
                className={classes.actionButton}
                onClick={() => {
                  showGlobalSaveDialog();
                }}
              >
                {t("Set as Global Settings")}
              </Button>
            )}
            <Button
              id="DASHBOARD_SETTINGS_Save"
              aria-label={t("Save")}
              color="primary"
              variant="contained"
              className={classes.actionButton}
              onClick={() => {
                showSaveDialog();
              }}
            >
              {t("Save")}
            </Button>
          </>
        }
      />
      <Grid container>
        <Grid className={classes.wrapper} item xs={12}>
          <Paper className={commonClasses.commonPaper}>
            {Object.entries(settings)
              .filter(([key]) => key !== "dashboard" && isObject(SETTINGS[key]))
              .map(([key, value], index) => {
                const { label } = SETTINGS[key];
                return (
                  <Fragment key={key}>
                    {index > 0 && <Divider />}
                    <Typography variant="h5" className={classes.title}>
                      {label}:
                    </Typography>
                    {isObject(value) ? (
                      Object.entries(value)
                        .filter(
                          ([subKey]) =>
                            SETTINGS[key][subKey] &&
                            SETTINGS[key][subKey].label &&
                            !SETTINGS[key][subKey].disabled,
                        )
                        .map(([subKey, subValue]) => {
                          return (
                            <SettingField
                              {...SETTINGS[key]}
                              {...SETTINGS[key][subKey]}
                              key={subKey}
                              path={key}
                              id={subKey}
                              value={subValue}
                            />
                          );
                        })
                    ) : (
                      <SettingField {...SETTINGS[key]} id={key} value={value} />
                    )}
                  </Fragment>
                );
              })}
            <Grid container direction="row"></Grid>
          </Paper>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default LocalSettings;
