import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { change } from "redux-form";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import get from "lodash/get";
import flow from "lodash/flow";
import remove from "lodash/remove";
import cloneDeep from "lodash/cloneDeep";
import Variable from "msa2-ui/src/services/Variable";

import { getFormValues } from "msa2-ui/src/store/form";

import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
  DragIndicator as DragIcon,
} from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";

const component = "microserviceEditVariablesOverview";

const useStyles = makeStyles(({ palette, typography, colors }) => ({
  paper: {
    width: "inherit",
  },
  divider: {
    width: "inherit",
  },
  variableWrapper: {
    height: 74,
    width: "inherit",
    padding: 12,
  },
  variableWrapperInactive: {
    opacity: 0.4,
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
  },
  variableName: {
    fontSize: "1rem",
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.5,
    padding: 10,
  },
  variableDisplayName: {
    color: palette.text.secondary,
    padding: 10,
  },
  variableEditButton: {
    marginRight: 4,
  },
  createButton: {
    margin: 24,
  },
  createButtonPlusIcon: {
    marginRight: 5,
  },
  grab: {
    cursor: "grab",
  },
  grabbing: {
    cursor: "grabbing",
  },
  selectAllRow: {
    padding: 12,
  },
}));

const RenderVariable = ({
  variable,
  index,
  depth,
  changeOrder,
  setActive,
  setChecked,
  activeVariable,
  checked,
  deleteVariable,
  form,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [isHovered, setIsHovered] = useState(false);
  const lockHover = Boolean(activeVariable);

  const ref = useRef(null);

  useEffect(() => {
    if (!lockHover) {
      setIsHovered(false);
    }
  }, [lockHover]);

  useEffect(() => {
    if (activeVariable && activeVariable.index === index) {
      setIsHovered(true);
    }
  }, [activeVariable, lockHover, index]);

  const isKeyItem =
    Variable.isMandatoryItem(variable.name, form) || variable.isCompositeKey;

  const [{ isDragging }, drag] = useDrag({
    item: { type: component, id: variable.name, index },
    begin: () => {
      !lockHover && setIsHovered(false);
    },
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  });

  const [, drop] = useDrop({
    accept: component,
    hover(item) {
      const setIndex = (newIndex) => {
        item.index = newIndex;
      };
      if (!ref || lockHover) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      changeOrder(dragIndex, hoverIndex);
      setIndex(hoverIndex);
    },
    drop() {
      !lockHover && setIsHovered(true);
    },
  });

  drag(drop(ref));

  return (
    <div
      className={classnames(
        commonClasses.commonFlexStart,
        classes.variableWrapper,
        {
          [commonClasses.commonHoverArea]: isHovered,
          [classes.variableWrapperInactive]:
            activeVariable && activeVariable.index !== index,
        },
      )}
      onMouseLeave={() => !lockHover && setIsHovered(false)}
      onMouseEnter={() => !lockHover && setIsHovered(true)}
    >
      <div style={{ paddingLeft: depth * 36 }} />
      <Grid item>
        <Checkbox
          id={`MICROSERVICE_VARIABLE_${index}`}
          disabled={lockHover}
          checked={checked}
          onChange={setChecked}
        />
      </Grid>
      <Grid
        item
        xs={1}
        className={classnames(
          !lockHover && (isDragging ? classes.grabbing : classes.grab),
          commonClasses.commonFlexCenter,
        )}
        ref={ref}
      >
        <DragIcon color="secondary" />
      </Grid>
      <Grid item xs={9} lg={4}>
        <Typography
          className={classnames(
            classes.variableName,
            commonClasses.overflowEllipsis,
          )}
          title={variable.name}
        >
          {`$${variable.name}`}
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={3}>
          <Typography
            className={classnames(
              classes.variableDisplayName,
              commonClasses.overflowEllipsis,
            )}
            title={variable.displayName}
          >
            {variable.displayName}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={3}>
        {isHovered && (
          <Grid container direction="row" alignItems="center">
            <IconButton
              className={classes.variableEditButton}
              disabled={lockHover}
              onClick={setActive}
            >
              <EditIcon color="primary" />
            </IconButton>
            <Tooltip
              title={
                isKeyItem && !lockHover
                  ? t("You cannot delete default key variables")
                  : ""
              }
            >
              <span>
                <IconButton
                  disabled={isKeyItem || lockHover}
                  onClick={() => deleteVariable(index, variable.displayName)}
                >
                  <DeleteIcon
                    color={isKeyItem || lockHover ? "disabled" : "error"}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const Folder = ({ depth, folderName, folderType }) => {
  const classes = useStyles();
  const [hover] = useState(false);
  const commonClasses = useCommonStyles();
  const ref = useRef(null);

  return (
    <div
      className={classnames(
        commonClasses.commonFlexStart,
        classes.variableWrapper,
        hover && commonClasses.commonHoverArea,
      )}
    >
      <div style={{ paddingLeft: depth * 36 }} />
      <Grid
        item
        xs={1}
        className={classnames(commonClasses.commonFlexCenter)}
        ref={ref}
      >
        <DragIcon color="secondary" />
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.variableName}>
          {folderType}: {folderName}
        </Typography>
      </Grid>
    </div>
  );
};

const VariablesList = ({
  change,
  form,
  createVariable,
  deleteVariable,
  deleteSelectedVariables,
  cancelEdit,
  submitting,
}) => {
  const { t } = useTranslation();
  const { variablePath } = useSelector(getFormValues(form, "config"));

  const activeVariable = useSelector(getFormValues(form, "active"));
  const checkedVariables = useSelector(getFormValues(form, "checked")) || [];
  const variables = useSelector(getFormValues(form, variablePath)) || [];

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [selectAll, setSelectAll] = useState(false);

  const handleSetChecked = (i, variable) => {
    change(
      form,
      "checked",
      checkedVariables.find((v) => v.index === i)
        ? [...remove(cloneDeep(checkedVariables), (v) => v.index !== i)]
        : [...checkedVariables, { ...variable, index: i }],
    );
  };

  const handleChangeVariables = (source, target) => {
    const value = variables.reduce((acc, cur, i) => {
      if (i === source) {
        return acc;
      }
      if (i === target) {
        if (source < target) {
          return [...acc, cur, variables[source]];
        }
        if (source > target) {
          return [...acc, variables[source], cur];
        }
      }
      return [...acc, cur];
    }, []);

    const variablesWithTargetValue = cloneDeep(checkedVariables).push({
      index: target,
      variable: value[target],
    });
    const varsWithoutSourceValue = remove(
      variablesWithTargetValue,
      (v) => v.index !== source,
    );

    change(form, "checked", varsWithoutSourceValue);
    change(form, [variablePath], value);
  };

  const handleSelectAllClick = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      change(
        form,
        "checked",
        variables.map((variable, i) => ({ ...variable, index: i })),
      );
    } else {
      change(form, "checked", []);
    }
  };

  useEffect(() => {
    if (selectAll && checkedVariables.length < variables.length) {
      setSelectAll(false);
    }

    if (!selectAll && checkedVariables.length === variables.length) {
      setSelectAll(true);
    }
  }, [selectAll, checkedVariables, variables]);

  const hasKeyItems = () => {
    return checkedVariables.find(
      ({ name, isCompositeKey }) =>
        Variable.isMandatoryItem(name, form) || isCompositeKey,
    );
  };

  const hasSelectedItems = () => {
    return checkedVariables.length > 0;
  };

  const deleteSelectedDisabled = hasKeyItems() || !hasSelectedItems();

  return (
    <Grid xs={12} item onClick={activeVariable ? cancelEdit : undefined}>
      {submitting ? (
        <div
          className={classnames(
            classes.itemWrapper,
            commonClasses.commonFlexCenter,
          )}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container>
          <Grid
            container
            item
            justifyContent="space-between"
            className={classes.selectAllRow}
          >
            <Tooltip title={t("Select All")}>
              <Checkbox
                id={`MICROSERVICE_VARIABLES_SELECT_ALL`}
                checked={selectAll}
                indeterminate={
                  checkedVariables.length < variables.length &&
                  checkedVariables.length > 0
                }
                onChange={handleSelectAllClick}
              />
            </Tooltip>
            <Tooltip
              title={
                hasKeyItems()
                  ? t("You cannot delete key variables")
                  : hasSelectedItems()
                  ? "Delete selected variables"
                  : "Select variables to delete them"
              }
            >
              <span>
                <IconButton
                  disabled={Boolean(deleteSelectedDisabled)}
                  onClick={() => deleteSelectedVariables(checkedVariables)}
                >
                  <DeleteIcon
                    color={deleteSelectedDisabled ? "disabled" : "error"}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Divider className={classes.divider} />
          {variables.map((variable, i) => {
            const getGroup = (value) =>
              get(variables, [i + value, "grouped"])
                ? [get(variables, [i + value, "groupDisplayName"])]
                : [];
            const getSection = (value) =>
              get(variables, [i + value, "sections"]) ?? [];
            const group = variable.grouped ? [variable.groupDisplayName] : [];
            const sections = variable.sections ?? [];
            const shouldRenderGroup = group.length;
            const folder = shouldRenderGroup ? group : sections;
            const depth = folder.length;
            const prevFolder = shouldRenderGroup
              ? getGroup(-1)
              : getSection(-1);
            const nextFolder = shouldRenderGroup ? getGroup(1) : getSection(1);
            return (
              <Fragment key={i}>
                {folder.map(
                  (folderName, i) =>
                    get(prevFolder, [i]) !== folderName && (
                      <Fragment key={i}>
                        <Folder
                          depth={i}
                          folderName={folderName}
                          folderType={group.length ? t("Group") : t("Section")}
                        />
                      </Fragment>
                    ),
                )}
                <RenderVariable
                  form={form}
                  variable={variable}
                  index={i}
                  depth={depth}
                  changeOrder={handleChangeVariables}
                  setActive={() =>
                    change(form, "active", { index: i, variable })
                  }
                  checked={Boolean(checkedVariables.find((v) => v.index === i))}
                  setChecked={() => handleSetChecked(i, variable)}
                  activeVariable={activeVariable}
                  deleteVariable={deleteVariable}
                />
                {(folder.length === 0 || folder[0] !== nextFolder[0]) && (
                  <Divider className={classes.divider} />
                )}
              </Fragment>
            );
          })}
          <Button
            id="MICROSERVICE_VARIABLES_BTN_CREATE"
            variant="contained"
            size="medium"
            color="primary"
            disabled={Boolean(activeVariable)}
            aria-label={t("Create")}
            className={classes.createButton}
            onClick={createVariable}
          >
            <IconPlus className={classes.createButtonPlusIcon} />{" "}
            {t("Create Variable")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

VariablesList.propTypes = {
  form: PropTypes.string.isRequired,
  createVariable: PropTypes.func.isRequired,
};

export default flow(connect(null, { change }))(VariablesList);
