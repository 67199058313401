import React from "react";
import PropTypes from "prop-types";

import { TextField } from "@material-ui/core";

import useTogglePassword from "msa2-ui/src/hooks/useTogglePassword";

const PasswordInput = ({ InputProps, ...props }) => {
  const [showPassword, PasswordInputAdornment] = useTogglePassword();
  return (
    <TextField
      id="INPUT_PASSWORD"
      autoComplete="new-password"
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: <PasswordInputAdornment />,
      }}
      type={showPassword ? "text" : "password"}
    />
  );
};

PasswordInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};

export default PasswordInput;
