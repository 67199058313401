import React from "react";
import PropTypes from "prop-types";
import MsaSelectBase from "./MsaSelectBase";
import { AsyncPaginate } from "react-select-async-paginate";

const MsaSelectLazy = ({ scrollToLoadRatio = 1, ...rest }) => {
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const loadingThreshold = scrollHeight * scrollToLoadRatio - clientHeight;
    return scrollTop >= loadingThreshold;
  };

  return (
    <MsaSelectBase
      shouldLoadMore={shouldLoadMore}
      {...rest}
      select={AsyncPaginate}
    />
  );
};

MsaSelectLazy.propTypes = {
  ...MsaSelectBase.propTypes,
  options: PropTypes.array,
};

export default MsaSelectLazy;
