import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Log from "msa2-ui/src/services/Log";
import { getMsaTheme } from "msa2-ui/src/store/designations";

import { Grid, TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import BasicSelect from "msa2-ui/src/components/BasicSelect";

import UpsertAlarmFormConditionsTab from "./UpsertAlarmFormConditionsTab";

const useStyles = makeStyles(({ spacing }) => ({
  field: {
    marginTop: spacing(2),
    width: 350,
  },
}));

const UpsertAlarmFormDetailsTab = ({
  formState,
  dispatch,
  alarmName,
  onBlur,
}) => {
  const { name, description } = formState;

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useSelector(getMsaTheme);

  const {
    rule: { alarmSeverity },
  } = formState;

  const severities = Log.SEVERITY_LEVELS(theme);
  const severityLevels = Object.keys(severities);
  const renderSeveritiesPickerLabel = (selectedSeverity) =>
    Log.SEVERITY_LEVELS(theme)[selectedSeverity].label;

  const onChangeSelectedSeverities = (event) => {
    dispatch({
      type: "updateRuleAlarmSeverity",
      payload: event.target.value,
    });
  };

  return (
    <Grid container direction="column" alignItems="center">
      <TextField
        id="UPSERT_ALARM_FORM_NAME_FIELD"
        size="medium"
        label={t("Name")}
        value={name}
        onChange={({ target: { value } }) =>
          dispatch({ type: "updateName", payload: value })
        }
        onBlur={onBlur}
        variant="outlined"
        inputProps={{
          "data-testid": "upsert-alarm-form-name-field",
        }}
        className={classes.field}
      />
      <TextField
        id="UPSERT_ALARM_FORM_DESCRIPTION_FIELD"
        size="medium"
        label={t("Description")}
        value={description}
        onChange={({ target: { value } }) =>
          dispatch({ type: "updateDescription", payload: value })
        }
        variant="outlined"
        inputProps={{
          "data-testid": "upsert-alarm-form-description-field",
        }}
        className={classes.field}
      />
      <BasicSelect
        id="UPSERT_ALARM_FORM_QUERY_ALARM_SEVERITIES_FIELD"
        label={t("Alarm Severity")}
        variant="outlined"
        className={classes.field}
        name="upsert-alarm-form-query-alarm-severities-field"
        value={alarmSeverity}
        renderValue={renderSeveritiesPickerLabel}
        onChange={onChangeSelectedSeverities}
      >
        {severityLevels.map((severityLevel, index) => (
          <MenuItem
            value={parseInt(severityLevel)}
            key={`severity_level_${index}`}
          >
            {Log.SEVERITY_LEVELS(theme)[severityLevel].label}
          </MenuItem>
        ))}
      </BasicSelect>
    </Grid>
  );
};

UpsertAlarmFormConditionsTab.propTypes = {
  formState: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default UpsertAlarmFormDetailsTab;
