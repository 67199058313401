import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import omit from "lodash/omit";

import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import { editImportExportEntry } from "msa2-ui/src/api/settings";

import { getToken } from "msa2-ui/src/store/auth";

import SettingsForm from "msa2-ui/src/services/SettingsForm";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import Dialog from "msa2-ui/src/components/Dialog";
import PasswordInput from "msa2-ui/src/components/PasswordInput";
import { validUrl } from "msa2-ui/src/utils/validators";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const extractEntryFields = (entry) => {
  const { repoName, repoType, repo, username, password, storeToken } = entry;
  return {
    repoName,
    repoType,
    url: repo,
    username,
    password,
    storeToken,
  };
};

const ImportExportEditDialog = ({ handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const { state } = useLocation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);

  const [entry, setEntry] = useState(extractEntryFields(state?.entry));
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    const { url, username, password } = entry;

    setErrorMessage("");

    if (!url || !username || !password) {
      return setErrorMessage(t("Please fill out all the form fields"));
    }

    const validUrlError = validUrl(url);
    if (validUrlError) {
      return setErrorMessage(validUrlError);
    }

    setSubmitting(true);
    const [error, response] = await editImportExportEntry({
      token,
      body: omit(entry, [
        entry.repoType,
        entry.repoName,
        entry.url,
        entry.username,
        entry.password,
        entry.storeToken,
      ]),
    });
    setSubmitting(false);

    const variant = response.errorCode === 500 ? "error" : "success";
    const message = error ? error.getMessage() : t("Repository updated");

    if (!error) {
      onClose();
    }

    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  const onClose = () => {
    handleClose();
    history.push("/settings");
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={onClose}
      disabled={submitting}
      execLabel={t("Save")}
      title={`${t("Edit")} ${t("Import/Export")} ${t("entry")}`}
    >
      <Grid container direction="column">
        <BasicSelect
          label={t("Type")}
          variant="outlined"
          name={t("Type")}
          className={classes.formField}
          value={entry.repoType}
          required
          disabled
        >
          {SettingsForm.repositoryTypes.map((repoType, index) => (
            <MenuItem value={repoType.value} key={index}>
              {repoType.label}
            </MenuItem>
          ))}
        </BasicSelect>
        <TextField
          type={"text"}
          variant="outlined"
          label={t("URL")}
          value={entry.url}
          required
          onChange={({ target: { value } }) =>
            setEntry({ ...entry, url: value })
          }
          className={classes.formField}
        />
        <TextField
          type={"text"}
          variant="outlined"
          label={t("Directory")}
          value={entry.repoName}
          required
          disabled
          onChange={() => {}}
          className={classes.formField}
        />
        <TextField
          type={"text"}
          variant="outlined"
          label={t("Username")}
          value={entry.username}
          error={!entry.username}
          required
          onChange={({ target: { value } }) =>
            setEntry({ ...entry, username: value })
          }
          className={classes.formField}
        />
        <PasswordInput
          variant="outlined"
          label={t("Token")}
          error={!entry.password}
          value={entry.password}
          required
          onChange={({ target: { value } }) =>
            setEntry({ ...entry, password: value })
          }
          className={classes.formField}
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ "aria-label": t("Store Token") }}
                checked={entry.storeToken}
                className={classes.checkbox}
                disabled
              />
            }
            label={t("Store Token")}
          />
        </FormControl>
        {errorMessage && (
          <Typography color="error" className={classes.error}>
            {errorMessage}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default ImportExportEditDialog;
