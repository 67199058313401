import React, { useRef, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import flow from "lodash/flow";
import orderBy from "lodash/orderBy";

import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { filter } from "msa2-ui/src/utils/filter";
import { buildRoute, getParentRoute } from "msa2-ui/src/utils/urls";

import useFilter from "msa2-ui/src/hooks/useFilter";
import useApi from "msa2-ui/src/hooks/useApi";

import {
  getProcessDefinitions,
  getJobDefinitions,
  deleteProcessDefinition,
} from "msa2-ui/src/api/bpm";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";

import {
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { sortOrder } from "msa2-ui/src/Constants";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import MSATableBody from "msa2-ui/src/components/MSATableBody";
import MSATableRow from "msa2-ui/src/components/MSATableRow";
import SectionTabs from "msa2-ui/src/components/SectionTabs";

const TABLE_HEADER_COLUMNS = [
  {
    id: "id",
    name: "ID",
    align: "left",
    sortKey: true,
  },
  {
    id: "periodUnitLabel",
    name: "Schedule",
    align: "left",
    width: "11%",
    sortKey: true,
  },
  {
    id: "nextExecDate",
    name: "Next execution date",
    align: "left",
    sortKey: true,
    width: "18%",
    format: displayMonthDayYearTimeDate,
  },
  {
    id: "endDate",
    name: "End date",
    align: "left",
    sortKey: true,
    width: "18%",
    format: displayMonthDayYearTimeDate,
  },
  {
    id: "action",
    name: "",
    align: "left",
    width: "12%",
    sortKey: false,
  },
];

const BpmScheduled = ({ bpmFilename, tabs }) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { url: pathname, isExact } = useRouteMatch(
    "/automation/bpm/:bpmUri/scheduled",
  );

  const commonClasses = useCommonStyles();
  const { ubiqubeId } = useSelector(getSelectedSubtenant);

  const [filterState, filterActions] = useFilter({
    searchValue: "",
    sortByValue: "nextExecDate",
    sortOrderValue: 1,
  });

  const [
    processDefinitionsLoading,
    processDefinitionsError,
    processDefinitions,
    ,
    reloadProcessDefinitions,
  ] = useApi(getProcessDefinitions, { subtenantId: ubiqubeId, bpmFilename });

  const [
    timerJobsLoading,
    timerJobsError,
    timerJobs,
    ,
    reloadTimerJobs,
  ] = useApi(getJobDefinitions, {
    jobType: "timer-start-event",
    subtenantId: ubiqubeId,
    bpmFilename,
  });

  const loading = timerJobsLoading || processDefinitionsLoading;
  const error = processDefinitionsError || timerJobsError;
  const reload = useCallback(() => {
    reloadProcessDefinitions();
    reloadTimerJobs();
  }, [reloadProcessDefinitions, reloadTimerJobs]);

  const scheduledProcessDefinitions =
    processDefinitions &&
    timerJobs &&
    flow(
      // Combine processDefinitions with timerJobs
      (processDefinitions) =>
        processDefinitions.reduce((acc, cur) => {
          const job = timerJobs.find(
            ({ processDefinitionId }) => processDefinitionId === cur.id,
          );
          return job ? [...acc, { ...job, ...cur }] : acc;
        }, []),
      // Filter out processDefinitions from past
      (processDefinitions) =>
        processDefinitions.filter(({ nextExecDate }) => Boolean(nextExecDate)),
      // Filter with search term
      (processDefinitions) =>
        filter(processDefinitions, filterState.searchValue, ["id"]),
      // sort
      (processDefinitions) =>
        orderBy(
          processDefinitions,
          filterState.sortByValue,
          sortOrder[filterState.sortOrderValue].text,
        ),
    )(processDefinitions);

  const previousUrl = useRef(url);
  useEffect(() => {
    if (isExact && previousUrl.current !== url) {
      reload();
      previousUrl.current = url;
    }
  }, [isExact, reload, url]);

  const colSpan = TABLE_HEADER_COLUMNS.length;
  const tabsWithCounts = tabs.map((tab) =>
    url.endsWith(tab.destination)
      ? { ...tab, count: scheduledProcessDefinitions?.length }
      : tab,
  );
  return (
    <>
      <SectionTabs tabs={tabsWithCounts} />
      <Paper
        id="BPM_SCHEDULED_TAB"
        className={commonClasses.commonPaperNoPadding}
      >
        <Table>
          <colgroup>
            {TABLE_HEADER_COLUMNS.map(({ id, width }) => (
              <col key={id} style={{ width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={colSpan}
                className={commonClasses.commonTableCell}
              >
                <FilterMenu
                  id="BPM_SCHEDULED_TABLE_FILTER_MENU"
                  {...filterState}
                  {...filterActions}
                  handleViewAsChange={undefined}
                />
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                <TableCell
                  key={tableHeaderColumn.id}
                  align={tableHeaderColumn.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {tableHeaderColumn.sortKey ? (
                    <TableSortLabel
                      id={`BPM_SCHEDULED_TABLE_SORT_${tableHeaderColumn.id}`}
                      active={tableHeaderColumn.id === filterState.sortByValue}
                      direction={sortOrder[filterState.sortOrderValue].text}
                      onClick={() => {
                        filterActions.handleSortByOrderChange(
                          filterState.sortByValue !== tableHeaderColumn.id
                            ? Number(filterState.sortOrderValue)
                            : Number(!filterState.sortOrderValue),
                        );
                        filterActions.handleSortByValueChange(
                          tableHeaderColumn.id,
                        );
                      }}
                    >
                      {tableHeaderColumn.name}
                    </TableSortLabel>
                  ) : (
                    tableHeaderColumn.name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <MSATableBody
            loading={!scheduledProcessDefinitions && loading}
            error={error && t("Unable to load x", { x: t("BPM") })}
            noContents={
              !scheduledProcessDefinitions?.length && t("No BPM found")
            }
            colSpan={colSpan}
          >
            {scheduledProcessDefinitions?.map((processDefinition, i) => {
              const { id } = processDefinition;
              const onDelete = (token) =>
                deleteProcessDefinition({ token, id });
              return (
                <MSATableRow
                  key={i}
                  columns={TABLE_HEADER_COLUMNS}
                  data={processDefinition}
                  deleteAPI={onDelete}
                  callbackPostDelete={reload}
                  editLinkTo={buildRoute(
                    getParentRoute(pathname),
                    [encodeURIComponent(id), "edit"].join("/"),
                  )}
                  delegationEdit={{
                    type: delegationProfileTypes.BPM,
                    action: "schedule.edit",
                  }}
                  delegationDelete={{
                    type: delegationProfileTypes.BPM,
                    action: "schedule.delete",
                  }}
                />
              );
            })}
          </MSATableBody>
        </Table>
      </Paper>
    </>
  );
};

export default BpmScheduled;
