import { configureStore } from "@reduxjs/toolkit";
import throttle from "lodash/throttle";

import { saveStateToStorage, loadStateFromStorage } from "./storage";
import initialState from "./initialState";
import FrameMessenger from "msa2-ui/src/services/FrameMessenger";

import form from "./form";
import ai from "msa2-ui/src/store/ai";
import ui from "msa2-ui/src/store/ui";
import auth, { logout } from "./auth";
import designations from "./designations";
import settings from "./settings";
import delegationProfiles from "./delegationProfiles";
import innerBus from "msa2-ui/src/utils/InnerBus";

const reducer = {
  ai,
  auth,
  delegationProfiles,
  designations,
  settings,
  form,
  ui,
};

export default async () => {
  const preloadedState = (await loadStateFromStorage()) ?? initialState;

  const store = configureStore({
    reducer,
    preloadedState,
  });

  FrameMessenger.listen((event) => {
    if (event.type === "logout") {
      store.dispatch(logout());
    }
  });

  innerBus.on(innerBus.evt.LOGOUT, () => {
    // We need to logout from other tabs.
    // NB! FrameMessenger doesn't send message to the tab-initiator so we dispatch logout one more time.
    FrameMessenger.send({ type: "logout" });
    store.dispatch(logout());
  });

  store.subscribe(
    throttle(() => {
      const theState = store.getState();
      saveStateToStorage(theState);
    }, 1000),
  );

  return store;
};
