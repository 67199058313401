import i18n from "i18next";
import get from "lodash/get";
import Process from "msa2-ui/src/services/Process";
import Repository from "msa2-ui/src/services/Repository";
import { is } from "bpmn-js/lib/util/ModelUtil";

import flow from "lodash/flow";
import maxBy from "lodash/maxBy";
import isEmpty from "lodash/isEmpty";

import { parseChainFunctions } from "msa2-ui/src/utils/parseChainFunctions";
import { partition } from "lodash";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_PATH = process.env.REACT_APP_API_PATH;
const TYPES = {
  "bpmn:IntermediateCatchEvent": {
    "bpmn:SignalEventDefinition": {
      id: "bpmn:SignalEventDefinition",
      idPrefix: "Signal",
      refKey: "signalRef",
      referenceType: "bpmn:Signal",
    },
    "bpmn:MessageEventDefinition": {
      id: "bpmn:MessageEventDefinition",
      idPrefix: "Message",
      refKey: "messageRef",
      referenceType: "bpmn:Message",
    },
  },
};

export const PLACEHOLDER_UBIQUBE_ID_STRING = "PLACEHOLDER_UBIQUBE_ID";
export const PLACEHOLDER_PROCESS_ID_STRING = "PLACEHOLDER_PROCESS_ID";
export const PLACEHOLDER_EXECUTOR_ID_STRING = "PLACEHOLDER_EXECUTOR_ID";

const PLACEHOLDER_API_URL_STRING = "http://PLACEHOLDER_API_URL";
const PLACEHOLDER_AUTHORIZATION_TOKEN_STRING =
  "PLACEHOLDER_AUTHORIZATION_TOKEN";
export const EXTERNAL_REFERENCE_PREFIX = "BPM_";
export const VARIABLE_NAME_FOR_INPUT_CONTEXT = "inputs";
export const PROCESS_DEFINITION_KEY_PREFIX = "BPM_PROCESS_ID_";

export const OPERATORS = {
  IS: { value: "==", label: i18n.t("is") },
  IS_NOT: { value: "!=", label: i18n.t("is not") },
};

export const DECISION_TYPES = {
  STATUS: {
    value: "status",
    label: i18n.t("Result Status"),
  },
  COMMENT: {
    value: "comment",
    label: i18n.t("Result Message"),
    disabled: true,
  },
  VARIABLE: {
    value: "variables",
    label: i18n.t("Variable"),
  },
};

export const VARIABLE_TYPE = {
  SAVED: {
    value: "saved",
    displayName: i18n.t("Saved"),
    title: i18n.t("Always use values set here."),
  },
  LATEST: {
    value: "latest",
    displayName: i18n.t("Latest"),
    title: i18n.t("Use values stored in Workflow instance."),
    disabled: (processType) => Process.isCreate(processType),
  },
  PASSED: {
    value: "passed",
    displayName: i18n.t("Pass"),
    title: i18n.t("Pass results from other Workflow."),
  },
};

const convertObjectToCamundaMap = (moddle, object) => {
  const getCamundaObjectByType = (value) => {
    if (Array.isArray(value)) {
      return moddle.create("camunda:List", {
        items: value.map((value) => getCamundaObjectByType(value)),
      });
    }
    if (value && typeof value === "object") {
      return moddle.create("camunda:Map", {
        entries: Object.entries(value).map(([key, value]) => {
          return value
            ? moddle.create("camunda:Entry", {
                key,
                definition: getCamundaObjectByType(value),
              })
            : moddle.create("camunda:Entry", { key });
        }),
      });
    }
    return moddle.create("camunda:Value", { value: value.toString() });
  };
  return getCamundaObjectByType(object);
};

const convertCamundaMapToObject = (element) => {
  const getCamundaObjectByType = (value) => {
    if (is(value, "camunda:Map")) {
      return (
        value.entries?.reduce(
          // Each Element should be camunda:Entry
          (acc, element) => ({
            ...acc,
            [element.key]: element.definition
              ? getCamundaObjectByType(element.definition)
              : getCamundaObjectByType(element.value),
          }),
          {},
        ) ?? {}
      );
    }
    if (value && is(value, "camunda:List")) {
      return (
        value.items?.map((element) => getCamundaObjectByType(element)) ?? []
      );
    }
    if (value && is(value, "camunda:Value")) {
      return value.value;
    }
    return value;
  };
  return getCamundaObjectByType(element);
};

const readAttachedWorkflowValuesFromBpmElement = (bpmElement) => {
  let parsedWorkflowValues = {};
  const outputParameters =
    get(
      bpmElement,
      "businessObject.extensionElements.values[0].outputParameters",
    ) ?? [];
  const _inputParameters =
    get(
      bpmElement,
      "businessObject.extensionElements.values[0].inputParameters",
    ) ?? [];
  const [[rawWorkflowValues], inputParameters = []] = partition(
    _inputParameters,
    ({ name }) => name === "workflow_data",
  );
  // From the historical reason, we can have two types of format for Workflow section in BPM file
  // If Workflow object in BPM fils has "value", it is old format with raw JSON
  if (rawWorkflowValues?.value) {
    parsedWorkflowValues = JSON.parse(rawWorkflowValues.value);
  }
  // If Workflow object in BPM fils has "definition", it is new format with raw Camunda MAP/LIST
  if (rawWorkflowValues?.definition) {
    parsedWorkflowValues = convertCamundaMapToObject(
      rawWorkflowValues.definition,
    );
  }

  const requiredProps = [
    "serviceName",
    "processName",
    "processType",
    "serviceInstanceId",
    "processVariables",
    "resumeOnFail",
    "ubiqubeId",
  ];

  const extraProps = {};
  Object.keys(parsedWorkflowValues).forEach((key) => {
    if (!requiredProps.includes(key)) {
      extraProps[key] = parsedWorkflowValues[key];
    }
  });

  return {
    displayName: bpmElement?.businessObject.name ?? "",
    workflowPath: parsedWorkflowValues.serviceName ?? "",
    processName: parsedWorkflowValues.processName ?? "",
    processType: parsedWorkflowValues.processType ?? "",
    workflowInstanceId: parsedWorkflowValues.serviceInstanceId ?? "",
    processVariables: parsedWorkflowValues.processVariables ?? {},
    resumeOnFail: parsedWorkflowValues.resumeOnFail ?? false,
    activityId: bpmElement?.businessObject.id ?? "",
    extraProps: isEmpty(extraProps) ? undefined : extraProps,
    outputParameters,
    inputParameters,
  };
};

const writeAttachedWorkflowValuesToBpmElement = ({
  displayName = "",
  workflowPath = "",
  processName = "",
  processType = "",
  workflowInstanceId = "",
  processVariables = {},
  resumeOnFail = false,
  moddle,
  extraProps = {},
  inputParameters = [],
  outputParameters = [],
}) => {
  if (!workflowPath) {
    return {
      type: "bpmn:Task",
      businessObject: moddle.create("bpmn:Task", {
        name: displayName,
      }),
    };
  }

  const serviceTaskId = `ServiceTask_${Date.now()}`;

  // Quick fix for ubiqubeId
  if ("ubiqubeId" in extraProps) {
    delete extraProps["ubiqubeId"];
  }

  const workflowDataValue = {
    ubiqubeId: PLACEHOLDER_UBIQUBE_ID_STRING,
    serviceName: workflowPath,
    ...(!Process.isCreate(processType) && {
      serviceInstanceId: workflowInstanceId,
    }),
    processName,
    processType,
    processVariables,
    resumeOnFail: resumeOnFail.toString(),
    ...extraProps,
    processExecutor: PLACEHOLDER_EXECUTOR_ID_STRING,
  };

  return {
    type: "bpmn:ServiceTask",
    businessObject: moddle.create("bpmn:ServiceTask", {
      name: displayName,
      id: serviceTaskId,
      "camunda:type": "external",
      "camunda:topic": "msa_workflow",
      extensionElements: moddle.create("bpmn:ExtensionElements", {
        values: [
          moddle.create("camunda:InputOutput", {
            inputParameters: [
              moddle.create("camunda:InputParameter", {
                name: "workflow_data",
                definition: convertObjectToCamundaMap(
                  moddle,
                  workflowDataValue,
                ),
              }),
              ...inputParameters,
            ],
            outputParameters,
          }),
        ],
      }),
    }),
  };
};

const readConditionsFromSequenceFlow = (bpmElement) => {
  const id = bpmElement?.businessObject.id ?? "";
  const name = bpmElement?.businessObject.name ?? "";
  const condition = bpmElement?.businessObject.conditionExpression?.body;

  if (!isCamundaJsonParser(condition)) {
    return {
      id,
      name,
      camundaTaskId: "",
      field: "",
      operator: "",
      value: "",
    };
  }

  const [parser, operator, value] = stripBracketsFromJsonParser(
    condition,
  ).split(" ");
  const {
    JSON: [camundaTaskId],
    prop: [field, variable],
  } = parseChainFunctions(parser);

  return {
    id,
    name,
    camundaTaskId,
    field,
    variable,
    operator,
    value: value?.replace(/'/g, ""),
  };
};

const writeConditionsToSequenceFlow = ({
  id,
  camundaTaskId,
  field,
  variable,
  operator,
  value,
  label,
  moddle,
}) => {
  const body = buildCamundaJsonParser({
    variableName: camundaTaskId,
    props: variable ? [field, variable] : [field],
    operator,
    value,
  });
  return {
    type: "bpmn:SequenceFlow",
    businessObject: moddle.create("bpmn:SequenceFlow", {
      id,
      name: label,
      conditionExpression: moddle.create("bpmn:FormalExpression", {
        "xsi:type": "bpmn:tFormalExpression",
        body,
      }),
    }),
  };
};

const readInfoFromMessageEvent = (bpmElement) => {
  const id = bpmElement?.businessObject.id ?? "";
  const name = bpmElement?.businessObject.name ?? "";
  const messageEventId = bpmElement?.businessObject.eventDefinitions[0].id;
  const messageRef = bpmElement?.businessObject.eventDefinitions[0].messageRef;
  const workflowActivityId = bpmElement?.businessObject.extensionElements?.values[0].inputParameters.find(
    ({ name }) => name === "target_workflow",
  ).value;

  return {
    id,
    name,
    messageEventId,
    messageRef,
    workflowActivityId,
  };
};

const writeInfoToMessageEvent = ({
  id,
  name,
  messageEventId,
  messageRef,
  workflowActivityId,
  moddle,
}) => {
  return {
    type: "bpmn:IntermediateCatchEvent",
    businessObject: moddle.create("bpmn:IntermediateCatchEvent", {
      name,
      id,
      eventDefinitions: [
        moddle.create("bpmn:MessageEventDefinition", {
          id: messageEventId,
          messageRef,
        }),
      ],
      extensionElements: moddle.create("bpmn:ExtensionElements", {
        values: [
          moddle.create("camunda:InputOutput", {
            inputParameters: [
              moddle.create("camunda:InputParameter", {
                name: "target_workflow",
                value: workflowActivityId,
              }),
            ],
          }),
        ],
      }),
    }),
  };
};

/**
 * Replaces placeholders in BPM diagram XML with real values needed for execution
 * @param {string} xml
 * @param {string} bpmFilename - filename for the BPM in the format myDiagram.xml
 * @param {string} ubiqubeId - ubiqube ID that owns the BPM diagram
 * @param {string} authToken
 * @param {string} processExecutor - will contain the login detail; This will be the name of the Owner
 */
const replacePlaceholdersInDiagramXml = ({
  xml = "",
  bpmFilename,
  ubiqubeId,
  authToken,
  processExecutor,
} = {}) => {
  if (!bpmFilename || !ubiqubeId || !authToken) {
    return xml;
  }

  const placeholdersToReplace = [
    {
      string: PLACEHOLDER_PROCESS_ID_STRING,
      replaceValue: buildProcessDefinitionKey(ubiqubeId, bpmFilename),
    },
    {
      string: PLACEHOLDER_API_URL_STRING,
      replaceValue: `${API_BASE_URL}${API_PATH}`,
    },
    {
      string: PLACEHOLDER_UBIQUBE_ID_STRING,
      replaceValue: ubiqubeId,
    },
    {
      string: PLACEHOLDER_AUTHORIZATION_TOKEN_STRING,
      replaceValue: authToken,
    },
    {
      string: PLACEHOLDER_EXECUTOR_ID_STRING,
      replaceValue: processExecutor,
    },
  ];

  let cleanedXml = xml;
  placeholdersToReplace.forEach(({ string, replaceValue }) => {
    const regex = new RegExp(string, "g");
    cleanedXml = cleanedXml.replace(regex, replaceValue);
  });

  return cleanedXml;
};

const buildProcessDefinitionKey = (ubiqubeId, bpmFilename) =>
  ubiqubeId &&
  bpmFilename &&
  `${PROCESS_DEFINITION_KEY_PREFIX}${ubiqubeId}_${bpmFilename}`;

const parseProcessDefinitionKey = (processDefinitionKey) => {
  const sections = processDefinitionKey
    .replace(PROCESS_DEFINITION_KEY_PREFIX, "")
    .split("_");
  const ubiqubeId = sections[0];
  const bpmFilename = sections.slice(1).join("_");
  const bpmName = Repository.stripFileExtensionFromString(bpmFilename);
  return { ubiqubeId, bpmFilename, bpmName };
};

const getRecords = (totalRecords) => {
  const completedRecords = totalRecords
    .filter((record) => {
      const { instances, incidents } = record;
      return instances === 0 && incidents.length === 0;
    })
    .map((record) => record.id);

  const activeRecords = totalRecords
    .filter((record) => {
      const { instances, incidents } = record;
      return instances > 0 && incidents.length === 0;
    })
    .map((record) => record.id);

  const failedRecords = totalRecords
    .filter((record) => {
      const { incidents } = record;
      return incidents.length > 0;
    })
    .map((record) => record.id);

  return [activeRecords, failedRecords, completedRecords];
};

const filterOutDeletedInstances = (statistics, processInstances) =>
  statistics.filter(({ id }) =>
    processInstances
      .map(({ processDefinitionId }) => processDefinitionId)
      .includes(id),
  );

const transformBusinessFailure = (
  [activeRecords, _failedRecords, _completedRecords],
  externalTaskLogs,
) => {
  const failedWorkflows = _completedRecords.filter(
    (definitionId) =>
      flow(
        (externalTaskLogs) =>
          externalTaskLogs.filter(
            ({ processDefinitionId }) => processDefinitionId === definitionId,
          ),
        (externalTaskLogs) =>
          maxBy(externalTaskLogs, ({ timestamp }) => timestamp),
      )(externalTaskLogs)?.deletionLog === true,
  );
  const failedRecords = _failedRecords.concat(failedWorkflows);
  const completedRecords = _completedRecords.filter(
    (record) => !failedWorkflows.includes(record),
  );
  return [activeRecords, failedRecords, completedRecords];
};

const isCamundaJsonParser = (value) => value?.startsWith("${JSON") ?? false;

const buildCamundaJsonParser = ({
  variableName,
  props,
  operator,
  value,
  noWrapper,
  parser = "value",
  parserProp = "",
}) =>
  (noWrapper ? "" : "${") +
  `JSON(${variableName})` +
  (props?.map((prop) => '.prop("' + prop + '")').join("") ?? "") +
  ("." + parser + "(" + parserProp + ")") +
  (operator ? ` ${operator}` : "") +
  (operator ? ` '${value || ""}'` : "") +
  (noWrapper ? "" : "}");

const stripBracketsFromJsonParser = (str) => str.replace(/^\$\{|\}$/g, "");

const parseCamundaJsonParser = (str) => {
  if (typeof str !== "string") return {};
  return (
    stripBracketsFromJsonParser(str)
      // split ternary operator for each section
      .split(/ [?:] /)
      .map((entry) => {
        const { JSON, prop, ...rest } = flow(
          stripBracketsFromJsonParser,
          parseChainFunctions,
        )(entry);
        return { name: JSON[0], props: prop, ...rest };
      })
  );
};

// Returns if processVariables in bpmn XML is SAVED, PASSED or LATEST
const getVariableType = (processVariables) => {
  if (isEmpty(processVariables)) {
    return VARIABLE_TYPE.LATEST.value;
  }
  if (
    typeof processVariables === "string" &&
    isCamundaJsonParser(processVariables)
  ) {
    return VARIABLE_TYPE.PASSED.value;
  }
  return VARIABLE_TYPE.SAVED.value;
};

// Function to get business object from bpmElement
// we are using 'definition' as parent key for all bpmElement in CCLA
const getInputParameterFromElement = (bpmElement) => {
  const inputParameters =
    get(
      bpmElement,
      "businessObject.extensionElements.values[0].inputParameters",
    ) ?? [];
  // Todo: temporary function! as we only have workflow_data or one other inputParameter now
  const inputParameter = inputParameters.find(
    ({ name }) => name !== "workflow_data",
  );
  if (!inputParameter?.definition) return {};

  return convertCamundaMapToObject(inputParameter.definition);
};

export default {
  TYPES,
  readAttachedWorkflowValuesFromBpmElement,
  writeAttachedWorkflowValuesToBpmElement,
  readConditionsFromSequenceFlow,
  writeConditionsToSequenceFlow,
  readInfoFromMessageEvent,
  writeInfoToMessageEvent,
  replacePlaceholdersInDiagramXml,
  buildProcessDefinitionKey,
  parseProcessDefinitionKey,
  getRecords,
  filterOutDeletedInstances,
  transformBusinessFailure,
  isCamundaJsonParser,
  buildCamundaJsonParser,
  parseCamundaJsonParser,
  getVariableType,
  convertObjectToCamundaMap,
  convertCamundaMapToObject,
  getInputParameterFromElement,
};
