import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

import { CircularProgress, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  formSection: {
    padding: 16,
    width: "100%",
  },
  activeSection: {
    outline: palette.primary.main + " solid 2px",
  },
  formTitle: {
    marginBottom: 20,
  },
  formFieldContainer: {
    padding: "15px 20px 20px",
  },
}));

const Loading = () => (
  <Grid container justifyContent="center" alignContent="center">
    <Grid item xs={2}>
      <CircularProgress />
    </Grid>
  </Grid>
);

const FormSection = ({
  active,
  title,
  actions,
  children,
  loading,
  extraClassNames = [],
}) => {
  const classes = useStyles();

  return (
    <section
      className={classnames(
        classes.formSection,
        {
          [classes.activeSection]: active,
        },
        ...extraClassNames,
      )}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        {title && (
          <Grid item>
            <Typography variant="h5" className={classes.formTitle}>
              {title}
            </Typography>
          </Grid>
        )}
        {!loading && actions && <Grid item>{actions}</Grid>}
      </Grid>
      <section className={classes.formFieldContainer}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={4}>
            {children}
          </Grid>
        )}
      </section>
    </section>
  );
};

export default FormSection;
