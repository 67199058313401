import React, { memo } from "react";
import { isValidUrl } from "msa2-ui/src/utils/urls";
import PropTypes from "prop-types";
import { get, flow } from "lodash";

import { useSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  TextField as MUITextField,
  Typography,
} from "@material-ui/core";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { useSelector } from "react-redux";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Variable from "msa2-ui/src/services/Variable";
import ManagedEntity from "msa2-ui/src/services/ManagedEntity";
import Repository from "msa2-ui/src/services/Repository";
import Validation from "msa2-ui/src/services/Validation";

import MUISelect from "msa2-ui/src/components/BasicSelect";
import PasswordInput from "msa2-ui/src/components/PasswordInput";
import MicroserviceReference from "msa2-ui/src/components/variables/MicroserviceReference";
import FileReference from "msa2-ui/src/components/variables/FileReference";
import WorkflowReference from "msa2-ui/src/components/variables/WorkflowReference";
import ManagedEntityReference from "msa2-ui/src/components/variables/ManagedEntityReference";
import ComboBox from "msa2-ui/src/components/ComboBox";
import RepositoryPicker from "msa2-ui/src/components/RepositoryPicker";
import Tooltip from "msa2-ui/src/components/TooltipWithLineBreaks";

import { downloadArchive } from "msa2-ui/src/api/repository";
import EditorAce from "msa2-ui/src/components/EditorAce";
import MsaSelect from "msa2-ui/src/components/msa-select";

export const useStyles = makeStyles(({ palette }) => ({
  field: {
    width: "100%",
  },
  blackMenu: {
    height: 30,
  },
  checkbox: {
    padding: 0,
  },
  itemValue: {
    fontSize: "0.8125rem",
    letterSpacing: 0.3,
    color: palette.text.primary,
    width: "inherit",
    textOverflow: "ellipsis",
  },
}));

const ReadOnlyToolTip = ({ type, value, children }) => {
  const classes = useStyles();
  return ["Password", "Code"].includes(type) || !value ? (
    // don't expose Password on Tooltip..
    children
  ) : (
    <Tooltip title={value} enterDelay={500}>
      <span className={classes.field}>{children}</span>
    </Tooltip>
  );
};

// storeInfo to have run time information like token
// to get it from different store, msa2-ui and cloudclapp
const ExecutionVariableField = ({ storeInfo = {}, ...props }) => {
  const { id, value: _value, variable, data, isEditing } = props;

  // if the variable is composite, it should recurse based on behaviour object
  // Unlike the other types, Composite should recurse here as we need to take "visible" into account
  if (variable.type === "Composite") {
    const targetVariable = Variable.getBehaviourObjectBasedOnSelectorValue(
      variable,
      data,
      id,
    );
    // return component recursively
    if (targetVariable) {
      return (
        <ExecutionVariableField
          {...props}
          storeInfo={storeInfo}
          variable={targetVariable}
        />
      );
    }
  }

  if (!variable.visible) return null;

  const idSuffix = (Array.isArray(id) ? id.join("_") : id).toUpperCase();
  const nullify = [undefined, null];

  const value = (() => {
    if (!nullify.includes(_value)) {
      return _value;
    }
    const val = get(data, id);
    if (!nullify.includes(val)) {
      return val;
    }
    return "";
  })();

  // Show Tooltip only when the field is disabled
  return variable.userLocked || !isEditing ? (
    <ReadOnlyToolTip type={variable.type} value={value}>
      <ExecutionVariableFieldReadOnly
        {...props}
        {...storeInfo}
        idSuffix={idSuffix}
        value={value}
      />
    </ReadOnlyToolTip>
  ) : (
    <ExecutionVariableFieldEditable
      {...props}
      {...storeInfo}
      idSuffix={idSuffix}
      value={value}
    />
  );
};

// Convert "False", "false", "True" and "true" into Boolean
const convertStringIntoBoolean = (value) => {
  if (typeof value === "boolean") {
    return value;
  }
  return value?.toLowerCase() === "false" ? false : Boolean(value);
};

const ExecutionVariableFieldReadOnly = ({
  idSuffix,
  // from props
  value,
  variable,
  showLabel,
  token,
  // from storeInfo
  managedEntities = [],
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const snackbar = useSnackbar();

  const displayValue = variable.values?.length
    ? Variable.getDisplayValueForList(variable, value)
    : value;

  switch (variable.type) {
    case "Link": {
      if (isValidUrl(value)) {
        return (
          <Link
            id={`VARIABLE_FIELD_LINK_${idSuffix}`}
            href={value}
            target="_blank"
          >
            {displayValue}
          </Link>
        );
      }

      return (
        <Typography
          id={`VARIABLE_FIELD_DOWNLOAD_${idSuffix}`}
          variant="body2"
          onClick={() => {
            downloadArchive({
              path: value,
              token,
              fileName: Repository.getFilenameFromUri(value),
              onError: (e) => {
                if (snackbar) {
                  snackbar.enqueueSnackbar("Unable to download contents", {
                    variant: "error",
                  });
                }
              },
            });
          }}
          className={commonClasses.commonLink}
        >
          {displayValue}
        </Typography>
      );
    }
    case "Password":
      return (
        <Typography
          id={`VARIABLE_FIELD_PASSWORD_${idSuffix}`}
          variant="body2"
          className={commonClasses.commonItemValue}
        >
          {"•••"}
        </Typography>
      );
    case "Boolean":
      return (
        <Checkbox
          id={`VARIABLE_FIELD_BOOLEAN_${idSuffix}`}
          checked={convertStringIntoBoolean(value)}
          disabled
          size={"small"}
          className={classes.checkbox}
        />
      );
    case "Device":
    case "ManagedEntity": {
      const managedEntity = managedEntities.find(
        (managedEntity) => managedEntity.ubiId === value,
      );
      return (
        <Typography
          id={`VARIABLE_FIELD_DEVICE_${idSuffix}`}
          variant="body2"
          className={commonClasses.commonItemValue}
        >
          {managedEntity ? `${managedEntity.name} - ${value}` : displayValue}
        </Typography>
      );
    }
    case "Code":
      return (
        <EditorAce
          readOnly={true}
          height={"150px"}
          options={{
            showInvisibles: true,
            maxLines: 15,
          }}
          mode={variable.codeLanguage || undefined}
          value={value}
        />
      );
    default:
      return showLabel ? (
        <MUITextField
          id={`VARIABLE_FIELD_TEXT_${idSuffix}`}
          variant={"outlined"}
          label={variable.displayName}
          required={Boolean(variable.mandatory)}
          value={value}
          className={classes.itemValue}
          classes={{ root: classes.field }}
          disabled
          type="text"
          margin="none"
        />
      ) : (
        <Typography
          id={`VARIABLE_FIELD_READONLY_${idSuffix}`}
          variant="body2"
          className={classnames(
            commonClasses.commonItemValue,
            commonClasses.commonTwoLineWrapper,
          )}
        >
          {displayValue}
        </Typography>
      );
  }
};

const ExecutionVariableFieldEditable = (props) => {
  const {
    id,
    idSuffix,
    value,
    // from props
    variable,
    data,
    handleOnChange,
    showLabel,
    predefinedValuesEnabled,
    validate: externalValidator = () => undefined,
    components: {
      Select = MUISelect,
      TextField = MUITextField,
      Password = PasswordInput,
    } = {},
    // from storeInfo
    token,
    subtenant = {},
    tenant = {},
    subtenants = [],
    selectedManagedEntity,
    userDetails = {},
  } = props;
  const selectedSubTenant = useSelector(getSelectedSubtenant);
  const classes = useStyles();
  const errorText = Validation.variable(value, variable, externalValidator);
  const showUniqueValuesInDropdown =
    ["Device", "ServiceRef", "OBMFRef"].includes(variable.type) &&
    variable.name.includes(".0.") &&
    variable?.uniqueItemsInDropDownList === true;

  let selectedOptions = [];
  if (showUniqueValuesInDropdown) {
    const rootName = variable?.name?.replace("params.", "").split(".")[0];
    const subName = variable?.name?.split(".").pop();
    selectedOptions = data[rootName].map((item) => item[subName]);
  }

  // case for predefined list value
  if (variable?.values?.length) {
    // MSA 12814 and MSA 12642 - Uniqueness on array dropdown for Unique value(Index) variable in microservices and array dropdown in workflows
    const variableValues = flow(
      () => {
        if (
          (variable?.type === "Index" ||
            variable?.uniqueItemsInDropDownList === true) &&
          variable.name.includes(".0.")
        ) {
          const rootName = variable?.name?.replace("params.", "").split(".")[0];
          const subName = variable?.name?.split(".").pop();
          const usedValues = data[rootName].map((item) => item[subName]);
          return variable?.values.filter(
            ({ actualValue }) =>
              actualValue === value || !usedValues.includes(actualValue),
          );
        }
        return variable?.values || [];
      },
      (values = []) =>
        values.map(({ actualValue, displayValue, ...rest }) => ({
          ...rest,
          value: actualValue,
          label: displayValue || actualValue,
        })),
    )();

    return variable.editable ? (
      <ComboBox
        id={`VARIABLE_FIELD_COMBOBOX_${idSuffix}`}
        options={variableValues}
        value={value}
        label={showLabel && variable.displayName}
        required={showLabel && Boolean(variable.mandatory)}
        error={Boolean(errorText)}
        onChange={(value) => {
          handleOnChange(value, id);
        }}
        disabled={variable.userLocked}
        fullWidth
        variableName={Variable.removePrefix(variable.name)}
      />
    ) : (
      <>
        <MsaSelect
          id={`VARIABLE_FIELD_LIST_${idSuffix}`}
          variant={"outlined"}
          label={showLabel && variable.displayName}
          options={variableValues}
          required={showLabel && Boolean(variable.mandatory)}
          value={{
            label: value,
            value: value,
          }}
          onChange={(event) => {
            const { value = null } = event || {};
            handleOnChange(value, id);
          }}
          disabled={variable.userLocked}
          isMulti={false}
          fullWidth
          error={Boolean(errorText)}
        />
      </>
    );
  }

  switch (variable.type) {
    case "Device":
    case "ManagedEntity": {
      return (
        <ManagedEntityReference
          label={showLabel ? variable.displayName : ""}
          id={`VARIABLE_FIELD_DEVICE_${idSuffix}`}
          value={value}
          showUniqueValuesInDropdown={showUniqueValuesInDropdown}
          selectedOptions={selectedOptions}
          onSelect={(value) => handleOnChange(value, id)}
          disabled={variable.userLocked}
          sdTypes={variable.sdTypes}
          predefinedValuesEnabled={predefinedValuesEnabled}
          token={token}
          subtenantId={subtenant.id}
          deviceUbiId={selectedManagedEntity}
          managerId={userDetails.id}
        />
      );
    }
    case "Customer":
    case "Subtenant": {
      return (
        <Select
          id={`VARIABLE_FIELD_SUBTENANT_${idSuffix}`}
          value={value}
          label={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          helperText={errorText ?? (showLabel && variable.description)}
          onChange={(event) => handleOnChange(event.target.value, id)}
          disabled={variable.userLocked}
          displayEmpty
          fullWidth
        >
          <MenuItem value={""} />
          {subtenants.map((subtenant) => (
            <MenuItem key={subtenant.ubiqubeId} value={subtenant.ubiqubeId}>
              {subtenant.label + " - " + subtenant.ubiqubeId}
            </MenuItem>
          ))}
        </Select>
      );
    }
    case "Boolean":
      return (
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                id={`VARIABLE_FIELD_BOOLEAN_${idSuffix}`}
                checked={convertStringIntoBoolean(get(data, id, false))}
                onChange={({ target: { checked } }) => {
                  handleOnChange(checked, id);
                }}
                disabled={variable.userLocked}
              />
            }
            label={showLabel && variable.displayName}
          />
        </FormControl>
      );
    case "Integer":
      return (
        <TextField
          id={`VARIABLE_FIELD_INTEGER_${idSuffix}`}
          label={showLabel && variable.displayName}
          variant={"outlined"}
          required={Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          helperText={errorText ?? (showLabel && variable.description)}
          value={value}
          className={classes.itemValue}
          classes={{ root: classes.field }}
          onChange={(event) => handleOnChange(event.target.value, id)}
          disabled={variable.userLocked}
          type="number"
          margin="none"
        />
      );
    case "Password": {
      return (
        <Password
          id={`VARIABLE_FIELD_PW_${idSuffix}`}
          variant={"outlined"}
          label={showLabel && variable.displayName}
          placeholder={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          helperText={errorText ?? (showLabel && variable.description)}
          value={value}
          classes={{ root: classes.field }}
          className={classes.itemValue}
          margin="normal"
          onChange={(event) => handleOnChange(event.target.value, id)}
          disabled={variable.userLocked}
        />
      );
    }
    case "ServiceRef": {
      return (
        <WorkflowReference
          id={`VARIABLE_FIELD_SERVICE_REF_${idSuffix}`}
          value={value}
          showUniqueValuesInDropdown={showUniqueValuesInDropdown}
          selectedOptions={selectedOptions}
          onSelect={(value) => handleOnChange(value, id)}
          disabled={variable.userLocked}
          workflowPath={variable.refServiceURI}
          token={token}
          ubiqubeId={subtenant.ubiqubeId}
        />
      );
    }
    // Microservice Reference for Workflow
    case "OBMFRef": {
      const {
        localVarToFilter,
        remoteVarToFilter,
        remoteVarValueToFilter,
      } = variable;
      // If "refDeviceIdVar" starts with "params.", get the managed entity id off a sibling device variable
      // Otherwise "refDeviceIdVar" itself is managed entity id
      const managedEntityId = variable.refDeviceIdVar?.startsWith(
        Variable.variablePrefix,
      )
        ? get(
            data,
            Variable.getArrayNumberAndApplyToId(variable.refDeviceIdVar, id),
          )
        : variable.refDeviceIdVar;

      // remove trigram prefix (like NCL1000) and strip out device id (1000).
      const operatorPrefix = selectedSubTenant
        ? selectedSubTenant.operatorPrefix
        : tenant?.prefix;
      const obmfDeviceId =
        managedEntityId &&
        operatorPrefix &&
        managedEntityId.startsWith(operatorPrefix)
          ? ManagedEntity.removeGivenPrefix(managedEntityId, operatorPrefix)
          : managedEntityId;

      const localVarValueToFilter = Variable.getTableValData(
        variable.localVarToFilter ?? "",
      ).isTable
        ? get(data, Variable.getArrayNumberAndApplyToId(localVarToFilter, id))
        : data[localVarToFilter];

      return (
        <MicroserviceReference
          id={`VARIABLE_FIELD_MS_REFERENCE_SELECT_${variable.refDeviceIdVar}`}
          value={value}
          showUniqueValuesInDropdown={showUniqueValuesInDropdown}
          selectedOptions={selectedOptions}
          label={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          onChange={(event = {}) => {
            const value = event?.target?.value || event?.value || null;
            handleOnChange(value, id);
          }}
          disabled={variable.userLocked}
          obmfRefData={{
            deviceId: obmfDeviceId,
            uris: variable.class,
            localVarToFilter,
            localVarValueToFilter,
            remoteVarToFilter,
            remoteVarValueToFilter,
          }}
          token={token}
          showConsole={true}
          components={props.components}
        />
      );
    }
    // Microservice Reference for Microservice
    case "ObjectRef": {
      const { localVarNameMatch, remoteVarNameMatch } = variable;
      return (
        <MicroserviceReference
          id={`VARIABLE_FIELD_OBJECT_REFERENCE_${idSuffix}`}
          value={value}
          label={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          onChange={(event = {}) => {
            const value = event?.target?.value || event?.value || null;
            handleOnChange(value, id);
          }}
          disabled={variable.userLocked}
          objectRefData={{
            microserviceNames: variable.class,
            localVarToFilter: localVarNameMatch,
            remoteVarToFilter: remoteVarNameMatch,
            localVarValue: localVarNameMatch && data[localVarNameMatch],
          }}
          token={token}
        />
      );
    }

    case "Link":
      return (
        <RepositoryPicker
          id={`VARIABLE_FIELD_LINK_${idSuffix}`}
          value={value}
          label={showLabel && variable.displayName}
          required={showLabel && Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          onChange={(value) => {
            handleOnChange(value, id);
          }}
          disabled={variable.userLocked}
          helperText={errorText ?? (showLabel && variable.description)}
          token={token}
          fullWidth
        />
      );

    case "IpAddress": {
      return (
        <TextField
          id={`VARIABLE_FIELD_IP_V4_${idSuffix}`}
          variant={"outlined"}
          label={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          value={value}
          className={classes.itemValue}
          classes={{ root: classes.field }}
          onChange={(event) => handleOnChange(event.target.value, id)}
          disabled={variable.userLocked}
          type="text"
          margin="none"
          helperText={errorText ?? (showLabel && variable.description)}
        />
      );
    }
    case "Ipv6Address": {
      return (
        <TextField
          id={`VARIABLE_FIELD_IP_V6_${idSuffix}`}
          variant={"outlined"}
          label={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          value={value}
          className={classes.itemValue}
          classes={{ root: classes.field }}
          onChange={(event) => handleOnChange(event.target.value, id)}
          disabled={variable.userLocked}
          type="text"
          margin="none"
          helperText={errorText ?? (showLabel && variable.description)}
        />
      );
    }
    case "IpMask": {
      return (
        <TextField
          id={`VARIABLE_FIELD_IP_MASK_${idSuffix}`}
          variant={"outlined"}
          label={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          value={value}
          className={classes.itemValue}
          classes={{ root: classes.field }}
          onChange={(event) => handleOnChange(event.target.value, id)}
          disabled={variable.userLocked}
          type="text"
          margin="none"
          helperText={errorText ?? (showLabel && variable.description)}
        />
      );
    }
    case "File":
      return (
        <FileReference
          id={`VARIABLE_FIELD_FILE_${idSuffix}`}
          value={value}
          label={showLabel && variable.displayName}
          required={showLabel && Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          onChange={(value) => handleOnChange(value, id)}
          disabled={variable.userLocked}
          repositories={variable.repositories}
          tenant={tenant}
          subtenant={subtenant}
          token={token}
        />
      );

    case "Code":
      return (
        <EditorAce
          height={"150px"}
          options={{
            enableLiveAutocompletion: true,
            showInvisibles: true,
            maxLines: 15,
          }}
          value={value}
          onChange={(value) => handleOnChange(value, id)}
          disabled={variable.userLocked}
          mode={variable.codeLanguage || undefined}
          showFullScreenToggle={true}
        />
      );

    // Composite should be recursed in the parent level. (See above ExecutionVariableField)
    case "Composite":
    case "Index":
    case "AutoIncrement":
    case "String":
    default:
      return (
        <TextField
          id={`VARIABLE_FIELD_TEXT_${idSuffix}`}
          variant={"outlined"}
          label={showLabel && variable.displayName}
          required={Boolean(variable.mandatory)}
          error={Boolean(errorText)}
          value={value}
          className={classes.itemValue}
          classes={{ root: classes.field }}
          onChange={(event) => handleOnChange(event.target.value, id)}
          disabled={variable.userLocked}
          type="text"
          margin="none"
          helperText={errorText ?? (showLabel && variable.description)}
        />
      );
  }
};

ExecutionVariableField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.object,
  variable: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  handleOnChange: PropTypes.func,
  showLabel: PropTypes.bool,
  validate: PropTypes.func,
  storeInfo: PropTypes.object,
};

export default memo(ExecutionVariableField);
