import React from "react";
import Highlighter from "react-highlight-words";

/**
 *
 * The component to highlight in <span> tag if matches in given searchWords
 *
 * @param {Boolean} enableHighlighting | to enable/disable highlighting
 * @param {Array[String]} searchWords | Array of search keywords
 * @param {*} value | value can be anything.
 * @returns {JSX}
 */
const HighlighterComponent = ({
  enableHighlighting = true,
  searchWords = [],
  value,
}) => {
  if (!enableHighlighting) {
    return <>{value}</>;
  }

  const text = (() => {
    switch (typeof value) {
      case "number": {
        return `${value}`;
      }
      default: {
        return value;
      }
    }
  })();

  if (typeof text === "string") {
    return <Highlighter searchWords={searchWords} textToHighlight={text} />;
  }
  return <>{text}</>;
};

export default HighlighterComponent;
