import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import useApi from "msa2-ui/src/hooks/useApi";
import { getStatesAndTransitions, generateWorkflows } from "msa2-ui/src/api/ai";
import { Divider, Paper } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import classNames from "classnames";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import AiParameters from "msa2-ui/src/routes/ai/generate-bpm/parameters/Parameters";
import NetworkGraph from "./network-graph/NetworkGraph";
import BmpSuggestions from "./bpm-suggestions/BmpSuggestions";
import { useSnackbar } from "notistack";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { operators } from "./constants";

const prepareInitialContextForApi = (initialContext = []) => {
  return initialContext.reduce((acc, { name, operator, value }) => {
    let newAcc = acc;
    if (name && operator && (value || value === 0)) {
      newAcc = {
        ...acc,
        [name]: operator === operators["="] ? value : `${operator}${value}`,
      };
    }
    return newAcc;
  }, {});
};

const GenerateBpm = ({ tabs: Tabs, isDeveloper }) => {
  const t = useBoundedTranslation();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bmpSuggestions, setBmpSuggestions] = useState(null);
  const [isApiLoading, , data = {}] = useApi(getStatesAndTransitions);

  const onSubmitHandler = useCallback(
    async ({
      initialState,
      finalState,
      initialContext,
      learningRate,
      reward,
      discountFactor,
    }) => {
      setIsSubmitting(true);

      const [error, response] = await generateWorkflows({
        initialState: initialState.value,
        finalState: finalState.value,
        discountFactor,
        initialContext: prepareInitialContextForApi(initialContext),
        learningRate,
        reward,
      });

      if (error) {
        enqueueSnackbar(
          error.getMessage(t("Unable to generate x", { x: "BPM" })),
          {
            variant: "error",
          },
        );
        setBmpSuggestions(null);
      } else {
        setBmpSuggestions(response);
      }

      setIsSubmitting(false);
    },
    [enqueueSnackbar, t],
  );

  const bmpCloseHandler = useCallback(() => {
    setBmpSuggestions(null);
  }, []);

  return (
    <div>
      {isDeveloper && <Tabs />}
      <Paper
        className={classNames([
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        ])}
      >
        <DelegationProfiles
          type={delegationProfileTypes.AI}
          action="general.generateBpm"
        >
          <AiParameters
            data={data}
            isParametersLoading={isApiLoading || isSubmitting}
            onSubmit={onSubmitHandler}
          />
          <BmpSuggestions data={bmpSuggestions} onBpmClose={bmpCloseHandler} />
          <Divider />
        </DelegationProfiles>
        <NetworkGraph data={data} />
      </Paper>
    </div>
  );
};

GenerateBpm.propTypes = {
  isDeveloper: PropTypes.bool.isRequired,
  tabs: PropTypes.func.isRequired,
};

export default GenerateBpm;
