import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: 8,
    marginLeft: 5,
    textAlign: "left",
  },
}));

const FormField = ({
  value,
  label,
  renderLabel,
  error,
  isError,
  disabled,
  required,
  children,
  fullWidth = true,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      error={isError}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
    >
      {Boolean(label || renderLabel) && (
        <FormLabel
          error={isError}
          disabled={disabled}
          required={required}
          className={classes.label}
        >
          {renderLabel ? renderLabel() : label}
        </FormLabel>
      )}
      {children}
      {value && <FormHelperText>{value?.description}</FormHelperText>}
      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormField;
