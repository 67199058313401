import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import PropTypes from "prop-types";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import { IconButton, Tooltip } from "@material-ui/core";
import { Refresh, Save, ZoomIn, ZoomOut } from "@material-ui/icons";

const ActionButtons = ({
  onSaveNodes,
  onUpdate,
  onZoomIn,
  onZoomOut,
  isToggle,
  onToggleChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("Toogle Link Data")}>
        <IconButton id="TOPOLOGY_TOOGLE_SWITCH">
          <ReactSwitch checked={isToggle} onChange={onToggleChange} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Zoom in")}>
        <IconButton id="TOPOLOGY_ZOOM_IN" onClick={onZoomIn}>
          <ZoomIn />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Zoom out")}>
        <IconButton id="TOPOLOGY_ZOOM_OUT" onClick={onZoomOut}>
          <ZoomOut />
        </IconButton>
      </Tooltip>
      <DelegationProfiles
        type={delegationProfileTypes.MANAGED_ENTITIES}
        action="topology.savePosition"
      >
        <Tooltip title={t("Save Positions")}>
          <IconButton
            id="TOPOLOGY_ZOOM_SAVE"
            onClick={onSaveNodes}
            data-testid="topology-save-button"
          >
            <Save />
          </IconButton>
        </Tooltip>
      </DelegationProfiles>
      <DelegationProfiles
        type={delegationProfileTypes.MANAGED_ENTITIES}
        action="topology.update"
      >
        <Tooltip title={t("Refresh")}>
          <IconButton
            id="TOPOLOGY_ZOOM_REFRESH"
            onClick={onUpdate}
            data-testid="topology-update-button"
          >
            <Refresh />
          </IconButton>
        </Tooltip>
      </DelegationProfiles>
    </>
  );
};

ActionButtons.propTypes = {
  onSaveNodes: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
};

export default ActionButtons;
