import MicroserviceCommand from "msa2-ui/src/services/MicroserviceCommand";
import Variable from "msa2-ui/src/services/Variable";
import Validation from "msa2-ui/src/services/Validation";

export const sidebarTabs = {
  information: "Information",
  variables: "Variables",
  create: "Create",
  read: "Read",
  update: "Update",
  delete: "Delete",
  import: "Import",
  constraint: "Constraint",
  list: "List",
};

const variablePath = "variables.variable";

const formConfig = { variablePath };
const getParserPath = (command) => {
  const commandIndex = command?.findIndex(
    ({ name }) => name === MicroserviceCommand.type.import,
  );
  return ["command", commandIndex || 0, "parser"].join(".");
};

const fields = {
  name: "information.displayName",
  displayField: "information.displayField",
  configType: "information.configType",
  directoryNames: "information.directoryNames",
  command: "command",
  parentURI: "metaInformationList.0.parentURI",
  filename: "metaInformationList.0.name",
  vendor: "metaInformationList.0.vendorId",
  model: "metaInformationList.0.modelId",
  uri: "metaInformationList.0.uri",
  modifiedDate: "modifiedDate",
  version: "version",
  description: "information.description",
  category: "information.category",
  importRank: "information.importRank",
  variables: variablePath,
};

const mapFieldsToTabs = {
  information: sidebarTabs.information,
  variables: sidebarTabs.variables,
};

const localFields = ["config", "active", "checked"];
const requiredFields = [fields.name, fields.vendor, fields.model];
const requiredFieldsInVariable = ["name"];
const uniqueFieldsInVariable = [{ displayOn: "name", comparisonOn: ["name"] }];

const validate = (values) => {
  const mandatoryErrors = Validation.requiredFields({
    values,
    requiredFields,
  });
  const variableErrors = Variable.validate({
    values,
    requiredFields: requiredFieldsInVariable,
    uniqueFields: uniqueFieldsInVariable,
    formPath: variablePath,
  });
  return { ...variableErrors, ...mandatoryErrors };
};

const EditFormName = "MicroserviceEdit";
const CreateFormName = "MicroserviceCreate";
const ConsoleEditFormName = "MicroserviceConsoleVariableEdit";
const ConsoleFormName = "ManagedEntityConfigureTable";
const microserviceForms = [
  CreateFormName,
  EditFormName,
  ConsoleFormName,
  ConsoleEditFormName,
];
const isMicroserviceForm = (form) => microserviceForms.includes(form);

export default {
  sidebarTabs,
  validate,
  formConfig,
  getParserPath,
  EditFormName,
  CreateFormName,
  ConsoleEditFormName,
  ConsoleFormName,
  microserviceForms,
  isMicroserviceForm,
  fields,
  mapFieldsToTabs,
  localFields,
};
