import Palette from "./Palette";
import ContextPad from "./ContextPad";
import CustomRenderer from "./CustomRenderer";

export default {
  __init__: ["customRenderer"],
  customRenderer: ["type", CustomRenderer],
  paletteProvider: ["type", Palette],
  contextPadProvider: ["type", ContextPad],
};
