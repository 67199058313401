import React, { useState } from "react";
import PropTypes from "prop-types";

import { Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Refresh from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
  alertBG: {
    backgroundColor: "#323232",
  },
  alertBgAdjust: {
    backgroundColor: "#323232",
    width: "calc(100% + 32px)",
    marginLeft: -16,
  },
  alertWrapper: {
    alignItems: "center",
    width: "calc(100% - 32px)",
    marginLeft: 16,
  },
  alertMessageWrapper: {
    display: "flex",
    justifyContent: "flex-start",
  },
  alertMessage: {
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.colors.white,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: "normal",
    marginTop: 12,
    marginBottom: 12,
    marginRight: 8,
    marginLeft: 8,
  },
  alertButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  alertButton: {
    fontWeight: theme.typography.fontWeightLight,
    color: theme.colors.white,
    borderRadius: 4,
    padding: 5,
  },
}));

const AlertBar = (props) => {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const {
    message,
    refreshFnc,
    closeFnc = () => setShow(false),
    adjust,
  } = props;
  return (
    show && (
      <div>
        {/* ToDo: modify callers and delete alertBgAdjust */}
        <Grid
          container
          className={adjust ? classes.alertBG : classes.alertBgAdjust}
        >
          <Grid container className={classes.alertWrapper}>
            <Grid item xs={6} className={classes.alertMessageWrapper}>
              <Typography variant="subtitle1" className={classes.alertMessage}>
                {message}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alertButtonWrapper}>
              {refreshFnc && (
                <IconButton
                  className={classes.alertButton}
                  onClick={refreshFnc}
                >
                  <Refresh fontSize="small" />
                </IconButton>
              )}
              <IconButton className={classes.alertButton} onClick={closeFnc}>
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  );
};

AlertBar.propTypes = {
  message: PropTypes.string.isRequired,
  refreshFnc: PropTypes.func,
  closeFnc: PropTypes.func,
  adjust: PropTypes.bool,
};

export default AlertBar;
