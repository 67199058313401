import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "@material-ui/core";

const getTitleWithLineBreaks = (title) => {
  if (typeof title === "string" && title.includes("\n")) {
    return title.split("\n").map((line, i) => <div key={i}>{line}</div>);
  }
  if (Array.isArray(title)) {
    return title.map((entry, i) => <div key={i}>{entry}</div>);
  }
  if (typeof title === "object") {
    return Object.entries(title).map(([key, value], i) => (
      <div key={i}>
        {key}: {value}
      </div>
    ));
  }
  if (typeof title === "boolean") {
    return title.toString();
  }

  return title;
};
const TooltipWithLineBreaks = ({ title, ...rest }) => {
  if (!title) {
    return rest.children;
  }
  return <Tooltip title={<>{getTitleWithLineBreaks(title)}</>} {...rest} />;
};

TooltipWithLineBreaks.propTypes = {
  title: PropTypes.any,
};

export default TooltipWithLineBreaks;
