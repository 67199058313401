import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import reduxForm from "redux-form/lib/reduxForm";
import { useSnackbar } from "notistack";
import flow from "lodash/flow";
import pick from "lodash/pick";

import { getFormValues } from "msa2-ui/src/store/form";
import { getToken } from "msa2-ui/src/store/auth";

import { getParentRoute } from "msa2-ui/src/utils/urls";
import Routing from "msa2-ui/src/utils/Routing";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  updateDeploymentSetting,
  getDeploymentSetting,
} from "msa2-ui/src/api/deploymentSettings";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import {
  useReturnToDeploymentSettings,
  getModalTabCount,
  validateDeploymentSettingsForm,
  modalTabs,
} from "./helpers";

import {
  Dialog,
  Button,
  IconButton,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Divider,
  Tab,
  Tabs,
} from "@material-ui/core";
import { useDeploymentSettingsStyles } from "./commonStyles";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Close from "@material-ui/icons/Close";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import { ModalContent } from "./ModalContent";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const form = "DeploymentSettingsEdit";

const Edit = ({
  handleSubmit,
  pristine,
  initialize,
  initialized,
  submitting,
  invalid,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id: deploymentSettingId } = useParams();
  const { pathname } = useLocation();
  const onClose = useReturnToDeploymentSettings();
  const history = useHistory();

  const classes = useDeploymentSettingsStyles();
  const commonClasses = useCommonStyles();

  const token = useSelector(getToken);
  const subtenantId = useSelector(getFormValues(form, "subtenantId"));
  const formData = useSelector(getFormValues(form));
  const name = useSelector(getFormValues(form, "name"));

  const [, , deploymentSetting] = useApi(getDeploymentSetting, {
    deploymentSettingId,
    filterByLabel: isPermissionProfileLabelsEnabled,
  });

  const submitForm = async (values) => {
    const [updateError] = await updateDeploymentSetting({
      token,
      profile_id: deploymentSetting.id,
      subtenantId,
      body: pick(values, [
        "id",
        "name",
        "externalReference",
        "comment",
        "model",
        "vendor",
        "microserviceUris",
        "templateUris",
        "attachedManagedEntities",
      ]),
    });
    const variant = updateError ? "error" : "success";
    const message = updateError
      ? updateError.getMessage(
          t("Unable to update Deployment setting.") +
            "(" +
            updateError.name +
            ")",
        )
      : t("Deployment setting has been updated.");
    variant === "success" && onClose();
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  useEffect(() => {
    if (!initialized && deploymentSetting) {
      initialize(deploymentSetting);
    }
  }, [deploymentSetting, initialize, initialized]);

  return (
    <Dialog
      id="CONFIGURATION_DEPLOYMENT_SETTINGS_EDIT_TITLE"
      open={true}
      onClose={onClose}
      aria-labelledby="modalArea"
      fullWidth={true}
      maxWidth={"md"}
      className={classes.dialog}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        id="modalArea"
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {name}
        </Typography>
        <IconButton
          id="CONFIGURATION_DEPLOYMENT_SETTINGS_EDIT_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogActions className={classes.tabsWrapper}>
        <Tabs
          value={Routing.getTabIndexByPath(modalTabs, pathname)}
          indicatorColor="primary"
          variant="standard"
        >
          {modalTabs.map((tab) => (
            <Tab
              key={tab.id}
              id={`CONFIGURATION_DEPLOYMENT_SETTINGS_EDIT_TAB_${tab.id}`}
              classes={{
                root: commonClasses.commonTab,
                selected: commonClasses.commonTabSelected,
              }}
              className={commonClasses.commonTab}
              component={Link}
              to={`${getParentRoute(pathname)}/${tab.path}`}
              label={
                <>
                  {tab.displayName}
                  <NumberFormat
                    value={getModalTabCount(tab, formData)}
                    displayType={"text"}
                    thousandSeparator={true}
                    className={commonClasses.commonTabCount}
                  />
                </>
              }
            />
          ))}
        </Tabs>
      </DialogActions>
      <Divider className={classes.dividerTop} />
      {initialized && (
        <DialogContent className={classes.dialogContent}>
          <div className={classes.contentWrapper}>
            <ModalContent form={form} />
          </div>
        </DialogContent>
      )}
      <Divider />
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          id="CONFIGURATION_DEPLOYMENT_SETTINGS_DIALOG_ACTIONS_BTN_CANCEL"
          variant="text"
          size="small"
          color="default"
          className={classnames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          disabled={submitting}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="CONFIGURATION_DEPLOYMENT_SETTINGS_DIALOG_ACTIONS_BTN_SAVE"
          variant="contained"
          size="small"
          color="primary"
          className={classnames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          disabled={submitting || pristine}
          onClick={() => {
            if (invalid) {
              history.push(`${getParentRoute(pathname)}/${modalTabs[0].path}`);
            }
            handleSubmit(submitForm)();
          }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Edit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default flow(
  reduxForm({
    form,
    validate: validateDeploymentSettingsForm,
  }),
)(Edit);
