import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import VariableReferencePanel from "./VariableReferencePanel";

export const useStyles = makeStyles(({ typography, palette }) => ({
  expandButton: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  input: {
    paddingLeft: 8,
    position: "relative",
    minHeight: "65vh",
    marginRight: "10px",
    width: "inherit",
  },
  sectionHeaderContainer: {
    marginBottom: 5,
  },
  sectionHeader: {
    fontSize: "1.5rem",
    fontWeight: typography.fontWeightMedium,
    marginRight: 20,
  },
  sectionSubheader: {
    fontSize: "0.875rem",
    fontWeight: typography.fontWeightRegular,
    color: palette.text.secondary,
    marginBottom: 20,
  },
  sectionTabAlignment: {
    marginTop: 1,
  },
}));

const MicroserviceCommandTemplate = ({
  title,
  subtitle,
  children,
  onEditVariableClicked,
  variables,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation("variables");
  const [showVariablePanel, handleShowVariablePanel] = useState(true);

  return (
    <>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          direction={"row"}
          alignItems={"baseline"}
          className={classes.sectionHeaderContainer}
        >
          <Typography className={classes.sectionHeader}>{title}</Typography>
          <Typography className={classes.sectionSubheader}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item xs={showVariablePanel ? 8 : 12} container>
          <Paper
            className={classnames(
              commonClasses.commonPaperNoPadding,
              classes.input,
            )}
          >
            <Grid
              item
              xs={12}
              container
              justifyContent={"flex-end"}
              alignItems={"baseline"}
            >
              {!showVariablePanel && (
                <IconButton
                  onClick={() => handleShowVariablePanel(true)}
                  className={classes.expandButton}
                >
                  <KeyboardArrowLeft color="primary" />
                </IconButton>
              )}
            </Grid>
            <Grid
              item
              direction={"row"}
              spacing={2}
              xs={12}
              container
              className={classes.sectionTabAlignment}
            >
              {children}
            </Grid>
          </Paper>
        </Grid>
        {showVariablePanel && (
          <Grid item xs={4}>
            <VariableReferencePanel
              variables={variables}
              onEditVariableClicked={onEditVariableClicked}
              handleCollapse={() => handleShowVariablePanel(false)}
              displayItems={[
                {
                  key: "type",
                  displayName: `${t("Type")}:`,
                },
                {
                  key: "defaultValue",
                  displayName: `${t("Default")}:`,
                  transform: { null: "-" },
                },
                {
                  key: "userLocked",
                  displayName: `${t("ReadOnly?")}:`,
                  transform: { true: "Yes", false: "No" },
                },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

MicroserviceCommandTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onEditVariableClicked: PropTypes.func.isRequired,
  variables: PropTypes.array,
};

export default MicroserviceCommandTemplate;
