import React, { memo } from "react";
import Field from "redux-form/lib/Field";
import Select from "msa2-ui/src/components/connectedFormComponents/Select";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  formLabel: {
    ...theme.typography.h5,
  },
  helperText: {
    // if not warning, this color will be replaced by .Mui-error
    color: theme.palette.warning.main,
  },
}));

const FormSelect = ({
  name,
  children,
  className,
  label,
  defaultValue,
  inputProps,
  onChange,
  id,
  disabled = false,
  validate,
  required = true,
  warn,
}) => {
  const classes = useStyles();
  return (
    <Field
      name={name}
      id={id ?? name}
      label={label}
      component={Select}
      defaultValue={defaultValue}
      className={classnames(className, classes.fullWidth)}
      type="text"
      onChange={onChange}
      disabled={disabled}
      inputProps={{
        name,
        id: name,
        ...inputProps,
      }}
      required={required}
      validate={validate}
      warn={warn}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
    >
      {children}
    </Field>
  );
};

export default memo(FormSelect);
