import { makeStyles } from "@material-ui/core";

export const useDeploymentSettingsStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
  },
  contentWrapper: {
    minHeight: 440,
  },
  tabsWrapper: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 24,
    display: "inline",
    paddingBottom: 0,
  },
  dividerTop: {
    marginBottom: 24,
  },
  hiddenTab: {
    display: "none",
  },
  errorMessage: {
    textAlign: "center",
    padding: 10,
  },
}));
