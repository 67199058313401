import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 15,
  },
  label: {
    paddingLeft: 5,
  },
  item: {
    paddingLeft: 10,
  },
}));

const InfoPanel = ({ data = {} }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const keyMap = {
    initialState: t("Initial state"),
    finalState: t("Final state"),
    transitions: t("Transitions"),
    learningRate: t("Learning Rate"),
    discountFactor: t("Discount Factor"),
    reward: t("Reward"),
    initialContext: t("Initial Context"),
  };
  const getLabel = (key) => keyMap[key] || t(key);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          {t("Information")}
        </Typography>
      </Grid>
      {Object.entries(data).map(([key, value]) => (
        <Fragment key={key}>
          <Grid item xs={12} md={2} className={classes.label}>
            <Typography className={commonClasses.commonDescription}>
              {getLabel(key)}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className={classes.item}>
            <Typography
              className={commonClasses.commonItemValue}
              variant="body2"
            >
              {value}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

InfoPanel.propTypes = {
  data: PropTypes.object,
};

export default InfoPanel;
