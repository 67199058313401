import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import classnames from "classnames";

import {
  Avatar,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { managedEntityStatus } from "msa2-ui/src/Constants";

import { ReactComponent as ConnectedIcon } from "msa2-ui/src/assets/icons/status/statusConnected.svg";
import { ReactComponent as CriticalIcon } from "msa2-ui/src/assets/icons/status/statusCritical.svg";
import { ReactComponent as NeverReachedIcon } from "msa2-ui/src/assets/icons/status/statusNeverReached.svg";
import { ReactComponent as UnreachableIcon } from "msa2-ui/src/assets/icons/status/statusUnreachable.svg";
import NotificationsOutlined from "@material-ui/icons/NotificationsOutlined";

const useStyles = makeStyles(({ darkMode, palette }) => ({
  card: {
    minWidth: 275,
  },
  title: {
    fontSize: "1.05rem",
    lineHeight: 1.17,
    color: palette.text.primary,
  },
  addCursor: {
    cursor: "pointer",
  },
  listItem: {
    padding: "0 6px",
  },
  status: {
    padding: 0,
    width: "auto",
  },
  avatar: {
    background: "transparent",
    width: 20,
    height: 20,
    marginRight: 3,
  },
  count: {
    fontSize: "1.125rem",
    lineHeight: "1rem",
    maxWidth: 80,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  secondaryType: {
    fontSize: "0.75rem",
    lineHeight: 1.17,
    color: palette.text.secondary,
  },
  popover: {
    border: darkMode ? `1px solid ${palette.text.secondary}` : "none",
    boxShadow: darkMode ? "none" : "3px 3px 20px 6px #c2c4ca",
  },
  alarmIcon: {
    height: 25,
    width: 25,
    color: "red",
  },
  badgeAlarm: {
    width: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palette.text.primary,
  },
  centeredContent: {
    textAlign: "center",
  },
}));

const RenderDataLabel = ({ text, children, value, isCentered }) => {
  const classes = useStyles();
  const numberFormatClass = isCentered ? classes.centeredContent : "";
  return (
    <TableCell component="th" scope="row" align="left" padding="none">
      <ListItem className={classes.status}>
        {children && <Avatar className={classes.avatar}>{children}</Avatar>}
        <ListItemText
          className={classes.listItem}
          classes={{
            primary: classes.count,
            secondary: classes.secondaryType,
          }}
          primary={
            <div className={numberFormatClass}>
              <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
              />
            </div>
          }
          secondary={text}
        />
      </ListItem>
    </TableCell>
  );
};

const RenderRepeatTableCell = ({ align, padding = "normal", text, value }) => {
  const classes = useStyles();
  return (
    <TableCell align={align} padding={padding}>
      {value > 0 ? (
        <Paper
          className={classnames({
            [classes.title]: Boolean(text),
          })}
        >
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Paper>
      ) : (
        <Paper className={classes.secondaryType}>-</Paper>
      )}
      {text && <Paper className={classes.secondaryType}>{text}</Paper>}
    </TableCell>
  );
};

const GraphTableView = ({
  chart,
  anchorEl,
  handleTitleOnClick,
  handleDataOnClick,
}) => {
  const classes = useStyles();

  const physicalData = chart.natureDeviceStatusReports.find(
    (arr) => arr.deviceNature === "PHSL",
  );
  const privateData = chart.natureDeviceStatusReports.find(
    (arr) => arr.deviceNature === "VPRV",
  );
  const publicData = chart.natureDeviceStatusReports.find(
    (arr) => arr.deviceNature === "VPUB",
  );

  const RenderStatusRow = ({ text, children, error, dataKey }) => {
    return (
      <TableRow>
        <RenderDataLabel text={text} value={error}>
          {children}
        </RenderDataLabel>
        <RenderRepeatTableCell align={"center"} value={physicalData[dataKey]} />
        <RenderRepeatTableCell align={"center"} value={publicData[dataKey]} />
        <RenderRepeatTableCell align={"center"} value={privateData[dataKey]} />
      </TableRow>
    );
  };

  const RenderDeviceAlarmRowLabel = ({ text, children, error }) => {
    return (
      <TableRow>
        <RenderDataLabel text={text} value={error} isCentered={true}>
          {children}
        </RenderDataLabel>
      </TableRow>
    );
  };

  return (
    <Popover
      PaperProps={{ classes: { root: classes.popover } }}
      id="DASHBOARD_GRAPH_TABLE_DIALOG"
      open={true}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Card className={classes.card}>
        <div
          style={{ position: "absolute", top: 0, right: 0, display: "flex" }}
        >
          <RenderDeviceAlarmRowLabel
            text={"Alarms"}
            error={chart.alarmCount}
            dataKey={"deviceNatureAlarmCount"}
          >
            <NotificationsOutlined
              className={classes.alarmIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (handleDataOnClick !== null) {
                  handleDataOnClick(chart.id, "/alarms/");
                }
              }}
            />
          </RenderDeviceAlarmRowLabel>
          <div className={classes.badgeAlarm}>
            <span> / </span>
          </div>
          <RenderDeviceAlarmRowLabel
            text={
              chart.numOfDevicesWithAlarms === 1
                ? "Managed Entity"
                : "Managed Entities"
            }
            error={chart.numOfDevicesWithAlarms}
          ></RenderDeviceAlarmRowLabel>
        </div>
        <CardContent>
          <Typography className={classes.secondaryType}>
            {chart.tenantName}
          </Typography>
          <Typography
            className={classnames(classes.cellSubtenant, {
              [classes.addCursor]: Boolean(handleTitleOnClick),
            })}
            color="textPrimary"
            gutterBottom
            onClick={() => {
              if (handleTitleOnClick !== null) {
                handleTitleOnClick(chart.id);
              }
            }}
          >
            {chart.name}
          </Typography>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <RenderRepeatTableCell
                  align={"left"}
                  padding={"none"}
                  text={"Managed Entites"}
                  value={chart.total}
                />
                <RenderRepeatTableCell
                  align={"center"}
                  text={"Physical"}
                  value={physicalData.total}
                />
                <RenderRepeatTableCell
                  align={"center"}
                  text={"Public"}
                  value={publicData.total}
                />
                <RenderRepeatTableCell
                  align={"center"}
                  text={"Private"}
                  value={privateData.total}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              <RenderStatusRow
                text={"Unreachable"}
                error={chart.error}
                dataKey={"error"}
              >
                <UnreachableIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (handleDataOnClick !== null) {
                      handleDataOnClick(
                        chart.id,
                        `/integration/managed-entities?status=${managedEntityStatus.ERROR.status}`,
                      );
                    }
                  }}
                />
              </RenderStatusRow>
              <RenderStatusRow
                text={"Critical"}
                error={chart.critical}
                dataKey={"critical"}
              >
                <CriticalIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (handleDataOnClick !== null) {
                      handleDataOnClick(
                        chart.id,
                        `/integration/managed-entities?status=${managedEntityStatus.CRITICAL.status}`,
                      );
                    }
                  }}
                />
              </RenderStatusRow>
              <RenderStatusRow
                text={"Never Reached"}
                error={chart.neverReached}
                dataKey={"neverReached"}
              >
                <NeverReachedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (handleDataOnClick !== null) {
                      handleDataOnClick(
                        chart.id,
                        `/integration/managed-entities?status=${managedEntityStatus.NEVERREACHED.status}`,
                      );
                    }
                  }}
                />
              </RenderStatusRow>
              <RenderStatusRow
                text={"Connected"}
                error={chart.ok}
                dataKey={"ok"}
              >
                <ConnectedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (handleDataOnClick !== null) {
                      handleDataOnClick(
                        chart.id,
                        `/integration/managed-entities?status=${managedEntityStatus.OK.status}`,
                      );
                    }
                  }}
                />
              </RenderStatusRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Popover>
  );
};

GraphTableView.propTypes = {
  chart: PropTypes.object.isRequired,
  anchorEl: PropTypes.object.isRequired,
  handleTitleOnClick: PropTypes.func,
};

export default GraphTableView;
