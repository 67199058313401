import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ReactDiffViewer from "react-diff-viewer";

import { makeStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@material-ui/core";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import useApi from "msa2-ui/src/hooks/useApi";

import { getConfigurationsToCompare } from "msa2-ui/src/api/managedEntity";

const useStyles = makeStyles(() => ({
  errorMessage: {
    textAlign: "center",
  },
  progressWrapper: {
    height: 300,
    overflowX: "auto",
  },
}));

const DiffDialog = ({ title, entries, deviceId, onClose, maxWidth = "xl" }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const [showDiffOnly, setShowDiffOnly] = useState(true);

  const { t } = useTranslation();

  const revisions = entries.map((entry) => entry.revision);

  const [isLoading, error, response = []] = useApi(
    getConfigurationsToCompare,
    { deviceId, revisions },
    !deviceId,
  );

  const { oldValue = "", newValue = "" } = response;

  const renderContent = () => {
    return (
      <>
        {isLoading ? (
          <div
            data-testid="diff-dialog-spinner"
            className={classNames(
              classes.progressWrapper,
              commonClasses.commonFlexCenter,
            )}
          >
            <CircularProgress />
          </div>
        ) : (
          <ReactDiffViewer
            rightTitle={revisions[0]}
            leftTitle={revisions[1]}
            useDarkTheme
            oldValue={oldValue}
            newValue={newValue}
            splitView={true}
            showDiffOnly={showDiffOnly}
          />
        )}
      </>
    );
  };

  const renderError = () => {
    return (
      <Typography className={classes.errorMessage}>
        {t("Error loading x", { x: t("revisions") })}
      </Typography>
    );
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {title}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={showDiffOnly}
              onChange={() => setShowDiffOnly(!showDiffOnly)}
            />
          }
          label={t("Only show differences")}
        />
        <IconButton
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        {error ? renderError() : renderContent()}
      </DialogContent>
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          variant="text"
          size="small"
          color="primary"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          onClick={onClose}
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiffDialog;
