import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  initialStateValue: null,
  finalStateValue: null,
  transitionValues: [],
};

const ai = createSlice({
  name: "AI",
  initialState,
  reducers: {
    resetAiWizard() {
      return initialState;
    },
    changeInitialStateValue(state, action) {
      state.initialStateValue = action.payload;
    },
    changeFinalStateValue(state, action) {
      state.finalStateValue = action.payload;
    },
    changeTransitionValues(state, action) {
      state.transitionValues = action.payload;
    },
  },
});

export const {
  resetAiWizard,
  changeInitialStateValue,
  changeFinalStateValue,
  changeTransitionValues,
} = ai.actions;

export const getTransitionValues = (state) => state.ai.transitionValues;

export default ai.reducer;
