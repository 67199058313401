import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";
import { hasUnicode } from "msa2-ui/src/utils/validators";

import Process from "msa2-ui/src/services/Process";
import Repository from "msa2-ui/src/services/Repository";

import { makeStyles } from "@material-ui/core";
import {
  TextField,
  MenuItem,
  Grid,
  FormControl,
  Typography,
} from "@material-ui/core";

import Dialog from "msa2-ui/src/components/Dialog";
import BasicSelect from "msa2-ui/src/components/BasicSelect";

const useStyles = makeStyles((theme) => ({
  gridSpacing: {
    marginTop: 20,
    marginBottom: 20,
  },
  selectField: {
    textAlign: "left",
    marginBottom: 35,
    width: "100%",
  },
  iconSpacing: {
    paddingRight: 20,
  },
  errorMessage: {
    color: theme.colors.red,
  },
  warningMessage: {
    color: theme.colors.yellow,
  },
}));

const AddProcess = ({
  showAddProcessDialog,
  setShowAddProcessDialog,
  addProcessToForm,
  workflowProcesses,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [processName, setProcessName] = useState("");
  const [processDirectory, setProcessDirectory] = useState("");
  const [processType, setProcessType] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [showDirectoryName, setShowDirectoryName] = useState(false);

  useEffect(() => {
    const sameName = workflowProcesses?.find(
      (process) => process.displayName === processName,
    );
    const samePath = workflowProcesses?.find(
      (process) =>
        Repository.getFilenameFromUri(process.name) === processDirectory,
    );
    if (isEmpty(processName)) {
      setErrorMessage(t("Please Enter the Process name"));
    } else if (sameName) {
      setErrorMessage(t("Please use a unique name for Processes"));
    } else if (samePath) {
      setErrorMessage(t("Please set a unique Directory Name for Processes"));
      setShowDirectoryName(true);
    } else if (!processType) {
      setErrorMessage(t("Please select a Process type"));
    } else {
      setErrorMessage(null);
    }
  }, [
    processName,
    processType,
    workflowProcesses,
    setErrorMessage,
    t,
    processDirectory,
  ]);

  useEffect(() => {
    if (hasUnicode(processName)) {
      setWarningMessage(
        t(
          "Double byte characters are replaced. Please change Directory name if needed.",
        ),
      );
    } else {
      setWarningMessage(null);
    }
  }, [processName, processType, workflowProcesses, setErrorMessage, t]);

  const sendToProcessForm = () => {
    if (!errorMessage) {
      addProcessToForm(processName, processDirectory, processType);
      clearForm();
    }
  };

  const clearForm = () => {
    setProcessName("");
    setProcessType("");
    setProcessDirectory("");
    setShowAddProcessDialog();
  };

  if (!showAddProcessDialog) return null;

  return (
    <Dialog
      execLabel={"Save"}
      onClose={clearForm}
      onExec={sendToProcessForm}
      title={t("Create Process")}
    >
      <Grid container direction="column">
        <Grid className={classes.gridSpacing}>
          <FormControl fullWidth>
            <TextField
              type={"text"}
              id="AUTOMATON_PROCESS_NAME"
              variant="outlined"
              inputProps={{ "data-testid": "process-name" }}
              onChange={({ target: { value } }) => {
                setProcessName(value);
                setProcessDirectory(
                  Process.DIRECTORY_PREFIX +
                    Repository.convertStringToFilename(value),
                );
              }}
              label={t("Process Name")}
              value={processName}
            />
          </FormControl>
        </Grid>
        {(hasUnicode(processName) || showDirectoryName) && (
          <Grid className={classes.subItems}>
            <FormControl fullWidth>
              <TextField
                type={"text"}
                id="AUTOMATON_PROCESS_DIRECTORY"
                variant="outlined"
                inputProps={{ "data-testid": "process-directory" }}
                onChange={({ target: { value } }) => {
                  setProcessDirectory(value);
                }}
                label={t("Process Directory Name")}
                value={processDirectory}
              />
            </FormControl>
          </Grid>
        )}
        <Grid className={classes.gridSpacing}>
          <BasicSelect
            label={t("Process Type")}
            id="AUTOMATON_PROCESS_TYPE"
            variant="outlined"
            className={classes.selectField}
            value={processType}
            onChange={({ target: { value } }) => {
              setProcessType(value);
            }}
          >
            {Object.entries(Process.processDefinitions)
              .filter(([_, { canAdd }]) => canAdd)
              .map(([key]) => {
                return (
                  <MenuItem
                    key={key}
                    value={key}
                    id={`AUTOMATION_PROCESS_TYPE_${key}`}
                  >
                    {key}
                  </MenuItem>
                );
              })}
          </BasicSelect>
        </Grid>
        <Grid>
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
          <Typography className={classes.warningMessage}>
            {warningMessage}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AddProcess.propTypes = {
  showAddProcessDialog: PropTypes.bool.isRequired,
  setShowAddProcessDialog: PropTypes.func.isRequired,
  addProcessToForm: PropTypes.func.isRequired,
  workflowProcesses: PropTypes.array,
};

export default AddProcess;
