import React, { useRef, useState, useEffect } from "react";
import {
  Select as MUISelect,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const BasicSelect = ({
  label,
  className,
  name,
  children,
  error,
  required,
  helperText,
  FormHelperTextProps,
  touched,
  t,
  fullWidth,
  ...props
}) => {
  const commonClasses = useCommonStyles();
  // This is used to inform the Select component how much space to leave for the label
  // See code source example at https://material-ui.com/components/selects/#simple-select
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl
      variant="outlined"
      required={required}
      className={className}
      error={error}
      fullWidth={fullWidth}
    >
      <InputLabel ref={inputLabel} htmlFor={name}>
        {label}
      </InputLabel>
      <MUISelect
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "center", horizontal: "left" },
          PopoverClasses: {
            paper: commonClasses.commonPopoverPaper,
          },
        }}
        name={name}
        inputProps={{
          name,
          id: name,
          "data-testid": name,
        }}
        touched={touched}
        labelWidth={labelWidth}
        classes={{ root: commonClasses.commonFlexStart }}
        {...props}
      >
        {children}
      </MUISelect>
      {helperText && (
        <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default BasicSelect;
