import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import flow from "lodash/flow";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ReactComponent as DownloadIcon } from "msa2-ui/src/assets/icons/download.svg";
import {
  Grid,
  Hidden,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import TableRow from "msa2-ui/src/components/TableRow";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import { filter } from "msa2-ui/src/utils/filter";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { buildRoute } from "msa2-ui/src/utils/urls";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";
import InstanceCounter from "msa2-ui/src/routes/automation/bpm/overview-tables/InstanceCounter";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { workflowStatus } from "msa2-ui/src/Constants";
import TableHead from "./TableHead";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { downloadArchive } from "msa2-ui/src/api/repository";
import Repository from "msa2-ui/src/services/Repository";
import { getToken } from "msa2-ui/src/store/auth";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { detachServicesFromMultipleSubtenants } from "msa2-ui/src/api/bpm";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

const useStyles = makeStyles(({ theme, palette, typography }) => ({
  barChartAlign: {
    top: 15,
  },
  tableIconsMinWidth: {
    width: 120,
  },
  tableLink: {
    textDecoration: "none",
    color: "currentColor",
    "&:active, &:visited": {
      color: "currentColor",
    },
  },
  tableSubtenants: {
    fontSize: 13,
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
    cursor: "pointer",
  },
  tableSubtenantsNone: {
    fontSize: 13,
    color: palette.text.secondary,
  },
  downloadIcon: {
    width: 48,
  },
}));

const TABLE_COLUMNS = [
  {
    id: "diagramName",
    name: "Name",
    align: "left",
  },
  {
    id: "timestamp",
    name: "Date Modified",
    align: "left",
  },
  {
    id: "total",
    name: "Instances",
    align: "right",
  },
  {
    id: "status",
    name: "",
    align: "left",
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
  },
];

const SubtenantOverviewTable = ({
  bpmDiagramsIsLoading,
  bpmDiagramsError,
  bpmDiagrams = [],
  filterState,
  filterActions,
  recordsCount,
  reloadGetBpmDiagrams,
  isWorkflowsOwnerDetailsEnabled,
}) => {
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();
  const { url: currentRoute } = useRouteMatch();
  const { pathname } = window.location;

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const bpmList = filter(bpmDiagrams, filterState.searchValue, ["name"]);

  const [showDetachDialog, DetachDialog] = useDialog();
  const [bpmToDetach, setBpmToDetach] = useState(null);
  const { ubiqubeId } = useSelector(getSelectedSubtenant);

  /**
   * If we navigate back to this page after creating or editing
   * we want to make sure we get the latest BPM diagrams
   */
  useEffect(() => {
    if (pathname === "/automation/bpm") {
      reloadGetBpmDiagrams();
    }
  }, [pathname, reloadGetBpmDiagrams]);

  const handleDelete = async () => {
    const [error, detached] = await detachServicesFromMultipleSubtenants({
      token,
      uri: bpmToDetach.uri,
      ubiqubeIds: ubiqubeId,
    });
    if (error) {
      enqueueSnackbar(
        error.getMessage(t("Unable to detach x", { x: bpmToDetach.name })),
        {
          variant: "error",
        },
      );
    } else if (detached) {
      enqueueSnackbar(
        t("BPM diagram has been detached!", { name: bpmToDetach.name }),
        { variant: "success" },
      );
      reloadGetBpmDiagrams();
    }
    setBpmToDetach(null);
  };

  const renderStatusGraph = (total, completed, active, failed) => {
    return total > 0 ? (
      <BarChart
        width={300}
        height={50}
        data={[
          {
            RUNNING: active,
            ENDED: completed,
            FAIL: failed,
          },
        ]}
        layout="vertical"
        className={classes.barChartAlign}
      >
        <XAxis type="number" tick={false} tickCount={0} axisLine={false} />
        <YAxis type="category" tick={false} axisLine={false} />
        {workflowStatus.map((status) => (
          <Bar
            key={status.id}
            radius={5}
            maxBarSize={status.barSize}
            dataKey={status.status}
            fill={status.color}
            stackId="instances"
          />
        ))}
      </BarChart>
    ) : null;
  };

  const renderTableBody = () => {
    if (bpmDiagramsIsLoading) {
      return <TableLoading numberOfTableColumns={TABLE_COLUMNS.length} />;
    }

    if (bpmDiagrams.length === 0) {
      return (
        <TableMessage
          message={
            bpmDiagramsError
              ? `${t("Error fetching x", { x: t("BPM diagrams") })}. ${t(
                  "Please reload the page",
                )}.`
              : t("No BPM diagrams found")
          }
          numberOfTableColumns={TABLE_COLUMNS.length}
        />
      );
    }

    return (
      <TableBody>
        <DetachDialog
          title={t("Detach BPM?")}
          content={t("Are you sure you want to detach x?", {
            x: bpmToDetach?.uri,
          })}
          onExec={handleDelete}
        />
        {bpmList.map((bpmFile, rowIndex) => {
          const bpmDetailsUri = buildRoute(
            currentRoute,
            `${encodeURIComponent(bpmFile.uri)}`,
          );

          return (
            <TableRow
              id={`BPM_TABLE_ROW_${rowIndex}`}
              key={rowIndex}
              actions={
                <>
                  {((isWorkflowsOwnerDetailsEnabled &&
                    bpmFile.permission?.modify) ||
                    !isWorkflowsOwnerDetailsEnabled) && (
                    <>
                      <DelegationProfiles
                        type={delegationProfileTypes.BPM}
                        action="general.modify"
                      >
                        <Link
                          className={commonClasses.commonNoTextDecoration}
                          id={`BPM_TABLE_BTN_EDIT_LINK_${rowIndex}`}
                          data-testid={`BPM_TABLE_BTN_EDIT_LINK_${rowIndex}`}
                          to={buildRoute(bpmDetailsUri, "edit")}
                        >
                          <IconButton
                            id={`BPM_TABLE_BTN_EDIT_${rowIndex}`}
                            aria-label={t("Edit")}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </Link>
                      </DelegationProfiles>

                      <IconButton
                        id={`BPM_TABLE_BTN_DOWNLOAD_${rowIndex}`}
                        aria-label={t("Download")}
                        className={classes.downloadIcon}
                        onClick={() => {
                          downloadArchive({
                            path: decodeURIComponent(
                              Repository.getFilenameFromUri(bpmDetailsUri),
                            ),
                            token,
                            fileName: flow(
                              decodeURIComponent,
                              Repository.getFilenameFromUri,
                              (bpmDetailsUri) =>
                                Repository.addExtension(bpmDetailsUri, "zip"),
                            )(bpmDetailsUri),
                            onError: (e) => {
                              enqueueSnackbar(
                                t("Unable to download contents"),
                                {
                                  variant: "error",
                                },
                              );
                            },
                          });
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>

                      <IconButton
                        id={`BPM_TABLE_BTN_DELETE_${rowIndex}`}
                        aria-label={t("Delete")}
                        onClick={() => {
                          showDetachDialog();
                          setBpmToDetach(bpmFile);
                        }}
                      >
                        <DeleteIcon color={"error"} />
                      </IconButton>
                    </>
                  )}
                </>
              }
            >
              <TableCell component="th" scope="row" padding="none">
                <Grid
                  id={`BPM_TABLE_CELL_NAME_${rowIndex}`}
                  className={classNames(
                    commonClasses.commonTableCellClickable,
                    classes.tableLink,
                  )}
                  component={Link}
                  to={{
                    pathname: bpmDetailsUri,
                    state: { bpmName: bpmFile.name },
                  }}
                  container
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    className={classNames(
                      commonClasses.commonTablePrimary,
                      commonClasses.overflowAnywhere,
                    )}
                    title={bpmFile.name}
                  >
                    {bpmFile.name}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {displayMonthDayYearTimeDate(bpmFile.dateModified)}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                className={commonClasses.commonTableCellDefault}
              >
                <InstanceCounter counter={bpmFile.totalInstanceCount} />
              </TableCell>
              <Hidden mdDown>
                <TableCell padding="none">
                  {renderStatusGraph(
                    bpmFile.totalInstanceCount,
                    bpmFile.completedInstanceCount,
                    bpmFile.activeInstanceCount,
                    bpmFile.failedInstanceCount,
                  )}
                </TableCell>
              </Hidden>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <Paper
      className={classNames([
        commonClasses.commonPaper,
        commonClasses.commonPaperNoPadding,
      ])}
    >
      <Table>
        <TableHead
          columns={TABLE_COLUMNS}
          filterActions={filterActions}
          filterState={filterState}
          recordsCount={recordsCount}
        />
        {renderTableBody()}
      </Table>
    </Paper>
  );
};

SubtenantOverviewTable.propTypes = {
  bpmDiagramsIsLoading: PropTypes.bool.isRequired,
  bpmDiagramsError: PropTypes.object,
  bpmDiagrams: PropTypes.array,
  filterState: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  recordsCount: PropTypes.number,
  reloadGetBpmDiagrams: PropTypes.func.isRequired,
};

export default SubtenantOverviewTable;
