import translation from "./translation";
import filterMenu from "./filterMenu";
import managedEntity from "./managedEntity";
import variables from "./variables";
import logs from "./logs";

export default {
  filterMenu,
  logs,
  variables,
  managedEntity,
  default: translation,
};
