import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import AceEditor from "react-ace";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ClipboardIcon from "msa2-ui/src/components/ClipboardIcon";
import { useTranslation } from "react-i18next";

import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-smarty";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-plain_text";
import "ace-builds/src-noconflict/mode-xml";

import "ace-builds/src-noconflict/mode-dockerfile";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-groovy";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-ini";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-makefile";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-terraform";
import "ace-builds/src-noconflict/mode-yaml";

import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/keybinding-vscode";
import Dialog from "msa2-ui/src/components/Dialog";

import zipObject from "lodash/zipObject";
import {
  Grid,
  useTheme,
  makeStyles,
  IconButton,
  Tooltip,
} from "@material-ui/core";

export const modes = [
  "dockerfile",
  "golang",
  "groovy",
  "html",
  "ini",
  "json",
  "makefile",
  "markdown",
  "php",
  "python",
  "sh",
  "smarty",
  "sql",
  "terraform",
  "text",
  "xml",
  "yaml",
];

const themes = ["default", "monokai", "chrome"];

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    height: "100%",
  },
  copyToClipboard: {
    position: "absolute",
    top: 8,
    right: 25,
    zIndex: 1,
  },
  editorWrapper: {
    overflowY: "auto",
    height: "100%",
  },
  iconButton: {
    marginTop: 13,
    marginBottom: 13,
    marginRight: 15,
    padding: 0,
  },
  "@global": {
    ".ace_scrollbar-h": {
      display: "block !important",
    },
  },
}));

const getEditorTheme = (theme, darkMode) => {
  const defaultTheme = darkMode ? themes[1] : themes[2];

  if (theme) {
    return themes.includes(theme) ? theme : defaultTheme;
  } else {
    return defaultTheme;
  }
};

const EditorAce = ({
  value,
  mode = "plain_text",
  readOnly = false,
  theme,
  onChange,
  options = {},
  width = "100%",
  height,
  keyboardHandler = "vscode",
  autoScroll,
  showCopyIcon = false,
  name = "Code",
  showFullScreenToggle = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const defaultOptions = {
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: false,
    highlightActiveLine: true,
    highlightSelectedWord: true,
    readOnly,
    showGutter: true,
    useWorker: true,
    useSoftTabs: false,
  };
  const ref = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    if (autoScroll && ref.current) {
      const { editor } = ref.current;
      editor.gotoLine(editor.session.getLength(), 0);
    }
  }, [autoScroll]);
  const { darkMode } = useTheme();
  const editorTheme = getEditorTheme(theme, darkMode);

  const handleClose = () => {
    setIsFullScreen(false);
  };

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"flex-start"}
        className={classes.wrapper}
      >
        {showCopyIcon && (
          <ClipboardIcon
            classes={{ icon: classes.copyToClipboard }}
            content={value}
            item={name}
          />
        )}
        <Grid container alignItems="center" direction="row">
          <Grid item xs={showFullScreenToggle ? 11 : 12}>
            <AceEditor
              ref={ref}
              mode={mode}
              theme={editorTheme}
              value={value}
              readOnly={readOnly}
              setOptions={{ ...defaultOptions, ...options }}
              onChange={onChange}
              width={width}
              height={height}
              fontSize={16}
              keyboardHandler={keyboardHandler}
              onLoad={function(editor) {
                editor.renderer.setScrollMargin(0, 20);
              }}
            />
          </Grid>
          {showFullScreenToggle && (
            <Grid item xs={1}>
              <Tooltip
                title={
                  isFullScreen ? t("Restore Editor") : t("Maximize Editor")
                }
              >
                <IconButton
                  aria-label={
                    isFullScreen
                      ? t("Restore Editor size")
                      : t("Maximize Editor size")
                  }
                  id="TASK_BTN_FULLSCREEN"
                  onClick={() => setIsFullScreen(!isFullScreen)}
                >
                  {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isFullScreen && (
        <Dialog onClose={handleClose} title={t("Edit Text")} maxWidth={"lg"}>
          <Grid item xs={12} className={classes.editorWrapper}>
            <AceEditor
              ref={ref}
              mode={mode}
              theme={editorTheme}
              value={value}
              readOnly={readOnly}
              minLines={30}
              setOptions={{ ...defaultOptions, ...options }}
              onChange={onChange}
              width={width}
              height={isFullScreen ? window.innerHeight - 300 + "px" : "500px"}
              fontSize={16}
              keyboardHandler={keyboardHandler}
            />
          </Grid>
        </Dialog>
      )}
    </>
  );
};

EditorAce.mode = zipObject(modes, modes);
EditorAce.theme = zipObject(themes, themes);

EditorAce.propTypes = {
  value: PropTypes.string,
  mode: PropTypes.oneOf(modes),
  options: PropTypes.object,
  readOnly: PropTypes.bool,
  theme: PropTypes.oneOf(themes),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyboardHandler: PropTypes.string,
  onChange: PropTypes.func,
};

export default EditorAce;
