import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import { buildRoute } from "msa2-ui/src/utils/urls";

import { useSelector } from "react-redux";
import { getIsDeveloper } from "msa2-ui/src/store/auth";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import { getDelegationProfile } from "msa2-ui/src/store/delegationProfiles";

import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";
import SectionTabs from "msa2-ui/src/components/SectionTabs";

import GenerateBpm from "msa2-ui/src/routes/ai/generate-bpm/GenerateBpm";
import AiStates from "msa2-ui/src/routes/ai/states/AiStates";
import AiActions from "msa2-ui/src/routes/ai/actions/AiActions";

export const AiTabs = ({
  currentCount,
  endElements,
  isDeveloper,
  canViewStates,
  canViewAction,
}) => {
  const tabs = [
    {
      path: "generate-bpm",
      displayName: "Generate BPM",
      disabled: !isDeveloper,
    },
    {
      path: "states",
      displayName: "States",
      disabled: !isDeveloper || !canViewStates,
    },
    {
      path: "actions",
      displayName: "Actions",
      disabled: !isDeveloper || !canViewAction,
    },
  ];

  const { url } = useRouteMatch();

  const enabledTabs = tabs.filter((tab) => !tab.disabled);
  const tabsWithCount = enabledTabs.map((tab) => {
    if (tab.path === url) {
      return {
        ...tab,
        count: currentCount,
      };
    }
    return tab;
  });

  return <SectionTabs tabs={tabsWithCount} endElements={endElements} />;
};

const AiRouter = () => {
  const { path } = useRouteMatch();
  const defaultRoute = buildRoute(path, "generate-bpm");
  const isDeveloper = useSelector(getIsDeveloper);
  const canViewStates = useSelector(
    getDelegationProfile(delegationProfileTypes.AI, "state.view"),
  );
  const canViewAction = useSelector(
    getDelegationProfile(delegationProfileTypes.AI, "action.view"),
  );

  const tabs = (props) => (
    <AiTabs
      isDeveloper={isDeveloper}
      canViewStates={canViewStates}
      canViewAction={canViewAction}
      {...props}
    />
  );
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={defaultRoute} />
      </Route>
      <Route
        exact
        path={buildRoute(path, "generate-bpm")}
        render={() => <GenerateBpm isDeveloper={isDeveloper} tabs={tabs} />}
      />
      <AuthorizedRoute
        guard={canViewStates && isDeveloper}
        redirect={defaultRoute}
        path={buildRoute(path, "states")}
      >
        <AiStates tabs={tabs} />
      </AuthorizedRoute>
      <AuthorizedRoute
        guard={canViewAction && isDeveloper}
        redirect={defaultRoute}
        path={buildRoute(path, "actions")}
      >
        <AiActions tabs={tabs} />
      </AuthorizedRoute>
      <Redirect to={defaultRoute} />
    </Switch>
  );
};

export default AiRouter;
