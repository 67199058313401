import React from "react";
import { Grid } from "@material-ui/core";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import { useTranslation } from "react-i18next";
import { required } from "msa2-ui/src/utils/validators";

const CommonSection = () => {
  const { t } = useTranslation();

  return (
    <FormSection title={t("Basic Information")}>
      <Grid item sm={6} md={4}>
        <FieldSelector
          name="name"
          label={t("Profile Name")}
          type="text"
          validate={[required]}
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FieldSelector name="comment" label={t("Comment")} type="text" />
      </Grid>
      <Grid item sm={12} md={4}>
        <FieldSelector
          name="externalReference"
          label={t("External Reference")}
          type="text"
        />
      </Grid>
    </FormSection>
  );
};

export default CommonSection;
