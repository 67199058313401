import React from "react";
import PropTypes from "prop-types";
import { is, getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import SequenceFlowPropertiesPanel from "./SequenceFlowPropertiesPanel";
import ExclusiveGatewayPropertiesPanel from "./ExclusiveGatewayPropertiesPanel";
import SignalEventPropertiesPanel from "./SignalEventPropertiesPanel";
import MessageEventPropertiesPanel from "./MessageEventPropertiesPanel";
import TaskPropertiesPanel from "./TaskPropertiesPanel";

const PropertiesPanel = ({
  modelerState,
  modelerActions,
  moddle,
  workflows = [],
  readOnly,
}) => {
  const { activeElement } = modelerState;

  switch (activeElement?.type) {
    case "bpmn:SequenceFlow":
      // Show Properties Panel only when the node comes after gateway
      if (
        activeElement.businessObject?.sourceRef?.$type !==
        "bpmn:ExclusiveGateway"
      )
        return null;

      return (
        <SequenceFlowPropertiesPanel
          modelerState={modelerState}
          modelerActions={modelerActions}
          moddle={moddle}
          readOnly={readOnly}
        />
      );

    case "bpmn:ExclusiveGateway":
      return (
        <ExclusiveGatewayPropertiesPanel
          modelerState={modelerState}
          modelerActions={modelerActions}
          moddle={moddle}
          readOnly={readOnly}
        />
      );
    case "bpmn:IntermediateCatchEvent": {
      const businessObject = getBusinessObject(activeElement);
      const eventDefinition = businessObject.eventDefinitions[0];
      if (is(eventDefinition, "bpmn:SignalEventDefinition")) {
        return (
          <SignalEventPropertiesPanel
            modelerState={modelerState}
            modelerActions={modelerActions}
            moddle={moddle}
          />
        );
      }
      if (is(eventDefinition, "bpmn:MessageEventDefinition")) {
        return (
          <MessageEventPropertiesPanel
            modelerState={modelerState}
            modelerActions={modelerActions}
            moddle={moddle}
          />
        );
      }
      return null;
    }
    case "bpmn:Task":
    case "bpmn:ServiceTask": {
      return (
        <TaskPropertiesPanel
          modelerState={modelerState}
          modelerActions={modelerActions}
          moddle={moddle}
          workflows={workflows}
          readOnly={readOnly}
        />
      );
    }
    default:
      return null;
  }
};

PropertiesPanel.propTypes = {
  modelerState: PropTypes.object.isRequired,
  modelerActions: PropTypes.object.isRequired,
  moddle: PropTypes.object.isRequired,
  workflows: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ),
  readOnly: PropTypes.bool,
};

export default PropertiesPanel;
