import isEmpty from "lodash/isEmpty";

const FEATURE_LIST = [
  { key: "admin" },
  { key: "alarms" },
  { key: "alarmsAutoClearance" },
  { key: "repository" },
  { key: "licence" },
  { key: "connectionStatus" },
  { key: "topology" },
  { key: "monitoringProfiles" },
  { key: "ai" },
  { key: "msBulkOperation" },
  { key: "msEditVariableInConsole" },
  { key: "profileAuditLogs" },
  { key: "permissionProfiles" },
  { key: "permissionProfileLabels" },
  { key: "enableBPM" },
  { key: "haDisplay" },
  { key: "importWithSameAndUpperRank" },
  { key: "importWithUpperRank" },
  { key: "incidentTicketManagement" },
  {
    key: "trial",
    hideOnDev: true,
  },
  {
    key: "workflowsOwner",
    hideOnDev: true,
  },
  {
    key: "workflowsVisibility",
    hideOnDev: true,
  },
];

const features = FEATURE_LIST.reduce(
  (acc, { key }) => ({ ...acc, [key]: key }),
  {},
);

/**
 * A utility to check if a feature flag is enabled.
 *
 * The values are populated in the index.html when the docker container
 * is started up.
 *
 * In development this function always returns true because we want to be able
 * to develop the feature.
 *
 * Otherwise we check the variables passed in. If they are non boolean or false we
 * return false otherwise we return true.
 *
 * You can use this function like:
 * const adminIsEnabled = FeatureFlag.isEnabled("admin");
 *
 * Then in the JSX you could do something like:
 * { adminIsEnabled && <Admin /> }
 *
 * @param {string} flag - The flag to check which should be available in the exported features variable
 */
const isEnabled = (flag) => {
  const feature = FEATURE_LIST.find(({ key }) => key === flag);
  // 1. 'development'
  // 2. 'test' and windows.MSA.features is not set
  if (
    process.env.NODE_ENV === "development" ||
    (process.env.NODE_ENV === "test" && isEmpty(window.MSA?.features))
  ) {
    // disable features in development as it's disabled in most of environment
    return !feature?.hideOnDev;
  }

  // 1. 'production'
  // 2. 'test' and windows.MSA.features is set
  const value = window.MSA?.features[flag];
  return value === true || value === "true";
};

const getFeatureValueAsArray = (listName) => {
  return window.MSA?.features[listName];
};

export const flags = Object.values(features).reduce(
  (acc, flag) => ({ ...acc, [flag]: isEnabled(flag) }),
  {},
);

export default {
  features,
  isEnabled,
  getFeatureValueAsArray,
  flags,
};
