import { createSlice } from "@reduxjs/toolkit";
import get from "lodash/get";
import { getDelegationProfiles } from "msa2-ui/src/api/delegationProfiles";
import { userRoles } from "msa2-ui/src/store/auth";

export const delegationProfileTypes = {
  MANAGED_ENTITIES: "managedEntities",
  MICROSERVICE: "microservice",
  MONITORING_PROFILES: "monitoringProfiles",
  DEPLOYMENT_SETTINGS: "deploymentSettings",
  LOGS: "logs",
  AUDIT_LOGS: "auditLogs",
  WORKFLOWS: "workflows",
  BPM: "bpm",
  AI: "ai",
  ALARMS: "alarms",
  PROFILE: "profile",
  REPOSITORY: "repository",
};

export const initialState = Object.values(delegationProfileTypes).reduce(
  (acc, key) => ({ ...acc, [key]: {} }),
  {},
);

const delegationProfiles = createSlice({
  name: "delegationProfiles",
  initialState,
  reducers: {
    fetchDelegationProfiles(state, action) {
      Object.values(delegationProfileTypes).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
  extraReducers: {
    // We have to use the string directly here because if
    // we import the auth module we get a circular dependency
    "auth/logout": () => {
      return initialState;
    },
  },
});

export const getDelegationProfile = (type, action) => ({
  delegationProfiles,
  auth,
}) => {
  const role = auth.userDetails.baseRole.id;
  if (role !== userRoles.MANAGER) {
    return true;
  }
  const actionObj = get(delegationProfiles[type], action);
  return Boolean(actionObj?.access);
};

export const getDelegationProfilesList = (typeActionList) => (state) => {
  return typeActionList.map(([type, action]) =>
    getDelegationProfile(type, action)(state),
  );
};

export const fetchDelegationProfiles = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      token,
      userDetails: { acteurId: managerId },
    } = state.auth;

    const [, response] = await getDelegationProfiles({
      token,
      managerId,
    });

    dispatch(delegationProfiles.actions.fetchDelegationProfiles(response));
  };
};

export default delegationProfiles.reducer;
