import React, { useCallback, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useReturnToPreviousPage } from "msa2-ui/src/components/formSection/FormCommon";
import MonitoringProfileForm from "msa2-ui/src/components/monitoring-profile-form";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { createMonitoringProfile } from "msa2-ui/src/api/monitoringProfiles";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { useSnackbar } from "notistack";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";

const MonitoringProfileCreate = () => {
  const t = useBoundedTranslation();
  const token = useSelector(getToken);
  const subtenantId = useSelector(getSelectedSubtenant).id;
  const { url } = useRouteMatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [pending, setPending] = useState(false);

  const returnToOverviewTab = useReturnToPreviousPage(history, url, false);

  const closeHandler = useCallback(() => {
    returnToOverviewTab();
  }, [returnToOverviewTab]);

  const submitHandler = useCallback(
    async (formData) => {
      setPending(true);

      const [error] = await createMonitoringProfile({
        token,
        subtenantId,
        data: formData,
      });

      setPending(false);

      const variant = error ? "error" : "success";
      const message = error
        ? error.getMessage(
            t("Unable to save x", { x: t("Monitoring Profile") }),
          )
        : t("x has been created", { x: t("Monitoring Profile") });

      enqueueSnackbar(message, {
        variant,
      });

      !error && returnToOverviewTab();
    },
    [returnToOverviewTab, subtenantId, enqueueSnackbar, t, token],
  );

  return (
    <MonitoringProfileForm
      submitButtonTitle={t("Create Monitoring Profile")}
      title={t("Create Monitoring Profile")}
      pending={pending}
      onClose={closeHandler}
      onSubmit={submitHandler}
    />
  );
};

export default MonitoringProfileCreate;
