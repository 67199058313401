import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { buildRoute, getParentRoute } from "msa2-ui/src/utils/urls";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { useHistory, Link, useRouteMatch } from "react-router-dom";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useReturnToPreviousPage } from "msa2-ui/src/components/formSection/FormCommon";

import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import {
  deleteProcessInstance,
  deleteHistoricProcessInstance,
} from "msa2-ui/src/api/bpm";

import { Grid, IconButton, TableCell, Typography } from "@material-ui/core";
import {
  DeleteOutlined as DeleteIcon,
  InfoOutlined as InfoIcon,
} from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import TableRow from "msa2-ui/src/components/TableRow";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { getToken } from "msa2-ui/src/store/auth";

const BpmInstance = ({ instanceData, reloadApi }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { url } = useRouteMatch();
  const { id: subtenantId } = useSelector(getSelectedSubtenant);
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const returnToOverviewTab = useReturnToPreviousPage(history, url, false);
  const token = useSelector(getToken);

  const {
    id,
    businessKey,
    processDefinitionId,
    startTime,
    endTime,
    instanceIndex,
  } = instanceData;
  const readPath = buildRoute(
    getParentRoute(url),
    [processDefinitionId, id].join("/"),
  );

  const handleDelete = async () => {
    if (!endTime) await deleteProcessInstance({ id, token });
    const [error] = await deleteHistoricProcessInstance({ id, token });
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage(t("Unable to delete BPM Instance"))
      : t("BPM Instance deleted");

    if (!error) {
      reloadApi();
      showDeleteDialog(false);
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  useEffect(() => {
    if (!subtenantId) {
      returnToOverviewTab();
    }
  }, [returnToOverviewTab, subtenantId]);

  return (
    <>
      <DeleteDialog
        title={t("Delete BPM Instance")}
        content={`${t("Are you sure you want to delete x?", {
          x: id,
        })}`}
        onExec={handleDelete}
      />
      <TableRow
        key={id}
        actions={
          <>
            <Link id={`BPM_INSTANCE_INFO_${instanceIndex}`} to={readPath}>
              <IconButton>
                <InfoIcon color="secondary" />
              </IconButton>
            </Link>
            <DelegationProfiles
              type={delegationProfileTypes.BPM}
              action="instance.delete"
            >
              <IconButton
                id={`BPM_INSTANCE_DELETE_${instanceIndex}`}
                onClick={() => showDeleteDialog()}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </DelegationProfiles>
          </>
        }
      >
        <TableCell component="th" scope="row" padding="none">
          <Grid
            id={`BPM_INSTANCE_ID_${instanceIndex}`}
            className={commonClasses.commonTableLink}
            component={Link}
            to={readPath}
            container
            alignItems="center"
          >
            <Typography className={commonClasses.commonTablePrimary}>
              {businessKey || id}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell className={commonClasses.commonTableCellDefault}>
          <Typography className={commonClasses.commonTableSecondary}>
            {displayMonthDayYearTimeDate(startTime)}
          </Typography>
        </TableCell>
        <TableCell className={commonClasses.commonTableCellDefault}>
          <Typography className={commonClasses.commonTableSecondary}>
            {displayMonthDayYearTimeDate(endTime)}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default BpmInstance;
