import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import getFormValues from "redux-form/lib/getFormValues";
import { reduxFormNames } from "msa2-ui/src/Constants";
import { Button, Grid, IconButton, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import { required } from "msa2-ui/src/utils/validators";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormColorPicker from "msa2-ui/src/components/formSection/FormColorPicker";
import { formArrayTypes } from "./constants";

const DEFAULT_AXIS_COLOR = "#FFFFFF";

const useStyles = makeStyles(() => ({
  row: {
    position: "relative",
    paddingTop: 45,

    "&:hover > .deleteIconHover": {
      opacity: 1,
    },
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    transition: ".2s ease",
  },
}));

const formValuesSelector = getFormValues(reduxFormNames.monitoringProfileForm);

const DataList = ({ fields }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const snmpValues =
    useSelector((state) => {
      return (formValuesSelector(state) || {})[formArrayTypes.snmp];
    }, shallowEqual) || [];

  const rowsValues = fields.getAll() || [];

  const isDisabled = (rowId, snmpId) => {
    const currentRow = rowsValues[rowId] || {};

    if (currentRow.snmpPollingId === snmpId) return false;

    return Boolean(rowsValues.find((value) => value.snmpPollingId === snmpId));
  };

  return (
    <Grid container>
      <Grid item md={1}>
        <Button
          disabled={!snmpValues.length}
          onClick={() => fields.push({ color: DEFAULT_AXIS_COLOR })}
        >
          <AddIcon color={snmpValues.length ? "primary" : "disabled"} />
        </Button>
      </Grid>
      <Grid item md={11}>
        {fields.map((field, rowId) => (
          <div className={classes.row} key={field} data-testid="graph-axis">
            <div className={classNames(classes.deleteIcon, "deleteIconHover")}>
              <IconButton onClick={() => fields.remove(rowId)}>
                <IconDeleteRed />
              </IconButton>
            </div>
            <Grid key={field} container spacing={4}>
              <Grid item sm={12} md={5}>
                <FormSelect
                  name={`${field}.snmpPollingId`}
                  label={t("Data Name")}
                  validate={required}
                >
                  {snmpValues.map(({ id, name }, snmpId) => (
                    <MenuItem
                      key={snmpId}
                      value={snmpId}
                      disabled={isDisabled(rowId, snmpId)}
                    >
                      {name || `No name ${snmpId}`}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>
              <Grid item sm={12} md={5}>
                <FieldSelector
                  name={`${field}.horizontalLabel`}
                  label={t("x Axis", { x: "X" })}
                  type="text"
                  validate={required}
                  noIndent
                />
              </Grid>
              <Grid item sm={12} md={2}>
                <FormColorPicker name={`${field}.color`} label={t("Color")} />
              </Grid>
            </Grid>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default memo(DataList);
