import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getFormValues } from "msa2-ui/src/store/form";
import { useSelector } from "react-redux";

import { Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";

export const useStyles = makeStyles(({ palette }) => ({
  divider: {
    backgroundColor: palette.text.hint,
    marginBottom: 10,
  },
}));

const ModalSidebar = ({ activeTab, handleOnClick, form }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const variables =
    useSelector(
      getFormValues(form, MicroserviceForm.formConfig.variablePath),
    ) ?? [];

  return (
    <Grid item xs={3} md={2}>
      <Sidebar>
        {Object.values(MicroserviceForm.sidebarTabs).map((tab) => {
          const isVariablesTab = tab === "Variables";
          const commandName = tab.toUpperCase();

          return (
            <Fragment key={tab}>
              <Sidebar.ListItem
                title={t(tab)}
                id={`MICROSERVICE_${commandName}_SIDEBAR_TAB`}
                selected={activeTab === tab}
                section={tab}
                onClick={handleOnClick}
                count={isVariablesTab ? variables.length : ""}
              />
              {isVariablesTab && <Divider className={classes.divider} />}
            </Fragment>
          );
        })}
      </Sidebar>
    </Grid>
  );
};

ModalSidebar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default ModalSidebar;
