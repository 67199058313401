import { ReactComponent as NetworkDarkIcon } from "msa2-ui/src/assets/icons/entities/network.svg";
import { ReactComponent as PhysicalEntityDarkIcon } from "msa2-ui/src/assets/icons/entities/physicalEntity.svg";
import { ReactComponent as PrivateEntityDarkIcon } from "msa2-ui/src/assets/icons/entities/privateEntity.svg";
import { ReactComponent as PublicEntityDarkIcon } from "msa2-ui/src/assets/icons/entities/publicEntity.svg";
import { ReactComponent as NetworkLightIcon } from "msa2-ui/src/assets/icons/entities/networkLight.svg";
import { ReactComponent as PhysicalEntityLightIcon } from "msa2-ui/src/assets/icons/entities/physicalEntityLight.svg";
import { ReactComponent as PrivateEntityLightIcon } from "msa2-ui/src/assets/icons/entities/privateEntityLight.svg";
import { ReactComponent as PublicEntityLightIcon } from "msa2-ui/src/assets/icons/entities/publicEntityLight.svg";
import { nodeTypes, statusTypes } from "./constants";

export const NETWORK_COLOR = "#0059A7";

export const typeToIconsMapDark = {
  [nodeTypes.NETWORK]: NetworkDarkIcon,
  [nodeTypes.PHSL]: PhysicalEntityDarkIcon,
  [nodeTypes.VPRV]: PrivateEntityDarkIcon,
  [nodeTypes.VPUB]: PublicEntityDarkIcon,
};

export const typeToIconsMapLight = {
  [nodeTypes.NETWORK]: NetworkLightIcon,
  [nodeTypes.PHSL]: PhysicalEntityLightIcon,
  [nodeTypes.VPRV]: PrivateEntityLightIcon,
  [nodeTypes.VPUB]: PublicEntityLightIcon,
};

export const statusToColorMap = {
  [statusTypes.OK]: "#26BD6F",
  [statusTypes.ERROR]: "#DB2E14",
  [statusTypes.CRITICAL]: "#F5B622",
  [statusTypes.NEVERREACHED]: "#B1BBCC",
  [statusTypes.UNKNOWN_STATUS]: "#fff",
};

export const getIconComponent = ({ type, darkMode }) => {
  return darkMode ? typeToIconsMapDark[type] : typeToIconsMapLight[type];
};
