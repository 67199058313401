import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "msa2-ui/src/components/menu";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { useHistory, useRouteMatch } from "react-router-dom";
import { buildRoute } from "msa2-ui/src/utils/urls";

const EditAction = ({ node }) => {
  const t = useBoundedTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const clickHandler = () =>
    history.push(buildRoute(path, `${node.__metadata__.objectId}/edit`));

  return (
    <MenuItem onClick={clickHandler} id="EDIT_ACTION">
      {t("Edit")}
    </MenuItem>
  );
};

EditAction.propTypes = {
  node: PropTypes.object.isRequired,
};

export default EditAction;
