import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useToggle from "react-use/lib/useToggle";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles((theme) => ({
  textButton: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.colors.blueDark1,
  },
}));

const ParserShowHide = ({ label, extraButtons, children }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { t } = useTranslation();

  const [show, toggleShow] = useToggle(true);

  const Spacer = () => <span className={classes.commonSpacer}>{"|"}</span>;

  return (
    <>
      <Grid
        item
        xs={12}
        md={7}
        container
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Typography variant="body1" className={commonClasses.commonDescription}>
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        {extraButtons}
        {extraButtons && <Spacer />}
        <Button onClick={toggleShow}>
          <Typography variant={"body1"} className={classes.textButton}>
            {show ? t("Hide") : t("Show")}
          </Typography>
        </Button>
      </Grid>
      {show && children}
    </>
  );
};

ParserShowHide.propTypes = {
  label: PropTypes.string.isRequired,
  extraButtons: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default ParserShowHide;
