import { get, post, destroy, put } from "./request";
import { sortOrder } from "msa2-ui/src/Constants";
import produce from "immer";

const API = process.env.REACT_APP_API_PATH;

const generateUbiqubeId = (id, operatorPrefix) => operatorPrefix + "A" + id;

const restructureResponse = (response) =>
  response.map(({ actorId, id, operatorPrefix, name, ...rest }) => ({
    value: actorId,
    id: id,
    ubiqubeId: generateUbiqubeId(id, operatorPrefix),
    operatorPrefix,
    label: name,
    ...rest,
  }));

/*
 * Spec: https://10.31.1.52/swagger/index.html#/User/listCustomerByManagerId
 */
export const getSubtenantsByManager = ({
  token,
  managerId,
  transforms = [restructureResponse],
}) => {
  return get({
    url: `${API}/user/customer-by-manager-id/${managerId}`,
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/index.html#/Lookup/listCustomerByOperatorPrefix
 */
export const getSubtenantsByTenant = ({
  token,
  tenantPrefix,
  transforms = [restructureResponse],
}) => {
  return get({
    url: `${API}/lookup/v1/customer-by-operator-prefix/${tenantPrefix}`,
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Lookup/listCustomers
 */
export const getSubtenants = ({
  token,
  filter = "name",
  searchValue,
  sortByValue,
  sortOrderValue,
  tpPage,
  tpRowsPerPage,
}) => {
  return get({
    url: `${API}/lookup/v1/customers`,
    token,
    queryParams: {
      filter,
      filter_criteria: searchValue,
      sort: sortByValue,
      sort_order: sortOrder[sortOrderValue]?.param,
      page: tpPage + 1,
      page_size: tpRowsPerPage,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Customer%20API/createCustomer_1
 */
export const createSubtenant = ({ token, body }) => {
  return post({
    url: `${API}/customer/v1`,
    token,
    body,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Customer%20API/updateCustomer_1
 */
export const editSubtenant = ({
  token,
  subtenant,
  name,
  email,
  operatorPrefix,
  externalReference,
  id,
}) => {
  let updatedSubtenant = subtenant;
  if (subtenant.contacts !== undefined && subtenant.contacts !== null) {
    updatedSubtenant = produce(subtenant, (draft) => {
      draft.contacts[0].address.email = email;
    });
  } else if (
    (subtenant.contacts === undefined || subtenant.contacts === null) &&
    email !== null
  ) {
    updatedSubtenant = {
      ...subtenant,
      contacts: [
        {
          address: { email },
        },
      ],
    };
  }

  return put({
    url: `${API}/customer/v1/${id}`,
    token,
    body: {
      ...updatedSubtenant,
      name,
      operatorPrefix,
      externalReference,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Customer%20API/deleteCustomerById
 */

export const deleteSubtenant = ({ token, id }) => {
  return destroy({
    url: `${API}/customer/id/${id}`,
    token,
  });
};
