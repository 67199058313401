import format from "date-fns/format";
import { get, post } from "msa2-ui/src/api/request";

const API = process.env.REACT_APP_API_PATH;

export const getMsaVars = ({
  isRootUser,
  token,
  vars = [],
  transforms = [],
}) => {
  return isRootUser
    ? get({
        url: `${API}/system-admin/v1/msa_vars`,
        token,
        queryParams: {
          name: vars,
        },
        transforms,
      })
    : [];
};

/*
 * Spec: https://10.31.1.52/swagger/#/Administration/updateMsaVars
 */
export const updateMsaVars = ({ isRootUser, token, vars }) => {
  return isRootUser
    ? post({
        url: `${API}/system-admin/v1/msa_vars`,
        token,
        body: vars.map(
          ({ name, value = "", comment = "", lastUpdate = new Date() }) => ({
            name,
            value,
            comment,
            lastUpdate: format(lastUpdate, "t"),
          }),
        ),
      })
    : [];
};

/*
 * Spec: https://10.31.1.52/swagger/#/Administration/getMsaVarsDefinition
 */
export const getMsaVarsDefinition = ({ isRootUser, token }) => {
  return isRootUser
    ? get({
        url: `${API}/system-admin/v1/msa_vars_definition`,
        token,
      })
    : [];
};

export const getMsaVarsAllUsers = ({ token, vars = [], transforms = [] }) => {
  return get({
    url: `${API}/system-admin/v1/msa_vars`,
    token,
    queryParams: {
      name: vars,
    },
    transforms,
  });
};
