import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles/index";
import {
  TextField,
  FormControl,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";
import { mergeStyles } from "msa2-ui/src/utils/styles";

import PasswordInput from "msa2-ui/src/components/PasswordInput";

const localStyles = (theme) => ({
  contentWrapper: {
    padding: 20,
    lineHeight: 1.5,
    letterSpacing: 0.25,
    textAlign: "center",
  },
  dialogContent: {
    fontSize: 16,
    color: theme.colors.greyDark1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 250,
  },
});

const styles = mergeStyles([commonStyles, localStyles]);

class InitialProvisioningInfoTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showAdminPassword: false,
    };
  }

  render() {
    const {
      isLoading,
      ipAddress,
      userName,
      rootPwd,
      userPwd,
      classes,
      handleOnChangeTextField,
      t,
    } = this.props;

    return (
      <Grid item md={12} className={classes.commonFlexColumnCenter}>
        {isLoading ? (
          <div className={classes.loaderWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <FormControl style={{ width: "50%" }}>
            <TextField
              id={"ip"}
              name={"ip"}
              label={t("Managed Entity Management IP Address")}
              value={ipAddress}
              autoComplete={"new-password"}
              variant={"outlined"}
              margin={"normal"}
              onChange={handleOnChangeTextField}
              disabled
            />

            <TextField
              id={"login"}
              name={"login"}
              label={t("User Name")}
              value={userName}
              variant={"outlined"}
              margin={"normal"}
              autoComplete={"new-password"}
              onChange={handleOnChangeTextField}
              disabled
            />
            {Boolean(rootPwd) && (
              <PasswordInput
                id={"adminPwd"}
                name={"adminPwd"}
                label={t("Admin Password")}
                value={rootPwd}
                variant={"outlined"}
                margin={"normal"}
                autoComplete={"new-password"}
                onChange={handleOnChangeTextField}
                disabled
              />
            )}
            <PasswordInput
              id={"pwd"}
              name={"pwd"}
              label={t("User Password")}
              value={userPwd}
              variant={"outlined"}
              margin={"normal"}
              autoComplete={"new-password"}
              onChange={handleOnChangeTextField}
              disabled
            />
          </FormControl>
        )}
      </Grid>
    );
  }
}

InitialProvisioningInfoTab.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  ipAddress: PropTypes.string,
  userName: PropTypes.string,
  rootPwd: PropTypes.string,
  userPwd: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleOnChangeTextField: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withTranslation()(InitialProvisioningInfoTab),
);
