import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import reduxForm from "redux-form/lib/reduxForm";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import flow from "lodash/flow";
import union from "lodash/union";
import difference from "lodash/difference";

import useApi from "msa2-ui/src/hooks/useApi";
import { getFormValues } from "msa2-ui/src/store/form";
import { getMicroservices } from "msa2-ui/src/api/microservices";

import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  filterWrapper: {
    marginBottom: 20,
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
  },
}));

const MicroservicesTab = ({ change, form, submitting }) => {
  const { t } = useTranslation();
  const idKey = "uri";
  const displayNameKey = "displayName";
  const displayNameTooltipKey = "uri";
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const attachedMicroserviceUris =
    useSelector(getFormValues(form, "microserviceUris")) || [];
  const vendorId = useSelector(getFormValues(form, "vendor.id"));
  const modelId = useSelector(getFormValues(form, "model.id"));

  const [, , microservices] = useApi(getMicroservices, {
    vendorId,
    modelId,
    pageSize: 10000,
    filterByLabel: isPermissionProfileLabelsEnabled,
  });

  const handleAttachAll = (target) => {
    change(["microserviceUris"], union(attachedMicroserviceUris, target));
  };
  const handleAttach = (target) => {
    change(["microserviceUris"], union(attachedMicroserviceUris, target));
  };
  const handleDetach = (target) => {
    change(["microserviceUris"], difference(attachedMicroserviceUris, target));
  };
  const handleDetachAll = (target) => {
    change(["microserviceUris"], difference(attachedMicroserviceUris, target));
  };

  return (
    <div className={classes.contentWrapper}>
      <Grid container>
        <Grid item xs={12}>
          {!microservices || submitting ? (
            <div
              className={classnames(
                classes.itemWrapper,
                commonClasses.commonFlexCenter,
              )}
            >
              <CircularProgress />
            </div>
          ) : (
            <AttachmentBoard
              allItems={microservices}
              noDataMessage={t(
                "There are no Microservices associated with this Vendor and Model",
              )}
              idKey={idKey}
              displayNameKey={displayNameKey}
              displayNameTooltipKey={displayNameTooltipKey}
              attachedItemIds={attachedMicroserviceUris}
              unattachedTitle={t("Select the Microservices to add") + ":"}
              attachedTitle={t("Microservices added") + ": "}
              searchPlaceholder={t("Search for a Microservice")}
              handleAttachAll={handleAttachAll}
              handleAttach={handleAttach}
              handleDetach={handleDetach}
              handleDetachAll={handleDetachAll}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

MicroservicesTab.propTypes = {
  form: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default flow(reduxForm())(MicroservicesTab);
