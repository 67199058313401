import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import useDialog from "msa2-ui/src/hooks/useDialog";

import { getToken } from "msa2-ui/src/store/auth";
import { deleteAdministrator } from "msa2-ui/src/api/administrator";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";

import {
  Grid,
  IconButton,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";

import TableRow from "msa2-ui/src/components/TableRow";
import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const AdministratorTableBody = ({
  loading,
  administrators,
  error,
  numberOfTableColumns,
  reloadAdministrators,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const token = useSelector(getToken);
  const { url } = useRouteMatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [selectedRow, setSelectedRow] = useState();

  const handleDelete = async () => {
    const [error] = await deleteAdministrator({
      token,
      id: selectedRow.id,
    });
    const variant = error ? "error" : "success";
    const message = error
      ? `${t("Unable to delete")} ${t("Administrator")}`
      : `${t("Administrator")} ${t("deleted")} `;

    if (!error) {
      showDeleteDialog(false);
      reloadAdministrators();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  if (loading) {
    return <TableLoading numberOfTableColumns={numberOfTableColumns} />;
  }

  if (administrators.length === 0) {
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching x", { x: t("Administrators") })}. ${t(
                "Please reload the page",
              )}.`
            : t("No Administrators found")
        }
        numberOfTableColumns={numberOfTableColumns}
      />
    );
  }

  return (
    <>
      <DeleteDialog
        title={t("Delete Administrator")}
        content={`${t("Are you sure you want to delete x?", {
          x: selectedRow?.name,
        })}`}
        onExec={handleDelete}
      />
      <TableBody>
        {administrators.map((administrator, index) => {
          return (
            <TableRow
              id={`ADMINISTRATOR_TABLE_ROW_${index}`}
              key={administrator.id}
              actions={
                <>
                  <IconButton
                    id={`ADMINISTRATOR_TABLE_BTN_EDIT_${index}`}
                    aria-label={t("Edit")}
                    component={Link}
                    to={{
                      pathname: `${url}/${administrator.id}/edit`,
                    }}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton
                    id={`ADMINISTRATOR_TABLE_BTN_DELETE_${index}`}
                    aria-label={t("Delete")}
                    onClick={() => {
                      setSelectedRow(administrator);
                      showDeleteDialog(true);
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </>
              }
            >
              <TableCell component="th" scope="row" padding="none">
                <Grid
                  id={`ADMINISTRATOR_TABLE_CELL_NAME_${index}`}
                  className={classNames(commonClasses.commonTableCellClickable)}
                  container
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    className={commonClasses.commonTablePrimary}
                  >
                    {administrator.name}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {administrator.address.email}
                </Typography>
              </TableCell>
              <TableCell className={commonClasses.commonTableCellDefault}>
                <Typography
                  variant="body1"
                  className={commonClasses.commonTableSecondary}
                >
                  {displayMonthDayYearTimeDate(administrator.lastUpdated)}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

AdministratorTableBody.propTypes = {
  loading: PropTypes.bool,
  administrators: PropTypes.array.isRequired,
  error: PropTypes.object,
  numberOfTableColumns: PropTypes.number.isRequired,
  reloadAdministrators: PropTypes.func.isRequired,
};

export default AdministratorTableBody;
