import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import useToggle from "react-use/lib/useToggle";

import {
  Button,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  Input,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Sort, ViewList, ViewModule, Search } from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import TablePagination from "msa2-ui/src/components/TablePagination";

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints, typography, darkMode } = theme;

  return {
    menuItem: {
      [breakpoints.up("md")]: {
        width: "auto",
        paddingRight: 15,
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
    searchInputRoot: {
      marginRight: 5,
      width: 180,
    },
    searchIcon: {
      margin: "5px 5px 5px -5px",
    },
    filterTabs: {
      minHeight: "auto",
    },
    tabRoot: {
      minWidth: "auto",
      minHeight: "auto",
      padding: "3px 10px",
      fontSize: "0.8125rem",
      background: darkMode ? "transparent" : "#e9eaec",
      color: darkMode ? palette.text.secondary : "#8995ab",
      border: `1px solid ${darkMode ? palette.text.secondary : "#8995ab"}`,
      textTransform: "capitalize",
    },
    tabIndicator: {
      backgroundColor: "transparent",
      border: "none",
    },
    filterTab: {
      "&:first-child": {
        borderRadius: "56px 0 0 56px",
      },
      "&:last-child": {
        borderRadius: "0 56px 56px 0",
      },
    },
    tabSelected: {
      background: darkMode ? "transparent" : "#fff",
      color: palette.text.primary,
      border: "1px solid" + palette.text.primary,
    },
    filterLabel: {
      fontSize: "0.8125rem",
      fontWeight: typography.fontWeightLight,
      lineHeight: "normal",
      letterSpacing: 0.3,
      color: palette.text.secondary,
      marginRight: 10,
    },
    selectInput: {
      fontSize: "0.8125rem",
      lineHeight: "1.2rem",
      padding: "0px 38px 2px 10px",
      textTransform: "capitalize",
    },
    sortIcon: {
      cursor: "pointer",
      transition: "100ms",
    },
    select: {
      borderRadius: 16,
      padding: 6,
      marginRight: 10,
      backgroundColor: "transparent",
      border: "1px solid #d5d7da",
      "&:hover, &:focus, &:active": {
        backgroundColor: "transparent",
      },
    },
    itemRight: {
      marginLeft: "auto",
    },
  };
});

const FilterMenu = ({
  className,
  handleViewAsChange,
  viewAsIcons,
  handleViewByChange,
  viewByTitle,
  viewByOptions,
  viewByValue,
  handleFilterByChange,
  filterByOptions,
  filterByValue,
  handleSortByChange,
  sortByOptions,
  sortByValue,
  handleSortOrder,
  sortOrderValue = false,
  handleGroupByChange,
  groupByOptions,
  groupByValue,
  handleViewChange,
  viewOptions,
  viewValue,
  handleSearchByChange,
  handleToggleSearch,
  searchValue,
  tpRowsPerPage,
  tpRowsPerPageOptions,
  tpTotal,
  tpPage,
  tpChangePage,
  tpChangeRowsPerPage,
  buttonText,
  buttonOnClick,
  disabledTenantTab,
  ActionLeft,
  ActionRight,
  paginationDisabled = false,
  searchDisabled = false,
}) => {
  const { t } = useTranslation("filterMenu");
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [showSearchInput, toggleSearchInput] = useToggle(false);
  const [sortOrder, toggleSortOrder] = useToggle(sortOrderValue);
  const [viewAs, setViewAs] = useState(0);

  const showViewAs = Boolean(handleViewAsChange);
  const showViewBy = Boolean(handleViewByChange && viewByOptions);
  const showSortBy = Boolean(handleSortByChange && sortByOptions);
  const showFilterBy = Boolean(handleFilterByChange && filterByOptions);
  const showGroupBy = Boolean(handleGroupByChange && groupByOptions);
  const showViewChange = Boolean(handleViewChange && viewOptions);
  const showSearchIcon = !searchDisabled && Boolean(handleSearchByChange);
  const showPagination = !paginationDisabled && Boolean(tpRowsPerPage);
  const showButton = Boolean(buttonText);

  const renderOptions = (options) =>
    options.map(({ id, text }) => (
      <option key={id} value={id}>
        {t(text)}
      </option>
    ));

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        className={className}
      >
        {/* Dynamic Components */}
        {ActionLeft && (
          <Grid item xs="auto" className={classes.menuItem}>
            <ActionLeft />
          </Grid>
        )}
        {/* View as */}
        {showViewAs && (
          <Grid item xs={2} md="auto" className={classes.menuItem}>
            <Tabs
              value={viewAs}
              onChange={(event, value) => {
                setViewAs(value);
                handleViewAsChange(event, value);
              }}
              className={classes.filterTabs}
              classes={{
                indicator: classes.tabIndicator,
              }}
              role="tablist"
            >
              <Tab
                id="FILTER_MENU_TAB_0"
                icon={viewAsIcons ? viewAsIcons[0] : <ViewModule />}
                className={classes.filterTab}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                aria-label={t("View as grid")}
                role="tab"
              />
              <Tab
                id="FILTER_MENU_TAB_1"
                icon={viewAsIcons ? viewAsIcons[1] : <ViewList />}
                className={classes.filterTab}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                aria-label={t("View as table")}
                role="tab"
              />
              {viewAsIcons && viewAsIcons[2] && (
                <Tab
                  id="TOPOLOGY_VIEW_TAB"
                  icon={viewAsIcons[2]}
                  className={classes.filterTab}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  aria-label={t("View as graph")}
                  role="tab"
                />
              )}
            </Tabs>
          </Grid>
        )}
        {/* View by */}
        {showViewBy && (
          <Grid
            item
            xs={5}
            container
            alignItems="center"
            md="auto"
            className={classes.menuItem}
          >
            <Typography className={classes.filterLabel}>
              {viewByTitle || t("View by") + ":"}
            </Typography>
            <Tabs
              value={viewByValue}
              onChange={handleViewByChange}
              className={classes.filterTabs}
              classes={{
                indicator: classes.tabIndicator,
              }}
              role="tablist"
            >
              {viewByOptions.map((tab) => {
                return (
                  <Tab
                    key={tab.id}
                    label={tab.text}
                    className={classes.filterTab}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    disabled={disabledTenantTab}
                    value={tab.id}
                    role="tab"
                  />
                );
              })}
            </Tabs>
          </Grid>
        )}

        {/* Sort by */}
        {showSortBy && (
          <Grid
            item
            xs={5}
            container
            alignItems="center"
            md="auto"
            className={classes.menuItem}
          >
            <Typography className={classes.filterLabel}>
              {t("Sort by") + ":"}
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                value={sortByValue}
                onChange={handleSortByChange}
                className={classes.select}
                classes={{
                  select: classes.selectInput,
                }}
                inputProps={{
                  "aria-label": t("Sort by") + ":",
                }}
                input={<FilledInput disableUnderline />}
              >
                {renderOptions(sortByOptions)}
              </Select>
            </FormControl>

            {handleSortOrder && (
              <IconButton
                onClick={(event) => {
                  toggleSortOrder();
                  handleSortOrder(event, !sortOrder);
                }}
                className={classes.sortIcon}
                style={{
                  transform: "rotate(" + 180 * sortOrder + "deg)",
                }}
                aria-label={t("Reverse Sort Order")}
              >
                <Sort />
              </IconButton>
            )}
          </Grid>
        )}

        {/* Filter by */}
        {showFilterBy && (
          <Grid
            item
            xs={5}
            container
            alignItems="center"
            md="auto"
            className={classes.menuItem}
          >
            <Typography className={classes.filterLabel}>
              {t("Filter by") + ":"}
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                value={filterByValue}
                onChange={handleFilterByChange}
                className={classes.select}
                classes={{
                  select: classes.selectInput,
                }}
                inputProps={{
                  "aria-label": t("Filter by") + ":",
                }}
                input={<FilledInput disableUnderline />}
              >
                {renderOptions(filterByOptions)}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Group by  if the handle exists and the options exist show the component */}
        {showGroupBy && (
          <Grid
            item
            xs={4}
            container
            alignItems="center"
            md="auto"
            className={classes.menuItem}
          >
            <Typography className={classes.filterLabel}>
              {t("Group by") + ":"}
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                value={groupByValue}
                onChange={handleGroupByChange}
                className={classes.select}
                classes={{
                  select: classes.selectInput,
                }}
                inputProps={{
                  "aria-label": t("Group by") + ":",
                }}
                input={<FilledInput disableUnderline />}
              >
                {renderOptions(groupByOptions)}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* View - customisable filter */}
        {showViewChange && (
          <Grid item xs={6} md="auto" className={classes.menuItem}>
            <Typography className={classes.filterLabel}>
              {t("View") + ":"}
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                value={viewValue}
                onChange={handleViewChange}
                className={classes.select}
                classes={{
                  select: classes.selectInput,
                }}
                inputProps={{
                  "aria-label": t("View") + ":",
                }}
                input={<FilledInput disableUnderline />}
              >
                {renderOptions(viewOptions)}
              </Select>
            </FormControl>
          </Grid>
        )}

        {showSearchIcon && (
          <Grid item xs={6} md="auto" className={classes.menuItem}>
            {showSearchInput && (
              <Input
                value={searchValue}
                onChange={({ target: { value } }) => {
                  handleSearchByChange(value);
                }}
                className={classes.searchInputRoot}
                classes={{
                  input: commonClasses.commonSearchInput,
                }}
                inputProps={{
                  "aria-label": "Filter by",
                  placeholder: "Filter by...",
                }}
                autoFocus // eslint-disable-line jsx-a11y/no-autofocus
              />
            )}
            <IconButton
              aria-label={t("Filter results")}
              onClick={() => {
                toggleSearchInput();
                if (handleToggleSearch) {
                  handleToggleSearch();
                }
              }}
              className={classes.searchIcon}
            >
              <Search />
            </IconButton>
          </Grid>
        )}

        {/* Button field */}
        {showButton && (
          <Grid item xs={6} md="auto" className={classes.menuItem}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classnames(
                commonClasses.commonBtn,
                commonClasses.commonBtnPrimary,
              )}
              onClick={buttonOnClick}
            >
              {buttonText}
            </Button>
          </Grid>
        )}
        <Grid
          item
          xs="auto"
          className={classnames(classes.menuItem, classes.itemRight)}
          container
        >
          {/* Table Pagination */}
          {showPagination && (
            <Grid item xs="auto">
              <TablePagination
                rowsPerPage={tpRowsPerPage}
                count={tpTotal}
                page={tpPage}
                onChangePage={tpChangePage}
                onChangeRowsPerPage={tpChangeRowsPerPage}
                rowsPerPageOptions={tpRowsPerPageOptions}
              />
            </Grid>
          )}
          {/* Dynamic Components */}
          {ActionRight && (
            <Grid item xs="auto">
              <ActionRight />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

FilterMenu.propTypes = {
  className: PropTypes.string,
  handleViewAsChange: PropTypes.func,
  viewAsIcons: PropTypes.arrayOf(PropTypes.element),
  handleViewByChange: PropTypes.func,
  viewByTitle: PropTypes.string,
  viewByOptions: PropTypes.array,
  viewByValue: PropTypes.string,
  handleSortByChange: PropTypes.func,
  sortByOptions: PropTypes.array,
  sortByValue: PropTypes.string,
  handleSortOrder: PropTypes.func,
  sortOrder: PropTypes.bool,
  handleGroupByChange: PropTypes.func,
  groupByOptions: PropTypes.array,
  groupByValue: PropTypes.string,
  handleViewChange: PropTypes.func,
  viewOptions: PropTypes.array,
  viewValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSearchByChange: PropTypes.func,
  searchValue: PropTypes.string,
  tpRowsPerPage: PropTypes.number,
  tpRowsPerPageOptions: PropTypes.array,
  tpTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tpPage: PropTypes.number,
  tpChangePage: PropTypes.func,
  tpChangeRowsPerPage: PropTypes.func,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  disabledTenantTab: PropTypes.bool,
  ActionLeft: PropTypes.func,
  ActionRight: PropTypes.func,
  paginationDisabled: PropTypes.bool,
};

export default FilterMenu;
