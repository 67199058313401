import { DAYS, MONTHS, PERIODICITY_VALUES } from "./constants";
import isString from "lodash/isString";

export const weekdayStringToDayObject = (weekdayString) => {
  if (isString(weekdayString)) {
    const weekdayArray = weekdayString.split("");
    const weekdays = {};

    for (const [, value] of Object.entries(DAYS)) {
      weekdays[value.name] = Number(weekdayArray[value.index]);
    }

    return weekdays;
  } else {
    return weekdayString;
  }
};

export const weekdayObjectToString = (wd) => {
  return Object.values(wd).join("");
};

export const monthStringToMonthObject = (monthString) => {
  if (isString(monthString)) {
    const monthArray = monthString.split("");
    const months = {};

    for (const [, value] of Object.entries(MONTHS)) {
      months[value.name] = Number(monthArray[value.index]);
    }

    return months;
  } else {
    return monthString;
  }
};

/**
 * https://en.wikipedia.org/wiki/ISO_8601#Durations
 */
export const parseDuration = (duration) => {
  const periodicity = PERIODICITY_VALUES.find(
    ({ durationDesignator, designator }) => {
      const regexp = new RegExp(
        `^${durationDesignator}[0-9]+${designator}`,
        "g",
      );

      return durationDesignator && designator && duration.match(regexp);
    },
  );
  const { durationDesignator, designator } = periodicity;
  const value = duration
    .replace(durationDesignator, "")
    .replace(designator, "");
  return { ...periodicity, periodUnit: periodicity.value, value };
};
