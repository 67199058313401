import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";

import { query as domQuery } from "min-dom";
import {
  append as svgAppend,
  attr as svgAttr,
  create as svgCreate,
  remove as svgRemove,
} from "tiny-svg";

import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";

const HIGH_PRIORITY = 1500;

export default class CustomRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer, canvas) {
    super(eventBus, HIGH_PRIORITY);
    this.eventBus = eventBus;
    this.bpmnRenderer = bpmnRenderer;
    this.canvas = canvas;
    // add a new filter in "def" the tag for bpmn modeler
    addShadowFilter(this.eventBus, this.canvas);
  }

  canRender(element) {
    // only render tasks can go through below functions
    return isAny(element, ["bpmn:Task"]) && !element.labelTarget;
  }

  drawShape(parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element);
    const rect = drawRect(parentNode, 100, 80, 10, "#46daff");

    prependTo(rect, parentNode);
    svgRemove(shape);

    return shape;
  }
}

CustomRenderer.$inject = ["eventBus", "bpmnRenderer", "canvas"];

// helpers //////////

// copied from node_modules/bpmn-js/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const rect = svgCreate("rect");

  svgAttr(rect, {
    width: width,
    height: height,
    rx: borderRadius,
    ry: borderRadius,
    stroke: strokeColor,
    strokeWidth: 2,
    fill: "#000",
    // add filter url for the glow
    filter: "url(#svg-bpmn-shadow)",
  });

  svgAppend(parentNode, rect);

  return rect;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}

function addShadowFilter(eventBus, canvas) {
  eventBus.on("import.done", function(event) {
    if (event.error) return;

    // Similar to AddMarker in bpmn-js/lib/draw/BpmnRenderer.js
    let defs = domQuery("defs", canvas._svg);
    if (!defs) {
      defs = svgCreate("defs");
      svgAppend(canvas._svg, defs);
    }
    const shadowFilter = svgCreate("filter");
    svgAttr(shadowFilter, {
      id: "svg-bpmn-shadow",
    });
    svgAppend(defs, shadowFilter);

    const feGaussianBlur = svgCreate("feGaussianBlur");
    svgAttr(feGaussianBlur, {
      result: "coloredBlur",
      stdDeviation: 4,
    });
    svgAppend(shadowFilter, feGaussianBlur);

    const feMerge = svgCreate("feMerge");
    svgAppend(shadowFilter, feMerge);

    const feMergeNode1 = svgCreate("feMergeNode");
    svgAttr(feMergeNode1, {
      in: "coloredBlur",
    });
    svgAppend(feMerge, feMergeNode1);

    const feMergeNode2 = svgCreate("feMergeNode");
    svgAttr(feMergeNode2, {
      in: "SourceGraphic",
    });
    svgAppend(feMerge, feMergeNode2);
  });
}
