import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Divider, Paper, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";
import CommonSection from "./CommonSection";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import SnmpSection from "./SnmpSection";
import GraphSection from "./GraphSection";
import { formSections } from "msa2-ui/src/components/monitoring-profile-form/constants";

const useStyles = makeStyles(() => ({
  progressWrap: {
    minHeight: 320,
  },
  hidden: {
    display: "none",
  },
}));

const ModalSections = ({
  pending,
  activeSection,
  onAddSnmpListField,
  onRemoveSnmpListField,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <ScrollingContainer>
      <Paper className={commonClasses.commonPaperNoPadding}>
        {pending ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.progressWrap}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <CommonSection />
            <Divider />
            <div
              className={classNames({
                [classes.hidden]:
                  formSections.snmpPollingList !== activeSection,
              })}
            >
              <SnmpSection
                onAddSnmpListField={onAddSnmpListField}
                onRemoveSnmpListField={onRemoveSnmpListField}
              />
            </div>
            <div
              className={classNames({
                [classes.hidden]:
                  formSections.graphRendererList !== activeSection,
              })}
            >
              <GraphSection />
            </div>
          </>
        )}
      </Paper>
    </ScrollingContainer>
  );
};

ModalSections.propTypes = {
  activeSection: PropTypes.string.isRequired,
  onAddSnmpListField: PropTypes.func.isRequired,
  onRemoveSnmpListField: PropTypes.func.isRequired,
};

export default ModalSections;
