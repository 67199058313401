import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIsAuth, getUserRole, getIsDeveloper } from "./store/auth";

import { compareUrlByDepth } from "./utils/urls";

import { ThemeProvider } from "@material-ui/core";
import createTheme from "./styles/theme";

import ErrorBoundary from "./components/ErrorBoundary";
import AppContents from "./components/AppContents";

import Login from "./routes/login";
import AppWrapper from "./components/AppWrapper";
import MainMenu from "./components/MainMenu";
import Alarms from "./routes/alarms";
import AuthorizedRoute from "./components/AuthorizedRoute";
import FeatureFlag from "./services/FeatureFlag";
import { getDelegationProfile } from "./store/delegationProfiles";
import { delegationProfileTypes } from "./store/delegationProfiles";
import { clearManagedEntity } from "./store/designations";
import { resetUi } from "./store/ui";
import useRouteLanguageParam from "./hooks/useRouteLanguageParam";

import "./assets/css/App.css";

const ROUTES = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
};

const App = () => {
  const dispatch = useDispatch();

  const delegationProfiles = useSelector(
    ({ delegationProfiles }) => delegationProfiles,
  );
  const isAuth = useSelector(getIsAuth);
  const userRole = useSelector(getUserRole);
  const isDeveloper = useSelector(getIsDeveloper);

  useRouteLanguageParam(Object.values(ROUTES));

  const isAlarmsEnabled =
    FeatureFlag.isEnabled(FeatureFlag.features.alarms) === true;
  const logsView = useSelector(
    getDelegationProfile(delegationProfileTypes.LOGS, "general.view"),
  );
  const alarmsView = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "alarms.view"),
  );
  const manageAlarmsView = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "manageAlarms.view"),
  );
  const canViewLogs =
    (logsView || alarmsView || manageAlarmsView) && isAlarmsEnabled;

  dispatch(resetUi());
  dispatch(clearManagedEntity()); // Selected managed entity should be cleared on app init as user can refresh page when topology context menu is open

  if (!isAuth) {
    const lightTheme = createTheme(false);
    return (
      <ThemeProvider theme={lightTheme}>
        <Route component={Login} />
      </ThemeProvider>
    );
  }

  return (
    <ErrorBoundary>
      <Route exact path="/">
        <Redirect to={ROUTES.DASHBOARD} />
      </Route>
      <Route exact path={ROUTES.LOGIN}>
        <Redirect to={ROUTES.DASHBOARD} />
      </Route>
      <AppWrapper>
        <AppContents>
          <Switch>
            {MainMenu.filter(({ canAccess }) =>
              canAccess({ isDeveloper, userRole, delegationProfiles }),
            ).map(
              ({
                label,
                route,
                exact,
                component,
                hasChildItems,
                childItems,
              }) => {
                return hasChildItems ? (
                  childItems
                    .filter(({ canAccess }) =>
                      canAccess({ isDeveloper, userRole, delegationProfiles }),
                    )
                    .sort(({ route: pRoute }, { route }) =>
                      compareUrlByDepth(pRoute, route),
                    )
                    .map(({ label, route: subRoute, exact, component }) => (
                      <Route
                        key={label}
                        path={route + subRoute}
                        exact={exact}
                        component={component}
                      />
                    ))
                ) : (
                  <Route
                    key={label}
                    path={route}
                    exact={exact}
                    component={component}
                  />
                );
              },
            )}
            <AuthorizedRoute
              guard={canViewLogs}
              redirect={ROUTES.DASHBOARD}
              path="/alarms"
              render={() => <Alarms />}
            />
            <Redirect to={ROUTES.DASHBOARD} />
          </Switch>
        </AppContents>
      </AppWrapper>
    </ErrorBoundary>
  );
};

export default App;
