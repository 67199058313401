import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Menu as MaterialUIMenu } from "@material-ui/core";

const useStyles = makeStyles(({ palette, darkMode }) => ({
  paper: {
    maxHeight: 300,
    minWidth: 200,
    maxWidth: 300,
    border: `1px solid #32363d`,
    backgroundColor: palette.background.default,
  },
  list: {
    pointerEvents: "auto",
  },
}));

export const MenuContext = React.createContext(null);

// For more info regarding MaterialUI options see here:
// https://material-ui.com/api/menu/
// https://material-ui.com/api/popover/
const Menu = ({ children, anchorPosition, open, ...rest }) => {
  const classes = useStyles();
  const [activeMenuItemId, setActiveMenuItemId] = useState(null);

  const contextValue = useMemo(
    () => ({
      activeMenuItemId,
      handleActiveItem: (id) => setActiveMenuItemId(id),
    }),
    [activeMenuItemId],
  );

  const { top, left } = anchorPosition || {};
  useEffect(() => {
    setActiveMenuItemId(null);
  }, [top, left, open]);

  if (!open) {
    return null;
  }

  return (
    <MenuContext.Provider value={contextValue}>
      <MaterialUIMenu
        {...rest}
        classes={classes}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
      >
        {children}
      </MaterialUIMenu>
    </MenuContext.Provider>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorPosition: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }),
};

export default Menu;
