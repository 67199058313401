import { get, post, destroy, put } from "./request";
import { userRoles } from "msa2-ui/src/store/auth";

const API = process.env.REACT_APP_API_PATH;

/*
 * Spec: https://10.31.1.52/swagger/#/Lookup/listAdmins
 */
export const getAdministrators = ({
  token,
  filter = "name",
  filterCriteria = "",
  sort = "name",
  sortOrder: sort_order = "ASC",
  page = 1,
  pageSize: page_size = 10,
}) => {
  return get({
    url: `${API}/lookup/v1/admins`,
    token,
    queryParams: {
      filter,
      filter_criteria: filterCriteria,
      sort,
      sort_order,
      page,
      page_size,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Lookup/listAdmins
 */
export const addAdministrator = ({
  token,
  name,
  email,
  username,
  password,
  usertype,
  tenantIds: operatorIds = [],
}) => {
  return post({
    url: `${API}/user/v1/manager`,
    token,
    body: {
      address: {
        email,
      },
      baseRole: {
        id: userRoles.ADMINISTRATOR,
      },
      login: username,
      name,
      pwd: password,
      userType: usertype,
      operatorIds,
    },
  });
};

export const editAdministrator = ({
  token,
  id,
  administrator,
  email,
  username,
  password,
  usertype,
  name,
  associatedTenantIds: operatorIds,
}) => {
  return put({
    url: `${API}/user/v1/manager/${id}`,
    token,
    body: {
      ...administrator,
      address: {
        ...administrator.address,
        email,
      },
      baseRole: {
        id: userRoles.ADMINISTRATOR,
      },
      login: username,
      pwd: password,
      userType: usertype,
      name,
      operatorIds,
    },
  });
};

export const deleteAdministrator = ({ token, id }) => {
  return destroy({
    url: `${API}/user/id/${id}`,
    token,
  });
};
