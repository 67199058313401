import React, { useState } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import flow from "lodash/flow";
import { getParentRoute } from "msa2-ui/src/utils/urls";
import useApi from "msa2-ui/src/hooks/useApi";
import { getBpmnXml, deleteProcessInstance } from "msa2-ui/src/api/bpm";
import { getToken } from "msa2-ui/src/store/auth";
import Repository from "msa2-ui/src/services/Repository";

import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Modal from "msa2-ui/src/components/modal/Modal";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import BpmModeler from "msa2-ui/src/components/bpm/BpmModeler";
import ModalTitleBar from "./ModalTitleBar";

const useStyles = makeStyles(() => ({
  modalMessage: {
    justifyContent: "center",
    marginTop: 30,
  },
}));

const Read = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isTerminating, setIsTerminating] = useState(false);
  const [isFinished, setIsFinished] = useState();
  const token = useSelector(getToken);

  const {
    url,
    params: { bpmUri: bpmUriFromRoute, definitionId, instanceId },
  } = useRouteMatch();

  const bpmUri = decodeURIComponent(bpmUriFromRoute);

  const onClose = () => {
    history.push({
      pathname: flow(getParentRoute, getParentRoute)(url),
      state: { bpmFile: state?.bpmFile ?? {} },
    });
  };

  const [loading, , bpmnXmlResponse] = useApi(
    getBpmnXml,
    { id: definitionId },
    !definitionId,
  );

  const terminateBpm = async () => {
    enqueueSnackbar(t("Terminating BPM..."));
    setIsTerminating(true);
    const [error] = await deleteProcessInstance({ id: instanceId, token });
    enqueueSnackbar(
      error
        ? error.getMessage()
        : t(
            "Terminate accepted. It will be stopped when current Process is finished.",
          ),
      {
        variant: error ? "error" : "success",
        persist: error,
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      },
    );
    if (error) {
      setIsTerminating(false);
    }
  };

  const renderModalContent = () => {
    if (loading) {
      return (
        <Grid container className={classes.modalMessage}>
          <CircularProgress />
        </Grid>
      );
    } else if (!bpmnXmlResponse) {
      return (
        <Grid container className={classes.modalMessage}>
          <Typography className={classes.errorMessage}>
            {t("Could not fetch BPM diagram")}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <BpmModeler
          diagramXml={bpmnXmlResponse.bpmn20Xml}
          executionProcessInstanceId={instanceId}
          onActiveInstanceChanged={({ isFinished }) => {
            setIsFinished(isFinished);
          }}
          readOnly
        />
      );
    }
  };

  return (
    <ErrorBoundary>
      <Modal onClose={onClose}>
        <ModalTitleBar
          title={Repository.stripFolderPathAndFileExtensionFromUri(bpmUri)}
          closeButtonLabel={t("Close")}
          onClose={onClose}
          executeButtonLabel={t("Terminate BPM")}
          executeButtonTooltip={
            isFinished && t("This BPM has already finished the execution.")
          }
          // isFinished should be exact false as while it's undefined we don't know if it's finished and want to disable the button
          disabled={isTerminating || loading || isFinished !== false}
          onExecute={terminateBpm}
        />
        <ModalContent>{renderModalContent()}</ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

export default Read;
