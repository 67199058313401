import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";

import Process from "msa2-ui/src/services/Process";
import useUpsertAlarmForm from "./useUpsertAlarmForm";
import useApi from "msa2-ui/src/hooks/useApi";
import useWorkflowList from "msa2-ui/src/hooks/useWorkflowList";

import useWorkflow from "msa2-ui/src/hooks/useWorkflow";
import { getManagedEntitiesByAdmin } from "msa2-ui/src/api/managedEntity";
import { getUserDetails, getToken } from "msa2-ui/src/store/auth";
import { createAlarm, updateAlarm } from "msa2-ui/src/api/alarms";

import { CircularProgress, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import Dialog from "msa2-ui/src/components/Dialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import UpsertAlarmFormDetailsTab from "./UpsertAlarmFormDetailsTab";
import UpsertAlarmFormConditionsTab from "./UpsertAlarmFormConditionsTab";
import UpsertAlarmFormThresholdTab from "./UpsertAlarmFormThresholdTab";
import UpsertAlarmFormNotificationTab from "./UpsertAlarmFormNotificationTab";
import UpsertAlarmFormActionsTab from "./UpsertAlarmFormActionsTab";
import UpsertAlarmAutoClearanceTab from "./UpsertAlarmAutoClearanceTab";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { getAvailableSubtenants } from "msa2-ui/src/store/designations";
import { getUserRole, userRoles } from "msa2-ui/src/store/auth";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const isAlarmsAutoClearanceEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.alarmsAutoClearance,
);

const useStyles = makeStyles(({ spacing }) => ({
  dialog: {
    height: 560,
  },
  loadingIndicator: {
    marginTop: spacing(2),
  },
  error: {
    marginTop: spacing(4),
  },
}));

const NUMBER_OF_WORKFLOWS_TO_REQUEST = 500;

const UpsertAlarmForm = ({
  alarmData,
  loading = false,
  error,
  alarmName,
  alarmId,
  isCreate = false,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const { id: userId } = useSelector(getUserDetails);
  const token = useSelector(getToken);

  const [activeTab, setActiveTab] = useState("UPSERT_ALARM_FORM_DETAILS_TAB");
  const [localValidationError, setLocalValidationError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formState, dispatch] = useUpsertAlarmForm(alarmData);
  const [
    isAutoClearanceDefined,
    setIsAutoClearanceDefined,
  ] = useState();

  const subtenants = useSelector(getAvailableSubtenants);
  const userRole = useSelector(getUserRole);

  const [
    managedEntitiesLoading,
    managedEntitiesError,
    managedEntities,
  ] = useApi(
    getManagedEntitiesByAdmin,
    {
      managerId: userId,
      pageSize: 10000,
      filterByLabel: isPermissionProfileLabelsEnabled,
    },
    !userId,
  );

  const [
    workflowsLoading,
    workflowsError,
    workflowsApiResponse,
  ] = useWorkflowList({
    pageSize: NUMBER_OF_WORKFLOWS_TO_REQUEST,
    sort: "timestamp",
    sortOrder: "DESC",
  });

  const selectedWorkflowData = useWorkflow({
    workflowPath: formState?.rule.workflowPath,
    processName: formState?.rule.processName,
  });
  const {
    processVariableTypesByTask,
    processVariableTypes,
    workflow,
  } = selectedWorkflowData;
  const createProcesses = workflow?.process?.filter(({ type }) =>
    Process.isCreate(type),
  );

  const dialogTitle =
    loading || formState?.name
      ? `${t("Edit")} ${formState?.name}`
      : `${t("Create")} ${t("Alarm")}`;

  const validRegex = (value) => {
    try {
      new RegExp(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getValidationError = () => {
    let error = "";
    const autoClearDataFound =
      isAutoClearanceDefined ||
      formState.alarmClearance.alarm_regex !== "" ||
      formState.alarmClearance.event_regex !== "" ||
      formState.alarmClearance.clearing_delay > 0 ||
      formState.alarmClearance.clear_comment !== "";

    if (!formState.name) {
      error = t("Please fill out the x field", { x: "Name" });
    } else if (formState.name.indexOf(" ") > -1) {
      error = t("x must not contain spaces", { x: t("Alarm name") });
    } else if (formState.rule.count <= -1) {
      error = t("x must contain positive log count in threshold", {
        x: formState.name,
      });
    } else if (formState.rule.duration <= 0) {
      error = t("x must contain positive values for treshold minutes", {
        x: formState.name,
      });
    } else if (
      autoClearDataFound &&
      formState.alarmClearance.alarm_regex === ""
    ) {
      error = t("Alarm Matcher cannot be empty");
    } else if (!validRegex(formState.alarmClearance.alarm_regex)) {
      error = t("Invalid Regular Expression x", {
        x: formState.alarmClearance.alarm_regex,
      });
    } else if (
      autoClearDataFound &&
      formState.alarmClearance.event_regex === ""
    ) {
      error = t("Clearance matcher cannot be empty");
    } else if (!validRegex(formState.alarmClearance.event_regex)) {
      error = t("Invalid Regular Expression x", {
        x: formState.alarmClearance.event_regex,
      });
    } else if (
      (autoClearDataFound &&
        parseInt(formState.alarmClearance.clearing_delay) === 0) ||
      formState.alarmClearance.clearing_delay < 0
    ) {
      error = t("Clearance delay must contain positive values");
    }
    return error;
  };

  const doValidation = () => {
    setLocalValidationError(getValidationError());
  };

  const onSubmitForm = async () => {
    let data = formState;
    const error = getValidationError();
    if (!isEmpty(error)) {
      setLocalValidationError(error);
    } else {
      setIsSubmitting(true);
      if (
        formState?.query.subtenantIds.length === 0 &&
        userRole !== userRoles.PRIVILEGED_ADMINISTRATOR
      ) {
        const payload = subtenants.map((entry) => entry.id);
        data = {
          ...data,
          query: {
            ...data?.query,
            subtenantIds: payload,
          },
        };
      }
      const [upsertAlarmError] = isCreate
        ? await createAlarm({
            alarmData: data,
            token,
          })
        : await updateAlarm({
            alarmData: data,
            alarmId,
            token,
          });

      setIsSubmitting(false);

      const message = upsertAlarmError
        ? upsertAlarmError.getMessage()
        : t("x saved successfully", { x: t("Alarm") });
      enqueueSnackbar(message, {
        variant: upsertAlarmError ? "error" : "success",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });

      if (!upsertAlarmError) {
        history.push("/alarms/manage");
      }
    }
  };
  const TABS = [
    {
      id: "UPSERT_ALARM_FORM_DETAILS_TAB",
      label: t("Details"),
      alwaysVisible: true,
      dataTestId: "alarms-details-tab",
    },
    {
      id: "UPSERT_ALARM_FORM_CONDITIONS_TAB",
      label: t("Conditions"),
      alwaysVisible: true,
      dataTestId: "alarms-conditions-tab",
    },
    {
      id: "UPSERT_ALARM_FORM_CLEAR_CONDITIONS_TAB",
      label: t("Auto Clearance"),
      alwaysVisible: false,
      dataTestId: "alarms-clear-conditions-tab",
    },
    {
      id: "UPSERT_ALARM_FORM_THRESHOLD_TAB",
      label: t("Threshold"),
      alwaysVisible: true,
      dataTestId: "alarms-treshold-tab",
    },
    {
      id: "UPSERT_ALARM_FORM_NOTIFICATION_TAB",
      label: t("Notification"),
      alwaysVisible: true,
      dataTestId: "alarms-notification-tab",
    },
    {
      id: "UPSERT_ALARM_FORM_ACTIONS_TAB",
      label: t("Actions"),
      alwaysVisible: true,
      dataTestId: "alarms-actions-tab",
    },
  ];

  const TABS_TO_DISPLAY = TABS.filter(
    ({ alwaysVisible }) =>
      // If tab is always visible, it will be shown
      // If not always visible, it will be shown based on the feature flag 'alarmsAutoClearance'
      alwaysVisible || isAlarmsAutoClearanceEnabled,
  );

  const renderTabs = () => {
    return (
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        variant="standard"
        onChange={(_, newValue) => setActiveTab(newValue)}
      >
        {TABS_TO_DISPLAY.map(({ id, label, dataTestId }, index) => (
          <Tab
            key={index}
            id={id}
            label={label}
            data-testid={dataTestId}
            classes={{
              selected: commonClasses.commonTabSelected,
            }}
            value={id}
          />
        ))}
      </Tabs>
    );
  };

  const renderFormContent = () => {
    if (loading || managedEntitiesLoading || workflowsLoading) {
      return <CircularProgress className={classes.loadingIndicator} />;
    }
    if (error || managedEntitiesError || workflowsError) {
      return (
        <Typography color="error" className={classes.error}>
          {error ?? t("Error fetching x", { x: t("Data") })}
        </Typography>
      );
    }
    switch (activeTab) {
      case "UPSERT_ALARM_FORM_DETAILS_TAB":
        return (
          <UpsertAlarmFormDetailsTab
            formState={formState}
            dispatch={dispatch}
            alarmName={alarmName}
            onBlur={doValidation}
          />
        );
      case "UPSERT_ALARM_FORM_CONDITIONS_TAB":
        return (
          <UpsertAlarmFormConditionsTab
            formState={formState}
            dispatch={dispatch}
            managedEntities={managedEntities}
          />
        );
      case "UPSERT_ALARM_FORM_CLEAR_CONDITIONS_TAB":
        return (
          <UpsertAlarmAutoClearanceTab
            formState={formState}
            dispatch={dispatch}
            setIsAutoClearanceDefined={setIsAutoClearanceDefined}
          />
        );
      case "UPSERT_ALARM_FORM_THRESHOLD_TAB":
        return (
          <UpsertAlarmFormThresholdTab
            formState={formState}
            dispatch={dispatch}
          />
        );
      case "UPSERT_ALARM_FORM_NOTIFICATION_TAB":
        return (
          <UpsertAlarmFormNotificationTab
            formState={formState}
            dispatch={dispatch}
          />
        );
      case "UPSERT_ALARM_FORM_ACTIONS_TAB":
        return (
          <UpsertAlarmFormActionsTab
            formState={formState}
            dispatch={dispatch}
            workflows={workflowsApiResponse?.workflows || []}
            processes={createProcesses}
            workflow={workflow}
            processVariableTypes={processVariableTypes}
            processVariableTypesByTask={processVariableTypesByTask}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      onExec={onSubmitForm}
      onClose={() => history.push("/alarms/manage")}
      execLabel={t("Save")}
      title={dialogTitle}
      tabs={renderTabs()}
      disabled={loading || isSubmitting}
      maxWidth="md"
      classes={{ paper: classes.dialog }}
    >
      {renderFormContent()}
      {localValidationError && (
        <Typography color="error" className={classes.error}>
          {localValidationError}
        </Typography>
      )}
    </Dialog>
  );
};

UpsertAlarmForm.propTypes = {
  alarmData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    query: PropTypes.shape({
      terms: PropTypes.string,
      managedEntityIds: PropTypes.arrayOf(PropTypes.string),
      severities: PropTypes.arrayOf(PropTypes.number),
      subtenantIds: PropTypes.arrayOf(PropTypes.number),
    }),
    rule: PropTypes.shape({
      count: PropTypes.string,
      duration: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.bool,
      workflowPath: PropTypes.string,
      processName: PropTypes.string,
      processVariables: PropTypes.shape({}),
      cleanUpProcessInstanceAfterExecution: PropTypes.bool,
    }),
  }),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default UpsertAlarmForm;
