import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import FieldArray from "redux-form/lib/FieldArray";
import FormMsaSelect from "msa2-ui/src/components/formSection/FormMsaSelect";
import { Grid, IconButton, MenuItem, Typography } from "@material-ui/core";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import { operators } from "../constants";
import { integerValue, maxValue, minValue } from "msa2-ui/src/utils/validators";

const renderMenuItems = (obj) =>
  Object.entries(obj).map(([key, name]) => (
    <MenuItem value={key} key={key}>
      {name}
    </MenuItem>
  ));

const minValueZero = minValue(0);
const maxValueOne = maxValue(1);

const contextFieldsRenderer = memo(({ fields }) => {
  const t = useBoundedTranslation();

  const operatorsOptions = useMemo(() => renderMenuItems(operators), []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ paddingBottom: 20 }}>
          {t("Initial Context")}
        </Typography>
      </Grid>
      {fields.map((field, rowIndex) => (
        <Grid item xs={12} key={field}>
          <Grid container spacing={4}>
            <Grid item md={3}>
              <FieldSelector
                name={`${field}.name`}
                label={t("Name")}
                type="text"
              />
            </Grid>
            <Grid item md={3}>
              <FormSelect
                required={false}
                name={`${field}.operator`}
                label={t("Operator")}
              >
                {operatorsOptions}
              </FormSelect>
            </Grid>
            <Grid item md={3}>
              <FieldSelector
                name={`${field}.value`}
                label={t("Value")}
                type="text"
              />
            </Grid>
            <Grid item md={3}>
              <IconButton
                id={`${field}.deleteBtn`}
                onClick={() => fields.remove(rowIndex)}
              >
                <IconDeleteRed />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton
          id="AI_ADVANCED_PARAMETERS_INITIAL_CONTEXT_ADD_BTN"
          onClick={() => fields.push({})}
        >
          <IconPlus />
        </IconButton>
      </Grid>
    </Grid>
  );
});

const AdvancedParameters = ({ transitionOptions }) => {
  const t = useBoundedTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={3}>
        <FieldSelector
          name="learningRate"
          label={t("Learning Rate")}
          type="number"
          noIndent
          inputProps={{
            min: 0,
            max: 1,
            step: 0.1,
          }}
          validate={[minValueZero, maxValueOne]}
        />
      </Grid>
      <Grid item sm={12} md={3}>
        <FieldSelector
          name="discountFactor"
          label={t("Discount Factor")}
          type="number"
          noIndent
          inputProps={{
            min: 0,
            max: 1,
            step: 0.1,
          }}
          validate={[minValueZero, maxValueOne]}
        />
      </Grid>
      <Grid item sm={12} md={3}>
        <FieldSelector
          name="reward"
          label={t("Final State Reward")}
          type="number"
          noIndent
          inputProps={{
            step: 1,
          }}
          validate={integerValue}
        />
      </Grid>
      <Grid item sm={12} md={3}>
        <FormMsaSelect
          name="transitions"
          options={transitionOptions}
          placeholder={t("Select transitions")}
          isClearable
          isMulti
          isSearchable
        />
      </Grid>
      <Grid item xs={12}>
        <FieldArray name="initialContext" component={contextFieldsRenderer} />
      </Grid>
    </Grid>
  );
};

AdvancedParameters.propTypes = {
  transitionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AdvancedParameters;
