import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import orderBy from "lodash/orderBy";

import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { filter } from "msa2-ui/src/utils/filter";

import useFilter from "msa2-ui/src/hooks/useFilter";
import useApi from "msa2-ui/src/hooks/useApi";
import { getMsaVars, getMsaVarsDefinition } from "msa2-ui/src/api/msaVars";

import { getIsRootUser } from "msa2-ui/src/store/auth";

import { makeStyles } from "@material-ui/core";
import {
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { sortOrder } from "msa2-ui/src/Constants";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import MSATableBody from "msa2-ui/src/components/MSATableBody";
import MSATableRow from "msa2-ui/src/components/MSATableRow";

import EditMSAVar from "./EditMSAVar";

const useStyles = makeStyles(({ palette, spacing, darkMode }) => ({
  paper: {
    boxShadow: darkMode
      ? `1px -1px 12px -1px ${palette.primary.main}`
      : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
    padding: spacing(2),
  },
  header: {
    marginBottom: 16,
  },
}));

const TABLE_HEADER_COLUMNS = [
  {
    id: "description",
    name: "Description",
    align: "left",
    width: "40%",
    sortKey: true,
    tooltipId: "help",
  },
  {
    id: "value",
    name: "Value",
    align: "left",
    width: "20%",
    sortKey: true,
  },
  {
    id: "comment",
    name: "Comment",
    align: "left",
    width: "20%",
    sortKey: true,
  },
  {
    id: "lastUpdate",
    name: "Last Update",
    align: "left",
    sortKey: true,
    width: "12%",
    format: displayMonthDayYearTimeDate,
  },
  {
    id: "action",
    name: "",
    align: "left",
    width: "8%",
    sortKey: false,
  },
];

const MSAVarSettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [varForEdit, setVarForEdit] = useState();
  const commonClasses = useCommonStyles();

  const [filterState, filterActions] = useFilter({
    searchValue: "",
    sortByValue: "lastUpdate",
    sortOrderValue: 0,
  });

  const isRootUser = useSelector(getIsRootUser);
  const [
    definitionLoading,
    definitionError,
    msaVarsDefinitions,
  ] = useApi(getMsaVarsDefinition, { isRootUser });

  const vars = msaVarsDefinitions?.map(({ name }) => name);
  const mergeWithDefinition = (response) =>
    response.map((msaVar) => {
      const msaVarsDefinition = msaVarsDefinitions?.find(
        ({ name }) => name === msaVar.name,
      );
      return { ...msaVarsDefinition, ...msaVar };
    });

  const [loading, error, msaVars, , reload] = useApi(
    getMsaVars,
    {
      isRootUser,
      vars,
      transforms: mergeWithDefinition,
    },
    !msaVarsDefinitions,
  );

  const sortedMsaVars = orderBy(
    msaVars,
    // converts null into empty string
    (entry) => entry[filterState.sortByValue] ?? "",
    sortOrder[filterState.sortOrderValue].text,
  );
  const colSpan = TABLE_HEADER_COLUMNS.length;

  return (
    <>
      {varForEdit && (
        <EditMSAVar
          {...msaVars?.find(({ name }) => name === varForEdit)}
          onClose={() => {
            reload();
            setVarForEdit("");
          }}
        />
      )}
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.header}>
          {t("MSA Variables")}
        </Typography>
        <Table>
          <colgroup>
            {TABLE_HEADER_COLUMNS.map(({ id, width }) => (
              <col key={id} style={{ width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={colSpan}
                className={commonClasses.commonTableCell}
              >
                <FilterMenu
                  id="SETTINGS_MSA_VAR_FILTER_MENU"
                  {...filterState}
                  {...filterActions}
                  handleViewAsChange={undefined}
                />
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                <TableCell
                  key={tableHeaderColumn.id}
                  align={tableHeaderColumn.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {tableHeaderColumn.sortKey ? (
                    <TableSortLabel
                      id={`SETTINGS_MSA_VAR_${tableHeaderColumn.id}`}
                      active={tableHeaderColumn.id === filterState.sortByValue}
                      direction={sortOrder[filterState.sortOrderValue].text}
                      onClick={() => {
                        filterActions.handleSortByOrderChange(
                          filterState.sortByValue !== tableHeaderColumn.id
                            ? Number(filterState.sortOrderValue)
                            : Number(!filterState.sortOrderValue),
                        );
                        filterActions.handleSortByValueChange(
                          tableHeaderColumn.id,
                        );
                      }}
                    >
                      {tableHeaderColumn.name}
                    </TableSortLabel>
                  ) : (
                    tableHeaderColumn.name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <MSATableBody
            loading={!msaVars?.length && (definitionLoading || loading)}
            error={
              (error || definitionError) &&
              t("Unable to load x", { x: t("MSA Variables") })
            }
            noContents={!msaVars?.length && t("No Variables found")}
            colSpan={colSpan}
          >
            {filter(sortedMsaVars, filterState.searchValue, [
              "description",
              "group",
              "value",
            ]).map((msaVar, i) => {
              const { name, type } = msaVar;
              let newMsaVar = msaVar;
              if (type === "Password") {
                newMsaVar = {
                  ...msaVar,
                  value: msaVar.value.replace(/./g, "*"),
                };
              }
              return (
                <MSATableRow
                  key={i}
                  columns={TABLE_HEADER_COLUMNS}
                  data={newMsaVar}
                  chip={{ id: "group" }}
                  onEdit={() => {
                    setVarForEdit(name);
                  }}
                />
              );
            })}
          </MSATableBody>
        </Table>
      </Paper>
    </>
  );
};

export default MSAVarSettings;
