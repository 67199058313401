import React, { useState, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import { Grid, Paper, Tooltip, Typography, Button } from "@material-ui/core";
import { Pie, PieChart, Cell } from "recharts";
import Dialog from "msa2-ui/src/components/Dialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getMsaTheme } from "msa2-ui/src/store/designations";
import Log from "msa2-ui/src/services/Log";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import AlarmList from "./AlarmList";
import PieChartIcon from "@material-ui/icons/PieChart";
import { getAlarmsDownloadFilePath } from "msa2-ui/src/api/logs";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import { downloadArchive } from "msa2-ui/src/api/repository";
import { AlarmContext } from "./AlarmContext";

const TriggeredAlarms = ({ sectionTabs: SectionTabs }) => {
  const commonClasses = useCommonStyles();
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // This will store whole API Response from child component
  const [showGraph, setShowGraph] = useState(false);
  const theme = useSelector(getMsaTheme);
  const token = useSelector(getToken);

  const {
    triggeredAlarmsCount,
    searchPayload,
    checkedColumns,
    summary,
  } = useContext(AlarmContext);

  const ackData = [
    {
      name: "Ack",
      value: summary?.acknowledgedCount,
      pecentage: summary?.acknowledgedPercentage,
      color: "#4397D6",
    },
    {
      name: "Non-Ack",
      value: summary?.unAcknowledgedCount,
      percentage: summary?.unAcknowledgedPercentage,
      color: "red",
    },
  ];
  const severityData = [
    {
      name: "Emergency",
      value: summary?.severity0Count,
      percentage: summary?.severity0Percentage,
      color: Log.SEVERITY_LEVELS(theme)[0].color,
    },
    {
      name: "Alert",
      value: summary?.severity1Count,
      percentage: summary?.severity1Percentage,
      color: Log.SEVERITY_LEVELS(theme)[1].color,
    },
    {
      name: "Critical",
      value: summary?.severity2Count,
      percentage: summary?.severity2Percentage,
      color: Log.SEVERITY_LEVELS(theme)[2].color,
    },
    {
      name: "Error",
      value: summary?.severity3Count,
      percentage: summary?.severity3Percentage,
      color: Log.SEVERITY_LEVELS(theme)[3].color,
    },
    {
      name: "Warning",
      value: summary?.severity4Count,
      percentage: summary?.severity4Percentage,
      color: Log.SEVERITY_LEVELS(theme)[4].color,
    },
    {
      name: "Notice",
      value: summary?.severity5Count,
      percentage: summary?.severity5Percentage,
      color: Log.SEVERITY_LEVELS(theme)[5].color,
    },
    {
      name: "Informational",
      value: summary?.severity6Count,
      percentage: summary?.severity6Percentage,
      color: Log.SEVERITY_LEVELS(theme)[6].color,
    },
    {
      name: "Debug",
      value: summary?.severity7Count,
      percentage: summary?.severity7Percentage,
      color: Log.SEVERITY_LEVELS(theme)[7].color,
    },
  ];
  const ackInfoWithData = ackData.filter((entry) => entry.value > 0);
  const severityInfoWithData = severityData.filter((entry) => entry.value > 0);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    payload,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 80 + innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        stroke={payload.color}
        fill={payload.color}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {payload.value > 0 &&
          `${payload.name} - ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleDownload = async () => {
    const fields = [];
    checkedColumns
      .filter((key) => !["check", "details"].includes(key.id))
      .forEach((column) => {
        if (column.id === "message") {
          fields.push("rawlog");
        } else {
          fields.push(column.id);
        }
      });

    const [error, response] = await getAlarmsDownloadFilePath({
      token,
      ...searchPayload,
      pageSize: 10000,
      fields: fields,
    });

    if (error) {
      enqueueSnackbar(error.getMessage(), {
        variant: "error",
      });
      return;
    }
    downloadArchive({
      path: response.exportFilepath,
      token,
      fileName: response?.exportFilepath?.split("/").pop(),
      onError: () => {
        enqueueSnackbar(t("Unable to download contents"), { variant: "error" });
      },
    });
  };

  return (
    <>
      <SectionTabs
        count={[path, triggeredAlarmsCount]}
        endElements={
          <Grid container>
            <Grid
              item
              container
              alignContent="center"
              justifyContent="center"
              xs={5}
            >
              <Button
                id="TRIGGERED_ALARMS_DOWNLOAD_BTN"
                variant="outlined"
                color="primary"
                onClick={handleDownload}
                disabled={triggeredAlarmsCount === 0}
              >
                {t("Download report")}
              </Button>
            </Grid>
            {summary.unAcknowledgedPercentage !== undefined && (
              <>
                <Grid
                  item
                  container
                  alignContent="center"
                  justifyContent="center"
                  xs={6}
                >
                  <span
                    style={{
                      textAlign: "center",
                      color: "red",
                      margin: "auto 10px",
                      fontSize: 15,
                    }}
                  >{`Non-Ack: ${summary.unAcknowledgedPercentage}`}</span>
                  <span
                    style={{
                      textAlign: "center",
                      color: "#4397D6",
                      margin: "auto 10px",
                      fontSize: 15,
                    }}
                  >{`Ack: ${summary.acknowledgedPercentage}`}</span>
                </Grid>
                <Grid
                  item
                  container
                  alignContent="center"
                  justifyContent="center"
                  xs={1}
                >
                  <Tooltip title={t("Summary")}>
                    <PieChartIcon
                      color="primary"
                      onClick={() => setShowGraph(true)}
                    ></PieChartIcon>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
            style={{
              overflowY: "auto",
            }}
          >
            <AlarmList />
          </Paper>
        </Grid>
        {showGraph && (
          <Dialog
            open
            title={t("Summary")}
            maxWidth="lg"
            onClose={() => setShowGraph(false)}
            classes={{
              paper: commonClasses.commonDialogPaper,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignContent="center"
                direction="column"
              >
                <Grid>
                  <Typography id="ALARM_SUMMARY_GRAPH_ACK">
                    <span>{t("Acknowledgement")}</span>
                  </Typography>
                </Grid>
                <Grid>
                  {ackInfoWithData.length ? (
                    <PieChart width={500} height={300}>
                      <Pie
                        dataKey="value"
                        data={ackInfoWithData}
                        outerRadius={100}
                        paddingAngle={2}
                        labelLine={false}
                        label={renderCustomizedLabel}
                      >
                        {ackInfoWithData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  ) : (
                    <Typography id="ALARM_SUMMARY_GRAPH_NODATA">
                      <span>{t("No Data")}</span>
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignContent="center"
                direction="column"
              >
                <Grid>
                  <Typography id="ALARM_SUMMARY_GRAPH_SEVERITY">
                    <span>{t("Severity")}</span>
                  </Typography>
                </Grid>
                <Grid>
                  {severityInfoWithData.length ? (
                    <PieChart width={500} height={300}>
                      <Pie
                        dataKey="value"
                        data={severityInfoWithData}
                        outerRadius={100}
                        paddingAngle={2}
                        label={renderCustomizedLabel}
                        labelLine={false}
                      >
                        {severityInfoWithData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  ) : (
                    <Typography>
                      <span>{t("No Data")}</span>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        )}
      </Grid>
    </>
  );
};

export default TriggeredAlarms;
