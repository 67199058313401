import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import classnames from "classnames";
import get from "lodash/get";
import flow from "lodash/flow";

import { workflowStatus, workflowActions } from "msa2-ui/src/Constants";

import {
  displayUserFriendlyDuration,
  displayYearMonthTimeDateAsES,
  formatDateOrString,
} from "msa2-ui/src/utils/date";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import commonStyles, { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import MSADialog from "msa2-ui/src/components/Dialog";
import WorkflowLiveConsole from "msa2-ui/src/components/workflow-live-console";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";

const localStyles = ({ spacing, colors, palette }) => ({
  responseTextOutput: {
    flex: 4,
  },
  processOverviewWrapper: {
    margin: "0px 20px",
  },
  contentWrapper: {
    padding: 0,
  },
});

// Combine Styles
const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

const PaperComponent = ({ className, ...props }) => {
  const commonClasses = useCommonStyles();

  return (
    <Paper
      className={classnames(
        commonClasses.commonPaperNoPadding,
        commonClasses.commonDialogPaper,
        className,
      )}
      {...props}
    />
  );
};

class AutomationProcessExecutionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
    };
  }

  render() {
    const {
      classes,
      onClose,
      token,
      pollingInterval,
      processId,
      processReference,
      processName,
      processIcon,
      onEnded,
    } = this.props;
    const { apiResponse } = this.state;
    const startingDate = get(apiResponse, ["status", "startingDate"]);
    const endingDate = get(apiResponse, ["status", "endingDate"]);
    const status = workflowStatus.find(
      ({ status }) => status === apiResponse?.status?.status,
    );
    return (
      <Fragment>
        <Dialog
          open={true}
          onClose={onClose}
          aria-labelledby="modalArea"
          fullWidth={true}
          maxWidth={"md"}
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            id="AUTOMATION_PROCESS_EXECUTION_DIALOG_TITLE"
            className={classes.commonDialogHeader}
            disableTypography
          >
            <div className={classes.commonDialogHeaderIcon}>
              {/* ToDo: revisit here and show icon */}
              {/* <this.props.processIcon /> */}
            </div>
            <Typography
              variant="h4"
              className={classes.commonDialogHeaderTitle}
            >
              {this.props.processName}
            </Typography>
            {apiResponse && (
              <div
                className={classnames(
                  classes.processOverviewWrapper,
                  classes.commonDialogHeaderTitle,
                  classes.responseTextOutput,
                )}
              >
                {apiResponse.executorUsername && (
                  <>
                    <Typography
                      variant="body1"
                      className={classes.commonDescription}
                    >
                      {apiResponse.executorUsername}
                    </Typography>
                    <span
                      className={`${classes.commonSpacer} ${classes.commonDescription}`}
                    >
                      {"|"}
                    </span>
                  </>
                )}
                <Typography
                  variant="body1"
                  className={classes.commonDescription}
                >
                  {startingDate
                    ? formatDateOrString(
                        displayYearMonthTimeDateAsES(startingDate) + "+0000",
                        "MMM dd, yyyy h:mm:ssaa",
                      )
                    : ""}
                </Typography>
                <span
                  className={`${classes.commonSpacer} ${classes.commonDescription}`}
                >
                  {"-"}
                </span>
                <Typography
                  variant="body1"
                  className={classes.commonDescription}
                >
                  {endingDate
                    ? formatDateOrString(
                        displayYearMonthTimeDateAsES(endingDate) + "+0000",
                        "MMM dd, yyyy h:mm:ssaa",
                      )
                    : status?.name}
                </Typography>
                {startingDate && endingDate && (
                  <Fragment>
                    <span className={classes.commonSpacer} />
                    <Typography
                      variant="body1"
                      className={classes.commonDescription}
                    >
                      {`(${displayUserFriendlyDuration(
                        startingDate,
                        endingDate,
                      )})`}{" "}
                    </Typography>
                  </Fragment>
                )}
              </div>
            )}
            <IconButton
              id="AUTOMATION_PROCESS_EXECUTION_DIALOG_BTN_CLOSE"
              onClick={onClose}
              className={classes.commonDialogHeaderCloseButton}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.contentWrapper}>
            <WorkflowLiveConsole
              processId={processId}
              processReference={processReference}
              processName={processName}
              processIcon={processIcon}
              processInstance={this.props.apiResponse}
              onEnded={onEnded}
              updateStatus={(apiResponse) => {
                this.setState({ apiResponse });
              }}
              onEvent={({ message, isError }) => {
                const { enqueueSnackbar, closeSnackbar } = this.props;
                enqueueSnackbar(message, {
                  variant: isError ? "error" : "success",
                  action: (key) => (
                    <SnackbarAction id={key} handleClose={closeSnackbar} />
                  ),
                });
              }}
              token={token}
              pollingInterval={pollingInterval}
              workflowStatus={workflowStatus}
              components={{
                ActionWrapper: ({ children, action }) => (
                  <DelegationProfiles
                    type={delegationProfileTypes.WORKFLOWS}
                    action={workflowActions[action].delegationName}
                  >
                    {children}
                  </DelegationProfiles>
                ),
                Dialog: MSADialog,
                MSAVariable: ExecutionVariableField,
              }}
              isMsaComponent={true}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

AutomationProcessExecutionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  apiResponse: PropTypes.object,
  token: PropTypes.string.isRequired,
  processId: PropTypes.number,
  processReference: PropTypes.string,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  processIcon: PropTypes.object.isRequired,
  processName: PropTypes.string.isRequired,
  onEnded: PropTypes.func,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  pollingInterval: getAutoRefreshSetting("pollingInterval")(state),
});

export default flow(
  connect(mapStateToProps),
  withSnackbar,
  withStyles(styles),
  withTranslation(),
  withRouter,
)(AutomationProcessExecutionDialog);
