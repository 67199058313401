import React, { useState } from "react";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { TABLE_HEADER_COLUMNS } from "msa2-ui/src/routes/ai/actions/AiActions";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import TableRow from "msa2-ui/src/components/TableRow";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { useSnackbar } from "notistack";
import { deleteAction } from "msa2-ui/src/api/ai";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

const AiActionsTableBody = ({ loading, actions, reload, error }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [actionToDelete, setActionToDelete] = useState(null);
  const [showDeleteDialog, DeleteDialog] = useDialog();

  const handleDelete = async () => {
    showDeleteDialog();

    const [error] = await deleteAction(actionToDelete.pk);

    if (error) {
      return enqueueSnackbar(
        error.getMessage(t("Unable to delete x", { x: actionToDelete.name })),
        { variant: "error" },
      );
    }

    enqueueSnackbar(t("x has been deleted", { x: actionToDelete.name }), {
      variant: "success",
    });

    reload();
  };

  if (loading) {
    return <TableLoading numberOfTableColumns={TABLE_HEADER_COLUMNS.length} />;
  }

  if (!actions || error || actions.length === 0) {
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching actions list")}. ${t(
                "Please reload the page",
              )}.`
            : t("No actions found")
        }
        numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
      />
    );
  }

  const emptyValue = "-";

  return (
    <TableBody>
      <DeleteDialog
        title={t("Delete x", { x: t("Action") })}
        content={t("Are you sure you want to delete x?", {
          x: actionToDelete?.name,
        })}
        onExec={handleDelete}
      />
      {actions.map((action, index) => (
        <TableRow
          key={index}
          actions={
            <DelegationProfiles
              type={delegationProfileTypes.AI}
              action="action.delete"
            >
              <IconButton
                id="AI_ACTION_BTN_DELETE"
                onClick={() => {
                  setActionToDelete(action);
                  showDeleteDialog();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </DelegationProfiles>
          }
        >
          {TABLE_HEADER_COLUMNS.map((column) => {
            const { id, primary, hidden } = column;

            if (hidden) return null;

            return (
              <TableCell
                key={id}
                className={commonClasses.commonTableCellDefault}
              >
                <Typography
                  variant="h4"
                  className={
                    primary
                      ? commonClasses.commonTablePrimary
                      : commonClasses.commonTableSecondary
                  }
                >
                  {action[id] || emptyValue}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default AiActionsTableBody;
