import { get, post } from "msa2-ui/src/api/request";

const API = process.env.REACT_APP_ML_AI_API_PATH;

const STATES_LIST_URL = `${API}/state/list`;
const INITIAL_STATES_LIST_URL = `${API}/state/initial_states`;
const STATE_CREATE_URL = `${API}/state/add`;
const STATE_DELETE_URL = `${API}/state/delete`;
const ACTIONS_LIST_URL = `${API}/action/list`;
const ACTION_CREATE_URL = `${API}/action/add`;
const ACTION_DELETE_URL = `${API}/action/delete`;
const ADD_TRANSITIONS_URL = `${API}/state/add_transition`;

const processStates = (response) => {
  const states = response.map((state) => {
    const { fields, model, pk } = state;
    const { name, tag, transition } = fields;

    return {
      name,
      tag,
      model,
      pk,
      transition: transition.length ? `[${transition.join(", ")}]` : undefined,
      transitions: transition,
    };
  });

  return {
    states: states || [],
    count: response.length,
  };
};

const processActions = (response) => {
  const actions = response.map((action) => {
    const { fields, model, pk } = action;

    return {
      model,
      pk,
      ...fields,
    };
  });

  return {
    actions: actions || [],
    count: response.length,
  };
};

export const getInitialStates = ({ finalStateId, transforms = [] }) => {
  return post({
    url: INITIAL_STATES_LIST_URL,
    transforms,
    body: {
      state_id: finalStateId,
    },
  });
};

export const getStates = () => {
  return get({
    url: STATES_LIST_URL,
  });
};

export const getStatesList = () => {
  return get({
    url: STATES_LIST_URL,
    transforms: [processStates],
  });
};

export const createState = ({ body }) => {
  return post({
    url: STATE_CREATE_URL,
    body,
  });
};

export const deleteState = (stateId) => {
  return post({
    url: STATE_DELETE_URL,
    body: {
      state_id: stateId,
    },
  });
};

export const getActionsList = () => {
  return get({
    url: ACTIONS_LIST_URL,
    transforms: [processActions],
  });
};

export const createAction = ({ body }) => {
  return post({
    url: ACTION_CREATE_URL,
    body,
  });
};

export const deleteAction = (actionId) => {
  return post({
    url: ACTION_DELETE_URL,
    body: {
      action_id: actionId,
    },
  });
};

export const getTransitions = () => {
  return get({
    url: `${API}/transition/list`,
  });
};

export const addTransitionsToState = ({ body }) => {
  return post({
    url: ADD_TRANSITIONS_URL,
    body,
  });
};

export const getStatesAndTransitions = async () => {
  const [statesResponse, transitionsResponse] = await Promise.all([
    getStates(),
    getTransitions(),
  ]);

  const error = statesResponse[0] || transitionsResponse[0];
  const response = {
    states: statesResponse[1] || [],
    transitions: transitionsResponse[1] || [],
  };

  return [error, response];
};

const transformWfs = (response) => {
  return (
    response?.output_actions?.map(
      ({
        name: displayName,
        execute: workflowPath,
        process_path: processName,
        process_type: processType,
      }) => ({
        displayName,
        workflowPath,
        processName,
        processType,
      }),
    ) || []
  );
};

export const generateWorkflows = ({
  environment = "gym_ubi:ubi-v0",
  learningRate,
  discountFactor,
  numberOfEpisodes,
  initialState,
  finalState,
  reward,
  initialContext = {},
  transforms = [],
}) =>
  post({
    url: `${API}/generate_workflows`,
    body: {
      environment,
      learningRate,
      discountFactor,
      numberOfEpisodes,
      initialState: initialState.toString(),
      finalState: finalState.toString(),
      reward,
      initialContext: initialContext,
    },
    transforms: [transformWfs, ...transforms],
  });
