import last from "lodash/last";
import get from "lodash/get";
import has from "lodash/has";
import every from "lodash/every";
import cloneDeep from "lodash/cloneDeep";
import Variable from "msa2-ui/src/services/Variable";

import { ReactComponent as IconArray } from "msa2-ui/src/assets/icons/import/importArray.svg";
import { ReactComponent as IconVariable } from "msa2-ui/src/assets/icons/import/importVariable.svg";
import { ReactComponent as IconVariableMultiple } from "msa2-ui/src/assets/icons/import/importVariableMultiple.svg";
import { ReactComponent as IconXpath } from "msa2-ui/src/assets/icons/import/importEntry.svg";
import { ReactComponent as IconVariableSet } from "msa2-ui/src/assets/icons/listCompact.svg";

const REGEX_PARSER_DEFAULT_VALUE = "@^(?<variable_name>.*)$@";
const XML_PARSER_DEFAULT_VALUE = "@<tag_name>(?<variable_name>.*)</tag_name>@";
const XPATH_DEFAULT_VALUE = "//entry";
const JSON_DEFAULT_VALUE = "$.entry";

export const CONFIGURATION_TYPES = {
  cli: {
    value: "cli",
    displayName: "CLI",
    rootParserLabel: "Microservice Identifier Extractor",
    editorMode: "txt",
    commandName: "Command",
    showExtraCommand: false,
    showCommandToRun: true,
  },
  xml: {
    value: "xml",
    displayName: "XML",
    rootParserLabel: "XPath of Microservice",
    commandName: "Rest Command",
    editorMode: "xml",
    showExtraCommand: true,
    showCommandToRun: true,
  },
  netconf: {
    value: "netconf",
    displayName: "NETCONF",
    rootParserLabel: "XPath of Microservice",
    commandName: "Rest Command",
    editorMode: "xml",
    showExtraCommand: true,
    showCommandToRun: false,
  },
  json: {
    value: "json",
    displayName: "JSON",
    rootParserLabel: "JSON Path of Microservice",
    commandName: "Rest Command",
    editorMode: "json",
    showExtraCommand: true,
    showCommandToRun: true,
  },
};

const microserviceObjectTypes = [
  {
    id: "parser",
    defaultValue: {
      cli: { section: [{ regexp: [] }], lines: { line: [], ignore: [] } },
      xml: { section: [{ xpath: [] }], lines: {} },
      netconf: { section: [{ xpath: [] }], lines: {} },
      json: { section: [{ xpath: [] }], lines: {} },
    },
  },
  {
    id: "lines",
    name: "array variable extractor set",
    icon: IconVariableSet,
    defaultValue: {
      cli: [{ line: [] }, { ignore: [] }],
      xml: [{ line: [] }],
      netconf: [{ line: [] }],
      json: [{ line: [] }],
    },
  },
  {
    id: "line",
    name: "Microservice Variable Extractor",
    children: {
      cli: ["regexp", "array"],
      xml: ["xpath", "array"],
      netconf: ["xpath", "array"],
      json: ["xpath", "array"],
    },
    defaultValue: [],
  },
  {
    id: "ignore",
    name: "Ignore Line Parsers",
    children: {
      cli: ["regexp"],
      xml: [],
      netconf: [],
      json: [],
    },
  },
  {
    id: "array",
    name: "array variable extractor",
    icon: IconArray,
    defaultValue: {
      cli: {
        name: "default_name",
      },
      xml: {
        name: "default_name",
        xpath: XPATH_DEFAULT_VALUE,
        lines: [{ line: [] }],
      },
      netconf: {
        name: "default_name",
        xpath: XPATH_DEFAULT_VALUE,
        lines: [{ line: [] }],
      },
      json: {
        name: "default_name",
        xpath: JSON_DEFAULT_VALUE,
        lines: [{ line: [] }],
      },
    },
    children: {
      cli: ["regexp", "mregexp", "lines"],
      xml: ["xpath", "lines"],
      netconf: ["xpath", "lines"],
      json: ["xpath", "lines"],
    },
  },
  {
    id: "regexp",
    name: "variable extractor",
    icon: IconVariable,
    defaultValue: {
      cli: REGEX_PARSER_DEFAULT_VALUE,
      xpath: XML_PARSER_DEFAULT_VALUE,
      lines: XML_PARSER_DEFAULT_VALUE,
      json: REGEX_PARSER_DEFAULT_VALUE,
    },
  },
  {
    id: "mregexp",
    name: "variable extractor (multiple)",
    icon: IconVariableMultiple,
    defaultValue: REGEX_PARSER_DEFAULT_VALUE,
  },
  {
    id: "xpath",
    name: {
      xml: "XPath of variable",
      netconf: "XPath of variable",
      json: "JSON Path of variable",
    },
    icon: IconXpath,
    defaultValue: {
      xml: XPATH_DEFAULT_VALUE,
      netconf: XPATH_DEFAULT_VALUE,
      json: JSON_DEFAULT_VALUE,
    },
    sibling: {
      xml: { regexp: XML_PARSER_DEFAULT_VALUE },
      netconf: { regexp: XML_PARSER_DEFAULT_VALUE },
      json: { regexp: REGEX_PARSER_DEFAULT_VALUE },
    },
    children: {
      xml: [],
      netconf: [],
      json: [],
    },
  },
];

const getParserDefinitionByKey = (key, configType) => {
  const parserDefinition = microserviceObjectTypes.find(
    (parser) => parser.id === key,
  );
  if (!parserDefinition) return;

  return configType
    ? Object.entries(parserDefinition).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value?.[configType] ?? value,
        }),
        {},
      )
    : parserDefinition;
};

/**
 * Since Parser object is a tree structure, we face the situation
 * that we have to catch a parent path and object.
 * This function gets parent's path and object.
 * @example
 * If you pass "command.IMPORT.parser.lines.line.1.array.lines",
 * the path will be "command.IMPORT.parser.lines.line.1.array"
 * and the object would be like,
 * {
 *   lines: [{ line: [{ regex: "regex" }] }],
 *   name: "array_name",
 * }
 * "key" will be "lines".
 * @param {object} parserObject
 * @param {string} parserPath This should be a full path from the root path of "parserObject"
 * @returns {array} [parentObject, parentPath, key] see @example
 */
const getParent = (parserObject, parserPath) => {
  const key = last(parserPath.split("."));
  const parentPath = parserPath
    .split(".")
    .slice(0, -1)
    .join(".");
  const parentObject = get(cloneDeep(parserObject), parentPath);
  return [parentObject, parentPath, key];
};

/**
 * These 2 functions converts from/to variables and parser
 * object_id <=> (?<object_id>)
 */
const wrapVariable = (variableName) =>
  `(?<${Variable.removePrefix(variableName)}>)`;
const pickVariables = (parser) =>
  parser.match(/\(\?<\S+?(?=>)/g)?.map((variable) => variable.slice(3)) || [];

/**
 * Compare passed variable names to the existing variable object,
 * then if there are new variable names, return them as variable objects.
 * @param {array} existingVariables The array of variable object
 * @param {array} inputVariableNames The array of variable name
 * @returns {array} The array of new variable object
 */
const getVariablesToAdd = (existingVariables, inputVariableNames) => {
  return Variable.getVariablesToAdd(
    existingVariables,
    inputVariableNames.map((variableName) => ({
      name: [Variable.variablePrefix, variableName].join("."),
    })),
  );
};

/**
 * xpath should be rendered as a parent of regexp but they are sibling in the object.
 * so regexp which is a sibling of xpath should be skipped and rendered with xpath rendering.
 */
const shouldRenderAsChild = (formObject, path, configType) => {
  const [parentObject, , key] = getParent(formObject, path);
  // if configType is cli, Parser should not have xpath object but sometimes API returns it
  // hence it returns false for cli here.
  if (key !== "regexp" || configType === "cli") return false;
  return has(parentObject, "xpath");
};

/**
 * This describes if passed object has valid value as children.
 * If all siblings are null, the parent object should also be deleted.
 */
const allSiblingsAreNull = (parentObject) =>
  parentObject === {} ||
  every(Object.values(parentObject), (value) => value === null);

export default {
  microserviceObjectTypes,
  getParserDefinitionByKey,
  getParent,
  wrapVariable,
  pickVariables,
  getVariablesToAdd,
  shouldRenderAsChild,
  allSiblingsAreNull,
};
