import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import { union, sortBy, get, set } from "lodash";

import { getToken, getIsDeveloper } from "msa2-ui/src/store/auth";
import useDialog from "msa2-ui/src/hooks/useDialog";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  listOrderStack,
  clearOrderStack,
  applyConfigurationToManagedEntityAsync,
  getPushConfigurationStatus,
  synchronizeConfiguration,
  getSynchronizeConfigurationStatus,
} from "msa2-ui/src/api/orderStack";
import { getDeploymentSetting } from "msa2-ui/src/api/deploymentSettings";
import Repository from "msa2-ui/src/services/Repository";
import { filter } from "msa2-ui/src/utils/filter";

import { IconButton, makeStyles } from "@material-ui/core";
import {
  Button,
  Grid,
  Paper,
  CircularProgress,
  SvgIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Sync,
  ViewCompact,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import TreeItem from "msa2-ui/src/components/TreeItem";
import TreeView from "msa2-ui/src/components/TreeView";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import AlertBar from "msa2-ui/src/components/AlertBar";
import ManagedEntityConfigureTable from "./ManagedEntityConfigureTable";
import {
  TreeFolderComponent,
  TreeItemComponent,
  TreeItemReadOnlyComponent,
} from "msa2-ui/src/components/msa-console/ManagedEntityConfigureTree";
import { wait } from "msa2-ui/src/utils/wait";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const useStyles = makeStyles(
  ({ breakpoints, darkMode, palette, typography, transitions, colors }) => ({
    paper: {
      padding: 0,
    },
    noDeploymentSettingMessage: {
      padding: "50px 0",
    },
    grid: {
      padding: 16,
    },
    gridSide: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    title: {
      fontSize: "0.8125rem",
      fontWeight: typography.fontWeightMedium,
      letterSpacing: 0.5,
      color: palette.text.primary,
      marginLeft: 12,
    },
    changeButton: {
      fontSize: "0.8125rem",
      fontWeight: 500,
      letterSpacing: 0.25,
      color: palette.primary.main,
      marginLeft: 12,
    },
    toolbarButton: {
      marginRight: 10,
    },
    toolbarMessage: {
      fontSize: "0.8125rem",
      letterSpacing: 0.25,
    },
    synchronizeIcon: {
      marginRight: 5,
    },
    treeView: {
      height: "calc(100% - 58px)",
      overflowY: "auto",
    },
    treeViewLoadingIndicator: {
      padding: 20,
    },
    toolbar: {
      borderBottom: `1px solid ${palette.text.hint}`,
      backgroundColor: palette.background.paper,
      zIndex: 2,
    },
    console: {
      height: "100%",
      width: "75%",
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    consoleOpen: {
      width: "calc(100% - 100px)",
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    consoleSidebar: {
      borderRight: `1px solid ${palette.text.hint}`,
      zIndex: 1,
      width: "25%",
      height: "100%",
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    consoleSidebarClosed: {
      width: 100,
      flexBasis: "unset",
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    chevrons: {
      color: "#fff",
      [breakpoints.down("md")]: {
        position: "absolute",
        top: 12,
      },
    },
    menuToggle: {
      margin: 5,
    },
  }),
);

export const MicroserviceConsole = ({
  deviceId,
  deploymentSetting,
  orderStack,
  reloadOrderStack,
  isEditVariableEnabled,
  isBulkOperationEnabled,
  isFilterVariableEnabled = true,
  isDeveloper = false,
  selectedMicroserviceName = "",
  maintenanceMode,
  filterItemClickCallback = () => {},
  showTreeView = true,
}) => {
  const classes = useStyles();

  const [filterValue, setFilterValue] = useState("");
  const [showMenu, setShowMenu] = useState(true);
  const [selectedMicroservice, setSelectedMicroservice] = useState(
    selectedMicroserviceName,
  );

  const handleFilterItemClick = (microservice) => {
    filterItemClickCallback(microservice);
    const { baseFilename } = microservice;
    setSelectedMicroservice(baseFilename);
  };

  const filteredMicroservices = deploymentSetting
    ? filter(deploymentSetting.microservices, filterValue, [
        "baseFilename",
        "name",
      ])
    : [];

  const structuredMicroservices = {
    title: deploymentSetting.name,
    childs: (() => {
      if (!showTreeView) {
        return filteredMicroservices;
      }
      const root = {
        childs: [],
      };
      const sorted = sortBy(
        filteredMicroservices,
        ({ directoryNames = [] }) => directoryNames.length,
      );

      // Form Tree structure with empty objects and childs
      sorted.forEach((k) => {
        const { childs = [] } = get(root, k.directoryNames) || {};
        if (!k.directoryNames?.length) {
          root.childs = [...root.childs, k];
        } else {
          set(root, k.directoryNames, {
            childs: [...childs, k],
          });
        }
      });

      // Update individual Node's childs
      const updateNode = (o = {}) => {
        const { childs = [], ...rest } = o;
        Object.keys(rest).forEach((k) => {
          childs.push({
            title: k || "Default",
            childs: updateNode(rest[k]),
          });
        });
        return childs;
      };
      return updateNode(root);
    })(),
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const renderRecursiveTreeView = (node = {}) => {
    const {
      title,
      childs = [],
      baseFilename,
      displayName,
      editRoutePath,
    } = node;

    if (!childs.length) {
      return (
        <TreeItem
          key={baseFilename}
          label={showMenu ? displayName : ""}
          isDeveloper={isDeveloper}
          deviceId={deviceId}
          path={editRoutePath}
          selected={selectedMicroservice === baseFilename}
          onClick={() => handleFilterItemClick(node)}
        />
      );
    }

    return (
      <TreeItem
        key={title}
        label={showMenu ? title : ""}
        countChildren={childs.length}
        hideCount={!showMenu}
      >
        {childs.map((child) => renderRecursiveTreeView(child))}
      </TreeItem>
    );
  };

  return (
    <>
      <Grid
        className={classNames(classes.consoleSidebar, {
          [classes.consoleSidebarClosed]: !showMenu,
        })}
      >
        <Grid
          container
          item
          alignItems="center"
          justifyContent={showMenu ? "space-between" : "flex-end"}
        >
          {showMenu && (
            <Grid
              item
              className={classNames([classes.borderBottom, classes.gridSide])}
            >
              <FilterMenu
                handleSearchByChange={setFilterValue}
                searchValue={filterValue}
              />
            </Grid>
          )}
          <Grid item className={classes.menuToggle}>
            <IconButton
              className={classes.chevrons}
              onClick={toggleMenu}
              aria-label="Open menu"
            >
              {showMenu ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classNames([classes.treeView, classes.grid])}
        >
          <TreeView
            folderComponent={TreeFolderComponent}
            itemComponent={
              showMenu ? TreeItemComponent : TreeItemReadOnlyComponent
            }
          >
            {renderRecursiveTreeView(structuredMicroservices)}
          </TreeView>
        </Grid>
      </Grid>
      <Grid
        className={classNames(classes.console, {
          [classes.consoleOpen]: !showMenu,
        })}
      >
        {selectedMicroservice &&
          deploymentSetting &&
          (() => {
            const filteredOrderStack = orderStack.filter(({ parameters }) =>
              Boolean(parameters[selectedMicroservice]),
            );
            const microserviceUri = deploymentSetting.microservices.find(
              ({ baseFilename }) => baseFilename === selectedMicroservice,
            )?.uri;
            return (
              <ManagedEntityConfigureTable
                key={microserviceUri}
                microserviceUri={microserviceUri}
                orderStack={filteredOrderStack}
                onAddToOrderStack={reloadOrderStack}
                deviceId={deviceId}
                maintenanceMode={maintenanceMode}
                isBulkOperationEnabled={isBulkOperationEnabled}
                isEditVariableEnabled={isEditVariableEnabled}
                isFilterVariableEnabled={isFilterVariableEnabled}
              />
            );
          })()}
      </Grid>
    </>
  );
};

const ManagedEntityConfigureTab = ({
  managedEntity: { deviceID: deviceId, configProfileId: deploymentSettingId },
  maintenanceMode,
}) => {
  const isBulkOperationEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.msBulkOperation,
  );
  const isEditVariableEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.msEditVariableInConsole,
  );

  const { t } = useTranslation();
  const discardMessage = t("Are you sure you want to discard your changes?");

  const { assetId: managedEntityId, microserviceName } = useParams();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const token = useSelector(getToken);
  const isDeveloper = useSelector(getIsDeveloper);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showDiscardDialog, DiscardDialog] = useDialog();
  const [showApplyDialog, ApplyDialog] = useDialog();
  const [showSynchronizeDialog, SynchronizeDialog] = useDialog();
  const [dialogMessage, setDialogMessage] = useState("");
  const [splitCount, setSplitCount] = useState(1);

  const history = useHistory();
  const formatMicroservice = (response) => {
    const { microserviceUris, microservicesByUri } = response;
    const microserviceByBaseFileName = {};

    const microservices = microserviceUris.map((uri) => ({
      uri,
      groups: microservicesByUri[uri].groups,
      directoryNames: microservicesByUri[uri].directoryNames || [],
      baseFilename: Repository.stripFolderPathAndFileExtensionFromUri(uri),
      displayName: microservicesByUri[uri].name,
      editRoutePath: `/integration/microservices/${encodeURIComponent(
        uri,
      )}/edit`,
    }));

    microservices.forEach(
      (microservice) =>
        (microserviceByBaseFileName[microservice.baseFilename] = microservice),
    );
    return { ...response, microservices, microserviceByBaseFileName };
  };

  const formatOrderStack = (response) =>
    response.map((order) => ({
      ...order,
      baseFilename: Object.keys(order?.parameters)[0],
    }));

  // Rather than being null the deployment setting id is 0 when no deployment setting is assigned
  const hasDeploymentSetting = deploymentSettingId !== 0;
  const [
    deploymentSettingIsLoading,
    deploymentSettingError,
    deploymentSetting,
    ,
    reloadDeploymentSetting,
  ] = useApi(
    getDeploymentSetting,
    {
      deploymentSettingId,
      filterByLabel: isPermissionProfileLabelsEnabled,
      transforms: [formatMicroservice],
    },
    !hasDeploymentSetting,
  );

  // Get instance data for the managed entity's microservices
  const [, , orderStack = [], , reloadOrderStack] = useApi(listOrderStack, {
    deviceId: managedEntityId,
    transforms: [formatOrderStack],
  });

  const handleDiscard = async () => {
    const [error] = await clearOrderStack({
      deviceId,
      token,
    });
    let message, variant;
    if (error) {
      message = error.getMessage(t("Unable to clear configuration."));
      variant = "error";
    } else {
      message = t("Configuration has been cleared.");
      variant = "success";
      reloadOrderStack();
    }
    enqueueSnackbar(t(message), {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  const handleApply = async () => {
    setDialogMessage(t("Applying changes..."));

    const modifiedMSFileNames = union(
      orderStack.map(({ baseFilename }) => baseFilename),
    );
    const microServiceUris = modifiedMSFileNames.map(
      (baseFilename) =>
        deploymentSetting.microserviceByBaseFileName[baseFilename]?.uri,
    );

    const [error] = await applyConfigurationToManagedEntityAsync({
      deviceId,
      token,
    });
    let finalMessage, variant;
    if (error) {
      finalMessage = error.getMessage(
        t(
          "Some error occurred while applying changes. Please check the managed entity status.",
        ),
      );
      variant = "error";
    } else {
      let applyInProgress = true;
      while (applyInProgress) {
        await wait(pollingInterval);
        const [, { status, message } = {}] = await getPushConfigurationStatus({
          deviceId,
          token,
        });

        if (["NONE", "RUNNING"].includes(status)) {
          setDialogMessage(message);
        } else {
          applyInProgress = false;
          finalMessage = message;
          if (status === "FAIL") {
            variant = "error";
          }
          if (status === "ENDED") {
            finalMessage = t("Configuration has been applied.");
            variant = "success";
          }
        }
      }
    }
    enqueueSnackbar(t(finalMessage), {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (variant === "success") {
      const importWithUpperRankFlag = FeatureFlag.isEnabled(
        FeatureFlag.features.importWithUpperRank,
      );
      const importWithSameAndUpperRankFlag = FeatureFlag.isEnabled(
        FeatureFlag.features.importWithSameAndUpperRank,
      );
      if (importWithUpperRankFlag || importWithSameAndUpperRankFlag) {
        await handleSynchronize(microServiceUris);
      } else {
        await handleSynchronize([]);
      }
    }

    showApplyDialog(false);
    setDialogMessage("");
  };

  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));
  const handleSynchronize = async (microServiceUris = []) => {
    setDialogMessage(t("Synchronizing with Managed Entity..."));
    const importWithUpperRankFlag = FeatureFlag.isEnabled(
      FeatureFlag.features.importWithUpperRank,
    );
    const importWithSameAndUpperRankFlag = FeatureFlag.isEnabled(
      FeatureFlag.features.importWithSameAndUpperRank,
    );

    const [error] = await synchronizeConfiguration({
      deviceId,
      isAsync: true,
      microServiceUris,
      token,
      importWithUpperRank: importWithUpperRankFlag,
      importWithSameAndUpperRank: importWithSameAndUpperRankFlag,
    });
    let finalMessage, variant;
    if (error) {
      finalMessage = error.getMessage(
        t("Unable to synchronize with Managed Entity."),
      );
      variant = "error";
    } else {
      let syncInProgress = true;
      while (syncInProgress) {
        await wait(pollingInterval);
        const [
          error,
          syncResponse = {},
        ] = await getSynchronizeConfigurationStatus({
          deviceId,
          token,
        });
        if (error) {
          syncInProgress = false;
          finalMessage = error.getMessage();
          variant = "error";
        } else {
          const { syncStatus, orderResult = {} } = syncResponse;
          const { message } = orderResult;
          if (syncStatus === "RUNNING") {
            const microserviceObjects = Object.entries(JSON.parse(message));
            const importingObject =
              microserviceObjects.find(
                ([, { status }]) => status === "execute",
              ) ?? microserviceObjects[0];

            if (importingObject) {
              const microservice = deploymentSetting.microservices.find(
                ({ baseFilename }) => baseFilename === importingObject[0],
              );
              const { step, total_steps } = importingObject[1];
              if (microservice?.displayName) {
                setDialogMessage(
                  `${t("Importing", {
                    name: microservice.displayName,
                  })} [ ${step} / ${total_steps} ]`,
                );
              }
            }
          } else {
            syncInProgress = false;
            finalMessage = message;

            if (syncStatus === "ENDED") {
              finalMessage = t(
                "Configuration has been synchronized with Managed Entity.",
              );
              variant = "success";
            } else if (syncStatus === "FAIL") {
              variant = "error";
            }
          }
        }
      }
      reloadOrderStack();
      reloadDeploymentSetting();
    }
    enqueueSnackbar(t(finalMessage), {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    showSynchronizeDialog(false);
    setDialogMessage("");
  };

  useEffect(() => {
    if (deploymentSetting) {
      (deploymentSetting.microservices || []).forEach((microservice) => {
        const { baseFilename } = microservice;

        if (baseFilename === microserviceName) {
          history.push({ state: { microServiceUri: microservice.uri } });
        }
      });
    }
  }, [deploymentSetting, history, microserviceName]);

  const renderLoader = ({
    className = commonClasses.commonLoaderWrapper,
    alignItems = "center",
    justifyContent = "center",
  } = {}) => (
    <Grid
      container
      className={className}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      <CircularProgress />
    </Grid>
  );

  const renderAlertBar = () => (
    <AlertBar
      message={t("Unable to load x", {
        x: t("Deployment Settings"),
      })}
      refreshFnc={reloadDeploymentSetting}
      adjust
    />
  );

  const renderEmptyDeployment = () => (
    <Grid container justifyContent="center">
      <Typography className={classes.noDeploymentSettingMessage}>
        {t(
          "There is no Deployment Setting associated with this Managed Entity",
        )}
      </Typography>
    </Grid>
  );

  const renderFilterMenu = () => (
    <FilterMenu
      handleViewAsChange={(_, value) => {
        setSplitCount(value + 1);
      }}
      viewAsIcons={[
        <Tooltip title={"Normal View"}>
          <ViewCompact />
        </Tooltip>,
        <Tooltip title={t("Split View")}>
          <SvgIcon>
            <path d="M3 19h6v-4H3v4zm7 0h12v-4H10v4zM3 14h6v-4H3v4zm7 0h12v-4H10v4zM3 5v4h19V5H3z" />
          </SvgIcon>
        </Tooltip>,
      ]}
    />
  );

  const renderActionButtons = () => (
    <>
      <Button
        id="MANAGED_ENTITY_DISCARD_BTN"
        variant="text"
        size="medium"
        color="primary"
        className={classes.toolbarButton}
        onClick={showDiscardDialog}
        disabled={false}
      >
        {t("Discard Changes")}
      </Button>
      <DiscardDialog
        title={t("Discard changes?")}
        content={discardMessage}
        onExec={handleDiscard}
      />
      <Button
        id="MANAGED_ENTITY_APPLY_BTN"
        variant="contained"
        size="medium"
        color="primary"
        className={classes.toolbarButton}
        onClick={showApplyDialog}
        disabled={false}
      >
        {t("Apply Changes")}
      </Button>
      <ApplyDialog
        title={t("Apply Changes?")}
        content={dialogMessage || t("Are you sure you want to apply changes?")}
        onExec={handleApply}
        disabled={Boolean(dialogMessage)}
        shouldHideOnExec={false}
      >
        {Boolean(dialogMessage) && <CircularProgress />}
      </ApplyDialog>
    </>
  );

  const renderDelegationProfiles = () => (
    <DelegationProfiles
      type={delegationProfileTypes.MANAGED_ENTITIES}
      action="obmf.import"
    >
      <Button
        id="MANAGED_ENTITY_SYNCHRONIZE_BTN"
        variant="text"
        size="medium"
        color="primary"
        className={classes.toolbarButton}
        onClick={showSynchronizeDialog}
        disabled={false}
      >
        <Sync className={classes.synchronizeIcon} />
        {t("Synchronize with Managed Entity")}
      </Button>
      <SynchronizeDialog
        title={t("Synchronize with Managed Entity?")}
        content={
          dialogMessage ||
          t("Are you sure you want to synchronize with Managed Entity?")
        }
        onExec={handleSynchronize}
        disabled={Boolean(dialogMessage)}
        shouldHideOnExec={false}
      >
        {Boolean(dialogMessage) && <CircularProgress />}
      </SynchronizeDialog>
    </DelegationProfiles>
  );

  const renderMicroserviceConsole = () =>
    [...Array(splitCount)].map((_, i) => {
      // For split view we are changing route whenever we select item from first section only
      const handleFilterItemClick = (microservice) => {
        if (i === 0) {
          const { baseFilename, uri } = microservice;

          history.push({
            pathname: `/integration/managed-entities/${parseInt(
              deviceId,
            )}/configure/${baseFilename}`,
            state: { microServiceUri: uri },
          });
        }
      };

      return (
        <Grid
          key={i}
          item
          xs={12}
          className={classes.toolbar}
          container
          style={{
            height: `calc((100vh - 330px) / ${splitCount})`,
          }}
        >
          <MicroserviceConsole
            deviceId={deviceId}
            deploymentSetting={deploymentSetting}
            orderStack={orderStack}
            reloadOrderStack={reloadOrderStack}
            isBulkOperationEnabled={isBulkOperationEnabled}
            isEditVariableEnabled={isEditVariableEnabled}
            selectedMicroserviceName={i === 0 ? microserviceName : ""}
            filterItemClickCallback={handleFilterItemClick}
            isDeveloper={isDeveloper}
            maintenanceMode={maintenanceMode}
          />
        </Grid>
      );
    });

  return (
    <>
      <Grid item xs={12}>
        <Paper
          id="MANAGED_ENTITY_CONFIGURE_TREE"
          className={classNames([commonClasses.commonPaper, classes.paper])}
        >
          {deploymentSettingIsLoading && renderLoader()}
          {deploymentSettingError &&
            !deploymentSettingIsLoading &&
            renderAlertBar()}
          {!hasDeploymentSetting &&
            !deploymentSettingIsLoading &&
            renderEmptyDeployment()}
          {!deploymentSettingIsLoading && deploymentSetting && (
            <Grid container>
              <Grid
                item
                xs={12}
                className={classNames([classes.toolbar, classes.grid])}
                container
              >
                <Grid item xs={8} container>
                  {renderFilterMenu()}
                </Grid>
                {maintenanceMode ? null : (
                  <Grid
                    item
                    xs={4}
                    container
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    {orderStack.length
                      ? renderActionButtons()
                      : renderDelegationProfiles()}
                  </Grid>
                )}
              </Grid>
              {renderMicroserviceConsole()}
            </Grid>
          )}
        </Paper>
      </Grid>
    </>
  );
};

ManagedEntityConfigureTab.propTypes = {
  managedEntity: PropTypes.object.isRequired,
};

export default ManagedEntityConfigureTab;
