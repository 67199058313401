import React, { useMemo, useState } from "react";

import AutomationDetailDrawer from "msa2-ui/src/routes/automation/workflows/detail/AutomationDetailDrawer";

const useWorkflowDrawer = () => {
  const [show, setShow] = useState(false);
  const initDrawer = {
    serviceId: null,
    displayName: "",
    processName: {},
    tab: 0,
    workflowPath: "",
  };
  const [drawer, setDrawer] = useState(initDrawer);

  const modal = useMemo(
    () => ({ workflowDefinition, onClose }) => {
      if (show) {
        const objProcess = workflowDefinition?.process.find(
          (prc) => prc.name === drawer.processName,
        );
        return (
          <AutomationDetailDrawer
            onClose={() => {
              onClose && onClose();
              setDrawer(initDrawer);
              setShow(false);
            }}
            tab={drawer.tab}
            workflow={workflowDefinition}
            instanceId={drawer.serviceId}
            instanceName={drawer.displayName}
            execProcess={objProcess}
            workflowPath={drawer.workflowPath}
            ubiqubeId={drawer.ubiqubeId}
          />
        );
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  );

  return [
    ({ tab = 0, ...rest }) => {
      setShow(true);
      setDrawer({ tab, ...rest });
    },
    modal,
  ];
};

export default useWorkflowDrawer;
