import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import get from "lodash/get";

import { buildRoute } from "msa2-ui/src/utils/urls";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import Utility from "../Functions";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";

import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";
import { ReactComponent as IconEdit } from "msa2-ui/src/assets/icons/edit.svg";
import { FileCopyOutlined as IconCopy } from "@material-ui/icons";
import Dialog from "msa2-ui/src/components/Dialog";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

const localStyles = ({ palette, darkMode }) => ({
  tableSecondary: {
    color: palette.text.secondary,
    fontWeight: "200",
    fontSize: "0.8rem",
    textDecoration: "none",
    marginRight: 5,
  },
  statusIcon: {
    background: "transparent",
    width: 20,
    height: 20,
  },
  negativeMargins: {
    marginLeft: "-16px",
    marginRight: "-16px",
  },
  actionsHidden: {
    visibility: "hidden",
  },
  haIcon: {
    border: `solid 1px ${
      darkMode ? palette.text.secondary : "rgba(191, 201, 217, 0.6)"
    }`,
    fontSize: 15,
    fontWeight: "bold",
    color: palette.text.primary,
    marginRight: 5,
  },
});
const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

const groupByOptions = [
  {
    id: "",
    text: "None",
  },
];

class ManagedEntityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDialogIsOpen: false,
      groupBy: groupByOptions[0].id,
      sortBy: "",
      sortAscending: true,
      page: 0,
      rowsPerPage: 20,
      total: 0,
      selected: "",
    };
  }

  sortTable = (list) => {
    if (this.state.sortBy === "") {
      return list;
    }

    const sortKey = this.state.sortBy;
    const ascending = this.state.sortAscending;

    const cloneList = [...list];
    cloneList.sort(function(a, b) {
      if (a[sortKey] < b[sortKey]) {
        if (ascending) {
          return -1;
        }
        return 1;
      }
      if (a[sortKey] > b[sortKey]) {
        if (ascending) {
          return 1;
        }
        return -1;
      }
      return 0;
    });

    return cloneList;
  };

  // functions for tables
  handleSelectRow = (key) => {
    this.setState({ selected: key });
  };

  handleDeselectRow = () => {
    this.setState({ selected: "" });
  };

  handleOnClickName = (event) => {
    //TODO
  };

  handleOpenDeleteDeviceDialog = (id) => {
    this.setState({ deleteDialogIsOpen: true, managedEntityToDelete: id });
  };

  handleCloseDeleteDeviceDialog = () => {
    this.setState({ deleteDialogIsOpen: false, managedEntityToDelete: null });
  };

  render() {
    const {
      classes,
      managedEntities,
      t,
      match,
      handleDeleteDevice,
      isManager,
    } = this.props;
    const { managedEntityToDelete, selected } = this.state;
    const managedEntitiesSorted = this.sortTable(managedEntities);

    const isUiHaDisplayEnabled = FeatureFlag.isEnabled(
      FeatureFlag.features.haDisplay,
    );

    const isOtherFieldsVisibleInHaView = !(isUiHaDisplayEnabled && isManager);

    return (
      <Fragment>
        {this.state.deleteDialogIsOpen && (
          <Dialog
            onClose={this.handleCloseDeleteDeviceDialog}
            onExec={() => handleDeleteDevice(managedEntityToDelete.deviceId.id)}
            title={t("Confirm Request")}
            content={t("Are you sure you want to remove", {
              name: managedEntityToDelete.name,
            })}
          />
        )}
        <Paper className={classes.negativeMargins}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.commonTableHeadRow}>
                <TableCell
                  key="status"
                  align="left"
                  classes={{ root: classes.commonTableCell }}
                >
                  {t("Status")}
                </TableCell>
                <TableCell
                  key="name"
                  align="left"
                  classes={{ root: classes.commonTableCell }}
                >
                  {t("Managed Entity Name")}
                </TableCell>
                {isUiHaDisplayEnabled && (
                  <TableCell
                    key="peerName"
                    align="left"
                    classes={{ root: classes.commonTableCell }}
                  >
                    {t("HA Peer Name")}
                  </TableCell>
                )}
                {isOtherFieldsVisibleInHaView && (
                  <TableCell
                    key="tenant"
                    align="left"
                    classes={{ root: classes.commonTableCell }}
                  >
                    {t("Tenant")}
                  </TableCell>
                )}
                <TableCell
                  key="subtenant"
                  align="left"
                  classes={{ root: classes.commonTableCell }}
                >
                  {t("Subtenant")}
                </TableCell>
                {isOtherFieldsVisibleInHaView && (
                  <TableCell
                    key="vendor"
                    align="left"
                    classes={{ root: classes.commonTableCell }}
                  >
                    {t("Vendor")}
                  </TableCell>
                )}
                {isOtherFieldsVisibleInHaView && (
                  <TableCell
                    key="Model"
                    align="left"
                    classes={{ root: classes.commonTableCell }}
                  >
                    {t("Model")}
                  </TableCell>
                )}
                {isOtherFieldsVisibleInHaView && (
                  <TableCell
                    key="ipConfig"
                    align="left"
                    classes={{ root: classes.commonTableCell }}
                  >
                    {t("IP Address")}
                  </TableCell>
                )}
                {isUiHaDisplayEnabled && (
                  <TableCell
                    key="groupname"
                    align="left"
                    classes={{ root: classes.commonTableCell }}
                  >
                    {t("Region/Group Name")}
                  </TableCell>
                )}
                <TableCell
                  key="edit"
                  align="left"
                  classes={{ root: classes.commonTableCell }}
                />
              </TableRow>
            </TableHead>

            <TableBody>
              {managedEntitiesSorted.map((managedEntity, index) => {
                const deviceId = get(managedEntity, "deviceID", null);

                return (
                  <TableRow
                    key={index}
                    onMouseLeave={this.handleDeselectRow}
                    onMouseEnter={() => this.handleSelectRow(index)}
                    onClick={() => this.handleSelectRow(index)}
                    className={classes.commonTableRowHighlight}
                  >
                    {/*status*/}
                    <TableCell classes={{ root: classes.commonTableCell }}>
                      {Utility.chooseAvatar(
                        managedEntity.pingStatus,
                        classes,
                        managedEntity?.maintenanceMode,
                      )}
                    </TableCell>

                    {/*Managed Entity name and tag*/}
                    <TableCell
                      align="left"
                      classes={{ root: classes.commonTableCell }}
                    >
                      <div className={classes.commonFlexStartRow}>
                        {isUiHaDisplayEnabled &&
                          managedEntity.isHa &&
                          Utility.chooseHAAvatar(
                            Utility.getManageEntityHaStatus(
                              managedEntity.haType,
                              false,
                            ),
                            classes,
                          )}
                        <Typography
                          variant="body2"
                          component={Link}
                          id="MANAGED_ENTITY_DETAIL_LINK"
                          to={{
                            pathname: buildRoute(
                              match.url,
                              `${managedEntity.deviceId.id}`,
                            ),
                          }}
                          className={classes.tableSecondary}
                          title={t("Managed Entity Id", {
                            deviceId,
                          })}
                        >
                          {isUiHaDisplayEnabled
                            ? `${managedEntity.name} -
                                ${managedEntity.deviceId.ubiId}`
                            : managedEntity.name}
                        </Typography>
                        {Utility.createTag(
                          managedEntity.deviceNature
                            ? managedEntity.deviceNature
                            : "",
                          classes,
                        )}
                      </div>
                    </TableCell>
                    {/* HA Peer Name */}
                    {isUiHaDisplayEnabled && (
                      <TableCell
                        align="left"
                        classes={{ root: classes.commonTableCell }}
                      >
                        <div className={classes.commonFlexStartRow}>
                          {Utility.chooseHAAvatar(
                            Utility.getManageEntityHaStatus(
                              managedEntity.haType,
                              true,
                            ),
                            classes,
                          )}
                          <Typography
                            variant="body2"
                            className={classes.tableSecondary}
                          >
                            {managedEntity.isHa
                              ? `${managedEntity.haPeerName} -
                                ${managedEntity.haPeerId.ubiId}`
                              : ""}
                          </Typography>
                        </div>
                      </TableCell>
                    )}
                    {/* Tenant */}
                    {isOtherFieldsVisibleInHaView && (
                      <TableCell
                        align="left"
                        classes={{ root: classes.commonTableCell }}
                      >
                        <div className={classes.commonFlexStartRow}>
                          <Typography
                            variant="body2"
                            className={classes.tableSecondary}
                          >
                            {managedEntity.tenantName}
                          </Typography>
                        </div>
                      </TableCell>
                    )}
                    {/* Subtenant */}
                    <TableCell
                      align="left"
                      classes={{ root: classes.commonTableCell }}
                    >
                      <div className={classes.commonFlexStartRow}>
                        <Typography
                          variant="body2"
                          className={classes.tableSecondary}
                        >
                          {managedEntity.subtenantName}
                        </Typography>
                      </div>
                    </TableCell>
                    {/* Manufacturer */}
                    {isOtherFieldsVisibleInHaView && (
                      <TableCell
                        align="left"
                        classes={{ root: classes.commonTableCell }}
                      >
                        <div className={classes.commonFlexStartRow}>
                          <Typography
                            variant="body2"
                            className={classes.tableSecondary}
                          >
                            {managedEntity.manufacturerName}
                          </Typography>
                        </div>
                      </TableCell>
                    )}
                    {/* Model */}
                    {isOtherFieldsVisibleInHaView && (
                      <TableCell
                        align="left"
                        classes={{ root: classes.commonTableCell }}
                      >
                        <div className={classes.commonFlexStartRow}>
                          <Typography
                            variant="body2"
                            className={classes.tableSecondary}
                          >
                            {managedEntity.modelName}
                          </Typography>
                        </div>
                      </TableCell>
                    )}
                    {/*Ip address*/}
                    {isOtherFieldsVisibleInHaView && (
                      <TableCell
                        align="left"
                        classes={{ root: classes.commonTableCell }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.tableSecondary}
                        >
                          {managedEntity.ipConfig ? managedEntity.ipConfig : ""}
                        </Typography>
                      </TableCell>
                    )}
                    {/*Region / Group Name*/}
                    <TableCell
                      align="left"
                      classes={{ root: classes.commonTableCell }}
                    >
                      {isUiHaDisplayEnabled && !managedEntity.isOpenStack && (
                        <Typography
                          variant="body2"
                          className={classes.tableSecondary}
                        >
                          {managedEntity.groupName}
                        </Typography>
                      )}
                    </TableCell>

                    {/* edit/delete icons are showed up on hover  */}
                    <TableCell
                      size="small"
                      align="center"
                      classes={{ root: classes.commonTableCell }}
                    >
                      <div
                        className={classnames(classes.commonFlexStartRow, {
                          [classes.actionsHidden]: index !== selected,
                        })}
                      >
                        <Fragment>
                          <DelegationProfiles
                            type={delegationProfileTypes.MANAGED_ENTITIES}
                            action="general.modify"
                          >
                            <Tooltip title={t("Edit")}>
                              <Link
                                to={{
                                  pathname: buildRoute(
                                    match.url,
                                    `${managedEntity.deviceId.id}/edit`,
                                  ),
                                }}
                              >
                                <IconButton
                                  id={`MANAGED_ENTITY_DETAIL_BTN_EDIT_${managedEntity.deviceId.id}`}
                                >
                                  <IconEdit color="primary" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </DelegationProfiles>
                          <DelegationProfiles
                            type={delegationProfileTypes.MANAGED_ENTITIES}
                            action="general.create"
                          >
                            <Tooltip title={t("Duplicate")}>
                              <Link
                                to={{
                                  pathname: buildRoute(
                                    match.url,
                                    `${managedEntity.deviceId.id}/duplicate`,
                                  ),
                                }}
                              >
                                <IconButton
                                  id={`MANAGED_ENTITY_DETAIL_BTN_DUPLICATE_${managedEntity.deviceId.id}`}
                                >
                                  <IconCopy color="primary" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </DelegationProfiles>
                          <DelegationProfiles
                            type={delegationProfileTypes.MANAGED_ENTITIES}
                            action="general.delete"
                          >
                            <Tooltip title={t("Delete")}>
                              <IconButton
                                id={`MANAGED_ENTITY_BTN_DELETE-${managedEntity.deviceId.id}`}
                                onClick={() =>
                                  this.handleOpenDeleteDeviceDialog(
                                    managedEntity,
                                  )
                                }
                              >
                                <IconDeleteRed />
                              </IconButton>
                            </Tooltip>
                          </DelegationProfiles>
                        </Fragment>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Fragment>
    );
  }
}

ManagedEntityList.propTypes = {
  classes: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isManager: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
  subtenantId: getSelectedSubtenant(state).id,
});

export default connect(mapStateToProps)(
  withRouter(
    withStyles(styles)(withTranslation("managedEntity")(ManagedEntityList)),
  ),
);
