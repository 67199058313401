import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { getParentRoute } from "msa2-ui/src/utils/urls";

import useDialog from "msa2-ui/src/hooks/useDialog";
import useApi from "msa2-ui/src/hooks/useApi";
import { getToken } from "msa2-ui/src/store/auth";
import { updateBpmDiagram } from "msa2-ui/src/api/bpm";
import { readRepository } from "msa2-ui/src/api/repository";
import Repository from "msa2-ui/src/services/Repository";

import {
  Grid,
  CircularProgress,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Modal from "msa2-ui/src/components/modal/Modal";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import BpmModeler from "msa2-ui/src/components/bpm/BpmModeler";
import ModalTitleBar from "./ModalTitleBar";

const useStyles = makeStyles(() => ({
  modalMessage: {
    justifyContent: "center",
    marginTop: 30,
  },
}));

const Edit = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = useLocation();

  const token = useSelector(getToken);

  const history = useHistory();
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      history.length > 2
        ? history.goBack()
        : history.push(getParentRoute(pathname));
    }
  };

  const handleCloseWithCheck = () => {
    if (isBpmChanged) {
      return showDiscardDialog();
    }
    handleClose();
  };

  const handleBmpChange = ({ touched }) => setIsBpmChanged(touched);

  const [shouldTriggerSaveBpm, setShouldTriggerSaveBpm] = useState(false);
  const [isSavingBpm, setIsSavingBpm] = useState(false);
  const [isBpmChanged, setIsBpmChanged] = useState(false);

  const [showDiscardDialog, DiscardDialog] = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    params: { bpmUri: bpmUriFromRoute },
  } = useRouteMatch();
  const bpmUri = decodeURIComponent(bpmUriFromRoute);
  const bpmFilename = Repository.getFilenameFromUri(bpmUri);
  const [displayName, setDisplayName] = useState("");

  const [fetchDiagramXmlLoading, , fetchDiagramXmlResponse] = useApi(
    readRepository,
    { uri: bpmUri },
    !bpmUri,
  );

  useEffect(() => {
    if (fetchDiagramXmlResponse) {
      setDisplayName(fetchDiagramXmlResponse.displayName);
    }
  }, [fetchDiagramXmlResponse]);

  const handleNameChange = (value) => {
    setDisplayName(value.replace(/[^\w\s]/gi, ""));
  };

  const saveBpm = async ({ xml: diagramXml, error: xmlError }) => {
    setShouldTriggerSaveBpm(false);
    setIsSavingBpm(true);

    const [upsertBpmError] = await updateBpmDiagram({
      bpmFilename,
      displayName,
      xmlContent: diagramXml,
      pathToBpmDatafilesDirectory: Repository.stripFilenameFromUri(bpmUri),
      token,
    });
    setIsSavingBpm(false);

    if (upsertBpmError || xmlError) {
      return enqueueSnackbar(t("Error saving BPM. Please try again"), {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
        autoHideDuration: null,
      });
    } else {
      enqueueSnackbar(t("BPM diagram saved successfully"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      return handleClose();
    }
  };

  const renderModalContent = () => {
    if (fetchDiagramXmlLoading) {
      return (
        <Grid container className={classes.modalMessage}>
          <CircularProgress />
        </Grid>
      );
    } else if (!fetchDiagramXmlResponse) {
      return (
        <Grid container className={classes.modalMessage}>
          <Typography className={classes.errorMessage}>
            {t("Could not fetch BPM diagram")}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <BpmModeler
          diagramXml={fetchDiagramXmlResponse.content}
          callback={saveBpm}
          shouldTriggerCallback={shouldTriggerSaveBpm}
          onChange={handleBmpChange}
        />
      );
    }
  };

  const shouldDisableModalTitleBar = !fetchDiagramXmlResponse || isSavingBpm;

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={handleClose}
      />
      <Modal onClose={handleCloseWithCheck} data-testid="BPM_EDIT_MODAL">
        <ModalTitleBar
          bpmUri={bpmUri}
          title={
            <TextField
              id="BPM_EDIT_NAME"
              data-testid="bpm-name-input"
              disabled={fetchDiagramXmlLoading}
              value={displayName}
              onChange={(event) => handleNameChange(event.target.value)}
            />
          }
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Save")}
          discardButtonLabel={t("Discard Changes")}
          disabled={shouldDisableModalTitleBar}
          onSave={() => setShouldTriggerSaveBpm(true)}
          onDiscard={showDiscardDialog}
          onClose={handleCloseWithCheck}
        />
        <ModalContent>{renderModalContent()}</ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

export default Edit;
