import React, { useState } from "react";
import PropTypes from "prop-types";
import { MenuItem, NestedMenuItem } from "msa2-ui/src/components/menu";
import { useSelector } from "react-redux";
import { getIsDeveloper, getToken } from "msa2-ui/src/store/auth";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import {
  getWorkflow,
  getWorkflowDetails,
  getWorkflowList,
} from "msa2-ui/src/api/workflow";
import Process from "msa2-ui/src/services/Process";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import useWorkflowDrawer from "msa2-ui/src/hooks/useWorkflowDrawer";
import { useSnackbar } from "notistack";
import MenuLoader from "./MenuLoader";

const TriggerWorkflowAction = ({ disabled = false, onDrawerClose }) => {
  const t = useBoundedTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isWorkflowsPending, setIsWorkflowsPending] = useState(false);
  const [workflows, setWorkflows] = useState([]);

  const [isWorkflowDataPending, setIsWorkflowDataPending] = useState(false);
  const [workflowData, setWorkflowData] = useState({});

  const token = useSelector(getToken);
  const isDeveloper = useSelector(getIsDeveloper);
  const { id: subtenantId, ubiqubeId } = useSelector(getSelectedSubtenant);

  const [showWorkflowDrawer, WorkflowDrawer] = useWorkflowDrawer();
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );

  const handleTriggerWorkflowClick = async () => {
    setIsWorkflowsPending(true);

    const [error, response] = await getWorkflowList({
      isDeveloper,
      isFilteredByOwner: isWorkflowsOwnerDetailsEnabled,
      token,
      pageSize: 50,
      subtenantId,
      transforms: [],
    });

    setIsWorkflowsPending(false);

    if (error) {
      enqueueSnackbar(error.getMessage(), {
        variant: "error",
      });
      return;
    }
    setWorkflows(response?.workflows || []);
  };

  const handleProcessClick = async (wf) => {
    setIsWorkflowDataPending(true);

    const [workflowInstancesResponse, workflowDataResponse] = await Promise.all(
      [
        getWorkflowDetails({
          token,
          workflowPath: wf.path,
          ubiqubeId,
        }),
        getWorkflow({ token, pathToWorkflowDefinitionFile: wf.path }),
      ],
    );

    const isError = workflowInstancesResponse[0] || workflowDataResponse[0];

    setIsWorkflowDataPending(false);

    if (isError) {
      enqueueSnackbar(t("GenericErrorMessage"), { variant: "error" });
      return;
    }

    const instancesResp = workflowInstancesResponse[1] || {};
    const wfDataResp = workflowDataResponse[1] || {};

    setWorkflowData({ ...wfDataResp, instances: instancesResp.instances });
  };

  const renderProcessesMenu = () => {
    const process = workflowData.process || [];
    const instances = workflowData.instances || [];

    if (!process.length)
      return (
        <MenuItem id={`TRIGGER_WF_PROCESS_DISABLED`} disabled>
          {t("No process to select")}
        </MenuItem>
      );

    const openDrawer = ({ serviceId = "", process }) =>
      showWorkflowDrawer({
        serviceId,
        displayName: process.displayName,
        processName: process.name,
      });

    const processesToRender = instances.length
      ? process
      : process.filter((process) => Process.isCreate(process.type));

    return processesToRender.map((process, i) => {
      const renderInstances = () => (
        <NestedMenuItem
          id={`TRIGGER_WF_PROCESS_${i}`}
          key={process.name}
          label={process.displayName}
        >
          {instances.map(([id, , name]) => (
            <MenuItem
              id={`TRIGGER_WF_INSTANCE_${i}`}
              key={id}
              onClick={() => openDrawer({ serviceId: id, process })}
            >
              ID: {id} {name ? `- ${name}` : ""}
            </MenuItem>
          ))}
        </NestedMenuItem>
      );
      switch (true) {
        case Process.isUpdate(process.type): {
          return (
            <DelegationProfiles
              type={delegationProfileTypes.MANAGED_ENTITIES}
              action="instance.update"
            >
              {renderInstances()}
            </DelegationProfiles>
          );
        }
        case Process.isDelete(process.type): {
          return (
            <DelegationProfiles
              type={delegationProfileTypes.MANAGED_ENTITIES}
              action="instance.delete"
            >
              {renderInstances()}
            </DelegationProfiles>
          );
        }
        case Process.isCreate(process.type): {
          return (
            <DelegationProfiles
              type={delegationProfileTypes.MANAGED_ENTITIES}
              action="instance.create"
            >
              <MenuItem
                id={`TRIGGER_WF_PROCESS_${i}`}
                key={process.name}
                onClick={() => openDrawer({ process })}
              >
                {process.displayName}
              </MenuItem>
            </DelegationProfiles>
          );
        }
        default:
          return null;
      }
    });
  };

  const renderWorkflowsMenu = () => {
    if (!workflows.length) {
      return (
        <MenuItem id={`TRIGGET_WF_NAME_NO_OP`} disabled>
          {t("No data available")}
        </MenuItem>
      );
    }

    return workflows.map((wf, i) => (
      <NestedMenuItem
        id={`TRIGGER_WF_NAME_${i}`}
        key={wf.path}
        label={wf.displayName}
        shouldOpenOnClick
        onClick={() => handleProcessClick(wf)}
      >
        {isWorkflowDataPending ? <MenuLoader /> : renderProcessesMenu()}
      </NestedMenuItem>
    ));
  };

  if (disabled) {
    return <MenuItem disabled>{t("Trigger x", { x: t("Workflow") })}</MenuItem>;
  }

  return (
    <>
      <WorkflowDrawer
        workflowDefinition={workflowData}
        onClose={onDrawerClose}
      />
      <NestedMenuItem
        id="TRIGGER_WF_ACTION"
        label={t("Trigger x", { x: t("Workflow") })}
        shouldOpenOnClick
        onClick={handleTriggerWorkflowClick}
      >
        {isWorkflowsPending ? <MenuLoader /> : renderWorkflowsMenu()}
      </NestedMenuItem>
    </>
  );
};

TriggerWorkflowAction.propTypes = {
  disabled: PropTypes.bool,
  onDrawerClose: PropTypes.func.isRequired,
};

export default TriggerWorkflowAction;
