import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { doBackup, getBackupStatus } from "msa2-ui/src/api/managedEntity";
import { wait } from "msa2-ui/src/utils/wait";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";

const useStyles = makeStyles(({ palette }) => ({
  content: {
    height: "100%",
  },
  contentWrapper: {
    padding: "10px 20px",
    height: 120,
  },
  itemWrapper: {
    height: 80,
  },
  warningMessage: {
    color: palette.warning.main,
    textAlign: "center",
    marginBottom: 15,
  },
}));

const END_STATUSES = ["ENDED", "WARNING", "OK"];

const BackupDialog = ({ deviceId, title, content, onClose, onBackup }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();

  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();

  const handlePush = async () => {
    let backupInProgress = true;
    setIsLoading(true);

    const [backupError] = await doBackup({ token, deviceId });

    if (backupError) {
      enqueueSnackbar(backupError.getMessage(), {
        variant: "error",
      });

      setIsLoading(false);
    } else {
      while (backupInProgress) {
        await wait(pollingInterval);

        const [statusError, statusResponse = {}] = await getBackupStatus({
          token,
          deviceId,
        });
        const { status } = statusResponse;
        setMessage(statusResponse?.message);

        if (statusError || status === "FAIL") {
          enqueueSnackbar(
            statusResponse?.message || statusError?.getMessage(),
            {
              variant: "error",
            },
          );

          setIsLoading(false);
          backupInProgress = false;
        } else if (END_STATUSES.includes(status)) {
          enqueueSnackbar(statusResponse?.message, {
            variant: "success",
          });

          setIsLoading(false);
          backupInProgress = false;

          onBackup();
        }
      }
    }
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {title}
        </Typography>
        <IconButton
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Grid
          className={classes.content}
          container
          alignItems="center"
          justifyContent="center"
        >
          {isLoading ? (
            <Grid item>
              <Typography align="center" variant="body2">
                {message || t("Backup in progress. Please wait.")}
              </Typography>
              <div
                data-testid="push-dialog-spinner"
                className={classNames(
                  classes.itemWrapper,
                  commonClasses.commonFlexCenter,
                )}
              >
                <CircularProgress />
              </div>
            </Grid>
          ) : (
            <Typography align="center" variant="body2">
              {content}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          variant="text"
          size="small"
          color="default"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          disabled={isLoading}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          disabled={isLoading}
          onClick={handlePush}
        >
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BackupDialog.propTypes = {
  content: PropTypes.string,
  deviceId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackup: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default BackupDialog;
