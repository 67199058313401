import React from "react";
import MSAConsole from "msa2-ui/src/components/msa-console";
import ExecutionVariableField from "msa2-ui/src/components/variables/ExecutionVariableField";

const RunTimeVariables = (props) => {
  return (
    <MSAConsole
      {...props}
      components={{ MSAVariable: ExecutionVariableField }}
    />
  );
};

export default RunTimeVariables;
