import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { correlateMessage } from "msa2-ui/src/api/bpm";
import { getToken } from "msa2-ui/src/store/auth";

import Bpm from "msa2-ui/src/services/Bpm";

import Dialog from "msa2-ui/src/components/Dialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import VariableEditDialog from "./VariableEditDialog";

const ContinueVariableEditDialog = ({
  bpmModeler: { modelerState, modelerActions },
  activeElementStatus: { isActive, willExecute },
  processInstanceId,
  isFinished,
}) => {
  const { activeElement } = modelerState;
  const { workflowActivityId, messageRef } = Bpm.readInfoFromMessageEvent(
    activeElement,
  );
  const targetWorkflowElement = modelerActions.getBpmElementById(
    workflowActivityId,
  );

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);

  const unmount = modelerActions.unselectElement;
  const canContinue = isActive && Boolean(messageRef);

  const onContinueBPM = async ({ processVariables }) => {
    const [error] = await correlateMessage({
      messageName: messageRef.name,
      processInstanceId,
      processVariables,
      token,
    });

    const message = error
      ? error.data?.message ?? t("Unable to continue BPM")
      : t("Signal accepted with Variables");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      unmount();
    }
  };

  return canContinue ? (
    <VariableEditDialog
      element={targetWorkflowElement}
      onClose={unmount}
      onExec={canContinue ? onContinueBPM : undefined}
      message={t("Do you want to send Variable to continue BPM?")}
      processInstanceId={processInstanceId}
      activeElementStatus={{ willExecute: isActive }}
      isFinished={isFinished}
    />
  ) : (
    <Dialog
      title={"Variable Edit Point"}
      content={
        willExecute
          ? t("You can continue BPM when the execution stops at this element.")
          : t("BPM has already passed this element.")
      }
      onClose={unmount}
    />
  );
};

ContinueVariableEditDialog.propTypes = {
  bpmModeler: PropTypes.shape({
    modelerState: PropTypes.object.isRequired,
    modelerActions: PropTypes.object.isRequired,
    moddle: PropTypes.object,
  }).isRequired,
  activeElementStatus: PropTypes.shape({
    isExecuted: PropTypes.bool,
    isActive: PropTypes.bool,
    willExecute: PropTypes.bool,
  }).isRequired,
  executionId: PropTypes.string,
};

export default ContinueVariableEditDialog;
