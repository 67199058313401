import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import get from "lodash/get";
import omit from "lodash/omit";
import useDialog from "msa2-ui/src/hooks/useDialog";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";

import classnames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { getActiveField, getFormValues } from "msa2-ui/src/store/form";
import {
  getAvailableSubtenants,
  getAvailableTenants,
  getModelsById,
  getManufacturersById,
  fetchManagedEntities,
} from "msa2-ui/src/store/designations";

import { Divider, Grid, Paper, MenuItem, Typography } from "@material-ui/core";
import reduxForm from "redux-form/lib/reduxForm";
import SubmissionError from "redux-form/lib/SubmissionError"; // ES6
import flow from "lodash/flow";
import pick from "lodash/pick";

import ManagedEntityToolbar from "../ManagedEntityToolbar";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import Modal from "msa2-ui/src/components/modal/Modal";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import {
  sections,
  fields,
  validate,
  managementSection,
  getAdvancedSection,
  renderDynamicFields,
  useSetCurrentSection,
  useFocusFirstFieldInSection,
} from "msa2-ui/src/components/formSection/FormCommon";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";

import useApi from "msa2-ui/src/hooks/useApi";
import {
  createConfigurationVariable,
  createManagedEntity,
  getManagedEntity,
  getManagedEntityData,
  getManagedEntityFields,
  listConfigurationVariable,
} from "msa2-ui/src/api/managedEntity";

import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";

import { natures } from "msa2-ui/src/Constants";
import DeviceAdapterSelector from "msa2-ui/src/components/DeviceAdapterSelector";
import { makeStyles } from "@material-ui/core";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { useSnackbar } from "notistack";
import { getToken } from "msa2-ui/src/store/auth";
import { getSubtenantsByTenant } from "msa2-ui/src/api/subtenant";
import {
  getDeploymentSetting,
  updateDeploymentSetting,
} from "msa2-ui/src/api/deploymentSettings";
import { getSecretKeyValue } from "msa2-ui/src/store/designations";
import Utility from "../Functions";
import isEmpty from "lodash/isEmpty";

const form = "ManagedEntityDuplicate";

const duplicateExtraInformationFields = {
  variables: {
    label: "Variables",
    name: "variables",
    type: "Boolean",
  },
  deploymentSettings: {
    label: "Deployment Settings",
    name: "deploymentSettings",
    type: "Boolean",
  },
};

const duplicateExtraInformationSection = Object.keys(
  duplicateExtraInformationFields,
);

const defaultDuplicateExtraInfoSectionValues = {
  variables: true,
  deploymentSettings: true,
};

const defaultFormValues = {
  ...defaultDuplicateExtraInfoSectionValues,
};

const useStyles = makeStyles((theme) => ({
  category: {
    padding: 16,
    width: "100%",
  },
  vendor: {
    padding: 16,
    width: "100%",
  },
  model: {
    padding: 16,
    width: "100%",
  },
}));

const ManagedEntityDuplicate = ({
  destroy,
  handleSubmit,
  initialize,
  initialized,
  change,
}) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const managedEntityId = get(match, "params.assetId");
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const token = useSelector(getToken);
  const subtenants = useSelector(getAvailableSubtenants);
  const tenants = useSelector(getAvailableTenants);
  const activeField = useSelector(getActiveField(form));
  const modelsById = useSelector(getModelsById);
  const manufacturersById = useSelector(getManufacturersById);

  const tenant = useSelector(getFormValues(form, fields.tenant));
  const category = useSelector(getFormValues(form, fields.category));
  const vendor = useSelector(getFormValues(form, fields.vendor));
  const model = useSelector(getFormValues(form, fields.model));

  const [submitting, setSubmitting] = useState(false);
  const [section, setSection] = useState(sections.TENANT_AND_SUBTENANT);
  const [deviceAdapterError, setDeviceAdapterError] = useState(null);
  const [subtenantsForTenant, setSubtenantsForTenant] = useState(subtenants);
  const secretKey = useSelector(getSecretKeyValue);

  const formValues = {
    ...useSelector(getFormValues(form)),
    ...defaultFormValues,
  };
  const dependencies = [{ name: "reporting", dependsOn: "logMoreEnabled" }];

  if (formValues) {
    dependencies.forEach((dependency) => {
      const { name: field, dependsOn: masterField } = dependency;

      if (!formValues[masterField] && formValues[field]) {
        change(field, false);
      }
    });
  }

  const [, , meResponse = {}] = useApi(getManagedEntity, {
    managedEntityId,
    transforms: [
      (response) => {
        return {
          ...response,
          password: !isEmpty(response?.password)
            ? Utility.getDecryptedPassword(
                response?.password,
                secretKey[0].value,
              )
            : "",
          passwordAdmin: !isEmpty(response?.passwordAdmin)
            ? Utility.getDecryptedPassword(
                response?.passwordAdmin,
                secretKey[0].value,
              )
            : "",
        };
      },
    ],
  });
  const [, , meDataResponse] = useApi(getManagedEntityData, {
    managedEntityId,
  });

  const [fieldsLoading, , fieldsResponse] = useApi(
    getManagedEntityFields,
    {
      manufacturerId: vendor,
      modelId: model,
    },
    !(vendor && model),
  );

  const [subtenantsLoading, , subtenantsResponse = []] = useApi(
    getSubtenantsByTenant,
    {
      tenantPrefix: tenant,
    },
    !tenant,
  );

  useDeepCompareEffect(() => {
    if (tenant) {
      setSubtenantsForTenant(subtenantsResponse);
    }
    if (subtenantsResponse.length === 1) {
      change(fields.subtenant, subtenantsResponse[0].id);
    }
  }, [change, subtenantsResponse, tenant]);

  useDeepCompareEffect(() => {
    const { manufacturerId, modelId } = meResponse;
    if (
      !initialized &&
      manufacturerId &&
      modelId &&
      meDataResponse &&
      manufacturersById
    ) {
      const {
        deviceNature,
        prefix: selectedTenant,
        abonneId: selectedSubtenant,
      } = meDataResponse;

      initialize({
        [fields.tenant]:
          tenants.find((t) => t.value === selectedTenant).value || "",
        [fields.subtenant]:
          subtenants.find((c) => c.id === selectedSubtenant)?.id || "",
        ...meResponse,
        /*  [fields.password]: !isEmpty(password)
          ? Utility.getDecryptedPassword(password, secretKey[0].value)
          : "",
        [fields.passwordAdmin]: !isEmpty(passwordAdmin)
          ? Utility.getDecryptedPassword(passwordAdmin, secretKey[0].value)
          : "", */
        // The nature value doesn't come back from MEResponse so
        // we need to grab it from here
        [fields.nature]: deviceNature,
        [fields.model]: modelId,
        [fields.vendor]: manufacturerId,
        [fields.category]: null,
        [fields.name]: "",
        [fields.externalReference]: "",
      });
    }
  }, [
    subtenants,
    initialize,
    initialized,
    manufacturersById,
    meDataResponse,
    meResponse,
    modelsById,
    t,
    tenants,
  ]);

  const duplicateVariables = async ({ deviceId }) => {
    const [, listVariables] = await listConfigurationVariable({
      token,
      deviceId: managedEntityId,
    });

    const promises = listVariables.map(({ name, comment, value }) =>
      createConfigurationVariable({
        token,
        deviceId,
        name,
        comment,
        value,
      }),
    );

    Promise.all(promises);
  };

  const duplicateDeploymentSettings = async ({
    deploymentSettingId,
    subtenantId,
    deviceId,
  }) => {
    const [, deploymentSettingResponse] = await getDeploymentSetting({
      token,
      deploymentSettingId,
    });

    deploymentSettingResponse.attachedManagedEntities.push(deviceId);
    const body = pick(deploymentSettingResponse, [
      "id",
      "name",
      "externalReference",
      "comment",
      "model",
      "vendor",
      "microserviceUris",
      "templateUris",
      "attachedManagedEntities",
    ]);

    await updateDeploymentSetting({
      token,
      profile_id: deploymentSettingId,
      subtenantId,
      body,
    });
  };

  const submitDuplicate = async (values) => {
    if (!vendor || !model) {
      setDeviceAdapterError("Please select Vendor and Model");
      return;
    } else {
      setDeviceAdapterError(null);
    }
    const errors = validate(values);
    if (Object.values(errors).length) {
      throw new SubmissionError(errors);
    }

    setSubmitting(true);
    const { configProfileId } = meDataResponse;
    const managedEntityData = omit(values, [
      fields.tenant,
      fields.subtenant,
      "id",
      ...duplicateExtraInformationSection,
    ]);
    const subtenantId = values.Subtenant;
    const [createError, createResponse] = await createManagedEntity({
      subtenantId,
      body: managedEntityData,
      token,
    });

    const deviceId = createResponse.id;

    // PARALLEL/ASYNC Api call - List variables and create variables (we are not waiting to finish it up)
    if (values.variables) {
      duplicateVariables({ deviceId });
    }

    // SYNC Api call - List and create deploment setting config
    if (values.deploymentSettings) {
      await duplicateDeploymentSettings({
        deploymentSettingId: configProfileId,
        subtenantId,
        deviceId,
      });
    }
    setSubmitting(false);
    if (createError) {
      enqueueSnackbar(createError.getMessage(), {
        variant: "error",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    } else {
      destroy();
      history.push({
        pathname: `/integration/managed-entities/${deviceId}`,
        state: { meCreated: createResponse.name },
      });
    }
    // update ME list in designation
    dispatch(fetchManagedEntities());
  };

  const advancedSection = getAdvancedSection(fieldsResponse);

  useSetCurrentSection(setSection, activeField, advancedSection);

  const [firstFields, handleSectionButtonClick] = useFocusFirstFieldInSection(
    setSection,
  );

  const [showDiscardDialog, DiscardDialog] = useDialog();

  const handleClose = () => {
    history.push("/integration/managed-entities");
  };

  const handleOnChangeSubtenant = (subtenantId) => {
    const operatorPrefix = subtenantsForTenant.find(
      ({ id }) => subtenantId === id,
    )?.operatorPrefix;
    change(fields.tenant, operatorPrefix);
  };

  const handleSelectDeviceAdapter = (value) => {
    const { category, vendor, model } = value;

    change(fields.category, category);
    change(fields.vendor, vendor ? vendor.manufacturerId : null);
    change(fields.model, model ? model.modelId : null);

    if (vendor && model) {
      setDeviceAdapterError(null);
    }
  };

  return (
    <ErrorBoundary>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={() => {
          handleClose();
          destroy();
        }}
      />
      <Modal onClose={handleClose}>
        <ManagedEntityToolbar
          title={t("Duplicate Managed Entity")}
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Save Managed Entity")}
          discardButtonLabel={t("Discard Changes")}
          disabled={submitting}
          onSave={handleSubmit(submitDuplicate)}
          onDiscard={showDiscardDialog}
          onClose={handleClose}
        />
        <ModalContent>
          <Grid item xs={3} md={2}>
            <Sidebar>
              <Sidebar.ListItem
                title={t("Tenant and Subtenant")}
                id="ME_DUPLICATE_SIDEBAR_TENANT_SUBTENANT"
                selected={section === sections.TENANT_AND_SUBTENANT}
                section={sections.TENANT_AND_SUBTENANT}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Basic Information")}
                id="ME_DUPLICATE_SIDEBAR_BASIC_INFORMATION"
                selected={section === sections.BASIC_INFORMATION}
                section={sections.BASIC_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Administrative Information")}
                id="ME_DUPLICATE_SIDEBAR_ADMINISTRATIVE_INFORMATION"
                selected={section === sections.ADMINISTRATIVE_INFORMATION}
                section={sections.ADMINISTRATIVE_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Management Information")}
                id="ME_DUPLICATE_SIDEBAR_MANAGEMENT_INFORMATION"
                selected={section === sections.MANAGEMENT_INFORMATION}
                section={sections.MANAGEMENT_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Advanced Information")}
                id="ME_DUPLICATE_SIDEBAR_ADVANCED_INFORMATION"
                selected={section === sections.ADVANCED_INFORMATION}
                section={sections.ADVANCED_INFORMATION}
                onClick={handleSectionButtonClick}
              />
              <Sidebar.ListItem
                title={t("Duplicate Extra Information")}
                id="ME_DUPLICATE_SIDEBAR_DUPLICATE_EXTRA_INFORMATION"
                selected={section === sections.DUPLICATE_EXTRA_INFORMATION}
                section={sections.DUPLICATE_EXTRA_INFORMATION}
                onClick={handleSectionButtonClick}
              />
            </Sidebar>
          </Grid>
          <ScrollingContainer>
            <Paper className={classnames(commonClasses.commonPaperNoPadding)}>
              <FormSection
                active={section === sections.TENANT_AND_SUBTENANT}
                title={t("Tenant and Subtenant")}
                loading={submitting || subtenantsLoading}
              >
                <Grid item xs={6} md={4}>
                  <FormSelect
                    name={fields.tenant}
                    label={t(fields.tenant)}
                    inputProps={{
                      ref: firstFields[sections.TENANT_AND_SUBTENANT],
                      id: t("TenantFormField"),
                    }}
                  >
                    {tenants.map(({ label, value }, index) => (
                      <MenuItem value={value} key={`${index}_${value}`}>
                        {label}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormSelect
                    name={fields.subtenant}
                    label={t(fields.subtenant)}
                    onChange={handleOnChangeSubtenant}
                    inputProps={{ id: t("SubtenantFormField") }}
                  >
                    {subtenantsForTenant.map(({ id, label }) => (
                      <MenuItem value={id} key={id}>
                        {label}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.BASIC_INFORMATION}
                title={t("Basic Information")}
                loading={submitting}
              >
                <DeviceAdapterSelector
                  error={deviceAdapterError}
                  onSelectDeviceAdapter={handleSelectDeviceAdapter}
                  category={category}
                  vendor={vendor}
                  model={model}
                  categoryProps={{
                    placeholder: t("Filter by Category..."),
                    inputRef: firstFields[sections.BASIC_INFORMATION],
                  }}
                  vendorProps={{ placeholder: t("Select Vendor...") }}
                  modelProps={{ placeholder: t("Select Model...") }}
                  categoryGridProps={{
                    xs: 6,
                    md: 4,
                    className: classes.category,
                  }}
                  vendorGridProps={{ xs: 6, md: 4, className: classes.vendor }}
                  modelGridProps={{ xs: 6, md: 4, className: classes.model }}
                />
                <Grid item xs={6} md={4}>
                  <FormSelect name={fields.nature} label={t(fields.nature)}>
                    {Object.keys(natures).map((key) => (
                      <MenuItem value={key} key={key}>
                        {t(natures[key])}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    name={fields.externalReference}
                    label={t("External Reference")}
                    type="text"
                  />
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.ADMINISTRATIVE_INFORMATION}
                title={t("Administrative Information")}
                loading={submitting}
              >
                <Grid item xs={6} md={4}>
                  <FieldSelector
                    label={t(fields.name)}
                    type="text"
                    name={fields.name}
                    inputProps={{
                      ref: firstFields[sections.ADMINISTRATIVE_INFORMATION],
                    }}
                    required
                  />
                </Grid>
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.MANAGEMENT_INFORMATION}
                title={t("Management Information")}
                loading={submitting || fieldsLoading || !fieldsResponse}
              >
                {!vendor || !model ? (
                  <Typography variant="body1" color="textSecondary">
                    {t("Please select Vendor and Model")}
                  </Typography>
                ) : (
                  fieldsResponse &&
                  renderDynamicFields(
                    managementSection,
                    fieldsResponse,
                    firstFields[sections.MANAGEMENT_INFORMATION],
                  )
                )}
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.ADVANCED_INFORMATION}
                title={t("Advanced Information")}
                loading={submitting || fieldsLoading || !fieldsResponse}
              >
                {!vendor || !model ? (
                  <Typography variant="body1" color="textSecondary">
                    {t("Please select Vendor and Model")}
                  </Typography>
                ) : (
                  fieldsResponse &&
                  renderDynamicFields(
                    advancedSection,
                    fieldsResponse,
                    firstFields[sections.ADVANCED_INFORMATION],
                    formValues,
                    dependencies,
                  )
                )}
              </FormSection>
              <Divider />
              <FormSection
                active={section === sections.DUPLICATE_EXTRA_INFORMATION}
                title={t("Duplicate Extra Information")}
                loading={submitting || fieldsLoading || !fieldsResponse}
              >
                {renderDynamicFields(
                  duplicateExtraInformationSection,
                  duplicateExtraInformationFields,
                  firstFields[sections.DUPLICATE_EXTRA_INFORMATION],
                  formValues,
                )}
              </FormSection>
            </Paper>
          </ScrollingContainer>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

export default flow(reduxForm({ form, validate }))(ManagedEntityDuplicate);
