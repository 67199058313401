import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import useApi from "msa2-ui/src/hooks/useApi";
import { status } from "msa2-ui/src/api/constants";
import { getSystemVersion } from "msa2-ui/src/api/settings";
import { getLicenseStatus } from "msa2-ui/src/api/license";
import { getIsDeveloper, getRoleDetails } from "msa2-ui/src/store/auth";
import { userRoles } from "msa2-ui/src/store/auth";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";

import {
  Badge,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import LicenseUpload from "./LicenseUpload";
import ImportExport from "msa2-ui/src/routes/settings/ImportExport";
import MSAVarSettings from "msa2-ui/src/routes/settings/MSAVarSettings";

const useStyles = makeStyles(({ darkMode, typography, palette, spacing }) => ({
  wrapper: {
    marginBottom: 20,
  },
  paper: {
    boxShadow: darkMode
      ? `1px -1px 12px -1px ${palette.primary.main}`
      : "0 4px 22px 4px rgba(81, 97, 133, 0.13)",
    padding: spacing(2),
  },
  dividerVertical: {
    width: 1,
    height: 72,
  },
  settingsSectionHeader: {
    marginBottom: 16,
  },
  dataText: {
    color: palette.text.primary,
    fontSize: "0.9rem",
  },
  lightText: {
    color: palette.text.secondary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightRegular,
  },
  warningText: {
    color: palette.warning.main,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightRegular,
  },
  errorText: {
    color: palette.error.main,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightRegular,
  },
  settingText: {
    flexGrow: 2,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  badge: {
    marginLeft: 20,
  },
  haCheckbox: {
    padding: 0,
  },
}));

const SettingItem = ({ label, value, error, warning }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.settingText}>
      <Typography className={classes.dataText}>{value}</Typography>
      <Typography className={classes.lightText}>{t(label)}</Typography>
      {warning && (
        <Typography className={classes.warningText}>{warning}</Typography>
      )}
      {error && <Typography className={classes.errorText}>{error}</Typography>}
    </Grid>
  );
};

const isLicenceEnabled = FeatureFlag.isEnabled(FeatureFlag.features.licence);

const GlobalSettings = ({ tabs: Tabs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const roleDetails = useSelector(getRoleDetails);
  const isDeveloper = useSelector(getIsDeveloper);

  const canAccessLicence =
    roleDetails.id === userRoles.PRIVILEGED_ADMINISTRATOR && isLicenceEnabled;
  const canAccessImportExportSettings =
    roleDetails.id === userRoles.PRIVILEGED_ADMINISTRATOR && isDeveloper;
  const canAccessMSAVarSettings =
    roleDetails.id === userRoles.PRIVILEGED_ADMINISTRATOR;

  const [, , settings = {}] = useApi(getSystemVersion);
  const [, licenseError, licenseResponse, meta, reloadLicense] = useApi(
    getLicenseStatus,
    {},
    !canAccessLicence,
    0,
  );
  const isLicenseExpired = meta?.status === status.NOT_FOUND;

  return (
    <ErrorBoundary>
      <Tabs />
      <Grid container>
        <Grid className={classes.wrapper} item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.settingsSectionHeader}>
              {t("Product Information")}
            </Typography>
            <Grid container direction="row">
              <SettingItem label={t("Version")} value={settings.version} />
              <Divider className={classes.dividerVertical} />
              <SettingItem label={t("Build")} value={settings.build} />
              <Divider className={classes.dividerVertical} />
              <SettingItem label={t("UI Version")} value={settings.uiVersion} />
            </Grid>
          </Paper>
        </Grid>

        {canAccessLicence && (
          <Grid className={classes.wrapper} item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                variant="h5"
                className={classes.settingsSectionHeader}
              >
                {t("License Information")}
                {(licenseResponse?.isExpiring || isLicenseExpired) && (
                  <Badge
                    color="error"
                    badgeContent={1}
                    className={classes.badge}
                  />
                )}
              </Typography>
              {!licenseError &&
                licenseResponse &&
                !licenseResponse.errorMessage && (
                  <Grid container direction="row">
                    <SettingItem
                      label={"Start Date"}
                      value={displayMonthDayYearTimeDate(
                        new Date(licenseResponse.startDate),
                      )}
                    />
                    <Divider className={classes.dividerVertical} />
                    <SettingItem
                      label={"Expiration Date"}
                      value={displayMonthDayYearTimeDate(
                        new Date(licenseResponse.expiryDate),
                      )}
                      warning={
                        licenseResponse.isExpiring &&
                        licenseResponse.daysUntilExpire > 0 &&
                        t("License is expiring", {
                          days: licenseResponse.daysUntilExpire,
                        })
                      }
                      error={
                        licenseResponse.daysUntilExpire <= 0 &&
                        t("License is expired")
                      }
                    />
                    <Divider className={classes.dividerVertical} />
                    <SettingItem
                      label={"License Type"}
                      value={licenseResponse.licenseType}
                    />
                    <Divider className={classes.dividerVertical} />
                    <SettingItem
                      label={"Number of Managed Entities"}
                      value={
                        <NumberFormat
                          value={licenseResponse.maxDevices}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                    />
                    <Divider className={classes.dividerVertical} />
                    <SettingItem
                      label={"License Owner"}
                      value={licenseResponse.licenseOwner}
                    />
                    <Divider className={classes.dividerVertical} />
                    <SettingItem
                      label="HA"
                      value={
                        <Checkbox
                          checked={licenseResponse.isHA}
                          disabled
                          classes={{
                            root: classes.haCheckbox,
                          }}
                        />
                      }
                    />
                    <Divider className={classes.dividerVertical} />
                    <Grid className={classes.settingText}>
                      <LicenseUpload reloadLicense={reloadLicense} />
                    </Grid>
                  </Grid>
                )}
              {isLicenseExpired && (
                <Grid container direction="row">
                  <SettingItem
                    error={t("No Valid License")}
                    value={t("Please upload your license")}
                  />
                  <Divider className={classes.dividerVertical} />
                  <Grid className={classes.settingText}>
                    <LicenseUpload reloadLicense={reloadLicense} />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        )}

        {canAccessImportExportSettings ? (
          <Grid className={classes.wrapper} item xs={12}>
            <ImportExport />
          </Grid>
        ) : null}
        {canAccessMSAVarSettings ? (
          <Grid className={classes.wrapper} item xs={12}>
            <MSAVarSettings />
          </Grid>
        ) : null}
      </Grid>
    </ErrorBoundary>
  );
};

export default GlobalSettings;
