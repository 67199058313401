import i18n from "i18next";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import AdminRouter from "msa2-ui/src/routes/admin";
import AutomationRouter from "msa2-ui/src/routes/automation";
import AiRouter from "msa2-ui/src/routes/ai";
import IntegrationRouter from "msa2-ui/src/routes/integration";
import Dashboard from "msa2-ui/src/routes/dashboard";
import Repository from "msa2-ui/src/routes/repository";
import Settings from "msa2-ui/src/routes/settings";
import News from "msa2-ui/src/routes/news";
import Support from "msa2-ui/src/routes/support";
import Profile from "msa2-ui/src/routes/profile";
import Alarms from "msa2-ui/src/routes/alarms";

import BorderAllOutlined from "@material-ui/icons/BorderAllOutlined";
import CodeOutlined from "@material-ui/icons/CodeOutlined";
import DashboardOutlined from "@material-ui/icons/DashboardOutlined";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import TuneOutlined from "@material-ui/icons/TuneOutlined";
import HelpOutlineOutlined from "@material-ui/icons/HelpOutlineOutlined";
import ProfileAvatar from "./ProfileAvatar";
import AnnouncementOutlined from "@material-ui/icons/AnnouncementOutlined";
import AutomationIcon from "msa2-ui/src/assets/icons/automation";
import AutomationInputBasedIcon from "msa2-ui/src/assets/icons/automationManual";
import AutomationIntentBasedIcon from "msa2-ui/src/assets/icons/automationIntentBased";
import IntegrationIcon from "msa2-ui/src/assets/icons/integration";

import { userRoles } from "msa2-ui/src/store/auth";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import SettingsNotification from "msa2-ui/src/components/settings-notification/SettingsNotification";

const isAdminDisabled = !FeatureFlag.isEnabled(FeatureFlag.features.admin);
const isAiMlDisabled = !FeatureFlag.isEnabled(FeatureFlag.features.ai);
const isRepositoryDisabled = !FeatureFlag.isEnabled(
  FeatureFlag.features.repository,
);

const MainMenu = [
  {
    label: () => i18n.t("Dashboard"),
    icon: DashboardOutlined,
    route: "/dashboard",
    exact: true,
    component: Dashboard,
    canAccess: () => true,
    id: "PRIMARY_MENU_NAV_BTN_DASHBOARD",
  },
  {
    label: (isDeveloper) =>
      isDeveloper ? i18n.t("Integration") : i18n.t("Infrastructure"),
    icon: IntegrationIcon,
    route: "/integration",
    exact: false,
    component: IntegrationRouter,
    canAccess: () => true,
    id: "PRIMARY_MENU_NAV_BTN_CONFIGURATIONS",
  },
  {
    label: () => i18n.t("Automation"),
    icon: AutomationIcon,
    route: "/automation",
    canAccess: () => true,
    id: "PRIMARY_MENU_NAV_BTN_AUTOMATION",
    hasChildItems: true,
    childItems: [
      {
        label: () => i18n.t("Input-based"),
        icon: AutomationInputBasedIcon,
        route: "/",
        exact: false,
        component: AutomationRouter,
        canAccess: () => true,
        id: "PRIMARY_MENU_NAV_BTN_AUTOMATION_INPUT_BASED",
      },
      {
        label: () => i18n.t("Intent-based"),
        icon: AutomationIntentBasedIcon,
        route: "/ai",
        exact: false,
        component: AiRouter,
        canAccess: ({ delegationProfiles, userRole }) => {
          const canViewAI = getDelegationProfile(
            delegationProfileTypes.AI,
            "general.view",
          )({
            delegationProfiles,
            auth: { userDetails: { baseRole: { id: userRole } } },
          });
          return !isAiMlDisabled && canViewAI;
        },
        id: "PRIMARY_MENU_NAV_BTN_AUTOMATION_INTENT_BASED",
      },
    ],
  },
  {
    label: () => i18n.t("Assurance"),
    icon: BorderAllOutlined,
    route: "/alarms",
    exact: false,
    component: Alarms,
    canAccess: ({ delegationProfiles, userRole }) => {
      const isManger = userRole === userRoles.MANAGER;

      const isAlarmsEnabled =
        FeatureFlag.isEnabled(FeatureFlag.features.alarms) === true;
      const logsView = getDelegationProfile(
        delegationProfileTypes.LOGS,
        "general.view",
      )({
        delegationProfiles,
        auth: { userDetails: { baseRole: { id: userRole } } },
      });
      const alarmsView = getDelegationProfile(
        delegationProfileTypes.ALARMS,
        "alarms.view",
      )({
        delegationProfiles,
        auth: { userDetails: { baseRole: { id: userRole } } },
      });
      const manageAlarmsView = getDelegationProfile(
        delegationProfileTypes.ALARMS,
        "manageAlarms.view",
      )({
        delegationProfiles,
        auth: { userDetails: { baseRole: { id: userRole } } },
      });
      const canViewLogs =
        (logsView || alarmsView || manageAlarmsView) && isAlarmsEnabled;

      return isManger ? canViewLogs : true;
    },
    id: "PRIMARY_MENU_NAV_BTN_ASSURANCE",
  },
  {
    label: () => i18n.t("Repository"),
    icon: CodeOutlined,
    route: "/repository",
    exact: false,
    component: Repository,
    canAccess: () => !isRepositoryDisabled,
    id: "PRIMARY_MENU_NAV_BTN_REPOSITORY",
  },
  {
    label: () => i18n.t("Admin"),
    icon: PersonOutlined,
    route: "/admin",
    exact: false,
    component: AdminRouter,
    canAccess: ({ userRole }) =>
      !isAdminDisabled &&
      (userRole === userRoles.PRIVILEGED_ADMINISTRATOR ||
        userRole === userRoles.ADMINISTRATOR ||
        userRole === userRoles.PRIVILEGED_MANAGER),
    id: "PRIMARY_MENU_NAV_BTN_ADMIN",
  },
  {
    label: () => i18n.t("Settings"),
    icon: TuneOutlined,
    route: "/settings",
    exact: false,
    component: Settings,
    canAccess: () => true,
    id: "PRIMARY_MENU_NAV_BTN_SETTINGS",
    badgeComponent: SettingsNotification,
  },
  {
    label: () => i18n.t("News"),
    icon: AnnouncementOutlined,
    route: "/news",
    exact: true,
    component: News,
    canAccess: () => false,
    id: "PRIMARY_MENU_NAV_BTN_NEWS",
  },
  {
    label: () => i18n.t("Support"),
    icon: HelpOutlineOutlined,
    route: "/support",
    exact: true,
    component: Support,
    canAccess: () => false,
    id: "PRIMARY_MENU_NAV_BTN_SUPPORT",
  },
  {
    label: () => i18n.t("Profile"),
    icon: ProfileAvatar,
    route: "/profile",
    exact: false,
    component: Profile,
    canAccess: () => true,
    id: "PRIMARY_MENU_NAV_BTN_PROFILE",
  },
];

export default MainMenu;
