import React, { useEffect, useRef, useState } from "react";
import { useInterval } from "react-use";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { useSnackbar } from "notistack";

import isEqual from "lodash/isEqual";

import useBpmModeler from "msa2-ui/src/hooks/useBpmModeler";
import useWorkflowProgress from "msa2-ui/src/hooks/useWorkflowProgress";
import { getActivityInstances } from "msa2-ui/src/api/bpm";
import { readServiceId } from "msa2-ui/src/api/workflow";
import useWorkflowList from "msa2-ui/src/hooks/useWorkflowList";

import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import Bpm from "msa2-ui/src/services/Bpm";

import Modeler from "bpmn-js/lib/Modeler";
import Viewer from "bpmn-js/lib/NavigatedViewer";
import camundaModdleDescriptor from "camunda-bpmn-moddle/resources/camunda";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";

import { Paper, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTheme } from "@material-ui/core";

import CustomModules from "./customModules";
import PropertiesPanel from "./PropertiesPanel";
import RuntimeDialog from "./RuntimeDialog";
import ExecutionPanel from "./ExecutionPanel";
import InfoPanel from "./InfoPanel";
import { bpmStarterDiagram } from "./bpmStarterDiagram";
import { getIsDeveloper, getToken } from "msa2-ui/src/store/auth";

const NUMBER_OF_WORKFLOWS_TO_REQUEST = 10000;

const useStyles = makeStyles(({ darkMode, palette, colors }) => ({
  [darkMode && "@global"]: {
    ".djs-visual": {
      fill: `${palette.primary.main} !important`,
    },
    ".djs-outline": {
      stroke: `${palette.primary.main} !important`,
    },
    ".djs-palette": {
      background: palette.bpm.background,
      borderColor: palette.primary.main,
      filter: `drop-shadow(0px 0px 3px ${palette.primary.main})`,
    },
    ".djs-palette .entry": {
      color: palette.text.secondary,
      "&:hover": {
        color: palette.primary.main,
      },
    },
    ".djs-palette .separator": {
      borderColor: palette.text.secondary,
    },
    "svg.new-parent": {
      background: `${palette.background.default} !important`,
    },
    ".djs-direct-editing-parent": {
      background: `${palette.background.default} !important`,
      color: `${palette.text.primary} !important`,
    },
  },
  [!darkMode && "@global"]: {
    ".djs-visual": {
      fill: `${palette.primary.main} !important`,
    },
    ".djs-outline": {
      stroke: `${palette.primary.main} !important`,
    },
    ".djs-palette": {
      background: palette.bpm.background,
      borderColor: palette.primary.main,
      filter: `drop-shadow(0px 0px 3px ${palette.primary.main})`,
    },
    ".djs-palette .entry": {
      color: palette.text.secondary,
      "&:hover": {
        color: palette.primary.main,
      },
    },
    ".djs-palette .separator": {
      borderColor: palette.text.secondary,
    },
    "svg.new-parent": {
      background: `${palette.background.default} !important`,
    },
    ".djs-direct-editing-parent": {
      background: `${palette.background.default} !important`,
      color: `${palette.text.primary} !important`,
    },
  },
  modelerContainer: {
    backgroundColor: palette.bpm.background,
    color: palette.common.black,
    height: "calc(100% - 32px)",
  },
  copyXmlButton: {
    bottom: 55,
    position: "absolute",
    right: 80,
  },
  overlayPanel: {
    maxHeight: "calc(100% - 200px)",
    overflowY: "scroll",
    position: "absolute",
    right: 20,
    top: 84,
    width: 400,
  },
  overlayPanel2: {
    maxHeight: "calc(100% - 200px)",
    overflowY: "scroll",
    position: "absolute",
    right: 370,
    top: 84,
    width: 330,
  },
  infoPanel: {
    maxHeight: "30%",
    maxWidth: "calc(100% - 400px)",
    overflowY: "auto",
    position: "absolute",
    left: 100,
    bottom: 50,
  },
}));

const BpmModeler = ({
  diagramXml,
  shouldTriggerCallback = false,
  callback,
  executionProcessInstanceId,
  setErrorMessage,
  readOnly,
  initWorkflows,
  initInfo,
  onChange,
  onActiveInstanceChanged,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const theme = useTheme();
  const { darkMode } = theme;
  const { enqueueSnackbar } = useSnackbar();

  const token = useSelector(getToken);
  const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const isDeveloper = useSelector(getIsDeveloper);
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );

  const [initialized, setInitialized] = useState(false);

  const [, , workflowsApiResponse = {}] = useWorkflowList({
    filter: { subtenant: true },
    pageSize: NUMBER_OF_WORKFLOWS_TO_REQUEST,
    isFilteredByOwner: isWorkflowsOwnerDetailsEnabled,
  });
  const { workflows } = workflowsApiResponse;

  const modeler = useRef();
  const bpmModeler = useBpmModeler(modeler.current, initWorkflows);
  const { modelerState, modelerActions, moddle } = bpmModeler;
  const { activeElement, xml, xmlError } = modelerState;
  const workflowProgress = useWorkflowProgress();
  const [, WorkflowProgressDialog] = workflowProgress;

  const pollingInterval = useSelector(getAutoRefreshSetting("pollingInterval"));
  const [autoRefresh, setAutoRefresh] = useState(pollingInterval);
  const [isFinished, setIsFinished] = useState(false);

  const [activityInstances, setActivityInstances] = useState();
  // Poll current activities and get which element is in execution
  useInterval(
    async () => {
      const [error, response] = await getActivityInstances({
        processInstanceId: executionProcessInstanceId,
        token,
      });

      if (error) {
        // Stop polling if BPM engine finish executing all diagrams.
        //  (it returns an error with "instance does not exist" when it finishes execution)
        setAutoRefresh(null);
        setIsFinished(true);
        onActiveInstanceChanged &&
          onActiveInstanceChanged({ isFinished: true });

        // Clear indicator
        modelerActions.addRunningIndicator([]);
        return;
      }

      const isActiveInstancesChanged = !isEqual(
        activityInstances,
        response?.childActivityInstances,
      );

      if (isActiveInstancesChanged) {
        const childActivityInstances = response?.childActivityInstances;
        const activeIds = childActivityInstances.map(
          ({ activityId }) => activityId,
        );

        // Show running indicator
        modelerActions.addRunningIndicator(activeIds);

        ////////// functions to poll message event and show popup automatically if there is active message element. //////////
        const newMessageElement = childActivityInstances
          // pick up only message element
          .filter(
            ({ activityType }) => activityType === "intermediateMessageCatch",
          )
          // find an element which is not in activityInstances.
          // (meaning it didn't exist as an active element before and newly activated.)
          .find(({ activityId }) => {
            const lastActivityIds =
              activityInstances?.map(({ activityId }) => activityId) ?? [];
            return !lastActivityIds.includes(activityId);
          });
        if (newMessageElement) {
          // select element to show the popup
          modelerActions.selectElement(newMessageElement.activityId);
        }
        ///// function for message event ends here /////

        onActiveInstanceChanged &&
          onActiveInstanceChanged({
            childActivityInstances,
            isFinished: false,
          });

        setActivityInstances(childActivityInstances);
      }
    },
    // Poll only when there is instance id.
    executionProcessInstanceId ? autoRefresh : null,
  );

  const BPMComponent = readOnly ? Viewer : Modeler;
  useEffect(() => {
    modeler.current = new BPMComponent({
      additionalModules: [
        {
          paletteProvider: CustomModules.paletteProvider,
          contextPadProvider: CustomModules.contextPadProvider,
          ...(darkMode && {
            __init__: CustomModules.__init__,
            customRenderer: CustomModules.customRenderer,
          }),
        },
      ],
      container: "#bpm-canvas",
      height: "100%",
      moddleExtensions: {
        camunda: camundaModdleDescriptor,
      },
      // node_modules/bpmn-js/lib/draw/TextRenderer.js - config
      textRenderer: {
        defaultStyle: {
          fontFamily: theme.typography.fontFamily,
          fontWeight: "lighter",
          mixBlendMode: "luminosity",
        },
      },
      // node_modules/bpmn-js/lib/draw/BpmnRenderer.js - config
      bpmnRenderer: {
        defaultFillColor: theme.palette.bpm.background,
        defaultStrokeColor: theme.palette.bpm.path,
      },
    });

    return () => modeler.current.destroy();
  }, [
    theme.palette.bpm.background,
    theme.palette.bpm.path,
    theme.typography.fontFamily,
    BPMComponent,
    darkMode,
  ]);

  useEffect(() => {
    if (!diagramXml) {
      modeler.current.importXML(bpmStarterDiagram);
    } else {
      modeler.current.importXML(diagramXml);
    }
  }, [modeler, diagramXml]);

  useEffect(() => {
    if (shouldTriggerCallback && callback) {
      callback({
        xml,
        error: xmlError,
        bpmModeler,
        modelerInstance: modeler.current,
      });
    }
  }, [bpmModeler, callback, shouldTriggerCallback, xml, xmlError]);

  useEffect(() => {
    onChange && onChange(modelerState);
  }, [modelerState, onChange]);

  const onCopyToClipboardClicked = () => {
    const message = xmlError ?? xml;
    navigator.clipboard.writeText(message);
    enqueueSnackbar(t("XML copied."));
  };
  const allAttachedWorkflowValues = modelerActions?.getAllAttachedWorkflowValues();
  const shouldShowExecutionPanel =
    Boolean(executionProcessInstanceId) && allAttachedWorkflowValues.length > 0;

  // Init validation
  //  To avoid that Workflow is triggered in wrong way,
  //  we clear it if Workflow is not attached to selected subtenant
  useEffect(() => {
    if (
      !initialized &&
      setErrorMessage &&
      allAttachedWorkflowValues &&
      workflows
    ) {
      (async () => {
        const workflowsToCheck = allAttachedWorkflowValues.filter(
          ({ workflowInstanceId }) =>
            workflowInstanceId && !Bpm.isCamundaJsonParser(workflowInstanceId),
        );
        const errors = await Promise.all(
          workflowsToCheck.map(
            ({ workflowInstanceId }) =>
              readServiceId({
                token,
                ubiqubeId,
                serviceId: workflowInstanceId,
              })[0],
          ),
        );

        workflowsToCheck.forEach((bpmElementValues, index) => {
          const {
            workflowPath,
            processName,
            processType,
            workflowInstanceId,
            processVariables,
            activityId,
          } = bpmElementValues;
          const workflowIsAttached = workflows
            .map(({ path }) => path)
            .includes(workflowPath);
          if (!workflowIsAttached || errors[index]) {
            const element = modelerActions.getBpmElementById(activityId);
            const updatedElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
              ...bpmElementValues,
              workflowPath: workflowIsAttached && workflowPath,
              processName: workflowIsAttached && processName,
              processType: workflowIsAttached && processType,
              workflowInstanceId:
                workflowIsAttached && !errors[index] && workflowInstanceId,
              processVariables:
                workflowIsAttached && !errors[index] && processVariables,
              moddle,
            });

            const replace = modeler.current.get("replace");
            replace.replaceElement(element, updatedElement);
          }
        });
        setInitialized(true);
      })();
    }
  }, [
    setErrorMessage,
    allAttachedWorkflowValues,
    token,
    ubiqubeId,
    initialized,
    workflows,
    modelerActions,
    moddle,
  ]);

  const validation = modelerActions.validate();
  useEffect(() => {
    if (setErrorMessage) {
      if (!initialized) {
        setErrorMessage("validating...");
      } else {
        setErrorMessage(validation);
      }
    }
  }, [validation, setErrorMessage, initialized]);

  return (
    <Grid item xs={12}>
      <Grid container className={classes.modelerContainer}>
        <Grid item id="bpm-canvas" data-testid="bpm-canvas" xs={12} />
        {/* Execution Panel on the right */}
        {shouldShowExecutionPanel && (
          <Paper
            className={classnames([
              commonClasses.commonPaperNoPadding,
              classes.overlayPanel,
            ])}
          >
            <WorkflowProgressDialog />
            <ExecutionPanel
              executionItems={allAttachedWorkflowValues}
              executionProcessInstanceId={executionProcessInstanceId}
              workflowProgress={workflowProgress}
              modelerActions={modelerActions}
              activityInstances={activityInstances}
              isFinished={isFinished}
            />
          </Paper>
        )}
        {/* Properties Panel on the right */}
        {activeElement && !readOnly && (
          <Paper
            className={classnames([
              commonClasses.commonPaperNoPadding,
              {
                [classes.overlayPanel]: !shouldShowExecutionPanel,
                [classes.overlayPanel2]: shouldShowExecutionPanel,
              },
            ])}
          >
            <PropertiesPanel
              modelerState={modelerState}
              modelerActions={modelerActions}
              moddle={moddle}
              workflows={workflows}
              readOnly={shouldShowExecutionPanel || readOnly}
            />
          </Paper>
        )}
        {/* Info Panel on the bottom-left */}
        {initInfo && (
          <Paper
            className={classnames([
              commonClasses.commonPaper,
              classes.infoPanel,
            ])}
          >
            <InfoPanel data={initInfo} />
          </Paper>
        )}
        {/* Icons on the bottom-right */}
        {isDeveloper && (
          <Button
            variant="text"
            size="medium"
            color="primary"
            aria-label={t("Copy XML to Clipboard")}
            className={classes.copyXmlButton}
            onClick={onCopyToClipboardClicked}
          >
            {t("Copy XML to Clipboard")}
          </Button>
        )}
        {/* Popup at the center */}
        {activeElement && readOnly && (
          <RuntimeDialog
            bpmModeler={bpmModeler}
            processInstanceId={executionProcessInstanceId}
            activityInstances={activityInstances}
            isFinished={isFinished}
          />
        )}
      </Grid>
    </Grid>
  );
};

BpmModeler.propTypes = {
  diagramXml: PropTypes.string,
  shouldTriggerCallback: PropTypes.bool,
  // This callback will be called with the modeler's current XML value and/or error
  callback: PropTypes.func,
  executionProcessInstanceId: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onSelection: PropTypes.func,
  onActiveInstanceChanged: PropTypes.func,
};

export default BpmModeler;
