import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { CircularProgress, Paper } from "@material-ui/core";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { makeStyles } from "@material-ui/core";

import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

import useApi from "msa2-ui/src/hooks/useApi";
import { getScheduledWorkflows } from "msa2-ui/src/api/workflow";

import { filter } from "msa2-ui/src/utils/filter";

import ScheduledWorkflowInstance from "./ScheduledWorkflowInstance";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import AlertBar from "msa2-ui/src/components/AlertBar";

const useStyles = makeStyles(({ spacing }) => ({
  filterWrapper: {
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
  },
}));

const AutomationScheduled = ({
  sectionTabs: SectionTabs,
  reload,
  setReload,
  workflowDefinition,
}) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { workflowUri } = useParams();

  const { ubiqubeId } = useSelector(getSelectedSubtenant);

  const [
    isLoading,
    error,
    scheduledWorkflows,
    ,
    reloadScheduledWorkflows,
  ] = useApi(getScheduledWorkflows, {
    ubiqubeId,
    workflowPath: decodeURIComponent(workflowUri),
  });

  useEffect(() => {
    if (reload) {
      reloadScheduledWorkflows();
      setReload(false);
    }
  }, [reload, setReload, reloadScheduledWorkflows]);

  const [searchString, setSearchString] = useState("");

  const instances = filter(scheduledWorkflows, searchString, [
    "processDisplayName",
  ]);
  const total = instances?.length ?? 0;

  const renderTileView = () => {
    return (
      <>
        {instances?.map((instance, i) => (
          <ScheduledWorkflowInstance
            workflowDefinition={workflowDefinition}
            instanceData={instance}
            reloadScheduledWorkflows={reloadScheduledWorkflows}
            key={i}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <SectionTabs workflowUri={decodeURI(workflowUri)} count={[path, total]} />
      <Paper
        className={classnames(
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        )}
      >
        <div className={classes.filterWrapper}>
          <FilterMenu
            handleSearchByChange={setSearchString}
            searchValue={searchString}
          />
        </div>
        {isLoading ? (
          <div className={commonClasses.commonLoaderWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {error && (
              <AlertBar
                message={t("Unable to load x", { x: t("Instances") })}
                refreshFnc={reloadScheduledWorkflows}
                adjust
              />
            )}
            {renderTileView()}
          </>
        )}
      </Paper>
    </>
  );
};

export default AutomationScheduled;
