import React from "react";

/**
 * ICONS
 */
import { ReactComponent as UnreachableIcon } from "msa2-ui/src/assets/icons/status/statusUnreachable.svg";
import { ReactComponent as CriticalIcon } from "msa2-ui/src/assets/icons/status/statusCritical.svg";
import { ReactComponent as NeverReachedIcon } from "msa2-ui/src/assets/icons/status/statusNeverReached.svg";
import { ReactComponent as ConnectedIcon } from "msa2-ui/src/assets/icons/status/statusConnected.svg";
import { ReactComponent as MaintenanceIcon } from "msa2-ui/src/assets/icons/status/maintenanceMode.svg";

import { Typography, Avatar, Tooltip } from "@material-ui/core";
import classnames from "classnames";

const statusToTooltipText = new Map([
  ["OK", "Ok"],
  ["ERROR", "Error"],
  ["CRITICAL", "Critical"],
  ["NEVERREACHED", "NeverReached"],
]);

export default class {
  static createTag = (deviceNature, classes) => {
    if (deviceNature === "") {
      return null;
    }

    let style = "";
    let label = "";

    switch (deviceNature.toLowerCase()) {
      case "phsl":
        style = [classes.commonTags, classes.commonTagPhysical].join(" ");
        label = "physical";
        break;
      case "vpub":
        style = [classes.commonTags, classes.commonTagPublic].join(" ");
        label = "public";
        break;
      case "vprv":
      case "vpriv":
        style = [classes.commonTags, classes.commonTagPrivate].join(" ");
        label = "private";
        break;
      case "sec":
        label = "security";
        style = [classes.commonTags, classes.commonTagDefault].join(" ");
        break;
      default:
    }
    return (
      <Typography variant="caption" className={style}>
        {label}
      </Typography>
    );
  };

  static createTypeTags = (typeNames, classes, remove) => {
    if (typeNames.length === 0) {
      return null;
    }

    return typeNames.map((name) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <div onClick={remove ? remove(name) : null} role="contentinfo">
        <Typography
          variant="caption"
          className={[classes.tags, classes.defaultTag].join(" ")}
        >
          {name}
        </Typography>
      </div>
    ));
  };

  static stringCheck = (str) => {
    if (str === undefined || str === null || str === "") {
      return " - ";
    }

    return str;
  };

  static chooseAvatar = (status, classes, maintenanceMode) => {
    let icon;

    if (maintenanceMode === "true") {
      return (
        <Tooltip title="MaintenanceMode">
          <MaintenanceIcon fontSize="large" />
        </Tooltip>
      );
    }

    switch (status) {
      case "OK":
        icon = <ConnectedIcon />;
        break;
      case "ERROR":
        icon = <UnreachableIcon />;
        break;
      case "CRITICAL":
        icon = <CriticalIcon />;
        break;
      case "NEVERREACHED":
        icon = <NeverReachedIcon />;
        break;
      default:
    }
    return (
      <Tooltip title={statusToTooltipText.get(status)}>
        <Avatar className={classes.statusIcon}>{icon}</Avatar>
      </Tooltip>
    );
  };

  static chooseHAAvatar = (haStatus, classes) => {
    switch (haStatus) {
      case 1:
        return (
          <Avatar className={classnames(classes.statusIcon, classes.haIcon)}>
            M
          </Avatar>
        );
      case 2:
        return (
          <Avatar className={classnames(classes.statusIcon, classes.haIcon)}>
            S
          </Avatar>
        );
      default:
    }
  };

  // getManageEntityHaStatus used to get the ha type of the peer device based on the current device
  // hatype(1/2) current device hatype
  // peer fasle for current device and true for peer device
  static getManageEntityHaStatus = (hatype, peer) => {
    switch (hatype) {
      case 1:
        return !peer ? 1 : 2;
      case 2:
        return !peer ? 2 : 1;
      default:
    }
  };

  //Decrypt the the encrypted value
  static getDecryptedPassword = (pass, secretKey) => {
    const Jasypt = require("jasypt");
    const jasypt = new Jasypt();
    jasypt.setPassword(secretKey);
    return jasypt.decrypt(pass);
  };
}
