import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

/**
 * MATERIAL UI
 */
import { withStyles } from "@material-ui/core";

/**
 * MATERIAL UI - ICONS
 */
import Close from "@material-ui/icons/Close";

/**
 * COMPONENTS
 */
import AlertBar from "msa2-ui/src/components/AlertBar";
/**
 * MODULES
 */
import { mergeStyles } from "msa2-ui/src/utils/styles";
import {
  getProvisioningStatus,
  provisionDevice,
  getManagedEntity,
} from "msa2-ui/src/api/managedEntity";
import InitialProvisioningInfoTab from "./InitialProvisioningInfoTab";
import InitialProvisioningStatusContent from "./InitialProvisioningStatusContent";
import classNames from "classnames";
import { provisioningStatus } from "msa2-ui/src/Constants";

/**
 * STYLES
 */
import commonStyles from "msa2-ui/src/styles/commonStyles";

import {
  Dialog,
  Button,
  IconButton,
  Divider,
  DialogTitle,
  DialogActions,
  Typography,
  Grid,
} from "@material-ui/core";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import { connect } from "react-redux";
import flow from "lodash/flow";
import { getSecretKeyValue } from "msa2-ui/src/store/designations";
import Utility from "../Functions";
import isEmpty from "lodash/isEmpty";

const localStyles = (theme) => ({
  dialogContainer: {
    height: 650,
  },
});

// Combine Styles
const styles = mergeStyles([commonStyles, localStyles]);

class InitialProvisioningDialog extends Component {
  state = {
    readyToCheck: false,
    selectedTab: 0,
    login: "",
    ip: "",
    adminPwd: "",
    pwd: "",
    isRunning: false,
    hasStopped: false,
    smsResult: [],
    deviceInformationIsPending: false,
  };

  handleOnClickContinue = () => {
    this.provisionDevice();
  };

  clearErrorMessages = () => {
    this.setState({
      apiError: false,
      apiErrorMsg: null,
      alertBar: false,
    });
  };

  handleOnChangeTextField = (event) => {
    const stateField = event.target.name;
    const newValue = event.target.value;
    this.setState({ [stateField]: newValue });
  };

  /**
   * Provision the Managed Entity,
   * Certain params is supplied can override the values saved in the database for the device. This will not update those values in the database
   * @returns {Promise<void>}
   */
  provisionDevice = async () => {
    //Clear error msgs
    this.clearErrorMessages();

    const {
      token,
      t,
      managedEntity: { deviceID },
    } = this.props;

    const [apiError, , meta] = await provisionDevice({
      token,
      deviceID,
    });

    if (apiError) {
      return this.setState({
        apiError: true,
        apiErrorMsg: t("Unable to Provision Managed Entity"),
        alertBar: true,
        isLoading: false,
        isRunning: false,
      });
    }
    if (meta.status === 202) {
      return this.setState({
        apiError: false,
        apiErrorMsg: "",
        alertBar: false,
        isLoading: false,
        isRunning: true,
      });
    }
  };

  /**
   * Get the Provisioning Status of a device
   * Sets the State with the results
   * @returns {Promise<void>}
   */
  getProvisioningStatus = async () => {
    const {
      token,
      t,
      managedEntity: { deviceID },
    } = this.props;

    const [apiError, apiResponse] = await getProvisioningStatus({
      token,
      deviceID,
    });

    if (apiError) {
      return this.setState({
        apiError: true,
        apiErrorMsg: t("Unable to Provision Managed Entity"),
        alertBar: true,
        isLoading: false,
        isRunning: false,
      });
    }
    const jsonResult = JSON.parse(apiResponse.rawJSONResult);
    const smsResult = jsonResult.sms_result;

    if (apiResponse.status !== provisioningStatus.RUNNING.status) {
      this.props.onCompleteHandler();
    }
    return this.setState({
      apiError: false,
      apiErrorMsg: "",
      alertBar: false,
      isLoading: false,
      isRunning: apiResponse.status === provisioningStatus.RUNNING.status,
      hasStopped: apiResponse.status !== provisioningStatus.RUNNING.status,
      smsResult,
    });
  };

  /**
   * Calls API to get basic information about a device that can be used during Provisioning
   * This Data is not required to provision device
   * */
  getDeviceInformationForProvisioning = async () => {
    const {
      token,
      managedEntity: { deviceID: managedEntityId },
      secretKey,
    } = this.props;

    this.setState({ deviceInformationIsPending: true });

    const [, apiResponse] = await getManagedEntity({
      token,
      managedEntityId,
      transforms: [
        (response) => {
          return {
            ...response,
            password: !isEmpty(response?.password)
              ? Utility.getDecryptedPassword(
                  response?.password,
                  secretKey[0].value,
                )
              : "",
            passwordAdmin: !isEmpty(response?.passwordAdmin)
              ? Utility.getDecryptedPassword(
                  response?.passwordAdmin,
                  secretKey[0].value,
                )
              : "",
          };
        },
      ],
    });

    this.setState({ deviceInformationIsPending: false });

    if (apiResponse) {
      const { login, managementAddress, passwordAdmin, password } = apiResponse;

      return this.setState({
        readyToCheck: true,
        login,
        ip: managementAddress,
        /* adminPwd: !isEmpty(passwordAdmin)
          ? Utility.getDecryptedPassword(passwordAdmin, secretKey[0].value)
          : "",
        pwd: !isEmpty(password)
          ? Utility.getDecryptedPassword(password, secretKey[0].value)
          : "", */
        adminPwd: passwordAdmin,
        pwd: password,
      });
    }
  };

  onClose = () => {
    this.props.onCloseHandler(this.state.hasStopped);
  };

  shouldShowInitialScreen = () => {
    return !this.state.isRunning && !this.state.hasStopped;
  };

  componentDidMount() {
    const { pollingInterval } = this.props;
    this.getDeviceInformationForProvisioning();
    this.checkInterval = setInterval(() => {
      const { isRunning, readyToCheck } = this.state;
      if (isRunning && readyToCheck) {
        this.getProvisioningStatus();
      }
    }, pollingInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isRunning && this.state.isRunning) {
      this.getProvisioningStatus();
    }
    if (this.state.hasStopped) {
      clearInterval(this.checkInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkInterval);
  }

  render() {
    const { isOpen, classes, t } = this.props;
    const {
      ip,
      adminPwd,
      pwd,
      login,
      alertBar,
      apiErrorMsg,
      deviceInformationIsPending,
    } = this.state;

    return (
      <Dialog
        id="INITIAL_PROVISIONING_DIALOG"
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="modalArea"
        fullWidth={true}
        maxWidth={"md"}
        classes={{
          paper: classes.commonDialogPaper,
        }}
      >
        <DialogTitle
          id="INITIAL_PROVISIONING_DIALOG_TITLE"
          className={classes.commonDialogHeader}
          disableTypography
        >
          <Typography variant="h4" className={classes.commonDialogHeaderTitle}>
            {t("INITIAL PROVISIONING")}
          </Typography>
          <IconButton
            id="INITIAL_PROVISIONING_DIALOG_BTN_CLOSE"
            onClick={this.onClose}
            className={classes.commonDialogHeaderCloseButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        {/* Dialog Content*/}
        <Grid className={classes.contentWrapper}>
          {alertBar && (
            <AlertBar
              message={apiErrorMsg}
              refreshFnc={this.handleOnClickContinue}
              closeFnc={this.clearErrorMessages}
            />
          )}
          {/*initial Provisioning screen*/}
          {this.shouldShowInitialScreen() && (
            <InitialProvisioningInfoTab
              isLoading={deviceInformationIsPending}
              ipAddress={ip}
              userName={login}
              rootPwd={adminPwd}
              userPwd={pwd}
              handleOnChangeTextField={this.handleOnChangeTextField}
            />
          )}
          {!this.shouldShowInitialScreen() && (
            <InitialProvisioningStatusContent results={this.state.smsResult} />
          )}
        </Grid>
        {this.shouldShowInitialScreen() && (
          <DialogActions
            id="DIALOG_ACTIONS"
            className={classes.commonFlexCenter}
          >
            <Divider />
            <Button
              id="INITIAL_PROVISIONING_DIALOG_ACTIONS_BTN_CANCEL"
              variant="text"
              size="small"
              color="primary"
              onClick={this.onClose}
            >
              {t("Close")}
            </Button>

            <Button
              id="INITIAL_PROVISIONING_DIALOG_ACTIONS_BTN_CONTINUE"
              variant="contained"
              size="large"
              color="primary"
              className={classNames(
                classes.commonBtn,
                classes.commonBtnPrimary,
                classes.commonBtnLarge,
              )}
              onClick={this.handleOnClickContinue}
            >
              {t("Continue")}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}
InitialProvisioningDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onCompleteHandler: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pollingInterval: getAutoRefreshSetting("pollingInterval")(state),
  secretKey: getSecretKeyValue(state),
});

export default flow(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation(),
)(InitialProvisioningDialog);
