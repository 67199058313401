import React from "react";
import { useLocation } from "react-router-dom";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { buildRoute } from "msa2-ui/src/utils/urls";

import { getIsDeveloper } from "msa2-ui/src/store/auth";

import BpmDetail from "./bpm/Detail";
import BpmOverview from "./bpm/BpmOverview";
import BpmCreate from "./bpm/Create";
import BpmEdit from "./bpm/Edit";

import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";

import SectionTabs from "msa2-ui/src/components/SectionTabs";
import RouteWithSubtenant from "msa2-ui/src/components/RouteWithSubtenant";
import AutomationOverview from "./workflows/overview/AutomationOverview";
import AutomationDetail from "./workflows/detail/Detail";
import AutomationCreate from "./workflows/create/AutomationCreate";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isBPMEnabled = FeatureFlag.isEnabled(FeatureFlag.features.enableBPM);

const AutomationRouter = () => {
  const { path } = useRouteMatch();
  const { state } = useLocation();

  const canAccessBpm = isBPMEnabled;

  const tabs = [
    {
      path: "/automation/bpm",
      displayName: "BPM",
      canAccess: canAccessBpm,
    },
    {
      path: "/automation/workflows",
      displayName: "Workflows",
      canAccess: true,
    },
  ];

  const AutomationTabs = ({ count, endElements }) => {
    const tabsWithCounts = tabs.map((tab) => {
      const [path, value] = count;
      if (tab.path === path) {
        return {
          ...tab,
          count: value,
        };
      }
      return tab;
    });
    return <SectionTabs tabs={tabsWithCounts} endElements={endElements} />;
  };

  const defaultRoutePath = canAccessBpm ? "bpm" : "workflows";
  const defaultRoute = buildRoute(path, defaultRoutePath);

  const canCreateBpm = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "general.create"),
  );
  const canEditBpm = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "general.modify"),
  );
  const canCreateWorkflow = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "general.create"),
  );

  const isDeveloper = useSelector(getIsDeveloper);
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={defaultRoute} />
      </Route>
      <AuthorizedRoute
        exact
        guard={canAccessBpm}
        redirect={defaultRoute}
        path={buildRoute(path, "bpm")}
        render={() => <BpmOverview sectionTabs={AutomationTabs} />}
      />
      <AuthorizedRoute
        exact
        guard={canCreateBpm}
        redirect={defaultRoute}
        path={buildRoute(path, "bpm/create")}
        render={() => <BpmCreate />}
      />
      {/* Route for /edit is called without selecting Subtenant */}
      {!state?.referer && (
        <AuthorizedRoute
          exact
          guard={canEditBpm}
          redirect={defaultRoute}
          path={buildRoute(path, "bpm/:bpmUri/edit")}
        >
          <BpmOverview sectionTabs={AutomationTabs} />
          <BpmEdit />
        </AuthorizedRoute>
      )}
      <RouteWithSubtenant
        path={buildRoute(path, "bpm/:bpmUri")}
        redirectTo={buildRoute(path, "bpm")}
        render={() => <BpmDetail />}
      />
      <Route
        exact
        path={buildRoute(path, "workflows")}
        render={() => <AutomationOverview sectionTabs={AutomationTabs} />}
      />
      <AuthorizedRoute
        exact
        guard={isDeveloper && canCreateWorkflow}
        redirect={defaultRoute}
        path={buildRoute(path, "workflows/create")}
        render={() => <AutomationCreate />}
      />
      <Route
        path={buildRoute(path, "workflows/:workflowUri")}
        render={() => <AutomationDetail />}
      />
      <Redirect to={defaultRoute} />
    </Switch>
  );
};

export default AutomationRouter;
