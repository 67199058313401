import { useMemo } from "react";
import debounce from "lodash/debounce";
import { useBoundCallback } from "msa2-ui/src/hooks/useBoundCallbacks";

export const useDebouncedCallback = (fn, delay) => {
  const boundedFn = useBoundCallback(fn);

  return useMemo(
    () =>
      debounce((...args) => {
        return boundedFn(...args);
      }, delay),
    [boundedFn, delay],
  );
};
