import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingTop: theme.topbar.height,
  },
}));

export const ModalContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {children}
    </Grid>
  );
};
