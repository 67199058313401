import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Route, Switch, withRouter, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import get from "lodash/get";
import has from "lodash/has";
import flow from "lodash/flow";

import { getManagedEntityData } from "msa2-ui/src/api/managedEntity";
import { buildRoute, getParentRoute } from "msa2-ui/src/utils/urls";
import {
  getSelectedSubtenant,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";

import { Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import commonStyles from "msa2-ui/src/styles/commonStyles";
import localStyles from "../Styles";

import SectionTabs from "msa2-ui/src/components/SectionTabs";
import ManagedEntityOverviewTab from "./ManagedEntityOverviewTab";
import ManagedEntityConfigureTab from "./ManagedEntityConfigureTab";
import ManagedEntityVariablesTab from "./ManagedEntityVariablesTab";
import ManagedEntityLogsTab from "./ManagedEntityLogsTab";
import AlertBar from "msa2-ui/src/components/AlertBar";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import ManagedEntityDetailHeader from "./ManagedEntityDetailHeader";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";
import ConfigurationVariable from "./ConfigurationVariable";
import ManagedEntityHistoryTab from "msa2-ui/src/routes/integration/managed-entities/detail/ManagedEntityHistoryTab";

const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

class ManagedEntityDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertBar: false,
      apiError: false,
      apiErrorMsg: "",
      isLoading: true,
      managedEntity: get(this.props, "location.state.managedEntity"),
      maintenanceMode: null,
    };
  }

  componentDidMount() {
    const shouldFetchManagedEntity = !has(
      this.props,
      "location.state.managedEntity",
    );
    if (shouldFetchManagedEntity) {
      this.getManagedEntity();
    }
    this.handleStatusSnackbar();
  }

  handleStatusSnackbar() {
    const { t, enqueueSnackbar, closeSnackbar } = this.props;
    const created = get(this.props, "location.state.meCreated");
    const updated = get(this.props, "location.state.meUpdated");
    if (created || updated) {
      enqueueSnackbar(
        t(created ? "Managed Entity created" : "Managed Entity updated", {
          name: created || updated,
        }),
        {
          variant: "success",
          action: (key) => (
            <SnackbarAction id={key} handleClose={closeSnackbar} />
          ),
        },
      );
    }
    this.clearLocationState();
  }

  componentWillUnmount() {
    this.clearLocationState();
  }

  componentDidUpdate(prevProps) {
    const prevAssetId = get(prevProps, "match.params.assetId", null);
    const assetId = get(this.props, "match.params.assetId", null);

    if (
      prevProps.subtenantId !== this.props.subtenantId ||
      prevProps.tenantId !== this.props.tenantId
    ) {
      return this.returnToParentRoute();
    }

    if (
      this.props.subtenantId &&
      this.state.managedEntity &&
      this.props.subtenantId !== this.state.managedEntity.abonneId
    ) {
      return this.returnToParentRoute();
    }

    if (prevAssetId !== assetId) {
      this.getManagedEntity();
    }
    this.handleStatusSnackbar();
  }

  returnToParentRoute() {
    this.props.history.push(getParentRoute(this.props.match.url));
  }

  reload = () => {
    this.clearState();
    this.getManagedEntity();
  };

  clearState = () => {
    this.setState({
      apiError: false,
      apiErrorMsg: "",
      isLoading: true,
      apiResponse: undefined,
      managedEntity: undefined,
    });
  };

  clearLocationState = () => {
    const { history } = this.props;
    const created = get(this.props, "location.state.meCreated");
    const updated = get(this.props, "location.state.meUpdated");
    if (created || updated) {
      history.replace({
        state: {},
      });
    }
  };

  getManagedEntity = async () => {
    const { token, match, t } = this.props;
    const managedEntityId = get(match, "params.assetId");

    const [apiError, apiResponse] = await getManagedEntityData({
      managedEntityId,
      token,
    });

    if (apiError) {
      return this.setState({
        apiError: true,
        apiErrorMsg: t("Unable to retrieve x", { x: t("Managed Entity") }),
        alertBar: true,
        isLoading: false,
        managedEntity: undefined,
      });
    }

    //during testing some times status 200 is returned but with an html msg so we will check that an ME with a name was actually returned
    if (!apiResponse.name) {
      return this.setState({
        apiError: true,
        apiErrorMsg: t("Unable to retrieve x", { x: t("Managed Entity") }),
        alertBar: true,
        isLoading: false,
        managedEntity: undefined,
      });
    }

    this.setState({
      apiResponse: apiResponse,
      managedEntity: apiResponse,
      isLoading: false,
      alertBar: false,
      apiError: false,
    });
  };

  render() {
    const {
      classes,
      t,
      match,
      canViewLogs,
      canViewObmf,
      canViewVariable,
      canCreateVariable,
      canEditVariable,
      canViewHistory,
    } = this.props;
    const { managedEntity } = this.state;
    const tabs = [
      {
        path: buildRoute(match.url, "overview"),
        displayName: t("Overview"),
        id: "MANAGED_ENTITY_DETAIL_TAB_OVERVIEW_SELECT",
      },
      {
        path: buildRoute(match.url, "logs"),
        displayName: t("Logs"),
        id: "MANAGED_ENTITY_DETAIL_TAB_LOGS_SELECT",
        canAccess: canViewLogs,
      },
      {
        path: buildRoute(match.url, "variables"),
        displayName: t("Variables"),
        id: "MANAGED_ENTITY_DETAIL_TAB_VARIABLES_SELECT",
        canAccess: canViewVariable,
      },
      {
        path: buildRoute(match.url, "configure"),
        displayName: t("Configure"),
        id: "MANAGED_ENTITY_DETAIL_TAB_CONFIGURE_SELECT",
        canAccess: canViewObmf,
      },
      {
        path: buildRoute(match.url, "history"),
        displayName: t("History"),
        id: "MANAGED_ENTITY_DETAIL_TAB_HISTORY_SELECT",
        canAccess: canViewHistory,
      },
    ];

    const renderManagedEntityConfigureTab = () => (
      <ManagedEntityConfigureTab
        managedEntity={managedEntity}
        maintenanceMode={this.state.maintenanceMode}
      />
    );

    const renderManagedEntityLogsTab = () => (
      <ManagedEntityLogsTab managedEntity={managedEntity} />
    );

    const renderManagedEntityHistoryTab = () => (
      <ManagedEntityHistoryTab
        managedEntity={managedEntity}
        maintenanceMode={this.state.maintenanceMode}
      />
    );

    const defaultRoute = buildRoute(match.url, "overview");

    return (
      <>
        <div>
          <div className={classes.backLinkWrapper}>
            <Link
              id="MANAGED_ENTITY_DETAIL_NAV_BACK_TO_MANAGED_ENTITIES"
              className={classes.link}
              to={{
                pathname: match.url
                  .split("/")
                  .slice(0, -1)
                  .join("/"),
              }}
            >
              <KeyboardArrowLeft className={classes.backLinkIcon} />
              <Typography variant="h4" className={classes.backLink}>
                {t("Back to Managed Entity List")}
              </Typography>
            </Link>
          </div>

          {this.state.apiError && (
            <Grid item xs={12}>
              <Paper
                id="MANAGED_ENTITY_LOAD_ERROR"
                className={classes.alertBar}
              >
                <AlertBar
                  message={this.state.apiErrorMsg}
                  refreshFnc={this.reload}
                  closeFnc={this.clearState}
                />
              </Paper>
            </Grid>
          )}

          {managedEntity && (
            <>
              <ManagedEntityDetailHeader
                managedEntity={managedEntity}
                handleCompleteProvision={this.getManagedEntity}
                maintenanceMode={(data) => {
                  this.setState({ maintenanceMode: JSON.parse(data) });
                }}
              />
              <SectionTabs tabs={tabs} />
              <Grid container spacing={3}>
                <Switch>
                  <AuthorizedRoute
                    exact
                    guard={canViewHistory}
                    redirect={defaultRoute}
                    path={buildRoute(match.path, "history")}
                    render={renderManagedEntityHistoryTab}
                  />
                  <AuthorizedRoute
                    exact
                    guard={canViewHistory}
                    redirect={defaultRoute}
                    path={buildRoute(match.path, "history/:revision")}
                    render={renderManagedEntityHistoryTab}
                  />
                  <AuthorizedRoute
                    exact
                    guard={canViewObmf}
                    redirect={defaultRoute}
                    path={buildRoute(match.path, "configure")}
                    render={renderManagedEntityConfigureTab}
                  />
                  <AuthorizedRoute
                    exact
                    guard={canViewObmf}
                    redirect={defaultRoute}
                    path={buildRoute(match.path, "configure/:microserviceName")}
                    render={renderManagedEntityConfigureTab}
                  />
                  <AuthorizedRoute
                    guard={canViewVariable}
                    redirect={defaultRoute}
                    path={buildRoute(match.path, "variables")}
                  >
                    <ManagedEntityVariablesTab />
                    <AuthorizedRoute
                      exact
                      guard={canCreateVariable}
                      redirect={buildRoute(match.url, "variables")}
                      path={buildRoute(match.path, "variables/create")}
                      render={() => <ConfigurationVariable />}
                    />
                    <AuthorizedRoute
                      exact
                      guard={canEditVariable}
                      redirect={buildRoute(match.url, "variables")}
                      path={buildRoute(
                        match.path,
                        "variables/:variableName/edit",
                      )}
                      render={() => <ConfigurationVariable />}
                    />
                  </AuthorizedRoute>

                  <Route
                    exact
                    path={buildRoute(match.path, "overview")}
                    render={() => (
                      <ManagedEntityOverviewTab managedEntity={managedEntity} />
                    )}
                  />
                  <AuthorizedRoute
                    exact
                    guard={canViewLogs}
                    redirect={buildRoute(match.url, "overview")}
                    path={buildRoute(match.path, "logs")}
                    render={renderManagedEntityLogsTab}
                  />
                  <Redirect to={defaultRoute} />
                </Switch>
              </Grid>
            </>
          )}
        </div>
      </>
    );
  }
}

ManagedEntityDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  subtenantId: getSelectedSubtenant(state).id,
  tenantId: getSelectedTenant(state).id,
  canViewLogs: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "logs.view",
  )(state),
  canViewObmf: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "obmf.view",
  )(state),
  canViewVariable: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "variable.view",
  )(state),
  canCreateVariable: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "variable.create",
  )(state),
  canEditVariable: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "variable.modify",
  )(state),
  canViewHistory: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "history.view",
  )(state),
});

export default flow(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation(),
  withSnackbar,
  withRouter,
)(ManagedEntityDetail);
