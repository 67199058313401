import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import flow from "lodash/flow";
import Ansi from "ansi-to-react";
import stripAnsi from "strip-ansi";
import Dialog from "msa2-ui/src/components/Dialog";

import { wait } from "msa2-ui/src/utils/wait";

import { workflowActions } from "msa2-ui/src/Constants";
import {
  attemptToStopWorkflow,
  getProcessLogs,
  getWorkflowProgress,
  pauseWorkflow,
} from "msa2-ui/src/api/workflow";
import {
  displayUserFriendlyDuration,
  displayYearMonthTimeDateAsES,
  formatDateOrString,
} from "msa2-ui/src/utils/date";

import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";

import AlertBar from "msa2-ui/src/components/AlertBar";
import Tooltip from "msa2-ui/src/components/TooltipWithLineBreaks";
import EditorAce from "msa2-ui/src/components/EditorAce";
import ClipboardIcon from "msa2-ui/src/components/ClipboardIcon";
import RerunDialog from "./RerunDialog";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import { connect } from "react-redux";
import { getUserRole, userRoles } from "msa2-ui/src/store/auth";

const localStyles = ({ spacing, colors, palette }) => ({
  taskName: {
    fontSize: 16,
    paddingBottom: 5,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "19px",
    maxHeight: "38px",
    maxWidth: 420,
  },
  taskWrapper: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingRight: spacing(2),
    textAlign: "left",
  },

  taskComment: {
    maxWidth: "inherit",
  },
  taskStatusWrapper: {
    padding: "2px 0px 1px 0px",
  },
  loaderWrapper: {
    padding: 10,
    textAlign: "center",
  },
  statusIcon: {
    width: 23,
    height: 23,
  },
  statusAvatar: {
    width: 48,
    height: 48,
    marginRight: 15,
    boxShadow: "0 4px 16px 0px rgb(0 0 0 / 10%)",
    backgroundColor: palette.background.paper,
  },
  taskStatus: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 5,
  },
  tabs: {
    backgroundColor: palette.background.default,
  },
  logs: {
    textAlign: "left",
    backgroundColor: "#000",
    color: "white!important",
    whiteSpace: "pre",
    overflowX: "scroll",
    height: "400px",
  },
  tabPanel: {
    position: "relative",
  },
  copyToClipboard: {
    position: "absolute",
    top: 37,
    right: 55,
    zIndex: 1,
  },
});

// Combine Styles
const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

const TABS = [
  { index: 0, label: "Tasks", alwaysVisible: true },
  { index: 1, label: "Logs", alwaysVisible: false },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

class WorkflowLiveConsole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processInstance: {},
      logsResponse: {},
      alertBar: false,
      tasksLoading: true,
      logsLoading: true,
      showRerunConfirmation: false,
      showStopConfirmation: false,
      activeTab: TABS[0].index,
    };
  }

  // life cycle methods
  componentDidMount() {
    const { processInstance } = this.props;
    processInstance && this.setState({ processInstance, tasksLoading: false });
    this.getValFromApi();
  }

  componentDidUpdate(_, prevState) {
    // keep calling if the response for API is RUNNING
    const { apiResponse: previousResponse } = prevState;
    const { apiResponse } = this.state;
    const { onEnded, updateStatus } = this.props;
    const status = get(apiResponse, ["status", "status"]);

    if (previousResponse !== apiResponse && status !== "ENDED") {
      this.getValFromApi(true);
    }

    if (
      updateStatus &&
      get(previousResponse, ["status", "status"]) !== status
    ) {
      updateStatus(apiResponse);
    }

    // execute passed onEnded function when status is ENDED
    if (onEnded && status === "ENDED") {
      onEnded();
    }
  }

  // functions for API
  getValFromApi = async (shouldWait) => {
    const {
      token,
      processReference,
      pollingInterval = 1000,
      setApiResponse,
    } = this.props;
    const processId =
      this.state.apiResponse?.processId?.id || this.props.processId;
    const serviceId =
      this.state.apiResponse?.serviceId?.id || this.props.serviceId;

    // artificial delay for polling
    if (shouldWait) {
      await wait(pollingInterval);
    }

    let apiError, apiResponse, logsError, logsResponse;
    if (processId && serviceId) {
      [[apiError, apiResponse], [logsError, logsResponse]] = await Promise.all([
        getWorkflowProgress({
          processId,
          processReference,
          token,
        }),
        getProcessLogs({
          processId,
          serviceId,
          token,
        }),
      ]);
    } else {
      [apiError, apiResponse] = await getWorkflowProgress({
        processId,
        processReference,
        token,
      });
      [logsError, logsResponse] = await getProcessLogs({
        processId: apiResponse?.processId.id,
        serviceId: apiResponse?.serviceId.id,
        token,
      });
    }

    if (apiError) {
      return this.setState({
        apiError,
        alertBar: true,
        tasksLoading: false,
        notification: false,
      });
    }

    this.setState({
      apiResponse,
      logsResponse,
      logsError,
      tasksLoading: false,
      logsLoading: false,
    });
    if (setApiResponse) {
      setApiResponse(apiResponse);
    }
    return get(apiResponse, ["status", "status"]);
  };

  reload = (addState) => {
    this.handleCloseAlertBar();
    this.setState(
      // merge objects
      Object.assign(
        {
          apiError: undefined,
          logsError: undefined,
          tasksLoading: true,
          logsLoading: true,
          apiResponse: undefined,
        },
        addState,
      ),
      () => {
        this.getValFromApi();
      },
    );
  };

  handleTabChange = (event, tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleCloseAlertBar = () => {
    this.setState({ alertBar: false });
  };

  onClickStop = async () => {
    this.setState({ showStopConfirmation: false });
    const { token, processId, onEvent, t } = this.props;
    const { apiResponse } = this.state;
    const [error] = await attemptToStopWorkflow({
      token,
      process_id: apiResponse?.processId?.id ?? processId,
    });
    if (onEvent) {
      const message = error
        ? error.getMessage(t("Attempt to stop failed"))
        : t("Stop Successful");
      onEvent({ message, isError: Boolean(error) });
    }
  };

  onClickAction = async (action) => {
    const { token, processId, onEvent, t } = this.props;
    const { apiResponse } = this.state;

    if (action === "PAUSE") {
      const [error] = await pauseWorkflow({
        token,
        process_id: apiResponse?.processId?.id ?? processId,
      });
      if (onEvent) {
        const message = error
          ? error.getMessage(t("Pause failed"))
          : t(
              "Pause accepted. It will be paused when current Task is finished.",
            );
        onEvent({ message, isError: Boolean(error) });
      }
    }
    if (action === "STOP") {
      this.setState({ showStopConfirmation: true });
    }
    if (["RESTART", "RESUME"].includes(action)) {
      this.setState({ showRerunConfirmation: action });
    }
  };

  renderTasks = () => {
    const {
      classes,
      t,
      components: { ActionWrapper = ({ children }) => <>{children}</> },
      singleColumnView,
      workflowStatus,
      useAvatar,
    } = this.props;
    const { apiResponse } = this.state;

    const processTaskStatus = get(
      apiResponse,
      ["status", "processTaskStatus"],
      [],
    );
    if (!apiResponse)
      return (
        <Grid container alignItems="center" justifyContent="center">
          <CircularProgress />
        </Grid>
      );
    const totalTaskCount = get(apiResponse, ["taskCount"]);
    if (processTaskStatus.length === 0) {
      return (
        <Typography>{t("Nothing to show", { name: t("Tasks") })}</Typography>
      );
    }
    return (
      apiResponse &&
      processTaskStatus &&
      processTaskStatus.map((task, i) => {
        const processStatus =
          workflowStatus.find((arr) => arr.status === task.status) ?? {};
        const {
          iconBig: ProcessStatusIcon,
          color,
          name,
          actions,
        } = processStatus;
        const availableActions = actions.filter(
          (action) =>
            !(
              processTaskStatus.length === totalTaskCount && action === "PAUSE"
            ),
        );
        const startingDate = get(task, ["startingDate"]);
        const endingDate = get(task, ["endingDate"]);
        const runningTime = displayUserFriendlyDuration(
          startingDate,
          endingDate,
        );
        return (
          <Fragment key={i}>
            <Grid container className={classes.taskWrapper}>
              <Grid
                item
                xs={1}
                className={classes.taskStatusWrapper}
                container
                justifyContent="center"
                alignContent="flex-start"
              >
                {useAvatar ? (
                  <Avatar
                    className={classes.statusAvatar}
                    style={{
                      color,
                      animation:
                        task.status === "RUNNING"
                          ? // reusing anim from line-awesome :p
                            "la-spin 1s infinite linear"
                          : undefined,
                    }}
                  >
                    <ProcessStatusIcon />
                  </Avatar>
                ) : (
                  <ProcessStatusIcon className={classes.statusIcon} />
                )}
              </Grid>
              <Grid item xs={singleColumnView ? 11 : 7}>
                <Typography variant="h4" className={classes.taskName}>
                  {task.scriptName}
                </Typography>
                <Tooltip title={stripAnsi(task.details)}>
                  <Typography
                    variant="body1"
                    className={`${classes.commonDescription} ${classes.commonTwoLineWrapper} ${classes.taskComment}`}
                  >
                    <Ansi>{task.details}</Ansi>
                  </Typography>
                </Tooltip>
                {task.details && (
                  <ClipboardIcon
                    content={stripAnsi(task.details)}
                    item={t("Message")}
                  />
                )}
                {singleColumnView && (
                  <Typography
                    variant="body1"
                    className={classes.taskStatus}
                    style={{ color }}
                  >
                    {`${name} - ${runningTime}`}
                  </Typography>
                )}
                {Boolean(availableActions.length) && (
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignContent="center"
                  >
                    <Typography
                      variant="body1"
                      className={classes.commonDescription}
                    >
                      {t("Actions")}:
                    </Typography>
                    <span className={classes.commonSpacer} />
                    {availableActions.map((action, i) => (
                      <ActionWrapper key={i} action={action}>
                        <Typography
                          variant="h3"
                          className={classes.commonLink}
                          onClick={() => {
                            this.onClickAction(action);
                          }}
                        >
                          {workflowActions[action].displayName}
                        </Typography>
                        {availableActions.length - 1 !== i && (
                          <div className={classes.commonVerticalLine} />
                        )}
                      </ActionWrapper>
                    ))}
                  </Grid>
                )}
              </Grid>
              {!singleColumnView && (
                <Grid item xs={4} className={classes.commonFlexVerticalStart}>
                  <Typography
                    variant="body1"
                    className={classes.commonDescription}
                  >
                    {startingDate
                      ? formatDateOrString(
                          displayYearMonthTimeDateAsES(startingDate) + "+0000",
                          "MMM dd, yyyy h:mm:ssaa",
                        )
                      : ""}
                  </Typography>
                  <span className={classes.commonSpacer}>{"-"}</span>
                  <Typography
                    variant="body1"
                    className={classes.commonDescription}
                  >
                    {endingDate
                      ? formatDateOrString(
                          displayYearMonthTimeDateAsES(endingDate) + "+0000",
                          "MMM dd, yyyy h:mm:ssaa",
                        )
                      : t("Running")}
                  </Typography>
                  {startingDate && endingDate && (
                    <Fragment>
                      <span className={classes.commonSpacer} />
                      <Typography
                        variant="body1"
                        className={classes.commonDescription}
                      >
                        {`(${runningTime})`}
                      </Typography>
                    </Fragment>
                  )}
                </Grid>
              )}
            </Grid>
            {processTaskStatus.length - 1 !== i && <Divider />}
          </Fragment>
        );
      })
    );
  };

  renderLogs = () => {
    const { t } = this.props;
    const { logsResponse, logsError } = this.state;
    return !logsResponse || logsError ? (
      <EditorAce readOnly value={t("No logs to show")} />
    ) : (
      <Ansi>{logsResponse?.logContent}</Ansi>
    );
  };

  render() {
    const {
      classes,
      t,
      components,
      token,
      onEvent,
      logsView,
      userRole,
      isMsaComponent,
    } = this.props;
    const { activeTab } = this.state;
    const {
      apiResponse,
      showRerunConfirmation,
      showStopConfirmation,
    } = this.state;

    const canViewLogs =
      (logsView && userRole >= userRoles.MANAGER) ||
      [
        userRoles.PRIVILEGED_ADMINISTRATOR,
        userRoles.ADMINISTRATOR,
        userRoles.PRIVILEGED_MANAGER,
      ].includes(userRole);

    const TABS_TO_DISPLAY = TABS.filter(
      ({ alwaysVisible }) =>
        // Logs tab will be shown base on permission profile
        alwaysVisible || canViewLogs,
    );

    const tabToUse = isMsaComponent === true ? TABS_TO_DISPLAY : TABS;

    return (
      <>
        {showRerunConfirmation && (
          <RerunDialog
            onClose={() => this.setState({ showRerunConfirmation: false })}
            processInstance={apiResponse}
            components={components}
            token={token}
            onEvent={onEvent}
          />
        )}

        {showStopConfirmation && (
          <Dialog
            onClose={() =>
              this.setState({
                showStopConfirmation: false,
              })
            }
            onExec={this.onClickStop}
            title={t("Stop x?", { x: t("Instance") })}
            content={t("Are you sure you want to stop running this instance ?")}
          />
        )}

        <Tabs
          value={activeTab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          className={classes.tabs}
        >
          {tabToUse.map((tab) => (
            <Tab key={tab.index} label={t(tab.label)} />
          ))}
        </Tabs>
        <Fragment>
          {/* after loading  */}
          {this.state.alertBar && (
            // if api returns an error, show alert bar
            <AlertBar
              message={t("Unable to load x", { x: t("live console") })}
              refreshFnc={this.reload}
              closeFnc={this.handleCloseAlertBar}
            />
          )}
          <TabPanel value={activeTab} index={0} className={classes.tabPanel}>
            {this.state.tasksLoading ? (
              <div className={classes.loaderWrapper}>
                <CircularProgress />
              </div>
            ) : (
              this.renderTasks()
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1} className={classes.tabPanel}>
            {this.state.logsLoading ? (
              <div className={classes.loaderWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <div id="WORKFLOW_LIVE_CONSOLE_LOGS" className={classes.logs}>
                {this.renderLogs()}
                <ClipboardIcon
                  classes={{ icon: classes.copyToClipboard }}
                  content={this.state.logsResponse?.logContent}
                  item={t("Log")}
                />
              </div>
            )}
          </TabPanel>
        </Fragment>
      </>
    );
  }
}

WorkflowLiveConsole.propTypes = {
  processInstance: PropTypes.object,
  token: PropTypes.string.isRequired,
  processId: PropTypes.number,
  processReference: PropTypes.string,
  processName: PropTypes.string.isRequired,
  onEnded: PropTypes.func,
};

const mapStateToProps = (state) => ({
  logsView: getDelegationProfile(
    delegationProfileTypes.WORKFLOWS,
    "consoleLogs.view",
  )(state),
  userRole: getUserRole(state),
});

export default flow(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation(),
)(WorkflowLiveConsole);
