import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core";
import flow from "lodash/flow";
import ceil from "lodash/ceil";
import max from "lodash/max";

import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
} from "recharts";

const SpoolDataGraph = ({
  data: _data,
  legends,
  references = [],
  width = "100%",
  height = "100%",
  xAxis,
}) => {
  const theme = useTheme();
  const colors = [
    theme.colors.green,
    theme.colors.blue,
    theme.colors.yellow,
    theme.colors.red,
    theme.colors.orange,
    theme.colors.black,
    theme.colors.white,
    theme.colors.grey,
    theme.colors.blueDark3,
    theme.colors.green3,
    theme.colors.redLight,
    theme.colors.greyDark1,
  ];

  const data = _data?.map((graphData) => ({
    ...graphData,
    ...legends.reduce(
      // Convert target data into Int as Recharts behaves weird when String is passed
      (acc, { id }) => ({
        ...acc,
        [id]: parseInt(graphData[id]) || graphData[id],
      }),
      {},
    ),
  }));

  return (
    <LineChart
      data={data}
      margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
      width={width}
      height={height}
    >
      <defs>
        {legends.map((e, i) => (
          <linearGradient key={i} id={`color${i}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors[i]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors[i]} stopOpacity={0} />
          </linearGradient>
        ))}
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xAxis} />
      <YAxis
        domain={[
          0,
          // If one of the reference is higher than the max value for data,
          // set a new max value for YAxis
          (dataMax) => {
            const maxReference = max(
              references.map((reference) => parseInt(reference.value) || 0),
            );

            const calcMaxValue = (int) =>
              flow(
                (num) => num * 1.1,
                (num) => Math.round(num),
                (num) => ceil(num, -String(num).length + 2),
              )(int);

            return maxReference > dataMax
              ? calcMaxValue(maxReference)
              : calcMaxValue(dataMax);
          },
        ]}
      />
      <Tooltip labelStyle={{ color: theme.colors.grey }} />
      {references.map(({ displayName, value, stroke }, i) => (
        <ReferenceLine
          y={value}
          stroke={stroke || theme.palette.text.secondary}
          key={i}
        >
          <Label
            value={displayName}
            fill={stroke || theme.palette.text.secondary}
            position={i % 2 ? "insideBottomLeft" : "insideBottomRight"}
          />
        </ReferenceLine>
      ))}
      <Legend />
      {legends.map(({ id: dataKey }, i) => (
        <Line
          key={i}
          name={
            legends.find((legend) => legend.id === dataKey)?.displayName ??
            dataKey
          }
          type="monotone"
          id="MANAGED_ENTITY_MONITORING_LATENCY"
          dataKey={dataKey}
          stroke={colors[i]}
          fillOpacity={1}
          fill={`url(#color${i})`}
        />
      ))}
    </LineChart>
  );
};

SpoolDataGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
    }).isRequired,
  ),
  legends: PropTypes.array.isRequired,
  references: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SpoolDataGraph;
