import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { gitRemoteRepoPull } from "msa2-ui/src/api/settings";
import { getToken } from "msa2-ui/src/store/auth";
import SettingsForm from "msa2-ui/src/services/SettingsForm";

import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Dialog from "msa2-ui/src/components/Dialog";
import PasswordInput from "msa2-ui/src/components/PasswordInput";

const useStyles = makeStyles(({ spacing }) => ({
  dialog: {
    height: 640,
  },
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const ImportExportPullDialog = ({ handleClose: onClose, entry }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);

  const repoType = SettingsForm.repositoryTypes.find(
    ({ value }) => entry.repoType === value,
  ).label;
  const [username, setUsername] = useState(entry.username);
  const [password, setPassword] = useState(entry.password);

  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async () => {
    setErrorMessage("");

    if (!username || !password) {
      return setErrorMessage(t("Please fill out all the form fields"));
    }

    setSubmitting(true);
    const { repoName, repoType, repo: url } = entry;
    const [error, response] = await gitRemoteRepoPull({
      token,
      body: { url, repoName, repoType, username, password },
    });
    setSubmitting(false);

    const variant = response.errorCode === 500 ? "error" : "success";
    const message = error
      ? error?.data?.message
      : t("Update with the latest changes.", { repoType });
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    if (!error) {
      onClose();
    }
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={onClose}
      disabled={submitting}
      execLabel={t("Pull latest changes")}
      title={t("Pull Repository")}
      classes={{ paper: classes.dialog }}
    >
      {submitting ? (
        <Grid container justifyContent="center" alignContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container direction="column">
          <TextField
            type={"text"}
            variant="outlined"
            label={t("Type")}
            value={repoType}
            className={classes.formField}
            required
            disabled
          />
          <TextField
            type={"text"}
            variant="outlined"
            label={t("URL")}
            value={entry.repo}
            className={classes.formField}
            required
            disabled
          />
          <TextField
            type={"text"}
            variant="outlined"
            label={t("Directory")}
            value={entry.repoName}
            className={classes.formField}
            required
            disabled
          />
          <TextField
            id={"IMPORT_EXPORT_PULL_DIALOG_USER_NAME"}
            type={"text"}
            variant="outlined"
            label={t("Username")}
            value={username}
            className={classes.formField}
            onChange={({ target: { value } }) => setUsername(value)}
            error={!username}
            required
          />
          <PasswordInput
            id={"IMPORT_EXPORT_PULL_DIALOG_PASSWORD"}
            variant={"outlined"}
            label={t("Token")}
            value={password}
            className={classes.formField}
            onChange={({ target: { value } }) => setPassword(value)}
            error={!password}
            required
          />
          {errorMessage && (
            <Typography color="error" className={classes.error}>
              {errorMessage}
            </Typography>
          )}
        </Grid>
      )}
    </Dialog>
  );
};

export default ImportExportPullDialog;
