import React from "react";
import PropTypes from "prop-types";

const ConnectionTooltip = ({ data }) => {
  return (
    <div
      style={{
        padding: 8,
        background: "#555",
        color: "#fff",
        borderRadius: 4,
        maxWidth: 350,
      }}
      data-testid="network-graph-connection-tooltip"
    >
      {data?.map((d, i) => {
        const fields = d.fields || {};
        const textStyles = {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        };

        return (
          <div key={i} style={{ padding: 5 }}>
            <div style={textStyles}>
              <b>name:</b> {fields.name}
            </div>
            {Boolean(fields.description) && (
              <div style={textStyles}>
                <b>description:</b> {fields.description}
              </div>
            )}
            <div style={textStyles}>
              <b>workflow:</b> {fields.workflow_name}
            </div>
            <div style={textStyles}>
              <b>workflow path:</b> {fields.execute}
            </div>
            <div style={textStyles}>
              <b>process:</b> {fields.process}
            </div>
            <div style={textStyles}>
              <b>process path:</b> {fields.process_path}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ConnectionTooltip.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ConnectionTooltip;
