import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ReferenceArea,
} from "recharts";
import UiTooltip from "@material-ui/core/Tooltip";
import { ReactComponent } from "msa2-ui/src/assets/icons/download.svg";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { Grid, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import sub from "date-fns/sub";
import isValid from "date-fns/isValid";
import { displayHourMinute, displayMonthDayYear } from "msa2-ui/src/utils/date";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import { useCurrentPng } from "recharts-to-png";
import { saveAs } from "file-saver";

const DEFAULT_COLOR = "#003e74";

const useStyles = makeStyles(({ palette, typography }) => ({
  container: {
    position: "relative",
    minHeight: 500,
    padding: 10,
  },
  title: {
    color: palette.text.secondary,
    fontWeight: typography.fontWeightMedium,
    textTransform: "uppercase",
    marginRight: 5,
  },
  noDataMessage: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  responsiveContainer: {
    backgroundColor: palette.background.paper,
  },
}));

const TIME_BOUNDS = {
  MINUTES: "MINUTES",
  HOUR: "HOUR",
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
  YEARS: "YEARS",
};

export const parseTime = (timestamp, timeRange) => {
  if (!isValid(timestamp)) {
    return;
  }

  switch (timeRange) {
    case TIME_BOUNDS.HOUR:
    case TIME_BOUNDS.DAY:
      return displayHourMinute(timestamp);
    default:
      return displayMonthDayYear(timestamp);
  }
};

const tooltipFormatter = (value, kpi = {}) => {
  const { label, suffix = "" } = kpi;
  return [`${value} ${suffix}`, label];
};

const getStepConfig = (timeRange) => {
  switch (timeRange) {
    case TIME_BOUNDS.HOUR:
      return { minutes: 5 };
    case TIME_BOUNDS.DAY:
      return { hours: 1 };
    case TIME_BOUNDS.WEEK:
      return { days: 1 };
    case TIME_BOUNDS.MONTH:
      return { days: 2 };
    case TIME_BOUNDS.YEAR:
      return { months: 1 };
    case TIME_BOUNDS.YEARS:
      return { months: 3 };
    default:
      return { hours: 1 };
  }
};

const getTimeBounds = (data) => {
  if (!data?.length) {
    return TIME_BOUNDS.DAY;
  }

  const { time: start } = data[0];
  const { time: end } = data[data.length - 1];

  if (!isValid(start) || !isValid(end)) {
    return TIME_BOUNDS.DAY;
  }

  const diff = differenceInDays(end, start);

  if (diff <= 1) {
    if (differenceInHours(end, start) <= 1) {
      return TIME_BOUNDS.HOUR;
    }

    return TIME_BOUNDS.DAY;
  } else if (diff > 1 && diff <= 7) {
    return TIME_BOUNDS.WEEK;
  } else if (diff > 7 && diff <= 31) {
    return TIME_BOUNDS.MONTH;
  } else if (diff > 31 && diff <= 365) {
    return TIME_BOUNDS.YEAR;
  } else if (diff > 365) {
    return TIME_BOUNDS.YEARS;
  }
};

/*
  Here we are creating a custom set of ticks because ticks provided by the graph library could be inconsistent in some cases (see MSA-9357 for details).
  For each period we create range of ticks with predefined step (begin from the end and continue till we reach the start).
 */
const getTicks = (data, timeRange) => {
  if (!data?.length) {
    return [];
  }

  const { time: start } = data[0];
  const { time: end } = data[data.length - 1];

  if (!isValid(start) || !isValid(end)) {
    return [];
  }

  const tickStep = getStepConfig(timeRange);

  const ticks = [];
  let current = end;

  while (current >= start) {
    ticks.push(current);
    current = sub(current, tickStep).getTime();
  }

  return ticks.reverse();
};

const Graph = ({
  data: initialData,
  name,
  kpis,
  chartType = "area",
  downloadIcon: DownloadIcon = ReactComponent,
}) => {
  const [data, setData] = useState(initialData);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [showZoomBtn, setShowZoomBtn] = useState(false);
  const t = useBoundedTranslation();

  const classes = useStyles();
  const kpisList = kpis && Object.values(kpis);

  function zoomIn() {
    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    setRefAreaLeft("");
    setRefAreaRight("");
    setData(data.slice());
    setLeft(refAreaLeft);
    setRight(refAreaRight);
    setShowZoomBtn(true);
  }

  function zoomOut() {
    setData(data.slice());
    setRefAreaLeft("");
    setRefAreaRight("");
    setLeft("dataMin");
    setRight("dataMax");
    setShowZoomBtn(false);
  }

  const ChartWrapper = chartType === "area" ? AreaChart : LineChart;
  const Chart = chartType === "area" ? Area : Line;

  const [getPng, { ref }] = useCurrentPng();

  const timeBounds = useMemo(() => getTimeBounds(data), [data]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleGraphDownload = useCallback(async () => {
    const png = await getPng();

    if (png) {
      saveAs(png, name ? `${name}.png` : "chart.png");
    }
  }, [getPng, name]);

  const getChartProps = (id, color) => {
    let props = {
      key: id,
      type: "monotone",
      dataKey: id,
      stroke: color,
    };
    if (chartType === "area") {
      props = { ...props, fillOpacity: 1, fill: `url(#${id})` };
    }
    if (chartType === "line") {
      props = { ...props, dot: false };
    }
    return props;
  };

  return (
    <div className={classes.container} data-testid="Graph-component">
      <Grid alignItems="center" justifyContent="flex-start" container>
        <Grid item>
          <Typography className={classes.title}>{name}</Typography>
        </Grid>
        <Grid item>
          <UiTooltip title={`Download ${name} as PNG`}>
            <span>
              <IconButton
                id={`MONITORING_GRAPH_DOWNLOAD_${name}`}
                aria-label={t("Download")}
                onClick={() => handleGraphDownload()}
              >
                <DownloadIcon />
              </IconButton>
            </span>
          </UiTooltip>
        </Grid>
        {showZoomBtn && (
          <Grid item>
            <Button size="small" onClick={() => zoomOut()}>
              Zoom Out
            </Button>
          </Grid>
        )}
      </Grid>
      {!data?.length && (
        <div className={classes.noDataMessage}>{t("No data available")}</div>
      )}

      <ResponsiveContainer
        width="100%"
        height={500}
        ref={ref}
        className={classes.responsiveContainer}
      >
        <ChartWrapper
          data={data}
          margin={{ top: 20, right: 40, left: 30, bottom: 30 }}
          onMouseDown={(e) =>
            e && e.activeLabel && setRefAreaLeft(e.activeLabel)
          }
          onMouseMove={(e) =>
            e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)
          }
          onMouseUp={() => zoomIn()}
        >
          <Label value={name} />
          <defs>
            {kpisList?.map(({ color, id }) => (
              <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={color || "#ddd"}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={color || DEFAULT_COLOR}
                  stopOpacity={0}
                />
              </linearGradient>
            ))}
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            allowDataOverflow={true}
            dataKey="time"
            domain={[left, right]}
            scale="time"
            type="number"
            ticks={getTicks(data, timeBounds)}
            tickFormatter={(timestamp) => parseTime(timestamp, timeBounds)}
          />

          <YAxis type="number" yAxisId="1" />
          <YAxis type="number" yAxisId="2" orientation="right" />
          <YAxis type="number" yAxisId="3" hide />
          <YAxis type="number" yAxisId="4" hide />
          <YAxis type="number" yAxisId="5" hide />
          <YAxis type="number" yAxisId="6" hide />
          <YAxis type="number" yAxisId="7" hide />
          <YAxis type="number" yAxisId="8" hide />
          <YAxis type="number" yAxisId="9" hide />
          <YAxis type="number" yAxisId="10" hide />

          <Tooltip
            labelFormatter={(time) => parseTime(time, timeBounds)}
            formatter={(value, id) => tooltipFormatter(value, kpis[id])}
          />
          <Legend
            name="label"
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            formatter={(id) => kpis[id].label}
          />
          {kpisList?.map(({ id, color }, index) => (
            <Chart
              {...getChartProps(id, color)}
              yAxisId={`${index + 1}`}
              animationDuration={300}
            />
          ))}
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea
              yAxisId="1"
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}
        </ChartWrapper>
      </ResponsiveContainer>
    </div>
  );
};

Graph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  kpis: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default Graph;
