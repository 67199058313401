import React from "react";
import Select from "react-select";
import MsaSelectBase from "./MsaSelectBase";

const MsaSelect = ({ ...props }) => {
  return <MsaSelectBase {...props} select={Select} />;
};

MsaSelect.propTypes = MsaSelectBase.propTypes;
export default MsaSelect;
