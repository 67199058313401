import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import classnames from "classnames";
import get from "lodash/get";
import flow from "lodash/flow";

import {
  deleteDevice,
  markManagedEntityAsProvisioned,
  getMaintenanceModeStatus,
  setMaintenanceModeStatus,
} from "msa2-ui/src/api/managedEntity";
import { buildRoute, getParentRoute } from "msa2-ui/src/utils/urls";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import Utility from "../Functions";
import {
  getSelectedSubtenant,
  getSelectedTenant,
} from "msa2-ui/src/store/designations";

import {
  Button,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  Box,
} from "@material-ui/core";
import {
  FileCopyOutlined as IconCopy,
  WarningOutlined,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import commonStyles from "msa2-ui/src/styles/commonStyles";
import localStyles from "../Styles";

import Dialog from "msa2-ui/src/components/Dialog";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import SelectSearch from "msa2-ui/src/components/SelectSearch";
import InitialProvisioningDialog from "./InitialProvisioningDialog";
import AddCategoryActionDialog from "msa2-ui/src/components/topology/context-menu/AddCategoryActionDialog";

const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

class ManagedEntityDetailHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertBar: false,
      deleteDialogIsOpen: false,
      provisioningDialogIsOpen: false,
      addCategoryDialogIsOpen: false,
      processEl: null,
      maintenanceString: "",
    };
  }

  componentDidMount() {
    this.getMaintenanceModeStatus();
    this.handleStatusSnackbar();
  }

  getMaintenanceModeStatus = async () => {
    const { token, managedEntity } = this.props;

    const { deviceID } = managedEntity;

    const [apiError, apiResponse] = await getMaintenanceModeStatus({
      token,
      deviceID,
    });

    if (!apiError) {
      this.props.maintenanceMode(apiResponse);
      if (
        JSON.parse(apiResponse) === null ||
        JSON.parse(apiResponse) === false
      ) {
        this.setState({ maintenanceString: "Enable" });
      }
      if (JSON.parse(apiResponse) === true) {
        this.setState({ maintenanceString: "Disable" });
      }
    }
  };

  setMaintenanceModeStatus = async () => {
    const { token, managedEntity, enqueueSnackbar, closeSnackbar } = this.props;

    const { deviceID } = managedEntity;

    const status = this.state.maintenanceString === "Disable" ? false : true;

    const [apiError] = await setMaintenanceModeStatus({
      token,
      deviceID,
      attributeValue: status,
    });

    if (!apiError) {
      this.getMaintenanceModeStatus();
      const snackbarMessage = apiError
        ? apiError.message
        : `Maintenance mode is now ${this.state.maintenanceString}d`;

      enqueueSnackbar(snackbarMessage, {
        variant: apiError ? "error" : "success",
        action: (key) => (
          <SnackbarAction id={key} handleClose={closeSnackbar} />
        ),
      });
    }
  };

  handleStatusSnackbar() {
    const { t, enqueueSnackbar, closeSnackbar } = this.props;
    const created = get(this.props, "location.state.meCreated");
    const updated = get(this.props, "location.state.meUpdated");
    if (created || updated) {
      enqueueSnackbar(
        t(created ? "Managed Entity created" : "Managed Entity updated", {
          name: created || updated,
        }),
        {
          variant: "success",
          action: (key) => (
            <SnackbarAction id={key} handleClose={closeSnackbar} />
          ),
        },
      );
    }
    this.clearLocationState();
  }

  componentWillUnmount() {
    this.clearLocationState();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.subtenantId !== this.props.subtenantId ||
      prevProps.tenantId !== this.props.tenantId
    ) {
      this.props.history.push(getParentRoute(this.props.match.url));
    }
  }

  clearLocationState = () => {
    const { history } = this.props;
    const created = get(this.props, "location.state.meCreated");
    const updated = get(this.props, "location.state.meUpdated");
    if (created || updated) {
      history.replace({
        state: {},
      });
    }
  };

  handleOpenInitProvisioningDialog = () => {
    this.setState({ provisioningDialogIsOpen: true });
  };
  handleCloseInitProvisioningDialog = () => {
    this.setState({ provisioningDialogIsOpen: false });
  };

  handleOpenaddCategoryDialog = () => {
    this.setState({ addCategoryDialogIsOpen: true });
  };
  handleCloseaddCategoryDialog = () => {
    this.setState({ addCategoryDialogIsOpen: false });
  };

  handleActivateWithoutConnection = async () => {
    const {
      t,
      token,
      managedEntity,
      enqueueSnackbar,
      closeSnackbar,
    } = this.props;

    enqueueSnackbar(`${t("Marking Managed Entity as provisioned")}...`, {
      variant: "info",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    const [error] = await markManagedEntityAsProvisioned({
      token,
      managedEntityId: managedEntity?.deviceId?.id,
    });

    const snackbarMessage = error
      ? error.getMessage(
          t("Unable to x", { x: t("mark Managed Entity as provisioned") }),
        )
      : t("Managed Entity marked as provisioned");
    enqueueSnackbar(snackbarMessage, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
  };

  handleOpenDeleteDeviceDialog = () => {
    this.setState({ deleteDialogIsOpen: true });
  };
  handleCloseDeleteDeviceDialog = () => {
    this.setState({ deleteDialogIsOpen: false });
  };

  handleDeleteDevice = async (id) => {
    const {
      t,
      token,
      enqueueSnackbar,
      closeSnackbar,
      history,
      match,
    } = this.props;

    const [error] = await deleteDevice({
      token,
      id,
    });

    const snackbarMessage = error
      ? error.message || t("Unable to delete Managed Entity.")
      : t("Managed Entity has been deleted!");
    enqueueSnackbar(snackbarMessage, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      history.push(
        match.url
          .split("/")
          .slice(0, -1)
          .join("/"),
      );
    }
  };

  createVariable = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/variables/create`);
  };

  handleSetElement = (event, serviceId = "") => {
    this.setState({ processEl: event.currentTarget, actingId: serviceId });
  };

  handleUnsetElement = () => {
    this.setState({ processEl: null, serviceId: "" });
  };

  render() {
    const {
      classes,
      t,
      match,
      managedEntity,
      handleCompleteProvision,
      canProvision,
      canAddCategory,
      canUpdateMaintenanceMode,
    } = this.props;
    const assetId = get(this.props, "match.params.assetId", null);

    const actions = [
      {
        id: "MANAGED_ENTITY_DETAIL_BTN_ACTIVATE",
        label: t("Activate"),
        onClick: this.handleOpenInitProvisioningDialog,
        canAccess:
          this.state.maintenanceString === "Disable" ? null : canProvision,
      },
      {
        id: "MANAGED_ENTITY_DETAIL_BTN_ACTIVATE_WITHOUT_CONNECTION",
        label: t("Activate without connection"),
        onClick: this.handleActivateWithoutConnection,
        canAccess:
          this.state.maintenanceString === "Disable" ? null : canProvision,
      },
      {
        id: "MANAGED_ENTITY_DETAIL_BTN_MAINTENANCE",
        label: t(`${this.state.maintenanceString} Maintenance mode`),
        onClick: this.setMaintenanceModeStatus,
        canAccess: canUpdateMaintenanceMode,
      },
      {
        id: "MANAGED_ENTITY_DETAIL_BTN_CATEGORY",
        label: t("Add Tags"),
        onClick: this.handleOpenaddCategoryDialog,
        canAccess: canAddCategory,
      },
    ];

    const availableActions = actions.filter(
      ({ canAccess = true }) => canAccess,
    );
    return (
      <>
        {this.state.deleteDialogIsOpen && (
          <Dialog
            onClose={this.handleCloseDeleteDeviceDialog}
            onExec={() => this.handleDeleteDevice(managedEntity.deviceId.id)}
            title={t("Confirm Request")}
            content={t("Are you sure you want to remove", {
              name: managedEntity.name,
            })}
          />
        )}
        {this.state.addCategoryDialogIsOpen && (
          <AddCategoryActionDialog
            key="add-category-action"
            onClose={this.handleCloseaddCategoryDialog}
            deviceId={assetId}
            meName={managedEntity.name}
          />
        )}
        <Grid container className={classes.headerInfoGroup}>
          {/* Managed Entity Header Title */}
          <Grid item sm={12} md={12} lg={10} className={classes.subHeader}>
            <Grid container className={classes.subHeaderTitle}>
              <Grid item sm={12} md={12} lg={12}>
                <div className={classes.subHeaderItems}>
                  {Utility.chooseAvatar(
                    managedEntity.pingStatus,
                    classes,
                    null,
                  )}
                  <Typography variant="h4" className={classes.largeBold}>
                    {managedEntity.name}
                  </Typography>
                  <div className={classes.managedEntityTagSpace}>
                    {Utility.createTag(
                      managedEntity.deviceNature ?? "",
                      classes,
                    )}
                  </div>
                  <DelegationProfiles
                    type={delegationProfileTypes.MANAGED_ENTITIES}
                    action="general.modify"
                  >
                    <Tooltip title={t("Edit")}>
                      <Link
                        id="MANAGED_ENTITY_DETAIL_NAV_BACK_TO_MANAGED_ENTITIES"
                        to={{ pathname: buildRoute(match.url, "edit") }}
                      >
                        <IconButton
                          id={`MANAGED_ENTITY_DETAIL_BTN_EDIT_${managedEntity.deviceId.id}`}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </DelegationProfiles>
                  <DelegationProfiles
                    type={delegationProfileTypes.MANAGED_ENTITIES}
                    action="general.create"
                  >
                    <Tooltip title={t("Duplicate")}>
                      <Link
                        to={{
                          pathname: buildRoute(match.url, "duplicate"),
                        }}
                      >
                        <IconButton
                          id={`MANAGED_ENTITY_DETAIL_BTN_DUPLICATE_${managedEntity.deviceId.id}`}
                        >
                          <IconCopy color="primary" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </DelegationProfiles>
                  <DelegationProfiles
                    type={delegationProfileTypes.MANAGED_ENTITIES}
                    action="general.delete"
                  >
                    <Tooltip title={t("Delete")}>
                      <IconButton
                        id={`MANAGED_ENTITY_DETAIL_BTN_DELETE_${managedEntity.deviceId.id}`}
                        onClick={this.handleOpenDeleteDeviceDialog}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </DelegationProfiles>
                  {this.state.maintenanceString === "Disable" ? (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <WarningOutlined className={classes.warningIcon} />
                      <Typography
                        variant="h5"
                        color="error"
                        className={classes.maintenanceStyle}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <div>
                            {t("This Managed Entity is on Maintenance Mode.")}
                          </div>
                          <div>
                            {t("Some actions are disabled on this status.")}
                          </div>
                        </Box>
                      </Typography>
                    </Box>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/* Header Button Group */}
          <Grid
            item
            sm={12}
            md={12}
            lg={2}
            className={classes.headerButtonGroup}
          >
            {Boolean(availableActions.length) && (
              <>
                <>
                  <Button
                    id="AUTOMATION_DETAILS_BTN_CREATE"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.commonBtnPrimary}
                    onClick={this.handleSetElement}
                  >
                    {t("Actions")}
                    {this.state.processEl ? (
                      <KeyboardArrowUp className={classes.iconArrow} />
                    ) : (
                      <KeyboardArrowDown className={classes.iconArrow} />
                    )}
                  </Button>
                  <Popover
                    id="AUTOMATION_DETAILS_PO_CREATE"
                    open={Boolean(this.state.processEl)}
                    anchorEl={this.state.processEl}
                    onClose={this.handleUnsetElement}
                    classes={{ paper: classes.popover }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div
                      className={classnames(
                        classes.commonPopoverContainer,
                        classes.popoverContainer,
                      )}
                    >
                      <SelectSearch
                        id="AUTOMATION_DETAILS_SS_CREATE"
                        options={availableActions}
                        noOptionText="Process not found."
                        onSelect={(_, { onClick }) => {
                          this.handleUnsetElement();
                          onClick();
                        }}
                      />
                    </div>
                  </Popover>
                </>
                {this.state.provisioningDialogIsOpen && (
                  <InitialProvisioningDialog
                    token={this.props.token}
                    managedEntity={managedEntity}
                    isOpen={this.state.provisioningDialogIsOpen}
                    onCloseHandler={this.handleCloseInitProvisioningDialog}
                    onCompleteHandler={handleCompleteProvision}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

ManagedEntityDetailHeader.propTypes = {
  managedEntity: PropTypes.object.isRequired,
  handleCompleteProvision: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  subtenantId: getSelectedSubtenant(state).id,
  tenantId: getSelectedTenant(state).id,
  canProvision: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "general.provisioning",
  )(state),
  canCreateVariable: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "variable.create",
  )(state),
  canAddCategory: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "general.addCategory",
  )(state),
  canUpdateMaintenanceMode: getDelegationProfile(
    delegationProfileTypes.MANAGED_ENTITIES,
    "general.maintenanceMode",
  )(state),
});

export default flow(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation(),
  withSnackbar,
  withRouter,
)(ManagedEntityDetailHeader);
