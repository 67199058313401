import { get } from "./request";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import flow from "lodash/flow";

const API = process.env.REACT_APP_API_PATH;

const keyByName = (data) =>
  keyBy(data, ({ name }) => {
    return name;
  });

const flattenArrayOntoObject = (arrayKey) => ({
  [arrayKey]: arr,
  name,
  ...rest
}) => ({
  ...keyByName(arr),
  ...rest,
});

const flattenNestedArray = (data) =>
  mapValues(data, flattenArrayOntoObject("actions"));

const mapSubCategoriesToObject = (data) =>
  mapValues(
    data,
    flow([flattenArrayOntoObject("subcategories"), flattenNestedArray]),
  );

/*
 * Spec: http://10.31.1.52/swagger/#/Delegation/hasDelegationById
 */
export const getDelegationProfiles = ({ token, managerId }) => {
  return get({
    url: `${API}/delegation/v1/manager/id/${managerId}`,
    token,
    transforms: [keyByName, mapSubCategoriesToObject],
  });
};

export const getDelegationCCLAProfiles = ({ token, id }) => {
  return get({
    url: `${API}/delegation/v2/manager/id/${id}`,
    token,
    queryParams: {
      type: "ccla",
    },
  });
};
