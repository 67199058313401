import { useEffect, useRef } from "react";

export const useUnmountEffect = (effectCallback) => {
  const unmountCallbackRef = useRef();

  useEffect(() => {
    unmountCallbackRef.current = effectCallback;
  }, [effectCallback]);

  useEffect(
    () => () => {
      if (unmountCallbackRef.current) {
        unmountCallbackRef.current();
      }
    },
    [],
  );
};
