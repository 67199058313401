import produce from "immer";
import get from "lodash/get";
import form from "redux-form/lib/reducer";
import { logout } from "./auth";

export default (state, action) => {
  if (action.type === logout.type) {
    return {};
  }
  if (action.type === "REPLACE_FORM_VALUE") {
    const { formName, values } = action;
    return produce(state, (draft) => {
      draft[formName].values = values;
    });
  }

  return form(state, action);
};

export const getFormValues = (formName, value) => ({ form }) =>
  value
    ? get(form, `[${formName}].values[${value}]`)
    : get(form, `[${formName}].values`);

export const setFormValues = (formName, values) => ({
  type: "REPLACE_FORM_VALUE",
  formName,
  values,
});

export const getFormRowInitialValues = (formName, value) => ({ form }) =>
  value
    ? get(form, `[${formName}].initial[${value}]`)
    : get(form, `[${formName}].initial`);

export const getActiveField = (formName) => ({ form }) =>
  get(form, `[${formName}].active`);
