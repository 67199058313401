import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Workflow from "msa2-ui/src/services/Workflow";
import Variable from "msa2-ui/src/services/Variable";
import WorkflowForm from "msa2-ui/src/services/WorkflowForm";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import { getFormValues } from "msa2-ui/src/store/form";

import {
  Typography,
  TextField,
  Paper,
  Divider,
  Grid,
  MenuItem,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import FormSection from "msa2-ui/src/components/formSection/FormSection";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import classnames from "classnames";
import { roles } from "msa2-ui/src/store/auth";
const shouldShowVisibility = FeatureFlag.isEnabled(
  FeatureFlag.features.workflowsVisibility,
);

const useStyles = makeStyles(({ typography, palette }) => ({
  sectionHeader: {
    fontSize: "1.5rem",
    fontWeight: typography.fontWeightMedium,
    marginBottom: 20,
  },
  formSelectPadding: {
    paddingBottom: "1.4rem",
  },
  formContainer: {
    marginTop: 0,
    padding: "3px 10px",
  },
  hidden: {
    display: "none",
  },
  selectWrapper: {
    marginBottom: 20,
  },
  blankMenu: {
    height: 33,
  },
}));

const InformationSection = ({
  workflow,
  deleteProcesses,
  path,
  filename,
  isActive,
  shouldDisableWFLanguageSelector,
  form,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { variablePath } = WorkflowForm.formConfig;
  const formVariableValues =
    useSelector(getFormValues(form, variablePath)) || [];

  return (
    <div
      className={classnames({
        [classes.hidden]: !isActive,
      })}
    >
      <Grid item xs={12} md={12}>
        <Typography className={classes.sectionHeader}>
          {t("Workflow Information")}
        </Typography>
      </Grid>
      <Paper className={commonClasses.commonPaperNoPadding}>
        <Grid
          container
          direction={"row"}
          spacing={2}
          className={classes.formContainer}
        >
          <FormSection title={t("Basic Information")}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FieldSelector
                      name={"information.displayName"}
                      label={t("Workflow Name")}
                      type="text"
                      required
                    />
                  </Grid>
                  {path && (
                    <Grid item xs={12} md={8}>
                      <TextField
                        className={classes.formSelectPadding}
                        variant="outlined"
                        value={path}
                        label={t("File path")}
                        type="text"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  )}
                  {filename && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        className={classes.formSelectPadding}
                        variant="outlined"
                        value={filename}
                        label={t("File name")}
                        type="text"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FieldSelector
                      name={"information.category"}
                      label={t("Tags")}
                      type="text"
                      format={(value) => value?.replace(/ *\| */g, ", ") ?? "-"}
                      disabled
                    />
                  </Grid>
                  {deleteProcesses?.length > 0 && (
                    <Grid item xs={12} className={classes.selectWrapper}>
                      <FormSelect
                        name="information.processToAssociateToTrashIcon"
                        label={t("Delete process associated with Trash icon")}
                        variant="outlined"
                        required={false}
                        defaultValue={workflow.processToAssociateToTrashIcon}
                      >
                        <MenuItem
                          key={"NA"}
                          value={" "}
                          className={classes.blankMenu}
                        >
                          {""}
                        </MenuItem>
                        {deleteProcesses.map(({ displayName }) => (
                          <MenuItem key={displayName} value={displayName}>
                            {displayName}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    </Grid>
                  )}
                  <Grid item xs={12} className={classes.selectWrapper}>
                    <FormSelect
                      name="information.displayField"
                      label={t("Variable for Workflow Instance Name")}
                      variant="outlined"
                      required={false}
                    >
                      <MenuItem
                        value={"service_id"}
                        className={classes.blankMenu}
                      >
                        {""}
                      </MenuItem>
                      {formVariableValues.map(({ name, displayName }) => (
                        <MenuItem
                          value={Variable.removePrefix(name)}
                          key={name}
                        >
                          {displayName}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldSelector
                      name="information.description"
                      label={t("Description")}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.selectWrapper}>
                    {shouldDisableWFLanguageSelector ? (
                      <FieldSelector
                        name="information.serviceTaskType"
                        label={t("Workflow Language")}
                        type="text"
                        disabled
                      />
                    ) : (
                      <FormSelect
                        name="information.serviceTaskType"
                        label={t("Workflow Language")}
                        variant="outlined"
                      >
                        {Object.keys(Workflow.serviceTaskType).map((key) => (
                          <MenuItem key={key} value={key}>
                            {Workflow.serviceTaskType[key].language}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    )}
                  </Grid>
                  {shouldShowVisibility && (
                    <Grid item xs={12} className={classes.selectWrapper}>
                      <FormSelect
                        name="information.visibility"
                        label={t("Select minimum role to see the workflow")}
                        variant="outlined"
                        required={false}
                      >
                        {roles.map(({ name, id }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          </FormSection>
        </Grid>
      </Paper>
    </div>
  );
};

InformationSection.propTypes = {
  isActive: PropTypes.bool.isRequired,
  workflow: PropTypes.object,
  shouldDisableWFLanguageSelector: PropTypes.bool.isRequired,
  form: PropTypes.string.isRequired,
};

export default InformationSection;
