import React from "react";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";

import RepositoryRoute from "./RepositoryRoute";
import Repository from "./Repository";

export default () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const directories = pathname
    .split("/")
    .slice(2)
    .filter((directory) => Boolean(directory));

  return (
    <Switch>
      <RepositoryRoute
        path={path}
        pathName={pathname}
        directories={directories}
      >
        <Repository directories={directories} />
      </RepositoryRoute>
    </Switch>
  );
};
