import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import get from "lodash/get";

import { makeStyles } from "@material-ui/core";
import {
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Paper,
  Divider,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import {
  KeyboardTab as CollapseIcon,
  Create as EditIcon,
  FileCopy as CopyIcon,
} from "@material-ui/icons";
import ClipboardIcon from "msa2-ui/src/components/ClipboardIcon";

const useStyles = makeStyles(({ typography, palette }) => ({
  panelContainer: {
    overflowY: "scroll",
    height: "65vh",
    position: "fixed",
    marginRight: 20,
    padding: 10,
  },
  titleWrapper: {
    padding: "3px 5px 3px 17px",
  },
  title: {
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.5,
  },
  variableCount: {
    fontWeight: typography.fontWeightLight,
    letterSpacing: 0.5,
    margin: 12,
  },
  collapseIcon: {
    cursor: "pointer",
  },
  divider: {
    width: "inherit",
  },
  variableWrapper: {
    width: "inherit",
    position: "relative",
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
  },
  variableNameWrapper: {
    padding: "8px 17px",
  },
  variableName: {
    fontSize: "1rem",
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.5,
  },
  details: {
    margin: "0 12px 12px 31px",
  },
  detailLabel: {
    fontWeight: typography.fontWeightLight,
    letterSpacing: 0.25,
    color: palette.text.secondary,
  },
  detailValue: {
    letterSpacing: 0.25,
  },
  editButton: {
    position: "absolute",
    right: 5,
    top: 5,
  },
}));

const VariableOverview = (props) => {
  const { index, variable, onEditVariableClicked, displayItems } = props;
  const classes = useStyles();
  const { t } = useTranslation("variables");
  const [hover, setHover] = useState(false);
  const commonClasses = useCommonStyles();

  return (
    <Grid
      container
      className={classnames(
        classes.variableWrapper,
        hover && commonClasses.commonHoverArea,
      )}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => setHover(true)}
    >
      <Grid
        item
        xs={10}
        className={classnames(
          commonClasses.commonFlexStart,
          classes.variableNameWrapper,
        )}
      >
        <Typography className={classes.variableName}>
          {"$"}
          {variable.name}
        </Typography>
      </Grid>
      <Grid item className={classes.editButton}>
        {hover && (
          <>
            <ClipboardIcon
              content={variable.name}
              item={t("Variable name")}
              icon={({ onClick }) => (
                <IconButton onClick={onClick}>
                  <CopyIcon color="primary" />
                </IconButton>
              )}
            />
            <IconButton onClick={() => onEditVariableClicked(index, variable)}>
              <EditIcon color="primary" />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid item xs={12} container className={classes.details}>
        {displayItems.map((item) => (
          <Fragment key={item.key}>
            <Grid item xs={5}>
              <Typography variant={"body1"} className={classes.detailLabel}>
                {item.displayName}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant={"body1"} className={classes.detailValue}>
                {String(
                  get(
                    item.transform,
                    variable[item.key],
                    get(variable, item.key, ""),
                  ),
                )}
              </Typography>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

const VariableReferencePanel = (props) => {
  const {
    variables = [],
    displayItems,
    onEditVariableClicked,
    handleCollapse,
  } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation("variables");

  return (
    <>
      <Paper
        className={classnames(
          commonClasses.commonPaperNoPadding,
          classes.panelContainer,
        )}
      >
        <Grid container className={classes.titleWrapper}>
          <Grid item xs={10} className={commonClasses.commonFlexStart}>
            <Typography variant="body1" className={classes.title}>
              {t("VARIABLES")}
            </Typography>
            <Typography variant="body1" className={classes.variableCount}>
              <NumberFormat
                value={variables ? variables.length : 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <IconButton
              onClick={handleCollapse}
              className={classes.collapseIcon}
            >
              <CollapseIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        {!variables ? (
          <div
            className={classnames(
              classes.itemWrapper,
              commonClasses.commonFlexCenter,
            )}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {variables.map((variable, i) => {
              return (
                <Fragment key={i}>
                  <VariableOverview
                    index={i}
                    variable={variable}
                    onEditVariableClicked={onEditVariableClicked}
                    displayItems={displayItems}
                  />
                  <Divider className={classes.divider} />
                </Fragment>
              );
            })}
          </>
        )}
      </Paper>
    </>
  );
};

VariableReferencePanel.propTypes = {
  variables: PropTypes.array,
  displayItems: PropTypes.array.isRequired,
  onEditVariableClicked: PropTypes.func.isRequired,
  handleCollapse: PropTypes.func.isRequired,
};

export default VariableReferencePanel;
