import isNil from "lodash/isNil";
import get from "lodash/get";

import Repository from "msa2-ui/src/services/Repository";
import MicroserviceCommand from "msa2-ui/src/services/MicroserviceCommand";
import Variable from "msa2-ui/src/services/Variable";

const INIT_PATH = "CommandDefinition/microservices";
const KEY_VARIABLE = "object_id";

/**
 * Returns each command as a key on an object and a boolean indicating
 * whether that command is implemented on microservice i.e { CREATE: true }
 * @param {array} commands - the commands on the microservice
 */
const hasCommands = (commands) =>
  commands.reduce(
    (supportedCommands, { name, operation, output, rest, xpath }) => {
      return {
        ...supportedCommands,
        // return true if any of operation, rest, xpath has value
        [name]: [operation, output, rest, xpath].some(
          (command) => !isNil(command) && command !== "",
        ),
      };
    },
    {},
  );

/**
 * Gets the import command operation field to populate the redux form.
 *
 * @param microservice where we can find the command
 * @param commandType the command we want to find
 * @returns either the value in operation field or empty string if not found
 */

const getCommandOperation = (microservice, commandType) => {
  const commands = get(microservice, "command", []);

  const command = commands.find((command) => command.name === commandType);
  return MicroserviceCommand.make(commandType, command);
};

function getVariable(formValues) {
  return formValues.variables.variable?.map((variable) => ({
    ...variable,
    name: `${Variable.variablePrefix}.${Variable.removePrefix(
      variable.name.trim(),
    )}`,
  }));
}

/**
 *
 * @param formValues
 * @returns {{information: {displayName: *, icon: string, description: *, category: string, displayField: string, order: number, visibility: string}, variables: {}, example: {content: string}, command:  metaInformationList: *[]}}
 */
export const createMicroserviceObject = (formValues) => {
  const fileName = Repository.convertStringToFilename(
    formValues.information.displayName.trim(),
    ".xml",
  );
  return {
    information: {
      displayName: formValues.information.displayName?.trim(),
      icon: "none",
      description: formValues.information.description?.trim(),
      directoryNames: formValues.information.directoryNames,
      category: formValues.information.category,
      displayField: formValues.information.displayField,
      order: 0,
      visibility: "5",
      configType: formValues.information.configType,
      importRank: formValues.information.importRank,
    },
    variables: {
      variable: getVariable(formValues),
      frozen: 0,
    },
    example: formValues.example,
    command: formValues.command,
    metaInformationList: [
      {
        type: "FILE",
        uri: formValues.metaInformationList[0].uri,
        file: true,
        name: fileName,
        displayName: fileName,
        repositoryName: "CommandDefinition",
        parentURI: "CommandDefinition",
        fileType: "text",
        tag: "string",
        comment: "string",
        modelId: formValues.metaInformationList[0]?.modelId,
        vendorId: formValues.metaInformationList[0]?.vendorId,
      },
    ],
  };
};

export default {
  INIT_PATH,
  KEY_VARIABLE,
  hasCommands,
  getCommandOperation,
  createMicroserviceObject,
};
