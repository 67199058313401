import React from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Dialog from "msa2-ui/src/components/Dialog";
import useApi from "msa2-ui/src/hooks/useApi";
import { getDeploymentSettingsBySubtenant } from "msa2-ui/src/api/deploymentSettings";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

const TABLE_HEADER = [
  {
    id: "name",
    name: "Name",
    align: "left",
  },
  {
    id: "ds",
    name: "Managed Entities Attached",
    align: "left",
  },
];

const ListDeploymentSettingsDialog = ({
  title,
  onClose,
  deploymentSettings,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const subtenantId = useSelector(getSelectedSubtenant).id;

  const [isLoading, error, response = []] = useApi(
    getDeploymentSettingsBySubtenant,
    {
      subtenantId,
    },
    !subtenantId,
  );

  const renderTableRow = ({ id, name, attachedManagedEntities }) => {
    const managedEntitiesCount = attachedManagedEntities.length;
    return (
      <TableRow key={id}>
        <TableCell>
          <Typography variant="h4" className={commonClasses.commonTablePrimary}>
            {name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h4" className={commonClasses.commonTablePrimary}>
            {managedEntitiesCount}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderDeploymentSettings = (response) => {
    const attachedDeploymentSettings = response.filter((item) =>
      deploymentSettings.includes(item.id),
    );
    return attachedDeploymentSettings?.length > 0 ? (
      attachedDeploymentSettings.map((item) => renderTableRow(item))
    ) : (
      <TableRow>
        <TableCell align="center" colSpan={TABLE_HEADER.length}>
          {t("There is nothing to display", {
            name: t("Deployment Settings"),
          })}
        </TableCell>
      </TableRow>
    );
  };

  const renderError = () => {
    return (
      <TableRow>
        <TableCell align="center" colSpan={TABLE_HEADER.length}>
          <Typography color="error">
            {t("Unable to load x", { x: t("Deployment Settings") })}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Dialog maxWidth="md" onClose={onClose} title={title}>
      {isLoading ? (
        <div
          data-testid="attachment-dialog-spinner"
          className={commonClasses.commonLoaderWrapper}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table>
          <colgroup>
            {TABLE_HEADER.map((header) => (
              <col key={header.id} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_HEADER.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? renderError() : renderDeploymentSettings(response)}
          </TableBody>
        </Table>
      )}
    </Dialog>
  );
};

export default ListDeploymentSettingsDialog;
