import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";

import Lens from "@material-ui/icons/Lens";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";

import { ReactComponent as PhysicalIcon } from "msa2-ui/src/assets/icons/device_type/physical.svg";
import { ReactComponent as PublicIcon } from "msa2-ui/src/assets/icons/device_type/public.svg";
import { ReactComponent as PrivateIcon } from "msa2-ui/src/assets/icons/device_type/private.svg";
import { statusTypes } from "msa2-ui/src/components/topology/constants";
import { managedEntityStatus } from "../Constants";

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  keyWrapper: {
    padding: "5px 0",
  },
  statusWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 150,
    [breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
    "@media (min-width: 1280px) and (max-width: 1440px)": {
      justifyContent: "center",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  countWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 110,
    [breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
    "@media (min-width: 1280px) and (max-width: 1440px)": {
      justifyContent: "center",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  deviceWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 370,
    [breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
    "@media (min-width: 1280px) and (max-width: 1440px)": {
      justifyContent: "center",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  listWrapper: {
    display: "flex",
    padding: "4px 0",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItem: {
    padding: 0,
    whiteSpace: "nowrap",
    marginRight: 20,
  },
  divider: {
    borderBottom: `0.5px solid ${palette.text.primary}`,
  },
  listItemSelected: {
    textDecoration: "underline",
  },
  listItemClickable: {
    cursor: "pointer",
  },
  label: {
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightLight,
    lineHeight: "normal",
    letterSpacing: 0.3,
    color: palette.text.secondary,
    marginRight: 20,
  },
  listItemText: {
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightLight,
    lineHeight: "normal",
    letterSpacing: 0.3,
    color: palette.text.primary,
    padding: 0,
  },
  listIcon: {
    marginRight: 5,
  },
  unreachable: {
    color: palette.error.main,
    width: 12,
  },
  critical: {
    color: palette.warning.main,
    width: 12,
  },
  neverReached: {
    color: palette.info.main,
    width: 12,
  },
  connected: {
    color: palette.success.main,
    width: 12,
  },
  unreachableText: {
    color: palette.error.main,
    paddingLeft: 0,
  },
  criticalText: {
    color: palette.warning.main,
    paddingLeft: 0,
  },
  neverReachedText: {
    color: palette.info.main,
    paddingLeft: 0,
  },
  connectedText: {
    color: palette.success.main,
    paddingLeft: 0,
  },
  subContainer: {
    flex: "0 0 100%",
  },
  subContainer1: {
    flexBasis: "60%" /* First container takes up 65% of available space */,
  },
  subContainer2: {
    flexBasis: "40%" /* Second container takes up 35% of available space */,
  },
  deviceStatusLabel: {
    minWidth: "95px",
  },
}));

const deviceTypes = [
  {
    label: "Physical",
    Icon: PhysicalIcon,
  },
  {
    label: "Public",
    Icon: PublicIcon,
  },
  {
    label: "Private",
    Icon: PrivateIcon,
  },
];

const ChartKey = ({
  filterStatus,
  setFilterStatus = () => {},
  readOnly,
  summaryReport = {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [statusSummary, setStatusSummary] = useState({
    [managedEntityStatus.ERROR.label]: "",
    [managedEntityStatus.CRITICAL.label]: "",
    [managedEntityStatus.NEVERREACHED.label]: "",
    [managedEntityStatus.OK.label]: "",
  });

  useEffect(() => {
    if (!isEmpty(summaryReport)) {
      const {
        error,
        critical,
        neverReached,
        ok,
        errorPercentage,
        criticalPercentage,
        neverReachedPercentage,
        okPercentage,
      } = summaryReport;
      const delimeter = " - ";
      const parseValue = (val) => parseFloat(val).toFixed(0);

      setStatusSummary({
        [managedEntityStatus.ERROR.label]: `${error}${delimeter}${parseValue(
          errorPercentage,
        )}%`,
        [managedEntityStatus.CRITICAL
          .label]: `${critical}${delimeter}${parseValue(criticalPercentage)}%`,
        [managedEntityStatus.NEVERREACHED
          .label]: `${neverReached}${delimeter}${parseValue(
          neverReachedPercentage,
        )}%`,
        [managedEntityStatus.OK.label]: `${ok}${delimeter}${parseValue(
          okPercentage,
        )}%`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryReport]);

  return (
    <div className={classes.keyWrapper}>
      <Grid container>
        <Grid
          container
          item
          className={classnames(classes.subContainer, classes.subContainer1)}
        >
          {/* Status */}
          <List dense className={classes.listWrapper}>
            <Grid item className={classes.statusWrapper}>
              <Typography variant="subtitle1" className={classes.label}>
                {`${t("Status")}:`}
              </Typography>
            </Grid>
            <Grid item className={classes.statusWrapper}>
              {!readOnly && (
                <ListItem
                  divider={filterStatus === null}
                  classes={{ divider: classes.divider }}
                  className={classnames(
                    classes.listItem,
                    classes.listItemClickable,
                  )}
                  {...(!readOnly && { onClick: () => setFilterStatus(null) })}
                >
                  <ListItemText
                    primary="All"
                    className={classes.listItemText}
                  />
                </ListItem>
              )}
            </Grid>
            <Grid item className={classes.statusWrapper}>
              <ListItem
                divider={filterStatus === statusTypes.ERROR}
                classes={{ divider: classes.divider }}
                className={classnames(classes.listItem, {
                  [classes.listItemClickable]: !readOnly,
                })}
                {...(!readOnly && {
                  onClick: () => setFilterStatus(statusTypes.ERROR),
                })}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Lens className={classes.unreachable} />
                </ListItemIcon>
                <ListItemText
                  primary={managedEntityStatus.ERROR.label}
                  secondary={statusSummary[managedEntityStatus.ERROR.label]}
                  className={classes.listItemText}
                  secondaryTypographyProps={{
                    className: classnames(classes.unreachableText),
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item className={classes.statusWrapper}>
              <ListItem
                divider={filterStatus === statusTypes.CRITICAL}
                classes={{ divider: classes.divider }}
                className={classnames(classes.listItem, {
                  [classes.listItemClickable]: !readOnly,
                })}
                {...(!readOnly && {
                  onClick: () => setFilterStatus(statusTypes.CRITICAL),
                })}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Lens className={classes.critical} />
                </ListItemIcon>
                <ListItemText
                  primary={managedEntityStatus.CRITICAL.label}
                  secondary={statusSummary[managedEntityStatus.CRITICAL.label]}
                  className={classes.listItemText}
                  secondaryTypographyProps={{
                    className: classnames(classes.criticalText),
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item className={classes.statusWrapper}>
              <ListItem
                divider={filterStatus === statusTypes.NEVERREACHED}
                classes={{ divider: classes.divider }}
                className={classnames(classes.listItem, {
                  [classes.listItemClickable]: !readOnly,
                })}
                {...(!readOnly && {
                  onClick: () => setFilterStatus(statusTypes.NEVERREACHED),
                })}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Lens className={classes.neverReached} />
                </ListItemIcon>
                <ListItemText
                  primary={managedEntityStatus.NEVERREACHED.label}
                  secondary={
                    statusSummary[managedEntityStatus.NEVERREACHED.label]
                  }
                  className={classes.listItemText}
                  secondaryTypographyProps={{
                    className: classnames(classes.neverReachedText),
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item className={classes.statusWrapper}>
              <ListItem
                divider={filterStatus === statusTypes.OK}
                classes={{ divider: classes.divider }}
                className={classnames(classes.listItem, {
                  [classes.listItemClickable]: !readOnly,
                })}
                {...(!readOnly && {
                  onClick: () => setFilterStatus(statusTypes.OK),
                })}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Lens className={classes.connected} />
                </ListItemIcon>
                <ListItemText
                  primary={managedEntityStatus.OK.label}
                  secondary={statusSummary[managedEntityStatus.OK.label]}
                  className={classes.listItemText}
                  secondaryTypographyProps={{
                    className: classnames(classes.connectedText),
                  }}
                />
              </ListItem>
            </Grid>
          </List>
        </Grid>

        {/* Device Types */}
        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          className={classnames(classes.subContainer, classes.subContainer2)}
        >
          <List dense className={classes.listWrapper}>
            <Grid item>
              <Typography
                variant="subtitle1"
                className={classnames(classes.label, classes.deviceStatusLabel)}
              >
                {`${t("Device Types")}:`}
              </Typography>
            </Grid>
            {deviceTypes.map(({ label, Icon }) => (
              <ListItem className={classes.listItem} key={label}>
                <Icon className={classes.listIcon} />
                <ListItemText
                  primary={label}
                  className={classes.listItemText}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

ChartKey.propTypes = {
  filterStatus: PropTypes.string,
  setFilterStatus: PropTypes.func,
  readOnly: PropTypes.bool,
  summaryReport: PropTypes.object,
};

export default ChartKey;
