import React, { useEffect } from "react";
import MenuItemNotification from "msa2-ui/src/components/menu-item-notification/MenuItemNotification";
import { getRoleDetails, userRoles } from "msa2-ui/src/store/auth";
import { useSelector } from "react-redux";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import useApi from "msa2-ui/src/hooks/useApi";
import { status } from "msa2-ui/src/api/constants";
import { getLicenseStatus } from "msa2-ui/src/api/license";
import innerBus from "msa2-ui/src/utils/InnerBus";

const isLicenceEnabled = FeatureFlag.isEnabled(FeatureFlag.features.licence);

const SettingsNotification = () => {
  const roleDetails = useSelector(getRoleDetails);
  const canAccessLicence =
    roleDetails.id === userRoles.PRIVILEGED_ADMINISTRATOR && isLicenceEnabled;

  const [, , licenseResponse, meta, reloadLicense] = useApi(
    getLicenseStatus,
    {},
    !canAccessLicence,
    0,
  );
  const isLicenseExpired = meta?.status === status.NOT_FOUND;

  useEffect(() => {
    const licenseUploadHandler = () => {
      reloadLicense();
    };

    innerBus.on(innerBus.evt.LICENCE_UPLOAD, licenseUploadHandler);

    return () => {
      innerBus.off(innerBus.evt.LICENCE_UPLOAD, licenseUploadHandler);
    };
  }, [reloadLicense]);

  if (licenseResponse?.isExpiring || isLicenseExpired) {
    return <MenuItemNotification content="1" />;
  }
  return null;
};

export default SettingsNotification;
