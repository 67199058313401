import React from "react";
import PropTypes from "prop-types";
import { Table } from "@material-ui/core";
import THead from "./THead";
import TBody from "./TBody";

const OverviewTable = ({
  t,
  columns,
  editLinkBuilder,
  isError,
  isLoading,
  subtenantId,
  filterState,
  filterActions,
  monitoringProfiles,
  monitoringProfilesTotalCount,
  onAttachClick,
  onDownloadClick,
  onDeleteClick,
  onAttachedManagedEntitiesClick,
}) => {
  return (
    <Table data-testid="monitoring-profiles-table">
      <THead
        columns={columns}
        filterState={filterState}
        filterActions={filterActions}
        totalCounter={monitoringProfilesTotalCount}
      />
      <TBody
        t={t}
        editLinkBuilder={editLinkBuilder}
        isError={isError}
        isLoading={isLoading}
        colSpan={columns.length}
        subtenantId={subtenantId}
        monitoringProfiles={monitoringProfiles}
        onAttachClick={onAttachClick}
        onDownloadClick={onDownloadClick}
        onDeleteClick={onDeleteClick}
        onAttachedManagedEntitiesClick={onAttachedManagedEntitiesClick}
      />
    </Table>
  );
};

OverviewTable.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      align: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  editLinkBuilder: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  subtenantId: PropTypes.number,
  filterState: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  monitoringProfiles: PropTypes.array.isRequired,
  monitoringProfilesTotalCount: PropTypes.number.isRequired,
  onAttachClick: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onAttachedManagedEntitiesClick: PropTypes.func.isRequired,
};

export default OverviewTable;
