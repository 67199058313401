import { isObject, reduce, mergeWith } from "lodash";

const flattenObject = (obj, path = []) =>
  !isObject(obj)
    ? { [path.join(".")]: obj }
    : reduce(
        obj,
        (cum, next, key) =>
          mergeWith(
            cum,
            flattenObject(next, [...path, key]),
            (objValue, srcValue) => {
              if (objValue && !srcValue) {
                return objValue;
              }
              if (srcValue) {
                return srcValue;
              }
            },
          ),
        {},
      );

export default flattenObject;
