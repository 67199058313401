import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import cloneDeep from "lodash/cloneDeep";

const LINK_DISTANCE = 90;
const LINK_ITERATIONS = 10;
const CHARGE_STRENGTH = -500;
const ZOOM_STEP = 0.3;

class Graph extends PureComponent {
  static ZOOM_IN = "ZOOM_IN";
  static ZOOM_OUT = "ZOOM_OUT";

  static d3Classes = {
    node: "d3Node",
    link: "d3Link",
    label: "d3Label",
    linkBox: "d3LinkBox",
  };

  _svgRef = null;
  _containerRef = null;
  _d3Simulation = null;
  _d3NodesData = null;
  _d3LinksData = null;
  _zoomBehaviour = null;
  _prevNodes = [];

  componentDidMount() {
    this._renderChart();
  }

  componentWillUnmount() {
    if (this._d3Simulation) {
      this._d3Simulation.stop();
    }
  }

  componentDidUpdate() {
    const { nodes } = this.props.data;
    // We need to fix nodes in their previous positions otherwise they will change it on each render.
    if (nodes !== this._prevNodes) {
      nodes.forEach((node, i) => {
        const prevNode = this._prevNodes[i];

        if (prevNode && node.id === prevNode.id) {
          node.fx = prevNode.x;
          node.fy = prevNode.y;
        }
      });
    }
    this._renderChart();
  }

  /**
   * Public function for retrieving node's data.
   * It is used for showing context menu for the selected node.
   *
   * @typedef {Object} Node
   * @property {String} id
   * @property {Number} fx
   * @property {Number} fy
   *
   * @returns {Node}
   */
  getClosestParentNodeData(maybeNodesChildEl) {
    const node = maybeNodesChildEl.closest(`.${Graph.d3Classes.node}`);

    if (!node) {
      return null;
    }

    return d3.select(node).data()[0];
  }

  /**
   * Public function for nodes data retrieval
   *
   * @typedef {Object} Node
   * @property {String} id
   * @property {Number} fx
   * @property {Number} fy
   *
   * @returns {Node[]}
   */
  getNodesData() {
    return d3
      .select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.node}`)
      .data();
  }

  /**
   * Public function to trigger zoomIn behaviour.
   */
  zoomGraphIn() {
    this._zoomGraph(Graph.ZOOM_IN);
  }

  /**
   * Public function to trigger zoomOut behaviour.
   */
  zoomGraphOut() {
    this._zoomGraph(Graph.ZOOM_OUT);
  }

  _zoomGraph = (direction) => {
    if (!this._zoomBehaviour || !this._svgRef) {
      return;
    }

    d3.select(this._svgRef)
      .transition()
      .call(
        this._zoomBehaviour.scaleBy,
        direction === Graph.ZOOM_IN ? 1 + ZOOM_STEP : 1 - ZOOM_STEP,
      );
  };

  _zoomHandler = (event) => {
    d3.select(this._containerRef).attr("transform", event.transform);
  };

  _configureZoomBehaviour() {
    this._zoomBehaviour = d3
      .zoom()
      .scaleExtent([0.3, 3])
      .on("zoom", this._zoomHandler);

    d3.select(this._svgRef)
      .call(this._zoomBehaviour)
      .on("wheel.zoom", null);
  }

  _nodeDragHandler = (simulation) => {
    const dragStarted = (event, node) => {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      node.fx = node.x;
      node.fy = node.y;
    };

    const dragged = (event, node) => {
      node.fx = event.x;
      node.fy = event.y;
    };

    const dragEnded = (event) => {
      if (event.active) simulation.alphaTarget(0);
    };

    return d3
      .drag()
      .on("start", dragStarted)
      .on("drag", dragged)
      .on("end", dragEnded);
  };

  _toggleLabelVisibility() {
    const { showLinkLabel } = this.props;

    d3.select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.linkBox}`)
      .style("display", showLinkLabel ? "block" : "none");
  }

  _renderChart() {
    const { data, iconSize, labelSize } = this.props;

    this._d3NodesData = cloneDeep(data.nodes);
    this._d3LinksData = cloneDeep(data.links);

    this._d3Simulation = d3
      .forceSimulation()
      .nodes(this._d3NodesData)
      .force(
        "link",
        d3
          .forceLink()
          .links(this._d3LinksData)
          .id((link) => link.id)
          .distance(LINK_DISTANCE)
          .iterations(LINK_ITERATIONS),
      )
      .force("charge", d3.forceManyBody().strength(CHARGE_STRENGTH))
      .force("x", d3.forceX())
      .force("y", d3.forceY());

    const d3Links = this._renderD3Links(this._d3LinksData);
    const d3Nodes = this._renderD3IconNodes();
    const d3Labels = this._renderD3Labels();
    const d3LinkLabels = this._renderD3LinkLabels();

    this._d3Simulation.on("tick", () => {
      d3Links
        .attr("x1", (link) => link.source.x)
        .attr("y1", (link) => link.source.y)
        .attr("x2", (link) => link.target.x)
        .attr("y2", (link) => link.target.y);

      const iconOffset = iconSize / 2;

      d3Nodes.attr(
        "transform",
        ({ x, y }) => `translate(${x - iconOffset}, ${y - iconOffset})`,
      );

      d3Labels
        .attr("x", (node) => node.x + iconSize * 0.75)
        .attr("y", (node) => node.y)
        .attr("font-size", labelSize);

      d3LinkLabels.attr(
        "transform",
        (d) =>
          `translate(${(d.source.x + d.target.x) / 2}, ${(d.source.y +
            d.target.y) /
            2})`,
      );

      this._prevNodes = cloneDeep(d3Nodes.data());
    });

    this._toggleLabelVisibility();
    this._configureZoomBehaviour();
  }

  // Add labels as rectangles on links bewteen nodes
  _renderD3LinkLabels() {
    const { labelSize, linkStrokeColor } = this.props;

    d3.select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.linkBox}`)
      .remove();

    const linkLabels = d3
      .select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.linkBox}`)
      .data(this._d3LinksData)
      .enter()
      .append("g") // A Group for each label
      .attr("class", Graph.d3Classes.linkBox);

    linkLabels.each(function({ label = "" }) {
      const labelGroup = d3.select(this);
      const labelRectangle = labelGroup
        .append("rect")
        .style("fill", "white")
        .style("stroke", linkStrokeColor)
        .style("stroke-width", 0.5)
        .style("rx", 3)
        .style("ry", 3);

      const labelText = labelGroup
        .append("text")
        .attr("x", 0) // Center text horizontally
        .attr("y", 0) // Center text vertically
        .attr("font-size", labelSize)
        .attr("text-anchor", "middle") // Center text horizontally
        .attr("dy", "-1em"); // Adjust text vertical alignment if needed

      label.split("\n").forEach((lineText, index) => {
        labelText
          .append("tspan")
          .attr("x", 0)
          .attr("dy", index === 0 ? "0" : "1.2em") // Spacing between lines if needed
          .text(lineText);
      });

      const bbox = labelText.node().getBBox();

      const padding = 10;

      labelRectangle
        .attr("x", bbox.x - padding)
        .attr("y", bbox.y - padding)
        .attr("width", bbox.width + 2 * padding)
        .attr("height", bbox.height + 2 * padding);
    });

    return linkLabels;
  }

  _renderD3Links() {
    const { linkStrokeWidth, linkStrokeOpacity, linkStrokeColor } = this.props;

    return d3
      .select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.link}`)
      .data(this._d3LinksData, (d) => d.id)
      .join("line")
      .attr("class", Graph.d3Classes.link)
      .attr("stroke", linkStrokeColor)
      .attr("stroke-opacity", linkStrokeOpacity)
      .attr("stroke-width", linkStrokeWidth)
      .attr("stroke-dasharray", "3 5");
  }

  _renderD3IconNodes() {
    const { iconSize, iconRenderer } = this.props;

    d3.select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.node}`)
      .remove();

    d3.select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.node}`)
      .data(this._d3NodesData, (d) => d.id)
      .join("svg:g")
      .attr("class", Graph.d3Classes.node)
      .attr("data-testid", "graph-node")
      .call(this._nodeDragHandler(this._d3Simulation));

    if (iconRenderer) {
      return d3
        .select(this._containerRef)
        .selectAll(`.${Graph.d3Classes.node}`)
        .data(this._d3NodesData, (d) => d.id)
        .html((node) => iconRenderer(node));
    }

    return d3
      .select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.node}`)
      .data(this._d3NodesData, (d) => d.id)
      .append("svg:image")
      .attr("xlink:href", (node) => node.icon)
      .attr("width", iconSize)
      .attr("height", iconSize);
  }

  _renderD3Labels() {
    const { labelColor } = this.props;

    return d3
      .select(this._containerRef)
      .selectAll(`.${Graph.d3Classes.label}`)
      .data(this._d3NodesData, (d) => d.id)
      .join("text")
      .attr("class", Graph.d3Classes.label)
      .attr("text-anchor", "right")
      .text((node) => node.id)
      .attr("fill", labelColor);
  }

  render() {
    const { width, height } = this.props;

    return (
      <svg
        data-testid="topology-graph"
        ref={(node) => (this._svgRef = node)}
        viewBox={`${-width / 2} ${-height / 2} ${width} ${height}`}
      >
        <g className="container" ref={(node) => (this._containerRef = node)} />
      </svg>
    );
  }
}

Graph.defaultProps = {
  labelColor: "#ffffff",
  linkStrokeWidth: 1,
  linkStrokeOpacity: 0.9,
  linkStrokeColor: "#7E93A6",
  iconSize: 25,
  labelSize: 10,
};

Graph.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        fx: PropTypes.number,
        fy: PropTypes.number,
        icon: PropTypes.string,
        color: PropTypes.string,
      }),
    ).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  labelColor: PropTypes.string,
  linkStrokeWidth: PropTypes.number,
  linkStrokeOpacity: PropTypes.number,
  linkStrokeColor: PropTypes.string,
  iconSize: PropTypes.number,
  labelSize: PropTypes.number,
  iconRenderer: PropTypes.func,
  showLinkLabel: PropTypes.bool,
};

export default Graph;
