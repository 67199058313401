import React from "react";
import PropTypes from "prop-types";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { markManagedEntityAsProvisioned } from "msa2-ui/src/api/managedEntity";
import { MenuItem } from "msa2-ui/src/components/menu";

const ActivateWithoutConnectionAction = ({ node, onChange }) => {
  const t = useBoundedTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();
  const [showActivationDialog, ActivationDialog] = useDialog();

  const handleActivation = async () => {
    enqueueSnackbar(`${t("Marking Managed Entity as provisioned")}...`, {
      variant: "info",
    });

    const [error] = await markManagedEntityAsProvisioned({
      token,
      managedEntityId: node.__metadata__.objectId,
    });

    if (error) {
      enqueueSnackbar(
        error.getMessage(
          t("Unable to x", { x: t("mark Managed Entity as provisioned") }),
        ),
        {
          variant: "error",
        },
      );
      return;
    }

    enqueueSnackbar(t("Managed Entity marked as provisioned"), {
      variant: "success",
    });
    onChange();
  };

  return (
    <>
      <ActivationDialog
        title={t("Confirm Request")}
        content={t("Are you sure you want to activate x without connection?", {
          x: node.id,
        })}
        onExec={handleActivation}
      />
      <MenuItem
        onClick={showActivationDialog}
        id="ACTIVATE_WITHOUT_CONNECTION_ACTION"
      >
        {t("Activate without connection")}
      </MenuItem>
    </>
  );
};

ActivateWithoutConnectionAction.propTypes = {
  node: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ActivateWithoutConnectionAction;
