import React from "react";

import { Grid, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleContainer: {
    maxWidth: "60vw",
  },
  avatar: {
    backgroundColor: "#e5e8f0",
  },
  version: {
    paddingRight: 3,
    paddingLeft: 3,
    fontSize: "0.625rem",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.colors.blueDark1,
    backgroundColor: "#e5e8f0",
  },
}));

const ModalTitle = ({ icon, name, uri, version }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      alignItems={"center"}
      justifyContent={"flex-start"}
      spacing={1}
      wrap="nowrap"
    >
      <Grid item>
        <Avatar className={classes.avatar}>{icon}</Avatar>
      </Grid>
      <Grid item className={classes.titleContainer}>
        <Typography noWrap title={uri} className={classes.name}>
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.version}>{version}</Typography>
      </Grid>
    </Grid>
  );
};

export default ModalTitle;
