import React from "react";
import PropTypes from "prop-types";

import {
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";

const useCommonStyles = makeStyles(commonStyles);

const TableLoading = ({ numberOfTableColumns = 1 }) => {
  const commonClasses = useCommonStyles();

  return (
    <TableBody>
      <TableRow>
        <TableCell
          colSpan={numberOfTableColumns}
          className={commonClasses.commonLoaderWrapperTable}
        >
          <CircularProgress />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

TableLoading.propTypes = {
  numberOfTableColumns: PropTypes.number,
};

export default TableLoading;
