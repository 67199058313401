import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { UserTypes } from "msa2-ui/src/store/auth";

import { withStyles } from "@material-ui/core";

import AdminDashboard from "./Admin";
import DeveloperDashboard from "./Developer";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";

const styles = () => ({
  root: {
    flexGrow: 1,
    alignItems: "stretch",
    height: "100%",
  },
  mainWrapper: {
    marginTop: 42,
    borderRadius: 8,
    height: "100%",
    minHeight: "100vh",
  },
});

const Dashboard = (props) => {
  const { classes, isDeveloper } = props;

  return (
    <ErrorBoundary>
      <div className={classes.mainWrapper}>
        {isDeveloper ? <DeveloperDashboard /> : <AdminDashboard />}
      </div>
    </ErrorBoundary>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  isDeveloper: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  isDeveloper: auth.userType === UserTypes.developer,
});

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(Dashboard)),
);
