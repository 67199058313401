import React from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import omit from "lodash/omit";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";

import { change } from "redux-form";
import { getFormValues } from "msa2-ui/src/store/form";
import { IconButton, Tooltip } from "@material-ui/core";
import TreeView from "msa2-ui/src/components/TreeView";

import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import Parser from "msa2-ui/src/services/Parser";
import TreeItem from "msa2-ui/src/components/TreeItem";
import ParserTreeItemArray from "./ParserTreeItemArray";
import ParserField from "./ParserField";
import ParserTreeFolder from "./ParserTreeFolder";

const VariableExtractors = ({
  change: reduxFormChange,
  form,
  variableExtractorPath,
  variableExtractor,
}) => {
  const { t } = useTranslation();
  const formObject = useSelector(getFormValues(form));
  const configType = get(formObject, MicroserviceForm.fields.configType);
  const variables = get(formObject, MicroserviceForm.formConfig.variablePath);
  const change = (field, value) => reduxFormChange(form, field, value);
  const handleSave = (currentPath, value) => {
    change(currentPath, value);
    const arrayNames = currentPath.split(".").reduce((acc, cur, i, arr) => {
      if (cur === "array") {
        const arrayPath = arr.slice(0, i + 1).join(".");
        const arrayName = get(formObject, arrayPath)?.name;
        return acc.concat(arrayName);
      }
      return acc;
    }, []);
    const typedVariables = Parser.pickVariables(value);
    const newVariables = Parser.getVariablesToAdd(
      variables,
      arrayNames.length > 0
        ? typedVariables.map((variableName) =>
            [...arrayNames, variableName].join(".0."),
          )
        : typedVariables,
    );
    if (newVariables.length > 0) {
      change(
        MicroserviceForm.formConfig.variablePath,
        concat(variables, newVariables),
      );
    }
  };
  const handleDelete = (path) => {
    const [parentObject, parentPath, targetKey] = Parser.getParent(
      formObject,
      path,
    );
    const filteredParent = Array.isArray(parentObject)
      ? parentObject.filter((_, i) => i !== parseInt(targetKey))
      : omit(
          parentObject,
          targetKey,
          // regexp which is sibling of xpath should be deleted with xpath
          targetKey === "xpath" && "regexp",
        );
    change(parentPath, filteredParent);
    if (Parser.allSiblingsAreNull(filteredParent)) {
      const [grandParentObject, grandParentPath, parentKey] = Parser.getParent(
        formObject,
        parentPath,
      );
      if (Array.isArray(grandParentObject)) {
        const filteredGrandParent = grandParentObject.filter(
          (e, i) => i.toString() !== parentKey,
        );
        change(grandParentPath, filteredGrandParent);
      }
    }
  };

  const VariableExtractor = ({ keyValue: [key, value], formPath, ...rest }) => {
    const currentPath = [formPath, key].join(".");

    const nestParsers = (value, addKey) =>
      Object.entries(value)
        // skip rendering "regex" which is a sibling of "xpath"
        .filter(([key]) => {
          const nextPath = [currentPath, addKey, key].join(".");
          const shouldRenderAsChild = Parser.shouldRenderAsChild(
            formObject,
            nextPath,
            configType,
          );
          return !shouldRenderAsChild;
        })
        .map((keyValue, i) => {
          const formPath =
            addKey === undefined
              ? currentPath
              : [currentPath, addKey].join(".");

          return (
            <VariableExtractor
              key={i}
              keyValue={keyValue}
              formPath={formPath}
              {...rest}
            />
          );
        });
    const parserDefinition = Parser.getParserDefinitionByKey(key, configType);
    if (
      !parserDefinition ||
      !value ||
      // do not render "regex" which is a sibling of "xpath"
      Parser.shouldRenderAsChild(formObject, currentPath, configType)
    )
      return <></>;
    const { icon: Icon, name, children } = parserDefinition;
    const AddIcon = ({ id }) => {
      const {
        icon: Icon,
        defaultValue,
        name,
        sibling,
      } = Parser.getParserDefinitionByKey(id, configType);
      const handleAdd = () => {
        change(currentPath, concat(value, { [id]: defaultValue, ...sibling }));
      };
      return (
        <Tooltip title={t("Add an extractor.", { name })}>
          <span>
            <IconButton
              id={`CONFIGURATION_PARSER_ARRAY_BTN_EDIT_${currentPath}_${id}`}
              aria-label={t(name)}
              onClick={handleAdd}
            >
              <Icon />
            </IconButton>
          </span>
        </Tooltip>
      );
    };

    function renderAddButtons(AddIconComponent) {
      return () =>
        children.map((child) => <AddIconComponent key={child} id={child} />);
    }

    switch (key) {
      case "array": {
        // only "regexp" under "array" can be an array shape, so we keep
        // add icon button to add "regexp" separately
        const AddIcon = ({ id }) => {
          const {
            icon: Icon,
            defaultValue,
            name,
          } = Parser.getParserDefinitionByKey(id, configType);
          // regexp can be added without limit but other parsers can be added only once
          const canAdd =
            id === "regexp" ||
            value[id] === null ||
            value[id] === undefined ||
            value[id]?.length === 0;

          if (!canAdd) return <></>;
          const handleAdd = () => {
            id === "regexp"
              ? change(
                  [currentPath, id].join("."),
                  value[id] ? concat(value[id], defaultValue) : [defaultValue],
                )
              : change(currentPath, { ...value, [id]: defaultValue });
          };
          return (
            <Tooltip title={t("Add an extractor.", { name })}>
              <span>
                <IconButton
                  id={`CONFIGURATION_PARSER_ARRAY_BTN_EDIT_${currentPath}_${id}`}
                  onClick={handleAdd}
                >
                  <Icon />
                </IconButton>
              </span>
            </Tooltip>
          );
        };
        // Sort "xpath" to the top of object so that xpath can be looked next to Array name
        const sortedValue = value.xpath
          ? {
              xpath: value.xpath,
              lines: value.lines,
              ...omit(value, "xpath", "lines", "name"),
            }
          : value;
        return (
          <TreeItem
            labelPrefix={t("Array ")}
            label={value?.name}
            icon={Icon}
            component={ParserTreeItemArray}
            hasAction
            onSave={(value) => change([currentPath, "name"].join("."), value)}
            addButtons={renderAddButtons(AddIcon)}
            onDelete={() => handleDelete(currentPath)}
            {...rest}
          >
            {nestParsers(sortedValue)}
          </TreeItem>
        );
      }
      case "line":
      case "ignore": {
        return (
          <TreeItem
            label={t(name)}
            onDelete={() => handleDelete(currentPath)}
            addButtons={renderAddButtons(AddIcon)}
            hasAction
            {...rest}
          >
            {value.map((child, i) => nestParsers(child, i))}
          </TreeItem>
        );
      }
      case "xpath": {
        const [parentObject, parentPath] = Parser.getParent(
          formObject,
          currentPath,
        );
        const siblingRegexp = parentObject["regexp"];
        const { icon: regexpIcon } = Parser.getParserDefinitionByKey(
          "regexp",
          configType,
        );
        // If xpath has a regexp object as a sibling, it should render it as its child.
        return !isEmpty(siblingRegexp) ? (
          <TreeItem
            label={value}
            icon={Icon}
            component={ParserTreeItemArray}
            onSave={(value) => change(currentPath, value)}
            addButtons={renderAddButtons(AddIcon)}
            onDelete={() => handleDelete(currentPath)}
            hasAction
            {...rest}
          >
            <TreeItem
              icon={regexpIcon}
              value={siblingRegexp}
              onSave={(value) =>
                handleSave([parentPath, "regexp"].join("."), value)
              }
            />
          </TreeItem>
        ) : (
          <TreeItem
            icon={Icon}
            value={value}
            onSave={(value) => change(currentPath, value)}
            onDelete={() => handleDelete(currentPath)}
            {...rest}
          />
        );
      }
      case "regexp":
      case "mregexp": {
        // regexp could be either array or object.
        return Array.isArray(value) ? (
          value.map((regexpValue, i) => (
            <TreeItem
              icon={Icon}
              value={regexpValue}
              onSave={(value) => handleSave([currentPath, i].join("."), value)}
              onDelete={() => handleDelete([currentPath, i].join("."))}
              {...rest}
            />
          ))
        ) : (
          <TreeItem
            icon={Icon}
            value={value}
            onSave={(value) => handleSave(currentPath, value)}
            onDelete={() => handleDelete(currentPath)}
            {...rest}
          />
        );
      }
      case "lines": {
        return value.map((child, i) => nestParsers(child, i));
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <TreeView folderComponent={ParserTreeFolder} itemComponent={ParserField}>
      {variableExtractor.map((parser, i) =>
        Object.entries(parser).map((keyValue) => (
          <VariableExtractor
            keyValue={keyValue}
            formPath={[variableExtractorPath, i].join(".")}
          />
        )),
      )}
    </TreeView>
  );
};

VariableExtractors.propTypes = {
  form: PropTypes.string.isRequired,
  variableExtractorPath: PropTypes.string.isRequired,
  variableExtractor: PropTypes.array.isRequired,
};

export default connect(null, { change })(VariableExtractors);
