import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core";
import {
  Grid,
  TableCell as MUITableCell,
  TableRow as MUITableRow,
} from "@material-ui/core";

const useStyles = makeStyles(({ palette, spacing }) => ({
  tableRow: {
    "& .tableActions": {
      visibility: "hidden",
    },
    "&:hover .tableActions": {
      visibility: "visible",
    },
    "&:hover, &:active, &:focus": {
      backgroundColor: "rgba(128, 162, 217, 0.05)",
      outline: palette.primary.main + " solid 2px",
    },
  },
  actionsWrapper: {
    paddingRight: spacing(1),
  },
  root: {
    height: 64,
  },
}));

const TableRow = ({ children, actions, classes: classNames = {}, className, ...rest }) => {
  const classes = useStyles();
  
  return (
    <MUITableRow
      classes={{ root: classes.root }}
      className={classnames(classes.tableRow, classNames.row, className )}
      {...rest}
    >
      {children}
      <MUITableCell align="center" padding="none">
        <Grid
          container
          className={classnames(
            "tableActions",
            classes.actionsWrapper,
            classNames.actions,
          )}
          justifyContent="flex-end"
        >
          {actions}
        </Grid>
      </MUITableCell>
    </MUITableRow>
  );
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.element,
  classes: PropTypes.shape({
    root: PropTypes.string,
    actions: PropTypes.string,
  }),
};

export default TableRow;
