import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { deleteAlarm } from "msa2-ui/src/api/alarms";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";

import { getToken } from "msa2-ui/src/store/auth";
import { buildRoute } from "msa2-ui/src/utils/urls";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

import {
  TableBody,
  TableCell,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@material-ui/icons";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import TableRow from "msa2-ui/src/components/TableRow";
import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";

const AlarmsTableBody = ({ loading, alarms, reload, error, columns }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { url } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteDialog, DeleteDialog] = useDialog();

  const token = useSelector(getToken);
  const [selectedAlarm, setSelectedAlarm] = useState();
  const canEditAlarm = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "manageAlarms.edit"),
  );
  const canDeleteAlarm = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "manageAlarms.delete"),
  );

  const handleDelete = async () => {
    const [error] = await deleteAlarm({ token, alarmId: selectedAlarm });

    let variant = "success";
    let message = t("x has been deleted", { x: t("Alarm") });
    if (error) {
      variant = "error";
      message = error.getMessage(t("Unable to delete x", { x: t("Alarm") }));
    }
    reload();
    enqueueSnackbar(message, { variant });
    setSelectedAlarm("");
  };

  if (loading) {
    return <TableLoading numberOfTableColumns={columns.length} />;
  }

  if (!alarms || error || alarms.length === 0) {
    return (
      <TableMessage
        message={
          error
            ? `${t("Error fetching alarms")}. ${t("Please reload the page")}.`
            : t("No alarms found")
        }
        numberOfTableColumns={columns.length}
      />
    );
  }

  return (
    <TableBody>
      <DeleteDialog
        title={t("Delete Alarm?")}
        content={t("Are you sure you want to delete this alarm?")}
        onExec={handleDelete}
      />
      {alarms.map(({ id: alarmId, name, description, lastModified }, index) => (
        <TableRow
          key={index}
          actions={
            <>
              {canEditAlarm && (
                <IconButton
                  id={`ALARMS_TABLE_BTN_EDIT_${index}`}
                  aria-label={t("Edit")}
                  component={Link}
                  to={{ pathname: buildRoute(url, `${alarmId}/edit`) }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              )}
              {canDeleteAlarm && (
                <IconButton
                  id={`ALARMS_TABLE_BTN_DELETE_${index}`}
                  aria-label={t("Delete")}
                  onClick={() => {
                    showDeleteDialog();
                    setSelectedAlarm(alarmId);
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </>
          }
        >
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="h4"
              className={commonClasses.commonTablePrimary}
            >
              {name}
            </Typography>
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="body1"
              className={commonClasses.commonTableSecondary}
            >
              {description}
            </Typography>
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            <Typography
              variant="body1"
              className={commonClasses.commonTableSecondary}
            >
              {displayMonthDayYearTimeDate(Number(lastModified))}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

AlarmsTableBody.propTypes = {
  loading: PropTypes.bool,
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  error: PropTypes.object,
  reload: PropTypes.func,
};

export default AlarmsTableBody;
