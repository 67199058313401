import React, { useState } from "react";
import PropTypes from "prop-types";
import { getYear, parseISO, addMinutes } from "date-fns";
import validator from "validator";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import classnames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { makeStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import Close from "@material-ui/icons/Close";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Paper,
  IconButton,
} from "@material-ui/core";

import { DAYS, MONTHS, PERIODICITY, PERIODICITY_VALUES } from "./constants";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
  postfix: {
    marginLeft: 10,
    marginRight: 20,
  },
  scheduleField: {
    marginRight: 20,
    minWidth: 150,
  },
  startDateField: {
    marginRight: 20,
  },
  monthWrapper: {
    maxWidth: 750,
  },
  monthCheckbox: {
    minWidth: 120,
  },
  errorMessage: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
  },
  pickerWrapper: {
    padding: 20,
  },
}));

const getPeriodicityObject = (periodicity) =>
  PERIODICITY_VALUES.find((x) => x.value === periodicity);

const getPostfix = (periodicity) => {
  return PERIODICITY_VALUES.find((x) => x.value === periodicity).postfix;
};

const hasStartDate = (periodicity) => {
  return PERIODICITY_VALUES.find((x) => x.value === periodicity).hasStartDate;
};

const hasEndDate = (periodicity) => {
  return PERIODICITY_VALUES.find((x) => x.value === periodicity).hasEndDate;
};

const hasDayPicker = (periodicity) => {
  return PERIODICITY_VALUES.find((x) => x.value === periodicity).hasDayPicker;
};

const hasMonthPicker = (periodicity) => {
  return PERIODICITY_VALUES.find((x) => x.value === periodicity).hasMonthPicker;
};

const ScheduleDialog = ({
  predefinedEntry,
  readOnly = false,
  onClose,
  onSchedule,
  onSave,
  disableDayMonthPicker = false,
  onlyOnce = false,
  editMode = false,
}) => {
  const initialDate = addMinutes(new Date(), 1);
  const initialEntry = {
    periodicity: PERIODICITY_VALUES[0].value,
    periodicityValue: 1,
    startDate: initialDate,
    endDate: initialDate,
    weekDay: {
      [DAYS.MONDAY.name]: 1,
      [DAYS.TUESDAY.name]: 1,
      [DAYS.WEDNESDAY.name]: 1,
      [DAYS.THURSDAY.name]: 1,
      [DAYS.FRIDAY.name]: 1,
      [DAYS.SATURDAY.name]: 1,
      [DAYS.SUNDAY.name]: 1,
    },
    month: {
      [MONTHS.JANUARY.name]: 1,
      [MONTHS.FEBRUARY.name]: 1,
      [MONTHS.MARCH.name]: 1,
      [MONTHS.APRIL.name]: 1,
      [MONTHS.MAY.name]: 1,
      [MONTHS.JUNE.name]: 1,
      [MONTHS.JULY.name]: 1,
      [MONTHS.AUGUST.name]: 1,
      [MONTHS.SEPTEMBER.name]: 1,
      [MONTHS.OCTOBER.name]: 1,
      [MONTHS.NOVEMBER.name]: 1,
      [MONTHS.DECEMBER.name]: 1,
    },
  };

  const { t } = useTranslation();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [errors, setErrors] = useState([]);
  const [entry, setEntry] = useState(predefinedEntry ?? initialEntry);
  const INIT_TIME_CYCLE = 1;
  const [timeCycle, setTimeCycle] = useState(INIT_TIME_CYCLE);
  const {
    periodicity,
    weekDay = initialEntry.weekDay,
    month = initialEntry.month,
  } = entry;
  const { add, diff, hasValue: hasPeriodicityValue } = getPeriodicityObject(
    periodicity,
  );
  const allDaysSelected = Object.values(weekDay).every((day) => day === 1);
  const allMonthsSelected = Object.values(month).every((month) => month === 1);
  const timeCycleEnabled =
    allDaysSelected && allMonthsSelected && hasPeriodicityValue;
  const calcEndDate = (startDate, periodicityValue, timeCycle, _add = add) =>
    _add(startDate, periodicityValue * timeCycle);

  const renderPeriodicity = () => {
    const { startDate, periodicity, periodicityValue } = entry;

    const handlePeriodicityChange = ({ target: { value: periodicity } }) => {
      setTimeCycle(INIT_TIME_CYCLE);
      const { hasValue, add } = getPeriodicityObject(periodicity);
      if (hasValue) {
        const { startDate, periodicityValue } = initialEntry;
        const endDate = calcEndDate(
          startDate,
          periodicityValue,
          INIT_TIME_CYCLE,
          add,
        );
        setEntry({ ...initialEntry, periodicity, endDate });
        return;
      }
      setEntry({ ...initialEntry, periodicity });
    };

    const handlePeriodicityValueChange = ({
      target: { value: periodicityValue },
    }) => {
      if (timeCycleEnabled) {
        const endDate = calcEndDate(startDate, periodicityValue, timeCycle);
        setEntry({ ...entry, periodicityValue, endDate });
        return;
      }
      setEntry({ ...entry, periodicityValue });
    };

    const handleTimeCycleChange = ({ target: { value: timeCycle } }) => {
      setTimeCycle(timeCycle);
      const endDate = calcEndDate(startDate, periodicityValue, timeCycle);
      setEntry({ ...entry, endDate });
    };

    return (
      <Grid container direction="row" alignItems="center">
        <TextField
          variant="outlined"
          id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_PERIODICITY"
          select
          label={t("Schedule")}
          value={periodicity}
          disabled={readOnly}
          required
          onChange={handlePeriodicityChange}
          className={classnames(classes.formField, classes.scheduleField)}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {PERIODICITY_VALUES.filter((periodicity) =>
            onlyOnce ? periodicity.value === PERIODICITY.once : true,
          ).map((periodicity, index) => (
            <MenuItem
              value={periodicity.value}
              key={index}
              id={`AUTOMATION_DETAILS_SCHEDULE_DIALOG_PERIODICITY_VALUE_${index}`}
            >
              {periodicity.label}
            </MenuItem>
          ))}
        </TextField>
        {hasPeriodicityValue && (
          <>
            <TextField
              variant="outlined"
              id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_PERIODICITY_VALUE"
              label={t("Execute every")}
              value={periodicityValue}
              disabled={readOnly}
              required
              onChange={handlePeriodicityValueChange}
              className={classes.formField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                type: "number",
              }}
            />
            <Typography className={classes.postfix}>
              {getPostfix(periodicity)}
            </Typography>
            {!readOnly && timeCycleEnabled && (
              <>
                <TextField
                  variant="outlined"
                  id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_TIME_CYCLE"
                  label={t("Recurring Time")}
                  value={timeCycle}
                  disabled={readOnly}
                  onChange={handleTimeCycleChange}
                  className={classes.formField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
                <Typography className={classes.postfix}>
                  {t("Time(s)")}
                </Typography>
              </>
            )}
          </>
        )}
      </Grid>
    );
  };

  const renderStartDate = () => {
    const { startDate, periodicityValue } = entry;
    const handleStartDateChange = (startDate) => {
      if (timeCycleEnabled) {
        const endDate = calcEndDate(startDate, periodicityValue, timeCycle);
        setEntry({ ...entry, startDate, endDate });
        return;
      }
      setEntry({ ...entry, startDate });
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          variant="inline"
          format="yyyy/MM/dd HH:mm"
          id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_START_DATE"
          label={t("Start date")}
          value={startDate}
          disabled={readOnly}
          required
          onChange={handleStartDateChange}
          className={classnames(classes.formField, classes.startDateField)}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderEndDate = () => {
    const { endDate, startDate, periodicityValue } = entry;
    const handleEndDateChange = (endDate) => {
      if (timeCycleEnabled) {
        const timeCycle = Math.ceil(
          (diff(endDate, startDate) + 1) / periodicityValue,
        );
        setTimeCycle(timeCycle);
      }
      setEntry({ ...entry, endDate });
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          variant="inline"
          format="yyyy/MM/dd HH:mm"
          id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_END_DATE"
          label={t("End date")}
          value={endDate}
          disabled={readOnly}
          onChange={handleEndDateChange}
          className={classes.formField}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDayPicker = () => {
    const { weekDay } = entry;

    const handleDayChange = (event) => {
      setEntry({
        ...entry,
        weekDay: {
          ...weekDay,
          [event.target.name]: Number(event.target.checked),
        },
      });
    };

    return (
      <Paper variant="outlined" className={classes.pickerWrapper}>
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel component="legend">Pick at least one</FormLabel>
          <FormGroup>
            <Grid container direction="row">
              {Object.keys(DAYS).map((dayName, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={Boolean(weekDay[DAYS[dayName].name])}
                      disabled={readOnly}
                      onChange={handleDayChange}
                      name={DAYS[dayName].name}
                    />
                  }
                  label={DAYS[dayName].label}
                />
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
      </Paper>
    );
  };

  const renderMonthPicker = () => {
    const { month } = entry;

    const handleMonthChange = (event) => {
      setEntry({
        ...entry,
        month: { ...month, [event.target.name]: Number(event.target.checked) },
      });
    };

    return (
      <Paper variant="outlined" className={classes.pickerWrapper}>
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel component="legend">{t("Pick at least one")}</FormLabel>
          <FormGroup>
            <Grid container direction="row" className={classes.monthWrapper}>
              {Object.keys(MONTHS).map((monthName, index) => (
                <FormControlLabel
                  className={classes.monthCheckbox}
                  key={index}
                  control={
                    <Checkbox
                      checked={Boolean(month[MONTHS[monthName].name])}
                      disabled={readOnly}
                      onChange={handleMonthChange}
                      name={MONTHS[monthName].name}
                    />
                  }
                  label={MONTHS[monthName].label}
                />
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
      </Paper>
    );
  };

  const getErrors = (periodicity) => {
    const validationErrors = [];

    const {
      periodicityValue,
      weekDay = initialEntry.weekDay,
      month = initialEntry.month,
      startDate,
      endDate,
    } = entry;
    const maxAllowedMonthValue = 31;

    const atLeastOneSelected = (obj) => {
      return (
        Number(
          Object.keys(obj)
            .map((key) => obj[key])
            .join(""),
        ) > 0
      );
    };

    const getMonths = (monthObject) => {
      const months = [];
      Object.keys(monthObject).forEach(
        (key) => monthObject[key] > 0 && months.push(key.toUpperCase()),
      );
      return months.map((month) => MONTHS[month].index);
    };

    const daysInMonth = (month, year) => {
      switch (month) {
        case MONTHS.FEBRUARY.index: // February
          return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
        case MONTHS.APRIL.index:
        case MONTHS.JUNE.index:
        case MONTHS.SEPTEMBER.index:
        case MONTHS.NOVEMBER.index:
          return 30;
        default:
          return 31;
      }
    };

    const isValid = (day, month, year) => {
      return (
        month >= 0 && month < 12 && day > 0 && day <= daysInMonth(month, year)
      );
    };

    if (!startDate || (periodicity !== PERIODICITY.once && !periodicityValue)) {
      validationErrors.push("Please fill out all the required fields");
    }

    if (
      (startDate && !validator.isDate(new Date(startDate))) ||
      (endDate && !validator.isDate(new Date(endDate)))
    ) {
      validationErrors.push("Please fill with valid date");
    }

    if (new Date(startDate) < new Date()) {
      validationErrors.push("Start date must be equal or after current time");
    }

    if (
      new Date(startDate) > new Date(endDate) &&
      periodicity !== PERIODICITY.once
    ) {
      validationErrors.push("Start date must be earlier than end date");
    }

    if (periodicityValue < 0) {
      validationErrors.push(
        "In 'Execute every' field you can only put positive values",
      );
    }

    if (timeCycle < 1) {
      validationErrors.push(
        "In 'Recurring Time' field you can only put positive values",
      );
    }

    if (periodicity === PERIODICITY.daily && !atLeastOneSelected(weekDay)) {
      validationErrors.push("Please select at least one day");
    }

    if (periodicity === PERIODICITY.monthly) {
      if (
        startDate &&
        endDate &&
        periodicityValue &&
        atLeastOneSelected(month)
      ) {
        const years = [];
        const months = getMonths(month);

        for (
          let y = getYear(parseISO(startDate));
          y < getYear(parseISO(endDate));
          y++
        ) {
          years.push(y);
        }

        years.forEach((year) => {
          months.forEach((month) => {
            if (!isValid(periodicityValue, month, year)) {
              validationErrors.push(
                `Invalid date ${month + 1}/${periodicityValue}/${year}`,
              );
            }
          });
        });
      }

      if (!atLeastOneSelected(month)) {
        validationErrors.push("Please select at least one month");
      }

      if (periodicityValue > maxAllowedMonthValue) {
        validationErrors.push(
          `For 'Monthly' schedule 'Execute every' field value must be less than or equal to ${maxAllowedMonthValue}`,
        );
      }
    }

    return validationErrors;
  };

  const onScheduleClick = () => {
    const { periodicity } = entry;
    const validationErrors = getErrors(periodicity);
    setErrors(validationErrors);

    if (validationErrors.length === 0) {
      onSchedule(entry, timeCycle);
    }
  };

  const onSaveClick = () => {
    const { periodicity } = entry;
    const validationErrors = getErrors(periodicity);
    setErrors(validationErrors);

    if (validationErrors.length === 0) {
      onSave(entry, timeCycle);
    }
  };

  return (
    <Dialog
      id="AUTOMATION_DETAILS_SCHEDULE_DIALOG"
      className={classes.dialog}
      open
      onClose={onClose}
      aria-labelledby="modalArea"
      fullWidth={true}
      maxWidth={"md"}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        id="modalArea"
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {t("Schedule process")}
        </Typography>
        <IconButton
          id="SCHEDULE_DIALOG_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={commonClasses.commonDialogContent}>
        <Grid container direction="column">
          {renderPeriodicity()}
          {!disableDayMonthPicker &&
            hasDayPicker(periodicity) &&
            renderDayPicker()}
          {!disableDayMonthPicker &&
            hasMonthPicker(periodicity) &&
            renderMonthPicker()}
          <Grid container direction="row">
            {hasStartDate(periodicity) && renderStartDate()}
            {hasEndDate(periodicity) && renderEndDate()}
          </Grid>
        </Grid>
        <Grid className={classes.errorMessage}>
          {errors.map((errorMessage, index) => (
            <Typography key={index} color="error" id="SCHEDULE_ERROR">
              {t(errorMessage)}
            </Typography>
          ))}
        </Grid>
      </DialogContent>
      {!readOnly && (
        <DialogActions
          id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_ACTIONS"
          className={commonClasses.commonDialogActions}
        >
          <Button
            id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_ACTIONS_ACTIONS_BTN_CANCEL"
            variant="text"
            size="small"
            color="default"
            className={classnames(
              commonClasses.commonBtn,
              commonClasses.commonBtnSecondary,
            )}
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
          {editMode ? (
            <Button
              id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_ACTIONS_BTN_SAVE"
              aria-label={t("Save")}
              variant="contained"
              size="small"
              color="primary"
              className={classnames(
                commonClasses.commonBtn,
                commonClasses.commonBtnPrimary,
              )}
              onClick={onSaveClick}
            >
              {t("Save")}
            </Button>
          ) : (
            <Button
              id="AUTOMATION_DETAILS_SCHEDULE_DIALOG_ACTIONS_BTN_SCHEDULE"
              aria-label={t("Schedule")}
              variant="contained"
              size="small"
              color="primary"
              className={classnames(
                commonClasses.commonBtn,
                commonClasses.commonBtnPrimary,
              )}
              onClick={onScheduleClick}
            >
              {t("Schedule")}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

ScheduleDialog.propTypes = {
  predefinedEntry: PropTypes.object,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSchedule: PropTypes.func,
  onSave: PropTypes.func,
  disableDayMonthPicker: PropTypes.bool,
  onlyOnce: PropTypes.bool,
};

export default ScheduleDialog;
