import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import union from "lodash/union";
import set from "lodash/set";
import difference from "lodash/difference";
import { useParams } from "react-router";
import { Link, useHistory, Switch, Route, Redirect } from "react-router-dom";

import { getAvailableTenants } from "msa2-ui/src/store/designations";
import { getToken, userRoles } from "msa2-ui/src/store/auth";
import useApi from "msa2-ui/src/hooks/useApi";
import { updateManager } from "msa2-ui/src/api/manager";
import { getSubtenantsByTenant } from "msa2-ui/src/api/subtenant";

import {
  CircularProgress,
  Tabs,
  Tab,
  TextField,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import Radio from "msa2-ui/src/components/connectedFormComponents/Radio";
import Dialog from "msa2-ui/src/components/Dialog";
import PasswordInput from "msa2-ui/src/components/PasswordInput";
import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const Edit = ({ managers }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const token = useSelector(getToken);
  const { id } = useParams();
  const manager = managers?.find((item) => item.id.toString() === id);
  const tenants = useSelector(getAvailableTenants);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [firstname, setFirstname] = useState(manager?.firstname);
  const [name, setName] = useState(manager?.name);
  const [email, setEmail] = useState(manager?.address?.email);
  const [username, setUsername] = useState(manager?.login);
  const [password, setPassword] = useState(manager?.pwd);
  const [usertype, setUserType] = useState(manager?.userType);
  const [errorMessage, setErrorMessage] = useState("");
  const [associatedSubtenantIds, setAssociatedSubtenantIds] = useState(
    manager?.customerIds || [],
  );
  const roleId = manager?.baseRole.id;
  const tenantName =
    manager &&
    tenants.find((tenant) => tenant.value === manager.operatorPrefix).label;

  const handlePortalAccessChange = ({ target: { name, checked } }) => {
    const portalView = {
      manager: [0, 2].includes(usertype),
      developer: [1, 2].includes(usertype),
    };
    set(portalView, name, checked);
    if (portalView.manager && !portalView.developer) {
      setUserType(0);
    } else if (!portalView.manager && portalView.developer) {
      setUserType(1);
    } else if (portalView.manager && portalView.developer) {
      setUserType(2);
    } else {
      setUserType(null);
    }
  };

  const [subtenantsLoading, , subtenants = []] = useApi(
    getSubtenantsByTenant,
    { tenantPrefix: manager?.operatorPrefix },
    !manager,
  );

  if (!manager) {
    return <Redirect to="/admin/managers" />;
  }
  const handleAttach = (subtenantsToAttach) => {
    setAssociatedSubtenantIds(
      union(associatedSubtenantIds, subtenantsToAttach),
    );
  };
  const handleDetach = (subtenantsToDetach) => {
    setAssociatedSubtenantIds(
      difference(associatedSubtenantIds, subtenantsToDetach),
    );
  };

  const onSubmit = async () => {
    setErrorMessage("");
    if (!name || !username || !email || usertype === null) {
      return setErrorMessage(t("Please fill out all the form fields"));
    }

    const [error] = await updateManager({
      token,
      id,
      manager,
      firstname,
      name,
      email,
      username,
      password,
      usertype,
      associatedSubtenantIds,
    });
    const message = error ? error.getMessage() : t("Manager updated");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    if (!error) {
      history.push("/admin/managers");
    }
  };

  const renderTabs = () => {
    const { pathname } = window.location;
    const activeTab =
      pathname === `/admin/managers/${id}/edit/associated-subtenants` ? 1 : 0;

    return (
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        variant="standard"
        className={classes.tabs}
      >
        <Tab
          key={0}
          id={"EDIT_MANAGER_DIALOG_INFORMATION_TAB"}
          classes={{
            selected: commonClasses.commonTabSelected,
          }}
          label={t("Information")}
          component={Link}
          to={`/admin/managers/${id}/edit`}
        />
        {roleId === userRoles.MANAGER && (
          <Tab
            key={1}
            id={"EDIT_MANAGER_DIALOG_ASSOCIATED_SUBTENANTS_TAB"}
            classes={{
              selected: commonClasses.commonTabSelected,
            }}
            label={t("Associated Subtenants")}
            component={Link}
            to={`/admin/managers/${id}/edit/associated-subtenants`}
          />
        )}
      </Tabs>
    );
  };

  const renderInformationTab = () => {
    return (
      <>
        <TextField
          variant="outlined"
          id="MANAGER_EDIT_FIRST_NAME"
          label={t("First Name")}
          value={firstname}
          onChange={(event) => setFirstname(event.target.value)}
          className={classes.formField}
        />
        <TextField
          variant="outlined"
          id="MANAGER_EDIT_NAME"
          label={t("Name")}
          value={name}
          required
          onChange={(event) => setName(event.target.value)}
          className={classes.formField}
        />
        <TextField
          variant="outlined"
          id="MANAGER_EDIT_EMAIL"
          label={t("Email")}
          value={email}
          required
          onChange={(event) => setEmail(event.target.value)}
          className={classes.formField}
        />
        <TextField
          variant="outlined"
          id="MANAGER_EDIT_NAME"
          label={t("Tenant")}
          value={tenantName}
          disabled
          className={classes.formField}
        />
        <Radio
          label={t("Role")}
          input={{ value: roleId }}
          options={[
            {
              value: userRoles.MANAGER,
              displayName: t("Manager"),
              id: "MANAGER_EDIT_ROLE_0",
            },
            {
              value: userRoles.PRIVILEGED_MANAGER,
              displayName: t("Privileged Manager"),
              id: "MANAGER_EDIT_ROLE_PRIVILEGED_1",
            },
          ]}
          className={classes.formField}
          disabled
        />
        <TextField
          variant="outlined"
          id="MANAGER_EDIT_USERNAME"
          label={t("Username")}
          value={username}
          required
          onChange={(event) => setUsername(event.target.value)}
          className={classes.formField}
        />
        <PasswordInput
          variant="outlined"
          id="MANAGER_EDIT_PASSWORD"
          label={t("Password")}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className={classes.formField}
        />
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel component="legend" className={classes.formField}>
            {t("User Portal Access")}
          </FormLabel>
          <FormGroup>
            <Grid container direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    id="MANAGER_EDIT_MANAGER_PORTAL_ACCESS"
                    checked={[0, 2].includes(usertype)}
                    onChange={handlePortalAccessChange}
                    name="manager"
                    color="primary"
                  />
                }
                label="Manager"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="MANAGER_EDIT_DEVELOPER_PORTAL_ACCESS"
                    checked={[1, 2].includes(usertype)}
                    onChange={handlePortalAccessChange}
                    name="developer"
                    color="primary"
                  />
                }
                label="Developer"
              />
            </Grid>
          </FormGroup>
        </FormControl>
      </>
    );
  };

  const renderSubtenantsTab = () => {
    return subtenantsLoading ? (
      <Grid container justifyContent="center" alignContent="center">
        <CircularProgress />
      </Grid>
    ) : (
      <AttachmentBoard
        allItems={subtenants}
        attachedItemIds={associatedSubtenantIds}
        idKey="id"
        displayNameKey="label"
        unattachedTitle={`${t("Select the Subtenants to associate")}:`}
        attachedTitle={`${t("Associated Subtenants")}:`}
        searchPlaceholder={t("Search for Subtenants")}
        handleAttachAll={handleAttach}
        handleAttach={handleAttach}
        handleDetach={handleDetach}
        handleDetachAll={handleDetach}
      />
    );
  };

  return (
    <Dialog
      onExec={onSubmit}
      onClose={() => history.push("/admin/managers")}
      execLabel={t("Save")}
      title={t("Update Manager")}
      tabs={renderTabs()}
    >
      <Grid container direction="column">
        <Switch>
          <Route
            path={"/admin/managers/:id/edit/associated-subtenants"}
            render={renderSubtenantsTab}
          />
          <Route render={renderInformationTab} />
        </Switch>
        {errorMessage && (
          <Typography
            color="error"
            className={classes.error}
            id="MANAGER_EDIT_ERROR"
          >
            {errorMessage}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};

export default Edit;
