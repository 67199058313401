import React, { Component } from "react";

/**
 * ERROR BOUNDARY
 */
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";

import { Typography } from "@material-ui/core";

export default class News extends Component {
  render() {
    return (
      <ErrorBoundary>
        <div>
          <Typography variant="h4" color="inherit" noWrap>
            News
          </Typography>
        </div>
      </ErrorBoundary>
    );
  }
}
