import { initialState as settings } from "./settings";
import { initialState as ai } from "./ai";
import { initialState as auth } from "./auth";
import { initialState as delegationProfiles } from "./delegationProfiles";
import { initialState as designations } from "./designations";
import { initialState as ui } from "./ui";

export default {
  ai,
  auth,
  delegationProfiles,
  designations,
  settings,
  ui,
};
