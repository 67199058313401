import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import Close from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import useApi from "msa2-ui/src/hooks/useApi";
import { getManagers } from "msa2-ui/src/api/manager";
import { getToken, userRoles } from "msa2-ui/src/store/auth";
import MsaSelect from "msa2-ui/src/components/msa-select";
import { changeOwner } from "msa2-ui/src/api/workflow";
import Repository from "msa2-ui/src/services/Repository";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  noOverflow: {
    overflowY: "initial",
  },
}));

const transformManagersResponse = (response) =>
  (response || [])
    .filter((manager) => manager.baseRole.id === userRoles.MANAGER)
    .map(({ id, login }) => ({
      label: login,
      value: id,
    }));

const DEFAULT_OPTION = { label: "ncroot", value: 1 };

const AutomationChangeOwnerDialog = ({
  ownerId,
  ownerName,
  workflowPath,
  bpmPath,
  onClose,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();

  const [currentOwner, setCurrentOwner] = useState(
    ownerId ? { label: ownerName, value: ownerId } : DEFAULT_OPTION,
  );
  const [isSaving, setIsSaving] = useState(false);

  const [isManagersListLoading, , managers = []] = useApi(getManagers, {
    pageSize: 10000,
    transforms: transformManagersResponse,
  });

  const ownershipOptions = [DEFAULT_OPTION, ...managers];
  const isLoading = isSaving || isManagersListLoading;

  const handleSave = async () => {
    setIsSaving(true);

    const [error] = await changeOwner({
      token,
      uri: workflowPath || Repository.stripFileExtensionFromString(bpmPath),
      ownerId: currentOwner.value,
    });

    setIsSaving(false);
    onClose();

    if (error) {
      return enqueueSnackbar(error.getMessage(t("GenericErrorMessage")), {
        variant: "error",
      });
    }

    enqueueSnackbar(t("Owner has been changed"), { variant: "success" });
  };

  return (
    <Dialog
      id="AUTOMATION_CHANGE_OWNER_DIALOG"
      fullWidth
      maxWidth="sm"
      aria-labelledby="modalArea"
      open
      classes={{
        paper: commonClasses.commonDialogPaperNoOverflow,
      }}
      onClose={onClose}
    >
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
          aling="center"
        >
          {t("Change x", { x: t("Ownership") })}
        </Typography>
        <IconButton
          id="AUTOMATION_CHANGE_OWNER_DIALOG_BTN_CLOSE"
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={commonClasses.commonDialogContent}
        classes={{ root: classes.noOverflow }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: 150 }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <MsaSelect
              id={"AUTOMATION_CHANGE_OWNER_DIALOG_SELECT"}
              options={ownershipOptions}
              placeholder={t("Change x", { x: t("Ownership") })}
              value={currentOwner}
              onChange={setCurrentOwner}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          id="AUTOMATION_CHANGE_OWNER_DIALOG_BTN_CANCEL"
          variant="text"
          size="small"
          color="default"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="AUTOMATION_CHANGE_OWNER_DIALOG_BTN_SAVE"
          variant="contained"
          size="small"
          color="primary"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          onClick={handleSave}
          disabled={!currentOwner}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AutomationChangeOwnerDialog.propTypes = {
  ownerId: PropTypes.number,
  ownerName: PropTypes.string,
  workflowPath: PropTypes.string.isRequired,
  bpmPath: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AutomationChangeOwnerDialog;
