import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import RepositorySelector from "msa2-ui/src/components/RepositorySelector";

import { Typography } from "@material-ui/core";
const FileReference = ({
  id,
  label,
  error,
  value,
  onChange,
  repositories,
  required,
  disabled,
  tenant = {},
  subtenant = {},
  token,
}) => {
  const { t } = useTranslation();
  const { prefix } = tenant;
  const { ubiqubeId, operatorPrefix = prefix } = subtenant;
  if (!repositories) {
    return (
      <Typography>
        {t("No repository type is set for this variable.")}
      </Typography>
    );
  }
  // [Temporary]: Controlling accessibility manually
  const filePath = [repositories[0], operatorPrefix, ubiqubeId].join("/");
  return (
    <RepositorySelector
      initDirectory={filePath}
      onChange={onChange}
      noOptionsMessage={() => t("There are no Files in this directory.")}
      lockInitDirectory
      token={token}
      id={id}
      label={label}
      error={error}
      isError={error}
      disabled={disabled}
      required={required}
    />
  );
};

FileReference.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  repositories: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

export default FileReference;
