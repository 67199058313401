import flow from "lodash/flow";
import parseInt from "lodash/parseInt";
import omit from "lodash/omit";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const DEFAULT_GRID = 12;
const ID_PREFIX = "Dashboard_";
const isBPMEnabled = FeatureFlag.isEnabled(FeatureFlag.features.enableBPM);

const filterSettings = (settings) => {
  if (isBPMEnabled) return settings;
  return {
    ...settings,
    contents: settings.contents.filter(
      (content) => content.key !== "Dashboard_bpm",
    ),
  };
};

const INITIAL_SETTINGS = [
  {
    layouts: {
      xs: [
        { i: "Dashboard_managed_entity", x: 0, y: 0, w: 2, h: 12 },
        { i: "Dashboard_bpm", x: 0, y: 8, w: 2, h: 8 },
        { i: "Dashboard_workflow", x: 0, y: 8, w: 2, h: 8 },
      ],
      sm: [
        { i: "Dashboard_managed_entity", x: 0, y: 0, w: 6, h: 12 },
        { i: "Dashboard_bpm", x: 0, y: 6, w: 3, h: 10 },
        { i: "Dashboard_workflow", x: 3, y: 6, w: 3, h: 10 },
      ],
      md: [
        { i: "Dashboard_managed_entity", x: 0, y: 0, w: 6, h: 16 },
        { i: "Dashboard_bpm", x: 6, y: 0, w: 4, h: 8 },
        { i: "Dashboard_workflow", x: 6, y: 6, w: 4, h: 8 },
      ],
      lg: [
        { i: "Dashboard_managed_entity", x: 0, y: 0, w: 6, h: 14 },
        { i: "Dashboard_bpm", x: 6, y: 0, w: 6, h: 7 },
        { i: "Dashboard_workflow", x: 6, y: 7, w: 6, h: 7 },
      ],
      xl: [
        { i: "Dashboard_managed_entity", x: 0, y: 0, w: 10, h: 14 },
        { i: "Dashboard_bpm", x: 10, y: 0, w: 5, h: 14 },
        { i: "Dashboard_workflow", x: 15, y: 0, w: 5, h: 14 },
      ],
    },
    contents: [
      {
        key: "Dashboard_managed_entity",
        style: "Dashboard Panel",
        type: "MSA Component",
        component: "Managed Entity Status",
        title: "Infrastructure",
      },
      {
        key: "Dashboard_bpm",
        style: "Dashboard Panel",
        type: "MSA Component",
        component: "Recent BPM",
        title: "Automation",
      },
      {
        key: "Dashboard_workflow",
        style: "Dashboard Panel",
        type: "MSA Component",
        component: "Recent Workflow",
        title: " ",
      },
    ],
  },
].map(filterSettings);
const styles = ["Dashboard Panel", "Drawer Button"];
const types = ["MSA Component", "External Link"];

const getGridSize = (key, breakpoints) => {
  if (!breakpoints) return DEFAULT_GRID;

  const value = breakpoints[key];
  if (value === 0) {
    return 2;
  }
  return value ? 2 * Math.round(value / 100 / 2) : DEFAULT_GRID;
};

const createSettingFileName = (ubiqubeId) =>
  ubiqubeId
    ? `Datafiles/.${ubiqubeId}_UI_SETTINGS.json`
    : "Datafiles/.UI_SETTINGS.json";

/**
 * @param {object} dashboard
 *
 * Since we added a new Dashboard library in v2.6, we need
 *  to convert dashboard object which is saved > 2.6.
 * This returns the new structure by taking the old one.
 *
 */
const convertOldDashboardToNew = (dashboard) => {
  const getLayout = (gridMaxWidth, size, fallbackSize) =>
    dashboard.map((props, i) => {
      const GRID_MAX_WIDTH_OLD = 12;
      const GRID_MAX_HEIGHT_OLD = 120;
      const GRID_MAX_HEIGHT_NEW = 14;
      const COEFFICIENT = Math.floor(GRID_MAX_HEIGHT_OLD / GRID_MAX_HEIGHT_NEW);

      const getWidth = (props) => {
        if (props[size]) return parseInt(props[size]);
        if (props[fallbackSize]) {
          return (
            parseInt(props[fallbackSize]) * (gridMaxWidth / GRID_MAX_WIDTH_OLD)
          );
        }
        return gridMaxWidth;
      };
      const height = props.height ?? GRID_MAX_HEIGHT_NEW;
      const width = getWidth(props);

      const x = dashboard
        .slice(0, i)
        .reduce(
          (total, component) => (total + getWidth(component)) % gridMaxWidth,
          0,
        );
      const y = flow(
        (components) => components.slice(0, i),
        (components) =>
          components.reduce(
            (total, component) => total + getWidth(component),
            0,
          ),
        (totalWidth) =>
          Math.floor(totalWidth / gridMaxWidth) * GRID_MAX_HEIGHT_NEW,
      )(dashboard);

      return {
        i: i.toString(),
        x,
        y,
        w: width,
        h: Math.ceil(height / COEFFICIENT),
      };
    });

  const xs = getLayout(2, "xs");
  const sm = getLayout(6, "sm");
  const md = getLayout(10, "md", "lg");
  const lg = getLayout(12, "lg");
  const xl = getLayout(20, "xl", "lg");
  return {
    layouts: { xs, sm, md, lg, xl },
    contents: dashboard.map((props, i) => ({
      ...omit(props, "height", "xs", "sm", "md", "lg", "xl"),
      key: i.toString(),
    })),
  };
};

export default {
  DEFAULT_GRID,
  ID_PREFIX,
  INITIAL_SETTINGS,
  styles,
  types,
  getGridSize,
  createSettingFileName,
  convertOldDashboardToNew,
};
