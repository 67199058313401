const TAG_SUBTENANT = "A";
const TAG_MANAGER = "G";
const TAG_PROFILE = "PR";
const TAG_BATCH = "B";
const TAG_OPERATOR = "O";

const regexp = new RegExp(
  "^(\\w{3})(" +
    TAG_SUBTENANT +
    "|" +
    TAG_MANAGER +
    "|" +
    TAG_PROFILE +
    "|" +
    TAG_BATCH +
    "|" +
    TAG_OPERATOR +
    ")(\\d{1,5})$",
);

/**
 * @param ubiqubeId (eg. MSAA19224)
 * @return prefix (eg. MSA)
 * @return tag (eg. A)
 * @return id (eg. 19224)
 * @example extractUbiqubeId("MSAA19224").id
 *  => 19224
 */
export default (ubiqubeId) => {
  try {
    const extractedId = regexp.exec(ubiqubeId).slice(1);
    return { prefix: extractedId[0], tag: extractedId[1], id: extractedId[2] };
  } catch (e) {
    return "";
  }
};
