import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import useDialog from "msa2-ui/src/hooks/useDialog";
import useWorkflowTask from "../hooks/useWorkflowTask";

import {
  Avatar,
  Grid,
  Hidden,
  List,
  Paper,
  Typography,
  Button,
  MenuItem,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Process from "msa2-ui/src/services/Process";
import Sidebar from "msa2-ui/src/components/modal/Sidebar";

import TaskEditor from "./TaskEditor";
import FormCheckbox from "msa2-ui/src/components/formSection/FormCheckbox";
import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import AddTask from "../edit/AddTask";

const useStyles = makeStyles(
  ({ darkMode, palette, typography, spacing, colors }) => ({
    titleWrapper: {
      marginTop: 4,
      marginBottom: 24,
    },
    icon: {
      height: 36,
      width: 36,
      marginRight: 16,
    },
    title: {
      color: palette.text.primary,
      fontSize: "1.5rem",
      fontWeight: typography.fontWeightMedium,
      letterSpacing: 0.3,
    },
    fullScreenModeContainer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "calc(100% - 90px)",
      zIndex: 100000,
      padding: 20,
      marginTop: 90,
    },
    containerHeight: { height: "100%" },
    taskList: {
      height: "100%",
      overflowY: "auto",
      padding: 8,
    },
    tasksCount: {
      marginBottom: 12,
      marginLeft: 2,
    },
    taskEditor: {
      maxHeight: "inherit",
      height: "100%",
      borderLeft: darkMode
        ? `1px solid ${palette.text.hint}`
        : `2px solid ${palette.text.secondary}`,
    },
    addProcessIcon: {
      marginRight: 3,
      marginTop: -6,
    },
    deleteIcon: {
      marginRight: 5,
    },
    deleteButton: {
      color: palette.error.main,
      marginLeft: 30,
    },
    formField: {
      margin: `${spacing(2)}px 0`,
      padding: 0,
      textAlign: "left",
    },
    paper: {
      marginBottom: 20,
    },
    processDisplayName: {
      minWidth: 300,
    },
    processType: {
      minWidth: 150,
    },
  }),
);

const ProcessSection = ({
  change,
  form,
  isEditorUnsaved,
  setIsEditorUnsaved,
  workflowProcess,
  processIndex: index,
  showDeleteProcessDialog,
  onDisplayNameChange,
  setIsLoading,
}) => {
  const { name, tasks: _tasks, type, displayName } = workflowProcess;
  // When there are no tasks, it comes with "null" rather than undefined. So we cannot use "??" or "= []"
  const tasks = _tasks || [];
  const hasTasks = Boolean(tasks.length > 0);
  const TypeIcon = Process.getIcon(type);

  const { t } = useTranslation();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [isFullScreenMode, setIsFullScreenMode] = useState();

  const handleFullScreenModeChange = () =>
    setIsFullScreenMode(!isFullScreenMode);

  const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
  const {
    addTask,
    addTaskToForm,
    removeTaskFile,
    removeTaskFromForm,
    saveContentsToFile,
    extractVariableFromTask,
  } = useWorkflowTask({
    activeProcessIndex: index,
    setIsLoading,
    form,
  });

  const [showTaskDiscardDialog, TaskDiscardDialog, { task }] = useDialog();
  const [activeTask, setActiveTask] = useState();

  useEffect(() => {
    setActiveTask();
  }, [name]);

  useEffect(() => {
    if (activeTask) {
      const activeTaskDeleted = !tasks.find((task) => task === activeTask);
      if (activeTaskDeleted) {
        setActiveTask();
      }
    }
  }, [activeTask, tasks]);

  const handleChangePosition = (sourceIndex, target) => {
    const values = tasks.reduce((acc, current, currentIndex) => {
      if (currentIndex === sourceIndex) return acc;
      if (currentIndex === target) {
        if (sourceIndex < target) {
          return [...acc, current, tasks[sourceIndex]];
        } else {
          return [...acc, tasks[sourceIndex], current];
        }
      }
      return [...acc, current];
    }, []);
    change(`process[${index}].tasks`, values);
  };

  return (
    <>
      {showAddTaskDialog && (
        <AddTask
          tasks={tasks}
          onClose={() => {
            setShowAddTaskDialog(false);
          }}
          addTask={addTask}
          addTaskToForm={addTaskToForm}
          extractVariableFromTask={extractVariableFromTask}
          form={form}
        />
      )}
      <TaskDiscardDialog
        title={t("Discard changes?")}
        content={
          <>
            <div>{t("Your Task has not been saved.")} </div>
            <div>{t("Are you sure you want to discard your changes?")}</div>
          </>
        }
        onExec={() => {
          setActiveTask(task);
        }}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          className={classes.titleWrapper}
        >
          <Avatar className={classes.icon}>
            <TypeIcon />
          </Avatar>
          <Typography variant="h4" className={classes.title}>
            {displayName}
          </Typography>
          <Button
            id="PROCESS_BTN_DELETE"
            variant="text"
            size="small"
            className={classes.deleteButton}
            onClick={showDeleteProcessDialog}
          >
            <DeleteIcon color="error" />
            {t("Delete")}
          </Button>
        </Grid>
        {
          <Paper
            className={classnames(commonClasses.commonPaper, classes.paper)}
          >
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item>
                <FieldSelector
                  onChange={onDisplayNameChange}
                  name={`process[${index}].displayName`}
                  label={t("Process display name")}
                  type="text"
                  className={classes.formField}
                  required
                />
              </Grid>
              <Grid item>
                <FormSelect
                  name={`process[${index}].type`}
                  label={t("Process type")}
                  variant="outlined"
                  className={classnames(classes.formField, classes.processType)}
                  required
                  disabled={!Process.processDefinitions[type].canAdd}
                >
                  {Object.entries(Process.processDefinitions)
                    .filter(([_, { canAdd }]) =>
                      Process.processDefinitions[type].canAdd ? canAdd : true,
                    )
                    .map(([type, { label }], index) => (
                      <MenuItem
                        value={type}
                        key={index}
                        id={`AUTOMATION_EDIT_PROCESS_TYPE_VALUE_${index}`}
                      >
                        {label}
                      </MenuItem>
                    ))}
                </FormSelect>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <FormCheckbox name={`process[${index}].allowSchedule`} />
                  }
                  label={t("Allow scheduling")}
                />
              </Grid>
              <Hidden lgDown>
                <Grid item xs={6}>
                  <FieldSelector
                    onChange={onDisplayNameChange}
                    name={`process[${index}].name`}
                    label={t("Process path")}
                    type="text"
                    className={classnames(
                      classes.formField,
                      classes.processDisplayName,
                    )}
                    disabled
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Paper>
        }
        <div
          className={classnames({
            [classes.fullScreenModeContainer]: isFullScreenMode,
          })}
        >
          <Paper
            id="AUTOMATION_EDIT_TASK_LIST"
            className={classnames(
              commonClasses.commonPaperNoPadding,
              classes.containerHeight,
            )}
          >
            <Grid container className={classes.containerHeight}>
              <Grid item xs={3} className={classes.taskList}>
                <List>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      className={classnames(
                        commonClasses.commonDescription,
                        classes.tasksCount,
                      )}
                    >
                      {t("Tasks")}: {hasTasks ? tasks.length : 0}
                    </Typography>
                    <Tooltip title={t("Add Task")}>
                      <Button
                        aria-label={t("Add Task")}
                        onClick={() => {
                          setShowAddTaskDialog(true);
                        }}
                        className={classes.addProcessIcon}
                        id="AUTOMATION_EDIT_TASK_ADD"
                      >
                        <AddIcon color="primary" />
                      </Button>
                    </Tooltip>
                  </Grid>
                  {hasTasks ? (
                    tasks.map((task, i) => {
                      const { displayName } = task;
                      return (
                        <Sidebar.ListItemDraggable
                          key={displayName}
                          index={i}
                          type="WORKFLOW_TASKS"
                          onDragged={handleChangePosition}
                          id={`AUTOMATION_EDIT_TASK_${displayName}`}
                          title={displayName}
                          selected={activeTask?.displayName === displayName}
                          section={displayName}
                          onClick={() => {
                            if (isEditorUnsaved) {
                              showTaskDiscardDialog(true, { task });
                            } else {
                              setActiveTask(task);
                            }
                          }}
                        />
                      );
                    })
                  ) : (
                    <Grid container justifyContent="center">
                      <Typography>
                        {t(
                          "There are no Tasks associated with this Process yet.",
                        )}
                      </Typography>
                    </Grid>
                  )}
                </List>
              </Grid>
              <Grid item xs={9} className={classes.taskEditor}>
                {activeTask && (
                  <TaskEditor
                    form={form}
                    workflowProcess={workflowProcess}
                    task={activeTask}
                    isEditorUnsaved={isEditorUnsaved}
                    setIsEditorUnsaved={setIsEditorUnsaved}
                    saveContentsToFile={saveContentsToFile}
                    removeTaskFromForm={removeTaskFromForm}
                    removeTaskFile={removeTaskFile}
                    isFullScreenMode={isFullScreenMode}
                    onFullScreenMode={handleFullScreenModeChange}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Grid>
    </>
  );
};

ProcessSection.propTypes = {
  change: PropTypes.func.isRequired,
  isEditorUnsaved: PropTypes.bool.isRequired,
  setIsEditorUnsaved: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  workflowProcess: PropTypes.shape({
    tasks: PropTypes.array,
    type: PropTypes.string,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProcessSection;
