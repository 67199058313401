import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import classnames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { FileCopy as CopyIcon } from "@material-ui/icons";

import Tooltip from "msa2-ui/src/components/TooltipWithLineBreaks";

const ClipboardIcon = ({
  content,
  item,
  icon: Icon = CopyIcon,
  onClick,
  classes = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const snackbar = useSnackbar();

  const copyValueToClipboard = (e) => {
    onClick && onClick(e);
    navigator.clipboard.writeText(content);
    // todo
    if (snackbar) {
      snackbar.enqueueSnackbar(t("Copied to clipboard.", { item }));
    }
  };

  return (
    <Tooltip title={t("Click to copy", { item })} className={classes.tooltip}>
      <Icon
        id="COPY_ICON"
        aria-label={t("Click to copy")}
        onClick={copyValueToClipboard}
        className={classnames(commonClasses.commonCopyToClipboard, {
          [classes.icon]: Boolean(classes.icon),
        })}
        {...rest}
      />
    </Tooltip>
  );
};

ClipboardIcon.propTypes = {
  content: PropTypes.string.isRequired,
  item: PropTypes.string,
  icon: PropTypes.func,
};

export default ClipboardIcon;
