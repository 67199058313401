import React from "react";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";
import useActiveRoute from "msa2-ui/src/hooks/useActiveRoute";
import { buildRoute } from "msa2-ui/src/utils/urls";

import { getMsaVarValue } from "msa2-ui/src/store/designations";

import { getAdministrators } from "msa2-ui/src/api/administrator";
import { MSA_VARS, sortOrder } from "msa2-ui/src/Constants";

import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Button,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import AdministratorTableBody from "./AdministratorTableBody";
import Create from "./Create";
import Edit from "./Edit";

const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    active: true,
  },
  {
    id: "email",
    name: "Email",
    align: "left",
    active: true,
  },
  {
    id: "lastUpdated",
    name: "Date Modified",
    align: "left",
    active: true,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    active: false,
  },
];

const Administrators = ({ adminTabs: AdminTabs }) => {
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const ldapEnabled = Number(
    useSelector(getMsaVarValue(MSA_VARS.UBI_LDAP_ENABLE)),
  );

  const [filterState, filterActions] = useFilter({
    tableName: "admin",
    sortByValue: "lastUpdated",
    sortOrderValue: 0,
    searchValue: "",
    tpPage: 0,
  });

  const [
    loading,
    error,
    administrators = [],
    meta,
    reloadAdministratorList,
  ] = useApi(getAdministrators, {
    sortOrder: sortOrder[filterState.sortOrderValue].param,
    sort: filterState.sortByValue,
    filterCriteria: filterState.searchValue,
    filter: TABLE_HEADER_COLUMNS[0].id,
    page: filterState.tpPage + 1,
    pageSize: filterState.tpRowsPerPage,
  });

  useActiveRoute(reloadAdministratorList);

  const total = meta?.total_admin_count || 0;

  const endElements = ldapEnabled ? null : (
    <Button
      id="ADMINISTRATOR_BTN_CREATE_LINK"
      variant="contained"
      color="primary"
      aria-label={t("Create Administrator")}
      component={Link}
      to={{
        pathname: buildRoute(url, "create"),
      }}
    >
      <IconPlus className={commonClasses.commonBtnIcon} />
      {t("Create Administrator")}
    </Button>
  );

  return (
    <>
      <AdminTabs currentCount={parseInt(total, 10)} endElements={endElements} />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={TABLE_HEADER_COLUMNS.length}
                    className={commonClasses.commonTableCell}
                  >
                    <FilterMenu
                      {...filterState}
                      searchValue={filterState.searchValue}
                      handleSearchByChange={filterActions.handleSearchByChange}
                      tpChangePage={filterActions.tpChangePage}
                      tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
                      tpTotal={parseInt(total, 10)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) => (
                    <TableCell
                      key={tableHeaderColumn.id}
                      align={tableHeaderColumn.align}
                      className={commonClasses.commonTableCellDefault}
                    >
                      {tableHeaderColumn.active ? (
                        <TableSortLabel
                          id={`ADMINISTRATORS_TABLE_SORT_${tableHeaderColumn.id}`}
                          active={
                            filterState.sortByValue === tableHeaderColumn.id
                          }
                          direction={sortOrder[filterState.sortOrderValue].text}
                          onClick={() => {
                            filterActions.handleSortByOrderChange();
                            filterActions.handleSortByValueChange(
                              tableHeaderColumn.id,
                            );
                          }}
                        >
                          {tableHeaderColumn.name}
                        </TableSortLabel>
                      ) : (
                        tableHeaderColumn.name
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <AdministratorTableBody
                loading={loading}
                error={error}
                administrators={administrators}
                numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
                reloadAdministrators={reloadAdministratorList}
              />
            </Table>
            <Route path={`${path}/create`}>
              <Create />
            </Route>
            <Route path={`${path}/:id/edit`}>
              <Edit administrators={administrators} />
            </Route>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Administrators;
