import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Compact from "react-color/lib/Compact";
import { makeStyles } from "@material-ui/core";
import { Popover } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    cursor: "pointer",
  },
  disabled: {
    opacity: ".6",
    cursor: "default",
  },
  colorBox: {
    width: 38,
    height: 38,
    border: `2px solid ${theme.colors.grey}`,
    borderRadius: "3px",
    transition: "all .1s ease",
  },
  colorBoxActive: {
    borderColor: theme.palette.primary.main,
  },
  colorBoxError: {
    borderColor: `${theme.colors.red}`,
  },
  popoverContainer: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "3px",

    "& .compact-picker": {
      backgroundColor: `${theme.colors.blueDark2}`,
    },

    "& input": {
      marginTop: 3,
      color: "#fff !important",
    },
  },
}));

const ColorPicker = ({ disabled, error, value, onChange }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const openHandler = useCallback(() => {
    !disabled && setIsOpen(true);
  }, [disabled, setIsOpen]);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
  }, []);

  const colorChangeHandler = useCallback(
    ({ hex }) => {
      onChange(hex);
    },
    [onChange],
  );

  return (
    <div
      className={classNames(classes.container, {
        [classes.disabled]: disabled,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
      <div
        ref={anchorRef}
        className={classNames(classes.colorBox, {
          [classes.colorBoxActive]: isOpen,
          [classes.colorBoxError]: error,
        })}
        style={{
          backgroundColor: value || "",
        }}
        onClick={openHandler}
        role="button"
      />
      <Popover
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={closeHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popoverContainer}>
          <Compact color={value} onChange={colorChangeHandler} />
        </div>
      </Popover>
    </div>
  );
};

ColorPicker.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
