import React from "react";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { useTranslation } from "react-i18next";
import {
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import IncidentTicketView from "./IncidentTickeView";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles((theme) => {
  //const { palette, breakpoints, typography, darkMode } = theme;

  return {
    statusIcon: {
      background: "transparent",
      width: 20,
      height: 20,
      marginRight: 3,
    },
  };
});
const IncidentTicketList = ({
  colSpan,
  isLoading,
  isError,
  incidentTicketsData,
  selectedSubtenantId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  if (!selectedSubtenantId)
    return (
      <TableMessage
        numberOfTableColumns={colSpan}
        message={t("Please choose a subtenant")}
      />
    );

  if (isLoading) return <TableLoading numberOfTableColumns={colSpan} />;

  if (isError || !incidentTicketsData.length) {
    return (
      <TableMessage
        numberOfTableColumns={colSpan}
        message={isError ? t("GenericErrorMessage") : t("No Data Found")}
      />
    );
  }

  const handleView = () => {
    return <IncidentTicketView incidentTicketsData={incidentTicketsData} />;
  };

  const chooseIncidentTicketStatusAvatar = (status, classes) => {
    let color;
    switch (status) {
      case "open":
        color = "#db2e14";
        break;
      case "closed":
        color = "#12c83e";
        break;
      default:
    }
    return (
      <Avatar className={classes.statusIcon}>
        {<FiberManualRecordIcon style={{ color: color }} />}
      </Avatar>
    );
  };

  return (
    <TableBody>
      {incidentTicketsData.map(({ id, fields, ticketid }) => (
        <TableRow key={`INCIDENT_TICKET_DATA_ROW_${id}`}>
          <TableCell className={commonClasses.commonTableCell}>
            {chooseIncidentTicketStatusAvatar(fields.status, classes)}
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            {fields.status.toUpperCase()}
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            {ticketid.title}
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            {id}
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            {fields.date}
          </TableCell>
          <TableCell className={commonClasses.commonTableCellDefault}>
            {
              <IconButton onClick={handleView}>
                <Search />
              </IconButton>
            }
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default IncidentTicketList;
