import React, { useEffect, useReducer } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";

import { is, getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";

import Process from "msa2-ui/src/services/Process";
import Bpm, { VARIABLE_NAME_FOR_INPUT_CONTEXT } from "msa2-ui/src/services/Bpm";
import {
  PERIODICITY,
  PERIODICITY_VALUES,
} from "msa2-ui/src/components/schedule/constants";
import { ReactComponent as Running } from "msa2-ui/src/assets/icons/bpm/running.svg";

export const initialModelerState = {
  activeElement: null,
  touched: false,
  xml: null,
  xmlError: null,
};

const modelerStateReducer = (state, action) => {
  if (action.type === "setActiveElement") {
    const activeElement = action.payload;

    if (!activeElement) {
      return {
        ...state,
        activeElement: null,
      };
    }

    return { ...state, activeElement };
  }

  if (action.type === "setXml") {
    const { xml, error: xmlError } = action.payload;
    return { ...state, xml, xmlError };
  }
  if (action.type === "touch") {
    return { ...state, touched: true };
  }

  return state;
};

const gatewayConditionsAreSet = (gateway, elementRegistry) => {
  const { outgoing = [] } = getBusinessObject(gateway);

  const outgoingIds = outgoing.map((out) => out.id);
  const defaultFlow = getBusinessObject(gateway).default || {};

  const sequenceFlowsWithCondition = elementRegistry.filter(
    (element) =>
      outgoingIds.includes(element.id) && element.id !== defaultFlow.id,
  );

  const values = sequenceFlowsWithCondition.map((flow) =>
    Boolean(Bpm.readConditionsFromSequenceFlow(flow)?.value),
  );

  return !values.includes(false);
};

const useBpmModeler = (modelerInstance, initWorkflows) => {
  const { t } = useTranslation();
  const [modelerState, dispatch] = useReducer(
    modelerStateReducer,
    initialModelerState,
  );

  /**
   * Listeners
   */
  const onSelectionChanged = (event) => {
    const { newSelection } = event;
    // Set ActiveElement as null before selection to unmount PropertiesPanel
    // Otherwise it will keep some local states when you switch BPM nodes back and forth
    dispatch({ type: "setActiveElement", payload: null });
    if (newSelection.length === 1) {
      dispatch({ type: "setActiveElement", payload: newSelection[0] });
    }
  };

  const onShapeAdded = (modelerInstance) => ({
    context: { shape: element },
  }) => {
    // Add name
    if (!isAny(element, ["bpmn:Task", "bpmn:TextAnnotation"])) {
      let name = element.id;
      if (is(element, "bpmn:StartEvent")) {
        name = "Start Event";
      }
      if (is(element, "bpmn:EndEvent")) {
        name = "End Event";
      }
      const modeling = modelerInstance.get("modeling");
      modeling.updateProperties(element, { name });
    }

    // Add signal for User Breakpoints
    if (is(element, "bpmn:IntermediateCatchEvent")) {
      const businessObject = getBusinessObject(element);
      const eventDefinition = businessObject.eventDefinitions[0];

      const TYPE =
        Bpm.TYPES["bpmn:IntermediateCatchEvent"][eventDefinition["$type"]];
      const modeling = modelerInstance.get("modeling");
      const moddle = modelerInstance.get("moddle");
      const canvas = modelerInstance.get("canvas");

      const refId = eventDefinition.id.replace(
        `${TYPE.idPrefix}EventDefinition_`,
        `${TYPE.idPrefix}_`,
      );

      // add a signal object for a user breakpoint
      const referenceElement = moddle.create(TYPE.referenceType, {
        id: refId,
        name: refId,
      });
      const rootElement = canvas.getRootElement().businessObject.$parent
        .rootElements;
      rootElement.push(referenceElement);

      // add a signal reference in the created object
      const reference = { [TYPE.refKey]: referenceElement };

      const newEventDefinition = moddle.create(TYPE.id, {
        id: eventDefinition.id,
        ...reference,
      });
      modeling.updateProperties(element, {
        eventDefinitions: [newEventDefinition],
      });
    }
  };

  const onShapeRemoved = (modelerInstance) => ({
    context: { shape: element },
  }) => {
    if (is(element, "bpmn:IntermediateCatchEvent")) {
      // Remove reference element along with the element itself
      const canvas = modelerInstance.get("canvas");
      const businessObject = getBusinessObject(element);
      const eventDefinition = businessObject.eventDefinitions[0];
      const TYPE =
        Bpm.TYPES["bpmn:IntermediateCatchEvent"][eventDefinition["$type"]];
      const referenceElement = eventDefinition[TYPE.refKey];
      const refId = referenceElement.id;

      const rootElements = canvas.getRootElement().businessObject.$parent
        .rootElements;
      rootElements.forEach(({ id }, i) => {
        if (id === refId) {
          rootElements.splice(i, 1);
        }
      });
    }
  };

  const onImportRenderComplete = (
    modelerInstance,
    generatedWorkflows,
  ) => () => {
    // Auto Generate BPM based on generatedWorkflows
    if (generatedWorkflows) {
      // node_modules/bpmn-js/lib/features/modeling/Modeling.js
      const modeling = modelerInstance.get("modeling");
      const autoPlace = modelerInstance.get("autoPlace");
      const elementFactory = modelerInstance.get("elementFactory");
      // node_modules/diagram-js/lib/core/ElementRegistry.js
      const elementRegistry = modelerInstance.get("elementRegistry");
      const replace = modelerInstance.get("replace");
      // node_modules/bpmn-moddle/dist/index.js
      // https://github.com/camunda/camunda-bpmn-moddle/blob/master/resources/camunda.json
      const moddle = modelerInstance.get("moddle");

      const activityIds = [];
      generatedWorkflows.forEach((workflow, i) => {
        // Get incoming element
        const source =
          i === 0
            ? elementRegistry.get("StartEvent_1")
            : elementRegistry.get(activityIds[i - 1]);

        // Create a shape for Workflow as Task first to populate Activity Id
        const shape = elementFactory.createShape({ type: "bpmn:Task" });
        autoPlace.append(source, shape);

        const currentId = shape.id;
        // Loop the workflows BEFORE this workflow and find if there is the same Workflow with Create Process
        const indexForSameWorkflowWithCreateType = generatedWorkflows
          .slice(0, i)
          .findIndex(
            ({ workflowPath, processType }) =>
              workflowPath === workflow.workflowPath &&
              Process.isCreate(processType),
          );

        // If there is, set camunda Json as instanceId to select "New Instance" automatically.
        const workflowInstanceId =
          indexForSameWorkflowWithCreateType >= 0 &&
          Bpm.buildCamundaJsonParser({
            variableName: activityIds[indexForSameWorkflowWithCreateType],
            props: ["serviceId"],
          });
        const createdElement = elementRegistry.get(currentId);
        const workflowElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
          displayName: workflow.displayName,
          workflowPath: workflow.workflowPath,
          processName: workflow.processName,
          processType: workflow.processType,
          processVariables: workflow.processVariables,
          workflowInstanceId,
          moddle,
        });
        // Change Task into ServiceTask
        modeling.updateProperties(createdElement, {
          workflowElement,
        });
        replace.replaceElement(createdElement, workflowElement);
        activityIds.push(currentId);
      });

      // Create an EndEvent and attach it to the last Workflow element
      const lastElement = elementRegistry.get(activityIds.slice(-1)[0]);
      const endShape = elementFactory.createShape({ type: "bpmn:EndEvent" });
      autoPlace.append(lastElement, endShape);
    }
    // Set initial XML as State
    modelerInstance.saveXML({ format: true }, (error, xml) => {
      dispatch({ type: "setXml", payload: { error, xml } });
    });
  };

  const onBpmChange = (modelerInstance) => {
    modelerInstance.saveXML({ format: true }, (error, xml) => {
      dispatch({ type: "setXml", payload: { error, xml } });
      dispatch({ type: "touch" });
    });
  };

  // Set listeners here, find commands in
  //  - https://github.com/bpmn-io/docs.bpmn.io/tree/master/docs/Events/common-events
  //  - https://github.com/bpmn-io/docs.bpmn.io/tree/master/docs/Events/bpmn-events
  useEffect(() => {
    if (modelerInstance) {
      // init
      modelerInstance.on(
        "import.render.complete",
        onImportRenderComplete(modelerInstance, initWorkflows),
      );
      modelerInstance.on("selection.changed", (event) => {
        onSelectionChanged(event);
      });
      // cannot execute command during the different execution so add them in postExecute process
      modelerInstance.on(
        "commandStack.shape.create.postExecute",
        onShapeAdded(modelerInstance),
      );
      modelerInstance.on(
        "commandStack.shape.delete.postExecute",
        onShapeRemoved(modelerInstance),
      );
      modelerInstance.on("commandStack.changed", () => {
        onBpmChange(modelerInstance);
      });
    }
  }, [modelerInstance, initWorkflows]);

  /**
   * Actions
   */
  const selectElement = (elementId) => {
    if (!elementId) return;

    const targetElement = modelerInstance.get("elementRegistry").get(elementId);
    const selection = modelerInstance.get("selection");
    selection.select(targetElement);
  };

  const unselectElement = () => {
    const selection = modelerInstance.get("selection");
    dispatch({ type: "setActiveElement", payload: null });
    selection.select(null);
  };

  const getBpmElementById = (elementId) => {
    if (!elementId) return;
    return modelerInstance.get("elementRegistry").get(elementId);
  };

  // Reference node_modules/bpmn-js/lib/features/popup-menu/ReplaceMenuProvider.js - _createSequenceFlowEntries
  const updateDefaultSequence = (flowId, clear) => {
    const flowElement = getBpmElementById(flowId);
    const businessObject = getBusinessObject(flowElement);
    const gatewayElement = getBpmElementById(businessObject?.sourceRef?.id);
    const modeling = modelerInstance.get("modeling");
    gatewayElement &&
      modeling.updateProperties(gatewayElement, {
        default: clear ? undefined : businessObject,
      });
    !clear &&
      flowElement &&
      modeling.updateProperties(flowElement, {
        conditionExpression: undefined,
      });
  };

  const updateActiveElement = (businessObject) => {
    if (!modelerInstance || !businessObject) return;

    const modeling = modelerInstance.get("modeling");
    const activeElement = modelerState.activeElement;
    modeling.updateProperties(activeElement, businessObject);
  };

  const replaceActiveElement = (updatedElement) => {
    if (!modelerInstance || !updatedElement) return;

    // js-diagram replaceElement cannot replace Connection yet
    // node_modules/diagram-js/lib/features/replace/Replace.js
    if (modelerState.activeElement.waypoints) {
      const modeling = modelerInstance.get("modeling");
      const sequenceFlowElement = modelerState.activeElement;
      modeling.updateProperties(sequenceFlowElement, {
        name: updatedElement.businessObject.name,
        conditionExpression: updatedElement.businessObject.conditionExpression,
      });
    } else {
      const replace = modelerInstance.get("replace");
      replace.replaceElement(modelerState.activeElement, updatedElement);
    }

    selectElement(updatedElement.businessObject?.id);
  };

  const getAllAttachedWorkflowValues = () => {
    if (!modelerInstance) {
      return [];
    }
    const elementRegistry = modelerInstance.get("elementRegistry");
    const elementsWithAttachedWorkflows = elementRegistry.filter((element) =>
      is(element, "bpmn:Task"),
    );
    return elementsWithAttachedWorkflows.map(
      Bpm.readAttachedWorkflowValuesFromBpmElement,
    );
  };

  // Searches in diagram recursively if the target element is connecting to the current element
  const isConnectingToCurrentElement = (
    targetId,
    sourceId = modelerState.activeElement.id,
  ) => {
    if (!modelerInstance) {
      return [];
    }
    const elementRegistry = modelerInstance.get("elementRegistry");
    const currentElement = elementRegistry.get(sourceId);

    const searchedId = [];
    if (!currentElement) return false;
    const hasTargetInSource = (element) =>
      element.incoming?.some((connection) => {
        const sourceElement = getBusinessObject(connection)?.sourceRef;
        // Break if the id is already searched. (means diagram is looping)
        if (searchedId.includes(sourceElement.id)) return false;
        // Return true if the target is found.
        if (sourceElement.id === targetId) return true;
        searchedId.push(sourceElement.id);
        return hasTargetInSource(sourceElement);
      });
    return hasTargetInSource(currentElement);
  };

  const replaceStartEventWithTimer = (date) => {
    const { repetition, periodicity, periodicityValue } = date;
    const { durationDesignator, designator } = PERIODICITY_VALUES.find(
      (periodicityObject) => periodicityObject.value === periodicity,
    );

    const elementRegistry = modelerInstance.get("elementRegistry");
    const modeling = modelerInstance.get("modeling");
    const startEvents = elementRegistry.filter(
      (element) => element.type === "bpmn:StartEvent",
    );
    startEvents.forEach((element) => {
      const timerId = element.id.replace(
        "StartEvent_",
        "TimerEventDefinition_",
      );
      const startDate = new Date(date.startDate).toISOString();

      let timer;
      if (periodicity === PERIODICITY.once) {
        // Schedule Once
        const timeDate = moddle.create("bpmn:FormalExpression", {
          body: startDate,
        });
        timer = moddle.create("bpmn:TimerEventDefinition", {
          id: timerId,
          timeDate,
        });
      } else {
        // Schedule repeats
        const duration = `${durationDesignator}${periodicityValue}${designator}`;
        const timeCycle = moddle.create("bpmn:FormalExpression", {
          // ISO 8601 Repeating Intervals Format
          body: `R${repetition - 1}/${startDate}/${duration}`,
        });
        timer = moddle.create("bpmn:TimerEventDefinition", {
          id: timerId,
          timeCycle,
        });
      }

      // change start event into timer event
      modeling.updateProperties(element, {
        eventDefinitions: [timer],
      });
    });
  };

  const replaceVariablesWithReference = (activityId, referenceActivityId) => {
    const replace = modelerInstance.get("replace");

    const element = getBpmElementById(activityId);
    const bpmElementValues = Bpm.readAttachedWorkflowValuesFromBpmElement(
      element,
    );

    // eg. JSON(inputs).prop("Activity_1w07oak").toString()
    const inputContext = Bpm.buildCamundaJsonParser({
      variableName: VARIABLE_NAME_FOR_INPUT_CONTEXT,
      props: [activityId],
      parser: "toString",
      noWrapper: true,
    });
    // eg. JSON(inputs).hasProp("Activity_1w07oak")
    const conditionToCheckVariableExistence = Bpm.buildCamundaJsonParser({
      variableName: VARIABLE_NAME_FOR_INPUT_CONTEXT,
      parser: "hasProp",
      parserProp: `"${activityId}"`,
      noWrapper: true,
    });
    // eg. JSON(Activity_1w07oak).prop("variables").toString()
    const outputContext = Bpm.buildCamundaJsonParser({
      variableName: referenceActivityId,
      props: ["variables"],
      parser: "toString",
      noWrapper: true,
    });

    const processVariables = referenceActivityId
      ? "${" +
        conditionToCheckVariableExistence +
        " ? " +
        inputContext +
        " : " +
        outputContext +
        "}"
      : "${" + inputContext + "}";

    const updatedElement = Bpm.writeAttachedWorkflowValuesToBpmElement({
      ...bpmElementValues,
      processVariables,
      moddle,
    });
    replace.replaceElement(element, updatedElement);
  };

  const addRunningIndicator = (elements) => {
    const html = renderToString(<Running />);
    const overlays = modelerInstance.get("overlays");
    overlays.clear();
    elements.forEach((element) => {
      overlays.add(element, {
        position: { top: -24, right: 12 },
        html,
      });
    });
  };

  const validate = () => {
    if (!modelerInstance) return;
    let message = [];
    const elementRegistry = modelerInstance.get("elementRegistry");

    const noStartEvent = !elementRegistry
      .filter((element) => element.type === "bpmn:StartEvent")
      .some((element) => element.outgoing?.length);
    message = noStartEvent
      ? message.concat(t("Please create a Start Event with a connection."))
      : message;

    const noEndEvent = !elementRegistry
      .filter((element) => element.type === "bpmn:EndEvent")
      .some((element) => element.incoming?.length);
    message = noEndEvent
      ? message.concat(t("Please create an End Event with a connection."))
      : message;

    const noConditionData = elementRegistry
      .filter(
        (element) =>
          element.type === "bpmn:ExclusiveGateway" &&
          !gatewayConditionsAreSet(element, elementRegistry),
      )
      .map(({ id, name }) => name ?? id);
    message = noConditionData.length
      ? message.concat(
          `${t(
            "There are Decision Gateways without condition.",
          )} [${noConditionData}]`,
        )
      : message;

    const noDefaultGateways = elementRegistry
      .filter(
        (element) =>
          element.type === "bpmn:ExclusiveGateway" &&
          !getBusinessObject(element).default,
      )
      .map(({ id, name }) => name ?? id);
    message = noDefaultGateways.length
      ? message.concat(
          `${t(
            "There are Decision Gateways which do not have Default Flow.",
          )} [${noDefaultGateways}]`,
        )
      : message;

    const noMessageWorkflow = elementRegistry
      .filter((element) => {
        if (element.type === "bpmn:IntermediateCatchEvent") {
          const businessObject = getBusinessObject(element);
          const eventDefinition = businessObject.eventDefinitions[0];
          if (is(eventDefinition, "bpmn:MessageEventDefinition")) {
            const { workflowActivityId } = Bpm.readInfoFromMessageEvent(
              element,
            );
            return !workflowActivityId;
          }
        }
        return false;
      })
      .map((element) => {
        const businessObject = getBusinessObject(element);
        return businessObject?.name ?? businessObject?.id ?? element?.id;
      });
    message = noMessageWorkflow.length
      ? message.concat(
          `${t(
            "There are Variable Edit Point without Target Workflow.",
          )} [${noMessageWorkflow}]`,
        )
      : message;

    const emptyWorkflows = elementRegistry
      .filter((element) => element.type === "bpmn:Task")
      .map((element) => {
        const businessObject = getBusinessObject(element);
        if (!businessObject) return element.id;
        const { id, name } = businessObject;
        return name ?? id;
      });
    message = emptyWorkflows.length
      ? message.concat(`${t("There are empty Workflows.")} [${emptyWorkflows}]`)
      : message;

    const allAttachedWorkflowValues = getAllAttachedWorkflowValues();
    const invalidWorkflows = allAttachedWorkflowValues
      .filter(
        ({ workflowPath, processName, workflowInstanceId, processType }) =>
          !workflowPath ||
          !processName ||
          (processType &&
            !Process.isCreate(processType) &&
            !workflowInstanceId),
      )
      .map(({ displayName, activityId }) => displayName ?? activityId);
    message = invalidWorkflows.length
      ? message.concat(
          `${t(
            "There are Workflows which does not have all fields filled in.",
          )} [${invalidWorkflows}]`,
        )
      : message;

    return message.join("\n");
  };
  const moddle = modelerInstance ? modelerInstance.get("moddle") : null;

  return {
    modelerState,
    modelerActions: {
      selectElement,
      unselectElement,
      getBpmElementById,
      updateActiveElement,
      replaceActiveElement,
      getAllAttachedWorkflowValues,
      updateDefaultSequence,
      isConnectingToCurrentElement,
      replaceStartEventWithTimer,
      replaceVariablesWithReference,
      addRunningIndicator,
      validate,
    },
    moddle,
  };
};

export default useBpmModeler;
