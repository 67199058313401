import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useRouteMatch } from "react-router-dom";
import get from "lodash/get";
import mapKeys from "lodash/mapKeys";

import { getSelectedSubtenant } from "msa2-ui/src/store/designations";
import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";
import useWorkflowList from "msa2-ui/src/hooks/useWorkflowList";
import {
  addIconsToWorkflowList,
  getWorkflowReportByActor,
} from "msa2-ui/src/api/workflow";
import { sortOrder } from "msa2-ui/src/Constants";

import { Grid } from "@material-ui/core";

import AutomationGraphs from "./AutomationGraphs";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import AutomationRepository from "./AutomationRepository";
import AutomationTable from "./AutomationTable";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import { getIsDeveloper } from "msa2-ui/src/store/auth";
import { buildRoute } from "msa2-ui/src/utils/urls";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import CreateEntityButton from "msa2-ui/src/components/create-entity-button/CreateEntityButton";
import { getRoleDetails } from "msa2-ui/src/store/auth";

const shouldShowVisibility = FeatureFlag.isEnabled(
  FeatureFlag.features.workflowsVisibility,
);

const addObjectKeys = (first, second) =>
  Object.keys(first).reduce((newObject, key) => {
    const newValue = (first[key] ?? 0) + (second[key] ?? 0);
    return {
      ...newObject,
      [key]: newValue,
    };
  }, {});

const transformWorkflowReports = ({
  workflowReports,
  total_workflows: totalProcessInstances,
  ...rest
}) => {
  return {
    ...rest,
    totalProcessInstances,
    workflowInstanceReports: workflowReports.reduce(
      (reports, { total = 1, workflow, ...reportCounts }) => {
        const workflowName = workflow.serviceName;
        const existingReport = reports[workflowName] ?? { TOTAL: 0 };
        const addedReportValues = addObjectKeys(
          mapKeys(reportCounts, (value, key) => {
            return key.toUpperCase();
          }),
          existingReport,
        );
        const newTotal = existingReport.TOTAL + 1;
        return {
          ...reports,
          [workflowName]: { ...addedReportValues, workflow, TOTAL: newTotal },
        };
      },
      {},
    ),
  };
};

const mapWorkflowsToReports = (workflows, reports) => {
  return workflows.map((entry, i) => {
    const instance = get(reports, entry.path, {
      ENDED: 0,
      FAIL: 0,
      WARNING: 0,
      NONE: 0,
      PAUSE: 0,
      RUNNING: 0,
      TOTAL: 0,
    });
    return {
      key: i,
      name: entry.displayName,
      serviceName: entry.path,
      total: instance.TOTAL,
      badge: {},
      status: {
        ...instance,
      },
      graph: [
        [
          {
            name: "Running",
            value: instance.RUNNING,
          },
          {
            name: "Ended",
            value: instance.FAIL,
          },
          {
            name: "Warning",
            value: instance.WARNING,
          },
          {
            name: "None",
            value: instance.NONE,
          },
          {
            name: "Pause",
            value: instance.PAUSE,
          },
          {
            name: "Ended",
            value: instance.ENDED,
          },
        ],
      ],
    };
  });
};

const Automation = ({ sectionTabs: SectionTabs }) => {
  const { t } = useTranslation();

  const isDeveloper = useSelector(getIsDeveloper);
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );
  const { id: subtenantId } = useSelector(getSelectedSubtenant);
  const { pathname } = useLocation();
  const isRole = useSelector(getRoleDetails);

  const { url } = useRouteMatch();

  const [filterState, filterActions, resetFilter] = useFilter({
    tableName: "automation",
    sortByValue: "timestamp",
    sortOrderValue: 0,
    searchValue: "",
    tpPage: 0,
    total: 0,
  });

  const prevSubtenantIdRef = useRef(subtenantId);

  const filterByVisibility = (props) => {
    if (!shouldShowVisibility) {
      return props;
    }
    const { workflows, ...rest } = props;
    const filteredWorkflows = workflows.filter((workflow) => {
      return workflow.visibility >= isRole.id;
    });
    return { ...rest, workflows: filteredWorkflows };
  };

  const [
    workflowsLoading,
    workflowsError,
    workflows = {},
    ,
    reloadWorkflows,
  ] = useWorkflowList(
    {
      filter: { subtenant: true },
      page: filterState.tpPage + 1,
      pageSize: filterState.tpRowsPerPage,
      sort: filterState.sortByValue,
      sortOrder: sortOrder[filterState.sortOrderValue].param,
      filterCriteria: filterState.searchValue,
      isFilteredByOwner: isWorkflowsOwnerDetailsEnabled,
      transforms: [addIconsToWorkflowList, filterByVisibility],
    },
    prevSubtenantIdRef.current !== subtenantId,
  );

  useEffect(() => {
    // we need this hack to reset filter as soon as subtenant changes otherwise api will be triggered with stale filter params
    prevSubtenantIdRef.current = subtenantId;
    resetFilter();
  }, [subtenantId, resetFilter]);

  const [workflowsReportLoading, , totalWorkflowsReport] = useApi(
    getWorkflowReportByActor,
    {
      isDeveloper,
      subtenantId,
      transforms: [transformWorkflowReports],
    },
    !subtenantId,
  );

  const workflowReports =
    workflows.workflows && totalWorkflowsReport?.workflowInstanceReports
      ? mapWorkflowsToReports(
          workflows.workflows,
          totalWorkflowsReport.workflowInstanceReports,
        )
      : [];

  const workflowsData = {
    workflows: workflows.workflows,
    workflowsCount: workflows.workflows_count,
    totalWorkflowsReport,
    workflowReports,
    apiError: workflowsError,
    reloadWorkflows,
  };

  return (
    <ErrorBoundary>
      <SectionTabs
        count={[pathname, workflowsData.workflowsCount]}
        endElements={
          !subtenantId &&
          isDeveloper && (
            <CreateEntityButton
              id="AUTOMATION_BTN_ADD_WORKFLOW"
              aria-label={t("Create Workflow")}
              link={buildRoute(url, "create")}
              delegationProfileAction="general.create"
              delegationProfileType={delegationProfileTypes.WORKFLOWS}
            >
              {t("Create Workflow")}
            </CreateEntityButton>
          )
        }
      />
      {subtenantId && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AutomationGraphs
              {...workflowsData}
              workflowsLoading={workflowsLoading || workflowsReportLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <AutomationTable
              {...workflowsData}
              workflowsLoading={workflowsLoading}
              filterState={filterState}
              filterActions={filterActions}
              onAttachDialogClose={reloadWorkflows}
              isWorkflowsOwnerDetailsEnabled={isWorkflowsOwnerDetailsEnabled}
            />
          </Grid>
        </Grid>
      )}
      {!subtenantId && (
        <Grid container>
          <Grid item xs={12}>
            <AutomationRepository
              {...workflowsData}
              workflowsLoading={workflowsLoading}
              filterState={filterState}
              filterActions={filterActions}
              onAttachDialogClose={reloadWorkflows}
              isWorkflowsOwnerDetailsEnabled={isWorkflowsOwnerDetailsEnabled}
            />
          </Grid>
        </Grid>
      )}
    </ErrorBoundary>
  );
};

export default Automation;
