import React from "react";
import PropTypes from "prop-types";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { Link, useRouteMatch } from "react-router-dom";
import TableLoading from "msa2-ui/src/components/TableLoading";
import {
  IconButton,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import TableRow from "msa2-ui/src/components/TableRow";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import {
  Create as EditIcon,
  DeleteOutlined as DeleteIcon,
  Person as AttachedManagerIcon,
} from "@material-ui/icons";

const TBody = ({
  t,
  colSpan,
  isError,
  isLoading,
  permissionProfiles,
  onDeleteProfile,
}) => {
  const commonClasses = useCommonStyles();
  const { url } = useRouteMatch();

  if (isLoading) {
    return <TableLoading numberOfTableColumns={colSpan} />;
  }

  if (isError) {
    return (
      <TableMessage
        numberOfTableColumns={colSpan}
        message={t("GenericErrorMessage")}
      />
    );
  }

  if (!permissionProfiles.length) {
    return (
      <TableMessage
        numberOfTableColumns={colSpan}
        message={t("0 Results Returned")}
      />
    );
  }

  return (
    <TableBody>
      {permissionProfiles.map(
        ({ attachedManagers, profileId, profileName, lastUpdated }, index) => (
          <TableRow
            key={profileId}
            actions={
              <>
                <IconButton
                  id={`PERMISSION_PROFILES_TABLE_BTN_EDIT_${index}`}
                  aria-label={t("Edit")}
                  component={Link}
                  to={{
                    pathname: `${url}/${profileId}/edit`,
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton
                  id={`PERMISSION_PROFILES_TABLE_BTN_ASSOCIATED_MANAGERS_${index}`}
                  aria-label={t("Associated managers")}
                  component={Link}
                  to={{
                    pathname: `${url}/${profileId}/edit/managers`,
                  }}
                >
                  <AttachedManagerIcon color="primary" />
                </IconButton>
                <IconButton
                  aria-label={t("Delete")}
                  onClick={() =>
                    onDeleteProfile({ id: profileId, name: profileName })
                  }
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </>
            }
          >
            <TableCell className={commonClasses.commonTableCellDefault}>
              <Typography
                variant="h4"
                className={commonClasses.commonTablePrimary}
              >
                {profileName}
              </Typography>
            </TableCell>
            <TableCell
              className={commonClasses.commonTableCellDefault}
              align="center"
            >
              <Typography
                variant="body1"
                className={commonClasses.commonTableSecondary}
              >
                {lastUpdated
                  ? displayMonthDayYearTimeDate(lastUpdated)
                  : t("N/A")}
              </Typography>
            </TableCell>
            <TableCell
              className={commonClasses.commonTableCellDefault}
              align="center"
            >
              <div className={commonClasses.commonFlexCenter}>
                <Typography
                  variant="h4"
                  className={commonClasses.commonTablePrimary}
                >
                  {attachedManagers?.length || 0}
                </Typography>
                <AttachedManagerIcon />
              </div>
            </TableCell>
          </TableRow>
        ),
      )}
    </TableBody>
  );
};

TBody.propTypes = {
  t: PropTypes.func.isRequired,
  colSpan: PropTypes.number.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  permissionProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      lastUpdated: PropTypes.number,
      profileId: PropTypes.number.isRequired,
      profileName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDeleteProfile: PropTypes.func.isRequired,
};

export default TBody;
