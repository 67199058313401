export const formSections = {
  snmpPollingList: "SNMP Polling",
  graphRendererList: "Graphical Rendering",
};

export const formArrayTypes = {
  snmp: "snmpPollingList",
  graph: "graphRendererList",
  graphDataList: "graphRendererList.dataList",
};

export const pollingTypes = {
  C: "Counter",
  G: "Gauge",
};

export const pollingPeriods = {
  "1": "1",
  "5": "5",
  "10": "10",
  "15": "15",
};

export const thresholdComparator = {
  G: ">",
  L: "<",
};

export const thresholdFrequency = {
  N: "never",
  "0": "once",
  M: "1/min",
  H: "1/hour",
  D: "1/day",
};
