import { useRef } from "react";

export const useIsLatestCall = (fn) => {
  const sessionRef = useRef(null);
  const wrapFn = (...args) => {
    const session = {};
    sessionRef.current = session;
    wrapFn.isLatest = () => sessionRef.current === session;
    return fn(...args);
  };

  return wrapFn;
};
