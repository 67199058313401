import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useFilter from "msa2-ui/src/hooks/useFilter";
import {
  removeESUnescapableCharacters,
  makePartialMatchQuery,
} from "msa2-ui/src/utils/elasticsearch";

import { getLogs } from "msa2-ui/src/api/logs";
import useApi from "msa2-ui/src/hooks/useApi";

import {
  useTheme,
  useMediaQuery,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import LogsFilterMenu from "../../../alarms/LogsFilterMenu";
import LogsTableBody from "../../../alarms/LogsTableBody";

import { TABLE_HEADER_COLUMNS } from "msa2-ui/src/Constants";

/**
 * ElasticSearch uses a from parameter in combination with the page size to determine
 * which search results to show. The from parameter is just the offset from the first result
 * at index 0
 *
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/run-a-search.html#paginate-search-results
 *
 * @param {Number} page - the page number has determined by the pagination component
 * @param {Number} rowsPerPage - the number of logs to show on any given page
 */
const getElasticSearchOffset = (page, rowsPerPage) => page * rowsPerPage;

const ManagedEntityLogsTab = ({ managedEntity }) => {
  const commonClasses = useCommonStyles();
  const { t } = useTranslation("logs");
  const namespace = "managedEntityLogs";
  const dispatch = useDispatch();
  const [filterState, filterActions] = useFilter({
    tableName: namespace,
    tpPage: 0,
    flag1: false,
  });
  const {
    tpRowsPerPage: rowsPerPage,
    columns: checkedColumnIds,
    flag1: isESQueryMode,
    searchValue,
  } = filterState;

  const {
    deviceId: { ubiId },
  } = managedEntity;

  const handleSearchByChange = (val) => {
    filterActions.handleSearchByChange(removeESUnescapableCharacters(val));
  };

  const [logType, setLogType] = useState(null);
  const [severities, setSeverities] = useState([]);
  const [subtenantIds, setSubtenantIds] = useState([]);
  const [managedEntityIds, setManagedEntityIds] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [endDateToES, setEndDateToES] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startDateToES, setStartDateToES] = useState("");
  const [page, setPage] = useState(0);
  const [searchWords, setSearchWords] = useState([]);

  const { breakpoints } = useTheme();
  const isWidthSmall = useMediaQuery(breakpoints.down("md"));
  const columns = TABLE_HEADER_COLUMNS.managedEntityLogs
    .filter(({ onlyOnWideScreen }) => !(onlyOnWideScreen && isWidthSmall))
    .map((column) => ({
      ...column,
      check: checkedColumnIds.includes(column.id) ? true : undefined,
    }));
  const checkedColumns = columns.filter((entry) => entry.check);
  const tableCellLength = checkedColumns.length;

  const [loading, error, logsResponse = {}] = useApi(getLogs, {
    queryString: isESQueryMode
      ? searchValue
      : makePartialMatchQuery(searchValue),
    logType,
    severities,
    subtenantIds,
    managedEntityIds: [ubiId],
    startDate: startDateToES,
    endDate: endDateToES,
    from: getElasticSearchOffset(page, rowsPerPage),
    pageSize: rowsPerPage,
  });
  const { logs = [], count = 0 } = logsResponse;

  useEffect(() => {
    if (logs.length) {
      if (searchValue) {
        setSearchWords(searchValue.split(" "));
      } else {
        setSearchWords([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  const onChangeRowsPerPage = (e) => {
    dispatch(filterActions.tpChangeRowsPerPage(e));
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper
          className={classNames([
            commonClasses.commonPaper,
            commonClasses.commonPaperNoPadding,
          ])}
          style={{ overflowY: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={tableCellLength}
                  className={commonClasses.commonTableCell}
                >
                  <LogsFilterMenu
                    createAlarmView={false}
                    ubiId={ubiId}
                    searchValue={searchValue}
                    handleSearchByChange={handleSearchByChange}
                    logType={logType}
                    setLogType={setLogType}
                    severities={severities}
                    setSeverities={setSeverities}
                    subtenantIds={subtenantIds}
                    setSubtenantIds={setSubtenantIds}
                    managedEntityIds={managedEntityIds}
                    setManagedEntityIds={setManagedEntityIds}
                    startDate={startDate}
                    startDateToES={startDateToES}
                    setStartDate={setStartDate}
                    setStartDateToES={setStartDateToES}
                    endDate={endDate}
                    endDateToES={endDateToES}
                    setEndDate={setEndDate}
                    setEndDateToES={setEndDateToES}
                    count={count}
                    page={page}
                    onChangePage={(e, pageNumber) => setPage(pageNumber)}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    columns={columns}
                    addColumn={filterActions.handleAddColumn}
                    removeColumn={filterActions.handleRemoveColumn}
                    onClickSearchMode={filterActions.toggleFlag1}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={commonClasses.commonTableHeadRow}>
                {checkedColumns.map(
                  (tableHeaderColumn) =>
                    tableHeaderColumn.check && (
                      <TableCell
                        key={tableHeaderColumn.id}
                        align={tableHeaderColumn.align}
                        className={commonClasses.commonTableCellDefault}
                      >
                        {tableHeaderColumn.active ? (
                          <TableSortLabel
                            id={`LOGS_TABLE_SORT_${tableHeaderColumn.id}`}
                            active={false}
                          >
                            {t(tableHeaderColumn.name)}
                          </TableSortLabel>
                        ) : (
                          t(tableHeaderColumn.name)
                        )}
                      </TableCell>
                    ),
                )}
              </TableRow>
            </TableHead>

            <LogsTableBody
              loading={loading}
              error={error}
              logs={logs}
              columns={checkedColumns}
              enableHighlighting
              searchWordsToHighlight={searchWords}
            />
          </Table>
        </Paper>
      </Grid>
    </>
  );
};

export default ManagedEntityLogsTab;
