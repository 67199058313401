import React from "react";
import { useParams } from "react-router-dom";

import useApi from "msa2-ui/src/hooks/useApi";
import { getAlarm } from "msa2-ui/src/api/alarms";
import UpsertAlarmForm from "./UpsertAlarmForm";

const EditAlarm = () => {
  const { alarmId } = useParams();

  const [loading, error, alarmData] = useApi(getAlarm, {
    alarmId,
  });

  return (
    <UpsertAlarmForm
      alarmData={alarmData}
      loading={loading}
      error={error?.getMessage()}
      alarmId={alarmId}
    />
  );
};

export default EditAlarm;
