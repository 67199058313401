import React from "react";
import Field from "redux-form/lib/Field";
import Checkbox from "msa2-ui/src/components/connectedFormComponents/Checkbox";

const FormCheckbox = (props) => {
  const {
    name,
    options,
    className,
    label,
    onChange,
    disabled = false,
    required = false,
  } = props;
  return (
    <Field
      name={name}
      id={name}
      label={label}
      options={options}
      component={Checkbox}
      className={className}
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
  );
};

export default FormCheckbox;
