import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import reduxForm from "redux-form/lib/reduxForm";
import { getFormSyncErrors } from "redux-form";
import flow from "lodash/flow";
import WorkflowForm from "msa2-ui/src/services/WorkflowForm";
import Workflow from "msa2-ui/src/services/Workflow";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";

import { getToken } from "msa2-ui/src/store/auth";
import { useTranslation } from "react-i18next";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getParentRoute } from "msa2-ui/src/utils/urls";
import {
  createWorkflow,
  deleteWorkflow,
  editWorkflow,
} from "msa2-ui/src/api/workflow";

import Modal from "msa2-ui/src/components/modal/Modal";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import VariablesTabContent from "msa2-ui/src/components/variables/VariablesTabContent";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import { getFormValues } from "msa2-ui/src/store/form";

import ModalTitleBar from "msa2-ui/src/routes/automation/workflows/edit/ModalTitleBar";
import ModalSidebar from "msa2-ui/src/routes/automation/workflows/edit/ModalSidebar";
import InformationSection from "../edit/InformationSection";
import AddProcess from "../edit/AddProcess";
import ProcessSection from "../edit/ProcessSection";
import useWorkflowProcess from "../hooks/useWorkflowProcess";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const sections = {
  information: "Information",
  variables: "Variables",
};

const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.workflowsOwner,
);

const AutomationCreate = ({
  destroy,
  dirty,
  match,
  handleSubmit,
  history,
  initialize,
  initialized,
  change,
  formErrors,
}) => {
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const form = WorkflowForm.CreateFormName;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [savedWorkflowData, setSavedWorkflowData] = useState(null);
  const [activeSidebarTab, setActiveSidebarTab] = useState(
    sections.information,
  );
  const [showAddProcessDialog, setShowAddProcessDialog] = useState(false);
  const [showDiscardDialog, DiscardDialog] = useDialog();
  const [showTaskDiscardDialog, TaskDiscardDialog, { tabName }] = useDialog();
  const [showDeleteProcessDialog, DeleteProcessDialog] = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditorUnsaved, setIsEditorUnsaved] = useState(false);

  const workflowProcesses =
    useSelector(getFormValues(form, WorkflowForm.formConfig.processPath)) ?? [];

  const activeWorkflowProcess = workflowProcesses?.find(
    (workflowProcess) => workflowProcess.displayName === activeSidebarTab,
  );
  const activeProcessIndex = workflowProcesses?.findIndex(
    (workflowProcess) => workflowProcess.displayName === activeSidebarTab,
  );

  const returnToPreviousPage = () => {
    history.push(getParentRoute(match.url));
  };

  const discardChanges = async () => {
    if (savedWorkflowData) {
      setIsLoading(true);
      await deleteWorkflow({
        token,
        uri: savedWorkflowData.metaInformationList[0].uri,
      });
      setIsLoading(false);
    }

    destroy();
    returnToPreviousPage();
  };

  const navigateToError = (errors) => {
    if (errors.information) {
      setActiveSidebarTab(sections.information);
    }
  };

  const submitForm = async (values) => {
    setIsLoading(true);
    let workflowData = null;
    let error = null;

    if (savedWorkflowData) {
      workflowData = Workflow.formatDataForEditApi(values);
      [error] = await editWorkflow({
        token,
        workflow: workflowData,
      });
    } else {
      workflowData = Workflow.formatDataForCreateApi(values);
      [error] = await createWorkflow({
        token,
        workflow: workflowData,
        saveOwner: isWorkflowsOwnerDetailsEnabled,
      });
    }

    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage()
      : t("x has been created", { x: t("Workflow") });

    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    setIsLoading(false);
    return [error, workflowData];
  };

  const saveWorkflowAndReturnToPreviousPage = async (values) => {
    const [isError] = await submitForm(values);

    if (!isError) {
      destroy();
      returnToPreviousPage();
    }
  };

  const saveWorkflowAndTryToAddProcess = async (values) => {
    // we only need to save WF once
    if (!savedWorkflowData) {
      const [isError, workflowData] = await submitForm(values);
      if (isError) return;

      setSavedWorkflowData(workflowData);

      // we need to reinitialize the form with saved data
      initialize({
        ...Workflow.make(workflowData),
        config: WorkflowForm.formConfig,
      });
    }

    setShowAddProcessDialog(true);
  };

  const handleClose = () => {
    if (dirty || isEditorUnsaved) {
      return showDiscardDialog();
    }
    returnToPreviousPage();
  };

  const { addProcess, removeProcessFromForm } = useWorkflowProcess({
    activeProcessIndex,
    change,
    sections,
    setActiveSidebarTab,
    workflowData: savedWorkflowData,
    workflowProcesses,
    setIsLoading,
  });

  useEffect(() => {
    if (!initialized) {
      initialize({
        ...Workflow.make(),
        config: WorkflowForm.formConfig,
      });
    }
  }, [initialize, initialized]);

  return (
    <ErrorBoundary>
      <TaskDiscardDialog
        title={t("Discard changes?")}
        content={
          <>
            <div>{t("Your Task has not been saved.")} </div>
            <div>{t("Are you sure you want to discard your changes?")}</div>
          </>
        }
        onExec={() => {
          setActiveSidebarTab(tabName);
        }}
      />
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={discardChanges}
      />
      <DeleteProcessDialog
        title={`${t("Delete x", { x: t("Process") })}?`}
        content={t("Are you sure you want to delete this process?")}
        onExec={removeProcessFromForm}
      />
      <AddProcess
        showAddProcessDialog={showAddProcessDialog}
        setShowAddProcessDialog={() => {
          setShowAddProcessDialog(false);
        }}
        workflowProcesses={workflowProcesses}
        addProcessToForm={addProcess}
      />
      <Modal onClose={handleClose}>
        <ModalTitleBar
          onSave={() => {
            if (!isEmpty(formErrors)) {
              navigateToError(formErrors);
            }
            handleSubmit(saveWorkflowAndReturnToPreviousPage)();
          }}
          onDiscard={showDiscardDialog}
          onClose={handleClose}
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Save Workflow")}
          discardButtonLabel={t("Discard Changes")}
          isLoading={isLoading}
          disabled={isLoading}
        />
        <ModalContent>
          <ModalSidebar
            change={change}
            processPath={WorkflowForm.formConfig.processPath}
            activeTab={activeSidebarTab}
            handleOnClick={(tabName) => {
              if (isEditorUnsaved) {
                showTaskDiscardDialog(true, { tabName });
              } else {
                setActiveSidebarTab(tabName);
              }
            }}
            sections={sections}
            form={form}
            setShowAddProcessDialog={() => {
              if (!isEmpty(formErrors)) {
                navigateToError(formErrors);
              }
              handleSubmit(saveWorkflowAndTryToAddProcess)();
            }}
            processes={workflowProcesses}
          />
          <ScrollingContainer>
            <InformationSection
              isActive={activeSidebarTab === sections.information}
              shouldDisableWFLanguageSelector={Boolean(savedWorkflowData)}
              form={form}
            />
            {initialized && (
              <VariablesTabContent isActive={activeSidebarTab} form={form} />
            )}
            {activeWorkflowProcess && savedWorkflowData && (
              <ProcessSection
                change={change}
                form={form}
                isEditorUnsaved={isEditorUnsaved}
                setIsEditorUnsaved={setIsEditorUnsaved}
                showDeleteProcessDialog={showDeleteProcessDialog}
                workflowProcess={activeWorkflowProcess}
                processIndex={activeProcessIndex}
                setIsLoading={setIsLoading}
              />
            )}
          </ScrollingContainer>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

AutomationCreate.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors(WorkflowForm.CreateFormName)(state),
});

export default flow(
  withRouter,
  reduxForm({
    form: WorkflowForm.CreateFormName,
    validate: WorkflowForm.validate,
  }),
  connect(mapStateToProps),
)(AutomationCreate);
