import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { change } from "redux-form";
import set from "lodash/set";
import isEqual from "lodash/isEqual";
import flow from "lodash/flow";
import NumberFormat from "react-number-format";
import Variable from "msa2-ui/src/services/Variable";
import MicroserviceCommand from "msa2-ui/src/services/MicroserviceCommand";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";

import { getFormValues } from "msa2-ui/src/store/form";
import {
  getAdvancedVariableParametersByName,
  getAdvancedVariableParametersByType,
} from "msa2-ui/src/store/designations";

import useDialog from "msa2-ui/src/hooks/useDialog";

import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import classnames from "classnames";

import VariablesList from "./VariablesList";
import VariablesEdit from "./VariablesEdit";
import { cloneDeep } from "lodash";

export const useStyles = makeStyles(({ typography, palette }) => ({
  paper: {
    position: "relative",
    minHeight: "65vh",
    marginRight: "10px",
    width: "inherit",
  },
  sectionHeaderContainer: {
    marginBottom: 5,
  },
  sectionHeader: {
    fontSize: "1.5rem",
    fontWeight: typography.fontWeightMedium,
    marginRight: 20,
  },
  sectionSubheader: {
    fontSize: "0.875rem",
    fontWeight: typography.fontWeightRegular,
    color: palette.text.secondary,
    marginBottom: 20,
  },
  sectionTabAlignment: {
    marginTop: 1,
  },
  error: {
    color: palette.error.main,
  }
}));

const VariablesTabContent = ({ isActive, form, change, formErrors }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const formValues = useSelector(getFormValues(form));
  const { variablePath } = useSelector(getFormValues(form, "config"));

  const variables = useSelector(getFormValues(form, variablePath)) || [];

  const activeVariable = useSelector(getFormValues(form, "active"));
  const [showCancelDialog, CancelDialog] = useDialog();
  const [deleteTargets, setDeleteTargets] = useState([]);
  const [showDeleteDialog, DeleteDialog] = useDialog();

  const currentVariable = useSelector(
    getFormValues(form, `${variablePath}.${activeVariable?.index.toString()}`),
  );

  const advancedVariableParametersByName = useSelector(
    getAdvancedVariableParametersByName(form),
  );
  const advancedVariableParameters = useSelector(
    getAdvancedVariableParametersByType(form),
  );

  const initValue = {
    ...Variable.getInitVariableByType(
      advancedVariableParametersByName,
      advancedVariableParameters,
    ),
    displayOrder: variables.length,
  };
  const createVariable = () => {
    change(form, [variablePath], variables.concat(initValue));
    change(form, "active", { index: variables.length });
  };

  const handleCancelEdit = () => {
    if (isEqual(activeVariable.variable, currentVariable)) {
      change(form, "active", "");
    } else {
      showCancelDialog();
    }
  };
  const cancelEdit = () => {
    if (!activeVariable.variable) {
      change(
        form,
        [variablePath],
        variables.filter((e, i) => i !== activeVariable.index),
      );
    } else {
      change(
        form,
        [[variablePath, activeVariable.index].join(".")],
        activeVariable.variable,
      );
    }
    change(form, "active", "");
  };
  const maybeEditConstraint = (updatedKey, updatedValue) => {
    if (MicroserviceForm.isMicroserviceForm(form)) {
      const values = set(cloneDeep(formValues), updatedKey, updatedValue);
      // Update Constraint
      const {
        constraint,
        index: constraintIndex,
      } = MicroserviceCommand.buildConstraintByCompositeKey(values);
      change(form, ["command", constraintIndex].join("."), constraint);
    }
  };

  const saveVariable = () => {
    change(form, "active", "");
    maybeEditConstraint();
  };

  const deleteVariable = () => {
    Boolean(activeVariable) && cancelEdit();
    const deleteTargetsIds = deleteTargets.map((dt) => dt.id);
    const newVariables =
      variables.filter((e, i) => !deleteTargetsIds.includes(i)) || [];
    change(form, [variablePath], newVariables);
    setDeleteTargets([]);
    change(form, "checked", []);
    maybeEditConstraint(variablePath, newVariables);
  };

  const handleDeleteVariable = (id, name) => {
    setDeleteTargets([{ id, name }]);
    showDeleteDialog();
  };

  const handleDeleteSelectedVariables = (variablesToDelete) => {
    setDeleteTargets(
      variablesToDelete.map((v) => ({ id: v.index, name: v.displayName })),
    );
    showDeleteDialog();
  };

  const deleteDialogContent = () => {
    if (deleteTargets.length > 1) {
      return t("Are you sure you want to delete next variables x?", {
        x: deleteTargets.map((dt) => dt.name).join(", "),
      });
    } else {
      return t("Are you sure you want to delete x?", {
        x: deleteTargets[0]?.name,
      });
    }
  };

  if (isActive === "Variables") {
    return (
      <>
        <CancelDialog
          title={t("Cancel Edit?")}
          content={t("Are you sure you want to discard your changes?")}
          onExec={cancelEdit}
        />
        <DeleteDialog
          title={t("Delete Variable?")}
          content={deleteDialogContent()}
          onExec={deleteVariable}
        />
        <Grid container>
          <Grid
            container
            direction={"row"}
            alignItems={"baseline"}
            className={classes.sectionHeaderContainer}
          >
            <Typography className={classes.sectionHeader}>
              {t("Variables")}
            </Typography>
            <Typography className={classes.sectionSubheader}>
              <NumberFormat
                value={variables ? variables.length : 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography id="FORM_VALIDATION_ERROR_MESSAGE"
              className={classnames(classes.sectionSubheader, classes.error)}>
              {formErrors && formErrors.variables ?
                t("Invalid Variable(s). Edit parameters below for more detail") : ""}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Paper
              className={classnames(
                commonClasses.commonPaperNoPadding,
                classes.paper,
              )}
            >
              <Grid
                direction={"row"}
                container
                className={classes.sectionTabAlignment}
              >
                <VariablesList
                  form={form}
                  createVariable={createVariable}
                  deleteVariable={handleDeleteVariable}
                  deleteSelectedVariables={handleDeleteSelectedVariables}
                  cancelEdit={handleCancelEdit}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <VariablesEdit
              form={form}
              createVariable={createVariable}
              deleteVariable={handleDeleteVariable}
              saveVariable={saveVariable}
              cancelEdit={handleCancelEdit}
              variablePath={variablePath}
              activeVariable={activeVariable}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  return <></>;
};

VariablesTabContent.propTypes = {
  form: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
};

export default flow(connect(null, { change }))(VariablesTabContent);
