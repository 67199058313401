import { get, post } from "./request";
import { contentTypes } from "./constants";
import isPast from "date-fns/isPast";
import subDays from "date-fns/subDays";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

const API = process.env.REACT_APP_API_PATH;
export const LICENSE_WARNING_DAYS = 14;

const normalizeJSON = (response) => {
  return {
    licenseType: response.LicenseType,
    startDate: response.notBefore,
    expiryDate: response.ExpiryDate,
    maxDevices: response.MaxDevices,
    licenseOwner: response.LicenseOwner,
    isExpiring: isPast(
      subDays(new Date(response.ExpiryDate), LICENSE_WARNING_DAYS),
    ),
    daysUntilExpire: differenceInCalendarDays(
      new Date(response.ExpiryDate),
      new Date(),
    ),
    isHA: response.extensions?.["1.3.6.1.4.1.13567.1.3.3"] === "true",
  };
};

/*
 * No docs yet
 * Spec: https://10.31.1.52/swagger/#
 */
export const getLicenseStatus = ({ token, transforms = [normalizeJSON] }) => {
  return get({
    url: `${API}/system-admin/v1/license`,
    token,
    transforms,
  });
};

/*
 * No docs yet
 * Spec: https://10.31.1.52/swagger/#
 */
export const uploadLicense = ({ token, formData }) => {
  return post({
    url: `${API}/system-admin/v1/license`,
    token,
    body: formData,
    contentType: contentTypes.FORM_DATA,
  });
};
