import React, { memo, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getAttachedMonitoringProfiles } from "msa2-ui/src/api/monitoringProfiles";
import { getGraphs } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { defaultProfile, monitoringPeriods } from "./constants";
import useApi from "msa2-ui/src/hooks/useApi";
import MonitoringGraphsView from "./MonitoringGraphsView";
import { setMGData, getMGData } from "msa2-ui/src/store/designations";

const AUTO_REFRESH_INTERVAL = 1000 * 60;

const MonitoringGraphs = ({ deviceId, deviceUbiId, externalReference }) => {
  const dispatch = useDispatch();
  const monitoringGraphData = useSelector(getMGData);
  const [profileId, setProfileId] = useState(defaultProfile.id);
  const [monitoringPeriod, setMonitoringPeriod] = useState(
    monitoringPeriods[1].id, // last day by default
  );
  const [customMonitoringPeriod, setCustomMonitoringPeriod] = useState([]);

  const [isCustomPeriodShown, setIsCustomPeriodShown] = useState(false);

  useEffect(() => {
    if (monitoringGraphData?.monitoringPeriod) {
      setMonitoringPeriod(monitoringGraphData?.monitoringPeriod);
    }
    if (monitoringGraphData?.customMonitoringPeriod) {
      setCustomMonitoringPeriod(monitoringGraphData?.customMonitoringPeriod);
    }
  }, [monitoringGraphData]);

  const [isGraphsLoading, isGraphsError, graphs = []] = useApi(
    getGraphs,
    {
      deviceUbiId,
      profileId: profileId,
      monitoringPeriod,
      customMonitoringPeriod,
    },
    !profileId || !monitoringPeriod,
    AUTO_REFRESH_INTERVAL,
  );

  const [
    isAttachedProfilesLoading,
    isAttachedProfilesError,
    attachedProfiles,
  ] = useApi(getAttachedMonitoringProfiles, { deviceId }, !deviceId);

  const monitoringPeriodChangeHandler = useCallback(
    (evt) => {
      dispatch(setMGData({ monitoringPeriod: evt.target.value }));
      setMonitoringPeriod(evt.target.value);
    },
    [dispatch],
  );

  const handleCustomPeriodVisibility = () =>
    setIsCustomPeriodShown(!isCustomPeriodShown);

  const handleCustomMonitoringPeriodChange = (
    customPeriod,
    shouldToggleVisibility = true,
  ) => {
    setCustomMonitoringPeriod(customPeriod);
    dispatch(setMGData({ customMonitoringPeriod: customPeriod }));
    shouldToggleVisibility && handleCustomPeriodVisibility();
  };

  const profileChangeHandler = useCallback(
    (evt) => setProfileId(evt.target.value),
    [],
  );

  return (
    <MonitoringGraphsView
      attachedProfiles={attachedProfiles || []}
      externalReference={externalReference}
      graphs={graphs}
      error={isGraphsError || isAttachedProfilesError}
      isError={Boolean(isGraphsError || isAttachedProfilesError)}
      isLoading={Boolean(isGraphsLoading || isAttachedProfilesLoading)}
      isCustomPeriodShown={isCustomPeriodShown}
      customMonitoringPeriod={customMonitoringPeriod}
      monitoringPeriod={monitoringPeriod}
      profileId={profileId}
      onMonitoringPeriodChange={monitoringPeriodChangeHandler}
      onCustomMonitoringPeriodVisibilityChange={handleCustomPeriodVisibility}
      onCustomMonitoringPeriodChange={handleCustomMonitoringPeriodChange}
      onProfileChange={profileChangeHandler}
    />
  );
};

MonitoringGraphs.propTypes = {
  deviceId: PropTypes.number.isRequired,
  deviceUbiId: PropTypes.string.isRequired,
  externalReference: PropTypes.string.isRequired,
};

export default memo(MonitoringGraphs);
