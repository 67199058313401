import React from "react";
import PropTypes from "prop-types";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

/**
 * If delegationProfileAction and delegationProfileType props are specified,
 * it will check whether user has rights to see WrappedComponent.
 *
 * NB! delegationProfileAction && delegationProfileType will not be passed through to the WrappedComponent.
 */
const withDelegationProfiles = (WrappedComponent) => {
  const WithDelegationProfiles = (props) => {
    const {
      delegationProfileAction,
      delegationProfileType,
      ...restProps
    } = props;

    if (delegationProfileAction && delegationProfileType) {
      return (
        <DelegationProfiles
          action={delegationProfileAction}
          type={delegationProfileType}
        >
          <WrappedComponent {...restProps} />
        </DelegationProfiles>
      );
    }

    return <WrappedComponent {...restProps} />;
  };

  WithDelegationProfiles.displayName = `WithDelegationProfiles(${getDisplayName(
    WrappedComponent,
  )})`;

  WithDelegationProfiles.propTypes = {
    delegationProfileAction: PropTypes.string,
    delegationProfileType: PropTypes.string,
  };

  return WithDelegationProfiles;
};

export default withDelegationProfiles;
