import React, { useState } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import OverviewDetails from "msa2-ui/src/routes/profile/details/OverviewDetails";
import EditDetails from "msa2-ui/src/routes/profile/details/EditDetails";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { Button, Grid, Paper } from "@material-ui/core";
import EditOutlined from "@material-ui/icons/EditOutlined";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import ProfileAvatar from "msa2-ui/src/components/ProfileAvatar";
import { makeStyles } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getToken,
  getUserDetails,
  updateUserDetails,
} from "msa2-ui/src/store/auth";
import passwordGenerator from "generate-password";
import { change, submit } from "redux-form";
import { reduxFormNames } from "msa2-ui/src/Constants";
import { updateManager } from "msa2-ui/src/api/manager";
import { useSnackbar } from "notistack";
import {
  delegationProfileTypes,
  getDelegationProfilesList,
} from "msa2-ui/src/store/delegationProfiles";
import { userRoles, getUserRole } from "msa2-ui/src/store/auth";

const useStyles = makeStyles(() => ({
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 10,
  },
}));

const Details = ({ sectionTabs: SectionTabs }) => {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [canViewAuthenticationField, canEditAuthenticationField] = useSelector(
    getDelegationProfilesList([
      [delegationProfileTypes.PROFILE, "authentication.view"],
      [delegationProfileTypes.PROFILE, "authentication.modify"],
    ]),
    shallowEqual,
  );
  const userRole = useSelector(getUserRole);
  const isManager = userRole === userRoles.MANAGER;
  const userCanViewAuthentication = isManager
    ? canViewAuthenticationField
    : true;
  const userCanEditAuthentication = isManager
    ? canEditAuthenticationField
    : true;

  const token = useSelector(getToken);
  const userDetails = useSelector((state) => {
    const {
      id,
      baseRole,
      address,
      firstname,
      name,
      login,
      userType,
    } = getUserDetails(state);

    return {
      id,
      baseRole,
      address,
      firstname,
      name,
      login,
      userType,
    };
  }, shallowEqual);

  const formInitialValues = omit(userDetails, "baseRole");

  const t = useBoundedTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const handleGeneratePassword = () => {
    const password = passwordGenerator.generate({
      length: 10,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    });

    dispatch(change(reduxFormNames.profileForm, "password", password));
  };

  const handleSave = () => {
    dispatch(submit(reduxFormNames.profileForm));
  };

  const handleSubmit = async (values) => {
    const [error] = await updateManager({
      token,
      id: userDetails.id,
      name: values.name,
      email: values.address.mail,
      username: values.login,
      password: values.password,
      firstname: values.firstname,
      usertype: userDetails.userType,
      manager: {
        ...values,
        address: {
          ...omit(values.address, "mail"),
        },
      },
    });

    if (error) {
      return enqueueSnackbar(
        error.getMessage(t("Unable to save", { variant: "error" })),
      );
    }

    enqueueSnackbar(t("x saved successfully", { x: "Profile" }), {
      variant: "success",
    });
    dispatch(updateUserDetails(values));
    setIsEditing(false);
  };

  const renderDetailButtons = () =>
    isEditing ? (
      <>
        <Button
          id="DETAILS_CANCEL_BUTTON"
          variant="text"
          color="default"
          className={commonClasses.commonBtnPrimary}
          onClick={() => setIsEditing(false)}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="DETAILS_SAVE_BUTTON"
          variant="contained"
          color="primary"
          className={commonClasses.commonBtnPrimary}
          onClick={handleSave}
        >
          {t("Save")}
        </Button>
      </>
    ) : (
      <Button
        id="DETAILS_EDIT_BUTTON"
        variant="contained"
        color="primary"
        onClick={() => setIsEditing(true)}
      >
        <EditOutlined className={commonClasses.commonBtnIcon} />
        {t("Edit Profile")}
      </Button>
    );

  return (
    <div>
      <SectionTabs endElements={renderDetailButtons()} />
      <Paper className={commonClasses.commonPaper}>
        <Grid item container xs={12}>
          <Grid item xs={2} className={classes.avatarWrapper}>
            <div className={classes.avatar}>
              <ProfileAvatar size={80} />
            </div>
          </Grid>
          <Grid item xs={10}>
            {isEditing ? (
              <EditDetails
                initialValues={formInitialValues}
                onGeneratePassword={handleGeneratePassword}
                userCanViewAuthentication={userCanViewAuthentication}
                userCanEditAuthentication={userCanEditAuthentication}
                isManager={isManager}
                onSubmit={handleSubmit}
              />
            ) : (
              <OverviewDetails
                name={userDetails.name}
                login={userDetails.login}
                firstname={userDetails.firstname}
                baseRoleName={userDetails?.baseRole?.name}
                address={userDetails.address || {}}
                userCanViewAuthentication={userCanViewAuthentication}
                userCanEditAuthentication={userCanEditAuthentication}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

Details.propTypes = {
  sectionTabs: PropTypes.func.isRequired,
};

export default Details;
