export const nodeTypes = {
  PHSL: "PHSL",
  VPUB: "VPUB",
  VPRV: "VPRV",
  NETWORK: "NETWORK",
  UNKNOWN_TYPE: "UNKNOWN_TYPE",
};

export const filterableNodeTypesMap = {
  [nodeTypes.PHSL]: "Physical",
  [nodeTypes.VPUB]: "Public Virtual",
  [nodeTypes.VPRV]: "Private Virtual",
  [nodeTypes.NETWORK]: "Network",
};

export const statusTypes = {
  OK: "OK",
  NEVERREACHED: "NEVERREACHED",
  CRITICAL: "CRITICAL",
  ERROR: "ERROR",
  UNKNOWN_STATUS: "UNKNOWN_STATUS",
};
