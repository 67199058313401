export const defaultGraphsConfiguration = {
  AVAILABILITY: {
    id: "AVAILABILITY",
    name: "Availability",
    kpis: {
      latency: {
        color: "#24a36a",
        label: "Latency",
        id: "latency",
        suffix: "ms",
      },
      ttl: {
        color: "#0059a7",
        label: "TTL",
        id: "ttl",
      },
    },
    verticalLabel: "y",
  },
  SYSUPTIME: {
    id: "SYSUPTIME",
    name: "Sysuptime",
    kpis: {
      uptime: {
        color: "#24a36a",
        label: "Up/Down",
        id: "uptime",
        suffix: "day(s)",
      },
    },
    verticalLabel: "y",
  },
  TRAFFIC: {
    id: "TRAFFIC",
    name: "Traffic",
    kpis: {
      incoming: {
        color: "#24a36a",
        label: "Incoming",
        id: "incoming",
        suffix: "Bytes/second",
      },
      outgoing: {
        color: "#0059a7",
        label: "Outgoing",
        id: "outgoing",
        suffix: "Bytes/second",
      },
    },
    verticalLabel: "y",
  },
  CPU: {
    id: "CPU",
    name: "Cpu",
    kpis: {
      cpu: {
        color: "#24a36a",
        label: "Cpu",
        id: "cpu",
        suffix: "%",
      },
    },
    verticalLabel: "y",
  },
};

export const defaultProfile = {
  id: "__DEFAULT_ID__",
  name: "Default",
};

export const monitoringPeriods = [
  { id: "hour", name: "Last hour" },
  { id: "day", name: "Last day" },
  { id: "week", name: "Last week" },
  { id: "month", name: "Last month" },
  { id: "year", name: "Last year" },
];
