import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import classnames from "classnames";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    paddingBottom: 5,
  },
  formHeading: {
    marginBottom: 15,
  },
  formField: {
    marginBottom: 35,
    width: "100%",
  },
  description: {
    margin: 5,
  },
  continueBpm: {
    marginBottom: 35,
  },
}));

const SignalEventPropertiesPanel = ({
  modelerState,
  modelerActions,
  moddle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Grid container direction="column" className={classes.container}>
      <Typography variant="h5" className={classes.formHeading}>
        {t("User Breakpoint")}
      </Typography>
      <Typography
        variant="body1"
        className={classnames(
          commonClasses.commonDescription,
          classes.formField,
        )}
      >
        {t(
          "You can suspend your BPM. When your BPM reaches here, you will set the button to continue.",
        )}
      </Typography>
    </Grid>
  );
};

SignalEventPropertiesPanel.propTypes = {
  modelerState: PropTypes.object.isRequired,
  modelerActions: PropTypes.object.isRequired,
  moddle: PropTypes.object.isRequired,
};

export default SignalEventPropertiesPanel;
