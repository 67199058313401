import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import useApi from "msa2-ui/src/utils/useApi";
import usePopover from "msa2-ui/src/hooks/usePopover";

import { Button, CircularProgress, Typography } from "@material-ui/core";

import Repository from "msa2-ui/src/services/Repository";
import { getWorkflowDetails } from "msa2-ui/src/api/workflow";

const WorkflowReference = ({
  workflowPath,
  value,
  onSelect,
  token,
  ubiqubeId,
  showUniqueValuesInDropdown = false,
  selectedOptions = [],
  ...props
}) => {
  const { t } = useTranslation();

  const [, showAddPopover, AddPopover] = usePopover();

  const [loading, error, response] = useApi({
    apiCall: getWorkflowDetails,
    params: {
      ubiqubeId,
      workflowPath: decodeURIComponent(
        Repository.stripFileExtensionFromString(workflowPath),
      ),
    },
    wait: !workflowPath,
    token,
  });

  if (loading) {
    return <CircularProgress aria-label={t("Loading")} />;
  }
  if (error) {
    return <Typography>{t("Unable to load Workflow Instances.")}</Typography>;
  }
  if (!response) return null;

  const { instances } = response;
  if (isEmpty(instances)) {
    return <Typography>{t("No Workflow instance found.")}</Typography>;
  }

  const refList = response.instances.map(([serviceId, , displayName]) => ({
    id: serviceId,
    label: `${displayName} - ${serviceId}`,
  }));

  //If an option is selected, display corresponding label as button text
  const label =
    refList?.find(({ id }) => id === value)?.label ??
    (value ? `Unknown Instance - ${value}` : "Not Selected");

  //Filter workflow reference options to not show already selected values, when unique option is selected for WFreference lists
  let filteredReferenceList = refList;
  if (showUniqueValuesInDropdown) {
    filteredReferenceList = refList.filter(
      (wfRefItem) =>
        wfRefItem.id === value || !selectedOptions.includes(wfRefItem.id),
    );
  }

  return (
    <>
      <Button onClick={showAddPopover} {...props}>
        {label}
      </Button>
      {filteredReferenceList && (
        <AddPopover
          options={[{ id: "", label: "" }, ...filteredReferenceList]}
          onSelect={({ id }) => onSelect(id)}
          notFoundMessage={t("No workflow instances found")}
        />
      )}
    </>
  );
};

WorkflowReference.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  workflowPath: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  ubiqubeId: PropTypes.string.isRequired,
};

export default WorkflowReference;
