import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getFormValues } from "msa2-ui/src/store/form";

import Workflow from "msa2-ui/src/services/Workflow";
import Task from "msa2-ui/src/services/Task";
import Repository from "msa2-ui/src/services/Repository";

import { useTranslation } from "react-i18next";
import useApi from "msa2-ui/src/hooks/useApi";
import { readRepository } from "msa2-ui/src/api/repository";
import { getPythonSdk } from "msa2-ui/src/api/workflow";
import useDialog from "msa2-ui/src/hooks/useDialog";

import { makeStyles } from "@material-ui/core";
import {
  CircularProgress,
  Grid,
  Typography,
  Button,
  Hidden,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import InfoIcon from "@material-ui/icons/Info";
import TaskEditorKeyBindings from "./TaskEditorKeyBindings";

import EditorAce from "msa2-ui/src/components/EditorAce";
import TaskExecutor from "msa2-ui/src/routes/automation/workflows/edit/task-executor/TaskExecutor";
import { useBoundCallback } from "msa2-ui/src/hooks/useBoundCallbacks";

const TASK_EDITOR_HEADER_HEIGHT = 58;

const useStyles = makeStyles(({ darkMode, palette, typography }) => ({
  wrapper: {
    minHeight: 200,
    height: "100%",
  },
  taskEditorHeader: {
    minHeight: TASK_EDITOR_HEADER_HEIGHT,
    borderBottom: darkMode
      ? `1px solid ${palette.text.hint}`
      : `4px solid ${palette.text.secondary}`,
  },
  runButton: {
    marginTop: 13,
    marginBottom: 13,
    marginRight: 15,
  },
  saveButton: {
    marginTop: 13,
    marginBottom: 13,
    marginRight: 15,
  },
  deleteButton: {
    color: palette.error.main,
    marginTop: 13,
    marginBottom: 13,
    marginRight: 15,
  },
  iconButton: {
    marginTop: 13,
    marginBottom: 13,
    marginRight: 15,
    padding: 0,
  },
  deleteIcon: {
    marginRight: 5,
  },
  language: {
    padding: "20px 24px 16px 24px",
    fontWeight: typography.fontWeightMedium,
    color: palette.text.primary,
  },
  editorWrapper: {
    overflowY: "auto",
    height: "100%",
  },
  unsavedChanges: {
    padding: "20px 24px 16px 24px",
    fontWeight: typography.fontWeightMedium,
    color: palette.error.main,
  },
  "@global": {
    ".ace_autocomplete": {
      width: "750px !important",

      "& .ace_line": {
        fontSize: 11,
      },
    },
  },
}));

const TaskEditor = ({
  form,
  task,
  workflowProcess,
  isEditorUnsaved,
  setIsEditorUnsaved,
  saveContentsToFile,
  removeTaskFromForm,
  removeTaskFile,
  isFullScreenMode,
  onFullScreenMode,
}) => {
  const { t } = useTranslation();
  const {
    information: { serviceTaskType },
    metaInformationList: [{ parentURI }],
    process,
  } = useSelector(getFormValues(form));
  const uri = Task.getTaskUri(task);
  const isInternalTask = uri.includes(workflowProcess.name);
  const isSharingTask = process.some(({ tasks }) =>
    (tasks || [])
      .filter((_task) => _task !== task)
      .some(
        (_task) =>
          Task.getTaskUri(_task).replace(Repository.REPOSITORY_PREFIX, "") ===
          uri.replace(Repository.REPOSITORY_PREFIX, ""),
      ),
  );
  const canDelete = isInternalTask && !isSharingTask;

  const keyBindingsButtonRef = useRef(null);
  const [isKeyBindingsOpened, setIsKeyBindingsOpened] = useState(false);

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [showDeleteDialog, DeleteDialog] = useDialog();
  const [showConfirmSaveDialog, ConfirmSaveDialog] = useDialog();

  const [taskCode, setTaskCode] = useState("");
  const [isTaskExecutorShown, setIsTaskExecutorShown] = useState(false);

  const [loadingRepositoryData, , repositoryData] = useApi(readRepository, {
    uri,
  });

  const [loadingSdk, , sdkData = {}] = useApi(
    getPythonSdk,
    {},
    serviceTaskType !== Workflow.serviceTaskType.python.type,
  );

  const handleAutoComplete = (editor, session, pos, prefix, callback) => {
    const tokens = session.getTokens(pos.row);
    const rowAsString = tokens.reduce((acc, next) => {
      return acc + next.value;
    }, "");

    const matchContextWithMethods = rowAsString.match(/(\w+)\.\w*$/);
    if (matchContextWithMethods) {
      const contextName = matchContextWithMethods[1];
      const contextMethods = sdkData[contextName]?.methods || [];

      return callback(
        null,
        contextMethods.map((obj) => {
          const [methodName, methodMeta] = Object.entries(obj)[0];
          return {
            caption: methodName,
            value: methodName,
            meta: methodMeta.parameters,
          };
        }),
      );
    }

    const contexts = Object.keys(sdkData);
    return callback(
      null,
      contexts.map((context) => {
        return {
          caption: context,
          value: context,
        };
      }),
    );
  };
  const boundedHandleAutoComplete = useBoundCallback(handleAutoComplete);

  useEffect(() => {
    if (repositoryData) {
      setTaskCode(repositoryData.content);
      setIsEditorUnsaved(false);
    }
  }, [repositoryData, setIsEditorUnsaved]);

  useEffect(() => {
    // clear isEditorUnsaved flag when the component is destroyed
    return () => {
      setIsEditorUnsaved(false);
    };
  }, [setIsEditorUnsaved]);

  const taskType = Workflow.serviceTaskType[serviceTaskType].language;
  const onSave = () => {
    saveContentsToFile(taskCode, task, () => {
      setIsEditorUnsaved(false);
    });
  };
  const handleSaveClick = () => {
    if (uri.includes(parentURI)) {
      onSave();
    } else {
      showConfirmSaveDialog(true);
    }
  };

  return (
    <>
      {isTaskExecutorShown && (
        <TaskExecutor
          taskName={task.displayName}
          taskType={taskType}
          form={form}
          taskCode={taskCode}
          onClose={() => setIsTaskExecutorShown(false)}
        />
      )}
      <ConfirmSaveDialog title={t("Save Task?")} onExec={onSave}>
        <Typography>
          {t("You are about to change Task from outside of this Workflow.")}
        </Typography>
        <Typography>{t("Are you sure you want to save this Task?")}</Typography>
      </ConfirmSaveDialog>
      {canDelete ? (
        <DeleteDialog
          title={t("Delete Task?")}
          onExec={() => {
            removeTaskFile(task);
          }}
          execLabel={t("Detach and Delete")}
          extraAction={() => {
            removeTaskFromForm(task);
          }}
          extraLabel={t("Detach")}
        >
          <Typography>
            {t("Are you sure you want to delete this Task?")}
          </Typography>
          <Typography>{t("Choose Detach to keep Task File.")}</Typography>
        </DeleteDialog>
      ) : (
        <DeleteDialog
          title={t("Detach Task?")}
          onExec={() => {
            removeTaskFromForm(task);
          }}
        >
          <Typography>
            {t("Are you sure you want to detach this Task from Process?")}
          </Typography>
          <Typography>({t("Task file will be kept.")})</Typography>
        </DeleteDialog>
      )}
      <TaskEditorKeyBindings
        isOpen={isKeyBindingsOpened}
        onClose={() => setIsKeyBindingsOpened(false)}
        anchorRef={keyBindingsButtonRef}
      />
      <Grid container className={classes.wrapper}>
        <Grid
          container
          justifyContent={"space-between"}
          direction={"row"}
          className={classes.taskEditorHeader}
        >
          <Grid item xs={12} lg={2} xl={1}>
            <Typography variant="body1" className={classes.language}>
              {taskType}:
            </Typography>
          </Grid>
          <Hidden lgDown>
            <Grid item xl={6}>
              <TextField
                value={uri}
                label={t("Task path")}
                type="text"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            lg={10}
            xl={5}
            container
            direction={"row"}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Typography variant="body1" className={classes.unsavedChanges}>
                {isEditorUnsaved && t("UNSAVED CHANGES")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                id="TASK_EDITOR_TRY_BTN"
                variant="text"
                size="small"
                color="default"
                className={classes.runButton}
                disabled={!taskCode}
                onClick={() => setIsTaskExecutorShown(true)}
              >
                {t("Test Task")}
              </Button>
              <Button
                id="TASK_EDITOR_SAVE_BTN"
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSaveClick}
                className={classes.saveButton}
                disabled={!taskCode}
              >
                {t("Save Task")}
              </Button>
              <Button
                id="TASK_BTN_DELETE"
                variant="text"
                size="small"
                color="primary"
                className={classes.deleteButton}
                onClick={showDeleteDialog}
              >
                <DeleteIcon color="error" className={classes.deleteIcon} />
                {t("Delete Task")}
              </Button>
              <Tooltip
                title={
                  isFullScreenMode ? t("Restore Editor") : t("Maximize Editor")
                }
              >
                <IconButton
                  aria-label={
                    isFullScreenMode
                      ? t("Restore Editor size")
                      : t("Maximize Editor size")
                  }
                  id="TASK_BTN_FULLSCREEN"
                  className={classes.iconButton}
                  onClick={onFullScreenMode}
                >
                  {isFullScreenMode ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={t("View Keyboard Shortcuts")}>
                <IconButton
                  aria-label={t("View Keyboard Shortcuts")}
                  id="TASK_BTN_KEY_BINDINGS"
                  data-testid="TASK_BTN_KEY_BINDINGS"
                  ref={keyBindingsButtonRef}
                  className={classes.iconButton}
                  onClick={() => setIsKeyBindingsOpened(true)}
                >
                  <InfoIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.editorWrapper}>
          {loadingRepositoryData || loadingSdk ? (
            <Grid
              container
              className={commonClasses.commonLoaderWrapper}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <EditorAce
              height={
                isFullScreenMode ? window.innerHeight - 300 + "px" : "500px"
              }
              mode={
                EditorAce.mode[Workflow.serviceTaskType[serviceTaskType].type]
              }
              options={{
                enableBasicAutocompletion: [
                  { getCompletions: boundedHandleAutoComplete },
                ],
                enableLiveAutocompletion: true,
                showInvisibles: true,
              }}
              value={taskCode}
              onChange={(value) => {
                setTaskCode(value);
                if (value !== repositoryData?.content) {
                  setIsEditorUnsaved(true);
                }
              }}
              onComplete={handleAutoComplete}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

TaskEditor.propTypes = {
  form: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  isEditorUnsaved: PropTypes.bool.isRequired,
  setIsEditorUnsaved: PropTypes.func.isRequired,
  removeTaskFromForm: PropTypes.func.isRequired,
  saveContentsToFile: PropTypes.func.isRequired,
  isFullScreenMode: PropTypes.bool,
  onFullScreenMode: PropTypes.func.isRequired,
};

export default TaskEditor;
