import {
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
} from "date-fns";

export const DAYS = {
  MONDAY: { name: "monday", label: "Monday", index: 0 },
  TUESDAY: { name: "tuesday", label: "Tuesday", index: 1 },
  WEDNESDAY: { name: "wednesday", label: "Wednesday", index: 2 },
  THURSDAY: { name: "thursday", label: "Thursday", index: 3 },
  FRIDAY: { name: "friday", label: "Friday", index: 4 },
  SATURDAY: { name: "saturday", label: "Saturday", index: 5 },
  SUNDAY: { name: "sunday", label: "Sunday", index: 6 },
};

export const MONTHS = {
  JANUARY: { name: "january", label: "January", index: 0 },
  FEBRUARY: { name: "february", label: "February", index: 1 },
  MARCH: { name: "march", label: "March", index: 2 },
  APRIL: { name: "april", label: "April", index: 3 },
  MAY: { name: "may", label: "May", index: 4 },
  JUNE: { name: "june", label: "June", index: 5 },
  JULY: { name: "july", label: "July", index: 6 },
  AUGUST: { name: "august", label: "August", index: 7 },
  SEPTEMBER: { name: "september", label: "September", index: 8 },
  OCTOBER: { name: "october", label: "October", index: 9 },
  NOVEMBER: { name: "november", label: "November", index: 10 },
  DECEMBER: { name: "december", label: "December", index: 11 },
};

export const PERIODICITY = {
  once: "once",
  minute: "minute",
  hourly: "hour",
  daily: "day",
  weekly: "week",
  monthly: "month",
};

export const PERIODICITY_VALUES = [
  {
    value: PERIODICITY.once,
    label: "Once",
    postfix: "",
    hasValue: false,
    hasStartDate: true,
    hasEndDate: false,
    hasDayPicker: false,
    hasMonthPicker: false,
  },
  {
    value: PERIODICITY.minute,
    label: "Minute",
    postfix: "minute(s)",
    hasValue: true,
    hasStartDate: true,
    hasEndDate: true,
    hasDayPicker: false,
    hasMonthPicker: false,
    add: addMinutes,
    diff: differenceInMinutes,
    durationDesignator: "PT",
    designator: "M",
  },
  {
    value: PERIODICITY.hourly,
    label: "Hourly",
    postfix: "hour(s)",
    hasValue: true,
    hasStartDate: true,
    hasEndDate: true,
    hasDayPicker: false,
    hasMonthPicker: false,
    add: addHours,
    diff: differenceInHours,
    durationDesignator: "PT",
    designator: "H",
  },
  {
    value: PERIODICITY.daily,
    label: "Daily",
    postfix: "day(s)",
    hasValue: true,
    hasStartDate: true,
    hasEndDate: true,
    hasDayPicker: false,
    hasMonthPicker: false,
    add: addDays,
    diff: differenceInDays,
    durationDesignator: "P",
    designator: "D",
  },
  {
    value: PERIODICITY.weekly,
    label: "Weekly",
    postfix: "week(s)",
    hasValue: true,
    hasStartDate: true,
    hasEndDate: true,
    hasDayPicker: true,
    hasMonthPicker: false,
    add: addWeeks,
    diff: differenceInWeeks,
    durationDesignator: "P",
    designator: "W",
  },
  {
    value: PERIODICITY.monthly,
    label: "Monthly",
    postfix: "of the month",
    hasValue: true,
    hasStartDate: true,
    hasEndDate: true,
    hasDayPicker: false,
    hasMonthPicker: true,
    add: addMonths,
    diff: differenceInMonths,
    durationDesignator: "P",
    designator: "M",
  },
];
