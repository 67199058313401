import React from "react";
import PropTypes from "prop-types";
import { Badge, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ colors, typography }) => ({
  badgeRoot: {
    left: -10,
  },
  badgeInside: {
    color: "#fff",
    fontSize: "0.6875rem",
    fontWeight: typography.fontWeightMedium,
    background: colors.orange,
  },
}));

const MenuItemNotification = ({ content }) => {
  const classes = useStyles();
  return (
    <Badge
      badgeContent={content}
      children={""}
      classes={{
        root: classes.badgeRoot,
        badge: classes.badgeInside,
      }}
    />
  );
};

MenuItemNotification.propTypes = {
  content: PropTypes.node,
};

export default MenuItemNotification;
