import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getSettingsTheme, systemThemeId } from "msa2-ui/src/store/settings";
import { getMsaTheme, setMsaTheme } from "msa2-ui/src/store/designations";
import { useThemeDetector } from "msa2-ui/src/hooks/useThemeDetector";
import createTheme from "./styles/theme";

const MsaThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const settingsTheme = useSelector(getSettingsTheme);
  const msaTheme = useSelector(getMsaTheme);
  const systemTheme = useThemeDetector();

  if (settingsTheme === systemThemeId) {
    dispatch(setMsaTheme(Number(systemTheme)));
  } else {
    dispatch(setMsaTheme(Number(settingsTheme)));
  }

  return (
    <ThemeProvider theme={createTheme(msaTheme)}>{children}</ThemeProvider>
  );
};

export default MsaThemeProvider;
