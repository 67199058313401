/**
 * COMPONENTS
 */
import createComponent from "./createComponent";

import mapProps from "./mapProps";
import { TextField as MUITextField } from "@material-ui/core";

const Textfield = createComponent(MUITextField, mapProps);

export default Textfield;
