import React, { memo } from "react";
import FieldArray from "redux-form/lib/FieldArray";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import { useTranslation } from "react-i18next";
import { formArrayTypes } from "./constants";
import GraphList from "./GraphList";

const GraphSection = () => {
  const { t } = useTranslation();

  return (
    <FormSection title={t("Graphical Rendering")}>
      <FieldArray name={formArrayTypes.graph} component={GraphList} />
    </FormSection>
  );
};

export default memo(GraphSection);
