import React, { useState } from "react";
import PropTypes from "prop-types";
import InitialProvisioningDialog from "msa2-ui/src/routes/integration/managed-entities/detail/InitialProvisioningDialog";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { MenuItem } from "msa2-ui/src/components/menu";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";

const ActivateAction = ({ node, onChange }) => {
  const t = useBoundedTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const token = useSelector(getToken);

  return (
    <>
      <InitialProvisioningDialog
        isOpen={isDialogOpen}
        token={token}
        managedEntity={{ deviceID: node.__metadata__.objectId }}
        onCloseHandler={() => setIsDialogOpen(false)}
        onCompleteHandler={onChange}
      />
      <MenuItem onClick={() => setIsDialogOpen(true)} id="ACTIVATE_ACTION">
        {t("Activate")}
      </MenuItem>
    </>
  );
};

ActivateAction.propTypes = {
  node: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ActivateAction;
