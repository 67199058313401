import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import stripAnsi from "strip-ansi";

// REDUX
import { connect } from "react-redux";

// MODULES
import { withTranslation } from "react-i18next";
import flow from "lodash/flow";

// MATERIAL UI
import { withStyles } from "@material-ui/core";
import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import {
  displayUserFriendlyDuration,
  displayYearMonthTimeDateAsES,
  formatDateOrString,
} from "msa2-ui/src/utils/date";
import { getWorkflowInstanceHistory } from "msa2-ui/src/api/workflow";
import { workflowStatus } from "msa2-ui/src/Constants";
import Process from "msa2-ui/src/services/Process";
import { EXTERNAL_REFERENCE_PREFIX } from "msa2-ui/src/services/Bpm";

// STYLES
import commonStyles from "msa2-ui/src/styles/commonStyles";

// COMPONENTS
import AlertBar from "msa2-ui/src/components/AlertBar";
import Tooltip from "msa2-ui/src/components/TooltipWithLineBreaks";

import AutomationProcessExecutionDialog from "./AutomationProcessExecutionDialog";
import TablePagination from "msa2-ui/src/components/TablePagination";
import ClipboardIcon from "msa2-ui/src/components/ClipboardIcon";

const localStyles = (theme) => ({
  processName: {
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: 0,
  },
  processWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  processLineWrapper: {
    paddingBottom: 5,
  },
  removedProcess: {
    color: theme.colors.red,
  },
  taskComment: {
    maxWidth: "inherit",
  },
  processTypeWrapper: {
    padding: "1px 10px 1px 10px",
  },
  loaderWrapper: {
    padding: 10,
    textAlign: "center",
  },
});
const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

class AutomationDetailsTabHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertBar: false,
      isLoading: true,
      selected: "",
      subtenantsDialog: "",
      rowsPerPage: 10,
      page: 0,
      count: 0,
    };
  }

  // life cycle methods
  componentDidMount() {
    // don't call API when CREATE process is executed as no instanceId
    this.props.instanceId && this.getValFromApi();
  }

  componentDidUpdate(prevProps) {
    !prevProps.open && this.props.open && this.getValFromApi();
  }

  getAutomationStatus = () => {
    if (this.state.isLoading) return "loading";
    if (this.state.alertBar) return "error";
    if (this.state.apiResponse === 0) return "noResults";
    return "ready";
  };
  // functions for API
  getValFromApi = async () => {
    const { token, instanceId, setTotal } = this.props;
    const [
      apiError,
      apiResponse,
      apiMeta = {},
    ] = await getWorkflowInstanceHistory({
      instanceId,
      token,
      page: this.state.page,
      rowsPerPage: this.state.rowsPerPage,
    });

    const { totalcount = 0 } = apiMeta;

    if (apiError) {
      return this.setState({
        apiError,
        alertBar: true,
        isLoading: false,
        notification: false,
      });
    }
    this.setState({ apiResponse, count: Number(totalcount), isLoading: false });
    setTotal(totalcount);
  };

  reload = (addState) => {
    this.handleCloseAlertBar();
    this.setState(
      // merge objects
      Object.assign(
        {
          apiError: undefined,
          isLoading: true,
          apiResponse: undefined,
        },
        addState,
      ),
      () => {
        this.getValFromApi();
      },
    );
  };

  handleCloseAlertBar = () => {
    this.setState({ alertBar: false });
  };

  // functions for dialogs
  handleDialogOpen = (key) => {
    this.setState({ dialog: key });
  };

  handleDialogClose = () => {
    this.setState({ dialog: "" });
  };

  // functions for tables
  handleSelectRow = (key) => {
    this.setState({ selected: key });
  };

  handleDeselectRow = () => {
    this.setState({ selected: "" });
  };

  onChangePage = (e, value) => {
    this.setState({ page: value }, () => this.reload());
  };

  onChangeRowsPerPage = (e) => {
    this.setState(
      {
        rowsPerPage: e.target.value,
        page: Math.floor(
          (this.state.page * this.state.rowsPerPage) / e.target.value,
        ),
      },
      () => this.reload(),
    );
  };

  render() {
    const { classes, t, open, processes } = this.props;
    const { apiResponse } = this.state;
    if (!open) {
      return null;
    }
    return (
      <Fragment>
        <Paper className={`${classes.commonPaper} ${classes.paper}`}>
          <TablePagination
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            count={this.state.count}
            onChangePage={this.onChangePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
          />
          {this.getAutomationStatus() === "loading" && (
            <div className={classes.loaderWrapper}>
              <CircularProgress />
            </div>
          )}
          {this.getAutomationStatus() === "error" && (
            // after loading - error case
            <AlertBar
              id="AUTOMATION_DETAILS_TAB_HISTORY_ALERT_BAR_FAILED_TO_LOAD"
              message={t("Unable to load x", { x: t("Workflows") })}
              refreshFnc={this.reload}
              closeFnc={this.handleCloseAlertBar}
            />
          )}
          {this.getAutomationStatus() === "ready" &&
            // after loading
            apiResponse.map((prc, i) => {
              const processId = prc.processId.id;
              const serviceId = prc.serviceId.id;
              const processDef = Process.getDefinition(
                processes,
                prc.processId.name,
              );
              const startingDate = prc?.status?.startingDate;
              const endingDate = prc?.status?.endingDate;
              const processReference = prc?.status?.processReference;
              const ProcessIcon = Process.getIcon(processDef?.type);
              const processStatus = workflowStatus.find(
                (arr) => arr.status === prc.status.status,
              );
              const ProcessStatusIcon = processStatus.iconBig;
              const displayName = Process.getNameFromPath(
                processes,
                prc.processId.name,
              );
              const instanceIsOrphaned = Process.instanceIsOrphaned(
                processes,
                prc.processId.name,
              );

              return (
                <Fragment key={processId}>
                  {/* dialog, this is available after clicking "details" button */}
                  {this.state.dialog === processId && (
                    <AutomationProcessExecutionDialog
                      open
                      onClose={this.handleDialogClose}
                      processId={processId}
                      serviceId={serviceId}
                      processName={processDef.displayName}
                      processIcon={ProcessIcon}
                    />
                  )}
                  <Grid
                    id={`AUTOMATION_DETAILS_TAB_HISTORY_SELECT_ROW_${processId}`}
                    container
                    onMouseLeave={this.handleDeselectRow}
                    onMouseEnter={() => this.handleSelectRow(processId)}
                    onClick={() => this.handleSelectRow(processId)}
                    className={[
                      classes.commonTableRowHighlight,
                      classes.processWrapper,
                    ].join(" ")}
                  >
                    <Grid
                      item
                      xs={1}
                      className={`${classes.commonFlexVerticalStart} ${classes.processTypeWrapper}`}
                    >
                      {!instanceIsOrphaned && <ProcessStatusIcon />}
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container className={classes.processLineWrapper}>
                        <Grid item xs={8}>
                          <Typography
                            variant="h4"
                            className={classes.processName}
                          >
                            {displayName}
                          </Typography>
                          {instanceIsOrphaned && (
                            <Typography
                              variant="body2"
                              className={classes.removedProcess}
                            >
                              [{t("Removed Process")}]
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={4} className={classes.commonFlexEnd}>
                          {processId === this.state.selected &&
                            !instanceIsOrphaned && (
                              <Typography
                                variant="h3"
                                className={classes.commonLink}
                                onClick={() => this.handleDialogOpen(processId)}
                              >
                                {t("Details")}
                              </Typography>
                            )}
                        </Grid>
                      </Grid>
                      <Grid container className={classes.processLineWrapper}>
                        <Grid item xs={12} className={classes.commonFlexStart}>
                          <Typography
                            variant="body1"
                            className={classes.commonDescription}
                          >
                            {prc.executorUsername}
                            {processReference?.startsWith(
                              EXTERNAL_REFERENCE_PREFIX,
                            ) && t(" (via BPM)")}
                          </Typography>
                          <span className={classes.commonSpacer}>{"|"}</span>
                          <Typography
                            variant="body1"
                            className={classes.commonDescription}
                          >
                            {startingDate
                              ? formatDateOrString(
                                  displayYearMonthTimeDateAsES(startingDate) +
                                    "+0000",
                                  "MMM dd, yyyy h:mm:ssaa",
                                )
                              : ""}
                          </Typography>
                          <span className={classes.commonSpacer}>{"-"}</span>
                          <Typography
                            variant="body1"
                            className={classes.commonDescription}
                          >
                            {endingDate
                              ? formatDateOrString(
                                  displayYearMonthTimeDateAsES(endingDate) +
                                    "+0000",
                                  "MMM dd, yyyy h:mm:ssaa",
                                )
                              : t("Running")}
                          </Typography>
                          {startingDate && endingDate && (
                            <Fragment>
                              <span className={classes.commonSpacer} />
                              <Typography
                                variant="body1"
                                className={classes.commonDescription}
                              >
                                {`(${displayUserFriendlyDuration(
                                  startingDate,
                                  endingDate,
                                )})`}
                              </Typography>
                            </Fragment>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Tooltip title={stripAnsi(prc.status.details)}>
                            <Typography
                              variant="body1"
                              className={`${classes.commonDescription} ${classes.commonTwoLineWrapper} ${classes.taskComment}`}
                            >
                              {stripAnsi(prc.status.details)}
                            </Typography>
                          </Tooltip>
                          {processId === this.state.selected && (
                            <ClipboardIcon
                              content={stripAnsi(prc.status.details)}
                              item={t("Message")}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {apiResponse.length - 1 !== i && <Divider />}
                </Fragment>
              );
            })}
        </Paper>
      </Fragment>
    );
  }
}
AutomationDetailsTabHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  instanceId: PropTypes.string,
  setTotal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  processes: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default flow(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation(),
)(AutomationDetailsTabHistory);
