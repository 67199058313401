import React from "react";

/**
 * MATERIAL UI
 */
import { withStyles } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";

/**
 * REDUX
 */
import { connect } from "react-redux";
import {
  changeLanguage,
  getLanguage,
  SETTINGS,
} from "msa2-ui/src/store/settings";

/**
 * LANGUAGES
 */
import { withTranslation } from "react-i18next";

import { Button, List, ListItem, Popover } from "@material-ui/core";

const styles = (theme) => ({
  typography: {
    margin: theme.spacing(2),
  },
  wrapper: {
    top: 15,
    left: 15,
    position: "absolute",
  },
  button: {
    background: "#e9eaec",
  },
});

class LanguageController extends React.Component {
  state = {
    open: false,
    anchorEl: null,
    languages: SETTINGS.language.options,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  };

  handleChangeLanguage = (event) => {
    const lng = event.target.innerHTML.toString().trim();
    this.props.changeLanguage(lng);
    SETTINGS.language.onChange(lng);

    this.setState({
      anchorEl: false,
    });

    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  };

  render() {
    const { t, classes } = this.props;
    const { anchorEl, open } = this.state;
    const LanguageList = this.state.languages.map((language, index) => {
      return (
        <ListItem
          key={index}
          value={language}
          onClick={this.handleChangeLanguage}
          style={{ cursor: "pointer" }}
        >
          {" "}
          {language}
        </ListItem>
      );
    });

    return (
      <div className={classes.wrapper}>
        <Button
          aria-owns={open ? "simple-popper" : undefined}
          aria-haspopup="true"
          variant="contained"
          className={classes.button}
          aria-label={t("Change language")}
          onClick={this.handleClick}
        >
          <LanguageIcon />
        </Button>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>{LanguageList}</List>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: getLanguage(state),
});

const mapDispatchToProps = {
  changeLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withTranslation()(LanguageController)));
