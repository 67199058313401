import { get, destroy, put, post } from "./request";

const API = process.env.REACT_APP_API_PATH;

/**
 * We currently expect a deploymentSetting to be assigned to one
 * subtenant on the UI. The DB has a many to many relationship so
 * we alter it to suit our model
 *
 * @param {apiResponse} - API response
 */
const subtenantArrayToValue = ({ customerIds, ...rest }) => ({
  ...rest,
  subtenantId: customerIds[0],
});

/**
 * The API response for a deploymentSetting contains a microserviceUris object.
 * We normalize this object for convenience in the UI: after this transform,
 * the deploymentSetting has a flat array of microserviceUris and an object
 * containing the microservice data by URI.
 *
 * @param {apiResponse} - API response containing a deploymentSetting object
 */
const normalizeMicroserviceUris = (apiResponse) => {
  const originalMicroserviceUrisObject = apiResponse.microserviceUris ?? {};

  return {
    ...apiResponse,
    microservicesByUri: originalMicroserviceUrisObject,
    microserviceUris: Object.keys(originalMicroserviceUrisObject),
  };
};

export const buildSortParam = ({ sort, sortBy, separator = ":" }) => {
  return sort && sortBy ? `${sortBy}${separator}${sort}` : null;
};

//we need this method because currently we cant sort by  microservices and managed entites
export const getSortBy = (sortBy) => {
  if (sortBy === "name" || sortBy === "vendor" || sortBy === "mod_date") {
    return sortBy;
  }
  return "";
};

/*
 * Spec: https://10.31.1.52/swagger/#/ConfProfile/deleteDeploymentSetting
 */
export const deleteDeploymentSetting = ({ token, profileId }) => {
  return destroy({
    url: `${API}/conf-profile/v2/${profileId}`,
    token,
  });
};

/**
 * The API returns an array of deploymentSettings, and each deploymentSetting has
 * a microserviceUris object. We normalize this object for convenience
 * in the UI: after this transform, each deploymentSetting has a flat array
 * of microserviceUris and an object containing the microservice data by URI.
 *
 * @param {apiResponse} API response containing an array of deploymentSettings
 */
const normalizeMicroserviceUrisForEachDeploymentSetting = (apiResponse) => {
  return apiResponse.map((deploymentSetting) => {
    const originalMicroserviceUrisObject =
      deploymentSetting.microserviceUris || {};

    return {
      ...deploymentSetting,
      microservicesByUri: originalMicroserviceUrisObject,
      microserviceUris: Object.keys(originalMicroserviceUrisObject),
    };
  });
};

export const getDeploymentSetting = ({
  token,
  deploymentSettingId,
  filterByLabel,
  order = "ALPHA",
  transforms = [],
}) => {
  return get({
    url: `${API}/conf-profile/v2/${deploymentSettingId}`,
    queryParams: {
      filterByLabel,
      order,
    },
    token,
    transforms: [
      subtenantArrayToValue,
      normalizeMicroserviceUris,
      ...transforms,
    ],
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/ConfProfile/findDeploymentSettingsByCustomer
 */
export const getDeploymentSettingsBySubtenant = ({
  subtenantId,
  token,
  sortBy,
  sortOrder,
  page,
  pageSize: page_size,
  transforms = [normalizeMicroserviceUrisForEachDeploymentSetting],
  filterCriteria: filter_criteria,
  filter = "name",
}) => {
  return get({
    url: `${API}/conf-profile/v2/list/customer/${subtenantId}`,
    queryParams: {
      sort_by: buildSortParam({
        sortBy: getSortBy(sortBy),
        sort: sortOrder,
        separator: "_",
      }),
      page,
      page_size,
      filter: filter_criteria ? filter : undefined,
      filter_criteria: filter_criteria || undefined,
    },
    token,
    transforms,
  });
};

export const getDeploymentSettings = ({
  type,
  id,
  token,
  sortBy,
  page,
  pageSize,
  sortOrder,
  filter,
  searchTerm: filter_criteria,
}) => {
  if (type === "subtenant") {
    return getDeploymentSettingsBySubtenant({
      subtenantId: id,
      token,
      sortBy: buildSortParam({
        sortBy: getSortBy(sortBy),
        sort: sortOrder,
        separator: "_",
      }),
      page,
      pageSize,
      filter,
      filter_criteria,
    });
  }

  return [undefined, undefined, undefined];
};

export const initBodyDeploymentSetting = {
  id: null,
  name: "",
  externalReference: "",
  comment: "",
  model: {
    id: "",
    name: "",
  },
  vendor: {
    id: "",
    name: "",
  },
  microserviceUris: [],
  templateUris: [],
  attachedManagedEntities: [],
};

/**
 * Spec: https://10.30.18.86/swagger/#/ConfProfile/updateDeploymentSettings
 */
export const updateDeploymentSetting = ({
  token,
  profile_id,
  subtenantId: customer_id,
  body,
  transforms = [],
}) => {
  return put({
    url: `${API}/conf-profile/v2/${profile_id}`,
    queryParams: {
      customer_id,
    },
    token,
    body: {
      ...initBodyDeploymentSetting,
      ...body,
    },
    transforms,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/ConfProfile/createEmptyConfProfileV2
 */
export const createDeploymentSetting = ({
  token,
  subtenantId,
  body,
  transforms = [],
}) => {
  return post({
    url: `${API}/conf-profile/v2/${subtenantId}`,
    token,
    body: {
      ...initBodyDeploymentSetting,
      ...body,
    },
    transforms,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/ConfProfile/attachMicroserviceToMultiPlf
 */
export const addDeploymentSettingsToMicroservice = ({ token, body }) => {
  return post({
    url: `${API}/conf-profile/v2/attach-ms`,
    token,
    body,
  });
};
