import React from "react";
import { makeStyles } from "@material-ui/core";

import createComponent from "./createComponent";
import mapProps from "./mapProps";

import {
  Radio as MUIRadio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  radioGroup: (styleProps) => ({
    flexDirection: styleProps.flexdirection,
  }),
}));

export const BasicRadio = ({
  value,
  onChange,
  label,
  options,
  className,
  name,
  error,
  required,
  helperText,
  disabled,
  ...props
}) => {
  const classes = useStyles({ flexdirection: props.flexdirection ?? "row" });
  return (
    <FormControl
      variant="outlined"
      required={required}
      className={className}
      error={error}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        className={classes.radioGroup}
        value={value}
        onChange={onChange}
        {...props}
      >
        {options.map((option, i) => (
          <Tooltip key={i} title={option.title ?? ""}>
            <FormControlLabel
              id={option.id}
              value={option.value}
              control={<MUIRadio color="primary" />}
              label={option.displayName}
              disabled={disabled || option.disabled}
            />
          </Tooltip>
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const Radio = createComponent(
  BasicRadio,
  ({
    input: { onChange, value, ...inputProps },
    onChange: onChangeFromField,
    options,
    ...props
  }) => ({
    ...mapProps(props),
    ...inputProps,
    value: value || options[0],
    options,
    onChange: (event, value) => {
      onChange(value);
      if (onChangeFromField) {
        onChangeFromField(value);
      }
    },
  }),
);

export default Radio;
