import React from "react";
import classnames from "classnames";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getIsDeveloper, getUserRole } from "msa2-ui/src/store/auth";
import cloneDeep from "lodash/cloneDeep";
import { compareUrlByDepth } from "msa2-ui/src/utils/urls";

const useStyles = makeStyles((theme) => {
  const { darkMode, palette } = theme;

  return {
    listItemMinimized: {
      borderRadius: "30px 0 0 30px",
      backgroundColor: darkMode
        ? palette.background.default
        : theme.colors.greyLight3,
    },
    listItemIcon: {
      marginRight: 10,
      color: darkMode ? palette.text.hint : "#c7cdd8",
    },
    listItemText: {
      fontSize: "0.813rem",
      color: darkMode ? palette.text.hint : "#c7cdd8",
      margin: "0",
      padding: "0",
    },
    sidebarLinks: {
      textDecoration: "none",
      color: "#fff",
      display: "block",
    },
    sidebarBtn: {
      padding: 18,
      fontSize: "12px",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    sidebarLinksActive: {
      padding: 0,
      textDecoration: "none",
      "& $sidebarBtn": {
        margin: 0,
        background: darkMode
          ? palette.background.default
          : theme.colors.greyLight3,
        borderRadius: "30px 0 0 30px",
      },
      "& $listItemText": {
        color: darkMode ? palette.text.primary : theme.colors.blueDark1,
        fontSize: "1rem",
        fontWeight: 500,
      },
      "& $listItemIcon": {
        color: darkMode ? palette.primary.main : theme.colors.blueDark1,
      },
    },
    sidebarLinksActiveMinimized: {
      padding: 0,
      "& $sidebarBtn": {
        margin: 0,
      },
      "& $listItemText": {
        color: darkMode ? palette.text.primary : theme.colors.blueDark1,
        fontSize: "1rem",
        fontWeight: 500,
      },
      "& $listItemIcon": {
        color: darkMode ? theme.colors.white : theme.colors.blueDark1,
      },
    },
    menuDivider: {
      background: "#c7cdd8",
      opacity: darkMode ? 0 : 0.2,
    },
    childItemsContainer: {
      marginTop: -4,
      marginLeft: 20,
      paddingTop: 4,
    },
    childItemsContainerMinimized: {
      marginLeft: 15,
    },
    childItemsContainerOpen: {
      borderBottom: `3px solid ${palette.background.default}`,
      borderLeft: `3px solid ${palette.background.default}`,
      borderRadius: "0 0 0 34px",
    },
    childItem: {
      position: "relative",
      marginTop: 2,
    },
    navigationLink: {
      position: "absolute",
      top: -30,
      left: 10,
      height: 62,
      width: 2,
      marginRight: -4,
      backgroundColor: darkMode ? palette.text.hint : "#c7cdd8",
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        bottom: 0,
        left: 2,
        width: 4,
        height: 2,
        backgroundColor: darkMode ? palette.text.hint : "#c7cdd8",
      },
    },
    navigationLinkFirst: {
      top: -18,
      height: 50,
    },
    navigationLinkActive: {
      backgroundColor: darkMode ? theme.colors.white : theme.colors.blueDark1,
    },
    navigationLinkSelected: {
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        bottom: 0,
        left: 2,
        width: 4,
        height: 2,
        backgroundColor: darkMode ? theme.colors.white : theme.colors.blueDark1,
      },
    },
  };
});

const MainMenuItem = ({ minimized, item }) => {
  const delegationProfiles = useSelector(
    ({ delegationProfiles }) => delegationProfiles,
  );
  const isDeveloper = useSelector(getIsDeveloper);
  const userRole = useSelector(getUserRole);
  const classes = useStyles();
  const { hasChildItems = false, childItems = [] } = item;

  const { pathname: currentPath } = useLocation();

  const availableChildItems = childItems.filter(({ canAccess }) =>
    canAccess({ isDeveloper, userRole, delegationProfiles }),
  );
  const open = currentPath.includes(item.route);
  const activeRoute = cloneDeep(availableChildItems)
    .sort(({ route: pRoute }, { route }) => compareUrlByDepth(pRoute, route))
    .find(({ route }) => currentPath.includes(item.route + route));
  const activeRouteIndex = availableChildItems.findIndex(
    (item) => item.route === activeRoute?.route,
  );

  const renderListItem = (item) => {
    const { badgeComponent: BadgeComponent } = item;
    return (
      <ListItem button className={classes.sidebarBtn}>
        <ListItemIcon className={classes.listItemIcon}>
          <item.icon />
        </ListItemIcon>
        {!minimized && (
          <ListItemText
            primary={item.label(isDeveloper)}
            classes={{
              root: classes.listItemText,
              primary: classes.listItemText,
            }}
          />
        )}
        {BadgeComponent && <BadgeComponent />}
      </ListItem>
    );
  };

  return hasChildItems ? (
    <li
      className={classnames(null, {
        [classes.listItemMinimized]: minimized && open,
      })}
      key={item.label(isDeveloper)}
    >
      <NavLink
        id={item.id}
        to={item.route}
        className={classes.sidebarLinks}
        activeClassName={classes.sidebarLinksActive}
      >
        <div
          className={classnames(classes.sidebarLinks, {
            [classes.sidebarLinksActive]: open,
          })}
        >
          {renderListItem(item)}
        </div>
      </NavLink>
      {!open && <Divider className={classes.menuDivider} />}
      <div
        className={classnames(classes.childItemsContainer, {
          [classes.childItemsContainerOpen]: open,
          [classes.childItemsContainerMinimized]: minimized,
        })}
      >
        {open &&
          availableChildItems.map((cItem, index) => (
            <div key={cItem.id} className={classes.childItem}>
              {minimized && (
                <div
                  className={classnames(classes.navigationLink, {
                    [classes.navigationLinkFirst]: index === 0,
                    [classes.navigationLinkActive]: activeRouteIndex >= index,
                    [classes.navigationLinkSelected]:
                      activeRouteIndex === index,
                  })}
                />
              )}
              <NavLink
                id={cItem.id}
                to={item.route + cItem.route}
                className={classnames(null, {
                  [classes.sidebarLinks]: activeRouteIndex !== index,
                  [classes.sidebarLinksActive]:
                    activeRouteIndex === index && !minimized,
                  [classes.sidebarLinksActiveMinimized]:
                    activeRouteIndex === index && minimized,
                })}
              >
                {renderListItem(cItem)}
              </NavLink>
            </div>
          ))}
      </div>
    </li>
  ) : (
    <li key={item.label(isDeveloper)}>
      <NavLink
        id={item.id}
        to={item.route}
        className={classes.sidebarLinks}
        activeClassName={classes.sidebarLinksActive}
      >
        {renderListItem(item)}
      </NavLink>
      <Divider className={classes.menuDivider} />
    </li>
  );
};

export default MainMenuItem;
