import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  removeESUnescapableCharacters,
  makePartialMatchQuery,
} from "msa2-ui/src/utils/elasticsearch";
import useFilter from "msa2-ui/src/hooks/useFilter";

import { getLogs } from "msa2-ui/src/api/logs";
import useApi from "msa2-ui/src/hooks/useApi";
import {
  getAvailableSubtenants,
  getSelectedSubtenant,
} from "msa2-ui/src/store/designations";

import {
  useTheme,
  useMediaQuery,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import LogsFilterMenu from "./LogsFilterMenu";
import LogsTableBody from "./LogsTableBody";
import { TABLE_HEADER_COLUMNS } from "msa2-ui/src/Constants";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";
import { setFilterValue, getFilters } from "msa2-ui/src/store/ui";
import { displayYearMonthDayHourMinuteSecond } from "msa2-ui/src/utils/date";
import LogsFilterLabels from "./LogsFilterLabels";

/**
 * ElasticSearch uses a from parameter in combination with the page size to determine
 * which search results to show. The from parameter is just the offset from the first result
 * at index 0
 *
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/run-a-search.html#paginate-search-results
 *
 * @param {Number} page - the page number has determined by the pagination component
 * @param {Number} rowsPerPage - the number of logs to show on any given page
 */
const getElasticSearchOffset = (page, rowsPerPage) => page * rowsPerPage;

export default ({ sectionTabs: SectionTabs, createAlarmView }) => {
  const namespace = "logs";
  const commonClasses = useCommonStyles();
  const { t } = useTranslation(namespace);
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { [namespace]: TABLE_HEADER_COLUMNS_LOGS } = TABLE_HEADER_COLUMNS;

  const [tableFilterState, tableFilterActions] = useFilter({
    tableName: namespace,
    tpPage: 0,
    flag1: false,
  });
  const {
    tpRowsPerPage: rowsPerPage,
    columns: checkedColumnIds,
    flag1: isESQueryMode,
  } = tableFilterState;

  const { breakpoints } = useTheme();
  const isWidthSmall = useMediaQuery(breakpoints.down("md"));
  const columns = TABLE_HEADER_COLUMNS_LOGS.filter(
    ({ onlyOnWideScreen }) => !(onlyOnWideScreen && isWidthSmall),
  ).map((column) => ({
    ...column,
    check: checkedColumnIds.includes(column.id) ? true : undefined,
  }));
  const checkedColumns = columns.filter((entry) => entry.check);
  const tableCellLength = checkedColumns.length;

  const logFilters = useSelector(getFilters(namespace));
  const changeDatetoES = (date) => {
    return date !== null
      ? displayYearMonthDayHourMinuteSecond(
          date.getTime() + date.getTimezoneOffset() * 60000,
        )
      : "";
  };

  const [searchValue, setSearchValue] = useState(logFilters.searchValue);
  const [severities, setSeverities] = useState(logFilters.severities);
  const [logType, setLogType] = useState(logFilters.logType);
  const [subtenantIds, setSubtenantIds] = useState([]);
  const subtenantsListIds = useSelector(getAvailableSubtenants).map(
    (ids, index) => ids.id,
  );
  const subtenantsIds =
    subtenantsListIds.length !== 0 ? subtenantsListIds : [0];
  const { id: subtenantId } = useSelector(getSelectedSubtenant) ?? "";
  const subtenants = subtenantId !== null ? [subtenantId] : subtenantsIds;
  const [managedEntityIds, setManagedEntityIds] = useState(
    logFilters.managedEntityIds.map(({ id }) => id),
  );
  const [endDate, setEndDate] = useState(logFilters.endDate);
  const [endDateToES, setEndDateToES] = useState(
    changeDatetoES(logFilters.endDate),
  );
  const [startDate, setStartDate] = useState(logFilters.startDate);
  const [startDateToES, setStartDateToES] = useState(
    changeDatetoES(logFilters.startDate),
  );
  const [page, setPage] = useState(0);
  const autoRefresh = useSelector(getAutoRefreshSetting("alarms"));
  const [searchWords, setSearchWords] = useState([]);

  const [loading, error, logsResponse = {}] = useApi(
    getLogs,
    {
      queryString: isESQueryMode
        ? searchValue
        : makePartialMatchQuery(searchValue),
      logType,
      severities,
      subtenantIds: subtenants,
      managedEntityIds,
      startDate: startDateToES,
      endDate: endDateToES,
      from: getElasticSearchOffset(page, rowsPerPage),
      pageSize: rowsPerPage,
    },
    false,
    autoRefresh,
  );
  const { logs = [], count = 0 } = logsResponse;

  useEffect(() => {
    if (logs.length) {
      if (searchValue) {
        setSearchWords(searchValue.split(" "));
      } else {
        setSearchWords([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  const handleSearchByChange = (val) => {
    const value = removeESUnescapableCharacters(val);
    setSearchValue(value);
    tableFilterActions.handleSearchByChange(value);
    handleFilterValue({
      table: namespace,
      key: "searchValue",
      value: value,
    });
  };

  const handleFilterValue = (filter) => {
    dispatch(
      setFilterValue({
        table: filter.table,
        key: filter.key,
        value: filter.value,
      }),
    );
  };

  return (
    <>
      <SectionTabs count={[path, count]} />

      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
            style={{ overflowY: "auto" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={tableCellLength}
                    className={commonClasses.commonTableCell}
                  >
                    <LogsFilterMenu
                      createAlarmView={createAlarmView}
                      searchValue={searchValue}
                      handleSearchByChange={handleSearchByChange}
                      logType={logType}
                      setLogType={setLogType}
                      severities={severities}
                      setSeverities={setSeverities}
                      subtenantIds={subtenantIds}
                      setSubtenantIds={setSubtenantIds}
                      managedEntityIds={managedEntityIds}
                      setManagedEntityIds={setManagedEntityIds}
                      startDate={startDate}
                      startDateToES={startDateToES}
                      setStartDate={setStartDate}
                      setStartDateToES={setStartDateToES}
                      endDate={endDate}
                      endDateToES={endDateToES}
                      setEndDate={setEndDate}
                      setEndDateToES={setEndDateToES}
                      count={count}
                      page={page}
                      onChangePage={(e, pageNumber) => setPage(pageNumber)}
                      onChangeRowsPerPage={
                        tableFilterActions.tpChangeRowsPerPage
                      }
                      rowsPerPage={rowsPerPage}
                      columns={columns}
                      addColumn={tableFilterActions.handleAddColumn}
                      removeColumn={tableFilterActions.handleRemoveColumn}
                      onClickSearchMode={tableFilterActions.toggleFlag1}
                      handleFilterValue={handleFilterValue}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={tableCellLength}
                    className={commonClasses.commonTableCell}
                  >
                    <LogsFilterLabels
                      filters={logFilters}
                      handleFilterValue={handleFilterValue}
                      handleSearchByChange={handleSearchByChange}
                      setLogType={setLogType}
                      setSeverities={setSeverities}
                      setManagedEntityIds={setManagedEntityIds}
                      setStartDate={setStartDate}
                      setStartDateToES={setStartDateToES}
                      setEndDate={setEndDate}
                      setEndDateToES={setEndDateToES}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {checkedColumns.map(
                    (tableHeaderColumn) =>
                      tableHeaderColumn.check && (
                        <TableCell
                          key={tableHeaderColumn.id}
                          align={tableHeaderColumn.align}
                          className={commonClasses.commonTableCellDefault}
                        >
                          {tableHeaderColumn.active ? (
                            <TableSortLabel
                              id={`LOGS_TABLE_SORT_${tableHeaderColumn.id}`}
                              active={false}
                            >
                              {t(tableHeaderColumn.name)}
                            </TableSortLabel>
                          ) : (
                            t(tableHeaderColumn.name)
                          )}
                        </TableCell>
                      ),
                  )}
                </TableRow>
              </TableHead>

              <LogsTableBody
                loading={loading}
                error={error}
                logs={logs}
                columns={checkedColumns}
                enableHighlighting
                searchWordsToHighlight={searchWords}
              />
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
