const managedEntityStyles = ({
  darkMode,
  palette,
  breakpoints,
  colors,
  typography,
}) => ({
  // Container Layout
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  managedEntityHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
  },
  warningIcon: {
    color: "orange",
    fontSize: "22px",
    margin: "8px",
  },
  maintenanceStyle: {
    fontSize: "10px",
  },
  headerInfoGroup: {
    alignItems: "center",
    display: "flex",
    marginBottom: 5,
    marginTop: 20,
  },
  subHeader: {
    paddingLeft: 24,
    [breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  subHeaderItems: {
    display: "flex",
    alignItems: "center",
    [breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  subHeaderActions: {
    display: "flex",
    alignItems: "center",
    [breakpoints.down("md")]: {
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  subHeaderTitle: {
    display: "flex",
    alignItems: "center",
    [breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  headerButtonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  managedEntityTagSpace: {
    marginLeft: 10,
    marginRight: 10,
  },
  root: {
    marginLeft: -15,
    marginRight: -15,
    padding: "10px 20px 10px 20px",
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 0,
  },
  headerActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  monitorSelection: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 15,
  },
  actionDivider: {
    borderLeft: "1px solid" + colors.blueLight4,
    borderRight: "1px solid" + colors.blueLight4,
    height: 30,
  },
  infoBox: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: colors.greyLight1,
    margin: "0px 20px 0px 20px",
    paddingTop: "5px",
    paddingLeft: 10,
    paddingRight: 5,
    paddingBottom: 5,
    alignItems: "center",
  },
  infoBoxSeeMore: {
    marginLeft: "auto",
  },
  configSectionContent: {
    borderLeft: "1px solid " + colors.blueLight4,
    "&:first-child": {
      border: 0,
    },
    [breakpoints.down("md")]: {
      borderLeft: 0,
      borderBottom: "1px solid " + colors.blueLight4,
      "&:last-child": {
        border: 0,
      },
    },
  },
  seeMoreSection: {},
  seeMoreSectionTitle: {
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  tableCell: {
    border: "1px none " + colors.blueLight4,
  },
  backLinkWrapper: {
    width: "fit-content",
  },
  backLinkIcon: {
    color: darkMode ? palette.secondary.main : palette.primary.main,
  },
  backLink: {
    display: "flex",
    alignItems: "center",
    color: darkMode ? palette.secondary.main : palette.primary.main,
    fontSize: "0.8125rem",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    marginBottom: 15,
  },
  tabs: {
    paddingLeft: 20,
  },
  standardTab: {
    minWidth: "auto",
    fontSize: "1rem",
    fontWeight: typography.fontWeightRegular,
    lineHeight: 1.5,
  },
  standardTabSelected: {
    fontWeight: typography.fontWeightMedium,
  },
  sideMargin: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  tagGroup: {
    marginLeft: 20,
    marginRight: 5,
  },
  leftMargin: {
    marginLeft: 10,
  },
  graph: {
    marginTop: 15,
    width: "90%",
    height: "40%",
  },
  topBorder: {
    borderTop: "1px solid rgba(137, 149, 171, 0.17)",
  },
  filterLabel: {
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightLight,
    lineHeight: "normal",
    letterSpacing: 0.3,
    color: palette.text.secondary,
    marginRight: 10,
  },
  selectInput: {
    fontSize: "0.8125rem",
    lineHeight: "1.2rem",
    padding: "0px 10px 2px 10px",
  },
  select: {
    minWidth: 200,
    borderRadius: 16,
    padding: 6,
    backgroundColor: "#fff",
    border: "1px solid #d5d7da",
    "&:hover, &:focus, &:active": {
      backgroundColor: "transparent",
    },
  },
  // Text and Typography
  lightText: {
    color: palette.text.secondary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightRegular,
    letterSpacing: "0.3px",
  },
  primaryText: {
    color: palette.text.primary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightRegular,
  },
  secondaryText: {
    color: palette.primary.main,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightMedium,
    letterSpacing: "0.3px",
  },
  smallBoldText: {
    color: palette.text.primary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightMedium,
  },
  largeBold: {
    color: palette.text.primary,
    fontSize: "1.75rem",
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.3,
  },
  regularHeading: {
    color: palette.text.primary,
    fontSize: "1.5rem",
    fontWeight: typography.fontWeightRegular,
    letterSpacing: "0.3px",
  },
  uppercaseHeadingText: {
    color: palette.text.primary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightMedium,
    textTransform: "uppercase",
  },
  uppercaseActionText: {
    color: palette.primary.main,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightMedium,
    textTransform: "uppercase",
  },
  // Status Icons
  statusIcon: {
    display: "inline-block",
    background: "transparent",
    width: 20,
    height: 20,
    marginRight: 10,
  },
  alertBar: {
    marginBottom: 15,
  },
  popoverContainer: {
    "&:after, &:before": {
      left: "90%",
    },
  },
  popover: {
    overflowY: "inherit",
    overflowX: "inherit",
  },
  iconArrow: {
    marginLeft: 10,
  },
});

export default managedEntityStyles;
