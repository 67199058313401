import { useTranslation } from "react-i18next";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { Tab, Tabs } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";

export const TABS = {
  information: 0,
  permissions: 1,
  managers: 2,
};

const DialogTabs = () => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const activeTab = window.location.pathname.split("/").pop();

  return (
    <Tabs
      value={TABS[activeTab] || 0}
      indicatorColor="primary"
      variant="standard"
      data-testid="permissionProfiles-dialogTabs"
    >
      <Tab
        key={TABS.information}
        id={"PERMISSION_PROFILE_DIALOG_INFORMATION_TAB"}
        classes={{
          selected: commonClasses.commonTabSelected,
        }}
        label={t("Information")}
        component={Link}
        to={"information"}
      />
      <Tab
        key={TABS.permissions}
        id={"PERMISSION_PROFILE_DIALOG_PERMISSIONS_TAB"}
        classes={{
          selected: commonClasses.commonTabSelected,
        }}
        label={t("Permissions")}
        component={Link}
        to={"permissions"}
      />
      <Tab
        key={TABS.managers}
        id={"PERMISSION_PROFILE_DIALOG_ASSOCIATED_MANAGERS_TAB"}
        classes={{
          selected: commonClasses.commonTabSelected,
        }}
        label={t("Associated Managers")}
        component={Link}
        to={"managers"}
      />
    </Tabs>
  );
};

export default DialogTabs;
