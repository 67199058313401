import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import isObject from "lodash/isObject";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import produce from "immer";

import Bpm, { VARIABLE_NAME_FOR_INPUT_CONTEXT } from "msa2-ui/src/services/Bpm";

import useApi from "msa2-ui/src/hooks/useApi";
import useWorkflow from "msa2-ui/src/hooks/useWorkflow";

import {
  getProcessVariables,
  getHistoricProcessVariables,
} from "msa2-ui/src/api/bpm";

import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Dialog from "msa2-ui/src/components/Dialog";
import RunTimeVariables from "msa2-ui/src/components/variables/RunTimeVariableFields";

const useStyles = makeStyles(({ palette }) => ({
  warning: {
    color: palette.warning.main,
  },
}));

const VariableEditDialog = ({
  element,
  processInstanceId,
  activeElementStatus,
  isFinished,
  statusLoading,
  onClose,
  onExec,
  message,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const bpmElementValues = Bpm.readAttachedWorkflowValuesFromBpmElement(
    element,
  );
  const {
    activityId,
    displayName,
    workflowPath,
    processName,
    processType,
    workflowInstanceId,
    processVariables,
  } = bpmElementValues;

  const isVariableStatic = isObject(processVariables);
  const variableRef = Bpm.parseCamundaJsonParser(processVariables);
  const inputParser = variableRef[1] ?? variableRef[0];
  const sourceVariable = variableRef[2];
  const [data, setData] = useState(isVariableStatic ? processVariables : null);
  const [submitting, setSubmitting] = useState(false);
  const [instanceId, setInstanceId] = useState(
    !Bpm.isCamundaJsonParser(workflowInstanceId) && workflowInstanceId,
  );

  const [isLatestType, setIsLatestType] = useState();
  const isPassType = Boolean(sourceVariable);

  const {
    workflow,
    processVariableTypesByTask,
    workflowInstanceVariableValues,
    isLoading: workflowLoading,
  } = useWorkflow({
    workflowPath,
    processName,
    instanceId,
    wait: {
      workflowDetails: true,
      processVariableTypesByTask:
        statusLoading ||
        (!activeElementStatus.willExecute && activeElementStatus.isExecuted),
      workflowInstanceVariableValues: !isLatestType,
    },
  });

  const [
    processVariableLoading,
    ,
    {
      processVariables: camundaProcessVariables,
      inputVariables: camundaInputVariables,
    } = {},
  ] = useApi(
    getProcessVariables,
    {
      processInstanceId,
      transforms: [
        (res) => {
          const inputVariables = get(res, [
            VARIABLE_NAME_FOR_INPUT_CONTEXT,
            "value",
          ]);

          if (Bpm.isCamundaJsonParser(workflowInstanceId)) {
            // Parse instance id
            // Extract ${JSON(Activity_17bsh7n).prop("serviceId").value()}
            const [{ name, props }] = Bpm.parseCamundaJsonParser(
              workflowInstanceId,
            );
            // Get service_id from the response
            const instanceId = get(res, [name, "value", props]);
            setInstanceId(instanceId);
          }

          let data;
          if (
            !activeElementStatus.willExecute &&
            activeElementStatus.isExecuted
          ) {
            // If selected element is already executed and won't be executed again
            // Get the result variable data stored by back-end
            data =
              get(res, [activityId, "value", "variables"]) ??
              // If the result still does not exist (= 1st execution is active), get it from input.
              inputVariables[activityId];
          } else if (inputParser.name) {
            // If the value for WF is a reference for other variable name.
            // eg. <camunda:entry key="processVariables">${JSON(inputs).prop("Activity_1r3oq7c").toString()}</camunda:entry>
            // Get value from the object under "input" variable which is set when BPM starts
            data = get(res, [inputParser.name, "value", inputParser.props]);
            // If data is not Pass type and is empty object, mark it as Latest type
            setIsLatestType(!isPassType && isEqual(data, {}));

            if (!data && isPassType) {
              // If no activityId in "input" and PASS type variable, get value from reference
              data = get(
                res,
                [sourceVariable.name, "value", sourceVariable.props],
                {},
              );
            }
          }
          // set variables data to show on the popup
          data && setData(data);
          return { processVariables: res, inputVariables };
        },
      ],
    },
    // wait for willExecute/isExecuted to be ready.
    statusLoading || isFinished,
  );

  useEffect(() => {
    // If Instance:"New Instance" and Variable:"Latest",
    // extract service_id from Shape to call API and set context value from the response
    if (isLatestType && workflowInstanceVariableValues) {
      setData(workflowInstanceVariableValues);
    }
  }, [isLatestType, workflowInstanceVariableValues]);

  // If BPM finished its execution, try to fetch variables from the history.
  const [historicProcessVariableLoading, ,] = useApi(
    getHistoricProcessVariables,
    {
      processInstanceId,
      transforms: [
        (res) => {
          const data = res.find(({ name }) => name === activityId)?.value
            ?.variables;
          data && setData(data);
          return res;
        },
      ],
    },
    !isFinished,
  );

  const handleExec = async () => {
    setSubmitting(true);
    const inputVariables = { ...camundaInputVariables, [activityId]: data };
    const processVariables = produce(camundaProcessVariables, (draft) => {
      draft[VARIABLE_NAME_FOR_INPUT_CONTEXT].value = inputVariables;
    });
    await onExec({ processVariables, inputVariables });

    setSubmitting(false);
  };

  // If clicked element is not yet executed and it can change its value,
  // it will show Variable edit screen
  const canEdit = activeElementStatus.willExecute && !isVariableStatic;
  const warning =
    (!activeElementStatus.willExecute &&
      t("You cannot edit Variable for the active or the finished element.")) ||
    (isVariableStatic && t("You cannot edit Variable for this element."));

  return (
    <Dialog
      title={`${t("Variables")}: ${displayName}`}
      maxWidth={"md"}
      onClose={onClose}
      onExec={canEdit ? handleExec : undefined}
      disabled={submitting}
    >
      {statusLoading ||
      workflowLoading ||
      processVariableLoading ||
      historicProcessVariableLoading ||
      submitting ? (
        <CircularProgress />
      ) : (
        workflow &&
        data && (
          <>
            {message && <Typography variant="body1">{message}</Typography>}
            {warning && (
              <Typography variant="body1" className={classes.warning}>
                {warning}
              </Typography>
            )}
            <RunTimeVariables
              variables={workflow.variables.variable}
              processVariables={processVariableTypesByTask}
              editMode={canEdit ? processType : false}
              data={data}
              onChange={(data) => setData(data)}
            />
          </>
        )
      )}
    </Dialog>
  );
};

VariableEditDialog.propTypes = {
  processInstanceId: PropTypes.string.isRequired,
  activeElementStatus: PropTypes.shape({
    isExecuted: PropTypes.bool,
    isActive: PropTypes.bool,
    willExecute: PropTypes.bool,
  }).isRequired,
  statusLoading: PropTypes.bool,
  element: PropTypes.object.isRequired,
};

export default VariableEditDialog;
