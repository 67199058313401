import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import get from "lodash/get";
import set from "lodash/set";
import { filter } from "msa2-ui/src/utils/filter";

export const getDeploymentSettingsList = (
  subtenant,
  deploymentSettings,
  searchTerm,
) => {
  if (!subtenant) return [];
  if (searchTerm) return filter(deploymentSettings, searchTerm, ["name"]);
  return deploymentSettings;
};

export const getDeploymentSettingsCount = (subtenantId, meta) => {
  if (!subtenantId || !meta) return null;
  return Number.parseInt(get(meta, "ds_count", 0));
};

export const useReturnToDeploymentSettings = () => {
  const history = useHistory();
  return useCallback(() => {
    history.push("/integration/deployment-settings");
  }, [history]);
};

export const modalTabs = [
  {
    id: "information",
    path: "information",
    displayName: "Information",
  },
  {
    id: "microservices",
    path: "microservices",
    displayName: "Microservices",
  },
  {
    id: "managedEntities",
    path: "managed-entities",
    displayName: "Managed Entities",
  },
];

export const getModalTabCount = (tab, data) => {
  if (!data) return "";
  if (tab.id === "microservices") {
    return data.microserviceUris.length + data.templateUris.length || "";
  }
  if (tab.id === "managedEntities") {
    return data.attachedManagedEntities.length || "";
  }
};

export const deploymentSettingsFields = {
  subtenantId: "Subtenant",
  externalReference: "External Reference",
  name: "Deployment Setting Name",
  comment: "Comment",
  vendor: {
    id: "Vendor",
  },
  model: {
    id: "Model",
  },
};

export const deploymentSettingsRequiredFields = [
  "name",
  "model.id",
  "vendor.id",
  "subtenantId",
];

export const validateDeploymentSettingsForm = (values) => {
  const requiredField = i18n.t("This is a required field");

  return deploymentSettingsRequiredFields.reduce((errors, field) => {
    return !get(values, field.split("."))
      ? set(errors, field, requiredField)
      : errors;
  }, {});
};
