import React, { Fragment, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconClose from "@material-ui/icons/Close";
import NumberFormat from "react-number-format";

import {
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";

import useApi from "msa2-ui/src/hooks/useApi";
import { getWorkflow } from "msa2-ui/src/api/workflow";

import { instanceTabs } from "./constants";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import {
  useAdjustableDrawerWidth,
  HANDLE_POSITION,
} from "msa2-ui/src/hooks/useAdjustableDrawerWidth";
import {
  changeDrawerWidthSetting,
  getDrawerWidthSetting,
  SETTINGS,
} from "msa2-ui/src/store/settings";

import AlertBar from "msa2-ui/src/components/AlertBar";
import Process from "msa2-ui/src/services/Process";
import AdvancedParameters from "msa2-ui/src/services/AdvancedParameters";

const useStyles = makeStyles(() => ({
  drawerPaper: {
    overflowY: "scroll",
  },
  instanceNameWrapper: {
    height: 38,
  },
  drawerResizeHandle: {
    position: "fixed",
    top: 0,
    height: "100%",
    width: 5,
    cursor: "ew-resize",
  },
}));

const AutomationDetailDrawer = ({
  execProcess: execProcessDefault,
  instanceId: instanceIdDefault = "",
  instanceName: instanceNameDefault = "",
  ubiqubeId,
  workflow: _workflow,
  tab = 0,
  workflowPath,
  onClose,
}) => {
  const DRAWER_MIN_WIDTH = SETTINGS.drawerWidth.automationDetail.minValue;
  const DRAWER_WIDE_WIDTH = SETTINGS.drawerWidth.automationDetail.defaultValue;

  const [instanceName, setInstanceName] = useState(instanceNameDefault);
  const [instanceId, setInstanceId] = useState(instanceIdDefault);
  const [execProcess, setExecProcess] = useState(execProcessDefault);
  const [selectedTab, setSelectedTab] = useState(tab);
  const [total, setTotal] = useState({});

  const [isLoading, , workflow = _workflow, , reloadWorkflow] = useApi(
    getWorkflow,
    { pathToWorkflowDefinitionFile: decodeURIComponent(workflowPath) },
    !workflowPath || Boolean(_workflow),
  );

  const variables = useMemo(() => {
    const _variables = workflow?.variables?.variable || [];
    if (!Process.isUpdate(execProcess?.type)) {
      return _variables;
    }
    return AdvancedParameters.utilizeAdvancedParameters(_variables);
  }, [workflow, execProcess]);

  const dispatch = useDispatch();

  const drawerWidthFromSettings = useSelector(
    getDrawerWidthSetting("automationDetail"),
  );

  const drawerResizeHandleRef = useRef();
  const [drawerWidth, handlers] = useAdjustableDrawerWidth({
    drawerResizeHandleRef,
    defaultWidth:
      drawerWidthFromSettings ||
      (execProcessDefault ? DRAWER_WIDE_WIDTH : DRAWER_MIN_WIDTH),
    minWidth: DRAWER_MIN_WIDTH,
    handlePosition: HANDLE_POSITION.LEFT,
    onFinalChange: (width) =>
      dispatch(changeDrawerWidthSetting({ drawer: "automationDetail", width })),
  });

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const t = useBoundedTranslation();

  const reloadDrawer = (instanceId, execProcess) => {
    setInstanceId(instanceId);
    setExecProcess(execProcess);
  };
  const handleChangeTab = (_, value) => setSelectedTab(value);
  const handleSetInstanceName = (value) => setInstanceName(value);
  const handleSetTotal = (key, value) => setTotal({ [key]: value });

  return (
    <Drawer
      anchor="right"
      open
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{ style: { width: drawerWidth } }}
    >
      <div
        ref={drawerResizeHandleRef}
        className={classes.drawerResizeHandle}
        {...handlers}
      />
      <div className={classes.drawerWrapper}>
        {(() => {
          if (isLoading) {
            return (
              <Grid container justifyContent="center" alignContent="center">
                <CircularProgress />
              </Grid>
            );
          }
          if (!workflow) {
            return (
              <AlertBar
                message={t("Unable to load x", { x: t("Workflow") })}
                refreshFnc={reloadWorkflow}
              />
            );
          }

          return (
            <>
              <div className={commonClasses.commonDrawerHeader}>
                <Grid container>
                  <Grid item xs={11}>
                    <Typography
                      variant="body2"
                      className={commonClasses.commonDescription}
                    >
                      {workflow?.information.displayName}
                      {execProcess && "  > " + execProcess.displayName}
                    </Typography>
                    <Typography
                      variant="h3"
                      className={classes.instanceNameWrapper}
                    >
                      {instanceName}
                      {instanceId &&
                        instanceId !== instanceName &&
                        ` (#${instanceId})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={commonClasses.commonFlexEnd}>
                    <IconButton
                      id="AUTOMATION_DETAIL_DRAWER_BTN_CLOSE"
                      aria-label={t("Close")}
                      onClick={onClose}
                      className={commonClasses.closeButton}
                    >
                      <IconClose />
                    </IconButton>
                  </Grid>
                </Grid>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  centered
                >
                  {instanceTabs.map((tab) => (
                    <Tab
                      id={`AUTOMATION_DETAIL_DRAWER_TAB_${tab.id}`}
                      key={tab.id}
                      disabled={Boolean(execProcess)}
                      className={commonClasses.commonTab}
                      classes={{
                        root: commonClasses.commonTab,
                        selected: commonClasses.commonTabSelected,
                      }}
                      label={
                        <div className={commonClasses.commonFlexCenter}>
                          <tab.icon className={commonClasses.commonTabIcon} />
                          {t(tab.name)}
                          <NumberFormat
                            value={total[tab.id]}
                            displayType={"text"}
                            thousandSeparator={true}
                            className={commonClasses.commonTabCount}
                          />
                        </div>
                      }
                    />
                  ))}
                </Tabs>
              </div>
              <div className={commonClasses.commonDrawerBody}>
                {/* Render all tabs to get the number besides tab name. Visibility is controled with open props */}
                {instanceTabs.map((tab, i) => (
                  <Fragment key={tab.id}>
                    <tab.component
                      instanceId={instanceId}
                      open={selectedTab === i}
                      onClose={onClose}
                      workflowPath={workflow.metaInformationList[0].uri}
                      setTotal={(val) =>
                        handleSetTotal(instanceTabs[i].id, val)
                      }
                      variables={variables}
                      processes={workflow.process}
                      displayField={workflow.information.displayField}
                      execProcess={execProcess}
                      handleSetInstanceName={handleSetInstanceName}
                      reloadDrawer={reloadDrawer}
                      ubiqubeId={ubiqubeId}
                    />
                  </Fragment>
                ))}
              </div>
            </>
          );
        })()}
      </div>
    </Drawer>
  );
};

AutomationDetailDrawer.propTypes = {
  instanceName: PropTypes.string,
  instanceId: PropTypes.string,
  execProcess: PropTypes.object,
  tab: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  workflow: PropTypes.object,
};

export default AutomationDetailDrawer;
