import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Table,
  Checkbox,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import TableRow from "msa2-ui/src/components/TableRow";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import format from "date-fns/format";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { displayMonthDayYearTimeDate } from "msa2-ui/src/utils/date";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { makeStyles } from "@material-ui/core";
import useFilter from "msa2-ui/src/hooks/useFilter";
import useApi from "msa2-ui/src/hooks/useApi";
import { getRevisionsByDeviceId } from "msa2-ui/src/api/managedEntity";
import ManagedEntityHistoryButtons from "./ManagedEntityHistoryButtons";
import xor from "lodash/xor";

export const TABLE_HEADER_COLUMNS = [
  {
    id: "checkbox",
    name: "",
    align: "left",
  },
  {
    id: "revision",
    name: "Revision",
    align: "left",
  },
  {
    id: "placeholder",
    name: "",
    align: "right",
  },
];

const groupRevisionsByDate = (revisions) => {
  const revisionsMap = revisions.reduce((acc, revision) => {
    const date = format(revision.timeInMiliSeconds, "MMM d, yyyy");

    if (acc.has(date)) {
      acc.get(date).push(revision);
    } else {
      acc.set(date, [revision]);
    }

    return acc;
  }, new Map());

  return Array.from(revisionsMap);
};

const useStyles = makeStyles(({ palette, typography }) => ({
  headerWrap: {
    padding: "4px 10px",
  },
  checkboxCell: {
    width: 64,
  },
  tableLink: {
    textDecoration: "none",
    color: "currentColor",
    "&:active, &:visited": {
      color: "currentColor",
    },
  },
  revision: {
    display: "block",
    margin: 0,
  },
  tableHint: {
    padding: "10px 0 30px 20px",
  },
  groupWrap: {
    marginLeft: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    borderLeft: `1px solid ${palette.primary.main}`,
    position: "relative",
  },
  groupPoint: {
    position: "absolute",
    top: 3,
    left: -5,
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: palette.primary.main,
  },
  groupDate: {
    color: "#fff",
    paddingBottom: 10,
    ...typography.h5,
  },
}));

const ManagedEntityHistoryTable = ({ deviceId, maintenanceMode }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [selectedRevisions, setSelectedRevisions] = useState([]);

  const handleRevisionSelect = (revision) => {
    setSelectedRevisions(xor(selectedRevisions, [revision]));
  };

  const [filterState, filterActions] = useFilter({
    tableName: "history",
    searchValue: "",
    tpPage: 0,
  });

  const [
    loading,
    error,
    revisionsEntries = [],
    revisionsMeta = {},
    reloadRevisionsEntries,
  ] = useApi(
    getRevisionsByDeviceId,
    {
      id: deviceId,
      filters: `revision:${filterState.searchValue}`,
      page: filterState.tpPage + 1,
      pageSize: filterState.tpRowsPerPage,
    },
    !deviceId,
  );
  const revisionsTotalCount = parseInt(revisionsMeta.totalcount) || 0;

  const revisionsGroupedByDate = useMemo(
    () => groupRevisionsByDate(revisionsEntries),
    [revisionsEntries],
  );

  const renderTable = () => {
    if (loading) {
      return (
        <Table>
          <TableLoading numberOfTableColumns={TABLE_HEADER_COLUMNS.length} />
        </Table>
      );
    }

    if (!revisionsEntries || error || revisionsEntries.length === 0) {
      return (
        <Table>
          <TableMessage
            message={
              error
                ? `${t("Error fetching x", { x: t("history") })}. ${t(
                    "Please reload the page",
                  )}.`
                : t("No history entries found")
            }
            numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
            t
          />
        </Table>
      );
    }

    return revisionsGroupedByDate.map(([date, revisionsGroup]) => (
      <div key={date} className={classes.groupWrap}>
        <div className={classes.groupPoint} />
        <div className={classes.groupDate}>{date}</div>
        <Table>
          <TableBody>
            {revisionsGroup.map(
              ({ revision, author, timeInMiliSeconds }, i) => (
                <TableRow key={i}>
                  <TableCell className={classes.checkboxCell}>
                    <Checkbox
                      id={`MANAGED_ENTITY_HISTORY_TABLE_CHECK_${i}`}
                      checked={selectedRevisions.includes(revision)}
                      onChange={() => handleRevisionSelect(revision)}
                    />
                  </TableCell>
                  <TableCell
                    className={classnames(
                      commonClasses.commonTableCellDefault,
                      commonClasses.commonTableCellClickable,
                    )}
                  >
                    <Typography
                      variant="h4"
                      className={classnames(
                        commonClasses.commonTablePrimary,
                        classes.tableLink,
                        classes.revision,
                      )}
                      component={Link}
                      to={`${pathname}/${revision}`}
                    >
                      {revision}
                    </Typography>
                    <Typography className={commonClasses.commonTableSecondary}>
                      {author} |{" "}
                      {displayMonthDayYearTimeDate(timeInMiliSeconds)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </div>
    ));
  };

  return (
    <div>
      <div className={classes.headerWrap}>
        <Grid container justifyContent="space-between">
          <Grid item lg={8}>
            <FilterMenu
              id="MANAGED_ENTITY_HISTORY_FILTER_MENU"
              tpTotal={revisionsTotalCount}
              tpPage={filterState.tpPage}
              handleSearchByChange={filterActions.debouncedHandleSearchByChange}
              tpChangeRowsPerPage={filterActions.tpChangeRowsPerPage}
              tpRowsPerPage={filterState.tpRowsPerPage}
              tpChangePage={filterActions.tpChangePage}
            />
          </Grid>
          <Grid item container alignItems="center" lg={4}>
            <ManagedEntityHistoryButtons
              deviceId={deviceId}
              revisionsEntries={revisionsEntries}
              selectedRevisions={selectedRevisions}
              reloadRevisions={reloadRevisionsEntries}
              maintenanceMode={maintenanceMode}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.tableHint}>
        {t("See the details made per update, or select two updates to compare")}
      </div>
      {renderTable()}
    </div>
  );
};

ManagedEntityHistoryTable.propTypes = {
  deviceId: PropTypes.number.isRequired,
};

export default ManagedEntityHistoryTable;
