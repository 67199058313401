import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";

const useStyles = makeStyles(({ palette }) => ({
  contentWrapper: {
    padding: "10px 20px",
    height: 444,
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
  },
  warningMessage: {
    color: palette.warning.main,
    textAlign: "center",
    marginBottom: 15,
  },
}));

const AttachmentDialog = ({
  allItems,
  attachedItemIds,
  idKey,
  displayNameKey,
  displayNameTooltipKey,
  attachedTitle,
  popupTitle,
  noDataMessage,
  searchPlaceholder,
  unattachedTitle,
  isLoading,
  onAttach,
  onDetach,
  onClose,
  onSave,
  warningMessage = "",
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const t = useBoundedTranslation();

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
      classes={{
        paper: commonClasses.commonDialogPaper,
      }}
    >
      <DialogTitle
        className={commonClasses.commonDialogHeader}
        disableTypography
      >
        <Typography
          variant="h4"
          className={commonClasses.commonDialogHeaderTitle}
        >
          {popupTitle}
        </Typography>
        <IconButton
          onClick={onClose}
          className={commonClasses.commonDialogHeaderCloseButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        {isLoading ? (
          <div
            data-testid="attachment-dialog-spinner"
            className={classNames(
              classes.itemWrapper,
              commonClasses.commonFlexCenter,
            )}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {warningMessage && (
              <div className={classes.warningMessage}>{warningMessage}</div>
            )}
            <AttachmentBoard
              allItems={allItems}
              attachedItemIds={attachedItemIds}
              idKey={idKey}
              displayNameKey={displayNameKey}
              displayNameTooltipKey={displayNameTooltipKey}
              handleAttach={onAttach}
              handleAttachAll={onAttach}
              handleDetach={onDetach}
              handleDetachAll={onDetach}
              attachedTitle={attachedTitle}
              noDataMessage={noDataMessage}
              searchPlaceholder={searchPlaceholder}
              unattachedTitle={unattachedTitle}
            />
          </>
        )}
      </DialogContent>
      <DialogActions className={commonClasses.commonFlexCenter}>
        <Button
          variant="text"
          size="small"
          color="default"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnSecondary,
          )}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classNames(
            commonClasses.commonBtn,
            commonClasses.commonBtnPrimary,
          )}
          onClick={onSave}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AttachmentDialog.propTypes = {
  allItems: PropTypes.array.isRequired,
  attachedItemIds: PropTypes.array.isRequired,
  idKey: PropTypes.string.isRequired,
  displayNameKey: PropTypes.string.isRequired,
  displayNameTooltipKey: PropTypes.string,
  attachedTitle: PropTypes.string,
  popupTitle: PropTypes.string,
  noDataMessage: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  unattachedTitle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onAttach: PropTypes.func.isRequired,
  onDetach: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AttachmentDialog;
