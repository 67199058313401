import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import initializeStore from "msa2-ui/src/store/initializeStore";
import "./localisation/i18n";

import { CssBaseline } from "@material-ui/core";
import { StylesProvider, createGenerateClassName } from "@material-ui/core";

import SnackbarProvider from "msa2-ui/src/components/SnackbarProvider";
import InquiryChatProvider from "msa2-ui/src/components/InquiryChatProvider";
import ConnectionStatus from "msa2-ui/src/components/ConnectionStatus";
import MsaThemeProvider from "msa2-ui/src/MsaThemeProvider";
import App from "./App";

const generateClassName = createGenerateClassName();
const basePath = process.env.REACT_APP_MSA_SERVER_BASEPATH;

const AppWithProviders = ({ store }) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <StylesProvider generateClassName={generateClassName}>
          <MsaThemeProvider>
            <SnackbarProvider>
              <DndProvider backend={HTML5Backend}>
                <InquiryChatProvider>
                  <CssBaseline />
                  <ConnectionStatus />
                  <Router basename={basePath}>
                    <App />
                  </Router>
                </InquiryChatProvider>
              </DndProvider>
            </SnackbarProvider>
          </MsaThemeProvider>
        </StylesProvider>
      </Provider>
    </StrictMode>
  );
};

initializeStore()
  .then((store) => {
    ReactDOM.render(
      <AppWithProviders store={store} />,
      document.getElementById("ubiqubeMSA"),
    );
  })
  .catch((error) => {
    console.log(
      "MSA2: An error has occurred while loading the application",
      error,
    );
  });
