import React, { useCallback, useMemo, useEffect, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";
import flow from "lodash/flow";

import useFilter from "msa2-ui/src/hooks/useFilter";
import useApi from "msa2-ui/src/hooks/useApi";

import {
  getBpmDetails,
  getProcessInstanceStatistics,
  getExternalTaskLogs,
} from "msa2-ui/src/api/bpm";
import { getToken } from "msa2-ui/src/store/auth";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import { bpmStatus } from "msa2-ui/src/Constants";

import Bpm from "msa2-ui/src/services/Bpm";

import SectionTabs from "msa2-ui/src/components/SectionTabs";
import FilterMenu from "msa2-ui/src/components/FilterMenu";
import TableLoading from "msa2-ui/src/components/TableLoading";
import TableMessage from "msa2-ui/src/components/TableMessage";
import BpmInstance from "./BpmInstance";

const TABLE_HEADER_COLUMNS = [
  {
    id: "id",
    name: "ID",
    align: "left",
    width: "44%",
    active: true,
  },
  {
    id: "startTime",
    name: "Start Time",
    align: "left",
    width: "21%",
    active: true,
  },
  {
    id: "endTime",
    name: "End Time",
    align: "left",
    width: "21%",
    active: true,
  },
  {
    id: "blank1",
    name: "",
    align: "left",
  },
];

const bpmStatusList = [
  {
    id: "all",
    displayName: "All instances",
    text: "All instances",
  },
  ...bpmStatus.map(({ id, name }) => ({ id, displayName: name, text: name })),
];

const BpmDetails = ({ bpmFilename, tabs }) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { isExact } = useRouteMatch("/automation/bpm/:bpmUri/instances");

  const commonClasses = useCommonStyles();
  const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const token = useSelector(getToken);

  const [
    processInstancesLoading,
    processInstancesError,
    processInstancesResponse,
    ,
    reloadProcessInstances,
  ] = useApi(getBpmDetails, {
    subtenantId: ubiqubeId,
    bpmFilename,
  });

  const [, , externalTaskLogsResponse, , reloadExternalTaskLogs] = useApi(
    getExternalTaskLogs,
    {
      subtenantId: ubiqubeId,
      bpmFilename,
      token,
    },
  );
  const [, statisticsError, statistics, , reloadStatistics] = useApi(
    getProcessInstanceStatistics,
  );

  const totalRecords = useMemo(
    () =>
      statistics &&
      processInstancesResponse &&
      externalTaskLogsResponse &&
      flow(
        (statistics) =>
          statistics.filter(
            ({ definition: { key } }) =>
              key === Bpm.buildProcessDefinitionKey(ubiqubeId, bpmFilename),
          ),
        // filter out deleted instances
        (statistics) =>
          Bpm.filterOutDeletedInstances(statistics, processInstancesResponse),
        // divide records by statuses
        (statistics) => Bpm.getRecords(statistics),
        // a business error (WF Failure) is assumed as completed in camunda, we convert it into failure manually
        (totalRecords) =>
          Bpm.transformBusinessFailure(totalRecords, externalTaskLogsResponse),
      )(statistics),
    [
      bpmFilename,
      externalTaskLogsResponse,
      processInstancesResponse,
      statistics,
      ubiqubeId,
    ],
  );

  const reloadApi = useCallback(() => {
    reloadProcessInstances();
    reloadStatistics();
    reloadExternalTaskLogs();
  }, [reloadProcessInstances, reloadStatistics, reloadExternalTaskLogs]);

  const processInstances = processInstancesResponse,
    isLoading = processInstancesLoading,
    error = processInstancesError || statisticsError;

  const [filterState, filterActions] = useFilter({
    searchValue: "",
    filterByValue: bpmStatusList[0].id,
  });

  const filterByStatus = (instances, status, totalRecords) => {
    const statusIndex = bpmStatus.findIndex(
      ({ id }) => id === filterState.filterByValue,
    );
    return statusIndex > -1
      ? // filter instances so the instances with different status from selected tab won't be shown
        instances.filter((instance) =>
          totalRecords[statusIndex].includes(instance.processDefinitionId),
        )
      : // if "All instances" is selected, don't filter instances
        instances;
  };

  const filterByTerm = (instances, filterSearchTerm) =>
    // Will return the list based on search term
    instances.filter(
      (instance) =>
        instance.id
          .toString()
          .toLowerCase()
          .search(filterSearchTerm.toLowerCase()) !== -1,
    );

  const organizeProcessInstances = (processInstances) => {
    if (!processInstances || error) {
      return [];
    }
    return flow(
      // filter by status
      (instances) =>
        filterByStatus(instances, filterState.filterByValue, totalRecords),
      // filter by term
      (instances) => filterByTerm(instances, filterState.searchValue),
    )(processInstances);
  };

  const instances = organizeProcessInstances(processInstances);
  const tabsWithCounts = tabs.map((tab) =>
    url.endsWith(tab.destination) ? { ...tab, count: instances?.length } : tab,
  );

  // reload when comes back from the different route.
  const previousUrl = useRef(url);
  useEffect(() => {
    if (isExact && previousUrl.current !== url) {
      reloadApi();
      previousUrl.current = url;
    }
  }, [isExact, reloadApi, url]);

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <TableLoading numberOfTableColumns={TABLE_HEADER_COLUMNS.length} />
      );
    }

    if (instances.length === 0) {
      return (
        <TableMessage
          message={
            error
              ? error.getMessage(t("Unable to load x", { x: t("Instances") }))
              : t("Nothing to show")
          }
          numberOfTableColumns={TABLE_HEADER_COLUMNS.length}
        />
      );
    }

    return (
      <>
        <TableBody>
          {instances?.map((instance, i) => (
            <BpmInstance
              key={i}
              instanceData={{ ...instance, instanceIndex: i }}
              reloadApi={reloadApi}
            />
          ))}
        </TableBody>
      </>
    );
  };

  return (
    <>
      <SectionTabs tabs={tabsWithCounts} />
      <Paper
        className={classnames(
          commonClasses.commonPaper,
          commonClasses.commonPaperNoPadding,
        )}
      >
        <Table>
          <colgroup>
            {TABLE_HEADER_COLUMNS.map(({ id, width }) => (
              <col key={id} style={{ width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={TABLE_HEADER_COLUMNS.length}
                className={commonClasses.commonTableCell}
              >
                <FilterMenu
                  id="BPM_DETAILS_FILTER_MENU"
                  {...filterState}
                  handleSearchByChange={filterActions.handleSearchByChange}
                  filterByOptions={bpmStatusList}
                  filterByValue={filterState.filterByValue}
                  handleFilterByChange={filterActions.handleFilterByChange}
                />
              </TableCell>
            </TableRow>
            <TableRow className={commonClasses.commonTableHeadRow}>
              {TABLE_HEADER_COLUMNS.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  className={commonClasses.commonTableCellDefault}
                >
                  {header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {renderTableContent()}
        </Table>
      </Paper>
    </>
  );
};

export default BpmDetails;
