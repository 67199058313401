import fileDownload from "js-file-download";
import flow from "lodash/flow";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import { buildQueryParamsFromObject } from "msa2-ui/src/utils/urls";
import APIError from "./apiError";
import { methods, status, contentTypes } from "./constants";
import innerBus from "msa2-ui/src/utils/InnerBus";

export const constructURL = (url, params) => {
  const queryString = new URLSearchParams(params).toString();

  if (queryString) {
    return `${url}?${queryString}`;
  }

  return url;
};

/**
 * JS fetch headers are a map-like structure rather
 * than a vanilla JS object so they need to be handled
 * in this special way
 */
const parseHeaders = (headersMap) => {
  const headers = {};
  headersMap.forEach((value, name) => (headers[name] = value));
  return headers;
};

export const maybeCreateError = (statusCode, response) => {
  switch (statusCode) {
    case status.INTERNAL_SERVER_ERROR:
    case status.UNAUTHORIZED:
    case status.NOT_IMPLEMENTED:
    case status.SERVICE_UNAVAILABLE:
    case status.ENTITY_TOO_LARGE:
    case status.BAD_REQUEST:
    case status.FORBIDDEN:
    case status.NOT_FOUND:
    case status.UNPROCESSABLE_ENTITY:
    case status.GATEWAY_TIMEOUT:
      return new APIError(statusCode, response);
    default:
      return;
  }
};

const convertBody = (body, contentType) => {
  switch (contentType) {
    case contentTypes.JSON: {
      return JSON.stringify(body);
    }
    case contentTypes.ENCODED_FORM: {
      return buildQueryParamsFromObject(body);
    }
    default:
      return body;
  }
};
export const makeRequest = async ({
  url: urlString,
  queryParams = {},
  customHeaders = {},
  method,
  token,
  transforms = [],
  body,
  defaultResponse = [],
  contentType = contentTypes.JSON,
  accept = contentTypes.JSON,
}) => {
  let response, meta, res;

  /**
   * Don't send undefined or null as query params
   * Perhaps find way to only add params we need to
   * the params object
   */
  const cleanParams = omitBy(queryParams, isNil);
  const url = constructURL(urlString, cleanParams);
  try {
    res = await fetch(url, {
      method,
      headers: {
        ...(contentType !== contentTypes.FORM_DATA && {
          "Content-Type": contentType,
        }),
        Accept: accept,
        ...(token && {
          Authorization: "Bearer " + token,
        }),
        ...customHeaders,
      },
      body: convertBody(body, contentType),
    });

    // proceed to logout page
    if (res.status === status.UNAUTHORIZED) {
      innerBus.emit(innerBus.evt.LOGOUT);
    }

    meta = {
      ...parseHeaders(res.headers),
      status: res.status,
    };
    const mediaType = meta?.["content-type"];
    if (process.env.NODE_ENV === "test") {
      // todo: revisit to find global header when mocking fetch in jest
      const json = await res.json();
      response = flow(transforms)(json);
    } else if (!mediaType) {
      response = defaultResponse;
    } else {
      const [type, _subTypes] = mediaType.split("/");
      const subTypes = _subTypes?.split("+") ?? [];
      // handles application/problem+json as well
      if (type === "application" && subTypes.includes("json")) {
        const json = await res.json();
        response = flow(transforms)(json);
      } else if (type === "text") {
        const text = await res.text();
        try {
          const json = JSON.parse(text);
          response = flow(transforms)(json);
        } catch (e) {
          response = text;
        }
      } else {
        const blob = await res.blob();
        response = blob;
      }
    }
  } catch (e) {
    /**
     * Sometimes the API returns no response body
     * If thats the case and we hit an exception where it can't
     * parse the JSON correctly we return the defaultResponse specified
     * by the caller
     */
    if (e instanceof SyntaxError) {
      response = defaultResponse;
    }
  }

  const error = maybeCreateError(
    meta?.status ?? 500,
    response ?? { message: "No response from server" },
  );
  return [error, response, meta];
};

export const download = async ({
  url,
  queryParams = {},
  token,
  fileName = "download-file",
  onError,
}) => {
  const [error, response] = await makeRequest({
    url,
    queryParams,
    token,
    method: methods.GET,
  });
  if (error) {
    onError && onError(error);
  } else {
    fileDownload(response, fileName);
  }
};

export const get = ({
  url,
  queryParams,
  customHeaders,
  transforms,
  token,
  defaultResponse,
  contentType,
  accept,
}) => {
  return makeRequest({
    url,
    queryParams,
    token,
    customHeaders,
    method: methods.GET,
    transforms,
    defaultResponse,
    contentType,
    accept,
  });
};

export const post = ({
  url,
  queryParams,
  token,
  customHeaders,
  transforms,
  body = {},
  defaultResponse,
  contentType,
}) => {
  return makeRequest({
    url,
    queryParams,
    customHeaders,
    token,
    method: methods.POST,
    transforms,
    body,
    defaultResponse,
    contentType,
  });
};

export const put = ({
  url,
  queryParams,
  token,
  customHeaders,
  transforms,
  body = {},
  contentType,
}) => {
  return makeRequest({
    url,
    queryParams,
    customHeaders,
    token,
    method: methods.PUT,
    transforms,
    body,
    contentType,
  });
};

export const destroy = ({
  url,
  queryParams,
  token,
  customHeaders,
  transforms,
  body = {},
  defaultResponse,
  contentType,
}) => {
  return makeRequest({
    url,
    queryParams,
    customHeaders,
    token,
    method: methods.DELETE,
    transforms,
    body,
    defaultResponse,
    contentType,
  });
};

export const head = ({ url, token, customHeaders, contentType }) => {
  return makeRequest({
    url,
    customHeaders,
    token,
    method: methods.HEAD,
    contentType,
  });
};
