import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import useDialog from "msa2-ui/src/hooks/useDialog";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import { commonStyles } from "./commonStyles";

import { ReactComponent as IconEdit } from "msa2-ui/src/assets/icons/edit.svg";
import { ReactComponent as IconDeleteRed } from "msa2-ui/src/assets/icons/deleteRed.svg";

const useStyles = makeStyles(commonStyles);

const ParserField = ({
  icon: Icon,
  value: initialValue,
  onSave,
  onDelete,
  isDefault = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const [editing, setEdit] = useState(isDefault);

  /**
   * Example values
   *  regex: @(?<variable_name>.*)@
   *  xpath: //entry
   */
  const preSufFix = /^@|@$/g;
  const isRegex = initialValue.match(preSufFix)?.length === 2;
  const pureInitialValue = initialValue.replace(preSufFix, "") ?? initialValue;
  const [value, setValue] = useState(pureInitialValue);

  const [showDeleteDialog, DeleteDialog] = useDialog();

  const canDelete = Boolean(onDelete);

  return (
    <Grid
      container
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => setHover(true)}
      className={hover ? classes.hover : ""}
    >
      <Grid item xs={8} className={classes.parserWrapper}>
        <Grid
          container
          wrap="nowrap"
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          className={classes.contentWrapper}
          onDoubleClick={() => setEdit(true)}
        >
          <Icon className={classes.icon} />
          {editing ? (
            <>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  labelWidth={0}
                  value={value}
                  multiline={value.length > 45}
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                  classes={{ input: classes.textField }}
                />
              </FormControl>
            </>
          ) : (
            <Tooltip title={value} enterDelay={500}>
              <Typography variant={"body1"} className={classes.content}>
                {value}
              </Typography>
            </Tooltip>
          )}
          {isRegex && <Icon className={classes.icon} />}
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        container
        justifyContent="flex-end"
        alignContent="center"
      >
        <div>
          {editing ? (
            <>
              <Button
                id="CONFIGURATION_PARSER_BTN_CANCEL"
                variant="text"
                size="small"
                color="default"
                className={classnames(
                  classes.commonBtn,
                  classes.commonBtnPrimary,
                )}
                onClick={() => {
                  setEdit(false);
                  setValue(pureInitialValue);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                id="CONFIGURATION_PARSER_BTN_SAVE"
                variant="contained"
                size="small"
                color="primary"
                className={classnames(
                  classes.commonBtn,
                  classes.commonBtnPrimary,
                )}
                onClick={() => {
                  setEdit(false);
                  onSave(isRegex ? "@" + value + "@" : value);
                }}
              >
                {t("Save")}
              </Button>
            </>
          ) : (
            hover && (
              <>
                <IconButton
                  id="CONFIGURATION_PARSER_FOLDER_BTN_EDIT"
                  aria-label={t("Edit")}
                  onClick={() => setEdit(true)}
                >
                  <IconEdit />
                </IconButton>
                {canDelete && (
                  <IconButton
                    id="CONFIGURATION_PARSER_FOLDER_BTN_DELETE"
                    aria-label={t("Delete")}
                    onClick={showDeleteDialog}
                  >
                    <IconDeleteRed />
                  </IconButton>
                )}
              </>
            )
          )}
          <DeleteDialog
            title={t("Confirm Request")}
            content={t("Are you sure you want to delete this regexp?")}
            onExec={onDelete}
          />
        </div>
      </Grid>
    </Grid>
  );
};

ParserField.propTypes = {
  icon: PropTypes.object,
  value: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isDefault: PropTypes.bool,
};

export default ParserField;
