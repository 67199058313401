import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import classnames from "classnames";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import Radio from "msa2-ui/src/components/connectedFormComponents/Radio";

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    paddingBottom: 5,
  },
  formHeading: {
    marginBottom: 15,
  },
  formField: {
    marginBottom: 35,
    width: "100%",
  },
  description: {
    margin: 5,
  },
}));

const ExclusiveGatewayPropertiesPanel = ({
  modelerState,
  modelerActions,
  moddle,
  readOnly,
}) => {
  const { activeElement } = modelerState;
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const businessObject = getBusinessObject(activeElement);
  const [defaultFlowValue, setDefaultFlowValue] = useState(
    businessObject.default?.id,
  );

  return (
    <Grid container direction="column" className={classes.container}>
      <Typography variant="h5" className={classes.formHeading}>
        {t("Decision Gateway")}
      </Typography>
      <Typography
        variant="body1"
        className={classnames(
          commonClasses.commonDescription,
          classes.formField,
        )}
      >
        {t("Create arrows from this node and click it to set conditions.")}
      </Typography>

      {businessObject.outgoing?.length > 0 && (
        <Radio
          label={t("Default Flow")}
          input={{
            onChange: (flowId) => {
              modelerActions.updateDefaultSequence(flowId);
              setDefaultFlowValue(businessObject.default?.id);
            },
            // We could set businessObject.default?.id directly but set value via useState to force re-render
            value: defaultFlowValue,
          }}
          options={businessObject.outgoing.map(({ id, name }, i) => ({
            id,
            value: id,
            displayName: name || `Flow ${i} (${t("No name")})`,
          }))}
          className={classes.formField}
          disabled={readOnly}
        />
      )}
    </Grid>
  );
};

ExclusiveGatewayPropertiesPanel.propTypes = {
  modelerState: PropTypes.object.isRequired,
  modelerActions: PropTypes.object.isRequired,
  moddle: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default ExclusiveGatewayPropertiesPanel;
