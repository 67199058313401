import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import reduxForm from "redux-form/lib/reduxForm";
import { useTranslation } from "react-i18next";
import flow from "lodash/flow";
import classnames from "classnames";

import useApi from "msa2-ui/src/hooks/useApi";
import { listAllLabels } from "msa2-ui/src/api/managedEntity";

import { getFormValues } from "msa2-ui/src/store/form";
import Variable from "msa2-ui/src/services/Variable";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import Parser, { CONFIGURATION_TYPES } from "msa2-ui/src/services/Parser";

import { makeStyles } from "@material-ui/core";
import { Grid, Paper, MenuItem, Typography } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import FieldSelector from "msa2-ui/src/components/formSection/FieldSelector";
import DeviceAdapterSelector from "msa2-ui/src/components/DeviceAdapterSelector";
import FormSection from "msa2-ui/src/components/formSection/FormSection";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";
import FormRadio from "msa2-ui/src/components/formSection/FormRadio";
import Microservice from "msa2-ui/src/services/Microservice";
import Repository from "msa2-ui/src/services/Repository";
import { MsaSelectCreatable } from "msa2-ui/src/components/msa-select";
import isEmpty from "lodash/isEmpty";

export const useStyles = makeStyles((theme) => ({
  formSelectPadding: {
    paddingBottom: "1.4rem",
  },
  sectionHeader: {
    fontSize: "1.5rem",
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 20,
  },
  formContainer: {
    marginTop: 0,
    padding: "3px 10px",
  },
  hidden: {
    display: "none",
  },
  category: {
    padding: 16,
    width: "100%",
  },
  vendor: {
    padding: 16,
    width: "100%",
  },
  model: {
    padding: 16,
    width: "100%",
  },
}));

const InformationTabContentCreate = ({
  change,
  manufacturersById,
  manufacturers,
  modelsById,
  isActive,
  setDeviceAdapterError,
  deviceAdapterError,
  setCheckVendor,
  setCheckModel,
}) => {
  const { t } = useTranslation();
  const form = MicroserviceForm.CreateFormName;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { variablePath } = MicroserviceForm.formConfig;
  const parserFormPath = MicroserviceForm.getParserPath(
    useSelector(getFormValues(form, MicroserviceForm.fields.command)),
  );
  const [submitting] = useState(false);

  const formVariableValues =
    useSelector(getFormValues(form, variablePath)) || [];

  const vendor = useSelector(
    getFormValues(form, MicroserviceForm.fields.vendor),
  );
  const [category, setCategory] = useState("");

  const model = useSelector(getFormValues(form, MicroserviceForm.fields.model));

  const fileName = useSelector(
    getFormValues(form, MicroserviceForm.fields.name),
  );

  const formCategories = useSelector(
    getFormValues(form, MicroserviceForm.fields.category),
  );

  const categories = isEmpty(formCategories)
    ? []
    : formCategories?.split("|").map((c) => ({ label: c, value: c }));

  const formDirectoryNames = useSelector(
    getFormValues(form, MicroserviceForm.fields.directoryNames),
  );

  const directoryNames =
    formDirectoryNames?.map((c) => ({ label: c, value: c })) || [];

  const [isOptionsLoading, , options = []] = useApi(listAllLabels, {
    category: listAllLabels.categories.MICROSERVICE,
    transforms: [
      (response) => response.map((label) => ({ label, value: label })),
    ],
  });

  useEffect(() => {
    change(
      [MicroserviceForm.fields.uri],
      Microservice.INIT_PATH +
        "/" +
        manufacturersById[vendor]?.manufacturerName +
        "/" +
        modelsById[model]?.modelName +
        "/" +
        Repository.convertStringToFilename(fileName?.trim(), "xml"),
    );
  }, [change, vendor, model, manufacturersById, modelsById, fileName]);

  const handleVendorChange = (value) => {
    const { category, vendor, model } = value;
    setCategory(category);
    change(
      [MicroserviceForm.fields.vendor],
      vendor ? vendor.manufacturerId : null,
    );
    change([MicroserviceForm.fields.model], model ? model.modelId : null);
    setCheckVendor(vendor ? vendor.manufacturerId : null);
    setCheckModel(model ? model.modelId : null);

    if (vendor && model) {
      setDeviceAdapterError(null);
    }
  };

  const handleChange = (newCategories) => {
    let categoryList = [];
    if (newCategories != null) {
      categoryList = newCategories.map((c) => c.value);
    }
    change([MicroserviceForm.fields.category], categoryList.join("|"));
  };

  const handleDirectoryNamesChange = (newDirectoryNames = []) => {
    change(
      [MicroserviceForm.fields.directoryNames],
      Array.isArray(newDirectoryNames)
        ? newDirectoryNames.map((c) => c.value)
        : [],
    );
  };

  const filterOption = ({ label }, subStr) => label.includes(subStr);

  return (
    <>
      <div
        className={classnames({
          [classes.hidden]: !isActive,
        })}
      >
        <Grid item xs={12} md={12}>
          <Typography className={classes.sectionHeader}>
            {t("Microservice Information")}
          </Typography>
        </Grid>
        <Paper className={commonClasses.commonPaperNoPadding}>
          <Grid
            container
            direction={"row"}
            spacing={2}
            className={classes.formContainer}
          >
            <FormSection title={t("Basic Information")} loading={submitting}>
              <Grid container item xs={8} md={8} direction={"row"} spacing={2}>
                <Grid item xs={12}>
                  <FieldSelector
                    label={t("Microservice Name")}
                    type="text"
                    name={MicroserviceForm.fields.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MsaSelectCreatable
                    id="DIRECTORY_INPUT"
                    options={options}
                    label={t("Category")}
                    placeholder={t("Add Category")}
                    isClearable
                    isMulti
                    isSearchable
                    value={directoryNames}
                    onChange={handleDirectoryNamesChange}
                    onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
                    isLoading={isOptionsLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MsaSelectCreatable
                    id="CATEGORY_INPUT"
                    options={options}
                    filterOption={filterOption}
                    label={t("Tags")}
                    placeholder={t("Add Tags")}
                    isClearable
                    isMulti
                    isSearchable
                    value={categories}
                    onChange={handleChange}
                    onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
                    isLoading={isOptionsLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSelect
                    label={t("Variable for Microservice Object Name")}
                    type="text"
                    name={MicroserviceForm.fields.displayField}
                    className={classes.formSelectPadding}
                  >
                    {!formVariableValues.some(
                      ({ name }) => name === "params.object_id",
                    ) && <MenuItem value={"object_id"}>{"object_id"}</MenuItem>}
                    {formVariableValues.map(({ name, displayName }) => (
                      <MenuItem value={Variable.removePrefix(name)} key={name}>
                        {displayName}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>

                <DeviceAdapterSelector
                  error={deviceAdapterError}
                  onSelectDeviceAdapter={handleVendorChange}
                  category={category}
                  vendor={vendor}
                  model={model}
                  categoryProps={{ placeholder: t("Filter by Category...") }}
                  vendorProps={{ placeholder: t("Select Vendor...") }}
                  modelProps={{ placeholder: t("Select Model...") }}
                  categoryGridProps={{
                    xs: 6,
                    md: 4,
                    className: classes.category,
                  }}
                  vendorGridProps={{ xs: 6, md: 4, className: classes.vendor }}
                  modelGridProps={{ xs: 6, md: 4, className: classes.model }}
                  required
                />

                <Grid item xs={12}>
                  <FormRadio
                    label={t("Configuration Type")}
                    name={MicroserviceForm.fields.configType}
                    options={Object.values(CONFIGURATION_TYPES)}
                    required
                    onChange={(configType) => {
                      change(
                        parserFormPath,
                        Parser.getParserDefinitionByKey("parser", configType)
                          .defaultValue,
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldSelector
                    label={t("Import Rank")}
                    type="number"
                    name={MicroserviceForm.fields.importRank}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldSelector
                    label={t("Description")}
                    type="text"
                    name={MicroserviceForm.fields.description}
                  />
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

InformationTabContentCreate.propTypes = {
  change: PropTypes.func.isRequired,
  manufacturersById: PropTypes.object.isRequired,
  manufacturers: PropTypes.array.isRequired,
  modelsById: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
};

InformationTabContentCreate.defaultProps = {
  isActive: true,
};

export default flow(
  withRouter,
  reduxForm({
    form: MicroserviceForm.CreateFormName,
    forceUnregisterOnUnmount: false,
    destroyOnUnmount: false,
  }),
)(InformationTabContentCreate);
