import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import { doDownload } from "msa2-ui/src/api/managedEntity";
import { useSelector } from "react-redux";
import { getToken } from "msa2-ui/src/store/auth";
import { useSnackbar } from "notistack";
import DiffDialog from "msa2-ui/src/components/diffDialog/DiffDialog";
import BackupDialog from "./history-dialogs/BackupDialog";
import RestoreDialog from "./history-dialogs/RestoreDialog";

const ManagedEntityHistoryButtons = ({
  deviceId,
  revisionsEntries,
  selectedRevisions,
  hideBackup = false,
  hideDiff = false,
  maintenanceMode,
  reloadRevisions,
}) => {
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const { enqueueSnackbar } = useSnackbar();

  const [showBackupDialog, setShowBackupDialog] = useState(false);
  const [showRestoreDialog, setShowRestoreDialog] = useState(false);

  const [isDiffDialogShown, setIsDiffDialogShown] = useState(false);

  const isRestoreEnabled = selectedRevisions.length === 0;
  const isRollbackEnabled = selectedRevisions.length === 1;
  const isDiffEnabled = selectedRevisions.length === 2;
  const isDownloadEnabled = selectedRevisions.length === 1;

  const handleDownload = async () => {
    const onError = () => {
      enqueueSnackbar(t("Unable to download contents"), {
        variant: "error",
      });
    };

    const r = selectedRevisions[0];

    await doDownload({
      token,
      deviceId,
      revision: r,
      fileName: `${r}_running.conf`,
      onError,
    });
  };

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      {showBackupDialog && (
        <BackupDialog
          deviceId={deviceId}
          title={t("Confirm x", { x: t("Backup") })}
          content={t("Are you sure you want to backup the configuration?")}
          onBackup={() => {
            setShowBackupDialog(false);
            reloadRevisions();
          }}
          onClose={() => setShowBackupDialog(false)}
        />
      )}
      {showRestoreDialog && (
        <RestoreDialog
          deviceId={deviceId}
          revision={selectedRevisions[0]}
          title={t("Confirm x", { x: t("Restore") })}
          content={t("Are you sure you want to restore this configuration?")}
          onRestore={() => {
            setShowRestoreDialog(false);
            reloadRevisions();
          }}
          onClose={() => setShowRestoreDialog(false)}
        />
      )}
      {isDiffDialogShown && (
        <DiffDialog
          title={t("Diff")}
          maxWidth={"xl"}
          entries={revisionsEntries.filter(({ revision }) =>
            selectedRevisions.includes(revision),
          )}
          deviceId={deviceId}
          onClose={() => setIsDiffDialogShown(false)}
        />
      )}

      {!maintenanceMode && !hideBackup && (
        <DelegationProfiles
          type={delegationProfileTypes.MANAGED_ENTITIES}
          action="history.backup"
        >
          <Grid item>
            <Button
              id="MANAGED_ENTITY_HISTORY_PUSH_BTN"
              variant="contained"
              color="primary"
              className={commonClasses.commonBtnPrimary}
              onClick={() => setShowBackupDialog(true)}
              disabled={!isRestoreEnabled}
            >
              {t("Backup")}
            </Button>
          </Grid>
        </DelegationProfiles>
      )}
      {!maintenanceMode && (
        <DelegationProfiles
          type={delegationProfileTypes.MANAGED_ENTITIES}
          action="history.restore"
        >
          <Grid item>
            <Button
              id="MANAGED_ENTITY_HISTORY_PULL_BTN"
              variant="contained"
              color="primary"
              className={commonClasses.commonBtnPrimary}
              onClick={() => setShowRestoreDialog(true)}
              disabled={!isRollbackEnabled}
            >
              {t("Restore")}
            </Button>
          </Grid>
        </DelegationProfiles>
      )}
      {!hideDiff && (
        <Grid item>
          <Button
            id="MANAGED_ENTITY_HISTORY_DIFF_BTN"
            variant="contained"
            color="primary"
            className={commonClasses.commonBtnPrimary}
            onClick={() => setIsDiffDialogShown(true)}
            disabled={!isDiffEnabled}
          >
            {t("Diff")}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          id="MANAGED_ENTITY_HISTORY_GET_BTN"
          variant="contained"
          color="primary"
          className={commonClasses.commonBtnPrimary}
          onClick={handleDownload}
          disabled={!isDownloadEnabled}
        >
          {t("Download")}
        </Button>
      </Grid>
    </Grid>
  );
};

ManagedEntityHistoryButtons.propTypes = {
  deviceId: PropTypes.number.isRequired,
  revisionsEntries: PropTypes.array.isRequired,
  selectedRevisions: PropTypes.array.isRequired,
  hideBackup: PropTypes.bool,
  hideDiff: PropTypes.bool,
};

export default ManagedEntityHistoryButtons;
