import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import { Grid, TableCell, makeStyles } from "@material-ui/core";

import Variable from "msa2-ui/src/services/Variable";
import { RunTimeVariablesTable } from "msa2-ui/src/components/msa-console";
import { Fragment } from "react";

const useStyles = makeStyles(({ darkMode, palette, colors }) => ({
  tableCell: {
    fontSize: "0.8125rem",
    borderRight: `1px solid ${
      darkMode ? palette.text.hint : colors.greyLight2
    }`,
  },
  noPadding: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

const VariableViewTableCells = ({ variables, data, components, ...props }) => {
  const classes = useStyles();

  const { MSAVariable } = components;

  return variables.map((variable, i) => {
    if (!Variable.shouldShow(variable)) return null;

    const id = Variable.removePrefix(variable.name);
    const { tableSection, isTable } = Variable.getTableValData(variable.name);

    if (isTable) {
      // If Variable is in the same array group as the previous loop,
      // return null as it's already rendered
      const hasBeenRendered =
        i > 0 &&
        tableSection ===
          Variable.getTableValData(variables[i - 1].name).tableSection;
      if (hasBeenRendered) return null;

      const { size: tableSize } = Variable.getTableSize(variables, i);
      return (
        <TableCell
          key={variable.name}
          style={{ width: variable?.maxLength || Variable.DEFAULT_TABLE_WIDTH }}
          className={classnames(classes.tableCell, classes.noPadding)}
          {...props}
          colSpan={tableSize}
        >
          <RunTimeVariablesTable
            variables={variables.slice(i, i + tableSize)}
            data={data}
            currentPath={tableSection}
            noHeader
            MSAVariable={MSAVariable}
          />
        </TableCell>
      );
    }

    // grouped variable should be in the same cell with concatenated all together.
    if (variable.grouped) {
      // If groupDisplayName is the same as the previous loop,
      // return null as it's already rendered
      if (i !== 0) {
        const hasBeenRendered =
          variable.groupDisplayName === variables[i - 1].groupDisplayName;
        if (hasBeenRendered) return null;
      }

      const { size: colSpan } = Variable.countSameValue(variables, i, [
        "grouped",
        "groupDisplayName",
      ]);
      return (
        <TableCell key={variable.name} className={classes.tableCell} {...props}>
          <Grid container>
            {variables.slice(i, i + colSpan).map((variable, j) => (
              <Fragment key={j}>
                {j !== 0 &&
                  Variable.removePrefix(variable.name) in data &&
                  data[Variable.removePrefix(variable.name)] !== null &&
                  (variable.groupSeparator || <>&nbsp;</>)}
                <span style={{ width: "fit-content" }}>
                  <MSAVariable
                    id={Variable.removePrefix(variable.name)}
                    data={data}
                    variable={variable}
                  />
                </span>
              </Fragment>
            ))}
          </Grid>
        </TableCell>
      );
    }

    return (
      <TableCell key={variable.name} className={classes.tableCell} {...props}>
        <MSAVariable id={id} data={data} variable={variable} />
      </TableCell>
    );
  });
};

VariableViewTableCells.propTypes = {
  variables: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};

export default VariableViewTableCells;
