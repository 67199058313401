import React from "react";
import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

/**
 * MATERIAL UI
 */
import { withStyles } from "@material-ui/core";

/**
 * COMPONENTS
 */
import Snackbar from "msa2-ui/src/components/Snackbar";
import { snackbarVariantStyles } from "msa2-ui/src/styles/commonStyles";

const SnackbarProvider = ({ classes, children }) => {
  const isTrialMSA = FeatureFlag.isEnabled(FeatureFlag.features.trial);
  return (
    <NotistackSnackbarProvider
      classes={{
        base: classes.default,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isTrialMSA ? "left" : "right",
      }}
      hideIconVariant
      children={(key) => <Snackbar id={key} />}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default withStyles(snackbarVariantStyles)(SnackbarProvider);
