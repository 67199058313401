import i18n from "i18next";

import set from "lodash/set";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import flow from "lodash/flow";
import difference from "lodash/difference";
import flatten from "lodash/flatten";
import pick from "lodash/pick";

import Validation from "msa2-ui/src/services/Validation";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import Microservice from "msa2-ui/src/services/Microservice";
import Workflow from "msa2-ui/src/services/Workflow";
import Process from "msa2-ui/src/services/Process";
import { isValidNumber } from "msa2-ui/src/utils/common";

const TYPES = {
  AUTOINCREMENT: "AutoIncrement",
  IPADDRESS: "IpAddress",
  IPMASK: "IpMask",
};

const DEFAULT_TABLE_WIDTH = 100;
const DISPLAY_NAMES = {
  AutoIncrement: "Auto Increment",
  Customer: "Subtenant",
  Device: "Managed Entity",
  ManagedEntity: "Managed Entity",
  Index: "Unique value",
  IpAddress: "IP Address",
  IpMask: "IP Mask",
  Ipv6Address: "IPv6 Address",
  OBMFRef: "Microservice Reference",
  ServiceRef: "Workflow Reference",
  Code: "Code",
};

const MAP_LEGACY_KEY_TO_NEW = {
  Customer: "Subtenant",
  Device: "ManagedEntity",
};

const mandatoryItems = ["object_id"];
const variablePrefix = "params";
const integerTypes = ["Integer", "AutoIncrement"];
const isNumberVariable = (variable) => integerTypes.includes(variable.type);
const freeFormatTypes = ["String", "Code", "Password", "Link", "Index"];
const isFreeFormatVariable = (variable) =>
  freeFormatTypes.includes(variable.type) &&
  // If drop-down list is defined, it's not free format.
  // But if it checks "Allow adding free value", it's free format
  !(Boolean(variable.values?.length) && !variable.editable);

const initVariable = {
  displayName: "",
  fullDisplayName: "",
  displayNameHeader: "",
  displayOrder: 0,
  name: variablePrefix + ".",
  description: "",
  type: "String",
  visible: true,
  userLocked: false,
  onlyDetailView: false,
  grouped: false,
  groupDisplayName: "",
  groupSeparator: "",
  mandatory: false,
  editable: false,
  arrayCanAdd: true,
  arrayCanRemove: true,
  arrayCanMove: true,
  arrayCanEdit: true,
};
const getInitVariableByType = (
  advancedVariableParametersByName,
  advancedVariableParameters,
  type = initVariable.type,
) => ({
  ...initVariable,
  // Get default value corresponding variable type from the response of extended-paramter API
  ...advancedVariableParameters[type].parameters.reduce(
    (acc, parameterName) => {
      const advancedVariableParameter =
        advancedVariableParametersByName[parameterName];
      return advancedVariableParameter?.defaultValue
        ? {
            ...acc,
            [parameterName]: advancedVariableParameter.defaultValue,
          }
        : acc;
    },
    {},
  ),
});

const removePrefix = (item) => item?.replace(/^params\./, "");

const cleanVariableName = (string = "") => {
  return string.replace(/[^a-z0-9._-]/gi, "");
};

const getDisplayValueForList = (variable, displayValue) =>
  variable.values.find((value) => value.actualValue === displayValue)
    ?.displayValue ?? displayValue;

const isValidVariableName = (variablePath, variable, errors, errorMessages) => {
  if (variable.name && variable.name.trim() === variablePrefix + ".") {
    set(errors, variablePath, errorMessages.valid);
  }
  if (variable.name === variablePrefix + ".") {
    set(errors, variablePath, errorMessages.required);
  }
};

const isValidRegex = (value) => {
  try {
    new RegExp(value);
    return true;
  } catch (e) {
    return false;
  }
};

const validate = ({ values, requiredFields, uniqueFields, formPath }) => {
  const errorMessages = {
    valid: i18n.t("Invalid"),
    required: i18n.t("This is a required field"),
    duplicated: i18n.t("Duplicated"),
  };
  const errors = {};

  const variables = get(values, formPath.split("."));

  variables &&
    variables.forEach((variable, index) => {
      uniqueFields.forEach(({ displayOn, comparisonOn }) => {
        if (Validation.unique(variables, comparisonOn)) {
          set(
            errors,
            `${formPath}.${index}.${displayOn}`,
            Validation.unique(variables, comparisonOn),
          );
        }
        const structureError = Validation.objectStructure(variables, variable);
        if (structureError) {
          set(errors, `${formPath}.${index}.${comparisonOn}`, structureError);
        }
      });

      requiredFields.forEach((field) => {
        if (Validation.required(variable[field])) {
          set(
            errors,
            `${formPath}.${index}.${field}`,
            Validation.required(variable[field]),
          );
        }
      });

      requiredFields.forEach((field) => {
        if (Validation.validString(variable[field])) {
          set(
            errors,
            `${formPath}.${index}.${field}`,
            Validation.validString(variable[field]),
          );
        }
      });
      isValidVariableName(
        `${formPath}.${index}.name`,
        variable,
        errors,
        errorMessages,
      );
    });

  return errors;
};

/**
 * Gets variable ids from the variable name
 * @param varName original variable name (ex. "params.device.0.id")
 * @param depth optional. Pass depth for variables.
 *        (ex. "params.deep.0.device.0.id"
 *               depth: 1 => { "tableSection": "deep", tableKey: "device.0.id" }
 *               depth: 2 => { "tableSection": "deep.0.device", tableKey: "id" }
 *        )
 * @return id, tableArray, tableDivPoint, tableSection, tableKey
 */
const getTableValData = (varName, depth = 1) => {
  // variable name without "params."
  const id = removePrefix(varName);
  const tableArray = id.split(".");
  const arrayDepth = tableArray.filter((section) => section === "0").length;
  const arrayBreakPoints = tableArray.reduce(
    (acc, cur, i) => (cur === "0" ? acc.concat(i) : acc),
    [],
  );
  const tableDivPoint =
    arrayBreakPoints[depth <= arrayDepth ? depth - 1 : 0] || -1;
  const isTable = tableDivPoint >= 0;
  // variable section (ex. device.0.id => device)
  const tableSection = tableArray.slice(0, tableDivPoint).join(".");
  // variable key (ex. device.0.id => id)
  const tableKey = tableArray.slice(tableDivPoint + 1).join(".");
  // variable section for deep objects (ex. "params.deep.0.deeper.0.device.0.id" => device)
  const deepTableSection =
    depth > 1 && depth <= arrayDepth
      ? tableArray
          .slice(arrayBreakPoints[depth - 2] + 1, tableDivPoint)
          .join(".")
      : tableSection;
  // variable key for deep objects (ex. "params.deep.0.deeper.0.device.0.id" => id)
  const deepTableKey = tableArray[tableArray.length - 1];

  return {
    id,
    tableArray,
    tableDivPoint,
    tableSection,
    tableKey,
    isTable,
    arrayDepth,
    deepTableSection,
    deepTableKey,
  };
};

/**
 * Pops out variable object
 * @param variables variable array object from Rest API (`/orchestration/v1/{ubiqubeId}/workflow/details`)
 * @param varName variable name (ex. "device.0.id")
 * @return variable object
 */
const popTargetVariable = (variables, varName) =>
  variables?.find((arr) => {
    // get a parameter name without "params."
    const { id } = getTableValData(arr.name);
    // get a variable object which has the same name as a parameter
    return id === varName;
    // get a default value
  });

/**
 * @param variable target variable object which includes you want to get default value
 * @param data obj which has selector value
 * @param instanceNum optional. if the target is for existing instance, put an instance number to check the selector
 * @return selector variable object under behaviour object ["permit", 0, "action"]
 */
const getBehaviourObjectBasedOnSelectorValue = (
  variable,
  data,
  targetInstance,
  variables,
) => {
  if (variable?.type !== "Composite" || !variable.selector) {
    return;
  }
  const {
    id: targetId,
    isTable,
    tableArray: targetIdArray,
    tableDivPoint,
  } = getTableValData(variable.selector);
  let selectorValue;

  if (isTable) {
    const isNewInstance =
      typeof targetInstance === "undefined" || targetInstance === null;

    if (!isNewInstance) {
      const instanceNum = get(targetInstance.split("."), tableDivPoint);
      // put current array number
      targetIdArray.splice(1, 1, instanceNum);
    }
    // If this is after new instance is created, there is no value yet.
    // so we determine the selectorValue based on default value for target
    selectorValue = (() => {
      let val;
      if (isNewInstance && variables) {
        val = popTargetVariable(variables, targetId)?.defaultValue;
      }
      return val ?? get(data, targetIdArray.join("."), "");
    })();
  } else {
    selectorValue = get(data, targetId, "");
  }

  // get a variable object under the behaviour object
  return getBehaviour(variable.behaviour, selectorValue);
};

/**
 * Function to determine behaviour for a Composite Type variable based on given selector value
 *
 * @param {Array|Object} behaviour | List of objects or object for variable's behaviour
 * @param {any} selectorValue | The value on basis of which behaviour will be determined
 * @returns {any} Undefined or Behaviour Object
 */
const getBehaviour = (behaviour, selectorValue) => {
  if (!behaviour) {
    return;
  }
  if (Array.isArray(behaviour)) {
    return behaviour.find((variable) => {
      const selectorValueToBeCompared = selectorValue?.toString() ?? "";
      if (isValidRegex(variable.selectorValue)) {
        const pattern = new RegExp(variable.selectorValue);
        return pattern.test(selectorValueToBeCompared);
      } else {
        // Fallback comparison if regex fails
        return variable.selectorValue === selectorValueToBeCompared;
      }
    });
  }
  return behaviour[selectorValue];
};

/**
 * Function to Replace behaviour of Composite type variable based on given data model
 *
 * @param {Object} variable | The variable object
 * @param {Object} data | [Optional] The Data Model object
 * @param {any} id | [Optional] The string for target instance
 * @param {Boolean} editMode | [Optional] In Edit mode it will check visiblity of given variable
 *
 * @returns {Object} | returns determined behaviour or given variable itself
 */
const maybeReplaceWithBehaviourObject = (
  variable,
  data,
  id,
  editMode = false,
) => {
  const getBehaviour = (variable) => {
    const behaviour = getBehaviourObjectBasedOnSelectorValue(
      variable,
      data,
      id,
    );
    if (behaviour?.type === "Composite") {
      return getBehaviour(behaviour);
    }
    return behaviour;
  };

  const behaviour = getBehaviour(variable);

  const { isTable } = getTableValData(variable.name);

  // Note: we are not making invisible table variable right now
  if (editMode && !behaviour && !isTable && variable?.type === "Composite") {
    return { ...variable, visible: false };
  }

  return behaviour || variable;
};

/**
 * Gets default value by provided deep table section
 * @param data selector obj
 * @param deepTableVariable deep table section which act likes the target obj/variable
 * @param variables obj which contains deepTableVariable and may be other property also
 * @param depth how deep we want to go to get default value
 * @return default value in obj
 */
const getDefaultValueByDeepTableSection = ({
  data,
  deepTableVariable = "",
  variables = [],
  depth = 1,
}) => {
  return variables.reduce((acc, variable) => {
    // get ids from the original variable name which includes "params.".
    const { id, tableKey, arrayDepth, deepTableSection } = getTableValData(
      variable.name,
      depth,
    );

    if (deepTableSection !== deepTableVariable) return acc;

    // if the object is deeper, do not create a default value as it should be created in the deep level
    // eg. bad case: { "deep.0.var": "Default Value" }
    if (depth < arrayDepth) return acc;

    // get a variable default value
    const defaultValue = flow(
      (variables, id) => popTargetVariable(variables, id),
      (variable) => getDefaultValue({ variable, variables, data }),
    )(variables, id);

    return {
      ...acc,
      [tableKey]: defaultValue,
    };
  }, {});
};

/**
 * Gets default value
 * @param variable target variable object which includes you want to get default value
 * @param target object which has selector value if the target variable is Composite type
 * @return default value
 */
const getDefaultValue = ({ variable, variables, data, instanceData, key }) => {
  if (variable?.type === "Composite" && data) {
    // if the type is Composite, get variable object from selector and call itself recursively
    const selector = getBehaviourObjectBasedOnSelectorValue(
      variable,
      data,
      key,
      variables,
    );
    if (selector) {
      return getDefaultValue({
        variable: selector,
        variables,
        data,
        instanceData,
        key,
      });
    }
  }
  if (variable?.type === "Boolean") {
    const value = get(variable, "defaultValue", "false");
    return value?.toLowerCase() === "false" ? false : Boolean(value);
  }

  if (variable?.type === "AutoIncrement") {
    const key = removePrefix(variable.name)
      .split(".")
      .slice(-1);

    const existingValues = Object.values(instanceData || {})
      .map((obj) => obj[key])
      .filter((value) => isValidNumber(value))
      .map((value) => parseFloat(value));

    if (!existingValues.length) {
      return variable.startIncrement;
    }

    const latestValue =
      variable.increment >= 0
        ? Math.max(...existingValues)
        : Math.min(...existingValues);

    return (latestValue + variable.increment).toString();
  }

  return get(variable, "defaultValue", "");
};

const getInitData = (variables, instanceData) =>
  variables.reduce(
    (initData, variable) =>
      getTableValData(variable.name).isTable
        ? initData
        : {
            ...initData,
            [removePrefix(variable.name)]: getDefaultValue({
              variable,
              variables,
              instanceData,
            }),
          },
    {},
  );

const shouldShowOnReadOnlyView = (variable) =>
  variable.visible && !variable.onlyDetailView;
const shouldShowOnEditView = (variable) => variable.visible;
const shouldShow = (_variable, canEdit, data, id) => {
  const variable = maybeReplaceWithBehaviourObject(
    _variable,
    data,
    id,
    canEdit,
  );
  return canEdit
    ? shouldShowOnEditView(variable)
    : shouldShowOnReadOnlyView(variable);
};

const filterVariables = (variables, variableNamesToShow) =>
  variables.filter((variable) =>
    variableNamesToShow
      ? variableNamesToShow.includes(removePrefix(variable.name))
      : true,
  );

const filterInvisible = (variables, data, filterTable) =>
  variables.filter((variable) => {
    const { isTable } = getTableValData(variable.name);
    return variable?.type === "Composite" && data && (filterTable || !isTable)
      ? flow(
          (variable) => getBehaviourObjectBasedOnSelectorValue(variable, data),
          (selectorVariable) =>
            shouldShowOnEditView(selectorVariable ?? variable),
        )(variable)
      : shouldShowOnEditView(variable);
  });

const filterEditOnly = (variables, data) =>
  variables.filter((variable) =>
    flow(
      (variable) => maybeReplaceWithBehaviourObject(variable, data),
      (variable) => shouldShowOnReadOnlyView(variable),
    )(variable),
  );

// returns display name for the error variables
const missingMandatory = (variables, data) =>
  variables
    .filter((variable) => {
      // if not mandatory variable, skip validating
      if (!variable.mandatory) return false;
      const { isTable, tableSection, tableKey } = getTableValData(
        variable.name,
      );
      if (isTable) {
        const nestedData = get(data, tableSection);
        if (!Array.isArray(nestedData)) return false;
        // check all data for the target variable. if at least one data is empty, return error
        return nestedData.some(
          (nestedData) =>
            nestedData[tableKey] == null || nestedData[tableKey] === "",
        );
      }
      const value = get(data, removePrefix(variable.name));
      return value == null || value === "";
    })
    .map((variable) => variable.displayName);

const isUniqueValueInMicroservice = (
  value,
  variable,
  microserviceInstanceRows = [],
  data,
) => {
  const microserviceInstanceWithoutSelf = microserviceInstanceRows.filter(
    (instance) => instance.object_id !== data.object_id,
  );
  const checkIsUniqueValue = (variableValue, variableName, msInstances) => {
    const { isTable, tableSection, tableKey } = getTableValData(variableName);

    if (isTable) {
      return msInstances.every((row) => {
        const nestedData = get(row, tableSection);

        if (!Array.isArray(nestedData)) {
          return true;
        }

        return nestedData.every((d) => d[tableKey] !== variableValue);
      });
    }

    const name = removePrefix(variableName);
    return msInstances.every((row) => row[name] !== variableValue);
  };
  const indexVariables = variable.indexVariables ?? [];
  const isUniqueWithinRow = indexVariables.every((variableName) =>
    checkIsUniqueValue(value, variableName, [data]),
  );
  if (!isUniqueWithinRow) {
    return false;
  }

  return indexVariables
    .concat(variable.name)
    .every((variableName) =>
      checkIsUniqueValue(value, variableName, microserviceInstanceWithoutSelf),
    );
};

const isMandatoryItem = (variableName, form) =>
  // Only Microservice needs this check
  MicroserviceForm.isMicroserviceForm(form) &&
  mandatoryItems.includes(removePrefix(variableName));

const isKeyVariable = (variableName, form) => {
  const id = removePrefix(variableName);
  if (MicroserviceForm.isMicroserviceForm(form)) {
    return id === Microservice.KEY_VARIABLE;
  } else {
    return id === Workflow.KEY_VARIABLE;
  }
};

const convertVariablesBasedOnProcess = (variables, processVariable) => {
  const flattenProcessVar = Object.keys(processVariable).reduce(
    (acc, processName) => [...acc, ...processVariable[processName]],
    [],
  );
  return variables.map((variable) => {
    const variableInProcess = flattenProcessVar.find(
      (processVariable) => processVariable.name === removePrefix(variable.name),
    );
    return {
      ...variable,
      // If non-default value is set in Process variable, replace it
      type:
        variableInProcess && variableInProcess.type !== initVariable.type
          ? variableInProcess.type
          : variable.type,
      defaultValue:
        variableInProcess &&
        !isEmpty(variableInProcess.default_value) &&
        variableInProcess.default_value !== "null"
          ? variableInProcess.default_value
          : variable.defaultValue,
      values:
        variableInProcess &&
        !isEmpty(variableInProcess.values) &&
        variableInProcess.values !== "[]" &&
        variableInProcess.values !== ""
          ? variableInProcess.values
          : variable.values,
    };
  });
};

const filterVariablesBasedOnProcess = (variables, processVariables) => {
  const processVariableNames = flatten(Object.values(processVariables)).map(
    (variable) => variable.name,
  );
  return variables.filter(({ name }) =>
    processVariableNames.includes(removePrefix(name)),
  );
};

const getShownVariables = (variables, processVariables, data) =>
  flow(
    (variables) => filterInvisible(variables, data),
    (variables) =>
      filterVariables(
        variables,
        flatten(Object.values(processVariables)).map(
          (variable) => variable.name,
        ),
      ),
  )(variables);

const getDataToPost = (data, processType, variables, processVariables) => {
  // We need to populate all default values so we don't filter values for Create Process
  if (Process.isCreate(processType)) return data;

  const shownVariables = processVariables
    ? getShownVariables(variables, processVariables, data)
    : variables;
  const keysToPick = shownVariables.map(({ name }) => {
    const { isTable, tableSection } = getTableValData(name);
    return isTable ? tableSection : removePrefix(name);
  });
  return pick(data, keysToPick);
};

const convertObjectPath = (variablesWithObjectPath) =>
  Object.entries(variablesWithObjectPath).reduce(
    (acc, [key, value]) => set(acc, key, value),
    {},
  );

const createDefaultValue = (variables) =>
  variables.reduce((acc, variable) => {
    const { id, isTable } = getTableValData(variable.name);
    return isTable
      ? // do not populate default value for array variables as they should be added by UI operation
        acc
      : // for other variables, populate all default values
        // even if they are hidden, default value should be generated
        {
          ...acc,
          [id]: getDefaultValue({ variable, variables }),
        };
  }, {});

/**
 * Swap array number
 * @param name e.g. params.this.is.table.0.nest.0.string
 * @param index e.g. 2
 * @param breakPoint optional. the index for sections which has "0" in variable name
 * @return params.this.is.table.2.nest.0.string
 */
const swapArrayNumberInVariableName = (
  name,
  index,
  breakPoint = getTableValData(name).tableDivPoint,
) =>
  removePrefix(name)
    .split(".")
    .map((section, i) => (i === breakPoint ? index : section))
    .join(".");

const getTableSize = (variables, startFrom, editMode, depth) => {
  return countSameValue(
    variables,
    startFrom,
    [(variable) => getTableValData(variable.name, depth).tableSection],
    editMode,
  );
};

const countSameValue = (variables, currentIndex, parameterName, editMode) => {
  const parameterNames = Array.isArray(parameterName)
    ? parameterName
    : [parameterName];
  return variables.slice(currentIndex).reduce(
    (acc, variable, i, arr) => {
      const show = shouldShow(variable, editMode);
      const allValuesNotSame = parameterNames.some((parameterName) => {
        const getName = (variable) =>
          isFunction(parameterName)
            ? parameterName(variable)
            : variable[parameterName];
        const currentName = getName(variables[currentIndex]);
        return currentName !== getName(variable);
      });
      if (allValuesNotSame) {
        // break
        arr.splice(1);
        return { size: i, hidden: acc.hidden };
      }
      return {
        // returns the last iterator (+1 to represent length)
        size: i + 1,
        hidden: show ? acc.hidden : acc.hidden + 1,
      };
    },
    { size: 0, hidden: 0 },
  );
};

/**
 * When you change the selector value for Composite, we need to change value for Composite variable
 * based on the default value set in behaviour object.
 * This function set the value and merge it data object
 *
 * @param variables variable array object from Rest API (`/orchestration/v1/{ubiqubeId}/workflow/details`)
 * @param data current data object to append changes
 * @param key changed item name by onChange
 * @param value changed item value by onChange
 * @return data object with default value appended
 */
const maybeChangeValueForComposite = (variables, data, _key, value) => {
  const tempData = data;
  const key =
    !Array.isArray(_key) && (_key.match(/\./g) || []).length > 1
      ? _key.split(".")
      : _key;

  const selector = Array.isArray(key) ? key[0] + ".0." + key[2] : key;
  const setValueToControlledItems = (variable) => {
    const { isTable, tableSection, tableKey, tableArray } = getTableValData(
      variable.name,
    );
    if (
      variable.type === "Composite" &&
      variable.behaviour &&
      variable.selector
    ) {
      // look up variable object by setting selector value
      const target = getBehaviour(variable.behaviour, value);
      const selectorTableData = getTableValData(variable.selector);
      const path = `${tableSection}.${key[1]}.${tableKey}`;
      if (
        selector === selectorTableData.id &&
        isTable === selectorTableData.isTable
      ) {
        set(
          tempData,
          isTable ? path : tableArray,
          target
            ? getDefaultValue({
                variable: target,
                variables,
                data,
                key,
              })
            : "",
        );
      }
      // loop objects under behaviour object and call myself recursively
      const behaviour = Array.isArray(variable.behaviour)
        ? variable.behaviour
        : Object.values(variable.behaviour);
      behaviour.forEach((selectorObj) => {
        setValueToControlledItems(selectorObj);
      });
    }
  };

  variables.forEach((variable) => {
    setValueToControlledItems(variable);
  });
  return tempData;
};

/**
 * Compare passed variable names to the existing variable object,
 * then if there are new variable names, return them as variable objects.
 * @param {array} existingVariables The array of variable object
 * @param {array} inputVariables The array of variable with name
 * @returns {array} The array of new variable object
 */
const getVariablesToAdd = (
  existingVariables,
  inputVariables,
  advancedVariableParametersByName,
  advancedVariableParameters,
) => {
  const newVariableNames = difference(
    inputVariables.map(({ name }) => name),
    existingVariables.map(({ name }) => name),
  );
  return newVariableNames.map((variableName) => {
    const inputVariable = inputVariables.find(
      ({ name }) => name === variableName,
    );
    const { deepTableKey } = getTableValData(variableName);
    return {
      ...(advancedVariableParametersByName && advancedVariableParameters
        ? getInitVariableByType(
            advancedVariableParametersByName,
            advancedVariableParameters,
            inputVariable.type,
          )
        : initVariable),
      ...inputVariable,
      displayName: deepTableKey,
    };
  });
};

/**
 * @param runTimeId id with array number (eg. "deep.1.object.3.ms_ref.2.id")
 * @return variableName variable.name (eg. "params.deep.0.object.0.ms_ref.0.id")
 */
const convertKeyToName = (runTimeId) => {
  const id = runTimeId
    .split(".")
    .map((key) => (isNaN(key) ? key : 0))
    .join(".");
  return [variablePrefix, id].join(".");
};

/**
 * @param variableName variable.name (eg. "params.deep.0.object.0.device_id")
 * @param runTimeId id with array number (eg. "deep.1.object.3.ms_ref.2.id")
 * @return Variable id with array number applied (eg. "deep.1.object.3.device_id")
 */
const getArrayNumberAndApplyToId = (variableName, runTimeId) => {
  const { id, arrayDepth } = getTableValData(variableName);
  const ret = id.split(".");
  [...Array(arrayDepth)].forEach((_, i) => {
    // Loop and get the key based on array depth.
    // 1st time: deep.0
    // 2nd time: deep.0.object.0
    const { tableSection, tableDivPoint } = getTableValData(
      variableName,
      i + 1,
    );
    // Convert .0. into \.\d+\. to match with runtime id.
    // eg. "object.0" should match with "object.3"
    const regexp = new RegExp(`^${tableSection.replace(".0.", "\\.\\d+\\.")}`);
    const matched = runTimeId.match(regexp);
    if (matched) {
      // If the keys match, replace array number in the id to return
      ret[tableDivPoint] = runTimeId.split(".")[tableDivPoint];
    }
  });
  return ret.join(".");
};

const convertErrorsToDisplayName = (syncErrors = {}, variables) =>
  Object.entries(syncErrors).map(([key, message]) => {
    const variableName = convertKeyToName(key);
    const { displayName } = variables.find(({ name }) => name === variableName);
    return `${displayName}: ${message}`;
  });

export default {
  TYPES,
  DEFAULT_TABLE_WIDTH,
  DISPLAY_NAMES,
  MAP_LEGACY_KEY_TO_NEW,
  isValidRegex,
  validate,
  removePrefix,
  initVariable,
  getInitVariableByType,
  variablePrefix,
  mandatoryItems,
  integerTypes,
  isNumberVariable,
  isFreeFormatVariable,
  cleanVariableName,
  getDisplayValueForList,
  popTargetVariable,
  getTableValData,
  getBehaviourObjectBasedOnSelectorValue,
  maybeReplaceWithBehaviourObject,
  getDefaultValue,
  getBehaviour,
  getInitData,
  shouldShow,
  shouldShowOnEditView,
  filterInvisible,
  filterVariables,
  filterEditOnly,
  missingMandatory,
  isMandatoryItem,
  isKeyVariable,
  convertVariablesBasedOnProcess,
  filterVariablesBasedOnProcess,
  getShownVariables,
  getDataToPost,
  convertObjectPath,
  createDefaultValue,
  swapArrayNumberInVariableName,
  getTableSize,
  countSameValue,
  maybeChangeValueForComposite,
  getVariablesToAdd,
  convertKeyToName,
  getArrayNumberAndApplyToId,
  isUniqueValueInMicroservice,
  convertErrorsToDisplayName,
  getDefaultValueByDeepTableSection,
};
