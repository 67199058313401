import React, { useCallback, useMemo, memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { renderToStaticMarkup } from "react-dom/server";
import PropTypes from "prop-types";
import { prepareGraphData } from "msa2-ui/src/routes/ai/generate-bpm/network-graph/utils";
import Graph from "msa2-ui/src/routes/ai/generate-bpm/network-graph/Graph";
import Node from "msa2-ui/src/routes/ai/generate-bpm/network-graph/Node";
import ConnectionTooltip from "msa2-ui/src/routes/ai/generate-bpm/network-graph/ConnectionTooltip";
import getFormValues from "redux-form/lib/getFormValues";
import { reduxFormNames } from "msa2-ui/src/Constants";

const formValuesSelector = getFormValues(reduxFormNames.aiParametersForm);

const NetworkGraph = ({ data }) => {
  const aiParameters = useSelector(formValuesSelector, shallowEqual) || {};
  const initialStateValue = aiParameters.initialState?.value;
  const finalStateValue = aiParameters.finalState?.value;

  const graphData = useMemo(
    () => prepareGraphData(data, initialStateValue, finalStateValue),
    [data, initialStateValue, finalStateValue],
  );

  const nodeRenderer = useCallback((node) => {
    return renderToStaticMarkup(
      <Node isActive={node.active} label={node.label} />,
    );
  }, []);

  const connectionTooltipRenderer = useCallback((connectionData) => {
    return renderToStaticMarkup(
      <ConnectionTooltip data={connectionData || []} />,
    );
  }, []);

  return (
    <Graph
      nodes={graphData.nodes}
      connections={graphData.connections}
      nodeRenderer={nodeRenderer}
      connectionTooltipRenderer={connectionTooltipRenderer}
    />
  );
};

NetworkGraph.propTypes = {
  data: PropTypes.shape({
    states: PropTypes.array,
    transitions: PropTypes.array,
  }),
};

export default memo(NetworkGraph);
