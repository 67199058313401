import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import flow from "lodash/flow";
import get from "lodash/get";

import { buildRoute } from "msa2-ui/src/utils/urls";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";

import { withStyles } from "@material-ui/core";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";
import {
  Create,
  DeleteOutlined,
  FileCopyOutlined as IconCopy,
} from "@material-ui/icons";

import Dialog from "msa2-ui/src/components/Dialog";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";
import Utility from "../Functions";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const localStyles = ({
  darkMode,
  palette,
  typography,
  colors,
  breakpoints,
}) => ({
  managedEntity: {
    padding: 20,
    marginLeft: -16,
    width: "calc(100% + 32px)",
    borderBottom: `1px solid ${darkMode ? palette.text.hint : "#e6eaee"}`,
    "&:last-child": {
      borderBottom: 0,
    },
  },
  entityWrapper: {
    height: 40,
  },
  entityName: {
    color: palette.text.primary,
    fontWeight: typography.fontWeightRegular,
    "&:hover": {
      color: colors.blue,
    },
  },
  infoBoxWrapper: {
    width: "100%",
  },
  infoBox: {
    backgroundColor: darkMode ? "transparent" : colors.greyLight2,
    padding: 10,
    alignItems: "center",
  },
  infoBoxList: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  infoBoxListItem: {
    width: "50%",
  },
  infoLists: {
    fontSize: "0.8125rem",
  },
  listItemPrimary: {
    display: "inline-block",
    marginRight: 6,
    color: palette.text.secondary,
    fontWeight: typography.fontWeightLight,
  },
  listItemSecondary: {
    display: "inline-block",
    color: palette.text.primary,
    fontWeight: typography.fontWeightMedium,
    verticalAlign: "bottom",
    maxWidth: 160,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  key: {
    color: palette.text.secondary,
    fontSize: "0.8125rem",
    fontWeight: typography.fontWeightRegular,
    letterSpacing: 0.3,
    maxWidth: 140,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subtitle: {
    color: palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: typography.fontWeightRegular,
    letterSpacing: 0.3,
  },
  subtitleValue: {
    fontWeight: typography.fontWeightMedium,
  },
  value: {
    marginRight: 50,
    color: palette.text.primary,
    fontSize: "13px",
    fontWeight: typography.fontWeightRegular,
  },
  status: {
    marginRight: 10,
  },
  actions: {
    marginLeft: 10,
  },
  tableCell: {
    padding: "3px 12px",
    border: 0,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
  },
  statusIcon: {
    background: "transparent",
    width: 20,
    height: 20,
    marginRight: 3,
  },
  middleElement: {
    width: "60%",
    minHeight: 150,
    [breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rotated: {
    transform: "rotated(-90deg)",
    lineHeight: "1.25rem",
  },
  heading: {
    cursor: "pointer",
    marginRight: 10,
  },
  toolTip: {
    backgroundColor: "white",
    padding: 10,
    boxShadow: "3px 3px 12px 0px #c2c4ca",
  },
  haIcon: {
    border: `solid 1px ${
      darkMode ? palette.text.secondary : "rgba(191, 201, 217, 0.6)"
    }`,
    fontSize: 15,
    fontWeight: "bold",
    color: palette.text.primary,
  },
});

// Combine local and common styles
const styles = (e) =>
  Object.assign(commonStyles.call(this, e), localStyles.call(this, e));

class ManagedEntity extends Component {
  state = {
    selected: false,
    deleteDialogIsOpen: false,
  };

  handleOnMouseEnter = () => {
    this.setState({ selected: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ selected: false });
  };

  handleOpenDeleteDeviceDialog = () => {
    this.setState({ deleteDialogIsOpen: true });
  };

  handleCloseDeleteDeviceDialog = () => {
    this.setState({ deleteDialogIsOpen: false });
  };

  handleOnClickName = () => {
    const { match, managedEntity } = this.props;
    const path = match.path + "/" + managedEntity.deviceID;
    //this goes into history.location.state
    this.props.history.push(path, { managedEntity: managedEntity });
  };

  render() {
    const {
      managedEntity,
      classes,
      t,
      handleDeleteDevice,
      match,
      isMultiSubtenant,
      isMultiTenant,
      isManager,
    } = this.props;

    const deviceId = get(managedEntity, "deviceID", null);
    const hostName = get(managedEntity, "serialNb", "-");
    const tenantId = get(managedEntity, "tenantId", null);
    const tenantName = get(managedEntity, "tenantName", null);
    const subtenantId = get(managedEntity, "subtenantId", null);
    const subtenantIdWithSuffix =
      tenantName && subtenantId ? `${tenantName}A${subtenantId}` : null;

    const isUiHaDisplayEnabled = FeatureFlag.isEnabled(
      FeatureFlag.features.haDisplay,
    );

    const isOtherFieldsVisibleInHaView = !(isUiHaDisplayEnabled && isManager);

    return (
      <div
        className={classnames(
          classes.managedEntity,
          classes.commonTableRowHighlight,
        )}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <Grid container>
          {this.state.deleteDialogIsOpen && (
            <Dialog
              onClose={this.handleCloseDeleteDeviceDialog}
              onExec={() => handleDeleteDevice(managedEntity.deviceId.id)}
              title={t("Confirm Request")}
              content={t("Are you sure you want to remove", {
                name: managedEntity.name,
              })}
            />
          )}
          <Grid item sm={6}>
            <div
              className={classnames(
                classes.commonFlexStartRow,
                classes.entityWrapper,
              )}
            >
              <div className={classes.status}>
                {Utility.chooseAvatar(
                  managedEntity.pingStatus,
                  classes,
                  managedEntity?.maintenanceMode,
                )}
              </div>
              {isUiHaDisplayEnabled && (
                <div className={classes.status}>
                  {Utility.chooseHAAvatar(
                    Utility.getManageEntityHaStatus(
                      managedEntity.haType,
                      false,
                    ),
                    classes,
                  )}
                </div>
              )}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
              <div
                className={classes.heading}
                onClick={this.handleOnClickName}
                role="heading"
              >
                <Tooltip
                  title={
                    t("Managed Entity Id", { deviceId }) +
                    (tenantId ? " | " + t("Tenant Id", { x: tenantId }) : "") +
                    (subtenantId
                      ? " | " + t("Subtenant Id", { x: subtenantIdWithSuffix })
                      : "")
                  }
                >
                  <Typography
                    className={classes.entityName}
                    id={`MANAGED_ENTITY_TITLE_${deviceId}`}
                    variant="h3"
                  >
                    {isUiHaDisplayEnabled
                      ? `${managedEntity.name} - ${managedEntity.deviceId.ubiId}`
                      : managedEntity.name}
                  </Typography>
                </Tooltip>
              </div>

              {Utility.createTag(
                get(managedEntity, "deviceNature", ""),
                classes,
              )}
              {this.state.selected && (
                <div className={classes.actions}>
                  <DelegationProfiles
                    type={delegationProfileTypes.MANAGED_ENTITIES}
                    action="general.modify"
                  >
                    <Tooltip title={t("Edit")}>
                      <Link
                        id="MANAGED_ENTITY_DETAIL_NAV_BACK_TO_MANAGED_ENTITIES"
                        to={{
                          pathname: buildRoute(
                            match.url,
                            `${managedEntity.deviceId.id}/edit`,
                          ),
                        }}
                      >
                        <IconButton
                          id={`MANAGED_ENTITY_DETAIL_BTN_EDIT_${managedEntity.deviceId.id}`}
                        >
                          <Create color="primary" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </DelegationProfiles>
                  <DelegationProfiles
                    type={delegationProfileTypes.MANAGED_ENTITIES}
                    action="general.create"
                  >
                    <Tooltip title={t("Duplicate")}>
                      <Link
                        to={{
                          pathname: buildRoute(
                            match.url,
                            `${managedEntity.deviceId.id}/duplicate`,
                          ),
                        }}
                      >
                        <IconButton
                          id={`MANAGED_ENTITY_DETAIL_BTN_DUPLICATE_${managedEntity.deviceId.id}`}
                        >
                          <IconCopy color="primary" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </DelegationProfiles>
                  <DelegationProfiles
                    type={delegationProfileTypes.MANAGED_ENTITIES}
                    action="general.delete"
                  >
                    <Tooltip title={t("Delete")}>
                      <IconButton
                        id={`MANAGED_ENTITY_BTN_DELETE-${managedEntity.deviceId.id}`}
                        onClick={this.handleOpenDeleteDeviceDialog}
                      >
                        <DeleteOutlined color="error" />
                      </IconButton>
                    </Tooltip>
                  </DelegationProfiles>
                </div>
              )}
            </div>
            {/* Host name */}
            <div className={classes.row}>
              {hostName && (
                <Typography className={classes.subtitle}>
                  {`${t("Router Host Name")}:`}
                  <span className={classes.subtitleValue}>{hostName}</span>
                </Typography>
              )}
              <Typography className={classes.value}>
                {get(managedEntity, "hostname")}
              </Typography>
            </div>
            {/* Ha Peer Name */}
            {isUiHaDisplayEnabled && managedEntity.isHa && (
              <div className={classes.row}>
                <div className={classes.status}>
                  {Utility.chooseHAAvatar(
                    Utility.getManageEntityHaStatus(managedEntity.haType, true),
                    classes,
                  )}
                </div>

                <Typography className={classes.entityName}>
                  <span className={classes.subtitleValue}>
                    {`${managedEntity.haPeerName}- ${managedEntity.haPeerId.ubiId}`}
                  </span>
                </Typography>
              </div>
            )}
          </Grid>

          {/* Information Box */}

          <Grid item xs={6} className={classes.infoBoxWrapper}>
            <Grid container className={classes.infoBox}>
              <List
                disablePadding
                dense
                classes={{ root: classes.infoBoxList }}
              >
                {isMultiTenant && isOtherFieldsVisibleInHaView && (
                  <ListItem classes={{ root: classes.infoBoxListItem }}>
                    <ListItemText
                      primary={`${t("Tenant")}:`}
                      secondary={managedEntity.tenantName}
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listItemSecondary,
                      }}
                      className={classes.infoLists}
                    />
                  </ListItem>
                )}
                {isMultiSubtenant && (
                  <ListItem classes={{ root: classes.infoBoxListItem }}>
                    <ListItemText
                      primary={`${t("Subtenant")}:`}
                      secondary={managedEntity.subtenantName}
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listItemSecondary,
                      }}
                      className={classes.infoLists}
                    />
                  </ListItem>
                )}
                {isOtherFieldsVisibleInHaView && (
                  <ListItem classes={{ root: classes.infoBoxListItem }}>
                    <ListItemText
                      primary={`${t("IP Address")}:`}
                      secondary={managedEntity.ipConfig}
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listItemSecondary,
                      }}
                      className={classes.infoLists}
                    />
                  </ListItem>
                )}
                {isUiHaDisplayEnabled && !managedEntity.isOpenStack && (
                  <ListItem classes={{ root: classes.infoBoxListItem }}>
                    <ListItemText
                      primary={`${t("Region/Group Name")}:`}
                      secondary={managedEntity.groupName}
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listItemSecondary,
                      }}
                      className={classes.infoLists}
                    />
                  </ListItem>
                )}
                {isOtherFieldsVisibleInHaView && (
                  <ListItem classes={{ root: classes.infoBoxListItem }}>
                    <ListItemText
                      primary={`${t("Vendor")}:`}
                      secondary={managedEntity.manufacturerName}
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listItemSecondary,
                      }}
                      className={classes.infoLists}
                    />
                  </ListItem>
                )}
                {isOtherFieldsVisibleInHaView && (
                  <ListItem classes={{ root: classes.infoBoxListItem }}>
                    <ListItemText
                      primary={`${t("Model")}:`}
                      secondary={managedEntity.modelName}
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listItemSecondary,
                      }}
                      className={classes.infoLists}
                    />
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ManagedEntity.propTypes = {
  classes: PropTypes.object.isRequired,
  managedEntity: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isMultiSubtenant: PropTypes.bool.isRequired,
  isMultiTenant: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
};

export default flow(
  withRouter,
  withTranslation("managedEntity"),
  withStyles(styles),
)(ManagedEntity);
