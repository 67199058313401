import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useScreenshot } from "use-react-screenshot";
import jsPDF from "jspdf";
import useDialog from "msa2-ui/src/hooks/useDialog";

import { kibanaLogin } from "msa2-ui/src/api/auth";
import { kibanaGetSavedObject } from "msa2-ui/src/api/kibana";
import { getUserDetails } from "msa2-ui/src/store/auth";
import { useSelector } from "react-redux";
import {
  getAvailableSubtenants,
  getSelectedSubtenant,
} from "msa2-ui/src/store/designations";

import Repository from "msa2-ui/src/services/Repository";

import { makeStyles, Button, TextField } from "@material-ui/core";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

export const WORKFLOW_PATH = "Process/Analytics/Kibana/kibana_dashboard";
const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
  },
  filename: {
    width: 300,
  },
}));

const shouldSkipLogin =
  process.env.NODE_ENV === "production"
    ? // The flag can come as String
      [true, "true"].includes(window.MSA.flags.disableKibanaLogin)
    : false;

const KibanaDashboard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { hash, title = t("Kibana Dashboard") } = props;
  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();

  const { id } = useSelector(getUserDetails);
  const subtenantsListIds = useSelector(getAvailableSubtenants)?.map(
    (ids, index) => ids.id,
  );

  const buildKibanaSearchQuery = (field, valuesList) => {
    const query = valuesList?.join(" OR ");
    return field ? `${field} : (${query})` : 0;
  };

  const subtenantsIds = buildKibanaSearchQuery(
    "customer_id",
    subtenantsListIds,
  );

  const { id: subtenantId } = useSelector(getSelectedSubtenant) ?? "";
  const subtenantsFiltering =
    subtenantId !== null ? `customer_id : ${subtenantId}` : subtenantsIds;

  const kibanaUrl = `/kibana/app/kibana#/dashboard/${hash}?embed=true&show-time-filter=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1d,to:now))&_a=(query:(language:kuery,query:'actor_id:${id} OR ${subtenantsFiltering}'))`;

  const [showDownloadDialog, DownloadDialog] = useDialog();

  const [fileName, setFileName] = useState(
    Repository.convertStringToFilename(title),
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [isLoaded, setIsLoaded] = useState();
  const [pdfParams, setPdfParams] = useState({});

  const onClickSavePDF = () => {
    const iframeDocument = ref?.current?.contentDocument;
    const kibanaBody =
      iframeDocument?.getElementById("kibana-body") ??
      iframeDocument?.getElementsByTagName("div")[0];

    setIsGeneratingImage(true);
    takeScreenshot(kibanaBody).then(() => {
      setIsGeneratingImage(false);
    });

    const A4_LONG_SIDE = 297;
    const A4_SHORT_SIDE = 210;
    const height = kibanaBody.clientHeight;
    const width = kibanaBody.clientWidth;

    const isPortrait = height >= width;
    const a4_height = isPortrait ? A4_LONG_SIDE : A4_SHORT_SIDE;
    const a4_width = isPortrait ? A4_SHORT_SIDE : A4_LONG_SIDE;
    const shouldAdjustHeight = a4_height / a4_width > height / width;

    const orientation = isPortrait ? "portrait" : "landscape";
    const w = shouldAdjustHeight ? a4_width : a4_height * (width / height);
    const h = shouldAdjustHeight ? a4_width * (height / width) : a4_height;
    const x = shouldAdjustHeight ? 0 : (a4_width - w) / 2;
    const y = shouldAdjustHeight ? (a4_height - h) / 2 : 0;

    setPdfParams({ orientation, x, y, w, h });
    showDownloadDialog();
  };

  useEffect(() => {
    if (!shouldSkipLogin && isLoaded === undefined) {
      (async () => {
        setIsLoaded(false);
        const [loginError] = await kibanaLogin({
          username: process.env.REACT_APP_ES_USERNAME,
          password: process.env.REACT_APP_ES_PASSWORD,
          currentURL: kibanaUrl,
        });

        if (loginError) {
          setErrorMessage(
            loginError.getMessage(t("Unable to login to Kibana")),
          );
        } else {
          const [objectError] = await kibanaGetSavedObject({
            type: "dashboard",
            id: hash,
          });

          if (objectError) {
            setErrorMessage(
              objectError.getMessage(t("Unable to load Kibana Dashboard")),
            );
          }
        }
        setIsLoaded(true);
      })();
    }
  }, [hash, isLoaded, kibanaUrl, t]);

  if (!hash) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Typography>{t("Dashboard is not selected.")}</Typography>
      </Grid>
    );
  }

  if (!shouldSkipLogin && !isLoaded) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  if (errorMessage) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Typography>{errorMessage}</Typography>
      </Grid>
    );
  }
  if (shouldSkipLogin || (isLoaded && !errorMessage)) {
    return (
      <>
        <DownloadDialog
          title={t("Preview PDF")}
          maxWidth={"xl"}
          onClose={() => {
            setPdfParams({});
          }}
        >
          {isGeneratingImage ? (
            <CircularProgress />
          ) : (
            <>
              {
                <Grid container justify="flex-end">
                  <Grid item xs={11} container justify="flex-end">
                    <TextField
                      label={t("File Name")}
                      fullWidth
                      className={classes.filename}
                      value={fileName}
                      onChange={({ target: { value } }) => {
                        setFileName(Repository.convertStringToFilename(value));
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} container>
                    <Button
                      id="KIBANA_DASHBOARD_DOWNLOAD_BTN"
                      color="primary"
                      onClick={() => {
                        const { orientation, x, y, w, h } = pdfParams;
                        const doc = new jsPDF({ orientation });
                        doc.addImage(image, "PNG", x, y, w, h);
                        doc.save(`${fileName}.pdf`);
                      }}
                      disabled={!image}
                    >
                      {t("Download")}
                    </Button>
                  </Grid>
                </Grid>
              }
              <img src={image} alt={"Screenshot"} />
            </>
          )}
        </DownloadDialog>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              id="KIBANA_DASHBOARD_SAVE_AS_PDF_BTN"
              color="primary"
              onClick={onClickSavePDF}
            >
              {t("Save as PDF")}
            </Button>
          </Grid>
          <iframe
            src={kibanaUrl}
            ref={ref}
            width="100%"
            height="95%"
            title={title}
            style={{ border: 0 }}
          />
        </Grid>
      </>
    );
  }
  return null;
};

KibanaDashboard.propTypes = {
  hash: PropTypes.string,
  title: PropTypes.string,
};
export default KibanaDashboard;
