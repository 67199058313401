import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  formContentWrapper: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing(4),
  },
  field: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
    width: 70,
  },
}));

const UpsertAlarmFormThresholdTab = ({ formState, dispatch }) => {
  const { count, duration } = formState.rule;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center">
      <Typography>{t("Trigger Notification and Actions after")}:</Typography>
      <Grid
        item
        container
        direction="row"
        className={classes.formContentWrapper}
      >
        <TextField
          id="UPSERT_ALARM_FORM_RULE_COUNT_FIELD"
          size="medium"
          value={count}
          onChange={({ target: { value } }) =>
            dispatch({ type: "updateRuleCount", payload: value })
          }
          variant="outlined"
          inputProps={{
            "data-testid": "upsert-alarm-form-rule-count-field",
            type: "number",
          }}
          className={classes.field}
        />
        <Typography>{t("logs within")}</Typography>
        <TextField
          id="UPSERT_ALARM_FORM_RULE_DURATION_FIELD"
          size="medium"
          value={duration}
          onChange={({ target: { value } }) =>
            dispatch({ type: "updateRuleDuration", payload: value })
          }
          variant="outlined"
          inputProps={{
            "data-testid": "upsert-alarm-form-rule-duration-field",
            type: "number",
          }}
          className={classes.field}
        />
        <Typography>{t("minutes")}</Typography>
      </Grid>
    </Grid>
  );
};

UpsertAlarmFormThresholdTab.propTypes = {
  formState: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default UpsertAlarmFormThresholdTab;
