import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  formField: {
    margin: `${spacing(2)}px 0`,
    textAlign: "left",
  },
  error: {
    marginTop: spacing(2),
    textAlign: "center",
  },
}));

const InformationTab = ({
  entry,
  setEntry,
  tenant,
  setTenant,
  tenants,
  editMode = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { profileName, comment } = entry;

  return (
    <>
      <TextField
        variant="outlined"
        id="PERMISSION_PROFILE_NAME_FIELD"
        label={t("Name")}
        value={profileName}
        required
        onChange={(event) =>
          setEntry({ ...entry, profileName: event.target.value })
        }
        className={classes.formField}
      />
      <BasicSelect
        label={t("Tenant")}
        id="PERMISSION_PROFILE_TENANT_SELECT"
        variant="outlined"
        name="PERMISSION_PROFILE_DIALOG_TENANT_PICKER"
        className={classes.formField}
        value={tenant}
        disabled={editMode}
        required
        onChange={(event) => {
          const { value, id } = event.target.value;
          setEntry({
            ...entry,
            tenantID: id,
            prefix: value,
            attachedManagers: [],
          });
          !editMode && setTenant(event.target.value);
        }}
      >
        {tenants?.map((tenant, index) => (
          <MenuItem
            value={tenant}
            key={index}
            id={`PERMISSION_PROFILE_MANAGER_TENANT_${index}`}
          >
            {tenant.label}
          </MenuItem>
        ))}
      </BasicSelect>
      <TextField
        variant="outlined"
        id="PERMISSION_PROFILE_DESCRIPTION_FIELD"
        label={t("Description")}
        value={comment}
        onChange={(event) =>
          setEntry({ ...entry, comment: event.target.value })
        }
        className={classes.formField}
        multiline
      />
    </>
  );
};

export default InformationTab;
