export const getDimensions = (html) => {
  const el = document.createElement("span");

  el.style.display = "inline-block";
  el.style.visibility = "hidden";

  el.innerHTML = html;

  document.body.append(el);

  const width = el.offsetWidth + 1;
  const height = el.offsetHeight;

  el.remove();

  return { width, height };
};

export const getViewBox = (nodes, container) => {
  const offset = 100;
  const Xs = [];
  const Ys = [];

  nodes.forEach(({ x, y }) => {
    Xs.push(x);
    Ys.push(y);
  });

  if (Xs.length === 0 || Ys.length === 0) {
    return "0 0 0 0";
  }

  const coordinates = {
    x: Math.min(...Xs) - offset,
    y: Math.min(...Ys) - offset,
  };

  const dimensions = {
    width: Math.max(...Xs) - coordinates.x + offset,
    height: Math.max(...Ys) - coordinates.y + offset,
  };

  // We need to adjust viewport for the cases when it is smaller than the viewbox, otherwise we will see scaled nodes.
  if (dimensions.width < container.offsetWidth) {
    const diff = container.offsetWidth - dimensions.width;
    dimensions.width = dimensions.width + diff;
    coordinates.x = coordinates.x - diff / 2;
  }

  return `${coordinates.x} ${coordinates.y} ${dimensions.width} ${dimensions.height}`;
};

const isPointAboveTheLine = (linePoint1, linePoint2, pointToTest) => {
  const { x: x1, y: y1 } = linePoint1;
  const { x: x2, y: y2 } = linePoint2;
  const { x, y } = pointToTest;

  if (x2 - x1 === 0) {
    return true;
  }

  // We are using linear equation for two points to determine if pointToTest is above the line
  const calculatedY = ((y2 - y1) * (x - x1)) / (x2 - x1) + y1;

  return y < calculatedY;
};

// By default we have coordinates of the center of the target node but we need coordinates of the edge of the node.
// So, here we try to calculate coordinates of end point for the connection line
// in a way that it will be on the center of the nearest edge of the node.
export const getLastPointForConnection = (connection) => {
  const { source, target } = connection;
  const { x: sourceX, y: sourceY } = source;
  const { x: centerX, y: centerY } = target;
  const offsetX = target.width / 2;
  const offsetY = target.height / 2;

  /*
  Here we are trying to determine the position of the source point relative to the center of the target which is a rectangle node.
  By using the center of the target node we divide plane into 4 quadrants.

          y
          |
   fourth |  first
          |
    ------ ------ x
          |
    third |  second
          |
  NB! y axis is inverted inside svg

  Then we get a point in the corner of the node (point1) and create an imaginary line from it to the next point (point2).
  By defining position of the source point relative to this line we can say which edge of the node we are gonna use.
  For instance, if source point (A) is in the first quadrant and above this imaginary line we need to connect it to the top edge of the node.
  If it is below then to the right edge of the node.

                  /  <---- This is imaginary line
          |      /
          |  *A /
       ---|--- /
      |   |   |
      |     /
    ----- 0 ------ x
         /
      |   |   |
       ---|---
          |
          y

   */

  const corners = {
    topRight: { x: centerX + offsetX, y: centerY - offsetY },
    bottomRight: { x: centerX + offsetX, y: centerY + offsetY },
    bottomLeft: { x: centerX - offsetX, y: centerY + offsetY },
    topLeft: { x: centerX - offsetX, y: centerY - offsetY },
  };

  const edgeCenters = {
    top: { x: centerX, y: centerY - offsetY },
    bottom: { x: centerX, y: centerY + offsetY },
    right: { x: centerX + offsetX, y: centerY },
    left: { x: centerX - offsetX, y: centerY },
  };

  if (sourceX >= centerX) {
    // second quadrant
    if (sourceY >= centerY) {
      const point1 = corners.bottomRight;
      const point2 = { x: point1.x + 1, y: point1.y + 1 };

      if (isPointAboveTheLine(point1, point2, source)) {
        return edgeCenters.right;
      } else {
        return edgeCenters.bottom;
      }
      // first quadrant
    } else {
      const point1 = corners.topRight;
      const point2 = { x: point1.x + 1, y: point1.y - 1 };

      if (isPointAboveTheLine(point1, point2, source)) {
        return edgeCenters.top;
      } else {
        return edgeCenters.right;
      }
    }
  } else {
    // third quadrant
    if (sourceY >= centerY) {
      const point1 = corners.bottomLeft;
      const point2 = { x: point1.x - 1, y: point1.y + 1 };

      if (isPointAboveTheLine(point1, point2, source)) {
        return edgeCenters.left;
      } else {
        return edgeCenters.bottom;
      }
      // fourth quadrant
    } else {
      const point1 = corners.topLeft;
      const point2 = { x: point1.x - 1, y: point1.y - 1 };

      if (isPointAboveTheLine(point1, point2, source)) {
        return edgeCenters.top;
      } else {
        return edgeCenters.left;
      }
    }
  }
};
