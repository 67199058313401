import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import { throwSignal } from "msa2-ui/src/api/bpm";
import { getToken } from "msa2-ui/src/store/auth";

import { CircularProgress } from "@material-ui/core";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import Dialog from "msa2-ui/src/components/Dialog";

const ContinueBreakpointDialog = ({
  bpmModeler: { modelerState, modelerActions },
  activeElementStatus: { willExecute },
  executionId,
}) => {
  const { activeElement } = modelerState;
  const businessObject = getBusinessObject(activeElement);

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useSelector(getToken);
  const [submitting, setSubmitting] = useState(false);

  const unmount = modelerActions.unselectElement;
  const canContinue = Boolean(executionId);

  const onContinueBPM = async () => {
    setSubmitting(true);
    const signalName = businessObject.eventDefinitions[0].signalRef.name;
    const [error] = await throwSignal({
      name: signalName,
      executionId,
      token,
    });

    const message = error
      ? error.getMessage(t("Unable to continue BPM"))
      : t("Signal accepted");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });

    if (!error) {
      unmount();
    }
    setSubmitting(false);
  };

  const getMessage = () => {
    if (canContinue) {
      return t("Do you want to throw Signal to continue BPM?");
    } else if (willExecute) {
      return t(
        "You can continue BPM when the execution stops at this element.",
      );
    }
    return t("BPM has already passed this element.");
  };

  return (
    <Dialog
      title={t("User Breakpoint")}
      onClose={unmount}
      onExec={canContinue ? onContinueBPM : undefined}
      disabled={submitting}
      content={getMessage()}
    >
      {submitting && <CircularProgress />}
    </Dialog>
  );
};

ContinueBreakpointDialog.propTypes = {
  bpmModeler: PropTypes.shape({
    modelerState: PropTypes.object.isRequired,
    modelerActions: PropTypes.object.isRequired,
    moddle: PropTypes.object,
  }).isRequired,
  activeElementStatus: PropTypes.shape({
    isExecuted: PropTypes.bool,
    isActive: PropTypes.bool,
    willExecute: PropTypes.bool,
  }).isRequired,
  executionId: PropTypes.string,
  statusLoading: PropTypes.bool.isRequired,
};

export default ContinueBreakpointDialog;
