import React from "react";
import { Grid } from "@material-ui/core";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import OverviewTable from "./OverviewTable";

const Overview = ({ sectionTabs }) => {
  return (
    <ErrorBoundary>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OverviewTable sectionTabs={sectionTabs} />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default Overview;
