import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import reduxForm from "redux-form/lib/reduxForm";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import flow from "lodash/flow";
import union from "lodash/union";
import difference from "lodash/difference";

import { getFormValues } from "msa2-ui/src/store/form";
import { getManagedEntitiesBySubtenant } from "msa2-ui/src/api/managedEntity";
import useApi from "msa2-ui/src/hooks/useApi";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import AttachmentBoard from "msa2-ui/src/components/AttachmentBoard";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  filterWrapper: {
    marginBottom: 20,
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
  },
}));

const ManagedEntitiesTab = ({ change, form, submitting }) => {
  const { t } = useTranslation();
  const idKey = "deviceID";
  const displayNameKey = "name";
  const shouldDisableCheckbox = (microservice) =>
    microservice.configProfileId !== 0;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const manufacturerId = useSelector(getFormValues(form, "vendor.id"));
  const modelId = useSelector(getFormValues(form, "model.id"));
  const attachedManagedEntities =
    useSelector(getFormValues(form, "attachedManagedEntities")) || [];
  const subtenantId = useSelector(getFormValues(form, "subtenantId"));
  const [loading, , apiResponse] = useApi(
    getManagedEntitiesBySubtenant,
    {
      subtenantId,
      manufacturerId,
      modelId,
      pageSize: 10000,
      filterByLabel: isPermissionProfileLabelsEnabled,
    },
    !subtenantId,
  );
  const [managedEntities, setManagedEntities] = useState([]);
  const handleAttach = (target) => {
    change(["attachedManagedEntities"], union(attachedManagedEntities, target));
  };
  const handleDetach = (target) => {
    change(
      ["attachedManagedEntities"],
      difference(attachedManagedEntities, target),
    );
  };

  useEffect(() => {
    apiResponse &&
      setManagedEntities(
        apiResponse.filter(
          (me) => me.manId === manufacturerId && me.modId === modelId,
        ),
      );
  }, [manufacturerId, modelId, apiResponse]);

  return (
    <div className={classes.contentWrapper}>
      {loading || submitting ? (
        <div
          className={classnames(
            classes.itemWrapper,
            commonClasses.commonFlexCenter,
          )}
        >
          <CircularProgress />
        </div>
      ) : (
        <AttachmentBoard
          allItems={managedEntities}
          attachedItemIds={attachedManagedEntities}
          noDataMessage={t(
            "There is no Managed Entities associated to Vendor/Model.",
          )}
          idKey={idKey}
          displayNameKey={displayNameKey}
          checkboxTooltipText={t(
            "This Managed Entity already has a Deployment Setting attached",
          )}
          unattachedTitle={t("Select the Managed Entities to add") + ":"}
          attachedTitle={t("Managed Entities added") + ": "}
          searchPlaceholder={t("Search for Managed Entities")}
          handleAttachAll={handleAttach}
          handleAttach={handleAttach}
          handleDetach={handleDetach}
          handleDetachAll={handleDetach}
          shouldDisableCheckbox={shouldDisableCheckbox}
        />
      )}
    </div>
  );
};

ManagedEntitiesTab.propTypes = {
  form: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default flow(reduxForm())(ManagedEntitiesTab);
