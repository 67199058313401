import { get, put } from "./request";
import {
  getProcessInstanceStatus,
  launchProcessInstance,
  getWorkflowInstanceVariableValues,
} from "msa2-ui/src/api/workflow";
import { withRetries } from "msa2-ui/src/utils/withRetries";
import Variable from "msa2-ui/src/services/Variable";

const API = process.env.REACT_APP_API_PATH;

export const normalizeTopologyData = (response) => {
  return {
    nodes: response?.Nodes || [],
    serviceVariables: {
      serviceId: response?.service_vars?.SERVICEINSTANCEID,
      ubiqubeId: response?.service_vars?.UBIQUBEID,
    },
  };
};

/**
 * Retrieves topology data for selected subtenant
 *
 * @param {String} token
 * @param {Number} subtenantId
 * @returns {Promise<(error|response|meta)[]>}
 */
export const getTopologyDataBySubtenant = ({ token, subtenantId }) => {
  return get({
    url: `${API}/orchestration/service/topology/${subtenantId}`,
    token,
    transforms: [normalizeTopologyData],
  });
};

/**
 * Retrieves topology data for selected workflow instance
 *
 * @param {String} token
 * @param {String} instanceId
 * @returns {Promise<(error|response|meta)[]>}
 */
export const getTopologyDataByInstanceId = ({ token, instanceId }) => {
  return getWorkflowInstanceVariableValues({
    token,
    instanceId,
    transforms: [
      Variable.convertObjectPath,
      (response) => {
        const { Nodes, SERVICEINSTANCEID, UBIQUBEID } = response;
        return {
          nodes: Nodes ?? [],
          serviceVariables: {
            serviceId: SERVICEINSTANCEID,
            ubiqubeId: UBIQUBEID,
          },
        };
      },
    ],
  });
};

/**
 * Creates topology if it doesn't exist.
 *
 * @param {String} token
 * @param {Number} subtenantId
 * @returns {Promise<(error|response|meta)[]>}
 */
export const createTopologyAndRetrieveTheData = ({ token, subtenantId }) => {
  return put({
    url: `${API}/orchestration/topology/activate/${subtenantId}`,
    token,
    transforms: [normalizeTopologyData],
  });
};

export const runUpdateTopologyProcessAndRetrieveData = ({
  pollingInterval,
  ubiqubeId,
  serviceId,
  subtenantId,
  token,
  isMountedComponentRef,
}) => {
  const launchUpdateProcessStage = () =>
    launchUpdateTopologyProcess({
      ubiqubeId,
      serviceId,
      token,
    });

  const checkUpdateProcessStatusStage = (response) => {
    const [error, data] = response;
    if (error) {
      return response;
    }

    const checkStatusWithRetries = withRetries({
      apiCall: checkUpdateTopologyProcess,
      interval: pollingInterval,
      maxNumberOfTries: 30,
      checkForRetry: ([error, response]) =>
        isMountedComponentRef.current &&
        !error &&
        response?.status?.status !== "ENDED" &&
        response?.status?.status !== "FAIL",
    });

    return checkStatusWithRetries({
      processId: data.processId?.id,
      token,
    });
  };

  const fetchTopologyStage = (response) => {
    const [error] = response;
    if (error) {
      return response;
    }
    return getTopologyDataByInstanceId({ token, instanceId: serviceId });
  };

  return [
    launchUpdateProcessStage,
    checkUpdateProcessStatusStage,
    fetchTopologyStage,
  ].reduce((chain, stage) => chain.then(stage), Promise.resolve());
};

/**
 * Topology specific endpoint for launching update process
 *
 * @param {Number} ubiqubeId
 * @param {Number} serviceId
 * @param {String} token
 * @returns {Promise<(error|response|meta)[]>}
 */
export const launchUpdateTopologyProcess = ({
  ubiqubeId,
  serviceId,
  token,
}) => {
  return launchProcessInstance({
    token,
    ubiqubeId,
    serviceId,
    processName: "Process/Topology/Process_Update_View",
    transforms: [],
  });
};

/**
 * Topology specific endpoint for checking process status
 *
 * @param {String} token
 * @param {Number} processId
 * @returns {Promise<(error|response|meta)[]>}
 */
export const checkUpdateTopologyProcess = ({ token, processId }) => {
  return getProcessInstanceStatus({ token, processId, transforms: [] });
};

/**
 * Saves positions of nodes defined by user in Topology view.
 *
 * @typedef {Object} Node
 * @property {Number} objectId
 * @property {Number} x
 * @property {Number} y
 *
 * @param {Node[]} nodes
 * @param {Number} serviceId
 * @param {String} token
 * @returns {Promise<(error|response|meta)[]>}
 */
export const saveNodesPositions = ({ nodes, serviceId, token }) => {
  return put({
    url: `${API}/orchestration/v1/services/${serviceId}/topology/save/coordinates`,
    token,
    body: {
      nodes,
    },
  });
};
