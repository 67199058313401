import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

const RouteWithSubtenant = ({ redirectTo, render, children, ...rest }) => {
  const selectedSubtenant = useSelector(getSelectedSubtenant);
  if (!selectedSubtenant.id) {
    return <Redirect to={redirectTo} />;
  }
  return (
    <Route {...rest}>
      {render && render()}
      {children}
    </Route>
  );
};

export default RouteWithSubtenant;
