import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Menu, { MenuContext } from "./Menu";
import { MenuItem as MaterialUIMenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuItem: ({ open }) => ({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: open ? theme.palette.action.hover : "transparent",
  }),
}));

const menuListProps = { style: { zIndex: 1 } };
const paperProps = { style: { pointerEvents: "auto" } };

const NestedMenuItem = ({
  containerProps = {},
  disabled = false,
  label,
  tabIndex: tabIndexProp,
  children,
  shouldOpenOnClick = false,
  ...menuItemProps
}) => {
  const containerRef = useRef();
  const menuItemRef = useRef();

  const context = React.useContext(MenuContext);
  const nestedItemId = useRef(
    `${Math.floor(Math.random() * 1000)}.${Date.now()}`,
  );
  const isOpen = context.activeMenuItemId === nestedItemId.current;

  const classes = useStyles({ open: isOpen });

  const focusHandler = (evt) => {
    if (evt.target === containerRef.current) {
      context.handleActiveItem(nestedItemId.current);
    }
    containerProps.onFocus && containerProps.onFocus(evt);
  };

  const mouseEnterHandler = () => {
    if (!shouldOpenOnClick && !disabled) {
      context.handleActiveItem(nestedItemId.current);
    }
    containerProps.onMouseEnter && containerProps.onMouseEnter();
  };

  const clickHandler = () => {
    if (shouldOpenOnClick) {
      context.handleActiveItem(nestedItemId.current);
    }
    menuItemProps.onClick && menuItemProps.onClick();
  };

  const calculateAnchorPosition = () => {
    const menuItem = menuItemRef.current;
    if (!menuItem) {
      return { top: 0, left: 0 };
    }

    const rect = menuItem.getBoundingClientRect();

    return {
      top: rect.top,
      left: rect.left + menuItem.offsetWidth,
    };
  };

  let tabIndex;
  if (!disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      {...containerProps}
      ref={containerRef}
      onFocus={focusHandler}
      tabIndex={tabIndex}
      onMouseEnter={mouseEnterHandler}
    >
      <MaterialUIMenuItem
        {...menuItemProps}
        disabled={disabled}
        onClick={clickHandler}
        open={isOpen}
        ref={menuItemRef}
        className={classes.menuItem}
      >
        {label}
        <ArrowRight />
      </MaterialUIMenuItem>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={calculateAnchorPosition()}
        style={{ pointerEvents: "none" }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={false}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={isOpen}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        variant="menu"
        MenuListProps={menuListProps}
        PaperProps={paperProps}
      >
        {children}
      </Menu>
    </div>
  );
};

NestedMenuItem.propTypes = {
  containerProps: PropTypes.shape({
    onFocus: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.node.isRequired,
  shouldOpenOnClick: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.forwardRef((props, _) => <NestedMenuItem {...props} />);
