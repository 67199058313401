import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import {
  removeESUnescapableCharacters,
  makePartialMatchQuery,
} from "msa2-ui/src/utils/elasticsearch";
import { getTriggeredAlarms } from "msa2-ui/src/api/logs";

import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";

import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { TABLE_HEADER_COLUMNS } from "msa2-ui/src/Constants";

import {
  getAvailableSubtenants,
  getSelectedSubtenant,
} from "msa2-ui/src/store/designations";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import { getAutoRefreshSetting } from "msa2-ui/src/store/settings";

import LogsFilterMenu from "./LogsFilterMenu";
import LogsTableBody from "./LogsTableBody";
import LogsFilterLabels from "./LogsFilterLabels";
import { setFilterValue, getFilters } from "msa2-ui/src/store/ui";
import { displayYearMonthDayHourMinuteSecond } from "msa2-ui/src/utils/date";
import AcknowledgeAlarmButton from "./AcknowledgeAlarmButton";
import { getAlarmSummary } from "msa2-ui/src/api/logs";
import isEmpty from "lodash/isEmpty";
import { AlarmContext } from "./AlarmContext";

/**
 * ElasticSearch uses a from parameter in combination with the page size to determine
 * which search results to show. The from parameter is just the offset from the first result
 * at index 0
 *
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/run-a-search.html#paginate-search-results
 *
 * @param {Number} page - the page number has determined by the pagination component
 * @param {Number} rowsPerPage - the number of logs to show on any given page
 */
const getElasticSearchOffset = (page, rowsPerPage) => page * rowsPerPage;

const getSubtenantsIds = (subtenants) => {
  if (subtenants.length === 0) {
    return [0];
  }
  return subtenants.map((subtenant) => subtenant.id);
};

/**
 * Component to list Alarm
 *
 * @param {Function} responseCallback [This is optional param to pass API response to Parent component]
 */
const AlarmList = () => {
  const namespace = "alarms";
  const commonClasses = useCommonStyles();

  const { t } = useTranslation("logs");
  const history = useHistory();
  const dispatch = useDispatch();

  const alarmFilters = useSelector(getFilters(namespace));
  const {
    setTriggeredAlarmsCount,
    setSearchPayload,
    setCheckedColumns,
    setSummary,
  } = useContext(AlarmContext);

  const changeDatetoES = (date) => {
    return date !== null
      ? displayYearMonthDayHourMinuteSecond(
          date.getTime() + date.getTimezoneOffset() * 60000,
        )
      : "";
  };

  const [startDate, setStartDate] = useState(alarmFilters.startDate);
  const [startDateToES, setStartDateToES] = useState(
    changeDatetoES(alarmFilters.startDate),
  );
  const [endDate, setEndDate] = useState(alarmFilters.endDate);
  const [endDateToES, setEndDateToES] = useState(
    changeDatetoES(alarmFilters.endDate),
  );

  const [searchValue, setSearchValue] = useState(alarmFilters.searchValue);
  const [alarmName, setAlarmName] = useState(alarmFilters.alarmName);
  const [severities, setSeverities] = useState(alarmFilters.severities);
  const [logType, setLogType] = useState(alarmFilters.logType);
  const [subtenantIds, setSubtenantIds] = useState([]);
  const [managedEntityIds, setManagedEntityIds] = useState(
    alarmFilters.managedEntityIds.map(({ id }) => id),
  );
  const [checkedEntries, setCheckedEntries] = useState([]);
  const [ackFilter, setAckFilter] = useState(alarmFilters.ack);
  const [page, setPage] = useState(0);

  const autoRefresh = useSelector(getAutoRefreshSetting(namespace));
  const [searchWords, setSearchWords] = useState([]);

  const [tableFilterState, tableFilterActions] = useFilter({
    tableName: namespace,
    tpPage: 0,
    flag1: false,
  });
  const {
    tpRowsPerPage: rowsPerPage,
    columns: checkedColumnIds,
    flag1: isESQueryMode,
  } = tableFilterState;

  const columns = TABLE_HEADER_COLUMNS.alarms.map((column) => ({
    ...column,
    check: checkedColumnIds.includes(column.id) ? true : undefined,
  }));

  const checkedColumns = columns.filter((entry) => entry.check);
  const tableCellLength = checkedColumns.length;

  const { availableSubtenants, selectedSubtenantId } = useSelector(
    (state) => ({
      availableSubtenants: getAvailableSubtenants(state),
      selectedSubtenantId: getSelectedSubtenant(state)?.id,
    }),
    shallowEqual,
  );

  const searchPayload = {
    subtenantIds:
      selectedSubtenantId || selectedSubtenantId === 0
        ? [selectedSubtenantId]
        : getSubtenantsIds(availableSubtenants),
    from: getElasticSearchOffset(page, rowsPerPage),
    pageSize: rowsPerPage,
    logType,
    severity: severities.join(","),
    deviceID: managedEntityIds[0],
    search: isESQueryMode ? searchValue : makePartialMatchQuery(searchValue),
    ack: ackFilter,
    rule: alarmName ? "*" + alarmName + "*" : "",
    startDate: startDateToES,
    endDate: endDateToES,
  };

  const [, , summary = {}] = useApi(
    getAlarmSummary,
    searchPayload,
    false,
    autoRefresh,
  );

  const [loading, error, logsResponse = {}, , reload] = useApi(
    getTriggeredAlarms,
    searchPayload,
    false,
    autoRefresh,
  );
  const { logs = [], count = 0 } = logsResponse;

  useEffect(() => {
    if (!isEmpty(summary)) {
      setSummary && setSummary(summary);
    }
  }, [summary, setSummary]);

  useEffect(() => {
    if (logs.length) {
      if (searchValue) {
        setSearchWords(searchValue.split(" "));
      } else {
        setSearchWords([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  useEffect(() => {
    setTriggeredAlarmsCount && setTriggeredAlarmsCount(count);
    if (count !== 0) {
      // We need searchPayload and checkedColumns
      setSearchPayload && setSearchPayload(searchPayload);
      setCheckedColumns && setCheckedColumns(checkedColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsResponse, count, checkedColumnIds]);

  useEffect(() => {
    // initialize checked
    if (Boolean(logs.length) && !checkedEntries.length)
      setCheckedEntries && setCheckedEntries(Array(logs.length).fill(false));
  }, [checkedEntries, logs]);

  const canAck = useSelector(
    getDelegationProfile(delegationProfileTypes.ALARMS, "alarms.acknowledge"),
  );

  const renderAlarmAckButton = ({
    renderAck = true,
    ignoreDisabled = false,
  }) => {
    return (
      canAck &&
      renderAck && (
        <Grid item>
          <AcknowledgeAlarmButton
            alarms={logs.filter((_, index) => checkedEntries[index])}
            searchQuery={searchPayload}
            searchResult={{ count }}
            onAck={() => {
              reload();
              history.push({
                pathname: "/alarms/",
                state: { reloadAlarmNotification: true },
              });
            }}
          />
        </Grid>
      )
    );
  };

  const handleSearchByChange = (val) => {
    const value = removeESUnescapableCharacters(val);
    setSearchValue(value);
    tableFilterActions.handleSearchByChange(value);
    handleFilterValue({
      table: namespace,
      key: "searchValue",
      value: value,
    });
  };

  const handleCheckboxChange = () => {
    const len = checkedEntries.filter((value) => value === true).length;
    if (len) {
      setCheckedEntries(Array(logs.length).fill(false));
    } else {
      setCheckedEntries(Array(logs.length).fill(true));
    }
  };

  const handleFilterValue = (filter) => {
    dispatch(
      setFilterValue({
        table: filter.table,
        key: filter.key,
        value: filter.value,
      }),
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              colSpan={tableCellLength}
              className={commonClasses.commonTableCell}
            >
              <LogsFilterMenu
                renderAlarmAckButton={renderAlarmAckButton}
                isTriggeredAlarms
                searchValue={searchValue}
                handleSearchByChange={handleSearchByChange}
                logType={logType}
                setLogType={setLogType}
                alarmName={alarmName}
                setAlarmName={setAlarmName}
                severities={severities}
                setSeverities={setSeverities}
                subtenantIds={subtenantIds}
                setSubtenantIds={setSubtenantIds}
                managedEntityIds={managedEntityIds}
                setManagedEntityIds={setManagedEntityIds}
                startDate={startDate}
                startDateToES={startDateToES}
                setStartDate={setStartDate}
                setStartDateToES={setStartDateToES}
                endDate={endDate}
                endDateToES={endDateToES}
                setEndDate={setEndDate}
                setEndDateToES={setEndDateToES}
                count={count}
                page={page}
                onChangePage={(e, pageNumber) => setPage(pageNumber)}
                onChangeRowsPerPage={tableFilterActions.tpChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                acknowledgement={ackFilter}
                setAcknowledgement={setAckFilter}
                columns={columns}
                addColumn={tableFilterActions.handleAddColumn}
                removeColumn={tableFilterActions.handleRemoveColumn}
                onClickSearchMode={tableFilterActions.toggleFlag1}
                handleFilterValue={handleFilterValue}
                tableName="alarms"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={tableCellLength}
              className={commonClasses.commonTableCell}
            >
              <LogsFilterLabels
                isTriggeredAlarms
                filters={alarmFilters}
                handleFilterValue={handleFilterValue}
                handleSearchByChange={handleSearchByChange}
                setLogType={setLogType}
                setAlarmName={setAlarmName}
                setAcknowledgement={setAckFilter}
                setSeverities={setSeverities}
                setManagedEntityIds={setManagedEntityIds}
                setStartDate={setStartDate}
                setStartDateToES={setStartDateToES}
                setEndDate={setEndDate}
                setEndDateToES={setEndDateToES}
              />
            </TableCell>
          </TableRow>
          <TableRow className={commonClasses.commonTableHeadRow}>
            {checkedColumns.map(
              (tableHeaderColumn) =>
                tableHeaderColumn.check &&
                (tableHeaderColumn.id !== "check" ? (
                  <TableCell
                    key={tableHeaderColumn.id}
                    align={tableHeaderColumn.align}
                    className={commonClasses.commonTableCellDefault}
                  >
                    {tableHeaderColumn.active ? (
                      <TableSortLabel
                        id={`LOGS_TABLE_SORT_${tableHeaderColumn.id}`}
                        active={false}
                      >
                        {t(tableHeaderColumn.name)}
                      </TableSortLabel>
                    ) : (
                      t(tableHeaderColumn.name)
                    )}
                  </TableCell>
                ) : (
                  canAck && (
                    <TableCell
                      className={commonClasses.commonTableCellDefault}
                      key={tableHeaderColumn.id}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="ACK_ALARM_UPDATE_LINK"
                            checked={
                              checkedEntries.filter((value) => value === true)
                                .length === logs.length
                            }
                            indeterminate={
                              checkedEntries.filter((value) => value === true)
                                .length < logs.length &&
                              checkedEntries.filter((value) => value === true)
                                .length > 0
                            }
                            onChange={handleCheckboxChange}
                            inputProps={{
                              "data-testid": "upsert-ack-alarm",
                            }}
                          />
                        }
                      />
                    </TableCell>
                  )
                )),
            )}
          </TableRow>
        </TableHead>
        <LogsTableBody
          loading={loading}
          error={error}
          isTriggeredAlarms
          canAck={canAck}
          logs={logs}
          columns={checkedColumns}
          checkedEntries={checkedEntries}
          setCheckedEntries={(index, checked) => {
            setCheckedEntries(
              checkedEntries.map((entry, i) => (i === index ? checked : entry)),
            );
          }}
          enableHighlighting
          searchWordsToHighlight={searchWords}
          tableName="alarms"
        />
      </Table>
    </>
  );
};

export default AlarmList;
