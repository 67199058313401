import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSelectedSubtenant } from "msa2-ui/src/store/designations";

const AuthorizedRouteWithSubtenant = ({
  guard,
  render,
  redirect,
  children,
  ...rest
}) => {
  const selectedSubtenant = useSelector(getSelectedSubtenant);
  if (!guard) return <Redirect to={redirect} />;
  return (
    <Route {...rest}>
      {!selectedSubtenant.id && <Redirect to={redirect} />}
      {render && render()}
      {children}
    </Route>
  );
};

export default AuthorizedRouteWithSubtenant;
