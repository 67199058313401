import React, { useState } from "react";
import classNames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";
import useApi from "msa2-ui/src/hooks/useApi";
import useFilter from "msa2-ui/src/hooks/useFilter";

import { getActionsList } from "msa2-ui/src/api/ai";

import { filter } from "msa2-ui/src/utils/filter";

import {
  Button,
  Grid,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import { Link, useRouteMatch } from "react-router-dom";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";

import FilterMenu from "msa2-ui/src/components/FilterMenu";
import AiActionsTableBody from "msa2-ui/src/routes/ai/actions/AiActionsTableBody";
import { buildRoute } from "msa2-ui/src/utils/urls";
import CreateAction from "msa2-ui/src/routes/ai/actions/Create";
import AuthorizedRoute from "msa2-ui/src/components/AuthorizedRoute";
import { useSelector } from "react-redux";
import { getIsDeveloper } from "msa2-ui/src/store/auth";

import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

export const TABLE_HEADER_COLUMNS = [
  {
    id: "name",
    name: "Name",
    align: "left",
    active: false,
    primary: true,
  },
  {
    id: "pk",
    name: "Key",
    align: "left",
    active: false,
  },
  {
    id: "tag",
    name: "Tag ID",
    align: "left",
    active: false,
  },
  {
    id: "workflow_name",
    name: "Workflow",
    align: "left",
    active: false,
  },
  {
    id: "process",
    name: "Process",
    align: "left",
    active: false,
  },
  {
    id: "target_state",
    name: "Target State ID",
    align: "left",
    active: false,
  },
  {
    id: "actionIconsContainer",
    name: "",
    align: "right",
    active: false,
    hidden: true,
  },
];

const AiActions = ({ tabs: Tabs }) => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const isDeveloper = useSelector(getIsDeveloper);
  const commonClasses = useCommonStyles();

  const [searchString, setSearchString] = useState("");

  const [filterState] = useFilter({
    searchString: "",
  });

  const [isLoading, error, response = [], , reload] = useApi(getActionsList);

  const { actions = [], count = 0 } = response;
  const actionsList = filter(actions, searchString, ["name"]);

  const canCreateAction = useSelector(
    getDelegationProfile(delegationProfileTypes.AI, "action.create"),
  );

  return (
    <>
      <Tabs
        currentCount={count}
        endElements={
          <DelegationProfiles
            type={delegationProfileTypes.AI}
            action="action.create"
          >
            <Button
              id="AI_ACTION_BTN_CREATE_LINK"
              variant="contained"
              color="primary"
              aria-label={t("Create Action")}
              component={Link}
              to={{ pathname: `${url}/create` }}
            >
              <IconPlus className={commonClasses.commonBtnIcon} />
              {t("Create Action")}
            </Button>
          </DelegationProfiles>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={classNames([
              commonClasses.commonPaper,
              commonClasses.commonPaperNoPadding,
            ])}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={TABLE_HEADER_COLUMNS.length}
                    className={commonClasses.commonTableCell}
                  >
                    <FilterMenu
                      id="AI_ACTIONS_TABLE_FILTER_MENU"
                      {...filterState}
                      handleSearchByChange={setSearchString}
                      searchValue={searchString}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={commonClasses.commonTableHeadRow}>
                  {TABLE_HEADER_COLUMNS.map((tableHeaderColumn) =>
                    tableHeaderColumn.hidden ? (
                      <TableCell
                        key={tableHeaderColumn.id}
                        align={tableHeaderColumn.align}
                        className={commonClasses.commonTableCellDefault}
                      />
                    ) : (
                      <TableCell
                        key={tableHeaderColumn.id}
                        align={tableHeaderColumn.align}
                        className={commonClasses.commonTableCellDefault}
                      >
                        {tableHeaderColumn.active ? (
                          <TableSortLabel
                            id={`AI_ACTIONS_TABLE_SORT_${tableHeaderColumn.id}`}
                          >
                            {t(tableHeaderColumn.name)}
                          </TableSortLabel>
                        ) : (
                          tableHeaderColumn.name
                        )}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <AiActionsTableBody
                loading={isLoading}
                error={error}
                reload={reload}
                actions={actionsList}
              />
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <AuthorizedRoute
        guard={isDeveloper && canCreateAction}
        redirect={path}
        path={buildRoute(path, "create")}
      >
        <CreateAction reloadActions={reload} />
      </AuthorizedRoute>
    </>
  );
};

export default AiActions;
