import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tableInstances: {
    fontSize: "1.3rem",
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const InstanceCounter = ({ counter }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4" className={classes.tableInstances}>
        {Number.isNaN(Number(counter)) ? (
          "-"
        ) : (
          <NumberFormat
            value={counter}
            displayType={"text"}
            thousandSeparator={true}
          />
        )}
      </Typography>
      <Typography
        variant="body2"
        className={commonClasses.commonTableSecondary}
      >
        {t("Instances")}
      </Typography>
    </>
  );
};

InstanceCounter.propTypes = {
  counter: PropTypes.number,
};

export default InstanceCounter;
