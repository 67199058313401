import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import get from "lodash/get";

import { Typography, CircularProgress, Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { entityTypes } from "msa2-ui/src/Constants";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

const classNames = require("classnames");
const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0 6px 16px 4px rgba(81, 97, 133, 0.13), 0 3px 8px 0 rgba(0, 0, 0, 0.15)",
    marginTop: 4,
    borderRadius: 4,
    outline: "none",
    overflowY: "auto",
    height: "auto",
    maxHeight: 385,
  },
  resultHeader: {
    letterSpacing: 0.5,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "1.0rem",
    lineHeight: "1.625rem",
    marginLeft: 20,
  },
  resultContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingLeft: 40,
    paddingTop: 8,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  hoverStyle: {
    cursor: "pointer",
    "&:hover": {
      color: theme.colors.blueLight,
    },
  },
  resultBody: {
    letterSpacing: 0.5,
    color: theme.palette.text.primary,
    opacity: 0.8,
    lineHeight: "1.1875rem",
    fontSize: "1.0rem",
  },
  resultBodyHighlight: {
    padding: 1,
  },
  resultLink: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "1rem",
    textTransform: "uppercase",
    letterSpacing: 0.3,
    marginLeft: 10,
    marginRight: 10,
  },
  resultCount: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    letterSpacing: 0.3,
    color: theme.palette.text.secondary,
    fontSize: ".8125rem",
  },
  divider: {
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    fontSize: "1.0rem",
    marginRight: 10,
    lineHeight: 1,
  },
  result: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  loaderWrapper: {
    textAlign: "center",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  errorWrapper: {
    margin: 10,
    padding: 10,
    backgroundColor: "#323232",
  },
  alertMessage: {
    fontSize: "0.8125rem",
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: "normal",
    letterSpacing: 0.3,
    color: "#fff",
    marginRight: 20,
  },
  resultTitle: {
    display: "flex",
    alignItems: "center",
  },
  resultType: {
    marginLeft: 10,
  },
});

function SearchResults(props) {
  const {
    classes,
    showSearchResults,
    searchResults,
    searchResultsHitCount,
    handleOnClickCallBack,
    t,
    searchErrorMessage,
    isLoading,
    searchString,
  } = props;

  const applyHighLightStyle = (searchString, textToCheck, style) => {
    const strippedString = searchString.replace(/[*]/gi, "");
    const result = textToCheck
      .toLocaleLowerCase()
      .includes(strippedString.toLocaleLowerCase());
    if (result) {
      return classNames(style, classes.resultBodyHighlight);
    }
    return style;
  };

  const commonClasses = useCommonStyles();

  const getDisplayName = (source) => {
    const { device_name, name } = source;

    return device_name || name;
  };

  const getDisplayType = (type) => {
    return get(entityTypes, `${type}.displayName`) || type;
  };

  return (
    <div>
      {showSearchResults && (
        <div className={classes.paper} id="search-dropdown-results">
          {isLoading && (
            <div
              className={classes.loaderWrapper}
              aria-label={t("Search Results Loading")}
            >
              <CircularProgress />
            </div>
          )}

          {searchErrorMessage && (
            <div className={classes.errorWrapper}>
              <Typography variant="subtitle1" className={classes.alertMessage}>
                {searchErrorMessage}
              </Typography>
            </div>
          )}

          {!isLoading && (
            <div>
              <Typography className={classes.resultCount}>
                {" "}
                {t("Search Results")}:{" "}
                {searchResultsHitCount || searchResults.length}
              </Typography>
            </div>
          )}

          <div>
            {searchResults.map(({ _id, _source, _type }) => {
              const {
                customer_id: subtenant_id,
                customer_name: subtenant_name,
                tenant,
                device_id,
                type,
                path,
              } = _source;

              const id_label = "search-dropdown-result-" + _id;
              const display_name = getDisplayName(_source);
              const display_type = getDisplayType(type);

              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                <div
                  key={_id}
                  id={id_label}
                  className={classNames(classes.result, classes.hoverStyle)}
                  onClick={() =>
                    handleOnClickCallBack(
                      type,
                      tenant,
                      parseInt(subtenant_id),
                      parseInt(device_id),
                      path,
                      display_name,
                    )
                  }
                  role="button"
                >
                  <div key={_id} className={classes.resultTitle}>
                    <Typography
                      className={applyHighLightStyle(
                        searchString,
                        display_name,
                        classes.resultHeader,
                      )}
                    >
                      {display_name}
                    </Typography>
                    <Chip
                      value="primary"
                      label={display_type}
                      color="primary"
                      className={[
                        classes.resultType,
                        commonClasses.commonTags,
                        commonClasses.commonTagSystem1,
                      ].join(" ")}
                    />
                  </div>
                  <div className={classes.resultContainer}>
                    <div className={classes.flexRow}>
                      <Typography className={classes.resultBody}>
                        {tenant}
                      </Typography>
                      <Typography className={classes.resultLink}>
                        {t("Tenant")}
                      </Typography>
                    </div>
                    <div className={classes.flexRow}>
                      <Typography className={classes.divider}>/</Typography>
                    </div>
                    <div className={classes.flexRow}>
                      <Typography
                        className={applyHighLightStyle(
                          searchString,
                          subtenant_name,
                          classes.resultBody,
                        )}
                      >
                        {subtenant_name}
                      </Typography>
                      <Typography className={classes.resultLink}>
                        {t("Subtenant")}
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withTranslation(["dashboard", "search"])(SearchResults),
);
