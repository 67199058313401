import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { change } from "redux-form";
import get from "lodash/get";
import Variable from "msa2-ui/src/services/Variable";
import Validation from "msa2-ui/src/services/Validation";
import Repository from "msa2-ui/src/services/Repository";
import MicroserviceForm from "msa2-ui/src/services/MicroserviceForm";
import useDialog from "msa2-ui/src/hooks/useDialog";

import { getMicroservices } from "msa2-ui/src/api/microservices";
import { getToken } from "msa2-ui/src/store/auth";
import { getFormValues } from "msa2-ui/src/store/form";
import {
  getModelsById,
  getManufacturersById,
  getManufacturers,
} from "msa2-ui/src/store/designations";
import { modes } from "msa2-ui/src/components/EditorAce";

import { makeStyles } from "@material-ui/core";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { ReactComponent as IconEdit } from "msa2-ui/src/assets/icons/edit.svg";

import AdvancedParameterField from "msa2-ui/src/components/variables/AdvancedParameterField";
import VariableEditor from "msa2-ui/src/components/variables/VariableEditor";
import { useIsLatestCall } from "msa2-ui/src/hooks/useIsLatestCall";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import useApi from "msa2-ui/src/hooks/useApi";
import { listRepositoriesOrFilesInFolder } from "msa2-ui/src/api/repository";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.5,
    marginBottom: 10,
  },
}));

const AdvancedParametersTab = ({ commonParams }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const { form, path, variablePath, activeVariable } = commonParams;
  const index = activeVariable?.index;
  const manufacturers = useSelector(getManufacturers);
  const manufacturersById = useSelector(getManufacturersById);
  const modelsById = useSelector(getModelsById);
  const formValues = useSelector(getFormValues(form));
  const formVariableValues = get(formValues, variablePath);
  const currentVariableObject = get(formValues, path);
  const [showBehaviourDialog, BehaviourDialog, { behaviourPath }] = useDialog();
  const variableType = currentVariableObject.type;
  const { isTable } = Variable.getTableValData(currentVariableObject.name);
  const isMicroserviceForm = MicroserviceForm.isMicroserviceForm(form);

  const variableWithoutSelf = formVariableValues.filter((v, i) => i !== index);
  const deviceTypeVariables = formVariableValues.filter(
    (variable) => variable.type === "Device",
  );
  const selectorVariable = formVariableValues.find(
    (variable) => variable.name === currentVariableObject.selector,
  );
  const [searchedMicroservices, setSearchedMicroservices] = useState([]);

  const searchMicroservice = useIsLatestCall((searchParams) =>
    getMicroservices({
      // Vendor/Model is only for Microservice
      vendorId: get(formValues, MicroserviceForm.fields.vendor),
      modelId: get(formValues, MicroserviceForm.fields.model),
      searchParams,
      page: 1,
      pageSize: 10000,
      token,
      filterByLabel: isPermissionProfileLabelsEnabled,
    }),
  );

  const searchMicroserviceHandler = async (searchParams) => {
    setSearchedMicroservices([]);
    // retrieve Microservices when users type more than one character to avoid showing massive result
    if (searchParams.length <= 1) {
      return;
    }

    const [, response] = await searchMicroservice(searchParams);
    // If there is a brand new call we don't show stale data
    if (!searchMicroservice.isLatest()) {
      return;
    }
    setSearchedMicroservices(
      response.map((microservice) => ({
        name: Repository.stripFolderPathAndFileExtensionFromUri(
          microservice.uri,
        ),
        displayName: microservice.displayName,
        // show a chip for vendor/model with display name as there are many duplicated names.
        chip: `${manufacturersById[microservice.vendor]?.manufacturerName ??
          microservice.vendor} / ${modelsById[microservice.model]?.modelName ??
          microservice.model}`,
      })),
    );
  };

  const getValueList = (variable) => {
    if (variable.type === "Boolean") {
      return [
        { name: true, displayName: "true" },
        { name: false, displayName: "false" },
      ];
    }
    if (variable.values?.length) {
      return [
        { name: null, displayName: null },
        ...variable.values.map(({ actualValue, displayValue }) => ({
          name: actualValue,
          displayName: displayValue,
        })),
      ];
    }
    return undefined;
  };

  const microserviceComboProps = () => ({
    class: {
      label: t("Microservice"),
      type: "combo",
      searchOptions: searchMicroserviceHandler,
      listData: searchedMicroservices,
    },
  });

  const validateIpAddress = (type = variableType) =>
    [
      type === "IpAddress" && Validation.ipv4,
      type === "Ipv6Address" && Validation.ipv6,
      type === "IpMask" && Validation.netmask,
    ].filter((fn) => fn);

  const [, , rootFoldersInRepository] = useApi(
    listRepositoriesOrFilesInFolder,
    {
      transforms: (response) =>
        response?.map(({ name, path }) => ({
          name: path,
          displayName: name,
        })) || [],
    },
    variableType !== "File",
  );

  useEffect(() => {
    if (
      currentVariableObject.values?.length &&
      !currentVariableObject.values
        .map(({ actualValue }) => actualValue)
        .includes(currentVariableObject.defaultValue)
    ) {
      // if values for drop-down list changed and default value no more exists in the list,
      // clear default value
      dispatch(change(form, [variablePath, "defaultValue"].join("."), null));
    }
  }, [currentVariableObject, dispatch, form, variablePath]);

  return (
    <>
      <BehaviourDialog title={t("Parameters for Composite")} maxWidth={"md"}>
        <VariableEditor
          form={form}
          nestedVariablePath={behaviourPath}
          variablePath={variablePath}
          activeVariable={activeVariable}
        />
      </BehaviourDialog>

      {/* Type oriented variables */}
      {/* Composite */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"selector"}
        label={t("Selector Variable")}
        validate={[Validation.required]}
        listData={variableWithoutSelf}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"behaviour"}
        label={t("Behaviour for Composite")}
        validate={[Validation.required]}
        inputProps={{ disabled: selectorVariable === undefined }}
        extraChildObject={{
          ...currentVariableObject,
          type: "String",
          behaviour: undefined,
          selectorValue: undefined,
        }}
        childProps={(childIndex) => ({
          selectorValue: {
            type: Variable.integerTypes.includes(selectorVariable?.type)
              ? "integer"
              : "string",
            listData: getValueList(selectorVariable),
            validate: validateIpAddress(selectorVariable?.type),
            additionalAction: ({ id }) => (
              <IconButton
                id={id}
                aria-label="Edit Composite Parameter"
                onClick={() => {
                  showBehaviourDialog(true, {
                    behaviourPath: `${path}.behaviour.${childIndex}`,
                  });
                }}
              >
                <IconEdit />
              </IconButton>
            ),
          },
        })}
      />
      {/* Managed Entity */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"sdTypes"}
        label={t("Managed Entity Type")}
        inputProps={{ disabled: isMicroserviceForm }}
        childProps={(index) => ({
          manId: {
            label: t("Vendor"),
            listData: manufacturers.map((value) => ({
              name: value,
              displayName: manufacturersById[value].manufacturerName,
            })),
          },
          modId: {
            label: t("Model"),
            listData:
              manufacturersById[
                get(currentVariableObject, ["sdTypes", index, "manId"])
              ]?.models.map((value) => ({
                name: value,
                displayName: modelsById[value].modelName,
              })) || [],
          },
        })}
      />
      {/* ServiceRef */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"refServiceURI"}
        label={t("Workflow Reference")}
        validate={[Validation.required]}
      />
      {/* OBMFRef */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"refDeviceIdVar"}
        label={t("Managed Entity ID")}
        description={t(
          "You can either put Managed Entity ID directly or Managed Entity type variable which takes Managed Entity ID.",
        )}
        type={"combo"}
        listData={deviceTypeVariables}
        validate={[Validation.required]}
      />
      {/* AutoIncrement */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"increment"}
        label={t("Increment")}
        validate={[Validation.required]}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"startIncrement"}
        label={t("Start Increment")}
        validate={[Validation.required]}
      />
      {/* class is used both for OBMFRef/ObjectRef and AutoIncrement */}
      {variableType === "AutoIncrement" ? (
        //  AutoIncrement: Microservices sharing the same increment
        <AdvancedParameterField
          {...commonParams}
          parameterName={"class"}
          label={t("Microservices sharing the same increment")}
          childProps={microserviceComboProps}
        />
      ) : (
        // OBMFRef/ObjectRef: Referenced Microservices
        <AdvancedParameterField
          {...commonParams}
          parameterName={"class"}
          label={t("Microservice Reference")}
          childProps={microserviceComboProps}
          validate={[Validation.required]}
        />
      )}
      {/* OBMFRef/ObjectRef: Referenced Microservices */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"localVarToFilter"}
        label={t("Local Variable Name to filter")}
        description={t(
          "Variable for Target Microservice to filter the object with the value for Remote Variable.",
        )}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"remoteVarToFilter"}
        label={t("Remote Variable Name to filter")}
        description={t(
          "Variable for Target Microservice to filter the object with the value for Remote Variable.",
        )}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"remoteVarValueToFilter"}
        label={t("Remote Variable Value to filter")}
        description={t(
          "Variable Value for Target Microservice to filter the object with the value for Remote Variable.",
        )}
      />
      {/* ServiceRef & OBMFRef */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"localVarNameMatch"}
        label={t("Local Variable")}
        description={t(
          "Target Microservice Object will be filtered if the value for this variable matches the value for Remote Variable.",
        )}
        listData={variableWithoutSelf.map((variable) => ({
          ...variable,
          name: Variable.removePrefix(variable.name),
        }))}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"remoteVarNameMatch"}
        label={t("Remote Variable")}
        description={t(
          "Variable for Target Microservice to filter the object with the value for Local Variable.",
        )}
      />
      {/* Index */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"indexVariables"}
        label={t("Variable used for Index")}
        listData={variableWithoutSelf}
      />
      {/* File */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"repositories"}
        label={t("Repository")}
        listData={rootFoldersInRepository}
      />
      {/* Code */}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"codeLanguage"}
        label={t("Code Language")}
        listData={modes}
      />
      <Divider className={classes.divider} />
      {/* Parameters for Value */}
      <Typography variant={"body1"} className={classes.title}>
        {`${t("Parameters to control Value")}:`}
      </Typography>
      <AdvancedParameterField
        {...commonParams}
        type={
          Variable.integerTypes.includes(variableType) ? "integer" : "string"
        }
        parameterName={"defaultValue"}
        label={t("Default Value")}
        listData={getValueList(currentVariableObject)}
        validate={validateIpAddress()}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"values"}
        label={t("Values for Drop-down list")}
        childProps={() => ({
          actualValue: {
            validate: validateIpAddress().concat(Validation.required),
          },
        })}
        validate={[Validation.required]}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"editable"}
        label={t("Allow adding free value")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"mandatory"}
        label={t("Mandatory")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"validationRegex"}
        label={t("Regular Expression for Value")}
        validate={[Validation.regex]}
        description={t("Regular Expression to validate value.")}
      />
      {!isTable && (
        <AdvancedParameterField
          {...commonParams}
          parameterName={"isCompositeKey"}
          label={t("Composite Primary Key")}
          description={t(
            "If checked, it validates if the combination of all checked variables is unique.",
          )}
        />
      )}
      <AdvancedParameterField
        {...commonParams}
        parameterName={"keepOnImport"}
        label={t("Keep on Import")}
        description={t(
          "This variable will work as a local variable. Values for this variable will stay after IMPORT command is triggered.",
        )}
      />
      {/* Parameters for View */}
      <Divider className={classes.divider} />
      <Typography variant={"body1"} className={classes.title}>
        {`${t("Parameters to control View")}:`}
      </Typography>
      <AdvancedParameterField
        {...commonParams}
        parameterName={"sections"}
        label={t("Section Header")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"uniqueItemsInDropDownList"}
        label={t("Unique")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"visible"}
        label={t("Visible")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"maxLength"}
        label={t("Column Size")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"userLocked"}
        label={t("Read only variable")}
        onChange={(checked) => {
          if (isMicroserviceForm) {
            // check editLocked if userLocked is true as editLocked will be hidden but it should be disabled when userLocked is true
            dispatch(
              change(
                form,
                [commonParams.path, "editLocked"].join("."),
                checked,
              ),
            );
          }
        }}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"editLocked"}
        label={t("Editable only in Create view")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"onlyDetailView"}
        label={t("Visible only in Edit view")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"grouped"}
        label={t("Group variable")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"groupDisplayName"}
        label={t("Group Name")}
      />
      <AdvancedParameterField
        {...commonParams}
        parameterName={"groupSeparator"}
        label={t("Group Separator")}
      />
      {isTable && (
        <>
          {/* Parameters for Array */}
          <Divider className={classes.divider} />
          <Typography variant={"body1"} className={classes.title}>
            {`${t("Array Settings")}:`}
          </Typography>
          <AdvancedParameterField
            {...commonParams}
            parameterName={"arrayCanAdd"}
            label={t("Add")}
          />
          <AdvancedParameterField
            {...commonParams}
            parameterName={"arrayCanEdit"}
            label={t("Edit")}
          />
          <AdvancedParameterField
            {...commonParams}
            parameterName={"arrayCanMove"}
            label={t("Move")}
          />
          <AdvancedParameterField
            {...commonParams}
            parameterName={"arrayCanRemove"}
            label={t("Remove")}
          />
        </>
      )}
    </>
  );
};

AdvancedParametersTab.propTypes = {
  commonParams: PropTypes.shape({
    form: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    variablePath: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdvancedParametersTab;
