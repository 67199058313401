import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    overflowY: "auto",
    maxHeight: "100%",
  },
  sectionPadding: {
    padding: "20px 20px 40px",
  },
}));

export const ScrollingContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid item xs={9} md={10} className={classes.scrollContainer}>
      <div className={classes.sectionPadding}>{children}</div>
    </Grid>
  );
};
