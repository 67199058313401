import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import {
  Avatar,
  Grid,
  Tooltip,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  Create as EditIcon,
  FlashOn as MicroserviceIcon,
} from "@material-ui/icons";
import { delegationProfileTypes } from "msa2-ui/src/store/delegationProfiles";
import DelegationProfiles from "msa2-ui/src/components/DelegationProfiles";

const useStyles = makeStyles(({ darkMode, palette, colors, typography }) => ({
  treeItem: {
    height: 48,
    marginBottom: 5,
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: darkMode ? "transparent" : colors.blueLight2,
    color: palette.text.secondary,
  },
  folder: {
    marginLeft: 8,
    fontWeight: typography.fontWeightLight,
    marginBottom: 10,
    color: palette.text.secondary,
  },
  hover: {
    backgroundColor: darkMode ? palette.background.default : colors.blueLight2,
    borderRadius: 24,
  },
  selected: {
    backgroundColor: darkMode ? palette.background.default : colors.blueLight,
    borderRadius: 24,
  },
  contentWrapper: {
    cursor: "pointer",
    height: 48,
    overflow: "hidden",
  },
  content: {
    marginLeft: 4,
    fontWeight: typography.fontWeightMedium,
    letterSpacing: 0.25,
    cursor: "pointer",
    color: palette.text.primary,
  },
  contentSelected: {
    color: colors.white,
  },
  readOnly: {
    width: 48,
    cursor: "pointer",
  },
}));

export const TreeFolderComponent = ({
  label,
  externalReference,
  countChildren,
}) => {
  const classes = useStyles();
  const folderLabel = (
    <Typography variant={"body1"} className={classes.folder}>
      {label}
      {": "}
      {countChildren}
    </Typography>
  );

  if (externalReference) {
    return <Tooltip title={externalReference}>{folderLabel}</Tooltip>;
  }

  return folderLabel;
};

export const TreeItemComponent = ({
  label,
  path,
  selected,
  onClick,
  deviceId,
  isDeveloper,
}) => {
  const classes = useStyles();

  const [hover, setHover] = useState(false);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      className={classnames(classes.treeItem, {
        [classes.hover]: hover,
        [classes.selected]: selected,
      })}
    >
      <Grid item xs={10}>
        <Grid
          container
          wrap={"nowrap"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          className={classes.contentWrapper}
          onClick={onClick}
        >
          <Avatar className={classes.avatar}>
            <MicroserviceIcon />
          </Avatar>
          <Typography
            variant={"body1"}
            className={classnames(classes.content, {
              [classes.contentSelected]: selected,
            })}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
      <DelegationProfiles
        type={delegationProfileTypes.MICROSERVICE}
        action="general.modify"
      >
        <Grid item xs={2}>
          {hover && isDeveloper && (
            <Grid container alignItems={"center"} justifyContent={"flex-end"}>
              <DelegationProfiles
                type={delegationProfileTypes.MICROSERVICE}
                action="general.modify"
              >
                <Link
                  to={{
                    pathname: path,
                    state: { deviceId },
                  }}
                >
                  <IconButton>
                    <EditIcon color="primary" />
                  </IconButton>
                </Link>
              </DelegationProfiles>
            </Grid>
          )}
        </Grid>
      </DelegationProfiles>
    </Grid>
  );
};

export const TreeItemReadOnlyComponent = ({ selected, onClick }) => {
  const classes = useStyles();

  const [hover, setHover] = useState(false);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={onClick}
      className={classnames(classes.treeItem, classes.readOnly, {
        [classes.hover]: hover,
        [classes.selected]: selected,
      })}
    >
      <Avatar className={classes.avatar}>
        <MicroserviceIcon />
      </Avatar>
    </Grid>
  );
};
