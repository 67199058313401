import React from "react";
import { MenuItem } from "msa2-ui/src/components/menu";
import { CircularProgress } from "@material-ui/core";

const MenuLoader = () => (
  <MenuItem
    disabled
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress />
  </MenuItem>
);

export default MenuLoader;
