import React, { memo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core";

import Field from "redux-form/lib/Field";

import PasswordField from "msa2-ui/src/components/connectedFormComponents/Password";
import Textfield from "msa2-ui/src/components/connectedFormComponents/Textfield";
import Checkbox from "msa2-ui/src/components/connectedFormComponents/Checkbox";
import { FormControlLabel } from "@material-ui/core";

const fieldTypes = {
  BOOLEAN: "checkbox",
  PASSWORD: "password",
};

const useStyles = makeStyles((theme) => ({
  helperText: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "none",
    // if not warning, this color will be replaced by .Mui-error
    color: theme.palette.warning.main,
  },
  label: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "capitalize",
    lineHeight: "1.4rem",
  },
  inputField: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 25,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 20,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  field: {
    width: "100%",
    paddingBottom: "1.4rem",
  },
  noIndent: {
    padding: 0,
  },
}));

const FieldSelector = ({
  label,
  type,
  name,
  format,
  required = false,
  className,
  disabled,
  inputProps,
  validate,
  warn,
  noIndent = false,
  onChange,
}) => {
  const classes = useStyles();
  if (type === fieldTypes.BOOLEAN) {
    return (
      <FormControlLabel
        control={
          <Field
            name={name}
            id={name}
            format={format}
            component={Checkbox}
            disabled={disabled}
            color="primary"
            onChange={onChange}
            inputProps={{
              ...inputProps,
            }}
          />
        }
        label={label}
        classes={{
          root: classes.label,
        }}
      />
    );
  }
  return (
    <Field
      name={name}
      id={name}
      format={format}
      label={label}
      component={type === fieldTypes.PASSWORD ? PasswordField : Textfield}
      required={required}
      disabled={disabled}
      className={classnames(className, classes.field, {
        [classes.noIndent]: noIndent,
      })}
      type={type}
      validate={validate}
      warn={warn}
      onChange={onChange}
      inputProps={{
        ...inputProps,
      }}
      InputProps={{
        classes: {
          input: classes.inputField,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
    />
  );
};

FieldSelector.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  format: PropTypes.func,
  type: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func,
  ]),
};

export default memo(FieldSelector);
