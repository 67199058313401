import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import reduxForm from "redux-form/lib/reduxForm";
import { getFormValues } from "msa2-ui/src/store/form";
import flow from "lodash/flow";
import WorkflowForm from "msa2-ui/src/services/WorkflowForm";
import Workflow from "msa2-ui/src/services/Workflow";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import useDialog from "msa2-ui/src/hooks/useDialog";

import useApi from "msa2-ui/src/hooks/useApi";
import { getToken } from "msa2-ui/src/store/auth";
import { getParentRoute } from "msa2-ui/src/utils/urls";
import { getWorkflow, editWorkflow } from "msa2-ui/src/api/workflow";

import { Grid, CircularProgress } from "@material-ui/core";
import { ReactComponent as FileComputer } from "msa2-ui/src/assets/icons/fileComputer.svg";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import Modal from "msa2-ui/src/components/modal/Modal";
import ModalTitle from "msa2-ui/src/components/modal/ModalTitle";
import { ModalContent } from "msa2-ui/src/components/modal/ModalContent";
import { ScrollingContainer } from "msa2-ui/src/components/ScrollingContainer";
import ErrorBoundary from "msa2-ui/src/components/ErrorBoundary";
import VariablesTabContent from "msa2-ui/src/components/variables/VariablesTabContent";

import ModalTitleBar from "msa2-ui/src/routes/automation/workflows/edit/ModalTitleBar";
import ModalSidebar from "msa2-ui/src/routes/automation/workflows/edit/ModalSidebar";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";

import InformationSection from "./InformationSection";
import ProcessSection from "./ProcessSection";
import AddProcess from "./AddProcess";
import useWorkflowProcess from "msa2-ui/src/routes/automation/workflows/hooks/useWorkflowProcess";
import Process from "msa2-ui/src/services/Process";

const sections = {
  information: "Information",
  variables: "Variables",
};

const AutomationEdit = ({
  destroy,
  dirty,
  handleSubmit,
  history,
  initialize,
  initialized,
  change,
  onClose,
  reload,
}) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const form = WorkflowForm.EditFormName;
  const token = useSelector(getToken);
  const { workflowUri } = useParams();
  const { pathname } = useLocation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDiscardDialog, DiscardDialog] = useDialog();
  const [showTaskDiscardDialog, TaskDiscardDialog, { tabName }] = useDialog();
  const [showDeleteProcessDialog, DeleteProcessDialog] = useDialog();
  const [showAddProcessDialog, setShowAddProcessDialog] = useState(false);
  const [activeSidebarTab, setActiveSidebarTab] = useState(
    sections.information,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isEditorUnsaved, setIsEditorUnsaved] = useState(false);

  const [, , workflowData] = useApi(getWorkflow, {
    pathToWorkflowDefinitionFile: decodeURIComponent(workflowUri),
  });

  const workflowProcesses =
    useSelector(getFormValues(form, WorkflowForm.formConfig.processPath)) ?? [];

  const deleteWorkflowProcesses = workflowProcesses?.filter((process) =>
    Process.isDelete(process.type),
  );
  const activeWorkflowProcess = workflowProcesses?.find(
    (workflowProcess) => workflowProcess.displayName === activeSidebarTab,
  );
  const activeProcessIndex = workflowProcesses?.findIndex(
    (workflowProcess) => workflowProcess.displayName === activeSidebarTab,
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      history.length > 2
        ? history.goBack()
        : history.push(getParentRoute(pathname));

      reload();
    }
  };

  const handleCloseWithCheck = () => {
    if (dirty || isEditorUnsaved) {
      return showDiscardDialog();
    }
    handleClose();
  };

  useEffect(() => {
    if (!initialized && workflowData) {
      initialize({
        ...Workflow.make(workflowData),
        config: WorkflowForm.formConfig,
      });
    }
  }, [initialize, initialized, workflowData]);

  const submitForm = async (values) => {
    setIsLoading(true);
    const [error] = await editWorkflow({
      token,
      workflow: Workflow.formatDataForEditApi(values),
    });
    const variant = error ? "error" : "success";
    const message = error
      ? error.getMessage()
      : t("x saved successfully", { x: t("Workflow") });
    if (!error) {
      destroy();
      handleClose();
    }
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    setIsLoading(false);
  };

  const { addProcess, removeProcessFromForm } = useWorkflowProcess({
    activeProcessIndex,
    change,
    sections,
    setActiveSidebarTab,
    workflowData,
    workflowProcesses,
    setIsLoading,
  });

  return (
    <ErrorBoundary>
      <TaskDiscardDialog
        title={t("Discard changes?")}
        content={
          <>
            <div>{t("Your Task has not been saved.")} </div>
            <div>{t("Are you sure you want to discard your changes?")}</div>
          </>
        }
        onExec={() => {
          setActiveSidebarTab(tabName);
        }}
      />
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={handleClose}
      />
      <DeleteProcessDialog
        title={`${t("Delete x", { x: t("Process") })}?`}
        content={t("Are you sure you want to delete this process?")}
        onExec={removeProcessFromForm}
      />
      <AddProcess
        workflowProcesses={workflowProcesses}
        showAddProcessDialog={showAddProcessDialog}
        setShowAddProcessDialog={() => {
          setShowAddProcessDialog(false);
        }}
        addProcessToForm={addProcess}
      />
      <Modal onClose={handleCloseWithCheck}>
        <ModalTitleBar
          title={
            workflowData && (
              <ModalTitle
                icon={<FileComputer />}
                name={workflowData.information?.displayName}
              />
            )
          }
          onSave={handleSubmit(submitForm)}
          onDiscard={showDiscardDialog}
          onClose={handleCloseWithCheck}
          closeButtonLabel={t("Close")}
          saveButtonLabel={t("Save Workflow")}
          discardButtonLabel={t("Discard Changes")}
          workflowUri={decodeURIComponent(workflowUri)}
          isLoading={isLoading}
          disabled={isLoading}
        />
        <ModalContent>
          <ModalSidebar
            change={change}
            processPath={WorkflowForm.formConfig.processPath}
            activeTab={activeSidebarTab}
            handleOnClick={(tabName) => {
              if (isEditorUnsaved) {
                showTaskDiscardDialog(true, { tabName });
              } else {
                setActiveSidebarTab(tabName);
              }
            }}
            sections={sections}
            form={form}
            setShowAddProcessDialog={setShowAddProcessDialog}
            processes={workflowProcesses}
          />
          <ScrollingContainer>
            {workflowData ? (
              <>
                <InformationSection
                  isActive={activeSidebarTab === sections.information}
                  workflow={workflowData.information}
                  deleteProcesses={deleteWorkflowProcesses}
                  path={workflowData.metaInformationList[0].parentURI}
                  filename={workflowData.metaInformationList[0].name}
                  shouldDisableWFLanguageSelector={Boolean(
                    workflowData.information,
                  )}
                  form={form}
                />
                {initialized && (
                  <VariablesTabContent
                    isActive={activeSidebarTab}
                    form={form}
                  />
                )}
                {activeWorkflowProcess && (
                  <ProcessSection
                    change={change}
                    form={form}
                    isEditorUnsaved={isEditorUnsaved}
                    setIsEditorUnsaved={setIsEditorUnsaved}
                    showDeleteProcessDialog={showDeleteProcessDialog}
                    workflowProcess={activeWorkflowProcess}
                    processIndex={activeProcessIndex}
                    onDisplayNameChange={(event) =>
                      setActiveSidebarTab(event.target.value)
                    }
                    setIsLoading={setIsLoading}
                  />
                )}
              </>
            ) : (
              <Grid
                container
                className={commonClasses.commonLoaderWrapper}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <CircularProgress />
              </Grid>
            )}
          </ScrollingContainer>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

AutomationEdit.propTypes = {
  match: PropTypes.object.isRequired,
};

export default flow(
  withRouter,
  reduxForm({
    form: WorkflowForm.EditFormName,
    validate: WorkflowForm.validate,
  }),
)(AutomationEdit);
