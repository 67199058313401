import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch } from "react-redux";
import reduxForm from "redux-form/lib/reduxForm";
import { change } from "redux-form";
import { reduxFormNames } from "msa2-ui/src/Constants";
import { required } from "msa2-ui/src/utils/validators";
import FormMsaSelect from "msa2-ui/src/components/formSection/FormMsaSelect";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import classNames from "classnames";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { useSelector } from "react-redux";
import getFormValues from "redux-form/lib/getFormValues";
import AdvancedParameters from "./AdvancedParameters";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { getInitialStates } from "msa2-ui/src/api/ai";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    padding: 25,
  },
  loader: {
    height: 215,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  generateBpmButton: {
    marginTop: 25,
  },
  advancedParamsButton: {
    marginBottom: 20,
  },
}));

const prepareSelectOptions = (options) => {
  return options.map((option) => ({
    data: { ...option },
    label: option.fields.name,
    value: option.pk,
    description: option.fields.description,
  }));
};

const transformInitialStatesResponse = (response) => {
  const states = response?.initial_states?.output || [];
  return states.map((state) => ({
    pk: state.id,
    fields: { name: state.name },
  }));
};

const formValuesSelector = getFormValues(reduxFormNames.aiParametersForm);

const Parameters = ({ data, isParametersLoading, handleSubmit }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const t = useBoundedTranslation();
  const dispatch = useDispatch();

  const formValues = useSelector(formValuesSelector, shallowEqual) || {};
  const [isAdvanced, setIsAdvanced] = useState(false);

  const [initialStates, setInitialStates] = useState([]);
  const [isInitialStatesLoading, setIsInitialStatesLoading] = useState(false);

  const { states: finalStates = [], transitions = [] } = data;

  const finalStateValue = formValues.finalState?.value;

  useEffect(() => {
    const fetchInitialStates = async () => {
      setIsInitialStatesLoading(true);

      const [isError, response] = await getInitialStates({
        finalStateId: finalStateValue,
        transforms: [transformInitialStatesResponse],
      });

      setIsInitialStatesLoading(false);

      if (isError) return setInitialStates([]);
      setInitialStates(response);
    };

    dispatch(
      change(reduxFormNames.aiParametersForm, "initialState", undefined),
    );

    if (!finalStateValue) {
      return setInitialStates([]);
    }

    fetchInitialStates();
  }, [dispatch, finalStateValue]);

  const finalStatesAsOptions = useMemo(
    () => prepareSelectOptions(finalStates),
    [finalStates],
  );

  const initialStatesAsOptions = useMemo(() => {
    const filteredFinalStates = finalStates.filter((finalState) => {
      return !initialStates.find(
        (initialState) => initialState.pk === finalState.pk,
      );
    });

    return initialStates.length
      ? [
          {
            label: t("Suggested States"),
            options: prepareSelectOptions(initialStates),
          },
          {
            label: t("Other States"),
            options: prepareSelectOptions(filteredFinalStates),
          },
        ]
      : prepareSelectOptions(filteredFinalStates);
  }, [initialStates, finalStates, t]);

  const transitionsAsOptions = useMemo(
    () => prepareSelectOptions(transitions),
    [transitions],
  );

  if (isParametersLoading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={5}>
          <FormMsaSelect
            id="AI_FINAL_STATE_SELECT"
            name="finalState"
            label={t("Final state")}
            options={finalStatesAsOptions}
            validate={required}
            placeholder={t("Enter your intent")}
            isClearable
            isSearchable
          />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <FormMsaSelect
            id="AI_INITIAL_STATE_SELECT"
            name="initialState"
            label={t("Initial state")}
            options={initialStatesAsOptions}
            validate={required}
            placeholder={t("Enter initial state")}
            isClearable
            isLoading={isInitialStatesLoading}
            isSearchable
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignContent="flex-start"
          xs={12}
          lg={2}
        >
          <Button
            id="AI_GENERATE_WF_BTN"
            variant="contained"
            color="primary"
            className={classNames(
              commonClasses.commonBtn,
              commonClasses.commonBtnPrimary,
              classes.generateBpmButton,
            )}
            disabled={
              isParametersLoading ||
              !formValues.finalState?.value ||
              !formValues.initialState?.value
            }
            onClick={handleSubmit}
          >
            {t("Generate x", { x: "BPM" })}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                id="AI_ADVANCED_PARAMETERS_BTN"
                size="small"
                endIcon={isAdvanced ? <ExpandLess /> : <ExpandMore />}
                onClick={() => setIsAdvanced(!isAdvanced)}
                className={classNames(
                  commonClasses.commonBtn,
                  classes.advancedParamsButton,
                )}
              >
                {t("Advanced Parameters")}
              </Button>
            </Grid>
            {isAdvanced && (
              <Grid item xs={12}>
                <AdvancedParameters transitionOptions={transitionsAsOptions} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Parameters.propTypes = {
  data: PropTypes.shape({
    states: PropTypes.array,
    transitions: PropTypes.array,
  }).isRequired,
  isParametersLoading: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: reduxFormNames.aiParametersForm,
})(Parameters);
