import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { listFilesInFolder } from "msa2-ui/src/api/repository";

import Repository from "msa2-ui/src/services/Repository";

import ComboBox from "msa2-ui/src/components/ComboBox";

const RepositoryPicker = ({ onChange, token, ...rest }) => {
  const [uri, setUri] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (uri && !uri.includes(":") && uri.includes("/")) {
      (async () => {
        const [, response] = await listFilesInFolder({ token, uri });
        setOptions(response);
      })();
    }
  }, [token, uri]);

  return (
    <ComboBox
      options={options}
      onChange={(value) => {
        setUri(Repository.stripFilenameFromUri(value));
        onChange(value);
      }}
      {...rest}
    />
  );
};

RepositoryPicker.propTypes = {
  value: PropTypes.string,
  token: PropTypes.string.isRequired,
};

export default RepositoryPicker;
