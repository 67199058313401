import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plusWhite.svg";
import { useTranslation } from "react-i18next";
import {
  Button,
  Tooltip,
  ButtonGroup,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import withDelegationProfiles from "msa2-ui/src/components/hocs/withDelegationProfiles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const CreateEntityButton = ({
  children,
  disabled,
  link,
  tooltipTitle,
  additionalOptions,
  size = "large",
  ...rest
}) => {
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  if (additionalOptions?.length > 0) {
    return (
      <>
        <Tooltip title={tooltipTitle ?? ""}>
          <ButtonGroup
            variant="contained"
            size={size}
            color="primary"
            aria-label={t("Split Button")}
          >
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={link}
              disabled={disabled}
              {...rest}
            >
              <IconPlus className={commonClasses.commonBtnIcon} />
              {children}
            </Button>
            <Button
              id="CREATE_ENTITY_SPLIT_BUTTON_EXPAND"
              color="primary"
              size="small"
              disabled={disabled}
              aria-label={t("Split Button Expand")}
              aria-haspopup="menu"
              onClick={() => setIsOpen(true)}
              ref={anchorRef}
              data-testid="SPLIT_BUTTON_EXPAND"
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Tooltip>
        <Menu
          open={isOpen}
          anchorEl={anchorRef.current}
          onClose={() => setIsOpen(false)}
          variant="menu"
          style={{
            marginTop: anchorRef.current?.offsetHeight + 5 ?? undefined,
          }}
        >
          {additionalOptions.map(
            (
              {
                id,
                disabled,
                onClick,
                delegationProfileAction,
                delegationProfileType,
                tooltipTitle,
                label,
              },
              index,
            ) => (
              <Tooltip title={tooltipTitle ?? ""}>
                <div>
                  <MenuItem
                    key={index}
                    disabled={disabled}
                    id={id}
                    delegationProfileAction={delegationProfileAction}
                    delegationProfileType={delegationProfileType}
                    onClick={() => {
                      setIsOpen(false);
                      onClick();
                    }}
                  >
                    {label}
                  </MenuItem>
                </div>
              </Tooltip>
            ),
          )}
        </Menu>
      </>
    );
  }

  return (
    <Tooltip title={tooltipTitle || ""}>
      <span>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={link}
          disabled={disabled}
          {...rest}
        >
          <IconPlus className={commonClasses.commonBtnIcon} />
          {children}
        </Button>
      </span>
    </Tooltip>
  );
};

CreateEntityButton.defaultProps = {
  disabled: false,
};

CreateEntityButton.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  tooltipTitle: PropTypes.string,
};

export default withDelegationProfiles(CreateEntityButton);
