import { get, destroy, download, post, put } from "./request";
import { displayMonthDayYear, displayHourMinute } from "msa2-ui/src/utils/date";

const API = process.env.REACT_APP_API_PATH;

const reportBy = {
  subtenant: "C",
  tenant: "T",
};

const emptyResponse = {
  reports: [],
  summaryReport: { total: 0 },
};

/*
 * http://10.31.1.52/swagger/#/Device/getDeviceById
 */
export const getManagedEntity = ({
  managedEntityId,
  token,
  transforms = [],
}) => {
  return get({
    url: `${API}/device/v3/${managedEntityId}`,
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Asset%20Management/getDeviceAsset
 */
export const getManagedEntityData = ({
  managedEntityId,
  token,
  transforms = [],
}) => {
  return get({
    url: `${API}/assetManagement/v1/device-asset/device/id/${managedEntityId}`,
    token,
    transforms,
  });
};

const restructureManagedEntitiesReports = (response) => {
  return {
    reports: response.Reports,
    summaryReport: response["Summary Report"],
  };
};

/*
 * Spec: https://10.31.1.52/swagger/#/Device/getDeviceStatusReports
 */
export const getAllManagedEntityReports = ({
  token,
  managerId,
  reportBy: report_by = 34523,
  page = 1,
  pageSize: page_size = 20,
  sort = "+name",
  tenantId: tenant_id,
  subtenantId: customer_id,
  transforms = [restructureManagedEntitiesReports],
  defaultResponse = emptyResponse,
  status,
  filterByLabel,
}) => {
  return get({
    url: `${API}/device/v1/manager/${managerId}/customers`,
    queryParams: {
      report_by,
      page,
      page_size,
      sort,
      tenant_id,
      customer_id,
      status,
      filterByLabel,
    },
    token,
    transforms,
    defaultResponse,
  });
};
getAllManagedEntityReports.reportBy = reportBy;

const restructureManageEntityReport = (report) => {
  return {
    reports: [report],
    summaryReport: report,
  };
};

/*
 * Spec: https://10.31.1.52/swagger/#/Device/getDeviceStatusReport
 */
export const getManagedEntityReport = ({
  token,
  managerId,
  id,
  idType,
  transforms = [restructureManageEntityReport],
  defaultResponse = emptyResponse,
  filterByLabel,
}) => {
  return get({
    url: `${API}/device/v1/status/report/${managerId}`,
    queryParams: {
      id,
      idType,
      filterByLabel,
    },
    token,
    transforms,
    defaultResponse,
  });
};
getManagedEntityReport.reportBy = reportBy;

/*
 * Spec: https://10.31.1.52/swagger/#/Asset%20Management/getCustomerAssets
 */
export const getManagedEntitiesBySubtenant = ({
  token,
  subtenantId,
  page,
  pageSize: page_size,
  modelId,
  manufacturerId,
  filterName,
  filterByLabel,
  filterByStatus,
}) => {
  return get({
    url: `${API}/assetManagement/v1/customer-asset/customer/${subtenantId}`,
    queryParams: {
      page,
      page_size,
      modelId,
      manufacturerId,
      filterName,
      filterByLabel,
      filterByStatus,
    },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Asset%20Management/getCustomerAssetsByOperatorId
 */
export const getManagedEntitiesByTenant = ({
  token,
  tenantId,
  page,
  pageSize: page_size,
  filter,
  filterName: filter_criteria,
  filterByLabel,
}) => {
  return get({
    url: `${API}/assetManagement/v1/customer-asset/operator/prefix/${tenantId}`,
    queryParams: {
      page,
      page_size,
      filter,
      filter_criteria,
      filterByLabel,
    },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Asset%20Management/getCustomerAssetsByManager
 */
export const getManagedEntitiesByAdmin = ({
  token,
  managerId,
  page = 1,
  pageSize: page_size = 10,
  filter,
  filterName: filter_criteria,
  filterByLabel,
}) => {
  return get({
    url: `${API}/assetManagement/v1/customer-asset/manager/${managerId}`,
    queryParams: {
      page,
      page_size,
      filter,
      filter_criteria,
      filterByLabel,
    },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Device/getManagedEntityCount
 */
export const getManagedEntitiesCount = ({
  token,
  subtenantId: customer_id,
  tenantId: tenant_id,
  transforms,
  defaultResponse = 0,
}) => {
  const queryParams = {
    customer_id,
    tenant_id: !customer_id ? tenant_id : null,
  };
  return get({
    url: `${API}/device/v2/count`,
    queryParams,
    token,
    transforms,
    defaultResponse,
  });
};

const normalizeManufacturersAndModels = (response) => {
  return response.reduce(
    (
      {
        manufacturers,
        models,
        manufacturersById,
        modelsById,
        categoriesWithIds,
      },
      manufacturer,
    ) => {
      const {
        manufacturerId,
        manufacturerName,
        models: modelsForManufacturer,
        categories: categoriesForManufacturer,
      } = manufacturer;
      const modelIds = modelsForManufacturer.map((m) => m.modelId);

      let categories = {};

      categoriesForManufacturer.forEach((category) => {
        const manufacturers = categoriesWithIds[category]?.manufacturers || [];
        const models = categoriesWithIds[category]?.models || [];
        categories = {
          [category]: {
            ...categoriesWithIds[category],
            categoryName: category,
            manufacturers: [...manufacturers, manufacturerId],
            models: [...models, ...modelIds],
          },
        };
      });

      return {
        manufacturers: [...manufacturers, manufacturerId],
        models: [...models, ...modelIds],
        categoriesWithIds: {
          ...categoriesWithIds,
          ...categories,
        },
        manufacturersById: {
          ...manufacturersById,
          [manufacturerId]: {
            manufacturerId,
            manufacturerName,
            models: modelIds,
            categories: categoriesForManufacturer,
          },
        },
        modelsById: {
          ...modelsById,
          ...modelsForManufacturer.reduce(
            (acc, curr) => ({ ...acc, [curr.modelId]: curr }),
            [],
          ),
        },
      };
    },
    {
      manufacturers: [],
      models: [],
      categoriesWithIds: {},
      manufacturersById: {},
      modelsById: {},
    },
  );
};

/**
 * Spec: https://10.31.1.52/swagger/index.html#/Device/getManufacturerAndModels
 */
export const getManufacturersAndModels = ({
  token,
  transforms = [normalizeManufacturersAndModels],
}) => {
  return get({
    url: `${API}/device/v1/manufacturers`,
    token,
    transforms,
  });
};

const combineFieldData = ({ fields, mappings }) =>
  Object.keys(mappings).reduce((combinedFields, fieldLabel) => {
    if (!fields[fieldLabel]) return combinedFields;
    const newKey = mappings[fieldLabel];
    return {
      ...combinedFields,
      [newKey]: {
        label: fieldLabel,
        type: fields[fieldLabel],
        name: newKey,
      },
    };
  }, {});

/**
 * Spec: https://10.31.1.52/swagger/index.html#/Device/createDeviceFields
 */
export const getManagedEntityFields = ({
  token,
  manufacturerId,
  modelId,
  transforms = [combineFieldData],
}) => {
  return get({
    url: `${API}/device/v1/form/fields`,
    token,
    queryParams: {
      manufacturerId,
      modelId,
    },
    transforms,
  });
};

/**
 * Spec: http://10.31.1.52/swagger/#/Device/createManagedDevice
 */
export const createManagedEntity = ({
  token,
  subtenantId,
  body,
  transforms = [],
}) => {
  return post({
    url: `${API}/device/v2/${subtenantId}`,
    token,
    body: {
      name: "",
      externalReference: "",
      manufacturerId: "",
      modelId: "",
      managementAddress: "",
      managementInterface: "",
      login: "",
      password: "",
      passwordAdmin: "",
      logEnabled: "",
      logMoreEnabled: "",
      mailAlerting: "",
      reporting: "",
      useNat: "",
      snmpCommunity: "",
      sdNature: "",
      ...body,
    },
    transforms,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/index.html#/Device/updateManagedDevice
 */
export const updateManagedEntity = ({
  token,
  managedEntityId,
  body,
  transforms = [],
}) => {
  return put({
    url: `${API}/device/v2/${managedEntityId}`,
    token,
    body: {
      name: "",
      externalReference: "",
      managementAddress: "",
      managementInterface: "",
      login: "",
      password: "",
      passwordAdmin: "",
      logEnabled: "",
      logMoreEnabled: "",
      mailAlerting: "",
      reporting: "",
      useNat: "",
      snmpCommunity: "",
      sdNature: "",
      ...body,
    },
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Device/deleteDevice_1
 */
export const deleteDevice = ({ token, id }) => {
  return destroy({
    url: `${API}/device/id/${id}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.200/swagger/#/Device/getDeviceExtendedAttributes
 */
export const getMaintenanceModeStatus = ({ token, deviceID }) => {
  return get({
    url: `${API}/device/extended_attribute/get/${deviceID}`,
    token,
    queryParams: {
      attributeName: "MAINTENANCE_MODE",
    },
  });
};

/*
 * Spec: https://10.31.1.200/swagger/#/Device/setDeviceExtendedAttributes
 */
export const setMaintenanceModeStatus = ({
  token,
  deviceID,
  attributeValue,
}) => {
  return put({
    url: `${API}/device/extended_attribute/set/${deviceID}`,
    token,
    queryParams: {
      attributeName: "MAINTENANCE_MODE",
      attributeValue,
    },
  });
};

/*
 * Spec: http://10.31.1.52/swagger/#/Device/getProvisioningStatusById
 */
export const getProvisioningStatus = ({ token, deviceID }) => {
  return get({
    url: `${API}/device/provisioning/status/${deviceID}`,
    token,
  });
};

/*
 * https://10.31.1.52/swagger/#/Device/provisionDeviceByIdAndOptionalParams
 */
export const provisionDevice = ({ token, deviceID }) => {
  return post({
    url: `${API}/device/v2/activate/${deviceID}`,
    token,
  });
};

/*
 * https://10.31.1.52/swagger/#/Device/markAsProvisionedByDeviceId
 */
export const markManagedEntityAsProvisioned = ({ token, managedEntityId }) => {
  return post({
    url: `${API}/device/activate/${managedEntityId}`,
    token,
  });
};

const getDateFormat = (date, reportType) => {
  if (reportType === "day") {
    return displayHourMinute(date);
  }
  return displayMonthDayYear(date);
};

const transformMonitoringData = (reportType) => (response) => {
  return response?.xport?.data?.row.map(
    ({ t: timestampInSeconds, v: values }) => {
      const [, /*ping*/ latency, ttl] = values.map((value) => {
        if (value === "NaN") {
          return "-";
        }
        return parseFloat(value);
      });

      /**
       * For some reason the RRD format returns the timestamp in
       * second rather than milliseconds so we need to convert
       */
      const timestampInMilliseconds = timestampInSeconds * 1000;
      return {
        timestamp: getDateFormat(timestampInMilliseconds, reportType),
        /*ping*/
        latency,
        ttl,
      };
    },
  );
};

/*
 * Spec: https://10.31.1.52/swagger/#/Device/getDeviceMonitoringData
 */
export const getMonitoringData = ({
  token,
  managedEntityId,
  reportType = "day",
  transforms = [transformMonitoringData(reportType)],
}) => {
  return get({
    url: `${API}/device/${managedEntityId}/monitor`,
    token,
    queryParams: {
      name: "AVAILABILITY",
      period: reportType,
    },
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Variables/readConfigurationVariable
 */
export const readConfigurationVariable = ({ token, deviceId, name }) => {
  return get({
    url: `${API}/variables/${deviceId}/${name}`,
    token,
  });
};

/**
 * Spec: https://10.31.1.52/swagger/#/Variables/createConfigurationVariable
 */
export const createConfigurationVariable = ({
  token,
  deviceId,
  name,
  value,
  type,
  comment,
}) => {
  return put({
    url: `${API}/variables/${deviceId}/${name}`,
    token,
    queryParams: {
      value,
      type,
      comment,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Variables/deleteConfigurationVariable
 */
export const deleteConfigurationVariable = ({ token, deviceId, name }) => {
  return destroy({
    url: `${API}/variables/${deviceId}/${name}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Variables/listConfigurationVariable
 */
export const listConfigurationVariable = ({ token, deviceId }) => {
  return get({
    url: `${API}/variables/${deviceId}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Conf%20BackUp%20Service/doListRevisions
 */
export const getRevisionsByDeviceId = ({
  token,
  id: deviceId,
  filters,
  page,
  pageSize,
}) => {
  return get({
    url: `${API}/conf-backup/v2/revision-list/${deviceId}`,
    queryParams: { filters, page, page_size: pageSize },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Conf%20BackUp%20Service/doGetConfiguration
 */
export const getConfiguration = ({ token, deviceId, revision }) => {
  return get({
    url: `${API}/conf-backup/v1/configuration/${deviceId}/${revision}`,
    token,
  });
};

export const getConfigurationsToCompare = async ({
  token,
  deviceId,
  revisions,
}) => {
  const [firstRevision, secondRevision] = await Promise.all([
    getConfiguration({ token, deviceId, revision: revisions[0] }),
    getConfiguration({ token, deviceId, revision: revisions[1] }),
  ]);

  const error = firstRevision[0] || secondRevision[0];
  const response = {
    oldValue: secondRevision[1],
    newValue: firstRevision[1],
  };

  return [error, response];
};

/*
 * Spec: https://10.31.1.52/swagger/#/Conf%20BackUp%20Service/doBackup
 */
export const doBackup = ({ token, deviceId }) => {
  return post({
    url: `${API}/conf-backup/v1/backup/${deviceId}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Conf%20BackUp%20Service/doRestore
 */
export const doRestore = ({ token, deviceId, revision }) => {
  return post({
    url: `${API}/conf-backup/v1/restore/${deviceId}/${revision}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Conf%20BackUp%20Service/doDownloadConfigurationApplied
 */
export const doDownload = ({
  token,
  deviceId,
  revision,
  fileName,
  onError,
}) => {
  return download({
    url: `${API}/conf-backup/v1/${deviceId}/download`,
    token,
    fileName,
    onError,
    queryParams: {
      revision,
    },
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Conf%20BackUp%20Service/getBackupStatusByDeviceId
 */
export const getBackupStatus = ({ token, deviceId }) => {
  return get({
    url: `${API}/conf-backup/v1/backup-status/${deviceId}`,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Conf%20BackUp%20Service/getRestoreStatusByDeviceId
 */
export const getRestoreStatus = ({ token, deviceId }) => {
  return put({
    url: `${API}/conf-backup/v1/restore-status/${deviceId}`,
    token,
  });
};

/*
 * https://10.31.1.52/swagger/#/Device/getLabels
 */
export const getLabels = ({ token, deviceId, type = "ME" }) => {
  return get({
    url: `${API}/device/v2/labels`,
    token,
    queryParams: {
      id: deviceId,
      type,
    },
  });
};

/*
 * https://10.31.1.52/swagger/#/Device/addLabelsToAManagedEntity
 */
export const addLabelsToAManagedEntity = ({ token, deviceId, labels }) => {
  return post({
    url: `${API}/device/v2/${deviceId}/labels`,
    token,
    queryParams: {
      labelValues: labels.join(";"),
    },
  });
};

/*
 * https://10.31.1.52/swagger/#/Device/listAllLabels
 */
export const listAllLabels = ({ token, category, transforms = [] }) => {
  return get({
    url: `${API}/device/v2/list-labels`,
    token,
    queryParams: {
      category,
    },
    transforms,
  });
};
listAllLabels.categories = {
  MICROSERVICE: "MS",
  MANAGED_ENTITY: "ME",
};
