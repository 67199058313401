// Visit https://github.com/bpmn-io/bpmn-js/blob/develop/lib/features/palette/PaletteProvider.js to see the usage and context
// and node_modules/bpmn-js/lib/features/palette/PaletteProvider.js

export default class Palette {
  constructor(
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
    globalConnect,
    translate,
  ) {
    this.palette = palette;
    this.create = create;
    this.elementFactory = elementFactory;
    this.spaceTool = spaceTool;
    this.lassoTool = lassoTool;
    this.handTool = handTool;
    this.globalConnect = globalConnect;
    this.translate = translate;

    palette.registerProvider(this);
  }

  getPaletteEntries(element) {
    const create = this.create;
    const elementFactory = this.elementFactory;
    const lassoTool = this.lassoTool;
    const handTool = this.handTool;
    const globalConnect = this.globalConnect;
    const translate = this.translate;

    function createAction(type, group, className, title, options) {
      function createListener(event) {
        const shape = elementFactory.createShape({ ...options, type });

        if (options) {
          shape.businessObject.di.isExpanded = options.isExpanded;
        }

        create.start(event, shape);
      }

      const shortType = type.replace(/^bpmn:/, "");

      return {
        group: group,
        className: className,
        title: title || translate("Create {type}", { type: shortType }),
        action: {
          dragstart: createListener,
          click: createListener,
        },
      };
    }

    return {
      "hand-tool": {
        group: "tools",
        className: "bpmn-icon-hand-tool",
        title: translate("Activate the hand tool"),
        action: {
          click: function(event) {
            handTool.activateHand(event);
          },
        },
      },
      "lasso-tool": {
        group: "tools",
        className: "bpmn-icon-lasso-tool",
        title: translate("Activate the lasso tool"),
        action: {
          click: function(event) {
            lassoTool.activateSelection(event);
          },
        },
      },
      "global-connect-tool": {
        group: "tools",
        className: "bpmn-icon-connection-multi",
        title: translate("Activate the global connect tool"),
        action: {
          click: function(event) {
            globalConnect.toggle(event);
          },
        },
      },
      "tool-separator": {
        group: "tools",
        separator: true,
      },
      "create.start-event": createAction(
        "bpmn:StartEvent",
        "event",
        "bpmn-icon-start-event-none",
        translate("Create Start Event"),
      ),
      "create.signal-intermediate-event": createAction(
        "bpmn:IntermediateCatchEvent",
        "event",
        "bpmn-icon-intermediate-event-catch-signal",
        translate("Create User Breakpoint"),
        { eventDefinitionType: "bpmn:SignalEventDefinition" },
      ),
      "create.message-intermediate-event": createAction(
        "bpmn:IntermediateCatchEvent",
        "event",
        "bpmn-icon-intermediate-event-catch-message",
        translate("Create Variable Edit Point"),
        { eventDefinitionType: "bpmn:MessageEventDefinition" },
      ),
      "create.end-event": createAction(
        "bpmn:EndEvent",
        "event",
        "bpmn-icon-end-event-none",
        translate("Create End Event"),
      ),
      "create.exclusive-gateway": createAction(
        "bpmn:ExclusiveGateway",
        "gateway",
        "bpmn-icon-gateway-xor",
        translate("Create Decision Gateway"),
      ),
      "create.parallel-gateway": createAction(
        "bpmn:ParallelGateway",
        "gateway",
        "bpmn-icon-gateway-parallel",
        translate("Create Parallel Gateway"),
      ),
      "create.task": createAction(
        "bpmn:Task",
        "activity",
        "bpmn-icon-task",
        translate("Create Workflow Container"),
      ),
    };
  }
}

Palette.$inject = [
  "palette",
  "create",
  "elementFactory",
  "spaceTool",
  "lassoTool",
  "handTool",
  "globalConnect",
  "translate",
];
