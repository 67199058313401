import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

import { getParentRoute } from "msa2-ui/src/utils/urls";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { displayYearMonthDayHourMinuteSecond } from "msa2-ui/src/utils/date";

import { getRoleDetails, userRoles } from "msa2-ui/src/store/auth";
import {
  getMsaTheme,
  getSelectedSubtenant,
} from "msa2-ui/src/store/designations";
import {
  getManagedEntitiesBySubtenant,
  getManagedEntitiesByAdmin,
} from "msa2-ui/src/api/managedEntity";
import { getUserDetails, getToken } from "msa2-ui/src/store/auth";
import Log from "msa2-ui/src/services/Log";
import usePrevious from "msa2-ui/src/hooks/usePrevious";

import { Link, useRouteMatch } from "react-router-dom";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import MsaSelect from "msa2-ui/src/components/msa-select";
import { getFilterValue } from "msa2-ui/src/store/ui";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Popover,
  Select,
  TextField,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as SearchIcon } from "msa2-ui/src/assets/icons/search.svg";
import { ReactComponent as FilterIcon } from "msa2-ui/src/assets/icons/filter.svg";
import { ReactComponent as ResetIcon } from "msa2-ui/src/assets/icons/closeWhite.svg";
import { ReactComponent as IconPlus } from "msa2-ui/src/assets/icons/plus.svg";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import BasicSelect from "msa2-ui/src/components/BasicSelect";
import TablePagination from "msa2-ui/src/components/TablePagination";
import ViewColumnOutlinedIcon from "@material-ui/icons/ViewColumnOutlined";
import DeveloperMode from "@material-ui/icons/DeveloperMode";
import { LOG_EVENT_TYPES } from "msa2-ui/src/Constants";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);

const useStyles = makeStyles(
  ({ breakpoints, spacing, palette, typography, colors }) => {
    return {
      menuItem: {
        [breakpoints.up("md")]: {
          width: "auto",
          paddingRight: 15,
          "&:last-child": {
            paddingRight: 0,
          },
        },
      },
      filteredLabel: {
        color: palette.primary.main,
      },
      resetIcon: {
        marginLeft: 10,
      },
      filterField: {
        height: 70,
      },
      columnFilterBox: {
        width: 600,
        height: "auto",
      },
      inputRoot: {
        "& .Mui-disabled": {
          cursor: "pointer",
        },
      },
      selectInput: {
        fontSize: "0.8125rem",
        lineHeight: "1.2rem",
        padding: "0px 38px 2px 10px",
        textTransform: "capitalize",
        width: 120,
      },
      select: {
        height: 37,
        borderRadius: 20,
        padding: 6,
        marginRight: 10,
        backgroundColor: "transparent",
        "&:hover, &:focus, &:active": {
          backgroundColor: "transparent",
        },
      },
      formControl: {
        margin: spacing(1),
        minWidth: 120,
      },
      searchInputLabel: {
        fontSize: "0.8125rem",
        marginLeft: 10,
      },
      searchField: {
        "& .MuiOutlinedInput-root": {
          borderRadius: 30,
        },
      },
      filterIcon: {
        position: "absolute",
        top: 18,
        left: 15,
      },
      filterInputLabel: {
        fontSize: "0.8125rem",
        position: "absolute",
        top: -8,
        left: 30,
      },
      paper: {
        overflowY: "inherit",
        overflowX: "inherit",
        padding: 10,
      },
      field: {
        textAlign: "left",
        width: 350,
      },
      buttonText: {
        fontSize: "0.875rem",
        fontWeight: typography.fontWeightRegular,
      },
      pagination: {
        marginLeft: "auto",
      },
      viewColumnIcon: {
        color: colors.blueDark1,
      },
      searchModeToggle: {
        marginRight: -7,
      },
      searchModeToggleButton: {
        height: 32,
        width: 32,
      },
    };
  },
);

const SearchField = React.memo(
  ({ onClickSearchMode, handleSearchByChange, searchValue }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [isESQueryMode, setIsESQueryMode] = useState(false);

    return (
      <TextField
        id={"LOG_SEARCH_TEXT_FIELD"}
        variant="outlined"
        fullWidth
        className={classes.searchField}
        style={isESQueryMode ? { width: 600 } : {}}
        size={"small"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment: onClickSearchMode ? (
            <InputAdornment className={classes.searchModeToggle}>
              <Tooltip
                title={
                  isESQueryMode
                    ? t("Switch to Normal Mode")
                    : t("Switch to Elasticsearch Query Mode")
                }
              >
                <IconButton
                  id={"LOG_FILTER_SEARCH_MODE_BUTTON"}
                  className={classes.searchModeToggleButton}
                  aria-label={t("Toggle password visibility")}
                  onClick={() => {
                    setIsESQueryMode(!isESQueryMode);
                    onClickSearchMode(!isESQueryMode);
                  }}
                  tabIndex="-1"
                >
                  <DeveloperMode
                    color={isESQueryMode ? "primary" : "secondary"}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ) : null,
          inputProps: {
            className: classnames(classes.searchInputLabel),
            "aria-label": t("Search Logs"),
            placeholder: t("Search Logs..."),
          },
        }}
        value={searchValue}
        onChange={({ target: { value } }) => handleSearchByChange(value)}
      />
    );
  },
  ({ searchValue: previous }, { searchValue: next }) => isEqual(previous, next),
);

const LogFilterMenu = ({
  renderAlarmAckButton,
  searchValue,
  handleSearchByChange,
  alarmName,
  setAlarmName,
  logType,
  setLogType,
  severities,
  setSeverities,
  subtenantIds,
  setSubtenantIds,
  createAlarmView,
  ubiId,
  managedEntityIds,
  setManagedEntityIds,
  startDate,
  startDateToES,
  setStartDate,
  setStartDateToES,
  endDate,
  endDateToES,
  setEndDate,
  setEndDateToES,
  count,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
  acknowledgement,
  setAcknowledgement,
  columns,
  addColumn,
  removeColumn,
  handleCheck,
  onClickSearchMode,
  handleFilterValue,
  tableName = "logs",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const managedEntityFilterValue = useSelector(
    getFilterValue(tableName, "managedEntityIds"),
  );
  const valueForManagedEntityInFilter = managedEntityFilterValue[0]
    ? [
        {
          value: managedEntityFilterValue[0].id,
          label: managedEntityFilterValue[0].label,
        },
      ]
    : null;

  const [showFilter, setShowFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const { id: managerId } = useSelector(getUserDetails);
  const { url } = useRouteMatch();
  const { id: subtenantId } = useSelector(getSelectedSubtenant) ?? "";
  const token = useSelector(getToken);
  const msaTheme = useSelector(getMsaTheme);
  const roleDetails = useSelector(getRoleDetails);
  const [managedEntities, setManagedEntities] = useState([]);
  const [loadingManagedEntities, setLoadingManagedEntities] = useState(false);

  const prevSubtenantId = usePrevious(subtenantId);
  const managedEntitiesOptions = managedEntities.map(
    ({ name, deviceId }, i) => ({
      label: name,
      ubiId: deviceId.ubiId,
      value: deviceId.ubiId,
      key: `managed_entity_${i}`,
    }),
  );

  const handleFilterValueCallback = useCallback(
    ({ table, key, value }) => {
      if (handleFilterValue) {
        handleFilterValue({
          table: table,
          key: key,
          value: value,
        });
      }
    },
    [handleFilterValue],
  );

  const canCreateAlarm =
    roleDetails?.id === userRoles.PRIVILEGED_ADMINISTRATOR &&
    createAlarmView !== false;

  useEffect(() => {
    if (setManagedEntityIds) {
      (async () => {
        setLoadingManagedEntities(true);
        if (subtenantId) {
          const [, response] = await getManagedEntitiesBySubtenant({
            subtenantId,
            pageSize: 10000,
            token,
            filterByLabel: isPermissionProfileLabelsEnabled,
          });
          setManagedEntities(response);
        } else {
          const [, response] = await getManagedEntitiesByAdmin({
            managerId,
            pageSize: 10000,
            token,
            filterByLabel: isPermissionProfileLabelsEnabled,
          });
          setManagedEntities(response);
        }
        setLoadingManagedEntities(false);
      })();
    }
  }, [managerId, subtenantId, token, setManagedEntityIds]);
  useEffect(() => {
    if (Boolean(startDate) && endDate === null) {
      const date = new Date();
      setEndDate(date);
      setEndDateToES(
        displayYearMonthDayHourMinuteSecond(
          date.getTime() + date.getTimezoneOffset() * 60000,
        ),
      );
      handleFilterValueCallback({
        table: tableName,
        key: "endDate",
        value: date,
      });
    }
  }, [
    startDate,
    setEndDateToES,
    endDate,
    setEndDate,
    handleFilterValueCallback,
    tableName,
  ]);

  const isFiltered =
    severities.length + managedEntityIds.length > 0 ||
    (Boolean(startDateToES) && Boolean(endDateToES));

  const onChangeStartDate = (value) => {
    setStartDate(value);
    setStartDateToES(
      displayYearMonthDayHourMinuteSecond(
        value.getTime() + value.getTimezoneOffset() * 60000,
      ),
    );
    handleFilterValueCallback({
      table: tableName,
      key: "startDate",
      value: value,
    });
  };

  const onChangeEndDate = (value) => {
    setEndDate(value);
    setEndDateToES(
      displayYearMonthDayHourMinuteSecond(
        value.getTime() + value.getTimezoneOffset() * 60000,
      ),
    );
    handleFilterValueCallback({
      table: tableName,
      key: "endDate",
      value: value,
    });
  };

  useEffect(() => {
    if (prevSubtenantId !== undefined && prevSubtenantId !== subtenantId) {
      setManagedEntityIds([]);
      handleFilterValueCallback({
        table: tableName,
        key: "managedEntityIds",
        value: [],
      });
    }
  }, [
    setManagedEntityIds,
    subtenantId,
    prevSubtenantId,
    handleFilterValueCallback,
    tableName,
  ]);

  const isAlarmPage = tableName === "alarms";
  const isLogPage = tableName === "logs";

  return (
    <>
      <Grid container alignItems="center" justifyContent="flex-start">
        {isAlarmPage && (
          <Grid item xs={6} md="auto" className={classes.menuItem}>
            {renderAlarmAckButton({})}
          </Grid>
        )}
        <>
          <Grid item xs={6} md="auto" className={classes.menuItem}>
            <SearchField
              searchValue={searchValue}
              handleSearchByChange={handleSearchByChange}
              onClickSearchMode={onClickSearchMode}
            />
          </Grid>

          <Grid item xs={6} md="auto" className={classes.menuItem}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              classes={{ root: classes.inputRoot }}
            >
              <InputAdornment className={classes.filterIcon} position="start">
                <FilterIcon color="primary" />
              </InputAdornment>
              <InputLabel
                className={classnames(classes.filterInputLabel, {
                  [classes.filteredLabel]: isFiltered,
                })}
              >
                {t("Filter By")}
              </InputLabel>
              <Select
                aria-label={t("Filter By")}
                className={classes.select}
                classes={{ select: classes.selectInput }}
                disabled
                onClick={({ currentTarget }) => setShowFilter(currentTarget)}
                value={""}
              />
            </FormControl>
            {showFilter && (
              <Popover
                id={"LOG_FILTER_POPOVER"}
                open
                anchorEl={showFilter}
                onClose={() => setShowFilter(undefined)}
                classes={{ paper: classes.paper }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Paper
                  square
                  className={classnames([
                    commonClasses.commonPaperNoPadding,
                    classes.paper,
                  ])}
                >
                  {isAlarmPage && (
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      className={classes.filterField}
                    >
                      <TextField
                        variant="outlined"
                        id="LOG_FILTER_ALARM_NAME"
                        aria-label={t("Alarm Name Filter")}
                        label={t("Alarm Name")}
                        value={alarmName}
                        onChange={({ target: { value } }) => {
                          setAlarmName(value);
                          handleFilterValueCallback({
                            table: tableName,
                            key: "alarmName",
                            value: value,
                          });
                        }}
                        className={classes.field}
                      />
                      <Tooltip title={t("Reset Alarm Name")}>
                        <IconButton
                          id="LOG_FILTER_RESET_ALARM_NAME"
                          aria-label={t("Reset Alarm Name")}
                          onClick={() => {
                            setAlarmName("");
                            handleFilterValueCallback({
                              table: tableName,
                              key: "alarmName",
                              value: "",
                            });
                          }}
                          className={classes.resetIcon}
                        >
                          <ResetIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={classes.filterField}
                  >
                    <BasicSelect
                      id="LOG_FILTER_SELECT_TYPE"
                      data-testid="log-filter-select-type"
                      aria-label={t("Log Type Filter")}
                      label={t("Log Type")}
                      className={classes.field}
                      value={logType}
                      onChange={({ target: { value } }) => {
                        setLogType(value);
                        handleFilterValueCallback({
                          table: tableName,
                          key: "logType",
                          value: value,
                        });
                      }}
                    >
                      {Object.entries(LOG_EVENT_TYPES).map(
                        ([, { value, label }], index) => (
                          <MenuItem value={value} key={`event_type_${index}`}>
                            {label}
                          </MenuItem>
                        ),
                      )}
                    </BasicSelect>
                    <Tooltip title={t("Reset Log Type")}>
                      <IconButton
                        id="LOG_FILTER_RESET_TYPE"
                        aria-label={t("Reset Log Type")}
                        onClick={() => {
                          setLogType(null);
                          handleFilterValueCallback({
                            table: tableName,
                            key: "logType",
                            value: null,
                          });
                        }}
                        className={classes.resetIcon}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={classes.filterField}
                  >
                    <BasicSelect
                      id="LOG_FILTER_SELECT_SEVERITY"
                      aria-label={t("Severity Filter")}
                      label={t("Severity")}
                      className={classes.field}
                      value={severities}
                      onChange={({ target: { value } }) => {
                        setSeverities(value);
                        handleFilterValueCallback({
                          table: tableName,
                          key: "severities",
                          value: value,
                        });
                      }}
                      multiple
                    >
                      {Object.entries(Log.SEVERITY_LEVELS(msaTheme)).map(
                        ([severityLevel, { label }], index) => (
                          <MenuItem
                            value={
                              isAlarmPage
                                ? severityLevel
                                : parseInt(severityLevel)
                            }
                            key={`severity_level_${index}`}
                          >
                            {`${severityLevel}: ${label}`}
                          </MenuItem>
                        ),
                      )}
                    </BasicSelect>
                    <Tooltip title={t("Reset Severity")}>
                      <IconButton
                        id="LOG_FILTER_RESET_SEVERITY"
                        aria-label={t("Reset Severity")}
                        onClick={() => {
                          setSeverities([]);
                          handleFilterValueCallback({
                            table: tableName,
                            key: "severities",
                            value: [],
                          });
                        }}
                        className={classes.resetIcon}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {!ubiId && managedEntityIds && (
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      className={classes.filterField}
                    >
                      {loadingManagedEntities ? (
                        <Grid
                          container
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <MsaSelect
                          id="LOG_FILTER_SELECT_MANAGED_ENTITY"
                          placeholder={t("Managed Entity")}
                          fullWidth={false}
                          className={classes.field}
                          options={managedEntitiesOptions}
                          isMulti={false}
                          value={valueForManagedEntityInFilter}
                          onChange={(selectedME) => {
                            const value = selectedME?.value;
                            const name = selectedME?.label;
                            setManagedEntityIds(value ? [value] : []);
                            handleFilterValueCallback({
                              table: tableName,
                              key: "managedEntityIds",
                              value: name ? [{ label: name, id: value }] : [],
                            });
                          }}
                          onKeyDown={(evt) => evt.stopPropagation()}
                        />
                      )}
                      <Tooltip title={t("Reset Managed Entity")}>
                        <IconButton
                          id="LOG_FILTER_RESET_MANAGED_ENTITY"
                          aria-label={t("Reset Managed Entity")}
                          onClick={() => {
                            setManagedEntityIds([]);
                            handleFilterValueCallback({
                              table: tableName,
                              key: "managedEntityIds",
                              value: [],
                            });
                          }}
                          className={classes.resetIcon}
                        >
                          <ResetIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {isAlarmPage && (
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      className={classes.filterField}
                    >
                      <BasicSelect
                        id="LOG_FILTER_SELECT_ACK"
                        aria-label={t("Ack Filter")}
                        label={t("Acknowledement")}
                        className={classes.field}
                        value={acknowledgement ?? ""}
                        onChange={({ target: { value } }) => {
                          setAcknowledgement(value);
                          handleFilterValueCallback({
                            table: tableName,
                            key: "ack",
                            value: value,
                          });
                        }}
                      >
                        <MenuItem value={"true"} key={`ack_0`}>
                          true
                        </MenuItem>
                        <MenuItem value={"false"} key={`ack_1`}>
                          false
                        </MenuItem>
                      </BasicSelect>
                      <Tooltip title={t("Reset Acknowledge")}>
                        <IconButton
                          id="LOG_FILTER_RESET_ACK"
                          aria-label={t("Reset Acknowledge")}
                          onClick={() => {
                            setAcknowledgement("");
                            handleFilterValueCallback({
                              table: tableName,
                              key: "ack",
                              value: "",
                            });
                          }}
                          className={classes.resetIcon}
                        >
                          <ResetIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={classes.filterField}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        variant="inline"
                        format="yyyy/MM/dd HH:mm"
                        id="LOG_FILTER_START_DATE"
                        label={t("Start date")}
                        value={startDate}
                        onChange={onChangeStartDate}
                        className={classes.field}
                      />
                    </MuiPickersUtilsProvider>
                    <Tooltip title={t("Reset Start Date")}>
                      <IconButton
                        id="LOG_FILTER_RESET_START_DATE"
                        aria-label={t("Reset Start Date")}
                        onClick={() => {
                          setStartDate(null);
                          setStartDateToES("");
                          handleFilterValueCallback({
                            table: tableName,
                            key: "startDate",
                            value: null,
                          });
                        }}
                        className={classes.resetIcon}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={classes.filterField}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        variant="inline"
                        format="yyyy/MM/dd HH:mm"
                        id="LOG_FILTER_END_DATE"
                        label={t("End date")}
                        value={endDate}
                        onChange={onChangeEndDate}
                        className={classes.field}
                      />
                    </MuiPickersUtilsProvider>
                    <Tooltip title={t("Reset End Date")}>
                      <IconButton
                        id="LOG_FILTER_RESET_END_DATE"
                        aria-label={t("Reset End Date")}
                        onClick={() => {
                          setEndDate(null);
                          setEndDateToES("");
                          handleFilterValueCallback({
                            table: tableName,
                            key: "endDate",
                            value: null,
                          });
                        }}
                        className={classes.resetIcon}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Paper>
              </Popover>
            )}
          </Grid>
          <Grid item xs={6} md="auto" className={classes.menuItem}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              classes={{ root: classes.inputRoot }}
            >
              <InputAdornment
                className={classnames(
                  classes.filterIcon,
                  classes.viewColumnIcon,
                )}
                position="start"
              >
                <ViewColumnOutlinedIcon />
              </InputAdornment>
              <InputLabel className={classnames(classes.filterInputLabel)}>
                {t("Columns")}
              </InputLabel>
              <Select
                aria-label={t("Columns")}
                className={classes.select}
                classes={{ select: classes.selectInput }}
                disabled
                onClick={({ currentTarget }) => setShowColumns(currentTarget)}
                value={""}
              />
            </FormControl>
            {showColumns && (
              <Popover
                id={"COLUMN_FILTER_POPOVER"}
                open
                anchorEl={showColumns}
                onClose={() => setShowColumns(undefined)}
                classes={{ paper: classes.paper }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Paper
                  square
                  className={classnames([
                    commonClasses.commonPaperNoPadding,
                    classes.paper,
                  ])}
                >
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={classes.columnFilterBox}
                  >
                    {columns
                      .filter((key) => !["check", "details"].includes(key.id))
                      .map((entry) => (
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="ALARM_HEADER"
                                onChange={(e) => {
                                  if (addColumn && removeColumn) {
                                    e.target.checked
                                      ? addColumn(entry.id)
                                      : removeColumn(entry.id);
                                  }
                                  if (handleCheck) {
                                    handleCheck(e, entry.id);
                                  }
                                }}
                                value={entry}
                                checked={entry.check}
                              />
                            }
                            label={entry.name}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
              </Popover>
            )}
          </Grid>
          {/* create alarm button */}
          {canCreateAlarm && isLogPage && (
            <Grid item xs={6} md="auto" className={classes.menuItem}>
              <Button
                id="LOG_SEARCH_CREATE_ALARM"
                variant="outlined"
                aria-label={t("Create Alarm")}
                component={Link}
                className={classes.buttonText}
                to={{
                  pathname: `${getParentRoute(url)}/manage/create`,
                  state: {
                    alarmData: {
                      query: {
                        terms: searchValue,
                        severities,
                        subtenantIds,
                        managedEntityIds,
                      },
                    },
                  },
                }}
              >
                <IconPlus className={commonClasses.commonBtnIcon} />
                {t("Create Alarm")}
              </Button>
            </Grid>
          )}
        </>
        <Grid
          item
          xs={6}
          md="auto"
          className={classnames(classes.menuItem, classes.pagination)}
        >
          <TablePagination
            rowsPerPage={rowsPerPage}
            count={count}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

LogFilterMenu.propTypes = {
  handleSearchByChange: PropTypes.func,
  searchValue: PropTypes.string,
  severities: PropTypes.array,
  setSeverities: PropTypes.func,
  subtenantIds: PropTypes.array,
  managedEntityIds: PropTypes.array,
  setManagedEntityIds: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  removeColumn: PropTypes.func,
  addColumn: PropTypes.func,
};

export default LogFilterMenu;
