import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { getFormSyncErrors, change } from "redux-form";
import { useTranslation } from "react-i18next";
import flow from "lodash/flow";
import get from "lodash/get";
import Variable from "msa2-ui/src/services/Variable";
import Validation from "msa2-ui/src/services/Validation";
import AdvancedParameterField from "msa2-ui/src/components/variables/AdvancedParameterField";

import { getFormValues } from "msa2-ui/src/store/form";
import {
  getVariableTypes,
  getAdvancedVariableParametersByName,
} from "msa2-ui/src/store/designations";

import { makeStyles } from "@material-ui/core";
import {
  Tooltip,
  Tabs,
  Tab,
  FormControl,
  TextField,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import FeatureFlag from "msa2-ui/src/services/FeatureFlag";

import TabPanel from "msa2-ui/src/components/TabPanel";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";

import AdvancedParametersTab from "./AdvancedParametersTab";

const isRepositoryDisabled = !FeatureFlag.isEnabled(
  FeatureFlag.features.repository,
);

const useStyles = makeStyles(({ darkMode, palette }) => ({
  tabPanel: {
    height: "calc(100vh - 350px)",
    overflowY: "auto",
    padding: 15,
    textAlign: "left",
  },
  tabsBorder: {
    borderBottom: `1px solid ${darkMode ? palette.text.hint : "#e8e8e8"}`,
    width: "100%",
    marginLeft: 15,
    marginRight: 15,
  },
  fieldPadding: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

const getElementId = (path) => (suffix) => {
  const prefix = path.replace(/\./g, "_").toUpperCase();
  return `${prefix}_${suffix}`;
};

const VariableEditor = ({
  form,
  variablePath,
  activeVariable,
  change,
  // This will be set when the component is called recursively.
  // (means this is called by clicking edit icon in behaviour section for Composite type)
  nestedVariablePath,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation("variables");

  const formData = useSelector(getFormValues(form));
  const advancedVariableParametersByName = useSelector(
    getAdvancedVariableParametersByName(form),
  );

  const activeVariablePath =
    nestedVariablePath || `${variablePath}.${activeVariable?.index.toString()}`;

  const currentVariable = useSelector(
    getFormValues(form, `${activeVariablePath}.name`),
  );

  //Leaving this as is, but it will likely use a form path depending on workflow or microservices.
  const variableTypes = useSelector(getVariableTypes(form));

  const commonParams = {
    form,
    variablePath,
    activeVariable,
    // If nestedVariablePath is set (means if this component is called recursively),
    // Set nested path and call and nest children
    path: nestedVariablePath || activeVariablePath,
  };

  const [tabIndex, setTabIndex] = useState(0);
  const changeTab = (event, index) => {
    setTabIndex(index);
  };

  const isEditable = (item) =>
    Variable.isMandatoryItem(item, form) &&
    item === activeVariable?.variable?.name;

  const addPrefix = (event) => {
    change(
      form,
      `${activeVariablePath}.name`,
      `params.${Variable.cleanVariableName(event.target.value.trim())}`,
    );
  };

  const handleOnChangeType = (selectedType) => {
    const {
      name,
      displayName,
      description,
      type,
      displayOrder,
      selectorValue,
    } = get(formData, activeVariablePath);

    change(form, activeVariablePath, {
      ...Variable.initVariable,
      name,
      displayName,
      description,
      type,
      displayOrder,
      selectorValue,
      ...(selectedType === Variable.TYPES.AUTOINCREMENT
        ? { increment: 1, startIncrement: 0 }
        : {}),
      ...(selectedType === Variable.TYPES.IPADDRESS ||
      selectedType === Variable.TYPES.IPMASK
        ? { maxLength: 130 }
        : {
            maxLength: advancedVariableParametersByName.maxLength.defaultValue,
          }),
    });
  };

  const formError = useSelector(getFormSyncErrors(form));
  const error = get(formError, activeVariablePath);

  const getId = getElementId(activeVariablePath);

  return (
    <>
      <Tabs
        className={classes.tabsBorder}
        value={tabIndex}
        onChange={changeTab}
        indicatorColor="primary"
      >
        <Tab
          id={getId("VARIABLE_EDITOR_DEFAULT_TAB")}
          classes={{
            root: commonClasses.commonTab,
            selected: commonClasses.commonTabSelected,
          }}
          label={t("Default")}
        />
        <Tab
          id={getId("VARIABLE_EDITOR_ADVANCED_TAB")}
          classes={{
            root: commonClasses.commonTab,
            selected: commonClasses.commonTabSelected,
          }}
          label={t("Advanced")}
        />
      </Tabs>
      <TabPanel
        className={classes.tabPanel}
        style={{ display: tabIndex === 0 ? "block" : "none" }}
      >
        <FormControl className={classes.fieldPadding} fullWidth>
          <Tooltip
            title={
              isEditable(currentVariable)
                ? t("You cannot edit a default variable.")
                : ""
            }
          >
            <TextField
              id={getId("VARIABLES_EDIT_CREATE_NAME")}
              label={t("Variable")}
              size={"medium"}
              value={Variable.removePrefix(currentVariable)}
              onChange={addPrefix}
              // disable it when it is mandatory item (like object_id) or it is child window for Composite
              disabled={
                isEditable(currentVariable) || Boolean(nestedVariablePath)
              }
              variant="outlined"
              required
              error={Boolean(error?.name)}
              helperText={error?.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`$params.`}</InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </FormControl>
        <FormControl className={classes.fieldPadding} fullWidth>
          <FormSelect
            name={`${activeVariablePath}.type`}
            label={t("Type")}
            onChange={handleOnChangeType}
            inputProps={{
              "aria-label": "typeSelect",
              id: getId("VARIABLES_EDIT_CREATE_TYPE"),
            }}
          >
            {variableTypes
              // Filter out File type when Repository feature flag is disabled
              .filter((item) => !(item === "File" && isRepositoryDisabled))
              .map((item) => (
                <MenuItem value={item} key={item}>
                  {t(Variable.DISPLAY_NAMES[item] ?? item)}
                </MenuItem>
              ))
              .concat(
                (() => {
                  const { type } = get(formData, activeVariablePath);
                  if (variableTypes.includes(type)) return null;
                  const item = Variable.MAP_LEGACY_KEY_TO_NEW[type];
                  return (
                    <MenuItem value={type} key={type}>
                      {t(Variable.DISPLAY_NAMES[item] ?? item ?? type)}
                    </MenuItem>
                  );
                })(),
              )}
          </FormSelect>
        </FormControl>
        <AdvancedParameterField
          {...commonParams}
          parameterName={"displayName"}
          label={t("Display Name")}
          validate={[Validation.required]}
        />
        <AdvancedParameterField
          {...commonParams}
          parameterName={"description"}
          label={t("Description")}
          inputProps={{ multiline: true, rows: "3" }}
        />
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        style={{ display: tabIndex === 1 ? "block" : "none" }}
      >
        <AdvancedParametersTab commonParams={commonParams} />
      </TabPanel>
    </>
  );
};

VariableEditor.propTypes = {
  form: PropTypes.string.isRequired,
  nestedVariablePath: PropTypes.string,
  variablePath: PropTypes.string.isRequired,
  activeVariable: PropTypes.object,
};

export default flow(connect(null, { change }))(VariableEditor);
