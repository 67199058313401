import React from "react";
import PropTypes from "prop-types";
import MicroserviceVariables from "msa2-ui/src/services/MicroserviceVariables";
import Validation from "msa2-ui/src/services/Validation";
import omit from "lodash/omit";

import BasicSelect from "msa2-ui/src/components/BasicSelect";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ComboBox from "msa2-ui/src/components/ComboBox";

const useStyles = makeStyles(() => ({
  checkbox: {
    height: 20,
  },
}));

const Field = ({
  type,
  label,
  listData,
  value,
  onClick,
  onChange,
  inputProps,
  searchOptions,
  validate = [],
}) => {
  const classes = useStyles();
  let textFieldFormat = "text";
  const lowerCaseType = type?.toLowerCase();
  const required = validate.includes(Validation.required);
  if (lowerCaseType === "boolean" || lowerCaseType === "boolan") {
    const booleanValue = MicroserviceVariables.stringToBool(value);
    return (
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={booleanValue}
              onClick={({ target: { checked } }) => {
                onClick(checked);
              }}
              {...omit(inputProps, "error", "helperText")}
            />
          }
          label={label}
          className={classes.checkbox}
        />
      </FormControl>
    );
  }
  if (lowerCaseType === "combo") {
    return (
      <ComboBox
        options={listData.map(({ name, displayName, ...rest }) => ({
          value: name,
          label: displayName,
          ...rest,
        }))}
        value={value}
        label={label}
        onChange={onClick}
        fullWidth
        searchOptions={searchOptions}
        required={required}
        {...inputProps}
      />
    );
  }

  if (listData || lowerCaseType === "list") {
    return (
      <BasicSelect
        value={value}
        onChange={onChange}
        label={label}
        variant="outlined"
        required={required}
        {...inputProps}
      >
        {listData?.map((item, i) => {
          return (
            <MenuItem
              key={i}
              value={typeof item === "string" ? item : item.name}
            >
              {typeof item === "string" ? item : item.displayName}
            </MenuItem>
          );
        })}
      </BasicSelect>
    );
  }

  if (lowerCaseType === "integer" || lowerCaseType === "long") {
    textFieldFormat = "number";
  }

  if (lowerCaseType === "password") {
    textFieldFormat = "password";
  }

  return (
    <TextField
      autoComplete="new-password"
      variant="outlined"
      label={label}
      value={value}
      type={textFieldFormat}
      onChange={onChange}
      required={required}
      {...inputProps}
    />
  );
};

Field.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  listData: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  searchOptions: PropTypes.func,
};

export default Field;
