import { useEffect, useRef } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";

export default (callback) => {
  const { pathname: currentPath } = useLocation();
  const { path: routePath } = useRouteMatch();
  const lastPath = useRef(currentPath);

  useEffect(() => {
    if (currentPath !== lastPath.current && currentPath === routePath) {
      callback();
    }
    lastPath.current = currentPath;
  }, [callback, routePath, currentPath]);
};
