import i18n from "i18next";

class APIError extends Error {
  constructor(statusCode, data = {}) {
    super();
    this.data = data;
    this.message = data.message;
    this.name = "APIError";
    this.statusCode = statusCode;
  }

  getMessage(clientMessage = "") {
    return (
      // normally "message" field should not have Java exception, but sometimes it does. we truncate it on front-end side..
      this.message?.replace(/[\w.]+Exception(\$\w+)?: */g, "") ||
      clientMessage ||
      i18n.t("The server encountered an error processing the request.")
    );
  }
}

export default APIError;
