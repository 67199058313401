import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getAvailableSubtenants,
  getMsaTheme,
} from "msa2-ui/src/store/designations";
import Log from "msa2-ui/src/services/Log";

import { Grid, Typography, TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import BasicSelect from "msa2-ui/src/components/BasicSelect";
import { MsaSelectCreatable } from "msa2-ui/src/components/msa-select";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles(({ spacing }) => ({
  field: {
    marginTop: spacing(4),
    textAlign: "left",
    width: 350,
  },
}));

const UpsertAlarmFormConditionsTab = ({
  formState,
  dispatch,
  managedEntities: _managedEntities = [],
}) => {
  const {
    query: { terms, subtenantIds, managedEntityIds, severities },
  } = formState;

  const { t } = useTranslation();
  const theme = useSelector(getMsaTheme);
  const classes = useStyles();

  const subtenants = useSelector(getAvailableSubtenants);
  const uniqueMEs = _managedEntities.filter(
    (me, index) =>
      index ===
      _managedEntities.findIndex(
        (item) => item.deviceId.ubiId === me.deviceId.ubiId,
      ),
  );
  const managedEntities = uniqueMEs.map(({ name, deviceId }, i) => ({
    label: name,
    ubiId: deviceId.ubiId,
    value: deviceId.ubiId,
  }));

  const onChangeSelectedSubtenantId = (selectedSubtenants) => {
    dispatch({
      type: "updateQuerySubtenantIds",
      payload: isEmpty(selectedSubtenants)
        ? []
        : selectedSubtenants.map(({ id }) => id),
    });
  };

  const onChangeSelectedManagedEntityId = (selectedManagedEntitys) => {
    dispatch({
      type: "updateQueryManagedEntityIds",
      payload: isEmpty(selectedManagedEntitys)
        ? []
        : selectedManagedEntitys.map(({ ubiId }) => ubiId),
    });
  };

  const severityLevels = Object.keys(Log.SEVERITY_LEVELS(theme)).filter(
    (level) => level !== "-1",
  );
  const renderSeveritiesPickerLabel = (selectedSeverities) =>
    selectedSeverities
      .map(
        (selectedSeverity) =>
          Log.SEVERITY_LEVELS(theme)[selectedSeverity].label,
      )
      .join(", ");
  const onChangeSelectedSeverities = (event) => {
    dispatch({
      type: "updateQuerySeverities",
      payload: event.target.value,
    });
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Typography>
        {t("Trigger on logs that meet all of these conditions")}:
      </Typography>
      <TextField
        id="UPSERT_ALARM_FORM_QUERY_TERMS_FIELD"
        size="medium"
        label={t("Contains String")}
        value={terms.replace(/\*/g, "")}
        onChange={({ target: { value } }) =>
          dispatch({ type: "updateQueryTerms", payload: value })
        }
        variant="outlined"
        inputProps={{
          "data-testid": "upsert-alarm-form-query-terms-field",
        }}
        className={classes.field}
      />
      <Grid>
        <MsaSelectCreatable
          id="UPSERT_ALARM_FORM_QUERY_SUBTENANT_IDS_FIELD"
          options={subtenants}
          placeholder={t("Subtenants")}
          isMulti
          value={subtenants.filter(({ id }) => subtenantIds.includes(id))}
          onChange={onChangeSelectedSubtenantId}
          onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
          className={classes.field}
        />
      </Grid>
      <Grid>
        <MsaSelectCreatable
          id="UPSERT_ALARM_FORM_QUERY_ME_IDS_FIELD"
          options={managedEntities}
          placeholder={t("Managed Entity")}
          value={managedEntities.filter(({ ubiId }) =>
            managedEntityIds.includes(ubiId),
          )}
          getOptionValue={({ ubiId }) => ubiId}
          isMulti
          onChange={onChangeSelectedManagedEntityId}
          onKeyDown={(evt) => evt.stopPropagation()} // prevents loosing focus when some keys are pressed
          className={classes.field}
        />
      </Grid>
      <BasicSelect
        id="UPSERT_ALARM_FORM_QUERY_SEVERITIES_FIELD"
        label={t("Severity Levels")}
        variant="outlined"
        className={classes.field}
        name="upsert-alarm-form-query-severities-field"
        value={severities}
        renderValue={renderSeveritiesPickerLabel}
        onChange={onChangeSelectedSeverities}
        multiple
      >
        {severityLevels.map((severityLevel, index) => (
          <MenuItem
            value={parseInt(severityLevel)}
            key={`severity_level_${index}`}
          >
            {Log.SEVERITY_LEVELS(theme)[severityLevel].label}
          </MenuItem>
        ))}
      </BasicSelect>
    </Grid>
  );
};

UpsertAlarmFormConditionsTab.propTypes = {
  formState: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  managedEntities: PropTypes.arrayOf(PropTypes.shape({})),
};

export default UpsertAlarmFormConditionsTab;
