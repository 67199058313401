import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { AppBar, Toolbar as MUIToolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import commonStyles from "msa2-ui/src/styles/commonStyles";
import { mergeStyles } from "msa2-ui/src/utils/styles";

const toolbarStyles = (theme) => ({
  appBar: {
    backgroundColor: theme.colors.white,
    display: "flex",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarSection: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
});

const Toolbar = ({ className, classes, children }) => {
  return (
    <AppBar
      className={classnames(className, classes.appBar)}
      position="static"
      color="default"
    >
      <MUIToolbar className={classes.toolbar}>{children}</MUIToolbar>
    </AppBar>
  );
};

Toolbar.propTypes = {
  children: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const sectionStyles = mergeStyles([
  () => ({
    section: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
  }),
  commonStyles,
]);

const Section = ({ classes, children }) => {
  return (
    <section className={classnames(classes.section, classes.commonFlexRow)}>
      {children}
    </section>
  );
};

Section.propTypes = {
  classes: PropTypes.object,
};

Toolbar.Section = withStyles(sectionStyles)(Section);

const dividerStyles = (theme) => ({
  container: {
    display: "flex",
    height: 60,
    padding: "0 20px",
  },
  vr: {
    borderRight: `1px solid ${theme.colors.greyLight1}`,
    borderLeft: "0",
    color: theme.colors.greyLight1,
  },
});

const Divider = ({ classes }) => {
  return (
    <div className={classes.container}>
      <hr className={classes.vr} />
    </div>
  );
};

Divider.propTypes = {
  classes: PropTypes.object,
};

Toolbar.Divider = withStyles(dividerStyles)(Divider);

export default withStyles(toolbarStyles)(Toolbar);
