import React from "react";
import PropTypes from "prop-types";
import flow from "lodash/flow";
import { TextField, InputAdornment } from "@material-ui/core";

const AdornedTextField = ({
  onChange,
  value,
  prefix,
  suffix,
  InputProps = {},
  ...rest
}) => {
  const regexpPrefix = new RegExp("^" + prefix, "g");
  const regexpSuffix = new RegExp(suffix + "$", "g");
  const trimmedValue = flow(
    (value) => (prefix ? value.replace(regexpPrefix, "") : value),
    (value) => (suffix ? value.replace(regexpSuffix, "") : value),
  )(value);

  return (
    <TextField
      value={trimmedValue}
      onChange={(event) => {
        const newValue = (prefix || "") + event.target.value + (suffix || "");
        onChange && onChange(event, newValue);
      }}
      InputProps={{
        startAdornment: prefix && (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
        endAdornment: suffix && (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ),
        ...InputProps,
      }}
      {...rest}
    />
  );
};

AdornedTextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  InputProps: PropTypes.object,
};

export default AdornedTextField;
