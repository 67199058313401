import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../store/settings";

const languageQueryParam = "language";

/**
 * A hook to adapt language value in translation & redux from route query param
 * with given allowed/restricted routes
 *
 * @param {Array} allowedRoutes | All routes allowed if it is Empty
 * @param {Array} restrictedRoutes
 * @param {Boolean} removeParamFromUrl | will remove query param from URL if it true else not
 */
const useRouteLanguageParam = (
  allowedRoutes = [],
  restrictedRoutes = [],
  removeParamFromUrl = false,
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  const language = searchParams.get(languageQueryParam);

  if (
    language &&
    (isEmpty(allowedRoutes) || allowedRoutes.includes(pathname)) &&
    !restrictedRoutes.includes(pathname)
  ) {
    dispatch(changeLanguage(language));
    if (removeParamFromUrl) {
      // Remove a specific query parameter
      searchParams.delete(languageQueryParam);

      // Set the updated search query on the URL without reloading the page
      history.replace({
        pathname,
        search: searchParams.toString(),
      });
    }
  }
};

export default useRouteLanguageParam;
