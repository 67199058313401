export const normalizePath = (path) =>
  path
    .split("/")
    .filter((e) => e)
    .join("/");

export const buildRoute = (url, destination) => {
  const lastCharacterOfUrl = url.charAt(url.length - 1);
  if (lastCharacterOfUrl === "/") {
    return `${url}${destination}`;
  }

  return `${url}/${destination}`;
};

export const returnToPreviousPage = (history, url) => {
  history.push(getParentRoute(url));
};

export const getParentRoute = (url, gen = 1) =>
  url
    .split("/")
    .slice(0, -gen)
    .join("/");

export const getQueryParamValue = (url, queryParamName) => {
  if (!url || !queryParamName) {
    return null;
  }

  try {
    const urlObject = new URL(url);
    const queryParamValue = urlObject.searchParams.get(queryParamName);
    if (queryParamValue) {
      return decodeURIComponent(queryParamValue);
    } else {
      return null;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const isValidUrl = (string) => {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
};

export const buildQueryParamsFromObject = (object) =>
  Object.entries(object)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
    .join("&");

// function to pass .sort
export const compareUrlByDepth = (routeA, routeB) => {
  const getDepth = (route) => route.split("/").filter((route) => route).length;
  return getDepth(routeA) < getDepth(routeB) ? 1 : -1;
};
