import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import reduxForm from "redux-form/lib/reduxForm";
import Field from "redux-form/lib/Field";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import flow from "lodash/flow";

import { getFormValues } from "msa2-ui/src/store/form";
import {
  getAvailableSubtenants,
  getModelsById,
  getManufacturersById,
  getManufacturers,
} from "msa2-ui/src/store/designations";
import {
  deploymentSettingsFields,
  deploymentSettingsRequiredFields,
} from "./helpers";

import { CircularProgress, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";

import Textfield from "msa2-ui/src/components/connectedFormComponents/Textfield";
import FormSelect from "msa2-ui/src/components/formSection/FormSelect";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    paddingTop: 10,
    paddingRight: 100,
    paddingLeft: 100,
  },
  field: {
    width: "100%",
    marginRight: 16,
    marginLeft: 16,
    marginBottom: 24,
    padding: 8,
  },
  itemWrapper: {
    height: 300,
    overflowX: "auto",
  },
}));

const InformationTab = ({ change, form, submitting }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const id = useSelector(getFormValues(form, "id"));
  const vendor = useSelector(getFormValues(form, "vendor.id"));

  const subtenants = useSelector(getAvailableSubtenants);
  const manufacturers = useSelector(getManufacturers);
  const manufacturersById = useSelector(getManufacturersById);
  const modelsById = useSelector(getModelsById);
  const isCreate = Boolean(!id);

  const handleVendorChange = (event, value) => {
    change(["vendor.name"], manufacturersById[value].manufacturerName);
    const defaultModelId = manufacturersById[value].models[0];
    change(["model.id"], defaultModelId);
    const { modelName } = modelsById[defaultModelId];
    change(["model.name"], modelName);
    change(["attachedManagedEntities"], []);
    change(["microserviceUris"], []);
    change(["templateUris"], []);
  };
  const handleModelChange = (event, value) => {
    change(["model.name"], modelsById[value].modelName);
    change(["attachedManagedEntities"], []);
    change(["microserviceUris"], []);
    change(["templateUris"], []);
  };

  return (
    <Grid container className={classes.contentWrapper}>
      {!manufacturers || submitting ? (
        <Grid
          item
          xs={12}
          className={classnames(
            classes.itemWrapper,
            commonClasses.commonFlexCenter,
          )}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {isCreate && (
            <Grid item xs={8}>
              <FormSelect
                name={"subtenantId"}
                label={t(deploymentSettingsFields.subtenantId)}
                className={classes.field}
              >
                {subtenants.map(({ id, label }) => (
                  <MenuItem value={id} key={id}>
                    {label}
                  </MenuItem>
                ))}
              </FormSelect>
            </Grid>
          )}
          <Grid item xs={8}>
            <Field
              name={"name"}
              id={"name"}
              label={t(deploymentSettingsFields.name)}
              component={Textfield}
              required={deploymentSettingsRequiredFields.includes("name")}
              className={classes.field}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={"externalReference"}
              id={"externalReference"}
              label={t(deploymentSettingsFields.externalReference)}
              component={Textfield}
              required={deploymentSettingsRequiredFields.includes(
                "externalReference",
              )}
              className={classes.field}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name={"vendor.id"}
              label={t(deploymentSettingsFields.vendor.id)}
              className={classes.field}
              onChange={handleVendorChange}
              disabled={!isCreate}
            >
              {manufacturers.map((id) => (
                <MenuItem value={id} key={id}>
                  {manufacturersById[id].manufacturerName}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name={"model.id"}
              label={t(deploymentSettingsFields.model.id)}
              required={deploymentSettingsRequiredFields.includes("model.id")}
              className={classes.field}
              onChange={handleModelChange}
              disabled={!isCreate}
            >
              {manufacturersById[vendor]?.models.map((id) => (
                <MenuItem value={id} key={id}>
                  {modelsById[id].modelName}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={12}>
            <Field
              name={"comment"}
              id={"comment"}
              label={t(deploymentSettingsFields.comment)}
              component={Textfield}
              required={deploymentSettingsRequiredFields.includes("comment")}
              className={classes.field}
              multiline
              rows="3"
              variant="outlined"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

InformationTab.propTypes = {
  form: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default flow(reduxForm())(InformationTab);
