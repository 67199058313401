import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";

import { TableBody, TableRow, TableCell } from "@material-ui/core";

import AlertBar from "msa2-ui/src/components/AlertBar";
import TableMessage from "msa2-ui/src/components/TableMessage";
import TableLoading from "msa2-ui/src/components/TableLoading";

const MSATableBody = ({
  colSpan,
  reload,
  loading,
  error,
  noContents,
  ...rest
}) => {
  if (loading) return <TableLoading numberOfTableColumns={colSpan} />;
  if (error) {
    const Error = error;
    return (
      <TableBody>
        <TableRow>
          {isFunction(error) ? (
            <Error />
          ) : (
            <TableCell colSpan={colSpan}>
              <AlertBar message={error} refreshFnc={reload} adjust />
            </TableCell>
          )}
        </TableRow>
        {rest.children}
      </TableBody>
    );
  }
  if (noContents)
    return <TableMessage message={noContents} numberOfTableColumns={colSpan} />;

  return <TableBody {...rest} />;
};

MSATableBody.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.bool]),
  errorMessage: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.bool,
  ]),
  notFoundMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  colSpan: PropTypes.number,
  reload: PropTypes.func,
};
export default MSATableBody;
