import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default () => {
  return (
    <SvgIcon viewBox="0 0 512 512">
      <path d="M90.1 395.9c-7.2 0-14.1 1.8-20.2 5.1C37.7 359.8 20 308.5 20 256 20 125.9 125.9 20 256 20c15.8 0 31.6 1.6 47 4.7 5.4 1.1 10.7-2.4 11.8-7.8S312.4 6.2 307 5.1C290.3 1.7 273.1 0 256 0 187.6 0 123.3 26.6 75 75S0 187.6 0 256c0 57.5 19.5 113.7 55.1 158.7-4.6 6.8-7 14.9-7 23.3 0 11.2 4.4 21.8 12.3 29.7 7.9 7.9 18.5 12.3 29.7 12.3s21.8-4.4 29.7-12.3c7.9-7.9 12.3-18.5 12.3-29.7s-4.4-21.8-12.3-29.7c-8-8-18.5-12.4-29.7-12.4zm15.5 57.6c-4.2 4.2-9.7 6.4-15.6 6.4s-11.4-2.3-15.6-6.4-6.4-9.7-6.4-15.6 2.3-11.4 6.4-15.6c4.2-4.2 9.7-6.4 15.6-6.4s11.4 2.3 15.6 6.4c4.2 4.2 6.4 9.7 6.4 15.6.1 5.9-2.2 11.4-6.4 15.6zM456.9 97.3c10.9-16.3 9.1-38.6-5.3-53-7.9-7.9-18.5-12.3-29.7-12.3s-21.8 4.4-29.7 12.3c-7.9 7.9-12.3 18.5-12.3 29.7s4.4 21.8 12.3 29.7 18.5 12.3 29.7 12.3c7.2 0 14.1-1.8 20.2-5.1C474.3 152.2 492 203.5 492 256c0 130.1-105.9 236-236 236-15.7 0-31.3-1.5-46.6-4.6-5.4-1.1-10.7 2.4-11.8 7.8-1.1 5.4 2.4 10.7 7.8 11.8 16.5 3.3 33.5 5 50.5 5 68.4 0 132.7-26.6 181-75s75-112.6 75-181c.1-57.5-19.4-113.7-55-158.7zm-50.5-7.7c-4.2-4.2-6.4-9.7-6.4-15.6s2.3-11.4 6.4-15.6c4.2-4.2 9.7-6.4 15.6-6.4s11.4 2.3 15.6 6.4c8.6 8.6 8.6 22.5 0 31.1-4.2 4.2-9.7 6.4-15.6 6.4-5.9.2-11.5-2.1-15.6-6.3z" />
      <path d="M214.7 428c0 5.5 4.5 10 10 10h62.5c5.5 0 10-4.5 10-10v-19.4c13.1-3.5 25.7-8.8 37.5-15.6l13.7 13.7c1.9 1.9 4.4 2.9 7.1 2.9 2.6 0 5.2-1.1 7.1-2.9l44.2-44.2c3.9-3.9 3.9-10.2 0-14.1l-13.7-13.7c6.8-11.8 12-24.4 15.6-37.5H428c5.5 0 10-4.5 10-10v-62.5c0-5.5-4.5-10-10-10h-19.3c-3.5-13.1-8.7-25.7-15.6-37.5l13.6-13.6c1.9-1.9 2.9-4.4 2.9-7.1 0-2.7-1.1-5.2-2.9-7.1l-44.2-44.2c-3.9-3.9-10.2-3.9-14.1 0l-13.6 13.6c-11.8-6.8-24.4-12-37.5-15.6V84c0-5.5-4.5-10-10-10h-62.5c-5.5 0-10 4.5-10 10v19.2c-13.1 3.5-25.7 8.7-37.5 15.6l-13.6-13.6c-3.9-3.9-10.2-3.9-14.1 0l-44.2 44.2c-1.9 1.9-2.9 4.4-2.9 7.1s1.1 5.2 2.9 7.1l13.6 13.6c-6.8 11.8-12 24.4-15.6 37.5H84c-5.5 0-10 4.5-10 10v62.5c0 2.7 1.1 5.2 2.9 7.1s4.4 2.9 7.1 2.9h19.3c3.5 13.1 8.8 25.7 15.6 37.5l-13.7 13.7c-3.9 3.9-3.9 10.2 0 14.1l44.2 44.2c1.9 1.9 4.4 2.9 7.1 2.9 2.7 0 5.2-1.1 7.1-2.9l13.7-13.7c11.8 6.8 24.4 12 37.5 15.6V428zm-33.6-56c-4-2.6-9.2-2-12.5 1.3l-12.2 12.2-30.1-30.1 12.2-12.2c3.3-3.3 3.9-8.5 1.3-12.5-9.1-14-15.4-29.4-18.9-45.7-1-4.6-5.1-7.9-9.8-7.9H94v-42.5h17.2c4.7 0 8.8-3.3 9.8-7.9 3.5-16.3 9.9-31.7 18.9-45.7 2.6-4 2-9.2-1.3-12.5l-12.1-12.1 30.1-30.1 12.1 12.1c3.3 3.3 8.5 3.9 12.5 1.3 14-9.1 29.4-15.4 45.7-18.9 4.6-1 7.9-5.1 7.9-9.8V94h42.5v17.1c0 4.7 3.3 8.8 7.9 9.8 16.3 3.5 31.7 9.9 45.7 18.9 4 2.6 9.2 2 12.5-1.3l12.1-12.1 30.1 30.1-12.1 12.1c-3.3 3.3-3.9 8.5-1.3 12.5 9.1 14 15.4 29.4 18.9 45.7 1 4.6 5.1 7.9 9.8 7.9H418v42.5h-17.2c-4.7 0-8.8 3.3-9.8 7.9-3.5 16.3-9.9 31.7-18.9 45.7-2.6 4-2 9.2 1.3 12.5l12.2 12.2-30.1 30.1-12.2-12.2c-3.3-3.3-8.5-3.9-12.5-1.3-14 9.1-29.4 15.4-45.7 18.9-4.6 1-7.9 5.1-7.9 9.8V418h-42.5v-17.3c0-4.7-3.3-8.8-7.9-9.8-16.3-3.4-31.6-9.8-45.7-18.9z" />
    </SvgIcon>
  );
};
