import React from "react";

import AccountCircle from "@material-ui/icons/AccountCircle";
/**
 * REDUX
 */
import { connect } from "react-redux";

import { Avatar } from "@material-ui/core";

const ProfileAvatar = ({ name, size, icon }) => {
  // no parameter: set menu size
  const wh = size || 24;
  return icon ? (
    <Avatar alt={name} src={icon} style={{ width: wh, height: wh }} />
  ) : (
    <AccountCircle style={{ width: wh, height: wh }} />
  );
};

const mapStateToProps = (state) => ({
  name: state.auth.userDetails.name,
});

export default connect(mapStateToProps)(ProfileAvatar);
