import { get, destroy, post, put, download } from "./request";
import Repository from "msa2-ui/src/services/Repository";
import { contentTypes } from "msa2-ui/src/api/constants";

const API = process.env.REACT_APP_API_PATH;

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/listFilesInFolder
 */
export const listFilesInFolder = ({ token, uri, transforms = [] }) => {
  return get({
    url: `${API}/repository/files`,
    queryParams: { uri },
    token,
    transforms,
  });
};

/**
 NB! If there is no 'parentFolder' then the api will return list of folders for 'opt/fmc_repository'
 */
export const listRepositoriesOrFilesInFolder = ({
  uri,
  type = "",
  token,
  transforms = [],
}) => {
  return get({
    url: `${API}/repository/v2/repositories`,
    queryParams: {
      uri,
      type,
    },
    token,
    transforms,
  });
};

export const listFilesInFmcRepositories = async ({
  repositories,
  token,
  transforms = [],
}) => {
  const promises = repositories.map((uri) =>
    listRepositoriesOrFilesInFolder({ uri, type: "F", token, transforms }),
  );
  const responses = await Promise.all(promises);
  const error = responses.find(([err]) => err);
  const data = responses.reduce((acc, [, files]) => {
    acc.push(...(files || []));
    return acc;
  }, []);
  return [error, data];
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/readFileContent
 */
export const readRepository = ({ token, uri = "", transforms = [] }) => {
  return get({
    url: `${API}/repository/file`,
    queryParams: { uri: Repository.removeRepositoryPrefix(uri) },
    token,
    transforms,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/downloadArchive
 */
export const readBinaryRepository = ({ token, uri: path, transforms = [] }) => {
  return get({
    url: `${API}/repository/v1/download`,
    queryParams: { path },
    accept: "*/*",
    token,
    transforms,
  });
};

/*
 * Spec: http://10.31.1.52/swagger/#/Repository/addFile
 */
export const createRepositoryFile = ({
  uri,
  tag,
  comment,
  content,
  token,
  displayName,
  customerId,
  saveOwner,
}) => {
  return post({
    url: `${API}/repository/file`,
    queryParams: {
      uri: Repository.removeRepositoryPrefix(uri),
      tag,
      comment,
      displayName,
      customerId,
      saveOwner,
    },
    body: { content },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/updateFile
 */
export const updateRepositoryFile = ({
  uri,
  displayName,
  tag,
  comment,
  content,
  token,
}) => {
  return put({
    url: `${API}/repository/file`,
    queryParams: {
      uri: Repository.removeRepositoryPrefix(uri),
      displayName,
      tag,
      comment,
    },
    body: { content },
    token,
  });
};
/*
 * Spec: https://10.31.1.52/swagger/#/Repository/removeFile
 */
export const deleteRepositoryFile = ({ token, uri }) => {
  return destroy({
    url: `${API}/repository/file`,
    queryParams: { uri: Repository.removeRepositoryPrefix(uri) },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/exists
 */
export const checkRepositoryExistence = ({ token, uri }) => {
  return get({
    url: `${API}/repository/exists`,
    queryParams: { uri },
    token,
  });
};
/*
 * Spec: https://10.31.1.52/swagger/#/Repository/getElementAbsoluteURI
 */
export const getFileAtAbsoluteURI = ({ token, fileURI }) => {
  return get({
    url: `${API}/repository/v1/absolute-uri`,
    queryParams: { fileURI },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/addDirectory
 */
export const addDirectory = ({ uri, tag, comment, token }) => {
  return post({
    url: `${API}/repository/directory`,
    queryParams: { uri: Repository.removeRepositoryPrefix(uri), tag, comment },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/removeDirectory
 */
export const removeDirectory = ({ token, uri }) => {
  return destroy({
    url: `${API}/repository/directory`,
    queryParams: { uri: Repository.removeRepositoryPrefix(uri) },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/downloadArchive
 */
export const downloadArchive = ({
  token,
  path,
  fileName = Repository.getFilenameFromUri(path),
  onError,
}) => {
  return download({
    url: `${API}/repository/v1/download`,
    queryParams: { path },
    token,
    fileName,
    onError,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/downloadArchive
 */
export const getImage = async ({ token, path }) => {
  const [error, blob, meta] = await get({
    url: `${API}/repository/v1/download`,
    queryParams: { path },
    accept: "image/*",
    token,
  });
  const url = error ? "" : URL.createObjectURL(blob);
  return [error, { blob, url }, meta];
};

/*
 * Spec: https://10.31.1.5/swagger/#/Repository/uploadDatafiles
 */
export const uploadDataFile = ({ token, file, customerId, path }) => {
  return post({
    url: `${API}/repository/v1/upload-datafiles`,
    body: file,
    queryParams: { customerId, path },
    contentType: contentTypes.FORM_DATA,
    token,
  });
};

/*
 * Spec: https://10.31.1.5/swagger/#/Repository/uploadFile
 */
export const repoFileUpload = ({ token, file, path }) => {
  return post({
    url: `${API}/repository/v1/upload`,
    body: file,
    queryParams: { path },
    contentType: contentTypes.FORM_DATA,
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/uploadDatafiles
 */
export const unzipFile = ({ token, path, folderNameForextractedContent }) => {
  return post({
    url: `${API}/repository/v1/unzip`,
    queryParams: { path, folderNameForextractedContent },
    token,
  });
};

/*
 * Spec: https://10.31.1.52/swagger/#/Repository/uploadDatafiles
 */
export const zipFolder = ({ token, path, zipFileName }) => {
  return post({
    url: `${API}/repository/v1/zip`,
    queryParams: { path, zipFileName },
    token,
  });
};
