import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Repository from "msa2-ui/src/services/Repository";
import FeatureFlag from "msa2-ui/src/services/FeatureFlag";
import useApi from "msa2-ui/src/hooks/useApi";
import useWorkflowList from "msa2-ui/src/hooks/useWorkflowList";
import { getMicroservices } from "msa2-ui/src/api/microservices";
import { getBpmDiagramsForSubtenant } from "msa2-ui/src/api/bpm";
import { makeStyles } from "@material-ui/core";
import { Grid, List, Typography } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";

import { ReactComponent as Documentation } from "msa2-ui/src/assets/icons/developer/icon-documentation.svg";
import { ReactComponent as Github } from "msa2-ui/src/assets/icons/developer/icon-github.svg";
import { ReactComponent as Support } from "msa2-ui/src/assets/icons/developer/icon-support.svg";
import { ReactComponent as DocumentationLight } from "msa2-ui/src/assets/icons/developer/icon-documentation-light.svg";
import { ReactComponent as GithubLight } from "msa2-ui/src/assets/icons/developer/icon-github-light.svg";
import { ReactComponent as SupportLight } from "msa2-ui/src/assets/icons/developer/icon-support-light.svg";

import openMsaImg from "msa2-ui/src/assets/icons/developer/openmsa-logo-OK.svg";
import AutomationIcon from "msa2-ui/src/assets/icons/developer/automation.svg";
import ConfigurationsIcon from "msa2-ui/src/assets/icons/developer/configurations.svg";
import ManagedEntitiesIcon from "msa2-ui/src/assets/icons/developer/managed-entites.svg";

import {
  DashboardBoxWrapper,
  DashboardBoxItem,
  DeveloperDashboardPanel,
} from "./DeveloperDashboardPanel";
import { getMsaTheme } from "msa2-ui/src/store/designations";
import {
  delegationProfileTypes,
  getDelegationProfile,
} from "msa2-ui/src/store/delegationProfiles";

const isPermissionProfileLabelsEnabled = FeatureFlag.isEnabled(
  FeatureFlag.features.permissionProfileLabels,
);
const isBPMEnabled = FeatureFlag.isEnabled(FeatureFlag.features.enableBPM);

const useStyles = makeStyles(({ darkMode }) => ({
  boxWrapper: {
    background: darkMode ? "transparent" : "#F3F5F9",
  },
  openMsa: {
    backgroundImage: `url(${openMsaImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: 110,
    height: 34,
    display: "block",
  },
  boxHeading: {
    color: "#fff",
    lineHeight: "1.75rem",
    paddingTop: 8,
  },
  boxSubHeading: {
    display: "block",
    fontSize: "0.8125rem",
    fontWeight: "normal",
    margin: "0px 10px",
  },
  listBlock: {
    width: "calc(100% + 32px)",
    marginLeft: -16,
  },
}));

const DeveloperDashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isWorkflowsOwnerDetailsEnabled = FeatureFlag.isEnabled(
    FeatureFlag.features.workflowsOwner,
  );

  const canAccessBpm = isBPMEnabled;
  const canCreateBpm = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "general.create"),
  );
  const canCreateWorkflow = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "general.create"),
  );
  const canCreateMicroservice = useSelector(
    getDelegationProfile(delegationProfileTypes.MICROSERVICE, "general.create"),
  );
  const canEditBpm = useSelector(
    getDelegationProfile(delegationProfileTypes.BPM, "general.modify"),
  );
  const canEditWorkflow = useSelector(
    getDelegationProfile(delegationProfileTypes.WORKFLOWS, "general.modify"),
  );
  const canEditMicroservice = useSelector(
    getDelegationProfile(delegationProfileTypes.MICROSERVICE, "general.modify"),
  );
  const darkMode = useSelector(getMsaTheme);

  const [workflowsLoading, , workflows = {}] = useWorkflowList({
    page: 1,
    pageSize: 3,
    sort: "timestamp",
    sortOrder: "DESC",
  });

  const [
    microservicesLoading,
    ,
    microservices = [],
    microservicesMetadata,
  ] = useApi(getMicroservices, {
    page: 1,
    pageSize: 3,
    sortBy: "mod_date",
    sort: "desc",
    filterByLabel: isPermissionProfileLabelsEnabled,
  });

  const [bpmDiagramsIsLoading, , bpmDiagrams = [], bpmDiagramsMeta] = useApi(
    getBpmDiagramsForSubtenant,
    {
      isFilteredByOwner: isWorkflowsOwnerDetailsEnabled,
      page: 1,
      page_size: 3,
    },
    !canAccessBpm,
  );

  const dashboardPanelItems = [
    {
      layer: t("BPM"),
      total: bpmDiagramsMeta?.total || 0,
      loading: bpmDiagramsIsLoading,
      items: bpmDiagrams?.map(({ name, dateModified, uri, permission }) => ({
        displayName: Repository.stripFileExtensionFromString(name),
        timestamp: dateModified,
        uri: `/automation/bpm/${encodeURIComponent(uri)}/edit`,
        disable: !isEmpty(permission) ? !permission.modify : false,
      })),
      backgroundImage: `url(${ManagedEntitiesIcon})`,
      canCreate: canCreateBpm,
      canEdit: canEditBpm,
      createLink: "/automation/bpm/create",
      seeMoreLink: "/automation/bpm",
      emptyMessage: t("There are no BPM to display"),
      canAccess: canAccessBpm,
    },
    {
      layer: t("Workflow"),
      total: workflows.workflows_count || 0,
      loading: workflowsLoading,
      items: workflows.workflows?.map(
        ({ displayName, timestamp, icon, path, permission }) => ({
          displayName,
          timestamp,
          icon,
          uri: `/automation/workflows/${encodeURIComponent(path)}/edit`,
          disable: !isEmpty(permission) ? !permission.modify : false,
        }),
      ),
      backgroundImage: `url(${AutomationIcon})`,
      canCreate: canCreateWorkflow,
      canEdit: canEditWorkflow,
      createLink: "/automation/workflows/create",
      seeMoreLink: "/automation/workflows",
      emptyMessage: t("There are no Workflows to display"),
      canAccess: true,
    },
    {
      layer: t("Microservice"),
      total: microservicesMetadata?.total_ms_count || 0,
      loading: microservicesLoading,
      items: microservices?.map(({ displayName, lastModified, icon, uri }) => ({
        displayName,
        timestamp: lastModified,
        icon,
        uri: `/integration/microservices/${encodeURIComponent(uri)}/edit`,
      })),
      backgroundImage: `url(${ConfigurationsIcon})`,
      canCreate: canCreateMicroservice,
      canEdit: canEditMicroservice,
      createLink: "/integration/microservices/create",
      seeMoreLink: "/integration/microservices",
      emptyMessage: t("There are no Microservices to display"),
      canAccess: true,
    },
  ];

  return (
    <div className={classes.root}>
      {/* Top Grid */}
      <Grid container spacing={2}>
        <Grid container item spacing={2} justifyContent="center">
          {dashboardPanelItems
            .filter(({ canAccess }) => canAccess)
            .map((panelItemProps, index) => {
              const key = `${panelItemProps.layer}-${index}`;
              return (
                <Grid key={key} item xs>
                  <DeveloperDashboardPanel {...panelItemProps} />
                </Grid>
              );
            })}
        </Grid>
        <Grid
          className={classes.boxWrapper}
          item
          xs={12}
          sm={12}
          md={6}
          lg={12}
        >
          <DashboardBoxWrapper
            header={
              <Grid container justifyContent={"center"} alignContent={"center"}>
                <span className={classes.openMsa}></span>
                <Typography className={classes.boxHeading} variant="h4" noWrap>
                  <span className={classes.boxSubHeading}>
                    {t("Resources and Community")}
                  </span>
                </Typography>
              </Grid>
            }
          >
            <List className={classes.listBlock}>
              <DashboardBoxItem
                icon={darkMode ? Documentation : DocumentationLight}
                linkTo="https://ubiqube.com/openmsa-access/#documentation"
                primaryText={t("View Documentation")}
                secondaryText={t("Learn how to use the MSA")}
              />
              <DashboardBoxItem
                icon={darkMode ? Github : GithubLight}
                linkTo="https://github.com/openmsa"
                primaryText={t("Contribute on Github")}
                secondaryText={t("Share your work with the community")}
              />
              <DashboardBoxItem
                icon={darkMode ? Support : SupportLight}
                linkTo="https://discuss.ubiqube.com/"
                primaryText={t("Support")}
                secondaryText={t("If you get stuck, contact us on our forum.")}
              />
            </List>
          </DashboardBoxWrapper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeveloperDashboard;
