import React, { useState } from "react";
import PropTypes from "prop-types";
import { MenuItem } from "msa2-ui/src/components/menu";
import { useBoundedTranslation } from "msa2-ui/src/hooks/useBoundedTranslation";
import { getCategories } from "msa2-ui/src/components/topology/utils";
import AddCategoryActionDialog from "./AddCategoryActionDialog";

const AddCategoryAction = ({ node }) => {
  const t = useBoundedTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <>
      <AddCategoryActionDialog
        key="add-category-action"
        categories={getCategories(node)}
        meName={node.id}
        deviceId={node.__metadata__?.objectId}
        isDialogOpen={isDialogOpen}
        onClose={toggleDialog}
      />
      <MenuItem onClick={toggleDialog} id="ADD_CATEGORY_ACTION">
        {t("Add Tags")}
      </MenuItem>
    </>
  );
};

AddCategoryAction.propTypes = {
  node: PropTypes.object.isRequired,
};

export default AddCategoryAction;
