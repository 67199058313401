import React, { useState } from "react";
import { PropTypes } from "prop-types";
import Dialog from "msa2-ui/src/components/Dialog";
import { useTranslation } from "react-i18next";
import useToggle from "react-use/lib/useToggle";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeRequest } from "msa2-ui/src/api/request";
import { methods, contentTypes } from "msa2-ui/src/api/constants";
import {
  readRepository,
  readBinaryRepository,
  updateRepositoryFile,
} from "msa2-ui/src/api/repository";

import useApi from "msa2-ui/src/hooks/useApi";
import { useCommonStyles } from "msa2-ui/src/styles/commonStyles";
import { makeStyles } from "@material-ui/core";
import EditorAce from "msa2-ui/src/components/EditorAce";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import useDialog from "msa2-ui/src/hooks/useDialog";
import { getToken } from "msa2-ui/src/store/auth";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import SnackbarAction from "msa2-ui/src/components/SnackbarAction";
import MsaSelect from "msa2-ui/src/components/msa-select";
import { get, isObject, isEmpty, omit } from "lodash";

const useStyles = makeStyles(({ darkMode, palette, typography }) => ({
  editorWrapper: {
    overflowY: "auto",
    height: "100%",
  },
  unsavedChanges: {
    padding: "20px 24px 16px 24px",
    fontWeight: typography.fontWeightMedium,
    color: palette.error.main,
  },
  iconButton: {
    marginTop: 13,
    marginBottom: 13,
    marginRight: 15,
    padding: 0,
  },
  validationSelect: {
    width: 400,
  },
  validateButton: {
    marginLeft: 10,
    marginRight: 20,
  },
  errorMessage: {
    overflowX: "auto",
  },
  binaryMessage: {
    margin: 64,
  },
}));

const EditFileDialog = ({
  closeDialog,
  fileUri,
  validations = [],
  isBinary = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [editedData, setEditedData] = useState("");

  const [loadingFileData, , fileData] = useApi(
    readRepository,
    {
      uri: fileUri,
      transforms: [
        (response) => {
          const content = response?.content;
          setEditedData(
            isObject(content) ? JSON.stringify(content) : content || "",
          );
          return response;
        },
      ],
    },
    isBinary,
  );
  const [isFullScreenMode, toggleFullScreenMode] = useToggle(false);
  const [isEditorUnsaved, setIsEditorUnsaved] = useState(false);
  const [showDiscardDialog, DiscardDialog] = useDialog();
  const token = useSelector(getToken);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedValidation, setSelectedValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const filteredValidations = validations.filter(
    ({ extensions: _extensions }) => {
      if (!_extensions) {
        return true;
      }
      const extensions = Array.isArray(_extensions)
        ? _extensions
        : [_extensions];
      return extensions.some((extension) => fileUri.endsWith(extension));
    },
  );
  let editorMode = "text";
  if (fileUri.includes(".")) {
    const findFileType = fileUri.split(".");
    editorMode = findFileType[findFileType.length - 1];
  }

  const handleSave = async () => {
    const [error] = await updateRepositoryFile({
      uri: fileUri,
      content: editedData,
      token,
    });
    const message = error
      ? error.getMessage(t("Unable to save file"))
      : t("File saved successfully");
    enqueueSnackbar(message, {
      variant: error ? "error" : "success",
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    closeDialog();
  };

  const handleClose = () => {
    if (isEditorUnsaved) {
      return showDiscardDialog();
    }
    closeDialog();
  };
  const executeValidation = async () => {
    enqueueSnackbar(t("Validating..."), {
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    const {
      endpoint,
      method = methods.POST,
      headers = {},
      errorMessagePath = "message",
      formKey = "file",
    } = selectedValidation;
    const contentType = headers["Content-Type"];
    let body;
    if (method !== methods.GET) {
      if (contentType === contentTypes.FORM_DATA) {
        const [, blob] = await readBinaryRepository({
          uri: fileUri,
          token,
        });
        const formData = new FormData();
        formData.append(formKey, blob);
        body = formData;
      } else {
        body = fileData.content;
      }
    }
    const [, response, meta] = await makeRequest({
      url: endpoint,
      customHeaders: omit(headers, "Content-Type"),
      method,
      contentType,
      body,
    });
    const isError = ["4", "5"].some((firstDigit) =>
      meta.status.toString().startsWith(firstDigit),
    );

    const variant = isError ? "error" : "success";
    const message = isError
      ? t("An error has occurred while validating the file.")
      : t("The File has been validated without errors.");
    enqueueSnackbar(message, {
      variant,
      action: (key) => <SnackbarAction id={key} handleClose={closeSnackbar} />,
    });
    if (isError) {
      setErrorMessage(
        errorMessagePath
          ? get(response, errorMessagePath)
          : JSON.stringify(response),
      );
    } else {
      setErrorMessage("");
    }
  };

  const editorHeight = isFullScreenMode ? window.innerHeight - 300 : "500px";

  return (
    <>
      <Dialog
        onExec={handleSave}
        onClose={handleClose}
        execLabel={t("Save")}
        title={t("Edit File") + " : " + fileUri}
        maxWidth={isFullScreenMode ? "xl" : "lg"}
        disabled={isBinary}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography
                id="EDIT_FILE_UNSAVED_CHANGES"
                variant="body1"
                className={classes.unsavedChanges}
              >
                {isEditorUnsaved && t("UNSAVED CHANGES")}
              </Typography>
            </Grid>
            {Boolean(filteredValidations.length) && (
              <>
                <Grid item>
                  <MsaSelect
                    id="EDIT_FILE_VALIDATION_SELECT"
                    options={filteredValidations}
                    placeholder={t("Select Validation...")}
                    value={selectedValidation}
                    onChange={setSelectedValidation}
                    className={classes.validationSelect}
                  />
                </Grid>
                <Grid item className={classes.validateButton}>
                  <Button
                    id="EDIT_FILE_VALIDATE_BUTTON"
                    variant="contained"
                    color="primary"
                    onClick={executeValidation}
                    disabled={isEmpty(selectedValidation)}
                  >
                    {t("Validate")}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item>
              <Tooltip
                title={
                  isFullScreenMode ? t("Restore Editor") : t("Maximize Editor")
                }
              >
                <IconButton
                  aria-label={
                    isFullScreenMode
                      ? t("Restore Editor size")
                      : t("Maximize Editor size")
                  }
                  id="TASK_BTN_FULLSCREEN"
                  className={classes.iconButton}
                  onClick={toggleFullScreenMode}
                >
                  {isFullScreenMode ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {errorMessage && (
            <Grid
              item
              xs={12}
              className={classes.errorMessage}
              style={{
                maxHeight: editorHeight,
              }}
            >
              <Typography
                id="EDIT_FILE_ERROR_MESSAGE"
                variant="body1"
                className={classes.unsavedChanges}
              >
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.editorWrapper}>
            {(() => {
              if (isBinary) {
                return (
                  <Typography className={classes.binaryMessage}>
                    {t("This is Binary file")}
                  </Typography>
                );
              }
              if (loadingFileData) {
                return (
                  <Grid
                    container
                    className={commonClasses.commonLoaderWrapper}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <CircularProgress />
                  </Grid>
                );
              }
              return (
                <EditorAce
                  height={editorHeight}
                  mode={EditorAce.mode[editorMode]}
                  value={editedData}
                  onChange={(value) => {
                    if (value !== fileData?.content) {
                      setEditedData(value);
                      setIsEditorUnsaved(true);
                    }
                  }}
                />
              );
            })()}
          </Grid>
        </Grid>
      </Dialog>
      <DiscardDialog
        title={t("Discard changes?")}
        content={t("Are you sure you want to discard your changes?")}
        onExec={closeDialog}
      />
    </>
  );
};

EditFileDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fileUri: PropTypes.string.isRequired,
  isBinary: PropTypes.bool,
};
export default EditFileDialog;
