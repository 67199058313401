import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import useApi from "msa2-ui/src/hooks/useApi";
import useWorkflowDrawer from "msa2-ui/src/hooks/useWorkflowDrawer";
import { getWorkflow, getWorkflowInstances } from "msa2-ui/src/api/workflow";
import {
  initialState,
  getSelectedSubtenant,
  selectWorkflow,
} from "msa2-ui/src/store/designations";
import Variable from "msa2-ui/src/services/Variable";
import Repository from "msa2-ui/src/services/Repository";
import { statusTypes } from "msa2-ui/src/Constants";

import { Grid, Table, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import AlertBar from "msa2-ui/src/components/AlertBar";
import MSATableBody from "msa2-ui/src/components/MSATableBody";
import VariableViewTableHeader from "msa2-ui/src/components/variables/VariableViewTableHeader";

import VariableViewTableBody from "./VariableViewTableBody";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 10,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const WorkflowInstanceVariableView = ({
  workflowData,
  instanceData = [],
  workflowUri = Repository.stripFileExtensionFromString(
    workflowData.metaInformationList[0].uri,
  ),
  columnCount = 8,
  hideInstanceName = false,
  stateForApi = {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [instances, setInstances] = useState(instanceData);

  const { ubiqubeId } = useSelector(getSelectedSubtenant);
  const [
    processInstancesLoading,
    processInstancesError,
    workflowDetails,
    ,
    reloadProcessInstances,
  ] = useApi(
    getWorkflowInstances,
    {
      ubiqubeId,
      workflowPath: decodeURIComponent(workflowUri),
      ...stateForApi,
    },
    !workflowUri || !ubiqubeId,
  );

  useEffect(() => {
    if (workflowDetails) {
      setInstances(workflowDetails.instances);
    }
  }, [workflowDetails]);

  useEffect(() => {
    if (!ubiqubeId) {
      setInstances([]);
    }
  }, [ubiqubeId]);

  const [
    workflowDefinitionLoading,
    workflowDefinitionError,
    workflowDefinition = workflowData,
    ,
    reloadWorkflowDefinitions,
  ] = useApi(
    getWorkflow,
    {
      pathToWorkflowDefinitionFile: decodeURIComponent(workflowUri),
    },
    workflowData || !workflowUri,
  );

  const [showWorkflowDrawer, WorkflowDrawer] = useWorkflowDrawer();

  const targetVariables = Variable.filterEditOnly(
    workflowDefinition?.variables?.variable || [],
  ).slice(0, columnCount);
  const targetProcesses = workflowDefinition?.process;
  const colSpan = targetVariables.length + 3;

  useEffect(() => {
    dispatch(selectWorkflow(initialState.selectedWorkflow));
    return () => {
      // Clear selected Workflow when unmount
      dispatch(selectWorkflow(initialState.selectedWorkflow));
    };
  }, [dispatch, ubiqubeId]);

  const isRunning = (instance) => {
    return instance?.status?.status === statusTypes.IN_PROGRESS;
  };

  const Error = () => (
    <TableCell colSpan={colSpan}>
      <AlertBar
        message={t("Unable to load x", { x: t("Workflow Instances") })}
        refreshFnc={() => {
          reloadProcessInstances();
          reloadWorkflowDefinitions();
        }}
        adjust
      />
    </TableCell>
  );

  return (
    <Grid container className={classes.tableWrapper}>
      <WorkflowDrawer
        workflowDefinition={workflowDefinition}
        onClose={reloadProcessInstances}
      />
      <Table stickyHeader className={classes.root}>
        <VariableViewTableHeader
          variables={targetVariables}
          columnCount={8}
          primaryLabel={!hideInstanceName && t("Instance Name")}
          colSpanSuffix={2}
        />

        <MSATableBody
          loading={
            !instances.length &&
            (processInstancesLoading || workflowDefinitionLoading)
          }
          error={(processInstancesError || workflowDefinitionError) && Error}
          noContents={
            (workflowDefinition?.variables?.variable?.length === 0 ||
              !instances.length) &&
            (ubiqubeId
              ? t("No Workflow Instances found")
              : t("Please select a subtenant to show Workflow Instances"))
          }
          colSpan={colSpan}
        >
          {instances?.map((instance) => {
            const id = instance.variables?.SERVICEINSTANCEID;
            const displayName =
              instance.variables?.[workflowData?.information.displayField];
            return (
              <VariableViewTableBody
                processExecutionDisabled={isRunning(instance)}
                isActionButtonsDisabled={processInstancesLoading}
                key={id}
                id={id}
                workflowPath={decodeURIComponent(workflowUri)}
                variables={targetVariables}
                processes={targetProcesses}
                displayName={displayName}
                instance={instance}
                showWorkflowDrawer={showWorkflowDrawer}
                reloadProcessInstances={reloadProcessInstances}
                hideInstanceName={hideInstanceName}
              />
            );
          })}
        </MSATableBody>
      </Table>
    </Grid>
  );
};

WorkflowInstanceVariableView.propTypes = {
  workflowData: PropTypes.object,
  instanceData: PropTypes.array,
  workflowUri: PropTypes.string,
  columnCount: PropTypes.number,
  hideInstanceName: PropTypes.bool,
};

export default WorkflowInstanceVariableView;
