import { getTime, format, parseISO, intervalToDuration } from "date-fns";

export const formatDateOrString = (date, formatString) => {
  if (!date) {
    return null;
  }
  if (typeof date === "string") {
    return format(parseISO(date), formatString);
  }
  return format(date, formatString);
};

export const getTimestamp = (date) => {
  return getTime(new Date(date)).toString();
};

/**
 * Returns timezone of given date
 *
 * @param {Date|number} date
 * @returns {String} example: GMT+5:30
 */
export const getTimeZone = (date) =>
  new Date(date)
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

/**
 * Returns timezone abbreviation
 *
 * @param {Date|number} date
 * @returns {String} example: GMT
 */
export const getTimeZoneAbbreviation = (date) =>
  getTimeZone(date).substring(0, 3);

/**
 * To display date in given format with timezone
 *
 * @param {Date|number} date
 * @returns {String} example: Dec 06, 2023 2:42:50 GMT+5:30
 */
export const displayMonthDayYearTimeDateTimeZone = (date) =>
  formatDateOrString(date, "MMM dd, yyyy h:mm:ss z");

export const displayMonthDayYearTimeDate = (date) =>
  formatDateOrString(date, "MMM dd, yyyy h:mm:ssaa");

export const displayMonthDayYear = (date) =>
  formatDateOrString(date, "MMM dd, yyyy");

export const displayHourMinute = (date) => formatDateOrString(date, "HH:mm");

export const displayYearMonthTimeDateAsES = (date) =>
  formatDateOrString(date, "yyyy-MM-dd'T'HH:mm:ss");

export const displayYearMonthDayHourMinuteSecond = (date) =>
  formatDateOrString(date, "yyyy-MM-dd HH:mm:ss");

export const displayUserFriendlyDuration = (start, end) => {
  if (start && end) {
    const duration = intervalToDuration({
      start: new Date(start),
      end: new Date(end),
    });

    Object.keys(duration).forEach((key) => {
      duration[key] = duration[key].toString().padStart(2, "0");
    });

    const { days, hours, minutes, seconds } = duration;

    const difference = (end - start).toString();
    const milliseconds = difference
      .slice(difference.length - 3)
      .padStart(3, "0");

    return `${
      days > 0 ? days * 24 + Number(hours) : hours
    }:${minutes}:${seconds}.${milliseconds}`;
  } else {
    return "";
  }
};
