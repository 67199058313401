import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default () => {
  return (
    <SvgIcon viewBox="0 0 840 840">
      <path
        d="M89.6,724.1c-3.8,0-7.6-1.5-10.6-4.4c-5.8-5.8-5.8-15.3,0-21.1l127.5-127.3c2.8-2.8,6.6-4.4,10.5-4.4h109.8
		l73.3-73.3V426c0-8.2,6.7-14.9,14.9-14.9s14.9,6.7,14.9,14.9v73.8c0,4-1.6,7.7-4.4,10.5l-82,82c-2.8,2.8-6.6,4.4-10.5,4.4H223.3
		L100.2,719.8C97.2,722.7,93.4,724.1,89.6,724.1z"
      />
      <path
        d="M290.4,152.5c-29.1,0-52.7-23.7-52.7-52.7c0-29.1,23.7-52.7,52.7-52.7c29.1,0,52.7,23.7,52.7,52.7
		S319.4,152.5,290.4,152.5z M290.4,76.8c-12.6,0-22.9,10.3-22.9,22.9s10.3,22.9,22.9,22.9s22.9-10.3,22.9-22.9S303,76.8,290.4,76.8z
		"
      />
      <path
        d="M61.1,249.2c-29.1,0-52.7-23.7-52.7-52.7c0-29.1,23.7-52.7,52.7-52.7s52.7,23.7,52.7,52.7
		S90.2,249.2,61.1,249.2z M61.1,173.5c-12.6,0-22.9,10.3-22.9,22.9c0,12.6,10.3,22.9,22.9,22.9S84,209.1,84,196.5
		C84,183.8,73.7,173.5,61.1,173.5z"
      />
      <path
        d="M412.3,436.3c-29.1,0-52.7-23.7-52.7-52.7c0-29.1,23.7-52.7,52.7-52.7c14.3,0,27.6,5.5,37.6,15.6
		c9.9,10,15.3,23.2,15.2,37.3C465,412.7,441.3,436.3,412.3,436.3z M412.3,360.7c-12.6,0-22.9,10.3-22.9,22.9s10.3,22.9,22.9,22.9
		s22.9-10.3,22.9-22.9c0.1-6.2-2.3-11.8-6.5-16.1C424.3,363.1,418.5,360.7,412.3,360.7z"
      />
      <path
        d="M439.3,675.1c-29.1,0-52.7-23.7-52.7-52.7c0-29.1,23.7-52.7,52.7-52.7c29.1,0,52.7,23.7,52.7,52.7
		C492,651.4,468.4,675.1,439.3,675.1z M439.3,599.4c-12.6,0-22.9,10.3-22.9,22.9s10.3,22.9,22.9,22.9s22.9-10.3,22.9-22.9
		S451.9,599.4,439.3,599.4z"
      />
      <path
        d="M61.1,784.5c-29.1,0-52.7-23.7-52.7-52.7C8.4,702.7,32,679,61.1,679s52.7,23.7,52.7,52.7
		C113.8,760.9,90.2,784.5,61.1,784.5z M61.1,708.8c-12.6,0-22.9,10.3-22.9,22.9s10.3,22.9,22.9,22.9S84,744.4,84,731.8
		S73.7,708.8,61.1,708.8z"
      />
      <path
        d="M449.8,779.9c-16,0-34-2.6-54.3-9.1c-30.1-9.7-54.4-33.7-62-61.1c-5.3-19.1-1.7-37.6,9.9-50.8
		c5.4-6.2,14.8-6.8,21-1.4c6.2,5.4,6.8,14.8,1.4,21c-6.6,7.6-5.1,17.8-3.6,23.3c3.8,13.7,17.2,32.5,42.4,40.7
		c77.5,25,114.2-18.5,122.5-30.4V250.9c0-8.2,6.7-14.9,14.9-14.9s14.9,6.7,14.9,14.9v465.4c0,2.5-0.6,5-1.9,7.2
		c-0.5,0.9-12.3,21.8-38.1,37.9C501.8,770.9,479.5,779.9,449.8,779.9z"
      />
      <path
        d="M275.1,537c-1.6,0-3.3-0.3-4.9-0.8c-7.8-2.7-11.9-11.2-9.2-19c11.4-32.9,34.5-62.8,63.2-82.1
		c6.8-4.6,16.1-2.7,20.7,4.1c4.6,6.8,2.7,16.1-4.1,20.7c-23.4,15.7-42.3,40.1-51.6,67.1C287,533.1,281.2,537,275.1,537z"
      />
      <path
        d="M354.8,683.5c-1.8,0-3.6-0.3-5.4-1c-1.7-0.7-42.5-16.9-70.5-50.6c-5.3-6.3-4.4-15.7,1.9-21s15.7-4.4,21,1.9
		c23,27.6,58.1,41.7,58.5,41.9c7.6,3,11.4,11.7,8.4,19.3C366.4,679.9,360.8,683.5,354.8,683.5z"
      />
      <path
        d="M306.2,485.3c-4.2,0-8.5-1.8-11.4-5.3c-2.2-2.6-53-64.4-25.4-144.2c12.1-34.8,39.2-57.9,78.5-66.9
		c11.4-2.6,22.3-3.6,31.2-3.9c-2.6-9.4-5.3-21.1-6.5-33.6c-5.3-53.3,18.4-77.2,39.2-87.8c35.7-18.3,66.5-20.2,91.4-5.5
		c50.3,29.6,53.5,116.4,53.6,120.1c0.3,8.2-6.2,15.1-14.4,15.4c-8.2,0.2-15.1-6.2-15.4-14.4c-0.7-20.5-9-77.7-39-95.3
		c-15.6-9.1-36.6-7-62.7,6.3c-45.3,23.1-11.9,103.9-11.5,104.7c2.1,4.9,1.4,10.6-1.9,14.9s-8.5,6.4-13.8,5.7
		c-3.2-0.4-79.8-9.8-100.6,50.1c-22,63.5,18.4,113.2,20.1,115.3c5.2,6.3,4.4,15.7-1.9,21C312.9,484.2,309.6,485.3,306.2,485.3z"
      />
      <path
        d="M778.1,485.4c-3.4,0-6.8-1.1-9.6-3.5c-6.3-5.3-7.1-14.7-1.9-21c1.8-2.2,42-51.9,20.1-115.3
		c-21.1-60.3-99.8-50.2-100.6-50.1c-6,0.8-11.9-2.1-14.9-7.3c-3-5.2-2.6-11.8,1.1-16.6c0.1-0.1,7.4-9.7,13.5-23.2
		c5.2-11.6,10.5-28.6,6.7-44c-3.5-14.3-14.3-25.3-33-33.9c-27.4-12.5-49.5-13.7-65.5-3.6c-29.3,18.5-36.5,72.7-36.8,92
		c-0.1,8.2-6.9,14.8-15.1,14.7c-8.2-0.1-14.8-6.9-14.7-15.1c0-3.5,1.8-85.8,50.6-116.8c25-15.9,56.6-15.3,93.8,1.6
		c27.2,12.4,43.9,30.6,49.7,54c6.1,24.9-2,50.2-10.8,68c7.7,0.5,16.4,1.6,25.5,3.6c39.3,9,66.5,32.1,78.6,66.8
		c27.6,79.8-23.2,141.6-25.4,144.2C786.6,483.5,782.4,485.4,778.1,485.4z"
      />
      <path
        d="M635.4,793c-57.1,0-97.4-27.4-107.8-73.4c-1.8-8,3.2-16,11.2-17.8s16,3.2,17.8,11.2
		c10.2,45.2,58.2,50.2,78.8,50.2c0.2,0,0.3,0,0.4,0c47.4-0.2,94-23.6,99.9-50.1c7.8-35.8-4.1-66.4-35.3-90.8
		c-24.7-19.3-51.9-27.3-52.1-27.4c-7.9-2.3-12.5-10.6-10.2-18.5s10.6-12.5,18.5-10.2c1.3,0.4,33.1,9.7,62.2,32.5
		c40.4,31.6,56.4,73.4,46.1,120.6c-4.7,21.3-21.9,40.5-48.4,54.3c-23.4,12.1-52.7,19.2-80.5,19.2C635.8,793,635.6,793,635.4,793z"
      />
      <path
        d="M627.9,361c-1.7,0-3.4-0.3-5.1-0.9c-7.7-2.8-11.7-11.3-9-19.1c1.1-3.1,27.3-74.2,81.8-74.2c0.4,0,0.9,0,1.3,0
		c8.2,0.2,14.8,7,14.6,15.2s-7.1,14.8-15.2,14.6c-33.4-0.7-54.2,53.9-54.5,54.5C639.7,357.3,634,361,627.9,361z"
      />
      <path
        d="M679.7,494.5c-73.4,0-125.5-23.4-150.5-67.6c-4.1-7.2-1.5-16.3,5.6-20.3c7.2-4.1,16.3-1.5,20.3,5.6
		c24.6,43.4,81.3,52.5,124.6,52.5c8.2,0,14.9,6.7,14.9,14.9S688,494.5,679.7,494.5z"
      />
      <path
        d="M580.1,470.5c-1.2,0-2.3,0-3.5-0.1c-8.2-0.3-14.6-7.3-14.3-15.5s7.3-14.6,15.5-14.3c18.7,0.7,47.1-8,75.6-54.1
		c4.3-7,13.5-9.2,20.5-4.8c7,4.3,9.2,13.5,4.8,20.5C644.3,458.1,606.7,470.5,580.1,470.5z"
      />
      <path
        d="M748.9,678.8c-5.2,0-10.2-2.7-13-7.5c-4.1-7.2-1.6-16.3,5.6-20.3c18.4-10.5,61.1-40.1,60.3-89.1
		c-0.4-23.5-9.2-48-24.5-69.2c-10.1,11.7-21.8,28.9-20,42.3c1.1,8.2-4.6,15.7-12.8,16.8s-15.7-4.6-16.8-12.8
		c-5.2-38.3,36-75.8,40.7-79.9c6.1-5.4,15.4-4.9,20.9,1.2c26.3,28.9,41.7,65.8,42.3,101.1c1.1,65-52.4,102.4-75.4,115.5
		C753.9,678.2,751.4,678.8,748.9,678.8z"
      />
      <path
        d="M637.7,711c-25.7,0-50.3-9.7-72.6-28.9c-23.3-20.1-35.7-43.7-36.2-44.7c-3.8-7.3-0.9-16.3,6.4-20.1
		c7.3-3.8,16.3-1,20.1,6.3c1.7,3.2,40.7,75.6,108.1,53.1c7.8-2.6,16.3,1.6,18.9,9.4c2.6,7.8-1.6,16.3-9.4,18.9
		C661,709,649.3,711,637.7,711z"
      />
      <path
        d="M217.1,596.8c-3.8,0-7.6-1.5-10.5-4.4l-156-156c-2.8-2.8-4.4-6.6-4.4-10.5V241.7c0-8.2,6.7-14.9,14.9-14.9
		S76,233.5,76,241.7v178l151.6,151.6c5.8,5.8,5.8,15.3,0,21.1C224.7,595.4,220.9,596.8,217.1,596.8z"
      />
      <path
        d="M123.9,422.7c-8.2,0-14.9-6.7-14.9-14.9V270.5c0-4,1.6-7.7,4.4-10.5l140.7-140.7c5.8-5.8,15.3-5.8,21.1,0
		c5.8,5.8,5.8,15.3,0,21.1L138.8,276.6v131.2C138.8,416.1,132.1,422.7,123.9,422.7z"
      />
      <path
        d="M412.3,608.9c-3.8,0-7.6-1.5-10.5-4.4L356,558.9c-5.8-5.8-5.8-15.3,0-21.1s15.3-5.8,21.1,0l45.7,45.7
		c5.8,5.8,5.8,15.3,0,21.1C419.9,607.5,416.1,608.9,412.3,608.9z"
      />
    </SvgIcon>
  );
};
